import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { fetch, optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ClassGroupQueries } from '.';
import { ClassGroupInterface } from '../../+models';
import { ClassGroupServiceInterface, CLASS_GROUP_SERVICE_TOKEN } from '../../class-group/class-group.service.interface';
import { DalState } from '../dal.state.interface';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import {
  AddClassGroup,
  ClassGroupsActionTypes,
  ClassGroupsLoaded,
  ClassGroupsLoadError,
  DeleteClassGroup,
  LoadClassGroups,
  NavigateToClassGroupDetail,
  StartAddClassGroup,
  UpdateClassGroup,
} from './class-group.actions';

@Injectable()
export class ClassGroupEffects {
  loadClassGroups$ = createEffect(() =>
    this.actions.pipe(
      ofType(ClassGroupsActionTypes.LoadClassGroups),
      concatLatestFrom(() => this.store.select(ClassGroupQueries.getLoaded)),
      fetch({
        run: (action: LoadClassGroups, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.classGroupService
            .getAllForUser(action.payload.userId)
            .pipe(map((classGroups) => new ClassGroupsLoaded({ classGroups })));
        },
        onError: (action: LoadClassGroups, error) => {
          return new ClassGroupsLoadError(error);
        },
      })
    )
  );

  startAddClassGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(ClassGroupsActionTypes.StartAddClassGroup),
      pessimisticUpdate({
        run: (action: StartAddClassGroup) => {
          return this.classGroupService
            .createClassGroupForSchool(action.payload.schoolId, {
              ...action.payload.classGroup,
              years: [action.payload.year],
            })
            .pipe(
              switchMap((classGroup: ClassGroupInterface) => {
                const actionsToDispatch: Action[] = [new AddClassGroup({ classGroup })];

                if (action.payload.navigateAfterCreate) {
                  actionsToDispatch.push(new NavigateToClassGroupDetail({ classGroup }));
                }

                return from(actionsToDispatch);
              })
            );
        },
        onError: (action: StartAddClassGroup, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de klas te maken.'
            ),
          });
        },
      })
    )
  );

  deleteClassGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(ClassGroupsActionTypes.DeleteClassGroup),
      optimisticUpdate({
        run: (action: DeleteClassGroup) => {
          // removing class group and linked persons is optimistically done in the classgroup and schoolRoleMappingClassGroup reducers
          // call api
          return this.classGroupService.removeClassGroupFromSchool(action.payload.schoolId, action.payload.id).pipe(
            map((_) => {
              return new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(this.uuid(), action, 'De klas werd verwijderd.'),
              });
            })
          );
        },
        undoAction: (action: DeleteClassGroup, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de klas te verwijderen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  updateClassGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(ClassGroupsActionTypes.UpdateClassGroup),
      optimisticUpdate({
        run: (action: UpdateClassGroup) => {
          return this.classGroupService
            .updateClassGroupForSchool(
              action.payload.schoolId,
              +action.payload.classGroup.id,
              action.payload.classGroup.changes
            )
            .pipe(
              map((update) => {
                return new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'De klas werd bijgewerkt.'
                  ),
                });
              })
            );
        },
        undoAction: (action: UpdateClassGroup, error: any) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de klas bij te werken.'
            ),
          });

          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  redirectToClassGroup$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(ClassGroupsActionTypes.NavigateToClassGroupDetail),
        tap((action: NavigateToClassGroupDetail) => {
          this.router.navigate([], {
            queryParams: { classGroup: action.payload.classGroup.id },
            queryParamsHandling: 'merge',
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    @Inject('uuid') private uuid: () => string,
    private actions: Actions,
    private store: Store<DalState>,
    private router: Router,
    @Inject(CLASS_GROUP_SERVICE_TOKEN)
    private classGroupService: ClassGroupServiceInterface
  ) {}
}
