import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { EvaluationInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum EvaluationsActionTypes {
  EvaluationsLoaded = '[Evaluations] Evaluations Loaded',
  EvaluationsLoadError = '[Evaluations] Load Error',
  LoadEvaluationsForBook = '[Evaluations] Load Evaluations For Book',
  AddEvaluation = '[Evaluations] Add Evaluation',
  StartAddEvaluation = '[Evaluations] Start Add Evaluation',
  AddEvaluations = '[Evaluations] Add Evaluations',
  AddEvaluationsForBook = '[Evaluations] Add Evaluations for Book',
  UpsertEvaluation = '[Evaluations] Upsert Evaluation',
  UpsertEvaluations = '[Evaluations] Upsert Evaluations',
  UpdateEvaluation = '[Evaluations] Update Evaluation',
  UpdateEvaluations = '[Evaluations] Update Evaluations',
  DeleteEvaluation = '[Evaluations] Delete Evaluation',
  DeleteEvaluations = '[Evaluations] Delete Evaluations',
  ClearEvaluations = '[Evaluations] Clear Evaluations',
  AddLoadedForBook = '[Evaluations ] Add Loaded For Book',
  NavigateToEvaluationDetail = '[Evaluations ] Navigate To Evaluation Detail',
}

export class LoadEvaluationsForBook implements Action {
  readonly type = EvaluationsActionTypes.LoadEvaluationsForBook;

  constructor(public payload: { force?: boolean; bookId: number }) {}
}

export class EvaluationsLoaded implements Action {
  readonly type = EvaluationsActionTypes.EvaluationsLoaded;

  constructor(public payload: { evaluations: EvaluationInterface[] }) {}
}

export class EvaluationsLoadError implements Action {
  readonly type = EvaluationsActionTypes.EvaluationsLoadError;
  constructor(public payload: any) {}
}

export class AddEvaluation implements Action {
  readonly type = EvaluationsActionTypes.AddEvaluation;

  constructor(public payload: { evaluation: EvaluationInterface }) {}
}
export class StartAddEvaluation implements FeedbackTriggeringAction {
  readonly type = EvaluationsActionTypes.StartAddEvaluation;

  constructor(
    public payload: {
      evaluation: EvaluationInterface;
      tocId: number;
      navigateAfterCreate?: boolean;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpsertEvaluation implements Action {
  readonly type = EvaluationsActionTypes.UpsertEvaluation;

  constructor(public payload: { evaluation: EvaluationInterface }) {}
}

export class AddEvaluations implements Action {
  readonly type = EvaluationsActionTypes.AddEvaluations;

  constructor(public payload: { evaluations: EvaluationInterface[] }) {}
}

export class AddEvaluationsForBook implements Action {
  readonly type = EvaluationsActionTypes.AddEvaluationsForBook;

  constructor(public payload: { evaluations: EvaluationInterface[]; bookId: number }) {}
}

export class UpsertEvaluations implements Action {
  readonly type = EvaluationsActionTypes.UpsertEvaluations;

  constructor(public payload: { evaluations: EvaluationInterface[] }) {}
}

export class UpdateEvaluation implements Action, FeedbackTriggeringAction {
  readonly type = EvaluationsActionTypes.UpdateEvaluation;

  constructor(
    public payload: {
      evaluation: Update<EvaluationInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpdateEvaluations implements Action {
  readonly type = EvaluationsActionTypes.UpdateEvaluations;

  constructor(public payload: { evaluations: Update<EvaluationInterface>[] }) {}
}

export class DeleteEvaluation implements FeedbackTriggeringAction {
  readonly type = EvaluationsActionTypes.DeleteEvaluation;

  constructor(
    public payload: {
      evaluationId: number;
      tocId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class DeleteEvaluations implements FeedbackTriggeringAction {
  readonly type = EvaluationsActionTypes.DeleteEvaluations;

  constructor(public payload: { ids: number[]; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export class ClearEvaluations implements Action {
  readonly type = EvaluationsActionTypes.ClearEvaluations;
}

export class NavigateToEvaluationDetail implements Action {
  readonly type = EvaluationsActionTypes.NavigateToEvaluationDetail;

  constructor(public payload: { evaluation: EvaluationInterface }) {}
}

export type EvaluationsActions =
  | LoadEvaluationsForBook
  | EvaluationsLoaded
  | EvaluationsLoadError
  | AddEvaluation
  | StartAddEvaluation
  | AddEvaluationsForBook
  | UpsertEvaluation
  | AddEvaluations
  | UpsertEvaluations
  | UpdateEvaluation
  | UpdateEvaluations
  | DeleteEvaluation
  | DeleteEvaluations
  | ClearEvaluations
  | NavigateToEvaluationDetail;
