/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { LearningDomainApi as SDKLearningDomainApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { LearningDomainInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class LearningDomainApi extends ModelApiBase {
  private SDKLearningDomainApi = inject(SDKLearningDomainApi);

  constructor(api: PolpoApi) {
    super(api, 'LearningDomains');
  }

  find(options?): Observable<LearningDomainInterface[]> {
    return this.SDKLearningDomainApi.find(...arguments);
  }
}
