import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { Action, Store, select } from '@ngrx/store';
import { fetch, pessimisticUpdate } from '@nrwl/angular';
import { Observable, combineLatest, from, of } from 'rxjs';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { LinkedPersonQueries } from '.';
import { PersonInterface, SchoolRoleMappingClassGroupInterface, SchoolRoleMappingInterface } from '../../+models';
import { AUTH_SERVICE_TOKEN, AuthServiceInterface, LinkedPersonService } from '../../persons';
import { SCHOOL_SERVICE_TOKEN, SchoolServiceInterface } from '../../school';
import { DalState } from '../dal.state.interface';
import { LoadData } from '../data/person/person-data.actions';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { RoleQueries } from '../role';
import { SchoolRoleMappingQueries } from '../school-role-mapping';
import { SchoolRoleMappingClassGroupQueries } from '../school-role-mapping-class-group';
import {
  AddSchoolRoleMappingClassGroup,
  DeleteSchoolRoleMappingClassGroups,
  UpsertSchoolRoleMappingClassGroup,
  UpsertSchoolRoleMappingClassGroups,
} from '../school-role-mapping-class-group/school-role-mapping-class-group.actions';
import {
  AddSchoolRoleMapping,
  DeleteSchoolRoleMappings,
  UpdateSchoolRoleMapping,
  UpsertSchoolRoleMappings,
} from '../school-role-mapping/school-role-mapping.actions';
import {
  AddLinkedPerson,
  AddStudentCoins,
  DeleteSchoolAdmin,
  DeleteStudent,
  DeleteStudents,
  DeleteTeacher,
  LinkedPersonsActionTypes,
  LinkedPersonsLoadError,
  LinkedPersonsLoaded,
  LoadLinkedPersons,
  NavigateToStudentDetail,
  NavigateToTeacherDetail,
  SendInviteMail,
  SendResetMail,
  StartAddSchoolAdmin,
  StartAddStudent,
  StartAddTeacher,
  StartImportStudents,
  StartLinkSchoolAdmin,
  StartUpdateSchoolAdmin,
  StartUpdateStudent,
  StartUpdateTeacher,
  UpdateLinkedPerson,
  UpdateStudentPersonalCode,
  UpsertLinkedPersons,
} from './linked-person.actions';

@Injectable()
export class LinkedPersonEffects {
  loadLinkedPersons$ = createEffect(() =>
    this.actions.pipe(
      ofType(LinkedPersonsActionTypes.LoadLinkedPersons),
      concatLatestFrom(() => this.store.select(LinkedPersonQueries.getLoaded)),
      fetch({
        run: (action: LoadLinkedPersons, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.linkedPersonService
            .getAllForUser(action.payload.userId)
            .pipe(map((persons) => new LinkedPersonsLoaded({ persons })));
        },
        onError: (action: LoadLinkedPersons, error) => {
          return new LinkedPersonsLoadError(error);
        },
      })
    )
  );

  startAddStudent$ = createEffect(() =>
    this.actions.pipe(
      ofType(LinkedPersonsActionTypes.StartAddStudent),
      pessimisticUpdate({
        run: (action: StartAddStudent) => {
          const { schoolId, student, classGroup, classNumber } = action.payload;
          const classGroupId = classGroup && classGroup.id;

          return this.linkedPersonService.createStudent(schoolId, student, classGroupId, classNumber).pipe(
            switchMap(({ person, schoolRoleMapping, schoolRoleMappingClassGroup }) => {
              const actionsToDispatch: Action[] = [
                new AddLinkedPerson({ person }),
                new AddSchoolRoleMapping({ schoolRoleMapping }),
              ];

              if (schoolRoleMappingClassGroup) {
                actionsToDispatch.push(
                  new AddSchoolRoleMappingClassGroup({
                    schoolRoleMappingClassGroup,
                  })
                );
              }

              if (action.payload.navigateAfterCreate) {
                actionsToDispatch.push(
                  new NavigateToStudentDetail({
                    studentId: person.id,
                    classGroupId: classGroupId || 0,
                  })
                );
              }

              return from(actionsToDispatch);
            })
          );
        },
        onError: (action: StartAddStudent, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de leerling te maken.'
            ),
          });
        },
      })
    )
  );

  redirectToStudent$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(LinkedPersonsActionTypes.NavigateToStudentDetail),
        tap((action: NavigateToStudentDetail) => {
          this.router.navigate([], {
            queryParams: {
              student: action.payload.studentId,
              classGroup: action.payload.classGroupId,
            },
            queryParamsHandling: 'merge',
          });
        })
      ),
    { dispatch: false }
  );

  sendInviteMail$ = createEffect(() =>
    this.actions.pipe(
      ofType(LinkedPersonsActionTypes.SendInviteMail),
      pessimisticUpdate({
        run: (action: SendInviteMail) => {
          const { schoolId, personId } = action.payload;

          return this.schoolService.sendInviteMail(schoolId, personId).pipe(
            switchMap((person: Partial<PersonInterface>) => {
              return from(<Action[]>[
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'De uitnodigingsmail werd verstuurd.'
                  ),
                }),
                new UpdateLinkedPerson({
                  person: {
                    id: personId,
                    changes: person,
                  },
                }),
              ]);
            })
          );
        },
        onError: (action: SendInviteMail, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de uitnodigingsmail te versturen.'
            ),
          });
        },
      })
    )
  );

  startImportStudents$ = createEffect(() =>
    this.actions.pipe(
      ofType(LinkedPersonsActionTypes.StartImportStudents),
      pessimisticUpdate({
        run: (action: StartImportStudents) => {
          const { schoolId, students, rootNumbersToRemove = [], rootNumbersToUnlink = [] } = action.payload;

          return this.linkedPersonService.importStudents(schoolId, students, !!rootNumbersToRemove.length).pipe(
            withLatestFrom(
              this.getDeleteStudentForRootNumbersActions(schoolId, rootNumbersToRemove),
              this.getDeleteStudentForRootNumbersActions(schoolId, rootNumbersToUnlink, true)
            ),
            switchMap(([{ success, errors }, studentDeleteActions, studentUnlinkActions]) => {
              const { persons, schoolRoleMappings, schoolRoleMappingClassGroups } = success;
              const actionsToDispatch: Action[] = [
                ...studentDeleteActions,
                ...studentUnlinkActions,
                new UpsertLinkedPersons({ persons }),
                new UpsertSchoolRoleMappings({ schoolRoleMappings }),
                new UpsertSchoolRoleMappingClassGroups({
                  schoolRoleMappingClassGroups,
                }),
                // TODO: this is a band-aid solution for studentIds being out of date after import
                // the proper fix would be to deprecate .studentIds everywhere and use roles instead
                new LoadData({
                  userId: this.authService.userId,
                  force: true,
                  fields: ['classGroups'],
                }),
              ];

              if (errors.length === 0) {
                actionsToDispatch.push(
                  new AddEffectFeedback({
                    effectFeedback: EffectFeedback.generateSuccessFeedback(
                      this.uuid(),
                      action,
                      'Het importeren is voltooid.'
                    ),
                  })
                );
              } else {
                const errorMessage = errors.map((error) => {
                  const [[name, reason]] = Object.entries(error);
                  switch (reason) {
                    case 'class_group_not_found':
                      return `<li>${name}: klas niet gevonden</li>`;
                    case 'duplicate_class_number':
                      return `<li>${name}: klasnummer is niet uniek</li>`;
                    case 'validation_failed':
                    default:
                      return (
                        `<li>${name}: naam en voornaam zijn verplicht, ` +
                        `stamnummer moet exact 7 cijfers tellen en uniek zijn in de school</li>`
                      );
                  }
                });

                actionsToDispatch.push(
                  new AddEffectFeedback({
                    effectFeedback: EffectFeedback.generateErrorFeedback(
                      this.uuid(),
                      action,
                      (persons.length
                        ? `Er ${
                            persons.length === 1 ? 'werd 1 leerling' : `werden ${persons.length} leerlingen`
                          } succesvol geïmporteerd.<br />`
                        : '') +
                        `Het importeren van deze leerling${
                          errorMessage.length > 1 ? 'en' : ''
                        } is mislukt:<ul>${errorMessage.join('')}</ul>`
                    ),
                  })
                );
              }

              return from(actionsToDispatch);
            })
          );
        },
        onError: (action: StartImportStudents, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de leerlingen te importeren.'
            ),
          });
        },
      })
    )
  );

  startAddTeacher$ = createEffect(() =>
    this.actions.pipe(
      ofType(LinkedPersonsActionTypes.StartAddTeacher),
      pessimisticUpdate({
        run: (action: StartAddTeacher) => {
          const { schoolId, teacher } = action.payload;
          return this.linkedPersonService.createTeacher(schoolId, teacher).pipe(
            switchMap(({ person, schoolRoleMapping }) => {
              const actionsToDispatch: Action[] = [
                new AddLinkedPerson({ person }),
                new AddSchoolRoleMapping({ schoolRoleMapping }),
              ];

              if (action.payload.navigateAfterCreate) {
                actionsToDispatch.push(
                  new NavigateToTeacherDetail({
                    teacherId: person.id,
                  })
                );
              }
              return from(actionsToDispatch);
            })
          );
        },
        onError: (action: StartAddTeacher, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de leerkracht te maken.'
            ),
          });
        },
      })
    )
  );

  redirectToTeacher$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(LinkedPersonsActionTypes.NavigateToTeacherDetail),
        tap((action: NavigateToTeacherDetail) => {
          this.router.navigate([], {
            queryParams: { teacher: action.payload.teacherId },
            queryParamsHandling: 'merge',
          });
        })
      ),
    { dispatch: false }
  );

  deleteStudent$ = createEffect(() =>
    this.actions.pipe(
      ofType(LinkedPersonsActionTypes.DeleteStudent),
      pessimisticUpdate({
        run: (action: DeleteStudent) => {
          return this.linkedPersonService.removeStudentFromSchool(action.payload.schoolId, action.payload.id).pipe(
            switchMap((_) =>
              this.getRemoveSchoolRoleRelationActions(action.payload.schoolId, action.payload.id, 'student')
            ),
            switchMap((removeActions) =>
              from([
                ...removeActions,
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'De leerling werd verwijderd.'
                  ),
                }),
              ])
            )
          );
        },
        onError: (action: DeleteStudent, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de leerling te verwijderen.'
            ),
          });
          return errorFeedbackAction;
        },
      })
    )
  );

  deleteStudents$ = createEffect(() =>
    this.actions.pipe(
      ofType(LinkedPersonsActionTypes.DeleteStudents),
      pessimisticUpdate({
        run: (action: DeleteStudents) => {
          return this.linkedPersonService.removeStudentsFromSchool(action.payload.schoolId, action.payload.ids).pipe(
            switchMap((_) =>
              this.getRemoveSchoolRoleRelationActions(action.payload.schoolId, action.payload.ids, 'student')
            ),
            switchMap((removeActions) =>
              from([
                ...removeActions,
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'De leerlingen werden verwijderd.'
                  ),
                }),
              ])
            )
          );
        },
        onError: (action: DeleteStudents, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de leerlingen te verwijderen.'
            ),
          });
          return errorFeedbackAction;
        },
      })
    )
  );

  startUpdateStudent$ = createEffect(() =>
    this.actions.pipe(
      ofType(LinkedPersonsActionTypes.StartUpdateStudent),
      pessimisticUpdate({
        run: (action: StartUpdateStudent) => {
          const { schoolId, studentId, student, classGroupId, classNumber } = action.payload;

          return combineLatest([
            this.store.pipe(select(SchoolRoleMappingClassGroupQueries.getBySchoolRoleMappingId)),
            this.linkedPersonService.updateStudent(schoolId, studentId, student, classGroupId, classNumber),
          ]).pipe(
            take(1),
            switchMap(([bySchoolRoleMappingId, { person, schoolRoleMapping, schoolRoleMappingClassGroup }]) =>
              from(
                this.getUpdateStudentActions(
                  bySchoolRoleMappingId,
                  person,
                  schoolRoleMapping,
                  schoolRoleMappingClassGroup
                )
              )
            )
          );
        },
        onError: (action: StartUpdateStudent, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de leerling bij te werken.'
            ),
          });
        },
      })
    )
  );

  startUpdateStudentPersonalCode$ = createEffect(() =>
    this.actions.pipe(
      ofType(LinkedPersonsActionTypes.UpdateStudentPersonalCode),
      pessimisticUpdate({
        run: (action: UpdateStudentPersonalCode) => {
          const { schoolId, personId, personalCode: personalCode } = action.payload;

          return this.schoolService.updateStudentPersonalCode(schoolId, personId, personalCode).pipe(
            map((srm) => {
              return new UpdateSchoolRoleMapping({
                schoolRoleMapping: {
                  id: srm.id,
                  changes: { personalCode: srm.personalCode },
                },
              });
            })
          );
        },
        onError: (action: UpdateStudentPersonalCode, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het wachtwoord van de leerling bij te werken.'
            ),
          });
        },
      })
    )
  );

  startAddStudentCoins$ = createEffect(() =>
    this.actions.pipe(
      ofType(LinkedPersonsActionTypes.AddStudentCoins),
      pessimisticUpdate({
        run: (action: AddStudentCoins) => {
          const { schoolId, personId, gameCoinsToAdd } = action.payload;

          return this.schoolService.addStudentCoins(schoolId, personId, gameCoinsToAdd).pipe(
            map((srm) => {
              return new UpdateSchoolRoleMapping({
                schoolRoleMapping: {
                  id: srm.id,
                  changes: { gameCoins: srm.gameCoins },
                },
              });
            })
          );
        },
        onError: (action: AddStudentCoins, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de kwetons van de leerling toe te voegen.'
            ),
          });
        },
      })
    )
  );

  deleteTeacher$ = createEffect(() =>
    this.actions.pipe(
      ofType(LinkedPersonsActionTypes.DeleteTeacher),
      pessimisticUpdate({
        run: (action: DeleteTeacher) => {
          return this.linkedPersonService.removeTeacherFromSchool(action.payload.schoolId, action.payload.id).pipe(
            switchMap((_) =>
              this.getRemoveSchoolRoleRelationActions(action.payload.schoolId, action.payload.id, 'teacher')
            ),
            switchMap((removeActions) =>
              from([
                ...removeActions,
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'De leerkracht werd verwijderd.'
                  ),
                }),
              ])
            )
          );
        },
        onError: (action: DeleteTeacher, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de leerkracht te verwijderen.'
            ),
          });
          return errorFeedbackAction;
        },
      })
    )
  );

  DeleteSchoolAdmin$ = createEffect(() =>
    this.actions.pipe(
      ofType(LinkedPersonsActionTypes.DeleteSchoolAdmin),
      pessimisticUpdate({
        run: (action: DeleteSchoolAdmin) => {
          return this.linkedPersonService.removeSchoolAdminFromSchool(action.payload.schoolId, action.payload.id).pipe(
            switchMap((_) =>
              this.getRemoveSchoolRoleRelationActions(action.payload.schoolId, action.payload.id, 'schooladmin')
            ),
            switchMap((removeActions) =>
              from([
                ...removeActions,
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'De schoolbeheerder werd verwijderd.'
                  ),
                }),
              ])
            )
          );
        },
        onError: (action: DeleteSchoolAdmin, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de schoolbeheerder te verwijderen.'
            ),
          });
          return errorFeedbackAction;
        },
      })
    )
  );

  startUpdateTeacher$ = createEffect(() => {
    return this.actions.pipe(
      ofType(LinkedPersonsActionTypes.StartUpdateTeacher),
      pessimisticUpdate({
        run: (action: StartUpdateTeacher) => {
          return this.linkedPersonService
            .updateTeacher(action.payload.schoolId, +action.payload.teacher.id, action.payload.teacher.changes)
            .pipe(
              switchMap((updatedTeacher) =>
                from([
                  new UpdateLinkedPerson({
                    person: {
                      id: updatedTeacher.id,
                      changes: updatedTeacher,
                    },
                  }),
                  new AddEffectFeedback({
                    effectFeedback: EffectFeedback.generateSuccessFeedback(
                      this.uuid(),
                      action,
                      'De leerkracht werd bijgewerkt.'
                    ),
                  }),
                ])
              )
            );
        },
        onError: (action: StartUpdateTeacher, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de leerkracht bij te werken.'
            ),
          });

          return errorFeedbackAction;
        },
      })
    );
  });

  sendResetMail$ = createEffect(() =>
    this.actions.pipe(
      ofType(LinkedPersonsActionTypes.SendResetMail),
      pessimisticUpdate({
        run: (action: SendResetMail) => {
          const { email } = action.payload;

          return this.authService.resetPassword(email).pipe(
            map(
              () =>
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'Mail om wachtwoord te resetten verstuurd.'
                  ),
                })
            )
          );
        },
        onError: (action: SendResetMail, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het wachtwoord te resetten van deze gebruiker.'
            ),
          });
        },
      })
    )
  );

  startAddSchoolAdmin$ = createEffect(() =>
    this.actions.pipe(
      ofType(LinkedPersonsActionTypes.StartAddSchoolAdmin),
      pessimisticUpdate({
        run: (action: StartAddSchoolAdmin) => {
          const { schoolId, schoolAdmin } = action.payload;

          return this.linkedPersonService.createSchoolAdmin(schoolId, schoolAdmin).pipe(
            switchMap(({ person, schoolRoleMapping }) => {
              const actionsToDispatch: Action[] = [
                new AddLinkedPerson({ person }),
                new AddSchoolRoleMapping({ schoolRoleMapping }),
              ];

              if (action.payload.sendInviteMail) {
                actionsToDispatch.push(
                  new SendInviteMail({
                    personId: person.id,
                    schoolId,
                  })
                );
              }
              return from(actionsToDispatch);
            })
          );
        },
        onError: (action: StartAddSchoolAdmin, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de schoolbeheerder te maken.'
            ),
          });
        },
      })
    )
  );

  startLinkSchoolAdmin$ = createEffect(() =>
    this.actions.pipe(
      ofType(LinkedPersonsActionTypes.StartLinkSchoolAdmin),
      pessimisticUpdate({
        run: (action: StartLinkSchoolAdmin) => {
          const { schoolId, id } = action.payload;

          return this.linkedPersonService.linkSchoolAdmin(schoolId, id).pipe(
            switchMap(({ person, schoolRoleMapping }) => {
              const actionsToDispatch: Action[] = [
                new AddLinkedPerson({ person }),
                new AddSchoolRoleMapping({ schoolRoleMapping }),
              ];

              return from(actionsToDispatch);
            })
          );
        },
        onError: (action: StartLinkSchoolAdmin, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de schoolbeheerder toe te wijzen.'
            ),
          });
        },
      })
    )
  );

  startUpdateSchoolAdmin$ = createEffect(() => {
    return this.actions.pipe(
      ofType(LinkedPersonsActionTypes.StartUpdateSchoolAdmin),
      pessimisticUpdate({
        run: (action: StartUpdateSchoolAdmin) => {
          return this.linkedPersonService
            .updateSchoolAdmin(
              action.payload.schoolId,
              +action.payload.schoolAdmin.id,
              action.payload.schoolAdmin.changes
            )
            .pipe(
              switchMap((updatedSchoolAdmin) =>
                from([
                  new UpdateLinkedPerson({
                    person: {
                      id: updatedSchoolAdmin.id,
                      changes: updatedSchoolAdmin,
                    },
                  }),
                  new AddEffectFeedback({
                    effectFeedback: EffectFeedback.generateSuccessFeedback(
                      this.uuid(),
                      action,
                      'De beheerder werd bijgewerkt.'
                    ),
                  }),
                ])
              )
            );
        },
        onError: (action: StartUpdateSchoolAdmin, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de beheerder bij te werken.'
            ),
          });

          return errorFeedbackAction;
        },
      })
    );
  });

  /**
   * Returns the actions that need to happen when an updateStudent call completes.
   */
  private getUpdateStudentActions(
    bySchoolRoleMappingId: Dictionary<SchoolRoleMappingClassGroupInterface[]>,
    person: PersonInterface,
    schoolRoleMapping: SchoolRoleMappingInterface,
    schoolRoleMappingClassGroup: SchoolRoleMappingClassGroupInterface
  ) {
    const actionsToDispatch: Action[] = [
      new UpdateLinkedPerson({
        person: { id: person.id, changes: person },
      }),
      new UpdateSchoolRoleMapping({
        schoolRoleMapping: {
          id: schoolRoleMapping.id,
          changes: schoolRoleMapping,
        },
      }),
    ];

    if (!schoolRoleMappingClassGroup) {
      const schoolRoleMappingClassGroups = bySchoolRoleMappingId[schoolRoleMapping.id] || [];

      if (schoolRoleMappingClassGroups.length > 0) {
        // the related schoolRoleMappingClassGroups were removed
        actionsToDispatch.push(
          new DeleteSchoolRoleMappingClassGroups({
            ids: schoolRoleMappingClassGroups.map((srmcg) => srmcg.id),
          })
        );
      }
    } else {
      actionsToDispatch.push(
        new UpsertSchoolRoleMappingClassGroup({
          schoolRoleMappingClassGroup,
        })
      );
    }

    return actionsToDispatch;
  }

  /**
   * Returns actions to delete the SchoolRoleMappings and SchoolRoleMappingClassGroups
   *
   * Note: this does not return an action to delete the linked person, since there might be other roles
   * That's ok, persons are shown based on their roles -> no role === not shown
   */
  private getRemoveSchoolRoleRelationActions(
    schoolId: number,
    personId: number | number[],
    roleName: 'student' | 'teacher' | 'schooladmin',
    classGroupOnly = false
  ): Observable<Action[]> {
    return combineLatest([
      this.store.select(RoleQueries.getByName),
      this.store.select(SchoolRoleMappingQueries.getBySchoolIdByRoleId),
      this.store.select(SchoolRoleMappingClassGroupQueries.getAll),
    ]).pipe(
      take(1),
      map(([rolesMap, schoolRoleMappingBySchoolIdByRoleId, schoolRoleMappingClassGroups]) => {
        const actions: Action[] = [];

        const schoolRoleMappingsByRoleId = schoolRoleMappingBySchoolIdByRoleId[schoolId] || {};
        const schoolRoleMappings = schoolRoleMappingsByRoleId[rolesMap[roleName].id] || [];

        const personIdSet: Set<number> = new Set(Array.isArray(personId) ? personId : [personId]);
        const filteredSchoolRoleMappings = schoolRoleMappings.filter((srm) => personIdSet.has(srm.personId));

        if (filteredSchoolRoleMappings.length) {
          const srmIds = filteredSchoolRoleMappings.map((srm) => srm.id);

          if (!classGroupOnly) {
            actions.push(
              new DeleteSchoolRoleMappings({
                ids: srmIds,
              })
            );
          }

          const srmIdSet = new Set(srmIds);
          const filteredSRMCG = schoolRoleMappingClassGroups.filter((srmcg) => srmIdSet.has(srmcg.schoolRoleMappingId));

          if (filteredSRMCG.length) {
            actions.push(
              new DeleteSchoolRoleMappingClassGroups({
                ids: filteredSRMCG.map((srmcg) => srmcg.id),
              })
            );
          }
        }

        return actions;
      })
    );
  }

  private getDeleteStudentForRootNumbersActions(
    schoolId: number,
    rootNumbers: string[],
    classGroupOnly = false
  ): Observable<Action[]> {
    if (!rootNumbers.length) {
      return of([]);
    }

    const rootNumberSet = new Set(rootNumbers);
    return this.store.pipe(
      select(SchoolRoleMappingQueries.getBySchoolId),
      take(1),
      map((schoolRoleMappingBySchoolId) => {
        const schoolRoleMappings = schoolRoleMappingBySchoolId[schoolId] || [];
        const studentsIdsToRemove = schoolRoleMappings
          .filter((srm) => rootNumberSet.has(srm.rootNumber))
          .map((srm) => srm.personId);

        return studentsIdsToRemove;
      }),
      switchMap((studentIds) =>
        this.getRemoveSchoolRoleRelationActions(schoolId, studentIds, 'student', classGroupOnly)
      )
    );
  }

  constructor(
    @Inject('uuid') private uuid: () => string,
    private actions: Actions,
    private store: Store<DalState>,
    private linkedPersonService: LinkedPersonService,
    @Inject(AUTH_SERVICE_TOKEN)
    private authService: AuthServiceInterface,
    @Inject(SCHOOL_SERVICE_TOKEN)
    private schoolService: SchoolServiceInterface,
    private router: Router
  ) {}
}
