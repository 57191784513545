import { Inject, Pipe, PipeTransform } from '@angular/core';
import { EnvironmentTextMappingInterface, ENVIRONMENT_TEXT_MAPPING_TOKEN } from '@campus/environment';
import { StringFunctions, TextTransform } from '@campus/utils';

// https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object/58436959#58436959
type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]];

type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : '.'}${P}`
    : never
  : never;

// '3' here is the max recursion depth
export type Paths<T, D extends number = 3> = [D] extends [never]
  ? never
  : T extends object
  ? {
      [K in keyof T]-?: K extends string | number ? `${K}` | Join<K, Paths<T[K], Prev[D]>> : never;
    }[keyof T]
  : '';

export class TextMappingPipeError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'TextMappingPipeError';

    // https://www.dannyguo.com/blog/how-to-fix-instanceof-not-working-for-custom-errors-in-typescript/
    Object.setPrototypeOf(this, TextMappingPipeError.prototype);
  }
}

@Pipe({
  name: 'textMapping',
})
export class TextMappingPipe implements PipeTransform {
  constructor(
    @Inject(ENVIRONMENT_TEXT_MAPPING_TOKEN)
    private textMapping: EnvironmentTextMappingInterface
  ) {}

  transform(dotPath: Paths<EnvironmentTextMappingInterface>, transformOp: TextTransform = null): string {
    const result = dotPath.split('.').reduce((curr, next) => {
      if (curr[next] === undefined) {
        throw new TextMappingPipeError(
          `Got undefined when trying to read ${dotPath} from the EnvironmentTextMapping, '${next}' not found.`
        );
      }

      return curr[next];
    }, this.textMapping);

    if (transformOp) {
      return StringFunctions.transform(result, transformOp);
    }

    return result;
  }
}
