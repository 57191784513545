import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { fetch, pessimisticUpdate } from '@nrwl/angular';
import { from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { EduContentQueries } from '.';
import { EduContentServiceInterface, EDU_CONTENT_SERVICE_TOKEN } from '../../edu-content/edu-content.service.interface';
import { DalState } from '../dal.state.interface';
import {
  AddEduContentsForBook,
  AddGeneralFilesForBook,
  EduContentsActionTypes,
  EduContentsLoadError,
  LoadEduContentsForBook,
  LoadGeneralFilesForBook,
  NavigateToEduContentDetail,
  StartAddEduContent,
} from './edu-content.actions';

@Injectable()
export class EduContentsEffects {
  private actions: Actions = inject(Actions);
  private store: Store<DalState> = inject(Store);
  private eduContentService: EduContentServiceInterface = inject(EDU_CONTENT_SERVICE_TOKEN);
  private router: Router = inject(Router);

  loadEduContentsForBook$ = createEffect(() =>
    this.actions.pipe(
      ofType(EduContentsActionTypes.LoadEduContentsForBook),
      concatLatestFrom(() => this.store.select(EduContentQueries.selectEduContentState)),
      fetch({
        run: (action: LoadEduContentsForBook, eduContentState) => {
          const { bookId } = action.payload;

          if (!action.payload.force && eduContentState.loadedForBook[bookId]) return;

          return this.eduContentService
            .getAllForBook(bookId)
            .pipe(map((eduContents) => new AddEduContentsForBook({ bookId, eduContents })));
        },
        onError: (action: LoadEduContentsForBook, error) => {
          return new EduContentsLoadError(error);
        },
      })
    )
  );

  loadGeneralFilesForBook$ = createEffect(() =>
    this.actions.pipe(
      ofType(EduContentsActionTypes.LoadGeneralFilesForBook),
      concatLatestFrom(() => this.store.select(EduContentQueries.selectEduContentState)),
      fetch({
        run: (action: LoadGeneralFilesForBook, eduContentState) => {
          const { bookId } = action.payload;
          if (!action.payload.force && eduContentState.loadedGeneralFilesForBook[bookId]) return;

          return this.eduContentService
            .getGeneralEduContentForBookId(bookId)
            .pipe(map((eduContents) => new AddGeneralFilesForBook({ bookId, eduContents })));
        },
        onError: (action: LoadGeneralFilesForBook, error) => {
          return new EduContentsLoadError(error);
        },
      })
    )
  );

  createEduContent$ = createEffect(() =>
    this.actions.pipe(
      ofType(EduContentsActionTypes.StartAddEduContent),
      pessimisticUpdate({
        run: (action: StartAddEduContent) => {
          return this.eduContentService
            .createEduContentDraft(
              action.payload.personPreferenceId,
              action.payload.eduContentBookId,
              action.payload.eduContentId
            )
            .pipe(
              switchMap((eduContentId) => {
                const actions: Action[] = [];
                // FYI: no eduContent is added to the store
                // Not needed at the moment
                if (action.payload.navigateAfterCreate) {
                  actions.push(new NavigateToEduContentDetail({ eduContentId }));
                }
                return from(actions);
              })
            );
        },
        onError: (action: StartAddEduContent, error) => {
          return new EduContentsLoadError(error);
        },
      })
    )
  );

  redirectToEduContentDetail$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EduContentsActionTypes.NavigateToEduContentDetail),
        tap((action: NavigateToEduContentDetail) => {
          this.router.navigate(['/edu-contents-v2', action.payload.eduContentId]);
        })
      ),
    { dispatch: false }
  );
}
