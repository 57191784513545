import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { MethodProductGroupInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum MethodProductGroupsActionTypes {
  MethodProductGroupsLoaded = '[MethodProductGroups] MethodProductGroups Loaded',
  MethodProductGroupsLoadError = '[MethodProductGroups] Load Error',
  LoadMethodProductGroups = '[MethodProductGroups] Load MethodProductGroups',
  StartAddManyMethodProductGroups = '[MethodProductGroups] Start Add MethodProductGroups',
  AddMethodProductGroup = '[MethodProductGroups] Add MethodProductGroup',
  UpsertMethodProductGroup = '[MethodProductGroups] Upsert MethodProductGroup',
  AddMethodProductGroups = '[MethodProductGroups] Add MethodProductGroups',
  UpsertMethodProductGroups = '[MethodProductGroups] Upsert MethodProductGroups',
  UpdateMethodProductGroup = '[MethodProductGroups] Update MethodProductGroup',
  UpdateMethodProductGroups = '[MethodProductGroups] Update MethodProductGroups',
  DeleteMethodProductGroup = '[MethodProductGroups] Delete MethodProductGroup',
  DeleteMethodProductGroups = '[MethodProductGroups] Delete MethodProductGroups',
  ClearMethodProductGroups = '[MethodProductGroups] Clear MethodProductGroups',
  StartAddMethodProductGroup = '[MethodProductGroups] Start Add MethodProductGroup',
}

export class LoadMethodProductGroups implements Action {
  readonly type = MethodProductGroupsActionTypes.LoadMethodProductGroups;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class MethodProductGroupsLoaded implements Action {
  readonly type = MethodProductGroupsActionTypes.MethodProductGroupsLoaded;

  constructor(public payload: { methodProductGroups: MethodProductGroupInterface[] }) {}
}

export class MethodProductGroupsLoadError implements Action {
  readonly type = MethodProductGroupsActionTypes.MethodProductGroupsLoadError;
  constructor(public payload: any) {}
}

export class StartAddManyMethodProductGroups implements Action {
  readonly type = MethodProductGroupsActionTypes.StartAddManyMethodProductGroups;

  constructor(
    public payload: {
      userId: number;
      methodProductGroups: MethodProductGroupInterface[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartAddMethodProductGroup implements FeedbackTriggeringAction {
  readonly type = MethodProductGroupsActionTypes.StartAddMethodProductGroup;

  constructor(
    public payload: {
      methodProductGroup: MethodProductGroupInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class AddMethodProductGroup implements Action {
  readonly type = MethodProductGroupsActionTypes.AddMethodProductGroup;

  constructor(public payload: { methodProductGroup: MethodProductGroupInterface }) {}
}

export class UpsertMethodProductGroup implements Action {
  readonly type = MethodProductGroupsActionTypes.UpsertMethodProductGroup;

  constructor(public payload: { methodProductGroup: MethodProductGroupInterface }) {}
}

export class AddMethodProductGroups implements Action {
  readonly type = MethodProductGroupsActionTypes.AddMethodProductGroups;

  constructor(public payload: { methodProductGroups: MethodProductGroupInterface[] }) {}
}

export class UpsertMethodProductGroups implements Action {
  readonly type = MethodProductGroupsActionTypes.UpsertMethodProductGroups;

  constructor(public payload: { methodProductGroups: MethodProductGroupInterface[] }) {}
}

export class UpdateMethodProductGroup implements FeedbackTriggeringAction {
  readonly type = MethodProductGroupsActionTypes.UpdateMethodProductGroup;

  constructor(
    public payload: {
      methodProductGroup: Update<MethodProductGroupInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpdateMethodProductGroups implements Action {
  readonly type = MethodProductGroupsActionTypes.UpdateMethodProductGroups;

  constructor(
    public payload: {
      methodProductGroups: Update<MethodProductGroupInterface>[];
    }
  ) {}
}

export class DeleteMethodProductGroup implements FeedbackTriggeringAction {
  readonly type = MethodProductGroupsActionTypes.DeleteMethodProductGroup;

  constructor(
    public payload: {
      id: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class DeleteMethodProductGroups implements FeedbackTriggeringAction {
  readonly type = MethodProductGroupsActionTypes.DeleteMethodProductGroups;

  constructor(
    public payload: {
      ids: number[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class ClearMethodProductGroups implements Action {
  readonly type = MethodProductGroupsActionTypes.ClearMethodProductGroups;
}

export type MethodProductGroupsActions =
  | LoadMethodProductGroups
  | MethodProductGroupsLoaded
  | MethodProductGroupsLoadError
  | AddMethodProductGroup
  | StartAddManyMethodProductGroups
  | StartAddMethodProductGroup
  | UpsertMethodProductGroup
  | AddMethodProductGroups
  | UpsertMethodProductGroups
  | UpdateMethodProductGroup
  | UpdateMethodProductGroups
  | DeleteMethodProductGroup
  | DeleteMethodProductGroups
  | ClearMethodProductGroups;
