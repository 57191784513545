/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { SectionApi as SDKSectionApi } from '@diekeure/polpo-api-angular-sdk';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class SectionApi extends ModelApiBase {
  private SDKSectionApi = inject(SDKSectionApi);

  constructor(api: PolpoApi) {
    super(api, 'Sections');
  }

  replaceSections(toReplace, eduContentTocId: number) {
    // @ts-expect-error TS 2556
    return this.SDKSectionApi.replaceSections(...arguments);
  }
}
