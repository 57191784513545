import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NAME, State, selectAll, selectEntities, selectIds, selectTotal } from './method-option.reducer';

export const selectMethodOptionState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectMethodOptionState, (state: State) => state.error);

export const getLoaded = createSelector(selectMethodOptionState, (state: State) => state.loaded);

export const getAll = createSelector(selectMethodOptionState, selectAll);

export const getCount = createSelector(selectMethodOptionState, selectTotal);

export const getIds = createSelector(selectMethodOptionState, selectIds);

export const getAllEntities = createSelector(selectMethodOptionState, selectEntities);

export const isBookLoaded = (props: { bookId: number }) =>
  createSelector(selectMethodOptionState, (state: State) => !!state.loadedForBook[props.bookId]);
