import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { PersonApi, SchoolApi } from '../+api';
import { ClassGroupInterface } from '../+models';
import {
  ClassGroupServiceInterface,
  ClassGroupServiceStudentInterface,
  LinkRolesClassGroupResultInterface,
} from './class-group.service.interface';

@Injectable({
  providedIn: 'root',
})
export class ClassGroupService implements ClassGroupServiceInterface {
  constructor(private personApi: PersonApi, private schoolApi: SchoolApi) {}

  getAllForUser(userId: number): Observable<ClassGroupInterface[]> {
    return this.personApi
      .getData(userId, 'classGroups')
      .pipe(map((res: { classGroups: ClassGroupInterface[] }) => res.classGroups));
  }

  createClassGroupForSchool(schoolId: number, classGroup: ClassGroupInterface): Observable<ClassGroupInterface> {
    return this.schoolApi.createClassGroup(schoolId, classGroup) as Observable<ClassGroupInterface>;
  }

  updateClassGroupForSchool(
    schoolId: number,
    classGroupId: number,
    update: Partial<ClassGroupInterface>
  ): Observable<ClassGroupInterface> {
    return this.schoolApi.updateClassGroup(schoolId, classGroupId, update) as Observable<ClassGroupInterface>;
  }

  removeClassGroupFromSchool(schoolId: number, classGroupId: number): Observable<boolean> {
    return this.schoolApi.removeClassGroup(schoolId, classGroupId).pipe(mapTo(true));
  }

  linkTeacherRolesClassGroupForSchool(
    schoolId: number,
    classGroupId: number,
    schoolRoleMappingIds: number[]
  ): Observable<LinkRolesClassGroupResultInterface> {
    return this.schoolApi.linkTeacherRolesClassGroup(
      schoolId,
      classGroupId,
      schoolRoleMappingIds
    ) as Observable<LinkRolesClassGroupResultInterface>;
  }

  linkStudentRolesClassGroupForSchool(
    schoolId: number,
    classGroupId: number,
    studentSchoolRoleMappingData: ClassGroupServiceStudentInterface[]
  ): Observable<LinkRolesClassGroupResultInterface> {
    return this.schoolApi.linkStudentRolesClassGroup(
      schoolId,
      classGroupId,
      studentSchoolRoleMappingData
    ) as Observable<LinkRolesClassGroupResultInterface>;
  }

  unlinkRolesClassGroupForSchool(
    schoolId: number,
    classGroupId: number,
    schoolRoleMappingIds: number[]
  ): Observable<boolean> {
    return this.schoolApi.unlinkRolesClassGroup(schoolId, classGroupId, schoolRoleMappingIds).pipe(mapTo(true));
  }
}
