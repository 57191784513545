import { InjectionToken } from '@angular/core';
import { BundleInterface } from '@campus/dal';
import { BundleActionInterface } from '../bundle-action.interface';

export interface BundleActionsTeacherServiceInterface {
  bundleActionDictionary: { [key: string]: BundleActionInterface };
  getActions(bundle: BundleInterface): BundleActionInterface[];
}

export interface TeacherBundleOpenerInterface {
  openBundle(props: { bundle: BundleInterface });
  openResultsForBundle(props: { bundle: BundleInterface });
  acceptSharedBundle(props: { bundle: BundleInterface });
  rejectSharedBundle(props: { bundle: BundleInterface });
}

export const TEACHER_BUNDLE_OPENER_TOKEN = new InjectionToken<TeacherBundleOpenerInterface>('TeacherBundleOpener');
