import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MethodProductGroupProductServiceInterface } from '.';
import { PersonApi } from '../+api';
import { MethodProductGroupProductInterface } from '../+models';

@Injectable({
  providedIn: 'root',
})
export class MethodProductGroupProductService implements MethodProductGroupProductServiceInterface {
  constructor(private personApi: PersonApi) {}

  getAllForUser(userId: number): Observable<MethodProductGroupProductInterface[]> {
    return this.personApi
      .getData(userId, 'methodProductGroupProducts')
      .pipe(
        map(
          (res: { methodProductGroupProducts: MethodProductGroupProductInterface[] }) => res.methodProductGroupProducts
        )
      );
  }
}
