import { InjectionToken } from '@angular/core';
import { FileReaderResult } from '@campus/browser';
import { Observable } from 'rxjs';
import {
  EduContentMetadataInterface,
  EduContentTOCEduContentMetadataInterface,
  EduContentTOCInterface,
  LearningPlanGoalInterface,
  MethodGoalInterface,
} from '../+models';

export interface EduContentMetadataDataResponseInterface {
  eduContentTocs?: EduContentTOCInterface[];
  eduContentTocEduContentMetadata?: EduContentTOCEduContentMetadataInterface[];
  learningPlanGoals?: LearningPlanGoalInterface[];
  methodGoals?: MethodGoalInterface[];
  eduContentMetadata?: EduContentMetadataInterface[];
}

export const EDU_CONTENT_METADATA_SERVICE_TOKEN = new InjectionToken('EduContentMetadataService');

export interface EduContentMetadataServiceInterface {
  getAll(): Observable<EduContentMetadataInterface[]>;
  getEduContentMetadatasForBook(bookId: number): Observable<EduContentMetadataInterface[]>;
  getWithArtifact(): Observable<EduContentMetadataInterface[]>;
  uploadImage(eduContentMetadataId: number, image: FileReaderResult);
}
