import { ScormCmiMode } from '../../+external-interfaces/scorm-api.interface';
import { ResultInterface } from '../../+models';
import {
  CurrentExerciseActions,
  CurrentExerciseActionTypes,
  CurrentExerciseOptionsInterface,
} from './current-exercise.actions';

export const NAME = 'currentExercise';

export interface CurrentExerciseInterface {
  eduContentId?: number;
  cmiMode?: ScormCmiMode;
  result?: ResultInterface; // for initializing scorm API
  saveToApi?: boolean;
  url?: string; // used for ludo exercises
  options?: CurrentExerciseOptionsInterface;
  finalUpdate?: boolean;
  readyForNextRecommendation?: boolean;
}
export interface State extends CurrentExerciseInterface {
  error?: any;
}

export const initialState: State = {
  eduContentId: null,
  cmiMode: null,
  result: null,
  saveToApi: null,
  url: null,
  options: null,
  finalUpdate: null,
  readyForNextRecommendation: null,
};

export function reducer(state = initialState, action: CurrentExerciseActions): State {
  switch (action.type) {
    case CurrentExerciseActionTypes.LoadExercise: {
      // reset state, effect will do the rest
      return {
        ...initialState,
      };
    }

    case CurrentExerciseActionTypes.ClearCurrentExercise: {
      return {
        ...state,
        ...initialState,
      };
    }

    case CurrentExerciseActionTypes.SaveCurrentExercise: {
      return {
        ...state,
        ...action.payload.exercise,
      };
    }

    case CurrentExerciseActionTypes.SetCurrentResult: {
      const { result, finalUpdate } = action.payload;
      return {
        ...initialState,
        result,
        finalUpdate,
        readyForNextRecommendation: false,
      };
    }
    case CurrentExerciseActionTypes.SetReadyForNextRecommendation: {
      const { readyForNextRecommendation } = action.payload;
      return {
        ...state,
        readyForNextRecommendation,
      };
    }

    case CurrentExerciseActionTypes.CurrentExerciseLoaded: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case CurrentExerciseActionTypes.CurrentExerciseError: {
      return {
        ...state,
        eduContentId: null,
        cmiMode: null,
        result: null,
        saveToApi: null,
        url: null,
        options: null,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
