<svg
  class="[ transform -rotate-90 overflow-visible ]"
  [ngClass]="{ 'animate-spin animate-infinite animate-extra-long-5': isPending }"
  viewBox="0 0 32 32"
  width="100%"
  height="100%"
>
  <circle
    id="percentage-meter-stroke"
    cx="16"
    cy="16"
    r="16"
    [attr.stroke-width]="strokeWidth"
    fill="none"
    stroke-linecap="round"
    [attr.stroke-dasharray]="dashArray"
    [ngClass]="{ 'animate-pending-dash animate-infinite animate-extra-long-5': isPending }"
    class="[ transition-all ]"
  ></circle>
</svg>

<span class="[ imposter overflow-visible ]"> <ng-content> </ng-content></span>
