import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { EduContentMetadataEvaluationSubjectInterface } from '../../+models';
import {
  EduContentMetadataEvaluationSubjectsActions,
  EduContentMetadataEvaluationSubjectsActionTypes,
} from './edu-content-metadata-evaluation-subject.actions';

export const NAME = 'eduContentMetadataEvaluationSubjects';

export interface State extends EntityState<EduContentMetadataEvaluationSubjectInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
  loadedForBook: Dictionary<boolean>;
}

export const adapter: EntityAdapter<EduContentMetadataEvaluationSubjectInterface> =
  createEntityAdapter<EduContentMetadataEvaluationSubjectInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForBook: {},
});

export function reducer(state = initialState, action: EduContentMetadataEvaluationSubjectsActions): State {
  switch (action.type) {
    case EduContentMetadataEvaluationSubjectsActionTypes.AddEduContentMetadataEvaluationSubject: {
      return adapter.addOne(action.payload.eduContentMetadataEvaluationSubject, state);
    }

    case EduContentMetadataEvaluationSubjectsActionTypes.UpsertEduContentMetadataEvaluationSubject: {
      return adapter.upsertOne(action.payload.eduContentMetadataEvaluationSubject, state);
    }

    case EduContentMetadataEvaluationSubjectsActionTypes.AddEduContentMetadataEvaluationSubjects: {
      return adapter.addMany(action.payload.eduContentMetadataEvaluationSubjects, state);
    }

    case EduContentMetadataEvaluationSubjectsActionTypes.UpsertEduContentMetadataEvaluationSubjects: {
      return adapter.upsertMany(action.payload.eduContentMetadataEvaluationSubjects, state);
    }

    case EduContentMetadataEvaluationSubjectsActionTypes.UpdateEduContentMetadataEvaluationSubject: {
      return adapter.updateOne(action.payload.eduContentMetadataEvaluationSubject, state);
    }

    case EduContentMetadataEvaluationSubjectsActionTypes.UpdateEduContentMetadataEvaluationSubjects: {
      return adapter.updateMany(action.payload.eduContentMetadataEvaluationSubjects, state);
    }

    case EduContentMetadataEvaluationSubjectsActionTypes.DeleteEduContentMetadataEvaluationSubject: {
      return adapter.removeOne(action.payload.id, state);
    }

    case EduContentMetadataEvaluationSubjectsActionTypes.DeleteEduContentMetadataEvaluationSubjects: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case EduContentMetadataEvaluationSubjectsActionTypes.EduContentMetadataEvaluationSubjectsLoaded: {
      return adapter.setAll(action.payload.eduContentMetadataEvaluationSubjects, { ...state, loaded: true });
    }

    case EduContentMetadataEvaluationSubjectsActionTypes.EduContentMetadataEvaluationSubjectsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case EduContentMetadataEvaluationSubjectsActionTypes.ClearEduContentMetadataEvaluationSubjects: {
      return adapter.removeAll(state);
    }

    case EduContentMetadataEvaluationSubjectsActionTypes.AddEduContentMetadataEvaluationSubjectsForBook: {
      const { bookId, eduContentMetadataEvaluationSubjects } = action.payload;

      return adapter.addMany(eduContentMetadataEvaluationSubjects, {
        ...state,
        loadedForBook: { ...state.loadedForBook, [bookId]: true },
      });
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
