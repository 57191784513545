<div
  class="ui-section__header"
  *ngIf="sectionTitle"
  [class.ui-headline-4]="mode === modes.CONTAINER"
  [class.ui-headline-6]="mode !== modes.CONTAINER"
>
  <ng-content select="section-title"></ng-content>
  <ng-content select="section-fab-container"></ng-content>
  <div class="ui-section__header__actions" (click)="clickAction($event)">
    <ng-content select="section-actions"></ng-content>
  </div>
</div>
<div class="ui-section__content">
  <ng-content select="section-content"></ng-content>
  <ng-content select="section-footer-actions"></ng-content>
</div>
