import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { BulkUpdateResultInfoInterface } from '../+external-interfaces/bulk-update-result-info';
import {
  EvaluationScoreInterface,
  EvaluationScoreListInterface,
  EvaluationScoreListSubjectInterface,
  PrintSize,
  TaskClassGroupInterface,
  TaskEduContentInterface,
  TaskGoalYearLevelInterface,
  TaskGroupInterface,
  TaskInstanceInterface,
  TaskInterface,
  TaskStudentInterface,
} from '../+models';
import { FeedbackTriggeringAction } from '../+state/effect-feedback';

export const TASK_SERVICE_TOKEN = new InjectionToken<TaskServiceInterface>('TaskService');

export interface CreateEvaluationTaskResponseInterface {
  task: TaskInterface;
  evaluationScoreListSubjects: EvaluationScoreListSubjectInterface[];
  evaluationScoreList: EvaluationScoreListInterface;
  taskEduContents: TaskEduContentInterface[];
  taskClassGroups?: TaskClassGroupInterface[];
  taskGroups?: TaskGroupInterface[];
}

export interface UpdateEvaluationScoreListSubjectResponseInterface {
  taskEduContents?: TaskEduContentInterface[]; // evaluation task
  evaluationScoreListSubjects: EvaluationScoreListSubjectInterface[]; // both evaluation and adaptive task
  evaluationScores?: EvaluationScoreInterface[]; // adaptive task
  taskGoalYearLevels?: TaskGoalYearLevelInterface[]; // adaptive task
}

export interface TaskServiceInterface {
  getAllForUser(userId: number): Observable<TaskInterface[]>;
  linkEduContent(taskId: number, eduContentId: number): Observable<TaskEduContentInterface>;

  updateTasks(userId: number, update: Partial<TaskInterface>[]): Observable<UpdateTaskResultInterface>;

  createTask(userId: number, task: TaskInterface): Observable<TaskInterface>;

  duplicateTask(taskId: number): Observable<TaskInterface>;

  createEvaluationTask(
    task: TaskInterface,
    evaluationId: number,
    evaluationSubjectIds: number[],
    assignees?: {
      taskClassGroups: TaskClassGroupInterface[];
      taskGroups: TaskGroupInterface[];
    }
  ): Observable<CreateEvaluationTaskResponseInterface>;

  deleteEvaluationTasks(taskIds: number[]): Observable<DeleteEvaluationTasksResultInterface>;
  deleteTasks(taskIds: number[]): Observable<UpdateTaskResultInterface>;

  updateAccess(
    userId: number,
    taskId: number,
    taskGroups: TaskGroupInterface[],
    taskStudents: TaskStudentInterface[],
    taskClassGroups?: TaskClassGroupInterface[]
  ): Observable<TaskInterface>;
  printTask(taskId: number, withNames: boolean, size: PrintSize);
  printSolution(taskId: number, size: PrintSize);
  startEvaluationTask(taskId: number): Observable<StartEvaluationTaskResultInterface>;
  updateEvaluationScoreListSubjects(
    taskId: number,
    evaluationSubjectIds: number[]
  ): Observable<UpdateEvaluationScoreListSubjectResponseInterface>;
}

export interface TaskActiveErrorInterface {
  task: string;
  activeUntil: Date;
  user: string;
}

export interface UpdateTaskResultInterface
  extends BulkUpdateResultInfoInterface<TaskInterface, TaskActiveErrorInterface> {}

export interface StartEvaluationTaskResultInterface extends FeedbackTriggeringAction {
  tasks: UpdateTaskResultInterface;
  taskInstances: TaskInstanceInterface[];
}

export interface DeleteEvaluationTasksResultInterface {
  success: {
    tasks: number[];
    taskClassGroups: number[];
    taskEduContents: number[];
    evaluationScoreListSubjects: number[];
    evaluationScoreLists: number[];
    evaluationScores: number[];
    results: number[];
  };
  errors: { id: number; reason: string; task: TaskInterface; relatedTasks: TaskInterface[] }[];
}
