import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthServiceInterface, AUTH_SERVICE_TOKEN, DalState, UserActions, UserQueries } from '@campus/dal';
import { EnvironmentLoginInterface, ENVIRONMENT_ALLOWED_ROLES, ENVIRONMENT_LOGIN_TOKEN } from '@campus/environment';
import { select, Store } from '@ngrx/store';
import { merge } from 'rxjs';
import { filter, map, mapTo, switchMapTo, tap } from 'rxjs/operators';
import { RedirectHelper } from './redirect.guard';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  private loginUrl: string;
  private unAuthorizedUrl = '/error/401';

  constructor(
    private store: Store<DalState>,
    @Inject(AUTH_SERVICE_TOKEN) private authService: AuthServiceInterface,
    @Inject(ENVIRONMENT_LOGIN_TOKEN)
    private environmentLogin: EnvironmentLoginInterface,
    @Inject(ENVIRONMENT_ALLOWED_ROLES)
    private environmentAllowedRoles: string[],
    private router: Router
  ) {
    this.loginUrl = RedirectHelper.getRoute(environmentLogin.url);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.isLoggedIn()) return this.router.parseUrl(this.loginUrl);

    this.store.dispatch(new UserActions.LoadUser({}));
    const userHasCorrectType$ = this.store.pipe(
      select(UserQueries.getLoaded),
      filter((loaded) => !!loaded),
      switchMapTo(this.store.pipe(select(UserQueries.getCurrentUser))),
      map((user) =>
        (user?.types || []).some((type) =>
          (this.environmentAllowedRoles || ['teacher', 'schooladmin', 'student']).includes(type)
        )
      )
    );

    const correctType$ = userHasCorrectType$.pipe(
      filter((hasType) => !!hasType),
      tap(() => this.store.dispatch(new UserActions.LoadPermissions({}))),
      switchMapTo(this.store.pipe(select(UserQueries.getPermissionsLoaded))),
      filter((loaded) => !!loaded)
    );

    const wrongType$ = userHasCorrectType$.pipe(
      filter((hasType) => !hasType),
      mapTo(this.router.parseUrl(this.unAuthorizedUrl))
    );

    return merge(correctType$, wrongType$);
  }
}
