<div *ngIf="headerInfo && showHeader" class="[ cluster ]">
  <div class="[ items-start ]">
    <img
      *ngIf="headerInfo.logo"
      class="[ icon-size-3xl ]"
      [src]="headerInfo.logo | assetsPath: 'images':'illustrations'"
    />
    <div class="[ stack stack-space-3xs ] [ flex-1 ]">
      <div class="[ title-large ]">{{ headerInfo.strandName }}</div>
      <div *ngIf="headerInfo.methodName || headerInfo.surveyAnswers?.length" class="[ title-medium ]">
        <span>{{ headerInfo.methodName }}</span>
        <span *ngIf="headerInfo.surveyAnswers?.length" class="[ label-medium ]">
          Gestart als
          <strong>{{ headerInfo.surveyAnswers.join(' - ') }}</strong>
        </span>
      </div>
    </div>
  </div>
</div>

<div class="[ stack stack-space-2xl overflow-x-auto ]">
  <ng-container *ngFor="let concept of concepts">
    <table class="[ table w-full ] [ surface text-left ]">
      <tr>
        <th [attr.colspan]="useLevelIndicator ? 8 : 7">
          <div
            class="[ p-b-2xs p-is-l p-ie-2xs ] [ title-medium truncate ] [ corner-top-m corner-bottom-xs ]"
            [ngClass]="
              concept.resultsWithActions && concept.resultsWithActions.length > 0
                ? 'secondary-container'
                : 'surface-variant'
            "
          >
            {{ concept.name }}
          </div>
        </th>
      </tr>
      <ng-container *ngIf="concept.resultsWithActions && concept.resultsWithActions.length > 0; else noResults">
        <tr class="[ label-medium white-space-nowrap opacity-medium ]">
          <th class="[ w-0 p-i-2xs p-bs-2xs ]"></th>
          <th *ngIf="useLevelIndicator" class="[ w-0 p-i-2xs p-bs-2xs ]"></th>
          <th class="[ p-i-2xs p-bs-2xs min-w-3xs ]">Titel</th>
          <th class="[ w-0 p-i-2xs p-bs-2xs ]">Score</th>
          <th class="[ w-0 p-i-2xs p-bs-2xs ]"></th>
          <th class="[ w-0 p-i-xs p-bs-2xs ]">Tijd</th>
          <th class="[ w-0 p-i-xs p-bs-2xs ]">Ingediend op</th>
          <th class="[ w-0 p-i-2xs p-bs-2xs ]"></th>
        </tr>

        <tr
          *ngFor="let resultWithActions of concept.resultsWithActions"
          class="[ border-be last:border-none hover:background ] [ transition-colors ]"
        >
          <ng-container *ngIf="resultWithActions.result as result">
            <ng-container *ngIf="result.eduContent">
              <td class="[ p-b-s p-i-2xs ]"
                ><campus-icon [svgIcon]="EduContent.toEduContent(result.eduContent).icon"></campus-icon
              ></td>

              <td class="[ p-b-s p-i-2xs ]" *ngIf="useLevelIndicator">
                <campus-level-indicator
                  *ngIf="result.eduContent.level"
                  [levels]="levels"
                  [active]="result.eduContent.level"
                  class="[ icon-size-s ]"
                ></campus-level-indicator>
              </td>

              <td class="[ p-b-s p-i-2xs ]">
                <div class="[ stack stack-space-3xs ]">
                  <span class="[ label-overline ]" *ngIf="!useLevelIndicator && result.eduContent.level">
                    {{ result.eduContent.level.name }}
                  </span>
                  <span class="[ label-large line-clamp-2 ]">
                    {{ result.eduContent.publishedEduContentMetadata.title }}
                  </span>
                </div>
              </td>
              <td class="[ p-b-s p-i-2xs ]"> <campus-score [result]="result"></campus-score></td>
              <td class="[ p-b-s p-i-2xs ]">
                <campus-rating
                  *ngIf="result.eduContent.isScorable"
                  class="[ white-space-nowrap ]"
                  [total]="3"
                  [count]="result.stars"
                ></campus-rating>
              </td>
              <td class="[ p-b-s p-i-xs ]">
                <div *ngIf="result.time" class="[ body-small white-space-nowrap ]">
                  {{ result.time | time: true }}
                </div>
              </td>
              <td class="[ p-b-s p-i-xs ]">
                <div class="[ body-small ]">
                  {{ result.lastUpdated | humanDateTime: { format: 'shortDateTime' } }}
                </div>
              </td>
              <td class="[ p-b-s p-i-2xs ]">
                <button
                  inline-icon-button
                  *ngFor="let action of resultWithActions.actions"
                  (click)="handleEduContentAction(action, result)"
                  [matTooltip]="action.tooltip"
                  icon="preview"
                ></button>
              </td>
            </ng-container>
          </ng-container>
        </tr>
      </ng-container>
    </table>

    <ng-template #noResults>
      <tr class="[ label-medium opacity-medium ]">
        <td class="[ p-i-2xs p-bs-2xs ] [ text-center ]">
          {{ headerInfo.studentDisplayName ? headerInfo.studentDisplayName + ' heeft' : 'Je hebt' }}
          nog niet geoefend aan <strong>"{{ concept.name }}".</strong>
        </td>
      </tr>
    </ng-template>
  </ng-container>
</div>
