import { Component, Directive, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[campusBreadcrumbsSeparator], campus-breadcrumbs-separator',
})
export class BreadcrumbsSeparatorDirective {
  @HostBinding('class.ui-breadcrumbs-separator')
  hasUiBreadcrumbsSeparatorClass = true;
}
@Component({
  selector: 'campus-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnChanges {
  @Input() separator: string;
  @Input() separatorCharacter = '&raquo;';
  @Input() breadcrumbs: BreadcrumbLinkInterface[] = [];
  @Input() baseIcon: string;
  @Input() overflowedLinkString = '…';
  @Input() maxLength = 99;
  @Input() baseUrl = '/';
  @Input() baseDisplayText = 'home';

  @HostBinding('class.ui-breadcrumbs')
  uiBreadcrumbClass = true;

  public canClickLastItem = true;

  ngOnChanges(changes: SimpleChanges): void {
    const breadCrumbs: BreadcrumbLinkInterface[] = changes.breadcrumbs?.currentValue;
    //remove the clickevent of the last item if you're on the same page of that item
    if (changes.breadcrumbs && this.breadcrumbs?.length) {
      const lastBreadCrumb: BreadcrumbLinkInterface = this.breadcrumbs.slice(-1)[0];
      this.canClickLastItem = !window.location.pathname.endsWith(lastBreadCrumb.link?.join('/'));
    }
  }
}

export interface BreadcrumbLinkInterface {
  displayText: string;
  link: string[]; // see routerlink docs
}
