import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EvaluationSubjectGoalInterface } from '../../+models';
import {
  EvaluationSubjectActions,
  EvaluationSubjectsActionTypes,
} from '../evaluation-subject/evaluation-subject.actions';
import { EvaluationSubjectGoalsActions, EvaluationSubjectGoalsActionTypes } from './evaluation-subject-goal.actions';

export const NAME = 'evaluationSubjectGoals';

export interface State extends EntityState<EvaluationSubjectGoalInterface> {
  // additional entities state properties
  loaded: boolean;
  loadedForBook: {
    [bookId: number]: boolean;
  };
  error?: any;
}

export const adapter: EntityAdapter<EvaluationSubjectGoalInterface> =
  createEntityAdapter<EvaluationSubjectGoalInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForBook: {},
});

export function reducer(state = initialState, action: EvaluationSubjectGoalsActions | EvaluationSubjectActions): State {
  switch (action.type) {
    case EvaluationSubjectGoalsActionTypes.AddEvaluationSubjectGoal: {
      return adapter.addOne(action.payload.evaluationSubjectGoal, state);
    }

    case EvaluationSubjectGoalsActionTypes.UpsertEvaluationSubjectGoal: {
      return adapter.upsertOne(action.payload.evaluationSubjectGoal, state);
    }

    case EvaluationSubjectGoalsActionTypes.AddEvaluationSubjectGoals: {
      return adapter.addMany(action.payload.evaluationSubjectGoals, state);
    }

    case EvaluationSubjectGoalsActionTypes.AddEvaluationSubjectGoalsForBook: {
      const newState = adapter.addMany(action.payload.evaluationSubjectGoals, state);

      return {
        ...newState,
        loadedForBook: {
          ...newState.loadedForBook,
          [action.payload.bookId]: true,
        },
      };
    }

    case EvaluationSubjectGoalsActionTypes.UpsertEvaluationSubjectGoals: {
      return adapter.upsertMany(action.payload.evaluationSubjectGoals, state);
    }

    case EvaluationSubjectGoalsActionTypes.SetEvaluationSubjectGoalsForEvaluationSubject: {
      const { evaluationSubjectId, evaluationSubjectGoals } = action.payload;
      const toRemove = Object.values(state.entities)
        .filter((entity) => entity.evaluationSubjectId === evaluationSubjectId)
        .map((e) => e.id);
      const clearedState = adapter.removeMany(toRemove, state);
      const newState = adapter.addMany(evaluationSubjectGoals, clearedState);

      return newState;
    }

    case EvaluationSubjectGoalsActionTypes.UpdateEvaluationSubjectGoal: {
      return adapter.updateOne(action.payload.evaluationSubjectGoal, state);
    }

    case EvaluationSubjectGoalsActionTypes.UpdateEvaluationSubjectGoals: {
      return adapter.updateMany(action.payload.evaluationSubjectGoals, state);
    }

    case EvaluationSubjectGoalsActionTypes.DeleteEvaluationSubjectGoal: {
      return adapter.removeOne(action.payload.id, state);
    }

    case EvaluationSubjectGoalsActionTypes.DeleteEvaluationSubjectGoals: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case EvaluationSubjectGoalsActionTypes.EvaluationSubjectGoalsLoaded: {
      return adapter.setAll(action.payload.evaluationSubjectGoals, {
        ...state,
        loaded: true,
      });
    }

    case EvaluationSubjectGoalsActionTypes.EvaluationSubjectGoalsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case EvaluationSubjectGoalsActionTypes.ClearEvaluationSubjectGoals: {
      return adapter.removeAll(state);
    }

    case EvaluationSubjectsActionTypes.DeleteEvaluationSubject: {
      const { id: evaluationSubjectId } = action.payload;
      const idsToDelete = Object.values(state.entities)
        .filter((eSG) => eSG.evaluationSubjectId === evaluationSubjectId)
        .map((eSG) => eSG.id);
      return adapter.removeMany(idsToDelete, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
