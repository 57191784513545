import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CurrentExerciseQueries } from '.';
import { ExerciseService } from '../../exercise';
import { DalState } from '../dal.state.interface';
import { EffectFeedback, Priority } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { ResultActions } from '../result';
import { LoadTaskEduContents } from '../task-edu-content/task-edu-content.actions';
import {
  CurrentExerciseActionTypes,
  CurrentExerciseError,
  CurrentExerciseLoaded,
  LoadExercise,
  SaveCurrentExercise,
} from './current-exercise.actions';

@Injectable()
export class CurrentExerciseEffects {
  loadExercise$ = createEffect(() =>
    this.actions.pipe(
      ofType(CurrentExerciseActionTypes.LoadExercise),
      pessimisticUpdate({
        run: (action: LoadExercise) => {
          return this.exerciseService
            .loadExercise(
              action.payload.userId,
              action.payload.eduContentId,
              action.payload.saveToApi,
              action.payload.cmiMode,
              action.payload.taskId,
              action.payload.unlockedContentId,
              action.payload.result,
              action.payload.unlockedFreePracticeId,
              action.payload.context
            )
            .pipe(map((ex) => new CurrentExerciseLoaded({ ...ex, options: action.payload.options })));
        },
        onError: (action: LoadExercise, error) => {
          const currentExerciseError = new CurrentExerciseError(error);

          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            'U heeft geen licentie meer voor dit product. Neem contact met de boekverantwoordelijke of ICT-coördinator.'
          );

          const addEffectFeedback = new AddEffectFeedback({
            effectFeedback,
          });

          return from<Action[]>([currentExerciseError, addEffectFeedback]);
        },
      })
    )
  );

  saveExercise$ = createEffect(() =>
    this.actions.pipe(
      ofType(CurrentExerciseActionTypes.SaveCurrentExercise),
      concatLatestFrom(() => this.store.select(CurrentExerciseQueries.getCurrentExercise)),
      optimisticUpdate({
        run: (action: SaveCurrentExercise, currentExercise) => {
          const exercise = action.payload.exercise;

          if (!exercise.saveToApi) return;

          return this.exerciseService.saveExercise(currentExercise).pipe(
            switchMap((ex) => {
              const effectFeedback = new EffectFeedback({
                id: this.uuid(),
                triggerAction: action,
                message: 'Oefening is bewaard.',
              });
              const { result } = ex;
              return [
                new AddEffectFeedback({
                  effectFeedback,
                }),
                new LoadTaskEduContents({
                  userId: action.payload.userId,
                  force: effectFeedback.display,
                }),
                new ResultActions.UpsertResult({ result }),
              ];
            })
          );
        },
        undoAction: (action: SaveCurrentExercise, e: any) => {
          const undoAction = undo(action);

          const effectFeedback = new EffectFeedback({
            id: this.uuid(),
            triggerAction: action,
            message: 'Het is niet gelukt om de oefening te bewaren.',
            userActions: [
              {
                title: 'Opnieuw proberen',
                userAction: action,
              },
            ],
            type: 'error',
            priority: Priority.HIGH,
          });
          // resetting the state won't help, because ludo won't update
          // todo examine further
          return from<Action[]>([
            undoAction,
            new AddEffectFeedback({ effectFeedback }),
            new CurrentExerciseError(new Error('failed')),
          ]);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    private exerciseService: ExerciseService,
    @Inject('uuid') private uuid: () => string
  ) {}
}
