import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CurriculumInterface, CurriculumTreeInterface } from '../../+models';
import { getCurriculumForBookId } from '../curriculum/curriculum.selectors';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './curriculum-tree.reducer';

export const selectCurriculumTreeState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectCurriculumTreeState, (state: State) => state.error);

export const getLoaded = createSelector(selectCurriculumTreeState, (state: State) => state.loaded);

export const getAll = createSelector(selectCurriculumTreeState, selectAll);

export const getCount = createSelector(selectCurriculumTreeState, selectTotal);

export const getIds = createSelector(selectCurriculumTreeState, selectIds);

export const getAllEntities = createSelector(selectCurriculumTreeState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * curriculumTree$: CurriculumTreeInterface[] = this.store.pipe(
    select(CurriculumTreeQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectCurriculumTreeState, (state: State, props: { ids: string[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * curriculumTree$: CurriculumTreeInterface = this.store.pipe(
    select(CurriculumTreeQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectCurriculumTreeState,
  (state: State, props: { id: string }) => state.entities[props.id]
);

export const getAllStrands = createSelector(getAll, (cTs) => cTs.filter((cT) => !cT.parent));

export const getAllTopics = createSelector(getAll, (cTs) => cTs.filter((cT) => !!cT.parent));

export const getTopicsByStrandGuid = createSelector(getAllTopics, (topics) => {
  const byParent: Dictionary<CurriculumTreeInterface[]> = groupArrayByKeys(topics, ['parent']);

  return Object.entries(byParent).reduce((byParentSorted, [key, topicsToSort]) => {
    byParentSorted[key] = topicsToSort.sort((a, b) => a.displayOrder - b.displayOrder);
    return byParentSorted;
  }, {} as Dictionary<CurriculumTreeInterface[]>);
});

export const getByBookId = createSelector(
  getAll,
  getCurriculumForBookId,
  (curriculumTrees: CurriculumTreeInterface[], curriculum: CurriculumInterface, props: { id: number }) => {
    if (!curriculum) {
      return [];
    }

    return curriculumTrees.filter((ct) => ct.curriculumId === curriculum.guid);
  }
);
