/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { GoalApi as SDKGoalApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { GoalInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class GoalApi extends ModelApiBase {
  private SDKGoalApi = inject(SDKGoalApi);

  constructor(api: PolpoApi) {
    super(api, 'Goals');
  }

  getGoalsForLearningArea(learningAreaId: number): Observable<GoalInterface[]> {
    // @ts-expect-error TS 2556
    return this.SDKGoalApi.getGoalsForLearningArea(...arguments);
  }

  deleteGoal(goalId: number): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKGoalApi.deleteGoal(...arguments);
  }

  upsertGoal(goal: GoalInterface): Observable<GoalInterface> {
    // @ts-expect-error TS 2556
    return this.SDKGoalApi.upsertGoal(...arguments);
  }

  updateDisplayOrders(goals: Pick<GoalInterface, 'id' | 'displayOrder'>[]): Observable<GoalInterface[]> {
    // @ts-expect-error TS 2556
    return this.SDKGoalApi.updateDisplayOrders(...arguments);
  }
}
