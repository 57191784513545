import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { CurriculumTreeYearInterface } from '../../+models';

export enum CurriculumTreeYearsActionTypes {
  CurriculumTreeYearsLoaded = '[CurriculumTreeYears] CurriculumTreeYears Loaded',
  CurriculumTreeYearsLoadError = '[CurriculumTreeYears] Load Error',
  LoadCurriculumTreeYears = '[CurriculumTreeYears] Load CurriculumTreeYears',
  AddCurriculumTreeYear = '[CurriculumTreeYears] Add CurriculumTreeYear',
  UpsertCurriculumTreeYear = '[CurriculumTreeYears] Upsert CurriculumTreeYear',
  AddCurriculumTreeYears = '[CurriculumTreeYears] Add CurriculumTreeYears',
  UpsertCurriculumTreeYears = '[CurriculumTreeYears] Upsert CurriculumTreeYears',
  UpdateCurriculumTreeYear = '[CurriculumTreeYears] Update CurriculumTreeYear',
  UpdateCurriculumTreeYears = '[CurriculumTreeYears] Update CurriculumTreeYears',
  DeleteCurriculumTreeYear = '[CurriculumTreeYears] Delete CurriculumTreeYear',
  DeleteCurriculumTreeYears = '[CurriculumTreeYears] Delete CurriculumTreeYears',
  ClearCurriculumTreeYears = '[CurriculumTreeYears] Clear CurriculumTreeYears',
}

export class LoadCurriculumTreeYears implements Action {
  readonly type = CurriculumTreeYearsActionTypes.LoadCurriculumTreeYears;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class CurriculumTreeYearsLoaded implements Action {
  readonly type = CurriculumTreeYearsActionTypes.CurriculumTreeYearsLoaded;

  constructor(public payload: { curriculumTreeYears: CurriculumTreeYearInterface[] }) {}
}

export class CurriculumTreeYearsLoadError implements Action {
  readonly type = CurriculumTreeYearsActionTypes.CurriculumTreeYearsLoadError;
  constructor(public payload: any) {}
}

export class AddCurriculumTreeYear implements Action {
  readonly type = CurriculumTreeYearsActionTypes.AddCurriculumTreeYear;

  constructor(public payload: { curriculumTreeYear: CurriculumTreeYearInterface }) {}
}

export class UpsertCurriculumTreeYear implements Action {
  readonly type = CurriculumTreeYearsActionTypes.UpsertCurriculumTreeYear;

  constructor(public payload: { curriculumTreeYear: CurriculumTreeYearInterface }) {}
}

export class AddCurriculumTreeYears implements Action {
  readonly type = CurriculumTreeYearsActionTypes.AddCurriculumTreeYears;

  constructor(public payload: { curriculumTreeYears: CurriculumTreeYearInterface[] }) {}
}

export class UpsertCurriculumTreeYears implements Action {
  readonly type = CurriculumTreeYearsActionTypes.UpsertCurriculumTreeYears;

  constructor(public payload: { curriculumTreeYears: CurriculumTreeYearInterface[] }) {}
}

export class UpdateCurriculumTreeYear implements Action {
  readonly type = CurriculumTreeYearsActionTypes.UpdateCurriculumTreeYear;

  constructor(public payload: { curriculumTreeYear: Update<CurriculumTreeYearInterface> }) {}
}

export class UpdateCurriculumTreeYears implements Action {
  readonly type = CurriculumTreeYearsActionTypes.UpdateCurriculumTreeYears;

  constructor(
    public payload: {
      curriculumTreeYears: Update<CurriculumTreeYearInterface>[];
    }
  ) {}
}

export class DeleteCurriculumTreeYear implements Action {
  readonly type = CurriculumTreeYearsActionTypes.DeleteCurriculumTreeYear;

  constructor(public payload: { id: number }) {}
}

export class DeleteCurriculumTreeYears implements Action {
  readonly type = CurriculumTreeYearsActionTypes.DeleteCurriculumTreeYears;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearCurriculumTreeYears implements Action {
  readonly type = CurriculumTreeYearsActionTypes.ClearCurriculumTreeYears;
}

export type CurriculumTreeYearsActions =
  | LoadCurriculumTreeYears
  | CurriculumTreeYearsLoaded
  | CurriculumTreeYearsLoadError
  | AddCurriculumTreeYear
  | UpsertCurriculumTreeYear
  | AddCurriculumTreeYears
  | UpsertCurriculumTreeYears
  | UpdateCurriculumTreeYear
  | UpdateCurriculumTreeYears
  | DeleteCurriculumTreeYear
  | DeleteCurriculumTreeYears
  | ClearCurriculumTreeYears;
