import { Action } from '@ngrx/store';
import { NewsCategoryNewsInterface } from '../../+models';

export enum NewsCategoryNewsActionTypes {
  NewsCategoryNewsLoaded = '[NewsCategoryNews] News Category News Loaded',
  NewsCategoryNewsLoadError = '[NewsCategoryNews] Load Error',
  LoadNewsCategoryNews = '[NewsCategoryNews] Load News Category News',
  ClearNewsCategoryNews = '[NewsCategoryNews] Clear News Category News',
  AddNewsCategoryNews = '[NewsCategoryNews] Add News Category News',
  SetNewsCategoryNewsForNews = '[NewsCategoryNews] Set news category news for news',
  RemoveNewsCategoryNewsForNews = '[NewsCategoryNews] Remove news category news for news',
}

export class LoadNewsCategoryNews implements Action {
  readonly type = NewsCategoryNewsActionTypes.LoadNewsCategoryNews;
  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class NewsCategoryNewsLoaded implements Action {
  readonly type = NewsCategoryNewsActionTypes.NewsCategoryNewsLoaded;
  constructor(public payload: { newsCategoryNews: NewsCategoryNewsInterface[] }) {}
}

export class NewsCategoryNewsLoadError implements Action {
  readonly type = NewsCategoryNewsActionTypes.NewsCategoryNewsLoadError;
  constructor(public payload: any) {}
}

export class ClearNewsCategoryNews implements Action {
  readonly type = NewsCategoryNewsActionTypes.ClearNewsCategoryNews;
}

export class AddNewsCategoryNews implements Action {
  readonly type = NewsCategoryNewsActionTypes.AddNewsCategoryNews;
  constructor(public payload: { newsCategoryNews: NewsCategoryNewsInterface[] }) {}
}

export class SetNewsCategoryNewsForNews implements Action {
  readonly type = NewsCategoryNewsActionTypes.SetNewsCategoryNewsForNews;
  constructor(public payload: { newsId: number; newsCategoryNews: NewsCategoryNewsInterface[] }) {}
}

export class RemoveNewsCategoryNewsForNews implements Action {
  readonly type = NewsCategoryNewsActionTypes.RemoveNewsCategoryNewsForNews;
  constructor(public payload: { newsId: number }) {}
}

export type NewsCategoryNewsActions =
  | LoadNewsCategoryNews
  | NewsCategoryNewsLoaded
  | NewsCategoryNewsLoadError
  | ClearNewsCategoryNews
  | AddNewsCategoryNews
  | SetNewsCategoryNewsForNews
  | RemoveNewsCategoryNewsForNews;
