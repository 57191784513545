<div [matMenuTriggerFor]="toolBarItemDropdown" class="ui-dropdown-menu__trigger"></div>
<mat-menu
  #toolBarItemDropdown="matMenu"
  class="ui-dropdown-menu  {{ smallWidth ? 'ui-dropdown-menu--small' : 'ui-dropdown-menu--large' }} {{ class }}"
  [overlapTrigger]="isSubMenu"
  [yPosition]="'below'"
>
  <div *ngIf="showHeader" class="ui-dropdown-menu__header">
    <h6>
      <campus-icon *ngIf="headerIcon" class="icon" [svgIcon]="headerIcon"></campus-icon>
      <strong>{{ newItemCount }} nieuwe</strong> {{ itemType }}
    </h6>
    <span *ngIf="linkText && linkUrl">
      <a data-cy="btn-dropdown-menu-header" [routerLink]="linkUrl">{{ linkText }}</a></span
    >
  </div>
  <div class="ui-dropdown-menu__body"> <ng-content></ng-content> </div>
</mat-menu>
