import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import {
  DalState,
  EduContentActions,
  EduContentInterface,
  IntermediateReportInterface,
  LearningEventVerbEnum,
  LearningRecordActions,
  LearnosityAssessementApiService,
  LearnosityItemsConfigInterface,
  LearnositySessionResponsesDataResponseInterface,
  ResultInterface,
} from '@campus/dal';
import { EnvironmentPlatformType, ENVIRONMENT_PLATFORM_TOKEN } from '@campus/environment';
import {
  ConnectOnPictureComponent,
  HundredFieldComponent,
  NumberTileComponent,
  ZoneMarkerComponent,
} from '@diekeure/components';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { learnosityScriptVersion } from '../../constants/learnosity-version';
import { ItemsAppInterface } from '../../interfaces';
import { RESULT_UPDATER_TOKEN } from '../../interfaces/exercise-store.service.interface';
import { LEARNOSITY_WINDOW } from '../../services';
ZoneMarkerComponent;
NumberTileComponent;
HundredFieldComponent;
ConnectOnPictureComponent;
@Component({
  selector: 'campus-assessement',
  templateUrl: './assessement.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessementComponent implements OnChanges, OnDestroy {
  @HostBinding('class') defaultClasses = ['ca', 'block'];

  @Input() config: LearnosityItemsConfigInterface;
  @Input() eduContent?: EduContentInterface;
  @Output() close = new EventEmitter();

  private platform: EnvironmentPlatformType = inject(ENVIRONMENT_PLATFORM_TOKEN);
  private document: Document = inject(DOCUMENT);
  private renderer: Renderer2 = inject(Renderer2);
  private el: ElementRef = inject(ElementRef);
  private window = inject(LEARNOSITY_WINDOW);
  private learnosityAssessementApiService: LearnosityAssessementApiService = inject(LearnosityAssessementApiService);
  private resultUpdater = inject(RESULT_UPDATER_TOKEN, { optional: true });

  // TODO: move store out of component?
  private store = inject<Store<DalState>>(Store);

  private itemsApp: ItemsAppInterface;

  private preventSaveOnDestroy = false;

  private isInkPlatform = this.platform === 'ink-kabas' || this.platform === 'ink-polpo';

  private readSpeakerCustomerId = '13371';

  @HostBinding('attr.data-inline')
  @Input()
  inline = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config) {
      this.setupLearnosityScript();
      if (this.isInkPlatform) this.setupReadSpeakerScript();
    }
  }

  ngOnDestroy(): void {
    if (!this.preventSaveOnDestroy) {
      this.onCloseClicked(false);
    }
  }

  private setupLearnosityScript(): void {
    this.isLearnosityScriptLoaded() ? this.initializeLearnosity() : this.createAndAppendLearnosityScript();
  }

  private createLearnosityScript(): HTMLElement {
    const script = this.renderer.createElement('script');

    script.id = 'learnosity_items_script_id';
    script.type = 'text/javascript';
    script.src = `//items.learnosity.com/?${learnosityScriptVersion}`;

    return script;
  }

  private isLearnosityScriptLoaded(): boolean {
    return !!this.document.getElementById('learnosity_items_script_id');
  }

  private createAndAppendLearnosityScript(): void {
    const script: HTMLElement = this.createLearnosityScript();

    script.onload = (): void => {
      this.initializeLearnosity();
    };

    this.renderer.appendChild(this.el.nativeElement.ownerDocument.head, script);
  }

  private initializeLearnosity() {
    if (!this.config) return;

    const itemsConfig = JSON.parse(JSON.stringify(this.config)); // workaround for readonly issue
    this.itemsApp = this.window.LearnosityItems.init(itemsConfig, '#learnosity_assess', {
      readyListener: () => {
        this.onItemsAppReady();
      },
    });
    this.preventSaveOnDestroy = false;
  }

  private updateResult(result: ResultInterface, finalUpdate?: boolean): void {
    if (this.config.request.type !== 'submit_practice') return;

    this.resultUpdater?.(result, finalUpdate);
  }

  private onItemsAppReady(): void {
    if (this.config.request.state === 'review') return;

    this.window.itemsApp = this.itemsApp;

    this.listenToLearnosityEvents(this.config, this.itemsApp);
    this.getReportForLearningEvent(false, (report) => this.dispatchExerciseStarted(report));

    if (this.isInkPlatform) this.startReadSpeakerScript();
  }

  public setupReadSpeakerScript() {
    this.window.rsConf = {
      Learnosity: {
        customerParams: {
          customerid: this.readSpeakerCustomerId,
          region: 'eu',
          lang: 'nl_be',
          voice: 'Luc',
        },
      },

      ui: {
        tools: {
          controlpanel: false,
          skipbuttons: false,
          speedbutton: false,
        },
      },
    };

    if (!this.isReadSpeakerScriptLoaded()) this.createAndAppendReadSpeakerScript();
  }

  private startReadSpeakerScript() {
    this.window.rsCallbacks.readyListener();
  }

  private createAndAppendReadSpeakerScript(): void {
    const script: HTMLElement = this.createReadSpeakerScript();

    this.renderer.appendChild(this.el.nativeElement.ownerDocument.head, script);
  }

  private createReadSpeakerScript(): HTMLElement {
    const script = this.renderer.createElement('script');

    script.id = 'readspeaker_learnosity_script';
    script.type = 'text/javascript';
    script.src = `//cdn-eu.readspeaker.com/script/${this.readSpeakerCustomerId}/webReaderForEducation/learnosity/current/ReadSpeaker.Learnosity.js`;

    return script;
  }

  private isReadSpeakerScriptLoaded(): boolean {
    return !!this.document.getElementById('readspeaker_learnosity_script');
  }

  private listenToLearnosityEvents(
    learnosityItemsConfig: LearnosityItemsConfigInterface,
    itemsApp: ItemsAppInterface
  ): void {
    const learnosityItemEvents: string[] = [
      'test:save:success',
      'test:submit:success',
      'test:finished:save',
      'test:finished:submit',
      'item:beforeunload',
    ];

    learnosityItemEvents.forEach((event: string) => {
      itemsApp.on(event, this._getEventHandler(event, learnosityItemsConfig));
    });
  }

  private _getEventHandler(event: string, learnosityItemsConfig: LearnosityItemsConfigInterface): () => void {
    const closeExercise: boolean = event === 'test:finished:save' || event === 'test:finished:submit';
    const isItemBeforeUnloadEvent = event === 'item:beforeunload';

    return () => {
      if (isItemBeforeUnloadEvent) {
        // very important to get the reference immediately during the item:beforeunload event,
        // the item reference will be a different one after the item is unloaded
        const currentItemReference = this.itemsApp.getCurrentItem().reference;

        this.getReportForLearningEvent(true, (report) => {
          this.dispatchQuestionCompleted(currentItemReference, report);
        });
      }
      if (closeExercise) this.onCloseClicked();
    };
  }

  private dispatchExerciseStarted(report: IntermediateReportInterface) {
    const isFirstTime = report.data.result === null;
    let attemptScores = null;
    if (!isFirstTime) {
      attemptScores = this.calculateAttemptScores(report.data.learnosityResponses.data[0]?.responses || []);
    }

    this.store.dispatch(
      new LearningRecordActions.SaveLearningRecord({
        learningRecord: {
          registrationId: this.config.registrationId,
          verb: LearningEventVerbEnum.ATTEMPTED,
          eduContentId: this.eduContent?.id,
          completion: report.data.result?.status === 'completed',
          score: isFirstTime ? null : report.data.result.score,
          attemptScores: attemptScores?.length ? attemptScores : null,
        },
      })
    );
  }

  private dispatchQuestionCompleted(itemReference: string, report: IntermediateReportInterface) {
    const allResponses = report.data.learnosityResponses.data[0]?.responses || [];
    const relevantResponses = allResponses.filter((response) => response.item_reference === itemReference);

    const questionScores = this.toQuestionScores(relevantResponses);

    questionScores.forEach(({ questionId, score, attempts, attemptScores, hintRequested }) => {
      this.store.dispatch(
        new LearningRecordActions.SaveLearningRecord({
          learningRecord: {
            registrationId: this.config.registrationId,
            verb: LearningEventVerbEnum.ANSWERED,
            eduContentId: this.eduContent?.id,
            questionId,
            score,
            attempts,
            attemptScores,
            hintRequested,
          },
        })
      );
    });
  }

  private toQuestionScores(responses: LearnositySessionResponsesDataResponseInterface[]): {
    questionId: string;
    score: number;
    attempts: number;
    attemptScores: number[];
    hintRequested: boolean;
  }[] {
    return responses.map((response) => {
      const { score, max_score, question_reference } = response;

      const scorePercentage = Math.round((score / max_score) * 100);
      const attempts = response.response?.feedbackAttemptsCount || 0;
      const attemptScores = this.calculateAttemptScores([response])[0];

      return {
        questionId: question_reference,
        score: scorePercentage,
        attempts,
        attemptScores,
        hintRequested: false,
      };
    });
  }

  private getReportForLearningEvent(saveRequired: boolean, callback: (report: IntermediateReportInterface) => void) {
    if (!this.config.registrationId) return;

    const source = saveRequired ? this.saveAndGetIntermediateReport$() : this.getIntermediateReport$();

    source.subscribe((report) => {
      callback(report);
    });
  }

  private dispatchExerciseFinished(report: IntermediateReportInterface) {
    const attemptScores = this.calculateAttemptScores(report.data.learnosityResponses.data[0]?.responses || []);
    const completion = report.data.result?.status === 'completed';

    const { data } = report;
    const { result } = data;
    this.updateResult(result, true);

    this.store.dispatch(
      new LearningRecordActions.SaveLearningRecord({
        learningRecord: {
          registrationId: this.config.registrationId,
          verb: completion ? LearningEventVerbEnum.COMPLETED : LearningEventVerbEnum.ATTEMPTED,
          eduContentId: this.eduContent?.id,
          completion,
          score: report.data.result.score,
          duration: report.data.result.time / 1000, // should be in seconds, not milliseconds
          attemptScores: attemptScores?.length ? attemptScores : null,
        },
      })
    );
  }

  private calculateAttemptScores(responses: LearnositySessionResponsesDataResponseInterface[]) {
    return responses.reduce((acc, { response, score, max_score }) => {
      if (!response) {
        acc.push([0]);

        return acc;
      }

      const feedbackAttemptsCount = response.feedbackAttemptsCount;
      const scorePercentage = Math.round((score / max_score) * 100);

      // TODO: how to get different attempts' scores? right now the previous attempts just count as score null
      const scores = new Array(feedbackAttemptsCount)
        .fill(0)
        .map((_, index, arr) => (index === arr.length - 1 ? scorePercentage : null));

      acc.push(scores);

      return acc;
    }, []);
  }

  private getIntermediateReport$(): Observable<IntermediateReportInterface> {
    return this.learnosityAssessementApiService.getIntermediateReport(this.config);
  }

  private saveAndGetIntermediateReport$(): Observable<IntermediateReportInterface> {
    return new Observable((observer) => {
      this.itemsApp.save({
        success: () => {
          this.getIntermediateReport$().subscribe((response) => {
            observer.next(response);
            observer.complete();
          });
        },
        error: (error) => {
          observer.error(error);
          observer.complete();
        },
      });
    });
  }

  public onCloseClicked(saveLastQuestion = true) {
    if (this.eduContent?.id && this.config.registrationId) this.dispatchResultPendingForEduContent(true);

    // when the player is closed on the 'start' screen, there is no item loaded yet
    const currentItemReference = this.itemsApp.getCurrentItem()?.reference;

    this.getReportForLearningEvent(true, (report) => {
      if (saveLastQuestion && currentItemReference) {
        this.dispatchQuestionCompleted(currentItemReference, report);
      }

      this.dispatchExerciseFinished(report);
      this.dispatchResultPendingForEduContent(false);
    });

    this.window.ReadSpeaker?.ui.destroyActivePlayer();
    this.preventSaveOnDestroy = true;
    this.itemsApp.off();
    this.close.emit();
  }

  private dispatchResultPendingForEduContent(pending: boolean) {
    if (!this.eduContent?.id) return;

    this.store.dispatch(
      new EduContentActions.SetEduContentResultPending({
        eduContentId: this.eduContent.id,
        pending,
      })
    );
  }
}
