import {
  AfterViewInit,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ListFormat } from '@campus/ui';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, filter, skipWhile, take } from 'rxjs/operators';
import { SearchPortalDirective } from '../../directives';
import { SearchFilterComponentInterface, SearchFilterCriteriaInterface, SearchFilterInterface } from '../../interfaces';
import { ColumnFilterService } from '../column-filter/column-filter.service';
import { ResultsListComponent } from '../results-list/results-list.component';
import { SearchTermComponent } from '../search-term/search-term.component';
import { SearchViewModel } from '../search.viewmodel';
import { SearchModeInterface, SortModeInterface } from './../../interfaces/search-mode-interface';
import { SearchResultInterface } from './../../interfaces/search-result-interface';
import { SearchStateInterface } from './../../interfaces/search-state.interface';

@Component({
  selector: 'campus-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [SearchViewModel, ColumnFilterService],
})
export class SearchComponent implements AfterViewInit, OnDestroy, OnChanges {
  private _initialState: SearchStateInterface;
  private searchTermComponent: SearchTermComponent;
  private subscriptions = new Subscription();
  private portalsMap: HostCollectionInterface = {};
  private _searchPortals$ = new BehaviorSubject<QueryList<SearchPortalDirective>>(null);

  @Input() public listFormat: ListFormat = ListFormat.LINE;
  @Input() public searchMode: SearchModeInterface;
  @Input() public autoCompleteValues: string[];
  @Input() public autoCompleteDebounceTime = 300;
  @Input() public searchTermChangeDebounceTime = 500;
  @Input() public set initialState(state: SearchStateInterface) {
    if (!state) return;

    this._initialState = state;
    this.reset(this._initialState);
  }
  public get initialState() {
    return this._initialState;
  }
  @Input() public searchResults: SearchResultInterface;
  @Input() public autoFocusSearchTerm = false;
  @Input() public resetSearchTerm = false;
  @Input() public showSearchTermMagnifier = true;
  @Input() public emitOnSearchTermChange = false;
  @Input() public hideSearchResultList = false;

  @Input()
  public set searchPortals(searchPortals: QueryList<SearchPortalDirective>) {
    if (searchPortals) {
      searchPortals.forEach((portalHost) => {
        this.portalsMap[portalHost.searchPortal] = {
          host: portalHost.viewContainerRef,
          subscriptions: new Subscription(),
        };
      });
      this._searchPortals$.next(searchPortals);

      if (this.searchMode?.searchTerm) {
        this.createSearchTermComponent();
      }
    }
  }

  @Output() public searchState$: Observable<SearchStateInterface>;
  @Output() public searchTermChangeForAutoComplete = new EventEmitter<string>();

  @ViewChild(ResultsListComponent, { static: false })
  resultList: ResultsListComponent;

  constructor(private searchViewmodel: SearchViewModel, private columnFilterService: ColumnFilterService) {
    this.searchState$ = this.searchViewmodel.searchState$.pipe(
      skipWhile((searchState) => !searchState) // first emit from viewmodel is null
    );
  }

  ngAfterViewInit() {
    this.warnMissingSearchPortals();
    this.createFilters();

    if (!this.hideSearchResultList) {
      this.subscriptions.add(
        this.searchViewmodel.searchResultItemsToUpdate$.subscribe((ids) => {
          this.resultList.items
            .filter((item) => ids.some((id) => id === item.dataObject.eduContent.id))
            .forEach((item) => this.searchViewmodel.updateSearchResult(item));
        })
      );
    }
  }

  ngOnDestroy() {
    // remove filters
    this.removeFilters();

    // clean up subscriptions
    this.subscriptions.unsubscribe();

    // reset filter-specific services
    this.columnFilterService.reset();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchResults) {
      this.searchViewmodel.updateResult(this.searchResults);
    }
    if (changes.autoCompleteValues && this.searchTermComponent) {
      this.searchTermComponent.autoCompleteValues = this.autoCompleteValues;
    }
  }

  public reset(initialState: SearchStateInterface = this._initialState, clearSearchTerm?: boolean): void {
    if (clearSearchTerm) {
      initialState.searchTerm = undefined;
      this.searchTermComponent.currentValue = undefined;
    }

    this.searchViewmodel.reset(this.searchMode, {
      ...initialState,
      filterCriteriaSelections: new Map(initialState.filterCriteriaSelections),
    });
  }

  public onSort(event: SortModeInterface): void {
    this.searchViewmodel.changeSort(event);
  }

  public onFilterSelectionChange(criteria: SearchFilterCriteriaInterface | SearchFilterCriteriaInterface[]): void {
    this.searchViewmodel.updateFilterCriteria(criteria);
  }

  public onSearchTermChange(value: string): void {
    this.searchViewmodel.changeSearchTerm(value);
  }

  public onSearchTermChangeForAutoComplete(value: string): void {
    this.searchTermChangeForAutoComplete.emit(value);
  }

  public onScroll(): void {
    this.searchViewmodel.getNextPage();
  }

  // Creates a SearchTermComponent and appends it to the DOM
  // as a sibling to the domHost (as defined by the SearchMode)
  // Note: the SearchTermComponent must not by added to
  // the same domHost as the FilterComponent
  private createSearchTermComponent(): void {
    this.removeFilters([this.searchMode.searchTerm]);

    const componentRef = this.addComponent(this.searchMode.searchTerm.domHost, SearchTermComponent);
    if (!componentRef) return;

    this.searchTermComponent = componentRef.instance;

    this.searchTermComponent.initialValue = this._initialState?.searchTerm;
    this.searchTermComponent.autoCompleteValues = this.autoCompleteValues;
    this.searchTermComponent.autofocus = this.autoFocusSearchTerm;
    this.searchTermComponent.showReset = this.resetSearchTerm;
    this.searchTermComponent.emitOnTextChange = this.emitOnSearchTermChange;
    this.searchTermComponent.showMagnifier = this.showSearchTermMagnifier;

    // needed to avoid ExpressionChangedAfterItHasBeenCheckedError
    componentRef.changeDetectorRef.detectChanges();

    // listen for valueChange -> new search
    this.subscriptions.add(
      this.searchTermComponent.valueChange
        .pipe(debounceTime(this.searchTermChangeDebounceTime))
        .subscribe((value) => this.onSearchTermChange(value))
    );

    // listen for valueChangeForAutoComplete -> new autoComplete results
    this.subscriptions.add(
      this.searchTermComponent.valueChangeForAutoComplete
        .pipe(debounceTime(this.autoCompleteDebounceTime))
        .subscribe((value) => this.onSearchTermChangeForAutoComplete(value))
    );
  }

  private createFilters(): void {
    this.subscriptions.add(
      combineLatest([this.searchViewmodel.searchFilters$, this._searchPortals$])
        .pipe(filter(([_, portals]) => !!portals?.length))
        .subscribe(([searchFilters, _]) => {
          // remove old filters
          this.removeFilters(searchFilters);

          // add updated filters
          searchFilters.forEach((filter) => this.addSearchFilter(filter));
        })
    );
  }

  private addSearchFilter(filter: SearchFilterInterface): void {
    const domHosts = Array.isArray(filter.domHost) ? filter.domHost : [filter.domHost];
    domHosts.forEach((domHost) => {
      const componentRef = this.addComponent<SearchFilterComponentInterface>(domHost, filter.component);
      if (!componentRef) return;
      // set inputs
      const filterItem = componentRef.instance;
      if (filter.options) componentRef.setInput('filterOptions', filter.options);
      componentRef.setInput('filterCriteria', filter.criteria);

      // subscribe to outputs
      this.portalsMap[domHost].subscriptions.add(
        filterItem.filterSelectionChange.subscribe(
          (criteria: SearchFilterCriteriaInterface | SearchFilterCriteriaInterface[]): void => {
            this.onFilterSelectionChange(criteria);
          }
        )
      );

      // solve "Expression has changed after it was checked" error
      componentRef.changeDetectorRef.detectChanges();
    });
  }

  private removeFilters(filters?: { domHost: string }[]): void {
    let portals = [];
    if (filters) {
      filters.forEach((filter) => {
        const domHosts = Array.isArray(filter.domHost) ? filter.domHost : [filter.domHost];
        portals.push(...domHosts.map((domHost) => this.portalsMap[domHost]));
      });
      // portals = filters.map((filter) => this.portalsMap[filter.domHost]);
      portals = Array.from(new Set(portals)); // only reset each host once
    } else {
      portals = Object.values(this.portalsMap);
    }

    portals.forEach((portal) => {
      if (!portal) return;
      // close subscriptions
      portal.subscriptions.unsubscribe();
      portal.subscriptions = new Subscription();

      // remove filters from portals
      portal.host.clear();
    });
  }

  private addComponent<T>(domHost: string, component: Type<T>): ComponentRef<T> {
    const portalHost = this.portalsMap[domHost];
    if (!portalHost) {
      console.warn(`Portal ${domHost} not found! Did you add a 'searchPortal="${domHost}"' to the page?'`);
      return;
    }

    const componentRef = portalHost.host.createComponent(component);

    return componentRef;
  }

  private warnMissingSearchPortals(): void {
    this.searchViewmodel.searchFilters$
      .pipe(
        skipWhile((filters) => !filters.length),
        take(1)
      )
      .subscribe(() => {
        if (!this._searchPortals$.value?.length) {
          console.warn('The searchportals are not set');
        }
      });
  }
}

interface HostCollectionInterface {
  [key: string]: {
    host: ViewContainerRef;
    subscriptions: Subscription;
  };
}
