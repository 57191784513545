<campus-dropdown-menu #dropdownChildren [isSubMenu]="true" [showHeader]="false" [smallWidth]="true">
  <campus-dropdown-menu-item
    *ngFor="let child of children"
    [icon]="child.icon"
    [description]="child.description"
    [header]="child.header"
    [children]="child.children"
    [handler]="child.handler"
    [image]="child.image"
    [class.ui-dropdown-menu-item__divider]="child.dividerBefore"
    [class]="child.cssClass"
  >
  </campus-dropdown-menu-item>
</campus-dropdown-menu>

<div class="ui-dropdown-menu-item" #item>
  <div *ngIf="header" class="ui-dropdown-menu-item__header">{{ header }}</div>
  <a
    data-cy="btn-dropdown-menu"
    class="[ relative row-h-m p-i-s max-w-full surface ] [ flex gap-xs items-center decoration-none ][ border-none outline-none ][ cursor-pointer state-layer-group ]"
    (click)="onClick($event)"
  >
    <campus-icon *ngIf="icon && !image" [svgIcon]="icon"></campus-icon>
    <img *ngIf="image && !icon" src="{{ image }}" alt="{{ imageAltText }}" class="ui-dropdown-menu-item__image" />
    <div class="ui-dropdown-menu-item__description [ label-large truncate ]">{{ description }} </div>

    <campus-state-layer [for]="item" class="bg-primary"></campus-state-layer>
  </a>
</div>
