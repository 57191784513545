import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaskGoalYearLevelApi } from '../+api';
import { TaskGoalYearLevelInterface } from '../+models';
import {
  SetTaskGoalYearLevelResponseInterface,
  TaskGoalYearLevelServiceInterface,
} from './task-goal-year-level.service.interface';

@Injectable({
  providedIn: 'root',
})
export class TaskGoalYearLevelService implements TaskGoalYearLevelServiceInterface {
  constructor(private taskGoalYearLevelApi: TaskGoalYearLevelApi) {}

  getTaskGoalYearLevelsForTask(taskId: number): Observable<TaskGoalYearLevelInterface[]> {
    throw new Error('Not implemented yet');
  }

  setTaskGoalYearLevelsForTask(
    taskId: number,
    taskGoalYearLevels: TaskGoalYearLevelInterface[]
  ): Observable<SetTaskGoalYearLevelResponseInterface> {
    return this.taskGoalYearLevelApi.setTaskGoalYearLevelsForTask(
      taskId,
      taskGoalYearLevels
    ) as unknown as Observable<SetTaskGoalYearLevelResponseInterface>;
  }
}
