<cdk-tree
  [dataSource]="nestedDataSource"
  [treeControl]="nestedTreeControl"
  class="ui-tree-nav__root"
  [class.ui-tree-nav__root--horizontal]="mode === 'horizontal'"
  [trackBy]="trackById"
>
  <cdk-tree-node *cdkTreeNodeDef="let node" cdkTreeNodeToggle>
    <li class="ui-tree-nav__node" data-cy="tree-nav-node">
      <a
        *ngIf="handleNavigation; else noRouterLink"
        [routerLink]="node.link"
        [queryParamsHandling]="node.queryParamsHandling"
        [queryParams]="node.queryParams"
        routerLinkActive="ui-tree-nav__node--active"
        class="ui-tree-nav__node-item ui-body-1 [ relative ]"
        [class.ui-tree-nav__node-item--with-badge]="node.badge && node.badge !== '0'"
        [attr.data-usetiful]="node.usetifulIdentifier"
      >
        <campus-icon [svgIcon]="node.icon" *ngIf="node.icon" class="ui-tree-nav__node-icon"></campus-icon>
        <span class="ui-tree-nav__node-label">{{ node.title }}</span>
        <span
          class="ui-tree-nav__node-badge"
          data-cy="tree-nav-node-badge"
          *ngIf="node.badge && node.badge !== '0'"
          @fadeIn
        >
          {{ node.badge }}
        </span>
        <campus-badge
          *ngIf="indicatorsByNodeId && indicatorsByNodeId[node.id]"
          size="small"
          class="[ absolute right-2xs top-s ]"
        ></campus-badge>
      </a>

      <ng-template #noRouterLink>
        <div
          class="ui-tree-nav__node-item [ relative ]"
          [class.ui-body-1]="node?.data?.depth > 0 || (mode === 'horizontal' && !node.hasChildren)"
          [class.ui-tree-nav__node--active]="node.id === activeNode?.id"
          [attr.data-usetiful]="node.usetifulIdentifier"
          (click)="toggleNode(node)"
        >
          <campus-icon [svgIcon]="node.icon" *ngIf="node.icon" class="ui-tree-nav__node-icon"></campus-icon>
          <span class="ui-tree-nav__node-label">{{ node.title }}</span>
          <campus-badge
            *ngIf="indicatorsByNodeId && indicatorsByNodeId[node.id]"
            size="small"
            class="[ absolute right-2xs top-xs ]"
          ></campus-badge>
        </div>
      </ng-template>
    </li>
  </cdk-tree-node>

  <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChildren">
    <li
      data-cy="tree-nav-list-item"
      class="ui-tree-nav__node"
      [class.ui-tree-nav__node--closed]="!nestedTreeControl.isExpanded(node)"
    >
      <a
        data-cy="tree-nav-node"
        *ngIf="handleNavigation; else noRouterLinkChildren"
        class="ui-tree-nav__node-item ui-body-1 [ relative ]"
        [routerLink]="node.link"
        [queryParamsHandling]="node.queryParamsHandling"
        routerLinkActive="router-link-active"
        routerLinkActive="ui-tree-nav__node--active"
        cdkTreeNodeToggle
        [class.ui-tree-nav__node-item--with-children]="hasChildren(0, node)"
        (click)="toggleNode(node)"
      >
        <campus-icon [svgIcon]="node.icon" *ngIf="node.icon" class="ui-tree-nav__node-icon"></campus-icon>
        <span class="ui-tree-nav__node-label">{{ node.title }}</span>
        <campus-icon
          *ngIf="hasChildren(0, node)"
          class="ui-tree-nav__node__arrow"
          svgIcon="expand-more"
          (click)="toggleNode(node)"
          campusClickPreventDefault
          campusClickStopPropagation
        ></campus-icon>
        <campus-badge
          *ngIf="indicatorsByNodeId && indicatorsByNodeId[node.id]"
          size="small"
          class="[ absolute right-2xs top-s ]"
        ></campus-badge>
      </a>
      <ng-template #noRouterLinkChildren>
        <div
          cdkTreeNodeToggle
          (click)="toggleNode(node)"
          class="ui-tree-nav__node-item [ relative ]"
          [class.ui-tree-nav__node--active]="node.id === activeNode?.id"
        >
          <campus-icon [svgIcon]="node.icon" *ngIf="node.icon" class="ui-tree-nav__node-icon"></campus-icon>
          <span class="ui-tree-nav__node-label">{{ node.title }}</span>
          <campus-icon
            *ngIf="hasChildren(0, node)"
            class="ui-tree-nav__node__arrow"
            svgIcon="expand-more"
            (click)="toggleNode(node)"
          ></campus-icon>
          <campus-badge
            *ngIf="indicatorsByNodeId && indicatorsByNodeId[node.id]"
            size="small"
            class="[ absolute right-2xs top-s ]"
          ></campus-badge>
        </div>
      </ng-template>
      <ul
        [@.disabled]="!animate"
        [@toggleOpened]="
          nestedTreeControl.isExpanded(node) ? CollapsibleAnimationState.OPENED : CollapsibleAnimationState.CLOSED
        "
        class="ui-tree-nav__subtree"
        data-cy="tree-nav-node-subtree"
      >
        <ng-container cdkTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </cdk-nested-tree-node>
</cdk-tree>
