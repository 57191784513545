import {
  AfterContentInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { easing } from '../constants';

export enum ParticlesAnimationEnum {
  NONE = 'none',
  EXPLODE = 'explode',
  PATH = 'path',
  GLOW = 'glow',
}
interface AxisInterface {
  x: number;
  y: number;
}
interface AxisDirectionInterface {
  x: Function;
  y: Function;
}
@Directive({
  selector: '[campusParticles]',
})
export class ParticlesDirective implements OnChanges, AfterContentInit, OnDestroy {
  private animations = 0;
  private particles: HTMLElement[] = [];

  private _borderRadius: { value: number; unit: string };
  private _boudingClientRect: any;
  private _baseParticleClass: string;

  private _isDirty = true;
  private _cachedMode: ParticlesAnimationEnum;

  get isDirty() {
    return this._isDirty;
  }
  set isDirty(value: boolean) {
    this._isDirty = value;
    if (value) {
      //clear caches to recalculate dimensions
      this._borderRadius = undefined;
      this._boudingClientRect = undefined;
      this._baseParticleClass = undefined;
      this._cachedMode = undefined;
    }
  }

  @Input() particleClass = 'ui-particle--star';
  @Input() particleAmount = 200;
  @Input() particleMinSpread = 2;
  @Input() particleDistance = 20;
  @Input() particleMinSpeed = 1000;
  @Input() particleMaxSpeed = 2000;
  @Input() particleMinDelay = 0;
  @Input() particleMaxDelay = 300;
  @Input() particleMaxSize = 20;
  @Input() particleMinSize = 10;
  @Input() particleHue: number;
  @Input() particleAppendTo: 'element' | 'body' = 'element';
  @Input() particleEasing: string = easing.explode;
  @Input() particleFadeInOffset = 0;
  @Input() particleStartFromCenter = false;

  @Input() campusParticles: ParticlesAnimationEnum;

  @Output() particleExplodeDone: EventEmitter<void> = new EventEmitter();
  @Output() particleAnimationDone: EventEmitter<void> = new EventEmitter();
  @Output() campusParticlesChange: EventEmitter<ParticlesAnimationEnum> = new EventEmitter<ParticlesAnimationEnum>();

  private resizeObserver;
  constructor(private elementRef: ElementRef, private cdRef: ChangeDetectorRef) {
    if ((window as any).ResizeObserver) {
      this.resizeObserver = new (window as any).ResizeObserver(() => (this.isDirty = true));
    }
  }

  ngAfterContentInit() {
    const originalPosition = window.getComputedStyle(this.elementRef.nativeElement).position;

    if (!['absolute', 'fixed'].includes(originalPosition)) {
      this.elementRef.nativeElement.style.position = 'relative';
    }
    if (this.resizeObserver) {
      this.resizeObserver.observe(this.elementRef.nativeElement);
    }
    this.prepareParticles();

    if (this.campusParticles !== ParticlesAnimationEnum.NONE) {
      this.animate();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.campusParticles) {
      const { currentValue } = changes.campusParticles;
      if (currentValue !== ParticlesAnimationEnum.NONE) {
        if (this._cachedMode !== currentValue) {
          this.isDirty = true;
        }
        this.animate();
      }
    } else {
      this.isDirty = true;
    }
  }
  ngOnDestroy() {
    this.cdRef.detach();

    this.particles.forEach((particle) => particle.getAnimations().forEach((anim) => anim.cancel()));

    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.elementRef.nativeElement);
    }
  }

  prepareParticles() {
    this.particles.forEach((particle) => particle.remove());
    this.particles = [];
    const { left, top, width, height } = this.getBoundingClientRect();

    const spread = this.getSpread(width, height);

    this.createParticlesForDimension(
      { start: this.relativePosition(left), length: width },
      { start: this.relativePosition(top), length: height },
      spread,
      'horizontal'
    );
    this.createParticlesForDimension(
      { start: this.relativePosition(top), length: height },
      { start: this.relativePosition(left), length: width },
      spread,
      'vertical'
    );
  }

  animate() {
    if (this._isDirty) {
      this._cachedMode = this.campusParticles;
      this.prepareParticles();
      this._isDirty = false;
    }
    this.particles.forEach((particle) => {
      if (this.particleAppendTo === 'body') {
        document.body.appendChild(particle);
      } else {
        this.elementRef.nativeElement.appendChild(particle);
      }
      particle.getAnimations().forEach((animation) => {
        this.animations++;
        animation.play();
      });
    });
  }

  private relativePosition(value: number) {
    return this.particleAppendTo === 'body' ? value : 0;
  }

  private randomMinMax(min: number, max: number) {
    if (min > max) min = max;
    return Math.random() * (max - min) + min;
  }

  private getBorderRadius(): { value: number; unit: string } {
    if (!this._borderRadius) {
      // TODO: firefox doesn't return border-radius value
      // for now we're only checking border-top-left-radius and assume this value is
      // used for the other border-radius values too.
      // Fix this and support smaller border-radius too.
      const style = window
        .getComputedStyle(this.elementRef.nativeElement, null)
        .getPropertyValue('border-top-left-radius');

      const borderRadiusValueUnit = style.split(/^([-.\d]+(?:\.\d+)?)(.*)$/);
      const value = Number.parseFloat(borderRadiusValueUnit[1].trim());
      const unit = borderRadiusValueUnit[2].trim();
      this._borderRadius = { value, unit };
    }
    return this._borderRadius;
  }

  private getBoundingClientRect() {
    if (!this._boudingClientRect) {
      this._boudingClientRect = this.elementRef.nativeElement.getBoundingClientRect();
    }
    return this._boudingClientRect;
  }

  private getPositionOnPath(x: number, y: number): AxisInterface {
    const { width, left, top } = this.getBoundingClientRect();

    const { value: borderRadius, unit: borderRadiusUnit } = this.getBorderRadius();

    const isRound =
      (borderRadiusUnit === '%' && borderRadius >= 50) || (borderRadiusUnit === 'px' && borderRadius >= width / 2);

    if (!isRound) {
      return { x, y };
    } else {
      const radius = width / 2;

      const positionOnXAxis = -radius + (x - this.relativePosition(left));
      const positionOnYAxis = -radius + (y - this.relativePosition(top));

      const radians = Math.atan2(positionOnYAxis, positionOnXAxis);

      const newX = this.relativePosition(left) + radius + radius * Math.cos(radians);
      const newY = this.relativePosition(top) + radius + radius * Math.sin(radians);

      return { x: newX, y: newY };
    }
  }

  private getSpread(width: number, height: number): number {
    const sides = width * 2 + height * 2;
    const spread = sides / this.particleAmount; //equally spread over 4 sides

    return spread < this.particleMinSpread ? this.particleMinSpread : spread;
  }

  private getOffset(value: number): number {
    return Math.floor(Math.random() * value);
  }

  private createParticlesForDimension(
    variableDimension: { start: number; length: number },
    fixedDimension: { start: number; length: number },
    spread: number,
    dimension: 'horizontal' | 'vertical'
  ) {
    const bidirectionalFn = () => (Math.random() - 0.5) * 2 * this.particleDistance;
    const forwardDirectionFn = () => Math.random() * this.particleDistance;
    const backwardDirectionFn = () => Math.random() * -1 * this.particleDistance;

    for (
      let position = variableDimension.start;
      position < variableDimension.start + variableDimension.length;
      position += spread
    ) {
      let positionOnBase: AxisInterface,
        positionOnOpposite: AxisInterface,
        directionFnForBase: AxisDirectionInterface,
        directionFnForOpposite: AxisDirectionInterface;

      if (dimension === 'horizontal') {
        positionOnBase = this.getPositionOnPath(position + this.getOffset(spread), fixedDimension.start);
        positionOnOpposite = this.getPositionOnPath(
          position + this.getOffset(spread),
          fixedDimension.start + fixedDimension.length
        );
        directionFnForBase = { x: bidirectionalFn, y: backwardDirectionFn };
        directionFnForOpposite = { x: bidirectionalFn, y: forwardDirectionFn };
      } else {
        positionOnBase = this.getPositionOnPath(fixedDimension.start, position + this.getOffset(spread));
        positionOnOpposite = this.getPositionOnPath(
          fixedDimension.start + fixedDimension.length,
          position + this.getOffset(spread)
        );
        directionFnForBase = { x: backwardDirectionFn, y: bidirectionalFn };
        directionFnForOpposite = { x: forwardDirectionFn, y: bidirectionalFn };
      }

      this.createParticle(positionOnBase, directionFnForBase);
      this.createParticle(positionOnOpposite, directionFnForOpposite);
    }
  }

  private getBaseParticleClass(): string {
    if (!this._baseParticleClass) {
      const list = ['ui-particle'];
      if (this.particleClass) {
        list.push(this.particleClass);
      }

      if (this.particleAppendTo === 'body') {
        list.push('ui-particle--fixed');
      } else {
        list.push('ui-particle--absolute');
      }
      this._baseParticleClass = list.join(' ');
    }
    return this._baseParticleClass;
  }

  private getExplodeAnimations(
    particle: HTMLElement,
    point: AxisInterface,
    size: number,
    directions: AxisDirectionInterface
  ) {
    const { width, height } = this.getBoundingClientRect();

    const startX = this.particleStartFromCenter ? width / 2 : point.x - size / 2;
    const startY = this.particleStartFromCenter ? height / 2 : point.y - size / 2;

    const destinationX = point.x - size / 2 + directions.x();
    const destinationY = point.y - size / 2 + directions.y();

    const animation = particle.animate(
      [
        {
          transform: `translate(${startX}px, ${startY}px) `,
          opacity: 0,
        },
        {
          transform: `translate(${startX}px, ${startY}px) `,
          opacity: 1,
          offset: this.particleFadeInOffset,
        },
        {
          transform: `translate(${destinationX}px, ${destinationY}px) `,
          opacity: 0,
        },
      ],
      {
        duration: this.randomMinMax(this.particleMinSpeed, this.particleMaxSpeed),
        easing: this.particleEasing,
        delay: this.randomMinMax(this.particleMinDelay, this.particleMaxDelay),
      }
    );
    animation.pause();
    return [animation];
  }

  private getGlowAnimations(particle: HTMLElement, point: AxisInterface, size: number) {
    const initialRotation = this.randomMinMax(0, 180);
    const destinationRotation = this.randomMinMax(-720, 720);
    const delay = this.randomMinMax(this.particleMinDelay, this.particleMaxDelay);
    const duration = this.randomMinMax(this.particleMinSpeed, this.particleMaxSpeed);
    const destinationSize = this.randomMinMax(this.particleMinSize, this.particleMaxSize);
    const originalOpacity = this.randomMinMax(0, 0.5);
    const destinationOpacity = this.randomMinMax(0.5, 1);
    const pulseAnimation = particle.animate(
      [
        {
          width: `${size}px`,
          height: `${size}px`,
          transform: `translate(${point.x - size / 2}px, ${point.y - size / 2}px) rotate(${initialRotation}deg)`,
          opacity: originalOpacity,
          offset: 0,
        },
        {
          width: `${destinationSize}px`,
          height: `${destinationSize}px`,
          transform: `translate(${point.x - destinationSize / 2}px, ${
            point.y - destinationSize / 2
          }px) rotate(${destinationRotation}deg)`,
          opacity: destinationOpacity,
          offset: 0.5,
        },
        {
          width: `${size}px`,
          height: `${size}px`,
          transform: `translate(${point.x - size / 2}px, ${point.y - size / 2}px) rotate(${initialRotation}deg)`,
          opacity: originalOpacity,
          offset: 1,
        },
      ],
      {
        duration,
        easing: this.particleEasing,
        delay,
        iterations: Infinity,
      }
    );
    pulseAnimation.pause();
    return [pulseAnimation];
  }

  private getPathAnimations(particle: HTMLElement, point: AxisInterface, size: number) {
    const clientBoundingRect = this.getBoundingClientRect();
    //detect on which side
    let destinationX;
    let destinationY;

    if (point.y === this.relativePosition(clientBoundingRect.top)) {
      // move on top
      destinationX = point.x - size / 2 + this.randomMinMax(0, clientBoundingRect.width - point.x);
      destinationY = point.y - size / 2;
    } else if (point.y === this.relativePosition(clientBoundingRect.top) + clientBoundingRect.height) {
      // move on bottom
      destinationX = point.x - size / 2 - this.randomMinMax(0, point.x);
      destinationY = point.y - size / 2;
    } else if (point.x === this.relativePosition(clientBoundingRect.left)) {
      //move on left
      destinationX = point.x - size / 2;
      destinationY = point.y - size / 2 - this.randomMinMax(0, point.y);
    } else if (point.x === this.relativePosition(clientBoundingRect.left) + clientBoundingRect.width) {
      //move on right
      destinationX = point.x - size / 2;
      destinationY = point.y - size / 2 + this.randomMinMax(0, clientBoundingRect.height - point.y);
    }

    const initialRotation = this.randomMinMax(0, 180);
    const destinationRotation = this.randomMinMax(-720, 720);
    const delay = this.randomMinMax(this.particleMinDelay, this.particleMaxDelay);
    const duration = this.randomMinMax(this.particleMinSpeed, this.particleMaxSpeed);

    // circles are not supported right now in calculations
    // so keep destination at original point
    if (!(destinationX || destinationY)) {
      destinationX = point.x - size / 2;
      destinationY = point.y - size / 2;
    }
    const translateAnimation = particle.animate(
      [
        {
          transform: `translate(${point.x - size / 2}px, ${point.y - size / 2}px) rotate(${initialRotation}deg)`,
        },
        {
          transform: `translate(${destinationX}px, ${destinationY}px) rotate(${destinationRotation}deg)`,
        },
      ],
      {
        duration,
        easing: this.particleEasing,
        delay,
        iterations: Infinity,
      }
    );

    const fadeAnimation = particle.animate(
      [{ opacity: 0 }, { opacity: 1, offset: 0.1 }, { opacity: 1, offset: 0.9 }, { opacity: 0, offset: 1 }],
      {
        duration,
        easing: this.particleEasing,
        delay,
        iterations: Infinity,
      }
    );
    translateAnimation.pause();
    fadeAnimation.pause();
    return [translateAnimation, fadeAnimation];
  }

  private createParticle(point: AxisInterface, directions: AxisDirectionInterface) {
    const particle = document.createElement('particle');
    particle.setAttribute('class', this.getBaseParticleClass());

    if (this.particleHue) {
      const hue = (Math.random() - 0.5) * 2 * 10 + this.particleHue;
      const saturation = this.randomMinMax(80, 100);
      const lightness = this.randomMinMax(30, 90);
      const alpha = this.randomMinMax(80, 100);
      particle.style.background = `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`;
    }

    const size = Math.floor(this.randomMinMax(this.particleMinSize, this.particleMaxSize));

    particle.style.width = `${size}px`;
    particle.style.height = `${size}px`;

    let animations = [];
    switch (this.campusParticles) {
      case ParticlesAnimationEnum.EXPLODE:
        animations = this.getExplodeAnimations(particle, point, size, directions);

        break;
      case ParticlesAnimationEnum.PATH:
        animations = this.getPathAnimations(particle, point, size);
        break;
      case ParticlesAnimationEnum.GLOW:
        animations = this.getGlowAnimations(particle, point, size);
        break;
    }

    animations.forEach((animation: Animation) => {
      animation.onfinish = () => {
        animation.pause();
        animation.currentTime = 0;
        try {
          this.elementRef.nativeElement.removeChild(particle);
        } catch (err) {
          //can be ignored
        }
        this.animations--;

        if (this.animations === 0) {
          this.campusParticles = ParticlesAnimationEnum.NONE;
          this.campusParticlesChange.emit(this.campusParticles);
          this.particleAnimationDone.emit();
          this.cdRef.detectChanges();
        }
      };
    });
    this.particles.push(particle);
  }
}
