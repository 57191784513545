import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { GoalYearInterface } from '../../+models';
import { GoalsActions, GoalsActionTypes } from '../goal/goal.actions';
import { GoalYearsActions, GoalYearsActionTypes } from './goal-year.actions';

export const NAME = 'goalYears';

export interface State extends EntityState<GoalYearInterface> {
  // additional entities state properties
  loaded: boolean;
  loadedForLearningArea: {
    [learningAreaId: number]: boolean;
  };
  error?: any;
}

export const adapter: EntityAdapter<GoalYearInterface> = createEntityAdapter<GoalYearInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForLearningArea: {},
});

export function reducer(state = initialState, action: GoalYearsActions | GoalsActions): State {
  switch (action.type) {
    case GoalYearsActionTypes.AddGoalYear: {
      return adapter.addOne(action.payload.goalYear, state);
    }

    case GoalYearsActionTypes.UpsertGoalYear: {
      return adapter.upsertOne(action.payload.goalYear, state);
    }

    case GoalYearsActionTypes.AddGoalYears: {
      return adapter.addMany(action.payload.goalYears, state);
    }

    case GoalYearsActionTypes.AddGoalYearsForLearningArea: {
      const newState = adapter.addMany(action.payload.goalYears, state);

      return {
        ...newState,
        loadedForLearningArea: {
          ...newState.loadedForLearningArea,
          [action.payload.learningAreaId]: true,
        },
      };
    }

    case GoalYearsActionTypes.UpsertGoalYears: {
      return adapter.upsertMany(action.payload.goalYears, state);
    }

    case GoalYearsActionTypes.UpdateGoalYear: {
      return adapter.updateOne(action.payload.goalYear, state);
    }

    case GoalYearsActionTypes.UpdateGoalYears: {
      return adapter.updateMany(action.payload.goalYears, state);
    }

    case GoalYearsActionTypes.DeleteGoalYear: {
      return adapter.removeOne(action.payload.id, state);
    }

    case GoalYearsActionTypes.DeleteGoalYears: {
      return adapter.removeMany(action.payload.ids, state);
    }
    // TODO: refactor see #10036
    case GoalsActionTypes.AppendYearInState:
    case GoalYearsActionTypes.GoalYearsLoaded: {
      return adapter.setAll(action.payload.goalYears, { ...state, loaded: true });
    }

    case GoalYearsActionTypes.GoalYearsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case GoalYearsActionTypes.ClearGoalYears: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
