import { createFeatureSelector, createSelector } from '@ngrx/store';

import { groupArrayByKeys } from '@campus/utils';
import { MethodNewsInterface } from '../../+models';
import { NAME, selectAll, State } from './method-news.reducer';

export const selectMethodNewsState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectMethodNewsState, (state: State) => state.error);

export const getLoaded = createSelector(selectMethodNewsState, (state: State) => state.loaded);

export const getAll = createSelector(selectMethodNewsState, selectAll);

export const getAllEntities = createSelector(selectMethodNewsState, (state: State) => state.entities);

export const getByNewsId = createSelector(getAll, (methodNews: MethodNewsInterface[]) =>
  groupArrayByKeys(methodNews, ['newsId'])
);
