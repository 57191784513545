<div class="[ frame frame-square ]">
  <div class="[ overflow-visible ]">
    <div
      *ngIf="type === 'full'"
      class="[ surface bg-opacity-glass ] [ w-full h-full ] [ border corner-full ] [ backdrop-filter backdrop-blur-xs ]"
    ></div>
    <div class="[ imposter overflow-visible ] [ w-11/12 h-11/12 ] [ corner-full ]">
      <campus-ability-arc
        [angle]="expertAngle"
        [ngClass]="bestMode ? 'opacity-full' : 'opacity-none'"
        class="[ excellent elevation-1 no-elevation-layer ]"
      ></campus-ability-arc>
    </div>
    <div class="[ imposter overflow-visible ] [ secondary-container w-9/12 h-9/12 ] [ corner-full ]">
      <campus-ability-arc
        [angle]="masterAngle"
        class="[ bg-brand elevation-2 no-elevation-layer ]"
      ></campus-ability-arc>
    </div>

    <campus-ability-arc
      *ngIf="type === 'full'"
      [angle]="-180"
      class="[ surface bg-opacity-glass ] [ border border-on-surface-inverse ] [ backdrop-filter backdrop-blur-xs ] [ elevation-2 no-elevation-layer ]"
    ></campus-ability-arc>
  </div>
</div>
