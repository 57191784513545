export interface GameLearningEventsServiceInterface {
  registerEventListener(): void;
}

export enum GameMessageType {
  /////////////////////////////
  // RECEIVING FROM THE GAME //
  /////////////////////////////
  /** Received when the game reports it has loaded */
  GAME_LOADED = 'GAME_LOADED',
  /** Received when a playthrough of the game has completed */
  GAME_COMPLETED = 'GAME_COMPLETED',
  /** Received when the user requested to restart the game */
  GAME_RESTARTED = 'GAME_RESTARTED',
  /** Received when a single 'question' in the game was answered */
  GAME_EXERCISE = 'GAME_EXERCISE',
  /** Received when the game reports the window is being closed */
  GAME_WINDOW_CLOSED = 'GAME_WINDOW_CLOSED',
  /** Received when the user asks to view instructions inside the game */
  GAME_OPEN_INSTRUCTIONS = 'GAME_OPEN_INSTRUCTIONS',

  ///////////////////////////
  //  SENDING TO THE GAME  //
  ///////////////////////////
  /** Sent when a registration was created so the game can keep track of it */
  GAME_REGISTRATION = 'GAME_REGISTRATION',
  /** Sent when the game window should open a specific URL (used after receiving GAME_OPEN_INSTRUCTIONS) */
  GAME_OPEN_URL = 'GAME_OPEN_URL',
}

export interface GameMessageBase {
  type: GameMessageType;
  data: any;
  sourceWindow?: Window;
}

export interface GameLoadedMessage extends GameMessageBase {
  type: GameMessageType.GAME_LOADED;
  data: {
    gameName: string;
    eduContentId: number;
  };
}

export interface GameRegistrationMessage extends GameMessageBase {
  type: GameMessageType.GAME_REGISTRATION;
  data: {
    registrationId: number;
    eduContentId: number;
    currentHighscore: number;
  };
}

export interface GameCompletedMessage extends GameMessageBase {
  type: GameMessageType.GAME_COMPLETED;
  data: {
    gameName: string;
    registrationId: number;
    eduContentId: number;
    score: number; // int
    playTime: number; // float (seconds)
    kwetonCount: number; // int
    totalExercises: number; // int
    correctExercises: number; // int
  };
}

export interface GameRestartedMessage extends GameMessageBase {
  type: GameMessageType.GAME_RESTARTED;
  data: {
    gameName: string;
    eduContentId: number;
  };
}

export interface GameExerciseMessage extends GameMessageBase {
  type: GameMessageType.GAME_EXERCISE;
  data: {
    gameName: string;
    registrationId: number;
    eduContentId: number;
    playTime: number; //float (seconds)
    question: string;
    answer: string; // (the answer the user selected/entered),
    correctAnswer: boolean;
  };
}

export interface GameWindowClosedMessage extends GameMessageBase {
  type: GameMessageType.GAME_WINDOW_CLOSED;
  data: {
    gameName: string;
    registrationId: number;
    eduContentId: number;
  };
}

export interface GameOpenInstructionsMessage extends GameMessageBase {
  type: GameMessageType.GAME_OPEN_INSTRUCTIONS;
  data: {
    eduContentId: number;
  };
}

export interface GameOpenUrlMessage extends GameMessageBase {
  type: GameMessageType.GAME_OPEN_URL;
  data: {
    url: string;
  };
}

export type GameMessage =
  | GameLoadedMessage
  | GameRegistrationMessage
  | GameCompletedMessage
  | GameRestartedMessage
  | GameExerciseMessage
  | GameOpenInstructionsMessage
  | GameOpenUrlMessage
  | GameWindowClosedMessage;
