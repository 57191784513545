import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EduContentBookInterface, LinkInterface } from '@campus/dal';
import { ENVIRONMENT_API_TOKEN } from '@campus/environment';
import { LinkServiceInterface } from './link.service.interface';

@Injectable({
  providedIn: 'root',
})
export class LinkService implements LinkServiceInterface {
  private router = inject(Router);
  private environment = inject(ENVIRONMENT_API_TOKEN);
  private apiBase = this.environment.APIBase;
  private entity = null;
  private entityKey = null;

  public forBook(book: EduContentBookInterface): LinkServiceInterface {
    this.entityKey = 'bookId';
    this.entity = book;

    return this;
  }

  public createUrl(link?: LinkInterface, activatedRoute?: ActivatedRoute): string {
    const queryParams = {};
    if (link?.type === 'api') return this.createApiUrl(link);

    if (!link && !this.entity) {
      throw new Error('Please provide a link or an entity to create a url.');
    }

    if (this.entityKey) {
      queryParams[this.entityKey] = this.entity.id;
    }
    if (link) {
      return link.url;
    }

    return this.router.createUrlTree([this.entity?.id], { queryParams, relativeTo: activatedRoute }).toString();
  }

  createApiUrl(link: LinkInterface): string {
    return (
      this.apiBase +
      '/api/' +
      link.url
        .replace(':id', link.id.toString())
        .replace(`:entityKey`, `${this.entityKey}=${this.entity.id.toString()}`)
    );
  }
}
