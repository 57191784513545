/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { TaskEvaluationSubjectApi as SDKTaskEvaluationSubjectApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { TaskEvaluationSubjectInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class TaskEvaluationSubjectApi extends ModelApiBase {
  private SDKTaskEvaluationSubjectApi = inject(SDKTaskEvaluationSubjectApi);

  constructor(api: PolpoApi) {
    super(api, 'TaskEvaluationSubjects');
  }

  setTaskEvaluationSubjectForTask(
    taskId: number,
    taskEvaluationSubjects: TaskEvaluationSubjectInterface[]
  ): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKTaskEvaluationSubjectApi.setTaskEvaluationSubjectForTask(...arguments);
  }
}
