<div
  class="checkbox__list__filter__title search-filter-title"
  data-cy="checkbox-list-filter-label"
  *ngIf="hasPredictions"
  >{{ filterCriteria.label }}</div
>
<mat-list class="checkbox__list__filter__list" *ngIf="hasPredictions">
  <campus-checkbox-filter
    class="checkbox-filter"
    [criterium]="filterCriteria"
    [maxVisibleItems]="maxVisibleItems"
    [sortBySelection]="sortBySelection"
    (selectionChanged)="onSelectionChange()"
  >
  </campus-checkbox-filter>
</mat-list>
