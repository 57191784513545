/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { EduContentBookApi as SDKEduContentBookApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { EduContentBookInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class EduContentBookApi extends ModelApiBase {
  private SDKEduContentBookApi = inject(SDKEduContentBookApi);

  constructor(api: PolpoApi) {
    super(api, 'EduContentBooks');
  }

  find(options?): Observable<EduContentBookInterface[]> {
    return this.SDKEduContentBookApi.find(...arguments);
  }

  getData(bookId: number, fields?: string): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentBookApi.getData(...arguments);
  }

  clearEduContent(bookId: number): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentBookApi.clearEduContent(...arguments);
  }

  unlinkEduContentsRemote(bookId: number): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentBookApi.unlinkEduContentsRemote(...arguments);
  }

  patchAttributes(bookId: number, update): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentBookApi.patchAttributes(...arguments);
  }

  getUnlockedExercisesRemote(bookId: number): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentBookApi.getUnlockedExercisesRemote(...arguments);
  }
}
