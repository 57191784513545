import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserQueries } from '../user';
import { NAME, UiState } from './ui.reducer';

// Lookup the 'Ui' feature state managed by NgRx
const getUiState = createFeatureSelector<UiState>(NAME);

const getLoaded = createSelector(getUiState, (state: UiState) => state.loaded);

const getListFormat = createSelector(getUiState, (state: UiState) => state.listFormat);

const getNavOpen = createSelector(getUiState, (state: UiState) => state.navOpen);

const getDenseMenu = createSelector(getUiState, (state: UiState) => state.denseMenu);

const getDisableGlobalBookFilter = createSelector(getUiState, (state: UiState) => state.disableGlobalBookFilter);

const getBreadcrumbs = createSelector(getUiState, (state: UiState) => state.breadcrumbs);

const getIsRootComponent = createSelector(getUiState, (state: UiState) => state.isRootComponent);

const getTourRoles = createSelector(getUiState, (state: UiState) => state.tourRoles);

const getTourEnabled = createSelector(getTourRoles, UserQueries.getCurrentUser, (tourRoles, user) => {
  if (!user) return false;
  return (user.types || []).some((type) => tourRoles.map((tourRole) => tourRole.toString()).includes(type));
});

const getHeaderDetails = createSelector(getUiState, (state: UiState) => state.headerDetails);

const getSavedSettings = createSelector(getUiState, (state: UiState) => state.savedSettings);

const getPageNavigationOpen = createSelector(getUiState, (state: UiState) => state.pageNavigationOpen);

export const UiQuery = {
  getLoaded,
  getListFormat,
  getNavOpen,
  getTourEnabled,
  getTourRoles,
  getBreadcrumbs,
  getHeaderDetails,
  getSavedSettings,
  getDenseMenu,
  getDisableGlobalBookFilter,
  getPageNavigationOpen,
  getIsRootComponent,
};
