import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { TaskEduContentInterface } from '../../+models';
import { TaskEduContentsActions, TaskEduContentsActionTypes } from './task-edu-content.actions';

export const NAME = 'taskEduContents';

const sortByIndex = (a: TaskEduContentInterface, b: TaskEduContentInterface): number =>
  a.index - b.index || a.id - b.id; // if index is equal, sort by id asc

export interface State extends EntityState<TaskEduContentInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
  calculating: boolean;
  loadedForTask: Dictionary<boolean>;
}

export const adapter: EntityAdapter<TaskEduContentInterface> = createEntityAdapter<TaskEduContentInterface>({
  sortComparer: sortByIndex,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  calculating: false,
  loadedForTask: {}
});

export function reducer(state = initialState, action: TaskEduContentsActions): State {
  switch (action.type) {
    case TaskEduContentsActionTypes.AddTaskEduContent: {
      return adapter.addOne(action.payload.taskEduContent, state);
    }

    case TaskEduContentsActionTypes.UpsertTaskEduContent: {
      return adapter.upsertOne(action.payload.taskEduContent, state);
    }

    case TaskEduContentsActionTypes.AddTaskEduContents: {
      const calculatedState = {
        ...state,
        calculating: false,
      };
      return adapter.addMany(action.payload.taskEduContents, calculatedState);
    }

    case TaskEduContentsActionTypes.UpsertTaskEduContents: {
      return adapter.upsertMany(action.payload.taskEduContents, state);
    }

    case TaskEduContentsActionTypes.UpdateTaskEduContent: {
      return adapter.updateOne(action.payload.taskEduContent, state);
    }

    case TaskEduContentsActionTypes.UpdateTaskEduContents: {
      return adapter.updateMany(action.payload.taskEduContents, state);
    }

    case TaskEduContentsActionTypes.DeleteTaskEduContent: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TaskEduContentsActionTypes.DeleteTaskEduContents: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TaskEduContentsActionTypes.TaskEduContentsLoaded: {
      return adapter.setAll(action.payload.taskEduContents, {
        ...state,
        loaded: true,
      });
    }

    case TaskEduContentsActionTypes.TaskEduContentsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case TaskEduContentsActionTypes.AddTaskEduContentsForTask: {
      const { taskEduContents, taskId } = action.payload;
      return adapter.upsertMany(taskEduContents, { ...state, loadedForTask: { ...state.loadedForTask, [taskId]: true } });
    }

    case TaskEduContentsActionTypes.SetTaskEduContentsForTask: {
      const toRemove = Object.values(state.entities)
        .filter((entity) => entity.taskId === action.payload.taskId)
        .map((e) => e.id);

      const clearedState = adapter.removeMany(toRemove, state);
      const newState = adapter.addMany(action.payload.taskEduContents, clearedState);

      return newState;
    }

    case TaskEduContentsActionTypes.ClearTaskEduContents: {
      return adapter.removeAll(state);
    }

    case TaskEduContentsActionTypes.CalculateTaskEduContentsForTask: {
      return {
        ...state,
        calculating: true,
      };
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
