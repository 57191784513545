import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ButtonCoreComponent } from '../button-core/button-core.component';

@Component({
  selector: 'campus-tonal-button, [tonal-button]',
  templateUrl: './tonal-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TonalButtonComponent extends ButtonCoreComponent {
  @HostBinding('class')
  defaultClasses = [
    ...this.coreClasses,
    'tonal-button',

    'inline-flex',
    'w-fit',

    'corner-full',
    'border-0',
    'outline-none',

    'disabled:bg-on-surface',
    'disabled:bg-opacity-pressed',
    'disabled:on-surface',
    'disabled:border-none',

    'transition-elevation',
  ];

  @HostBinding('attr.data-color')
  @Input()
  color: 'primary' | 'secondary' | 'tertiary' = 'primary';

  @HostBinding('attr.data-has-leading-icon')
  @Input()
  leadingIcon: string;

  @HostBinding('attr.data-has-trailing-icon')
  @Input()
  trailingIcon: string;
}
