import { Component, HostBinding, Inject, Input, Optional } from '@angular/core';
import { EduContent, LevelInterface, ResultInterface, StudentInterface } from '@campus/dal';
import { ENVIRONMENT_SCORE_MAPPING_TOKEN } from '@campus/environment';
import { ConceptWithResultActionsInterface } from '../../interfaces/practice/concept-with-result-actions.interface';
import { ContentActionInterface } from '../../services/content-actions';

export interface HeaderInfoStrandDetailInterface {
  studentDisplayName?: string;
  strandName: string;
  methodName: string;
  logo: string;
  surveyAnswers?: string[];
}

@Component({
  selector: 'campus-learning-path',
  templateUrl: './learning-path.component.html',
  styleUrls: ['./learning-path.component.scss'],
})
export class LearningPathComponent {
  @HostBinding('class')
  defaultClasses = ['stack'];

  public EduContent = EduContent;

  @Input() concepts: ConceptWithResultActionsInterface[];
  @Input() headerInfo: HeaderInfoStrandDetailInterface;
  @Input() student: StudentInterface;
  @Input() useLevelIndicator = false;
  @Input() levels: LevelInterface[];
  @Input() showHeader = true;

  constructor(@Optional() @Inject(ENVIRONMENT_SCORE_MAPPING_TOKEN) private scoreToStars?: [number, number][]) {}

  public handleEduContentAction(action: ContentActionInterface, result: ResultInterface) {
    action.handler(EduContent.toEduContent(result.eduContent), {
      forResult: result,
    });
  }
}
