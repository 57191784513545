import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaskApi } from '../+api';
import { TaskLevelSettingInterface } from '../+models';
import { TaskLevelSettingServiceInterface } from './task-level-setting.service.interface';

@Injectable({
  providedIn: 'root',
})
export class TaskLevelSettingService implements TaskLevelSettingServiceInterface {
  constructor(private taskApi: TaskApi) {}

  getTaskLevelSettingsForTask(taskId: number): Observable<TaskLevelSettingInterface[]> {
    throw new Error('Not implemented yet');
  }

  setTaskLevelSettingsForTask(
    taskId: number,
    taskLevelSettings: TaskLevelSettingInterface[]
  ): Observable<TaskLevelSettingInterface[]> {
    return this.taskApi.setTaskLevelSettings(taskId, taskLevelSettings);
  }
}
