import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch, optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from } from 'rxjs';
import { map, mapTo, switchMap } from 'rxjs/operators';
import { UnlockedFreePracticeQueries } from '.';
import {
  UnlockedFreePracticeServiceInterface,
  UNLOCKED_FREE_PRACTICE_SERVICE_TOKEN,
} from '../../unlocked-free-practice/unlocked-free-practice.service.interface';
import { DalState } from '../dal.state.interface';
import { EffectFeedback, EffectFeedbackActions } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import {
  DeleteUnlockedFreePractices,
  LoadUnlockedFreePractices,
  ReplaceUnlockedFreePractices,
  StartAddManyUnlockedFreePractices,
  StartReplaceUnlockedFreePracticesForBook,
  UnlockedFreePracticesActionTypes,
  UnlockedFreePracticesLoaded,
  UnlockedFreePracticesLoadError,
} from './unlocked-free-practice.actions';

@Injectable()
export class UnlockedFreePracticeEffects {
  loadUnlockedFreePractices$ = createEffect(() =>
    this.actions.pipe(
      ofType(UnlockedFreePracticesActionTypes.LoadUnlockedFreePractices),
      concatLatestFrom(() => this.store.select(UnlockedFreePracticeQueries.getLoaded)),
      fetch({
        run: (action: LoadUnlockedFreePractices, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.unlockedFreePracticeService
            .getAllForUser(action.payload.userId)
            .pipe(map((unlockedFreePractices) => new UnlockedFreePracticesLoaded({ unlockedFreePractices })));
        },
        onError: (action: LoadUnlockedFreePractices, error) => {
          return new UnlockedFreePracticesLoadError(error);
        },
      })
    )
  );

  deleteUnlockedFreePractices$ = createEffect(() =>
    this.actions.pipe(
      ofType(UnlockedFreePracticesActionTypes.DeleteUnlockedFreePractices),
      optimisticUpdate({
        run: (action: DeleteUnlockedFreePractices) => {
          return this.unlockedFreePracticeService
            .deleteUnlockedFreePractices(action.payload.userId, action.payload.ids)
            .pipe(
              mapTo(
                new AddEffectFeedback({
                  effectFeedback: new EffectFeedback({
                    id: this.uuid(),
                    triggerAction: action,
                    message: "De 'zelfstandig leren' status werd gewijzigd.",
                  }),
                })
              )
            );
        },
        undoAction: (action: DeleteUnlockedFreePractices, error) => {
          const undoAction = undo(action);
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            "Het is niet gelukt om de 'zelfstandig leren' status aan te passen."
          );
          const effectFeedbackAction = new EffectFeedbackActions.AddEffectFeedback({ effectFeedback });

          return from([undoAction, effectFeedbackAction]);
        },
      })
    )
  );

  startAddManyUnlockedFreePractices$ = createEffect(() =>
    this.actions.pipe(
      ofType(UnlockedFreePracticesActionTypes.StartAddManyUnlockedFreePractices),
      pessimisticUpdate({
        run: (action: StartAddManyUnlockedFreePractices) => {
          return this.unlockedFreePracticeService
            .createUnlockedFreePractices(action.payload.userId, action.payload.unlockedFreePractices)
            .pipe(
              switchMap((unlockedFreePractices) => {
                return from([
                  new LoadUnlockedFreePractices({
                    force: true,
                    userId: action.payload.userId,
                  }),
                  new AddEffectFeedback({
                    effectFeedback: new EffectFeedback({
                      id: this.uuid(),
                      triggerAction: action,
                      message: "De 'zelfstandig leren' status werd gewijzigd.",
                    }),
                  }),
                ]);
              })
            );
        },
        onError: (action: StartAddManyUnlockedFreePractices, error) => {
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            "Het is niet gelukt om de 'zelfstandig leren' status van het hoofdstuk aan te passen."
          );
          const effectFeedbackAction = new EffectFeedbackActions.AddEffectFeedback({ effectFeedback });
          return effectFeedbackAction;
        },
      })
    )
  );

  startReplaceUnlockedFreePracticesForBook$ = createEffect(() =>
    this.actions.pipe(
      ofType(UnlockedFreePracticesActionTypes.StartReplaceUnlockedFreePracticesForBook),
      pessimisticUpdate({
        run: (action: StartReplaceUnlockedFreePracticesForBook) => {
          const { userId, productId, bookId, groupIds } = action.payload;
          return this.unlockedFreePracticeService
            .replaceUnlockedFreePracticesForBook(userId, productId, bookId, groupIds)
            .pipe(
              switchMap((unlockedFreePractices) => {
                return from([
                  new ReplaceUnlockedFreePractices({
                    unlockedFreePractices,
                  }),
                  new AddEffectFeedback({
                    effectFeedback: new EffectFeedback({
                      id: this.uuid(),
                      triggerAction: action,
                      message: "De 'zelfstandig leren' status werd gewijzigd.",
                    }),
                  }),
                ]);
              })
            );
        },
        onError: (action: StartReplaceUnlockedFreePracticesForBook, error) => {
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            "Het is niet gelukt om de 'zelfstandig leren' status van het hoofdstuk aan te passen."
          );
          const effectFeedbackAction = new EffectFeedbackActions.AddEffectFeedback({ effectFeedback });
          return effectFeedbackAction;
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject('uuid') private uuid: () => string,
    @Inject(UNLOCKED_FREE_PRACTICE_SERVICE_TOKEN)
    private unlockedFreePracticeService: UnlockedFreePracticeServiceInterface
  ) {}
}
