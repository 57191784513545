import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ComponentsModule } from '@campus/components';
import { SvgModule } from '@campus/svg';
import { UiModule } from '@campus/ui';
import { BeDateAdapter, UtilsModule } from '@campus/utils';
import { BreadcrumbFilterComponent } from './components/breadcrumb-filter/breadcrumb-filter.component';
import { ButtonToggleFilterComponent } from './components/button-toggle-filter-component/button-toggle-filter.component';
import { CheckboxLineFilterComponent } from './components/checkbox-line-filter/checkbox-line-filter-component';
import { CheckboxFilterComponent } from './components/checkbox-list-filter/checkbox-filter/checkbox-filter.component';
import { CheckboxListFilterComponent } from './components/checkbox-list-filter/checkbox-list-filter.component';
import { ChipToggleFilterComponent } from './components/chip-toggle-filter-component/chip-toggle-filter.component';
import { ColumnFilterComponent } from './components/column-filter/column-filter.component';
import { ColumnFilterService } from './components/column-filter/column-filter.service';
import { DateFilterComponent } from './components/date-filter-component/date-filter.component';
import { ResultItemBase } from './components/results-list/result.component.base';
import { ResultListDirective, ResultsListComponent } from './components/results-list/results-list.component';
import { SearchTermComponent } from './components/search-term/search-term.component';
import { SearchComponent } from './components/search/search.component';
import { SelectFilterComponent } from './components/select-filter-component/select-filter.component';
import { SearchPortalDirective } from './directives/search-portal.directive';
import { EmptyFilterFactory } from './factories/empty-filter.factory';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    UiModule,
    MatTooltipModule,
    MatSelectModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatInputModule,
    MatListModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatTooltipModule,
    MatRadioModule,
    MatButtonModule,
    MatChipsModule,
    FormsModule,
    UtilsModule,
    ComponentsModule,
    SvgModule,
  ],
  declarations: [
    ResultItemBase,
    BreadcrumbFilterComponent,
    SelectFilterComponent,
    DateFilterComponent,
    SearchTermComponent,
    ResultsListComponent,
    ResultListDirective,
    CheckboxLineFilterComponent,
    SearchComponent,
    CheckboxListFilterComponent,
    CheckboxFilterComponent,
    ColumnFilterComponent,
    ButtonToggleFilterComponent,
    ChipToggleFilterComponent,
    SearchPortalDirective,
  ],
  exports: [
    BreadcrumbFilterComponent,
    SelectFilterComponent,
    DateFilterComponent,
    SearchTermComponent,
    ResultsListComponent,
    CheckboxLineFilterComponent,
    SearchComponent,
    CheckboxListFilterComponent,
    CheckboxFilterComponent,
    ColumnFilterComponent,
    SearchComponent,
    ButtonToggleFilterComponent,
    ChipToggleFilterComponent,
    SearchPortalDirective,
  ],
  providers: [ColumnFilterService, { provide: DateAdapter, useClass: BeDateAdapter }, EmptyFilterFactory],
})
export class SearchModule {}
