import { createFeatureSelector, createSelector } from '@ngrx/store';

import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { NewsCategoryNewsInterface } from '../../+models';
import { NAME, selectAll, State } from './news-category-news.reducer';

export const selectNewsCategoryNewsState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectNewsCategoryNewsState, (state: State) => state.error);

export const getLoaded = createSelector(selectNewsCategoryNewsState, (state: State) => state.loaded);

export const getAll = createSelector(selectNewsCategoryNewsState, selectAll);

export const getByNewsId = createSelector(getAll, (entities) => {
  return groupArrayByKeys(entities, ['newsId']) as Dictionary<NewsCategoryNewsInterface[]>;
});
