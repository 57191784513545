import { groupArrayByKey } from '@campus/utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GoalEduContentTOCInterface } from '../../+models';
import { NAME, selectAll, selectEntities, State } from './goal-edu-content-toc.reducer';

export const selectGoalEduContentTOCState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectGoalEduContentTOCState, (state: State) => state.error);

export const getLoaded = createSelector(selectGoalEduContentTOCState, (state: State) => state.loaded);

export const getAll = createSelector(selectGoalEduContentTOCState, selectAll);

export const getAllEntities = createSelector(selectGoalEduContentTOCState, selectEntities);

export const getAllByEduContentTocId = createSelector(getAll, (goalEduContentTocs: GoalEduContentTOCInterface[]) => {
  return groupArrayByKey(goalEduContentTocs, 'eduContentTOCId');
});

export const getAllByGoalId = createSelector(getAll, (goalEduContentTocs: GoalEduContentTOCInterface[]) => {
  return groupArrayByKey(goalEduContentTocs, 'goalId');
});

export const isBookLoaded = (props: { bookId: number }) =>
  createSelector(selectGoalEduContentTOCState, (state: State) => !!state.loadedForBook[props.bookId]);
