<div class="search-term__field-wrapper">
  <campus-text-field
    *ngIf="!autoComplete; else autoCompleteTemplate"
    [disabled]="disabled"
    [(ngModel)]="currentValue"
    (keyup.enter)="onChange()"
    (input)="onTextChange()"
    [leadingIcon]="magnifier"
    [placeholder]="placeholder"
    style="
      --cds-comp-text-field-input-group-shape: var(--cds-sys-shape-full);
      --cds-comp-text-field-input-leading-icon-display: flex;
      --cds-comp-text-field-input-leading-icon-items-flow: items-center;
    "
  >
    <button
      inline-icon-button
      leading-icon
      *ngIf="!emitOnTextChange || showMagnifier"
      data-cy="btn-search-term-filter"
      icon="magnifier"
      (click)="onChange()"
      aria-label="Wissen"
      class="-m-b-3xs -m-is-xs"
    ></button>

    <button
      inline-icon-button
      trailing-icon
      data-cy="btn-clear-search-term-filter"
      icon="close"
      (click)="reset()"
      aria-label="Wissen"
      *ngIf="showReset"
      class="-m-b-3xs -m-ie-xs"
    >
    </button>
  </campus-text-field>
</div>

<ng-template #autoCompleteTemplate>
  <mat-form-field class="mat-form-field-no-hint">
    <mat-label>{{ placeholder }}</mat-label>
    <div class="[ flex gap-2xs -m-b-2xs ]">
      <input
        [disabled]="disabled"
        [autofocus]="getAutoFocusValue()"
        [(ngModel)]="currentValue"
        [matAutocomplete]="auto"
        (keyup.enter)="onChange(); trigger.closePanel()"
        (input)="onTextChange(); getAutoCompleteValues()"
        #trigger="matAutocompleteTrigger"
        matInput
        data-cy="input-text-field"
      />

      <button
        [disabled]="disabled"
        data-cy="btn-clear-search-term-filter"
        inline-icon-button
        class="search-term-filter__reset-icon"
        *ngIf="showReset"
        [ngClass]="{ 'search-term-filter__reset-icon--visible': currentValue }"
        aria-label="Wissen"
        clickStopPropagation
        (click)="reset()"
        icon="close"
      >
      </button>

      <button
        inline-icon-button
        data-cy="btn-search-term-filter"
        class="search-term-filter__search-icon"
        [disabled]="disabled"
        *ngIf="!emitOnTextChange || showMagnifier"
        (click)="onChange()"
        icon="magnifier"
      ></button>
    </div>
    <mat-autocomplete (optionSelected)="onChange()" #auto="matAutocomplete" class="search-term__autocomplete">
      <mat-option *ngFor="let option of autoCompleteValues" [value]="option">{{ option }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>
