import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { MethodProductGroupInterface } from '../+models';

export const METHOD_PRODUCT_GROUP_SERVICE_TOKEN = new InjectionToken('MethodProductGroupServiceInterface');

export interface MethodProductGroupServiceInterface {
  getAllForUser(userId: number): Observable<MethodProductGroupInterface[]>;
  createMethodProductGroup(
    methodProductGroup: Partial<MethodProductGroupInterface>
  ): Observable<MethodProductGroupInterface>;
  updateMethodProductGroup(
    id: number,
    update: Partial<MethodProductGroupInterface>
  ): Observable<MethodProductGroupInterface>;
  deleteMethodProductGroup(methodProductGroupId: number): Observable<boolean>;
}
