import { ProductInterface } from '.';
import { BundleInterface } from './Bundle.interface';
import { EduContentInterface } from './EduContent.interface';
import { EduContentBookInterface } from './EduContentBook.interface';
import { LearningAreaInterface } from './LearningArea.interface';
import { PersonInterface } from './Person.interface';
import { TaskInterface } from './Task.interface';

export enum FavoriteTypesEnum {
  AREA = 'area',
  SEARCH = 'search',
  EDUCONTENT = 'educontent',
  BUNDLE = 'bundle',
  BOEKE = 'boek-e',
  BOOK = 'book',
}

export interface FavoriteInterface {
  type: FavoriteTypesEnum | string;
  name?: string;
  criteria?: string;
  created: Date;
  id?: number;
  personId?: number;
  learningAreaId?: number;
  eduContentId?: number;
  eduContentBookId?: number;
  bundleId?: number;
  taskId?: number;
  person?: PersonInterface;
  learningArea?: LearningAreaInterface;
  eduContent?: EduContentInterface;
  eduContentBook?: EduContentBookInterface;
  bundle?: BundleInterface;
  task?: TaskInterface;
  productId?: number;
  product?: ProductInterface;
}
