import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GoalYearApi } from '../+api';
import { GoalYearInterface } from '../+models';
import { GoalYearServiceInterface } from './goal-year.service.interface';

@Injectable({
  providedIn: 'root',
})
export class GoalYearService implements GoalYearServiceInterface {
  constructor(private goalYearApi: GoalYearApi) {}

  getGoalYearsForLearningArea(learningAreaId: number): Observable<GoalYearInterface[]> {
    return this.goalYearApi.getGoalYearsForLearningArea(learningAreaId);
  }
}
