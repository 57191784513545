import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@campus/browser';
import { ENVIRONMENT_API_TOKEN, EnvironmentApiInterface } from '@campus/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonApi, TaskApi } from '../+api';
import {
  PrintSize,
  TaskClassGroupInterface,
  TaskEduContentInterface,
  TaskGroupInterface,
  TaskStudentInterface,
} from '../+models';
import { DAL_OPTIONS, DalOptions } from '../../lib/dal.module';
import { TaskInterface } from './../+models/Task.interface';
import {
  CreateEvaluationTaskResponseInterface,
  DeleteEvaluationTasksResultInterface,
  StartEvaluationTaskResultInterface,
  TaskServiceInterface,
  UpdateEvaluationScoreListSubjectResponseInterface,
  UpdateTaskResultInterface,
} from './task.service.interface';
@Injectable({
  providedIn: 'root',
})
export class TaskService implements TaskServiceInterface {
  constructor(
    private personApi: PersonApi,
    private taskApi: TaskApi,
    private http: HttpClient,
    @Inject(ENVIRONMENT_API_TOKEN) private api: EnvironmentApiInterface,
    @Inject(DAL_OPTIONS) private dalOptions: DalOptions,
    @Inject(WINDOW) private window: Window
  ) {}

  getAllForUser(userId: number): Observable<TaskInterface[]> {
    return this.personApi.getData(userId, 'tasks').pipe(map((res: { tasks: TaskInterface[] }) => res.tasks));
  }

  linkEduContent(taskId: number, eduContentId: number): Observable<TaskEduContentInterface> {
    return this.taskApi.linkEduContents(taskId, eduContentId);
  }

  updateTasks(userId: number, update: Partial<TaskInterface>[]): Observable<UpdateTaskResultInterface> {
    return this.taskApi.updateTasks(update) as Observable<UpdateTaskResultInterface>;
  }

  createTask(userId: number, task: TaskInterface): Observable<TaskInterface> {
    return this.personApi.createTeacherTasks(userId, task) as unknown as Observable<TaskInterface>;
  }

  duplicateTask(taskId: number): Observable<TaskInterface> {
    return this.taskApi
      .duplicateTask(taskId)
      .pipe(map((response: { errors: []; success: { task: TaskInterface } }) => response.success?.task));
  }

  createEvaluationTask(
    task: TaskInterface,
    evaluationId: number,
    evaluationSubjectIds: number[],
    assignees: {
      taskClassGroups?: TaskClassGroupInterface[];
      taskGroups?: TaskGroupInterface[];
    } = {}
  ): Observable<CreateEvaluationTaskResponseInterface> {
    return this.taskApi.createForEvaluation(
      { ...task, evaluationId, ...assignees },
      evaluationSubjectIds
    ) as Observable<CreateEvaluationTaskResponseInterface>;
  }

  deleteEvaluationTasks(taskIds: number[]): Observable<DeleteEvaluationTasksResultInterface> {
    return this.taskApi.destroyEvaluationTasks(taskIds) as Observable<DeleteEvaluationTasksResultInterface>;
  }

  deleteTasks(taskIds: number[]): Observable<UpdateTaskResultInterface> {
    return this.taskApi.destroyTasks(taskIds) as Observable<UpdateTaskResultInterface>;
  }

  startEvaluationTask(taskId: number): Observable<StartEvaluationTaskResultInterface> {
    return this.taskApi.startEvaluation(taskId) as Observable<StartEvaluationTaskResultInterface>;
  }

  updateAccess(
    userId: number,
    taskId: number,
    taskGroups: TaskGroupInterface[],
    taskStudents: TaskStudentInterface[],
    taskClassGroups?: TaskClassGroupInterface[]
  ): Observable<TaskInterface> {
    return this.http
      .put(
        `${this.api.APIBase}/api/Tasks/${taskId}/update-access`,
        {
          groups: taskGroups,
          classGroups: taskClassGroups,
          students: taskStudents,
        },
        {
          withCredentials: true,
        }
      )
      .pipe(
        map(
          (task: TaskInterface): TaskInterface => ({
            ...task,
            taskClassGroups: task.taskClassGroups.map((tCG) => castStartEndToDate(tCG)),
            taskGroups: task.taskGroups.map((tG) => castStartEndToDate(tG)),
            taskStudents: task.taskStudents.map((tS) => castStartEndToDate(tS)),
          })
        )
      );
  }

  printTask(taskId: number, withNames: boolean, size: PrintSize = 'A5') {
    const { apiBaseUrl } = this.dalOptions;
    this.window.open(`${apiBaseUrl}/api/tasks/paper-task-pdf?taskId=${taskId}&withNames=${withNames}&size=${size}`);
  }

  printSolution(taskId: number, size: PrintSize = 'A5') {
    const { apiBaseUrl } = this.dalOptions;
    this.window.open(`${apiBaseUrl}/api/tasks/paper-task-solution-pdf?taskId=${taskId}&size=${size}`);
  }

  updateEvaluationScoreListSubjects(
    taskId: number,
    evaluationSubjectIds: number[]
  ): Observable<UpdateEvaluationScoreListSubjectResponseInterface> {
    return this.taskApi.updateEvaluationScoreListSubjects(
      taskId,
      evaluationSubjectIds
    ) as Observable<UpdateEvaluationScoreListSubjectResponseInterface>;
  }
}

function castStartEndToDate<T extends TaskClassGroupInterface | TaskGroupInterface | TaskStudentInterface>(
  assignee: T
): T {
  return {
    ...assignee,
    start: assignee.start ? new Date(assignee.start) : undefined,
    end: assignee.end ? new Date(assignee.end) : undefined,
  };
}
