import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { NewsRoleInterface } from '../../+models';
import { NewsRolesActions, NewsRolesActionTypes } from './news-role.actions';

export const NAME = 'newsRoles';

export interface State extends EntityState<NewsRoleInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<NewsRoleInterface> = createEntityAdapter<NewsRoleInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: NewsRolesActions): State {
  switch (action.type) {
    case NewsRolesActionTypes.NewsRolesLoaded: {
      return adapter.setAll(action.payload.newsRoles, {
        ...state,
        loaded: true,
      });
    }

    case NewsRolesActionTypes.NewsRolesLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case NewsRolesActionTypes.AddNewsRoles: {
      return adapter.addMany(action.payload.newsRoles, state);
    }

    case NewsRolesActionTypes.SetNewsRolesForNews: {
      const { newsId, newsRoles } = action.payload;

      const toRemove = (state.ids as number[]).filter((id) => {
        const entity = state.entities[id];
        return entity.newsId === newsId;
      });

      const clearedState = adapter.removeMany(toRemove, state);
      const newState = adapter.addMany(newsRoles, clearedState);

      return newState;
    }

    case NewsRolesActionTypes.RemoveNewsRolesForNews: {
      const { newsId } = action.payload;

      const toRemove = (state.ids as number[]).filter((id) => {
        const entity = state.entities[id];
        return entity.newsId === newsId;
      });
      return adapter.removeMany(toRemove, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
