<campus-elevated-card [ngClass]="highlight ? ['border', 'border-primary'] : []" [relative]="false">
  <campus-card-hero class="frame-contain"><img [src]="imageUrl" /></campus-card-hero>
  <campus-card-overline-head class="center-content" *ngIf="learningAreaName">{{
    learningAreaName
  }}</campus-card-overline-head>
  <campus-card-headline *ngIf="title">{{ title }}</campus-card-headline>
  <campus-card-action-area class="[ cluster ]">
    <ng-container *ngIf="rowDisplayType === 'multiple'">
      <div class="justify-center">
        <campus-select
          [ngModel]="selected"
          type="button"
          label="Kies jaar"
          data-cy="product-card-menu-trigger"
          multiple
          checkbox
          badge
        >
          <campus-select-option
            *ngFor="let row of rows; trackBy: trackByGuid"
            [value]="row"
            [label]="row.label"
            (click)="selectionChanged(row)"
            data-cy="product-card-menu-row"
          ></campus-select-option>
        </campus-select>
      </div>
    </ng-container>
    <ng-container *ngIf="rowDisplayType === 'menu'">
      <div class="justify-center" *ngIf="rows?.length <= 1">
        <button
          *ngFor="let row of rows; trackBy: trackByGuid"
          filled-button
          (click)="selectionChanged(row)"
          data-cy="product-card-row"
        >
          {{ row.label }}
        </button>
      </div>
      <div class="justify-center" *ngIf="rows?.length > 1">
        <div>
          <button filled-button #popoverTrigger trailingIcon="arrow-drop-down" data-cy="product-card-menu-trigger"
            >Kies jaar</button
          >
          <campus-menu [anchor]="popoverTrigger" positioning="fixed">
            <campus-menu-item
              *ngFor="let row of rows; trackBy: trackByGuid"
              [label]="row.label"
              (click)="selectionChanged(row)"
              data-cy="product-card-row"
            ></campus-menu-item>
          </campus-menu>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="rowDisplayType === 'actions'">
      <div class="[ stack ]">
        <div
          *ngFor="let row of rows; trackBy: trackByGuid"
          class="[ w-full flex justify-between center-items ]"
          data-cy="product-card-row"
        >
          <span *ngIf="row.label" class="title-small" data-cy="product-card-title">
            {{ row.label }}
          </span>
          <div *ngIf="row.actions?.length">
            <div class="[ flex ]">
              <button
                inline-icon-button
                *ngFor="let action of row.actions"
                (click)="action.handler(row.guid, data?.id)"
                [icon]="action.icon.icon"
                data-cy="product-card-action"
                [plainTooltip]="action.tooltip"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </campus-card-action-area>
</campus-elevated-card>
