import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { WINDOW } from '@campus/browser';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SpeechBubbleComponent } from '../../feedback';
import { AbilityModeEnum } from '../../progress';
import { ConceptButtonKaiDirective } from './concept-button-kai.directive';
import { ConceptButtonLabelDirective } from './concept-button-label.directive';

interface ConceptButtonDOMRectsInterface {
  element: DOMRect;
  kai: DOMRect;
  container: DOMRect;
  bubble: DOMRect;
  active: boolean;
}
const POINTER_EDGE_OFFSET_VAR = '--cds-comp-speech-bubble-pointer-edge-offset';
const POINTER_EDGE_MIN_OFFSET = 16;

@Component({
  selector: 'campus-concept-button',
  templateUrl: './concept-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConceptButtonComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ContentChild(ConceptButtonLabelDirective, { read: ElementRef, static: false }) labelRef: ElementRef;
  @ContentChild(SpeechBubbleComponent, { read: ElementRef, static: false }) bubbleRef: ElementRef;
  @ContentChild(ConceptButtonKaiDirective, { read: ElementRef, static: false }) kaiRef: ElementRef;
  @ViewChild(SpeechBubbleComponent, { read: ElementRef, static: false }) bubbleViewChild: ElementRef;

  @HostBinding('class')
  defaultClasses = ['overflow-visible', 'relative', 'group'];

  @HostBinding('style.--c-concept-bubble-x')
  bubbleX: number;

  @HostBinding('class.grayscale-full')
  @HostBinding('class.filter')
  @HostBinding('class.opacity-low')
  @HostBinding('class.pointer-event-none')
  @Input()
  disabled: boolean;

  @Input()
  active: boolean;

  @Input()
  done: boolean;

  @Input()
  mode: AbilityModeEnum;

  @Input()
  ability: number;

  @Input()
  showAbility: boolean;

  @Input()
  progress: number;

  @Input()
  showProgress: boolean;

  @Input()
  selectorOptions: string[];

  @Input()
  selectorIndex: number;

  @Input() speechBubble: string;

  @Output()
  buttonClicked = new EventEmitter<PointerEvent>();

  private resize$: Observable<Event>;
  private subscriptions = new Subscription();

  constructor(
    private elementRef: ElementRef,
    private cdRef: ChangeDetectorRef,
    @Inject(WINDOW) private window: Window
  ) {}

  ngAfterViewInit(): void {
    this.resize$ = fromEvent(window, 'resize').pipe(filter(() => !!this.bubbleViewChild));
    this.subscriptions.add(this.resize$.subscribe(this.setBubblePosition.bind(this)));

    this.setLabelClasses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.active) {
      this.setLabelClasses();
    }
    if (changes.speechBubble) {
      this.setBubblePosition();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  observeKaiChange(mutations: MutationRecord[]) {
    const childListMutations = mutations.filter((mutation) => mutation.type === 'childList');
    const hasKaiNode = (nodes: NodeList) => Array.from(nodes).some((node) => node.nodeName === 'svg');

    const bubbleAdded = childListMutations.some((mutation) => !!hasKaiNode(mutation.addedNodes));

    if (bubbleAdded) {
      this.setBubblePosition();
    }
  }

  private setLabelClasses() {
    const el = this.labelRef?.nativeElement as HTMLElement;
    if (!el) return;

    if (this.active) {
      el.classList.add('label-large');
      el.classList.remove('label-medium');
    } else {
      el.classList.add('label-medium');
      el.classList.remove('label-large');
    }
  }

  /**
   * Sets all style values for the absolute positioned speech bubble and its pointer
   */
  private setBubblePosition() {
    const rects = this.getDOMRects();

    if (!rects.active) return;

    const bubbleElement = this.getHTMLElement(this.bubbleViewChild);
    bubbleElement.style.setProperty('margin-left', 'calc(var(--c-concept-bubble-x) * 1px)');

    const kaiX = this.getKaiPositionX(rects);
    this.bubbleX = this.getBubblePositionX(kaiX, rects);

    if (this.bubbleExceedsKaiX(kaiX, rects)) {
      const pointerRightOffset = this.getPointerRightOffset(kaiX, rects);
      this.setPointerRightOffsetProperty(bubbleElement, pointerRightOffset);
    } else {
      this.removePointerRightOffsetProperty(bubbleElement);
    }

    this.cdRef.detectChanges();
  }

  /**
   * Gets a  position of Kai element, relative towards the container
   */
  private getKaiPositionX(rects: ConceptButtonDOMRectsInterface) {
    return rects.kai.left - rects.element.left;
  }

  /**
   * Gets the left value for the repositioned speech-bubble, relative towards the container.
   */
  private getBubblePositionX(kaiX: number, { bubble, kai }: ConceptButtonDOMRectsInterface): number {
    return Math.max(kaiX - bubble.width + kai.width / 2.5, 0);
  }

  /**
   * Gets the position of the pointer of the speech-bubble, relative to Kai's position
   */
  private getPointerRightOffset(kaiX: number, { bubble, kai }: ConceptButtonDOMRectsInterface): number {
    return bubble.width - kaiX + POINTER_EDGE_MIN_OFFSET;
  }

  /**
   * Check whether the speech-bubble width goes beyond Kai's position
   */
  private bubbleExceedsKaiX(kaiX: number, { bubble }: ConceptButtonDOMRectsInterface): boolean {
    return bubble.width > kaiX;
  }

  private setPointerRightOffsetProperty(element: HTMLElement, offset: number) {
    element.style.setProperty(POINTER_EDGE_OFFSET_VAR, `max(${offset}px, ${POINTER_EDGE_MIN_OFFSET}px)`);
  }
  private removePointerRightOffsetProperty(element: HTMLElement) {
    element.style.removeProperty(POINTER_EDGE_OFFSET_VAR);
  }

  private getDOMRects(): ConceptButtonDOMRectsInterface {
    const element = this.getRectFromElement(this.elementRef);
    const kai = this.getRectFromElement(this.kaiRef);
    const container = this.getRectFromElement(this.elementRef, 'parent');
    const bubble = this.getRectFromElement(this.bubbleViewChild);

    const active = [kai, container, element, bubble].every(Boolean);

    return { kai, container, element, bubble, active };
  }

  private getHTMLElement(elementRef: ElementRef | HTMLElement | Node, parent?: 'parent'): HTMLElement {
    let element: HTMLElement;

    if (elementRef instanceof ElementRef) {
      element = elementRef?.nativeElement as HTMLElement;
    } else {
      element = elementRef as HTMLElement;
    }

    return parent ? (element = element.parentElement) : element;
  }

  private getRectFromElement(elementRef: ElementRef | HTMLElement | Node, parent?: 'parent'): DOMRect {
    const element = this.getHTMLElement(elementRef, parent);
    if (!element) return;

    const rect = element.getBoundingClientRect();

    return rect;
  }
}
