import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SurveyQuestionInterface } from '../../+models/SurveyQuestion.interface';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './survey-question.reducer';

export const selectSurveyQuestionState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectSurveyQuestionState, (state: State) => state.error);

export const getLoaded = createSelector(selectSurveyQuestionState, (state: State) => state.loaded);

export const getAll = createSelector(selectSurveyQuestionState, selectAll);

export const getCount = createSelector(selectSurveyQuestionState, selectTotal);

export const getIds = createSelector(selectSurveyQuestionState, selectIds);

export const getAllEntities = createSelector(selectSurveyQuestionState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * survey$: SurveyQuestionInterface[] = this.store.pipe(
    select(SurveyQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectSurveyQuestionState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * survey$: SurveyInterface = this.store.pipe(
    select(SurveyQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectSurveyQuestionState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getBySurveyId = createSelector(
  getAll,
  (surveyQuestions: SurveyQuestionInterface[]) =>
    groupArrayByKeys(surveyQuestions, ['surveyId']) as Dictionary<SurveyQuestionInterface[]>
);
