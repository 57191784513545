import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { LoopInterface } from '../../+models';

export enum LoopsActionTypes {
  LoopsLoaded = '[Loops] Loops Loaded',
  LoopsLoadError = '[Loops] Load Error',
  LoadLoops = '[Loops] Load Loops',
  AddLoop = '[Loops] Add Loop',
  UpsertLoop = '[Loops] Upsert Loop',
  AddLoops = '[Loops] Add Loops',
  UpsertLoops = '[Loops] Upsert Loops',
  UpdateLoop = '[Loops] Update Loop',
  UpdateLoops = '[Loops] Update Loops',
  DeleteLoop = '[Loops] Delete Loop',
  DeleteLoops = '[Loops] Delete Loops',
  ClearLoops = '[Loops] Clear Loops',
}

export class LoadLoops implements Action {
  readonly type = LoopsActionTypes.LoadLoops;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class LoopsLoaded implements Action {
  readonly type = LoopsActionTypes.LoopsLoaded;

  constructor(public payload: { loops: LoopInterface[] }) {}
}

export class LoopsLoadError implements Action {
  readonly type = LoopsActionTypes.LoopsLoadError;
  constructor(public payload: any) {}
}

export class AddLoop implements Action {
  readonly type = LoopsActionTypes.AddLoop;

  constructor(public payload: { loop: LoopInterface }) {}
}

export class UpsertLoop implements Action {
  readonly type = LoopsActionTypes.UpsertLoop;

  constructor(public payload: { loop: LoopInterface }) {}
}

export class AddLoops implements Action {
  readonly type = LoopsActionTypes.AddLoops;

  constructor(public payload: { loops: LoopInterface[] }) {}
}

export class UpsertLoops implements Action {
  readonly type = LoopsActionTypes.UpsertLoops;

  constructor(public payload: { loops: LoopInterface[] }) {}
}

export class UpdateLoop implements Action {
  readonly type = LoopsActionTypes.UpdateLoop;

  constructor(public payload: { loop: Update<LoopInterface> }) {}
}

export class UpdateLoops implements Action {
  readonly type = LoopsActionTypes.UpdateLoops;

  constructor(public payload: { loops: Update<LoopInterface>[] }) {}
}

export class DeleteLoop implements Action {
  readonly type = LoopsActionTypes.DeleteLoop;

  constructor(public payload: { id: number }) {}
}

export class DeleteLoops implements Action {
  readonly type = LoopsActionTypes.DeleteLoops;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearLoops implements Action {
  readonly type = LoopsActionTypes.ClearLoops;
}

export type LoopsActions =
  | LoadLoops
  | LoopsLoaded
  | LoopsLoadError
  | AddLoop
  | UpsertLoop
  | AddLoops
  | UpsertLoops
  | UpdateLoop
  | UpdateLoops
  | DeleteLoop
  | DeleteLoops
  | ClearLoops;
