/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { TeacherInfoApi as SDKTeacherInfoApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class TeacherInfoApi extends ModelApiBase {
  private SDKTeacherInfoApi = inject(SDKTeacherInfoApi);

  constructor(api: PolpoApi) {
    super(api, 'TeacherInfos');
  }

  checkUnique(userId, prop: string, publicKey) {
    // @ts-expect-error TS 2556
    return this.SDKTeacherInfoApi.checkUnique(...arguments);
  }

  create(data) {
    // @ts-expect-error TS 2556
    return this.SDKTeacherInfoApi.create(...arguments);
  }

  updateAttributes(id, data): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKTeacherInfoApi.updateAttributes(...arguments);
  }

  deleteById(id) {
    // @ts-expect-error TS 2556
    return this.SDKTeacherInfoApi.deleteById(...arguments);
  }
}
