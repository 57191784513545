import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { SchoolProviderInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum SchoolProvidersActionTypes {
  SchoolProvidersLoaded = '[SchoolProviders] SchoolProviders Loaded',
  SchoolProvidersLoadError = '[SchoolProviders] Load Error',
  LoadSchoolProviders = '[SchoolProviders] Load SchoolProviders',
  AddSchoolProvider = '[SchoolProviders] Add SchoolProvider',
  AddSchoolProviders = '[SchoolProviders] Add SchoolProviders',
  UpsertSchoolProvider = '[SchoolProviders] Upsert SchoolProvider',
  UpsertSchoolProviders = '[SchoolProviders] Upsert SchoolProviders',
  UpdateSchoolProvider = '[SchoolProviders] Update SchoolProvider',
  UpdateSchoolProviders = '[SchoolProviders] Update SchoolProviders',
  DeleteSchoolProvider = '[SchoolProviders] Delete SchoolProvider',
  DeleteSchoolProviders = '[SchoolProviders] Delete SchoolProviders',
  ClearSchoolProviders = '[SchoolProviders] Clear SchoolProviders',
  StartAddSchoolProvider = '[SchoolProviders] Start Add SchoolProvider',
  StartAddSchoolProviders = '[SchoolProviders] Start Add SchoolProviders',
}

export class LoadSchoolProviders implements Action {
  readonly type = SchoolProvidersActionTypes.LoadSchoolProviders;

  constructor(public payload: { force?: boolean }) {}
}

export class SchoolProvidersLoaded implements Action {
  readonly type = SchoolProvidersActionTypes.SchoolProvidersLoaded;

  constructor(public payload: { schoolProviders: SchoolProviderInterface[] }) {}
}

export class SchoolProvidersLoadError implements Action {
  readonly type = SchoolProvidersActionTypes.SchoolProvidersLoadError;
  constructor(public payload: any) {}
}

export class AddSchoolProvider implements Action {
  readonly type = SchoolProvidersActionTypes.AddSchoolProvider;

  constructor(public payload: { schoolProvider: SchoolProviderInterface }) {}
}

export class UpsertSchoolProvider implements Action {
  readonly type = SchoolProvidersActionTypes.UpsertSchoolProvider;

  constructor(public payload: { schoolProvider: SchoolProviderInterface }) {}
}

export class AddSchoolProviders implements Action {
  readonly type = SchoolProvidersActionTypes.AddSchoolProviders;

  constructor(public payload: { schoolProviders: SchoolProviderInterface[] }) {}
}

export class UpsertSchoolProviders implements Action {
  readonly type = SchoolProvidersActionTypes.UpsertSchoolProviders;

  constructor(public payload: { schoolProviders: SchoolProviderInterface[] }) {}
}

export class UpdateSchoolProvider implements Action {
  readonly type = SchoolProvidersActionTypes.UpdateSchoolProvider;

  constructor(public payload: { schoolProvider: Update<SchoolProviderInterface> }) {}
}

export class UpdateSchoolProviders implements Action {
  readonly type = SchoolProvidersActionTypes.UpdateSchoolProviders;

  constructor(public payload: { schoolProviders: Update<SchoolProviderInterface>[] }) {}
}

export class DeleteSchoolProvider implements FeedbackTriggeringAction {
  readonly type = SchoolProvidersActionTypes.DeleteSchoolProvider;

  constructor(
    public payload: { id: number; schoolId: number; customFeedbackHandlers?: CustomFeedbackHandlersInterface }
  ) {}
}

export class DeleteSchoolProviders implements FeedbackTriggeringAction {
  readonly type = SchoolProvidersActionTypes.DeleteSchoolProviders;

  constructor(public payload: { ids: number[]; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export class ClearSchoolProviders implements Action {
  readonly type = SchoolProvidersActionTypes.ClearSchoolProviders;
}

export class StartAddSchoolProvider implements FeedbackTriggeringAction {
  readonly type = SchoolProvidersActionTypes.StartAddSchoolProvider;

  constructor(
    public payload: {
      schoolId: number;
      schoolProvider: SchoolProviderInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export type SchoolProvidersActions =
  | LoadSchoolProviders
  | SchoolProvidersLoaded
  | SchoolProvidersLoadError
  | AddSchoolProvider
  | UpsertSchoolProvider
  | AddSchoolProviders
  | UpsertSchoolProviders
  | UpdateSchoolProvider
  | UpdateSchoolProviders
  | DeleteSchoolProvider
  | DeleteSchoolProviders
  | ClearSchoolProviders
  | StartAddSchoolProvider;
