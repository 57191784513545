import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { fetch, optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { FavoriteQueries } from '.';
import { DalActions } from '..';
import { FavoriteServiceInterface, FAVORITE_SERVICE_TOKEN } from '../../favorite/favorite.service.interface';
import { AuthServiceInterface, AUTH_SERVICE_TOKEN } from '../../persons';
import { DalState } from '../dal.state.interface';
import { EffectFeedback, EffectFeedbackActions, Priority } from '../effect-feedback';
import {
  AddFavorite,
  DeleteFavorite,
  FavoritesActionTypes,
  FavoritesLoaded,
  FavoritesLoadError,
  LoadFavorites,
  StartAddFavorite,
  ToggleFavorite,
  UpdateFavorite,
} from './favorite.actions';
import { getByTypeAndId } from './favorite.selectors';

@Injectable()
export class FavoriteEffects {
  loadFavorites$ = createEffect(() =>
    this.actions.pipe(
      ofType(FavoritesActionTypes.LoadFavorites),
      concatLatestFrom(() => this.store.select(FavoriteQueries.getLoaded)),
      fetch({
        run: (action: LoadFavorites, loaded: boolean) => {
          if (!action.payload.force && loaded) return;

          return this.favoriteService
            .getAllForUser(action.payload.userId)
            .pipe(map((favorites) => new FavoritesLoaded({ favorites })));
        },
        onError: (action: LoadFavorites, error) => {
          return new FavoritesLoadError(error);
        },
      })
    )
  );

  startAddFavorite$ = createEffect(() =>
    this.actions.pipe(
      ofType(FavoritesActionTypes.StartAddFavorite),
      pessimisticUpdate({
        run: (action: StartAddFavorite) => {
          return this.favoriteService
            .addFavorite(action.payload.userId, action.payload.favorite)
            .pipe(map((favorite) => new AddFavorite({ favorite })));
        },
        onError: (action: StartAddFavorite, error) => {
          const effectFeedback = new EffectFeedback({
            id: this.uuid(),
            triggerAction: action,
            message: 'Het is niet gelukt om het item aan jouw favorieten toe te voegen.',
            type: 'error',
            userActions: [{ title: 'Opnieuw proberen', userAction: action }],
            priority: Priority.HIGH,
          });
          return new EffectFeedbackActions.AddEffectFeedback({ effectFeedback });
        },
      })
    )
  );

  deleteFavorite$ = createEffect(() =>
    this.actions.pipe(
      ofType(FavoritesActionTypes.DeleteFavorite),
      optimisticUpdate({
        run: (action: DeleteFavorite) => {
          return this.favoriteService.deleteFavorite(action.payload.userId, action.payload.id).pipe(
            map(
              (result) =>
                new DalActions.ActionSuccessful({
                  successfulAction: '[Favorite] deleted',
                })
            )
          );
        },
        undoAction: (action: DeleteFavorite, error) => {
          const undoAction = undo(action);
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            'Het is niet gelukt om het item uit jouw favorieten te verwijderen.'
          );
          const effectFeedbackAction = new EffectFeedbackActions.AddEffectFeedback({ effectFeedback });
          return from([undoAction, effectFeedbackAction]);
        },
      })
    )
  );

  updateFavorite$ = createEffect(() =>
    this.actions.pipe(
      ofType(FavoritesActionTypes.UpdateFavorite),
      optimisticUpdate({
        run: (action: UpdateFavorite) => {
          return this.favoriteService
            .updateFavorite(action.payload.userId, +action.payload.favorite.id, action.payload.favorite.changes)
            .pipe(
              map(() => {
                const effectFeedback = EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'Je favoriet is gewijzigd.'
                );
                return new EffectFeedbackActions.AddEffectFeedback({
                  effectFeedback,
                });
              })
            );
        },
        undoAction: (action: UpdateFavorite, error) => {
          const undoAction = undo(action);
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            'Het is niet gelukt om je favoriet te wijzigen.'
          );
          const effectFeedbackAction = new EffectFeedbackActions.AddEffectFeedback({ effectFeedback });
          return from([undoAction, effectFeedbackAction]);
        },
      })
    )
  );

  toggleFavorite$ = createEffect(() =>
    this.actions.pipe(
      ofType(FavoritesActionTypes.ToggleFavorite),
      switchMap((action: ToggleFavorite): Observable<StartAddFavorite | DeleteFavorite> => {
        return this.store.pipe(
          select(getByTypeAndId, {
            type: action.payload.favorite.type,
            eduContentId: action.payload.favorite.eduContentId,
            eduContentBookId: action.payload.favorite.eduContentBookId,
            bundleId: action.payload.favorite.bundleId,
            taskId: action.payload.favorite.taskId,
            learningAreaId: action.payload.favorite.learningAreaId,
            productId: action.payload.favorite.productId,
          }),
          take(1),
          map((favorite) => {
            if (favorite) {
              return new DeleteFavorite({
                id: favorite.id,
                userId: this.authService.userId,
              });
            } else {
              return new StartAddFavorite({
                favorite: action.payload.favorite,
                userId: this.authService.userId,
              });
            }
          })
        );
      })
    )
  );

  constructor(
    @Inject('uuid') private uuid: () => string,
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(FAVORITE_SERVICE_TOKEN)
    private favoriteService: FavoriteServiceInterface,
    @Inject(AUTH_SERVICE_TOKEN)
    private authService: AuthServiceInterface
  ) {}
}
