import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonApi, UserContentApi } from '../+api';
import { UserContentInterface } from '../+models';
import { UserContentServiceInterface } from './user-content.service.interface';

@Injectable({
  providedIn: 'root',
})
export class UserContentService implements UserContentServiceInterface {
  constructor(private personApi: PersonApi, private userContentApi: UserContentApi) {}

  getAllForUser(userId: number): Observable<UserContentInterface[]> {
    return this.personApi
      .getData(userId, 'userContents')
      .pipe(map((res: { userContents: UserContentInterface[] }) => res.userContents));
  }

  createUserContent(userId: number, userContent: UserContentInterface): Observable<UserContentInterface> {
    return this.personApi.createUserContents(userId, userContent) as Observable<UserContentInterface>;
  }

  updateUserContent(userContent: Partial<UserContentInterface>): Observable<UserContentInterface> {
    return this.userContentApi.patchAttributes(userContent.id, userContent);
  }
}
