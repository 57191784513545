/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { EvaluationScoreApi as SDKEvaluationScoreApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class EvaluationScoreApi extends ModelApiBase {
  private SDKEvaluationScoreApi = inject(SDKEvaluationScoreApi);

  constructor(api: PolpoApi) {
    super(api, 'EvaluationScores');
  }

  setEvaluationScoresForTask(taskId: number, evaluationScores): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEvaluationScoreApi.setEvaluationScoresForTask(...arguments);
  }
}
