import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { WINDOW } from '@campus/browser';
import {
  BreadcrumbLinkInterface,
  DalState,
  EffectFeedbackActions,
  EffectFeedbackInterface,
  EffectFeedbackQueries,
  PageHeaderInterface,
  Permissions,
  PersonInterface,
  TaskEduContentQueries,
  TaskInstanceQueries,
  TaskQueries,
  UiActions,
  UiQuery,
  UserQueries,
  getRouterState,
} from '@campus/dal';
import {
  FEEDBACK_SERVICE_TOKEN,
  FeedBackServiceInterface,
  NAVIGATION_ITEM_SERVICE_TOKEN,
  NavigationItemServiceInterface,
  STUDENT_TASK_SERVICE_TOKEN,
  StudentTaskServiceInterface,
} from '@campus/shared';
import { NavItem } from '@campus/ui';
import { Action, Store, select } from '@ngrx/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppViewModel implements OnDestroy {
  //presentation streams
  public sideNavOpen$: Observable<boolean>;
  public bannerFeedback$: Observable<EffectFeedbackInterface>;
  public pageHeaderDetails$: Observable<PageHeaderInterface>;
  public userPermissions$: Observable<string[]>;

  public denseMenu$: Observable<boolean>;
  public breadcrumbs$: Observable<BreadcrumbLinkInterface[]>;
  public sideNavItems$: Observable<NavItem[]>;

  // intermediate  streams
  private isLoggedIn$: Observable<boolean>;

  private subscriptions: Subscription;

  constructor(
    private store: Store<DalState>,
    @Inject(NAVIGATION_ITEM_SERVICE_TOKEN)
    private navItemService: NavigationItemServiceInterface,
    @Inject(WINDOW) private window: Window,
    @Inject(FEEDBACK_SERVICE_TOKEN)
    private feedbackService: FeedBackServiceInterface,
    private breakPointObserver: BreakpointObserver,
    @Inject(STUDENT_TASK_SERVICE_TOKEN)
    private studentTaskService: StudentTaskServiceInterface
  ) {
    this.setSourceStream();
    this.initialize();
  }

  // sets sideNav opened state
  public toggleSidebar(open: boolean) {
    this.store.dispatch(new UiActions.ToggleNavOpen({ open }));
  }

  // event handler for feedback dismiss
  // used by banner and snackbar
  public onFeedbackDismiss(event: { action: Action; feedbackId: string }): void {
    const payload: { id: string; userAction?: Action } = {
      id: event.feedbackId,
    };
    if (event.action) {
      this.store.dispatch(event.action);
      payload.userAction = event.action;
    }

    this.store.dispatch(new EffectFeedbackActions.DeleteEffectFeedback(payload));
  }

  public onNavItemChanged(navItem: NavItem) {
    this.store.dispatch(new UiActions.UpdateNavItem({ navItem }));
  }

  public toggleSidebarOnNavigation() {
    //Hide sidebar on mobile if we navigate or change screensize
    this.subscriptions.add(
      this.store
        .pipe(
          select(getRouterState),
          switchMap(() => {
            return this.breakPointObserver
              .observe([Breakpoints.XSmall, Breakpoints.Small])
              .pipe(map((result) => result.matches));
          })
        )
        .subscribe((isMobile) => {
          this.toggleSidebar(!isMobile);
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private initialize() {
    this.setNavItems();
    this.setFeedbackFlow();

    this.denseMenu$ = this.store.select(UiQuery.getDenseMenu);
    this.breadcrumbs$ = this.store.pipe(select(UiQuery.getBreadcrumbs));

    this.subscriptions = new Subscription();
  }

  private setNavItems() {
    // send data to service -> get array of navItems
    this.sideNavItems$ = combineLatest([
      this.userPermissions$,
      this.isLoggedIn$,
      this.store.select(TaskQueries.getAll),
      this.store.select(TaskInstanceQueries.getAllGroupedByTaskId),
      this.store.select(TaskEduContentQueries.getAllGroupedByTaskId),
    ]).pipe(
      map(([permissions, isLoggedIn, tasks, taskInstanceDict, taskEduContentDict]) => {
        if (!isLoggedIn) return [];

        const openTasks = tasks
          .filter((task) => (taskInstanceDict[task.id] || []).some((ti) => ti.isOpen))
          .map((task) => ({
            ...task,
            taskInstances: taskInstanceDict[task.id] || [],
            taskEduContents: taskEduContentDict[task.id] || [],
          }))
          .filter((task) => !this.studentTaskService.isTaskFinished(task));

        return this.navItemService.getNavItemsForTree('sideNav', permissions).map((navItem) => {
          return {
            ...navItem,
            ...(permissions.includes(Permissions.tasks.OPEN_POLPO_TASKS) &&
            navItem.link?.toString().startsWith('/tasks')
              ? { badge: openTasks.length.toString() }
              : {}),
          };
        });
      })
    );

    // get sideNav opened state
    this.sideNavOpen$ = this.store.pipe(select(UiQuery.getNavOpen));
  }

  private setSourceStream() {
    this.pageHeaderDetails$ = this.store.select(UiQuery.getHeaderDetails);

    this.userPermissions$ = this.store.pipe(select(UserQueries.getPermissions));

    this.isLoggedIn$ = this.store.pipe(
      select(UserQueries.getCurrentUser),
      tap((user) => this.setUsetifulTags(user)),
      map((user) => !!user)
    );
  }

  private setUsetifulTags(user: PersonInterface) {
    if (user) {
      const firstName = user.firstName || '';
      this.window['usetifulTags'] = { firstName };
    }
  }

  private setFeedbackFlow() {
    // success feedback goes to the feedbackService -> snackbar
    // success feedback goes to the feedbackService -> snackbar
    this.store
      .pipe(
        select(EffectFeedbackQueries.getNextSuccess),
        map((feedback) => this.feedbackService.openSnackbar(feedback)),
        filter((snackbar) => !!snackbar),
        switchMap((snackbarInfo) => this.feedbackService.snackbarAfterDismiss(snackbarInfo)),
        map((event) => ({
          action: event.actionToDispatch,
          feedbackId: event.feedback.id,
        }))
      )
      .subscribe((evt) => this.onFeedbackDismiss(evt));

    // error feedback goes into a stream -> bannerComponent
    this.bannerFeedback$ = this.store.pipe(
      select(EffectFeedbackQueries.getNextError),
      map(this.feedbackService.addDefaultCancelButton)
    );
  }
}
