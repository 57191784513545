import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LicenseInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './license.reducer';

export const selectLicenseState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectLicenseState, (state: State) => state.error);

export const getLoaded = createSelector(selectLicenseState, (state: State) => state.loaded);

export const getAll = createSelector(selectLicenseState, selectAll);

export const getCount = createSelector(selectLicenseState, selectTotal);

export const getIds = createSelector(selectLicenseState, selectIds);

export const getAllEntities = createSelector(selectLicenseState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * methodLicenseGroup$: LicenseInterface[] = this.store.pipe(
    select(LicenseQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectLicenseState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * methodLicenseGroup$: LicenseInterface = this.store.pipe(
    select(LicenseQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectLicenseState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getBySchoolIdByProductId = createSelector(
  getAll,
  (licenses) => groupArrayByKeys(licenses, ['schoolId', 'productId']) as Dictionary<Dictionary<LicenseInterface[]>>
);
export const getActiveLicenses = createSelector(getAll, (licenses) =>
  licenses.filter(
    (license) =>
      license.activated && license.activated <= new Date() && (!license.expired || license.expired > new Date())
  )
);
