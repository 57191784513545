import { DalState, UserQueries } from '@campus/dal';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import {
  ContentOpenActionsService,
  ContentOpenActionsStudentService,
  ContentOpenerInterface,
  DefaultContentOpenerService,
} from '../services';

export function ContentOpenerFactory(
  store: Store<DalState>,
  contentOpener: ContentOpenerInterface,
  defaultContentOpener: DefaultContentOpenerService
) {
  let isTeacher = false;
  store.pipe(select(UserQueries.getCurrentUser), take(1)).subscribe((user) => {
    isTeacher = (user?.roles || []).some((role) => role.name === 'teacher');
  });

  return isTeacher
    ? new ContentOpenActionsService(contentOpener, defaultContentOpener)
    : new ContentOpenActionsStudentService(contentOpener, defaultContentOpener);
}
