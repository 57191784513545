import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '@campus/browser';
import { EduContent } from '@campus/dal';
import { ContentOpenerInterface, OpenContentOptions } from './content-open-actions.service.interface';

export class DefaultContentOpenerService implements ContentOpenerInterface {
  private router = inject(Router);
  private location = inject(Location);
  private window = inject(WINDOW);

  openEduContentAsExercise(eduContent: EduContent, options?: OpenContentOptions): void {
    throw new Error('There is no default opener fallback for this method.');
  }

  openEduContentAsSolution(eduContent: EduContent, options?: OpenContentOptions): void {
    throw new Error('There is no default opener fallback for this method.');
  }

  openEduContentAsStream(eduContent: EduContent, options?: OpenContentOptions): void {
    throw new Error('There is no default opener fallback for this method.');
  }

  openEduContentAsDownload(eduContent: EduContent, options?: OpenContentOptions): void {
    throw new Error('There is no default opener fallback for this method.');
  }

  openEduContentAsLink(eduContent: EduContent, options?: OpenContentOptions): void {
    throw new Error('There is no default opener fallback for this method.');
  }

  openBoeke(eduContent: EduContent, options?: OpenContentOptions): void {
    throw new Error('There is no default opener fallback for this method.');
  }

  openEduContentForReview(eduContent: EduContent, options?: OpenContentOptions): void {
    throw new Error('There is no default opener fallback for this method.');
  }

  openPaperExercise(eduContent: EduContent, options?: OpenContentOptions): void {
    throw new Error('There is no default opener fallback for this method.');
  }

  openEduContentAsHtml?(eduContent: EduContent, options?: OpenContentOptions): void {
    throw new Error('There is no default opener fallback for this method.');
  }

  openInstructions?(eduContent: EduContent, options?: OpenContentOptions): void {
    const { artifactId } = eduContent.publishedEduContentMetadata;

    const path = this.router.createUrlTree(['/content', 'instructions', artifactId]).toString();
    const fullPath = this.location.prepareExternalUrl(path);

    // no need to add the domain since we're already on the same domain
    this.window.open(fullPath, '_blank');
  }
}
