import { UpdateNum } from '@ngrx/entity/src/models';
import { Action } from '@ngrx/store';
import { NewsInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum NewsActionTypes {
  NewsLoaded = '[News] News Loaded',
  NewsLoadError = '[News] Load Error',
  LoadNews = '[News] Load News',
  ClearNews = '[News] Clear News',
  StartAddNews = '[News] Start Add News',
  AddNews = '[News] Add News',
  NavigateToNewsDetail = '[News] Navigate To News Detail',
  StartUploadNewsImage = '[News] Start Upload News Image',
  UploadNewsImage = '[News] Upload News Image',
  UpdateNews = '[News] Update News',
  DeleteNews = '[News] Delete News',
}

export class LoadNews implements Action {
  readonly type = NewsActionTypes.LoadNews;
  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class NewsLoaded implements Action {
  readonly type = NewsActionTypes.NewsLoaded;
  constructor(public payload: { news: NewsInterface[] }) {}
}

export class NewsLoadError implements Action {
  readonly type = NewsActionTypes.NewsLoadError;
  constructor(public payload: any) {}
}

export class ClearNews implements Action {
  readonly type = NewsActionTypes.ClearNews;
}

export class StartAddNews implements FeedbackTriggeringAction {
  readonly type = NewsActionTypes.StartAddNews;

  constructor(
    public payload: {
      news: NewsInterface;
      navigateAfterCreate?: boolean;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartUploadNewsImage implements FeedbackTriggeringAction {
  readonly type = NewsActionTypes.StartUploadNewsImage;

  constructor(
    public payload: {
      news: NewsInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UploadNewsImage implements Action {
  readonly type = NewsActionTypes.UploadNewsImage;

  constructor(public payload: { id: number; image: string }) {}
}

export class AddNews implements Action {
  readonly type = NewsActionTypes.AddNews;

  constructor(public payload: { news: NewsInterface }) {}
}

export class UpdateNews implements FeedbackTriggeringAction {
  readonly type = NewsActionTypes.UpdateNews;

  constructor(
    public payload: {
      news: UpdateNum<NewsInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class NavigateToNewsDetail implements Action {
  readonly type = NewsActionTypes.NavigateToNewsDetail;

  constructor(public payload: { news: NewsInterface }) {}
}

export class DeleteNews implements Action, FeedbackTriggeringAction {
  readonly type = NewsActionTypes.DeleteNews;

  constructor(public payload: { ids: number[]; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export type NewsActions =
  | LoadNews
  | NewsLoaded
  | NewsLoadError
  | ClearNews
  | StartAddNews
  | AddNews
  | StartUploadNewsImage
  | UploadNewsImage
  | UpdateNews
  | DeleteNews;
