import { CdkPortal, DomPortalOutlet, PortalOutlet } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  Injector,
  OnDestroy,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'campus-breadcrumbs-template',
  template: '<ng-template cdk-portal> <ng-content></ng-content> </ng-template>',
})
export class BreadcrumbsTemplateComponent implements AfterViewInit, OnDestroy {
  private portalHost: PortalOutlet;
  @ViewChild(CdkPortal) portal;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {}

  ngAfterViewInit(): void {
    // Create a portalHost from a DOM element
    this.portalHost = this.getPortalHost('.ui-breadcrumbs-host');
    // Attach portal to host
    if (this.portalHost != null) {
      this.portalHost.attach(this.portal);
    }
  }

  ngOnDestroy(): void {
    if (this.portalHost != null) {
      this.portalHost.detach();
    }
  }

  private getPortalHost(selector: string) {
    const element = document.querySelector(selector);
    if (element === null) return null;
    return new DomPortalOutlet(element, this.componentFactoryResolver, this.appRef, this.injector);
  }
}
