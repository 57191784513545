import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WINDOW } from '@campus/browser';
import { ApiEnvironment } from '@campus/utils';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch, pessimisticUpdate } from '@nrwl/angular';
import { combineLatest, from, timer } from 'rxjs';
import { filter, map, mapTo, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { UserActions, UserQueries } from '.';
import { DalState } from '..';
import { PersonInterface, TeacherInfoInterface } from '../../+models';

import { AuthServiceInterface, AUTH_SERVICE_TOKEN } from '../../persons/auth-service.interface';
import { EffectFeedback, EffectFeedbackActions, Priority } from '../effect-feedback';
import { PersonServiceInterface, PERSON_SERVICE_TOKEN } from './../../persons/persons.service';
import {
  LoadPermissions,
  LoadUser,
  LogInUser,
  LogInUserWithPersonalCode,
  PermissionsLoaded,
  PermissionsLoadError,
  RemoveUser,
  SendForgotPasswordEmail,
  StartUpdateCompleteProfile,
  StartUpdateUser,
  StartUpsertTeacherInfo,
  UpdateCompleteProfile,
  UpdatePassword,
  UpsertTeacherInfo,
  UserActionTypes,
  UserLoaded,
  UserLoadError,
  UserRemoved,
  UserRemoveError,
} from './user.actions';

@Injectable()
export class UserEffects {
  /**
   * get current from api. errors when no user is logged in.
   *
   * @memberof UserEffects
   */

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.LoadUser),
      concatLatestFrom(() => this.store.select(UserQueries.getLoaded)),
      fetch({
        run: (action: LoadUser, loaded: boolean) => {
          if (!action.payload.force && loaded) return;

          return this.authService.getCurrent().pipe(
            map((r) => {
              this.sendAccessTokenMessage();

              // like types, the server apiEnvironment is also appended to the login
              // and getPersonById API endpoints
              ApiEnvironment.setFromLoginResponse(r);

              return new UserLoaded(r);
            })
          );
        },
        onError: (action: LoadUser, error) => {
          return new UserLoadError(error);
        },
      })
    )
  );

  /**
   * logs a user in.
   *
   * @memberof UserEffects
   */

  loginUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.LogInUser),
      pessimisticUpdate({
        run: (action: LogInUser) => {
          const { customFeedbackHandlers, ...credentials } = action.payload;
          return this.authService.login(credentials).pipe(
            map(() => {
              return new LoadUser({ force: false });
            })
          );
        },
        onError: (action: LogInUser, error) => {
          const effectFeedback = new EffectFeedback({
            id: this.uuid(),
            triggerAction: action,
            message: 'De gebruikersnaam of het wachtwoord is niet correct.',
            userActions: [],
            type: 'error',
            priority: Priority.HIGH,
          });
          const feedbackAction = new EffectFeedbackActions.AddEffectFeedback({
            effectFeedback,
          });
          return feedbackAction;
        },
      })
    )
  );

  loginUserWithPersonalCode = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.LoginUserWithPersonalCode),
      pessimisticUpdate({
        run: (action: LogInUserWithPersonalCode) => {
          const { customFeedbackHandlers, ...credentials } = action.payload;
          return this.authService.loginWithPersonalCode(credentials).pipe(
            map(() => {
              return new LoadUser({ force: false });
            })
          );
        },
        onError: (action: LogInUserWithPersonalCode, error) => {
          const effectFeedback = new EffectFeedback({
            id: this.uuid(),
            triggerAction: action,
            message: `Helaas. Dit wachtwoord is niet juist. Probeer opnieuw.`,
            userActions: [],
            type: 'error',
            priority: Priority.HIGH,
          });
          const feedbackAction = new EffectFeedbackActions.AddEffectFeedback({
            effectFeedback,
          });
          return feedbackAction;
        },
      })
    )
  );

  /**
   * logsout the current user and remove from store.
   *
   * @memberof UserEffects
   */

  removedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.RemoveUser),
      pessimisticUpdate({
        run: (action: RemoveUser) => {
          return this.authService.logout().pipe(
            tap(() => {
              this.window.sessionStorage.clear();
            }),
            map(() => {
              return new UserRemoved();
            })
          );
        },
        onError: (action: RemoveUser, error) => {
          return new UserRemoveError(error);
        },
      })
    )
  );

  startUpsertTeacherInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.StartUpsertTeacherInfo),
      pessimisticUpdate({
        run: (action: StartUpsertTeacherInfo) => {
          return this.personService
            .upsertTeacherInfo(action.payload.teacherInfo)
            .pipe(map((teacherInfo: TeacherInfoInterface) => new UpsertTeacherInfo(teacherInfo)));
        },
        onError: (action: StartUpsertTeacherInfo, error) => {
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            'Het is niet gelukt om de leerkrachtencode op te slaan.'
          );
          const feedbackAction = new EffectFeedbackActions.AddEffectFeedback({
            effectFeedback,
          });
          return feedbackAction;
        },
      })
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.StartUpdateUser),
      pessimisticUpdate({
        run: (action: StartUpdateUser) => {
          return this.personService.updateUser(action.payload.userId, action.payload.changedProps).pipe(
            switchMap((repsonse) => {
              return from([
                new UserActions.UpdateUser({
                  userId: action.payload.userId,
                  changedProps: repsonse,
                }),
                new EffectFeedbackActions.AddEffectFeedback({
                  effectFeedback: new EffectFeedback({
                    id: this.uuid(),
                    triggerAction: action,
                    message: 'Je gegevens zijn opgeslagen.',
                  }),
                }),
              ]);
            })
          );
        },
        onError: (action: StartUpdateUser, error) => {
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            'Het is niet gelukt om je gegevens te bewaren.'
          );
          const feedbackAction = new EffectFeedbackActions.AddEffectFeedback({
            effectFeedback,
          });
          return feedbackAction;
        },
      })
    )
  );

  updatePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.UpdatePassword),
      pessimisticUpdate({
        run: (action: UpdatePassword) => {
          return this.personService
            .updatePassword(
              action.payload.userId,
              action.payload.password,
              action.payload.currentPassword,
              action.payload.accessToken
            )
            .pipe(
              mapTo(
                new EffectFeedbackActions.AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'Je wachtwoord is gewijzigd.'
                  ),
                })
              )
            );
        },
        onError: (action: UpdatePassword, error) => {
          const effectFeedback = EffectFeedback.generateErrorFeedback(this.uuid(), action, error.message);
          const feedbackAction = new EffectFeedbackActions.AddEffectFeedback({
            effectFeedback,
          });
          return feedbackAction;
        },
      })
    )
  );

  /**
   * get permissions from api. errors when call fails.
   *
   * @memberof UserEffects
   */

  loadPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.LoadPermissions),
      concatLatestFrom(() => this.store.select(UserQueries.getPermissionsLoaded)),
      fetch({
        run: (action: LoadPermissions, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.authService.getPermissions().pipe(
            map((r) => {
              return new PermissionsLoaded(r);
            })
          );
        },
        onError: (action: LoadPermissions, error) => {
          return new PermissionsLoadError(error);
        },
      })
    )
  );

  startUpdateCompleteProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.StartUpdateCompleteProfile),
      concatLatestFrom(() => this.store.select(UserQueries.getCurrentUser)),
      pessimisticUpdate({
        run: (action: StartUpdateCompleteProfile, currentUser: PersonInterface) => {
          const userId = currentUser?.id;
          return this.personService.checkProfileComplete().pipe(
            map(
              (completeProfile) =>
                new UpdateCompleteProfile({
                  userId,
                  changedProps: { completeProfile },
                })
            )
          );
        },
        onError: (action: StartUpdateCompleteProfile, error) => {
          return new UserLoadError({ error });
        },
      })
    )
  );

  sendForgotPasswordEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.SendForgotPasswordEmail),
      pessimisticUpdate({
        run: (action: SendForgotPasswordEmail) => {
          const { email } = action.payload;

          return this.authService.resetPassword(email).pipe(
            mapTo(
              new EffectFeedbackActions.AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  `Controleer ${email} voor een e-mail met instructies om je wachtwoord opnieuw in te stellen.`
                ),
              })
            )
          );
        },
        onError: (action: SendForgotPasswordEmail, error) => {
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            `Controleer ${action.payload.email} voor een e-mail met instructies om je wachtwoord opnieuw in te stellen.`
          );
          const feedbackAction = new EffectFeedbackActions.AddEffectFeedback({
            effectFeedback,
          });
          return feedbackAction;
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private route: ActivatedRoute,
    @Inject(WINDOW) private window: Window,
    private store: Store<DalState>,
    @Inject(PERSON_SERVICE_TOKEN) private personService: PersonServiceInterface,
    @Inject(AUTH_SERVICE_TOKEN) private authService: AuthServiceInterface,
    @Inject('uuid') private uuid: () => string
  ) {}

  private sendAccessTokenMessage() {
    if (!this.window.opener) return;

    combineLatest([
      this.authService.getAccessToken$(),
      this.route.queryParams.pipe(filter(({ externalLogin }) => !!externalLogin)),
    ])
      .pipe(
        takeUntil(timer(10000)), // don't listen for longer than 10s
        take(1)
      )
      .subscribe(([{ id: token, user }, { targetOrigin }]) => {
        const msg = { token, user };
        this.window.opener.postMessage(msg, targetOrigin);

        this.window.setTimeout(() => this.window.close(), 500);
      });
  }
}
