import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { fetch, optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { BundleActions, BundleQueries } from '.';
import { BundleInterface, HistoryTypesEnum, UnlockedContentInterface } from '../../+models';
import { BundleServiceInterface, BUNDLE_SERVICE_TOKEN } from '../../bundle/bundle.service.interface';
import { BundleGroupActions } from '../bundle-group';
import { BundlePersonActions } from '../bundle-person';
import { DalState } from '../dal.state.interface';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { HistoryActions } from '../history';
import { AddUnlockedContent } from '../unlocked-content/unlocked-content.actions';
import {
  AddBundle,
  BundlesActionTypes,
  BundlesLoaded,
  BundlesLoadError,
  DeleteBundles,
  LinkEduContent,
  LinkUserContent,
  LoadBundles,
  NavigateToBundleDetail,
  NavigateToBundleOverview,
  ShareBundle,
  StartAddBundle,
  StartSetAssignees,
  UpdateBundle,
  UpsertBundle,
} from './bundle.actions';

@Injectable()
export class BundlesEffects {
  loadBundles$ = createEffect(() =>
    this.actions.pipe(
      ofType(BundlesActionTypes.LoadBundles),
      concatLatestFrom(() => this.store.select(BundleQueries.getLoaded)),
      fetch({
        run: (action: LoadBundles, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.bundleService
            .getAllForUser(action.payload.userId)
            .pipe(map((bundles) => new BundlesLoaded({ bundles })));
        },
        onError: (action: LoadBundles, error) => {
          return new BundlesLoadError(error);
        },
      })
    )
  );

  linkEduContent$ = createEffect(() =>
    this.actions.pipe(
      ofType(BundlesActionTypes.LinkEduContent),
      pessimisticUpdate({
        run: (action: LinkEduContent) => {
          const { bundleId, eduContentIds } = action.payload;
          return this.bundleService.linkEduContent(bundleId, eduContentIds).pipe(
            switchMap((unlockedContents: UnlockedContentInterface[]) => {
              const actions: (AddEffectFeedback | AddUnlockedContent)[] = unlockedContents.map(
                (unlockedContent) => new AddUnlockedContent({ unlockedContent })
              );
              const effectFeedback = EffectFeedback.generateSuccessFeedback(
                this.uuid(),
                action,
                'Het lesmateriaal is aan de bundel toegevoegd.'
              );
              actions.push(new AddEffectFeedback({ effectFeedback }));
              return from(actions);
            })
          );
        },
        onError: (action: LinkEduContent, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het lesmateriaal aan de bundel toe te voegen.'
            ),
          });
        },
      })
    )
  );

  linkUserContent$ = createEffect(() =>
    this.actions.pipe(
      ofType(BundlesActionTypes.LinkUserContent),
      pessimisticUpdate({
        run: (action: LinkUserContent) => {
          const { bundleId, userContentIds } = action.payload;
          return this.bundleService.linkUserContent(bundleId, userContentIds).pipe(
            switchMap((unlockedContents: UnlockedContentInterface[]) => {
              const actions: (AddEffectFeedback | AddUnlockedContent)[] = unlockedContents.map(
                (unlockedContent) => new AddUnlockedContent({ unlockedContent })
              );
              const effectFeedback = EffectFeedback.generateSuccessFeedback(
                this.uuid(),
                action,
                'Het eigen lesmateriaal is aan de bundel toegevoegd.'
              );
              actions.push(new AddEffectFeedback({ effectFeedback }));
              return from(actions);
            })
          );
        },
        onError: (action: LinkUserContent, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het eigen lesmateriaal aan de bundel toe te voegen.'
            ),
          });
        },
      })
    )
  );

  startAddBundle$ = createEffect(() =>
    this.actions.pipe(
      ofType(BundlesActionTypes.StartAddBundle),
      pessimisticUpdate({
        run: (action: StartAddBundle) => {
          return this.bundleService.createBundle(action.payload.userId, action.payload.bundle).pipe(
            switchMap((bundle: BundleInterface) => {
              const actionsToDispatch: Action[] = [new AddBundle({ bundle })];

              if (action.payload.writeToHistory) {
                actionsToDispatch.push(
                  new HistoryActions.StartUpsertHistory({
                    history: {
                      name: bundle.name,
                      type: HistoryTypesEnum.BUNDLE,
                      bundleId: bundle.id,
                      created: new Date(),
                      learningAreaId: bundle.learningAreaId,
                    },
                  })
                );
              }

              if (action.payload.linkEduContent) {
                const { eduContentIds } = action.payload.linkEduContent;

                actionsToDispatch.push(
                  new BundleActions.LinkEduContent({
                    bundleId: bundle.id,
                    eduContentIds,
                  })
                );
              }

              if (action.payload.navigateAfterCreate) {
                actionsToDispatch.push(new NavigateToBundleDetail({ bundle }));
              }

              return from(actionsToDispatch);
            })
          );
        },
        onError: (action: StartAddBundle, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de bundel te maken.'
            ),
          });
        },
      })
    )
  );

  updateBundle$ = createEffect(() =>
    this.actions.pipe(
      ofType(BundlesActionTypes.UpdateBundle),
      optimisticUpdate({
        run: (action: UpdateBundle) => {
          return this.bundleService.updateBundle(action.payload.bundle.changes).pipe(
            map((update) => {
              return new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'De bundel werd bijgewerkt.'
                ),
              });
            })
          );
        },
        undoAction: (action: UpdateBundle, error: any) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de bundel bij te werken.'
            ),
          });
        },
      })
    )
  );

  shareBundle$ = createEffect(() =>
    this.actions.pipe(
      ofType(BundlesActionTypes.ShareBundle),
      optimisticUpdate({
        run: (action: ShareBundle) => {
          const { ownerId, bundleId, teacherIds, message } = action.payload;
          return this.bundleService.shareBundle(ownerId, bundleId, teacherIds, message).pipe(
            map(({ succeeded, errors }) => {
              let effectFeedback: EffectFeedback;
              if (succeeded.length) {
                effectFeedback = EffectFeedback.generateSuccessFeedback(this.uuid(), action, 'De bundel werd gedeeld.');
              } else if (errors.length) {
                effectFeedback = EffectFeedback.generateErrorFeedback(
                  this.uuid(),
                  action,
                  'Het is niet gelukt om de bundel te delen.'
                );
              }

              return new AddEffectFeedback({
                effectFeedback,
              });
            })
          );
        },
        undoAction: (action: ShareBundle, error: any) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de bundel te delen.'
            ),
          });
        },
      })
    )
  );

  startSetAssignees$ = createEffect(() =>
    this.actions.pipe(
      ofType(BundlesActionTypes.StartSetAssignees),
      pessimisticUpdate({
        run: (action: StartSetAssignees) => {
          const { bundleId, studentIds, groupIds, dateRange } = action.payload;
          return this.bundleService.setAssignees(bundleId, { groupIds, studentIds, dateRange }).pipe(
            switchMap(({ bundle, bundleGroups, bundlePersons, unlockedContentGroups, unlockedContentStudents }) => {
              const actionsToDispatch: Action[] = [
                new UpsertBundle({ bundle }),
                BundleGroupActions.updateBundleGroupsAccess({ bundleId, bundleGroups }),
                BundlePersonActions.updateBundlePersonsAccess({ bundleId, bundlePersons }),
              ];
              return from(actionsToDispatch);
            })
          );
        },
        onError: (action: StartSetAssignees, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de toegang aan te passen.'
            ),
          });
        },
      })
    )
  );

  deleteBundles$ = createEffect(() =>
    this.actions.pipe(
      ofType(BundlesActionTypes.DeleteBundles),
      optimisticUpdate({
        run: (action: DeleteBundles) => {
          return this.bundleService.deleteBundles(action.payload.ids).pipe(
            switchMap(() => {
              const feedBackAction = new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  `De ${getPlural(action.payload.ids, 'bundel werd', 'bundels werden')} verwijderd.`
                ),
              });

              const redirectAction = new NavigateToBundleOverview();
              return from([feedBackAction, redirectAction]);
            })
          );
        },
        undoAction: (action: DeleteBundles, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              `Het is niet gelukt om de ${getPlural(action.payload.ids, 'bundel', 'bundels')} te verwijderen.`
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  redirectToBundleOverview$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(BundlesActionTypes.NavigateToBundleOverview),
        tap((action: NavigateToBundleOverview) => {
          this.router.navigate(['bundles']);
        })
      ),
    { dispatch: false }
  );

  redirectToBundleDetail$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(BundlesActionTypes.NavigateToBundleDetail),
        tap((action: NavigateToBundleDetail) => {
          this.router.navigate(['bundles', action.payload.bundle.id]);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(BUNDLE_SERVICE_TOKEN) private bundleService: BundleServiceInterface,
    @Inject('uuid') private uuid: () => string,
    private router: Router
  ) {}
}

function getPlural(dependency: unknown[] | boolean, singular, plural) {
  const usePlural = Array.isArray(dependency) ? dependency.length !== 1 : dependency;
  return usePlural ? plural : singular;
}
