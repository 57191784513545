/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { EduContentProductTypeApi as SDKEduContentProductTypeApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { EduContentProductTypeInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class EduContentProductTypeApi extends ModelApiBase {
  private SDKEduContentProductTypeApi = inject(SDKEduContentProductTypeApi);

  constructor(api: PolpoApi) {
    super(api, 'EduContentProductTypes');
  }

  find(options?): Observable<EduContentProductTypeInterface[]> {
    return this.SDKEduContentProductTypeApi.find(...arguments);
  }
}
