import { inject, Injectable } from '@angular/core';
import { LearnosityApi, LearnosityItemsConfigInterface } from '@campus/dal';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivityPreviewService {
  private learnosityApi = inject(LearnosityApi);

  public getRequest(learnosityReference: string): Observable<LearnosityItemsConfigInterface> {
    return this.learnosityApi.previewActivity(learnosityReference);
  }
}
