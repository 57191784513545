import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { LearnerProfileConceptInterface } from '../../+models/LearnerProfileConcept.interface';
import { LearnerProfileConceptsActions, LearnerProfileConceptsActionTypes } from './learner-profile-concept.actions';

export const NAME = 'learnerProfileConcepts';

export interface State extends EntityState<LearnerProfileConceptInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
  loadedForCourse: Dictionary<boolean>;
}

export const adapter: EntityAdapter<LearnerProfileConceptInterface> =
  createEntityAdapter<LearnerProfileConceptInterface>({
    selectId: (learnerProfileConcept) => `${learnerProfileConcept.conceptGuid}-${learnerProfileConcept.personId}`,
  });

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForCourse: {},
});

export function reducer(state = initialState, action: LearnerProfileConceptsActions): State {
  switch (action.type) {
    case LearnerProfileConceptsActionTypes.AddLearnerProfileConcept: {
      return adapter.addOne(action.payload.learnerProfileConcept, state);
    }

    case LearnerProfileConceptsActionTypes.UpsertLearnerProfileConcept: {
      return adapter.upsertOne(action.payload.learnerProfileConcept, state);
    }

    case LearnerProfileConceptsActionTypes.AddLearnerProfileConcepts: {
      return adapter.addMany(action.payload.learnerProfileConcepts, state);
    }

    case LearnerProfileConceptsActionTypes.AddLearnerProfileConceptsForCourse: {
      const newState = adapter.upsertMany(action.payload.learnerProfileConcepts, state);

      return {
        ...newState,
        loadedForCourse: {
          ...newState.loadedForCourse,
          [action.payload.course]: true,
        },
      };
    }

    case LearnerProfileConceptsActionTypes.UpsertLearnerProfileConcepts: {
      return adapter.upsertMany(action.payload.learnerProfileConcepts, state);
    }

    case LearnerProfileConceptsActionTypes.UpdateLearnerProfileConcept: {
      return adapter.updateOne(action.payload.learnerProfileConcept, state);
    }

    case LearnerProfileConceptsActionTypes.UpdateLearnerProfileConcepts: {
      return adapter.updateMany(action.payload.learnerProfileConcepts, state);
    }

    case LearnerProfileConceptsActionTypes.DeleteLearnerProfileConcept: {
      return adapter.removeOne(action.payload.id, state);
    }

    case LearnerProfileConceptsActionTypes.DeleteLearnerProfileConcepts: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case LearnerProfileConceptsActionTypes.LearnerProfileConceptsLoaded: {
      return adapter.setAll(action.payload.learnerProfileConcepts, {
        ...state,
        loaded: true,
      });
    }

    case LearnerProfileConceptsActionTypes.LearnerProfileConceptsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case LearnerProfileConceptsActionTypes.ClearLearnerProfileConcepts: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
