import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SchoolApi } from '../+api';
import {
  PassportUserCredentialInterface,
  PersonInterface,
  SchoolProviderInterface,
  SchoolRoleMappingInterface,
} from '../+models';
import { SchoolProviderServiceInterface } from './school-provider.service.interface';

@Injectable({
  providedIn: 'root',
})
export class SchoolProviderService implements SchoolProviderServiceInterface {
  private httpService = inject(HttpClient);
  constructor(private schoolApi: SchoolApi) {}

  deleteSchoolProvider(schoolId: number, schoolProviderId: number): Observable<number[]> {
    return this.schoolApi
      .unlinkProvider(schoolId, schoolProviderId)
      .pipe(
        map(
          (result: { schoolProvider: SchoolProviderInterface; schoolRoleMappingIds: number[] }) =>
            result.schoolRoleMappingIds
        )
      );
  }

  createSchoolProvider(
    schoolId: number,
    schoolProvider: SchoolProviderInterface
  ): Observable<{
    schoolProvider: SchoolProviderInterface;
    credentials: PassportUserCredentialInterface[];
    schoolRoleMappings: SchoolRoleMappingInterface[];
    persons: PersonInterface[];
  }> {
    return this.schoolApi.linkProvider(schoolId, schoolProvider) as Observable<{
      schoolProvider: SchoolProviderInterface;
      credentials: PassportUserCredentialInterface[];
      schoolRoleMappings: SchoolRoleMappingInterface[];
      persons: PersonInterface[];
    }>;
  }

  updateProvider(schoolProvider: SchoolProviderInterface): Observable<SchoolProviderInterface> {
    const url = `/api/SchoolProviders/${schoolProvider.id}/update`;
    return this.httpService.put<SchoolProviderInterface>(url, schoolProvider, { withCredentials: true });
  }

  syncProvider(schoolProviderId: number): Observable<any> {
    const url = `/api/SchoolProviders/${schoolProviderId}/sync`;
    return this.httpService.post<any>(url, undefined, { withCredentials: true });
  }

  testConnection(schoolProvider: SchoolProviderInterface): Observable<{ connected: boolean }> {
    const url = `/api/SchoolProviders/test-connection`;
    return this.httpService.post<{ connected: boolean }>(url, schoolProvider, { withCredentials: true });
  }

  createNewSchoolProvider(schoolProvider: SchoolProviderInterface): Observable<SchoolProviderInterface> {
    const url = `/api/SchoolProviders/create`;

    return this.httpService.post<SchoolProviderInterface>(url, schoolProvider, { withCredentials: true });
  }

  deleteNewSchoolProvider(schoolProviderId: number): Observable<number> {
    const url = `/api/SchoolProviders/${schoolProviderId}/delete`;

    return this.httpService.delete<number>(url, { withCredentials: true });
  }
}
