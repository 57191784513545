import {
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';

export enum SectionModeEnum {
  STATIC, // no hover effects, no click triggers
  EDITABLE, // elevation/actions hover effect, click triggers EDITING mode
  EDITING, // elevated, actions visible
  HOVERABLE, // elevation/actions hover effect
  CONTAINER, // same as static, other styling
}

/**
 * Title of a section, needed as it's used as a selector in the API.
 * @docs-private
 */
@Directive({
  selector: '[campusSectionTitle], [sectionTitle], section-title',
})
export class SectionTitleDirective {
  @HostBinding('class')
  hasSectionTitleClass = 'ui-section__title ui-text-ellipsis';
}

/**
 * Content of a section, needed as it's used as a selector in the API.
 * @docs-private
 */
@Directive({
  selector: '[campusSectionContent], [sectionContent], section-content',
})
export class SectionContentDirective {
  @HostBinding('attr.data-cy')
  dataCy = 'content';
}

/**
 * Actions of a section, needed as it's used as a selector in the API.
 * @docs-private
 */
@Directive({
  selector: '[campusSectionActions], [sectionActions], section-actions',
})
export class SectionActionsDirective {}

/**
 * FAB of a section, needed as it's used as a selector in the API.
 * @docs-private
 */
@Directive({
  selector: '[campusSectionFabContainer], [sectionFabContainer], section-fab-container',
})
export class SectionFabContainerDirective {
  @HostBinding('class.ui-section__fab-container')
  hasUiSectionFabContainer = true;
}

/**
 * Footer Actions of a section, needed as it's used as a selector in the API.
 * @docs-private
 */
@Directive({
  selector: '[campusFooterSectionActions], [sectionFooterActions], section-footer-actions',
})
export class SectionFooterActionsDirective {
  @HostBinding('class.ui-section__footer__actions')
  footerActionsClass = true;
}

@Component({
  selector: 'campus-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent {
  @Input() class = '';

  @HostBinding('class')
  get modeClass() {
    const classes = this.class.split(' ');
    classes.push('ui-section');
    const keys = Object.keys(SectionModeEnum).filter((x) => !(Number.parseInt(x, 10) >= 0));
    if (keys[this.mode]) {
      const mode = keys[this.mode].toLowerCase();
      classes.push(`ui-section--${mode}`);
    }

    if (this.disabled) {
      classes.push('ui-section--disabled');
    }
    if (!!this.sectionFab) {
      classes.push('ui-section--has-fab');
    }
    return classes.join(' ');
  }

  @Input()
  mode: SectionModeEnum = SectionModeEnum.STATIC;

  @Input() disabled: boolean;

  @Output() modeChange = new EventEmitter<SectionModeEnum>();
  @Output() actionClick = new EventEmitter<void>();

  modes = SectionModeEnum; // needed for usage in the template

  @ContentChild(SectionTitleDirective, { static: true })
  sectionTitle: ElementRef;

  @ContentChild(SectionFabContainerDirective, { static: true })
  sectionFab: ElementRef;

  constructor() {}

  @HostListener('click')
  clickSection() {
    if (!this.disabled && this.mode === SectionModeEnum.EDITABLE) {
      this.updateMode(SectionModeEnum.EDITING);
    }
  }

  clickAction(event: MouseEvent) {
    event.stopPropagation(); // this should not trigger the click section event
    if (this.disabled) {
      return;
    }

    if (this.mode === SectionModeEnum.EDITABLE) {
      this.updateMode(SectionModeEnum.EDITING);
    } else if (this.mode === SectionModeEnum.STATIC || this.mode === SectionModeEnum.HOVERABLE) {
      this.actionClick.emit();
    }
  }

  private updateMode(mode: SectionModeEnum) {
    this.mode = mode;
    this.modeChange.emit(this.mode);
  }
}
