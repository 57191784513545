import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { WINDOW } from '@campus/browser';
import { MapObjectConversionService } from '@campus/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EduContentApi, EduContentBookApi, PersonApi } from '../+api';
import { SearchResultInterface, SearchStateInterface, TableApiResponseInterface } from '../+external-interfaces';
import { EduContentMetadataInterface } from '../+models';
import { EduContentInterface, PrintSize } from '../+models/EduContent.interface';
import { DalOptions, DAL_OPTIONS } from '../../lib/dal.module';
import {
  EduContentDraftInterface,
  EduContentServiceInterface,
  ErrorResponseInterface,
  InstructionContentInterface,
  SuccessResponseInterface,
  UpdateDraftRelationChangesInterface,
} from './edu-content.service.interface';

@Injectable({
  providedIn: 'root',
})
export class EduContentService implements EduContentServiceInterface {
  private basePath: string;

  constructor(
    private personApi: PersonApi,
    private eduContentApi: EduContentApi,

    // not used in INK
    @Optional() private mapObjectConversionService: MapObjectConversionService,

    private bookApi: EduContentBookApi,
    @Inject(DAL_OPTIONS) private dalOptions: DalOptions,
    @Inject(WINDOW) private window: Window,
    private http: HttpClient
  ) {
    this.basePath = dalOptions.apiBaseUrl + '/api/educontents/';
  }

  getAllForUser(userId: number): Observable<EduContentInterface[]> {
    return this.personApi
      .getData(userId, 'eduContents')
      .pipe(map((res: { eduContents: EduContentInterface[] }) => res.eduContents));
  }

  getAllForBook(bookId: number): Observable<EduContentInterface[]> {
    return this.bookApi
      .getData(bookId, 'eduContents')
      .pipe(map((res: { eduContents: EduContentInterface[] }) => res.eduContents));
  }

  printEduContent(
    eduContentId: number,
    ufpId?: number,
    taskId?: number,
    withNames = true,
    size: PrintSize = 'A5'
  ): void {
    const url = `${this.basePath}paper-exercise-pdf?eduContentId=${eduContentId}&withNames=${withNames}&size=${size}`;
    if (ufpId) {
      this.window.open(`${url}&ufpId=${ufpId}`);
    } else if (taskId) {
      this.window.open(`${url}&taskId=${taskId}`);
    } else {
      this.window.open(url);
    }
  }

  printCombinedEduContents(eduContentIds: number[], withSolutions?: boolean, size: PrintSize = 'A5'): void {
    const url = withSolutions ? 'combined-paper-solutions-pdf' : 'combined-paper-exercises-pdf';
    this.window.open(`${this.basePath}${url}?eduContentIds=[${eduContentIds.join(',')}]&size=${size}`);
  }

  getLatestEduContents(): Observable<EduContentDraftInterface[]> {
    return this.eduContentApi.getLatestEduContents();
  }

  getMyEduContents(): Observable<EduContentDraftInterface[]> {
    return this.eduContentApi.getMyEduContents();
  }

  getGeneralEduContentForBookId(bookId: number): Observable<EduContentInterface[]> {
    return this.eduContentApi.getGeneralEduContentForBookId(bookId);
  }

  getEduContents<T = EduContentMetadataInterface>(
    searchTerm?: string,
    filters?: { [key: string]: string | number | number[] },
    pagination?: { from?; amount? },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<TableApiResponseInterface<T>> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/EduContents/v2/filtered`;
    const params = new HttpParams({
      fromObject: {
        searchTerm: searchTerm || '',
        filters: JSON.stringify(filters),
        columns: JSON.stringify(columns),
        sorting: JSON.stringify(sorting),
        pagination: JSON.stringify(pagination),
      },
    });

    return this.http.get<TableApiResponseInterface<T>>(url, {
      params,
      withCredentials: true,
    });
  }

  getInstructionContentsForMethod(
    methodId: number
  ): Observable<{ results: InstructionContentInterface[]; count: number }> {
    const url = `${this.basePath}instruction-contents-for-method`;

    const params = new HttpParams({
      fromString: `methodId=${methodId}`,
    });

    return this.http.get<{ results: InstructionContentInterface[]; count: number }>(url, {
      params,
      withCredentials: true,
    });
  }

  search(state: SearchStateInterface): Observable<SearchResultInterface> {
    return this.eduContentApi
      .search({
        ...state,
        filterCriteriaSelections: this.mapObjectConversionService.mapToObject(state.filterCriteriaSelections),
        filterCriteriaOptions: this.mapObjectConversionService.mapToObject(state.filterCriteriaOptions),
      })
      .pipe(
        map(
          (res: {
            count: number;
            results: any[];
            filterCriteriaPredictions: {
              [key: string]: { [key: number]: number };
            };
          }) => {
            const returnValue: SearchResultInterface = {
              ...res,
              filterCriteriaPredictions: this.mapObjectConversionService.objectToMap(
                res.filterCriteriaPredictions,
                false, // first map key type is string
                true, // we want to convert first map value to map as wel
                true // second map key type is number
              ),
            };

            return returnValue;
          }
        )
      );
  }
  autoComplete(state: SearchStateInterface): Observable<string[]> {
    return this.eduContentApi.autocomplete({
      ...state,
      filterCriteriaSelections: this.mapObjectConversionService.mapToObject(state.filterCriteriaSelections),
      filterCriteriaOptions: this.mapObjectConversionService.mapToObject(state.filterCriteriaOptions),
    });
  }

  patchEduContent(eduContentId: number, changes?: Partial<EduContentDraftInterface>): Observable<number> {
    return this.eduContentApi.updateEduContentDraft(eduContentId, { changes }) as Observable<number>;
  }

  bulkUpdateEduContents(
    ids: number[],
    personPreferenceId: number,
    fields: string[] = [],
    changes: { [key: string]: any },
    options?: { [key: string]: any }
  ) {
    const url = `${this.basePath}draft/bulk-update`;
    return this.http.patch(url, { ids, personPreferenceId, fields, changes, options }, { withCredentials: true });
  }

  getEduContentDraft(eduContentId: number): Observable<EduContentDraftInterface> {
    return this.eduContentApi.getEduContentDraft(eduContentId) as Observable<EduContentDraftInterface>;
  }

  getPublishedEduContent(eduContentId: number): Observable<EduContentDraftInterface[]> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/Educontents/get-published-versions/${eduContentId}`;
    return this.http.get<EduContentDraftInterface[]>(url, { withCredentials: true });
  }

  updateEduContentDraftRelation(eduContentId: number, body: UpdateDraftRelationChangesInterface): Observable<number> {
    return this.eduContentApi.updateEduContentDraftRelation(eduContentId, body);
  }

  applyTemplateToEduContentDraft(eduContentId: number, personPreferenceId: number) {
    const url = `${this.basePath}${eduContentId}/draft/template`;
    return this.http.patch<number>(url, { personPreferenceId }, { withCredentials: true });
  }

  createEduContentDraft(personPreferenceId?: number, eduContentBookId?: number, eduContentId?: number) {
    const url = `${this.basePath}draft`;
    return this.http.post<number>(
      url,
      { personPreferenceId, eduContentBookId, eduContentId },
      { withCredentials: true }
    );
  }

  publishEduContentDrafts(eduContentIds: number[], message?: string) {
    const url = `${this.basePath}publish-v2`;

    const body = {
      ids: eduContentIds,
      message,
    };

    return this.http.put(url, body, { withCredentials: true });
  }

  unlockDraft(eduContentId: number) {
    const url = `${this.basePath}${eduContentId}/draft/unlock`;
    return this.http.patch<number>(url, null, { withCredentials: true });
  }

  unpublishEduContent(eduContentIds: number[], ignoreInUse = false) {
    const url = `${this.basePath}unpublish-v2`;

    const body = {
      ids: eduContentIds,
      ignoreInUse,
    };

    return this.http.put<number[]>(url, body, { withCredentials: true });
  }

  removeEduContent(eduContentIds: number[], ignoreInUse = false) {
    const url = `${this.basePath}remove-v2`;

    const body = {
      ids: eduContentIds,
      ignoreInUse,
    };
    //DELETE DOESN'T SUPPORT BODY SO USE POST INSTEAD
    return this.http.post<{ errored: ErrorResponseInterface[]; resolved: SuccessResponseInterface[] }>(url, body, {
      withCredentials: true,
    });
  }

  updateQuestions(eduContentId: number, activityItems: string[]): Observable<void> {
    const url = `${this.basePath}${eduContentId}/learnosity-questions/sync`;

    return this.http.post<void>(url, { activityItems }, { withCredentials: true });
  }
}
