import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Inject, NgModule } from '@angular/core';
import { ErrorHandler, LoopBackAuth, LoopBackConfig, SDKBrowserModule } from '@diekeure/polpo-api-angular-sdk';
import { DAL_OPTIONS } from '../dal.module';
import { ApiAuth } from './internals/auth';

@NgModule({
  imports: [CommonModule, HttpClientModule, SDKBrowserModule.forRoot()],
  declarations: [],
  exports: [],
  providers: [ErrorHandler, ApiAuth, { provide: LoopBackAuth, useExisting: ApiAuth }],
})
class ApiModule {
  constructor(@Inject(DAL_OPTIONS) options) {
    LoopBackConfig.setBaseURL(options.apiBaseUrl);
    LoopBackConfig.setRequestOptionsCredentials(true);
  }
}

export { ApiModule };
