import { Action } from '@ngrx/store';
import { ContextInterface, LoopLearnerStatusInterface } from '../../+models';

export enum LoopLearnerStatusesActionTypes {
  LoadLoopLearnerStatuses = '[LoopLearnerStatuses] Load LoopLearnerStatuses',
  LoadLoopLearnerStatusesForToc = '[LoopLearnerStatuses] Load LoopLearnerStatuses for toc',
  LoadLoopLearnerStatus = '[LoopLearnerStatuses] Load LoopLearnerStatus',
  LoopLearnerStatusesLoaded = '[LoopLearnerStatuses] LoopLearnerStatuses Loaded',
  LoopLearnerStatusLoaded = '[LoopLearnerStatuses] LoopLearnerStatus Loaded',
  LoopLearnerStatusesLoadError = '[LoopLearnerStatuses] Load Error',
  LoopLearnerStatusLoadError = '[LoopLearnerStatuses] Load Error',
  UpsertLoopLearnerStatuses = '[LoopLearnerStatuses] Upsert LoopLearnerStatuses',
  UpsertLoopLearnerStatus = '[LoopLearnerStatuses] Upsert one LoopLearnerStatus',
  AddLoopLearnerStatusesForBook = '[LoopLearnerStatusesForBook] Add LoopLearnerStatuses for Book',
  AddLoopLearnerStatusesForTocs = '[LoopLearnerStatusesForBook] Add LoopLearnerStatuses for tocs',
}

export class LoadLoopLearnerStatuses implements Action {
  readonly type = LoopLearnerStatusesActionTypes.LoadLoopLearnerStatuses;

  constructor(public payload: { force?: boolean; bookId: number; setLoadedOnError?: boolean } = { bookId: null }) {}
}

export class LoadLoopLearnerStatus implements Action {
  readonly type = LoopLearnerStatusesActionTypes.LoadLoopLearnerStatus;

  constructor(public payload: { userId: number; context: ContextInterface } = { userId: null, context: null }) {}
}

export class LoopLearnerStatusesLoaded implements Action {
  readonly type = LoopLearnerStatusesActionTypes.LoopLearnerStatusesLoaded;

  constructor(public payload: { loopLearnerStatuses: LoopLearnerStatusInterface[] }) {}
}
export class LoopLearnerStatusLoaded implements Action {
  readonly type = LoopLearnerStatusesActionTypes.LoopLearnerStatusLoaded;

  constructor(public payload: { loopLearnerStatus: LoopLearnerStatusInterface }) {}
}
export class LoopLearnerStatusLoadedForTocs implements Action {
  readonly type = LoopLearnerStatusesActionTypes.LoopLearnerStatusLoaded;

  constructor(public payload: { loopLearnerStatus: LoopLearnerStatusInterface }) {}
}
export class LoadLoopLearnerStatusesForToc implements Action {
  readonly type = LoopLearnerStatusesActionTypes.LoadLoopLearnerStatusesForToc;

  constructor(public payload: { tocId: number[] }) {}
}

export class LoopLearnerStatusesLoadError implements Action {
  readonly type = LoopLearnerStatusesActionTypes.LoopLearnerStatusesLoadError;
  constructor(public payload: any) {}
}
export class LoopLearnerStatusLoadError implements Action {
  readonly type = LoopLearnerStatusesActionTypes.LoopLearnerStatusLoadError;
  constructor(public payload: any) {}
}

export class UpsertLoopLearnerStatuses {
  readonly type = LoopLearnerStatusesActionTypes.UpsertLoopLearnerStatuses;

  constructor(
    public payload: {
      loopLearnerStatuses: LoopLearnerStatusInterface[];
    }
  ) {}
}
export class UpsertLoopLearnerStatus {
  readonly type = LoopLearnerStatusesActionTypes.UpsertLoopLearnerStatus;

  constructor(
    public payload: {
      loopLearnerStatus: LoopLearnerStatusInterface;
    }
  ) {}
}
export class AddLoopLearnerStatusesForBook implements Action {
  readonly type = LoopLearnerStatusesActionTypes.AddLoopLearnerStatusesForBook;

  constructor(public payload: { bookId: number; loopLearnerStatuses: LoopLearnerStatusInterface[] }) {}
}
export class AddLoopLearnerStatusesForToc implements Action {
  readonly type = LoopLearnerStatusesActionTypes.AddLoopLearnerStatusesForTocs;

  constructor(public payload: { tocId: number; loopLearnerStatuses: LoopLearnerStatusInterface[] }) {}
}

export type LoopLearnerStatusesActions =
  | LoadLoopLearnerStatuses
  | LoadLoopLearnerStatus
  | LoopLearnerStatusLoaded
  | UpsertLoopLearnerStatuses
  | UpsertLoopLearnerStatus
  | AddLoopLearnerStatusesForBook
  | AddLoopLearnerStatusesForToc
  | LoopLearnerStatusesLoaded
  | LoopLearnerStatusesLoadError
  | LoopLearnerStatusLoadError
  | LoadLoopLearnerStatusesForToc;
