import { ApiEnvironment } from './api-environment.functions';

export interface DateAddInterface {
  days?: number;
  weeks?: number;
  months?: number;
  years?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
}

export interface DateRangeInterface {
  start: Date;
  end: Date;
}

export class DateFunctions {
  public static startOfWeek(date, weekStartDay = 1) {
    const copy = new Date(date);
    const diff = copy.getDate() - ((copy.getDay() - weekStartDay + 7) % 7);
    copy.setDate(diff);
    copy.setHours(0, 0, 0, 0);

    return copy;
  }

  public static nextWeek(date, weekStartDay = 1) {
    const copy = DateFunctions.startOfWeek(date, weekStartDay);
    copy.setDate(copy.getDate() + 7);
    copy.setHours(0, 0, 0, 0);

    return copy;
  }

  public static lastWeek(date, weekStartDay = 1) {
    const copy = DateFunctions.startOfWeek(date, weekStartDay);
    copy.setDate(copy.getDate() - 7);
    copy.setHours(0, 0, 0, 0);

    return copy;
  }

  public static endOfWeek(date, weekStartDay = 1) {
    const copy = DateFunctions.nextWeek(date, weekStartDay);
    copy.setDate(copy.getDate() - 1);
    copy.setHours(23, 59, 59, 0);

    return copy;
  }

  // return start date of schoolyear, using schoolyear (as opposed to a date as in getSchoolYearBoundaries)
  public static getSchoolYearStart(schoolYear: number) {
    const { day, month } = ApiEnvironment.get().schoolYearBoundaries.schoolYearStart;

    return new Date(schoolYear, month, day, 0, 0, 0, 0);
  }

  // return start- and endDate of schoolyear
  public static getSchoolYearBoundaries(date: Date, startYear?: number): DateRangeInterface {
    startYear = startYear || DateFunctions.getSchoolYear(date);
    const apiEnvironment = ApiEnvironment.get();

    return {
      start: new Date(
        startYear,
        apiEnvironment.schoolYearBoundaries.schoolYearStart.month,
        apiEnvironment.schoolYearBoundaries.schoolYearStart.day
      ),
      end: new Date(
        startYear + 1,
        apiEnvironment.schoolYearBoundaries.schoolYearEnd.month,
        apiEnvironment.schoolYearBoundaries.schoolYearEnd.day
      ),
    };
  }

  public static fromStartOfSchoolYear(date: Date = new Date()): DateRangeInterface {
    const startYear = DateFunctions.getSchoolYear(date);
    const apiEnvironment = ApiEnvironment.get();

    return {
      start: new Date(
        startYear,
        apiEnvironment.schoolYearBoundaries.schoolYearStart.month,
        apiEnvironment.schoolYearBoundaries.schoolYearStart.day
      ),
      end: DateFunctions.midnight(date),
    };
  }

  public static rangeWithinSchoolYear(
    dateRange: {
      start: Date;
      end: Date;
    },
    today: Date = new Date()
  ): DateRangeInterface {
    const { start: startSchoolYear, end: endSchoolYear } = DateFunctions.getSchoolYearBoundaries(today);

    if (dateRange.end < startSchoolYear || dateRange.start > endSchoolYear) {
      return null;
    }

    const adjustedStart = dateRange.start < startSchoolYear ? startSchoolYear : new Date(dateRange.start);
    const adjustedEnd = dateRange.end > endSchoolYear ? endSchoolYear : new Date(dateRange.end);

    return { start: adjustedStart, end: adjustedEnd };
  }

  public static getOrderPeriodYear(): number {
    return ApiEnvironment.get().orderPeriod.orderStart.getFullYear();
  }

  public static getSchoolYear(date: Date): number {
    const apiEnvironment = ApiEnvironment.get();

    const yearModifier = +(
      date.getMonth() < apiEnvironment.schoolYearBoundaries.schoolYearEnd.month ||
      (date.getMonth() === apiEnvironment.schoolYearBoundaries.schoolYearEnd.month &&
        date.getDate() <= apiEnvironment.schoolYearBoundaries.schoolYearEnd.day)
    );
    const startYear = date.getFullYear() - yearModifier;

    return startYear;
  }

  public static getSchoolYearFromTransitionPeriod(): number {
    const apiEnvironment = ApiEnvironment.get();

    return apiEnvironment.yearTransitionBoundaries.start.getFullYear();
  }

  public static tomorrow(today: Date | number): Date {
    const copy = new Date(today);
    copy.setDate(copy.getDate() + 1);
    return copy;
  }

  public static midnight(today: Date | number): Date {
    const copy = new Date(today);
    copy.setHours(0, 0, 0, 0);
    return copy;
  }

  public static dateAdd(date: Date | number, dateParts: DateAddInterface) {
    const copy = new Date(date);

    if (dateParts.days) {
      copy.setDate(copy.getDate() + dateParts.days);
    }
    if (dateParts.weeks) {
      copy.setDate(copy.getDate() + dateParts.weeks * 7);
    }
    if (dateParts.months) {
      copy.setMonth(copy.getMonth() + dateParts.months);
    }
    if (dateParts.years) {
      copy.setFullYear(copy.getFullYear() + dateParts.years);
    }
    if (dateParts.hours) {
      copy.setHours(copy.getHours() + dateParts.hours);
    }
    if (dateParts.minutes) {
      copy.setMinutes(copy.getMinutes() + dateParts.minutes);
    }
    if (dateParts.seconds) {
      copy.setSeconds(copy.getSeconds() + dateParts.seconds);
    }
    if (dateParts.milliseconds) {
      copy.setMilliseconds(copy.getMilliseconds() + dateParts.milliseconds);
    }

    return copy;
  }

  public static toLongDateString(date: Date): string {
    return date.toLocaleDateString('nl-BE', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }

  public static toShortDateString(date: Date): string {
    return date.toLocaleDateString('nl-BE', {
      month: 'short',
      day: 'numeric',
    });
  }

  public static timeOfDayGreeting(date: Date): string {
    const time = date.getHours();
    const greetingsByMaxTime = {
      6: 'goeienacht',
      12: 'goeiemorgen',
      13: 'goeiemiddag',
      18: 'goeienamiddag',
      23: 'goeienavond',
    };

    const maxTime = Object.keys(greetingsByMaxTime).find((key) => time < +key) || 6;
    return greetingsByMaxTime[maxTime];
  }

  public static lastWeeksDateRange(weeks: number, date: Date | number = new Date()): DateRangeInterface {
    // Note: differing behavior here:
    // - weeks 1 -> up to the end of the week 23:59:59
    // - weeks * -> up to, but not including, today 00:00:00
    const end = weeks === 1 ? DateFunctions.endOfWeek(date) : DateFunctions.midnight(date);
    // weeks-1 because the first week implies the current week
    const start = DateFunctions.startOfWeek(DateFunctions.dateAdd(end, { weeks: -(weeks - 1) }));

    const range = DateFunctions.rangeWithinSchoolYear({
      start,
      end,
    }) || { start, end }; // fallback if schoolyear range falls completely outside of the schoolyear

    return range;
  }

  public static lifetimeExpireDate(): Date {
    //Returns 31st of august 2040 and is used as expiredate for lifetime accesses
    return new Date(2040, 7, 31);
  }

  public static dateRange(from: Date, to: Date, interval: DateAddInterface = { days: 1 }): Date[] {
    const range = [from];

    let nextDate = DateFunctions.dateAdd(from, interval);
    while (nextDate <= to) {
      range.push(nextDate);
      nextDate = DateFunctions.dateAdd(nextDate, interval);
    }

    return range;
  }

  public static yearRange(from: Date, to: Date): number[] {
    const fromYear = this.getSchoolYear(from);
    const toYear = this.getSchoolYear(to);

    return Array.from({ length: toYear - fromYear + 1 }, (_, i) => fromYear + i);
  }

  public static rangeIntersects(
    range1: DateRangeInterface,
    range2: DateRangeInterface,
    compare: 'time' | 'day' = 'day'
  ) {
    const r1Start = compare === 'day' ? DateFunctions.midnight(range1.start) : range1.start;
    const r1End = compare === 'day' ? DateFunctions.midnight(range1.end) : range1.end;
    const r2Start = compare === 'day' ? DateFunctions.midnight(range2.start) : range2.start;
    const r2End = compare === 'day' ? DateFunctions.midnight(range2.end) : range2.end;

    return r1End >= r2Start && r1Start <= r2End;
  }

  public static dateIsInRange(date: Date, range: DateRangeInterface, compare: 'time' | 'day' = 'day') {
    const resultDate = DateFunctions.midnight(date);
    const rangeStart = compare === 'day' ? DateFunctions.midnight(range.start) : range.start;
    const rangeEnd = compare === 'day' ? DateFunctions.midnight(range.end) : range.end;

    return resultDate >= rangeStart && resultDate <= rangeEnd;
  }

  public static findMostFrequentDateRange(
    dateRanges: DateRangeInterface[],
    normalizeDates = false
  ): DateRangeInterface {
    if (normalizeDates) dateRanges = dateRanges.map(DateFunctions.normalizeDateRange);
    // reduce date ranges to object containing
    // most frequently used start- and endDates
    const boundaryFrequencies = dateRanges.reduce(
      (acc, { start, end }) => {
        const startMs = start?.getTime();
        const endMs = end?.getTime();

        if (!acc.start.has(startMs)) {
          acc.start.set(startMs, 1);
        } else {
          acc.start.set(startMs, acc.start.get(startMs) + 1);
        }

        if (!acc.end.has(endMs)) {
          acc.end.set(endMs, 1);
        } else {
          acc.end.set(endMs, acc.end.get(endMs) + 1);
        }

        return acc;
      },
      { start: new Map<number, number>(), end: new Map<number, number>() }
    );

    const mostFrequentStartDate = new Date(getMostFrequent(boundaryFrequencies.start));
    const mostFrequentEndDate = new Date(getMostFrequent(boundaryFrequencies.end));

    return {
      start: mostFrequentStartDate,
      end: mostFrequentEndDate,
    };
  }

  public static normalizeDateRange(dateRange: DateRangeInterface): DateRangeInterface {
    const { start, end } = dateRange;

    // ensure start is at 00:00 and end is at 23:59
    const normalizedDateInterval = {
      start: start ? new Date(start) : null,
      end: end ? new Date(end) : null,
    };
    normalizedDateInterval.start?.setHours(0, 0, 0, 0);
    normalizedDateInterval.end?.setHours(23, 59, 59, 0);

    return normalizedDateInterval;
  }

  public static compareDateRanges(dateRangeA: DateRangeInterface, dateRangeB: DateRangeInterface): boolean {
    return (
      dateRangeA?.start?.getTime() === dateRangeB?.start?.getTime() &&
      dateRangeA?.end?.getTime() === dateRangeB?.end?.getTime()
    );
  }
}

function getMostFrequent(dateMap: Map<number, number>) {
  let maxValue = 0;
  let maxKey: number;

  dateMap.forEach((value, key) => {
    if (value > maxValue) {
      maxValue = value;
      maxKey = key;
    }
  });

  return maxKey;
}
