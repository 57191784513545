import { Location } from '@angular/common';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '@campus/browser';
import {
  DalState,
  EduContentHighScoreActions,
  EduContentHighScoreService,
  EduContentQueries,
  LearningEventVerbEnum,
  LearningRecordActions,
  LearningRecordInterface,
  RegistrationInterface,
  RegistrationService,
  RegistrationTypeEnum,
} from '@campus/dal';
import { select, Store } from '@ngrx/store';
import { forkJoin, fromEvent, Observable, Subscription } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import {
  GameCompletedMessage,
  GameExerciseMessage,
  GameLearningEventsServiceInterface,
  GameLoadedMessage,
  GameMessage,
  GameMessageType,
  GameOpenInstructionsMessage,
  GameRestartedMessage,
  GameWindowClosedMessage,
} from './game-learning-events.service.interface';
export interface RegistrationWithHighScoreInterface extends RegistrationInterface {
  highScore: number;
}

@Injectable({
  providedIn: 'root',
})
export class GameLearningEventsService implements GameLearningEventsServiceInterface, OnDestroy {
  private gameMessageTypes: Set<string>;
  private gameMessages$: Observable<GameMessage>;

  private subscriptions = new Subscription();

  private window = inject(WINDOW);
  private registrationService = inject(RegistrationService);
  private store: Store<DalState> = inject(Store);
  private eduContentHighScoreService = inject(EduContentHighScoreService);
  private router = inject(Router);
  private location = inject(Location);

  constructor() {
    this.gameMessageTypes = new Set(Object.values(GameMessageType));

    this.setupStreams();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  registerEventListener() {
    this.subscriptions.add(this.gameMessages$.subscribe((gameMessage) => this.handleGameMessage(gameMessage)));
  }

  private setupStreams() {
    this.gameMessages$ = fromEvent(this.window, 'message').pipe(
      filter((event: MessageEvent) => this.validateGameMessage(event)),
      map((event: MessageEvent) => {
        return {
          ...event.data,
          sourceWindow: event.source,
        };
      })
    );
  }

  private handleGameMessage(message: GameMessage) {
    switch (message.type) {
      case GameMessageType.GAME_LOADED:
        return this.handleGameLoadedMessage(message);
      case GameMessageType.GAME_RESTARTED:
        return this.handleGameRestartedMessage(message);
      case GameMessageType.GAME_COMPLETED:
        return this.handleGameCompletedMessage(message);
      case GameMessageType.GAME_EXERCISE:
        return this.handleGameExerciseMessage(message);
      case GameMessageType.GAME_WINDOW_CLOSED:
        return this.handleGameWindowClosedMessage(message);
      case GameMessageType.GAME_OPEN_INSTRUCTIONS:
        return this.handleGameOpenInstructionsMessage(message);
      default:
        console.warn('[GameLearningEventsService] handleGameMessage: unknown message type', message);
    }
  }

  private handleGameLoadedMessage(message: GameLoadedMessage) {
    this.replyWithRegistration(message).subscribe((registration) => {
      this.store.dispatch(
        new LearningRecordActions.SaveLearningRecord({
          learningRecord: {
            registrationId: registration.id,
            verb: LearningEventVerbEnum.EXPERIENCED,
            duration: 0,
            eduContentId: registration.contentId,
            completion: false,
          },
        })
      );
    });
  }

  private handleGameRestartedMessage(message: GameRestartedMessage) {
    this.replyWithRegistration(message).subscribe((registration) => {
      this.store.dispatch(
        new LearningRecordActions.SaveLearningRecord({
          learningRecord: {
            registrationId: registration.id,
            verb: LearningEventVerbEnum.EXPERIENCED,
            duration: 0,
            eduContentId: registration.contentId,
            completion: false,
          },
        })
      );
    });
  }

  private handleGameCompletedMessage(message: GameCompletedMessage) {
    this.store.dispatch(
      new EduContentHighScoreActions.UpdateEduContentHighScore({
        score: message.data.score,
        eduContentId: message.data.eduContentId,
      })
    );
    this.store.dispatch(
      new LearningRecordActions.SaveLearningRecord({
        learningRecord: {
          registrationId: message.data.registrationId,
          verb: LearningEventVerbEnum.COMPLETED,
          score: Math.round((message.data.correctExercises / message.data.totalExercises) * 100),
          rawScore: message.data.correctExercises,
          maxScore: message.data.totalExercises,
          duration: message.data.playTime,
          eduContentId: message.data.eduContentId,
          completion: true,
          earnedKwetons: message.data.kwetonCount,
        } as LearningRecordInterface,
      })
    );
  }

  private handleGameExerciseMessage(message: GameExerciseMessage) {
    this.store.dispatch(
      new LearningRecordActions.SaveLearningRecord({
        learningRecord: {
          registrationId: message.data.registrationId,
          verb: LearningEventVerbEnum.ANSWERED,
          duration: message.data.playTime,
          eduContentId: message.data.eduContentId,
          completion: false,
          success: message.data.correctAnswer,
        },
      })
    );
  }

  private handleGameWindowClosedMessage(message: GameWindowClosedMessage) {
    // nothing for now
  }

  private handleGameOpenInstructionsMessage(message: GameOpenInstructionsMessage) {
    const { eduContentId } = message.data;

    this.store
      .pipe(select(EduContentQueries.getById(eduContentId)))
      .pipe(take(1))
      .subscribe((eduContent) => {
        const { artifactId } = eduContent.publishedEduContentMetadata;

        const path = this.router.createUrlTree(['/content', 'instructions', artifactId]).toString();
        const fullPath = this.location.prepareExternalUrl(path);
        const url = location.origin + fullPath;

        this.replyToGameMessage(message, {
          type: GameMessageType.GAME_OPEN_URL,
          data: {
            url,
          },
        });
      });
  }

  private replyToGameMessage(sourceMessage: GameMessage, message: GameMessage) {
    sourceMessage.sourceWindow.postMessage(message, '*');
  }

  private replyWithRegistration(sourceMessage: GameLoadedMessage | GameRestartedMessage) {
    return this.createNewRegistration(sourceMessage.data.eduContentId).pipe(
      tap((registration) => {
        this.replyToGameMessage(sourceMessage, {
          type: GameMessageType.GAME_REGISTRATION,
          data: {
            registrationId: registration.id,
            eduContentId: registration.contentId,
            currentHighscore: registration.highScore,
          },
        });
      })
    );
  }

  private createNewRegistration(contentId: number): Observable<RegistrationWithHighScoreInterface> {
    return forkJoin([
      this.eduContentHighScoreService.getHighScoreForEduContent(contentId),
      this.registrationService.createRegistration({
        contentId,
        type: RegistrationTypeEnum.GAME,
      }),
    ]).pipe(
      map(([highScore, registration]) => {
        return {
          ...registration,
          highScore: highScore?.score || 0,
        };
      })
    );
  }

  private validateGameMessage(event: MessageEvent): boolean {
    const originIsCorrect = event.origin === this.window.location.origin;
    const isGameMessage = event.data?.type && this.gameMessageTypes.has(event.data.type);

    return originIsCorrect && isGameMessage;
  }
}
