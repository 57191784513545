import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NAME, State, selectAll, selectEntities, selectIds, selectTotal } from './edu-content-source.reducer';

export const selectEduContentSourceState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectEduContentSourceState, (state: State) => state.error);

export const getLoaded = createSelector(selectEduContentSourceState, (state: State) => state.loaded);

export const getAll = createSelector(selectEduContentSourceState, selectAll);

export const getAllOrderedByName = createSelector(selectEduContentSourceState, (state: State) =>
  Object.values(state.entities).sort((a, b) => a.name.localeCompare(b.name))
);

export const getCount = createSelector(selectEduContentSourceState, selectTotal);

export const getIds = createSelector(selectEduContentSourceState, selectIds);

export const getAllEntities = createSelector(selectEduContentSourceState, selectEntities);
