import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { BundlePersonInterface } from '../../+models';

export const loadBundlePersons = createAction(
  '[BundlePerson] Load BundlePersons',
  (userId: number = null, force: boolean = false) => ({
    userId,
    force,
  })
);

export const bundlePersonsLoaded = createAction(
  '[BundlePerson] BundlePersons loaded',
  props<{ bundlePersons: BundlePersonInterface[] }>()
);

export const bundlePersonsLoadError = createAction('[BundlePerson] Load Error', props<{ error: any }>());

export const addBundlePerson = createAction(
  '[BundlePerson] Add BundlePerson',
  props<{ bundlePerson: BundlePersonInterface }>()
);

export const upsertBundlePerson = createAction(
  '[BundlePerson] Upsert BundlePerson',
  props<{ bundlePerson: BundlePersonInterface }>()
);

export const addBundlePersons = createAction(
  '[BundlePerson] Add BundlePersons',
  props<{ bundlePersons: BundlePersonInterface[] }>()
);

export const upsertBundlePersons = createAction(
  '[BundlePerson] Upsert BundlePersons',
  props<{ bundlePersons: BundlePersonInterface[] }>()
);

export const updateBundlePerson = createAction(
  '[BundlePerson] Update BundlePerson',
  props<{ bundlePerson: Update<BundlePersonInterface> }>()
);

export const updateBundlePersons = createAction(
  '[BundlePerson] Update BundlePersons',
  props<{ bundlePersons: Update<BundlePersonInterface>[] }>()
);

export const deleteBundlePerson = createAction('[BundlePerson] Delete BundlePerson', props<{ id: number }>());

export const deleteBundlePersons = createAction('[BundlePerson] Delete BundlePersons', props<{ ids: number[] }>());

export const clearBundlePersons = createAction('[BundlePerson] Clear BundlePersons');

export const updateBundlePersonsAccess = createAction(
  '[BundlePerson] Update Access',
  props<{ bundleId: number; bundlePersons: BundlePersonInterface[] }>()
);
