import { LoopCodeEnum } from './Loop.interface';

export enum ContextDomainEnum {
  NAV = 'nav',
  SEARCH = 'search',
  ADMIN = 'admin',
  RESULT = 'result',
}

export interface ContextInterface {
  tocId?: number; // Passepartout, Kadet, ...
  strandId?: string; // Fluo
  loop?: LoopCodeEnum;
  yearId?: number;
  conceptId?: string;
  recommendation?: string;
  unlockedCurriculumTreeId?: number;
  unlockedFreePracticeId?: number;
  unlockedContentId?: number;
  taskId?: number;
  contextDomain?: ContextDomainEnum;
}

export enum RegistrationTypeEnum {
  EXERCISE = 'exercise',
  EDUCONTENT = 'eduContent',
  SURVEY = 'survey', // only Fluo
  GAME = 'game',
}

export interface RegistrationInterface {
  id?: number;
  personId?: number;
  type?: RegistrationTypeEnum;
  contentId?: number;
  loop?: LoopCodeEnum;
  strandId?: string | number; // strand.guid (Fluo) or toc.id (Passepartout, Kadet ...)
  yearId?: number;
  recommendation?: string;
  isCompleted?: boolean;
  created?: Date;
  taskId?: number;
  resultId?: number;
  unlockedFreePracticeId?: number;
}
