import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { TaskEvaluationSubjectScoreInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum TaskEvaluationSubjectScoresActionTypes {
  TaskEvaluationSubjectScoresLoaded = '[TaskEvaluationSubjectScores] TaskEvaluationSubjectScores Loaded',
  TaskEvaluationSubjectScoresLoadError = '[TaskEvaluationSubjectScores] Load Error',
  LoadTaskEvaluationSubjectScores = '[TaskEvaluationSubjectScores] Load TaskEvaluationSubjectScores',
  LoadTaskEvaluationSubjectScoresForTask = '[TaskEvaluationSubjectScores] Load TaskEvaluationSubjectScores for Task',
  StartSetTaskEvaluationSubjectScoresForTask = '[TaskEvaluationSubjectScores] Start Set TaskEvaluationSubjectScores for task',
  SetTaskEvaluationSubjectScoresForTask = '[TaskEvaluationSubjectScores] Set TaskEvaluationSubjectScores for task',
  AddTaskEvaluationSubjectScoresForTask = '[TaskEvaluationSubjectScores] Add TaskEvaluationSubjectScores for Task',
  AddTaskEvaluationSubjectScore = '[TaskEvaluationSubjectScores] Add TaskEvaluationSubjectScore',
  UpsertTaskEvaluationSubjectScore = '[TaskEvaluationSubjectScores] Upsert TaskEvaluationSubjectScore',
  AddTaskEvaluationSubjectScores = '[TaskEvaluationSubjectScores] Add TaskEvaluationSubjectScores',
  UpsertTaskEvaluationSubjectScores = '[TaskEvaluationSubjectScores] Upsert TaskEvaluationSubjectScores',
  UpdateTaskEvaluationSubjectScore = '[TaskEvaluationSubjectScores] Update TaskEvaluationSubjectScore',
  UpdateTaskEvaluationSubjectScores = '[TaskEvaluationSubjectScores] Update TaskEvaluationSubjectScores',
  DeleteTaskEvaluationSubjectScore = '[TaskEvaluationSubjectScores] Delete TaskEvaluationSubjectScore',
  DeleteTaskEvaluationSubjectScores = '[TaskEvaluationSubjectScores] Delete TaskEvaluationSubjectScores',
  ClearTaskEvaluationSubjectScores = '[TaskEvaluationSubjectScores] Clear TaskEvaluationSubjectScores',
}

export class LoadTaskEvaluationSubjectScores implements Action {
  readonly type = TaskEvaluationSubjectScoresActionTypes.LoadTaskEvaluationSubjectScores;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class LoadTaskEvaluationSubjectScoresForTask implements Action {
  readonly type = TaskEvaluationSubjectScoresActionTypes.LoadTaskEvaluationSubjectScoresForTask;

  constructor(public payload: { taskId: number }) {}
}

export class AddTaskEvaluationSubjectScoresForTask implements Action {
  readonly type = TaskEvaluationSubjectScoresActionTypes.AddTaskEvaluationSubjectScoresForTask;

  constructor(public payload: { taskId: number; taskEvaluationSubjectScores: TaskEvaluationSubjectScoreInterface[] }) {}
}
//TODO: for taskEvaluationSubjectIds or for taskId ???
export class StartSetTaskEvaluationSubjectScoresForTask implements Action, FeedbackTriggeringAction {
  readonly type = TaskEvaluationSubjectScoresActionTypes.StartSetTaskEvaluationSubjectScoresForTask;

  constructor(
    public payload: {
      taskId: number;
      taskEvaluationSubjectIds: number[];
      taskEvaluationSubjectScores: TaskEvaluationSubjectScoreInterface[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}
export class SetTaskEvaluationSubjectScoresForTask implements Action {
  readonly type = TaskEvaluationSubjectScoresActionTypes.SetTaskEvaluationSubjectScoresForTask;

  constructor(
    public payload: {
      taskId: number;
      taskEvaluationSubjectIds: number[];
      taskEvaluationSubjectScores: TaskEvaluationSubjectScoreInterface[];
    }
  ) {}
}

export class TaskEvaluationSubjectScoresLoaded implements Action {
  readonly type = TaskEvaluationSubjectScoresActionTypes.TaskEvaluationSubjectScoresLoaded;

  constructor(public payload: { taskEvaluationSubjectScores: TaskEvaluationSubjectScoreInterface[] }) {}
}

export class TaskEvaluationSubjectScoresLoadError implements Action {
  readonly type = TaskEvaluationSubjectScoresActionTypes.TaskEvaluationSubjectScoresLoadError;
  constructor(public payload: any) {}
}

export class AddTaskEvaluationSubjectScore implements Action {
  readonly type = TaskEvaluationSubjectScoresActionTypes.AddTaskEvaluationSubjectScore;

  constructor(public payload: { taskEvaluationSubjectScore: TaskEvaluationSubjectScoreInterface }) {}
}

export class UpsertTaskEvaluationSubjectScore implements Action {
  readonly type = TaskEvaluationSubjectScoresActionTypes.UpsertTaskEvaluationSubjectScore;

  constructor(public payload: { taskEvaluationSubjectScore: TaskEvaluationSubjectScoreInterface }) {}
}

export class AddTaskEvaluationSubjectScores implements Action {
  readonly type = TaskEvaluationSubjectScoresActionTypes.AddTaskEvaluationSubjectScores;

  constructor(public payload: { taskEvaluationSubjectScores: TaskEvaluationSubjectScoreInterface[] }) {}
}

export class UpsertTaskEvaluationSubjectScores implements Action {
  readonly type = TaskEvaluationSubjectScoresActionTypes.UpsertTaskEvaluationSubjectScores;

  constructor(public payload: { taskEvaluationSubjectScores: TaskEvaluationSubjectScoreInterface[] }) {}
}

export class UpdateTaskEvaluationSubjectScore implements Action {
  readonly type = TaskEvaluationSubjectScoresActionTypes.UpdateTaskEvaluationSubjectScore;

  constructor(public payload: { taskEvaluationSubjectScore: Update<TaskEvaluationSubjectScoreInterface> }) {}
}

export class UpdateTaskEvaluationSubjectScores implements Action {
  readonly type = TaskEvaluationSubjectScoresActionTypes.UpdateTaskEvaluationSubjectScores;

  constructor(public payload: { taskEvaluationSubjectScores: Update<TaskEvaluationSubjectScoreInterface>[] }) {}
}

export class DeleteTaskEvaluationSubjectScore implements Action {
  readonly type = TaskEvaluationSubjectScoresActionTypes.DeleteTaskEvaluationSubjectScore;

  constructor(public payload: { id: number }) {}
}

export class DeleteTaskEvaluationSubjectScores implements Action {
  readonly type = TaskEvaluationSubjectScoresActionTypes.DeleteTaskEvaluationSubjectScores;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearTaskEvaluationSubjectScores implements Action {
  readonly type = TaskEvaluationSubjectScoresActionTypes.ClearTaskEvaluationSubjectScores;
}

export type TaskEvaluationSubjectScoresActions =
  | LoadTaskEvaluationSubjectScores
  | TaskEvaluationSubjectScoresLoaded
  | TaskEvaluationSubjectScoresLoadError
  | AddTaskEvaluationSubjectScore
  | UpsertTaskEvaluationSubjectScore
  | AddTaskEvaluationSubjectScores
  | UpsertTaskEvaluationSubjectScores
  | UpdateTaskEvaluationSubjectScore
  | UpdateTaskEvaluationSubjectScores
  | DeleteTaskEvaluationSubjectScore
  | DeleteTaskEvaluationSubjectScores
  | ClearTaskEvaluationSubjectScores
  | LoadTaskEvaluationSubjectScoresForTask
  | AddTaskEvaluationSubjectScoresForTask
  | StartSetTaskEvaluationSubjectScoresForTask
  | SetTaskEvaluationSubjectScoresForTask;
