import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { LearnerProfileStrandInterface } from '../../+models';

export enum LearnerProfileStrandsActionTypes {
  LearnerProfileStrandsLoaded = '[LearnerProfileStrands] LearnerProfiles Loaded (per strand)',
  LearnerProfileStrandsLoadError = '[LearnerProfileStrands] Load Error',
  LoadLearnerProfileStrands = '[LearnerProfileStrands] Load LearnerProfiles (per strand)',
  AddLearnerProfileStrand = '[LearnerProfileStrands] Add LearnerProfileStrand',
  UpsertLearnerProfileStrand = '[LearnerProfileStrands] Upsert LearnerProfileStrand',
  AddLearnerProfileStrands = '[LearnerProfileStrands] Add LearnerProfileStrands',
  UpsertLearnerProfileStrands = '[LearnerProfileStrands] Upsert LearnerProfileStrands',
  UpdateLearnerProfileStrand = '[LearnerProfileStrands] Update LearnerProfileStrand',
  UpdateLearnerProfileStrands = '[LearnerProfileStrands] Update LearnerProfileStrands',
  DeleteLearnerProfileStrand = '[LearnerProfileStrands] Delete LearnerProfileStrand',
  DeleteLearnerProfileStrands = '[LearnerProfileStrands] Delete LearnerProfileStrands',
  ClearLearnerProfileStrands = '[LearnerProfileStrands] Clear LearnerProfileStrands',
}

export class LoadLearnerProfileStrands implements Action {
  readonly type = LearnerProfileStrandsActionTypes.LoadLearnerProfileStrands;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class LearnerProfileStrandsLoaded implements Action {
  readonly type = LearnerProfileStrandsActionTypes.LearnerProfileStrandsLoaded;

  constructor(public payload: { learnerProfileStrands: LearnerProfileStrandInterface[] }) {}
}

export class LearnerProfileStrandsLoadError implements Action {
  readonly type = LearnerProfileStrandsActionTypes.LearnerProfileStrandsLoadError;
  constructor(public payload: any) {}
}

export class AddLearnerProfileStrand implements Action {
  readonly type = LearnerProfileStrandsActionTypes.AddLearnerProfileStrand;

  constructor(public payload: { learnerProfileStrand: LearnerProfileStrandInterface }) {}
}

export class UpsertLearnerProfileStrand implements Action {
  readonly type = LearnerProfileStrandsActionTypes.UpsertLearnerProfileStrand;

  constructor(public payload: { learnerProfileStrand: LearnerProfileStrandInterface }) {}
}

export class AddLearnerProfileStrands implements Action {
  readonly type = LearnerProfileStrandsActionTypes.AddLearnerProfileStrands;

  constructor(public payload: { learnerProfileStrands: LearnerProfileStrandInterface[] }) {}
}

export class UpsertLearnerProfileStrands implements Action {
  readonly type = LearnerProfileStrandsActionTypes.UpsertLearnerProfileStrands;

  constructor(public payload: { learnerProfileStrands: LearnerProfileStrandInterface[] }) {}
}

export class UpdateLearnerProfileStrand implements Action {
  readonly type = LearnerProfileStrandsActionTypes.UpdateLearnerProfileStrand;

  constructor(
    public payload: {
      learnerProfileStrand: Update<LearnerProfileStrandInterface>;
    }
  ) {}
}

export class UpdateLearnerProfileStrands implements Action {
  readonly type = LearnerProfileStrandsActionTypes.UpdateLearnerProfileStrands;

  constructor(
    public payload: {
      learnerProfileStrands: Update<LearnerProfileStrandInterface>[];
    }
  ) {}
}

export class DeleteLearnerProfileStrand implements Action {
  readonly type = LearnerProfileStrandsActionTypes.DeleteLearnerProfileStrand;

  constructor(public payload: { id: string }) {}
}

export class DeleteLearnerProfileStrands implements Action {
  readonly type = LearnerProfileStrandsActionTypes.DeleteLearnerProfileStrands;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearLearnerProfileStrands implements Action {
  readonly type = LearnerProfileStrandsActionTypes.ClearLearnerProfileStrands;
}

export type LearnerProfileStrandsActions =
  | LoadLearnerProfileStrands
  | LearnerProfileStrandsLoaded
  | LearnerProfileStrandsLoadError
  | AddLearnerProfileStrand
  | UpsertLearnerProfileStrand
  | AddLearnerProfileStrands
  | UpsertLearnerProfileStrands
  | UpdateLearnerProfileStrand
  | UpdateLearnerProfileStrands
  | DeleteLearnerProfileStrand
  | DeleteLearnerProfileStrands
  | ClearLearnerProfileStrands;
