import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { LearnerProfileService } from '../../learner-profile';
import { DalState } from '../dal.state.interface';
import {
  AddLearnerProfileConceptsForCourse,
  LearnerProfileConceptsActionTypes,
  LearnerProfileConceptsLoadError,
  LoadLearnerProfileConceptsForCourse,
} from './learner-profile-concept.actions';
import { selectLearnerProfileConceptState } from './learner-profile-concept.selectors';

@Injectable()
export class LearnerProfileConceptEffects {
  loadLearnerProfileConceptsForCourse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LearnerProfileConceptsActionTypes.LoadLearnerProfileConceptsForCourse),
      concatLatestFrom(() => this.store.pipe(select(selectLearnerProfileConceptState))),
      fetch({
        run: (action: LoadLearnerProfileConceptsForCourse, learnerProfileConceptState) => {
          const { curriculum, course } = action.payload;

          if (!action.payload.force && learnerProfileConceptState.loadedForCourse[course]) return;

          return this.learnerProfileService.getReports(curriculum, course).pipe(
            map(
              (learnerProfileConcepts) =>
                new AddLearnerProfileConceptsForCourse({
                  course,
                  learnerProfileConcepts,
                })
            )
          );
        },
        onError: (action: LoadLearnerProfileConceptsForCourse, error) => {
          return new LearnerProfileConceptsLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<DalState>,
    private learnerProfileService: LearnerProfileService
  ) {}
}
