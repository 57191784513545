import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { SectionInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum SectionsActionTypes {
  SectionsLoaded = '[Sections] Sections Loaded',
  SectionsLoadError = '[Sections] Load Error',
  LoadSections = '[Sections] Load Sections',
  AddSection = '[Sections] Add Section',
  AddSections = '[Sections] Add Sections',
  AddSectionsForBook = '[Sections] Add Sections for Book',
  AddSectionsForToc = '[Sections] Add Sections for Toc',
  UpsertSection = '[Sections] Upsert Section',
  UpsertSections = '[Sections] Upsert Sections',
  UpdateSection = '[Sections] Update Section',
  UpdateSections = '[Sections] Update Sections',
  DeleteSection = '[Sections] Delete Section',
  DeleteSections = '[Sections] Delete Sections',
  ClearSections = '[Sections] Clear Sections',
  StartAddSection = '[Sections] Start Add Section',
  StartAddSections = '[Sections] Start Add Sections',
}

export class LoadSections implements Action {
  readonly type = SectionsActionTypes.LoadSections;

  constructor(public payload: { force?: boolean; bookId: number } = { bookId: null }) {}
}

export class SectionsLoaded implements Action {
  readonly type = SectionsActionTypes.SectionsLoaded;

  constructor(public payload: { sections: SectionInterface[] }) {}
}

export class SectionsLoadError implements Action {
  readonly type = SectionsActionTypes.SectionsLoadError;
  constructor(public payload: any) {}
}

export class AddSection implements Action {
  readonly type = SectionsActionTypes.AddSection;

  constructor(public payload: { section: SectionInterface }) {}
}

export class UpsertSection implements Action {
  readonly type = SectionsActionTypes.UpsertSection;

  constructor(public payload: { section: SectionInterface }) {}
}

export class AddSections implements Action {
  readonly type = SectionsActionTypes.AddSections;

  constructor(public payload: { sections: SectionInterface[] }) {}
}

export class AddSectionsForBook implements Action {
  readonly type = SectionsActionTypes.AddSectionsForBook;

  constructor(public payload: { sections: SectionInterface[]; bookId: number }) {}
}

export class AddSectionsForToc implements Action {
  readonly type = SectionsActionTypes.AddSectionsForToc;

  constructor(public payload: { sections: SectionInterface[]; tocId: number }) {}
}

export class UpsertSections implements Action {
  readonly type = SectionsActionTypes.UpsertSections;

  constructor(public payload: { sections: SectionInterface[] }) {}
}

export class UpdateSection implements Action {
  readonly type = SectionsActionTypes.UpdateSection;

  constructor(public payload: { section: Update<SectionInterface> }) {}
}

export class UpdateSections implements Action {
  readonly type = SectionsActionTypes.UpdateSections;

  constructor(public payload: { sections: Update<SectionInterface>[] }) {}
}

export class DeleteSection implements FeedbackTriggeringAction {
  readonly type = SectionsActionTypes.DeleteSection;

  constructor(public payload: { id: number; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export class DeleteSections implements FeedbackTriggeringAction {
  readonly type = SectionsActionTypes.DeleteSections;

  constructor(public payload: { ids: number[]; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export class ClearSections implements Action {
  readonly type = SectionsActionTypes.ClearSections;
}

export class StartAddSection implements FeedbackTriggeringAction {
  readonly type = SectionsActionTypes.StartAddSection;

  constructor(
    public payload: {
      section: SectionInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartAddSections implements FeedbackTriggeringAction {
  readonly type = SectionsActionTypes.StartAddSections;

  constructor(
    public payload: {
      sections: SectionInterface[];
      eduContentTocId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export type SectionsActions =
  | LoadSections
  | SectionsLoaded
  | SectionsLoadError
  | AddSection
  | AddSectionsForBook
  | AddSectionsForToc
  | UpsertSection
  | AddSections
  | UpsertSections
  | UpdateSection
  | UpdateSections
  | DeleteSection
  | DeleteSections
  | ClearSections
  | StartAddSection;
