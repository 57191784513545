import { groupArrayByKey } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssigneeInterface, AssigneeTypesEnum, PersonInterface } from '../../+models';
import { BundlePersonInterface } from '../../+models/BundlePerson.interface';
import { selectLinkedPersonState } from '../linked-person/linked-person.selectors';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './bundle-person.reducer';

export const selectBundlePersonState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectBundlePersonState, (state: State) => state.error);

export const getLoaded = createSelector(selectBundlePersonState, (state: State) => state.loaded);

export const getAll = createSelector(selectBundlePersonState, selectAll);

export const getCount = createSelector(selectBundlePersonState, selectTotal);

export const getIds = createSelector(selectBundlePersonState, selectIds);

export const getAllEntities = createSelector(selectBundlePersonState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * bundlePerson$: BundlePersonInterface[] = this.store.pipe(
    select(BundlePersonQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectBundlePersonState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * bundlePerson$: BundlePersonInterface = this.store.pipe(
    select(BundlePersonQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectBundlePersonState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getBundlePersonAssigneeByBundle = createSelector(
  getAll,
  selectLinkedPersonState,
  (bundlePersons, linkedPersonState) =>
    bundlePersons
      .sort((bpA, bpB) => {
        const personA: PersonInterface = linkedPersonState.entities[bpA.personId];
        const personB: PersonInterface = linkedPersonState.entities[bpB.personId];
        if (!personA) return 1;
        if (!personB) return -1;

        const getName = ({ name, firstName, displayName }: PersonInterface) =>
          [name, firstName].join(' ').trim() || displayName;
        return getName(personA).localeCompare(getName(personB), undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      })
      .reduce((dict, bP) => {
        const student = linkedPersonState.entities[bP.personId];

        if (!dict[bP.bundleId]) {
          dict[bP.bundleId] = [];
        }

        // Check if the student still exists (could be deleted)
        if (!student) {
          return dict;
        }

        dict[bP.bundleId].push({
          id: bP.id,
          type: AssigneeTypesEnum.STUDENT,
          relationId: bP.personId,
          label: student.displayName,
        });

        return dict;
      }, {} as Dictionary<AssigneeInterface[]>)
);

export const getByBundleId = createSelector(
  selectBundlePersonState,
  (state: State, props: { bundleId: number }): BundlePersonInterface[] =>
    (state.ids as (string | number)[]).reduce((acc, id) => {
      if (state.entities[id].bundleId === props.bundleId) {
        acc.push(state.entities[id]);
      }
      return acc;
    }, [])
);

export const getAllGroupedByBundleId = createSelector(selectBundlePersonState, (state: State) => {
  return groupArrayByKey(Object.values(state.entities), 'bundleId');
});
