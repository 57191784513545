<ng-container *ngIf="hasScore; else noResult">
  <div class="ui-score-level__level ui-script"> {{ scoreLevel.level }}</div>
  <div
    *ngIf="scoreLevel.status === ResultStatusEnum.STATUS_INCOMPLETE"
    class="ui-score-level__wip"
    data-cy="result-score-level-incomplete"
  >
    <campus-icon class="ui-score-level__wip-icon" svgIcon="hourglass"></campus-icon>
  </div>
</ng-container>

<ng-template #noResult>
  <campus-icon
    class="ui-score-level__no-result-icon"
    svgIcon="no-result"
    data-cy="ui-score-level-no-result"
  ></campus-icon>
</ng-template>
