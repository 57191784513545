import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LicenseApi, PersonApi } from '../+api';
import { LicenseInterface } from '../+models';
import { LicenseServiceInterface } from './license.service.interface';

@Injectable({
  providedIn: 'root',
})
export class LicenseService implements LicenseServiceInterface {
  constructor(private personApi: PersonApi, private licenseApi: LicenseApi) {}
  updateLicense(licenseId: number | string, license: Partial<LicenseInterface>): Observable<LicenseInterface> {
    return this.licenseApi.patchAttributes(licenseId, license) as Observable<LicenseInterface>;
  }

  getAllForUser(userId: number): Observable<LicenseInterface[]> {
    return this.personApi
      .getData(userId, 'licenses')
      .pipe(map((res: { licenses: LicenseInterface[] }) => res.licenses));
  }
}
