import { Component, EventEmitter, HostBinding, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DalState, EduContentQueries } from '@campus/dal';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';

@Component({
  selector: 'campus-edu-content-result-loader',
  templateUrl: './edu-content-result-loader.component.html',
})
export class EduContentResultLoaderComponent implements OnInit, OnDestroy {
  @HostBinding('class.relative') relative = true;
  @Input() eduContentId: number;

  @Output() loadedChanged = new EventEmitter<void>();

  public eduContentResultPending$: Observable<boolean>;
  private _eduContentResultPending$ = new BehaviorSubject<boolean>(false);

  private subscriptions = new Subscription();
  private store: Store<DalState> = inject(Store);

  ngOnInit(): void {
    this.eduContentResultPending$ = this._eduContentResultPending$.asObservable();

    // fyi: it's written this way instead of using async pipe in the template to avoid change detection issues
    // If not done this way, in the Polpo Bundle and Task pages, the loader would show indefinitely
    this.subscriptions.add(
      this.isEduContentResultPending$().subscribe((isPending) => {
        this._eduContentResultPending$.next(isPending);
        this.loadedChanged.emit();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private isEduContentResultPending$(): Observable<boolean> {
    if (!this.eduContentId) return of(false);

    return this.store.select(
      EduContentQueries.isEduContentResultPending({
        eduContentId: this.eduContentId,
      })
    );
  }
}
