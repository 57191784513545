import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { EditorStatusInterface } from '../../+models';

export enum EditorStatusesActionTypes {
  EditorStatusesLoaded = '[EditorStatuses] EditorStatuses Loaded',
  EditorStatusesLoadError = '[EditorStatuses] Load Error',
  LoadEditorStatuses = '[EditorStatuses] Load EditorStatuses',
  AddEditorStatus = '[EditorStatuses] Add EditorStatus',
  UpsertEditorStatus = '[EditorStatuses] Upsert EditorStatus',
  AddEditorStatuses = '[EditorStatuses] Add EditorStatuses',
  UpsertEditorStatuses = '[EditorStatuses] Upsert EditorStatuses',
  UpdateEditorStatus = '[EditorStatuses] Update EditorStatus',
  UpdateEditorStatuses = '[EditorStatuses] Update EditorStatuses',
  DeleteEditorStatus = '[EditorStatuses] Delete EditorStatus',
  DeleteEditorStatuses = '[EditorStatuses] Delete EditorStatuses',
  ClearEditorStatuses = '[EditorStatuses] Clear EditorStatuses',
}

export class LoadEditorStatuses implements Action {
  readonly type = EditorStatusesActionTypes.LoadEditorStatuses;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class EditorStatusesLoaded implements Action {
  readonly type = EditorStatusesActionTypes.EditorStatusesLoaded;

  constructor(public payload: { editorStatuses: EditorStatusInterface[] }) {}
}

export class EditorStatusesLoadError implements Action {
  readonly type = EditorStatusesActionTypes.EditorStatusesLoadError;
  constructor(public payload: any) {}
}

export class AddEditorStatus implements Action {
  readonly type = EditorStatusesActionTypes.AddEditorStatus;

  constructor(public payload: { editorStatus: EditorStatusInterface }) {}
}

export class UpsertEditorStatus implements Action {
  readonly type = EditorStatusesActionTypes.UpsertEditorStatus;

  constructor(public payload: { editorStatus: EditorStatusInterface }) {}
}

export class AddEditorStatuses implements Action {
  readonly type = EditorStatusesActionTypes.AddEditorStatuses;

  constructor(public payload: { editorStatuses: EditorStatusInterface[] }) {}
}

export class UpsertEditorStatuses implements Action {
  readonly type = EditorStatusesActionTypes.UpsertEditorStatuses;

  constructor(public payload: { editorStatuses: EditorStatusInterface[] }) {}
}

export class UpdateEditorStatus implements Action {
  readonly type = EditorStatusesActionTypes.UpdateEditorStatus;

  constructor(public payload: { editorStatus: Update<EditorStatusInterface> }) {}
}

export class UpdateEditorStatuses implements Action {
  readonly type = EditorStatusesActionTypes.UpdateEditorStatuses;

  constructor(public payload: { editorStatuses: Update<EditorStatusInterface>[] }) {}
}

export class DeleteEditorStatus implements Action {
  readonly type = EditorStatusesActionTypes.DeleteEditorStatus;

  constructor(public payload: { id: number }) {}
}

export class DeleteEditorStatuses implements Action {
  readonly type = EditorStatusesActionTypes.DeleteEditorStatuses;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearEditorStatuses implements Action {
  readonly type = EditorStatusesActionTypes.ClearEditorStatuses;
}

export type EditorStatusesActions =
  | LoadEditorStatuses
  | EditorStatusesLoaded
  | EditorStatusesLoadError
  | AddEditorStatus
  | UpsertEditorStatus
  | AddEditorStatuses
  | UpsertEditorStatuses
  | UpdateEditorStatus
  | UpdateEditorStatuses
  | DeleteEditorStatus
  | DeleteEditorStatuses
  | ClearEditorStatuses;
