import { Action } from '@ngrx/store';
import { EduContentTOCEvaluationInterface } from '../../+models';

export enum EduContentTocEvaluationsActionTypes {
  EduContentTocEvaluationsLoadError = '[EduContentTocEvaluations] Load Error',
  LoadEduContentTocEvaluationsForBook = '[EduContentTocEvaluations] Load EduContentTocEvaluations for Book',
  AddEduContentTocEvaluationsForBook = '[EduContentTocs] Add EduContentTocEvaluations for Book',
  UpsertEduContentTocEvaluations = '[EduContentTocs] Upsert EduContentTocEvaluations',
  DeleteEduContentTocEvaluation = '[EduContentTocs] Delete EduContentTocEvaluations',
}

export class EduContentTocEvaluationsLoadError implements Action {
  readonly type = EduContentTocEvaluationsActionTypes.EduContentTocEvaluationsLoadError;
  constructor(public payload: any) {}
}

export class LoadEduContentTocEvaluationsForBook implements Action {
  readonly type = EduContentTocEvaluationsActionTypes.LoadEduContentTocEvaluationsForBook;

  constructor(public payload: { bookId: number }) {}
}

export class UpsertEduContentTocEvaluations implements Action {
  readonly type = EduContentTocEvaluationsActionTypes.UpsertEduContentTocEvaluations;

  constructor(public payload: { eduContentTocEvaluations: EduContentTOCEvaluationInterface[] }) {}
}
export class DeleteEduContentTocEvaluation implements Action {
  readonly type = EduContentTocEvaluationsActionTypes.DeleteEduContentTocEvaluation;

  constructor(public payload: { id: number }) {}
}

export class AddEduContentTocEvaluationsForBook implements Action {
  readonly type = EduContentTocEvaluationsActionTypes.AddEduContentTocEvaluationsForBook;

  constructor(
    public payload: {
      bookId: number;
      eduContentTocEvaluations: EduContentTOCEvaluationInterface[];
    }
  ) {}
}

export type EduContentTocEvaluationsActions =
  | LoadEduContentTocEvaluationsForBook
  | EduContentTocEvaluationsLoadError
  | AddEduContentTocEvaluationsForBook
  | UpsertEduContentTocEvaluations
  | DeleteEduContentTocEvaluation;
