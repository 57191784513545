<ng-container *ngIf="inline; else formFieldSelect">
  <mat-select
    [multiple]="multiple"
    [formControl]="selectControl"
    (openedChange)="openedChange($event)"
    class="mat-select-flat"
    data-cy="select-filter"
  >
    <mat-option data-cy="select-reset" *ngIf="resetLabel" [value]="null">{{ resetLabel }}</mat-option>

    <ng-container *ngIf="optionGroups; else optionTemplate">
      <ng-container *ngFor="let group of optionGroups">
        <mat-optgroup *ngIf="group.options.length" [label]="group.label">
          <mat-option data-cy="select-option" *ngFor="let option of group.options" [value]="option.value">
            {{ option.viewValue }}
          </mat-option>
        </mat-optgroup>
      </ng-container>
    </ng-container>
    <ng-template #optionTemplate>
      <mat-option data-cy="select-option" *ngFor="let option of options" [value]="option.value">
        {{ option.viewValue }}
      </mat-option>
    </ng-template>
  </mat-select>
</ng-container>

<ng-template #formFieldSelect>
  <mat-form-field
    appearance="outline"
    [matBadge]="count"
    [matBadgeHidden]="count === 0 || hideBadge"
    matBadgePosition="above after"
    class="mat-form-field-no-hint"
    [ngClass]="{ 'select-filter-component--has-selection': hasSelection }"
  >
    <mat-label>{{ filterCriteria?.label }}</mat-label>
    <mat-select
      panelClass="select-filter-component-panel"
      (openedChange)="openedChange($event)"
      [multiple]="multiple"
      [formControl]="selectControl"
      data-cy="select-filter"
    >
      <ng-container *ngIf="optionGroups; else optionTemplate">
        <ng-container *ngFor="let group of optionGroups">
          <mat-optgroup *ngIf="group.options.length" [label]="group.label">
            <mat-option data-cy="select-option" *ngFor="let option of group.options" [value]="option.value">
              {{ option.viewValue }}
            </mat-option>
          </mat-optgroup>
        </ng-container>
      </ng-container>
      <ng-template #optionTemplate>
        <div class="select-filter-component__option-container">
          <mat-option data-cy="select-option" *ngFor="let option of options" [value]="option.value">
            {{ option.viewValue }}
          </mat-option>
        </div>
        <div class="select-filter-component__button-container">
          <campus-button
            data-cy="btn-select-filter"
            *ngIf="showFilterButton"
            bordered
            secondary
            flat
            (click)="filterClicked()"
          >
            Filteren
          </campus-button>
        </div>
      </ng-template>
    </mat-select>
    <button
      matSuffix
      inline-icon-button
      aria-label="Reset"
      *ngIf="clearable && hasSelection"
      (click)="reset(); $event.stopPropagation()"
      data-cy="btn-filter-reset"
      class="select-reset-suffix"
      icon="close"
    >
    </button>
  </mat-form-field>
</ng-template>
