import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SurveyQuestionInterface } from '../../+models';
import { SurveyQuestionsActions, SurveyQuestionsActionTypes } from './survey-question.actions';

export const NAME = 'surveyQuestions';

export interface State extends EntityState<SurveyQuestionInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<SurveyQuestionInterface> = createEntityAdapter<SurveyQuestionInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: SurveyQuestionsActions): State {
  switch (action.type) {
    case SurveyQuestionsActionTypes.AddSurveyQuestion: {
      return adapter.addOne(action.payload.surveyQuestion, state);
    }

    case SurveyQuestionsActionTypes.UpsertSurveyQuestion: {
      return adapter.upsertOne(action.payload.surveyQuestion, state);
    }

    case SurveyQuestionsActionTypes.AddSurveyQuestions: {
      return adapter.addMany(action.payload.surveyQuestions, state);
    }

    case SurveyQuestionsActionTypes.UpsertSurveyQuestions: {
      return adapter.upsertMany(action.payload.surveyQuestions, state);
    }

    case SurveyQuestionsActionTypes.UpdateSurveyQuestion: {
      return adapter.updateOne(action.payload.surveyQuestion, state);
    }

    case SurveyQuestionsActionTypes.UpdateSurveyQuestions: {
      return adapter.updateMany(action.payload.surveyQuestions, state);
    }

    case SurveyQuestionsActionTypes.DeleteSurveyQuestion: {
      return adapter.removeOne(action.payload.id, state);
    }

    case SurveyQuestionsActionTypes.DeleteSurveyQuestions: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case SurveyQuestionsActionTypes.SurveyQuestionsLoaded: {
      return adapter.setAll(action.payload.surveyQuestions, {
        ...state,
        loaded: true,
      });
    }

    case SurveyQuestionsActionTypes.SurveyQuestionsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case SurveyQuestionsActionTypes.ClearSurveyQuestions: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
