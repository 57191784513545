import { EduContentBookInterface } from './EduContentBook.interface';
import { PersonInterface } from './Person.interface';

export enum PreferenceKey {
  BOOKS = 'FAVORITE_EDUCONTENT_BOOKS',
  SEARCH = 'FILTER_EDUCONTENT',
  TEMPLATE = 'EDUCONTENT_TEMPLATE',
  ALTERNATIVE_PLATFORM_USAGE = 'ALTERNATIVE_PLATFORM_USAGE',
  REMEMBER_LOGIN = 'REMEMBER_LOGIN',
  RECEIVE_MESSAGES = 'RECEIVE_MESSAGES',
  GOALS = 'FILTER_GOALS',
}

export interface SearchPreferenceValueInterface {
  filters: {
    [field: string]: string | number[];
  };
  columns: { name: string; width?: number; selected: boolean }[];
  sorting: { [name: string]: 'asc' | 'desc' };
  pagination: { from?: number; amount?: number };
}

export interface EduContentBookPreferenceValueInterface {
  favoriteIds: number[];
}

export interface EduContentMetadataTemplateInterface {
  // TODO expand this interface
  eduContentBookId: number;
}

export type PreferenceValue =
  | SearchPreferenceValueInterface
  | EduContentBookPreferenceValueInterface
  | EduContentMetadataTemplateInterface
  | any; // Backwards compatibility

export interface PersonPreferenceInterface {
  key: PreferenceKey | string;
  value: PreferenceValue;
  name?: string;
  id?: number;
  personId?: number;
  person?: PersonInterface;
  eduContentBookId?: number;
  eduContentBook?: EduContentBookInterface;
  favoriteBooks?: EduContentBookInterface[];
  isShared?: boolean;
}
