import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '@campus/browser';
import {
  AUTH_SERVICE_TOKEN,
  Alert,
  AlertActions,
  AlertQueries,
  AuthServiceInterface,
  CredentialQueries,
  DalState,
  EduContentBookInterface,
  EduContentBookQueries,
  NavItem,
  PassportUserCredentialInterface,
  PersonInterface,
  PersonPreferenceQueries,
  PreferenceKey,
  UiActions,
  UiQuery,
  UserQueries,
} from '@campus/dal';
import {
  ENVIRONMENT_ALERTS_FEATURE_TOKEN,
  ENVIRONMENT_GLOBAL_SEARCH_FEATURE_TOKEN,
  ENVIRONMENT_TOURS_FEATURE_TOKEN,
  EnvironmentAlertsFeatureInterface,
  EnvironmentGlobalSearchFeatureInterface,
  EnvironmentToursFeatureInterface,
} from '@campus/environment';
import { DropdownMenuItemInterface } from '@campus/ui';
import { DateFunctions } from '@campus/utils';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest, from, merge } from 'rxjs';
import { filter, map, mapTo, skipWhile, switchMapTo } from 'rxjs/operators';
import {
  NAVIGATION_ITEM_SERVICE_TOKEN,
  NavigationItemServiceInterface,
} from '../../services/navigation-item/navigation-item.service.interface';

export interface ArchiveYearOption {
  label: string;
  value: number;
  selected: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class HeaderViewModel {
  // publics
  enableAlerts: boolean;
  enableGlobalSearch: boolean;
  enableTours: boolean;

  alertsLoaded$: Observable<boolean>;

  // source streams
  currentUser$: Observable<PersonInterface>;
  unreadAlerts$: Observable<Alert[]>;
  userPermissions$: Observable<string[]>;
  showTours$: Observable<boolean>;

  // presentation stream
  alertNotifications$: Observable<Alert[]>;
  unreadAlertCount$: Observable<number>;
  profileMenuItems$: Observable<DropdownMenuItemInterface[]>;
  favoriteBooks$: Observable<EduContentBookInterface[]>;
  selectedBook$: Observable<EduContentBookInterface>;
  globalBookDisabled$: Observable<boolean>;

  constructor(
    @Inject(AUTH_SERVICE_TOKEN) private authService: AuthServiceInterface,
    @Inject(ENVIRONMENT_ALERTS_FEATURE_TOKEN)
    private environmentAlertsFeature: EnvironmentAlertsFeatureInterface,
    @Optional()
    @Inject(ENVIRONMENT_GLOBAL_SEARCH_FEATURE_TOKEN)
    private environmentGlobalSearchFeature: EnvironmentGlobalSearchFeatureInterface,
    @Optional()
    @Inject(ENVIRONMENT_TOURS_FEATURE_TOKEN)
    private environmentToursFeature: EnvironmentToursFeatureInterface,
    private store: Store<DalState>,
    @Inject(NAVIGATION_ITEM_SERVICE_TOKEN)
    private navigationItemService: NavigationItemServiceInterface,
    @Inject(WINDOW)
    private window: Window,
    private router: Router
  ) {
    this.loadAlertsLoaded();
    this.loadFeatureToggles();
    this.loadStateStreams();
    this.loadDisplayStream();
  }

  setSelectedBookId(bookId: number) {
    this.store.dispatch(
      new UiActions.SaveSettings({
        selectedBookId: bookId,
      })
    );
  }

  private loadAlertsLoaded(): any {
    this.alertsLoaded$ = this.store.pipe(select(AlertQueries.getLoaded));
  }

  private loadStateStreams(): void {
    //source state streams
    this.unreadAlerts$ = this.store.pipe(select(AlertQueries.getUnread));
    this.userPermissions$ = this.store.pipe(select(UserQueries.getPermissions));
    this.showTours$ = this.store.pipe(select(UiQuery.getTourEnabled));
    this.globalBookDisabled$ = this.store.pipe(select(UiQuery.getDisableGlobalBookFilter));

    //presentation state streams
    this.currentUser$ = this.store.pipe(select(UserQueries.getCurrentUser));
  }

  private loadDisplayStream(): void {
    this.alertNotifications$ = this.getAlertNotifications();
    this.unreadAlertCount$ = this.getUnreadAlertCount();
    this.profileMenuItems$ = this.getProfileMenuItems();
    this.favoriteBooks$ = this.getFavoriteBooks();
    this.selectedBook$ = this.getSelectedBook();
  }

  private loadFeatureToggles(): void {
    this.enableAlerts = this.environmentAlertsFeature?.enabled && this.environmentAlertsFeature?.hasAppBarDropDown;

    this.enableGlobalSearch = this.environmentGlobalSearchFeature?.enabled;
    this.enableTours = this.environmentToursFeature?.enabled;
  }

  private getAlertNotifications(): Observable<Alert[]> {
    return this.unreadAlerts$;
  }

  private getUnreadAlertCount(): Observable<number> {
    return this.unreadAlerts$.pipe(
      map((alerts) => {
        return alerts.length;
      })
    );
  }

  private getProfileMenuItems(): Observable<DropdownMenuItemInterface[]> {
    return combineLatest([this.userPermissions$, this.getCredentials(), this.currentUser$]).pipe(
      map(([permissions, credentials, currentUser]) => {
        const toDropDownMenuItem = (navItem: NavItem): DropdownMenuItemInterface => ({
          id: navItem.id,
          description: navItem.title,
          internalLink: navItem.link as string,
          icon: navItem.icon,
          cssClass: navItem.cssClass,
          children: (navItem.children || []).map(toDropDownMenuItem),
          handler: navItem.handler,
          dividerBefore: navItem.dividerBefore,
        });

        let navItems: DropdownMenuItemInterface[] = this.navigationItemService
          .getNavItemsForTree('profileMenuNav', permissions, credentials, currentUser)
          .map(toDropDownMenuItem);

        navItems = this.applyArchiveNavItems(navItems);

        return navItems;
      })
    );
  }

  private getFavoriteBooks(): Observable<EduContentBookInterface[]> {
    return combineLatest([
      this.store.pipe(select(PersonPreferenceQueries.getByKey)),
      this.store.pipe(select(EduContentBookQueries.getAllEntities)),
    ]).pipe(
      map(([preferencesByKey, books]) => {
        const bookPreferences = preferencesByKey[PreferenceKey.BOOKS];
        if (!bookPreferences?.length) {
          return [];
        }

        return bookPreferences[0].value.filter(bookId => !!books[bookId]).map((bookId) => books[bookId]);
      })
    );
  }

  private getSelectedBook(): Observable<EduContentBookInterface> {
    return combineLatest([
      this.store.pipe(select(UiQuery.getSavedSettings)),
      this.store.pipe(select(EduContentBookQueries.getAllEntities)),
    ]).pipe(
      map(([savedSettings, books]) => {
        return books[savedSettings.selectedBookId as number] ?? null;
      })
    );
  }

  private applyArchiveNavItems(navItems: DropdownMenuItemInterface[]): DropdownMenuItemInterface[] {
    const removeNavItem = (id) => {
      navItems = navItems.filter((item) => item.id !== id);
    };

    const archiveYearSelection = navItems.find((item) => item.id === 'archive-year-selection');
    const currentYearSelection = navItems.find((item) => item.id === 'current-year-selection');

    const [currentYearOption, ...archiveYearOptions] = this.getArchiveYearOptions();

    // if we're missing one of the nav items or there are no archive years to begin with, bail
    if (!archiveYearSelection || !currentYearSelection || !archiveYearOptions.length) {
      removeNavItem('current-year-selection');
      removeNavItem('archive-year-selection');

      return navItems;
    }

    const hasSelectedArchiveYear = archiveYearOptions && archiveYearOptions.some((archiveYear) => archiveYear.selected);

    if (hasSelectedArchiveYear) {
      // if any one archive year is selected, also make the parent nav item bold
      archiveYearSelection.cssClass = 'selected';
    } else {
      // no archive year selected, don't bother adding the ability to go to the current year
      removeNavItem('current-year-selection');
    }

    currentYearSelection.handler = () => this.setCurrentArchiveYear(currentYearOption);
    this.addArchiveMenuItemChildren(archiveYearSelection, archiveYearOptions);

    return navItems;
  }

  private addArchiveMenuItemChildren(
    archiveYearSelection: DropdownMenuItemInterface,
    archiveYears: ArchiveYearOption[]
  ) {
    archiveYearSelection.children = [
      {
        description: archiveYearSelection.description,
        icon: archiveYearSelection.icon,
        cssClass: archiveYearSelection.cssClass,
      },
      ...archiveYears.map((archiveYearOption) => {
        return {
          description: archiveYearOption.label,
          cssClass: archiveYearOption.selected ? 'selected' : '',
          handler: () => this.setCurrentArchiveYear(archiveYearOption),
        } as DropdownMenuItemInterface;
      }),
    ];
  }

  private setCurrentArchiveYear(option: ArchiveYearOption) {
    const currentSchoolYear = DateFunctions.getSchoolYearFromTransitionPeriod();

    if (option.value === currentSchoolYear) {
      this.window.sessionStorage.removeItem('archiveYear');
    } else {
      this.window.sessionStorage.setItem('archiveYear', option.value.toString());
    }

    from(this.router.navigateByUrl('/')).subscribe(() => {
      this.window.location.reload();
    });
  }

  private getArchiveYearOptions(): ArchiveYearOption[] {
    const currentSchoolYear = DateFunctions.getSchoolYearFromTransitionPeriod();
    const minArchiveYear = 2022;
    const maxArchiveYears = 6;
    const archiveStart = Math.max(minArchiveYear, currentSchoolYear - maxArchiveYears);
    const archiveYearOptions: ArchiveYearOption[] = [];
    const selectedArchiveYear = this.window.sessionStorage.getItem('archiveYear');

    for (let year = currentSchoolYear; year >= archiveStart; year--) {
      archiveYearOptions.push({
        label: `${year} - ${year + 1}`,
        value: year,
        selected: year.toString() === selectedArchiveYear,
      });
    }

    return archiveYearOptions;
  }

  // user interactions //

  setAlertAsRead(alertId: number): void {
    this.store.dispatch(
      new AlertActions.SetReadAlert({
        alertIds: alertId,
        personId: this.authService.userId,
        read: true,
        customFeedbackHandlers: {
          useCustomErrorHandler: 'useNoHandler',
          useCustomSuccessHandler: 'useNoHandler',
        },
      })
    );
  }

  toggleSideNav() {
    this.store.dispatch(new UiActions.ToggleNavOpen());
  }

  private getCredentials(): Observable<PassportUserCredentialInterface[]> {
    const withoutCredentials = this.store.pipe(
      filter((store) => !store.credentials),
      mapTo([])
    );

    const withCredentials = this.store.pipe(
      filter((store) => !!store.credentials),
      select(CredentialQueries.getLoaded),
      skipWhile((loaded) => !loaded),
      switchMapTo(this.store.pipe(select(CredentialQueries.getAll)))
    );

    return merge(withoutCredentials, withCredentials);
  }
}
