import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { TaskGoalYearLevelInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum TaskGoalYearLevelsActionTypes {
  TaskGoalYearLevelsLoaded = '[TaskGoalYearLevels] TaskGoalYearLevels Loaded',
  TaskGoalYearLevelsLoadError = '[TaskGoalYearLevels] Load Error',
  LoadTaskGoalYearLevels = '[TaskGoalYearLevels] Load TaskGoalYearLevels',
  LoadTaskGoalYearLevelsForTask = '[TaskGoalYearLevels] Load TaskGoalYearLevels for Task',
  StartSetTaskGoalYearLevelsForTask = '[TaskGoalYearLevels] Start Set TaskGoalYearLevels for task',
  SetTaskGoalYearLevelsForTask = '[TaskGoalYearLevels] Set TaskGoalYearLevels for task',
  AddTaskGoalYearLevelsForTask = '[TaskGoalYearLevels] Add TaskGoalYearLevels for Task',
  AddTaskGoalYearLevel = '[TaskGoalYearLevels] Add TaskGoalYearLevel',
  UpsertTaskGoalYearLevel = '[TaskGoalYearLevels] Upsert TaskGoalYearLevel',
  AddTaskGoalYearLevels = '[TaskGoalYearLevels] Add TaskGoalYearLevels',
  UpsertTaskGoalYearLevels = '[TaskGoalYearLevels] Upsert TaskGoalYearLevels',
  UpdateTaskGoalYearLevel = '[TaskGoalYearLevels] Update TaskGoalYearLevel',
  UpdateTaskGoalYearLevels = '[TaskGoalYearLevels] Update TaskGoalYearLevels',
  DeleteTaskGoalYearLevel = '[TaskGoalYearLevels] Delete TaskGoalYearLevel',
  DeleteTaskGoalYearLevels = '[TaskGoalYearLevels] Delete TaskGoalYearLevels',
  ClearTaskGoalYearLevels = '[TaskGoalYearLevels] Clear TaskGoalYearLevels',
}

export class LoadTaskGoalYearLevels implements Action {
  readonly type = TaskGoalYearLevelsActionTypes.LoadTaskGoalYearLevels;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class LoadTaskGoalYearLevelsForTask implements Action {
  readonly type = TaskGoalYearLevelsActionTypes.LoadTaskGoalYearLevelsForTask;

  constructor(public payload: { taskId: number }) {}
}

export class AddTaskGoalYearLevelsForTask implements Action {
  readonly type = TaskGoalYearLevelsActionTypes.AddTaskGoalYearLevelsForTask;

  constructor(public payload: { taskId: number; taskGoalYearLevels: TaskGoalYearLevelInterface[] }) {}
}
export class StartSetTaskGoalYearLevelsForTask implements Action, FeedbackTriggeringAction {
  readonly type = TaskGoalYearLevelsActionTypes.StartSetTaskGoalYearLevelsForTask;

  constructor(
    public payload: {
      taskId: number;
      taskGoalYearLevels: TaskGoalYearLevelInterface[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}
export class SetTaskGoalYearLevelsForTask implements Action {
  readonly type = TaskGoalYearLevelsActionTypes.SetTaskGoalYearLevelsForTask;

  constructor(public payload: { taskId: number; taskGoalYearLevels: TaskGoalYearLevelInterface[] }) {}
}

export class TaskGoalYearLevelsLoaded implements Action {
  readonly type = TaskGoalYearLevelsActionTypes.TaskGoalYearLevelsLoaded;

  constructor(public payload: { taskGoalYearLevels: TaskGoalYearLevelInterface[] }) {}
}

export class TaskGoalYearLevelsLoadError implements Action {
  readonly type = TaskGoalYearLevelsActionTypes.TaskGoalYearLevelsLoadError;
  constructor(public payload: any) {}
}

export class AddTaskGoalYearLevel implements Action {
  readonly type = TaskGoalYearLevelsActionTypes.AddTaskGoalYearLevel;

  constructor(public payload: { taskGoalYearLevel: TaskGoalYearLevelInterface }) {}
}

export class UpsertTaskGoalYearLevel implements Action {
  readonly type = TaskGoalYearLevelsActionTypes.UpsertTaskGoalYearLevel;

  constructor(public payload: { taskGoalYearLevel: TaskGoalYearLevelInterface }) {}
}

export class AddTaskGoalYearLevels implements Action {
  readonly type = TaskGoalYearLevelsActionTypes.AddTaskGoalYearLevels;

  constructor(public payload: { taskGoalYearLevels: TaskGoalYearLevelInterface[] }) {}
}

export class UpsertTaskGoalYearLevels implements Action {
  readonly type = TaskGoalYearLevelsActionTypes.UpsertTaskGoalYearLevels;

  constructor(public payload: { taskGoalYearLevels: TaskGoalYearLevelInterface[] }) {}
}

export class UpdateTaskGoalYearLevel implements Action {
  readonly type = TaskGoalYearLevelsActionTypes.UpdateTaskGoalYearLevel;

  constructor(public payload: { taskGoalYearLevel: Update<TaskGoalYearLevelInterface> }) {}
}

export class UpdateTaskGoalYearLevels implements Action {
  readonly type = TaskGoalYearLevelsActionTypes.UpdateTaskGoalYearLevels;

  constructor(public payload: { taskGoalYearLevels: Update<TaskGoalYearLevelInterface>[] }) {}
}

export class DeleteTaskGoalYearLevel implements Action {
  readonly type = TaskGoalYearLevelsActionTypes.DeleteTaskGoalYearLevel;

  constructor(public payload: { id: number }) {}
}

export class DeleteTaskGoalYearLevels implements Action {
  readonly type = TaskGoalYearLevelsActionTypes.DeleteTaskGoalYearLevels;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearTaskGoalYearLevels implements Action {
  readonly type = TaskGoalYearLevelsActionTypes.ClearTaskGoalYearLevels;
}

export type TaskGoalYearLevelsActions =
  | LoadTaskGoalYearLevels
  | TaskGoalYearLevelsLoaded
  | TaskGoalYearLevelsLoadError
  | AddTaskGoalYearLevel
  | UpsertTaskGoalYearLevel
  | AddTaskGoalYearLevels
  | UpsertTaskGoalYearLevels
  | UpdateTaskGoalYearLevel
  | UpdateTaskGoalYearLevels
  | DeleteTaskGoalYearLevel
  | DeleteTaskGoalYearLevels
  | ClearTaskGoalYearLevels
  | LoadTaskGoalYearLevelsForTask
  | AddTaskGoalYearLevelsForTask
  | StartSetTaskGoalYearLevelsForTask
  | SetTaskGoalYearLevelsForTask;
