import { Action } from '@ngrx/store';
import { TocDataResponseInterface } from '../../../data/data.service.interface';

export enum TocDataActionTypes {
  LoadTocData = '[Data] Load Toc Data',
  TocDataLoaded = '[Data] Toc Data Loaded',
  TocDataLoadError = '[Data] Toc Data Load Error',
}

export class TocDataLoadError implements Action {
  readonly type = TocDataActionTypes.TocDataLoadError;
  constructor(public payload: any) {}
}

export class LoadTocData implements Action {
  readonly type = TocDataActionTypes.LoadTocData;

  constructor(
    public payload: {
      tocId: number;
      fields: Array<keyof TocDataResponseInterface>;
      force?: boolean;
    }
  ) {}
}

export class TocDataLoaded implements Action {
  readonly type = TocDataActionTypes.TocDataLoaded;

  constructor(
    public payload: {
      tocId: number;
    }
  ) {}
}
