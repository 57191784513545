import { groupArrayByKey } from '@campus/utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BadgeInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './badge.reducer';

export const selectBadgeState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectBadgeState, (state: State) => state.error);

export const getLoaded = createSelector(selectBadgeState, (state: State) => state.loaded);

export const getAll = createSelector(selectBadgeState, selectAll);

export const getCount = createSelector(selectBadgeState, selectTotal);

export const getIds = createSelector(selectBadgeState, selectIds);

export const getAllEntities = createSelector(selectBadgeState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * badge$: BadgeInterface[] = this.store.pipe(
    select(BadgeQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectBadgeState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * badge$: BadgeInterface = this.store.pipe(
    select(BadgeQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectBadgeState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getByCurriculumTreeIdAndCode = createSelector(
  getAll,
  (badges: BadgeInterface[], props: { curriculumTreeId: string; code: string }) =>
    badges.find((badge) => badge.curriculumTreeId === props.curriculumTreeId && badge.code === props.code)
);

export const getByCurriculumTreeGuid = createSelector(getAll, (badges: BadgeInterface[]) =>
  groupArrayByKey(badges, 'curriculumTreeId')
);
