import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PurchaseInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './purchase.reducer';

export const selectPurchaseState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectPurchaseState, (state: State) => state.error);

export const getLoaded = createSelector(selectPurchaseState, (state: State) => state.loaded);

export const getAll = createSelector(selectPurchaseState, selectAll);

export const getCount = createSelector(selectPurchaseState, selectTotal);

export const getIds = createSelector(selectPurchaseState, selectIds);

export const getAllEntities = createSelector(selectPurchaseState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * purchase$: PurchaseInterface[] = this.store.pipe(
    select(PurchaseQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectPurchaseState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * purchase$: PurchaseInterface = this.store.pipe(
    select(PurchaseQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectPurchaseState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getBySchoolId = createSelector(
  getAll,
  (purchases) => groupArrayByKeys(purchases, ['schoolId']) as Dictionary<PurchaseInterface[]>
);

export const getByAtlasStatus = createSelector(
  getAll,
  (purchases) => groupArrayByKeys(purchases, ['atlasStatus']) as Dictionary<PurchaseInterface[]>
);
