import { Injectable, InjectionToken } from '@angular/core';
import {
  DalState,
  DidacticFunctionQueries,
  EduContentProductTypeQueries,
  LearningDomainQueries,
  MethodQueries,
} from '@campus/dal';
import {
  CheckboxListFilterComponent,
  SearchFilterCriteriaInterface,
  SearchFilterFactory,
  SearchFilterInterface,
  SearchStateInterface,
} from '@campus/search';
import { MemoizedSelector, select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterQueryInterface } from '../filter-query.interface';

export const GLOBAL_FILTER_FACTORY_TOKEN = new InjectionToken('GlobalFilterFactory');

@Injectable({
  providedIn: 'root',
})
export class GlobalFilterFactory implements SearchFilterFactory {
  public readonly maxVisibleItems = 5;

  private keyProperty = 'id';
  private displayProperty = 'name';
  private component = CheckboxListFilterComponent;
  private domHost = 'hostLeft';

  protected filterSortOrder = ['methods', 'years', 'learningDomains', 'eduContentProductType', 'didacticFunctions'];

  protected filterQueries: {
    [key: string]: FilterQueryInterface;
  } = {
    eduContentProductType: {
      query: EduContentProductTypeQueries.getAllOrderedBySequence,
      name: 'eduContentProductType',
      label: 'Type',
      options: { maxVisibleItems: this.maxVisibleItems },
    },
    didacticFunctions: {
      query: DidacticFunctionQueries.getAll,
      name: 'didacticFunctions',
      label: 'Didactische functie',
      options: { maxVisibleItems: this.maxVisibleItems },
    },
    methods: {
      query: MethodQueries.getAllowedMethods,
      name: 'methods',
      label: 'Methode',
      options: {
        maxVisibleItems: this.maxVisibleItems,
      },
    },
    learningDomains: {
      query: LearningDomainQueries.getAll,
      name: 'learningDomains',
      label: 'Leerdomein',
      options: { maxVisibleItems: this.maxVisibleItems },
    },
    years: {
      query: MethodQueries.getYearsForAllowedMethods,
      name: 'years',
      label: 'Jaar',
      component: CheckboxListFilterComponent,
    },
  };

  constructor(public store: Store<DalState>) {}

  public getFilters(searchState: SearchStateInterface): Observable<SearchFilterInterface[]> {
    return combineLatest(this.createFilters(searchState)).pipe(
      map((searchFilters) =>
        searchFilters
          .filter((f) => {
            return f.criteria.values.length > 0;
          })
          .sort((a, b) => this.filterSorter(a, b, this.filterSortOrder))
      )
    );
  }

  public getPredictionFilterNames(): string[] {
    return Object.values(this.filterQueries).map((value) => value.name);
  }

  protected createFilters(searchState: SearchStateInterface): Observable<SearchFilterInterface>[] {
    const filters = Object.values(this.filterQueries).map((fQ) => this.buildFilter(fQ.name, searchState));

    return filters;
  }

  private getFilter<T>(
    entities: T[],
    filterQuery: FilterQueryInterface,
    searchState: SearchStateInterface
  ): SearchFilterInterface {
    const searchFilter = {
      criteria: {
        name: filterQuery.name,
        label: filterQuery.label,
        keyProperty: this.keyProperty,
        displayProperty: filterQuery.displayProperty || this.displayProperty,
        values: entities.map((entity) => ({
          data: entity,
          visible: true,
          child: (entity as any).children
            ? this.getFilter((entity as any).children, filterQuery, searchState).criteria
            : undefined,
        })),
      },
      component: filterQuery.component || this.component,
      domHost: filterQuery.domHost || this.domHost,
    } as SearchFilterInterface;
    if (filterQuery.options) searchFilter.options = filterQuery.options;
    return searchFilter;
  }

  private filterSorter(a: SearchFilterInterface, b: SearchFilterInterface, order: string[]): number {
    let aIndex = order.indexOf((a.criteria as SearchFilterCriteriaInterface).name);
    aIndex = aIndex === -1 ? order.length : aIndex; // not found -> add at end

    let bIndex = order.indexOf((b.criteria as SearchFilterCriteriaInterface).name);
    bIndex = bIndex === -1 ? order.length : bIndex; // not found -> add at end

    return aIndex - bIndex;
  }

  protected buildFilter(name: string, searchState: SearchStateInterface): Observable<SearchFilterInterface> {
    const filterQuery = this.filterQueries[name];
    return this.store.pipe(
      select(filterQuery.query as MemoizedSelector<Object, any[]>),
      map((entities) => {
        return this.getFilter(entities, filterQuery, searchState);
      })
    );
  }
}
