import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'campus-search-term',
  templateUrl: './search-term.component.html',
  styleUrls: ['./search-term.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchTermComponent implements OnInit {
  @HostBinding('class')
  defaultClasses = ['search-term', 'flex-1', 'mat-form-field-no-hint'];

  @HostBinding('attr.data-cy') dataCyTag = 'search-term-filter';

  @Input() public initialValue = '';
  @Input() public placeholder = 'Zoeken';
  @Input() public autofocus = false;
  @Input() public showMagnifier = false;
  @Input() public showReset = false;
  @Input() public emptyValueOnReset = false;
  @Input() public disabled = false;

  // boolean => internal reference, need array of values
  // string => external reference TODO: figure this out, when required
  @Input() public autoComplete: string | boolean = true;
  @Input() public autoCompleteValues: string[] = [];
  @Input() public emitOnTextChange = false;

  @HostBinding('class.search-term--rounded')
  @Input()
  public rounded: boolean;

  public currentValue: string;

  @Output() public valueChange = new EventEmitter<string>();
  @Output() public valueChangeForAutoComplete = new EventEmitter<string>();

  constructor(@Inject(ChangeDetectorRef) private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.currentValue = this.initialValue;
  }

  onChange() {
    this.valueChange.emit(this.currentValue || '');
  }

  getAutoCompleteValues() {
    if (this.currentValue.length < 2 || !this.autoComplete) return;

    this.valueChangeForAutoComplete.emit(this.currentValue);
  }

  onTextChange() {
    if (this.emitOnTextChange) {
      this.onChange();
    }
  }

  getAutoFocusValue() {
    return this.autofocus ? 'autofocus' : null;
  }

  reset(emit = true) {
    this.currentValue = this.emptyValueOnReset ? '' : this.initialValue;
    this.cd.markForCheck();

    if (emit) {
      this.valueChange.emit(this.currentValue || '');
    }
  }
}
