import { InjectionToken } from '@angular/core';
import { StudentTaskInterface } from '../../../interfaces';
import { TaskActionInterface } from '../task-action.interface';
import { TaskActionDictionary } from '../teacher';

export interface TaskActionsStudentServiceInterface {
  taskActionDictionary: TaskActionDictionary;
  getActions(task: StudentTaskInterface): TaskActionInterface[];
}

export interface StudentTaskOpenerInterface {
  openTask: (props: { taskInstanceId: number }) => void;
}

export const STUDENT_TASK_OPENER_TOKEN = new InjectionToken<StudentTaskOpenerInterface>('StudentTaskOpener');

export const TASK_ACTIONS_STUDENT_SERVICE_TOKEN = new InjectionToken<TaskActionsStudentServiceInterface>(
  'TaskActionsStudentService'
);
