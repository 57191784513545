import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { GroupPersonInterface } from '../../+models';
import { GroupsActions, GroupsActionTypes } from '../group/group.actions';
import { GroupPersonsActions, GroupPersonsActionTypes } from './group-person.actions';

export const NAME = 'groupPersons';

export interface State extends EntityState<GroupPersonInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<GroupPersonInterface> = createEntityAdapter<GroupPersonInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: GroupPersonsActions | GroupsActions): State {
  switch (action.type) {
    case GroupPersonsActionTypes.AddGroupPerson: {
      return adapter.addOne(action.payload.groupPerson, state);
    }

    case GroupPersonsActionTypes.UpsertGroupPerson: {
      return adapter.upsertOne(action.payload.groupPerson, state);
    }

    case GroupPersonsActionTypes.AddGroupPersons: {
      return adapter.addMany(action.payload.groupPersons, state);
    }

    case GroupPersonsActionTypes.UpsertGroupPersons: {
      return adapter.upsertMany(action.payload.groupPersons, state);
    }

    case GroupPersonsActionTypes.UpdateGroupPerson: {
      return adapter.updateOne(action.payload.groupPerson, state);
    }

    case GroupPersonsActionTypes.UpdateGroupPersons: {
      return adapter.updateMany(action.payload.groupPersons, state);
    }

    case GroupPersonsActionTypes.DeleteGroupPerson: {
      return adapter.removeOne(action.payload.id, state);
    }

    case GroupPersonsActionTypes.DeleteGroupPersons: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case GroupPersonsActionTypes.GroupPersonsLoaded: {
      return adapter.setAll(action.payload.groupPersons, { ...state, loaded: true });
    }

    case GroupPersonsActionTypes.GroupPersonsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case GroupPersonsActionTypes.ClearGroupPersons: {
      return adapter.removeAll(state);
    }

    case GroupPersonsActionTypes.SetForGroup: {
      const { groupId, groupPersons } = action.payload;
      const idsToDelete = (state.ids as number[]).filter((id) => state.entities[id].groupId === groupId);
      const newState: State = adapter.removeMany(idsToDelete, state);
      return adapter.addMany(groupPersons, newState);
    }

    case GroupPersonsActionTypes.SetForStudent: {
      const { studentId, groupPersons } = action.payload;
      const idsToDelete = (state.ids as number[]).filter((id) => state.entities[id].personId === studentId);
      const newState: State = adapter.removeMany(idsToDelete, state);
      return adapter.addMany(groupPersons, newState);
    }

    case GroupsActionTypes.DeleteGroup: {
      const { id: groupId } = action.payload;
      const idsToDelete = (state.ids as number[]).filter((id) => state.entities[id].groupId === groupId);
      return adapter.removeMany(idsToDelete, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
