import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { SchoolRoleMappingClassGroupInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum SchoolRoleMappingClassGroupsActionTypes {
  SchoolRoleMappingClassGroupsLoaded = '[SchoolRoleMappingClassGroups] SchoolRoleMappingClassGroups Loaded',
  SchoolRoleMappingClassGroupsLoadError = '[SchoolRoleMappingClassGroups] Load Error',
  LinkTeacherRolesClassGroup = '[SchoolRoleMappingClassGroups] Link Teacher Roles ClassGroup',
  LinkStudentRolesClassGroup = '[SchoolRoleMappingClassGroups] Link Student Roles ClassGroup',
  MoveStudentRolesClassGroup = '[SchoolRoleMappingClassGroups] Move Student Roles ClassGroup',
  AddSchoolRoleMappingClassGroups = '[SchoolRoleMappingClassGroups] Add SchoolRoleMappingClassGroups',
  DeleteSchoolRoleMappingClassGroups = '[SchoolRoleMappingClassGroups] Delete SchoolRoleMappingClassGroups',
  UnlinkRolesClassGroup = '[SchoolRoleMappingClassGroups] Unlink Roles ClassGroup',
  AddSchoolRoleMappingClassGroup = '[SchoolRoleMappings] Add SchoolRoleMappingClassGroup',
  UpdateSchoolRoleMappingClassGroup = '[SchoolRoleMappings] Update SchoolRoleMappingClassGroup',
  DeleteSchoolRoleMappingClassGroup = '[SchoolRoleMappings] Delete SchoolRoleMappingClassGroup',
  UpsertSchoolRoleMappingClassGroup = '[SchoolRoleMappings] Upsert SchoolRoleMappingClassGroup',
  UpsertSchoolRoleMappingClassGroups = '[SchoolRoleMappings] Upsert SchoolRoleMappingClassGroups',
}

export class SchoolRoleMappingClassGroupsLoaded implements Action {
  readonly type = SchoolRoleMappingClassGroupsActionTypes.SchoolRoleMappingClassGroupsLoaded;

  constructor(
    public payload: {
      schoolRoleMappingClassGroups: SchoolRoleMappingClassGroupInterface[];
    }
  ) {}
}

export class SchoolRoleMappingClassGroupsLoadError implements Action {
  readonly type = SchoolRoleMappingClassGroupsActionTypes.SchoolRoleMappingClassGroupsLoadError;
  constructor(public payload: any) {}
}

export class AddSchoolRoleMappingClassGroup implements Action {
  readonly type = SchoolRoleMappingClassGroupsActionTypes.AddSchoolRoleMappingClassGroup;

  constructor(
    public payload: {
      schoolRoleMappingClassGroup: SchoolRoleMappingClassGroupInterface;
    }
  ) {}
}
export class AddSchoolRoleMappingClassGroups implements Action {
  readonly type = SchoolRoleMappingClassGroupsActionTypes.AddSchoolRoleMappingClassGroups;

  constructor(
    public payload: {
      schoolRoleMappingClassGroups: SchoolRoleMappingClassGroupInterface[];
    }
  ) {}
}

export class UpdateSchoolRoleMappingClassGroup implements FeedbackTriggeringAction {
  readonly type = SchoolRoleMappingClassGroupsActionTypes.UpdateSchoolRoleMappingClassGroup;

  constructor(
    public payload: {
      schoolId: number;
      schoolRoleMappingClassGroup: Update<SchoolRoleMappingClassGroupInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpsertSchoolRoleMappingClassGroup implements Action {
  readonly type = SchoolRoleMappingClassGroupsActionTypes.UpsertSchoolRoleMappingClassGroup;

  constructor(
    public payload: {
      schoolRoleMappingClassGroup: SchoolRoleMappingClassGroupInterface;
    }
  ) {}
}

export class UpsertSchoolRoleMappingClassGroups implements Action {
  readonly type = SchoolRoleMappingClassGroupsActionTypes.UpsertSchoolRoleMappingClassGroups;

  constructor(
    public payload: {
      schoolRoleMappingClassGroups: SchoolRoleMappingClassGroupInterface[];
    }
  ) {}
}

export class DeleteSchoolRoleMappingClassGroup implements FeedbackTriggeringAction {
  readonly type = SchoolRoleMappingClassGroupsActionTypes.DeleteSchoolRoleMappingClassGroup;

  constructor(
    public payload: {
      schoolId: number;
      id: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class DeleteSchoolRoleMappingClassGroups implements Action {
  readonly type = SchoolRoleMappingClassGroupsActionTypes.DeleteSchoolRoleMappingClassGroups;

  constructor(
    public payload: {
      ids: number[];
    }
  ) {}
}

export class LinkTeacherRolesClassGroup implements FeedbackTriggeringAction {
  readonly type = SchoolRoleMappingClassGroupsActionTypes.LinkTeacherRolesClassGroup;

  constructor(
    public payload: {
      schoolRoleMappingIds: number[];
      classGroupId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class LinkStudentRolesClassGroup implements FeedbackTriggeringAction {
  readonly type = SchoolRoleMappingClassGroupsActionTypes.LinkStudentRolesClassGroup;

  constructor(
    public payload: {
      schoolRoleMappingData: {
        schoolRoleMappingId: number;
        classNumber?: number;
      }[];
      classGroupId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}
export class MoveStudentRolesClassGroup implements FeedbackTriggeringAction {
  readonly type = SchoolRoleMappingClassGroupsActionTypes.MoveStudentRolesClassGroup;

  constructor(
    public payload: {
      schoolRoleMappingData: {
        schoolRoleMappingId: number;
        classNumber?: number;
      }[];
      classGroupId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UnlinkRolesClassGroup implements FeedbackTriggeringAction {
  readonly type = SchoolRoleMappingClassGroupsActionTypes.UnlinkRolesClassGroup;

  constructor(
    public payload: {
      schoolRoleMappingIds: number[];
      classGroupId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export type SchoolRoleMappingClassGroupsActions =
  | SchoolRoleMappingClassGroupsLoaded
  | SchoolRoleMappingClassGroupsLoadError
  | AddSchoolRoleMappingClassGroup
  | AddSchoolRoleMappingClassGroups
  | UpdateSchoolRoleMappingClassGroup
  | DeleteSchoolRoleMappingClassGroup
  | DeleteSchoolRoleMappingClassGroups
  | UpsertSchoolRoleMappingClassGroup
  | UpsertSchoolRoleMappingClassGroups
  | LinkTeacherRolesClassGroup
  | LinkStudentRolesClassGroup
  | MoveStudentRolesClassGroup
  | UnlinkRolesClassGroup;
