import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DalState, RolesEnum, UserActions, UserQueries } from '@campus/dal';
import {
  EnvironmentProfileInterface,
  EnvironmentTermPrivacyInterface,
  ENVIRONMENT_PROFILE_TOKEN,
  ENVIRONMENT_TERM_PRIVACY_TOKEN,
} from '@campus/environment';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class CompleteProfileGuard implements CanActivate {
  constructor(
    private store: Store<DalState>,
    private router: Router,
    @Inject(ENVIRONMENT_PROFILE_TOKEN) private environmentProfile: EnvironmentProfileInterface,
    @Optional()
    @Inject(ENVIRONMENT_TERM_PRIVACY_TOKEN)
    private environmentTermPrivacy: EnvironmentTermPrivacyInterface
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean> | boolean {
    if (
      [this.environmentProfile?.url, this.environmentTermPrivacy?.url]
        .filter((url) => !!url)
        .some((url) => state.url?.startsWith(url))
    ) {
      return true;
    }

    this.store.dispatch(new UserActions.LoadUser({}));

    return this.store.pipe(select(UserQueries.getCurrentUser)).pipe(
      filter((user) => !!user),
      map((user) => {
        const isSchoolAdmin = (user.types || []).includes(RolesEnum.Schooladmin);
        const profileCompleted = user?.completeProfile?.checks?.schools?.check;
        if (isSchoolAdmin || profileCompleted) return true;

        return this.router.parseUrl(this.environmentProfile.url);
      })
    );
  }
}
