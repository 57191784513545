<div class="[ p-i-s p-b-xs ] [ body-medium ] [ index-surface ] [ corner-l ]">
  <ng-content> </ng-content>
</div>
<div class="[ pointer ] [ absolute w-0 h-0 ] [ index-pullback ]" [attr.data-side]="side"></div>
<div class="[ inset-pointer ] [ absolute w-0 h-0 ] [ index-pullback ]" [attr.data-side]="side"></div>
<div class="absolute bottom-none left-xs right-xs">
  <div
    *ngIf="progress"
    class="[ imposter ] [ progress ] [ primary p-b-3xs corner-full ] [ transition-dimension ]"
    [style.width.%]="progress"
  ></div>
</div>
