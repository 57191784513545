import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { EduFileInterface, EduFileTypeEnum, StorageInfoInterface } from '../+models';

export const EDU_FILE_SERVICE_TOKEN = new InjectionToken<EduFileServiceInterface>('EduFileService');

export interface EduFileServiceInterface {
  getAll(): Observable<EduFileInterface[]>;

  getAllArtifacts(): Observable<EduFileInterface[]>;

  uploadFile(eduContentId: number, file: File, type?: EduFileTypeEnum): Observable<StorageInfoInterface>;

  downloadFile(eduContentMetadataId: number, type: EduFileTypeEnum): void;

  deleteFile(eduFileId: number, eduContentMetadataId: number): Observable<boolean>;
}
