import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { DidacticFunctionInterface } from '../../+models/DidacticFunction.interface';
import { DidacticFunctionsActions, DidacticFunctionsActionTypes } from './didactic-function.actions';

export const NAME = 'didacticFunctions';

export interface State extends EntityState<DidacticFunctionInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export function sortDidacticFunctions(a: DidacticFunctionInterface, b: DidacticFunctionInterface): number {
  return a.sequence - b.sequence;
}

export const adapter: EntityAdapter<DidacticFunctionInterface> = createEntityAdapter<DidacticFunctionInterface>({
  sortComparer: sortDidacticFunctions,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: DidacticFunctionsActions): State {
  switch (action.type) {
    case DidacticFunctionsActionTypes.DidacticFunctionsLoaded: {
      return adapter.setAll(action.payload.didacticFunctions, {
        ...state,
        loaded: true,
      });
    }

    case DidacticFunctionsActionTypes.DidacticFunctionsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
