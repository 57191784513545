import { groupArrayByKey, groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EvaluationScoreInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './evaluation-score.reducer';

export const selectEvaluationScoreState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectEvaluationScoreState, (state: State) => state.error);

export const getLoaded = createSelector(selectEvaluationScoreState, (state: State) => state.loaded);

export const getAll = createSelector(selectEvaluationScoreState, selectAll);

export const getCount = createSelector(selectEvaluationScoreState, selectTotal);

export const getIds = createSelector(selectEvaluationScoreState, selectIds);

export const getAllEntities = createSelector(selectEvaluationScoreState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * evaluationScore$: EvaluationScoreInterface[] = this.store.pipe(
    select(EvaluationScoreQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectEvaluationScoreState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns an EvaluationScore with the given id
 * @example
 * evaluationScore$: EvaluationScoreInterface = this.store.pipe(
    select(EvaluationScoreQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectEvaluationScoreState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const isTaskLoaded = (props: { taskId: number }) =>
  createSelector(selectEvaluationScoreState, (state: State) => !!state.loadedForTask[props.taskId]);

export const getByEvaluationScoreListSubjectId = createSelector(getAll, (evaluationScores) =>
  groupArrayByKey(evaluationScores, 'evaluationScoreListSubjectId')
);

export const getByPersonIdEvaluationScoreListSubjectId = createSelector(
  getAll,
  (evaluationScores) =>
    groupArrayByKeys(evaluationScores, ['personId', 'evaluationScoreListSubjectId'], null, true) as Dictionary<
      Dictionary<EvaluationScoreInterface>
    >
);

export const isSaving = createSelector(selectEvaluationScoreState, (state: State) => state.saving);
