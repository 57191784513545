import { Action } from '@ngrx/store';
import { MethodInterface } from '../../+models';

export enum InkMethodsActionTypes {
  InkMethodsLoaded = '[Methods-Ink] Methods-Ink Loaded',
  InkMethodsLoadError = '[Methods-Ink] Load Error',
  LoadInkMethods = '[Methods-Ink] Load Methods-Ink',
  ClearInkMethods = '[Methods-Ink] Clear Methods-Ink',
}

export class LoadInkMethods implements Action {
  readonly type = InkMethodsActionTypes.LoadInkMethods;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class InkMethodsLoaded implements Action {
  readonly type = InkMethodsActionTypes.InkMethodsLoaded;

  constructor(public payload: { inkMethods: MethodInterface[] }) {}
}

export class InkMethodsLoadError implements Action {
  readonly type = InkMethodsActionTypes.InkMethodsLoadError;
  constructor(public payload: any) {}
}

export class ClearInkMethods implements Action {
  readonly type = InkMethodsActionTypes.ClearInkMethods;
}

export type InkMethodsActions = LoadInkMethods | InkMethodsLoaded | InkMethodsLoadError | ClearInkMethods;
