import { Inject, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { fromEvent, interval, Observable, Subject } from 'rxjs';
import { filter, first, map, mapTo, tap } from 'rxjs/operators';
import { IframeComponent } from '../iframe/iframe.component';
import { WINDOW } from './window';
import { WindowServiceInterface } from './window.service.interface';

interface PersonInterface {
  email?: string;
  displayName?: string;
}

/**
 * Class window service is used to add additional services to the native window.
 * When you need native window functionality, inject WINDOW instead
 */
@Injectable({
  providedIn: 'root',
})
export class WindowService implements WindowServiceInterface {
  private _openedWindows: { [name: string]: Window } = {};
  private _closedDialog$ = new Subject<any>(); // subject with dialog response
  private _closedWindow$ = new Subject<string>(); // subject with window name
  private _origin = new URL(document.location.href).origin;

  constructor(@Inject(WINDOW) private nativeWindow, public dialog: MatDialog) {}

  openWindow(name: string, url: string, useIframe: boolean = false, permissions = [], currentUser?: PersonInterface) {
    if (useIframe) {
      const data = { url, permissions, currentUser };
      const dialogConfig = { data, panelClass: 'ui-iframe--fullscreen' };
      return this.openDialog(IframeComponent, dialogConfig);
    }

    const openedWindow: Window = this.nativeWindow.open(url, name);
    if (!name || this._openedWindows[name] === openedWindow) return;

    this._openedWindows[name] = openedWindow;

    const windowClosed$ = this.getWindowClosed$(url, openedWindow);

    windowClosed$.subscribe((_) => this._closedWindow$.next(name));
  }

  openDialog(component: any, dialogConfig: any) {
    const dialogRef = this.dialog.open(component, dialogConfig);

    if (dialogConfig.ignoreDocumentScrollBlocked) {
      /*
       * Temporarily resets document scroll position to (0,0) while modal dialogs are open.
       * This workaround addresses an interaction between CDK overlay and draggable elements:
       * 1. CDK overlay containers enforce scroll locking, which can disrupt UI elements
       *    that require document-relative positioning (e.g., Learnosity drag-and-drop features)
       * 2. Native scroll position preservation causes coordinate miscalculations for
       *    draggables that rely on absolute document top/left positioning
       * 3. We store the original scroll position at dialog open time and temporarily
       *    reset it to (0,0) to maintain consistent drag positioning coordinates
       * 4. Original scroll position is restored when the dialog closes
       */

      let left: string;
      let top: string;

      dialogRef.afterOpened().subscribe(() => {
        const computed = getComputedStyle(document.documentElement);
        left = computed.left;
        top = computed.top;

        document.documentElement.style.left = '0px';
        document.documentElement.style.top = '0px';
      });
      dialogRef.beforeClosed().subscribe(() => {
        document.documentElement.style.left = left;
        document.documentElement.style.top = top;
      });
    }

    dialogRef
      .afterClosed()
      .pipe(tap((result) => this._closedDialog$.next(result)))
      .subscribe();
  }

  private getWindowClosed$(url: string, openedWindow: Window) {
    const sameDomain = new URL(url, this._origin).origin === this._origin;
    if (sameDomain) {
      return fromEvent(openedWindow, 'onunload').pipe(mapTo(true), first());
    }

    // because the onunload event can only be caught for urls on the same domain,
    // we poll the window.closed property instead
    return interval(1000).pipe(
      map(() => openedWindow.closed),
      filter((isClosed) => isClosed),
      first()
    );
  }

  closeWindow(name: string) {
    if (this._openedWindows[name]) {
      this._openedWindows[name].close();
      delete this._openedWindows[name];
    }
  }

  get openedWindows(): { [name: string]: Window } {
    return this._openedWindows;
  }

  get closedDialog$(): Observable<{ [name: string]: any }> {
    return this._closedDialog$.asObservable();
  }

  get closedWindow$(): Observable<string> {
    return this._closedWindow$.asObservable();
  }
}
