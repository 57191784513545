import { Injector } from '@angular/core';
import { PersonProductQueries, ProductQueries, UnlockedFreePracticeQueries } from '@campus/dal';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { BooksTileComponentInterface } from '../../components';
import { BooksTileService, BooksTileServiceConfigInterface } from './books-tile.service';

export class PolpoBooksTileService extends BooksTileService {
  constructor(protected injector: Injector) {
    super(injector);
    this.store = injector.get(Store);
  }

  public getBookTiles$(
    tileFilter?: (data) => boolean,
    config: BooksTileServiceConfigInterface = { filterUnlockedBooks: true }
  ): Observable<BooksTileComponentInterface[]> {
    return combineLatest([
      this.store.select(UnlockedFreePracticeQueries.getGroupedByEduContentBookId),
      this.store.select(PersonProductQueries.getProductsWithMethod),
      this.store.select(ProductQueries.getBooksByProduct),
      this.store.select(ProductQueries.getMarketingHighlightBooksByProduct),
    ]).pipe(
      map(([ufpByBookIdDict, personProducts, booksByProductId, marketingBooksByProductId]) => {
        const filteredPersonProducts = tileFilter ? personProducts.filter(tileFilter) : personProducts;
        const booksTiles = filteredPersonProducts.map((product) => {
          let booksForProduct =
            (product.isMarketingHighlight ? marketingBooksByProductId[product.id] : booksByProductId[product.id]) || [];

          if (config.filterUnlockedBooks) {
            // filter out books that haven't been unlocked via UFP
            booksForProduct = booksForProduct.filter((book) => !!ufpByBookIdDict[book.id]);
          }

          const booksTile = this.mapToBooksTileWithoutActions(
            product.id,
            product.description,
            product.method,
            booksForProduct,
            product
          );

          return booksTile;
        });

        return booksTiles;
      })
    );
  }
}
