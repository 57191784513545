import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { EduContentBookInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum EduContentBooksActionTypes {
  LoadEduContentBooks = '[EduContentBooks] Load EduContentBooks',

  LoadEduContentBooksForMethod = '[EduContentBooks] Load EduContentBooks For Method',
  AddEduContentBooksForMethod = '[EduContentBooks] Add EduContentBooks For Method',

  EduContentBooksLoaded = '[EduContentBooks] EduContentBooks Loaded',
  EduContentBooksLoadError = '[EduContentBooks] Load Error',

  UpdateEduContentBook = '[EduContentBooks] Update EduContentBook',
  ClearEduContentBooks = '[EduContentBooks] Clear EduContentBooks',
  ClearEduContentTocsFromBook = '[EduContentBooks] Clear EduContents from Book',
  UnlinkEduContentTocsFromBook = '[EduContentBooks] Unlink EduContentTocEduContentMetadatas from Book',
  LoadEduContentBooksFromIds = '[EduContentBooks] Load EduContentBooks from Ids',
}

export class LoadEduContentBooks implements Action {
  readonly type = EduContentBooksActionTypes.LoadEduContentBooks;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class LoadEduContentBooksForMethod implements Action {
  readonly type = EduContentBooksActionTypes.LoadEduContentBooksForMethod;

  constructor(public payload: { methodId: number }) {}
}

export class AddEduContentBooksForMethod implements Action {
  readonly type = EduContentBooksActionTypes.AddEduContentBooksForMethod;

  constructor(
    public payload: {
      methodId: number;
      eduContentBooks: EduContentBookInterface[];
    }
  ) {}
}

export class LoadEduContentBooksFromIds implements Action {
  readonly type = EduContentBooksActionTypes.LoadEduContentBooksFromIds;

  constructor(public payload: { force?: boolean; bookIds: number[] } = { bookIds: [] }) {}
}

export class EduContentBooksLoaded implements Action {
  readonly type = EduContentBooksActionTypes.EduContentBooksLoaded;

  constructor(public payload: { eduContentBooks: EduContentBookInterface[] }) {}
}

export class EduContentBooksLoadError implements Action {
  readonly type = EduContentBooksActionTypes.EduContentBooksLoadError;
  constructor(public payload: any) {}
}

export class UpdateEduContentBook implements FeedbackTriggeringAction {
  readonly type = EduContentBooksActionTypes.UpdateEduContentBook;

  constructor(
    public payload: {
      eduContentBook: Update<EduContentBookInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class ClearEduContentBooks implements Action {
  readonly type = EduContentBooksActionTypes.ClearEduContentBooks;
}

export class ClearEduContentsFromBook implements FeedbackTriggeringAction {
  readonly type = EduContentBooksActionTypes.ClearEduContentTocsFromBook;
  constructor(public payload: { id: number; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export class UnlinkEduContentTocEduContentMetadataFromBook implements FeedbackTriggeringAction {
  readonly type = EduContentBooksActionTypes.UnlinkEduContentTocsFromBook;
  constructor(public payload: { id: number; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export type EduContentBooksActions =
  | LoadEduContentBooks
  | EduContentBooksLoaded
  | EduContentBooksLoadError
  | LoadEduContentBooksFromIds
  | LoadEduContentBooksForMethod
  | AddEduContentBooksForMethod
  | UpdateEduContentBook
  | ClearEduContentBooks
  | ClearEduContentsFromBook
  | UnlinkEduContentTocEduContentMetadataFromBook;
