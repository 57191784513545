import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TaskEvaluationSubjectInterface } from '../../+models';
import { TaskEvaluationSubjectsActions, TaskEvaluationSubjectsActionTypes } from './task-evaluation-subject.actions';

export const NAME = 'taskEvaluationSubjects';

export interface State extends EntityState<TaskEvaluationSubjectInterface> {
  // additional entities state properties
  loaded: boolean;
  loadedForTask: {
    [taskId: number]: boolean;
  };
  saving: boolean;
  error?: any;
}

export const adapter: EntityAdapter<TaskEvaluationSubjectInterface> =
  createEntityAdapter<TaskEvaluationSubjectInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForTask: {},
  saving: false,
});

export function reducer(state = initialState, action: TaskEvaluationSubjectsActions): State {
  switch (action.type) {
    case TaskEvaluationSubjectsActionTypes.AddTaskEvaluationSubject: {
      return adapter.addOne(action.payload.taskEvaluationSubject, state);
    }

    case TaskEvaluationSubjectsActionTypes.UpsertTaskEvaluationSubject: {
      return adapter.upsertOne(action.payload.taskEvaluationSubject, state);
    }

    case TaskEvaluationSubjectsActionTypes.AddTaskEvaluationSubjectsForTask: {
      const newState = adapter.addMany(action.payload.taskEvaluationSubjects, state);

      return {
        ...newState,
        loadedForTask: {
          ...newState.loadedForTask,
          [action.payload.taskId]: true,
        },
      };
    }
    case TaskEvaluationSubjectsActionTypes.StartSetTaskEvaluationSubjectsForTask: {
      return { ...state, saving: true };
    }

    case TaskEvaluationSubjectsActionTypes.SetTaskEvaluationSubjectsForTask: {
      const toRemove = Object.values(state.entities)
        .filter((entity) => entity.taskId === action.payload.taskId)
        .map((e) => e.id);

      const clearedState = adapter.removeMany(toRemove, state);
      const newState = adapter.addMany(action.payload.taskEvaluationSubjects, clearedState);

      return {
        ...newState,
        loadedForTask: {
          ...state.loadedForTask,
          [action.payload.taskId]: true,
        },
        saving: false,
      };
    }

    case TaskEvaluationSubjectsActionTypes.AddTaskEvaluationSubjects: {
      return adapter.addMany(action.payload.taskEvaluationSubjects, state);
    }

    case TaskEvaluationSubjectsActionTypes.UpsertTaskEvaluationSubjects: {
      return adapter.upsertMany(action.payload.taskEvaluationSubjects, state);
    }

    case TaskEvaluationSubjectsActionTypes.UpdateTaskEvaluationSubject: {
      return adapter.updateOne(action.payload.taskEvaluationSubject, state);
    }

    case TaskEvaluationSubjectsActionTypes.UpdateTaskEvaluationSubjects: {
      return adapter.updateMany(action.payload.taskEvaluationSubjects, state);
    }

    case TaskEvaluationSubjectsActionTypes.DeleteTaskEvaluationSubject: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TaskEvaluationSubjectsActionTypes.DeleteTaskEvaluationSubjects: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TaskEvaluationSubjectsActionTypes.TaskEvaluationSubjectsLoaded: {
      return adapter.setAll(action.payload.taskEvaluationSubjects, { ...state, loaded: true });
    }

    case TaskEvaluationSubjectsActionTypes.TaskEvaluationSubjectsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case TaskEvaluationSubjectsActionTypes.ClearTaskEvaluationSubjects: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
