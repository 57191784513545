/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { AdaptemyApi as SDKAdaptemyApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { ContextInterface, LearnerProfileConceptInterface, LoopLearnerStatusInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class AdaptemyApi extends ModelApiBase {
  private SDKAdaptemyApi = inject(SDKAdaptemyApi);

  constructor(api: PolpoApi) {
    super(api, 'Adaptemy');
  }

  getLoopLearnerStatus(personId: number, context: ContextInterface): Observable<LoopLearnerStatusInterface> {
    // @ts-expect-error TS 2556
    return this.SDKAdaptemyApi.getLoopLearnerStatus(...arguments);
  }

  getReports(curriculum: string, course: string): Observable<LearnerProfileConceptInterface[]> {
    // @ts-expect-error TS 2556
    return this.SDKAdaptemyApi.getReports(...arguments);
  }
}
