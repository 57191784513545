import { InjectionToken } from '@angular/core';
import { BundleInterface } from '@campus/dal';
import { BundleActionInterface } from '../bundle-action.interface';

export interface BundleActionsStudentServiceInterface {
  bundleActionDictionary: { [key: string]: BundleActionInterface };
  getActions(bundle: BundleInterface): BundleActionInterface[];
}

export interface StudentBundleOpenerInterface {
  openBundle(props: { bundle: BundleInterface });
}

export const STUDENT_BUNDLE_OPENER_TOKEN = new InjectionToken<StudentBundleOpenerInterface>('StudentBundleOpener');
