import { Inject, Optional } from '@angular/core';
import { ENVIRONMENT_SCORE_MAPPING_TOKEN } from '@campus/environment';
import { ResultFunctions } from '@campus/utils';
import { ConceptInterface } from './Concept.interface';
import { EduContentInterface } from './EduContent.interface';
import { LearningAreaInterface } from './LearningArea.interface';
import { MixedResultInterface } from './MixedResult.interface';
import { PersonInterface } from './Person.interface';
import { BestResultInterface, ResultInterface, ResultStatusEnum } from './Result.interface';
import { TaskInterface } from './Task.interface';
import { TaskEduContentInterface } from './TaskEduContent.interface';
import { TaskInstanceInterface } from './TaskInstance.interface';
import { UnlockedContentInterface } from './UnlockedContent.interface';
import { UnlockedCurriculumTreeInterface } from './UnlockedCurriculumTree.interface';
import { UnlockedFreePracticeInterface } from './UnlockedFreePractice.interface';

export class Result implements ResultInterface {
  score?: number;
  time?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  status: any;
  cmi?: string;
  created?: Date;
  id?: number;
  eduContentId?: number;
  personId?: number;
  taskId?: number;
  unlockedContentId?: number;
  eduContent?: EduContentInterface;
  person?: PersonInterface;
  task?: TaskInterface;
  unlockedContent?: UnlockedContentInterface;
  learningAreaId: number;
  assignment: string;
  taskInstanceId: number;
  taskInstance?: TaskInstanceInterface;
  personDisplayName: string;
  bundleId: number;
  lastUpdated?: Date;
  unlockedFreePracticeId?: number;
  unlockedFreePractice?: UnlockedFreePracticeInterface;
  unlockedCurriculumTreeId?: number;
  unlockedCurriculumTree?: UnlockedCurriculumTreeInterface;
  conceptId?: string;
  concept?: ConceptInterface;
  taskEduContentId?: number;
  taskEduContent?: TaskEduContentInterface;

  constructor(@Optional() @Inject(ENVIRONMENT_SCORE_MAPPING_TOKEN) private scoreToStars?: [number, number][]) {}

  get stars(): number {
    return ResultFunctions.starsFromScore(this.score, undefined, this.scoreToStars);
  }

  get colorClass(): string {
    return ResultFunctions.getColorClass(this.score, undefined, this.scoreToStars);
  }

  get isAttempted(): boolean {
    return this.status !== ResultStatusEnum.STATUS_NOT_ATTEMPTED;
  }

  get learningArea(): LearningAreaInterface {
    return (
      !!this.eduContent &&
      !!this.eduContent.publishedEduContentMetadata &&
      this.eduContent.publishedEduContentMetadata.learningArea
    );
  }

  static toResult<T extends ResultInterface>(result: T): Result & T {
    return result && Object.assign(new Result(), result);
  }

  static toBestResult(attempts: ResultInterface[]): BestResultInterface {
    if (!(Array.isArray(attempts) && attempts.length)) return null;

    const { bestResult, attemptsSumOfTime } = attempts.reduce(
      (acc, attempt) => {
        if (!acc.bestResult) {
          acc.bestResult = attempt;
          acc.attemptsSumOfTime = attempt.time;
          return acc;
        }

        // FYI: `.time` can be null
        // javascript coerces null to 0 in maths
        acc.attemptsSumOfTime += attempt.time;

        // in case of equal score -> most recent === best
        if (acc.bestResult.score <= attempt.score) {
          acc.bestResult = attempt;
        }
        return acc;
      },
      { bestResult: undefined, attemptsSumOfTime: 0 } as {
        bestResult: ResultInterface;
        attemptsSumOfTime: number;
      }
    );

    return { ...bestResult, attempts, attemptsSumOfTime };
  }

  static toMixedResult(result: ResultInterface): MixedResultInterface<ResultInterface> {
    const { id, eduContent, lastUpdated, time, bundleId, taskId, status, personId, learnositySessionId } = result;

    const inBundle = !!bundleId;
    const inTask = !!taskId;

    const attempts = 'attempts' in result ? (result as BestResultInterface).attempts : undefined;

    return {
      id,
      type: 'result',
      learnositySessionId,
      eduContent,
      lastUpdated,
      time,
      inBundle,
      inTask,
      status,
      data: result,
      personId,
      attempts,
    };
  }

  static getMostRecentResult(attempts: ResultInterface[]): ResultInterface {
    return (attempts || []).reduce((acc, attempt) => {
      if (!acc) {
        acc = attempt;
        return acc;
      }
      if (acc.lastUpdated < attempt.lastUpdated) {
        acc = attempt;
      }
      return acc;
    }, null);
  }
}
