import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { PersonApi, ProductApi } from '../+api';
import { MethodProductGroupProductInterface, ProductContentInterface, ProductInterface } from '../+models';
import { DalOptions, DAL_OPTIONS } from '../dal.module';
import { ProductServiceInterface } from './product.service.interface';

@Injectable({
  providedIn: 'root',
})
export class ProductService implements ProductServiceInterface {
  private httpClient = inject(HttpClient);
  private options = inject<DalOptions>(DAL_OPTIONS);
  private personApi = inject(PersonApi);
  private productApi = inject(ProductApi);

  getAllForUser(userId: number): Observable<ProductInterface[]> {
    return this.personApi
      .getData(userId, 'products')
      .pipe(map((res: { products: ProductInterface[] }) => res.products));
  }

  getAll(): Observable<ProductInterface[]> {
    return this.productApi.find();
  }

  updateProduct(productId: number, update: Partial<ProductInterface>): Observable<ProductInterface> {
    return this.productApi.patchAttributes(productId, update) as Observable<ProductInterface>;
  }

  deleteProduct(productId: number): Observable<boolean> {
    return this.productApi.destroyProduct(productId).pipe(mapTo(true));
  }

  linkNewProductContent(
    productId: number,
    productContent: ProductContentInterface
  ): Observable<ProductContentInterface> {
    return this.productApi.createProductContents(productId, productContent) as Observable<ProductContentInterface>;
  }

  removeLinkedProductContent(productId: number, productContentId: number): Observable<boolean> {
    return this.productApi.destroyByIdProductContents(productId, productContentId).pipe(mapTo(true));
  }

  linkMethodProductGroup(
    productId: number,
    methodProductGroupId: number
  ): Observable<MethodProductGroupProductInterface> {
    return this.productApi.linkMethodProductGroups(
      productId,
      methodProductGroupId
    ) as Observable<MethodProductGroupProductInterface>;
  }

  removeLinkedMethodProductGroup(productId: number, methodProductGroupId: number): Observable<boolean> {
    return this.productApi.unlinkMethodProductGroups(productId, methodProductGroupId).pipe(mapTo(true));
  }

  getProducts(
    searchTerm?: string,
    filters?: { [key: string]: string | number[] | number },
    pagination?: { from?: number; amount?: number },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<{ results: any[]; count: number }> {
    const { apiBaseUrl } = this.options;
    const url = `${apiBaseUrl}/api/products/filtered`;
    const params = new HttpParams({
      fromObject: {
        searchTerm: searchTerm || '',
        filters: JSON.stringify(filters || {}),
        columns: JSON.stringify(columns || null),
        sorting: JSON.stringify(sorting || {}),
        pagination: JSON.stringify(pagination || {}),
      },
    });

    return this.httpClient.get<{ results: any[]; count: number }>(url, {
      params,
      withCredentials: true,
    });
  }
}
