import { Injectable } from '@angular/core';
import { ObjectFunctions } from '@campus/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BundleApi, PersonApi } from '../+api';
import { BundleInterface, UnlockedContentInterface } from '../+models';
import {
  BundleServiceInterface,
  SetAssigneeDataInterface,
  SetAssigneeResponseInterface,
  ShareBundleApiResponseInterface,
  UpdateBundleResultInterface,
} from './bundle.service.interface';

@Injectable({
  providedIn: 'root',
})
export class BundleService implements BundleServiceInterface {
  constructor(private personApi: PersonApi, private bundleApi: BundleApi) {}

  private castDates = ObjectFunctions.castAs(Date)(['start', 'end', 'lastUpdated']);

  getAllForUser(userId: number): Observable<BundleInterface[]> {
    return this.personApi
      .getData(userId, 'bundles')
      .pipe(map((res: { bundles: BundleInterface[] }) => res.bundles.map(this.castDates)));
  }

  linkEduContent(bundleId: number, eduContentIds: number[]): Observable<UnlockedContentInterface[]> {
    return this.bundleApi.linkEduContent(bundleId, eduContentIds);
  }

  linkUserContent(bundleId: number, userContentIds: number[]): Observable<UnlockedContentInterface[]> {
    return this.bundleApi.linkUserContent(bundleId, userContentIds);
  }

  createBundle(userId: number, bundle: BundleInterface): Observable<BundleInterface> {
    return this.personApi.createBundles(userId, bundle).pipe(map(this.castDates));
  }

  updateBundle(update: Partial<BundleInterface>): Observable<BundleInterface> {
    return this.bundleApi.patchAttributes(update.id, update).pipe(map(this.castDates)) as Observable<BundleInterface>;
  }

  shareBundle(
    ownerId: number,
    bundleId: number,
    teacherIds: number[],
    message?: string
  ): Observable<ShareBundleApiResponseInterface> {
    return this.personApi.shareBundle(
      ownerId,
      bundleId,
      teacherIds,
      message
    ) as Observable<ShareBundleApiResponseInterface>;
  }

  setAssignees(bundleId: number, assigneeData: SetAssigneeDataInterface): Observable<SetAssigneeResponseInterface> {
    return (this.bundleApi.setAssignees(bundleId, assigneeData) as Observable<SetAssigneeResponseInterface>).pipe(
      map(({ bundle, bundleGroups, bundlePersons, unlockedContentGroups, unlockedContentStudents }) => ({
        bundle: this.castDates(bundle),
        bundleGroups,
        bundlePersons,
        unlockedContentGroups: unlockedContentGroups.map(this.castDates),
        unlockedContentStudents: unlockedContentStudents.map(this.castDates),
      }))
    );
  }

  deleteBundles(bundleIds: number[]): Observable<UpdateBundleResultInterface> {
    return this.bundleApi.destroyBundles(bundleIds) as Observable<UpdateBundleResultInterface>;
  }
}
