<div class="search-results-list">
  <div class="search-results-list__info">
    <div data-cy="search-results-count">{{ count + (count === 1 ? ' resultaat' : ' resultaten') }}.</div>

    <mat-select
      *ngIf="sortModes && sortModes.length > 1"
      class="search-results-list__sorting mat-select-flat"
      data-cy="search-sort-mode"
      (selectionChange)="sortModeChanged($event.value)"
      #sorting
      [value]="activeSortMode"
    >
      <mat-option *ngFor="let sortMode of sortModes" data-cy="sort-option" [value]="sortMode">
        {{ sortMode.description }}
      </mat-option>
    </mat-select>
  </div>
  <div data-cy="search-results-container" class="search-results-list__results">
    <campus-list-view [listFormat]="listFormat" [placeHolderText]="''">
      <ng-template result-list-host></ng-template>
    </campus-list-view>
    <div #loadMore></div>
    <div *ngIf="!searchCompleted" class="search-results-list__results__loading ui-body-1"
      >Even geduld, de resultaten worden geladen...</div
    >
  </div>
</div>
