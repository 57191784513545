import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[campusConceptButtonKai], [concept-button-kai]',
})
export class ConceptButtonKaiDirective {
  @HostBinding('class')
  defaultClasses = [
    'concept-button-kai',
    'absolute',
    'right-none',
    'top-none',
    'transform',
    'translate-x-full',
    '-translate-y-1/5',
  ];
}
