/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { MethodProductGroupApi as SDKMethodProductGroupApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { MethodProductGroupInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class MethodProductGroupApi extends ModelApiBase {
  private SDKMethodProductGroupApi = inject(SDKMethodProductGroupApi);

  constructor(api: PolpoApi) {
    super(api, 'MethodProductGroups');
  }

  find(options?): Observable<MethodProductGroupInterface[]> {
    return this.SDKMethodProductGroupApi.find(...arguments);
  }

  create(data: Partial<MethodProductGroupInterface>): Observable<MethodProductGroupInterface> {
    // @ts-expect-error TS2556
    return this.SDKMethodProductGroupApi.create(...arguments);
  }

  updateAttributes(id: number, data: Partial<MethodProductGroupInterface>): Observable<MethodProductGroupInterface> {
    // @ts-expect-error TS2556
    return this.SDKMethodProductGroupApi.updateAttributes(...arguments);
  }

  deleteById(id: number): Observable<any> {
    // @ts-expect-error TS2556
    return this.SDKMethodProductGroupApi.deleteById(...arguments);
  }
}
