import { Injector } from '@angular/core';
import { EduContentBookQueries, MethodQueries, UnlockedFreePracticeQueries } from '@campus/dal';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { BooksTileComponentInterface } from '../../components';
import { BooksTileService, BooksTileServiceConfigInterface } from './books-tile.service';

export class KabasBooksTileService extends BooksTileService {
  constructor(protected injector: Injector) {
    super(injector);
    this.store = this.injector.get(Store);
  }

  public getBookTiles$(
    tileFilter?: (data) => boolean,
    config: BooksTileServiceConfigInterface = { filterUnlockedBooks: true }
  ): Observable<BooksTileComponentInterface[]> {
    return combineLatest([
      this.store.select(MethodQueries.getAllowedMethods),
      this.store.select(EduContentBookQueries.getByMethodId),
      this.store.select(UnlockedFreePracticeQueries.getGroupedByEduContentBookId),
    ]).pipe(
      map(([allowedMethods, booksByMethodId, ufpByBookIdDict]) => {
        const filteredAllowedMethods = tileFilter ? allowedMethods.filter(tileFilter) : allowedMethods;
        const booksTiles = filteredAllowedMethods.map((method) => {
          let books = booksByMethodId[method.id] || [];
          if (config.filterUnlockedBooks) {
            // filter out books that haven't been unlocked via UFP or UCT
            books = books.filter((book) => !!ufpByBookIdDict[book.id]);
          }

          return this.mapToBooksTileWithoutActions(method.id, method.name, method, books);
        });

        return booksTiles;
      })
    );
  }
}
