import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { MethodProductGroupApi, PersonApi } from '../+api';
import { MethodProductGroupInterface } from '../+models';
import { MethodProductGroupServiceInterface } from './method-product-group.service.interface';

@Injectable({
  providedIn: 'root',
})
export class MethodProductGroupService implements MethodProductGroupServiceInterface {
  constructor(private personApi: PersonApi, private methodProductGroupApi: MethodProductGroupApi) {}

  getAllForUser(userId: number): Observable<MethodProductGroupInterface[]> {
    return this.personApi
      .getData(userId, 'methodProductGroups')
      .pipe(map((res: { methodProductGroups: MethodProductGroupInterface[] }) => res.methodProductGroups));
  }

  createMethodProductGroup(
    methodProductGroup: Partial<MethodProductGroupInterface>
  ): Observable<MethodProductGroupInterface> {
    return this.methodProductGroupApi.create(methodProductGroup);
  }

  updateMethodProductGroup(
    id: number,
    update: Partial<MethodProductGroupInterface>
  ): Observable<MethodProductGroupInterface> {
    return this.methodProductGroupApi.updateAttributes(id, update);
  }

  deleteMethodProductGroup(methodProductGroupId: number): Observable<boolean> {
    return this.methodProductGroupApi.deleteById(methodProductGroupId).pipe(mapTo(true));
  }
}
