import { Action } from '@ngrx/store';

export enum EduContentTocLearningPlanGoalsActionTypes {
  LearningPlanGoalIdsLoadError = '[EduContentTocLearningPlanGoals] Error LearningPlanGoals for Toc',
  LoadLearningPlanGoalsIdsForToc = '[EduContentTocLearningPlanGoals] Load LearningPlanGoals for Toc',
  AddLearningPlanGoalsIdsForToc = '[EduContentTocLearningPlanGoals] Add LearningPlanGoals for Toc',
  AddLearningPlanGoalsIdsForBook = '[EduContentTocLearningPlanGoals] Add LearningPlanGoals for Book',
  ClearLearningPlanGoalsIdsForToc = '[EduContentTocLearningPlanGoals] Clear LearningPlanGoals for Toc',
}

export class EduContentTocLearningPlanGoalLoadError implements Action {
  readonly type = EduContentTocLearningPlanGoalsActionTypes.LearningPlanGoalIdsLoadError;
  constructor(public payload: any) {}
}

export class LoadLearningPlanGoalsIdsForToc implements Action {
  readonly type = EduContentTocLearningPlanGoalsActionTypes.LoadLearningPlanGoalsIdsForToc;

  constructor(
    public payload: {
      tocId: number;
      force?: boolean;
    }
  ) {}
}

export class AddLearningPlanGoalsIdsForToc implements Action {
  readonly type = EduContentTocLearningPlanGoalsActionTypes.AddLearningPlanGoalsIdsForToc;

  constructor(
    public payload: {
      tocId: number;
      learningPlanGoalIds: number[];
    }
  ) {}
}

export class AddLearningPlanGoalsIdsForBook implements Action {
  readonly type = EduContentTocLearningPlanGoalsActionTypes.AddLearningPlanGoalsIdsForBook;

  constructor(
    public payload: {
      bookId: number;
      learningPlanGoalIdsByTocId: { [tocId: number]: number[] };
    }
  ) {}
}

export class ClearLearningPlanGoalsIdsForToc implements Action {
  readonly type = EduContentTocLearningPlanGoalsActionTypes.ClearLearningPlanGoalsIdsForToc;
}

export type EduContentTocLearningPlanGoalActions =
  | AddLearningPlanGoalsIdsForBook
  | EduContentTocLearningPlanGoalLoadError
  | AddLearningPlanGoalsIdsForToc
  | ClearLearningPlanGoalsIdsForToc
  | LoadLearningPlanGoalsIdsForToc;
