import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { BundleGroupInterface } from '../../+models';
import * as BundleGroupActions from './bundle-group.actions';

export const NAME = 'bundleGroups';

export interface State extends EntityState<BundleGroupInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<BundleGroupInterface> = createEntityAdapter<BundleGroupInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

const BundleGroupReducer = createReducer(
  initialState,
  on(BundleGroupActions.addBundleGroup, (state, action) => adapter.addOne(action.bundleGroup, state)),
  on(BundleGroupActions.upsertBundleGroup, (state, action) => adapter.upsertOne(action.bundleGroup, state)),
  on(BundleGroupActions.addBundleGroups, (state, action) => adapter.addMany(action.bundleGroups, state)),
  on(BundleGroupActions.upsertBundleGroups, (state, action) => adapter.upsertMany(action.bundleGroups, state)),
  on(BundleGroupActions.updateBundleGroup, (state, action) => adapter.updateOne(action.bundleGroup, state)),
  on(BundleGroupActions.updateBundleGroups, (state, action) => adapter.updateMany(action.bundleGroups, state)),
  on(BundleGroupActions.deleteBundleGroup, (state, action) => adapter.removeOne(action.id, state)),
  on(BundleGroupActions.deleteBundleGroups, (state, action) => adapter.removeMany(action.ids, state)),
  on(BundleGroupActions.clearBundleGroups, (state) => adapter.removeAll(state)),
  on(BundleGroupActions.bundleGroupsLoaded, (state, action) =>
    adapter.setAll(action.bundleGroups, { ...state, loaded: true })
  ),
  on(BundleGroupActions.bundleGroupsLoadError, (state, action) => {
    return {
      ...state,
      ...{ error: action.error },
      loaded: false,
    };
  }),
  on(BundleGroupActions.updateBundleGroupsAccess, (state, { bundleId, bundleGroups }) => {
    const cleanedState = adapter.removeMany(
      (bundleGroup: BundleGroupInterface) => bundleGroup.bundleId === bundleId,
      state
    );
    const updatedState = adapter.addMany(bundleGroups, cleanedState);
    return updatedState;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return BundleGroupReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
