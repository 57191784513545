import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch, pessimisticUpdate } from '@nrwl/angular';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TaskGoalYearLevelQueries } from '.';
import {
  SetTaskGoalYearLevelResponseInterface,
  TaskGoalYearLevelServiceInterface,
  TASK_GOAL_YEAR_LEVEL_SERVICE_TOKEN,
} from '../../task-goal-year-level/task-goal-year-level.service.interface';
import { DalState } from '../dal.state.interface';
import { EduContentActions } from '../edu-content';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { TaskEduContentActions } from '../task-edu-content';
import { TaskEduContentTaskGoalYearLevelActions } from '../task-edu-content-task-goal-year-level';
import {
  AddTaskGoalYearLevelsForTask,
  LoadTaskGoalYearLevelsForTask,
  SetTaskGoalYearLevelsForTask,
  StartSetTaskGoalYearLevelsForTask,
  TaskGoalYearLevelsActionTypes,
  TaskGoalYearLevelsLoadError,
} from './task-goal-year-level.actions';

@Injectable()
export class TaskGoalYearLevelEffects {
  loadTaskGoalYearLevelsForTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(TaskGoalYearLevelsActionTypes.LoadTaskGoalYearLevelsForTask),
      concatLatestFrom(() => this.store.select(TaskGoalYearLevelQueries.selectTaskGoalYearLevelState)),
      fetch({
        run: (action: LoadTaskGoalYearLevelsForTask, taskGoalYearLevelState) => {
          const { taskId } = action.payload;
          if (taskGoalYearLevelState.loadedForTask[taskId]) return;

          return this.taskGoalYearLevelService.getTaskGoalYearLevelsForTask(taskId).pipe(
            map(
              (taskGoalYearLevels) =>
                new AddTaskGoalYearLevelsForTask({
                  taskId,
                  taskGoalYearLevels,
                })
            )
          );
        },
        onError: (action: LoadTaskGoalYearLevelsForTask, error) => {
          return new TaskGoalYearLevelsLoadError(error);
        },
      })
    )
  );

  startSetTaskGoalYearLevelsForTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(TaskGoalYearLevelsActionTypes.StartSetTaskGoalYearLevelsForTask),
      pessimisticUpdate({
        run: (action: StartSetTaskGoalYearLevelsForTask) => {
          const { taskId, taskGoalYearLevels } = action.payload;
          return this.taskGoalYearLevelService.setTaskGoalYearLevelsForTask(taskId, taskGoalYearLevels).pipe(
            switchMap((response: SetTaskGoalYearLevelResponseInterface) =>
              from([
                new EduContentActions.AddEduContents({ eduContents: response.eduContents }),
                new SetTaskGoalYearLevelsForTask({
                  taskId,
                  taskGoalYearLevels: response.taskGoalYearLevels,
                }),
                new TaskEduContentTaskGoalYearLevelActions.SetTaskEduContentTaskGoalYearLevelsForTask({
                  taskId,
                  taskEduContentTaskGoalYearLevels: response.taskEduContentTaskGoalYearLevels,
                  taskEduContentIds: response.taskEduContents.map((tEC) => tEC.id),
                  removedTaskEduContentTaskGoalYearLevelIds: response.removedTaskEduContentTaskGoalYearLevelIds,
                }),
                new TaskEduContentActions.SetTaskEduContentsForTask({
                  taskId,
                  taskEduContents: response.taskEduContents,
                }),
              ])
            )
          );
        },
        onError: (action: StartSetTaskGoalYearLevelsForTask, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de instellingen te bewaren.'
            ),
          });
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(TASK_GOAL_YEAR_LEVEL_SERVICE_TOKEN)
    private taskGoalYearLevelService: TaskGoalYearLevelServiceInterface,
    @Inject('uuid') private uuid: () => string
  ) {}
}
