import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'campus-segmented-progress-bar',
  templateUrl: './segmented-progress-bar.component.html',
})
export class SegmentedProgressBarComponent {
  @HostBinding('class')
  defaultClasses = [
    'segmented-progress-bar',
    'progress-bar',

    'flex',
    'flex-row',
    'overflow-hidden',

    'border',
    'border-2',
    'border-secondary',
    'surface',

    'corner-full',

    'w-full',
  ];

  @HostBinding('class.large')
  @Input()
  isLarge = false;

  @Input() totalSegments: number;

  @Input() filledSegments: number;
}
