import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { TaskLevelSettingInterface } from '../+models';

export const TASK_LEVEL_SETTING_SERVICE_TOKEN = new InjectionToken('TaskLevelSettingService');

export interface TaskLevelSettingServiceInterface {
  getTaskLevelSettingsForTask(taskId: number): Observable<TaskLevelSettingInterface[]>;

  setTaskLevelSettingsForTask(
    taskId: number,
    taskLevelSettings: TaskLevelSettingInterface[]
  ): Observable<TaskLevelSettingInterface[]>;
}
