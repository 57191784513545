export const TaskStatusToReadableMap = {
  filter: {
    pending: 'Nog niet gestart',
    active: 'Gestart',
    finished: 'Afgelopen',
    paper: 'Papieren',
    auto_archived: 'Gearchiveerde',
  },
  tooltip: {
    pending: 'De taak is nog niet gestart',
    active: 'De taak is gestart',
    finished: 'De taak is afgelopen',
    paper: 'Dit is een papieren taak',
    auto_archived: 'De taak is gearchiveerd',
  },
  pending: 'Nog niet gestart',
  active: 'Gestart',
  finished: 'Afgelopen',
  paper: 'Op papier',
  auto_archived: 'Gearchiveerd',
};
