import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SchoolBillingInterface } from '../../+models/SchoolBilling.interface';
import { SchoolBillingsActions, SchoolBillingsActionTypes } from './school-billing.actions';

export const NAME = 'schoolBillings';

export interface State extends EntityState<SchoolBillingInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<SchoolBillingInterface> = createEntityAdapter<SchoolBillingInterface>({
  selectId: (entity) => `${entity.schoolId}-${entity.schoolYear}`,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: SchoolBillingsActions): State {
  switch (action.type) {
    case SchoolBillingsActionTypes.AddSchoolBilling: {
      return adapter.addOne(action.payload.schoolBilling, state);
    }

    case SchoolBillingsActionTypes.UpsertSchoolBilling: {
      return adapter.upsertOne(action.payload.schoolBilling, state);
    }

    case SchoolBillingsActionTypes.AddSchoolBillings: {
      return adapter.addMany(action.payload.schoolBillings, state);
    }

    case SchoolBillingsActionTypes.UpsertSchoolBillings: {
      return adapter.upsertMany(action.payload.schoolBillings, state);
    }

    case SchoolBillingsActionTypes.UpdateSchoolBilling: {
      return adapter.updateOne(action.payload.schoolBilling, state);
    }

    case SchoolBillingsActionTypes.UpdateSchoolBillings: {
      return adapter.updateMany(action.payload.schoolBillings, state);
    }

    case SchoolBillingsActionTypes.DeleteSchoolBilling: {
      return adapter.removeOne(action.payload.id, state);
    }

    case SchoolBillingsActionTypes.DeleteSchoolBillings: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case SchoolBillingsActionTypes.SchoolBillingsLoaded: {
      return adapter.setAll(action.payload.schoolBillings, {
        ...state,
        loaded: true,
      });
    }

    case SchoolBillingsActionTypes.SchoolBillingsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case SchoolBillingsActionTypes.ClearSchoolBillings: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
