import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { EduContentTOCInterface } from '@campus/dal';
import { NavItem } from '@campus/ui';
import { findTopMostParentInTreeById } from '@campus/utils';

@Component({
  selector: 'campus-toc-select',
  templateUrl: './toc-select.component.html',
  styleUrls: ['./toc-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TocSelectComponent implements OnChanges {
  @HostBinding('class.shared-toc-select')
  hasSharedTocSelectClass = true;
  tree: NavItem[] = [];
  activeTopLevelTocIndex: number;
  previousSelectedTocId: number;

  @HostBinding('attr.data-cy')
  dataCy = 'toc-select';

  @Input() toc: EduContentTOCInterface[];
  @Input() indicatorsByTOCId: {[key: number]: boolean} = {};
  @Input() selectedTocId: number;

  @HostBinding('class.opacity-disabled')
  @HostBinding('class.pointer-event-none')
  @Input()
  disabled = false;

  @Input() parentCollapseOnly = false;

  @Output() clickToc = new EventEmitter<EduContentTOCInterface>();

  private expandedEduContentTocIds: Set<number> = new Set();

  tocClick(toc: EduContentTOCInterface) {
    if (!this.disabled) {
      if (this.expandedEduContentTocIds.has(toc.id) && this.amISelected(toc)) {
        this.expandedEduContentTocIds.delete(toc.id);
      } else {
        this.expandedEduContentTocIds.add(toc.id);
      }

      if (!this.parentCollapseOnly || !toc.children?.length) {
        this.previousSelectedTocId = this.selectedTocId;
        this.selectedTocId = toc.id;
      }
      this.tree = this.toc.map(this.toNavItem.bind(this));

      this.clickToc.emit(toc);
    }
  }

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.toc && changes.toc.currentValue) {
      this.tree = changes.toc.currentValue.map(this.toNavItem.bind(this));

      if (this.selectedTocId) {
        this.autoSelectTab(this.selectedTocId);
      }
    }

    if (changes.selectedTocId && this.toc) {
      this.autoSelectTab(changes.selectedTocId.currentValue);
      this.tree = this.toc.map(this.toNavItem.bind(this));
    }
  }

  private toNavItem(node: EduContentTOCInterface): NavItem {
    return {
      title: node.title,
      children: (node.children || []).map(this.toNavItem.bind(this)),
      expanded: this.isExpanded(node),
      isActive: this.amISelected(node),
      data: node,
      id: node.id,
    };
  }

  private isExpanded(node: EduContentTOCInterface): boolean {
    const isSelected = this.amISelected(node);
    const isDescendantSelected = this.isOneOfMyDescendantsSelected(node);
    const sameItemSelected = this.previousSelectedTocId === this.selectedTocId;

    if ((isSelected && sameItemSelected) || !(isSelected || isDescendantSelected)) {
      return this.expandedEduContentTocIds.has(node.id);
    }

    return isSelected || isDescendantSelected;
  }

  private amISelected(node: EduContentTOCInterface): boolean {
    return node.id === this.selectedTocId;
  }

  private isOneOfMyDescendantsSelected(node: EduContentTOCInterface): boolean {
    let selected = false;
    (node.children || []).some((child) => {
      if (child.id === this.selectedTocId) {
        return (selected = true);
      }
      if (child.children) {
        const selectedChild = this.isExpanded(child);
        if (selectedChild) {
          return (selected = true);
        }
      }
    });
    return selected;
  }

  private autoSelectTab(value: number) {
    if (!this.toc) return;

    this.expandedEduContentTocIds.add(value);

    const item = findTopMostParentInTreeById(this.toc, value);

    if (item) {
      this.expandedEduContentTocIds.add(item.id);
      this.activeTopLevelTocIndex = this.toc.findIndex((toc) => toc.id === item.id);
    }
  }
}
