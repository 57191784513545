import { groupArrayByKeys } from '@campus/utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NewsProductInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './news-product.reducer';

export const selectNewsProductState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectNewsProductState, (state: State) => state.error);

export const getLoaded = createSelector(selectNewsProductState, (state: State) => state.loaded);

export const getAll = createSelector(selectNewsProductState, selectAll);

export const getCount = createSelector(selectNewsProductState, selectTotal);

export const getIds = createSelector(selectNewsProductState, selectIds);

export const getAllEntities = createSelector(selectNewsProductState, selectEntities);

export const getByIds = createSelector(selectNewsProductState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

export const getById = (props: { id: number }) =>
  createSelector(selectNewsProductState, (state: State) => state.entities[props.id]);

export const getByNewsId = createSelector(getAll, (newsProducts: NewsProductInterface[]) => {
  return groupArrayByKeys(newsProducts, ['newsId']);
});
