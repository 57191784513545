import { trigger } from '@angular/animations';
import { Component, inject, OnInit } from '@angular/core';
import { WINDOW } from '@campus/browser';
import { EnvironmentWebsiteInterface, ENVIRONMENT_WEBSITE_TOKEN } from '@campus/environment';
import { fadeAnimation } from '@campus/ui';

@Component({
  // eslint-disable-next-line
  selector: 'polpo-classroom-web-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  animations: [trigger('fadeIn', fadeAnimation)],
})
export class LoadingComponent implements OnInit {
  private environmentWebsite = inject<EnvironmentWebsiteInterface>(ENVIRONMENT_WEBSITE_TOKEN);
  private window = inject(WINDOW);

  ngOnInit() {
    this.closeWindow();
  }

  private closeWindow() {
    setTimeout(() => {
      try {
        if (this.window.location.href === this.environmentWebsite.loadingUrl) {
          this.window.close();
        }
      } catch (e) {
        // probably cors error on `window.location.href` because we redirected to an external domain
      }
    }, 3000);
  }
}
