import { Inject, Injectable } from '@angular/core';
import {
  AlgebrakitSession,
  AlgebrakitSessionDay,
  AlgebrakitSessionDayInterface,
  AlgebrakitSessionInterface,
  EduContent,
  Result,
  ResultInterface,
  ResultStatusEnum,
  Task,
  TaskInstanceInterface,
} from '@campus/dal';
import { ENVIRONMENT_TIME_TOKEN, EnvironmentTimeInterface } from '@campus/environment';
import { HumanDateTimePipe, getHumanDateTimeRules, humanDateTimeRulesEnum } from '@campus/ui';
import { ArrayFunctions, groupArrayByKey } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { StudentTaskInterface } from '../../interfaces';
import { MapperStudentTaskServiceInterface } from './mapper.student-task.service.interface';
@Injectable({
  providedIn: 'root',
})
export class MapperStudentTaskService implements MapperStudentTaskServiceInterface {
  get dateLabelRules() {
    return getHumanDateTimeRules([
      humanDateTimeRulesEnum.TODAY,
      humanDateTimeRulesEnum.TOMORROW,
      humanDateTimeRulesEnum.DAY_AFTER_TOMORROW,
      humanDateTimeRulesEnum.WEEKDAY,
      humanDateTimeRulesEnum.NEXT_WEEK,
    ]);
  }

  get dateGroupLabelRules() {
    return getHumanDateTimeRules([
      humanDateTimeRulesEnum.TODAY,
      humanDateTimeRulesEnum.TOMORROW,
      humanDateTimeRulesEnum.DAY_AFTER_TOMORROW,
      humanDateTimeRulesEnum.THIS_WEEK,
      humanDateTimeRulesEnum.PAST_WEEK,
      humanDateTimeRulesEnum.NEXT_WEEK,
      humanDateTimeRulesEnum.EARLIER,
      humanDateTimeRulesEnum.LATER,
    ]);
  }
  get isUrgentRules() {
    return getHumanDateTimeRules([humanDateTimeRulesEnum.TODAY, humanDateTimeRulesEnum.TOMORROW]);
  }

  constructor(
    @Inject(ENVIRONMENT_TIME_TOKEN)
    private timeEnvironment: EnvironmentTimeInterface
  ) {}

  public mapToStudentTasks(taskStudentInstances: TaskInstanceInterface[]): StudentTaskInterface[] {
    return taskStudentInstances.map((tsInstance) => {
      const date = new HumanDateTimePipe();
      const requiredIds = tsInstance.task.taskEduContents
        .filter((tec) => tec.required)
        .map((requiredTecs) => requiredTecs.eduContentId);
      const notRequiredIds = tsInstance.task.taskEduContents
        .filter((tec) => !tec.required)
        .map((notRequiredTecs) => notRequiredTecs.eduContentId);

      // only results
      // FYI: 1 eduContent = 1 result, 1 date
      const results = [...tsInstance.task.results];
      // results & sessions
      const mixedResults = [...results, ...tsInstance.task.sessions];

      // results used for progress calculations
      const requiredResults = results.filter((res) => requiredIds.includes(res.eduContentId));
      const optionalResults = results.filter((res) => notRequiredIds.includes(res.eduContentId));
      const completedRequired = requiredResults.filter((res) => this.isCompleted(res));
      const completedNotRequired = optionalResults.filter((res) => this.isCompleted(res));

      const completedRequiredIds = ArrayFunctions.unique(completedRequired.map((r) => r.eduContentId));
      const completedNotRequiredIds = ArrayFunctions.unique(completedNotRequired.map((r) => r.eduContentId));

      // sessions
      // FYI: 1 eduContent = 1 or many sessions, spread across multiple dates
      // in order to compare data with results, we have to:
      // - group sessions by eduContent:
      //   - in order to calculate progress
      //   - if at least one session.status = "completed" means eduContent is "completed"
      // - group sessions by day: to show in a chronological list
      const sessionsByEduContentId = groupArrayByKey(tsInstance.task.sessions, 'eduContentId');

      const {
        requiredAkitSessionDays,
        notRequiredAkitSessionDays,
        completedRequiredAkitEduContents,
        completedNotRequiredAkitEduContents,
      } = this.getAlgebrakitResultData(sessionsByEduContentId, requiredIds, notRequiredIds);

      let score; // session scores should be excluded
      const calculateScore = (completed: ResultInterface[], total: number): number => {
        let totalScorable = total;
        const completedScoreSum = completed.reduce((accScore, result) => {
          const eduContent = EduContent.toEduContent(result.eduContent);
          if (!eduContent.isScorable) {
            totalScorable--;
            return accScore;
          }
          return accScore + (result.score || 0);
        }, 0);
        return Math.round((completedScoreSum / (totalScorable * 100)) * 100);
      };
      if (requiredResults.length) {
        score = calculateScore(completedRequired, requiredResults.length);
      } else if (completedNotRequired.length) {
        score = calculateScore(completedNotRequired, optionalResults.length);
      }

      let time; // session time should be included
      if (mixedResults.length) {
        time = mixedResults.reduce((accTime, result) => {
          return accTime + (result.time || 0);
        }, 0);
      }

      // sessions should be included
      const completedPercentage = this.calculatePercentage(
        completedRequiredIds.length + completedRequiredAkitEduContents.length,
        requiredIds.length,
        completedNotRequiredIds.length + completedNotRequiredAkitEduContents.length,
        notRequiredIds.length
      );
      const isCompleted = completedPercentage === 100;

      const result: StudentTaskInterface = {
        taskInstanceId: tsInstance.id,
        name: tsInstance.task.name,
        description: tsInstance.task.description,
        learningAreaName: tsInstance.task.learningArea.name,
        learningAreaId: tsInstance.task.learningArea.id,
        taskId: tsInstance.task.id,
        requiredResults: requiredResults,
        optionalResults: optionalResults,
        mixedRequiredResults: [
          ...requiredResults.map(Result.toMixedResult),
          ...requiredAkitSessionDays.map(AlgebrakitSessionDay.toMixedResult),
        ],
        mixedOptionalResults: [
          ...optionalResults.map(Result.toMixedResult),
          ...notRequiredAkitSessionDays.map(AlgebrakitSessionDay.toMixedResult),
        ],
        taskEduContents: tsInstance.task.taskEduContents,
        count: {
          completedRequired: completedRequiredIds.length + completedRequiredAkitEduContents.length,
          totalRequired: requiredIds.length,
          completedNotRequired: completedNotRequiredIds.length + completedNotRequiredAkitEduContents.length,
          totalNotRequired: notRequiredIds.length,
          total: tsInstance.task.taskEduContents.length,
          completed:
            completedNotRequiredIds.length +
            completedRequiredIds.length +
            completedRequiredAkitEduContents.length +
            completedNotRequiredAkitEduContents.length,
          percentage: completedPercentage,
        },
        isFinished: tsInstance.end < new Date(),
        isUrgent: !isCompleted && this.isUrgent(tsInstance.end),
        isEvaluationTask: Task.isEvaluationTask(tsInstance.task),
        dateGroupLabel: date.transform(tsInstance.end, {
          rules: this.dateGroupLabelRules,
        }),
        score,
        time,
        dateLabel:
          tsInstance.end < new Date()
            ? 'ingediend op ' + date.transform(tsInstance.end, { format: 'shortDate' })
            : date.transform(tsInstance.end, {
                rules: this.dateLabelRules,
              }),
        endDate: tsInstance.end,
      };

      return result;
    });
  }

  public isUrgent(endDate: Date) {
    const timeDiff = endDate.getTime() - new Date().getTime();
    if (timeDiff < 0) return false;
    return timeDiff <= this.timeEnvironment.msOffsetUntilUrgent;
  }

  public calculatePercentage(
    completedRequired: number,
    requiredItems: number,
    completedOptional: number,
    optionalItems: number
  ): number {
    if (requiredItems > 0) {
      return (completedRequired / requiredItems) * 100;
    } else if (optionalItems > 0) {
      return (completedOptional / optionalItems) * 100;
    }
    return 0;
  }

  private isCompleted(result: ResultInterface) {
    const completedStatusArray = [ResultStatusEnum.STATUS_COMPLETED, ResultStatusEnum.STATUS_OPENED];

    return completedStatusArray.includes(result.status);
  }

  private getAlgebrakitResultData(
    akitSessionsByEduContentId: Dictionary<AlgebrakitSessionInterface[]>,
    requiredEduContentIds: number[],
    notRequiredEduContentIds: number[]
  ): {
    requiredAkitSessionDays: AlgebrakitSessionDayInterface[];
    notRequiredAkitSessionDays: AlgebrakitSessionDayInterface[];
    completedRequiredAkitEduContents: number[];
    completedNotRequiredAkitEduContents: number[];
  } {
    return Object.entries(akitSessionsByEduContentId).reduce(
      (acc, [eduContentId, sessionsForEduContent]) => {
        // sessions grouped by days for this eduContent
        const sessionsPerDay = AlgebrakitSession.toAlgebraKitSessionsPerDay(sessionsForEduContent);

        // for each day, aggregate the sessions and make an algebrakit "result" used for visualisation
        const algebrakitSessionDays = sessionsPerDay.map((sessionsForDay) => {
          const sessionDay = AlgebrakitSessionDay.toAlgebraKitSessionDay(sessionsForDay);
          return sessionDay;
        });

        const isSessionCompleted = sessionsForEduContent.some(
          (session) => session.status === ResultStatusEnum.STATUS_COMPLETED
        );

        if (requiredEduContentIds.includes(+eduContentId)) {
          acc.requiredAkitSessionDays.push(...algebrakitSessionDays);
          if (isSessionCompleted) acc.completedRequiredAkitEduContents.push(+eduContentId);
        }
        if (notRequiredEduContentIds.includes(+eduContentId)) {
          acc.notRequiredAkitSessionDays.push(...algebrakitSessionDays);
          if (isSessionCompleted) acc.completedNotRequiredAkitEduContents.push(+eduContentId);
        }

        return acc;
      },
      {
        requiredAkitSessionDays: [],
        notRequiredAkitSessionDays: [],
        completedRequiredAkitEduContents: [],
        completedNotRequiredAkitEduContents: [],
      }
    );
  }
}
