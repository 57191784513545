/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { SchoolApi as SDKSchoolApi } from '@diekeure/polpo-api-angular-sdk';
import {
  ClassGroupInterface,
  PersonInterface,
  PurchaseInterface,
  SchoolBillingAddressInterface,
  SchoolInterface,
  SchoolProviderInterface,
  SchoolRoleMappingClassGroupInterface,
  SchoolRoleMappingInterface,
  StudentInterface,
} from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class SchoolApi extends ModelApiBase {
  private SDKSchoolApi = inject(SDKSchoolApi);

  constructor(api: PolpoApi) {
    super(api, 'Schools');
  }

  findBySchoolCode(schoolCode: string | number) {
    const path = 'findBySchoolCode';

    return this.post(path, undefined, { schoolCode });
  }

  createClassGroup(schoolId: number, classGroup: Partial<ClassGroupInterface>) {
    const path = `${schoolId}/class-groups`;

    return this.post<ClassGroupInterface>(path, undefined, classGroup);
  }

  updateClassGroup(schoolId: number, classGroupId: number, classGroup: Partial<ClassGroupInterface>) {
    const path = `${schoolId}/class-groups/${classGroupId}`;

    return this.patch<ClassGroupInterface>(path, undefined, classGroup);
  }

  removeClassGroup(schoolId: number, classGroupId: number) {
    const path = `${schoolId}/class-groups/${classGroupId}`;

    return this.delete<{ count: number }>(path);
  }

  linkTeacherRolesClassGroup(schoolId: number, classGroupId: number, schoolRoleMappingIds: number[]) {
    const path = `${schoolId}/class-groups/${classGroupId}/link-teacher-roles`;

    return this.post(path, undefined, schoolRoleMappingIds);
  }

  linkStudentRolesClassGroup(
    schoolId: number,
    classGroupId: number,
    studentRoles: {
      schoolRoleMappingId: number;
      classNumber?: number;
    }[]
  ) {
    const path = `${schoolId}/class-groups/${classGroupId}/link-student-roles`;

    return this.post(path, undefined, studentRoles);
  }

  unlinkRolesClassGroup(schoolId: number, classGroupId: number, schoolRoleMappingIds: number[]) {
    const path = `${schoolId}/class-groups/${classGroupId}/unlink-roles`;

    return this.post(path, undefined, schoolRoleMappingIds);
  }

  getData(schoolId: number, fields?: string, since?: number) {
    const path = `${schoolId}/data`;

    return this.get(path);
  }

  createStudent(schoolId: number, student: Partial<StudentInterface>) {
    const path = `${schoolId}/students`;

    return this.post<{
      person: PersonInterface;
      schoolRoleMapping: SchoolRoleMappingInterface;
      schoolRoleMappingClassGroup: SchoolRoleMappingClassGroupInterface;
    }>(path, undefined, student);
  }

  createTeacher(schoolId: number, teacher: Partial<PersonInterface>) {
    const path = `${schoolId}/teachers`;

    return this.post<{
      person: PersonInterface;
      schoolRoleMapping: SchoolRoleMappingInterface;
    }>(path, undefined, teacher);
  }

  removeStudent(schoolId: number, studentId: number) {
    const path = `${schoolId}/students/${studentId}`;

    return this.delete<{ count: number }>(path);
  }

  removeStudents(schoolId: number, studentIds: number[]) {
    const path = `${schoolId}/students/remove-students`;

    return this.post(path, undefined, studentIds);
  }

  updateStudent(schoolId: number, studentId: number, student: Partial<StudentInterface>) {
    const path = `${schoolId}/students/${studentId}`;

    return this.patch<{
      person: PersonInterface;
      schoolRoleMapping: SchoolRoleMappingInterface;
      schoolRoleMappingClassGroup: SchoolRoleMappingClassGroupInterface;
    }>(path, undefined, student);
  }

  importStudents(schoolId: number, students: any[], deleteMissingRootNumbers: boolean = false) {
    const path = `${schoolId}/students/import`;

    const params = this._getHttpParams({ deleteMissingRootNumbers });

    return this.post(path, params, students);
  }

  updateTeacher(schoolId: number, teacherId: number, teacher: Partial<PersonInterface>) {
    const path = `${schoolId}/teachers/${teacherId}`;

    return this.patch<{
      person: PersonInterface;
      schoolRoleMapping: SchoolRoleMappingInterface[];
    }>(path, undefined, teacher);
  }

  removeTeacher(schoolId: number, teacherId: number) {
    const path = `${schoolId}/teachers/${teacherId}`;

    return this.delete<{ count: number }>(path);
  }

  createSchoolAdmin(schoolId: number, schoolAdmin: Partial<PersonInterface>) {
    const path = `${schoolId}/schoolAdmin`;

    return this.post<{
      person: PersonInterface;
      schoolRoleMapping: SchoolRoleMappingInterface;
    }>(path, undefined, schoolAdmin);
  }

  updateSchoolAdmin(schoolId: number, schoolAdminId: number, schoolAdmin: Partial<PersonInterface>) {
    const path = `${schoolId}/schoolAdmins/${schoolAdminId}`;

    return this.patch<{
      person: PersonInterface;
      schoolRoleMapping: SchoolRoleMappingInterface[];
    }>(path, undefined, schoolAdmin);
  }

  removeSchoolAdmin(schoolId: number, schoolAdminId: number) {
    const path = `${schoolId}/schoolAdmins/${schoolAdminId}`;

    return this.delete<{ count: number }>(path);
  }

  linkSchoolAdmin(schoolId: number, personId: number) {
    const path = `${schoolId}/link-school-admin/${personId}`;

    return this.post(path);
  }

  placeOrder(
    schoolId: number,
    productIds: number[],
    customerOrderReference = '',
    schoolBillingAddress?: SchoolBillingAddressInterface,
    fullDigital: number[] = []
  ) {
    const path = `${schoolId}/orders`;

    return this.post(path, undefined, { productIds, customerOrderReference, schoolBillingAddress, fullDigital });
  }

  updatePurchase(schoolId: number, purchaseId: number | string, purchase: Partial<PurchaseInterface>) {
    const path = `${schoolId}/update-purchase/${purchaseId}`;

    return this.patch(path, undefined, purchase);
  }

  deletePurchase(id: number) {
    const path = `purchase/${id}`;

    return this.delete<{ count: number }>(path);
  }

  createPurchase(schoolId: number, purchase: Partial<PurchaseInterface>) {
    const path = `${schoolId}/create-purchase`;

    return this.post<PurchaseInterface>(path, undefined, purchase);
  }

  public sendInviteMail(schoolId, teacherId) {
    // @ts-expect-error TS 2556
    return this.SDKSchoolApi.sendInviteMail(...arguments);
  }

  public createSchool(school: SchoolInterface) {
    // @ts-expect-error TS 2556
    return this.SDKSchoolApi.createSchool(...arguments);
  }

  public removeSchool(schoolId: number) {
    // @ts-expect-error TS 2556
    return this.SDKSchoolApi.removeSchool(...arguments);
  }

  public updateSchool(schoolId: number, update: Partial<SchoolInterface>) {
    // @ts-expect-error TS 2556
    return this.SDKSchoolApi.updateSchool(...arguments);
  }

  public updateStudentCode(schoolId: number, studentId: number, code: string) {
    // @ts-expect-error TS 2556
    return this.SDKSchoolApi.updateStudentCode(...arguments);
  }

  public addStudentCoins(schoolId: number, studentId: number, coins: number) {
    // @ts-expect-error TS 2556
    return this.SDKSchoolApi.addStudentCoins(...arguments);
  }

  public getForZip(zipCode: string) {
    // @ts-expect-error TS 2556
    return this.SDKSchoolApi.getForZip(...arguments);
  }

  public create(school: SchoolInterface) {
    // @ts-expect-error TS 2556
    return this.SDKSchoolApi.create(...arguments);
  }

  public updateTransition(schoolId: number, type) {
    // @ts-expect-error TS 2556
    return this.SDKSchoolApi.updateTransition(...arguments);
  }

  public unlinkStudentsInSchool(schoolId: number) {
    // @ts-expect-error TS 2556
    return this.SDKSchoolApi.unlinkStudentsInSchool(...arguments);
  }

  public calculateSchoolBilling(schoolId: number) {
    // @ts-expect-error TS 2556
    return this.SDKSchoolApi.calculateSchoolBilling(...arguments);
  }

  public createSchoolBillingAddress(schoolId: number, schoolBillingAddress) {
    // @ts-expect-error TS 2556
    return this.SDKSchoolApi.createSchoolBillingAddress(...arguments);
  }

  public updateSchoolBillingAddress(schoolId: number, schoolBillingAddressId: number, update) {
    // @ts-expect-error TS 2556
    return this.SDKSchoolApi.updateSchoolBillingAddress(...arguments);
  }

  public deleteSchoolBillingAddress(schoolId: number, schoolBillingAddressId: number) {
    // @ts-expect-error TS 2556
    return this.SDKSchoolApi.deleteSchoolBillingAddress(...arguments);
  }

  public unlinkProvider(schoolId: number, providerId: number) {
    // @ts-expect-error TS 2556
    return this.SDKSchoolApi.unlinkProvider(...arguments);
  }

  public linkProvider(schoolId: number, provider: SchoolProviderInterface) {
    // @ts-expect-error TS 2556
    return this.SDKSchoolApi.linkProvider(...arguments);
  }
}
