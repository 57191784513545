import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { BadgeInterface } from '../../+models';

export enum BadgesActionTypes {
  BadgesLoaded = '[Badges] Badges Loaded',
  BadgesLoadError = '[Badges] Load Error',
  LoadBadges = '[Badges] Load Badges',
  AddBadge = '[Badges] Add Badge',
  UpsertBadge = '[Badges] Upsert Badge',
  AddBadges = '[Badges] Add Badges',
  UpsertBadges = '[Badges] Upsert Badges',
  UpdateBadge = '[Badges] Update Badge',
  UpdateBadges = '[Badges] Update Badges',
  DeleteBadge = '[Badges] Delete Badge',
  DeleteBadges = '[Badges] Delete Badges',
  ClearBadges = '[Badges] Clear Badges',
}

export class LoadBadges implements Action {
  readonly type = BadgesActionTypes.LoadBadges;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class BadgesLoaded implements Action {
  readonly type = BadgesActionTypes.BadgesLoaded;

  constructor(public payload: { badges: BadgeInterface[] }) {}
}

export class BadgesLoadError implements Action {
  readonly type = BadgesActionTypes.BadgesLoadError;
  constructor(public payload: any) {}
}

export class AddBadge implements Action {
  readonly type = BadgesActionTypes.AddBadge;

  constructor(public payload: { badge: BadgeInterface }) {}
}

export class UpsertBadge implements Action {
  readonly type = BadgesActionTypes.UpsertBadge;

  constructor(public payload: { badge: BadgeInterface }) {}
}

export class AddBadges implements Action {
  readonly type = BadgesActionTypes.AddBadges;

  constructor(public payload: { badges: BadgeInterface[] }) {}
}

export class UpsertBadges implements Action {
  readonly type = BadgesActionTypes.UpsertBadges;

  constructor(public payload: { badges: BadgeInterface[] }) {}
}

export class UpdateBadge implements Action {
  readonly type = BadgesActionTypes.UpdateBadge;

  constructor(public payload: { badge: Update<BadgeInterface> }) {}
}

export class UpdateBadges implements Action {
  readonly type = BadgesActionTypes.UpdateBadges;

  constructor(public payload: { badges: Update<BadgeInterface>[] }) {}
}

export class DeleteBadge implements Action {
  readonly type = BadgesActionTypes.DeleteBadge;

  constructor(public payload: { id: number }) {}
}

export class DeleteBadges implements Action {
  readonly type = BadgesActionTypes.DeleteBadges;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearBadges implements Action {
  readonly type = BadgesActionTypes.ClearBadges;
}

export type BadgesActions =
  | LoadBadges
  | BadgesLoaded
  | BadgesLoadError
  | AddBadge
  | UpsertBadge
  | AddBadges
  | UpsertBadges
  | UpdateBadge
  | UpdateBadges
  | DeleteBadge
  | DeleteBadges
  | ClearBadges;
