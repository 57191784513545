/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { GroupApi as SDKGroupApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { GroupInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class GroupApi extends ModelApiBase {
  private SDKGroupApi = inject(SDKGroupApi);

  constructor(api: PolpoApi) {
    super(api, 'Groups');
  }

  create(group: GroupInterface): Observable<GroupInterface> {
    // @ts-expect-error TS 2556
    return this.SDKGroupApi.create(...arguments);
  }

  deleteById(groupId: number): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKGroupApi.deleteById(...arguments);
  }

  patchAttributes(groupId: number, updates: Partial<GroupInterface>): Observable<GroupInterface> {
    // @ts-expect-error TS 2556
    return this.SDKGroupApi.patchAttributes(...arguments);
  }
}
