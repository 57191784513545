import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone, Renderer2, RendererFactory2 } from '@angular/core';
import { WINDOW } from '@campus/browser';
import { take } from 'rxjs/operators';

export type ShareToProvider = 'microsoft' | 'google';
export interface ShareToConfigInterface {
  provider: ShareToProvider;
  src?: string;
  inner?: string;
  callbackOnLoad?: boolean;
}
export const SHARE_TO_CLASSROOM_ID = 'sharetoclassroom';

export type GoogleItemTypeInterface = 'announcement' | 'assignment' | 'material' | 'question';
export interface GoogleConfigInterface {
  size: number;
  url: string;
  title: string;
  body: string;
  itemtype: GoogleItemTypeInterface;
}

@Injectable({ providedIn: 'root' })
export class ShareToService {
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    rendererFactory: RendererFactory2,
    private ngZone: NgZone
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  renderSocialShareButton(provider: ShareToProvider, googleConfig?: GoogleConfigInterface) {
    if (this.hasScriptForProvider(provider)) {
      this.callBackForProvider(provider, googleConfig);
      return;
    }

    const configs = this.getScriptConfigs(provider);
    this.createScriptTag(configs, googleConfig);
  }

  clear(provider: ShareToProvider) {
    if (provider === 'microsoft') {
      const scripts = this.document.querySelectorAll(`script[data-target="${this.getTargetName(provider)}"]`);
      scripts.forEach((script) => script.remove());
    }
  }

  private getScriptConfigs(provider: ShareToProvider): ShareToConfigInterface[] {
    const configs = [];
    switch (provider) {
      case 'microsoft':
        configs.push({
          provider,
          src: 'https://teams.microsoft.com/share/launcher.js',
          callbackOnLoad: true,
        });
        break;
      case 'google':
        configs.push({
          provider,
          inner: 'window.___gcfg = { parsetags: "explicit" };',
        });
        configs.push({
          provider,
          src: 'https://apis.google.com/js/platform.js',
          callbackOnLoad: true,
        });
        break;
    }

    return configs;
  }

  private createScriptTag(configs: ShareToConfigInterface[], googleConfig?: GoogleConfigInterface): void {
    configs.forEach((config) => {
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.setAttribute('data-target', this.getTargetName(config.provider));

      if (config.src) script.src = config.src;
      else if (config.inner) script.append(config.inner);

      if (config.callbackOnLoad)
        this.renderer.listen(script, 'load', () => {
          this.callBackForProvider(config.provider, googleConfig);
        });

      this.renderer.appendChild(this.document.body, script);
    });
  }

  private getTargetName(provider: ShareToProvider) {
    return `share-to-${provider}`;
  }

  private hasScriptForProvider(provider: ShareToProvider) {
    return !!this.document.querySelectorAll(`script[data-target="${this.getTargetName(provider)}"]`).length;
  }

  private callBackForProvider(provider: ShareToProvider, googleConfig?: GoogleConfigInterface) {
    switch (provider) {
      case 'microsoft':
        this.callbackMicrosoft();
        break;
      case 'google':
        this.callbackGoogle(googleConfig);
    }
  }

  private callbackMicrosoft() {
    const { shareToMicrosoftTeams } = this.window as any;
    if (!shareToMicrosoftTeams) {
      return;
    }

    shareToMicrosoftTeams.renderButtons();
  }

  private callbackGoogle(config) {
    const { gapi } = this.window as any;

    if (!gapi) {
      return;
    }

    this.ngZone.onStable.pipe(take(1)).subscribe(() => {
      gapi.sharetoclassroom.render(SHARE_TO_CLASSROOM_ID, config);
    });
  }
}
