import { Component, HostBinding, Input } from '@angular/core';

export interface LevelInterface {
  //copy from DAL
  id?: number;
  name: string;
  icon?: string;
  sequence?: number;
  value?: number;
}
@Component({
  selector: 'campus-level-indicator',
  templateUrl: './level-indicator.component.html',
  styleUrls: ['./level-indicator.component.scss'],
})
export class LevelIndicatorComponent {
  @HostBinding('class.ui-level-indicator')
  hasLevelIndicatorClass = true;

  @Input()
  public levels: LevelInterface[];

  @Input()
  public active: LevelInterface;

  @HostBinding('class.ui-level-indicator--with-label')
  @Input()
  public showLabel = false;
}
