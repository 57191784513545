import { groupArrayByKey } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssigneeInterface, AssigneeTypesEnum } from '../../+models';
import { TaskStudentInterface } from '../../+models/TaskStudent.interface';
import { selectLinkedPersonState } from '../linked-person/linked-person.selectors';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './task-student.reducer';

export const selectTaskStudentState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectTaskStudentState, (state: State) => state.error);

export const getLoaded = createSelector(selectTaskStudentState, (state: State) => state.loaded);

export const getAll = createSelector(selectTaskStudentState, selectAll);

export const getCount = createSelector(selectTaskStudentState, selectTotal);

export const getIds = createSelector(selectTaskStudentState, selectIds);

export const getAllEntities = createSelector(selectTaskStudentState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * taskStudent$: TaskStudentInterface[] = this.store.pipe(
    select(TaskStudentQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectTaskStudentState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * taskStudent$: TaskStudentInterface = this.store.pipe(
    select(TaskStudentQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectTaskStudentState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getTaskStudentAssigneeByTask = createSelector(
  getAll,
  selectLinkedPersonState,
  (taskStudents, linkedPersonState) =>
    taskStudents.reduce((dict, ts) => {
      if (!dict[ts.taskId]) {
        dict[ts.taskId] = [];
      }

      // Check if the student still exists (could be deleted)
      if (!linkedPersonState.entities[ts.personId]) {
        return dict;
      }

      dict[ts.taskId].push({
        id: ts.id,
        type: AssigneeTypesEnum.STUDENT,
        relationId: ts.personId,
        label: linkedPersonState.entities[ts.personId].displayName,
        start: ts.start,
        end: ts.end,
      });

      return dict;
    }, {} as Dictionary<AssigneeInterface[]>)
);

export const getByTaskId = createSelector(
  selectTaskStudentState,
  (state: State, props: { taskId: number }): TaskStudentInterface[] =>
    (state.ids as (string | number)[]).reduce((acc, id) => {
      if (state.entities[id].taskId === props.taskId) {
        acc.push(state.entities[id]);
      }
      return acc;
    }, [])
);

export const getAllGroupedByTaskId = createSelector(selectTaskStudentState, (state: State) => {
  return groupArrayByKey(Object.values(state.entities), 'taskId');
});
