import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { SchoolInterface } from '../../+models';
import { SchoolsActions, SchoolsActionTypes } from './school.actions';

export const NAME = 'schools';
export interface State extends EntityState<SchoolInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<SchoolInterface> = createEntityAdapter<SchoolInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: SchoolsActions): State {
  switch (action.type) {
    case SchoolsActionTypes.SchoolsLoaded: {
      return adapter.setAll(action.payload.schools, {
        ...state,
        loaded: true,
      });
    }

    case SchoolsActionTypes.SchoolsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case SchoolsActionTypes.AddSchool: {
      return adapter.addOne(action.payload.school, state);
    }

    case SchoolsActionTypes.AddSchools: {
      return adapter.addMany(action.payload.schools, state);
    }

    case SchoolsActionTypes.DeleteSchool: {
      return adapter.removeOne(action.payload.id, state);
    }

    case SchoolsActionTypes.UpdateSchool: {
      return adapter.updateOne(action.payload.school, state);
    }

    case SchoolsActionTypes.UpdateTransition: {
      const { schoolId, type } = action.payload;
      const { yearTransition } = state.entities[schoolId];
      const update: Update<SchoolInterface> = {
        id: schoolId,
        changes: {
          yearTransition: {
            ...yearTransition,
            [type]: false,
          },
        },
      };
      return adapter.updateOne(update, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
