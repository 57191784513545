import { Dictionary } from '@ngrx/entity';

export interface ClassGroupInterface {
  id?: number;
  name?: string;
  years?: YearInterface[];
}
export interface YearInterface {
  name: string;
  id?: number;
}
export interface ClassGroupStudent {
  firstName: string;
  name: string;
  classGroupClassNumberDict: Dictionary<number>;
}
export class ClassGroupFunctions {
  public static sortStudentsInClassGroup<T extends ClassGroupStudent>(classGroupId: number, students: T[]): T[] {
    const result = [...students].sort((a, b) => this.orderByClassNumber(classGroupId, a, b));

    return result;
  }

  public static orderByClassNumber(classGroupId: number, a: ClassGroupStudent, b: ClassGroupStudent) {
    return this.classNumberComparer(classGroupId, a, b) || this.fullNameComparer(a, b);
  }
  public static sortClassGroups<T extends ClassGroupInterface>(classGroups: T[]): T[] {
    const result = [...classGroups].sort((a, b) => this.sortByYearNameAndClassGroupName(a, b));
    return result;
  }

  public static sortByYearNameAndClassGroupName(a: ClassGroupInterface, b: ClassGroupInterface): number {
    const yearA = a.years[0];
    const yearB = b.years[0];
    return yearA.name.localeCompare(yearB.name) || a.name.localeCompare(b.name);
  }
  private static classNumberComparer(classGroupId: number, a: ClassGroupStudent, b: ClassGroupStudent): number {
    // no classNumber -> last
    const cNrA = (a.classGroupClassNumberDict && a.classGroupClassNumberDict[classGroupId]) || Infinity;
    const cNrB = (b.classGroupClassNumberDict && b.classGroupClassNumberDict[classGroupId]) || Infinity;

    return cNrA - cNrB;
  }

  private static fullNameComparer(a: ClassGroupStudent, b: ClassGroupStudent): number {
    const fullNameA = a.name + a.firstName;
    const fullNameB = b.name + b.firstName;
    return fullNameA.localeCompare(fullNameB);
  }
}
