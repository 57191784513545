import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { PersonTermsInterface, TermsDTOInterface } from '../+models';
import { DalOptions, DAL_OPTIONS } from '../dal.module';
import { PersonTermsServiceInterface } from './person-terms.service.interface';

@Injectable({
  providedIn: 'root',
})
export class PersonTermsService implements PersonTermsServiceInterface {
  private getSummaryCache: TermsDTOInterface[] = null;

  private httpClient: HttpClient = inject(HttpClient);
  private dalOptions = inject<DalOptions>(DAL_OPTIONS);

  public getSummary(force = false): Observable<TermsDTOInterface[]> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/PersonTerms/summary`;

    if (this.getSummaryCache && !force) return of(this.getSummaryCache);

    return this.httpClient.get<TermsDTOInterface[]>(url, { withCredentials: true }).pipe(
      catchError(this.handleGetSummaryError),
      tap((terms) => (this.getSummaryCache = terms))
    );
  }

  public upsertPersonTerms(termsId: number, accepted: boolean): Observable<PersonTermsInterface> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/PersonTerms/upsert-person-terms`;
    const body = { termsId, accepted };

    return this.httpClient.post<PersonTermsInterface>(url, body, { withCredentials: true }).pipe(
      catchError(this.handleUpsertPersonTermsError),
      tap(() => (this.getSummaryCache = null))
    );
  }

  private handleUpsertPersonTermsError(error: HttpErrorResponse) {
    console.error('Error upserting person terms:', error);
    return throwError(new Error('Something went wrong; please try again later.'));
  }

  private handleGetSummaryError(error: HttpErrorResponse) {
    console.error('Error getting person terms summary:', error);
    return throwError(new Error('Something went wrong; please try again later.'));
  }
}
