import { BehaviorSubject } from 'rxjs';
import {
  ScormApiInterface,
  ScormCmiInterface,
  ScormCmiMode,
  ScormErrorCodes,
  ScormStatus,
} from './scorm-api.interface';

export class ScormApi implements ScormApiInterface {
  lastErrorCode: ScormErrorCodes = ScormErrorCodes.NO_ERROR;
  lastDiagnosticMessage = '';
  private currentCMI: ScormCmiInterface;
  private initialCMIValue: string;
  hasCalledLMSFinish: boolean;
  mode: ScormCmiMode;
  eduContentId?: number | null;

  /**
   * Stream that emits when the CMI data model needs to be saved to the server.
   *
   * @memberof ScormApi
   */
  commit$ = new BehaviorSubject<string>(null);

  /**
   * Stream that emits when the CMI data model has changed.
   * Used to updated the state.
   *
   * @memberof ScormApi
   */
  cmi$ = new BehaviorSubject<string>(null);

  constructor() {}

  setCurrentCMI(str: string) {
    this.initialCMIValue = str;
  }
  /**
   * Initialize the API and exercise.
   *
   * Resets the API, reads the right data from the window object
   * and sets the default values for the CMI data model.
   *
   * @returns {('true' | 'false')}
   * @memberof ScormApi
   */
  LMSInitialize(): 'true' | 'false' {
    this.reset();
    //check exerciseId and exercise info availability
    if (this.initialCMIValue) {
      try {
        this.currentCMI = JSON.parse(this.initialCMIValue);
      } catch (error) {
        this.lastErrorCode = ScormErrorCodes.NOT_INITIALIZED_ERROR;
        this.lastDiagnosticMessage = this.LMSGetErrorString(this.lastErrorCode);
        return 'false';
      }
      this.initialCMIValue = null;
    } else {
      this.currentCMI = this.getNewCmi();
    }

    this.currentCMI.mode = this.mode || ScormCmiMode.CMI_MODE_NORMAL;
    this.lastErrorCode = ScormErrorCodes.NO_ERROR;
    this.lastDiagnosticMessage = this.LMSGetErrorString(this.lastErrorCode);
    this.hasCalledLMSFinish = false;

    this.cmi$.next(JSON.stringify(this.currentCMI));

    return 'true';
  }

  /**
   * Finish the exercise.
   *
   * Saves the data to the server, removes the exercise window from above the game.
   *
   * @returns {('true' | 'false')}
   * @memberof ScormApiInterface
   */
  LMSFinish(): 'true' | 'false' {
    this.hasCalledLMSFinish = true;

    //check exerciseId and exercise info availability
    if (this.mode === ScormCmiMode.CMI_MODE_PREVIEW || this.mode === ScormCmiMode.CMI_MODE_REVIEW) {
      return 'true';
    }

    this.LMSCommit();

    if (!ScormStatus.isCompleted(this.currentCMI.core.lesson_status)) {
      return 'false';
    }

    return 'true';
  }
  /**
   * Get a value for the CMI Data Model
   *
   * @param {string} parameter the CMI Element identifier
   * @returns {('false' | string)} value for the requested CMI Element or 'false' if the value is not found
   * @memberof ScormApi
   */
  LMSGetValue(parameter: string): 'false' | string {
    if (this.checkInitialized() === false) {
      return 'false';
    }
    const value = this.getReferenceFromDotString(parameter, this.currentCMI);

    if (value !== undefined) {
      this.lastErrorCode = ScormErrorCodes.NO_ERROR;
      return value;
    }

    // no parameter available, pity
    this.lastErrorCode = ScormErrorCodes.NOT_IMPLEMENTED_ERROR;
    this.lastDiagnosticMessage = `Deze info (${parameter}) is niet beschikbaar`;
    return 'false';
  }

  /**
   * Sets a value for the CMI Data Model
   *
   * @param {string} parameter the CMI Element identifier
   * @param {string} value the value for the CMI Element identifier
   * @returns {('true' | 'false')}
   * @memberof ScormApi
   */
  LMSSetValue(parameter: string, value: string): 'true' | 'false' {
    //check exerciseId and exercise info availability
    if (this.mode === ScormCmiMode.CMI_MODE_PREVIEW || this.mode === ScormCmiMode.CMI_MODE_REVIEW) {
      return 'false';
    }

    if (this.checkInitialized() === false) {
      return 'false';
    }

    this.setReferenceFromDotString(parameter, value, this.currentCMI);

    this.cmi$.next(JSON.stringify(this.currentCMI));

    return 'true';
  }
  /**
   * Commits the CMI Data Model to the server.
   *
   * @returns {('true' | 'false')}
   * @memberof ScormApi
   */
  LMSCommit(): 'true' | 'false' {
    if (this.checkInitialized() === false) {
      return 'false';
    }

    //check exerciseId and exercise info availability
    if (this.mode === ScormCmiMode.CMI_MODE_PREVIEW || this.mode === ScormCmiMode.CMI_MODE_REVIEW) {
      return 'false';
    }

    this.commit$.next(JSON.stringify(this.currentCMI));
    return 'true';
  }
  /**
   * Get the code for the last error occured.
   *
   * @returns
   * @memberof ScormApi
   */
  LMSGetLastError(): ScormErrorCodes {
    return this.lastErrorCode;
  }
  /**
   * Get the detailed message for the last errorcode.
   *
   * @returns {string}
   * @memberof ScormApi
   */
  LMSGetDiagnostic(): string {
    return this.lastDiagnosticMessage;
  }

  /**
   * Get a short description for the specified errorCode
   *
   * @param {ScormErrorCodes} code code that identifies the error message
   * @returns {String} CMIErrorMessage
   * @memberof ScormApi
   */
  LMSGetErrorString(code: ScormErrorCodes): string {
    switch (code) {
      case ScormErrorCodes.NO_ERROR:
        return 'Geen vuiltje aan de lucht...';
      case ScormErrorCodes.INVALID_ARGUMENT_ERROR:
        return 'Foutief argument:';
      case ScormErrorCodes.ELEMENT_CANNOT_HAVE_CHILDREN_ERROR:
        return 'Dit element heeft geen _children';
      case ScormErrorCodes.ELEMENT_CANNOT_HAVE_COUNT_ERROR:
        return 'Dit element heeft geen _count';
      case ScormErrorCodes.NOT_INITIALIZED_ERROR:
        return 'De oefening werd niet correct opgestart';
      case ScormErrorCodes.NOT_IMPLEMENTED_ERROR:
        return 'Deze feature werd niet geïmplementeerd door het LMS';
      case ScormErrorCodes.INVALID_SET_VALUE_ELEMENT_IS_KEYWORD_ERROR:
        return 'Je kan geen waardes zetten voor keywords';
      case ScormErrorCodes.READ_ONLY_ERROR:
        return 'Dit element is alleen-lezen';
      case ScormErrorCodes.WRITE_ONLY_ERROR:
        return 'Dit element is alleen-schrijven';
      case ScormErrorCodes.INCORRECT_DATA_TYPE_ERROR:
        return 'De waarde die je wil schrijven is niet geldig';
      default:
        return 'Algemene fout:';
    }
  }

  private getNewCmi(): ScormCmiInterface {
    return {
      mode: this.mode,
      core: {
        score: {
          raw: 0,
          min: undefined,
          max: undefined,
        },
        lesson_location: '',
        lesson_status: ScormStatus.STATUS_INCOMPLETE,
        total_time: '0000:00:00',
        session_time: '0000:00:00',
      },
      objectives: {
        '0': getObjectives(),
        '1': getObjectives(),
        '2': getObjectives(),
        '3': getObjectives(),
        '4': getObjectives(),
        '5': getObjectives(),
        '6': getObjectives(),
        '7': getObjectives(),
        '8': getObjectives(),
        '9': getObjectives(),
      },
      suspend_data: '',
    };

    function getObjectives() {
      return {
        score: {
          raw: 0,
          min: undefined,
          max: undefined,
          scale: undefined,
        },
        status: ScormStatus.STATUS_INCOMPLETE,
      };
    }
  }

  private checkInitialized(): boolean {
    if (this.mode === ScormCmiMode.CMI_MODE_PREVIEW) {
      return true;
    }

    const cool = !!this.currentCMI;

    if (!cool) {
      this.lastErrorCode = ScormErrorCodes.NOT_INITIALIZED_ERROR;
    }
    this.lastDiagnosticMessage = this.LMSGetErrorString(this.lastErrorCode);
    return cool;
  }

  // Get the value of a reference from a dot-notation string
  private getReferenceFromDotString(parameter: string, exercise: ScormCmiInterface): string {
    function index(obj, prop, currentIndex, arr) {
      if (obj === undefined) {
        // It is very possible that a key is read before it has been set
        const lastProp = arr[arr.length - 1];
        if (lastProp === '_count') return 0;
        if (lastProp === '_children') return [];

        return;
      }

      if (prop === '_count') {
        return Object.keys(obj).length;
      }

      if (prop === '_children') {
        return Object.values(obj);
      }

      return obj[prop];
    }

    if (parameter.substring(0, 4) === 'cmi.') {
      parameter = parameter.substring(4);
    }

    // cmi.mode is Scorm 2004, lesson_mode is Scorm 1.1/1.2, they support the same values
    // but where they are on the cmi object is just different
    //
    // We allegedly use Scorm 1.2, but somehow we end up using cmi.mode instead,
    // so this line is a band-aid fix for that
    if (parameter === 'core.lesson_mode') {
      parameter = 'mode';
    }

    const value = parameter.split('.').reduce(index, exercise);

    if (value === undefined) {
      return;
    } else if (typeof value === 'object') {
      return JSON.stringify(value);
    } else {
      return value.toString();
    }
  }

  private setReferenceFromDotString(parameter: string, value: string, exercise: ScormCmiInterface) {
    function index(obj, prop, currentIndex, arr) {
      if (obj === undefined) {
        throw new Error('cannot set value of property ' + prop + ' on undefined');
      }
      if (new RegExp('^\\d+$').test(prop)) {
        prop = parseInt(prop, 10);
      }

      const isLastProp = currentIndex === arr.length - 1;
      if (isLastProp) {
        obj[prop] = value;
        return obj[prop];
      }

      if (!obj[prop]) {
        obj[prop] = {};
      }

      return obj[prop];
    }

    if (parameter.substring(0, 4) === 'cmi.') {
      parameter = parameter.substring(4);
    }

    parameter.split('.').reduce(index, exercise);
  }

  private reset() {
    this.lastErrorCode = ScormErrorCodes.NOT_INITIALIZED_ERROR;
    this.lastDiagnosticMessage = 'De oefening werd niet correct opgestart.';
  }
}
