/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { LevelApi as SDKLevelApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { LevelInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class LevelApi extends ModelApiBase {
  private SDKLevelApi = inject(SDKLevelApi);

  constructor(api: PolpoApi) {
    super(api, 'Levels');
  }

  find(options?): Observable<LevelInterface[]> {
    return this.SDKLevelApi.find(...arguments);
  }
}
