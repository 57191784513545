/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { RegistrationApi as SDKRegistrationApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { LearningRecordInterface, RegistrationInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class RegistrationApi extends ModelApiBase {
  private SDKRegistrationApi = inject(SDKRegistrationApi);

  constructor(api: PolpoApi) {
    super(api, 'Registrations');
  }

  createLearningRecord(
    registrationId: number,
    learningRecord: LearningRecordInterface
  ): Observable<LearningRecordInterface> {
    // @ts-expect-error TS 2556
    return this.SDKRegistrationApi.createLearningRecord(...arguments);
  }

  createRegistration(registration: Partial<RegistrationInterface>): Observable<RegistrationInterface> {
    return this.SDKRegistrationApi.createRegistration(registration);
  }
}
