import { Params } from '@angular/router';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NAME, State } from './router.reducer';

export const getRouterState = createFeatureSelector<State>(NAME);

export const getRouterStateParams = createSelector(getRouterState, (router): Params => {
  return router.state.params;
});
export const getRouterStateQueryParams = createSelector(getRouterState, (router): Params => {
  return router.state.queryParams;
});
