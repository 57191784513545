import { Injectable } from '@angular/core';
import { Navigation } from '@angular/router';
import { HistoryInterface } from '@campus/dal';
import { HistoryRedirectServiceInterface, SelectedHistoryTocResult } from './history-redirect.interface';

@Injectable({
  providedIn: 'root',
})
export class HistoryRedirectService implements HistoryRedirectServiceInterface {
  getUrlPartsFromNavigation(navigation: Navigation): string[] {
    const navigationFinalSegments = navigation.finalUrl?.root.children?.primary?.segments;
    return navigationFinalSegments?.length ? navigationFinalSegments.map((segment) => segment.path) : [];
  }

  getLastSelectedTOC(
    methodHistory: HistoryInterface[],
    bookId: number,
    productId?: number
  ): SelectedHistoryTocResult | null {
    const selectedHistoryTocResults: SelectedHistoryTocResult[] = methodHistory
      .filter((hist) => hist.criteria)
      .map((hist) => JSON.parse(hist.criteria))
      .filter((hist: SelectedHistoryTocResult) => {
        return hist.book === bookId && (!productId || hist.product === productId);
      });

    if (!selectedHistoryTocResults.length) return null;
    return selectedHistoryTocResults[0];
  }
}
