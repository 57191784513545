import { Component, HostBinding, Input } from '@angular/core';

export type SpeechBubbleSide = 'top' | 'bottom' | 'left' | 'right';
export type SpeechBubbleTriangle = 'start' | 'end';

@Component({
  selector: 'campus-speech-bubble',
  templateUrl: './speech-bubble.component.html',
})
export class SpeechBubbleComponent {
  private _progress: number;

  @HostBinding('class')
  defaultClasses = ['speech-bubble', 'relative', 'block', 'max-w-fit', 'index-surface', 'border', 'corner-l'];

  @HostBinding('attr.data-side')
  @Input()
  side: SpeechBubbleSide = 'right';

  @HostBinding('attr.data-triangle')
  @Input()
  triangle: SpeechBubbleTriangle = 'end';

  @Input()
  set progress(value: number) {
    value = value || 0;
    if (value > 100) {
      value = 100;
    }
    if (value < 0) {
      value = 0;
    }
    this._progress = value;
  }

  get progress() {
    return this._progress;
  }
}
