import { Inject, Injectable } from '@angular/core';
import {
  AuthService,
  BundleActions,
  BundleInterface,
  BundleQueries,
  ContentInterface,
  DalState,
  EduContent,
  EduContentActions,
  EduContentInterface,
  FavoriteQueries,
  FavoriteTypesEnum,
  HistoryQueries,
  HistoryTypesEnum,
  TaskActions,
  TaskEduContentActions,
  TaskEduContentInterface,
  TaskEduContentQueries,
  TaskInterface,
  TaskQueries,
  UnlockedContentActions,
  UnlockedContentQueries,
} from '@campus/dal';
import {
  CollectionManagerServiceInterface,
  COLLECTION_MANAGER_SERVICE_TOKEN,
  ItemToggledInCollectionInterface,
  ManageCollectionItemInterface,
} from '@campus/ui';
import { DateFunctions } from '@campus/utils';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, take } from 'rxjs/operators';
import { EduContentCollectionManagerServiceInterface } from './edu-content-collection-manager.service.interface';

@Injectable({
  providedIn: 'root',
})
export class EduContentCollectionManagerService implements EduContentCollectionManagerServiceInterface {
  constructor(
    private store: Store<DalState>,
    @Inject(COLLECTION_MANAGER_SERVICE_TOKEN)
    private collectionManagerService: CollectionManagerServiceInterface,
    private authService: AuthService
  ) {}

  /**
   * Open dialog and set up subscriptions to link content to bundles
   *
   * @param {ContentInterface | ContentInterface[]} contents
   * @param {number} learningAreaId required for EduContent, leave blank for UserContent
   */
  manageBundlesForContent(
    contents: ContentInterface | ContentInterface[],
    learningAreaId: number = null
  ): Observable<boolean> {
    const contentArr: ContentInterface[] = Array.isArray(contents) ? contents : [contents];
    const items: ManageCollectionItemInterface[] = contentArr.map((content) => ({
      id: content.id,
      label: content.name,
      icon: 'bundle',
    }));

    const title: string =
      (Array.isArray(contents) ? 'Lesmateriaal' : '"' + contents.name + '"') + ' toevoegen aan je bundels';

    const context =
      Array.isArray(contents) && contents.length > 1 ? `Je wil ${items.length} lesmaterialen toevoegen.` : undefined;

    // prepare streams
    let bundles$: Observable<BundleInterface[]>;
    if (learningAreaId) {
      bundles$ = this.store.pipe(
        select(BundleQueries.getByLearningAreaId),
        map((learningAreaDict) => learningAreaDict[learningAreaId] || [])
      );
    } else {
      bundles$ = this.store.pipe(select(BundleQueries.getAll));
    }
    const bundlesCollection$: Observable<ManageCollectionItemInterface[]> = bundles$.pipe(
      map((bundles: BundleInterface[]): ManageCollectionItemInterface[] => {
        return bundles.map(
          (bundle): ManageCollectionItemInterface => ({
            id: bundle.id,
            label: bundle.name,
            icon: 'bundle',
            linkToItem: 'bundles/' + bundle.id,
            linkToTooltip: 'Ga naar bundel detail',
          })
        );
      }),
      shareReplay(1)
    );
    const linkedBundleIds$: Observable<number[]> = combineLatest([
      this.store.pipe(select(UnlockedContentQueries.getAllByBundleAndEduContentId)),
      this.store.pipe(select(UnlockedContentQueries.getAllByBundleAndUserContentId)),
    ]).pipe(
      map(([byEduContentId, byUserContentId]) => {
        // return only bundleIds where all the contents are already linked in the bundle
        const bundleIds = Array.from(new Set([...Object.keys(byEduContentId), ...Object.keys(byUserContentId)]));
        return bundleIds
          .filter((bundleId) =>
            contentArr.every((content) => {
              if (content instanceof EduContent) {
                return byEduContentId[bundleId]?.[content.id];
              }
              return byUserContentId[bundleId]?.[content.id];
            })
          )
          .map((bundleId) => +bundleId);
      }),
      catchError((e) => {
        // if somehow the same content got linked twice to a bundle, creating the UnlockedContent dictionary will error
        console.error(e);
        return of([]);
      }),
      shareReplay(1)
    );
    const recentBundleIds$: Observable<number[]> = this.getRecentItemsStream(FavoriteTypesEnum.BUNDLE, 'bundleId');

    // subscribe to changeEvent
    const { itemToggled$, createCollection$, componentRef } = this.getManageCollectionEventStreams(
      title,
      items,
      bundlesCollection$,
      linkedBundleIds$,
      recentBundleIds$,
      'bundels',
      undefined,
      undefined,
      context
    );

    itemToggled$.subscribe((bundleToggled: ItemToggledInCollectionInterface) => {
      if (learningAreaId) {
        if (bundleToggled.selected) {
          this.addContentToStore(contentArr);
          this.addEduContentToBundle(bundleToggled.items, bundleToggled.relatedItem);
        } else {
          this.removeEduContentFromBundle(bundleToggled.items, bundleToggled.relatedItem);
        }
      } else {
        if (bundleToggled.selected) {
          this.addUserContentToBundle(bundleToggled.items, bundleToggled.relatedItem);
        } else {
          this.removeUserContentFromBundle(bundleToggled.items, bundleToggled.relatedItem);
        }
      }
    });

    createCollection$ // completes on dialog close
      .subscribe((collection) => {
        this.addContentToStore(contentArr);
        this.createBundle(
          collection.label,
          learningAreaId,
          contentArr.map((content) => content.id)
        );
      });

    return componentRef.instance.close.pipe(take(1));
  }

  manageTasksForContent(contents: EduContent | EduContent[]): Observable<boolean> {
    const contentArr: EduContent[] = Array.isArray(contents) ? contents : [contents];
    const items: ManageCollectionItemInterface[] = contentArr.map((content) => ({
      id: content.id,
      label: content.publishedEduContentMetadata.title,
      icon: 'task',
    }));

    const title: string =
      (Array.isArray(contents) ? 'Lesmateriaal' : '"' + contents.publishedEduContentMetadata.title + '"') +
      ' toevoegen aan je taken';

    const context =
      Array.isArray(contents) && contents.length > 1 ? `Je wil ${items.length} lesmaterialen toevoegen.` : undefined;

    // prepare streams
    const learningArea = contentArr[0].publishedEduContentMetadata.learningArea;
    const learningAreaId: number = contentArr[0].publishedEduContentMetadata.learningAreaId;
    const tasksCollection$: Observable<ManageCollectionItemInterface[]> = this.store.pipe(
      select(TaskQueries.getByLearningAreaId),
      map((tasksDict) =>
        (tasksDict[learningAreaId] || [])
          .filter((task) => !task.isAdaptive && !task.evaluationScoreListId && !task.archivedYear)
          .map((task) => ({
            id: task.id,
            label: task.name,
            icon: 'task',
            linkToItem: 'tasks/manage/' + task.id,
          }))
      ),
      shareReplay(1)
    );
    const linkedTaskIds$: Observable<number[]> = this.store.pipe(
      select(TaskEduContentQueries.getAllByTaskAndEduContentId),
      map((taskEduContentsDict) => {
        return Object.keys(taskEduContentsDict)
          .filter((taskId) => contentArr.every((content) => taskEduContentsDict[taskId][content.id]))
          .map((taskId) => +taskId);
      }),
      catchError((e) => {
        // if somehow the same eduContent got linked twice to a task, creating the TaskEduContent dictionary will error
        console.error(e);
        return of([]);
      }),
      shareReplay(1)
    );
    const recentTaskIds$: Observable<number[]> = this.getRecentItemsStream(HistoryTypesEnum.TASK, 'taskId');

    const contentLearningArea = learningArea?.name;
    const subtitle = `Taken voor ${contentLearningArea}`;

    // subscribe to changeEvent
    const { itemToggled$, createCollection$, componentRef } = this.getManageCollectionEventStreams(
      title,
      items,
      tasksCollection$,
      linkedTaskIds$,
      recentTaskIds$,
      'taken',
      subtitle,
      'Nieuwe taak toevoegen',
      context
    );

    itemToggled$ // completes on dialog close
      .subscribe((taskToggled: ItemToggledInCollectionInterface) => {
        if (taskToggled.selected) {
          this.addContentToStore(contentArr);
          this.addContentToTask(taskToggled.items, taskToggled.relatedItem);
        } else {
          this.removeContentFromTask(taskToggled.items, taskToggled.relatedItem);
        }
      });

    createCollection$ // completes on dialog close
      .subscribe((collection) => {
        this.addContentToStore(contentArr);
        this.createTask(
          collection.label,
          learningAreaId,
          contentArr.map((content) => content.id)
        );
      });

    return componentRef.instance.close.pipe(take(1));
  }

  private addContentToTask(contents: ManageCollectionItemInterface[], task: ManageCollectionItemInterface): void {
    const taskEduContents: Partial<TaskEduContentInterface>[] = contents.map((content) => ({
      taskId: task.id,
      eduContentId: content.id,
      index: 0,
    }));

    this.store.dispatch(
      new TaskEduContentActions.StartAddTaskEduContents({
        userId: this.authService.userId,
        taskEduContents,
      })
    );
  }

  private addEduContentToBundle(
    contents: ManageCollectionItemInterface[],
    bundle: ManageCollectionItemInterface
  ): void {
    this.store.dispatch(
      new BundleActions.LinkEduContent({
        bundleId: bundle.id,
        eduContentIds: contents.map((content) => content.id),
      })
    );
  }

  private addUserContentToBundle(contents: ManageCollectionItemInterface[], bundle: ManageCollectionItemInterface) {
    this.store.dispatch(
      new BundleActions.LinkUserContent({
        bundleId: bundle.id,
        userContentIds: contents.map((content) => content.id),
      })
    );
  }

  private removeContentFromTask(contents: ManageCollectionItemInterface[], task: ManageCollectionItemInterface): void {
    this.store
      .pipe(
        select(TaskEduContentQueries.getAllByTaskAndEduContentId),
        map((taskEduContentsDict): number[] => {
          return contents
            .filter((content) => taskEduContentsDict[task.id]?.[content.id])
            .map((content) => taskEduContentsDict[task.id][content.id].id);
        }),
        take(1)
      )
      .subscribe((taskEduContentIds) => {
        if (taskEduContentIds.length === 0) return;
        this.store.dispatch(
          new TaskEduContentActions.StartDeleteTaskEduContents({
            userId: this.authService.userId,
            taskEduContentIds,
          })
        );
      });
  }

  private removeEduContentFromBundle(
    contents: ManageCollectionItemInterface[],
    bundle: ManageCollectionItemInterface
  ): void {
    this.store
      .pipe(
        select(UnlockedContentQueries.getAllByBundleAndEduContentId),
        map((unlockedContentsDict): number[] => {
          return contents
            .filter((content) => unlockedContentsDict[bundle.id]?.[content.id])
            .map((content) => unlockedContentsDict[bundle.id][content.id].id);
        }),
        take(1)
      )
      .subscribe((unlockedContentIds) => {
        if (!unlockedContentIds.length) return;
        this.store.dispatch(
          new UnlockedContentActions.DeleteUnlockedContents({
            ids: unlockedContentIds,
          })
        );
      });
  }

  private removeUserContentFromBundle(
    contents: ManageCollectionItemInterface[],
    bundle: ManageCollectionItemInterface
  ): void {
    this.store
      .pipe(
        select(UnlockedContentQueries.getAllByBundleAndUserContentId),
        map((unlockedContentsDict): number[] => {
          return contents
            .filter((content) => unlockedContentsDict[bundle.id]?.[content.id])
            .map((content) => unlockedContentsDict[bundle.id][content.id].id);
        }),
        take(1)
      )
      .subscribe((unlockedContentIds) => {
        if (!unlockedContentIds.length) return;
        this.store.dispatch(
          new UnlockedContentActions.DeleteUnlockedContents({
            ids: unlockedContentIds,
          })
        );
      });
  }

  private getRecentItemsStream(type: FavoriteTypesEnum | HistoryTypesEnum, key: string): Observable<number[]> {
    return combineLatest([
      this.store.select(FavoriteQueries.favoritesByType),
      this.store.select(HistoryQueries.historyByType),
    ]).pipe(
      map(([favoritesByType, historiesByType]): number[] => {
        const favorites = favoritesByType[type] || [];
        const histories = historiesByType[type] || [];
        return Array.from(
          new Set<number>(
            [...favorites, ...histories]
              .sort((a, b) => b.created.getTime() - a.created.getTime())
              .map((item) => item[key])
          )
        );
      }),
      shareReplay(1)
    );
  }

  private getManageCollectionEventStreams(
    title: string,
    items: ManageCollectionItemInterface[],
    linkableItems$: Observable<ManageCollectionItemInterface[]>,
    linkedItemIds$: Observable<number[]>,
    recentItemIds$: Observable<number[]>,
    collectionType: string,
    subtitle?: string,
    createCollectionLabel?: string,
    context?: string
  ) {
    return this.collectionManagerService.manageCollectionsDynamic(
      title,
      items,
      linkableItems$,
      linkedItemIds$,
      recentItemIds$,
      collectionType,
      subtitle,
      createCollectionLabel,
      context
    );
  }

  private createTask(name: string, learningAreaId: number, eduContentIds?: number[]) {
    const task: TaskInterface = {
      name,
      learningAreaId,
      isOpen: true,
      isAdaptive: false,
    };
    const userId = this.authService.userId;

    this.store.dispatch(
      new TaskActions.StartAddTask({
        task,
        userId,
        linkEduContent: {
          eduContentIds,
        },
        writeToHistory: true,
      })
    );
  }

  private createBundle(name: string, learningAreaId: number, eduContentIds?: number[]) {
    const bundle: BundleInterface = {
      name,
      learningAreaId,
      start: DateFunctions.tomorrow(new Date()),
      end: DateFunctions.getSchoolYearBoundaries(new Date()).end,
    };

    this.store.dispatch(
      new BundleActions.StartAddBundle({
        userId: this.authService.userId,
        bundle,
        linkEduContent: {
          eduContentIds,
        },
        writeToHistory: true,
      })
    );
  }

  private addContentToStore(contents: (ContentInterface | EduContent)[]) {
    const eduContents: EduContentInterface[] = contents
      .filter((content) => {
        return content instanceof EduContent && content.minimal;
      })
      .map((content: EduContent) => content.minimal);

    if (eduContents.length) {
      this.store.dispatch(new EduContentActions.AddEduContents({ eduContents }));
    }
  }
}
