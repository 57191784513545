import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch, pessimisticUpdate } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { LicenseQueries } from '.';
import { LicenseService } from '../../license';
import { DalState } from '../dal.state.interface';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { LicensesActionTypes, LicensesLoaded, LicensesLoadError, LoadLicenses, UpdateLicense } from './license.actions';

@Injectable()
export class LicenseEffects {
  loadLicenses$ = createEffect(() =>
    this.actions.pipe(
      ofType(LicensesActionTypes.LoadLicenses),
      concatLatestFrom(() => this.store.select(LicenseQueries.getLoaded)),
      fetch({
        run: (action: LoadLicenses, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.methodLicenseGroupService.getAllForUser(action.payload.userId).pipe(
            map(
              (licenses) =>
                new LicensesLoaded({
                  licenses,
                })
            )
          );
        },
        onError: (action: LoadLicenses, error) => {
          return new LicensesLoadError(error);
        },
      })
    )
  );

  startUpdateLicense$ = createEffect(() =>
    this.actions.pipe(
      ofType(LicensesActionTypes.UpdateLicense),
      pessimisticUpdate({
        run: (action: UpdateLicense) => {
          return this.methodLicenseGroupService
            .updateLicense(action.payload.license.id, action.payload.license.changes)
            .pipe(
              map(() => {
                return new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'De licentie werd bijgewerkt.'
                  ),
                });
              })
            );
        },
        onError: (action: UpdateLicense, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de licentie bij te werken.'
            ),
          });
          return errorFeedbackAction;
        },
      })
    )
  );

  constructor(
    @Inject('uuid')
    private uuid,
    private actions: Actions,
    private store: Store<DalState>,
    private methodLicenseGroupService: LicenseService
  ) {}
}
