import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { SchoolRoleMappingInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum SchoolRoleMappingsActionTypes {
  SchoolRoleMappingsLoaded = '[SchoolRoleMappings] SchoolRoleMappings Loaded',
  SchoolRoleMappingsLoadError = '[SchoolRoleMappings] Load Error',
  AddSchoolRoleMapping = '[SchoolRoleMappings] Add SchoolRoleMapping',
  UpsertSchoolRoleMappings = '[SchoolRoleMappings] Upsert SchoolRoleMappings',
  UpdateSchoolRoleMapping = '[SchoolRoleMappings] Update SchoolRoleMapping',
  DeleteSchoolRoleMapping = '[SchoolRoleMappings] Delete SchoolRoleMapping',
  DeleteSchoolRoleMappings = '[SchoolRoleMappings] Delete SchoolRoleMappings',
}

export class SchoolRoleMappingsLoaded implements Action {
  readonly type = SchoolRoleMappingsActionTypes.SchoolRoleMappingsLoaded;

  constructor(public payload: { schoolRoleMappings: SchoolRoleMappingInterface[] }) {}
}

export class SchoolRoleMappingsLoadError implements Action {
  readonly type = SchoolRoleMappingsActionTypes.SchoolRoleMappingsLoadError;
  constructor(public payload: any) {}
}

export class AddSchoolRoleMapping implements Action {
  readonly type = SchoolRoleMappingsActionTypes.AddSchoolRoleMapping;

  constructor(
    public payload: {
      schoolRoleMapping: SchoolRoleMappingInterface;
    }
  ) {}
}

export class UpsertSchoolRoleMappings implements Action {
  readonly type = SchoolRoleMappingsActionTypes.UpsertSchoolRoleMappings;

  constructor(
    public payload: {
      schoolRoleMappings: SchoolRoleMappingInterface[];
    }
  ) {}
}

export class UpdateSchoolRoleMapping implements Action {
  readonly type = SchoolRoleMappingsActionTypes.UpdateSchoolRoleMapping;

  constructor(
    public payload: {
      schoolRoleMapping: Update<SchoolRoleMappingInterface>;
    }
  ) {}
}

export class DeleteSchoolRoleMapping implements FeedbackTriggeringAction {
  readonly type = SchoolRoleMappingsActionTypes.DeleteSchoolRoleMapping;

  constructor(
    public payload: {
      schoolId: number;
      id: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class DeleteSchoolRoleMappings implements FeedbackTriggeringAction {
  readonly type = SchoolRoleMappingsActionTypes.DeleteSchoolRoleMappings;

  constructor(
    public payload: {
      ids: number[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export type SchoolRoleMappingsActions =
  | SchoolRoleMappingsLoaded
  | SchoolRoleMappingsLoadError
  | AddSchoolRoleMapping
  | UpsertSchoolRoleMappings
  | UpdateSchoolRoleMapping
  | DeleteSchoolRoleMappings
  | DeleteSchoolRoleMapping;
