import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EduContentProductTypeApi } from '../+api';
import { EduContentProductTypeInterface } from '../+models';
import { EduContentProductTypeServiceInterface } from './edu-content-product-type.service.interface';

@Injectable({
  providedIn: 'root',
})
export class EduContentProductTypeService implements EduContentProductTypeServiceInterface {
  constructor(private eduContentProductTypeApi: EduContentProductTypeApi) {}

  getAll(): Observable<EduContentProductTypeInterface[]> {
    return this.eduContentProductTypeApi.find();
  }
}
