import { Action } from '@ngrx/store';
import { TaskDataResponseInterface } from '../../../data/data.service.interface';

export enum TaskDataActionTypes {
  LoadTaskData = '[Data] Load Task Data',
  TaskDataLoaded = '[Data] Task Data Loaded',
  TaskDataLoadError = '[Data] Task Data Load Error',
}

export class TaskDataLoadError implements Action {
  readonly type = TaskDataActionTypes.TaskDataLoadError;
  constructor(public payload: any) {}
}

export class LoadTaskData implements Action {
  readonly type = TaskDataActionTypes.LoadTaskData;

  constructor(
    public payload: {
      taskId: number;
      fields: Array<keyof TaskDataResponseInterface>;
      force?: boolean;
    }
  ) {}
}

export class TaskDataLoaded implements Action {
  readonly type = TaskDataActionTypes.TaskDataLoaded;

  constructor(
    public payload: {
      taskId: number;
    }
  ) {}
}
