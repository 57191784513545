import { trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ENVIRONMENT_ASSETS_TOKEN, EnvironmentAssetsInterface, LottieConfigInterface } from '@campus/environment';
import { Action } from '@ngrx/store';
import { AnimationConfigWithPath } from 'lottie-web';
import { BehaviorSubject, Observable, Subscription, fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, map, share, throttleTime } from 'rxjs/operators';
import { fadeAnimation, fadeInOnlyAnimation, fadeRotateAnimation } from '../animations';

/**
 * Component that acts a a skeleton for the app.
 * Contains placeholders for left-side content, logo, header-bar, body content and bottom content
 * Handles responsiveness for the side content as well
 * @example
 * <campus-shell>
 *   <campus-shell-logo>
 *       <img src="logo.png" alt="Logo">
 *   </campus-shell-logo>
 *   <campus-shell-left>
 *       <ul>
 *           <li><a href="#">home</a></li>
 *           <li><a href="#">about</a></li>
 *       </ul>
 *   </campus-shell-left>
 *   <campus-shell-top>
 *       Home > dashboard
 *   </campus-shell-top>
 *   <campus-shell-bottom>
 *      Ideal for a footer
 *   </campus-shell-bottom>
 *   <h1>Hello world</h1>
 *   <p>Is it wrong to be strong?</p>
 * </campus-shell>
 */
@Component({
  selector: 'campus-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fade', fadeAnimation),
    trigger('fadeRotate', fadeRotateAnimation),
    trigger('simpleFadeAnimation', fadeInOnlyAnimation),
  ],
})
export class ShellComponent implements OnInit, OnDestroy {
  @HostBinding('class.ui-shell')
  hasUiShellClass = true;

  @HostBinding('class.ui-shell--scrolled')
  hasScrolledClass: boolean;

  @Input() bodyScrolledOffset = 70;

  @Input() pageTitle: string;
  @Input() usetifulTag = 'backdrop-menu-icon';
  @Input() pageIcon: string;
  @Input() navigateTo?: string[];
  @Input() bannerFeedback: {
    id: string;
    message: string;
    icon: string;
    triggerAction: null;
    userActions: [];
  };

  @HostBinding('class.ui-shell--dropped')
  @Input()
  dropped: boolean;
  @Input() menuOpen: boolean;

  @HostBinding('class.ui-shell--dense-menu')
  @Input()
  denseMenu: boolean;

  @Output() bannerDismiss = new EventEmitter<{
    action: Action;
    feedbackId: string;
  }>();
  @Output() menuToggle = new EventEmitter<boolean>();

  scrollEvent$: Observable<Event>;
  scrollViewPortTop$ = new BehaviorSubject<number>(0);

  public lottieBgOptions: LottieConfigInterface;
  public lottieIconOptions$ = new BehaviorSubject<Partial<AnimationConfigWithPath>>({});

  lottieIcon: string;
  toolbarResizeObserver: ResizeObserver;
  toolbarMutationObserver: MutationObserver;

  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private el: ElementRef,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    @Inject(ENVIRONMENT_ASSETS_TOKEN) private environmentAssets: EnvironmentAssetsInterface
  ) {}

  ngOnInit() {
    const bodyEl = this.el.nativeElement.querySelector('.ui-shell__body');
    const toolbarNode = this.el.nativeElement.querySelector('.ui-shell__toolbar');

    const toolbarChanged = () => {
      this.scrollViewPortTop$.next(bodyEl.getBoundingClientRect().top);
    };
    if (window.ResizeObserver) {
      this.toolbarResizeObserver = new ResizeObserver(toolbarChanged);
      this.toolbarResizeObserver.observe(toolbarNode);
    }
    if (window.MutationObserver) {
      this.toolbarMutationObserver = new MutationObserver(toolbarChanged);
      this.toolbarMutationObserver.observe(toolbarNode, { childList: true });
    }

    this.scrollEvent$ = fromEvent(bodyEl, 'scroll');

    const scrolled$ = this.scrollEvent$.pipe(
      throttleTime(10),
      map(() => bodyEl.scrollTop > this.bodyScrolledOffset),
      distinctUntilChanged(),
      share()
    );

    this.lottieBgOptions = this.environmentAssets.animations?.menuBackground;

    this.setMenuAnimation(window.location.href);

    this.subscriptions.add(
      this.router.events
        .pipe(filter((routerEvent) => routerEvent instanceof NavigationEnd))
        .subscribe((r: NavigationEnd) => {
          this.menuToggle.emit(false);
          if (!this.route.snapshot.queryParamMap.has('highlight')) {
            bodyEl.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          }

          this.setMenuAnimation(r.url);

          this.hasScrolledClass = false;
          this.cdRef.markForCheck();
        })
    );

    this.subscriptions.add(
      scrolled$.subscribe((scrolled) => {
        this.hasScrolledClass = scrolled;
        this.cdRef.markForCheck();
      })
    );
  }

  private setMenuAnimation(route: string) {
    const icon = Object.keys(this.environmentAssets.animations)
      .filter((animation) => animation.startsWith('menu:'))
      .map((animation) => animation.split(':')[1])
      .find((part) => route.includes(part));

    if (!icon) return;

    this.lottieIcon = 'menu:' + icon;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    if (window.ResizeObserver) {
      this.toolbarResizeObserver.disconnect();
    }
    if (window.MutationObserver) {
      this.toolbarMutationObserver.disconnect();
    }
  }

  clickNavigateTo() {
    this.router.navigate(this.navigateTo);
  }
}
