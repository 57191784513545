import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { EduContentMetadataInterface } from '../../+models';

export enum EduContentMetadatasActionTypes {
  EduContentMetadatasLoaded = '[EduContentMetadatas] EduContentMetadatas Loaded',
  EduContentMetadatasLoadError = '[EduContentMetadatas] Load Error',
  LoadEduContentMetadatas = '[EduContentMetadatas] Load EduContentMetadatas',
  AddEduContentMetadata = '[EduContentMetadatas] Add EduContentMetadata',
  UpsertEduContentMetadata = '[EduContentMetadatas] Upsert EduContentMetadata',
  AddEduContentMetadatas = '[EduContentMetadatas] Add EduContentMetadatas',
  UpsertEduContentMetadatas = '[EduContentMetadatas] Upsert EduContentMetadatas',
  UpdateEduContentMetadata = '[EduContentMetadatas] Update EduContentMetadata',
  UpdateEduContentMetadatas = '[EduContentMetadatas] Update EduContentMetadatas',
  DeleteEduContentMetadata = '[EduContentMetadatas] Delete EduContentMetadata',
  DeleteEduContentMetadatas = '[EduContentMetadatas] Delete EduContentMetadatas',
  ClearEduContentMetadatas = '[EduContentMetadatas] Clear EduContentMetadatas',
  LoadEduContentMetadatasForBook = '[EduContentMetadatas] Load EduContentMetadatas For Book',
  AddEduContentMetadatasForBook = '[EduContentMetadatas] Add EduContentMetadatas For Book',
  AddEduContentMetadatasDraftForBook = '[EduContentMetadatas] Add Draft EduContentMetadatas For Book',
  AddEduContentMetadatasPublishedForBook = '[EduContentMetadatas] Add Published EduContentMetadatas For Book',
  LoadEduContentMetadatasWithArtifact = '[EduContentMetadatas] Load EduContentMetadatas With Artifact',
  AddEduContentMetadatasWithArtifact = '[EduContentMetadatas] Add EduContentMetadatas With Artifact',
}

export class LoadEduContentMetadatas implements Action {
  readonly type = EduContentMetadatasActionTypes.LoadEduContentMetadatas;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class EduContentMetadatasLoaded implements Action {
  readonly type = EduContentMetadatasActionTypes.EduContentMetadatasLoaded;

  constructor(
    public payload: {
      eduContentMetadatas: EduContentMetadataInterface[];
    }
  ) {}
}

export class EduContentMetadatasLoadError implements Action {
  readonly type = EduContentMetadatasActionTypes.EduContentMetadatasLoadError;
  constructor(public payload: any) {}
}

export class AddEduContentMetadata implements Action {
  readonly type = EduContentMetadatasActionTypes.AddEduContentMetadata;

  constructor(public payload: { eduContentMetadata: EduContentMetadataInterface }) {}
}

export class UpsertEduContentMetadata implements Action {
  readonly type = EduContentMetadatasActionTypes.UpsertEduContentMetadata;

  constructor(public payload: { eduContentMetadata: EduContentMetadataInterface }) {}
}

export class AddEduContentMetadatas implements Action {
  readonly type = EduContentMetadatasActionTypes.AddEduContentMetadatas;

  constructor(public payload: { eduContentMetadatas: EduContentMetadataInterface[] }) {}
}

export class UpsertEduContentMetadatas implements Action {
  readonly type = EduContentMetadatasActionTypes.UpsertEduContentMetadatas;

  constructor(public payload: { eduContentMetadatas: EduContentMetadataInterface[] }) {}
}

export class UpdateEduContentMetadata implements Action {
  readonly type = EduContentMetadatasActionTypes.UpdateEduContentMetadata;

  constructor(public payload: { eduContentMetadata: Update<EduContentMetadataInterface> }) {}
}

export class UpdateEduContentMetadatas implements Action {
  readonly type = EduContentMetadatasActionTypes.UpdateEduContentMetadatas;

  constructor(public payload: { eduContentMetadatas: Update<EduContentMetadataInterface>[] }) {}
}

export class DeleteEduContentMetadata implements Action {
  readonly type = EduContentMetadatasActionTypes.DeleteEduContentMetadata;

  constructor(public payload: { id: number }) {}
}

export class DeleteEduContentMetadatas implements Action {
  readonly type = EduContentMetadatasActionTypes.DeleteEduContentMetadatas;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearEduContentMetadatas implements Action {
  readonly type = EduContentMetadatasActionTypes.ClearEduContentMetadatas;
}

export class LoadEduContentMetadatasForBook implements Action {
  readonly type = EduContentMetadatasActionTypes.LoadEduContentMetadatasForBook;

  constructor(public payload: { bookId: number }) {}
}

export class AddEduContentMetadatasForBook implements Action {
  readonly type = EduContentMetadatasActionTypes.AddEduContentMetadatasForBook;

  constructor(public payload: { bookId: number; eduContentMetadatas: EduContentMetadataInterface[] }) {}
}

export class AddEduContentMetadatasDraftForBook implements Action {
  readonly type = EduContentMetadatasActionTypes.AddEduContentMetadatasDraftForBook;

  constructor(public payload: { bookId: number; eduContentMetadatas: EduContentMetadataInterface[] }) {}
}

export class AddEduContentMetadatasPublishedForBook implements Action {
  readonly type = EduContentMetadatasActionTypes.AddEduContentMetadatasPublishedForBook;

  constructor(public payload: { bookId: number; eduContentMetadatas: EduContentMetadataInterface[] }) {}
}

export class LoadEduContentMetadatasWithArtifact implements Action {
  readonly type = EduContentMetadatasActionTypes.LoadEduContentMetadatasWithArtifact;

  constructor() {}
}

export class AddEduContentMetadatasWithArtifact implements Action {
  readonly type = EduContentMetadatasActionTypes.AddEduContentMetadatasWithArtifact;

  constructor(public payload: { eduContentMetadatas: EduContentMetadataInterface[] }) {}
}

export type EduContentMetadatasActions =
  | LoadEduContentMetadatas
  | EduContentMetadatasLoaded
  | EduContentMetadatasLoadError
  | AddEduContentMetadata
  | UpsertEduContentMetadata
  | AddEduContentMetadatas
  | UpsertEduContentMetadatas
  | UpdateEduContentMetadata
  | UpdateEduContentMetadatas
  | DeleteEduContentMetadata
  | DeleteEduContentMetadatas
  | ClearEduContentMetadatas
  | LoadEduContentMetadatasForBook
  | AddEduContentMetadatasForBook
  | AddEduContentMetadatasDraftForBook
  | AddEduContentMetadatasPublishedForBook
  | LoadEduContentMetadatasWithArtifact
  | AddEduContentMetadatasWithArtifact;
