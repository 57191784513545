import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { ClassGroupInterface, DalState, SchoolRoleMappingClassGroupQueries } from '@campus/dal';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UniqueClassNumberValidator implements AsyncValidator {
  constructor(private store: Store<DalState>) {}

  private classGroup: ClassGroupInterface;
  private exclude: number;

  public setClassGroup(classGroup: ClassGroupInterface) {
    this.classGroup = classGroup;
  }
  public setExclude(value: number) {
    this.exclude = value;
  }

  validate(ctrl: AbstractControl): Observable<ValidationErrors | null> {
    if (this.exclude && this.exclude === ctrl.value) {
      return of(null);
    }

    return this.store.select(SchoolRoleMappingClassGroupQueries.getByClassGroupByRole).pipe(
      take(1),
      map((srmCGDict) => {
        if (!this.classGroup) {
          return null;
        }

        const srmForClassGroup = srmCGDict[this.classGroup.id];
        if (!srmForClassGroup) {
          return null;
        }

        const classNumbers = Object.values(srmForClassGroup).map((srmCG) => srmCG.classNumber);

        return classNumbers.includes(+ctrl.value) ? { uniqueClassNumber: true } : null;
      })
    );
  }
}
