import { Inject, Injectable } from '@angular/core';
import {
  AlertActions,
  AlertQueries,
  AuthServiceInterface,
  AUTH_SERVICE_TOKEN,
  CredentialActions,
  CredentialQueries,
  DalState,
  DataActions,
  FavoriteActions,
  FavoriteQueries,
  HistoryQueries,
  LearningAreaQueries,
  MethodQueries,
  RoleQueries,
  StateResolver,
  TaskEduContentQueries,
  TaskInstanceQueries,
  TaskQueries,
  UiActions,
  UiQuery,
} from '@campus/dal';
import { Action, Selector, Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class AppResolver extends StateResolver {
  constructor(private store: Store<DalState>, @Inject(AUTH_SERVICE_TOKEN) private authService: AuthServiceInterface) {
    super(store);
  }

  protected getLoadableActions(): Action[] {
    const userId = this.authService.userId;

    return [
      new DataActions.LoadData({
        userId,
        fields: [
          'allowedMethods',
          'learningAreas',
          'history',
          'bundles',
          'tasks',
          'taskInstances',
          'taskEduContents',
          'eduContents',
          'roles',
        ],
      }),
      new CredentialActions.LoadCredentials({ userId }),
      new AlertActions.LoadAlerts({ userId }),
      new UiActions.LoadUi(),
      new FavoriteActions.LoadFavorites({ userId }),
    ];
  }
  protected getResolvedQueries(): Selector<object, boolean>[] {
    return [
      CredentialQueries.getLoaded,
      AlertQueries.getLoaded,
      LearningAreaQueries.getLoaded,
      MethodQueries.getAllowedMethodsLoaded,
      FavoriteQueries.getLoaded,
      HistoryQueries.getLoaded,
      UiQuery.getLoaded,
      TaskQueries.getLoaded,
      TaskInstanceQueries.getLoaded,
      TaskEduContentQueries.getLoaded,
      RoleQueries.getLoaded,
    ];
  }
}
