import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LevelApi } from '../+api';
import { LevelInterface } from '../+models';
import { LevelServiceInterface } from './level.service.interface';
@Injectable({
  providedIn: 'root',
})
export class LevelService implements LevelServiceInterface {
  constructor(private levelApi: LevelApi) {}

  getAll(): Observable<LevelInterface[]> {
    return this.levelApi.find();
  }
}
