import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import {
  BROWSER_STORAGE_SERVICE_TOKEN,
  BrowserModule as CampusBrowserModule,
  FILEREADER_SERVICE_TOKEN,
  FileReaderService,
  StorageService,
} from '@campus/browser';

import { v4 as uuid } from 'uuid';
import { v4String } from 'uuid/interfaces';
import { ApiModule } from './+api/api.module';
import { DATA_FIELD_MAPPERS_TOKEN, defaultFieldMappers } from './+state/data/mappers/data-field-mappers';

export interface DalOptions {
  apiBaseUrl: string;
  apiV2BaseUrl?: string;
}
export const DAL_OPTIONS = new InjectionToken('dal-options');

export const UUID_TOKEN = new InjectionToken<v4String>('uuid');

@NgModule({
  imports: [CampusBrowserModule, CommonModule, ApiModule, HttpClientModule, MatSnackBarModule],
  providers: [
    {
      provide: 'uuid',
      useValue: uuid,
    },
    {
      provide: UUID_TOKEN,
      useValue: uuid,
    },
    {
      provide: DATA_FIELD_MAPPERS_TOKEN,
      useValue: defaultFieldMappers,
    },
    { provide: BROWSER_STORAGE_SERVICE_TOKEN, useClass: StorageService },
  ],
})
export class DalModule {
  static forRoot(options: DalOptions): ModuleWithProviders<DalModule> {
    return {
      ngModule: DalModule,
      providers: [
        {
          provide: DAL_OPTIONS,
          useValue: options,
        },
        { provide: FILEREADER_SERVICE_TOKEN, useClass: FileReaderService },
      ],
    };
  }
}
