import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import {
  AuthServiceInterface,
  AUTH_SERVICE_TOKEN,
  CurriculumTreeActions,
  CurriculumTreeQueries,
  DalState,
  UnlockedCurriculumTreeActions,
  UnlockedCurriculumTreeQueries,
} from '@campus/dal';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable()
export class AllowedStrandGuard implements CanActivate {
  constructor(private store: Store<DalState>, @Inject(AUTH_SERVICE_TOKEN) private authService: AuthServiceInterface) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const userId = this.authService.userId;
    this.store.dispatch(new CurriculumTreeActions.LoadCurriculumTrees({ userId }));
    this.store.dispatch(new UnlockedCurriculumTreeActions.LoadUnlockedCurriculumTrees({ userId }));

    return combineLatest([
      this.store.pipe(
        select(CurriculumTreeQueries.getLoaded),
        filter((isLoaded) => isLoaded),
        switchMap(() => {
          return this.store.pipe(select(CurriculumTreeQueries.getAllStrands));
        })
      ),
      this.store.pipe(
        select(UnlockedCurriculumTreeQueries.getLoaded),
        filter((isLoaded) => isLoaded),
        switchMap(() => this.store.pipe(select(UnlockedCurriculumTreeQueries.getByCurriculumTreeId)))
      ),
    ]).pipe(
      map(([strands, unlockedCurriculumTreesByCurriculumTreeGuid]) => {
        const strand = strands.find((s) => s.guid === route.params.strandId);
        if (!strand) return false;

        const unlockedCurriculumTrees = unlockedCurriculumTreesByCurriculumTreeGuid[strand.guid];
        const isUnlocked = Array.isArray(unlockedCurriculumTrees) && !!unlockedCurriculumTrees.length;
        return isUnlocked;
      })
    );
  }
}
