import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonApi } from '../+api';
import { AchievementInterface } from '../+models';

@Injectable({
  providedIn: 'root',
})
export class AchievementService {
  constructor(private personApi: PersonApi) {}

  getAllForUser(userId: number): Observable<AchievementInterface[]> {
    return this.personApi
      .getData(userId, 'achievements')
      .pipe(map((res: { achievements: AchievementInterface[] }) => res.achievements));
  }
}
