import { Inject, Injectable, Optional } from '@angular/core';
import { Params, Router } from '@angular/router';
import {
  AuthServiceInterface,
  AUTH_SERVICE_TOKEN,
  BundleInterface,
  BundleQueries,
  ContextDomainEnum,
  createHistoryFromEduContent,
  DalState,
  EduContent,
  EduContentActions,
  EduContentInterface,
  EduContentQueries,
  EduContentTypeEnum,
  EffectFeedbackActions,
  EffectFeedbackInterface,
  EffectFeedbackQueries,
  FavoriteActions,
  FavoriteInterface,
  FavoriteQueries,
  FavoriteTypesEnum,
  HistoryActions,
  HistoryInterface,
  HistoryQueries,
  HistoryTypesEnum,
  LearningAreaInterface,
  LearningAreaQueries,
  SettingsPermissions,
  TaskInterface,
  TaskQueries,
} from '@campus/dal';
import {
  EnvironmentFavoritesFeatureInterface,
  EnvironmentHistoryFeatureInterface,
  ENVIRONMENT_FAVORITES_FEATURE_TOKEN,
  ENVIRONMENT_HISTORY_FEATURE_TOKEN,
} from '@campus/environment';
import { ExerciseService } from '@campus/exercise';
import { Update } from '@ngrx/entity';
import { Action, select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMapTo, take } from 'rxjs/operators';
import { PermissionService } from '../../auth';
import {
  OpenStaticContentServiceInterface,
  OPEN_STATIC_CONTENT_SERVICE_TOKEN,
} from '../../services/content/open-static-content.interface';
import { FeedBackServiceInterface, FEEDBACK_SERVICE_TOKEN } from '../../services/feedback';
import { QuickLinkTypeEnum } from './quick-link-type.enum';
import {
  quickLinkActionDictionary,
  QuickLinkActionInterface,
  QuickLinkCategoryInterface,
  QuickLinkCategoryMap,
  QuickLinkInterface,
} from './quick-link.interface';

@Injectable()
export class QuickLinkViewModel {
  private allowedFavoriteTypes: FavoriteTypesEnum[];
  private allowedHistoryTypes: HistoryTypesEnum[];

  constructor(
    private store: Store<DalState>,
    @Inject(AUTH_SERVICE_TOKEN) private authService: AuthServiceInterface,
    private router: Router,
    @Inject(OPEN_STATIC_CONTENT_SERVICE_TOKEN)
    private openStaticContentService: OpenStaticContentServiceInterface,
    private exerciseService: ExerciseService,
    @Inject(FEEDBACK_SERVICE_TOKEN)
    private feedBackService: FeedBackServiceInterface,
    @Optional()
    @Inject(ENVIRONMENT_FAVORITES_FEATURE_TOKEN)
    private environmentFavoritesFeature: EnvironmentFavoritesFeatureInterface,
    @Optional()
    @Inject(ENVIRONMENT_HISTORY_FEATURE_TOKEN)
    private environmentHistoryFeature: EnvironmentHistoryFeatureInterface,
    private permissionService: PermissionService
  ) {
    this.allowedFavoriteTypes = environmentFavoritesFeature?.allowedFavoriteTypes || [];
    this.allowedHistoryTypes = environmentHistoryFeature?.allowedHistoryTypes || [];
  }

  public getQuickLinkCategories$(mode: QuickLinkTypeEnum): Observable<QuickLinkCategoryInterface[]> {
    let quickLinksDict$: Observable<{
      [key: string]: FavoriteInterface[] | HistoryInterface[];
    }>;

    if (mode === QuickLinkTypeEnum.FAVORITES) {
      quickLinksDict$ = this.store.pipe(
        select(FavoriteQueries.favoritesByType),
        map((favoriteCategories) =>
          this.mapToAllowedKeys(favoriteCategories, this.allowedFavoriteTypes, FavoriteTypesEnum)
        )
      );
    }

    if (mode === QuickLinkTypeEnum.HISTORY) {
      quickLinksDict$ = this.store.pipe(
        select(HistoryQueries.historyByType),
        map((historyCategories) => this.mapToAllowedKeys(historyCategories, this.allowedHistoryTypes, HistoryTypesEnum))
      );
    }

    return this.composeQuickLinkCategories$(quickLinksDict$, mode);
  }

  public update(id: number, name: string, mode: QuickLinkTypeEnum): void {
    let action: Action;
    switch (mode) {
      case QuickLinkTypeEnum.FAVORITES: {
        const favorite: Update<FavoriteInterface> = {
          id,
          changes: { name },
        };
        action = new FavoriteActions.UpdateFavorite({
          userId: this.authService.userId,
          favorite,
          customFeedbackHandlers: { useCustomErrorHandler: true },
        });

        break;
      }
      case QuickLinkTypeEnum.HISTORY:
        // dispatch update history action if relevant
        // no option to rename a history item yet
        throw new Error('no History State yet');
      default:
        return;
    }

    this.store.dispatch(action);
  }

  public remove(id: number, mode: QuickLinkTypeEnum): void {
    let action: Action;
    switch (mode) {
      case QuickLinkTypeEnum.FAVORITES:
        action = new FavoriteActions.DeleteFavorite({
          id: id,
          userId: this.authService.userId,
          customFeedbackHandlers: { useCustomErrorHandler: true },
        });
        break;
      case QuickLinkTypeEnum.HISTORY:
        action = new HistoryActions.DeleteHistory({
          id: id,
          userId: this.authService.userId,
          customFeedbackHandlers: { useCustomErrorHandler: true },
        });
        break;
      default:
        return;
    }
    this.store.dispatch(action);
  }

  public onFeedbackDismiss(event: { action: Action; feedbackId: string }): void {
    if (event.action) this.store.dispatch(event.action);

    this.store.dispatch(new EffectFeedbackActions.DeleteEffectFeedback({ id: event.feedbackId }));
  }

  public openBundle(bundle: BundleInterface): void {
    this.router.navigate(['/bundles', bundle.id]);
  }

  public openTask(task: TaskInterface): void {
    this.router.navigate(['/tasks', task.learningAreaId, task.id]);
  }

  public openArea(area: LearningAreaInterface): void {
    this.router.navigate(['/edu-content', area.id]);
  }

  public openStaticContent(eduContent: EduContent, stream?: boolean, showPreview?: boolean): void {
    this.openStaticContentService.open(eduContent, stream, !!showPreview, false, {
      contextDomain: ContextDomainEnum.NAV,
    });
    this.writeEduContentToHistory(eduContent);
  }

  public openExercise(eduContent: EduContent): void {
    this.exerciseService.openExerciseAsTeacher(eduContent, {});
    this.writeEduContentToHistory(eduContent);
  }

  public openEduContentAsSolution(eduContent: EduContent): void {
    this.exerciseService.browseExercise(eduContent, {});

    this.writeEduContentToHistory(eduContent);
  }

  public openSearch(quickLink: FavoriteInterface | HistoryInterface, type: QuickLinkTypeEnum): void {
    let queryParams: Params;
    switch (type) {
      case QuickLinkTypeEnum.FAVORITES:
        queryParams = { favorite_id: quickLink.id };
        break;
      case QuickLinkTypeEnum.HISTORY:
        queryParams = { history_id: quickLink.id };
        break;
    }
    this.router.navigate(['/edu-content', quickLink.learningAreaId, 'term'], {
      queryParams,
    });
  }

  public openBook(quickLink: FavoriteInterface, type: QuickLinkTypeEnum): void {
    const { eduContentBookId, productId: product } = quickLink;
    const queryParams: Params = { ...(product && { product }) };

    this.router.navigate(
      ['methods', eduContentBookId].filter((urlPart) => urlPart),
      { queryParams }
    );
  }

  public openMethod(quickLink: FavoriteInterface | HistoryInterface, type: QuickLinkTypeEnum): void {
    const { book, chapter, lesson, toc, product } = JSON.parse(quickLink.criteria);
    const queryParams: Params = { ...(product && { product }) };

    // there a no longer lesson and chapters, only tocs
    // however, this logic needs to be backward compatible
    // for DB history data that preceeds the toc refactor
    const deepestToc = toc || lesson || chapter;

    this.router.navigate(
      ['methods', book, deepestToc].filter((urlPart) => urlPart),
      { queryParams }
    );
  }

  public getFeedback$(): Observable<EffectFeedbackInterface> {
    const favoritesActionTypes = FavoriteActions.FavoritesActionTypes;
    const historyActionTypes = HistoryActions.HistoryActionTypes;

    return this.store.pipe(
      select(EffectFeedbackQueries.getNextErrorFeedbackForActions, {
        actionTypes: [
          favoritesActionTypes.UpdateFavorite,
          favoritesActionTypes.DeleteFavorite,
          historyActionTypes.DeleteHistory,
        ],
      }),
      map((feedBack) => this.feedBackService.addDefaultCancelButton(feedBack))
    );
  }

  private getBundlesStream(mode: QuickLinkTypeEnum) {
    const isAllowed =
      mode === QuickLinkTypeEnum.FAVORITES
        ? this.allowedFavoriteTypes.includes(FavoriteTypesEnum.BUNDLE)
        : this.allowedHistoryTypes.includes(HistoryTypesEnum.BUNDLE);

    if (isAllowed) {
      return this.store.pipe(select(BundleQueries.getAllEntities));
    } else {
      return of({});
    }
  }

  private getTasksStream(mode: QuickLinkTypeEnum) {
    if (this.allowedHistoryTypes.includes(HistoryTypesEnum.TASK)) {
      return this.store.pipe(select(TaskQueries.getAllEntities));
    } else {
      return of({});
    }
  }

  private getEduContentsStream(mode: QuickLinkTypeEnum) {
    const needEduContents =
      mode === QuickLinkTypeEnum.FAVORITES
        ? this.allowedFavoriteTypes.some((allowedFavoriteType) => {
            return (
              allowedFavoriteType === FavoriteTypesEnum.BOEKE || allowedFavoriteType === FavoriteTypesEnum.EDUCONTENT
            );
          })
        : this.allowedHistoryTypes.some((allowedFavoriteType) => {
            return (
              allowedFavoriteType === HistoryTypesEnum.BOEKE || allowedFavoriteType === HistoryTypesEnum.EDUCONTENT
            );
          });

    if (needEduContents) {
      return this.store.pipe(select(EduContentQueries.getAllEntities));
    } else {
      return of({});
    }
  }

  private composeQuickLinkCategories$(
    quickLinksDict$: Observable<{
      [key: string]: FavoriteInterface[] | HistoryInterface[];
    }>,
    mode: QuickLinkTypeEnum
  ): Observable<QuickLinkCategoryInterface[]> {
    const canManageBundles$ = this.permissionService.hasPermission$(SettingsPermissions.MANAGE_BUNDLES);
    const loadedQueries = [
      this.store.pipe(select(LearningAreaQueries.getLoaded)),
      this.store.pipe(select(TaskQueries.getLoaded)),
    ];

    canManageBundles$.pipe(take(1)).subscribe((canManageBundles) => {
      if (canManageBundles) {
        loadedQueries.push(this.store.pipe(select(BundleQueries.getLoaded)));
      }
    });

    const isEverythingResolved$ = combineLatest(loadedQueries).pipe(
      filter((loadedStates) => {
        return loadedStates.every((loaded) => loaded);
      })
    );

    const quickLinkCategories$ = combineLatest([
      quickLinksDict$,
      this.store.pipe(select(LearningAreaQueries.getAllEntities)),
      this.getBundlesStream(mode),
      this.getTasksStream(mode),
      this.getEduContentsStream(mode),
    ]).pipe(
      map(([quickLinkDict, learningAreaDict, bundleDict, taskDict, eduContentDict]) => {
        // A quick note: There is a QuickLinkCategoryMap that has an order property
        // these categories are not sorted according to that order
        return Object.keys(quickLinkDict).map(
          (key) =>
            ({
              type: key,
              title: this.getCategoryTitle(quickLinkDict[key][0]),
              order: this.getCategoryOrder(quickLinkDict[key][0]),
              quickLinks: quickLinkDict[key].map((qL) =>
                this.convertToQuickLink(
                  {
                    ...qL,
                    // add relation data
                    learningArea: qL.learningAreaId ? learningAreaDict[qL.learningAreaId] : undefined,
                    eduContent: qL.eduContentId ? eduContentDict[qL.eduContentId] : undefined,
                    task: qL.taskId ? taskDict[qL.taskId] : undefined,
                    bundle: qL.bundleId ? bundleDict[qL.bundleId] : undefined,
                  },
                  mode
                )
              ),
            } as QuickLinkCategoryInterface)
        );
      })
    );

    return isEverythingResolved$.pipe(switchMapTo(quickLinkCategories$));
  }

  // adds actions to Favorites and Histories
  private convertToQuickLink(value: FavoriteInterface | HistoryInterface, mode: QuickLinkTypeEnum): QuickLinkInterface {
    return {
      ...value,
      eduContent: value.eduContent as EduContent,
      defaultAction: this.getDefaultAction(value),
      alternativeOpenActions: this.getAlternativeOpenActions(value),
      manageActions: this.getManageActions(mode),
      icon: this.getIconForQuickLink(value),
    };
  }

  private getIconForQuickLink(quickLink: FavoriteInterface | HistoryInterface) {
    switch (quickLink.type) {
      case FavoriteTypesEnum.AREA:
      case FavoriteTypesEnum.BOOK:
      case HistoryTypesEnum.AREA:
      case HistoryTypesEnum.METHOD:
        return 'toc';
      case FavoriteTypesEnum.BOEKE:
      case HistoryTypesEnum.BOEKE:
        return 'boardbook';
      case FavoriteTypesEnum.EDUCONTENT:
      case HistoryTypesEnum.EDUCONTENT: {
        const eduContent = quickLink.eduContent as EduContent;
        return eduContent.icon;
      }
      case FavoriteTypesEnum.BUNDLE:
      case HistoryTypesEnum.BUNDLE:
        return 'bundle';
      case HistoryTypesEnum.TASK:
        return 'task';
      case FavoriteTypesEnum.SEARCH:
      case HistoryTypesEnum.HISTORY:
        return 'magnifier';
    }
  }

  private getDefaultAction(quickLink: FavoriteInterface | HistoryInterface): QuickLinkActionInterface {
    switch (quickLink.type) {
      case FavoriteTypesEnum.AREA:
      case HistoryTypesEnum.AREA:
        return quickLinkActionDictionary.openArea;
      case FavoriteTypesEnum.BOEKE:
      case HistoryTypesEnum.BOEKE:
        return quickLinkActionDictionary.openBoeke;
      case FavoriteTypesEnum.EDUCONTENT:
      case HistoryTypesEnum.EDUCONTENT: {
        const eduContent = quickLink.eduContent as EduContent;

        if (eduContent.isExercise) {
          return quickLinkActionDictionary.openEduContentAsExercise;
        }

        const isLink =
          eduContent.type === EduContentTypeEnum.LINK ||
          eduContent.type === EduContentTypeEnum.TIMELINE ||
          eduContent.type === EduContentTypeEnum.WHITEBOARD;
        if (isLink) return quickLinkActionDictionary.openEduContentAsLink;

        if (eduContent.streamable) return quickLinkActionDictionary.openEduContentAsStream;

        if (eduContent.isRichText) return quickLinkActionDictionary.openEduContentAsHtml;

        return quickLinkActionDictionary.openEduContentAsDownload;
      }
      case FavoriteTypesEnum.BUNDLE:
      case HistoryTypesEnum.BUNDLE:
        return quickLinkActionDictionary.openBundle;
      case HistoryTypesEnum.TASK:
        return quickLinkActionDictionary.openTask;
      case FavoriteTypesEnum.SEARCH:
      case HistoryTypesEnum.HISTORY:
        return quickLinkActionDictionary.openSearch;
      case HistoryTypesEnum.METHOD:
        return quickLinkActionDictionary.openMethod;
      case FavoriteTypesEnum.BOOK:
        return quickLinkActionDictionary.openBook;
    }
  }

  private getAlternativeOpenActions(quickLink: FavoriteInterface | HistoryInterface): QuickLinkActionInterface[] {
    switch (quickLink.type) {
      case FavoriteTypesEnum.EDUCONTENT:
      case HistoryTypesEnum.EDUCONTENT: {
        const eduContent = quickLink.eduContent as EduContent;
        if (eduContent.isExercise) {
          return [quickLinkActionDictionary.openEduContentAsSolution];
        }
        if (eduContent.streamable) {
          return [quickLinkActionDictionary.openEduContentAsDownload];
        }
      }
    }
    return [];
  }

  private getManageActions(mode: QuickLinkTypeEnum): QuickLinkActionInterface[] {
    switch (mode) {
      case QuickLinkTypeEnum.FAVORITES:
        return [quickLinkActionDictionary.edit, quickLinkActionDictionary.remove];
      case QuickLinkTypeEnum.HISTORY:
        return [quickLinkActionDictionary.remove];
    }
    return [];
  }

  private getCategoryTitle(quickLink: FavoriteInterface | HistoryInterface) {
    return QuickLinkCategoryMap.has(quickLink.type) ? QuickLinkCategoryMap.get(quickLink.type).label : quickLink.type;
  }

  private getCategoryOrder(quickLink: FavoriteInterface | HistoryInterface) {
    return QuickLinkCategoryMap.has(quickLink.type) ? QuickLinkCategoryMap.get(quickLink.type).order : -1;
  }

  private writeEduContentToHistory(eduContent: EduContentInterface): void {
    const history = createHistoryFromEduContent(eduContent);
    if (history) {
      this.store.dispatch(
        new HistoryActions.StartUpsertHistory({
          history,
        })
      );
    }
    this.store.dispatch(
      new EduContentActions.UpsertEduContent({
        eduContent,
      })
    );
  }

  private mapToAllowedKeys(
    categories: Record<string, FavoriteInterface[] | HistoryInterface[]>,
    allowedTypes,
    typeEnum
  ) {
    return Object.keys(categories).reduce((obj, key) => {
      const enumKey = key.replace('-', '').toLocaleUpperCase();

      return allowedTypes.includes(typeEnum[enumKey]) ? { ...obj, [key]: categories[key] } : obj;
    }, {});
  }
}
