import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import {
  EduContentInterface,
  TaskEduContentInterface,
  TaskEduContentTaskGoalYearLevelInterface,
  TaskGoalYearLevelInterface,
} from '../+models';

export const TASK_GOAL_YEAR_LEVEL_SERVICE_TOKEN = new InjectionToken('TaskGoalYearLevelService');

export interface SetTaskGoalYearLevelResponseInterface {
  taskGoalYearLevels: TaskGoalYearLevelInterface[];
  taskEduContents: TaskEduContentInterface[];
  taskEduContentTaskGoalYearLevels: TaskEduContentTaskGoalYearLevelInterface[];
  removedTaskEduContentTaskGoalYearLevelIds: number[];
  eduContents: EduContentInterface[];
}

export interface TaskGoalYearLevelServiceInterface {
  getTaskGoalYearLevelsForTask(taskId: number): Observable<TaskGoalYearLevelInterface[]>;

  setTaskGoalYearLevelsForTask(
    taskId: number,
    taskGoalYearLevels: TaskGoalYearLevelInterface[]
  ): Observable<SetTaskGoalYearLevelResponseInterface>;
}
