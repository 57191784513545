import { createFeatureSelector, createSelector } from '@ngrx/store';

import { NAME, selectAll, selectEntities, State } from './news-category.reducer';

export const selectNewsCategoryState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectNewsCategoryState, (state: State) => state.error);

export const getLoaded = createSelector(selectNewsCategoryState, (state: State) => state.loaded);

export const getAll = createSelector(selectNewsCategoryState, selectAll);

export const getAllEntities = createSelector(selectNewsCategoryState, selectEntities);
