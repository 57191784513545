import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  inject,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { EduContentInterface, LearnosityItemsConfigInterface } from '@campus/dal';
import { learnosityScriptVersion } from '../../constants/learnosity-version';
import { ActivityPreviewService, LEARNOSITY_WINDOW } from '../../services';
@Component({
  selector: 'campus-learnosity-activity-preview',
  templateUrl: './activity-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityPreviewComponent implements OnInit {
  @HostBinding('class') defaultClasses = ['ca', 'block'];

  private window = inject(LEARNOSITY_WINDOW);
  private renderer: Renderer2 = inject(Renderer2);
  private el: ElementRef = inject(ElementRef);
  private activityPreviewService: ActivityPreviewService = inject(ActivityPreviewService);

  private script = null;

  @Input() learnosityReference: string;
  @Input() config: LearnosityItemsConfigInterface;
  @Input() eduContent?: EduContentInterface;

  ngOnInit() {
    if (document.getElementById('learnosity_activity_preview_script_id')) {
      this.initializeLearnosity(this.learnosityReference);
    } else {
      this.script = this.renderer.createElement('script');
      this.script.id = 'learnosity_activity_preview_script_id';
      this.script.type = 'text/javascript';
      this.script.src = `//items.learnosity.com/?${learnosityScriptVersion}`;
      this.script.onload = () => {
        this.initializeLearnosity(this.learnosityReference);
      };
      this.renderer.appendChild(this.el.nativeElement.ownerDocument.head, this.script);
    }
  }

  initializeLearnosity(learnosityReference: string) {
    if (this.config) {
      this.window.LearnosityItems.init(this.config, '#activity_preview');
    } else {
      this.activityPreviewService.getRequest(learnosityReference).subscribe((request) => {
        this.window.LearnosityItems.init(request, '#activity_preview');
      });
    }
  }
}
