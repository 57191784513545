import { ChangeDetectorRef, Inject, Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { AuthServiceInterface, AUTH_SERVICE_TOKEN, PersonServiceInterface, PERSON_SERVICE_TOKEN } from '@campus/dal';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UniqueEmailValidator implements AsyncValidator {
  constructor(
    @Inject(PERSON_SERVICE_TOKEN) private personService: PersonServiceInterface,
    @Inject(AUTH_SERVICE_TOKEN) private authService: AuthServiceInterface,
    private cdRef: ChangeDetectorRef
  ) {}

  private exclude: string;
  private excludedUserId: number = this.authService.userId;

  public setExclude(value: string) {
    this.exclude = value;
  }

  public setExcludedUserId(value: number) {
    this.excludedUserId = value;
  }

  validate(ctrl: AbstractControl): Observable<ValidationErrors | null> {
    if (this.exclude && this.exclude.toLocaleLowerCase() === ctrl.value.toLowerCase().trim()) {
      return of(null);
    }

    return of(ctrl.value).pipe(
      delay(500), // prevents making unnecessary calls
      switchMap(() =>
        this.personService.checkUniqueEmail(this.excludedUserId, ctrl.value).pipe(
          map((isUnique) => {
            return isUnique ? null : { notUniqueEmail: true };
          }),
          catchError(() => of({ serverError: true })),
          delay(0), // makes sure the ctrl validity is updated
          tap((_) => {
            this.cdRef.markForCheck();
          }) // makes sure the UI is updated once the validation is done
        )
      )
    );
  }
}
