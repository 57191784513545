import { Component, ElementRef, inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { WINDOW } from '@campus/browser';
import { EduContentInterface } from '@campus/dal';
import { ENVIRONMENT_FRESHWORKS_FEATURE_TOKEN } from '@campus/environment';

declare global {
  interface Window {
    fwSettings: {
      widget_id: number;
    };
    FreshworksWidget: any;
  }
}

@Component({
  selector: 'campus-freshworks',
  templateUrl: './freshworks.component.html',
})
export class FreshworksComponent implements OnInit, OnDestroy {
  @Input() eduContent: EduContentInterface;

  private renderer: Renderer2 = inject(Renderer2);
  private el: ElementRef = inject(ElementRef);
  private window = inject(WINDOW);
  private environment = inject(ENVIRONMENT_FRESHWORKS_FEATURE_TOKEN);

  private scriptId = 'freshworks_script_id';
  private widgetId = this.environment?.widgetId;
  private productId = this.environment?.productId;
  private url = this.environment?.url;

  private script = null;

  ngOnInit(): void {
    this.loadFreshworks();
  }

  ngOnDestroy(): void {
    this.window.FreshworksWidget && this.window.FreshworksWidget('destroy');
  }

  private loadFreshworks(): void {
    if (!this.widgetId || !this.productId || !this.url) return;

    if (!document.getElementById(this.scriptId)) {
      this.window.fwSettings = {
        widget_id: this.widgetId,
      };

      window.FreshworksWidget ||
        (function () {
          if ('function' != typeof window.FreshworksWidget) {
            let n = function () {
              n['q'].push(arguments);
            };
            (n['q'] = []), (window.FreshworksWidget = n);
          }
        })();

      this.script = this.renderer.createElement('script');
      this.script.id = this.scriptId;
      this.script.type = 'text/javascript';
      this.script.src = `https://widget.freshworks.com/widgets/${this.widgetId}.js`;
      this.script.async = true;
      this.script.onload = () => {
        this.hideFields();
        this.fillInfo();
      };

      this.renderer.appendChild(this.el.nativeElement.ownerDocument.head, this.script);
    } else {
      this.window.FreshworksWidget('boot');
      this.hideFields();
      this.fillInfo();
    }
  }

  private hideFields() {
    this.window.FreshworksWidget('hide', 'ticketForm', [
      'product_id',
      'custom_fields.cf_onderwijs',
      'custom_fields.cf_url',
      'custom_fields.cf_ink_link',
      'custom_fields.cf_at_link',
      'custom_fields.cf_methode',
      'custom_fields.cf_les',
    ]);
  }

  private fillInfo() {
    this.window.FreshworksWidget('identify', 'ticketForm', {
      subject: `Fout in oefening: "${this.eduContent.publishedEduContentMetadata.title}"`,
      description: `Er staat een fout in vraag "<naam vraag>" \n<beschrijf de fout of voeg een schermafbeelding toe>`,
      product_id: this.productId,
      custom_fields: {
        cf_url: this.url.replace('{id}', this.eduContent.id.toString()),
      },
    });
  }
}
