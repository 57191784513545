import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { fetch, optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ProductQueries } from '.';
import { MethodProductGroupProductInterface, ProductContentInterface } from '../../+models';
import { ProductService } from '../../product';
import { DalState } from '../dal.state.interface';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { MethodProductGroupProductActions } from '../method-product-group-product';
import { ProductContentActions } from '../product-content';
import {
  DeleteLinkedMethodProductGroup,
  DeleteLinkedProductContent,
  DeleteProduct,
  LoadProducts,
  ProductsActionTypes,
  ProductsLoaded,
  ProductsLoadError,
  StartLinkMethodProductGroup,
  StartLinkProductContent,
  UpdateProduct,
} from './product.actions';

@Injectable()
export class ProductEffects {
  loadProducts$ = createEffect(() =>
    this.actions.pipe(
      ofType(ProductsActionTypes.LoadProducts),
      concatLatestFrom(() => this.store.select(ProductQueries.getLoaded)),
      fetch({
        run: (action: LoadProducts, loaded: boolean) => {
          if (!action.payload.force && loaded) return;

          return this.productService.getAll().pipe(map((products) => new ProductsLoaded({ products })));
        },
        onError: (action: LoadProducts, error) => {
          return new ProductsLoadError(error);
        },
      })
    )
  );

  updateProduct$ = createEffect(() =>
    this.actions.pipe(
      ofType(ProductsActionTypes.UpdateProduct),
      pessimisticUpdate({
        run: (action: UpdateProduct) => {
          const { product, methodProductGroups = { linked: [], unlinked: [] } } = action.payload;
          const { id, changes } = product;
          return this.productService.updateProduct(+id, changes).pipe(
            switchMap(() => {
              const actions: Action[] = [
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'Het product werd bijgewerkt.'
                  ),
                }),
                ...methodProductGroups.linked.map((payload) => new StartLinkMethodProductGroup(payload)),
                ...methodProductGroups.unlinked.map((payload) => new DeleteLinkedMethodProductGroup(payload)),
              ];

              return from(actions);
            })
          );
        },
        onError: (action: UpdateProduct, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het product bij te werken.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  deleteProduct$ = createEffect(() =>
    this.actions.pipe(
      ofType(ProductsActionTypes.DeleteProduct),
      optimisticUpdate({
        run: (action: DeleteProduct) => {
          return this.productService.deleteProduct(action.payload.id).pipe(
            map(() => {
              return new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'Het product werd verwijderd.'
                ),
              });
            })
          );
        },
        undoAction: (action: DeleteProduct, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het product te verwijderen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  startLinkNewProductContent$ = createEffect(() =>
    this.actions.pipe(
      ofType(ProductsActionTypes.StartLinkProductContent),
      pessimisticUpdate({
        run: (action: StartLinkProductContent) => {
          const { id, productContent: productContentData } = action.payload;
          return this.productService.linkNewProductContent(id, productContentData).pipe(
            switchMap((productContent: ProductContentInterface) => {
              return of(
                new ProductContentActions.AddProductContent({
                  productContent,
                })
              );
            })
          );
        },
        onError: (action: StartLinkProductContent, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de inhoud toe te voegen.'
            ),
          });
        },
      })
    )
  );

  deleteLinkedProductContent$ = createEffect(() =>
    this.actions.pipe(
      ofType(ProductsActionTypes.DeleteLinkedProductContent),
      optimisticUpdate({
        run: (action: DeleteLinkedProductContent) => {
          const { id: productId, productContentId } = action.payload;
          return this.productService.removeLinkedProductContent(productId, productContentId).pipe(
            map(() => {
              return new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'De inhoud van het product werd verwijderd.'
                ),
              });
            })
          );
        },
        undoAction: (action: DeleteLinkedProductContent, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de inhoud van het product te verwijderen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  startLinkMethodProductGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(ProductsActionTypes.StartLinkMethodProductGroup),
      pessimisticUpdate({
        run: (action: StartLinkMethodProductGroup) => {
          const { productId, methodProductGroupId } = action.payload;
          return this.productService.linkMethodProductGroup(productId, methodProductGroupId).pipe(
            switchMap((methodProductGroupProduct: MethodProductGroupProductInterface) => {
              return of(
                new MethodProductGroupProductActions.AddMethodProductGroupProduct({
                  methodProductGroupProduct,
                })
              );
            })
          );
        },

        onError: (action: StartLinkMethodProductGroup, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het product toe te voegen aan de productgroep.'
            ),
          });
        },
      })
    )
  );

  deleteLinkedMethodProductGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(ProductsActionTypes.DeleteLinkedMethodProductGroup),
      optimisticUpdate({
        run: (action: DeleteLinkedMethodProductGroup) => {
          const { productId, methodProductGroupId, methodProductGroupProductId } = action.payload;
          const actions = [];
          actions.push(
            new MethodProductGroupProductActions.DeleteMethodProductGroupProduct({ id: methodProductGroupProductId })
          );
          return this.productService.removeLinkedMethodProductGroup(productId, methodProductGroupId).pipe(
            switchMap(() => {
              actions.push(
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'Het product werd uit de productgroep verwijderd.'
                  ),
                })
              );
              return from(actions);
            })
          );
        },
        undoAction: (action: DeleteLinkedMethodProductGroup, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het product uit de productgroep te verwijderen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  constructor(
    @Inject('uuid')
    private uuid,
    private actions: Actions,
    private store: Store<DalState>,
    private productService: ProductService
  ) {}
}
