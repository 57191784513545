import { groupArrayByKey, groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GoalInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './goal.reducer';

export const selectGoalState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectGoalState, (state: State) => state.error);

export const getLoaded = createSelector(selectGoalState, (state: State) => state.loaded);

export const getAll = createSelector(selectGoalState, selectAll);

export const getCount = createSelector(selectGoalState, selectTotal);

export const getIds = createSelector(selectGoalState, selectIds);

export const getAllEntities = createSelector(selectGoalState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * goal$: GoalInterface[] = this.store.pipe(
    select(GoalQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectGoalState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * goal$: GoalInterface = this.store.pipe(
    select(GoalQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectGoalState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getAllByGoalTopic = createSelector(getAll, (goals: GoalInterface[]) => {
  return groupArrayByKey(goals, 'goalTopicId');
});

export const getGoalsLoadedForLearningArea = (props: { learningAreaId: number }) =>
  createSelector(selectGoalState, (state) => {
    return !!state.loadedForLearningArea[props.learningAreaId];
  });

export const isBookLoaded = (props: { bookId: number }) =>
  createSelector(selectGoalState, (state: State) => !!state.loadedForBook[props.bookId]);

export const getAllByGuid = createSelector(
  getAll,
  (goals: GoalInterface[]): Dictionary<GoalInterface> => groupArrayByKeys(goals, ['guid'], null, true)
);
