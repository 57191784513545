/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { HistoryApi as SDKHistoryApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { HistoryInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'any' })
export class HistoryApi extends ModelApiBase {
  private SDKHistoryApi = inject(SDKHistoryApi);

  constructor(api: PolpoApi) {
    super(api, 'Histories');
  }

  find(options?): Observable<HistoryInterface[]> {
    return this.SDKHistoryApi.find(...arguments);
  }

  upsertByInstance(
    name: string,
    type: string,
    learningAreaId: number,
    criteria: string,
    eduContentId: number,
    bundleId: number,
    taskId: number,
    eduContentBookId: number
  ): Observable<HistoryInterface> {
    // @ts-expect-error TS 2556
    return this.SDKHistoryApi.upsertByInstance(...arguments);
  }

  destroyByIdHistory(userId, historyId): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKHistoryApi.destroyByIdHistory(...arguments);
  }
}
