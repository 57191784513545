import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TaskGoalYearLevelInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './task-goal-year-level.reducer';

export const selectTaskGoalYearLevelState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectTaskGoalYearLevelState, (state: State) => state.error);

export const getLoaded = createSelector(selectTaskGoalYearLevelState, (state: State) => state.loaded);

export const getAll = createSelector(selectTaskGoalYearLevelState, selectAll);

export const getCount = createSelector(selectTaskGoalYearLevelState, selectTotal);

export const getIds = createSelector(selectTaskGoalYearLevelState, selectIds);

export const getAllEntities = createSelector(selectTaskGoalYearLevelState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * taskGoalYearLevel$: TaskGoalYearLevelInterface[] = this.store.pipe(
    select(TaskGoalYearLevelQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectTaskGoalYearLevelState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * taskGoalYearLevel$: TaskGoalYearLevelInterface = this.store.pipe(
    select(TaskGoalYearLevelQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectTaskGoalYearLevelState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getTaskGoalYearLevelLoadedForTask = createSelector(
  selectTaskGoalYearLevelState,
  (state, props: { taskId: number }) => {
    return !!state.loadedForTask[props.taskId];
  }
);

export const getAllForTask = createSelector(
  selectTaskGoalYearLevelState,
  (state: State, props: { taskId: number }): TaskGoalYearLevelInterface[] => {
    return (<number[]>state.ids)
      .filter((id) => state.entities[id].taskId === props.taskId)
      .map((id) => state.entities[id]);
  }
);

export const isSaving = createSelector(selectTaskGoalYearLevelState, (state: State) => state.saving);
