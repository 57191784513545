import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { GroupPersonInterface } from '../../+models';
import { CustomFeedbackHandlersInterface } from '../effect-feedback';

export enum GroupPersonsActionTypes {
  GroupPersonsLoaded = '[GroupPersons] GroupPersons Loaded',
  GroupPersonsLoadError = '[GroupPersons] Load Error',
  LoadGroupPersons = '[GroupPersons] Load GroupPersons',
  AddGroupPerson = '[GroupPersons] Add GroupPerson',
  UpsertGroupPerson = '[GroupPersons] Upsert GroupPerson',
  AddGroupPersons = '[GroupPersons] Add GroupPersons',
  UpsertGroupPersons = '[GroupPersons] Upsert GroupPersons',
  UpdateGroupPerson = '[GroupPersons] Update GroupPerson',
  UpdateGroupPersons = '[GroupPersons] Update GroupPersons',
  DeleteGroupPerson = '[GroupPersons] Delete GroupPerson',
  DeleteGroupPersons = '[GroupPersons] Delete GroupPersons',
  ClearGroupPersons = '[GroupPersons] Clear GroupPersons',
  StartSetForGroup = '[GroupPersons] Start Set For Group',
  StartSetForStudent = '[GroupPersons] Start Set For Student',
  SetForGroup = '[GroupPersons] Set For Group',
  SetForStudent = '[GroupPersons] Set For Student',
}

export class LoadGroupPersons implements Action {
  readonly type = GroupPersonsActionTypes.LoadGroupPersons;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class GroupPersonsLoaded implements Action {
  readonly type = GroupPersonsActionTypes.GroupPersonsLoaded;

  constructor(public payload: { groupPersons: GroupPersonInterface[] }) {}
}

export class GroupPersonsLoadError implements Action {
  readonly type = GroupPersonsActionTypes.GroupPersonsLoadError;
  constructor(public payload: any) {}
}

export class AddGroupPerson implements Action {
  readonly type = GroupPersonsActionTypes.AddGroupPerson;

  constructor(public payload: { groupPerson: GroupPersonInterface }) {}
}

export class UpsertGroupPerson implements Action {
  readonly type = GroupPersonsActionTypes.UpsertGroupPerson;

  constructor(public payload: { groupPerson: GroupPersonInterface }) {}
}

export class AddGroupPersons implements Action {
  readonly type = GroupPersonsActionTypes.AddGroupPersons;

  constructor(public payload: { groupPersons: GroupPersonInterface[] }) {}
}

export class UpsertGroupPersons implements Action {
  readonly type = GroupPersonsActionTypes.UpsertGroupPersons;

  constructor(public payload: { groupPersons: GroupPersonInterface[] }) {}
}

export class UpdateGroupPerson implements Action {
  readonly type = GroupPersonsActionTypes.UpdateGroupPerson;

  constructor(public payload: { groupPerson: Update<GroupPersonInterface> }) {}
}

export class UpdateGroupPersons implements Action {
  readonly type = GroupPersonsActionTypes.UpdateGroupPersons;

  constructor(public payload: { groupPersons: Update<GroupPersonInterface>[] }) {}
}

export class DeleteGroupPerson implements Action {
  readonly type = GroupPersonsActionTypes.DeleteGroupPerson;

  constructor(public payload: { id: number }) {}
}

export class DeleteGroupPersons implements Action {
  readonly type = GroupPersonsActionTypes.DeleteGroupPersons;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearGroupPersons implements Action {
  readonly type = GroupPersonsActionTypes.ClearGroupPersons;
}

export class StartSetForGroup implements Action {
  readonly type = GroupPersonsActionTypes.StartSetForGroup;

  constructor(
    public payload: { groupId: number; studentIds: number[]; customFeedbackHandlers?: CustomFeedbackHandlersInterface }
  ) {}
}

export class StartSetForStudent implements Action {
  readonly type = GroupPersonsActionTypes.StartSetForStudent;

  constructor(
    public payload: { studentId: number; groupIds: number[]; customFeedbackHandlers?: CustomFeedbackHandlersInterface }
  ) {}
}

export class SetForGroup implements Action {
  readonly type = GroupPersonsActionTypes.SetForGroup;

  constructor(public payload: { groupId: number; groupPersons: GroupPersonInterface[] }) {}
}

export class SetForStudent implements Action {
  readonly type = GroupPersonsActionTypes.SetForStudent;

  constructor(public payload: { studentId: number; groupPersons: GroupPersonInterface[] }) {}
}

export type GroupPersonsActions =
  | LoadGroupPersons
  | GroupPersonsLoaded
  | GroupPersonsLoadError
  | AddGroupPerson
  | UpsertGroupPerson
  | AddGroupPersons
  | UpsertGroupPersons
  | UpdateGroupPerson
  | UpdateGroupPersons
  | DeleteGroupPerson
  | DeleteGroupPersons
  | ClearGroupPersons
  | StartSetForGroup
  | StartSetForStudent
  | SetForGroup
  | SetForStudent;
