import { createFeatureSelector, createSelector } from '@ngrx/store';

import { groupArrayByKey } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { NewsInterface } from '../../+models';
import { MethodQueries } from '../method';
import { MethodNewsQueries } from '../method-news';
import { NewsCategoryQueries } from '../news-category';
import { NewsCategoryNewsQueries } from '../news-category-news';
import { NAME, selectAll, selectEntities, State } from './news.reducer';

export const selectNewsState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectNewsState, (state: State) => state.error);

export const getLoaded = createSelector(selectNewsState, (state: State) => state.loaded);

export const getAll = createSelector(selectNewsState, selectAll);

export const getAllEntities = createSelector(selectNewsState, selectEntities);

export const getNewsWithMethodsAndCategories = createSelector(
  getAll,
  MethodQueries.getAllEntities,
  MethodNewsQueries.getAll,
  NewsCategoryNewsQueries.getByNewsId,
  NewsCategoryQueries.getAllEntities,
  (allNews, methodEntities, allMethodNews, nCnByNewsId, newsCategoryDict): NewsInterface[] => {
    const methodNewsByNewsId = groupArrayByKey(allMethodNews, 'newsId');

    return allNews.map((news: NewsInterface) => {
      const methods = (methodNewsByNewsId[news.id] || []).map((method) => methodEntities[method.methodId]);
      const categories = (nCnByNewsId[news.id] || []).map((nCN) => newsCategoryDict[nCN.newsCategoryId]);
      return { ...news, methods, categories };
    });
  }
);

export const getNewsWithMethodsByCategoryId = createSelector(
  getNewsWithMethodsAndCategories,
  NewsCategoryNewsQueries.getAll,
  (newsWithMethods, allNewsCategoryNews): Dictionary<NewsInterface[]> => {
    const newsCategoryNewsByNewsId = groupArrayByKey(allNewsCategoryNews, 'newsId');

    return newsWithMethods.reduce(
      (acc: Dictionary<NewsInterface[]>, news: NewsInterface): Dictionary<NewsInterface[]> => {
        const categoryIds = newsCategoryNewsByNewsId[news.id]?.map((category) => category.newsCategoryId);

        categoryIds.forEach((categoryId) => {
          acc[categoryId] = [...(acc[categoryId] || []), { ...news }];
        });

        return acc;
      },
      {}
    );
  }
);

export const getById = createSelector(
  selectNewsState,
  (state: State, props: { id: number }) => state.entities[props.id]
);
