import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ConfirmationModalDataInterface } from './confirmation-modal-data.interface';

@Component({
  selector: 'campus-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
})
export class ConfirmationModalComponent implements ConfirmationModalDataInterface {
  title: string;
  message: string;
  disableConfirm: boolean;
  confirmLabel: string;
  cancelLabel: string;
  confirmType: 'default' | 'danger' | 'primary';
  cancelType: 'default' | 'inline' | 'hidden';
  confirmIcon: string;
  messageClass: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ConfirmationModalDataInterface,
    public dialogRef: MatDialogRef<ConfirmationModalComponent>
  ) {
    this.title = data.title;
    this.message = data.message;
    this.disableConfirm = data.disableConfirm;
    this.confirmLabel = data.confirmLabel || 'Ja';
    this.cancelLabel = data.cancelLabel || 'Nee';
    this.confirmType = data.confirmType || 'primary';
    this.cancelType = data.cancelType || 'inline';
    this.confirmIcon = data.confirmIcon;
    this.messageClass = data.messageClass || '[ label-large surface text-opacity-medium ]';
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    if (this.disableConfirm) return; // workaround because stop propagation does not work
    this.dialogRef.close(true);
  }
}
