import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { ArrayFunctions } from '@campus/utils';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { SearchFilterComponentInterface } from '../../interfaces/search-filter-component-interface';
import {
  SearchFilterCriteriaInterface,
  SearchFilterCriteriaValuesInterface,
} from '../../interfaces/search-filter-criteria.interface';

interface SelectOption {
  value: SearchFilterCriteriaValuesInterface;
  viewValue: string | number;
}
interface SelectGroup {
  options: SelectOption[];
  label: string;
}

@Component({
  selector: 'campus-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFilterComponent implements SearchFilterComponentInterface, OnInit, OnDestroy, OnChanges {
  options: SelectOption[];
  optionGroups: SelectGroup[];
  selectControl: UntypedFormControl = new UntypedFormControl();
  count = 0;

  private subscriptions: Subscription = new Subscription();
  public hasSelection: boolean;

  @Input() inline: boolean;
  @Input() multiple = false;
  @Input() showFilterButton = true;
  @Input() hideWithoutPredictions = false;
  @Input() resetLabel: string;
  @Input() hideBadge: boolean;
  @Input() filterCriteria: SearchFilterCriteriaInterface;
  @Input() filterOptions: any;

  @Output() filterSelectionChange: EventEmitter<SearchFilterCriteriaInterface[]> = new EventEmitter();

  @HostBinding('class.select-filter-component')
  selectFilterComponentClass = true;

  @HostBinding('class.select-filter-component--clearable')
  @Input()
  clearable: boolean;

  @ViewChild(MatSelect) selectComponent: MatSelect;

  constructor(@Inject(ChangeDetectorRef) private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.subscriptions.add(
      this.selectControl.valueChanges
        .pipe(
          startWith(this.selectControl.value),
          distinctUntilChanged((a, b) => ArrayFunctions.getArrayEquality(a, b))
        )
        .subscribe((selection: SearchFilterCriteriaValuesInterface | SearchFilterCriteriaValuesInterface[]): void => {
          if (!selection) selection = [];

          if (!Array.isArray(selection)) selection = [selection];

          // multiple === true
          // click reset -> adds null to array
          if (selection.includes(null)) {
            // this will emit a valuechange
            this.selectControl.setValue([]);
            return;
          }

          this.updateView(selection);
          this.updateCriteriaWithSelected(this.filterCriteria, selection);

          this.updateHasSelection();
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterOptions) {
      if (this.filterOptions && this.filterOptions.multiple !== undefined) {
        this.multiple = this.filterOptions.multiple;
      }
      if (this.filterOptions && this.filterOptions.hideWithoutPredictions !== undefined) {
        this.hideWithoutPredictions = this.filterOptions.hideWithoutPredictions;
      }
    }

    if (changes.filterCriteria) {
      let selection = [];
      const mapSelected = (list) => list.filter((option) => option.value.selected).map((option) => option.value);

      if (this.hasChildren(this.filterCriteria)) {
        this.optionGroups = this.criteriaToGroupOptions(this.filterCriteria);
        selection = this.optionGroups.reduce((arr, group) => [...arr, ...mapSelected(group.options)], []);
      } else {
        this.options = this.criteriaToOptions(this.filterCriteria);
        selection = mapSelected(this.options);
      }
      this.updateHasSelection();

      this.updateView(selection);
    }
  }

  public reset(emit = true) {
    this.selectControl.reset(undefined, { emitEvent: emit });

    if (!emit) {
      this.updateView(); // emit -> updateView called in valueChanges
    } else {
      this.emitCriteria();
    }
  }

  public openedChange(opened: boolean) {
    if (!opened) {
      this.emitCriteria();
    }
  }

  public filterClicked() {
    this.emitCriteria();
    this.selectComponent.close();
  }

  private updateHasSelection() {
    const recursiveSome = (values: SearchFilterCriteriaValuesInterface[]) =>
      values.some((value) => (value.child && value.child.values ? recursiveSome(value.child.values) : value.selected));

    this.hasSelection = recursiveSome(this.filterCriteria.values);
  }

  private emitCriteria() {
    this.filterSelectionChange.emit([this.filterCriteria]);
  }

  private predictionNeeded(value) {
    if (!this.hideWithoutPredictions) return true;
    if (value.selected) return true;
    if (value.prediction !== 0) return true;
    return false;
  }

  private hasChildren(criteria: SearchFilterCriteriaInterface) {
    return criteria.values.some((value) => value.child);
  }

  private criteriaToOptions(criteria: SearchFilterCriteriaInterface): SelectOption[] {
    return criteria.values
      .filter((value) => value.visible && this.predictionNeeded(value))
      .map((value: SearchFilterCriteriaValuesInterface): SelectOption => {
        let viewValue = value.data[criteria.displayProperty];
        if (value.prediction !== undefined) {
          viewValue += ' (' + value.prediction + ')';
        }
        return { value, viewValue };
      });
  }

  private criteriaToGroupOptions(criteria: SearchFilterCriteriaInterface) {
    return criteria.values.map((value: SearchFilterCriteriaValuesInterface): SelectGroup => {
      const child = value.child;
      return { label: child.label, options: this.criteriaToOptions(child) };
    });
  }

  // only updates view -> does not trigger valueChange
  private updateView(selection: SearchFilterCriteriaValuesInterface[] = []): void {
    this.count = selection.length;

    if (this.multiple) {
      this.selectControl.setValue(selection, { emitEvent: false });
    } else {
      this.selectControl.setValue(selection[0] || null, { emitEvent: false });
    }

    this.cd.markForCheck();
  }

  private updateCriteriaWithSelected(
    criteria: SearchFilterCriteriaInterface,
    selection: SearchFilterCriteriaValuesInterface[]
  ): void {
    const deselectAll = (list) => list.forEach((value) => (value.selected = false));
    // uncheck everything
    deselectAll(criteria.values);
    if (this.hasChildren(criteria)) {
      criteria.values.forEach((group) => {
        if (group.child && group.child.values) {
          deselectAll(group.child.values);
        }
      });
    }
    // then check selected
    selection.forEach((selected) => {
      selected.selected = true;
    });
  }
}
