import { ArrayFunctions, toDictionary } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';

import { createSelector } from '@ngrx/store';
import {
  ClassGroupInterface,
  CurriculumInterface,
  CurriculumTreeInterface,
  EduContentBookInterface,
  UnlockedCurriculumTreeInterface,
  YearInterface,
} from '../../+models';
import { getAllEntities as getClassGroupDict } from '../class-group/class-group.selectors';
import { getAllEntities as getCurriculumTreeDict } from '../curriculum-tree/curriculum-tree.selectors';
import { getAllEntities as getCurriculumDict } from '../curriculum/curriculum.selectors';
import { getByMethodId as getBooksByMethodId } from '../edu-content-book/edu-content-book.selectors';
import { getAll as getUnlockedCurriculumTrees } from '../unlocked-curriculum-tree/unlocked-curriculum-tree.selectors';
import { getStudentsYears } from '../user/user.extended.selectors';
import { getAll as getAllEduContentBooks } from './edu-content-book.selectors';

const { flatten, unique } = ArrayFunctions;

export const getAdaptiveUnlockedBooks = createSelector(
  getUnlockedCurriculumTrees,
  getCurriculumTreeDict,
  getCurriculumDict,
  getBooksByMethodId,
  getClassGroupDict,
  (
    unlockedCurriculumTrees: UnlockedCurriculumTreeInterface[],
    curriculumtTreeDict: Dictionary<CurriculumTreeInterface>,
    curriculumDict: Dictionary<CurriculumInterface>,
    booksByMethodId: Dictionary<EduContentBookInterface[]>,
    classGroupDict: Dictionary<ClassGroupInterface>
  ) => {
    const getYearId = (unlockedCurriculumTree: UnlockedCurriculumTreeInterface): number => {
      const { classGroupId } = unlockedCurriculumTree;
      const classGroup = classGroupDict[classGroupId];
      return classGroup.years[0].id;
    };

    const getMethodId = (unlockedCurriculumTree: UnlockedCurriculumTreeInterface): number => {
      const { curriculumTreeId } = unlockedCurriculumTree;
      const curriculumTree = curriculumtTreeDict[curriculumTreeId];
      if (!curriculumTree) return;
      const { curriculumId } = curriculumTree;
      return curriculumDict[curriculumId]?.methodId;
    };

    const getBooks = ([methodId, yearId]: [number, number]) =>
      (booksByMethodId[methodId] || []).filter((book) => book.years.some((year) => year.id === yearId));

    const books = pipeLine(
      unlockedCurriculumTrees,
      (uCTs) =>
        uCTs.map((uCT) => [getMethodId(uCT), getYearId(uCT)]).filter(([methodId, yearId]) => !!methodId && !!yearId),
      (methodYears) => methodYears.map((methodYear) => getBooks(methodYear)),
      flatten,
      unique
    );
    return books as EduContentBookInterface[];
  }
);

export const getUnlockedBooksWithGames = createSelector(
  getAllEduContentBooks,
  getStudentsYears,
  (books: EduContentBookInterface[], studentYears: YearInterface[]): EduContentBookInterface[] => {
    const yearDict = toDictionary(studentYears);
    const matchesStudentYears = (book: EduContentBookInterface) => book.years.some((year) => !!yearDict[year.id]);
    const hasGames = (book: EduContentBookInterface) => !!book.hasGames;

    const unlockedBooksWithGames = books.filter((book) => hasGames(book) && matchesStudentYears(book));

    return unlockedBooksWithGames;
  }
);

function pipeLine(arg, ...functions: Function[]) {
  const [currFn, ...restFns] = functions;
  const value = currFn.apply(null, [arg]);

  if (!restFns.length) return value;
  else return pipeLine(value, ...restFns);
}
