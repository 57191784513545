import { Action } from '@ngrx/store';
import { MethodNewsInterface } from '../../+models';

export enum MethodNewsActionTypes {
  MethodNewsLoaded = '[MethodNews] Method News Loaded',
  MethodNewsLoadError = '[MethodNews] Load Error',
  LoadMethodNews = '[MethodNews] Load Method News',
  ClearMethodNews = '[MethodNews] Clear Method News',
  AddMethodNews = '[MethodNews] Add Method News',
  SetMethodNewsForNews = '[MethodNews] Set method news for news',
  RemoveMethodNewsForNews = '[MethodNews] Remove method news for news',
}

export class LoadMethodNews implements Action {
  readonly type = MethodNewsActionTypes.LoadMethodNews;
  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class MethodNewsLoaded implements Action {
  readonly type = MethodNewsActionTypes.MethodNewsLoaded;
  constructor(public payload: { methodNews: MethodNewsInterface[] }) {}
}

export class MethodNewsLoadError implements Action {
  readonly type = MethodNewsActionTypes.MethodNewsLoadError;
  constructor(public payload: any) {}
}

export class ClearMethodNews implements Action {
  readonly type = MethodNewsActionTypes.ClearMethodNews;
}

export class AddMethodNews implements Action {
  readonly type = MethodNewsActionTypes.AddMethodNews;
  constructor(public payload: { methodNews: MethodNewsInterface[] }) {}
}

export class SetMethodNewsForNews implements Action {
  readonly type = MethodNewsActionTypes.SetMethodNewsForNews;
  constructor(public payload: { newsId: number; methodNews: MethodNewsInterface[] }) {}
}

export class RemoveMethodNewsForNews implements Action {
  readonly type = MethodNewsActionTypes.RemoveMethodNewsForNews;
  constructor(public payload: { newsId: number }) {}
}

export type MethodNewsActions =
  | LoadMethodNews
  | MethodNewsLoaded
  | MethodNewsLoadError
  | ClearMethodNews
  | AddMethodNews
  | SetMethodNewsForNews
  | RemoveMethodNewsForNews;
