import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { EvaluationSubjectInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum EvaluationSubjectsActionTypes {
  EvaluationSubjectsLoaded = '[EvaluationSubjects] EvaluationSubjects Loaded',
  EvaluationSubjectsLoadError = '[EvaluationSubjects] Load Error',
  LoadEvaluationSubjects = '[EvaluationSubjects] Load EvaluationSubjects',
  LoadEvaluationSubjectsForBook = '[EvaluationSubjects] Load EvaluationSubjects For Book',
  AddEvaluationSubjectsForBook = '[EvaluationSubjects] Add EvaluationSubjects For Book',
  StartAddEvaluationSubject = '[EvaluationSubjects] Start Add EvaluationSubjects',
  AddEvaluationSubject = '[EvaluationSubjects] Add EvaluationSubject',
  UpsertEvaluationSubject = '[EvaluationSubjects] Upsert EvaluationSubject',
  AddEvaluationSubjects = '[EvaluationSubjects] Add EvaluationSubjects',
  UpsertEvaluationSubjects = '[EvaluationSubjects] Upsert EvaluationSubjects',
  UpdateEvaluationSubject = '[EvaluationSubjects] Update EvaluationSubject',
  UpdateEvaluationSubjects = '[EvaluationSubjects] Update EvaluationSubjects',
  DeleteEvaluationSubject = '[EvaluationSubjects] Delete EvaluationSubject',
  DeleteEvaluationSubjects = '[EvaluationSubjects] Delete EvaluationSubjects',
  ClearEvaluationSubjects = '[EvaluationSubjects] Clear EvaluationSubjects',
}

export class LoadEvaluationSubjects implements Action {
  readonly type = EvaluationSubjectsActionTypes.LoadEvaluationSubjects;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class EvaluationSubjectsLoaded implements Action {
  readonly type = EvaluationSubjectsActionTypes.EvaluationSubjectsLoaded;

  constructor(public payload: { evaluationSubjects: EvaluationSubjectInterface[] }) {}
}

export class LoadEvaluationSubjectsForBook implements Action {
  readonly type = EvaluationSubjectsActionTypes.LoadEvaluationSubjectsForBook;

  constructor(public payload: { bookId: number }) {}
}

export class AddEvaluationSubjectsForBook implements Action {
  readonly type = EvaluationSubjectsActionTypes.AddEvaluationSubjectsForBook;

  constructor(public payload: { bookId: number; evaluationSubjects: EvaluationSubjectInterface[] }) {}
}

export class StartAddEvaluationSubject implements Action, FeedbackTriggeringAction {
  readonly type = EvaluationSubjectsActionTypes.StartAddEvaluationSubject;

  constructor(
    public payload: {
      evaluationSubject: EvaluationSubjectInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class EvaluationSubjectsLoadError implements Action {
  readonly type = EvaluationSubjectsActionTypes.EvaluationSubjectsLoadError;
  constructor(public payload: any) {}
}

export class AddEvaluationSubject implements Action {
  readonly type = EvaluationSubjectsActionTypes.AddEvaluationSubject;

  constructor(public payload: { evaluationSubject: EvaluationSubjectInterface }) {}
}

export class UpsertEvaluationSubject implements Action {
  readonly type = EvaluationSubjectsActionTypes.UpsertEvaluationSubject;

  constructor(public payload: { evaluationSubject: EvaluationSubjectInterface }) {}
}

export class AddEvaluationSubjects implements Action {
  readonly type = EvaluationSubjectsActionTypes.AddEvaluationSubjects;

  constructor(public payload: { evaluationSubjects: EvaluationSubjectInterface[] }) {}
}

export class UpsertEvaluationSubjects implements Action {
  readonly type = EvaluationSubjectsActionTypes.UpsertEvaluationSubjects;

  constructor(public payload: { evaluationSubjects: EvaluationSubjectInterface[] }) {}
}

export class UpdateEvaluationSubject implements Action {
  readonly type = EvaluationSubjectsActionTypes.UpdateEvaluationSubject;

  constructor(
    public payload: {
      evaluationSubject: Update<EvaluationSubjectInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpdateEvaluationSubjects implements Action {
  readonly type = EvaluationSubjectsActionTypes.UpdateEvaluationSubjects;

  constructor(
    public payload: {
      evaluationSubjects: Update<EvaluationSubjectInterface>[];
    }
  ) {}
}

export class DeleteEvaluationSubject implements Action, FeedbackTriggeringAction {
  readonly type = EvaluationSubjectsActionTypes.DeleteEvaluationSubject;

  constructor(public payload: { id: number; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export class DeleteEvaluationSubjects implements Action {
  readonly type = EvaluationSubjectsActionTypes.DeleteEvaluationSubjects;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearEvaluationSubjects implements Action {
  readonly type = EvaluationSubjectsActionTypes.ClearEvaluationSubjects;
}

export type EvaluationSubjectActions =
  | LoadEvaluationSubjects
  | LoadEvaluationSubjectsForBook
  | EvaluationSubjectsLoaded
  | EvaluationSubjectsLoadError
  | AddEvaluationSubject
  | AddEvaluationSubjectsForBook
  | UpsertEvaluationSubject
  | AddEvaluationSubjects
  | UpsertEvaluationSubjects
  | UpdateEvaluationSubject
  | UpdateEvaluationSubjects
  | DeleteEvaluationSubject
  | DeleteEvaluationSubjects
  | ClearEvaluationSubjects
  | StartAddEvaluationSubject;
