import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionService } from '@campus/shared';
import { map } from 'rxjs/operators';

@Injectable()
export class PermissionGuard implements CanActivate {
  private unAuthorizedUrl = '/error/401';

  constructor(private permissionService: PermissionService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const { requiredPermissions } = route.data;
    if (!requiredPermissions?.length) return true;

    return this.permissionService.hasPermission$(requiredPermissions).pipe(
      map((permitted) => {
        if (!permitted) return this.router.parseUrl(this.unAuthorizedUrl);
        return true;
      })
    );
  }
}
