import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { PersonProductInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum PersonProductsActionTypes {
  PersonProductsLoaded = '[PersonProducts] PersonProducts Loaded',
  PersonProductsLoadError = '[PersonProducts] Load Error',
  LoadPersonProducts = '[PersonProducts] Load PersonProducts',
  AddPersonProduct = '[PersonProducts] Add PersonProduct',
  StartAddPersonProduct = '[PersonProducts] Start Add PersonProduct',
  UpsertPersonProduct = '[PersonProducts] Upsert PersonProduct',
  AddPersonProducts = '[PersonProducts] Add PersonProducts',
  StartAddPersonProducts = '[PersonProducts] Start Add PersonProducts',
  UpsertPersonProducts = '[PersonProducts] Upsert PersonProducts',
  UpdatePersonProduct = '[PersonProducts] Update PersonProduct',
  UpdatePersonProducts = '[PersonProducts] Update PersonProducts',
  DeletePersonProduct = '[PersonProducts] Delete PersonProduct',
  DeletePersonProducts = '[PersonProducts] Delete PersonProducts',
  ClearPersonProducts = '[PersonProducts] Clear PersonProducts',
}

export class LoadPersonProducts implements Action {
  readonly type = PersonProductsActionTypes.LoadPersonProducts;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class PersonProductsLoaded implements Action {
  readonly type = PersonProductsActionTypes.PersonProductsLoaded;

  constructor(public payload: { personProducts: PersonProductInterface[] }) {}
}

export class PersonProductsLoadError implements Action {
  readonly type = PersonProductsActionTypes.PersonProductsLoadError;
  constructor(public payload: any) {}
}

export class AddPersonProduct implements Action {
  readonly type = PersonProductsActionTypes.AddPersonProduct;

  constructor(public payload: { personProduct: PersonProductInterface }) {}
}

export class StartAddPersonProduct implements FeedbackTriggeringAction {
  readonly type = PersonProductsActionTypes.StartAddPersonProduct;

  constructor(
    public payload: {
      personProduct: PersonProductInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpsertPersonProduct implements Action {
  readonly type = PersonProductsActionTypes.UpsertPersonProduct;

  constructor(public payload: { personProduct: PersonProductInterface }) {}
}

export class AddPersonProducts implements Action {
  readonly type = PersonProductsActionTypes.AddPersonProducts;

  constructor(
    public payload: {
      personProducts: PersonProductInterface[];
    }
  ) {}
}

export class StartAddPersonProducts implements FeedbackTriggeringAction {
  readonly type = PersonProductsActionTypes.StartAddPersonProducts;

  constructor(
    public payload: {
      personProducts: PersonProductInterface[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpsertPersonProducts implements Action {
  readonly type = PersonProductsActionTypes.UpsertPersonProducts;

  constructor(public payload: { personProducts: PersonProductInterface[] }) {}
}

export class UpdatePersonProduct implements FeedbackTriggeringAction {
  readonly type = PersonProductsActionTypes.UpdatePersonProduct;

  constructor(
    public payload: {
      personProduct: Update<PersonProductInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpdatePersonProducts implements Action {
  readonly type = PersonProductsActionTypes.UpdatePersonProducts;

  constructor(public payload: { personProducts: Update<PersonProductInterface>[] }) {}
}

export class DeletePersonProduct implements FeedbackTriggeringAction {
  readonly type = PersonProductsActionTypes.DeletePersonProduct;

  constructor(
    public payload: {
      id: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class DeletePersonProducts implements FeedbackTriggeringAction {
  readonly type = PersonProductsActionTypes.DeletePersonProducts;

  constructor(
    public payload: {
      ids: number[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class ClearPersonProducts implements Action {
  readonly type = PersonProductsActionTypes.ClearPersonProducts;
}

export type PersonProductsActions =
  | LoadPersonProducts
  | PersonProductsLoaded
  | PersonProductsLoadError
  | AddPersonProduct
  | UpsertPersonProduct
  | AddPersonProducts
  | UpsertPersonProducts
  | UpdatePersonProduct
  | UpdatePersonProducts
  | DeletePersonProduct
  | DeletePersonProducts
  | StartAddPersonProducts
  | StartAddPersonProduct
  | ClearPersonProducts;
