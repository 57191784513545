import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'campus-progress-bar',
  templateUrl: './linear-progress-bar.component.html',
})
export class LinearProgressBarComponent {
  private _progress: number;

  @HostBinding('class')
  defaultClasses = [
    'progress-bar',
    'linear-progress-bar',

    'surface',

    'block',
    'border',
    'border-secondary',
    'border-2',

    'corner-full',
  ];

  @HostBinding('class.large')
  @Input()
  isLarge = false;

  @HostBinding('style.--progress')
  @Input()
  set progress(value: number) {
    value = value || 0;
    if (value > 100) {
      value = 100;
    }
    if (value < 0) {
      value = 0;
    }
    this._progress = value;
  }

  get progress() {
    return this._progress;
  }
}
