/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { PersonPreferenceApi as SDKPersonPreferenceApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { PersonPreferenceInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class PersonPreferenceApi extends ModelApiBase {
  private SDKPersonPreferenceApi = inject(SDKPersonPreferenceApi);

  constructor(api: PolpoApi) {
    super(api, 'PersonPreferences');
  }

  find(options?): Observable<PersonPreferenceInterface[]> {
    return this.SDKPersonPreferenceApi.find(...arguments);
  }

  deleteById(id: number): Observable<any> {
    // @ts-expect-error TS2556
    return this.SDKPersonPreferenceApi.deleteById(...arguments);
  }

  upsertPersonPreference(personPreference: Partial<PersonPreferenceInterface>): Observable<any> {
    // @ts-expect-error TS2556
    return this.SDKPersonPreferenceApi.upsertPersonPreference(...arguments);
  }
}
