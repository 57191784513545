import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch, optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from } from 'rxjs';
import { map, mapTo, switchMap } from 'rxjs/operators';
import { GoalTopicQueries } from '.';
import { EduContentInterface, EduContentMetadataInterface, GoalTopicInterface } from '../../+models';
import { GoalTopicService } from '../../goal-topic';
import { DalState } from '../dal.state.interface';
import { EduContentActions } from '../edu-content';
import { EduContentMetadataActions } from '../edu-content-metadata';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import {
  AddGoalTopic,
  AddGoalTopicsForLearningArea,
  DeleteGoalTopic,
  GetLinkedEduContentMetadata,
  GoalTopicsActionTypes,
  GoalTopicsLoadError,
  LinkedEduContentMetadaLoaded,
  LoadGoalTopicsForLearningArea,
  StartAddGoalTopic,
  UpdateGoalTopic,
} from './goal-topic.actions';

@Injectable()
export class GoalTopicEffects {
  startAddGoalTopic$ = createEffect(() =>
    this.actions.pipe(
      ofType(GoalTopicsActionTypes.StartAddGoalTopic),
      pessimisticUpdate({
        run: (action: StartAddGoalTopic) => {
          return this.goalTopicService.createGoalTopic(action.payload.goalTopic).pipe(
            switchMap((goalTopic: GoalTopicInterface) => {
              return from([
                new AddGoalTopic({ goalTopic }),
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'Het topic is toegevoegd.'
                  ),
                }),
              ]);
            })
          );
        },
        onError: (action: StartAddGoalTopic, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het topic toe te voegen.'
            ),
          });
        },
      })
    )
  );

  loadGoalTopicsForLearningArea$ = createEffect(() =>
    this.actions.pipe(
      ofType(GoalTopicsActionTypes.LoadGoalTopicsForLearningArea),
      concatLatestFrom(() => this.store.select(GoalTopicQueries.selectGoalTopicState)),
      fetch({
        run: (action: LoadGoalTopicsForLearningArea, goalTopicState) => {
          const { learningAreaId } = action.payload;

          if (goalTopicState.loadedForLearningArea[learningAreaId]) return;

          return this.goalTopicService.getGoalTopicsForLearningArea(learningAreaId).pipe(
            map(
              (goalTopics) =>
                new AddGoalTopicsForLearningArea({
                  learningAreaId,
                  goalTopics,
                })
            )
          );
        },
        onError: (action: LoadGoalTopicsForLearningArea, error) => {
          return new GoalTopicsLoadError(error);
        },
      })
    )
  );

  getLinkedEduContentMetadata$ = createEffect(() =>
    this.actions.pipe(
      ofType(GoalTopicsActionTypes.GetLinkedEduContentMetadata),
      concatLatestFrom(() => this.store.select(GoalTopicQueries.selectGoalTopicState)),
      fetch({
        run: (action: GetLinkedEduContentMetadata, goalTopicState) => {
          const { goalTopicId } = action.payload;
          if (!action.payload.force && !!goalTopicState.eduContentsLoaded[goalTopicId]) return;
          return this.goalTopicService.getLinkedEduContentMetadata(goalTopicId).pipe(
            switchMap((metadata: EduContentMetadataInterface[]) => {
              const eduContents = metadata.map(this.toEduContent);
              return [
                new EduContentActions.AddEduContents({ eduContents }),
                new EduContentMetadataActions.AddEduContentMetadatas({ eduContentMetadatas: metadata }),
                new LinkedEduContentMetadaLoaded({ goalTopicId }),
              ];
            })
          );
        },
      })
    )
  );

  updateGoalTopic$ = createEffect(() =>
    this.actions.pipe(
      ofType(GoalTopicsActionTypes.UpdateGoalTopic),
      optimisticUpdate({
        run: (action: UpdateGoalTopic) => {
          const { changes } = action.payload.goalTopic;
          return this.goalTopicService.updateGoalTopic(changes.id, changes).pipe(
            mapTo(
              new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(this.uuid(), action, 'Het topic is aangepast.'),
              })
            )
          );
        },
        undoAction: (action: UpdateGoalTopic, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het topic aan te passen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  deleteGoalTopic$ = createEffect(() =>
    this.actions.pipe(
      ofType(GoalTopicsActionTypes.DeleteGoalTopic),
      optimisticUpdate({
        run: (action: DeleteGoalTopic) => {
          const { id } = action.payload;
          return this.goalTopicService.removeGoalTopic(id).pipe(
            mapTo(
              new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(this.uuid(), action, 'Het topic is verwijderd.'),
              })
            )
          );
        },
        undoAction: (action: DeleteGoalTopic, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het topic te verwijderen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  toEduContent(metadata: EduContentMetadataInterface): EduContentInterface {
    const { eduContent } = metadata;
    delete metadata.eduContent;
    return {
      ...eduContent,
      publishedEduContentMetadata: metadata,
    };
  }
  constructor(
    @Inject('uuid') private uuid: () => string,
    private actions: Actions,
    private store: Store<DalState>,
    private goalTopicService: GoalTopicService
  ) {}
}
