import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { EduContentTOCEduContentMetadataInterface } from '../../+models/EduContentTOCEduContentMetadata.interface';
import {
  EduContentTOCEduContentMetadataActions,
  EduContentTOCEduContentMetadataActionTypes,
} from './edu-content-toc-edu-content-metadata.actions';
export const NAME = 'eduContentTOCEduContentMetadata';

export interface State extends EntityState<EduContentTOCEduContentMetadataInterface> {
  // additional entities state properties
  error?: any;
  loadedForBook: Dictionary<boolean>;
}

export const adapter: EntityAdapter<EduContentTOCEduContentMetadataInterface> =
  createEntityAdapter<EduContentTOCEduContentMetadataInterface>({
    sortComparer: (a, b) => a.displayOrder - b.displayOrder || a.id - b.id,
  });

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loadedForBook: {},
});

export function reducer(state = initialState, action: EduContentTOCEduContentMetadataActions): State {
  switch (action.type) {
    case EduContentTOCEduContentMetadataActionTypes.AddEduContentTOCEduContentMetadata: {
      return adapter.addOne(action.payload.eduContentTOCEduContentMetadata, state);
    }

    case EduContentTOCEduContentMetadataActionTypes.UpsertEduContentTOCEduContentMetadata: {
      return adapter.upsertOne(action.payload.eduContentTOCEduContentMetadata, state);
    }

    case EduContentTOCEduContentMetadataActionTypes.AddEduContentTOCEduContentMetadatas: {
      return adapter.addMany(action.payload.eduContentTOCEduContentMetadata, state);
    }

    case EduContentTOCEduContentMetadataActionTypes.AddEduContentTOCEduContentMetadatasForBook: {
      const { bookId, eduContentTOCEduContentMetadata } = action.payload;
      const newState = adapter.addMany(eduContentTOCEduContentMetadata, state);

      return {
        ...newState,
        loadedForBook: {
          ...newState.loadedForBook,
          [bookId]: true,
        },
      };
    }

    case EduContentTOCEduContentMetadataActionTypes.UpsertEduContentTOCEduContentMetadatas: {
      return adapter.upsertMany(action.payload.eduContentTOCEduContentMetadata, state);
    }

    case EduContentTOCEduContentMetadataActionTypes.UpdateEduContentTOCEduContentMetadata: {
      return adapter.updateOne(action.payload.eduContentTOCEduContentMetadata, state);
    }

    case EduContentTOCEduContentMetadataActionTypes.UpdateEduContentTOCEduContentMetadatas: {
      return adapter.updateMany(action.payload.eduContentTOCEduContentMetadata, state);
    }

    case EduContentTOCEduContentMetadataActionTypes.DeleteEduContentTOCEduContentMetadata: {
      return adapter.removeOne(action.payload.id, state);
    }

    case EduContentTOCEduContentMetadataActionTypes.DeleteEduContentTOCEduContentMetadatas: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case EduContentTOCEduContentMetadataActionTypes.EduContentTOCEduContentMetadataLoaded: {
      return adapter.setAll(action.payload.eduContentTOCEduContentMetadata, { ...state });
    }

    case EduContentTOCEduContentMetadataActionTypes.EduContentTOCEduContentMetadataLoadError: {
      return { ...state, error: action.payload };
    }

    case EduContentTOCEduContentMetadataActionTypes.ClearEduContentTOCEduContentMetadatas: {
      return adapter.removeAll(state);
    }
    case EduContentTOCEduContentMetadataActionTypes.DeleteEduContentTOCEduContentMetadatasByTocIdAndMetadataId: {
      const entities = Object.values(state.entities);
      const ectEcmDict = entities.reduce((acc, entity) => {
        acc[`${entity.eduContentTOCId}-${entity.eduContentMetadataId}`] = entity.id;
        return acc;
      }, {});

      const idsToDelete = action.payload.eduContentTocEduContentMetadataPartials
        .filter(({ tocId, eduContentMetadataId }) => !!ectEcmDict[`${tocId}-${eduContentMetadataId}`])
        .map(({ tocId, eduContentMetadataId }) => ectEcmDict[`${tocId}-${eduContentMetadataId}`]);

      return adapter.removeMany(idsToDelete, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
