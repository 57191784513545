import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { DalState, RolesEnum, UserQueries } from '@campus/dal';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DashboardGuard implements CanActivate {
  constructor(private store: Store<DalState>, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.pipe(
      select(UserQueries.getCurrentUser),
      map((currentUser) => {
        // TODO: I was told this is a temporary thing
        if (currentUser.types.some((type) => type === RolesEnum.Schooladmin)) {
          return this.router.createUrlTree(['schools']);
        }

        return true;
      })
    );
  }
}
