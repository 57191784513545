import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { AlgebraKitSessionResultInterface } from './';

@Component({
  selector: 'campus-score-level-attempts',
  template: `<campus-score-level
    *ngFor="let scoreLevel of scoreLevels"
    [scoreLevel]="scoreLevel"
  ></campus-score-level>`,

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScoreLevelAttemptsComponent implements OnInit {
  public MAX_ATTEMPTS_DISPLAYED = 24;

  @HostBinding('class.ui-score-level-attempts')
  hasScoreLevelAttemptsClass = true;

  @HostBinding('attr.data-cy')
  dataCy = 'result-score-level-attempts';

  @Input() scoreLevels: AlgebraKitSessionResultInterface[];

  @HostBinding('style.--score-level-columns')
  get maxColumns() {
    return Math.ceil(this.scoreLevels.length / 3);
  }

  ngOnInit() {
    this.scoreLevels = this.scoreLevels.slice(-this.MAX_ATTEMPTS_DISPLAYED);
  }
}
