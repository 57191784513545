import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TaskLevelSettingInterface } from '../../+models';
import { TaskLevelSettingsActions, TaskLevelSettingsActionTypes } from './task-level-setting.actions';

export const NAME = 'taskLevelSettings';

export interface State extends EntityState<TaskLevelSettingInterface> {
  // additional entities state properties
  loaded: boolean;
  loadedForTask: {
    [taskId: number]: boolean;
  };
  saving: boolean;
  error?: any;
}

export const adapter: EntityAdapter<TaskLevelSettingInterface> = createEntityAdapter<TaskLevelSettingInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForTask: {},
  saving: false,
});

export function reducer(state = initialState, action: TaskLevelSettingsActions): State {
  switch (action.type) {
    case TaskLevelSettingsActionTypes.AddTaskLevelSetting: {
      return adapter.addOne(action.payload.taskLevelSetting, state);
    }

    case TaskLevelSettingsActionTypes.UpsertTaskLevelSetting: {
      return adapter.upsertOne(action.payload.taskLevelSetting, state);
    }

    case TaskLevelSettingsActionTypes.AddTaskLevelSettingsForTask: {
      const newState = adapter.addMany(action.payload.taskLevelSettings, state);

      return {
        ...newState,
        loadedForTask: {
          ...newState.loadedForTask,
          [action.payload.taskId]: true,
        },
      };
    }
    case TaskLevelSettingsActionTypes.StartSetTaskLevelSettingsForTask: {
      return { ...state, saving: true };
    }

    case TaskLevelSettingsActionTypes.SetTaskLevelSettingsForTask: {
      const toRemove = Object.values(state.entities)
        .filter((entity) => entity.taskId === action.payload.taskId)
        .map((e) => e.id);

      const clearedState = adapter.removeMany(toRemove, state);
      const newState = adapter.addMany(action.payload.taskLevelSettings, clearedState);

      return {
        ...newState,
        loadedForTask: {
          ...state.loadedForTask,
          [action.payload.taskId]: true,
        },
        saving: false,
      };
    }

    case TaskLevelSettingsActionTypes.AddTaskLevelSettings: {
      return adapter.addMany(action.payload.taskLevelSettings, state);
    }

    case TaskLevelSettingsActionTypes.UpsertTaskLevelSettings: {
      return adapter.upsertMany(action.payload.taskLevelSettings, state);
    }

    case TaskLevelSettingsActionTypes.UpdateTaskLevelSetting: {
      return adapter.updateOne(action.payload.taskLevelSetting, state);
    }

    case TaskLevelSettingsActionTypes.UpdateTaskLevelSettings: {
      return adapter.updateMany(action.payload.taskLevelSettings, state);
    }

    case TaskLevelSettingsActionTypes.DeleteTaskLevelSetting: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TaskLevelSettingsActionTypes.DeleteTaskLevelSettings: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TaskLevelSettingsActionTypes.TaskLevelSettingsLoaded: {
      return adapter.setAll(action.payload.taskLevelSettings, { ...state, loaded: true });
    }

    case TaskLevelSettingsActionTypes.TaskLevelSettingsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case TaskLevelSettingsActionTypes.ClearTaskLevelSettings: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
