/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { EvaluationSubjectApi as SDKEvaluationSubjectApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { EvaluationSubjectInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class EvaluationSubjectApi extends ModelApiBase {
  private SDKEvaluationSubjectApi = inject(SDKEvaluationSubjectApi);

  constructor(api: PolpoApi) {
    super(api, 'EvaluationSubjects');
  }

  getEvaluationSubjectsForBook(bookId: number): Observable<EvaluationSubjectInterface[]> {
    // @ts-expect-error TS 2556
    return this.SDKEvaluationSubjectApi.getEvaluationSubjectsForBook(...arguments);
  }

  addEvaluationSubject(evaluationSubject): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEvaluationSubjectApi.addEvaluationSubject(...arguments);
  }

  updateEvaluationSubject(evaluationSubjectId: number, changes): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEvaluationSubjectApi.updateEvaluationSubject(...arguments);
  }

  deleteEvaluationSubject(evaluationSubjectId: number): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEvaluationSubjectApi.deleteEvaluationSubject(...arguments);
  }
}
