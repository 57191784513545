import { RoleInterface, RolesEnum } from '@campus/dal';

const containsRole = (personRoles: RoleInterface[], desiredRole: RolesEnum): boolean => {
  if (!personRoles) return false;
  return personRoles.some((role) => role.name === desiredRole);
};

export default {
  containsRole,
};
