import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import {
  TaskEvaluationSubjectInterface,
  TaskEvaluationSubjectScoreInterface,
  TaskGoalYearLevelInterface,
} from '../+models';

export const TASK_EVALUATION_SUBJECT_SERVICE_TOKEN = new InjectionToken('TaskEvaluationSubjectService');

export interface SetTaskEvaluationSubjectsForTaskResponseInterface {
  taskEvaluationSubjects: TaskEvaluationSubjectInterface[];
  taskGoalYearLevels: TaskGoalYearLevelInterface[];
  scores: TaskEvaluationSubjectScoreInterface[];
}

export interface TaskEvaluationSubjectServiceInterface {
  getTaskEvaluationSubjectsForTask(taskId: number): Observable<TaskEvaluationSubjectInterface[]>;

  setTaskEvaluationSubjectsForTask(
    taskId: number,
    taskEvaluationSubjects: TaskEvaluationSubjectInterface[]
  ): Observable<SetTaskEvaluationSubjectsForTaskResponseInterface>;
}
