import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { NewsCategoryNewsInterface } from '../../+models';
import { NewsCategoryNewsActions, NewsCategoryNewsActionTypes } from './news-category-news.actions';

export const NAME = 'newsCategoryNews';

export interface State extends EntityState<NewsCategoryNewsInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<NewsCategoryNewsInterface> = createEntityAdapter<NewsCategoryNewsInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: NewsCategoryNewsActions): State {
  switch (action.type) {
    case NewsCategoryNewsActionTypes.NewsCategoryNewsLoaded: {
      return adapter.setAll(action.payload.newsCategoryNews, { ...state, loaded: true });
    }

    case NewsCategoryNewsActionTypes.NewsCategoryNewsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case NewsCategoryNewsActionTypes.ClearNewsCategoryNews: {
      return adapter.removeAll(state);
    }

    case NewsCategoryNewsActionTypes.AddNewsCategoryNews: {
      return adapter.addMany(action.payload.newsCategoryNews, state);
    }
    case NewsCategoryNewsActionTypes.SetNewsCategoryNewsForNews: {
      const { newsId, newsCategoryNews } = action.payload;

      const toRemove = (state.ids as number[]).filter((id) => {
        const entity = state.entities[id];
        return entity.newsId === newsId;
      });

      const clearedState = adapter.removeMany(toRemove, state);
      const newState = adapter.addMany(newsCategoryNews, clearedState);

      return newState;
    }

    case NewsCategoryNewsActionTypes.RemoveNewsCategoryNewsForNews: {
      const { newsId } = action.payload;

      const toRemove = (state.ids as number[]).filter((id) => {
        const entity = state.entities[id];
        return entity.newsId === newsId;
      });
      return adapter.removeMany(toRemove, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
