import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { YearQueries } from '.';
import { YearServiceInterface, YEAR_SERVICE_TOKEN } from '../../year/year.service.interface';
import { DalState } from '../dal.state.interface';
import { LoadYears, YearsActionTypes, YearsLoaded, YearsLoadError } from './year.actions';

@Injectable()
export class YearEffects {
  loadYears$ = createEffect(() =>
    this.actions.pipe(
      ofType(YearsActionTypes.LoadYears),
      concatLatestFrom(() => this.store.select(YearQueries.getLoaded)),
      fetch({
        run: (action: LoadYears, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.yearService.getAll().pipe(map((years) => new YearsLoaded({ years })));
        },
        onError: (action: LoadYears, error) => {
          return new YearsLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(YEAR_SERVICE_TOKEN)
    private yearService: YearServiceInterface
  ) {}
}
