import { Action } from '@ngrx/store';
import { NewsCategoryInterface } from '../../+models';

export enum NewsCategoryActionTypes {
  NewsCategoriesLoaded = '[NewsCategory] News Categories Loaded',
  NewsCategoriesLoadError = '[NewsCategory] Load Error',
  LoadNewsCategories = '[NewsCategory] Load News Categories',
  ClearNewsCategories = '[NewsCategory] Clear News Categories',
};

export class LoadNewsCategories implements Action {
  readonly type = NewsCategoryActionTypes.LoadNewsCategories;
  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {};
}

export class NewsCategoriesLoaded implements Action {
  readonly type = NewsCategoryActionTypes.NewsCategoriesLoaded;
  constructor(public payload: { newsCategories: NewsCategoryInterface[] }) {};
}

export class NewsCategoriesLoadError implements Action {
  readonly type = NewsCategoryActionTypes.NewsCategoriesLoadError;
  constructor(public payload: any) {};
}

export class ClearNewsCategories implements Action {
  readonly type = NewsCategoryActionTypes.ClearNewsCategories;
}

export type NewsCategoryActions =
  | LoadNewsCategories
  | NewsCategoriesLoaded
  | NewsCategoriesLoadError
  | ClearNewsCategories;
