import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NAME, selectEntities, State } from './edu-content-toc-learning-plan-goal.reducer';

export const selectEduContentTocLearningPlanGoalState = createFeatureSelector<State>(NAME);

export const getAllEntities = createSelector(selectEduContentTocLearningPlanGoalState, selectEntities);

export const getError = createSelector(selectEduContentTocLearningPlanGoalState, (state: State) => state.error);

export const getByIdsForToc = createSelector(
  selectEduContentTocLearningPlanGoalState,
  (state: State, props: { ids: number[] }) => {
    return props.ids.reduce((acc, id) => {
      acc[id] = state.loadedForToc[id];
      return acc;
    }, {} as { [key: number]: number[] });
  }
);

export const isTocLoaded = createSelector(
  selectEduContentTocLearningPlanGoalState,
  (state: State, props: { tocId: number }) => !!state.loadedForToc[props.tocId]
);
