/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { UnlockedContentApi as SDKUnlockedContentApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { UnlockedContentInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';
@Injectable({ providedIn: 'root' })
export class UnlockedContentApi extends ModelApiBase {
  private SDKUnlockedContentApi = inject(SDKUnlockedContentApi);

  constructor(api: PolpoApi) {
    super(api, 'UnlockedContents');
  }

  deleteById(id: number): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKUnlockedContentApi.deleteById(...arguments);
  }

  createUnlockedContents(unlockedContents: Partial<UnlockedContentInterface>[]): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKUnlockedContentApi.createUnlockedContents(...arguments);
  }

  updateUnlockedContents(update: Partial<UnlockedContentInterface>[]): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKUnlockedContentApi.updateUnlockedContents(...arguments);
  }
}
