import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { GoalYearInterface } from '../../+models';

export enum GoalYearsActionTypes {
  GoalYearsLoaded = '[GoalYears] GoalYears Loaded',
  GoalYearsLoadError = '[GoalYears] Load Error',
  LoadGoalYears = '[GoalYears] Load GoalYears',
  LoadGoalYearsForLearningArea = '[Goals] Load GoalYears For Learning Area',
  AddGoalYearsForLearningArea = '[Goals] Add GoalYears for Learning Area',
  AddGoalYear = '[GoalYears] Add GoalYear',
  UpsertGoalYear = '[GoalYears] Upsert GoalYear',
  AddGoalYears = '[GoalYears] Add GoalYears',
  UpsertGoalYears = '[GoalYears] Upsert GoalYears',
  UpdateGoalYear = '[GoalYears] Update GoalYear',
  UpdateGoalYears = '[GoalYears] Update GoalYears',
  DeleteGoalYear = '[GoalYears] Delete GoalYear',
  DeleteGoalYears = '[GoalYears] Delete GoalYears',
  ClearGoalYears = '[GoalYears] Clear GoalYears',
}

export class LoadGoalYears implements Action {
  readonly type = GoalYearsActionTypes.LoadGoalYears;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class LoadGoalYearsForLearningArea implements Action {
  readonly type = GoalYearsActionTypes.LoadGoalYearsForLearningArea;

  constructor(public payload: { learningAreaId: number }) {}
}

export class AddGoalYearsForLearningArea implements Action {
  readonly type = GoalYearsActionTypes.AddGoalYearsForLearningArea;

  constructor(public payload: { learningAreaId: number; goalYears: GoalYearInterface[] }) {}
}

export class GoalYearsLoaded implements Action {
  readonly type = GoalYearsActionTypes.GoalYearsLoaded;

  constructor(public payload: { goalYears: GoalYearInterface[] }) {}
}

export class GoalYearsLoadError implements Action {
  readonly type = GoalYearsActionTypes.GoalYearsLoadError;
  constructor(public payload: any) {}
}

export class AddGoalYear implements Action {
  readonly type = GoalYearsActionTypes.AddGoalYear;

  constructor(public payload: { goalYear: GoalYearInterface }) {}
}

export class UpsertGoalYear implements Action {
  readonly type = GoalYearsActionTypes.UpsertGoalYear;

  constructor(public payload: { goalYear: GoalYearInterface }) {}
}

export class AddGoalYears implements Action {
  readonly type = GoalYearsActionTypes.AddGoalYears;

  constructor(public payload: { goalYears: GoalYearInterface[] }) {}
}

export class UpsertGoalYears implements Action {
  readonly type = GoalYearsActionTypes.UpsertGoalYears;

  constructor(public payload: { goalYears: GoalYearInterface[] }) {}
}

export class UpdateGoalYear implements Action {
  readonly type = GoalYearsActionTypes.UpdateGoalYear;

  constructor(public payload: { goalYear: Update<GoalYearInterface> }) {}
}

export class UpdateGoalYears implements Action {
  readonly type = GoalYearsActionTypes.UpdateGoalYears;

  constructor(public payload: { goalYears: Update<GoalYearInterface>[] }) {}
}

export class DeleteGoalYear implements Action {
  readonly type = GoalYearsActionTypes.DeleteGoalYear;

  constructor(public payload: { id: number }) {}
}

export class DeleteGoalYears implements Action {
  readonly type = GoalYearsActionTypes.DeleteGoalYears;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearGoalYears implements Action {
  readonly type = GoalYearsActionTypes.ClearGoalYears;
}

export type GoalYearsActions =
  | LoadGoalYears
  | LoadGoalYearsForLearningArea
  | GoalYearsLoaded
  | GoalYearsLoadError
  | AddGoalYear
  | AddGoalYearsForLearningArea
  | UpsertGoalYear
  | AddGoalYears
  | UpsertGoalYears
  | UpdateGoalYear
  | UpdateGoalYears
  | DeleteGoalYear
  | DeleteGoalYears
  | ClearGoalYears;
