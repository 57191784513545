import { groupArrayByKey } from '@campus/utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EvaluationSubjectGoalInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './evaluation-subject-goal.reducer';

export const selectEvaluationSubjectGoalState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectEvaluationSubjectGoalState, (state: State) => state.error);

export const getLoaded = createSelector(selectEvaluationSubjectGoalState, (state: State) => state.loaded);

export const getAll = createSelector(selectEvaluationSubjectGoalState, selectAll);

export const getCount = createSelector(selectEvaluationSubjectGoalState, selectTotal);

export const getIds = createSelector(selectEvaluationSubjectGoalState, selectIds);

export const getAllEntities = createSelector(selectEvaluationSubjectGoalState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * EvaluationSubjectGoal$: EvaluationSubjectGoalInterface[] = this.store.pipe(
    select(EvaluationSubjectGoalQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectEvaluationSubjectGoalState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * EvaluationSubjectGoal$: EvaluationSubjectGoalInterface = this.store.pipe(
    select(EvaluationSubjectGoalQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectEvaluationSubjectGoalState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getEvaluationSubjectGoalsLoadedForBook = createSelector(
  selectEvaluationSubjectGoalState,
  (state, props: { bookId: number }) => {
    return !!state.loadedForBook[props.bookId];
  }
);

export const getEvaluationSubjectGoalsByEvaluationSubjectId = createSelector(
  getAll,
  (evaluationsSubjectGoals: EvaluationSubjectGoalInterface[]) => {
    return groupArrayByKey(evaluationsSubjectGoals, 'evaluationSubjectId');
  }
);

export const getByGoalId = createSelector(getAll, (evaluationsSubjectGoals: EvaluationSubjectGoalInterface[]) => {
  return groupArrayByKey(evaluationsSubjectGoals, 'goalId');
});
