import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SchoolBillingInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './school-billing.reducer';

export const selectSchoolBillingState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectSchoolBillingState, (state: State) => state.error);

export const getLoaded = createSelector(selectSchoolBillingState, (state: State) => state.loaded);

export const getAll = createSelector(selectSchoolBillingState, selectAll);

export const getCount = createSelector(selectSchoolBillingState, selectTotal);

export const getIds = createSelector(selectSchoolBillingState, selectIds);

export const getAllEntities = createSelector(selectSchoolBillingState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * schoolBilling$: SchoolBillingInterface[] = this.store.pipe(
    select(SchoolBillingQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectSchoolBillingState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * schoolBilling$: SchoolBillingInterface = this.store.pipe(
    select(SchoolBillingQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectSchoolBillingState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getBySchoolId = createSelector(
  getAll,
  (classGroups: SchoolBillingInterface[]) =>
    groupArrayByKeys(classGroups, ['schoolId']) as Dictionary<SchoolBillingInterface[]>
);
