import { Type } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { BookDataResponseInterface } from '../../../data';
import { ArtifactActions } from '../../artifact';
import { EduContentActions } from '../../edu-content';
import { EduContentMetadataActions } from '../../edu-content-metadata';
import { EduContentMetadataEvaluationSubjectActions } from '../../edu-content-metadata-evaluation-subject';
import { EduContentTocActions } from '../../edu-content-toc';
import { EduContentTocEduContentActions } from '../../edu-content-toc-edu-content';
import { EduContentTocEduContentMetadataActions } from '../../edu-content-toc-edu-content-metadata';
import { EduContentTocEvaluationActions } from '../../edu-content-toc-evaluation';
import { EduContentTocLearningPlanGoalActions } from '../../edu-content-toc-learning-plan-goal';
import { EduContentTocLoopActions } from '../../edu-content-toc-loop';
import { EvaluationActions } from '../../evaluation';
import { EvaluationSubjectActions } from '../../evaluation-subject';
import { EvaluationSubjectGoalActions } from '../../evaluation-subject-goal';
import { GoalActions } from '../../goal';
import { GoalEduContentTOCActions } from '../../goal-edu-content-toc';
import { GoalTopicActions } from '../../goal-topic';
import { LearningPlanGoalActions } from '../../learning-plan-goal';
import { LessonSheetActions } from '../../lesson-sheet';
import { LoopLearnerStatusActions } from '../../loop-learner-profile';
import { MethodGoalActions } from '../../method-goal';
import { MethodStyleActions } from '../../method-style';
import { SectionActions } from '../../section';
import { SectionContentActions } from '../../section-content';
import { MethodOptionsActions } from './../../method-option';

const fieldActionMap: Dictionary<Type<Action>> = {
  eduContentTocs: EduContentTocActions.AddEduContentTocsForBook,
  eduContentTocEduContents: EduContentTocEduContentActions.AddEduContentTocEduContentsForBook,
  learningPlanGoals: LearningPlanGoalActions.AddLearningPlanGoalsForBook,
  methodGoals: MethodGoalActions.AddMethodGoalsForBook,
  evaluationSubjects: EvaluationSubjectActions.AddEvaluationSubjectsForBook,
  evaluationSubjectGoals: EvaluationSubjectGoalActions.AddEvaluationSubjectGoalsForBook,
  eduContents: EduContentActions.AddEduContentsForBook,
  goalEduContentTOCs: GoalEduContentTOCActions.AddGoalEduContentTOCsForBook,
  goals: GoalActions.AddGoalsForBook,
  eduContentTOCLoops: EduContentTocLoopActions.AddEduContentTOCLoopsForBook,
  goalTopics: GoalTopicActions.AddGoalTopicsForBook,
  loopLearnerStatuses: LoopLearnerStatusActions.AddLoopLearnerStatusesForBook,
  sections: SectionActions.AddSectionsForBook,
  sectionContents: SectionContentActions.AddSectionContentsForBook,
  eduContentMetadataEvaluationSubjects:
    EduContentMetadataEvaluationSubjectActions.AddEduContentMetadataEvaluationSubjectsForBook,
  evaluations: EvaluationActions.AddEvaluationsForBook,
  eduContentTocEvaluations: EduContentTocEvaluationActions.AddEduContentTocEvaluationsForBook,
  methodOptions: MethodOptionsActions.AddMethodOptionsForBook,
  methodStyle: MethodStyleActions.AddMethodStyleForBook,
  lessonSheets: LessonSheetActions.AddLessonSheetsForBook,
  learningPlanGoalIdsByTocId: EduContentTocLearningPlanGoalActions.AddLearningPlanGoalsIdsForBook,
};

const fieldUpsertActionMap: Dictionary<Type<Action>> = {};

export class DataFieldActionFactory {
  /**
   * For a given field & data, return the appropriate 'Loaded' action containing the data.
   *
   * Example: field `"books"` with data `[{id: 1}]` returns `BooksLoaded({ books: [{id: 1}] })`
   */
  static getLoadedAction(bookId: number, field: keyof BookDataResponseInterface, fieldData: any, data?: any): Action {
    const fieldActionExceptions: Dictionary<(fieldData, responseData) => Action> = {
      eduContentTocEduContentMetadata: (eduContentTOCEduContentMetadata) =>
        new EduContentTocEduContentMetadataActions.AddEduContentTOCEduContentMetadatasForBook({
          bookId,
          eduContentTOCEduContentMetadata,
        }),
      eduContentMetadata: (eduContentMetadatas) =>
        new EduContentMetadataActions.AddEduContentMetadatasForBook({ bookId, eduContentMetadatas }),
      eduContentMetadataDraft: (eduContentMetadatas) =>
        new EduContentMetadataActions.AddEduContentMetadatasDraftForBook({ bookId, eduContentMetadatas }),
      eduContentMetadataPublished: (eduContentMetadatas) =>
        new EduContentMetadataActions.AddEduContentMetadatasPublishedForBook({ bookId, eduContentMetadatas }),
      generalFiles: (eduContents) => new EduContentActions.AddGeneralFilesForBook({ bookId, eduContents }),
      games: (eduContents) => new EduContentActions.AddGamesForBook({ bookId, eduContents }),
      sectionEduContents: (eduContents) =>
        new EduContentActions.AddEduContentsForSectionsForBook({ bookId, eduContents }),
      evaluationSubjects: (evaluationSubjects) =>
        new EvaluationSubjectActions.AddEvaluationSubjectsForBook({ bookId, evaluationSubjects }),
      artifacts: (artifacts) => new ArtifactActions.AddArtifactsForBook({ bookId, artifacts }),
    };

    if (fieldActionExceptions[field]) {
      return fieldActionExceptions[field](fieldData, data);
    }

    const FieldLoaded = fieldActionMap[field];

    return (
      FieldLoaded &&
      new FieldLoaded({
        bookId,
        [field]: fieldData,
      })
    );
  }

  /**
   * For a given field & data, return the appropriate 'Upsert' action containing the data.
   *
   * Example: field `"books"` with data `[{id: 1}]` returns `UpsertBooks({ books: [{id: 1}] })`
   */
  static getUpsertAction(bookId: number, field: keyof BookDataResponseInterface, fieldData: any, data?: any): Action {
    const fieldActionExceptions: Dictionary<(fieldData, responseData) => Action> = {};

    if (fieldActionExceptions[field]) {
      return fieldActionExceptions[field](fieldData, data);
    }

    const FieldUpsert = fieldUpsertActionMap[field];

    return (
      FieldUpsert &&
      new FieldUpsert({
        bookId,
        [field]: data,
      })
    );
  }
}
