import { Inject, Pipe, PipeTransform } from '@angular/core';
import { EnvironmentAssetsInterface, ENVIRONMENT_ASSETS_TOKEN } from '@campus/environment';

@Pipe({
  name: 'assetsPath',
})
export class AssetsPathPipe implements PipeTransform {
  constructor(
    @Inject(ENVIRONMENT_ASSETS_TOKEN)
    private assets: EnvironmentAssetsInterface
  ) {}

  //Type check the nested keys of EnvironmentAssetsInterface
  transform<T extends EnvironmentAssetsInterface, P1 extends keyof NonNullable<T>>(
    path: string,
    prop1: P1
  ): NonNullable<T>[P1] | undefined;
  transform<
    T extends EnvironmentAssetsInterface,
    P1 extends keyof NonNullable<T>,
    P2 extends keyof NonNullable<NonNullable<T>[P1]>
  >(path: string, prop1: P1, prop2: P2): NonNullable<NonNullable<T>[P1]>[P2] | undefined;

  transform(path: string, ...props: string[]): string {
    if (!path) return null;
    const base = props.reduce((result, prop) => {
      if ([null, undefined].includes(result)) {
        return undefined;
      }
      if (!result.hasOwnProperty(prop)) {
        throw new Error(
          `AbsolutePathPipe Error: Property "${prop}" not found. Possible values: [${Object.keys(result).join(', ')}]"`
        );
      }
      return result[prop];
    }, this.assets);

    return this.trimSlash(base, 'end') + '/' + this.trimSlash(path, 'start');
  }

  private trimSlash(value: string, position: 'start' | 'end') {
    if (!value) return value;

    if (position === 'start') {
      return value.startsWith('/') ? value.slice(1) : value;
    } else if (position === 'end') {
      return value.endsWith('/') ? value.slice(0, -1) : value;
    }
  }
}

@Pipe({
  name: 'methodsImagesPath',
})
export class MethodsImagesPathPipe extends AssetsPathPipe implements PipeTransform {
  transform(path: string) {
    return super.transform(path, 'images', 'methods');
  }
}
