import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { fetch, optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from } from 'rxjs';
import { map, mapTo, mergeMapTo } from 'rxjs/operators';
import { CredentialQueries } from '.';
import { DalActions } from '..';
import { CredentialServiceInterface, CREDENTIAL_SERVICE_TOKEN } from '../../persons';
import { DalState } from '../dal.state.interface';
import { EffectFeedback, EffectFeedbackActions, Priority } from '../effect-feedback';
import { LoadUser } from './../user/user.actions';
import {
  CredentialsActionTypes,
  CredentialsLoaded,
  CredentialsLoadError,
  LoadCredentials,
  UnlinkCredential,
  UseCredentialProfilePicture,
} from './credential.actions';

@Injectable()
export class CredentialEffects {
  loadCredentials$ = createEffect(() =>
    this.actions.pipe(
      ofType(CredentialsActionTypes.LoadCredentials),
      concatLatestFrom(() => this.store.select(CredentialQueries.getLoaded)),
      fetch({
        run: (action: LoadCredentials, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.credentialService
            .getAllForUser(action.payload.userId)
            .pipe(map((credentials) => new CredentialsLoaded({ credentials })));
        },
        onError: (action: LoadCredentials, error) => {
          return new CredentialsLoadError(error);
        },
      })
    )
  );

  unlinkCredential$ = createEffect(() =>
    this.actions.pipe(
      ofType(CredentialsActionTypes.UnlinkCredential),
      optimisticUpdate({
        run: (action: UnlinkCredential) => {
          return this.credentialService.unlinkCredential(action.payload.credential).pipe(
            mapTo(
              new DalActions.ActionSuccessful({
                successfulAction: 'Credential unlinked.',
              })
            )
          );
        },
        undoAction: (action: UnlinkCredential, error) => {
          return undo(action);
        },
      })
    )
  );

  useCredentialProfilePicture$ = createEffect(() =>
    this.actions.pipe(
      ofType(CredentialsActionTypes.UseCredentialProfilePicture),
      pessimisticUpdate({
        run: (action: UseCredentialProfilePicture) => {
          return this.credentialService.useCredentialProfilePicture(action.payload.credential).pipe(
            mergeMapTo(
              from<Action[]>([
                new LoadUser({ force: true }),
                new EffectFeedbackActions.AddEffectFeedback({
                  effectFeedback: new EffectFeedback({
                    id: this.uuid(),
                    triggerAction: action,
                    message: 'Je profielfoto is gewijzigd.',
                  }),
                }),
              ])
            )
          );
        },
        onError: (action: UseCredentialProfilePicture, error) => {
          return new EffectFeedbackActions.AddEffectFeedback({
            effectFeedback: new EffectFeedback({
              id: this.uuid(),
              triggerAction: action,
              message: 'Het is niet gelukt om je profielfoto te wijzigen.',
              type: 'error',
              userActions: [
                {
                  title: 'Opnieuw proberen',
                  userAction: action,
                },
              ],
              priority: Priority.HIGH,
            }),
          });
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(CREDENTIAL_SERVICE_TOKEN)
    private credentialService: CredentialServiceInterface,
    @Inject('uuid') private uuid: () => string
  ) {}
}
