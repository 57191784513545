import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { EduContentTOCLoopInterface } from '../../+models/EduContentTOCLoop.interface';
import { EduContentTOCLoopsActions, EduContentTOCLoopsActionTypes } from './edu-content-toc-loop.actions';

export const NAME = 'eduContentTOCLoops';

export interface State extends EntityState<EduContentTOCLoopInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
  loadedForBook: Dictionary<boolean>;
}

export const adapter: EntityAdapter<EduContentTOCLoopInterface> = createEntityAdapter<EduContentTOCLoopInterface>({
  selectId: (entity) => entity.id,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForBook: {},
});

export function reducer(state = initialState, action: EduContentTOCLoopsActions): State {
  switch (action.type) {
    case EduContentTOCLoopsActionTypes.EduContentTOCLoopsLoaded: {
      return adapter.setAll(action.payload.eduContentTOCLoops, {
        ...state,
        loaded: true,
      });
    }

    case EduContentTOCLoopsActionTypes.EduContentTOCLoopsLoadError: {
      return { ...state, error: action.payload, loaded: false, loadedForBook: {} };
    }

    case EduContentTOCLoopsActionTypes.AddEduContentTOCLoop: {
      return adapter.addOne(action.payload.eduContentTOCLoop, state);
    }

    case EduContentTOCLoopsActionTypes.AddEduContentTOCLoops: {
      return adapter.addMany(action.payload.eduContentTOCLoops, state);
    }

    case EduContentTOCLoopsActionTypes.DeleteEduContentTOCLoop: {
      return adapter.removeOne(action.payload.id, state);
    }

    case EduContentTOCLoopsActionTypes.DeleteEduContentTOCLoops: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case EduContentTOCLoopsActionTypes.ClearLoadedBooks: {
      return { ...state, loadedForBook: {} };
    }

    case EduContentTOCLoopsActionTypes.AddEduContentTOCLoopsForBook: {
      const updated = adapter.addMany(action.payload.eduContentTOCLoops, state);
      return { ...updated, loadedForBook: { ...updated.loadedForBook, [action.payload.bookId]: true } };
    }

    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities } = adapter.getSelectors();
