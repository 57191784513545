import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './learner-profile-concept.reducer';

export const selectLearnerProfileConceptState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectLearnerProfileConceptState, (state: State) => state.error);

export const getLoaded = createSelector(selectLearnerProfileConceptState, (state: State) => state.loaded);

export const getAll = createSelector(selectLearnerProfileConceptState, selectAll);

export const getCount = createSelector(selectLearnerProfileConceptState, selectTotal);

export const getIds = createSelector(selectLearnerProfileConceptState, selectIds);

export const getAllEntities = createSelector(selectLearnerProfileConceptState, selectEntities);

export const isCourseLoaded = (props: { course: string }) =>
  createSelector(selectLearnerProfileConceptState, (state: State) => !!state.loadedForCourse[props.course]);
