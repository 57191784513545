import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdaptemyApi, EduContentBookApi } from '../+api';
import { ContextInterface, LearnerProfileConceptInterface } from '../+models';
import { LoopLearnerStatusInterface } from '../+models/LearnerProfile.interface';

@Injectable({
  providedIn: 'root',
})
export class LearnerProfileService {
  constructor(private adaptemyApi: AdaptemyApi, private bookApi: EduContentBookApi) {}

  getLoopLearnerStatus(userId: number, context: ContextInterface): Observable<LoopLearnerStatusInterface> {
    return this.adaptemyApi.getLoopLearnerStatus(userId, context) as Observable<LoopLearnerStatusInterface>;
  }

  getLoopLearnerStatuses(bookId: number): Observable<LoopLearnerStatusInterface[]> {
    return this.bookApi
      .getData(bookId, 'loopLearnerStatuses')
      .pipe(
        map(({ loopLearnerStatuses }: { loopLearnerStatuses: LoopLearnerStatusInterface[] }) => loopLearnerStatuses)
      );
  }

  getReports(curriculum: string, course: string): Observable<LearnerProfileConceptInterface[]> {
    return this.adaptemyApi.getReports(curriculum, course) as Observable<LearnerProfileConceptInterface[]>;
  }
}
