import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ContentChild,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

export enum ProgressFormEnum {
  LINEAR = 'linear',
  CIRCULAR = 'circular',
  ARC = 'arc',
}

export enum ProgressModeEnum {
  DETERMINATE = 'determinate',
  INDETERMINATE = 'indeterminate',
}

@Directive({
  selector: '[campusCompletedProgressIcon], [completedProgressIcon], completed-progress-icon',
})
export class CompletedProgressIconDirective {
  @HostBinding('class.ui-progress__completed-icon')
  completedIconClass = true;
}

@Component({
  selector: 'campus-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
  animations: [
    trigger('fadeLabel', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(1)' }),
        animate('250ms ease', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate(
          '500ms cubic-bezier(.01,.67,.31,1)',
          keyframes([
            style({
              transform: 'scale(1.2)',
              opacity: 0.9,
              offset: 0.55,
              textShadow: 'none',
            }),
            style({
              transform: 'scale(0.7)',
              opacity: 0.85,
              offset: 0.75,
              textShadow: '1px 1px 2px #999',
            }),
            style({
              transform: 'scale(2)',
              opacity: 0,
              offset: 1,
              textShadow: '1px 1px 3px #999',
            }),
          ])
        ),
      ]),
    ]),
    trigger('explodeIcon', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0)' }),
        animate(
          '800ms 300ms cubic-bezier(.01,.67,.31,1)',
          keyframes([
            style({ transform: 'scale(1.5)', opacity: 1, offset: 0.65 }),
            style({ transform: 'scale(0.8)', opacity: 1, offset: 0.8 }),
            style({ transform: 'scale(1.1)', opacity: 1, offset: 0.9 }),
            style({ transform: 'scale(0.95)', opacity: 1, offset: 0.95 }),
            style({ transform: 'scale(1)', opacity: 1, offset: 1 }),
          ])
        ),
      ]),
    ]),
  ],
})
export class ProgressComponent implements OnChanges {
  public forms: typeof ProgressFormEnum = ProgressFormEnum;
  public modes: typeof ProgressModeEnum = ProgressModeEnum;
  public percentage = 0;
  public invertedPercentage = 100;

  @Input() total? = 100;
  @Input() count?: number;

  @Input() showPercentage? = false;
  @Input() percentageSuffix = '';
  @Input() form: ProgressFormEnum = ProgressFormEnum.CIRCULAR;
  @Input() mode? = ProgressModeEnum.INDETERMINATE;
  @Input() diameter? = 100;
  @Input() svgIcon: string;
  @Input() tooltip?: string;
  @Input() staticMode? = false;

  @HostBinding('class.ui-progress')
  public uiProgressClass = true;

  @ContentChild(CompletedProgressIconDirective, { static: true })
  completedIcon: ElementRef;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.count) {
      if(this.staticMode === false) {
        this.mode = this.count === undefined ? ProgressModeEnum.INDETERMINATE : ProgressModeEnum.DETERMINATE;
      }

      this.percentage = Math.min(Math.ceil((this.count / this.total) * 100), 100);
      this.invertedPercentage = 100 - this.percentage;
    }
  }
}
