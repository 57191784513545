import { groupArrayByKeys } from '@campus/utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EduContentBookInterface, MethodInterface, YearInterface } from '../../+models';
import {
  getAll as getAllEduContentBooks,
  getById as getByIdEduContentBooks,
} from '../edu-content-book/edu-content-book.selectors';
import { State as LearningAreaState } from '../learning-area/learning-area.reducer';
import { selectLearningAreaState } from '../learning-area/learning-area.selectors';
import { YearQueries } from '../year';
import { State as YearState } from '../year/year.reducer';
import { selectYearState } from '../year/year.selectors';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './ink-method.reducer';

export const selectInkMethodState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectInkMethodState, (state: State) => state.error);

export const getLoaded = createSelector(selectInkMethodState, (state: State) => state.loaded);

export const getAll = createSelector(selectInkMethodState, selectAll);

export const getAllOrderedByName = createSelector(selectInkMethodState, (state: State) =>
  Object.values(state.entities).sort((a, b) => a.name.localeCompare(b.name))
);

export const getCount = createSelector(selectInkMethodState, selectTotal);

export const getIds = createSelector(selectInkMethodState, selectIds);

export const getAllEntities = createSelector(selectInkMethodState, selectEntities);

/**
 * Utility to return all entities for the provided ids
 *
 * @param {State} state The inkMethod state
 * @param {number[]} ids The ids of the entities you want
 * @returns {MethodInterface[]}
 */
const getMethodsById = (state: State, ids: number[]): MethodInterface[] => ids.map((id) => state.entities[id]);

/**
 * returns array of objects in the order of the given ids
 * @example
 * inkMethod$: MethodInterface[] = this.store.pipe(
    select(MethodQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectInkMethodState, (state: State, props: { ids: number[] }) => {
  return getMethodsById(state, props.ids);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * inkMethod$: MethodInterface = this.store.pipe(
    select(MethodQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectInkMethodState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

/**
 * returns array of objects filtered by learning area id as key
 */
export const getForLearningAreaId = createSelector(
  selectInkMethodState,
  (state: State, props: { learningAreaId: number }) => {
    return Object.values(state.entities).filter((inkMethod) => inkMethod.learningAreaId === props.learningAreaId);
  }
);

/**
 * returns array of objects filtered by learning area ids as key
 */
export const getForLearningAreaIds = createSelector(
  selectInkMethodState,
  (state: State, props: { learningAreaIds: number[] }) => {
    return Object.values(state.entities).filter((inkMethod) =>
      props.learningAreaIds.some((learningAreaId) => inkMethod.learningAreaId === learningAreaId)
    );
  }
);

export const getByLearningAreaId = createSelector(getAll, (methods: MethodInterface[]) =>
  groupArrayByKeys(methods, ['learningAreaId'])
);

/**
 * returns a string representation of the inkMethod with it's year and learning area
 * e.g. 'Katapult 1 (wiskunde)'
 */
export const getMethodWithLearningAreaAndYearByBookId = createSelector(
  selectInkMethodState,
  selectYearState,
  selectLearningAreaState,
  getByIdEduContentBooks,
  (
    methodState: State,
    yearState: YearState,
    learningAreaState: LearningAreaState,
    eduContentBook: EduContentBookInterface
  ) => {
    const inkMethod = methodState.entities[eduContentBook.methodId];
    return `${inkMethod.name} ${yearState.entities[eduContentBook.years[0].id].name.substring(1)} (${
      learningAreaState.entities[inkMethod.learningAreaId].name
    })`;
  }
);

export const getMethodLogoByBookId = createSelector(
  selectInkMethodState,
  getByIdEduContentBooks,
  (methodState: State, eduContentBook: EduContentBookInterface) => {
    if (!eduContentBook) return '';

    return methodState.entities[eduContentBook.methodId].logoUrl;
  }
);

const getYearsByMethodId = createSelector(
  getAllEduContentBooks,
  YearQueries.getAllEntities,
  (eduContentBooks, yearsDict): { [methodId: string]: YearInterface[] } => {
    const uniqueYearsDict = {};
    const methodsMap = eduContentBooks.reduce((acc, book) => {
      if (!acc[book.methodId]) {
        acc[book.methodId] = [];
        uniqueYearsDict[book.methodId] = {};
      }

      const uniqueYears = uniqueYearsDict[book.methodId];
      book.years.forEach((year) => {
        if (uniqueYears[year.id]) return;

        uniqueYears[year.id] = true;
        acc[book.methodId].push({ ...yearsDict[year.id] });
      });

      return acc;
    }, {});

    return Object.keys(methodsMap).reduce(
      (obj, key) => ({
        ...obj,
        [key]: methodsMap[key].sort((a, b) => a.name.localeCompare(b.name)),
      }),
      {}
    );
  }
);
