import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EduFileInterface } from '../../+models/EduFile.interface';
import { EduFilesActions, EduFilesActionTypes } from './edu-file.actions';

export const NAME = 'eduFiles';

export interface State extends EntityState<EduFileInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<EduFileInterface> = createEntityAdapter<EduFileInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: EduFilesActions): State {
  switch (action.type) {
    case EduFilesActionTypes.EduFilesLoaded: {
      return adapter.setAll(action.payload.eduFiles, {
        ...state,
        loaded: true,
      });
    }

    case EduFilesActionTypes.EduFilesLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case EduFilesActionTypes.AddEduFile: {
      return adapter.addOne(action.payload.eduFile, state);
    }

    case EduFilesActionTypes.AddEduFiles: {
      return adapter.addMany(action.payload.eduFiles, state);
    }

    case EduFilesActionTypes.UpdateEduFile: {
      return adapter.updateOne(action.payload.eduFile, state);
    }

    case EduFilesActionTypes.DeleteEduFile: {
      return adapter.removeOne(action.payload.id, state);
    }

    case EduFilesActionTypes.UpsertEduFile: {
      return adapter.upsertOne(action.payload.eduFile, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities } = adapter.getSelectors();
