import { animate, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'campus-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-100%)', height: 0 }),
        animate('500ms', style({ opacity: 1, transform: 'translateY(0%)', height: '*' })),
      ]),
      transition(':leave', [animate('500ms', style({ opacity: 0, transform: 'translateY(-100%)', height: 0 }))]),
    ]),
  ],
})
export class BannerComponent<T> implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() message: string;
  @Input() icon: string;
  @Input() actions: BannerAction<T>[];
  @Output() afterDismiss = new EventEmitter<{
    action: T;
    feedbackId: string;
  }>();

  private mobile: boolean;
  private subscriptions: Subscription = new Subscription();
  public htmlMessage: { title?: string; content?: string };
  @HostBinding('@slideInOut')
  public hasSlideInOutTrigger = true;

  @HostBinding('class.ui-banner--mobile')
  get isMobile() {
    return this.mobile;
  }
  constructor(private breakpointObserver: BreakpointObserver) {
    this.subscriptions.add(
      this.breakpointObserver
        .observe([Breakpoints.XSmall, Breakpoints.Small])
        .subscribe((state: BreakpointState) => (this.mobile = state.matches))
    );
  }
  ngOnInit() {
    this.htmlMessage = this.setHtmlMessage();
  }
  setHtmlMessage(): { title?: string; content?: string } {
    if (this.message) {
      const div = document.createElement('div');
      div.innerHTML = this.message;

      let title = div.firstChild.textContent;
      if (div.firstElementChild && title === div.firstElementChild.innerHTML) title = div.firstElementChild.outerHTML;
      const content = this.message.slice(title.length);

      const htmlMessage = {
        title,
        content,
      };
      return htmlMessage;
    }
  }
  onAction(action: T) {
    this.afterDismiss.next({ action, feedbackId: this.id });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

export interface BannerAction<T> {
  title: string;
  userAction: T;
}
