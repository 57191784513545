import { groupArrayByKey } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConceptInterface, CurriculumTreeConceptInterface } from '../../+models';
import { CurriculumTreeConceptQueries } from '../curriculum-tree-concept';
import { getTopicsByStrandGuid } from '../curriculum-tree/curriculum-tree.selectors';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './concept.reducer';

export const selectConceptState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectConceptState, (state: State) => state.error);

export const getLoaded = createSelector(selectConceptState, (state: State) => state.loaded);

export const getAll = createSelector(selectConceptState, selectAll);

export const getCount = createSelector(selectConceptState, selectTotal);

export const getIds = createSelector(selectConceptState, selectIds);

export const getAllEntities = createSelector(selectConceptState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * concept$: ConceptInterface[] = this.store.pipe(
    select(ConceptQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectConceptState, (state: State, props: { ids: string[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

export const getAllByGuid = createSelector(getAll, (concepts: ConceptInterface[]) => {
  return groupArrayByKey(concepts, 'guid');
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * concept$: ConceptInterface = this.store.pipe(
    select(ConceptQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectConceptState,
  (state: State, props: { id: string }) => state.entities[props.id]
);

export const getConceptsByTopicId = createSelector(
  CurriculumTreeConceptQueries.getAll,
  getAllEntities,

  (cTC, conceptDict) => {
    const cTCbyTreeId: Dictionary<CurriculumTreeConceptInterface[]> = groupArrayByKey(cTC, 'curriculumTreeId');

    return Object.entries(cTCbyTreeId).reduce((conceptsByTreeId, [treeId, conceptGuids]) => {
      conceptsByTreeId[treeId] = conceptGuids
        .map((cTConcept) => conceptDict[cTConcept.conceptId])
        .sort((a, b) => a.displayOrder - b.displayOrder);
      return conceptsByTreeId;
    }, {} as Dictionary<ConceptInterface[]>);
  }
);

export const getConceptsByStrandId = createSelector(
  getTopicsByStrandGuid,
  getConceptsByTopicId,
  (topicsByStrandGuid, conceptsByTopic) => {
    return Object.entries(topicsByStrandGuid).reduce((conceptsByStrandGuid, [strandGuid, topics]) => {
      const conceptCheck = {};
      conceptsByStrandGuid[strandGuid] = []
        .concat(...topics.map((topic) => conceptsByTopic[topic.guid]))
        .filter((concept) => {
          if (conceptCheck[concept.guid]) {
            return false;
          }
          conceptCheck[concept.guid] = true;
          return true;
        });

      return conceptsByStrandGuid;
    }, {} as Dictionary<ConceptInterface[]>);
  }
);
