import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SchoolBillingAddressInterface } from '../../+models';
import { SchoolBillingAddressActions, SchoolBillingAddressActionTypes } from './school-billing-address.actions';

export const NAME = 'schoolBillingAddresses';

export interface State extends EntityState<SchoolBillingAddressInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<SchoolBillingAddressInterface> =
  createEntityAdapter<SchoolBillingAddressInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: SchoolBillingAddressActions): State {
  switch (action.type) {
    case SchoolBillingAddressActionTypes.AddSchoolBillingAddress: {
      return adapter.addOne(action.payload.schoolBillingAddress, state);
    }

    case SchoolBillingAddressActionTypes.UpsertSchoolBillingAddress: {
      return adapter.upsertOne(action.payload.schoolBillingAddress, state);
    }

    case SchoolBillingAddressActionTypes.AddSchoolBillingAddresses: {
      return adapter.addMany(action.payload.schoolBillingAddresses, state);
    }

    case SchoolBillingAddressActionTypes.UpsertSchoolBillingAddresses: {
      return adapter.upsertMany(action.payload.schoolBillingAddresses, state);
    }

    case SchoolBillingAddressActionTypes.UpdateSchoolBillingAddress: {
      return adapter.updateOne(action.payload.schoolBillingAddress, state);
    }

    case SchoolBillingAddressActionTypes.UpdateSchoolBillingAddresses: {
      return adapter.updateMany(action.payload.schoolBillingAddresses, state);
    }

    case SchoolBillingAddressActionTypes.DeleteSchoolBillingAddress: {
      return adapter.removeOne(action.payload.id, state);
    }

    case SchoolBillingAddressActionTypes.DeleteSchoolBillingAddresses: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case SchoolBillingAddressActionTypes.SchoolBillingAddressLoaded: {
      return adapter.setAll(action.payload.schoolBillingAddresses, {
        ...state,
        loaded: true,
      });
    }

    case SchoolBillingAddressActionTypes.SchoolBillingAddressLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case SchoolBillingAddressActionTypes.ClearSchoolBillingAddress: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
