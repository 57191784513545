import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { BulkUpdateResultInfoInterface } from '../+external-interfaces/bulk-update-result-info';
import { EduContentInterface, TaskEduContentInterface, TaskEduContentTaskGoalYearLevelInterface } from '../+models';
import { TaskActiveErrorInterface } from './task.service.interface';

export const TASK_EDU_CONTENT_SERVICE_TOKEN = new InjectionToken('TaskEduContentService');

export interface TaskEduContentServiceInterface {
  getAllForUser(userId: number): Observable<TaskEduContentInterface[]>;
  remove(taskEduContentId: number): Observable<boolean>;
  removeAll(taskEduContentIds: number[]): Observable<boolean>;
  updateTaskEduContents(
    userId: number,
    update: Partial<TaskEduContentInterface>[]
  ): Observable<UpdateTaskEduContentResultInterface>;
  deleteTaskEduContents(userId: number, taskEduContentIds: number[]);
  createTaskEduContent(userId: number, taskEduContents: Partial<TaskEduContentInterface>[]);
  calculateTaskEduContentForTask(taskId: number): Observable<CalculateTaskEduContentResponseInterface>;
}

export interface UpdateTaskEduContentResultInterface
  extends BulkUpdateResultInfoInterface<TaskEduContentInterface, TaskActiveErrorInterface> {}

export interface CalculateTaskEduContentResponseInterface {
  taskEduContents: TaskEduContentInterface[];
  taskEduContentTaskGoalYearLevels: TaskEduContentTaskGoalYearLevelInterface[];
  removedTaskEduContentTaskGoalYearLevelIds: number[];
  eduContents: EduContentInterface[];
}
