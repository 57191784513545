import { BundleInterface } from './Bundle.interface';
import { ConceptInterface } from './Concept.interface';
import { EduContentInterface } from './EduContent.interface';
import { PersonInterface } from './Person.interface';
import { StudentInterface } from './Student.interface';
import { TaskInterface } from './Task.interface';
import { TaskEduContentInterface } from './TaskEduContent.interface';
import { TaskInstanceInterface } from './TaskInstance.interface';
import { UnlockedContentInterface } from './UnlockedContent.interface';
import { UnlockedCurriculumTreeInterface } from './UnlockedCurriculumTree.interface';
import { UnlockedFreePracticeInterface } from './UnlockedFreePractice.interface';

// equal to all cmi statuses + own extensions
export enum ResultStatusEnum {
  STATUS_INCOMPLETE = 'incomplete',
  STATUS_COMPLETED = 'completed',
  STATUS_PASSED = 'passed',
  STATUS_FAILED = 'failed',
  STATUS_BROWSED = 'browsed',
  STATUS_NOT_ATTEMPTED = 'not attempted',
  // own extensions:
  STATUS_OPENED = 'opened',
}

export interface ResultInterface {
  score?: number;
  time?: number;
  status?: ResultStatusEnum;
  cmi?: string;
  created?: Date;
  id?: number;
  eduContentId?: number;
  personId?: number;
  taskId?: number;
  unlockedContentId?: number;
  eduContent?: EduContentInterface;
  person?: PersonInterface | StudentInterface;
  task?: TaskInterface;
  unlockedContent?: UnlockedContentInterface;
  learningAreaId?: number;
  assignment?: string;
  taskInstanceId?: number;
  taskInstance?: TaskInstanceInterface;
  personDisplayName?: string;
  bundleId?: number;
  bundle?: BundleInterface;
  stars?: number;
  lastUpdated?: Date;
  taskEduContentId?: number;
  taskEduContent?: TaskEduContentInterface;
  unlockedFreePracticeId?: number;
  unlockedFreePractice?: UnlockedFreePracticeInterface;
  unlockedCurriculumTreeId?: number;
  unlockedCurriculumTree?: UnlockedCurriculumTreeInterface;
  conceptId?: string;
  concept?: ConceptInterface;
  isAttempted?: boolean;
  colorClass?: string;
  learnositySessionId?: string;
}

// FYI covers a Result that is based on multiple attempts
export interface BestResultInterface extends ResultInterface {
  attempts: ResultInterface[];
  attemptsSumOfTime: number;
}
