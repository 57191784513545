import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { mapTo, switchMap, take } from 'rxjs/operators';
import {
  ClassGroupServiceInterface,
  CLASS_GROUP_SERVICE_TOKEN,
  UpdateClassGroupResultInterface,
} from '../../class-group/class-group.service.interface';
import { ClassGroupQueries } from '../class-group';
import { DalState } from '../dal.state.interface';
import { EffectFeedback, EffectFeedbackActions } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { RoleQueries } from '../role';
import { SchoolRoleMappingQueries } from '../school-role-mapping';
import {
  AddSchoolRoleMappingClassGroups,
  LinkStudentRolesClassGroup,
  LinkTeacherRolesClassGroup,
  MoveStudentRolesClassGroup,
  SchoolRoleMappingClassGroupsActionTypes,
  UnlinkRolesClassGroup,
  UpsertSchoolRoleMappingClassGroups,
} from './school-role-mapping-class-group.actions';
@Injectable()
export class SchoolRoleMappingClassGroupEffects {
  linkTeacherRolesClassGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(SchoolRoleMappingClassGroupsActionTypes.LinkTeacherRolesClassGroup),
      concatLatestFrom(() => this.store.select(ClassGroupQueries.getAllEntities)),
      pessimisticUpdate({
        run: (action: LinkTeacherRolesClassGroup, classGroupDict) => {
          const { schoolRoleMappingIds, classGroupId } = action.payload;
          const { schoolId } = classGroupDict[classGroupId];

          return this.classGroupService
            .linkTeacherRolesClassGroupForSchool(schoolId, classGroupId, schoolRoleMappingIds)
            .pipe(
              switchMap((result: UpdateClassGroupResultInterface) =>
                // TODO: handle errorbag errors?
                [
                  new EffectFeedbackActions.AddEffectFeedback({
                    effectFeedback: EffectFeedback.generateSuccessFeedback(
                      this.uuid(),
                      action,
                      'Het is gelukt om de leerkracht(en) aan de klas toe te voegen.'
                    ),
                  }),
                  new AddSchoolRoleMappingClassGroups({
                    schoolRoleMappingClassGroups: result.success,
                  }),
                ]
              )
            );
        },
        onError: (action: LinkTeacherRolesClassGroup, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de leerkracht(en) aan de klas toe te voegen.'
            ),
          });
        },
      })
    )
  );

  moveStudentRolesClassGroup$ = this.getStudentRolesClassGroupEditActions(
    SchoolRoleMappingClassGroupsActionTypes.MoveStudentRolesClassGroup,
    'De leerling is verplaatst.',
    'De leerlingen zijn verplaatst.',
    'Het is niet gelukt om de leerling te verplaatsen.',
    'Het is niet gelukt om de leerlingen te verplaatsen.'
  );

  linkStudentRolesClassGroup$ = this.getStudentRolesClassGroupEditActions(
    SchoolRoleMappingClassGroupsActionTypes.LinkStudentRolesClassGroup,
    'Het klasnummer is gewijzigd.',
    'De klasnummers zijn gewijzigd.',
    'Het is niet gelukt om het klasnummer te wijzigen.',
    'Het is niet gelukt om de klasnummers te wijzigen.'
  );

  unlinkRolesClassGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(SchoolRoleMappingClassGroupsActionTypes.UnlinkRolesClassGroup),
      concatLatestFrom(() => this.store),
      optimisticUpdate({
        run: (action: UnlinkRolesClassGroup, state) => {
          const { schoolRoleMappingIds, classGroupId } = action.payload;
          if (schoolRoleMappingIds.length === 0) {
            return new AddEffectFeedback({
              effectFeedback: EffectFeedback.generateSuccessFeedback(
                this.uuid(),
                action,
                `Je hebt niemand geselecteerd om uit de klas te verwijderen.`
              ),
            });
          }
          const { schoolId, roleId } = state.schoolRoleMappings.entities[schoolRoleMappingIds[0]];
          const role = state.roles.entities[roleId];

          return this.classGroupService
            .unlinkRolesClassGroupForSchool(schoolId, classGroupId, schoolRoleMappingIds)
            .pipe(
              mapTo(
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    `Het is gelukt om de ${
                      role.name === 'student' ? 'leerling(en)' : 'leerkracht(en)'
                    } uit de klas te verwijderen.`
                  ),
                })
              )
            );
        },
        undoAction: (action: UnlinkRolesClassGroup, error: any) => {
          return this.store.pipe(
            select(SchoolRoleMappingQueries.getById, {
              id: action.payload.schoolRoleMappingIds[0],
            }),
            take(1),
            switchMap((srm) => {
              return this.store.select(RoleQueries.getById, {
                id: srm.roleId,
              });
            }),
            switchMap((role) => {
              return [
                undo(action),
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateErrorFeedback(
                    this.uuid(),
                    action,
                    `Het is niet gelukt om de ${
                      role.name === 'student' ? 'leerling(en)' : 'leerkracht(en)'
                    } uit de klas te verwijderen.`
                  ),
                }),
              ];
            })
          );
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(CLASS_GROUP_SERVICE_TOKEN)
    private classGroupService: ClassGroupServiceInterface,
    @Inject('uuid') private uuid: () => string
  ) {}

  private getStudentRolesClassGroupEditActions(
    actionType:
      | SchoolRoleMappingClassGroupsActionTypes.LinkStudentRolesClassGroup
      | SchoolRoleMappingClassGroupsActionTypes.MoveStudentRolesClassGroup,
    successFeedbackSingle: string,
    successFeedbackMultiple: string,
    errorFeedbackSingle: string,
    errorFeedbackMultiple: string
  ) {
    return createEffect(() =>
      this.actions.pipe(
        ofType(actionType),
        concatLatestFrom(() => this.store),
        pessimisticUpdate({
          run: (action: LinkStudentRolesClassGroup | MoveStudentRolesClassGroup, state) => {
            const { schoolRoleMappingData, classGroupId } = action.payload;
            const { schoolId } = state.classGroups.entities[classGroupId];

            return this.classGroupService
              .linkStudentRolesClassGroupForSchool(schoolId, classGroupId, schoolRoleMappingData)
              .pipe(
                switchMap((result: UpdateClassGroupResultInterface) =>
                  // TODO: handle errorbag errors?
                  [
                    new EffectFeedbackActions.AddEffectFeedback({
                      effectFeedback: EffectFeedback.generateSuccessFeedback(
                        this.uuid(),
                        action,
                        schoolRoleMappingData.length === 1 ? successFeedbackSingle : successFeedbackMultiple
                      ),
                    }),
                    new UpsertSchoolRoleMappingClassGroups({
                      schoolRoleMappingClassGroups: result.success,
                    }),
                  ]
                )
              );
          },
          onError: (action: LinkStudentRolesClassGroup | MoveStudentRolesClassGroup, error) => {
            return new AddEffectFeedback({
              effectFeedback: EffectFeedback.generateErrorFeedback(
                this.uuid(),
                action,
                action.payload.schoolRoleMappingData.length === 1 ? errorFeedbackSingle : errorFeedbackMultiple
              ),
            });
          },
        })
      )
    );
  }
}
