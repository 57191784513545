import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { OlfLearningDomainInterface } from '../../+models';
import { OlfLearningDomainsActions, OlfLearningDomainsActionTypes } from './olf-learning-domain.actions';

export const NAME = 'olfLearningDomains';

export interface State extends EntityState<OlfLearningDomainInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<OlfLearningDomainInterface> = createEntityAdapter<OlfLearningDomainInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: OlfLearningDomainsActions): State {
  switch (action.type) {
    case OlfLearningDomainsActionTypes.AddOlfLearningDomain: {
      return adapter.addOne(action.payload.olfLearningDomain, state);
    }

    case OlfLearningDomainsActionTypes.UpsertOlfLearningDomain: {
      return adapter.upsertOne(action.payload.olfLearningDomain, state);
    }

    case OlfLearningDomainsActionTypes.AddOlfLearningDomains: {
      return adapter.addMany(action.payload.olfLearningDomains, state);
    }

    case OlfLearningDomainsActionTypes.UpsertOlfLearningDomains: {
      return adapter.upsertMany(action.payload.olfLearningDomains, state);
    }

    case OlfLearningDomainsActionTypes.UpdateOlfLearningDomain: {
      return adapter.updateOne(action.payload.olfLearningDomain, state);
    }

    case OlfLearningDomainsActionTypes.UpdateOlfLearningDomains: {
      return adapter.updateMany(action.payload.olfLearningDomains, state);
    }

    case OlfLearningDomainsActionTypes.DeleteOlfLearningDomain: {
      return adapter.removeOne(action.payload.id, state);
    }

    case OlfLearningDomainsActionTypes.DeleteOlfLearningDomains: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case OlfLearningDomainsActionTypes.OlfLearningDomainsLoaded: {
      return adapter.setAll(action.payload.olfLearningDomains, {
        ...state,
        loaded: true,
      });
    }

    case OlfLearningDomainsActionTypes.OlfLearningDomainsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case OlfLearningDomainsActionTypes.ClearOlfLearningDomains: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
