import { Action } from '@ngrx/store';
import { PersonDataResponseInterface } from '../../../data/data.service.interface';

export enum DataActionTypes {
  LoadData = '[Data] Load Data',
  DataLoaded = '[Data] Data Loaded',
  DataLoadError = '[Data] Data Load Error',
}

export class DataLoadError implements Action {
  readonly type = DataActionTypes.DataLoadError;
  constructor(public payload: any) {}
}

export class LoadData implements Action {
  readonly type = DataActionTypes.LoadData;

  constructor(
    public payload: {
      userId: number;
      force?: boolean;
      fields: Array<keyof PersonDataResponseInterface>;
      fieldsSince?: Array<keyof PersonDataResponseInterface>;
      v2?: boolean;
    }
  ) {}
}

export class DataLoaded implements Action {
  readonly type = DataActionTypes.DataLoaded;

  constructor() {}
}
