import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MethodProductGroupProductInterface } from '../../+models';
import {
  MethodProductGroupProductsActions,
  MethodProductGroupProductsActionTypes,
} from './method-product-group-product.actions';

export const NAME = 'methodProductGroupProducts';

export interface State extends EntityState<MethodProductGroupProductInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<MethodProductGroupProductInterface> =
  createEntityAdapter<MethodProductGroupProductInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: MethodProductGroupProductsActions): State {
  switch (action.type) {
    case MethodProductGroupProductsActionTypes.AddMethodProductGroupProduct: {
      return adapter.addOne(action.payload.methodProductGroupProduct, state);
    }

    case MethodProductGroupProductsActionTypes.UpsertMethodProductGroupProduct: {
      return adapter.upsertOne(action.payload.methodProductGroupProduct, state);
    }

    case MethodProductGroupProductsActionTypes.AddMethodProductGroupProducts: {
      return adapter.addMany(action.payload.methodProductGroupProducts, state);
    }

    case MethodProductGroupProductsActionTypes.UpsertMethodProductGroupProducts: {
      return adapter.upsertMany(action.payload.methodProductGroupProducts, state);
    }

    case MethodProductGroupProductsActionTypes.UpdateMethodProductGroupProduct: {
      return adapter.updateOne(action.payload.methodProductGroupProduct, state);
    }

    case MethodProductGroupProductsActionTypes.UpdateMethodProductGroupProducts: {
      return adapter.updateMany(action.payload.methodProductGroupProducts, state);
    }

    case MethodProductGroupProductsActionTypes.DeleteMethodProductGroupProduct: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MethodProductGroupProductsActionTypes.DeleteMethodProductGroupProducts: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case MethodProductGroupProductsActionTypes.MethodProductGroupProductsLoaded: {
      return adapter.setAll(action.payload.methodProductGroupProducts, {
        ...state,
        loaded: true,
      });
    }

    case MethodProductGroupProductsActionTypes.MethodProductGroupProductsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case MethodProductGroupProductsActionTypes.ClearMethodProductGroupProducts: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
