import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { GoalYearQueries } from '.';
import { GoalYearServiceInterface, GOAL_YEAR_SERVICE_TOKEN } from '../../goal-year/goal-year.service.interface';
import { DalState } from '../dal.state.interface';
import {
  AddGoalYearsForLearningArea,
  GoalYearsActionTypes,
  GoalYearsLoadError,
  LoadGoalYearsForLearningArea,
} from './goal-year.actions';

@Injectable()
export class GoalYearEffects {
  loadGoalYearsForLearningArea$ = createEffect(() =>
    this.actions.pipe(
      ofType(GoalYearsActionTypes.LoadGoalYearsForLearningArea),
      concatLatestFrom(() => this.store.select(GoalYearQueries.selectGoalYearState)),
      fetch({
        run: (action: LoadGoalYearsForLearningArea, goalYearState) => {
          const { learningAreaId } = action.payload;

          if (goalYearState.loadedForLearningArea[learningAreaId]) {
            return;
          }

          return this.goalYearService.getGoalYearsForLearningArea(learningAreaId).pipe(
            map(
              (goalYears) =>
                new AddGoalYearsForLearningArea({
                  learningAreaId,
                  goalYears,
                })
            )
          );
        },
        onError: (action: LoadGoalYearsForLearningArea, error) => {
          return new GoalYearsLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(GOAL_YEAR_SERVICE_TOKEN)
    private goalYearService: GoalYearServiceInterface
  ) {}
}
