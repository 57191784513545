import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { SchoolBillingAddressInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum SchoolBillingAddressActionTypes {
  SchoolBillingAddressLoaded = '[SchoolBillingAddress] SchoolBillingAddress Loaded',
  SchoolBillingAddressLoadError = '[SchoolBillingAddress] Load Error',
  LoadSchoolBillingAddress = '[SchoolBillingAddress] Load SchoolBillingAddress',
  AddSchoolBillingAddress = '[SchoolBillingAddress] Add SchoolBillingAddress',
  StartAddSchoolBillingAddress = '[SchoolBillingAddress] Start Add SchoolBillingAddress',
  UpsertSchoolBillingAddress = '[SchoolBillingAddress] Upsert SchoolBillingAddress',
  AddSchoolBillingAddresses = '[SchoolBillingAddress] Add SchoolBillingAddresses',
  UpsertSchoolBillingAddresses = '[SchoolBillingAddress] Upsert SchoolBillingAddresses',
  UpdateSchoolBillingAddresses = '[SchoolBillingAddress] Update SchoolBillingAddresses',
  UpdateSchoolBillingAddress = '[SchoolBillingAddress] Update SchoolBillingAddress',
  DeleteSchoolBillingAddress = '[SchoolBillingAddress] Delete SchoolBillingAddress',
  DeleteSchoolBillingAddresses = '[SchoolBillingAddress] Delete SchoolBillingAddresses',
  ClearSchoolBillingAddress = '[SchoolBillingAddress] Clear SchoolBillingAddress',
}

export class LoadSchoolBillingAddress implements Action {
  readonly type = SchoolBillingAddressActionTypes.LoadSchoolBillingAddress;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class SchoolBillingAddressLoaded implements Action {
  readonly type = SchoolBillingAddressActionTypes.SchoolBillingAddressLoaded;

  constructor(public payload: { schoolBillingAddresses: SchoolBillingAddressInterface[] }) {}
}

export class SchoolBillingAddressLoadError implements Action {
  readonly type = SchoolBillingAddressActionTypes.SchoolBillingAddressLoadError;
  constructor(public payload: any) {}
}

export class AddSchoolBillingAddress implements Action {
  readonly type = SchoolBillingAddressActionTypes.AddSchoolBillingAddress;

  constructor(public payload: { schoolBillingAddress: SchoolBillingAddressInterface }) {}
}

export class UpsertSchoolBillingAddress implements Action {
  readonly type = SchoolBillingAddressActionTypes.UpsertSchoolBillingAddress;

  constructor(public payload: { schoolBillingAddress: SchoolBillingAddressInterface }) {}
}

export class AddSchoolBillingAddresses implements Action {
  readonly type = SchoolBillingAddressActionTypes.AddSchoolBillingAddresses;

  constructor(public payload: { schoolBillingAddresses: SchoolBillingAddressInterface[] }) {}
}

export class StartAddSchoolBillingAddress implements FeedbackTriggeringAction {
  readonly type = SchoolBillingAddressActionTypes.StartAddSchoolBillingAddress;

  constructor(
    public payload: {
      schoolBillingAddress: SchoolBillingAddressInterface;
      schoolId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpsertSchoolBillingAddresses implements Action {
  readonly type = SchoolBillingAddressActionTypes.UpsertSchoolBillingAddresses;

  constructor(public payload: { schoolBillingAddresses: SchoolBillingAddressInterface[] }) {}
}

export class UpdateSchoolBillingAddress implements FeedbackTriggeringAction {
  readonly type = SchoolBillingAddressActionTypes.UpdateSchoolBillingAddress;

  constructor(
    public payload: {
      schoolId: number;
      schoolBillingAddress: Update<SchoolBillingAddressInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpdateSchoolBillingAddresses implements Action {
  readonly type = SchoolBillingAddressActionTypes.UpdateSchoolBillingAddresses;

  constructor(
    public payload: {
      schoolBillingAddresses: Update<SchoolBillingAddressInterface>[];
    }
  ) {}
}

export class DeleteSchoolBillingAddress implements FeedbackTriggeringAction {
  readonly type = SchoolBillingAddressActionTypes.DeleteSchoolBillingAddress;

  constructor(
    public payload: {
      id: number;
      schoolId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class DeleteSchoolBillingAddresses implements Action {
  readonly type = SchoolBillingAddressActionTypes.DeleteSchoolBillingAddresses;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearSchoolBillingAddress implements Action {
  readonly type = SchoolBillingAddressActionTypes.ClearSchoolBillingAddress;
}

export type SchoolBillingAddressActions =
  | LoadSchoolBillingAddress
  | SchoolBillingAddressLoaded
  | SchoolBillingAddressLoadError
  | AddSchoolBillingAddress
  | UpsertSchoolBillingAddress
  | AddSchoolBillingAddresses
  | UpsertSchoolBillingAddresses
  | UpdateSchoolBillingAddress
  | UpdateSchoolBillingAddresses
  | DeleteSchoolBillingAddress
  | DeleteSchoolBillingAddresses
  | ClearSchoolBillingAddress;
