import { groupArrayByKey } from '@campus/utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  NAME,
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
  State,
} from './evaluation-score-list-subject.reducer';

export const selectEvaluationScoreListSubjectState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectEvaluationScoreListSubjectState, (state: State) => state.error);

export const getLoaded = createSelector(selectEvaluationScoreListSubjectState, (state: State) => state.loaded);

export const getAll = createSelector(selectEvaluationScoreListSubjectState, selectAll);

export const getCount = createSelector(selectEvaluationScoreListSubjectState, selectTotal);

export const getIds = createSelector(selectEvaluationScoreListSubjectState, selectIds);

export const getAllEntities = createSelector(selectEvaluationScoreListSubjectState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * evaluationScoreListSubject$: EvaluationScoreListSubjectInterface[] = this.store.pipe(
    select(EvaluationScoreListSubjectQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(
  selectEvaluationScoreListSubjectState,
  (state: State, props: { ids: number[] }) => {
    return props.ids.map((id) => state.entities[id]);
  }
);

/**
 * returns an EvaluationScoreListSubject with the given id
 * @example
 * evaluationScoreListSubject$: EvaluationScoreListSubjectInterface = this.store.pipe(
    select(EvaluationScoreListSubjectQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectEvaluationScoreListSubjectState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const isTaskLoaded = (props: { taskId: number }) =>
  createSelector(selectEvaluationScoreListSubjectState, (state: State) => !!state.loadedForTask[props.taskId]);

export const getByEvaluationScoreListId = createSelector(getAll, (evaluationScoreListSubjects) =>
  groupArrayByKey(evaluationScoreListSubjects, 'evaluationScoreListId')
);

export const isSaving = createSelector(selectEvaluationScoreListSubjectState, (state: State) => state.saving);
