import { inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService, DalState, EduContentBookQueries, loadAndResolveBooks } from '@campus/dal';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class ResultsEvaluationsGuard implements CanActivate {
  private authService = inject(AuthService);
  private store = inject<Store<DalState>>(Store);

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const { userId } = this.authService;
    const isAdaptiveTaskOrHasEvaluations$ = of(undefined).pipe(
      loadAndResolveBooks(this.store, userId),
      switchMap(() => this.store.pipe(select(EduContentBookQueries.getHasEvaluationsOrAdaptiveTaskEnabled)))
    );

    return isAdaptiveTaskOrHasEvaluations$.pipe(take(1));
  }
}
