import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { EvaluationScoreListSubjectInterface } from '../../+models';
import { TasksActions, TasksActionTypes } from '../task/task.actions';
import {
  EvaluationScoreListSubjectsActions,
  EvaluationScoreListSubjectsActionTypes,
} from './evaluation-score-list-subject.actions';

export const NAME = 'evaluationScoreListSubjects';

export interface State extends EntityState<EvaluationScoreListSubjectInterface> {
  // additional entities state properties
  loaded: boolean;
  loadedForTask: Dictionary<boolean>;
  saving: boolean;
  error?: any;
}

export const adapter: EntityAdapter<EvaluationScoreListSubjectInterface> =
  createEntityAdapter<EvaluationScoreListSubjectInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForTask: {},
  saving: false,
});

export function reducer(state = initialState, action: EvaluationScoreListSubjectsActions | TasksActions): State {
  switch (action.type) {
    case EvaluationScoreListSubjectsActionTypes.AddEvaluationScoreListSubject: {
      return adapter.addOne(action.payload.evaluationScoreListSubject, state);
    }

    case EvaluationScoreListSubjectsActionTypes.UpsertEvaluationScoreListSubject: {
      return adapter.upsertOne(action.payload.evaluationScoreListSubject, state);
    }

    case EvaluationScoreListSubjectsActionTypes.AddEvaluationScoreListSubjects: {
      return adapter.addMany(action.payload.evaluationScoreListSubjects, state);
    }

    case EvaluationScoreListSubjectsActionTypes.UpsertEvaluationScoreListSubjects: {
      return adapter.upsertMany(action.payload.evaluationScoreListSubjects, state);
    }

    case EvaluationScoreListSubjectsActionTypes.UpdateEvaluationScoreListSubject: {
      return adapter.updateOne(action.payload.evaluationScoreListSubject, state);
    }

    case EvaluationScoreListSubjectsActionTypes.UpdateEvaluationScoreListSubjects: {
      return adapter.updateMany(action.payload.evaluationScoreListSubjects, state);
    }

    case EvaluationScoreListSubjectsActionTypes.DeleteEvaluationScoreListSubject: {
      return adapter.removeOne(action.payload.id, state);
    }

    case EvaluationScoreListSubjectsActionTypes.DeleteEvaluationScoreListSubjects: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case EvaluationScoreListSubjectsActionTypes.EvaluationScoreListSubjectsLoaded: {
      return adapter.setAll(action.payload.evaluationScoreListSubjects, { ...state, loaded: true });
    }

    case EvaluationScoreListSubjectsActionTypes.EvaluationScoreListSubjectsLoadError: {
      return { ...state, error: action.payload, loadedForTask: {} };
    }

    case EvaluationScoreListSubjectsActionTypes.ClearEvaluationScoreListSubjects: {
      return adapter.removeAll(state);
    }

    case EvaluationScoreListSubjectsActionTypes.AddEvaluationScoreListSubjectsForTask: {
      const { evaluationScoreListSubjects, taskId } = action.payload;
      return adapter.upsertMany(evaluationScoreListSubjects, {
        ...state,
        loadedForTask: { ...state.loadedForTask, [taskId]: true },
      });
    }

    case TasksActionTypes.SetEvaluationSubjectsForTask: {
      return { ...state, saving: true };
    }

    case EvaluationScoreListSubjectsActionTypes.SetEvaluationScoreListSubjectsForScoreList: {
      const { evaluationScoreListId, evaluationScoreListSubjects } = action.payload;

      const toRemove = Object.values(state.entities)
        .filter((entity) => entity.evaluationScoreListId === evaluationScoreListId)
        .map((e) => e.id);

      const clearedState = adapter.removeMany(toRemove, state);
      const newState = adapter.addMany(evaluationScoreListSubjects, clearedState);

      return {
        ...newState,
        saving: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
