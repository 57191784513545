import { Component, ContentChild, EventEmitter, HostBinding, Input, Output, TemplateRef } from '@angular/core';
import { MatLegacySlideToggle as MatSlideToggle } from '@angular/material/legacy-slide-toggle';
import {
  MultiCheckBoxTableColumnChangeEventInterface,
  MultiCheckBoxTableItemChangeEventInterface,
  MultiCheckBoxTableItemColumnInterface,
  MultiCheckBoxTableItemInterface,
  MultiCheckBoxTableRowHeaderColumnInterface,
  MultiCheckBoxTableSubLevelInterface,
} from './multi-check-box-table.interface';

@Component({
  selector: 'campus-multi-check-box-table',
  templateUrl: './multi-check-box-table.component.html',
})
export class MultiCheckBoxTableComponent<SubLevelItemType, ItemType, ItemColumnType> {
  @Input() public selectAllForColumnEnabled = false;
  @Input() public selectAllForSubLevelEnabled = true;

  // Pay some attention to the interfaces of the inputs
  // There is some overlap in the generic types
  // Only use either subLevels or items
  @Input() public subLevels: MultiCheckBoxTableSubLevelInterface<SubLevelItemType, ItemType>[];

  @Input() public items: MultiCheckBoxTableItemInterface<ItemType>[];

  @Input() public rowHeaderColumns: MultiCheckBoxTableRowHeaderColumnInterface<ItemType>[];
  @Input() public itemColumns: MultiCheckBoxTableItemColumnInterface<ItemColumnType>[];

  @Output() public checkBoxChanged = new EventEmitter<
    MultiCheckBoxTableItemChangeEventInterface<ItemType, ItemColumnType, SubLevelItemType>
  >();

  @Output() public subLevelCheckBoxChanged = new EventEmitter<
    MultiCheckBoxTableItemChangeEventInterface<SubLevelItemType, ItemColumnType, SubLevelItemType>
  >();

  @Output() public selectAllForColumnChanged = new EventEmitter<
    MultiCheckBoxTableColumnChangeEventInterface<ItemColumnType>
  >();

  @HostBinding('class.ui-multi-check-box-table')
  isMultiCheckBoxTable = true;

  @ContentChild('row') row: TemplateRef<any> | undefined;

  public clickSubLevelToggle(
    subLevel: MultiCheckBoxTableSubLevelInterface<SubLevelItemType, ItemType>,
    itemHeader: MultiCheckBoxTableItemColumnInterface<ItemColumnType>,
    toggle: MatSlideToggle,
    isAllChecked: boolean
  ) {
    const checkBoxChange = {
      column: itemHeader.item,
      item: subLevel.item,
      subLevel: subLevel.item,
      isChecked: !toggle.checked,
      isAllChecked,
    };

    this.subLevelCheckBoxChanged.emit(checkBoxChange);
  }

  public clickSelectAllForColumn(
    itemColumn: MultiCheckBoxTableItemColumnInterface<ItemColumnType>,
    toggle: MatSlideToggle,
    isAllChecked: boolean
  ) {
    // emit event: for this column item, all row items are selected/deselected
    this.selectAllForColumnChanged.emit({
      column: itemColumn.item,
      isChecked: !toggle.checked,
      isAllChecked,
    });
  }

  public clickToggle(
    item: ItemType,
    column: ItemColumnType,
    subLevel: SubLevelItemType,
    toggle: MatSlideToggle,
    isParentChecked: boolean,
    isAllChecked: boolean
  ) {
    this.checkBoxChanged.emit({
      column,
      item,
      subLevel,
      isChecked: !toggle.checked,
      isParentChecked,
      isAllChecked,
    });
  }

  trackByKey(index, tableRowHeader: MultiCheckBoxTableRowHeaderColumnInterface<ItemType>) {
    return tableRowHeader.key;
  }
  trackByItemColumns(index, itemColumn: MultiCheckBoxTableItemColumnInterface<ItemColumnType>) {
    return itemColumn.item[itemColumn.key];
  }

  trackBySubLevel(index, subLevel: MultiCheckBoxTableSubLevelInterface<SubLevelItemType, ItemType>) {
    // add more identifiers when necessary
    // subLevel.item is a fallback to avoid errors, it won't stop rerenders
    return subLevel.item['id'] || subLevel.item['guid'] || subLevel.item;
  }

  trackByItems(index, item: MultiCheckBoxTableItemInterface<ItemType>) {
    // add more identifiers when necessary
    // item.header is a fallback to avoid errors, it won't stop rerenders
    return item.header['id'] || item.header['guid'] || item.header;
  }

  trackByRowHeaderColumns(index, rowheaderColumn: MultiCheckBoxTableRowHeaderColumnInterface<ItemType>) {
    return rowheaderColumn.key;
  }
}
