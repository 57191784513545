import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { fetch, optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { combineLatest, from, Observable } from 'rxjs';
import { map, mapTo, switchMap, take } from 'rxjs/operators';
import { LearningPlanGoalProgressQueries } from '.';
import { LearningPlanGoalProgressInterface } from '../../+models';
import {
  LearningPlanGoalProgressServiceInterface,
  LEARNING_PLAN_GOAL_PROGRESS_SERVICE_TOKEN,
} from '../../learning-plan-goal-progress';
import { DalState } from '../dal.state.interface';
import { EffectFeedback, EffectFeedbackActions } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import {
  AddLearningPlanGoalProgresses,
  BulkAddLearningPlanGoalProgresses,
  DeleteLearningPlanGoalProgress,
  DeleteLearningPlanGoalProgresses,
  LearningPlanGoalProgressesActionTypes,
  LearningPlanGoalProgressesLoaded,
  LearningPlanGoalProgressesLoadError,
  LoadLearningPlanGoalProgresses,
  StartAddLearningPlanGoalProgresses,
  StartAddManyLearningPlanGoalProgresses,
  ToggleLearningPlanGoalProgress,
} from './learning-plan-goal-progress.actions';
import { findOne } from './learning-plan-goal-progress.selectors';

@Injectable()
export class LearningPlanGoalProgressEffects {
  loadLearningPlanGoalProgresses$ = createEffect(() =>
    this.actions.pipe(
      ofType(LearningPlanGoalProgressesActionTypes.LoadLearningPlanGoalProgresses),
      concatLatestFrom(() => this.store.select(LearningPlanGoalProgressQueries.getLoaded)),
      fetch({
        run: (action: LoadLearningPlanGoalProgresses, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.learningPlanGoalProgressService.getAllForUser(action.payload.userId).pipe(
            map(
              (learningPlanGoalProgresses: LearningPlanGoalProgressInterface[]) =>
                new LearningPlanGoalProgressesLoaded({
                  learningPlanGoalProgresses,
                })
            )
          );
        },
        onError: (action: LoadLearningPlanGoalProgresses, error) => {
          return new LearningPlanGoalProgressesLoadError(error);
        },
      })
    )
  );

  toggleLearningPlanGoalProgress$ = createEffect(() =>
    this.actions.pipe(
      ofType(LearningPlanGoalProgressesActionTypes.ToggleLearningPlanGoalProgress),
      switchMap(
        (
          action: ToggleLearningPlanGoalProgress
        ): Observable<StartAddLearningPlanGoalProgresses | DeleteLearningPlanGoalProgress> => {
          const { personId, ...selectParams } = action.payload;

          return this.store.pipe(
            select(findOne, selectParams),
            take(1),
            map((learningPlanGoalProgress) => {
              if (learningPlanGoalProgress) {
                return new DeleteLearningPlanGoalProgress({
                  id: learningPlanGoalProgress.id,
                  userId: action.payload.personId,
                });
              } else {
                return new StartAddLearningPlanGoalProgresses({
                  classGroupId: action.payload.classGroupId,
                  eduContentTOCId: action.payload.eduContentTOCId,
                  userLessonId: action.payload.userLessonId,
                  eduContentBookId: action.payload.eduContentBookId,
                  learningPlanGoalIds: [action.payload.learningPlanGoalId],
                  personId: action.payload.personId,
                });
              }
            })
          );
        }
      )
    )
  );

  startAddLearningPlanGoalProgresses$ = createEffect(() =>
    this.actions.pipe(
      ofType(LearningPlanGoalProgressesActionTypes.StartAddLearningPlanGoalProgresses),
      pessimisticUpdate({
        run: (action: StartAddLearningPlanGoalProgresses) => {
          let serviceCall: Observable<LearningPlanGoalProgressInterface[]>;
          if (
            (action.payload.eduContentTOCId || action.payload.userLessonId) &&
            !(action.payload.eduContentTOCId && action.payload.userLessonId)
          ) {
            serviceCall = this.learningPlanGoalProgressService.createLearningPlanGoalProgress(
              action.payload.personId,
              action.payload.classGroupId,
              action.payload.learningPlanGoalIds,
              action.payload.eduContentBookId,
              action.payload.userLessonId,
              action.payload.eduContentTOCId
            );
          } else {
            throw new Error('Fill in either eduContentTOCId or userLessonId.');
          }

          return serviceCall.pipe(
            switchMap((learningPlanGoalProgressArray) =>
              from([
                new AddLearningPlanGoalProgresses({
                  learningPlanGoalProgresses: learningPlanGoalProgressArray,
                }),
                new AddEffectFeedback({
                  effectFeedback: new EffectFeedback({
                    id: this.uuid(),
                    triggerAction: action,
                    message:
                      action.payload.learningPlanGoalIds.length > 1
                        ? 'Leerplandoel voortgangen toegevoegd.'
                        : 'Leerplandoel voortgang toegevoegd.',
                  }),
                }),
              ])
            )
          );
        },
        onError: (action: StartAddLearningPlanGoalProgresses, error) => {
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            'Het is niet gelukt om de status van het leerplandoel aan te passen.'
          );
          const effectFeedbackAction = new EffectFeedbackActions.AddEffectFeedback({ effectFeedback });
          return effectFeedbackAction;
        },
      })
    )
  );

  deleteLearningPlanGoalProgress$ = createEffect(() =>
    this.actions.pipe(
      ofType(LearningPlanGoalProgressesActionTypes.DeleteLearningPlanGoalProgress),
      optimisticUpdate({
        run: (action: DeleteLearningPlanGoalProgress) => {
          return this.learningPlanGoalProgressService
            .deleteLearningPlanGoalProgress(action.payload.userId, action.payload.id)
            .pipe(
              mapTo(
                new EffectFeedbackActions.AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'Leerplandoel voortgang verwijderd.'
                  ),
                })
              )
            );
        },
        undoAction: (action: DeleteLearningPlanGoalProgress, error) => {
          const undoAction = undo(action);
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            'Het is niet gelukt om de status van het leerplandoel aan te passen.'
          );
          const effectFeedbackAction = new EffectFeedbackActions.AddEffectFeedback({ effectFeedback });
          return from([undoAction, effectFeedbackAction]);
        },
      })
    )
  );

  deleteLearningPlanGoalProgresses$ = createEffect(() =>
    this.actions.pipe(
      ofType(LearningPlanGoalProgressesActionTypes.DeleteLearningPlanGoalProgresses),
      optimisticUpdate({
        run: (action: DeleteLearningPlanGoalProgresses) => {
          return this.learningPlanGoalProgressService
            .deleteLearningPlanGoalProgresses(action.payload.userId, action.payload.ids)
            .pipe(
              mapTo(
                new EffectFeedbackActions.AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    action.payload.ids.length > 1
                      ? 'Leerplandoel voortgangen verwijderd.'
                      : 'Leerplandoel voortgang verwijderd.'
                  ),
                })
              )
            );
        },
        undoAction: (action: DeleteLearningPlanGoalProgresses, error) => {
          const undoAction = undo(action);
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            'Het is niet gelukt om de status van de leerplandoelen aan te passen.'
          );
          const effectFeedbackAction = new EffectFeedbackActions.AddEffectFeedback({ effectFeedback });
          return from([undoAction, effectFeedbackAction]);
        },
      })
    )
  );

  bulkAddLearningPlanGoalProgress$ = createEffect(() =>
    this.actions.pipe(
      ofType(LearningPlanGoalProgressesActionTypes.BulkAddLearningPlanGoalProgresses),
      switchMap((action: BulkAddLearningPlanGoalProgresses): Observable<StartAddLearningPlanGoalProgresses> => {
        const { learningPlanGoalIds, personId, ...selectParams } = action.payload;

        const selectStreams = learningPlanGoalIds.map((learningPlanGoalId) =>
          this.store.pipe(select(findOne, { ...selectParams, learningPlanGoalId }))
        );

        return combineLatest(selectStreams).pipe(
          take(1),
          map((results) => {
            const neededLearningPlanGoalIds = results.reduce((acc, currentResult, index) => {
              if (!currentResult) {
                acc.push(learningPlanGoalIds[index]);
              }
              return acc;
            }, [] as number[]);

            return new StartAddLearningPlanGoalProgresses({
              classGroupId: action.payload.classGroupId,
              personId: action.payload.personId,
              eduContentTOCId: action.payload.eduContentTOCId,
              eduContentBookId: action.payload.eduContentBookId,
              userLessonId: action.payload.userLessonId,
              learningPlanGoalIds: neededLearningPlanGoalIds,
            });
          })
        );
      })
    )
  );

  startAddManyLearningPlanGoalProgresses$ = createEffect(() =>
    this.actions.pipe(
      ofType(LearningPlanGoalProgressesActionTypes.StartAddManyLearningPlanGoalProgresses),
      optimisticUpdate({
        run: (action: StartAddManyLearningPlanGoalProgresses) => {
          return this.learningPlanGoalProgressService
            .createLearningPlanGoalProgresses(action.payload.personId, action.payload.learningPlanGoalProgresses)
            .pipe(
              switchMap((learningPlanGoalProgresses) => {
                return [
                  new AddLearningPlanGoalProgresses({
                    learningPlanGoalProgresses,
                  }),
                  new AddEffectFeedback({
                    effectFeedback: new EffectFeedback({
                      id: this.uuid(),
                      triggerAction: action,
                      message: 'Leerplandoel voortgang toegevoegd.',
                    }),
                  }),
                ];
              })
            );
        },
        undoAction: (action: StartAddManyLearningPlanGoalProgresses, error) => {
          const undoAction = undo(action);
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            'Het is niet gelukt om de status van de leerplandoelen aan te passen.'
          );
          const effectFeedbackAction = new EffectFeedbackActions.AddEffectFeedback({ effectFeedback });
          return from([undoAction, effectFeedbackAction]);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(LEARNING_PLAN_GOAL_PROGRESS_SERVICE_TOKEN)
    private learningPlanGoalProgressService: LearningPlanGoalProgressServiceInterface,
    @Inject('uuid') private uuid: () => string
  ) {}
}
