import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { LearningPlanAssignmentInterface, LearningPlanInterface, SpecialtyInterface } from '../+models';

export const LEARNING_PLAN_SERVICE_TOKEN = new InjectionToken('LearningPlanService');

export interface LearningPlanServiceInterface {
  getLearningPlanAssignments(
    eduNetId: number,
    yearId: number,
    schoolTypeId: number,
    learningAreaId: number
  ): Observable<Map<SpecialtyInterface, LearningPlanAssignmentInterface[]>>;

  getSpecialities(
    eduNetId: number,
    yearId: number,
    schoolTypeId: number,
    learningAreaId: number
  ): Observable<SpecialtyInterface[]>;

  getLearningPlans(methodIds: number[] | number, yearIds?: number[]): Observable<LearningPlanInterface[]>;
  getLearningPlansForBooks(eduContentBookIds: number[] | number): Observable<LearningPlanInterface[]>;
}
