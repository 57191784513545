import { ArrayFunctions } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MethodInterface, PersonProductInterface, ProductInterface } from '../../+models';
import { MethodQueries } from '../method';
import { ProductQueries } from '../product';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './person-product.reducer';

export const selectPersonProductState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectPersonProductState, (state: State) => state.error);

export const getLoaded = createSelector(selectPersonProductState, (state: State) => state.loaded);

export const getAll = createSelector(selectPersonProductState, selectAll);

export const getCount = createSelector(selectPersonProductState, selectTotal);

export const getIds = createSelector(selectPersonProductState, selectIds);

export const getAllEntities = createSelector(selectPersonProductState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * personProduct$: PersonProductInterface[] = this.store.pipe(
    select(PersonProductQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectPersonProductState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

// gets products based on the user's PersonProducts
// with method added
export const getProductsWithMethod = createSelector(
  ProductQueries.getAllEntities,
  getAll,
  MethodQueries.getAllEntities,
  (productDict, personProducts, methodDict) => _getProductsWithMethod(productDict, personProducts, methodDict)
);

export const getSelectedProductsWithMethod = createSelector(
  ProductQueries.getAllEntities,
  getAll,
  MethodQueries.getAllEntities,
  (productDict, personProducts, methodDict) =>
    _getProductsWithMethod(productDict, personProducts, methodDict, (product) => !product.isMarketingHighlight)
);

export const getMarketingHighlightProductsWithMethod = createSelector(
  ProductQueries.getAllEntities,
  getAll,
  MethodQueries.getAllEntities,
  (productDict, personProducts, methodDict) =>
    _getProductsWithMethod(productDict, personProducts, methodDict, (product) => product.isMarketingHighlight)
);

function _getProductsWithMethod(
  productDict: Dictionary<ProductInterface>,
  personProducts: PersonProductInterface[],
  methodDict: Dictionary<MethodInterface>,
  productFilter?: (product: ProductInterface) => boolean
) {
  const personProductIds = personProducts.map((pp) => pp.productId);
  const forceDisplayProductIds = Object.keys(productDict)
    .filter((key) => productDict[key].forceDisplay)
    .map((key) => +key);

  const productIds = ArrayFunctions.unique([...personProductIds, ...forceDisplayProductIds]);

  const products = productIds.reduce((acc, productId) => {
    const product = productDict[productId];
    const method = methodDict[product.methodId];

    if (productFilter && !productFilter(product)) return acc;

    acc.push({ ...product, method });

    return acc;
  }, [] as ProductInterface[]);

  return products;
}
