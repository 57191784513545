import { Action } from '@ngrx/store';
import { NewsRoleInterface } from '../../+models';

export enum NewsRolesActionTypes {
  NewsRolesLoaded = '[News Roles] News Roles Loaded',
  NewsRolesLoadError = '[News Roles] Load Error',
  AddNewsRoles = '[News Roles] Add News Roles',
  SetNewsRolesForNews = '[News Roles] Set news roles for news',
  RemoveNewsRolesForNews = '[News Roles] Remove news roles for news',
}

export class NewsRolesLoaded implements Action {
  readonly type = NewsRolesActionTypes.NewsRolesLoaded;

  constructor(public payload: { newsRoles: NewsRoleInterface[] }) {}
}

export class NewsRolesLoadError implements Action {
  readonly type = NewsRolesActionTypes.NewsRolesLoadError;
  constructor(public payload: any) {}
}

export class AddNewsRoles implements Action {
  readonly type = NewsRolesActionTypes.AddNewsRoles;
  constructor(public payload: { newsRoles: NewsRoleInterface[] }) {}
}

export class SetNewsRolesForNews implements Action {
  readonly type = NewsRolesActionTypes.SetNewsRolesForNews;
  constructor(public payload: { newsId: number; newsRoles: NewsRoleInterface[] }) {}
}

export class RemoveNewsRolesForNews implements Action {
  readonly type = NewsRolesActionTypes.RemoveNewsRolesForNews;
  constructor(public payload: { newsId: number }) {}
}

export type NewsRolesActions =
  | NewsRolesLoaded
  | NewsRolesLoadError
  | AddNewsRoles
  | SetNewsRolesForNews
  | RemoveNewsRolesForNews;
