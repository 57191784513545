/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { GroupPersonApi as SDKGroupPersonApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class GroupPersonApi extends ModelApiBase {
  private SDKGroupPersonApi = inject(SDKGroupPersonApi);

  constructor(api: PolpoApi) {
    super(api, 'GroupPersons');
  }

  linkStudents(groupId: number, studentIds: number[]): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKGroupPersonApi.linkStudents(...arguments);
  }

  linkGroups(personId: number, groupIds: number[]): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKGroupPersonApi.linkGroups(...arguments);
  }
}
