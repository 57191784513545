import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ArrayFunctions } from '@campus/utils';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { SearchFilterComponentInterface } from '../../interfaces/search-filter-component-interface';
import {
  SearchFilterCriteriaInterface,
  SearchFilterCriteriaValuesInterface,
} from '../../interfaces/search-filter-criteria.interface';

interface ButtonOption {
  value: SearchFilterCriteriaValuesInterface;
  viewValue: string;
  tooltip: string;
}

@Component({
  selector: 'campus-button-toggle-filter',
  templateUrl: './button-toggle-filter.component.html',
  styleUrls: ['./button-toggle-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonToggleFilterComponent implements SearchFilterComponentInterface, OnInit, OnDestroy {
  criteria: SearchFilterCriteriaInterface;
  options: ButtonOption[];
  toggleControl: UntypedFormControl = new UntypedFormControl();

  private subscriptions: Subscription = new Subscription();

  @HostBinding('class.button-toggle-filter-component')
  isButtonToggleFilterComponentClass = true;

  @Input() multiple = false;
  @Input() disabled = false;
  @Input()
  public set filterCriteria(criteria: SearchFilterCriteriaInterface) {
    this.criteria = criteria;
    this.options = this.criteriaToOptions(criteria);
    const selection = this.options.filter((option) => option.value.selected).map((option) => option.value);

    this.updateView(selection);
  }
  public get filterCriteria() {
    return this.criteria;
  }

  @Input()
  public set filterOptions(value: any) {
    if (value && value.multiple !== undefined) {
      this.multiple = value.multiple;
    }
  }

  @Output() filterSelectionChange: EventEmitter<SearchFilterCriteriaInterface[]> = new EventEmitter();

  constructor(@Inject(ChangeDetectorRef) private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.subscriptions.add(
      this.toggleControl.valueChanges
        .pipe(
          startWith(this.toggleControl.value),
          distinctUntilChanged((a, b) => ArrayFunctions.getArrayEquality(a, b))
        )
        .subscribe((selection: SearchFilterCriteriaValuesInterface | SearchFilterCriteriaValuesInterface[]): void => {
          if (!Array.isArray(selection)) {
            selection = selection === null ? [] : [selection];
          }
          this.updateView(selection);
          this.updateCriteriaWithSelected(this.criteria.values, selection);
          this.filterSelectionChange.emit([this.criteria]);
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public reset(emit = true) {
    this.updateView([], emit);
    this.updateCriteriaWithSelected(this.criteria.values, []);
    this.cd.markForCheck();
  }

  private criteriaToOptions(criteria: SearchFilterCriteriaInterface): ButtonOption[] {
    return criteria.values
      .filter((value) => value.visible)
      .map((value: SearchFilterCriteriaValuesInterface): ButtonOption => {
        const viewValue = value.data[criteria.displayProperty];
        const tooltip = this.getToolTip(value);
        return { value, viewValue, tooltip };
      });
  }

  private updateView(selection: SearchFilterCriteriaValuesInterface[], emitEvent = true): void {
    if (this.multiple) {
      this.toggleControl.setValue(selection, { emitEvent });
    } else {
      this.toggleControl.setValue(selection[0] || null, { emitEvent });
    }
  }

  private updateCriteriaWithSelected(
    values: SearchFilterCriteriaValuesInterface[],
    selection: SearchFilterCriteriaValuesInterface[]
  ): void {
    // uncheck everything
    values.forEach((value) => {
      value.selected = false;
    });
    // then check selected
    selection.forEach((selected) => {
      selected.selected = true;
    });
  }

  private getToolTip(value: SearchFilterCriteriaValuesInterface): string {
    if (value.data.tooltip) {
      return value.data.tooltip;
    } else if (value.prediction === undefined) {
      return '';
    } else {
      if (value.prediction === 1) {
        return '1 resultaat';
      } else {
        return value.prediction + ' resultaten';
      }
    }
  }
}
