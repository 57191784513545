import { inject, Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { DalState } from '../dal.state.interface';


@Injectable()
export class EvaluationScoreListSubjectEffects {


  actions = inject(Actions);
  store =  inject(Store<DalState>);



}
