import { InjectionToken } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Observable } from 'rxjs';
import {
  EduContentBookInterface,
  EduContentTOCEduContentInterface,
  EduContentTOCEduContentMetadataInterface,
  EduContentTOCInterface,
  EvaluationWithSubjectsInterface,
} from '../+models';

export const EDU_CONTENT_TOC_SERVICE_TOKEN = new InjectionToken('EduContentTocService');

export interface EduContentTocServiceInterface {
  /**
   * Do not use for Kabas, currently only used in toc-filter.factory.ts (POLPO)
   */
  getTree(bookId: number): Observable<EduContentTOCInterface[]>;

  /**
   * Do not use for Kabas, currently only used LoadEduContentBooksForMethod > ProductDetailResolver (INK)
   */
  getBooksByMethodIds(methodIds: number[]): Observable<EduContentBookInterface[]>;
  /**
   * Do not use for Kabas, currently only used LoadEduContentBooksFromIds > ProductDetailResolver (INK)
   */
  getBooksByIds(bookIds: number[]): Observable<EduContentBookInterface[]>;
  getEduContentTocEduContentForBookId(bookId: number): Observable<EduContentTOCEduContentInterface[]>;
  getEduContentTocsForBook(bookId: number): Observable<EduContentTOCInterface[]>;

  addEvaluation(eduContentTOCId: number): Observable<EvaluationWithSubjectsInterface>;
  deleteEvaluation(eduContentTOCId: number): Observable<boolean>;
  moveEvaluation(oldEduContentTOCId: number, newEduContentTOCId: number): Observable<EvaluationWithSubjectsInterface>;
  updateEduContentToc(
    eduContentTOCId: number,
    eduContentTOC: Partial<EduContentTOCInterface>
  ): Observable<EduContentTOCInterface>;
  updateEduContentMetadataDisplayOrder(
    eduContentTOCId: number,
    displayOrderByEduContentMetadataId: Dictionary<number>
  ): Observable<EduContentTOCEduContentMetadataInterface[]>;
}
