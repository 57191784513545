import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { EvaluationSubjectGoalQueries } from '.';
import { EvaluationSubjectGoalService, EVALUATION_SUBJECT_GOAL_SERVICE_TOKEN } from '../../evaluation-subject-goal';
import { DalState } from '../dal.state.interface';
import {
  AddEvaluationSubjectGoalsForBook,
  EvaluationSubjectGoalsActionTypes,
  EvaluationSubjectGoalsLoadError,
  LoadEvaluationSubjectGoalsForBook,
} from './evaluation-subject-goal.actions';

@Injectable()
export class EvaluationSubjectGoalEffects {
  loadEvaluationSubjectGoalsForBook$ = createEffect(() =>
    this.actions.pipe(
      ofType(EvaluationSubjectGoalsActionTypes.LoadEvaluationSubjectGoalsForBook),
      concatLatestFrom(() => this.store.select(EvaluationSubjectGoalQueries.selectEvaluationSubjectGoalState)),
      fetch({
        run: (action: LoadEvaluationSubjectGoalsForBook, evalationSubjectGoalState) => {
          const { bookId } = action.payload;

          if (evalationSubjectGoalState.loadedForBook[bookId]) return;

          return this.evaluationSubjectGoalService.getEvaluationSubjectGoalsForBook(bookId).pipe(
            map(
              (evaluationSubjectGoals) =>
                new AddEvaluationSubjectGoalsForBook({
                  bookId,
                  evaluationSubjectGoals,
                })
            )
          );
        },
        onError: (action: LoadEvaluationSubjectGoalsForBook, error) => {
          return new EvaluationSubjectGoalsLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(EVALUATION_SUBJECT_GOAL_SERVICE_TOKEN)
    private evaluationSubjectGoalService: EvaluationSubjectGoalService
  ) {}
}
