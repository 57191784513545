import { Action } from '@ngrx/store';
import { MethodStyleInterface } from '../../+models';

export enum MethodStylesActionTypes {
  LoadMethodStyles = '[MethodStyles] Load MethodStyles',
  MethodStylesLoaded = '[MethodStyles] MethodStyles Loaded',
  MethodStylesLoadError = '[MethodStyles] Load Error',
  AddMethodStyleForBook = '[MethodStyles] Add MethodStyle For Book',
}

export class LoadMethodStyles implements Action {
  readonly type = MethodStylesActionTypes.LoadMethodStyles;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class MethodStylesLoaded implements Action {
  readonly type = MethodStylesActionTypes.MethodStylesLoaded;

  constructor(public payload: { methodStyles: MethodStyleInterface[] }) {}
}

export class MethodStylesLoadError implements Action {
  readonly type = MethodStylesActionTypes.MethodStylesLoadError;
  constructor(public payload: any) {}
}

export class AddMethodStyleForBook implements Action {
  readonly type = MethodStylesActionTypes.AddMethodStyleForBook;

  constructor(public payload: { methodStyle: MethodStyleInterface; bookId: number }) {}
}

export type MethodStylesActions = LoadMethodStyles | MethodStylesLoaded | MethodStylesLoadError | AddMethodStyleForBook;
