import { StudentContentStatusInterface } from './StudentContentStatus.interface';

export enum ContentStatusLabel {
  NEW = 'Nieuw',
  PENDING = 'Gestart',
  FINISHED = 'Klaar',
}

export enum ContentStatusEnum {
  FINISHED = 1,
  PENDING = 2,
}

export interface ContentStatusInterface {
  label: ContentStatusLabel | string;
  id?: number;
  studentContentStatuses?: StudentContentStatusInterface[];
}
