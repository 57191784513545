/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { PersonApi as SDKPersonApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable, of } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import {
  AlertQueueInterface,
  BundleInterface,
  ContextInterface,
  FavoriteInterface,
  LearningPlanGoalInterface,
  LearningPlanGoalProgressInterface,
  MethodGoalInterface,
  PersonInterface,
  PersonPreferenceInterface,
  RealmInterface,
  ResultInterface,
  SchoolRoleMappingInterface,
  StudentContentStatusInterface,
  TaskInterface,
  UnlockedFreePracticeInterface,
  UserContentInterface,
  UserLessonInterface,
} from '../../+models';
import {
  LearningPlanGoalProgressWithEduContentTocInterface,
  LearningPlanGoalProgressWithUserLessonInterface,
} from '../../+state/learning-plan-goal-progress/learning-plan-goal-progress.actions';
import { PersonDataResponseInterface } from '../../data/data.service.interface';
import { LoginCredentials, PersonalCodeCredentials, ValidateTokenResponse } from '../../persons';
import { ApiAuth, Config } from '../internals';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class PersonApi extends ModelApiBase {
  private SDKPersonApi = inject(SDKPersonApi);

  constructor(api: PolpoApi, private authApi: ApiAuth) {
    super(api, 'People');
  }

  public getData(personId: number, fields?: string | string[], since?: number | Date) {
    const path = `${personId}/data`;

    if (Array.isArray(fields)) fields = fields.join(',');
    if (since instanceof Date) since = +since;

    const params = this._getHttpParams({
      fields,
      since,
    });

    return this.get<PersonDataResponseInterface>(path, params);
  }

  public setAlertRead(personId: number, alertId: number | number[], read?: boolean, intended?: boolean) {
    const path = `${personId}/set-alert-read`;

    const params = this._getHttpParams({
      alertId,
      read,
      intended,
    });

    return this.put<PersonDataResponseInterface>(path, params);
  }

  public destroyByIdAlertQueues(personId: number, alertQueueId: number) {
    const path = `${personId}/alertQueues/${alertQueueId}`;

    return this.delete<{ count: number }>(path);
  }

  public createBundles(personId: number, bundle: BundleInterface) {
    const path = `${personId}/bundles`;

    const body = bundle;
    return this.post(path, undefined, body);
  }

  public shareBundle(personId: number, bundleId: number, teacherIds: number[], message?: string) {
    const path = `${personId}/share-bundle/${bundleId}`;

    const body = { teacherIds, message };
    return this.post(path, undefined, body);
  }

  public createUserContents(personId: number, userContent: UserContentInterface) {
    const path = `${personId}/userContents`;

    const body = userContent;
    return this.post(path, undefined, body);
  }

  public getFavorites(personId: number) {
    const path = `${personId}/favorites`;

    return this.get<FavoriteInterface[]>(path);
  }

  public createFavorites(personId: number, favorite: FavoriteInterface) {
    const path = `${personId}/favorites`;

    const body = favorite;
    return this.post<FavoriteInterface>(path, undefined, body);
  }

  public updateByIdFavorites(personId: number, favoriteId: number, changes) {
    const path = `${personId}/favorites/${favoriteId}`;

    const body = changes;
    return this.patch<FavoriteInterface>(path, undefined, body);
  }

  public destroyByIdFavorites(personId: number, favoriteId: number) {
    const path = `${personId}/favorites/${favoriteId}`;

    return this.delete<{ count: number }>(path);
  }

  public destroyByIdHistory(personId: number, historyId: number) {
    const path = `${personId}/history/${historyId}`;

    return this.delete<{ count: number }>(path);
  }

  public getLearningPlanGoalsForBookRemote(personId: number, bookId: number) {
    const path = `${personId}/learningPlanGoalsForBook/${bookId}`;

    return this.get<LearningPlanGoalInterface[]>(path);
  }

  public destroyByIdLearningPlanProgress(personId: number, learningPlanGoalProgressId: number) {
    const path = `${personId}/learningPlanProgress/${learningPlanGoalProgressId}`;

    return this.delete<{ count: number }>(path);
  }

  public destroyManyLearningPlanGoalProgress(personId: number, learningPlanGoalProgressIds: number[]) {
    const path = `${personId}/destroyManyLearningPlanGoalProgress`;

    const params = this._getHttpParams({
      ids: learningPlanGoalProgressIds,
    });

    return this.delete<{ count: number }>(path, params);
  }

  public createLearningPlanGoalProgress(
    personId: number,
    classGroupId: number,
    learningPlanGoalIds: number[],
    eduContentBookId: number,
    userLessonId?: number,
    eduContentTOCId?: number
  ) {
    const path = `${personId}/createLearningPlanGoalProgress`;

    const params = this._getHttpParams({
      classGroupId,
      learningPlanGoalIds,
      userLessonId: userLessonId || undefined,
      eduContentTOCId: eduContentTOCId || undefined,
      eduContentBookId,
    });

    return this.post<LearningPlanGoalProgressInterface[]>(path, params);
  }

  public createLearningPlanGoalProgresses(
    personId: number,
    learningGoalPlanProgresses: (
      | LearningPlanGoalProgressWithEduContentTocInterface
      | LearningPlanGoalProgressWithUserLessonInterface
    )[]
  ) {
    const path = `${personId}/createLearningPlanGoalProgresses`;

    // TODO doublecheck this
    // change to body ?
    const params = this._getHttpParams({
      progresses: learningGoalPlanProgresses as any,
    });

    return this.post<LearningPlanGoalProgressInterface[]>(path, params);
  }

  public getMethodGoalsForBookRemote(personId: number, bookId: number) {
    const path = `${personId}/getMethodGoalsForBook/${bookId}`;

    // TODO: really, a POST to *get* methodgoals?
    return this.post<MethodGoalInterface[]>(path);
  }

  public login(credentials: Partial<LoginCredentials>, include = 'user', rememberMe = true) {
    const path = `login`;

    const params = this._getHttpParams({
      include,
    });

    const body = { ...credentials };
    return this.post<any>(path, params, body).pipe(
      tap((response) => {
        response.ttl = parseInt(response.ttl, 10);
        response.rememberMe = rememberMe;
        this.authApi.setToken(response);
        return response;
      })
    );
  }

  public loginWithPersonalCode(credentials: PersonalCodeCredentials) {
    const path = `personal-code-login`;

    const body = credentials;
    return this.post(path, undefined, body);
  }

  public logout() {
    const path = `logout`;

    return this.post(path).pipe(
      tap(() => this.authApi.setUser(null)),
      mapTo(true),
      catchError((_) => of(true))
    );
  }

  public validateToken(personId: number, tokenId: string) {
    const path = `validate-token/${personId}/${tokenId}`;

    return this.post<ValidateTokenResponse>(path);
  }

  public destroyByIdCredentials(personId: number, credential: number) {
    const path = `${personId}/credentials/${credential}`;

    return this.delete<{ count: number }>(path);
  }

  public useAvatarFromCredential(personId: number, credential: number) {
    const path = `${personId}/avatarcredential/${credential}`;

    return this.get(path);
  }

  public linkStudentToTeacherRemote(publicKey: string) {
    const path = `link-teachers`;

    const params = this._getHttpParams({
      publicKey,
    });

    return this.put<PersonInterface[]>(path, params);
  }

  public destroyByIdTeacherStudentByStudent(personId: number, teacherStudentId: number) {
    const path = `${personId}/teacherStudentByStudent/${teacherStudentId}`;

    return this.delete<{ count: number }>(path);
  }

  public unlinkStudentsFromTeacherRemote(personId: number, studentIds: number[]) {
    const path = `${personId}/unlink-students-from-teacher`;

    const params = this._getHttpParams({
      studentIds,
    });

    return this.put(path, params);
  }

  public checkUnique(personId: number, property: string, value: any) {
    const path = `checkUnique`;

    const params = this._getHttpParams({
      property,
      value,
    });

    return this.post<{ unique: boolean }>(path, params);
  }

  public checkProfileComplete() {
    const path = `check-profile-complete`;

    return this.get(path);
  }

  public checkUniqueAdmin(email: string) {
    const path = `check-unique/admin`;

    const params = this._getHttpParams({
      email,
    });

    return this.post<{ unique: boolean; user?: PersonInterface }>(path, params);
  }

  public patchAttributes(personId: number, changedProps: Partial<PersonInterface> = {}) {
    const path = `${personId}`;

    const body = changedProps;
    return this.patch<Partial<PersonInterface>>(path, undefined, body);
  }

  public resultForReview(personId: number, resultId: number) {
    const path = `${personId}/review/result/${resultId}`;

    return this.get<ResultInterface>(path);
  }

  public resultForTask(personId: number, taskId: number, eduContentId: number) {
    const path = `${personId}/task/${taskId}/result/${eduContentId}`;

    return this.get<ResultInterface>(path);
  }

  public resultForUnlockedContent(personId: number, unlockedContentId: number, eduContentId: number) {
    const path = `${personId}/unlockedContent/${unlockedContentId}/result/${eduContentId}`;

    return this.get<ResultInterface>(path);
  }

  public resultForContext(personId: number, eduContentId: number, context: ContextInterface) {
    const path = `${personId}/context/result/${eduContentId}`;

    const params = this._getHttpParams({ context } as any);

    return this.get<ResultInterface>(path, params);
  }

  public linkSchools(personId: number, schoolIds: number[]) {
    const path = `${personId}/link-schools`;

    const body = schoolIds;
    return this.post<SchoolRoleMappingInterface[]>(path, undefined, body);
  }

  public destroyByIdSchoolRoleMapping(personId: number, schoolRoleMappingId: number) {
    const path = `${personId}/schoolRoleMapping/${schoolRoleMappingId}`;
    // Observable<boolean> ?
    return this.delete<{ count: number }>(path);
  }

  public getStudentContentStatuses(personId: number) {
    const path = `${personId}/studentContentStatuses`;

    return this.get<StudentContentStatusInterface[]>(path);
  }

  public createStudentContentStatuses(personId: number, studentContentStatus: StudentContentStatusInterface) {
    const path = `${personId}/studentContentStatuses`;

    const body = studentContentStatus;
    return this.post<StudentContentStatusInterface>(path, undefined, body);
  }

  public createTeacherTasks(personId: number, task: TaskInterface) {
    const path = `${personId}/teacherTasks`;

    const body = task;
    return this.post<TaskInterface>(path, undefined, body);
  }

  public createUnlockedFreePractices(personId: number, unlockedFreePractices: UnlockedFreePracticeInterface[]) {
    const path = `${personId}/createUnlockedFreePractices`;

    const params = this._getHttpParams({
      unlockedFreePractices,
    });

    return this.post<UnlockedFreePracticeInterface[]>(path, params);
  }

  public replaceUnlockedFreePracticesForBook(personId: number, productId: number, bookId: number, groupIds: number[]) {
    const path = `${personId}/replaceUnlockedFreePracticesForBook`;

    const body = { productId, bookId, groupIds };
    return this.post<UnlockedFreePracticeInterface[]>(path, undefined, body);
  }

  public deleteManyUnlockedFreePracticeRemote(personId: number, unlockedFreePracticeIds: number[]) {
    const path = `${personId}/deleteManyUnlockedFreePracticeRemote`;

    const params = this._getHttpParams({
      ufpIds: unlockedFreePracticeIds,
    });

    return this.delete<{ count: number }>(path, params);
  }

  public createUserLessons(personId: number, userLesson: UserLessonInterface) {
    const path = `${personId}/userLessons`;

    const body = userLesson;
    return this.post<UserLessonInterface>(path, undefined, body);
  }

  public getAccessTokens(personId: number): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKPersonApi.getAccessTokens(...arguments);
  }

  public resetPassword(credentials: { email: string }): Observable<any> {
    const path = `reset`;
    return this.post(path, undefined, credentials).pipe(mapTo(true));
  }

  public checkMultipleUniques(personId: number, property: string, values: any[]): Observable<{ nonuniques: string[] }> {
    // @ts-expect-error TS 2556
    return this.SDKPersonApi.checkMultipleUniques(...arguments);
  }

  public validatePasswordStrength(personId: number, password: string): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKPersonApi.validatePasswordStrength(...arguments);
  }

  public updateUser(personId: number, changedProps: Partial<PersonInterface>): Observable<PersonInterface> {
    // @ts-expect-error TS 2556
    return this.SDKPersonApi.updateUser(...arguments);
  }

  public updatePassword(
    personId: number,
    { password, currentPassword }: { password: string; currentPassword?: string },
    accessToken?: string
  ): Observable<any> {
    const headerFunction =
      accessToken &&
      ((headers: HttpHeaders): HttpHeaders => headers.set('Authorization', Config.getAuthPrefix() + accessToken));

    return this.SDKPersonApi.updatePassword(personId, { password, currentPassword }, headerFunction);
  }

  public getRealms(personId: number): Observable<RealmInterface[]> {
    // @ts-expect-error TS 2556
    return this.SDKPersonApi.getRealms(...arguments);
  }

  public loginWithRealm(personId: number, realm: string): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKPersonApi.loginWithRealm(...arguments);
  }

  public loginLink(personId: number): Observable<string> {
    // @ts-expect-error TS 2556
    return this.SDKPersonApi.loginLink(...arguments);
  }

  public alerts(personId: number, lastUpdateTime?: number): Observable<AlertQueueInterface[]> {
    // @ts-expect-error TS 2556;
    return this.SDKPersonApi.alerts(...arguments);
  }

  public register(email: string, password: string, userType: string): Observable<any> {
    const path = 'register';
    return this.post(path, undefined, { email, password, type: userType });
  }

  public getCredentials(personId: number): Observable<any> {
    // @ts-expect-error TS 2556;
    return this.SDKPersonApi.getCredentials(...arguments);
  }

  public saveResult(
    personId: number,
    resultId: number,
    score: number,
    time: number,
    status: string,
    cmi: string
  ): Observable<ResultInterface> {
    // @ts-expect-error TS 2556;
    return this.SDKPersonApi.saveResult(...arguments);
  }

  public resultForUnlockedFreePractice(
    personId: number,
    unlockedFreePracticeId: number,
    eduContentId: number
  ): Observable<ResultInterface> {
    // @ts-expect-error TS 2556;
    return this.SDKPersonApi.resultForUnlockedFreePractice(...arguments);
  }

  public createPersonPreferences(personId: number, personPreferences: PersonPreferenceInterface[]) {
    // @ts-expect-error TS 2556;
    return this.SDKPersonApi.createPersonPreferences(...arguments);
  }

  public getCurrent(): Observable<PersonInterface> {
    const path = `me`;

    return this.get<PersonInterface>(path).pipe(
      catchError(() => of(null)),
      tap((user) => {
        this.authApi.setUser(user);
      })
    );
  }

  public getCurrentId(): number {
    return this.authApi.getCurrentUserId();
  }
}
