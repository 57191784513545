import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { UnlockedCurriculumTreeInterface } from '../+models';

export const UNLOCKED_CURRICULUM_TREE_SERVICE_TOKEN = new InjectionToken('UnlockedCurriculumTreeServiceInterface');

export interface UnlockedCurriculumTreeServiceInterface {
  getAllForUser(userId: number): Observable<UnlockedCurriculumTreeInterface[]>;

  createUnlockedCurriculumTrees(
    userId: number,
    unlockedCurriculumTrees: UnlockedCurriculumTreeInterface[]
  ): Observable<UnlockedCurriculumTreeInterface[]>;

  deleteUnlockedCurriculumTrees(userId: number, unlockedCurriculumTreeIds: number[]): Observable<boolean>;
}
