import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { SurveyQuestionInterface } from '../../+models';

export enum SurveyQuestionsActionTypes {
  SurveyQuestionsLoaded = '[SurveyQuestions] SurveyQuestions Loaded',
  SurveyQuestionsLoadError = '[SurveyQuestions] Load Error',
  LoadSurveyQuestions = '[SurveyQuestions] Load SurveyQuestions',
  AddSurveyQuestion = '[SurveyQuestions] Add SurveyQuestion',
  UpsertSurveyQuestion = '[SurveyQuestions] Upsert SurveyQuestion',
  AddSurveyQuestions = '[SurveyQuestions] Add SurveyQuestions',
  UpsertSurveyQuestions = '[SurveyQuestions] Upsert SurveyQuestions',
  UpdateSurveyQuestion = '[SurveyQuestions] Update SurveyQuestion',
  UpdateSurveyQuestions = '[SurveyQuestions] Update SurveyQuestions',
  DeleteSurveyQuestion = '[SurveyQuestions] Delete SurveyQuestion',
  DeleteSurveyQuestions = '[SurveyQuestions] Delete SurveyQuestions',
  ClearSurveyQuestions = '[SurveyQuestions] Clear SurveyQuestions',
}

export class LoadSurveyQuestions implements Action {
  readonly type = SurveyQuestionsActionTypes.LoadSurveyQuestions;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class SurveyQuestionsLoaded implements Action {
  readonly type = SurveyQuestionsActionTypes.SurveyQuestionsLoaded;

  constructor(public payload: { surveyQuestions: SurveyQuestionInterface[] }) {}
}

export class SurveyQuestionsLoadError implements Action {
  readonly type = SurveyQuestionsActionTypes.SurveyQuestionsLoadError;
  constructor(public payload: any) {}
}

export class AddSurveyQuestion implements Action {
  readonly type = SurveyQuestionsActionTypes.AddSurveyQuestion;

  constructor(public payload: { surveyQuestion: SurveyQuestionInterface }) {}
}

export class UpsertSurveyQuestion implements Action {
  readonly type = SurveyQuestionsActionTypes.UpsertSurveyQuestion;

  constructor(public payload: { surveyQuestion: SurveyQuestionInterface }) {}
}

export class AddSurveyQuestions implements Action {
  readonly type = SurveyQuestionsActionTypes.AddSurveyQuestions;

  constructor(public payload: { surveyQuestions: SurveyQuestionInterface[] }) {}
}

export class UpsertSurveyQuestions implements Action {
  readonly type = SurveyQuestionsActionTypes.UpsertSurveyQuestions;

  constructor(public payload: { surveyQuestions: SurveyQuestionInterface[] }) {}
}

export class UpdateSurveyQuestion implements Action {
  readonly type = SurveyQuestionsActionTypes.UpdateSurveyQuestion;

  constructor(public payload: { surveyQuestion: Update<SurveyQuestionInterface> }) {}
}

export class UpdateSurveyQuestions implements Action {
  readonly type = SurveyQuestionsActionTypes.UpdateSurveyQuestions;

  constructor(public payload: { surveyQuestions: Update<SurveyQuestionInterface>[] }) {}
}

export class DeleteSurveyQuestion implements Action {
  readonly type = SurveyQuestionsActionTypes.DeleteSurveyQuestion;

  constructor(public payload: { id: number }) {}
}

export class DeleteSurveyQuestions implements Action {
  readonly type = SurveyQuestionsActionTypes.DeleteSurveyQuestions;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearSurveyQuestions implements Action {
  readonly type = SurveyQuestionsActionTypes.ClearSurveyQuestions;
}

export type SurveyQuestionsActions =
  | LoadSurveyQuestions
  | SurveyQuestionsLoaded
  | SurveyQuestionsLoadError
  | AddSurveyQuestion
  | UpsertSurveyQuestion
  | AddSurveyQuestions
  | UpsertSurveyQuestions
  | UpdateSurveyQuestions
  | UpdateSurveyQuestion
  | UpdateSurveyQuestions
  | DeleteSurveyQuestion
  | DeleteSurveyQuestions
  | ClearSurveyQuestions;
