<campus-file-icon
  data-cy="search-result-icon"
  class="search-result__file-icon"
  classModifier="product-type-zoekresultaat-algemeen-bestand"
  [icon]="data.eduContent.icon"
>
</campus-file-icon>

<div class="search-result__title ui-body-2">
  {{ data.eduContent.name
  }}{{ data.eduContent.eduNetNames.length ? ' - ' + data.eduContent.eduNetNames.join(', ') : '' }}
</div>

<div class="search-result__description ui-caption" [innerHTML]="data.eduContent.description"></div>

<ng-container *ngIf="['paper-exercise'].includes(data.eduContent.type) || data.eduContent.isExercise">
  <div data-cy="search-result-level" class="search-result__exercise-level ui-body-1"
    >{{ data.eduContent.level?.name }}
  </div>
</ng-container>

<div
  data-cy="search-result-practice"
  *ngIf="data.eduContent.publishedEduContentMetadata?.showInFreePractice"
  class="search-result__practice ui-caption"
>
  leerling
</div>

<div class="search-result__actions">
  <campus-button
    inline
    *ngFor="let action of actions"
    [matTooltip]="action.tooltip"
    campusClickStopPropagation
    (click)="onActionClick(action)"
    data-cy="search-result-action"
  >
    <campus-icon [svgIcon]="action.icon"></campus-icon>
    <span>{{ action.label }}</span>
  </campus-button>
</div>

<div
  *ngIf="data.eduContent.publishedEduContentMetadata as metadata"
  class="search-result__tocs"
  data-cy="search-result-caption"
  (click)="metadata.eduContentTOC?.length > 2 && (showMore = !showMore)"
>
  <div class="search-result__tocs__wrapper" *ngIf="metadata.eduContentTOC?.length; else bookInfo">
    <ng-container *ngFor="let tocInfo of metadata.eduContentTOC | tocInfo; index as i">
      <div class="search-result__toc-info ui-caption" data-cy="search-result-toc-info" *ngIf="showMore || i < 2">{{
        tocInfo
      }}</div>
    </ng-container>
    <campus-button inline class="search-result__tocs-toggle" *ngIf="metadata.eduContentTOC?.length > 2"
      >{{ showMore ? 'toon minder' : 'toon +' + (metadata.eduContentTOC?.length - 2) }}
    </campus-button>
  </div>

  <ng-template #bookInfo>
    <div class="search-result__book ui-caption">{{ data.eduContent.methodNames.join(', ') }}</div>
  </ng-template>
</div>
