import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ButtonCoreComponent } from '../button-core/button-core.component';

@Component({
  selector: 'campus-outline-icon-button, [outline-icon-button]',
  templateUrl: './outline-icon-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutlineIconButtonComponent extends ButtonCoreComponent {
  @HostBinding('class')
  defaultClasses = [
    ...this.coreClasses,
    'outline-icon-button',
    'relative',
    'flex',
    'p-3xs',
    'corner-full',
    'border',
    'transparent',

    'disabled:border-on-surface',
    'disabled:border-opacity-disabled',
    'disabled:icon-on-surface',
    'disabled:icon-opacity-disabled',
  ];

  @Input() icon: string;
}
