import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DalState, UiActions, UiQuery, UserActions, UserQueries } from '@campus/dal';
import {
  EnvironmentProfileInterface,
  EnvironmentTermPrivacyInterface,
  ENVIRONMENT_PROFILE_TOKEN,
  ENVIRONMENT_TERM_PRIVACY_TOKEN,
} from '@campus/environment';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMapTo } from 'rxjs/operators';

@Injectable()
export class YearTransitionGuard implements CanActivate {
  constructor(
    private store: Store<DalState>,
    private router: Router,
    @Optional() @Inject(ENVIRONMENT_PROFILE_TOKEN) private environmentProfile: EnvironmentProfileInterface,
    @Optional()
    @Inject(ENVIRONMENT_TERM_PRIVACY_TOKEN)
    private environmentTermPrivacy: EnvironmentTermPrivacyInterface
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean> | boolean {
    if (
      ['/settings/year-transition', this.environmentProfile?.url, this.environmentTermPrivacy?.url]
        .filter((url) => !!url)
        .some((url) => state.url?.startsWith(url))
    ) {
      return true;
    }

    this.store.dispatch(new UserActions.LoadUser({}));
    this.store.dispatch(new UiActions.LoadUi({}));

    const transition$ = this.store.pipe(
      select(UserQueries.getLoaded),
      filter((loaded) => !!loaded),
      switchMapTo(this.store.pipe(select(UserQueries.getCurrentUser))),
      map((user) => user.completeProfile?.checks?.transition)
    );

    const savedSoftTimeStamp$ = this.store.pipe(
      select(UiQuery.getSavedSettings),
      map((settings) => settings['softTransitionTimestamp'] as number)
    );

    return combineLatest([transition$, savedSoftTimeStamp$]).pipe(
      map(([transition, savedSoftTimeStamp]) => {
        if (transition?.check !== false) return true;

        const now = new Date();
        const timestamp = now.setHours(0, 0, 0, 0);

        const isSameDay = savedSoftTimeStamp && timestamp - savedSoftTimeStamp === 0;
        if (!isSameDay) this.store.dispatch(new UiActions.SaveSettings({ softTransitionTimestamp: timestamp }));

        const shouldRedirect = !(transition.checkInfo.requirement === 'soft' && isSameDay);
        if (!shouldRedirect) return true;

        return this.router.parseUrl('/settings/year-transition');
      })
    );
  }
}
