import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AchievementInterface } from '../../+models';
import { AchievementsActions, AchievementsActionTypes } from './achievement.actions';

export const NAME = 'achievements';

export interface State extends EntityState<AchievementInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<AchievementInterface> = createEntityAdapter<AchievementInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: AchievementsActions): State {
  switch (action.type) {
    case AchievementsActionTypes.AddAchievement: {
      return adapter.addOne(action.payload.achievement, state);
    }

    case AchievementsActionTypes.UpsertAchievement: {
      return adapter.upsertOne(action.payload.achievement, state);
    }

    case AchievementsActionTypes.AddAchievements: {
      return adapter.addMany(action.payload.achievements, state);
    }

    case AchievementsActionTypes.UpsertAchievements: {
      return adapter.upsertMany(action.payload.achievements, state);
    }

    case AchievementsActionTypes.UpdateAchievement: {
      return adapter.updateOne(action.payload.achievement, state);
    }

    case AchievementsActionTypes.UpdateAchievements: {
      return adapter.updateMany(action.payload.achievements, state);
    }

    case AchievementsActionTypes.DeleteAchievement: {
      return adapter.removeOne(action.payload.id, state);
    }

    case AchievementsActionTypes.DeleteAchievements: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case AchievementsActionTypes.AchievementsLoaded: {
      return adapter.setAll(action.payload.achievements, {
        ...state,
        loaded: true,
      });
    }

    case AchievementsActionTypes.AchievementsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case AchievementsActionTypes.ClearAchievements: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
