<campus-card [showBadge]="!!badge" data-usetiful="method-books-tile" [disabled]="disabled">
  <campus-card-image data-cy="shared-books-tile-logo"><img [src]="logoUrl" /></campus-card-image>
  <campus-card-title data-cy="shared-books-tile-title" *ngIf="title">{{ title }}</campus-card-title>
  <campus-card-badge data-cy="shared-books-tile-badge" *ngIf="badge">
    <campus-icon *ngIf="badge.icon" [svgIcon]="badge.icon" class="shared-books-tile__badge-icon"></campus-icon>
    <campus-lottie
      *ngIf="badge.lottie"
      [lottieKey]="badge.lottie"
      class="shared-books-tile__badge-lottie"
    ></campus-lottie>
    <span *ngIf="badge.label" class="ui-caption shared-books-tile__badge-label">{{ badge.label }}</span>
  </campus-card-badge>
  <campus-card-content>
    <div class="shared-books-tile__rows shared-books-tile--column">
      <div class="ui-demo-badge" *ngIf="headerIcon"
        ><campus-icon [svgIcon]="headerIcon.icon"></campus-icon> {{ headerIcon.label }}
      </div>
      <div *ngIf="rows.length; else noBooks">
        <div
          data-cy="shared-books-tile-book"
          class="[ relative flex gap-xs items-center justify-between ][ p-b-2xs p-i-s ][ state-layer-group ]"
          *ngFor="let row of rows; trackBy: trackByRowGuid"
          #rowElement
        >
          <div
            data-cy="books-tile-name"
            class="[ grow ][ title-medium line-clamp-2 ]"
            [style.cursor]="row.actions?.length ? 'default' : 'pointer'"
            [class.shared-books-tile__book-name--centered]="!row.actions?.length"
            (click)="onRowClick(row)"
            >{{ row.label }}
            <div *ngIf="row.icon" class="shared-books-tile__badge-container">
              <div [class]="row.icon.className">
                <campus-icon [svgIcon]="row.icon.icon"></campus-icon> {{ row.icon.label }}
              </div>
            </div>
          </div>

          <div data-cy="shared-books-tile-button-group" class="[ shrink-0 cluster cluster-space-3xs ]">
            <div>
              <ng-container *ngFor="let action of row.actions; trackBy: trackByRowGuid">
                <button
                  *ngIf="action.icon.icon.startsWith('favorite'); else outlineIconButton"
                  toggle-icon-button
                  class="favorite-toggle"
                  data-cy="shared-books-link"
                  [attr.data-usetiful]="'shared-books-' + action.icon.icon"
                  (click)="action.handler(row.guid, data?.id)"
                  [icon]="action.icon.icon"
                  [selected]="action.icon.className === 'is-favorite'"
                  [ngClass]="
                    action.icon.className === 'is-favorite'
                      ? 'animate-jello-horizontal icon-primary'
                      : 'hover:icon-primary'
                  "
                >
                </button>
                <ng-template #outlineIconButton>
                  <button
                    outline-icon-button
                    data-cy="shared-books-link"
                    [attr.data-usetiful]="'shared-books-' + action.icon.icon"
                    (click)="action.handler(row.guid, data?.id)"
                    [icon]="action.icon.icon"
                    class="hover:icon-primary"
                    [ngClass]="action.icon.className"
                  >
                  </button>
                </ng-template>
              </ng-container>
            </div>
          </div>

          <campus-state-layer [for]="rowElement" class="bg-primary"></campus-state-layer>
        </div>
      </div>
    </div>
  </campus-card-content>
</campus-card>

<ng-template #noBooks>
  <ng-content></ng-content>
</ng-template>
