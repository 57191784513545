import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonApi } from '../+api';
import { TaskClassGroupInterface } from '../+models';

@Injectable({
  providedIn: 'root',
})
export class TaskClassGroupService {
  constructor(private personApi: PersonApi) {}

  public getAllForUser(userId: number): Observable<TaskClassGroupInterface[]> {
    return this.personApi.getData(userId, 'taskClassGroups').pipe(
      map((res: { taskClassGroups: TaskClassGroupInterface[] }) =>
        res.taskClassGroups.map((taskClassGroup) => ({
          ...taskClassGroup,
          end: taskClassGroup.end && new Date(taskClassGroup.end),
          start: taskClassGroup.start && new Date(taskClassGroup.start),
        }))
      )
    );
  }
}
