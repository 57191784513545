import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PurchaseProductInterface } from '../../+models';
import { PurchaseProductsActions, PurchaseProductsActionTypes } from './purchase-product.actions';

export const NAME = 'purchaseProducts';

export interface State extends EntityState<PurchaseProductInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<PurchaseProductInterface> = createEntityAdapter<PurchaseProductInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: PurchaseProductsActions): State {
  switch (action.type) {
    case PurchaseProductsActionTypes.AddPurchaseProduct: {
      return adapter.addOne(action.payload.purchaseProduct, state);
    }

    case PurchaseProductsActionTypes.UpsertPurchaseProduct: {
      return adapter.upsertOne(action.payload.purchaseProduct, state);
    }

    case PurchaseProductsActionTypes.AddPurchaseProducts: {
      return adapter.addMany(action.payload.purchaseProducts, state);
    }

    case PurchaseProductsActionTypes.UpsertPurchaseProducts: {
      return adapter.upsertMany(action.payload.purchaseProducts, state);
    }

    case PurchaseProductsActionTypes.UpdatePurchaseProduct: {
      return adapter.updateOne(action.payload.purchaseProduct, state);
    }

    case PurchaseProductsActionTypes.UpdatePurchaseProducts: {
      return adapter.updateMany(action.payload.purchaseProducts, state);
    }

    case PurchaseProductsActionTypes.DeletePurchaseProduct: {
      return adapter.removeOne(action.payload.id, state);
    }

    case PurchaseProductsActionTypes.DeletePurchaseProducts: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case PurchaseProductsActionTypes.PurchaseProductsLoaded: {
      return adapter.setAll(action.payload.purchaseProducts, {
        ...state,
        loaded: true,
      });
    }

    case PurchaseProductsActionTypes.PurchaseProductsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case PurchaseProductsActionTypes.ClearPurchaseProducts: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
