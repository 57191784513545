import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { combineLatest, from, Observable } from 'rxjs';
import { map, mapTo, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { SchoolInterface, SchoolRoleMappingClassGroupInterface, SchoolRoleMappingInterface } from '../../+models';
import { SchoolServiceInterface, SCHOOL_SERVICE_TOKEN } from '../../school';
import { ClassGroupActions, ClassGroupQueries } from '../class-group';
import { DalState } from '../dal.state.interface';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { SchoolRoleMappingActions, SchoolRoleMappingQueries } from '../school-role-mapping';
import {
  SchoolRoleMappingClassGroupActions,
  SchoolRoleMappingClassGroupQueries,
} from '../school-role-mapping-class-group';
import { DeleteSchoolRoleMappings, UpsertSchoolRoleMappings } from '../school-role-mapping/school-role-mapping.actions';
import { UserActions } from '../user';
import {
  AddSchool,
  AddSchools,
  DeleteSchool,
  LinkSchoolsToTeacher,
  NavigateToSchoolDetail,
  NavigateToSchoolOverview,
  SchoolsActionTypes,
  StartAddAndLinkSchool,
  StartAddSchool,
  StartDeleteSchool,
  UnlinkStudentsInSchool,
  UnlinkTeacher,
  UpdateSchool,
  UpdateTransition,
} from './school.actions';

@Injectable()
export class SchoolEffects {
  unlinkTeacher$ = createEffect(() =>
    this.actions.pipe(
      ofType(SchoolsActionTypes.UnlinkTeacher),
      optimisticUpdate({
        run: (action: UnlinkTeacher) => {
          return this.schoolService
            .unlinkTeacher(action.payload.personId, action.payload.schoolRoleMappingId)
            .pipe(mapTo(new UserActions.StartUpdateCompleteProfile()));
        },
        undoAction: (action: UnlinkTeacher, error) => {
          const undoAction = undo(action);
          const effectFeedback = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de school te ontkoppelen.'
            ),
          });
          return from([undoAction, effectFeedback]);
        },
      })
    )
  );

  linkSchoolsToTeacher$ = createEffect(() =>
    this.actions.pipe(
      ofType(SchoolsActionTypes.LinkSchoolsToTeacher),
      pessimisticUpdate({
        run: (action: LinkSchoolsToTeacher) => {
          return this.schoolService
            .linkSchoolsToTeacher(
              action.payload.personId,
              action.payload.schools.map((school) => school.id)
            )
            .pipe(
              switchMap((schoolRoleMappings) => {
                return from([
                  new AddEffectFeedback({
                    effectFeedback: EffectFeedback.generateSuccessFeedback(this.uuid(), action, 'Scholen gekoppeld.'),
                  }),
                  new AddSchools({
                    schools: action.payload.schools,
                  }),
                  new UpsertSchoolRoleMappings({
                    schoolRoleMappings,
                  }),
                  new UserActions.StartUpdateCompleteProfile(),
                ]);
              })
            );
        },
        onError: (action: LinkSchoolsToTeacher, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de school te koppelen.'
            ),
          });
        },
      })
    )
  );

  startAddSchool$ = createEffect(() =>
    this.actions.pipe(
      ofType(SchoolsActionTypes.StartAddSchool),
      pessimisticUpdate({
        run: (action: StartAddSchool) => {
          return this.schoolService.createSchool(action.payload.school).pipe(
            switchMap((school: SchoolInterface) => {
              const actionsToDispatch: Action[] = [new AddSchool({ school })];
              if (action.payload.navigateAfterCreate) {
                actionsToDispatch.push(new NavigateToSchoolDetail({ school }));
              }
              return from(actionsToDispatch);
            })
          );
        },
        onError: (action: StartAddSchool, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de school toe te voegen.'
            ),
          });
        },
      })
    )
  );

  startDeleteSchool$ = createEffect(() =>
    this.actions.pipe(
      ofType(SchoolsActionTypes.StartDeleteSchool),
      pessimisticUpdate({
        run: (action: StartDeleteSchool) => {
          return this.schoolService.removeSchool(action.payload.schoolId).pipe(
            switchMap((bool) => this.getDeleteSchoolActions(action.payload.schoolId)),
            switchMap((removeActions) => {
              if (action.payload.navigateAfterDelete) {
                removeActions.push(new NavigateToSchoolOverview());
              }
              return from([
                ...removeActions,
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'De school werd verwijderd.'
                  ),
                }),
              ]);
            })
          );
        },
        onError: (action: StartDeleteSchool, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de school te verwijderen.'
            ),
          });
        },
      })
    )
  );

  updateSchool$ = createEffect(() =>
    this.actions.pipe(
      ofType(SchoolsActionTypes.UpdateSchool),
      optimisticUpdate({
        run: (action: UpdateSchool) => {
          const { id, changes } = action.payload.school;
          return this.schoolService.updateSchool(+id, changes).pipe(
            map(() => {
              return new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'De school werd bijgewerkt.'
                ),
              });
            })
          );
        },
        undoAction: (action: UpdateSchool, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de school bij te werken.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  updateTransition$ = createEffect(() =>
    this.actions.pipe(
      ofType(SchoolsActionTypes.UpdateTransition),
      optimisticUpdate({
        run: (action: UpdateTransition) => {
          const { schoolId, type } = action.payload;
          return this.schoolService.updateTransition(schoolId, type).pipe(
            map((school) => {
              if (school.yearTransition?.[type] !== false) {
                throw new Error(`yearTransition.${type} should be false`);
              }
              return new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'De jaarovergang is voltooid.'
                ),
              });
            })
          );
        },
        undoAction: (action: UpdateTransition, error) => {
          const { type } = action.payload;
          let typeLabel = '';
          switch (type) {
            case 'students':
              typeLabel = 'leerlingen';
              break;
            case 'licenses':
              typeLabel = 'licenties';
              break;
          }
          const errorMessage = `Het is niet gelukt om de jaarovergang voor ${typeLabel} te voltooien.`;
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(this.uuid(), action, errorMessage),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  redirectToSchool$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(SchoolsActionTypes.NavigateToSchoolDetail),
        tap((action: NavigateToSchoolDetail) => {
          this.router.navigate(['schools', action.payload.school.id]);
        })
      ),
    { dispatch: false }
  );

  redirectToSchoolOverview$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(SchoolsActionTypes.NavigateToSchoolOverview),
        tap((action: NavigateToSchoolOverview) => {
          this.router.navigate(['schools']);
        })
      ),
    { dispatch: false }
  );

  startAddAndLinkSchool$ = createEffect(() =>
    this.actions.pipe(
      ofType(SchoolsActionTypes.StartAddAndLinkSchool),
      pessimisticUpdate({
        run: (action: StartAddAndLinkSchool) => {
          const { personId, school } = action.payload;
          return this.schoolService
            .create(school)
            .pipe(map((newSchool: SchoolInterface) => new LinkSchoolsToTeacher({ personId, schools: [newSchool] })));
        },
        onError: (action: StartAddAndLinkSchool, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de school toe te voegen.'
            ),
          });
        },
      })
    )
  );

  unlinkStudentsInSchool$ = createEffect(() =>
    this.actions.pipe(
      ofType(SchoolsActionTypes.UnlinkStudentsInSchool),
      optimisticUpdate({
        run: (action: UnlinkStudentsInSchool) => {
          const { schoolId } = action.payload;

          return this.schoolService.unlinkStudentsInSchool(schoolId).pipe(
            withLatestFrom(
              this.getDeleteSchoolActions(schoolId).pipe(
                // we only need the schoolRoleMappingClassGroup action when unlinking
                map((deleteSchoolActions) =>
                  deleteSchoolActions.filter(
                    (action) => action instanceof SchoolRoleMappingClassGroupActions.DeleteSchoolRoleMappingClassGroups
                  )
                )
              )
            ),
            switchMap(([schoolRoleMappings, unlinkStudentActions]) => {
              const actions: Action[] = [
                new SchoolRoleMappingActions.UpsertSchoolRoleMappings({ schoolRoleMappings }),
                ...unlinkStudentActions,
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    `Alle leerlingen zijn losgekoppeld.`
                  ),
                }),
              ];
              return from(actions);
            })
          );
        },
        undoAction: (action: UnlinkStudentsInSchool, error: any) => {
          return from([
            undo(action),
            new AddEffectFeedback({
              effectFeedback: EffectFeedback.generateErrorFeedback(
                this.uuid(),
                action,
                `Het is niet gelukt om de leerlingen los te koppelen.`
              ),
            }),
          ]);
        },
      })
    )
  );

  private getDeleteSchoolActions(schoolId: number): Observable<Action[]> {
    return combineLatest([
      this.store.pipe(select(ClassGroupQueries.getClassGroupsBySchoolIdById)),
      this.store.pipe(select(SchoolRoleMappingQueries.getBySchoolId)),
      this.store.pipe(select(SchoolRoleMappingClassGroupQueries.getBySchoolRoleMappingId)),
    ]).pipe(
      take(1),
      map(([classGroupsBySchoolIdById, srmsBySchoolId, srmClassGroupsByRoleMappingId]) => {
        const actions: Action[] = [new DeleteSchool({ id: schoolId })];
        const { classGroupsIds, schoolRoleMappingIds, schoolRoleMappingClassGroups } = this.getProps(
          schoolId,
          classGroupsBySchoolIdById,
          srmsBySchoolId,
          srmClassGroupsByRoleMappingId
        );

        actions.push(
          new ClassGroupActions.DeleteClassGroups({
            ids: classGroupsIds,
          })
        );

        actions.push(
          new DeleteSchoolRoleMappings({
            ids: schoolRoleMappingIds,
          })
        );

        actions.push(
          new SchoolRoleMappingClassGroupActions.DeleteSchoolRoleMappingClassGroups({
            ids: schoolRoleMappingClassGroups.map((srmcg) => srmcg.id),
          })
        );
        return actions;
      })
    );
  }

  private getProps(schoolId, classGroupsBySchoolIdById, srmsBySchoolId, srmClassGroupsByRoleMappingId) {
    const schoolRoleMappingClassGroups: SchoolRoleMappingClassGroupInterface[] = [];
    const classGroupsIds = Object.keys(classGroupsBySchoolIdById[schoolId] || {}).map((id) => +id);

    const schoolRoleMappings: SchoolRoleMappingInterface[] = srmsBySchoolId[schoolId] || [];

    const schoolRoleMappingIds = schoolRoleMappings.map((srm) => srm.id);

    schoolRoleMappingIds.forEach((srmId) => {
      schoolRoleMappingClassGroups.push(...(srmClassGroupsByRoleMappingId[srmId] || []));
    });

    return {
      classGroupsIds,
      schoolRoleMappingIds,
      schoolRoleMappingClassGroups,
    };
  }

  constructor(
    @Inject('uuid')
    private uuid,
    private actions: Actions,
    @Inject(SCHOOL_SERVICE_TOKEN)
    private schoolService: SchoolServiceInterface,
    private store: Store<DalState>,
    private router: Router
  ) {}
}
