import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import {
  GroupInterface,
  GroupPersonInterface,
  PerRole,
  PersonInterface,
  SchoolAdminInterface,
  SchoolRoleMappingClassGroupInterface,
  SchoolRoleMappingInterface,
  StudentInterface,
  TeacherInterface,
} from '../../+models';
import { State } from '../class-group/class-group.reducer';
import { selectClassGroupState } from '../class-group/class-group.selectors';
import { GroupPersonQueries } from '../group-person';
import { selectGroupState } from '../group/group.selectors';
import { RoleQueries } from '../role';
import { SchoolRoleMappingQueries } from '../school-role-mapping';
import { SchoolRoleMappingClassGroupQueries } from '../school-role-mapping-class-group';
import { selectLinkedPersonState } from './linked-person.selectors';

export const getStudents: MemoizedSelector<
  object,
  StudentInterface[],
  DefaultProjectorFn<StudentInterface[]>
> = getPersonRoles('student', toStudent);

export const getTeachers: MemoizedSelector<
  object,
  TeacherInterface[],
  DefaultProjectorFn<TeacherInterface[]>
> = getPersonRoles('teacher', toTeacher);

export const getSchoolAdmins: MemoizedSelector<
  object,
  SchoolAdminInterface[],
  DefaultProjectorFn<SchoolAdminInterface[]>
> = getPersonRoles('schooladmin', toSchoolAdmin);

export const getStudentsByClassGroupId = createSelector(getStudents, (students: StudentInterface[]) =>
  groupByClassGroupId(students, true)
);

export const getTeachersByClassGroupId = createSelector(getTeachers, (teachers: TeacherInterface[]) =>
  groupByClassGroupId(teachers)
);

function getPersonRoles<T extends PerRole<PersonInterface>>(
  roleName: string,
  mapFn: (
    person: PersonInterface,
    schoolRoleMapping: SchoolRoleMappingInterface,
    schoolRoleMappingClassGroups: SchoolRoleMappingClassGroupInterface[],
    classGroupState: State,
    groupState: State,
    groupPersonsByPersonId: Dictionary<GroupPersonInterface[]>
  ) => T
) {
  return createSelector(
    selectLinkedPersonState,
    RoleQueries.getByName,
    SchoolRoleMappingQueries.getByRoleId,
    SchoolRoleMappingClassGroupQueries.getBySchoolRoleMappingId,
    selectClassGroupState,
    selectGroupState,
    GroupPersonQueries.getByPersonId,
    (
      linkedPersonState,
      rolesByName,
      schoolRoleMappingsByRoleId,
      schoolRoleMappingClassgroupsBySchoolRoleMappingId,
      classGroupState,
      groupState,
      groupPersonsByPersonId
    ) => {
      const roleId = rolesByName[roleName]?.id;
      const schoolRoleMappingsForRole = schoolRoleMappingsByRoleId[roleId] || [];

      const { personRoles } = schoolRoleMappingsForRole.reduce(
        (acc, sRM) => {
          if (acc.addedPersonIds.has(`${sRM.personId}-${sRM.schoolId}`)) return acc;

          const sRMCGs = schoolRoleMappingClassgroupsBySchoolRoleMappingId[sRM.id] || [];
          const person = linkedPersonState.entities[sRM.personId];
          if (!person) return acc;

          const mappedPerson = mapFn(person, sRM, sRMCGs, classGroupState, groupState, groupPersonsByPersonId);
          acc.personRoles.push(mappedPerson);
          acc.addedPersonIds.add(`${sRM.personId}-${sRM.schoolId}`);
          return acc;
        },
        { personRoles: [], addedPersonIds: new Set() }
      );

      return personRoles.filter(Boolean);
    }
  );
}

function toStudent(
  person: PersonInterface,
  schoolRoleMapping: SchoolRoleMappingInterface,
  schoolRoleMappingClassGroupsForSchoolRoleMapping: SchoolRoleMappingClassGroupInterface[],
  classGroupState: State,
  groupState: State,
  groupPersonsByPersonId: Dictionary<GroupPersonInterface[]>
): StudentInterface {
  const { id, gameCoins, rootNumber, personalCode, schoolId } = schoolRoleMapping;

  const { classGroups, classGroupClassNumberDict } = getClassGroupInfo(
    schoolRoleMappingClassGroupsForSchoolRoleMapping,
    classGroupState
  );

  const groups = getGroupInfoForStudent(person.id, groupState, groupPersonsByPersonId);

  const groupNames = [...classGroups, ...groups].map((cgg) => cgg.name);

  return {
    ...person,
    gameCoins,
    rootNumber,
    personalCode,
    schoolRoleMappingId: id,
    schoolId,
    classGroups,
    classGroupClassNumberDict,
    groups,
    groupNames,
  };
}

function toTeacher(
  person: PersonInterface,
  schoolRoleMapping: SchoolRoleMappingInterface,
  schoolRoleMappingClassGroupsForSchoolRoleMapping: SchoolRoleMappingClassGroupInterface[],
  classGroupState: State
): TeacherInterface {
  const { id, schoolId } = schoolRoleMapping;

  const { classGroups, classGroupClassNumberDict } = getClassGroupInfo(
    schoolRoleMappingClassGroupsForSchoolRoleMapping,
    classGroupState
  );

  return {
    ...person,
    email: person.email, // required for teacher, optional in person
    schoolRoleMappingId: id,
    schoolId,
    classGroups,
    classGroupClassNumberDict,
  };
}

function toSchoolAdmin(person: PersonInterface, schoolRoleMapping: SchoolRoleMappingInterface): SchoolAdminInterface {
  const { id, schoolId } = schoolRoleMapping;

  return {
    ...person,
    email: person.email, // required for schooladmin, optional in person
    schoolRoleMappingId: id,
    schoolId,
  };
}

function getClassGroupInfo(
  schoolRoleMappingClassGroups: SchoolRoleMappingClassGroupInterface[],
  classGroupState: State
) {
  return schoolRoleMappingClassGroups.reduce(
    (acc, sRMCG) => {
      acc.classGroups.push(classGroupState.entities[sRMCG.classGroupId]);
      acc.classGroupClassNumberDict[sRMCG.classGroupId] = sRMCG.classNumber;

      return acc;
    },
    { classGroups: [], classGroupClassNumberDict: {} }
  );
}

function getGroupInfoForStudent(
  personId: number,
  groupState: State,
  groupPersonsByPersonId: Dictionary<GroupPersonInterface[]>
): GroupInterface[] {
  const groupPersons = groupPersonsByPersonId[personId];
  return (groupPersons || []).map((gP) => groupState.entities[gP.groupId]);
}

function groupByClassGroupId<T extends PerRole<PersonInterface>>(personsPerRole: T[], sortByClassNumber = false) {
  const byClassGroup = personsPerRole.reduce((acc, personRole) => {
    if (personRole.classGroups.length === 0) {
      if (!acc[-1]) acc[-1] = [];
      acc[-1].push(personRole);
    }
    personRole.classGroups.forEach((cG) => {
      if (!acc[cG.id]) acc[cG.id] = [];
      acc[cG.id].push(personRole);
    });
    return acc;
  }, {} as Dictionary<T[]>);

  if (!sortByClassNumber) return byClassGroup;

  Object.keys(byClassGroup).forEach((classGroupId) => {
    byClassGroup[classGroupId].sort((a, b) => {
      const classNumberA = a.classGroupClassNumberDict?.[classGroupId] || 1000;
      const classNumberB = b.classGroupClassNumberDict?.[classGroupId] || 1000;
      const byClassNumber = classNumberA - classNumberB;
      if (byClassNumber) return byClassNumber;

      if (!a.displayName) return -1;
      if (!b.displayName) return 1;
      return a.displayName.localeCompare(b.displayName, undefined, { numeric: true, sensitivity: 'base' });
    });
  });

  return byClassGroup;
}

export const getStudentsById = createSelector(
  getStudents,
  (students) => groupArrayByKeys(students, ['id'], null, true) as Dictionary<StudentInterface>
);

export const getStudentsByLeerId = createSelector(
  getStudents,
  (students) => groupArrayByKeys(students, ['leerId'], null, true) as Dictionary<StudentInterface>
);
