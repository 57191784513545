import { groupArrayByKey } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductContentInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './product-content.reducer';

export const selectProductContentState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectProductContentState, (state: State) => state.error);

export const getLoaded = createSelector(selectProductContentState, (state: State) => state.loaded);

export const getAll = createSelector(selectProductContentState, selectAll);

export const getCount = createSelector(selectProductContentState, selectTotal);

export const getIds = createSelector(selectProductContentState, selectIds);

export const getAllEntities = createSelector(selectProductContentState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * productContent$: ProductContentInterface[] = this.store.pipe(
    select(ProductContentQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectProductContentState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * productContent$: ProductContentInterface = this.store.pipe(
    select(ProductContentQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectProductContentState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getByProductId = createSelector(
  getAll,
  (productContents: ProductContentInterface[]) =>
    groupArrayByKey(productContents, 'productId') as Dictionary<ProductContentInterface[]>
);
