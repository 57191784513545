import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { Action, Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  DataServiceInterface,
  DATA_SERVICE_TOKEN,
  TaskDataResponseInterface,
} from '../../../data/data.service.interface';
import { DalState } from '../../dal.state.interface';
import { DATA_FIELD_MAPPERS_TOKEN, FieldMappersInterface, formatData } from '../mappers/data-field-mappers';
import { DataFieldActionFactory } from './task-data-field-action.factory';
import { LoadTaskData, TaskDataActionTypes, TaskDataLoaded, TaskDataLoadError } from './task-data.actions';

@Injectable()
export class TaskDataEffects {
  loadTaskData$ = createEffect(() =>
    this.actions.pipe(
      ofType(TaskDataActionTypes.LoadTaskData),
      concatLatestFrom(() => this.store),
      fetch({
        run: (action: LoadTaskData, state) => {
          const { taskId } = action.payload;
          let { fields } = action.payload;

          // Filter out what isn't yet loaded or doesn't exist in the state
          if (!action.payload.force) {
            fields = fields.filter((field) => {
              const stateName = this.getStateName(field, state);

              return !state[stateName].loadedForTask[taskId];
            });
          }

          return this.dataService.getAllForTask(taskId, fields).pipe(
            switchMap((dataForTask) => {
              return from([
                ...this.getLoadActions(taskId, dataForTask).filter(Boolean),
                new TaskDataLoaded({ taskId }),
              ]);
            })
          );
        },
        onError: (action: LoadTaskData, error) => {
          return new TaskDataLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    @Inject(DATA_SERVICE_TOKEN)
    private dataService: DataServiceInterface,
    @Inject(DATA_FIELD_MAPPERS_TOKEN)
    private fieldMappers: FieldMappersInterface,
    private store: Store<DalState>
  ) {}

  private getStateName(field: keyof TaskDataResponseInterface, state: DalState): string {
    const stateNameExceptions: Dictionary<string> = {};

    const stateName = stateNameExceptions[field] || field;

    if (!state[stateName]) {
      throw new Error('loading unknown state');
    }

    return stateName;
  }

  private getLoadActions(taskId: number, data: TaskDataResponseInterface): Action[] {
    return this.getActions(DataFieldActionFactory.getLoadedAction)(taskId, data);
  }

  private getUpsertActions(taskId: number, data: TaskDataResponseInterface): Action[] {
    return this.getActions(DataFieldActionFactory.getUpsertAction)(taskId, data);
  }

  private getActions(
    getActionFn: (taskId: number, field: string, formattedData: any, data?: TaskDataResponseInterface) => Action
  ) {
    return (taskId: number, data: TaskDataResponseInterface) =>
      Object.keys(data)
        .filter((field) => data[field] !== undefined)
        .map((field: keyof TaskDataResponseInterface) => {
          const formattedData = formatData(field, data, this.fieldMappers);
          return getActionFn(taskId, field, formattedData, data);
        });
  }
}
