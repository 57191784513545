import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaluationSubjectGoalApi } from '../+api';
import { EvaluationSubjectGoalInterface } from '../+models';
import { EvaluationSubjectGoalServiceInterface } from './evaluation-subject-goal.service.interface';

@Injectable({
  providedIn: 'root',
})
export class EvaluationSubjectGoalService implements EvaluationSubjectGoalServiceInterface {
  constructor(private evaluationSubjectGoalApi: EvaluationSubjectGoalApi) {}

  getEvaluationSubjectGoalsForBook(bookId: number): Observable<EvaluationSubjectGoalInterface[]> {
    return this.evaluationSubjectGoalApi.getEvaluationSubjectGoalsForBook(bookId);
  }
}
