import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { ProductContentInterface, ProductInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum ProductsActionTypes {
  ProductsLoaded = '[Products] Products Loaded',
  ProductsLoadError = '[Products] Load Error',
  LoadProducts = '[Products] Load Products',
  StartAddManyProducts = '[Products] Start Add Products',
  AddProduct = '[Products] Add Product',
  UpsertProduct = '[Products] Upsert Product',
  AddProducts = '[Products] Add Products',
  UpsertProducts = '[Products] Upsert Products',
  UpdateProduct = '[Products] Update Product',
  UpdateProducts = '[Products] Update Products',
  DeleteProduct = '[Products] Delete Product',
  DeleteProducts = '[Products] Delete Products',
  ClearProducts = '[Products] Clear Products',
  StartLinkProductContent = '[Products] Start Link Product Content',
  DeleteLinkedProductContent = '[Products] Delete Linked Product Content',
  StartLinkMethodProductGroup = '[Products] Start Link Method Product Group',
  DeleteLinkedMethodProductGroup = '[Products] Delete Linked Method Product Group',
}

export class LoadProducts implements Action {
  readonly type = ProductsActionTypes.LoadProducts;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class ProductsLoaded implements Action {
  readonly type = ProductsActionTypes.ProductsLoaded;

  constructor(public payload: { products: ProductInterface[] }) {}
}

export class ProductsLoadError implements Action {
  readonly type = ProductsActionTypes.ProductsLoadError;
  constructor(public payload: any) {}
}

export class StartAddManyProducts implements Action {
  readonly type = ProductsActionTypes.StartAddManyProducts;

  constructor(
    public payload: {
      products: ProductInterface[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class AddProduct implements Action {
  readonly type = ProductsActionTypes.AddProduct;

  constructor(public payload: { product: ProductInterface }) {}
}

export class UpsertProduct implements Action {
  readonly type = ProductsActionTypes.UpsertProduct;

  constructor(public payload: { product: ProductInterface }) {}
}

export class AddProducts implements Action {
  readonly type = ProductsActionTypes.AddProducts;

  constructor(public payload: { products: ProductInterface[] }) {}
}

export class UpsertProducts implements Action {
  readonly type = ProductsActionTypes.UpsertProducts;

  constructor(public payload: { products: ProductInterface[] }) {}
}

export class UpdateProduct implements FeedbackTriggeringAction {
  readonly type = ProductsActionTypes.UpdateProduct;

  constructor(
    public payload: {
      product: Update<ProductInterface>;
      methodProductGroups?: {
        linked: {
          productId: number;
          methodProductGroupId: number;
        }[];
        unlinked: {
          productId: number;
          methodProductGroupId: number;
          methodProductGroupProductId: number;
        }[];
      };
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpdateProducts implements Action {
  readonly type = ProductsActionTypes.UpdateProducts;

  constructor(public payload: { products: Update<ProductInterface>[] }) {}
}

export class DeleteProduct implements FeedbackTriggeringAction {
  readonly type = ProductsActionTypes.DeleteProduct;

  constructor(
    public payload: {
      id: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class DeleteProducts implements FeedbackTriggeringAction {
  readonly type = ProductsActionTypes.DeleteProducts;

  constructor(
    public payload: {
      ids: number[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class ClearProducts implements Action {
  readonly type = ProductsActionTypes.ClearProducts;
}

export class StartLinkProductContent implements Action {
  readonly type = ProductsActionTypes.StartLinkProductContent;

  constructor(
    public payload: {
      id: number;
      productContent: ProductContentInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}
export class DeleteLinkedProductContent implements FeedbackTriggeringAction {
  readonly type = ProductsActionTypes.DeleteLinkedProductContent;

  constructor(
    public payload: {
      id: number;
      productContentId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartLinkMethodProductGroup implements FeedbackTriggeringAction {
  readonly type = ProductsActionTypes.StartLinkMethodProductGroup;

  constructor(
    public payload: {
      productId: number;
      methodProductGroupId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class DeleteLinkedMethodProductGroup implements FeedbackTriggeringAction {
  readonly type = ProductsActionTypes.DeleteLinkedMethodProductGroup;

  constructor(
    public payload: {
      productId: number;
      methodProductGroupId: number;
      methodProductGroupProductId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export type ProductsActions =
  | LoadProducts
  | ProductsLoaded
  | ProductsLoadError
  | AddProduct
  | StartAddManyProducts
  | UpsertProduct
  | AddProducts
  | UpsertProducts
  | UpdateProduct
  | UpdateProducts
  | DeleteProduct
  | DeleteProducts
  | ClearProducts
  | StartLinkProductContent
  | StartLinkMethodProductGroup
  | DeleteLinkedMethodProductGroup
  | DeleteLinkedProductContent;
