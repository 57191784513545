import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SchoolRoleMappingInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './school-role-mapping.reducer';

export const selectSchoolRoleMappingState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectSchoolRoleMappingState, (state: State) => state.error);

export const getLoaded = createSelector(selectSchoolRoleMappingState, (state: State) => state.loaded);

export const getAll = createSelector(selectSchoolRoleMappingState, selectAll);

export const getCount = createSelector(selectSchoolRoleMappingState, selectTotal);

export const getIds = createSelector(selectSchoolRoleMappingState, selectIds);

export const getAllEntities = createSelector(selectSchoolRoleMappingState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * schoolRoleMapping$: SchoolRoleMappingInterface[] = this.store.pipe(
    select(SchoolRoleMappingQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectSchoolRoleMappingState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * schoolRoleMapping$: SchoolRoleMappingInterface = this.store.pipe(
    select(SchoolRoleMappingQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectSchoolRoleMappingState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getBySchoolIdByRoleId = createSelector(getAll, (schoolRoleMappings: SchoolRoleMappingInterface[]) => {
  return groupArrayByKeys<SchoolRoleMappingInterface>(schoolRoleMappings, ['schoolId', 'roleId']);
});

export const getByRoleId = createSelector(
  selectSchoolRoleMappingState,
  (state: State): Dictionary<SchoolRoleMappingInterface[]> => {
    return groupArrayByKeys<SchoolRoleMappingInterface>(Object.values(state.entities), ['roleId']);
  }
);

export const getByPersonId = createSelector(
  selectSchoolRoleMappingState,
  (state: State): Dictionary<SchoolRoleMappingInterface[]> => {
    return groupArrayByKeys<SchoolRoleMappingInterface>(Object.values(state.entities), ['personId']);
  }
);

export const getBySchoolId = createSelector(
  selectSchoolRoleMappingState,
  (state: State): Dictionary<SchoolRoleMappingInterface[]> => {
    return groupArrayByKeys<SchoolRoleMappingInterface>(Object.values(state.entities), ['schoolId']);
  }
);
