import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AlgebrakitSessionQueries } from '.';
import { DalState } from '..';
import { AlgebrakitSessionService, AlgebrakitSessionServiceInterface } from '../../algebrakit-session';
import { BundlesActionTypes, DeleteBundles } from '../bundle/bundle.actions';
import { DeleteTasks, TasksActionTypes } from '../task/task.actions';
import {
  AlgebrakitSessionActionTypes,
  AlgebrakitSessionsLoaded,
  AlgebrakitSessionsLoadError,
  DeleteAlgebrakitSessions,
  LoadAlgebrakitSessions,
} from './algebrakit-session.actions';
import { getAlgebrakitSessionIdsForBundleIds, getAlgebrakitSessionIdsForTaskIds } from './algebrakit-session.selectors';

@Injectable()
export class AlgebrakitSessionEffects {
  loadAlgebrakitSessions$ = createEffect(() =>
    this.actions.pipe(
      ofType(AlgebrakitSessionActionTypes.LoadAlgebrakitSessions),
      concatLatestFrom(() => this.store.select(AlgebrakitSessionQueries.getLoaded)),
      fetch({
        run: (action: LoadAlgebrakitSessions, loaded: Boolean) => {
          if (!action.payload.force && loaded) return;
          return this.algebrakitSessionService
            .getAll(action.payload.userId)
            .pipe(map((algebrakitSessions) => new AlgebrakitSessionsLoaded({ algebrakitSessions })));
        },
        onError: (action: LoadAlgebrakitSessions, error) => {
          return new AlgebrakitSessionsLoadError(error);
        },
      })
    )
  );

  deleteAlgebrakitSessionsOnTaskDeletion$ = createEffect(() =>
    this.actions.pipe(
      ofType(TasksActionTypes.DeleteTasks),
      switchMap((action: DeleteTasks): Observable<any> => {
        return this.store.pipe(
          select(getAlgebrakitSessionIdsForTaskIds, { taskIds: action.payload.ids }),
          map((algebrakitSessionIds) => new DeleteAlgebrakitSessions({ ids: algebrakitSessionIds }))
        );
      })
    )
  );

  deleteAlgebrakitSessionsOnBundleDeletion$ = createEffect(() =>
    this.actions.pipe(
      ofType(BundlesActionTypes.DeleteBundles),
      switchMap((action: DeleteBundles): Observable<any> => {
        return this.store.pipe(
          select(getAlgebrakitSessionIdsForBundleIds, { bundleIds: action.payload.ids }),
          map((algebrakitSessionIds) => new DeleteAlgebrakitSessions({ ids: algebrakitSessionIds }))
        );
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(AlgebrakitSessionService)
    private algebrakitSessionService: AlgebrakitSessionServiceInterface
  ) {}
}
