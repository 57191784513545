import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SchoolBillingInterface } from '../+models/SchoolBilling.interface';

export const SCHOOL_BILLING_SERVICE_TOKEN = new InjectionToken('SchoolBillingServiceInterface');

export interface SchoolBillingServiceInterface {
  getAllForUser(userId: number): Observable<SchoolBillingInterface[]>;
  calculateSchoolBilling(schoolId: number): Observable<SchoolBillingInterface[]>;
}
