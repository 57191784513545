import { Inject, Optional } from '@angular/core';
import { ENVIRONMENT_SCORE_MAPPING_TOKEN } from '@campus/environment';
import { DateFunctions, ResultFunctions } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { AlgebrakitSessionInterface } from './AlgebrakitSession.interface';
import { BundleInterface } from './Bundle.interface';
import { EduContentInterface } from './EduContent.interface';
import { LearningAreaInterface } from './LearningArea.interface';
import { MixedResultInterface } from './MixedResult.interface';
import { PersonInterface } from './Person.interface';
import { ResultStatusEnum } from './Result.interface';
import { TaskInterface } from './Task.interface';
import { TaskEduContentInterface } from './TaskEduContent.interface';
import { UnlockedContentInterface } from './UnlockedContent.interface';
import { UnlockedFreePracticeInterface } from './UnlockedFreePractice.interface';

export class AlgebrakitSession implements AlgebrakitSessionInterface {
  sessionId: string;
  id?: number;

  userId?: number;
  user?: PersonInterface;

  score?: number;
  time?: number;
  status?: ResultStatusEnum;
  level?: number;

  eduContentId?: number;
  eduContent?: EduContentInterface;

  lastUpdated?: Date;

  taskId?: number;
  task?: TaskInterface;
  taskEduContentId?: number;
  taskEduContent?: TaskEduContentInterface;

  bundleId?: number;
  bundle?: BundleInterface;
  unlockedContentId?: number;
  unlockedContent?: UnlockedContentInterface;

  unlockedFreePracticeId?: number;
  unlockedFreePractice?: UnlockedFreePracticeInterface;

  constructor(@Optional() @Inject(ENVIRONMENT_SCORE_MAPPING_TOKEN) private scoreToStars?: [number, number][]) {}

  get personId() {
    return this.userId;
  }
  set personId(value: number) {
    this.userId = value;
  }
  get person() {
    return this.user;
  }
  set person(value: PersonInterface) {
    this.user = value;
  }

  get day() {
    return DateFunctions.midnight(this.lastUpdated);
  }

  get colorClass(): string {
    return ResultFunctions.getColorClass(this.score, undefined, this.scoreToStars);
  }

  get isCompleted(): boolean {
    return this.status === ResultStatusEnum.STATUS_COMPLETED;
  }

  get learningArea(): LearningAreaInterface {
    return (
      !!this.eduContent &&
      !!this.eduContent.publishedEduContentMetadata &&
      this.eduContent.publishedEduContentMetadata.learningArea
    );
  }

  static toAlgebrakitSession<T extends AlgebrakitSessionInterface>(session: T): AlgebrakitSession & T {
    return session && Object.assign(new AlgebrakitSession(), session);
  }

  static toAlgebraKitSessionsPerDay(sessions: AlgebrakitSessionInterface[]): AlgebrakitSessionInterface[][] {
    // Note: this only groups by day
    // Grouping by -for instance- eduContent is you own problem

    const { sessionsForDays } = sessions.reduce(
      (acc, session) => {
        const algebrakitSession = AlgebrakitSession.toAlgebrakitSession(session);
        const { day } = algebrakitSession;
        const dateString = day.toLocaleDateString();

        if (!acc.sessionsByDay[dateString]) {
          const sessionsForDay = [];
          acc.sessionsForDays.push(sessionsForDay);
          acc.sessionsByDay[dateString] = sessionsForDay;
        }

        acc.sessionsByDay[dateString].push(algebrakitSession);

        return acc;
      },
      {
        sessionsForDays: [] as AlgebrakitSessionInterface[][],
        sessionsByDay: {} as Dictionary<AlgebrakitSessionInterface[]>,
      }
    );

    return sessionsForDays;
  }

  static toMixedResult(session: AlgebrakitSessionInterface): MixedResultInterface<AlgebrakitSessionInterface> {
    const { eduContent, lastUpdated, time, status, bundleId, taskId, userId: personId, user: person } = session;

    const inTask = !!taskId;
    const inBundle = !!bundleId;

    return {
      type: 'session',
      eduContent,
      lastUpdated,
      time,
      data: session,
      status,
      inTask,
      inBundle,
      personId,
      person,
    };
  }
}
