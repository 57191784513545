import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, inject, Injectable, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { WINDOW, WindowService } from '@campus/browser';
import {
  ContentInterface,
  ContextInterface,
  DalState,
  EduContent,
  EduContentTypeEnum,
  EffectFeedback,
  EffectFeedbackActions,
  UserContent,
} from '@campus/dal';
import {
  EnvironmentApiInterface,
  EnvironmentWebsiteInterface,
  ENVIRONMENT_API_TOKEN,
  ENVIRONMENT_WEBSITE_TOKEN,
} from '@campus/environment';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ContentPreviewDialogComponent } from '../../components/content-preview-dialog/content-preview-dialog.component';
import {} from '../../interfaces';
import { TextMappingPipe } from '../../pipes';
import { MathJaxService } from './../mathjax/mathjax.service';
import { OpenStaticContentServiceInterface } from './open-static-content.interface';

@Injectable({
  providedIn: 'root',
})
export class OpenStaticContentService implements OpenStaticContentServiceInterface, OnDestroy {
  private mathjaxService = inject(MathJaxService);
  private subscriptions = new Subscription();

  constructor(
    private matDialog: MatDialog,
    @Inject(WINDOW) private window: Window,
    private windowService: WindowService,
    @Inject(ENVIRONMENT_API_TOKEN) private environmentApi: EnvironmentApiInterface,
    private store: Store<DalState>,
    @Inject('uuid') private uuid: () => string,
    private httpClient: HttpClient,
    @Inject(ENVIRONMENT_WEBSITE_TOKEN) private environmentWebsite: EnvironmentWebsiteInterface,
    private textMappingPipe: TextMappingPipe
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Opens any object that implements ContentInterface
   *
   * @param content The content that is being opened
   * @param stream Should the content be streamed instead of downloaded? (used for video content)
   * @param showPreview Should the content open in a preview dialog on the page, instead of a new window?
   * @param useIFrame Should the content open in an iframe on the page, instead of a new window?
   * @param context The context of the content
   * @param disableMathjax Disable mathjax rendering
   * @param eduContentMetadataId Use a specific version
   *
   */
  open(
    content: ContentInterface,
    stream?: boolean,
    showPreview?: boolean,
    useIFrame?: boolean,
    context?: ContextInterface,
    disableMathjax?: boolean,
    eduContentMetadataId?: number
  ): void {
    if (content instanceof UserContent) {
      this.window.open(content.link);
      return;
    }

    if (!(content instanceof EduContent)) return;

    if (content.isVideo && content.type === EduContentTypeEnum.FILE) {
      useIFrame = true;
    }

    const apiUrlBase = `${this.environmentApi.APIBase}/api/EduContents/${content.id}`;
    const isIFrameOrPreview = useIFrame || showPreview;
    const requestOrRedirect = isIFrameOrPreview ? 'redirect' : 'request';

    let url =
      eduContentMetadataId && isIFrameOrPreview
        ? `${apiUrlBase}/${requestOrRedirect}URL/${eduContentMetadataId}`
        : `${apiUrlBase}/${requestOrRedirect}URL`;

    const queryParam = (link: string, qs: string) => `${link}${link.includes('?') ? '&' : '?'}${qs}`;
    if (stream) url = queryParam(url, 'stream=true');
    if (context) url = queryParam(url, 'context=' + JSON.stringify(context));
    if (eduContentMetadataId) url = queryParam(url, `eduContentMetadataId=${eduContentMetadataId}`);

    if (showPreview) {
      if (disableMathjax || !content.isRichText) {
        const data = content.isRichText ? { html: content.data, title: content.name } : { url };

        this.matDialog.open(ContentPreviewDialogComponent, { data, panelClass: 'content-preview-dialog' });
      } else {
        this.subscriptions.add(
          this.mathjaxService
            .ready()
            .pipe(
              switchMap(() => {
                return this.mathjaxService.render(content.data);
              })
            )
            .subscribe((html) => {
              const data = { html, title: content.name };
              this.matDialog.open(ContentPreviewDialogComponent, { data, panelClass: 'content-preview-dialog' });
            })
        );
      }

      return;
    }

    if (useIFrame) {
      this.windowService.openWindow(null, url, useIFrame);
      return;
    }

    const isGame =
      content.type === EduContentTypeEnum.WEB_APP && content.publishedEduContentMetadata?.fileExt === 'gamehoek';
    const target = isGame ? '_campusstaticcontent' : undefined;

    const openedWindow = this.window.open(this.environmentWebsite.loadingUrl || '', target);
    this.httpClient.get<{ url: string }>(url, { withCredentials: true }).subscribe(
      (redirect) => {
        //give absolute links here because it can be opened from the ink
        if (redirect.url?.startsWith('/')) {
          redirect.url = this.environmentApi.APIBase + redirect.url;
        }

        return this.handleRedirect(openedWindow, redirect.url);
      },
      (err: HttpErrorResponse) => this.handleError(openedWindow, err)
    );
  }

  private handleRedirect(openedWindow: Window, url: string) {
    if (!url) {
      return this.handleError(openedWindow, new HttpErrorResponse({ status: 404 }));
    }
    openedWindow.location.replace(url);
  }

  private handleError(openedWindow: Window, err: HttpErrorResponse) {
    const errorMessage =
      err.status === 401
        ? 'Uw licentie voor dit lesmateriaal is verlopen. ' +
          `Vraag aan uw ${this.textMappingPipe.transform('common.schooladmin')} om de licentie te vernieuwen.`
        : 'Er was een probleem bij het openen van deze oefening. Als het probleem blijft aanhouden, contacteer de helpdesk.';

    const effectFeedback = EffectFeedback.generateErrorFeedback(this.uuid(), null, errorMessage);
    this.store.dispatch(new EffectFeedbackActions.AddEffectFeedback({ effectFeedback }));

    openedWindow.close();
  }
}
