import { Dictionary } from '@ngrx/entity';

export interface TreeBranchInterface {
  id?: number;
  treeId: number;
  rgt: number;
  lft: number;
  depth: number;
  children?: TreeBranchInterface[];
  parent?: TreeBranchInterface;
}

export function makeTree<T extends TreeBranchInterface>(data: T[]) {
  const clone = JSON.parse(JSON.stringify(data));
  const branch: T[] = [];

  // check if there are leaves (children) in the tree we passed
  while (clone.length) {
    // get the first element to process and remove it from the tree
    const currentBranch: T = { ...clone.shift() };

    // find the number of leaves in our branch (including sub-branches)
    // when there are no more sub-branches this will be 0
    let leaves: number = (currentBranch.rgt - currentBranch.lft - 1) / 2;
    // since we ordered by 'lft', the next x (= leaves) rows contain all child nodes
    // we get those nodes and remove them from the tree
    for (let i = leaves; i >= 0; i--) {
      // if the tree is incomplete, there might be less leaves
      // we search for the last leaf that's still a child of the current branch
      leaves = i;
      if (i === 0) break; // there are no children for this branch

      const leaf = clone[i - 1];
      if (leaf && leaf.lft < currentBranch.rgt) break;
    }
    const children: T[] = clone.splice(0, leaves).map((leaf) => ({ ...leaf, parent: currentBranch }));

    // build the subtree with our child rows and append it to the current element
    currentBranch.children = makeTree(children);

    // append the current element (with sub-branches) to the current branch
    branch.push(currentBranch);
  }

  // return the (sub)tree
  return branch;
}

export function findParent<T extends TreeBranchInterface>(currentBranch: T, branchesToSearch: T[]): T {
  return branchesToSearch.find(
    (parentToc: T) =>
      parentToc.depth === currentBranch.depth - 1 &&
      currentBranch.lft > parentToc.lft &&
      currentBranch.rgt < parentToc.rgt
  );
}

export function findTopMostParentInTreeById<T extends TreeBranchInterface>(branchesToSearch: T[], id: number) {
  let found: T;
  branchesToSearch.some((node) => {
    if (node.id && node.id === id) {
      return (found = node);
    }
    if (node.children) {
      const foundChild = findTopMostParentInTreeById(node.children, id);
      if (foundChild) {
        return (found = node);
      }
    }
    return false;
  });
  return found;
}

export function findToc<T extends TreeBranchInterface>(branchesToSearch: T[], id: number): T {
  let found: T;

  branchesToSearch.some((node) => {
    if (node.id && node.id === id) {
      return (found = node);
    }
    if (node.children) {
      const foundChild = findToc(node.children, id);
      if (foundChild) {
        return (found = foundChild as T);
      }
    }
    return false;
  });
  return found;
}

export function makeTreeFromIncomplete<T extends TreeBranchInterface>(data: T[]) {
  const tree: T[] = [];

  const branchDict: Dictionary<T> = {}; // hold references to branches in tree
  const rowDict: Dictionary<T> = {}; // hold references to original data (used for parent prop, to prevent circular data structures)

  const isChild = (parent: T, child: T) =>
    parent.treeId === child.treeId &&
    parent.depth === child.depth - 1 &&
    parent.lft < child.lft &&
    child.rgt < parent.rgt;

  // because tocs are sorted by lft, a toc is either:
  // - a root element (depth === 0)
  // - a child of the previous toc (=> data[i-1])
  // - a child of one of the parents of the previous toc (=> check parents recursively)
  const addBranchToTree = (branch: T, parentBranch: T) => {
    if (branch.depth === 0) {
      tree.push(branch);
    } else if (!parentBranch) {
      console.warn(`no parent found in provided data for id ${branch.id}`);
    } else if (isChild(parentBranch, branch)) {
      branch.parent = rowDict[parentBranch.id];
      parentBranch.children.push(branch);
    } else {
      addBranchToTree(branch, branchDict[parentBranch.parent?.id]);
    }
  };

  data.forEach((row, i) => {
    const branch = { ...row, children: [] };
    branchDict[branch.id] = branch;
    rowDict[row.id] = row;

    const previousBranch = branchDict[data[i - 1]?.id];
    addBranchToTree(branch, previousBranch);
  });

  return tree;
}

export function flatten<T extends TreeBranchInterface>(tree: T[]): T[] {
  const arr = [];

  const concatBranches = ({ children = [], parent, ...toc }: T) => {
    arr.push(toc as T);
    children.forEach(concatBranches);
  };

  tree.forEach(concatBranches);

  return arr;
}
