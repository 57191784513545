import { Component, HostBinding } from '@angular/core';

@Component({
  selector: '[campusCardProgress], [campus-card-progress], campus-card-progress',
  template: `<div class="[ imposter ] [ w-full p-i-s ]"><ng-content></ng-content></div>`,
})
export class CardProgressComponent {
  @HostBinding('class')
  defaultClasses = ['absolute', 'bottom-none', 'left-none', 'right-none'];
}
