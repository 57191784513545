import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GoalInterface,
  GoalTopicInterface,
  GoalYearInterface,
  TaskEvaluationSubjectScoreInterface,
  TaskGoalYearLevelInterface,
} from '../+models';

export const TASK_EVALUATION_SUBJECT_SCORE_SERVICE_TOKEN = new InjectionToken('TaskEvaluationScoreService');

export interface SetTaskEvaluationSubjectScoresForTaskResponseInterface {
  taskEvaluationSubjectScores: TaskEvaluationSubjectScoreInterface[];
  taskGoalYearLevels: TaskGoalYearLevelInterface[];
  goals: GoalInterface[];
  goalTopics: GoalTopicInterface[];
  goalYears: GoalYearInterface[];
}
export interface TaskEvaluationSubjectScoreServiceInterface {
  getTaskEvaluationSubjectScoresForTask(taskId: number): Observable<TaskEvaluationSubjectScoreInterface[]>;

  setTaskEvaluationSubjectScoresForTask(
    taskId: number,
    taskEvaluationSubjectScores: TaskEvaluationSubjectScoreInterface[]
  ): Observable<SetTaskEvaluationSubjectScoresForTaskResponseInterface>;
}
