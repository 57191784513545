import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { ArtifactInterface } from '../../+models/Artifact.interface';
import { ArtifactsActions, ArtifactsActionTypes } from './artifact.actions';

export const NAME = 'artifacts';

export interface State extends EntityState<ArtifactInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
  loadedArtifactsForBook?: Dictionary<number[]>;
}

export const adapter: EntityAdapter<ArtifactInterface> = createEntityAdapter<ArtifactInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedArtifactsForBook: {},
});

export function reducer(state = initialState, action: ArtifactsActions): State {
  switch (action.type) {
    case ArtifactsActionTypes.ArtifactsLoaded: {
      return adapter.setAll(action.payload.artifacts, {
        ...state,
        loaded: true,
      });
    }

    case ArtifactsActionTypes.ArtifactsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case ArtifactsActionTypes.AddArtifact: {
      return adapter.addOne(action.payload.artifact, state);
    }

    case ArtifactsActionTypes.AddArtifacts: {
      return adapter.addMany(action.payload.artifacts, state);
    }

    case ArtifactsActionTypes.UpdateArtifact: {
      return adapter.updateOne(action.payload.artifact, state);
    }

    case ArtifactsActionTypes.UploadArtifactImage: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: {
            image: action.payload.image,
          },
        },
        state
      );
    }

    case ArtifactsActionTypes.DeleteArtifact: {
      return adapter.removeOne(action.payload.id, state);
    }

    default: {
      return state;
    }

    case ArtifactsActionTypes.AddArtifactsForBook: {
      const { artifacts, bookId } = action.payload;
      return adapter.upsertMany(artifacts, {
        ...state,
        loadedArtifactsForBook: { ...state.loadedArtifactsForBook, [bookId]: artifacts.map((artifact) => artifact.id) },
      });
    }
  }
}

export const { selectAll, selectEntities } = adapter.getSelectors();
