import { Type } from '@angular/core';

import { Action, MemoizedSelector, MemoizedSelectorWithProps, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, shareReplay, switchMap, switchMapTo, take, tap } from 'rxjs/operators';
import { DalState } from '../../+state';
import { BundleActions, BundleQueries } from '../../+state/bundle';
import { ClassGroupActions, ClassGroupQueries } from '../../+state/class-group';
import { BookDataActions } from '../../+state/data';
import { EduContentBookActions, EduContentBookQueries } from '../../+state/edu-content-book';
import { EduContentTocQueries } from '../../+state/edu-content-toc';
import { EduContentTocEvaluationQueries } from '../../+state/edu-content-toc-evaluation';
import { EvaluationSubjectActions, EvaluationSubjectQueries } from '../../+state/evaluation-subject';
import { EvaluationSubjectGoalActions, EvaluationSubjectGoalQueries } from '../../+state/evaluation-subject-goal';
import { MethodStyleQueries } from '../../+state/method-style';
import { UnlockedFreePracticeActions, UnlockedFreePracticeQueries } from '../../+state/unlocked-free-practice';
import { UserActions, UserQueries } from '../../+state/user';

export function loadAndResolve<T, U>(
  loadAction: Type<Action>,
  isLoadedSelector: MemoizedSelectorWithProps<DalState, T, boolean> | MemoizedSelector<DalState, boolean>,
  fixedProps: Partial<U> = {}
) {
  return (store: Store<DalState>, userId: number) =>
    (source: Observable<T>): Observable<T> =>
      source.pipe(
        tap((props) => store.dispatch(new loadAction({ ...fixedProps, ...props, userId }))),
        switchMap((props) =>
          props !== undefined
            ? store.pipe(
                select(isLoadedSelector as MemoizedSelectorWithProps<DalState, T, boolean>, props),
                filter((loaded) => !!loaded),
                take(1)
              )
            : store.pipe(
                select(isLoadedSelector as MemoizedSelector<DalState, boolean>),
                filter((loaded) => !!loaded),
                take(1)
              )
        ),
        shareReplay(1),
        switchMapTo(source)
      );
}

export const loadAndResolveBundles = loadAndResolve(BundleActions.LoadBundles, BundleQueries.getLoaded);
export const loadAndResolveBooks = loadAndResolve(
  EduContentBookActions.LoadEduContentBooks,
  EduContentBookQueries.getLoaded
);
export const loadAndResolveUFP = loadAndResolve(
  UnlockedFreePracticeActions.LoadUnlockedFreePractices,
  UnlockedFreePracticeQueries.getLoaded
);

export const loadAndResolvePermissions = loadAndResolve(UserActions.LoadPermissions, UserQueries.getPermissionsLoaded);

export const loadAndResolveTocsForBook = loadAndResolve(
  BookDataActions.LoadBookData,
  EduContentTocQueries.isBookLoaded,
  { fields: ['eduContentTocs'] }
);
export const loadAndResolveEvaluationSubjectsForBook = loadAndResolve(
  EvaluationSubjectActions.LoadEvaluationSubjectsForBook,
  EvaluationSubjectQueries.getEvaluationSubjectsLoadedForBook
);
export const loadAndResolveEvaluationSubjectGoalsForBook = loadAndResolve(
  EvaluationSubjectGoalActions.LoadEvaluationSubjectGoalsForBook,
  EvaluationSubjectGoalQueries.getEvaluationSubjectGoalsLoadedForBook
);
export const loadAndResolveEduContentTocEvaluationsForBook = loadAndResolve<
  { bookId: number },
  BookDataActions.LoadBookData['payload']
>(BookDataActions.LoadBookData, EduContentTocEvaluationQueries.getEduContentTocEvaluationsLoadedForBook, {
  fields: ['eduContentTocEvaluations'],
});

export const loadAndResolveMethodStyleForBook = (bookId: number) =>
  loadAndResolve(BookDataActions.LoadBookData, MethodStyleQueries.isBookLoaded({ bookId }), {
    fields: ['methodStyle'],
  });

export const loadAndResolveClassGroups = loadAndResolve(ClassGroupActions.LoadClassGroups, ClassGroupQueries.getLoaded);
