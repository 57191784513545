import { Component, HostBinding } from '@angular/core';
import { ChipCoreComponent } from './../chip-core.component';

@Component({
  selector: 'campus-suggestion-chip',
  templateUrl: './suggestion-chip.component.html',
})
export class SuggestionChipComponent extends ChipCoreComponent {
  @HostBinding('class')
  defaultClasses = [...this.coreClasses];
}
