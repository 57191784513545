import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { TableApiResponseInterface } from '../+external-interfaces/table-api-v2';
import { GoalRootInterface, UpdateGoalRelationChangesInterface } from '../goal/goal.service.interface';

export const GOAL_GROUP_SERVICE_TOKEN = new InjectionToken('GoalGroupService');

export interface GoalGroupRootInterface {
  id?: number;
  methodId?: number;
  method?: string;
  learningAreaId?: number;
  learningArea?: string;
}

export interface GoalGroupServiceInterface {
  checkUniqueGoalGroup(methodId: number, learningAreaId: number): Observable<boolean>;
  getGoalGroups(
    searchTerm?: string,
    filters?: { [key: string]: string | number[] | number },
    pagination?: { from?; amount? },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<TableApiResponseInterface<GoalGroupRootInterface>>;
  getGoalGroup(id: number): Observable<GoalGroupRootInterface>;
  getGoalGroupForMethodAndLearningArea(methodId: number, learningAreaId: number): Observable<GoalGroupRootInterface>;
  createGoalGroup(goalGroup: GoalGroupRootInterface): Observable<number>;
  updateGoalGroup(goalGroup: GoalGroupRootInterface): Observable<number>;

  getGoals(
    groupId: number,
    searchTerm?: string,
    filters?: { [key: string]: string | number[] | number },
    pagination?: { from?; amount? },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<{ results: GoalRootInterface[]; count: number }>;

  updateRelation(goalGroupId: number, body: UpdateGoalRelationChangesInterface): Observable<number>;
}
