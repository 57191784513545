import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { PersonInterface, SchoolInterface, SchoolRoleMappingInterface, SchoolsTableInterface } from '../+models';

export const SCHOOL_SERVICE_TOKEN = new InjectionToken<SchoolServiceInterface>('SchoolServiceInterface');

export interface SchoolServiceInterface {
  printStudentLoginLabels(schoolId: number, studentIds: number[]): Observable<Blob>;

  downloadStudentLoginLabelsExcel(
    schoolId: number,
    studentIds: number[],
    downloadStudentLoginLabelsExcel?: boolean
  ): Observable<Blob>;

  printStudentLoginLetters(schoolId: number, studentIds: number[]): Observable<boolean>;

  sendInviteMail(schoolId: number, teacherId: number): Observable<Partial<PersonInterface>>;

  sendResetPasswordMail(schoolId: number, teacherId: number): Observable<boolean>;

  createSchool(school: SchoolInterface): Observable<SchoolInterface>;

  removeSchool(schoolId: number): Observable<{
    schoolId: number;
    classGroupIds: number[];
    schoolRoleMappingIds: number[];
    schoolRoleMappingClassGroupIds: number[];
  }>;
  updateSchool(id: number, update: Partial<SchoolInterface>): Observable<SchoolInterface>;

  updateStudentPersonalCode(
    schoolId: number,
    studentId: number,
    personalCode: string
  ): Observable<SchoolRoleMappingInterface>;

  addStudentCoins(schoolId: number, studentId: number, gameCoinsToAdd: number): Observable<SchoolRoleMappingInterface>;

  getForZip(zipCode: string): Observable<SchoolInterface[]>;

  linkSchoolsToTeacher(userId, schoolIds: number[]): Observable<SchoolRoleMappingInterface[]>;

  unlinkTeacher(userId: number, schoolId: number): Observable<boolean>;

  create(school: SchoolInterface): Observable<SchoolInterface>;

  updateTransition(schoolId: number, type: 'students' | 'licenses'): Observable<SchoolInterface>;
  unlinkStudentsInSchool(schoolId: number): Observable<SchoolRoleMappingInterface[]>;

  getSchools(
    orderSchoolYear: number,
    searchTerm?: string,
    filters?: { [key: string]: string | number[] | number },
    pagination?: { from?: number; amount?: number },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<{ results: SchoolsTableInterface[]; count: number }>;
}
