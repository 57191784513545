import { InjectionToken } from '@angular/core';
import { EduContent } from '@campus/dal';
import { ContentActionInterface } from '../..';

export interface ContentBundleManagerInterface {
  addEduContentToBundle(eduContent: EduContent | EduContent[]): void;
  removeEduContentFromBundle(eduContent: EduContent | EduContent[]): void;
}
export const CONTENT_BUNDLE_ACTIONS_SERVICE_TOKEN = new InjectionToken<ContentBundleActionsServiceInterface>(
  'ContentBundleActionsService'
);

export const CONTENT_BUNDLE_MANAGER_TOKEN = new InjectionToken<ContentBundleManagerInterface>('ContentBundleManager');

export interface ContentBundleActionsServiceInterface {
  contentBundleActionDictionary: { [key: string]: ContentActionInterface };
  getBundleActionsForEduContent(eduContent: EduContent, inBundle: boolean): ContentActionInterface[];
}
