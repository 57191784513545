import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NAME, selectAll, selectEntities, State } from './loop-learner-profile.reducer';

export const selectLoopLearnerStatusState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectLoopLearnerStatusState, (state: State) => state.error);

export const getLoaded = createSelector(selectLoopLearnerStatusState, (state: State) => state.loaded);

export const getAll = createSelector(selectLoopLearnerStatusState, selectAll);

export const getAllEntities = createSelector(selectLoopLearnerStatusState, selectEntities);

export const getById = (props: { id: string }) => createSelector(getAllEntities, (state) => state[props.id]);

export const isBookLoaded = (props: { bookId: number }) =>
  createSelector(selectLoopLearnerStatusState, (state: State) => !!state.loadedForBook[props.bookId]);
export const isTocLoaded = (props: { tocId: number }) =>
  createSelector(selectLoopLearnerStatusState, (state: State) => !!state.loadedForToc[props.tocId]);
