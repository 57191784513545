import { Component, HostBinding, Inject, Input, OnDestroy, OnInit, Optional, Pipe, PipeTransform } from '@angular/core';
import { EduContentTOCInterface } from '@campus/dal';
import { ResultItemBase } from '@campus/search';
import {
  ContentActionInterface,
  ContentBundleActionsServiceInterface,
  ContentOpenActionsServiceInterface,
  ContentTaskActionsServiceInterface,
  CONTENT_BUNDLE_ACTIONS_SERVICE_TOKEN,
  CONTENT_OPEN_ACTIONS_SERVICE_TOKEN,
  CONTENT_TASK_ACTIONS_SERVICE_TOKEN,
  EduContentSearchResultInterface,
} from '@campus/shared';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Component({
  // eslint-disable-next-line
  selector: 'edu-content-search-result',
  templateUrl: './edu-content-search-result.component.html',
  styleUrls: ['./edu-content-search-result.component.scss'],
})
export class EduContentSearchResultComponent extends ResultItemBase implements OnInit, OnDestroy {
  @HostBinding('class.search-result--included')
  public isIncluded = false;
  @HostBinding('attr.data-usetiful')
  public hasUsetifulTag = 'search-result';

  @HostBinding('class.app-educontentsearchresult')
  appEduContentSearchResultClass = true;

  @HostBinding('class.search-result')
  searchResultClass = true;

  @HostBinding('attr.data-cy')
  dataCy = 'search-result';

  @Input() throttleTime = 500;
  @Input() data: EduContentSearchResultInterface;

  public actions: ContentActionInterface[];
  public showMore = false;

  private clicks = new Subject<ContentActionInterface>();
  private subscription: Subscription;
  constructor(
    @Inject(CONTENT_OPEN_ACTIONS_SERVICE_TOKEN)
    private contentOpenActionsService: ContentOpenActionsServiceInterface,
    @Optional()
    @Inject(CONTENT_TASK_ACTIONS_SERVICE_TOKEN)
    private contentTaskActionsService: ContentTaskActionsServiceInterface,
    //Token is needed because the class is provided on root and therefor accessable for the task pages
    @Optional()
    @Inject(CONTENT_BUNDLE_ACTIONS_SERVICE_TOKEN)
    private contentBundleActionsService: ContentBundleActionsServiceInterface
  ) {
    super();
    this.selectable = false;
  }

  ngOnInit() {
    super.ngOnInit();
    this.update();
    this.subscription = this.clicks
      .pipe(throttleTime(this.throttleTime))
      .subscribe((action) => action.handler(this.data.eduContent));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public update() {
    super.update();
    this.isIncluded = !!(this.data.inTask || this.data.inBundle);
    this.setupActions();
  }

  public onActionClick(action: ContentActionInterface) {
    this.clicks.next(action);
  }

  private setupActions(): void {
    this.actions = this.contentOpenActionsService.getActionsForContent(this.data.eduContent);

    if (this.data.addTaskActions) {
      if (!this.contentTaskActionsService) {
        throw new Error('CONTENT_TASK_ACTIONS_SERVICE_TOKEN not provided in module');
      }

      this.actions = [
        ...this.contentTaskActionsService.getTaskActionsForEduContent(this.data.eduContent, this.data.inTask),
        ...this.actions,
      ];
    }

    if (this.data.addBundleActions) {
      if (!this.contentBundleActionsService) {
        throw new Error('ContentBundleActionsService not provided in module');
      }

      this.actions = [
        ...this.contentBundleActionsService.getBundleActionsForEduContent(this.data.eduContent, this.data.inBundle),
        ...this.actions,
      ];
    }
  }
}

interface EduContentTOCWithLabels extends EduContentTOCInterface {
  labels?: {
    book: string;
    chapter: string;
    lesson: string;
  };
}

@Pipe({ name: 'tocInfo' })
export class TocInfoPipe implements PipeTransform {
  public transform(value: EduContentTOCWithLabels | EduContentTOCWithLabels[]) {
    if (!Array.isArray(value)) value = [value];

    return value
      .filter((v) => !!v)
      .map((v) => this.toInfoLabel(v))
      .sort(this.stringCompare);
  }

  private toInfoLabel(toc: EduContentTOCWithLabels) {
    if (!toc.labels) return this.getFallBackValue(toc);

    const { book, chapter, lesson } = toc.labels;
    return `${book} ${chapter} ${lesson}`.trim();
  }

  private stringCompare(a: string, b: string) {
    return a.localeCompare(b, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  }

  private getFallBackValue(toc: EduContentTOCInterface) {
    if (!toc.eduContentBook) return toc.title;
    return toc.eduContentBook.title;
  }
}
