import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaskEvaluationSubjectScoreApi } from '../+api';
import { TaskEvaluationSubjectScoreInterface } from '../+models';
import {
  SetTaskEvaluationSubjectScoresForTaskResponseInterface,
  TaskEvaluationSubjectScoreServiceInterface,
} from './task-evaluation-subject-score.service.interface';
@Injectable({
  providedIn: 'root',
})
export class TaskEvaluationSubjectScoreService implements TaskEvaluationSubjectScoreServiceInterface {
  constructor(private taskEvaluationSubjectScoreApi: TaskEvaluationSubjectScoreApi) {}

  getTaskEvaluationSubjectScoresForTask(taskId: number): Observable<TaskEvaluationSubjectScoreInterface[]> {
    throw new Error('Not implemented yet');
  }

  setTaskEvaluationSubjectScoresForTask(
    taskId: number,
    taskEvaluationSubjectScores: TaskEvaluationSubjectScoreInterface[]
  ): Observable<SetTaskEvaluationSubjectScoresForTaskResponseInterface> {
    return this.taskEvaluationSubjectScoreApi.setTaskEvaluationSubjectScoresForTask(
      taskId,
      taskEvaluationSubjectScores
    ) as unknown as Observable<SetTaskEvaluationSubjectScoresForTaskResponseInterface>;
  }
}
