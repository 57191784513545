import { InjectionToken } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Observable } from 'rxjs';
import {
  AchievementInterface,
  AlgebrakitSessionInterface,
  ArtifactInterface,
  AtlasProductInterface,
  AtlasSchoolInterface,
  BadgeInterface,
  BundleClassGroupInterface,
  BundleGroupInterface,
  BundleInterface,
  BundlePersonInterface,
  ClassGroupInterface,
  ConceptInterface,
  ConceptYearInterface,
  CurriculumInterface,
  CurriculumTreeConceptInterface,
  CurriculumTreeInterface,
  CurriculumTreeYearInterface,
  DiaboloPhaseInterface,
  EditorStatusInterface,
  EduContentBookInterface,
  EduContentInterface,
  EduContentMetadataEvaluationSubjectInterface,
  EduContentMetadataInterface,
  EduContentProductTypeInterface,
  EduContentSourceInterface,
  EduContentTagInterface,
  EduContentTOCEduContentInterface,
  EduContentTOCEduContentMetadataInterface,
  EduContentTOCEvaluationInterface,
  EduContentTOCInterface,
  EduContentTOCLoopInterface,
  EduNetInterface,
  EvaluationInterface,
  EvaluationScoreInterface,
  EvaluationScoreListInterface,
  EvaluationScoreListSubjectInterface,
  EvaluationSubjectGoalInterface,
  EvaluationSubjectInterface,
  GoalEduContentTOCInterface,
  GoalInterface,
  GoalPrerequisiteInterface,
  GoalTopicInterface,
  GroupInterface,
  GroupPersonInterface,
  HistoryInterface,
  LearnerProfileConceptInterface,
  LearnerProfileStrandInterface,
  LearningAreaInterface,
  LearningDomainInterface,
  LearningPlanGoalInterface,
  LearningPlanGoalProgressInterface,
  LessonSheetInterface,
  LevelInterface,
  LicenseInterface,
  LoopInterface,
  LoopLearnerStatusInterface,
  MethodGoalInterface,
  MethodInterface,
  MethodLevelInterface,
  MethodNewsInterface,
  MethodOptionInterface,
  MethodProductGroupInterface,
  MethodProductGroupProductInterface,
  MethodStyleInterface,
  NewsCategoryInterface,
  NewsCategoryNewsInterface,
  NewsInterface,
  NewsProductInterface,
  NewsRoleInterface,
  OlfLearningDomainInterface,
  PassportUserCredentialInterface,
  PersonInterface,
  PersonPreferenceInterface,
  PersonProductInterface,
  ProductContentInterface,
  ProductInterface,
  PurchaseInterface,
  PurchaseProductInterface,
  ResultInterface,
  RoleInterface,
  SchoolBillingAddressInterface,
  SchoolBillingInterface,
  SchoolInterface,
  SchoolProviderInterface,
  SchoolRoleMappingClassGroupInterface,
  SchoolRoleMappingInterface,
  SectionContentInterface,
  SectionInterface,
  SurveyAnswerInterface,
  SurveyInterface,
  SurveyQuestionInterface,
  TaskClassGroupInterface,
  TaskEduContentInterface,
  TaskEduContentTaskGoalYearLevelInterface,
  TaskEvaluationSubjectInterface,
  TaskEvaluationSubjectScoreInterface,
  TaskGoalYearLevelInterface,
  TaskGroupInterface,
  TaskInstanceInterface,
  TaskInterface,
  TaskLevelSettingInterface,
  TaskStudentInterface,
  TeacherStudentInterface,
  UnlockedBoekeGroupInterface,
  UnlockedBoekeStudentInterface,
  UnlockedContentInterface,
  UnlockedCurriculumTreeInterface,
  UnlockedFreePracticeInterface,
  UserContentInterface,
  UserLessonInterface,
  YearInterface,
} from '../+models';
import { DidacticFunctionInterface } from '../+models/DidacticFunction.interface';
import { GoalYearInterface } from '../+models/GoalYear.interface';
import { LinkInterface } from '../+models/Link.interface';

export interface DataResponseInterface
  extends PersonDataResponseInterface,
    SchoolDataResponseInterface,
    TaskDataResponseInterface,
    BookDataResponseInterface,
    TocDataResponseInterface {}

export interface PersonDataResponseInterface {
  algebrakitSessions?: AlgebrakitSessionInterface[];
  eduContentTOCLoops?: EduContentTOCLoopInterface[];
  eduContents?: EduContentInterface[];
  eduContentsForResults?: EduContentInterface[];
  userContents?: UserContentInterface[];
  unlockedContents?: UnlockedContentInterface[];
  bundles?: BundleInterface[];
  unlockedBoekeStudents?: UnlockedBoekeStudentInterface[];
  unlockedBoekeGroups?: UnlockedBoekeGroupInterface[];
  tasks?: TaskInterface[];
  taskEduContents?: TaskEduContentInterface[];
  taskInstances?: TaskInstanceInterface[];
  teacherStudents?: TeacherStudentInterface[];
  persons?: PersonInterface[];
  allResults?: ResultInterface[];
  results?: ResultInterface[];
  permissions?: string[];
  methods?: MethodInterface[];
  inkMethods?: MethodInterface[];
  allowedMethods?: MethodInterface[];
  userLessons?: UserLessonInterface[];
  classGroups?: ClassGroupInterface[];
  learningPlanGoalProgress?: LearningPlanGoalProgressInterface[];
  unlockedFreePractices?: UnlockedFreePracticeInterface[];
  colleagues?: PersonInterface[];
  methodLevels?: MethodLevelInterface[];
  groups?: GroupInterface[];
  groupPersons?: GroupPersonInterface[];
  taskStudents?: TaskStudentInterface[];
  taskGroups?: TaskGroupInterface[];
  taskClassGroups?: TaskClassGroupInterface[];
  history?: HistoryInterface[];
  allowedEduContentBooks?: EduContentBookInterface[];
  methodGoals?: MethodGoalInterface[];
  schools?: SchoolInterface[];
  schoolProviders?: SchoolProviderInterface[];
  schoolRoleMappings?: SchoolRoleMappingInterface[];
  roles?: RoleInterface[];
  schoolRoleMappingClassGroups?: SchoolRoleMappingClassGroupInterface[];
  products?: ProductInterface[];
  productContents?: ProductContentInterface[];
  methodProductGroups?: MethodProductGroupInterface[];
  methodProductGroupProducts?: MethodProductGroupProductInterface[];
  licenses?: LicenseInterface[];
  purchases?: PurchaseInterface[];
  purchaseProducts?: PurchaseProductInterface[];
  atlasProducts?: AtlasProductInterface[];
  atlasSchools?: AtlasSchoolInterface[];
  schoolBillings?: SchoolBillingInterface[];
  loops?: LoopInterface[];
  unlockedCurriculumTrees?: UnlockedCurriculumTreeInterface[];
  badges?: BadgeInterface[];
  achievements?: AchievementInterface[];
  curriculums?: CurriculumInterface[];
  curriculumTrees?: CurriculumTreeInterface[];
  curriculumTreeConcepts?: CurriculumTreeConceptInterface[];
  curriculumTreeYears?: CurriculumTreeYearInterface[];
  concepts?: ConceptInterface[];
  conceptYears?: ConceptYearInterface[];
  surveys?: SurveyInterface[];
  surveyQuestions?: SurveyQuestionInterface[];
  surveyAnswers?: SurveyAnswerInterface[];
  learnerProfileStrands?: LearnerProfileStrandInterface[];
  learnerProfileConcepts?: LearnerProfileConceptInterface[];
  schoolBillingAddresses?: SchoolBillingAddressInterface[];
  learningAreas?: LearningAreaInterface[];
  diaboloPhases?: DiaboloPhaseInterface[];
  years?: YearInterface[];
  levels?: LevelInterface[];
  eduContentProductTypes?: EduContentProductTypeInterface[];
  didacticFunctions?: DidacticFunctionInterface[];
  eduNets?: EduNetInterface[];
  learningDomains?: LearningDomainInterface[];
  olfLearningDomains?: OlfLearningDomainInterface[];
  goalTopics?: GoalTopicInterface[];
  goals?: GoalInterface[];
  goalYears?: GoalYearInterface[];
  editorStatuses?: EditorStatusInterface[];
  personPreferences?: PersonPreferenceInterface[];
  personProducts?: PersonProductInterface[];
  bundleClassGroups?: BundleClassGroupInterface[];
  bundleGroups?: BundleGroupInterface[];
  bundlePersons?: BundlePersonInterface[];
  credentials?: PassportUserCredentialInterface[];
  goalPrerequisites?: GoalPrerequisiteInterface[];
  evaluations?: EvaluationInterface[];
  methodOptions?: MethodOptionInterface[];
  evaluationScoreLists?: EvaluationScoreListInterface[];
  evaluationScoreListSubjects?: EvaluationScoreListSubjectInterface[];
  evaluationScores?: EvaluationScoreInterface[];
  news?: NewsInterface[];
  newsCategories?: NewsCategoryInterface[];
  newsCategoryNews?: NewsCategoryNewsInterface[];
  methodNews?: MethodNewsInterface[];
  newsProducts?: NewsProductInterface[];
  newsRoles?: NewsRoleInterface[];
  eduContentTags?: EduContentTagInterface;
  eduContentSources?: EduContentSourceInterface[];
}

export type SchoolDataResponseInterface = Partial<
  Pick<
    PersonDataResponseInterface,
    | 'persons'
    | 'classGroups'
    | 'purchases'
    | 'purchaseProducts'
    | 'products'
    | 'licenses'
    | 'schoolRoleMappings'
    | 'schoolRoleMappingClassGroups'
    | 'schoolBillingAddresses'
    | 'schoolBillings'
    | 'credentials'
  >
>;

export interface TaskDataResponseInterface {
  eduContents?: EduContentInterface[];
  taskEvaluationSubjects?: TaskEvaluationSubjectInterface[];
  taskEvaluationSubjectScores?: TaskEvaluationSubjectScoreInterface[];
  taskGoalYearLevels?: TaskGoalYearLevelInterface[];
  taskLevelSettings?: TaskLevelSettingInterface[];
  taskEduContentTaskGoalYearLevels?: TaskEduContentTaskGoalYearLevelInterface[];
  evaluationScores?: EvaluationScoreInterface[];
  evaluationScoreListSubjects?: EvaluationScoreListSubjectInterface[];
  evaluationScoreLists?: EvaluationScoreListInterface[];
  evaluationSubjects?: EvaluationSubjectInterface[];
  taskEduContents?: TaskEduContentInterface[];
}

export interface BookDataResponseInterface {
  eduContentTocs?: EduContentTOCInterface[];
  eduContentTocEduContents?: EduContentTOCEduContentInterface[];
  learningPlanGoals?: LearningPlanGoalInterface[];
  methodGoals?: MethodGoalInterface[];
  evaluationSubjects?: EvaluationSubjectInterface[];
  evaluationSubjectGoals?: EvaluationSubjectGoalInterface[];
  eduContentTocEduContentMetadata?: EduContentTOCEduContentMetadataInterface[];
  eduContentMetadata?: EduContentMetadataInterface[];
  eduContentMetadataDraft?: EduContentMetadataInterface[];
  eduContentMetadataPublished?: EduContentMetadataInterface[];
  eduContents?: EduContentInterface[];
  generalFiles?: EduContentInterface[];
  goalEduContentTOCs?: GoalEduContentTOCInterface[];
  goals?: GoalInterface[];
  eduContentTOCLoops?: EduContentTOCLoopInterface[];
  goalTopics?: GoalTopicInterface[];
  sections?: SectionInterface[];
  sectionContents?: SectionContentInterface[];
  sectionEduContents?: EduContentInterface[];
  games?: EduContentInterface[];
  loopLearnerStatuses?: LoopLearnerStatusInterface[];
  eduContentMetadataEvaluationSubjects?: EduContentMetadataEvaluationSubjectInterface[];
  evaluations?: EvaluationInterface[];
  eduContentTocEvaluations?: EduContentTOCEvaluationInterface[];
  methodOptions?: MethodOptionInterface[];
  artifacts?: ArtifactInterface[];
  methodStyle?: MethodStyleInterface;
  lessonSheets?: LessonSheetInterface[];
  links?: LinkInterface[];
  productContents?: ProductContentInterface[];
  learningPlanGoalIdsByTocId?: Dictionary<number[]>;
}
export interface TocDataResponseInterface {
  loopLearnerStatuses?: LoopLearnerStatusInterface[];
  sections?: SectionInterface[];
  sectionContents?: SectionContentInterface[];
  sectionEduContents?: EduContentInterface[];
}

export const DATA_SERVICE_TOKEN = new InjectionToken('DataService');

export interface DataServiceInterface {
  getAllForUser(
    userId: number,
    fields: Array<keyof PersonDataResponseInterface>,
    since?: number,
    v2?: boolean
  ): Observable<PersonDataResponseInterface>;

  getAllForSchool(schoolId: number): Observable<SchoolDataResponseInterface>;

  getAllForTask(taskId: number, fields: Array<keyof TaskDataResponseInterface>): Observable<TaskDataResponseInterface>;

  getAllForBook(bookId: number, fields: Array<keyof BookDataResponseInterface>): Observable<BookDataResponseInterface>;
  getAllForToc(tocId: number, fields: Array<keyof TocDataResponseInterface>): Observable<TocDataResponseInterface>;
}
