import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { Action, Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  BookDataResponseInterface,
  DataServiceInterface,
  DATA_SERVICE_TOKEN,
} from '../../../data/data.service.interface';
import { DalState } from '../../dal.state.interface';

import { DATA_FIELD_MAPPERS_TOKEN, FieldMappersInterface, formatData } from '../mappers/data-field-mappers';
import { DataFieldActionFactory } from './book-data-field-action.factory';
import { BookDataActionTypes, BookDataLoaded, BookDataLoadError, LoadBookData } from './book-data.actions';

@Injectable()
export class BookDataEffects {
  loadBookData$ = createEffect(() =>
    this.actions.pipe(
      ofType(BookDataActionTypes.LoadBookData),
      concatLatestFrom(() => this.store),
      fetch({
        run: (action: LoadBookData, state) => {
          const { bookId } = action.payload;
          let { fields } = action.payload;

          // Filter out what isn't yet loaded or doesn't exist in the state
          if (!action.payload.force) {
            fields = fields.filter((field) => {
              const stateName = this.getStateName(field, state);
              const affectedState = state[stateName];

              if (!affectedState) return false;

              const loadedFlag = this.getLoadedFlag(field);

              const checkLoaded = (_state: DalState, _bookId: number): boolean => !_state[loadedFlag]?.[_bookId];
              return checkLoaded(affectedState, bookId);
            });
          }

          return this.dataService
            .getAllForBook(bookId, fields)
            .pipe(
              switchMap((dataForBook) =>
                from([...this.getLoadActions(bookId, dataForBook).filter(Boolean), new BookDataLoaded({ bookId })])
              )
            );
        },
        onError: (action: LoadBookData, error) => {
          return new BookDataLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    @Inject(DATA_SERVICE_TOKEN)
    private dataService: DataServiceInterface,
    @Inject(DATA_FIELD_MAPPERS_TOKEN)
    private fieldMappers: FieldMappersInterface,
    private store: Store<DalState>
  ) {}

  private getStateName(field: keyof BookDataResponseInterface, state: DalState): string {
    const stateNameExceptions: Dictionary<string> = {
      eduContentMetadata: 'eduContentMetadatas',
      eduContentMetadataDraft: 'eduContentMetadatas',
      eduContentMetadataPublished: 'eduContentMetadatas',
      eduContentTocEduContentMetadata: 'eduContentTOCEduContentMetadata',
      generalFiles: 'eduContents',
      games: 'eduContents',
      sectionEduContents: 'eduContents',
      methodStyle: 'methodStyles',
      learningPlanGoalIdsByTocId: 'learningPlanGoals',
    };

    const stateName = stateNameExceptions[field] || field;

    if (!state[stateName]) {
      throw new Error(`loading unknown state: ${stateName}`);
    }

    return stateName;
  }

  private getLoadedFlag(field: keyof BookDataResponseInterface): string {
    const loadedFlagExceptions: Dictionary<string> = {
      generalFiles: 'loadedGeneralFilesForBook',
      games: 'loadedGamesForBook',
      sectionEduContents: 'loadedForSectionsForBook',
      eduContentMetadataDraft: 'loadedDraftForBook',
      eduContentMetadataPublished: 'loadedPublishedForBook',
      artifacts: 'loadedArtifactsForBook',
    };

    const loadedFlag = loadedFlagExceptions[field] || 'loadedForBook';

    return loadedFlag;
  }

  private getLoadActions(bookId: number, data: BookDataResponseInterface): Action[] {
    return this.getActions(DataFieldActionFactory.getLoadedAction)(bookId, data);
  }

  private getUpsertActions(bookId: number, data: BookDataResponseInterface): Action[] {
    return this.getActions(DataFieldActionFactory.getUpsertAction)(bookId, data);
  }

  private getActions(
    getActionFn: (bookId: number, field: string, formattedData: any, data?: BookDataResponseInterface) => Action
  ) {
    return (bookId: number, data: BookDataResponseInterface) =>
      Object.keys(data)
        .filter((field) => data[field] !== undefined)
        .map((field: keyof BookDataResponseInterface) => {
          const formattedData = formatData(field, data, this.fieldMappers);
          return getActionFn(bookId, field, formattedData, data);
        });
  }
}
