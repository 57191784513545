import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { EvaluationScoreInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum EvaluationScoresActionTypes {
  EvaluationScoresLoaded = '[EvaluationScores] EvaluationScores Loaded',
  EvaluationScoresLoadError = '[EvaluationScores] Load Error',
  AddEvaluationScore = '[EvaluationScores] Add EvaluationScore',
  AddEvaluationScores = '[EvaluationScores] Add EvaluationScores',
  AddEvaluationScoresForTask = '[EvaluationScores] Add EvaluationScores for Task',
  UpsertEvaluationScore = '[EvaluationScores] Upsert EvaluationScore',
  UpsertEvaluationScores = '[EvaluationScores] Upsert EvaluationScores',
  UpdateEvaluationScore = '[EvaluationScores] Update EvaluationScore',
  UpdateEvaluationScores = '[EvaluationScores] Update EvaluationScores',
  DeleteEvaluationScore = '[EvaluationScores] Delete EvaluationScore',
  DeleteEvaluationScores = '[EvaluationScores] Delete EvaluationScores',
  ClearEvaluationScores = '[EvaluationScores] Clear EvaluationScores',
  StartSetEvaluationScoresForTask = '[EvaluationScores] Start Set EvaluationScores for task',
  SetEvaluationScoresForTask = '[EvaluationScores] Set EvaluationScores for task',
}

export class EvaluationScoresLoaded implements Action {
  readonly type = EvaluationScoresActionTypes.EvaluationScoresLoaded;

  constructor(public payload: { evaluationScores: EvaluationScoreInterface[] }) {}
}

export class EvaluationScoresLoadError implements Action {
  readonly type = EvaluationScoresActionTypes.EvaluationScoresLoadError;
  constructor(public payload: any) {}
}

export class AddEvaluationScore implements Action {
  readonly type = EvaluationScoresActionTypes.AddEvaluationScore;

  constructor(public payload: { evaluationScore: EvaluationScoreInterface }) {}
}

export class UpsertEvaluationScore implements Action {
  readonly type = EvaluationScoresActionTypes.UpsertEvaluationScore;

  constructor(public payload: { evaluationScore: EvaluationScoreInterface }) {}
}

export class AddEvaluationScores implements Action {
  readonly type = EvaluationScoresActionTypes.AddEvaluationScores;

  constructor(public payload: { evaluationScores: EvaluationScoreInterface[] }) {}
}

export class AddEvaluationScoresForTask implements Action {
  readonly type = EvaluationScoresActionTypes.AddEvaluationScoresForTask;

  constructor(public payload: { evaluationScores: EvaluationScoreInterface[]; taskId: number }) {}
}

export class UpsertEvaluationScores implements Action {
  readonly type = EvaluationScoresActionTypes.UpsertEvaluationScores;

  constructor(public payload: { evaluationScores: EvaluationScoreInterface[] }) {}
}

export class UpdateEvaluationScore implements Action {
  readonly type = EvaluationScoresActionTypes.UpdateEvaluationScore;

  constructor(public payload: { evaluationScore: Update<EvaluationScoreInterface> }) {}
}

export class UpdateEvaluationScores implements Action {
  readonly type = EvaluationScoresActionTypes.UpdateEvaluationScores;

  constructor(public payload: { evaluationScores: Update<EvaluationScoreInterface>[] }) {}
}

export class DeleteEvaluationScore implements FeedbackTriggeringAction {
  readonly type = EvaluationScoresActionTypes.DeleteEvaluationScore;

  constructor(public payload: { id: number; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export class DeleteEvaluationScores implements FeedbackTriggeringAction {
  readonly type = EvaluationScoresActionTypes.DeleteEvaluationScores;

  constructor(public payload: { ids: number[]; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export class ClearEvaluationScores implements Action {
  readonly type = EvaluationScoresActionTypes.ClearEvaluationScores;
}

export class StartSetEvaluationScoresForTask implements Action, FeedbackTriggeringAction {
  readonly type = EvaluationScoresActionTypes.StartSetEvaluationScoresForTask;

  constructor(
    public payload: {
      taskId: number;
      evaluationScores: EvaluationScoreInterface[];
      evaluationScoreListSubjectIds: number[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class SetEvaluationScoresForTask implements Action {
  readonly type = EvaluationScoresActionTypes.SetEvaluationScoresForTask;

  constructor(
    public payload: {
      taskId: number;
      evaluationScores: EvaluationScoreInterface[];
      evaluationScoreListSubjectIds: number[];
    }
  ) {}
}

export type EvaluationScoresActions =
  | EvaluationScoresLoaded
  | EvaluationScoresLoadError
  | AddEvaluationScore
  | AddEvaluationScoresForTask
  | UpsertEvaluationScore
  | AddEvaluationScores
  | UpsertEvaluationScores
  | UpdateEvaluationScore
  | UpdateEvaluationScores
  | DeleteEvaluationScore
  | DeleteEvaluationScores
  | ClearEvaluationScores
  | StartSetEvaluationScoresForTask
  | SetEvaluationScoresForTask;
