import { groupArrayByKeys } from '@campus/utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NewsRoleInterface } from '../../+models';
import { NAME, State, selectAll, selectEntities, selectIds, selectTotal } from './news-role.reducer';

export const selectNewsRoleState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectNewsRoleState, (state: State) => state.error);

export const getLoaded = createSelector(selectNewsRoleState, (state: State) => state.loaded);

export const getAll = createSelector(selectNewsRoleState, selectAll);

export const getCount = createSelector(selectNewsRoleState, selectTotal);

export const getIds = createSelector(selectNewsRoleState, selectIds);

export const getAllEntities = createSelector(selectNewsRoleState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * role$: NewsRoleInterface[] = this.store.pipe(
    select(NewsRoleQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectNewsRoleState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * role$: NewsRoleInterface = this.store.pipe(
    select(NewsRoleQueries.getById, { id: 3 })
  );
 */
export const getById = (props: { id: number }) =>
  createSelector(selectNewsRoleState, (state: State) => state.entities[props.id]);

export const getByNewsId = createSelector(getAll, (newsRoles: NewsRoleInterface[]) => {
  return groupArrayByKeys(newsRoles, ['newsId']);
});
