import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { SearchFilterComponentInterface } from '../../interfaces/search-filter-component-interface';
import {
  SearchFilterCriteriaInterface,
  SearchFilterCriteriaValuesInterface,
} from '../../interfaces/search-filter-criteria.interface';

@Component({
  selector: 'campus-chip-toggle-filter',
  templateUrl: './chip-toggle-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipToggleFilterComponent implements SearchFilterComponentInterface {
  public criteria: SearchFilterCriteriaInterface;

  @HostBinding('class.chip-toggle-filter-component')
  isChipToggleFilterComponentClass = true;

  @Input() multiple = false;
  @Input() disabled = false;
  @Input()
  public set filterCriteria(criteria: SearchFilterCriteriaInterface) {
    this.criteria = criteria;
  }

  public get filterCriteria() {
    return this.criteria;
  }

  @Input()
  public set filterOptions(value: { multiple: boolean }) {
    if (value && value.multiple !== undefined) {
      this.multiple = value.multiple;
    }
  }

  @Output() filterSelectionChange: EventEmitter<SearchFilterCriteriaInterface[]> = new EventEmitter();

  constructor(@Inject(ChangeDetectorRef) private cd: ChangeDetectorRef) {}

  public reset(emit = true) {
    this.criteria.values.forEach((criteriaValue) => (criteriaValue.selected = false));
    this.cd.markForCheck();

    if (emit) {
      this.filterSelectionChange.emit([this.criteria]);
    }
  }

  public selectCriteriaValue(value: SearchFilterCriteriaValuesInterface) {
    if (!this.multiple) {
      this.criteria.values.forEach((criteriaValue) => (criteriaValue.selected = false));
    }

    value.selected = !value.selected;
    this.cd.markForCheck();

    this.filterSelectionChange.emit([this.criteria]);
  }
}
