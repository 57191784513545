import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './concept-year.reducer';

export const selectConceptYearState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectConceptYearState, (state: State) => state.error);

export const getLoaded = createSelector(selectConceptYearState, (state: State) => state.loaded);

export const getAll = createSelector(selectConceptYearState, selectAll);

export const getCount = createSelector(selectConceptYearState, selectTotal);

export const getIds = createSelector(selectConceptYearState, selectIds);

export const getAllEntities = createSelector(selectConceptYearState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * conceptYear$: ConceptYearInterface[] = this.store.pipe(
    select(ConceptYearQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectConceptYearState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * conceptYear$: ConceptYearInterface = this.store.pipe(
    select(ConceptYearQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectConceptYearState,
  (state: State, props: { id: number }) => state.entities[props.id]
);
