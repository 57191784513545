import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EduContentBookInterface, MethodInterface, YearInterface } from '../../+models';
import {
  getAll as getAllEduContentBooks,
  getById as getByIdEduContentBooks,
} from '../edu-content-book/edu-content-book.selectors';
import { LearningAreaQueries } from '../learning-area';
import { State as LearningAreaState } from '../learning-area/learning-area.reducer';
import { selectLearningAreaState } from '../learning-area/learning-area.selectors';
import { YearQueries } from '../year';
import { State as YearState } from '../year/year.reducer';
import { selectYearState } from '../year/year.selectors';
import { LearningAreaInterface } from './../../+models/LearningArea.interface';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './method.reducer';

export const selectMethodState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectMethodState, (state: State) => state.error);

export const getLoaded = createSelector(selectMethodState, (state: State) => state.loaded);

export const getAll = createSelector(selectMethodState, selectAll);

export const getAllOrderedByName = createSelector(selectMethodState, (state: State) =>
  Object.values(state.entities).sort((a, b) => a.name.localeCompare(b.name))
);

export const getCount = createSelector(selectMethodState, selectTotal);

export const getIds = createSelector(selectMethodState, selectIds);

export const getAllEntities = createSelector(selectMethodState, selectEntities);

/**
 * Utility to return all entities for the provided ids
 *
 * @param {State} state The method state
 * @param {number[]} ids The ids of the entities you want
 * @returns {MethodInterface[]}
 */
const getMethodsById = (state: State, ids: number[]): MethodInterface[] => ids.map((id) => state.entities[id]);

/**
 * returns array of objects in the order of the given ids
 * @example
 * method$: MethodInterface[] = this.store.pipe(
    select(MethodQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectMethodState, (state: State, props: { ids: number[] }) => {
  return getMethodsById(state, props.ids);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * method$: MethodInterface = this.store.pipe(
    select(MethodQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectMethodState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

/**
 * returns array of objects filtered by learning area id as key
 */
export const getForLearningAreaId = createSelector(
  selectMethodState,
  (state: State, props: { learningAreaId: number }) => {
    return Object.values(state.entities).filter((method) => method.learningAreaId === props.learningAreaId);
  }
);

/**
 * returns array of objects filtered by learning area ids as key
 */
export const getForLearningAreaIds = createSelector(
  selectMethodState,
  (state: State, props: { learningAreaIds: number[] }) => {
    return Object.values(state.entities).filter((method) =>
      props.learningAreaIds.some((learningAreaId) => method.learningAreaId === learningAreaId)
    );
  }
);

export const getByLearningAreaId = createSelector(getAll, (methods: MethodInterface[]) =>
  groupArrayByKeys(methods, ['learningAreaId'])
);

export const getAllowedMethodsLoaded = createSelector(selectMethodState, (state: State) => {
  return state.allowedMethodsLoaded;
});

export const getAllowedMethodIds = createSelector(selectMethodState, (state: State) => state.allowedMethods);

export const getAllowedMethods = createSelector(selectMethodState, (state) => {
  const { ids, allowedMethods } = state;

  const allowedSet = new Set(allowedMethods);

  // order allowed method ids like in the state.ids property
  const allowedMethodIds = (ids as number[]).filter((id) => allowedSet.has(id));

  return getMethodsById(state, allowedMethodIds);
});

export const getAllowedAdaptiveMethods = createSelector(getAllowedMethods, (allowedMethods: MethodInterface[]) => {
  return allowedMethods.filter((method) => method.isAdaptive);
});

export const isAllowedMethod = createSelector(selectMethodState, (state: State, props: { id: number }) => {
  return state.allowedMethods.some((id) => id === props.id);
});

/**
 * returns a string representation of the method with it's year and learning area
 * e.g. 'Katapult 1 (wiskunde)'
 */
export const getMethodWithLearningAreaAndYearByBookId = createSelector(
  selectMethodState,
  selectYearState,
  selectLearningAreaState,
  getByIdEduContentBooks,
  (
    methodState: State,
    yearState: YearState,
    learningAreaState: LearningAreaState,
    eduContentBook: EduContentBookInterface
  ) => {
    const method = methodState.entities[eduContentBook.methodId];
    return `${method.name} ${yearState.entities[eduContentBook.years[0].id].name.substring(1)} (${
      learningAreaState.entities[method.learningAreaId].name
    })`;
  }
);

export const getMethodLogoByBookId = createSelector(
  selectMethodState,
  getByIdEduContentBooks,
  (methodState: State, eduContentBook: EduContentBookInterface) => {
    if (!eduContentBook) return '';

    return methodState.entities[eduContentBook.methodId].logoUrl;
  }
);

const getYearsByMethodId = createSelector(
  getAllEduContentBooks,
  YearQueries.getAllEntities,
  (eduContentBooks, yearsDict): { [methodId: string]: YearInterface[] } => {
    const uniqueYearsDict = {};
    const methodsMap = eduContentBooks.reduce((acc, book) => {
      if (!acc[book.methodId]) {
        acc[book.methodId] = [];
        uniqueYearsDict[book.methodId] = {};
      }

      const uniqueYears = uniqueYearsDict[book.methodId];
      book.years.forEach((year) => {
        if (uniqueYears[year.id]) return;

        uniqueYears[year.id] = true;
        acc[book.methodId].push({ ...yearsDict[year.id] });
      });

      return acc;
    }, {});

    return Object.keys(methodsMap).reduce(
      (obj, key) => ({
        ...obj,
        [key]: methodsMap[key].sort((a, b) => a.name.localeCompare(b.name)),
      }),
      {}
    );
  }
);

export const getYearsForAllowedMethods = createSelector(
  getYearsByMethodId,
  getAllowedMethodIds,
  (yearsByMethodId, allowedMethodIds) => {
    const uniqueYearsDict = {};
    const uniqueYears = [];

    allowedMethodIds.forEach((id) => {
      if (!yearsByMethodId[id]) return;

      yearsByMethodId[id].forEach((yearInMethod) => {
        if (uniqueYearsDict[yearInMethod.id]) return;

        uniqueYearsDict[yearInMethod.id] = true;

        const { ...year } = yearInMethod;
        uniqueYears.push(year);
      });
    });

    return uniqueYears.sort((a, b) => a.name.localeCompare(b.name));
  }
);

export const getAllowedLearningAreas = createSelector(
  LearningAreaQueries.getAllEntities,
  getAllowedMethods,
  (learningAreaDict: Dictionary<LearningAreaInterface>, allowMethods: MethodInterface[]) => {
    const learningAreaIds = Array.from(new Set(allowMethods.map((method) => method.learningAreaId)));
    return learningAreaIds.map((learningAreaId) => learningAreaDict[learningAreaId]);
  }
);
