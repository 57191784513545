import { groupArrayByKeys } from '@campus/utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EduFileInterface } from '../../+models';
import { NAME, State, selectAll, selectEntities } from './edu-file.reducer';

export const selectEduFileState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectEduFileState, (state: State) => state.error);

export const getLoaded = createSelector(selectEduFileState, (state: State) => state.loaded);

export const getAll = createSelector(selectEduFileState, selectAll);

export const getAllEntities = createSelector(selectEduFileState, selectEntities);

export const getByArtifactId = createSelector(getAll, (eduFiles: EduFileInterface[]) =>
  groupArrayByKeys(eduFiles, ['artifactId'], undefined, true)
);

export const getByEduContentMetadataId = createSelector(getAll, (eduFiles: EduFileInterface[]) =>
  groupArrayByKeys(eduFiles, ['eduContentMetadataId'], undefined, false)
);
