import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import {
  EduContent,
  PrintSize,
  TaskClassGroupInterface,
  TaskGroupInterface,
  TaskInterface,
  TaskStudentInterface,
} from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum TasksActionTypes {
  TasksLoaded = '[Tasks] Tasks Loaded',
  TasksLoadError = '[Tasks] Load Error',
  LoadTasks = '[Tasks] Load Tasks',
  AddTask = '[Tasks] Add Task',
  UpsertTask = '[Tasks] Upsert Task',
  AddTasks = '[Tasks] Add Tasks',
  UpsertTasks = '[Tasks] Upsert Tasks',
  UpdateTask = '[Tasks] Update Task',
  UpdateTasks = '[Tasks] Update Tasks',
  StartArchiveTasks = '[Tasks] Start Archive Tasks',
  StartEvaluationTask = '[Tasks] Start Evaluation Task',
  StopEvaluationTask = '[Tasks] Stop Evaluation Task',
  DeleteTask = '[Tasks] Delete Task',
  DeleteTasks = '[Tasks] Delete Tasks',
  ClearTasks = '[Tasks] Clear Tasks',
  UpdateAccess = '[Tasks] Update Access',
  StartDeleteTasks = '[Tasks] Start Delete Tasks',
  StartDeleteEvaluationTasks = '[Tasks] Start Delete Evaluation Tasks',
  StartAddTask = '[Tasks] Start Add Task',
  StartAddTaskWithAssignees = '[Tasks] Start Add Task With Assignees',
  StartDuplicateTask = '[Tasks] Start Duplicate Task',
  TaskDuplicated = '[Tasks] Task Was Duplicated',
  NavigateToTaskDetail = '[Tasks] Navigate To Task Detail',
  NavigateToTasksOverview = '[Tasks] Navigate To Task Overview',
  NavigateToAdaptiveTaskWizard = '[Tasks] Navigate To Adaptive Task Wizard',
  PrintPaperTaskSolution = '[Tasks] Print paper task solution',
  StartAddEvaluationTask = '[Tasks] Start Add Evaluation Task',
  NavigateToEvaluationTaskDetail = '[Tasks] Navigate To Evaluation Task Detail',
  SetEvaluationSubjectsForTask = '[Tasks] Set Evaluation Subjects For Task',
}

export class LoadTasks implements Action {
  readonly type = TasksActionTypes.LoadTasks;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class TasksLoaded implements Action {
  readonly type = TasksActionTypes.TasksLoaded;

  constructor(public payload: { tasks: TaskInterface[] }) {}
}

export class TasksLoadError implements Action {
  readonly type = TasksActionTypes.TasksLoadError;
  constructor(public payload: any) {}
}

export class AddTask implements Action {
  readonly type = TasksActionTypes.AddTask;

  constructor(public payload: { task: TaskInterface }) {}
}

export class UpsertTask implements Action {
  readonly type = TasksActionTypes.UpsertTask;

  constructor(public payload: { task: TaskInterface }) {}
}

export class AddTasks implements Action {
  readonly type = TasksActionTypes.AddTasks;

  constructor(public payload: { tasks: TaskInterface[] }) {}
}

export class UpsertTasks implements Action {
  readonly type = TasksActionTypes.UpsertTasks;

  constructor(public payload: { tasks: TaskInterface[] }) {}
}

export class UpdateTask implements FeedbackTriggeringAction {
  readonly type = TasksActionTypes.UpdateTask;

  constructor(
    public payload: {
      userId: number;
      task: Update<TaskInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpdateTasks implements Action {
  readonly type = TasksActionTypes.UpdateTasks;

  constructor(
    public payload: {
      userId: number;
      tasks: Update<TaskInterface>[];
    }
  ) {}
}
export class StartArchiveTasks implements FeedbackTriggeringAction {
  readonly type = TasksActionTypes.StartArchiveTasks;

  constructor(
    public payload: {
      userId: number;
      tasks: Update<TaskInterface>[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartEvaluationTask implements FeedbackTriggeringAction {
  readonly type = TasksActionTypes.StartEvaluationTask;

  constructor(
    public payload: {
      userId: number;
      taskId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StopEvaluationTask implements FeedbackTriggeringAction {
  readonly type = TasksActionTypes.StopEvaluationTask;

  constructor(
    public payload: {
      userId: number;
      taskId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class DeleteTask implements Action {
  readonly type = TasksActionTypes.DeleteTask;

  constructor(public payload: { id: number }) {}
}

export class DeleteTasks implements Action {
  readonly type = TasksActionTypes.DeleteTasks;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearTasks implements Action {
  readonly type = TasksActionTypes.ClearTasks;
}

export class UpdateAccess implements FeedbackTriggeringAction {
  readonly type = TasksActionTypes.UpdateAccess;
  constructor(
    public payload: {
      userId: number;
      taskId: number;
      taskGroups: TaskGroupInterface[];
      taskStudents: TaskStudentInterface[];
      taskClassGroups: TaskClassGroupInterface[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}
export class StartDeleteTasks implements FeedbackTriggeringAction {
  readonly type = TasksActionTypes.StartDeleteTasks;

  constructor(
    public payload: {
      ids: number[];
      userId: number;
      navigateAfterDelete?: boolean;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartDeleteEvaluationTasks implements FeedbackTriggeringAction {
  readonly type = TasksActionTypes.StartDeleteEvaluationTasks;

  constructor(
    public payload: {
      ids: number[];
      userId: number;
      navigateAfterDelete?: boolean;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartAddTask implements FeedbackTriggeringAction {
  readonly type = TasksActionTypes.StartAddTask;

  constructor(
    public payload: {
      task: TaskInterface;
      userId: number;
      navigateAfterCreate?: boolean;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
      assignTo?: {
        groupId?: number;
        classGroupId?: number;
        start: Date;
        end: Date;
      };
      linkEduContent?: {
        eduContentIds: number[];
      };
      writeToHistory?: boolean;
    }
  ) {}
}

export class StartAddTaskWithAssignees implements FeedbackTriggeringAction {
  readonly type = TasksActionTypes.StartAddTaskWithAssignees;

  constructor(
    public payload: {
      task: TaskInterface;
      userId: number;
      assignees: {
        taskGroups?: TaskGroupInterface[];
        taskClassGroups?: TaskClassGroupInterface[];
        students?: TaskStudentInterface[];
      };
      writeToHistory?: boolean;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartDuplicateTask implements FeedbackTriggeringAction {
  readonly type = TasksActionTypes.StartDuplicateTask;

  constructor(
    public payload: {
      taskId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
      writeToHistory?: boolean;
    }
  ) {}
}

export class TaskDuplicated implements Action {
  readonly type = TasksActionTypes.TaskDuplicated;

  constructor(public payload: { task: TaskInterface }) {}
}

export class NavigateToTaskDetail implements Action {
  readonly type = TasksActionTypes.NavigateToTaskDetail;

  constructor(
    public payload: {
      task: TaskInterface;
      force?: boolean;
    }
  ) {}
}

export class NavigateToTasksOverview implements Action {
  readonly type = TasksActionTypes.NavigateToTasksOverview;

  constructor() {}
}

export class NavigateToAdaptiveTaskWizard implements Action {
  readonly type = TasksActionTypes.NavigateToAdaptiveTaskWizard;

  constructor(
    public payload: {
      task: TaskInterface;
      step?: string;
    }
  ) {}
}

export class PrintPaperTaskSolution implements FeedbackTriggeringAction {
  readonly type = TasksActionTypes.PrintPaperTaskSolution;

  constructor(
    public payload: {
      task: PrintTaskInterface;
      size?: PrintSize;
      force?: boolean;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartAddEvaluationTask implements FeedbackTriggeringAction {
  readonly type = TasksActionTypes.StartAddEvaluationTask;

  constructor(
    public payload: {
      task: TaskInterface;
      evaluationId: number;
      evaluationSubjectIds: number[];
      assignTo?: {
        groupId?: number;
        classGroupId?: number;
        start: Date;
        end: Date;
      };
      navigateAfterCreate?: boolean;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class NavigateToEvaluationTaskDetail implements Action {
  readonly type = TasksActionTypes.NavigateToEvaluationTaskDetail;

  constructor(
    public payload: {
      task: TaskInterface;
    }
  ) {}
}

export class SetEvaluationSubjectsForTask implements FeedbackTriggeringAction {
  readonly type = TasksActionTypes.SetEvaluationSubjectsForTask;

  constructor(
    public payload: {
      taskId: number;
      evaluationScoreListId: number;
      evaluationSubjectIds: number[];
      evaluationScoreListSubjectIds: number[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

// interface added here to avoid importing TaskWithAssigneeInterface from the kabas-tasks page
interface PrintTaskInterface {
  id?: number;
  taskEduContents?: { eduContent?: EduContent }[];
}

export type TasksActions =
  | LoadTasks
  | TasksLoaded
  | TasksLoadError
  | AddTask
  | UpsertTask
  | AddTasks
  | UpsertTasks
  | UpdateTask
  | UpdateTasks
  | DeleteTask
  | DeleteTasks
  | ClearTasks
  | UpdateAccess
  | StartDeleteTasks
  | StartAddTask
  | StartAddTaskWithAssignees
  | StartDuplicateTask
  | TaskDuplicated
  | NavigateToTaskDetail
  | NavigateToTasksOverview
  | NavigateToAdaptiveTaskWizard
  | PrintPaperTaskSolution
  | StartAddEvaluationTask
  | NavigateToEvaluationTaskDetail
  | StartEvaluationTask
  | StopEvaluationTask
  | SetEvaluationSubjectsForTask;
