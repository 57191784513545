import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AtlasStatus,
  LicenseInterface,
  PurchaseInterface,
  PurchaseProductInterface,
  SchoolBillingAddressInterface,
  SchoolBillingInterface,
} from '../+models';

export const PURCHASE_SERVICE_TOKEN = new InjectionToken('PurchaseServiceInterface');

export interface GetPurchasesOptionsInterface {
  searchTerm?: string;
  filters?: { [key: string]: string | number[] | number };
  pagination?: { from?: number; amount?: number };
  columns?: string[];
  sorting?: { [key: string]: 'asc' | 'desc' };
}

export interface PurchaseServiceInterface {
  getAllForUser(userId: number): Observable<PurchaseInterface[]>;
  getAllForYear(schoolYear: number): Observable<PurchaseInterface[]>;
  getPurchases(
    orderSchoolYear: number,
    currentTableAtlasStatus: AtlasStatus,
    options?: GetPurchasesOptionsInterface
  ): Observable<{ results: any[]; count: number }>;

  getAtlasStatusTotals(schoolYear: number): Observable<{ [key: string]: number }>;

  placeOrder(
    schoolId: number,
    productIds: number[],
    fullDigital: number[],
    customerOrderReference?: string,
    schoolBillingAddress?: SchoolBillingAddressInterface
  ): Observable<{
    purchase: PurchaseInterface;
    purchaseProducts: PurchaseProductInterface[];
    licenses: LicenseInterface[];
    schoolBillings: SchoolBillingInterface[];
  }>;

  updatePurchase(
    schoolId: number,
    orderId: number | string,
    changed: Partial<PurchaseInterface>
  ): Observable<{
    purchase: PurchaseInterface;
    purchaseProducts: PurchaseProductInterface[];
    licenses: LicenseInterface[];
    purchaseProductIdsToDelete: number[];
    schoolBillings: SchoolBillingInterface[];
  }>;
  deletePurchase(id: number): Observable<{
    purchases: PurchaseInterface[];
    purchaseProducts: PurchaseProductInterface[];
    schoolBilling: SchoolBillingInterface;
  }>;
  createPurchase(
    schoolId: number,
    purchase: PurchaseInterface
  ): Observable<{
    purchase: PurchaseInterface;
    purchaseProducts: PurchaseProductInterface[];
    licenses: LicenseInterface[];
    schoolBillings: SchoolBillingInterface[];
  }>;
  finalisePurchases(purchaseIds?: number[]): Observable<{
    purchases: PurchaseInterface[];
    purchaseProducts: PurchaseProductInterface[];
    schoolBillings: SchoolBillingInterface[];
  }>;
}
