export enum SettingsPermissions {
  UPDATE_PROFILE = 'updateProfile',
  UPDATE_AVATAR = 'updateAvatar',
  ADD_CREDENTIALS = 'addCredentials',
  REMOVE_CREDENTIALS = 'removeCredentials',
  ADD_LICENSES = 'addLicenses',
  REMOVE_LICENSES = 'removeLicenses',
  ADD_SCHOOLS = 'addSchools',
  REMOVE_SCHOOLS = 'removeSchools',
  LINK_TEACHERS = 'linkTeachers',
  UNLINK_TEACHERS = 'unlinkTeachers',
  MANAGE_FAVORITES = 'manageFavorites',
  MANAGE_HISTORY = 'manageHistory',
  GLOBAL_SEARCH = 'globalSearch',
  ADAPTIVE_TASK = 'adaptiveTask',
  ADAPTIVE_TASK_V2 = 'adaptiveTaskV2',
  LINK_SCHOOLS = 'linkSchools',
  MANAGE_TASKS = 'manageTasks',
  MANAGE_TEACHERS = 'manageTeachers',
  MANAGE_STUDENTS = 'manageStudents',
  MANAGE_CLASSGROUPS = 'manageClassGroups',
  MANAGE_BUNDLES = 'manageBundles',
  MANAGE_RESULTS_V2 = 'manageResultsV2',
  MANAGE_TASK_VISIBILITY = 'manageTaskVisibility',
  STUDENTS = 'students',
  SHARE_TASK = 'shareTask',
  SHARE_BUNDLE = 'shareBundle',
  MANAGE_MY_METHODS = 'manageMyMethods',
  MANAGE_MY_LICENSES = 'manageMyLicenses',
  PREVIEW_PROFILE_CLASSGROUPS = 'previewProfileClassGroups',
  PREVIEW_PROFILE_ADMINS = 'previewProfileAdmins',
  MANAGE_PROFILE_PUBLIC_KEY = 'manageProfilePublicKey',
  CAN_LINK_SCHOOL = 'canLinkSchool',
  SHOW_SCHOOL_CODE = 'showSchoolCode',
  SHOW_CLASSGROUPS = 'showClassgroups',
  SHOW_STUDENT_TAB = 'showStudentTab',
  SHOW_CREDIT_DEBET = 'showCreditDebet',
  SHOW_CREDENTIALS = 'showCredentials',
  SHOW_SCHOOL_POPULATION_LOGS = 'showSchoolPopulationLogs',
  SHOW_PRIVACY_SETTINGS = 'showPrivacySettings',
  MANAGE_LEER_ID = 'manageLeerId',
  SHOW_LICENCE_ERROR_TILES = 'showLicensErrorTiles',
  HIDE_IN_ARCHIVE = 'hideInArchive',
  CAN_VIEW_ARCHIVE = 'canViewArchive',
  CAN_VIEW_REALMS = 'canViewRealms',
  MANAGE_CONNECTIONS = 'manageConnections',
}

export enum ResultsPermissions {
  MANAGE_BUNDLES = 'manageBundles',
  FILTER_TASKS_BY_AUTHOR = 'filterTasksByAuthor',
  SHOW_NOT_ATTEMPTED_ITEMS = 'showNotAttemptedItems',
  APPEARANCE_TABLE = 'appearanceTable',
  APPEARANCE_BY_STUDENT = 'appearanceByStudent',
  APPEARANCE_BY_EXERCISE = 'appearanceByExercise',
  OPEN_RESULT_STUDENT_DETAIL = 'openResultStudentDetail',
  SHOW_TASK_RESULT_WIDGETS = 'showTaskResultWidgets',
}

export enum MethodsPermissions {
  MANAGE_UNLOCKED_FREE_PRACTICES = 'manageUnlockedFreePractices',
  BOEKES_FROM_BOOKS = 'boekesFromBooks',
  MANAGE_METHODS = 'manageMethods',
  MANAGE_LEARNING_PLAN_GOAL_PROGRESS = 'manageLearningPlanGoalProgress',
  MANAGE_USER_LESSONS = 'manageUserLessons',
  MANAGE_PRACTICE_FROM_TOC = 'managePracticeFromToc',
  SHOW_KADET_TILE = 'showKadetTile',
  DIGITAL_WORKBOOK = 'digitalWorkbook',
  OPEN_METHODS = 'openMethods',
}

export enum TasksPermissions {
  FILTER_TASKS_BY_AUTHOR = 'filterTasksByAuthor',
  OPEN_POLPO_TASKS = 'openPolpoTasks',
  OPEN_TASKS = 'openTasks',
  DUPLICATE_TASK = 'duplicateTask',
  MANAGE_TASK_V2 = 'manageTaskV2',
  MANAGE_TASK_CONTENT_V2 = 'manageTaskContentV2',
  TASK_MULTIPLE_DATES = 'taskMultipleDates',
  TASK_CONTENT_REPEATABLE = 'taskContentRepeatable',
  SHARE_TASK = 'shareTask',
}

export enum BundlesPermissions {
  OPEN_BUNDLES = 'openBundles',
}

export enum CMSPermissions {
  MANAGE_WHITEBOARD = 'manageWhiteboard',
  CMS_MANAGE_ARTIFACTS = 'cmsManageArtifacts',
  CMS_MANAGE_BOOKS = 'cmsManageBooks',
  CMS_MANAGE_BOOKS_V2 = 'cmsManageBooksV2',
  CMS_MANAGE_EDUCONTENT = 'cmsManageEducontent',
  CMS_MANAGE_EDUCONTENT_V2 = 'cmsManageEducontentV2',
  CMS_MANAGE_FAQ = 'cmsManageFaq',
  CMS_MANAGE_GOALS = 'cmsManageGoals',
  CMS_MANAGE_GOALS_V2 = 'cmsManageGoalsV2',
  CMS_MANAGE_GOALS_V2_EVALUATIONS = 'cmsManageGoalsV2Evaluations',
  CMS_MANAGE_PRODUCTS = 'cmsManageProducts',
  CMS_MANAGE_PURCHASES = 'cmsManagePurchases',
  CMS_MANAGE_PURCHASE_LICENSE = 'cmsManagepurchase_License',
  CMS_MANAGE_SCHOOLS = 'cmsManageSchools',
  CMS_MANAGE_SECTIONS = 'cmsManageSections',
  CMS_MANAGE_IMAGE_DICTIONARY = 'cmsManageImageDictionary',
  CMS_MANAGE_SETTINGS = 'cmsManageSettings',
  CMS_MANAGE_UPDATE_AVATAR = 'cmsManageUpdateAvatar',
  CMS_MANAGE_UPDATE_PROFILE = 'cmsManageUpdateProfile',
  CMS_SHOW_ALL_PRODUCT_OPTIONS = 'cmsShowAllProductOptions',
  CMS_MANAGE_DIGITAL_EVALUATIONS = 'cmsManageDigitalEvaluations',
  CMS_MANAGE_NEWS = 'cmsManageNews',
  CMS_MANAGE_PREFERENCES = 'cmsManagePreferences',
  CMS_MANAGE_EDUCONTENT_BOOK_PREFERENCES = 'cmsManageEduContentBookPreferences',
  CMS_MANAGE_EDUCONTENT_SEARCH_PREFERENCES = 'cmsManageEduContentSearchPreferences',
  CMS_MANAGE_EDUCONTENT_TEMPLATE_PREFERENCES = 'cmsManageEduContentTemplatePreferences',
  CMS_MANAGE_BOEKES = 'cmsManageBoekes',
  CMS_EDUCONTENTS_V2_CUSTOM_TASK = 'cmsEduContentsV2CustomTask',
}

export enum CMSPublicationPermissions {
  CMS_PUBLICATION_MANAGE_BOEKE_OVERRIDES = 'cmsManagePublicationBoekeOverrides',
  CMS_PUBLICATION_MANAGE_ADD_TO_BUNDLE = 'cmsManagePublicationAddToBundle',
  CMS_PUBLICATION_MANAGE_ADVANCED_SETTINGS = 'cmsManagePublicationAdvancedSettings',
  CMS_PUBLICATION_BOEKE_FIELDS_REQUIRED = 'cmsPublicationsBoekeFieldsRequired',
}

export enum SchoolsPermissions {
  SHOW_FULL_DIGITAL = 'showFullDigital',
  CAN_AUTO_RENEW_LICENSES = 'canAutoRenewLicenses',
}

export enum ProductsPermissions {
  SHOW_PRODUCT_POINTS = 'showProductPoints',
}

export enum FeaturePermissions {
  digitalEvaluations = 'digitalEvaluations',
  DASHBOARD = 'dashboard',
  KAI = 'kai',
}

export const Permissions = {
  settings: SettingsPermissions,
  results: ResultsPermissions,
  methods: MethodsPermissions,
  tasks: TasksPermissions,
  bundles: BundlesPermissions,
  cms: CMSPermissions,
  products: ProductsPermissions,
  schools: SchoolsPermissions,
  feature: FeaturePermissions,
  cms_publications: CMSPublicationPermissions,
};
