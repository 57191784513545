import { groupArrayByKeys } from '@campus/utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TaskLevelSettingInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './task-level-setting.reducer';

export const selectTaskLevelSettingState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectTaskLevelSettingState, (state: State) => state.error);

export const getLoaded = createSelector(selectTaskLevelSettingState, (state: State) => state.loaded);

export const getAll = createSelector(selectTaskLevelSettingState, selectAll);

export const getCount = createSelector(selectTaskLevelSettingState, selectTotal);

export const getIds = createSelector(selectTaskLevelSettingState, selectIds);

export const getAllEntities = createSelector(selectTaskLevelSettingState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * taskLevelSetting$: TaskLevelSettingInterface[] = this.store.pipe(
    select(TaskLevelSettingQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectTaskLevelSettingState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * taskLevelSetting$: TaskLevelSettingInterface = this.store.pipe(
    select(TaskLevelSettingQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectTaskLevelSettingState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getAllByTask = createSelector(getAll, (taskLevelSettings: TaskLevelSettingInterface[]) =>
  groupArrayByKeys(taskLevelSettings, ['taskId'])
);

export const getTaskLevelSettingLoadedForTask = createSelector(
  selectTaskLevelSettingState,
  (state, props: { taskId: number }) => {
    return !!state.loadedForTask[props.taskId];
  }
);

export const isSaving = createSelector(selectTaskLevelSettingState, (state: State) => state.saving);
