import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { GoalTopicInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum GoalTopicsActionTypes {
  GoalTopicsLoaded = '[GoalTopics] GoalTopics Loaded',
  GoalTopicsLoadError = '[GoalTopics] Load Error',
  LoadGoalTopics = '[GoalTopics] Load GoalTopics',
  LoadGoalTopicsForLearningArea = '[GoalTopics] Load GoalTopics For Learning Area',
  AddGoalTopicsForLearningArea = '[GoalTopics] Add GoalTopics For Learning Area',
  StartAddGoalTopic = '[GoalTopics] Start Add GoalTopic',
  AddGoalTopic = '[GoalTopics] Add GoalTopic',
  UpsertGoalTopic = '[GoalTopics] Upsert GoalTopic',
  AddGoalTopics = '[GoalTopics] Add GoalTopics',
  UpsertGoalTopics = '[GoalTopics] Upsert GoalTopics',
  UpdateGoalTopic = '[GoalTopics] Update GoalTopic',
  UpdateGoalTopics = '[GoalTopics] Update GoalTopics',
  DeleteGoalTopic = '[GoalTopics] Delete GoalTopic',
  DeleteGoalTopics = '[GoalTopics] Delete GoalTopics',
  ClearGoalTopics = '[GoalTopics] Clear GoalTopics',
  GetLinkedEduContentMetadata = '[GoalTopics] Get Linked EduContentMetadata',
  LinkedEduContentMetadaLoaded = '[GoalTopics] Linked eduContentMetada loaded',
  AddGoalTopicsForBook = '[GoalTopics] Add Goals for Book',
}

export class LoadGoalTopics implements Action {
  readonly type = GoalTopicsActionTypes.LoadGoalTopics;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class GoalTopicsLoaded implements Action {
  readonly type = GoalTopicsActionTypes.GoalTopicsLoaded;

  constructor(public payload: { goalTopics: GoalTopicInterface[] }) {}
}

export class LoadGoalTopicsForLearningArea implements Action {
  readonly type = GoalTopicsActionTypes.LoadGoalTopicsForLearningArea;

  constructor(public payload: { learningAreaId: number }) {}
}

export class AddGoalTopicsForLearningArea implements Action {
  readonly type = GoalTopicsActionTypes.AddGoalTopicsForLearningArea;

  constructor(public payload: { learningAreaId: number; goalTopics: GoalTopicInterface[] }) {}
}

export class GoalTopicsLoadError implements Action {
  readonly type = GoalTopicsActionTypes.GoalTopicsLoadError;
  constructor(public payload: any) {}
}

export class StartAddGoalTopic implements Action, FeedbackTriggeringAction {
  readonly type = GoalTopicsActionTypes.StartAddGoalTopic;

  constructor(
    public payload: {
      goalTopic: GoalTopicInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class AddGoalTopic implements Action {
  readonly type = GoalTopicsActionTypes.AddGoalTopic;

  constructor(
    public payload: {
      goalTopic: GoalTopicInterface;
    }
  ) {}
}

export class AddGoalTopicsForBook implements Action {
  readonly type = GoalTopicsActionTypes.AddGoalTopicsForBook;

  constructor(public payload: { bookId: number; goalTopics: GoalTopicInterface[] }) {}
}

export class UpsertGoalTopic implements Action {
  readonly type = GoalTopicsActionTypes.UpsertGoalTopic;

  constructor(public payload: { goalTopic: GoalTopicInterface }) {}
}

export class AddGoalTopics implements Action {
  readonly type = GoalTopicsActionTypes.AddGoalTopics;

  constructor(public payload: { goalTopics: GoalTopicInterface[] }) {}
}

export class UpsertGoalTopics implements Action {
  readonly type = GoalTopicsActionTypes.UpsertGoalTopics;

  constructor(public payload: { goalTopics: GoalTopicInterface[] }) {}
}

export class UpdateGoalTopic implements Action {
  readonly type = GoalTopicsActionTypes.UpdateGoalTopic;

  constructor(
    public payload: {
      goalTopic: Update<GoalTopicInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpdateGoalTopics implements Action {
  readonly type = GoalTopicsActionTypes.UpdateGoalTopics;

  constructor(public payload: { goalTopics: Update<GoalTopicInterface>[] }) {}
}

export class DeleteGoalTopic implements Action, FeedbackTriggeringAction {
  readonly type = GoalTopicsActionTypes.DeleteGoalTopic;

  constructor(
    public payload: {
      id: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class DeleteGoalTopics implements Action {
  readonly type = GoalTopicsActionTypes.DeleteGoalTopics;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearGoalTopics implements Action {
  readonly type = GoalTopicsActionTypes.ClearGoalTopics;
}

export class GetLinkedEduContentMetadata implements Action {
  readonly type = GoalTopicsActionTypes.GetLinkedEduContentMetadata;

  constructor(public payload: { goalTopicId: number; force?: boolean }) {}
}

export class LinkedEduContentMetadaLoaded implements Action {
  readonly type = GoalTopicsActionTypes.LinkedEduContentMetadaLoaded;

  constructor(public payload: { goalTopicId: number }) {}
}

export type GoalTopicsActions =
  | LoadGoalTopics
  | LoadGoalTopicsForLearningArea
  | GoalTopicsLoaded
  | GoalTopicsLoadError
  | AddGoalTopic
  | AddGoalTopicsForBook
  | AddGoalTopicsForLearningArea
  | UpsertGoalTopic
  | StartAddGoalTopic
  | AddGoalTopics
  | UpsertGoalTopics
  | UpdateGoalTopic
  | UpdateGoalTopics
  | DeleteGoalTopic
  | DeleteGoalTopics
  | ClearGoalTopics
  | GetLinkedEduContentMetadata
  | LinkedEduContentMetadaLoaded;
