<ng-container *ngFor="let option of filteredFilterCriterium.values; let i = index">
  <!-- titles -->
  <mat-checkbox
    mat-list-item
    class="checkbox-filter__title"
    color="primary"
    [class.checkbox-filter__item--hidden]="
      maxVisibleItems &&
      i >= maxVisibleItems &&
      !showMoreItems &&
      !option.selected &&
      !indeterminateStatus[option.data.id]
    "
    [value]="option"
    [checked]="option.selected"
    [indeterminate]="indeterminateStatus[option.data.id]"
    (change)="onChange($event)"
    data-cy="checkbox-filter-value"
    ><span class="ui-body-1 checkbox-filter__item__name">{{ getDisplayValue(option) }}</span
    ><span *ngIf="option.prediction" class="ui-caption checkbox-filter__item__prediction">
      {{ ' (' + option.prediction + ')' }}</span
    ></mat-checkbox
  >

  <!-- children -->
  <campus-checkbox-filter
    class="checkbox-filter__items"
    [class.checkbox-filter__item--hidden]="
      maxVisibleItems &&
      i >= maxVisibleItems &&
      !showMoreItems &&
      !option.selected &&
      !indeterminateStatus[option.data.id]
    "
    *ngIf="option.child"
    [criterium]="option.child"
    [maxVisibleItems]="maxVisibleItems"
    [parentValueRef]="option"
    [sortBySelection]="sortBySelection"
    (selectionChanged)="onChildChange($event)"
  >
  </campus-checkbox-filter>
</ng-container>
<!-- show more titles ? -->
<a
  *ngIf="showMoreStatus"
  (click)="toggleShowMore()"
  class="checkbox-filter__footer"
  data-cy="checkbox-filter-more-toggle"
>
  <campus-icon
    [svgIcon]="'arrow-back'"
    [class.rotate--up]="showMoreItems"
    [class.rotate--down]="!showMoreItems"
  ></campus-icon>
  {{ !showMoreItems ? 'toon meer...' : 'toon minder...' }}
</a>
