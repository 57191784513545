<ng-container *ngIf="searchState$ | async as searchState">
  <campus-results-list
    *ngIf="!hideSearchResultList"
    class="search-results"
    [resultsPage]="searchResults"
    [searchMode]="searchMode"
    [searchState]="searchState"
    (sortBy)="onSort($event)"
    (getNextPage)="onScroll()"
    [listFormat]="listFormat"
  ></campus-results-list>
</ng-container>
