/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { EvaluationApi as SDKEvaluationApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { EvaluationInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class EvaluationApi extends ModelApiBase {
  private SDKEvaluationApi = inject(SDKEvaluationApi);

  constructor(api: PolpoApi) {
    super(api, 'Evaluations');
  }

  find(options?): Observable<EvaluationInterface[]> {
    return this.SDKEvaluationApi.find(...arguments);
  }

  addEvaluationForToc(tocId: number, evaluation): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEvaluationApi.addEvaluationForToc(...arguments);
  }

  patchAttributes(evaluationId: number, changes): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEvaluationApi.patchAttributes(...arguments);
  }

  deleteEvaluationForToc(tocId: number, evaluationId: number): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEvaluationApi.deleteEvaluationForToc(...arguments);
  }
}
