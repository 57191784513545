import { MethodInterface } from './Method.interface';
import { MethodNewsInterface } from './MethodNews.interface';
import { NewsCategoryInterface } from './NewsCategory.interface';
import { NewsCategoryNewsInterface } from './NewsCategoryNews.interface';
import { NewsRoleInterface } from './NewsRole.interface';

export enum LinkTypeEnum {
  LINK = 'LINK',
  EDUCONTENT = 'EDUCONTENT',
}

export interface NewsInterface {
  id?: number;
  title?: string;
  description?: string;
  image?: string;
  linkUrl?: string; // to remove in a later iteration
  publishDate?: Date;
  unpublishDate?: Date;

  linkType?: LinkTypeEnum;
  link?: string;
  linkLabel?: string;
  linkPublishDate?: Date;

  link2Type?: LinkTypeEnum;
  link2?: string;
  link2Label?: string;
  link2PublishDate?: Date;

  newsRoles?: NewsRoleInterface[];
  newsCategoryNews?: NewsCategoryNewsInterface[];
  methodNews?: MethodNewsInterface[];
  roleIds?: number[];
  categoryIds?: number[];
  methodIds?: number[];
  productIds?: number[];
  file?: File;
  createdAt?: Date;
  categories?: NewsCategoryInterface[];
  methods?: MethodInterface[];
}
