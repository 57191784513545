import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { EduContentTOCInterface, EvaluationWithSubjectsInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum EduContentTocsActionTypes {
  EduContentTocsLoadError = '[EduContentTocs] Load Error',
  LoadEduContentTocsForBook = '[EduContentTocs] Load EduContentTocs for Book',
  AddEduContentTocsForBook = '[EduContentTocs] Add EduContentTocs for Book',
  AddLoadedBook = '[EduContentTocs] Add loaded Book',
  AddEduContentToc = '[EduContentTocs] Add EduContentToc',
  UpsertEduContentToc = '[EduContentTocs] Upsert EduContentToc',
  UpsertEduContentTocs = '[EduContentTocs] Upsert EduContentTocs',
  StartUpdateEduContentToc = '[EduContentTocs] Start Update EduContentToc',
  UpdateEduContentToc = '[EduContentTocs] Update EduContentToc',
  UpdateEduContentTocs = '[EduContentTocs] Update EduContentTocs',
  DeleteEduContentToc = '[EduContentTocs] Delete EduContentToc',
  DeleteEduContentTocs = '[EduContentTocs] Delete EduContentTocs',
  ClearEduContentTocs = '[EduContentTocs] Clear EduContentTocs',
  ClearLoadedBooks = '[EduContentTocs] Clear loaded Books',
  DeleteEvaluation = '[EduContentTocs] Delete Evaluation',
  AddEvaluation = '[EduContentTocs] Add Evaluation',
  NavigateToEvaluationDetail = '[EduContentTocs] Navigate to Evaluation detail',
  MoveEvaluation = '[EduContentTocs] Move Evaluation',
}

export class EduContentTocsLoadError implements Action {
  readonly type = EduContentTocsActionTypes.EduContentTocsLoadError;
  constructor(public payload: any) {}
}

export class LoadEduContentTocsForBook implements Action {
  readonly type = EduContentTocsActionTypes.LoadEduContentTocsForBook;

  constructor(public payload: { bookId: number }) {}
}

export class AddEduContentTocsForBook implements Action {
  readonly type = EduContentTocsActionTypes.AddEduContentTocsForBook;

  constructor(public payload: { bookId: number; eduContentTocs: EduContentTOCInterface[] }) {}
}

export class AddLoadedBook implements Action {
  readonly type = EduContentTocsActionTypes.AddLoadedBook;

  constructor(public payload: { bookId: number }) {}
}

export class AddEduContentToc implements Action {
  readonly type = EduContentTocsActionTypes.AddEduContentToc;

  constructor(public payload: { eduContentToc: EduContentTOCInterface }) {}
}

export class UpsertEduContentToc implements Action {
  readonly type = EduContentTocsActionTypes.UpsertEduContentToc;

  constructor(public payload: { eduContentToc: EduContentTOCInterface }) {}
}

export class UpsertEduContentTocs implements Action {
  readonly type = EduContentTocsActionTypes.UpsertEduContentTocs;

  constructor(public payload: { eduContentTocs: EduContentTOCInterface[] }) {}
}

export class StartUpdateEduContentToc implements Action {
  readonly type = EduContentTocsActionTypes.StartUpdateEduContentToc;

  constructor(
    public payload: {
      eduContentToc: Update<EduContentTOCInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpdateEduContentToc implements Action {
  readonly type = EduContentTocsActionTypes.UpdateEduContentToc;

  constructor(
    public payload: {
      eduContentToc: Update<EduContentTOCInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpdateEduContentTocs implements Action {
  readonly type = EduContentTocsActionTypes.UpdateEduContentTocs;

  constructor(public payload: { eduContentTocs: Update<EduContentTOCInterface>[] }) {}
}

export class DeleteEduContentToc implements Action {
  readonly type = EduContentTocsActionTypes.DeleteEduContentToc;

  constructor(public payload: { id: number }) {}
}

export class DeleteEduContentTocs implements Action {
  readonly type = EduContentTocsActionTypes.DeleteEduContentTocs;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearEduContentTocs implements Action {
  readonly type = EduContentTocsActionTypes.ClearEduContentTocs;
}

export class ClearLoadedBooks implements Action {
  readonly type = EduContentTocsActionTypes.ClearLoadedBooks;
}

export class DeleteEvaluation implements Action, FeedbackTriggeringAction {
  readonly type = EduContentTocsActionTypes.DeleteEvaluation;

  constructor(public payload: { id: number; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export class AddEvaluation implements Action, FeedbackTriggeringAction {
  readonly type = EduContentTocsActionTypes.AddEvaluation;

  constructor(public payload: { eduContentTocId: number; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export class NavigateToEvaluationDetail implements Action {
  readonly type = EduContentTocsActionTypes.NavigateToEvaluationDetail;

  constructor(
    public payload: {
      evaluation: EvaluationWithSubjectsInterface;
    }
  ) {}
}

export class MoveEvaluation implements Action, FeedbackTriggeringAction {
  readonly type = EduContentTocsActionTypes.MoveEvaluation;

  constructor(
    public payload: {
      oldEduContentTOCId: number;
      newEduContentTOCId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export type EduContentTocsActions =
  | EduContentTocsLoadError
  | LoadEduContentTocsForBook
  | AddEduContentTocsForBook
  | AddLoadedBook
  | AddEduContentToc
  | UpsertEduContentToc
  | UpsertEduContentTocs
  | StartUpdateEduContentToc
  | UpdateEduContentToc
  | UpdateEduContentTocs
  | DeleteEduContentToc
  | DeleteEduContentTocs
  | ClearEduContentTocs
  | ClearLoadedBooks
  | DeleteEvaluation
  | AddEvaluation
  | MoveEvaluation
  | NavigateToEvaluationDetail;
