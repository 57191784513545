import { Action } from '@ngrx/store';
import { PersonPreferenceInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum PersonPreferencesActionTypes {
  PersonPreferencesLoaded = '[PersonPreferences] PersonPreferences Loaded',
  PersonPreferencesLoadError = '[PersonPreferences] Load Error',
  StartAddPersonPreference = '[PersonPreferences] Start Add PersonPreference',
  AddPersonPreference = '[PersonPreferences] Add PersonPreference',
  DeletePersonPreference = '[PersonPreferences] Delete PersonPreference',
  StartUpsertPersonPreference = '[PersonPreferences] Start upsert PersonPreference',
  UpsertPersonPreference = '[PersonPreferences] Upsert PersonPreference',
  StartAddTemplate = '[PersonPreferences] Start Add Template',
}

export class PersonPreferencesLoaded implements Action {
  readonly type = PersonPreferencesActionTypes.PersonPreferencesLoaded;

  constructor(public payload: { personPreferences: PersonPreferenceInterface[] }) {}
}

export class PersonPreferencesLoadError implements Action {
  readonly type = PersonPreferencesActionTypes.PersonPreferencesLoadError;
  constructor(public payload: any) {}
}

export class StartAddPersonPreference implements Action {
  readonly type = PersonPreferencesActionTypes.StartAddPersonPreference;

  constructor(
    public payload: {
      userId: number;
      personPreference: PersonPreferenceInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class AddPersonPreference implements Action {
  readonly type = PersonPreferencesActionTypes.AddPersonPreference;

  constructor(public payload: { personPreference: PersonPreferenceInterface; navigateAfterCreate?: boolean }) {}
}

export class DeletePersonPreference implements FeedbackTriggeringAction {
  readonly type = PersonPreferencesActionTypes.DeletePersonPreference;

  constructor(
    public payload: {
      id: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
      updateCompleteProfile?: boolean;
    }
  ) {}
}

export class StartUpsertPersonPreference implements FeedbackTriggeringAction {
  readonly type = PersonPreferencesActionTypes.StartUpsertPersonPreference;

  constructor(
    public payload: {
      personPreference: PersonPreferenceInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpsertPersonPreference implements FeedbackTriggeringAction {
  readonly type = PersonPreferencesActionTypes.UpsertPersonPreference;

  constructor(
    public payload: {
      personPreference: PersonPreferenceInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartAddTemplate implements Action {
  readonly type = PersonPreferencesActionTypes.StartAddTemplate;

  constructor(
    public payload: {
      personPreference: PersonPreferenceInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export type PersonPreferencesActions =
  | PersonPreferencesLoaded
  | PersonPreferencesLoadError
  | StartAddPersonPreference
  | DeletePersonPreference
  | StartUpsertPersonPreference
  | UpsertPersonPreference
  | AddPersonPreference
  | StartAddTemplate;
