<div class="shared-header">
  <ng-container bar__right *ngIf="enableTours">
    <div *ngIf="showTours$ | async" @slideIn>
      <ng-container *ngTemplateOutlet="tour"></ng-container>
    </div>
  </ng-container>
  <ng-container bar__right *ngIf="this.enableGlobalSearch">
    <ng-container *ngTemplateOutlet="globalSearch"></ng-container>
  </ng-container>

  <ng-container bar__right *ngIf="this.quicklinkPosition === 'top-right'">
    <ng-container *ngTemplateOutlet="quicklinks"></ng-container>
  </ng-container>
  <ng-container bar__right *ngIf="this.alertsPosition === 'top-right'">
    <ng-container *ngTemplateOutlet="alerts"></ng-container>
  </ng-container>
  <ng-container bar__right *ngIf="this.currentBookPosition === 'top-right'">
    <ng-container *hasPermission="Permissions.CMS_MANAGE_EDUCONTENT_V2">
      <ng-container *ngTemplateOutlet="currentBook"></ng-container>
    </ng-container>
  </ng-container>
  <ng-container bar__right *ngIf="this.profileMenuPosition === 'top-right'">
    <ng-container *ngTemplateOutlet="profilemenu"></ng-container>
  </ng-container>
</div>

<campus-slideout-modal-stack [activePanel]="activeHistoryPanel" *hasPermission="Permissions.MANAGE_HISTORY">
  <campus-slideout-modal [title]="'Recente items'" (scrimClicked)="activeHistoryPanel = -1">
    <campus-slideout-modal-header-actions>
      <campus-button inline icon (click)="activeHistoryPanel = -1"
        ><campus-icon svgIcon="close"></campus-icon
      ></campus-button>
    </campus-slideout-modal-header-actions>
    <campus-quick-link [mode]="QuickLinkTypeEnum.HISTORY" (close)="activeHistoryPanel = -1"></campus-quick-link>
  </campus-slideout-modal>
</campus-slideout-modal-stack>

<campus-slideout-modal-stack [activePanel]="activeFavoritesPanel" *hasPermission="Permissions.MANAGE_FAVORITES">
  <campus-slideout-modal data-cy="favorites-panel" [title]="'Favorieten'" (scrimClicked)="activeFavoritesPanel = -1">
    <campus-slideout-modal-header-actions>
      <campus-button data-cy="btn-close-favorites-panel" inline icon (click)="activeFavoritesPanel = -1"
        ><campus-icon svgIcon="close"></campus-icon
      ></campus-button>
    </campus-slideout-modal-header-actions>
    <campus-quick-link [mode]="QuickLinkTypeEnum.FAVORITES" (close)="activeFavoritesPanel = -1"></campus-quick-link>
  </campus-slideout-modal>
</campus-slideout-modal-stack>

<ng-template #profilemenu>
  <div class="shared-header__profile-menu" data-usetiful="btn-profile-menu">
    <ng-container *ngIf="currentUser$ | async as user">
      <campus-person-badge
        *ngIf="profileMenuWithUserBadge; else profileIcon"
        data-cy="btn-header-profile"
        (click)="dropDownProfile.toggle()"
        [person]="user"
        [showText]="false"
        size="medium"
        class="shared-header__person-badge"
      ></campus-person-badge>
    </ng-container>

    <ng-template #profileIcon>
      <div
        id="global-profile-btn"
        data-cy="btn-header-profile"
        class="shared-header__alerts shared-header__contextual__button"
        (click)="dropDownProfile.toggle()"
        ><campus-icon svgIcon="profile"></campus-icon><span>Profiel</span></div
      >
    </ng-template>
    <campus-dropdown-menu #dropDownProfile [showHeader]="false" [smallWidth]="true">
      <campus-dropdown-menu-item
        *ngFor="let menuItem of profileMenuItems$ | async"
        [icon]="menuItem.icon"
        [description]="menuItem.description"
        [header]="menuItem.header"
        [children]="menuItem.children"
        [handler]="menuItem.handler"
        [image]="menuItem.image"
        [campusRouterLink]="menuItem.internalLink || menuItem.externalLink"
        [class.ui-dropdown-menu-item__divider]="menuItem.dividerBefore"
        [class]="menuItem.cssClass"
      ></campus-dropdown-menu-item>
    </campus-dropdown-menu>
  </div>
</ng-template>

<ng-template #currentBook>
  <button
    *ngIf="globalBookDisabled$ | async as globalBookDisabled; else globalBookEnabled"
    filled-button
    class="[ border border-surface ]"
    [disabled]="true"
  >
    Niet van toepassing
  </button>

  <ng-template #globalBookEnabled>
    <button
      *ngIf="selectedBook$ | async as selectedBook; else noBook"
      filled-button
      class="[ border border-surface ]"
      [matMenuTriggerFor]="booksMenu"
      trailingIcon="arrow-drop-down"
    >
      Je werkt in {{ selectedBook.title }}
    </button>
  </ng-template>

  <ng-template #noBook>
    <button
      filled-button
      class="[ border border-on-primary ]"
      [matMenuTriggerFor]="booksMenu"
      trailingIcon="arrow-drop-down"
    >
      Kies een uitgave
    </button>
  </ng-template>

  <mat-menu #booksMenu="matMenu">
    <ng-container *ngIf="favoriteBooks$ | async as favoriteBooks">
      <div
        class="[ border-be last:border-none border-stroke-variant ] [ flex items-center gap-s ] [ p-b-s p-i-m ] [ transition-colors ] [ cursor-pointer hover:primary-container label-large ]"
        (click)="navigateToPreferences()"
        campusClickPreventDefault
        *ngIf="favoriteBooks.length === 0"
      >
        Favoriete uitgaves kiezen
        <campus-icon svgIcon="openInBrowser"></campus-icon>
      </div>
      <div
        class="[ border-be last:border-none border-stroke-variant ] [ flex itemss-center gap-s ] [ p-b-s p-i-m ] [ transition-colors ] [ cursor-pointer hover:primary-container label-large ]"
        (click)="selectBook(null)"
        campusClickPreventDefault
        >Niet binnen een uitgave werken</div
      >
      <div
        class="[ border-be last:border-none border-stroke-variant ] [ flex items-center gap-s ] [ p-b-s p-i-m ] [ transition-colors ] [ cursor-pointer hover:primary-container label-large ]"
        (click)="selectBook(favoriteBook.id)"
        campusClickPreventDefault
        *ngFor="let favoriteBook of favoriteBooks"
        >{{ favoriteBook.title }}</div
      >
    </ng-container>
  </mat-menu>
</ng-template>

<ng-template #alerts>
  <div class="shared-header__alerts" *ngIf="enableAlerts && (alertsLoaded$ | async)">
    <div
      id="global-alert-btn"
      data-cy="btn-header-alerts"
      (click)="dropDownAlerts.toggle()"
      class="shared-header__alerts__button shared-header__contextual__button ui-caption"
    >
      <campus-icon
        [svgIcon]="'notifications'"
        [matBadge]="unreadAlertCount$ | async"
        [matBadgeHidden]="!((unreadAlertCount$ | async) > 0)"
        matBadgePosition="above after"
        matBadgeColor="accent"
      ></campus-icon
      ><span>Meldingen</span>
    </div>

    <campus-dropdown-menu
      #dropDownAlerts
      [showHeader]="true"
      [newItemCount]="unreadAlertCount$ | async"
      [headerIcon]="'notifications'"
      itemType="meldingen"
      linkText="Alle meldingen"
      linkUrl="/alerts"
    >
      <ng-container *ngIf="alertNotifications$ | async as notifications">
        <campus-notification
          *ngFor="let notification of notifications; trackBy: trackById"
          dropdown
          [icon]="notification.icon"
          [notificationText]="notification.titleText"
          [campusRouterLink]="notification.link"
          [notificationDate]="notification.notificationDate"
          [accented]="notification.accented"
          (click)="setAlertAsRead(notification)"
        ></campus-notification>
      </ng-container>
    </campus-dropdown-menu>
  </div>
</ng-template>

<ng-template #quicklinks>
  <div
    id="global-favorites-btn"
    data-cy="btn-header-favorites"
    *hasPermission="[Permissions.MANAGE_FAVORITES, '!' + Permissions.HIDE_IN_ARCHIVE]"
    (click)="onManageFavoritesClick()"
    class="shared-header__contextual__button ui-caption"
    ><campus-icon svgIcon="favorite" class="icon-current"></campus-icon><span>Favorieten</span></div
  >
  <div
    id="global-history-btn"
    data-cy="btn-header-history"
    *hasPermission="[Permissions.MANAGE_HISTORY, '!' + Permissions.HIDE_IN_ARCHIVE]"
    (click)="onManageHistoryClick()"
    class="shared-header__contextual__button ui-caption"
    ><campus-icon svgIcon="history" class="icon-current"></campus-icon><span>Geschiedenis</span></div
  >
</ng-template>

<ng-template #globalSearch>
  <div
    id="global-search-btn"
    data-cy="btn-header-search"
    data-usetiful="header-search-btn"
    *hasPermission="[Permissions.GLOBAL_SEARCH, '!' + Permissions.HIDE_IN_ARCHIVE]"
    class="shared-header__contextual__button ui-caption"
    [routerLink]="['search']"
    ><campus-icon svgIcon="magnifier" class="icon-current"></campus-icon><span>Zoeken</span></div
  >
</ng-template>

<ng-template #tour>
  <div
    *hasPermission="'!' + Permissions.HIDE_IN_ARCHIVE"
    id="tour-btn"
    data-cy="header-tour-btn"
    data-usetiful="header-tour-btn"
    class="shared-header__contextual__button ui-caption"
    ><campus-icon svgIcon="tours" class="icon-current"></campus-icon><span>Rondleiding</span></div
  >
</ng-template>
