import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './evaluation-score-list.reducer';

export const selectEvaluationScoreListState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectEvaluationScoreListState, (state: State) => state.error);

export const getLoaded = createSelector(selectEvaluationScoreListState, (state: State) => state.loaded);

export const getAll = createSelector(selectEvaluationScoreListState, selectAll);

export const getCount = createSelector(selectEvaluationScoreListState, selectTotal);

export const getIds = createSelector(selectEvaluationScoreListState, selectIds);

export const getAllEntities = createSelector(selectEvaluationScoreListState, selectEntities);

export const isTaskLoaded = (props: { taskId: number }) =>
  createSelector(selectEvaluationScoreListState, (state: State) => !!state.loadedForTask[props.taskId]);
