import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { SchoolTypeQueries } from '.';
import { SchoolTypeServiceInterface, SCHOOL_TYPE_SERVICE_TOKEN } from '../../metadata/school-type.service.interface';
import { DalState } from '../dal.state.interface';
import {
  LoadSchoolTypes,
  SchoolTypesActionTypes,
  SchoolTypesLoaded,
  SchoolTypesLoadError,
} from './school-type.actions';

@Injectable()
export class SchoolTypeEffects {
  loadSchoolTypes$ = createEffect(() =>
    this.actions.pipe(
      ofType(SchoolTypesActionTypes.LoadSchoolTypes),
      concatLatestFrom(() => this.store.select(SchoolTypeQueries.getLoaded)),
      fetch({
        run: (action: LoadSchoolTypes, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.schoolTypeService.getAll().pipe(map((schoolTypes) => new SchoolTypesLoaded({ schoolTypes })));
        },
        onError: (action: LoadSchoolTypes, error) => {
          return new SchoolTypesLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(SCHOOL_TYPE_SERVICE_TOKEN)
    private schoolTypeService: SchoolTypeServiceInterface
  ) {}
}
