import { Component, inject, ViewEncapsulation } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { EduContent, EduContentBookInterface, EduContentInterface, LearnosityItemsConfigInterface } from '@campus/dal';
import { LearnosityOpenerService } from '../../services';

export type LearnosityDialogComponentType = 'assessment' | 'activity-preview' | 'reporting';

@Component({
  selector: 'campus-learnosity-dialog',
  templateUrl: './learnosity-dialog.component.html',
  styleUrls: ['./learnosity-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: '[ relative ] [ h-full w-full m-0 ] [ ] [ background ] ca' },
})
export class LearnosityDialogComponent {
  public config: LearnosityItemsConfigInterface;
  public component: LearnosityDialogComponentType;
  public eduContentBookId: number;
  public eduContent: EduContent;

  private dialogRef = inject(MatDialogRef);
  private dialogData: {
    config: LearnosityItemsConfigInterface;
    component: LearnosityDialogComponentType;
    eduContent: EduContentInterface;
  } = inject(MAT_DIALOG_DATA);
  private learnosityOpenerService = inject(LearnosityOpenerService);

  ngOnInit(): void {
    const { config, component, eduContent } = this.dialogData;

    this.config = config;
    this.component = component;
    this.eduContent = EduContent.toEduContent(eduContent);
    this.eduContentBookId = config.eduContentBookId || this.getEduContentBookId();
  }

  public onCloseClicked() {
    if (this.dialogRef.id === this.learnosityOpenerService.learnosityDialogId) {
      this.dialogRef.close();
    }
  }

  private getEduContentBookId(): number {
    const eduContent = this.eduContent;
    const eduContentMetadata = eduContent?.metadata;

    const eduContentBookIds =
      eduContentMetadata?.eduContentBooks?.map((book: EduContentBookInterface) => book.id) || [];

    return eduContentBookIds?.[0];
  }
}
