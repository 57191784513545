import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NumberFunctions } from '@campus/utils';
import { LoopModeEnum } from '../enums/loop-mode.enum';

interface ArcStrokeInterface {
  size: number;
  center: number;
  radius: number;
  strokeWidth: number;
  dashOffset: number;
  dashArray: number;
  transform: string;
}
interface ShadowDirection {
  x: number;
  y: number;
}
@Component({
  selector: 'campus-ability-meter',
  templateUrl: './ability-meter.component.html',
  styleUrls: ['./ability-meter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbilityMeterComponent implements OnChanges {
  loopModes: typeof LoopModeEnum = LoopModeEnum;
  bestMode = false;

  @Input() progress = 0;
  @Input() mode: LoopModeEnum = LoopModeEnum.MASTER;
  @Input() label = 'ksnap-o-meter';
  @Input() size: 'small' | 'large' = 'large';

  @HostBinding('class.ui-ability-meter') hasAbilityMeterClass = true;
  @HostBinding('class.ui-ability-meter--complete')
  get isComplete() {
    return this.progress === 100;
  }
  @HostBinding('class.ui-ability-meter--small')
  get isSmall() {
    return this.size === 'small';
  }

  @ViewChild('meter', { static: true, read: ElementRef }) meterEl: ElementRef;

  masterAngle = 0;
  expertArcStroke: ArcStrokeInterface;

  // This does not change
  shadowDirection: ShadowDirection = { x: 1, y: 3 };

  masterShadow: ShadowDirection = this.shadowDirection;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mode) {
      this.bestMode = this.mode === LoopModeEnum.EXPERT || this.mode === LoopModeEnum.DONE;
    }

    if (changes.mode || changes.progress || changes.size) {
      this.masterAngle = this.bestMode ? this.percentToAngle(100) : this.percentToAngle(this.progress);

      this.expertArcStroke = this.bestMode ? this.percentToArcStroke(this.progress) : this.percentToArcStroke(0);

      this.masterShadow = this.getMasterShadow(this.masterAngle);
    }
  }

  private percentToAngle(percent: number): number {
    return (percent / 100) * 180;
  }
  private percentToArcStroke(percent: number): ArcStrokeInterface {
    if (!this.size) return null;

    const radius = this.size === 'large' ? 80 : 40;
    const strokeWidth = this.size === 'large' ? 40 : 20;
    const size = this.size === 'large' ? radius * 2 + strokeWidth : radius * 2 + strokeWidth * 2;
    const center = size / 2;

    const dashArray = 2 * Math.PI * radius;

    const dashOffset = dashArray * (1 - percent / 100 / 2); // only take half of value because  180° = 100%

    return {
      size,
      strokeWidth,
      center,
      radius,
      dashOffset,
      dashArray,
      transform: `rotate(-180 ${center} ${center})`,
    };
  }

  private getMasterShadow(angle: number) {
    const { x, y } = this.rotateByAngle(this.shadowDirection, angle);
    return { x, y };
  }

  private rotateByAngle(shadowDirection: ShadowDirection, angle: number) {
    const radians = (Math.PI / 180) * angle;
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    const nx = cos * shadowDirection.x + sin * shadowDirection.y;
    const ny = cos * shadowDirection.y - sin * shadowDirection.x;

    return {
      x: NumberFunctions.roundToPlaces(nx, 2),
      y: NumberFunctions.roundToPlaces(ny, 2),
    };
  }
}
