import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { BundlePersonInterface } from '../../+models';
import * as BundlePersonActions from './bundle-person.actions';

export const NAME = 'bundlePersons';

export interface State extends EntityState<BundlePersonInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<BundlePersonInterface> = createEntityAdapter<BundlePersonInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

const bundlePersonReducer = createReducer(
  initialState,
  on(BundlePersonActions.addBundlePerson, (state, action) => adapter.addOne(action.bundlePerson, state)),
  on(BundlePersonActions.upsertBundlePerson, (state, action) => adapter.upsertOne(action.bundlePerson, state)),
  on(BundlePersonActions.addBundlePersons, (state, action) => adapter.addMany(action.bundlePersons, state)),
  on(BundlePersonActions.upsertBundlePersons, (state, action) => adapter.upsertMany(action.bundlePersons, state)),
  on(BundlePersonActions.updateBundlePerson, (state, action) => adapter.updateOne(action.bundlePerson, state)),
  on(BundlePersonActions.updateBundlePersons, (state, action) => adapter.updateMany(action.bundlePersons, state)),
  on(BundlePersonActions.deleteBundlePerson, (state, action) => adapter.removeOne(action.id, state)),
  on(BundlePersonActions.deleteBundlePersons, (state, action) => adapter.removeMany(action.ids, state)),
  on(BundlePersonActions.clearBundlePersons, (state) => adapter.removeAll(state)),
  on(BundlePersonActions.bundlePersonsLoaded, (state, action) =>
    adapter.setAll(action.bundlePersons, { ...state, loaded: true })
  ),
  on(BundlePersonActions.bundlePersonsLoadError, (state, action) => {
    return {
      ...state,
      ...{ error: action.error },
      loaded: false,
    };
  }),
  on(BundlePersonActions.updateBundlePersonsAccess, (state, { bundleId, bundlePersons }) => {
    const cleanedState = adapter.removeMany(
      (bundlePerson: BundlePersonInterface) => bundlePerson.bundleId === bundleId,
      state
    );
    const updatedState = adapter.addMany(bundlePersons, cleanedState);
    return updatedState;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return bundlePersonReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
