import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { NewsInterface } from '../../+models';
import { NewsActions, NewsActionTypes } from './news.actions';

export const NAME = 'news';

export interface State extends EntityState<NewsInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

function byPublishDate(a: NewsInterface, b: NewsInterface) {
  return +b.publishDate - +a.publishDate;
}

export const adapter: EntityAdapter<NewsInterface> = createEntityAdapter<NewsInterface>({
  sortComparer: byPublishDate,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: NewsActions): State {
  switch (action.type) {
    case NewsActionTypes.NewsLoaded: {
      return adapter.setAll(action.payload.news, { ...state, loaded: true });
    }

    case NewsActionTypes.NewsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case NewsActionTypes.ClearNews: {
      return adapter.removeAll(state);
    }

    case NewsActionTypes.AddNews: {
      return adapter.addOne(action.payload.news, state);
    }

    case NewsActionTypes.UploadNewsImage: {
      const actionPayload = action.payload as { id: number; image: string };
      return adapter.updateOne(
        {
          id: actionPayload.id,
          changes: { image: actionPayload.image },
        },
        state
      );
    }

    case NewsActionTypes.UpdateNews: {
      const { changes, id } = action.payload.news;
      const { categoryIds, roleIds, methodIds, ...newsItem } = changes;

      return adapter.updateOne(
        {
          id,
          changes: newsItem,
        },
        state
      );
    }

    case NewsActionTypes.DeleteNews: {
      return adapter.removeMany(action.payload.ids, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
