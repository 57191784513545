import { LearningRecordInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum LearningRecordActionTypes {
  SaveLearningRecord = '[LearningRecord] Save Learning Record',
}

export class SaveLearningRecord implements FeedbackTriggeringAction {
  readonly type = LearningRecordActionTypes.SaveLearningRecord;

  constructor(
    public payload: {
      learningRecord: LearningRecordInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
      isFinalUpdate?: boolean;
    }
  ) {}
}

export type LearningRecordsActions = SaveLearningRecord;
