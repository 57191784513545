import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { EduContentBookInterface, ProductInterface } from '@campus/dal';

export interface BooksTileIconInterface {
  icon?: string;
  tooltip?: string;
  label?: string;
  className?: string;
}
export interface BooksTileBadgeInterface extends BooksTileIconInterface {
  label?: string;
  lottie?: string;
}
export interface BooksTileEventInterface {
  handler?: (id: number) => void;
  tooltip?: string;
}
export interface BooksTileActionInterface extends BooksTileEventInterface {
  icon?: BooksTileIconInterface;
}
export interface BooksTileLabelInterface extends BooksTileEventInterface {
  text: string;
}
export interface BooksTileRowInterface {
  guid?: number; // for trackBy
  label?: string;
  class?: string;
  icon?: BooksTileIconInterface;
  actions?: BooksTileActionInterface[];
  data: EduContentBookInterface; // the original data this row represents
}

export interface BooksTileComponentInterface {
  guid: number; // used for trackBy
  isAdaptive: boolean;
  adaptiveTaskEnabled?: boolean;
  hasFavorite?: boolean;
  badge?: BooksTileBadgeInterface;
  logoUrl?: string;
  title?: string;
  headerIcon?: BooksTileIconInterface;
  rows: BooksTileRowInterface[];
  data: ProductInterface; // the original data this tile represents
  selected: BooksTileRowInterface[];
}

@Component({
  selector: 'campus-books-tile',
  templateUrl: './books-tile.component.html',
  styleUrls: ['./books-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BooksTileComponent {
  @Input() data: ProductInterface; // original data this tile represents
  @Input() badge: BooksTileBadgeInterface;
  @Input() logoUrl: string;
  @Input() title: string;
  @Input() headerIcon: BooksTileIconInterface;
  @Input() rows: BooksTileRowInterface[] = [];
  @Input() disabled = false;

  @Output() rowClick = new EventEmitter<BooksTileRowInterface>();

  @HostBinding('class.shared-books-tile')
  sharedBooksTileClass = true;

  public trackByRowGuid(index: number, row: BooksTileRowInterface): number {
    return row.guid;
  }

  public onRowClick(row: BooksTileRowInterface): void {
    this.rowClick.emit(row);
  }
}
