import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch, pessimisticUpdate } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import {
  EduContentTocServiceInterface,
  EDU_CONTENT_TOC_SERVICE_TOKEN,
} from '../../edu-content-toc/edu-content-toc.service.interface';
import { DalState } from '../dal.state.interface';
import {
  AddEduContentTocEduContentsForBook,
  AddLoadedBookForEduContentTocEduContent,
  EduContentTocEduContentsActionTypes,
  EduContentTocEduContentsLoadError,
  LoadEduContentTocEduContentsForBook,
} from './edu-content-toc-edu-content.actions';
import { isBookLoaded } from './edu-content-toc-edu-content.selectors';

@Injectable()
export class EduContentTocEduContentEffects {
  loadEduContentTocEduContents$ = createEffect(() =>
    this.actions.pipe(
      ofType(EduContentTocEduContentsActionTypes.LoadEduContentTocEduContentsForBook),
      concatLatestFrom(() => this.store),
      fetch({
        run: (action: LoadEduContentTocEduContentsForBook, state) => {
          const requestedBookId = action.payload.bookId;

          if (isBookLoaded({ bookId: requestedBookId })(state)) {
            return;
          }

          return this.tocService.getEduContentTocEduContentForBookId(requestedBookId).pipe(
            map(
              (eduContentTocEduContents) =>
                new AddEduContentTocEduContentsForBook({
                  bookId: requestedBookId,
                  eduContentTocEduContents,
                })
            )
          );
        },
        onError: (action: LoadEduContentTocEduContentsForBook, error) => {
          return new EduContentTocEduContentsLoadError(error);
        },
      })
    )
  );

  addLoadedBook$ = createEffect(() =>
    this.actions.pipe(
      ofType(EduContentTocEduContentsActionTypes.AddEduContentTocEduContentsForBook),
      pessimisticUpdate({
        run: (action: AddEduContentTocEduContentsForBook) => {
          const addedBookId = action.payload.bookId;

          return new AddLoadedBookForEduContentTocEduContent({
            bookId: addedBookId,
          });
        },
        onError: (action: AddEduContentTocEduContentsForBook, error) => {
          return new EduContentTocEduContentsLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(EDU_CONTENT_TOC_SERVICE_TOKEN)
    private tocService: EduContentTocServiceInterface
  ) {}
}
