<div class="ui-content-editable">
  <span class="ui-content-editable__text" *ngIf="!active" [class.ui-content-editable__text--placeholder]="!text">{{
    text || placeholder
  }}</span>
  <mat-form-field
    class="ui-content-editable__form-field"
    [style.display]="active ? null : 'none'"
    campusClickPreventDefault
    campusClickStopPropagation
  >
    <input
      *ngIf="!multiline"
      (focus)="onFocus($event)"
      matInput
      [(ngModel)]="text"
      (keydown.enter)="saveChanges()"
      (keydown.escape)="cancelChanges()"
      [placeholder]="placeholder"
    />
    <textarea
      *ngIf="multiline"
      matInput
      (focus)="onFocus($event)"
      [(ngModel)]="text"
      (keydown.escape)="cancelChanges()"
      [placeholder]="placeholder"
    >
    </textarea>
  </mat-form-field>
  <span *ngIf="active; else inactive" class="ui-content-editable__actions">
    <campus-button
      icon
      flat
      class="ui-content-editable__actions__confirm"
      *ngIf="textIsValid()"
      iconClass="check"
      (click)="saveChanges()"
      campusClickStopPropagation
    ></campus-button>
    <campus-button
      icon
      flat
      class="ui-content-editable__actions__cancel"
      iconClass="close"
      (click)="cancelChanges()"
      campusClickStopPropagation
    ></campus-button>
  </span>
  <ng-template #inactive
    ><campus-icon *ngIf="icon" [svgIcon]="icon" class="ui-content-editable__edit" (click)="active = true"></campus-icon
  ></ng-template>
</div>
