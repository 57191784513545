import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { WINDOW } from '@campus/browser';

@Injectable()
export class RedirectGuard implements CanActivate {
  private notFoundUrl = '/error/404';

  constructor(private router: Router, @Inject(WINDOW) private window: Window) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const { url } = route.queryParams;

    if (!url) return this.router.parseUrl(this.notFoundUrl);

    this.window.location.assign(url);

    return false;
  }
}

export class RedirectHelper {
  public static isExternalUrl(url: string = '') {
    return url.startsWith('http');
  }

  public static getExternalRoute(url: string = '') {
    const encodedUrl = encodeURIComponent(url);
    return `/external?url=${encodedUrl}`;
  }

  public static getRoute(url: string = '') {
    return RedirectHelper.isExternalUrl(url) ? RedirectHelper.getExternalRoute(url) : url;
  }
}
