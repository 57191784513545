import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaluationSubjectApi } from '../+api';
import { EvaluationSubjectInterface } from '../+models';
import { DAL_OPTIONS, DalOptions } from '../dal.module';
import {
  AddEvaluationSubjectResponseInterface,
  EvaluationSubjectServiceInterface,
  UpdateEvaluationSubjectResponseInterface,
} from './evaluation-subject.service.interface';

@Injectable({
  providedIn: 'root',
})
export class EvaluationSubjectService implements EvaluationSubjectServiceInterface {
  private http = inject(HttpClient);
  private dalOptions = inject<DalOptions>(DAL_OPTIONS);

  constructor(private evaluationSubjectApi: EvaluationSubjectApi) {}

  getEvaluationSubjectsForBook(bookId: number): Observable<EvaluationSubjectInterface[]> {
    return this.evaluationSubjectApi.getEvaluationSubjectsForBook(bookId);
  }

  addEvaluationSubject(
    evaluationSubject: EvaluationSubjectInterface
  ): Observable<AddEvaluationSubjectResponseInterface> {
    return this.evaluationSubjectApi.addEvaluationSubject(
      evaluationSubject
    ) as Observable<AddEvaluationSubjectResponseInterface>;
  }
  updateEvaluationSubject(
    evaluationSubjectId: number,
    changes: Partial<EvaluationSubjectInterface>
  ): Observable<UpdateEvaluationSubjectResponseInterface> {
    return this.evaluationSubjectApi.updateEvaluationSubject(
      evaluationSubjectId,
      changes
    ) as Observable<UpdateEvaluationSubjectResponseInterface>;
  }
  deleteEvaluationSubject(evaluationSubjectId: number): Observable<boolean> {
    return this.evaluationSubjectApi.deleteEvaluationSubject(evaluationSubjectId);
  }

  getEvaluationSubjects(
    searchTerm?: string,
    filters?: { [key: string]: string | number | number[] },
    pagination?: { from?; amount? },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<{ results: object[]; count: number }> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/EvaluationSubjects/filtered`;
    const params = new HttpParams({
      fromObject: {
        searchTerm: searchTerm || '',
        filters: JSON.stringify(filters),
        columns: JSON.stringify(columns),
        sorting: JSON.stringify(sorting),
        pagination: JSON.stringify(pagination),
      },
    });

    return this.http.get<{ results: object[]; count: number }>(url, {
      params,
      withCredentials: true,
    });
  }
}
