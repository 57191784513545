import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DAL_OPTIONS, DalOptions } from '../../dal.module';

export type PolpoApiGet = <T>(path: string, params?: HttpParams) => Observable<T>;
export type PolpoApiPut = <T>(path: string, params?: HttpParams, body?: object) => Observable<T>;
export type PolpoApiDelete = <T>(path: string, params?: HttpParams) => Observable<T>;
export type PolpoApiPost = <T>(path: string, params?: HttpParams, body?: object) => Observable<T>;
export type PolpoApiPatch = <T>(path: string, params?: HttpParams, body?: object) => Observable<T>;

@Injectable({ providedIn: 'root' })
export class PolpoApi {
  private _defaultHttpOptions = { withCredentials: true };

  constructor(private httpClient: HttpClient, @Inject(DAL_OPTIONS) private options: DalOptions) {}

  public get<T>(model: string) {
    return (path: string, params?: HttpParams) => {
      const endpoint = this.getEndPoint(model, path);

      return this.httpClient.get<T>(endpoint, {
        ...this._defaultHttpOptions,
        ...(params ? { params } : {}),
      });
    };
  }

  public put<T>(model: string) {
    return (path: string, params?: HttpParams, body: object = {}) => {
      const endpoint = this.getEndPoint(model, path);

      return this.httpClient.put<T>(endpoint, body, {
        ...this._defaultHttpOptions,
        ...(params ? { params } : {}),
      });
    };
  }

  public delete<T>(model: string) {
    return (path: string, params?: HttpParams) => {
      const endpoint = this.getEndPoint(model, path);

      return this.httpClient.delete<T>(endpoint, {
        ...this._defaultHttpOptions,
        ...(params ? { params } : {}),
      });
    };
  }

  public post<T>(model: string) {
    return (path: string, params?: HttpParams, body: object = {}) => {
      const endpoint = this.getEndPoint(model, path);

      return this.httpClient.post<T>(endpoint, body, {
        ...this._defaultHttpOptions,
        ...(params ? { params } : {}),
      });
    };
  }

  public patch<T>(model: string) {
    return (path: string, params?: HttpParams, body: object = {}) => {
      const endpoint = this.getEndPoint(model, path);

      return this.httpClient.patch<T>(endpoint, body, {
        ...this._defaultHttpOptions,
        ...(params ? { params } : {}),
      });
    };
  }

  private getEndPoint(model: string, path: string) {
    if (!path) return `${this.options.apiBaseUrl}/api/${model}`;
    return `${this.options.apiBaseUrl}/api/${model}/${path}`;
  }
}
