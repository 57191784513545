import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { ContentStatusQueries } from '.';
import {
  StudentContentStatusServiceInterface,
  STUDENT_CONTENT_STATUS_SERVICE_TOKEN,
} from '../../student-content-status/student-content-status.service.interface';
import { DalState } from '../dal.state.interface';
import {
  ContentStatusesActionTypes,
  ContentStatusesLoaded,
  ContentStatusesLoadError,
  LoadContentStatuses,
} from './content-status.actions';

@Injectable()
export class ContentStatusesEffects {
  loadContentStatuses$ = createEffect(() =>
    this.actions.pipe(
      ofType(ContentStatusesActionTypes.LoadContentStatuses),
      concatLatestFrom(() => this.store.select(ContentStatusQueries.getLoaded)),
      fetch({
        run: (action: LoadContentStatuses, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.contentStatusService
            .getAllContentStatuses()
            .pipe(map((contentStatuses) => new ContentStatusesLoaded({ contentStatuses })));
        },
        onError: (action: LoadContentStatuses, error) => {
          return new ContentStatusesLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(STUDENT_CONTENT_STATUS_SERVICE_TOKEN)
    private contentStatusService: StudentContentStatusServiceInterface
  ) {}
}
