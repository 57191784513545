import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { OlfLearningDomainInterface } from '../../+models';

export enum OlfLearningDomainsActionTypes {
  OlfLearningDomainsLoaded = '[OlfLearningDomains] OlfLearningDomains Loaded',
  OlfLearningDomainsLoadError = '[OlfLearningDomains] Load Error',
  LoadOlfLearningDomains = '[OlfLearningDomains] Load OlfLearningDomains',
  AddOlfLearningDomain = '[OlfLearningDomains] Add OlfLearningDomain',
  UpsertOlfLearningDomain = '[OlfLearningDomains] Upsert OlfLearningDomain',
  AddOlfLearningDomains = '[OlfLearningDomains] Add OlfLearningDomains',
  UpsertOlfLearningDomains = '[OlfLearningDomains] Upsert OlfLearningDomains',
  UpdateOlfLearningDomain = '[OlfLearningDomains] Update OlfLearningDomain',
  UpdateOlfLearningDomains = '[OlfLearningDomains] Update OlfLearningDomains',
  DeleteOlfLearningDomain = '[OlfLearningDomains] Delete OlfLearningDomain',
  DeleteOlfLearningDomains = '[OlfLearningDomains] Delete OlfLearningDomains',
  ClearOlfLearningDomains = '[OlfLearningDomains] Clear OlfLearningDomains',
}

export class LoadOlfLearningDomains implements Action {
  readonly type = OlfLearningDomainsActionTypes.LoadOlfLearningDomains;

  constructor(public payload: { force?: boolean } = {}) {}
}

export class OlfLearningDomainsLoaded implements Action {
  readonly type = OlfLearningDomainsActionTypes.OlfLearningDomainsLoaded;

  constructor(public payload: { olfLearningDomains: OlfLearningDomainInterface[] }) {}
}

export class OlfLearningDomainsLoadError implements Action {
  readonly type = OlfLearningDomainsActionTypes.OlfLearningDomainsLoadError;
  constructor(public payload: any) {}
}

export class AddOlfLearningDomain implements Action {
  readonly type = OlfLearningDomainsActionTypes.AddOlfLearningDomain;

  constructor(public payload: { olfLearningDomain: OlfLearningDomainInterface }) {}
}

export class UpsertOlfLearningDomain implements Action {
  readonly type = OlfLearningDomainsActionTypes.UpsertOlfLearningDomain;

  constructor(public payload: { olfLearningDomain: OlfLearningDomainInterface }) {}
}

export class AddOlfLearningDomains implements Action {
  readonly type = OlfLearningDomainsActionTypes.AddOlfLearningDomains;

  constructor(public payload: { olfLearningDomains: OlfLearningDomainInterface[] }) {}
}

export class UpsertOlfLearningDomains implements Action {
  readonly type = OlfLearningDomainsActionTypes.UpsertOlfLearningDomains;

  constructor(public payload: { olfLearningDomains: OlfLearningDomainInterface[] }) {}
}

export class UpdateOlfLearningDomain implements Action {
  readonly type = OlfLearningDomainsActionTypes.UpdateOlfLearningDomain;

  constructor(public payload: { olfLearningDomain: Update<OlfLearningDomainInterface> }) {}
}

export class UpdateOlfLearningDomains implements Action {
  readonly type = OlfLearningDomainsActionTypes.UpdateOlfLearningDomains;

  constructor(public payload: { olfLearningDomains: Update<OlfLearningDomainInterface>[] }) {}
}

export class DeleteOlfLearningDomain implements Action {
  readonly type = OlfLearningDomainsActionTypes.DeleteOlfLearningDomain;

  constructor(public payload: { id: number }) {}
}

export class DeleteOlfLearningDomains implements Action {
  readonly type = OlfLearningDomainsActionTypes.DeleteOlfLearningDomains;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearOlfLearningDomains implements Action {
  readonly type = OlfLearningDomainsActionTypes.ClearOlfLearningDomains;
}

export type OlfLearningDomainsActions =
  | LoadOlfLearningDomains
  | OlfLearningDomainsLoaded
  | OlfLearningDomainsLoadError
  | AddOlfLearningDomain
  | UpsertOlfLearningDomain
  | AddOlfLearningDomains
  | UpsertOlfLearningDomains
  | UpdateOlfLearningDomain
  | UpdateOlfLearningDomains
  | DeleteOlfLearningDomain
  | DeleteOlfLearningDomains
  | ClearOlfLearningDomains;
