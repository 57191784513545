import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { GoalTopicInterface } from '../../+models';
import { GoalTopicsActions, GoalTopicsActionTypes } from './goal-topic.actions';

export const NAME = 'goalTopics';

export interface State extends EntityState<GoalTopicInterface> {
  // additional entities state properties
  loaded: boolean;
  loadedForLearningArea: { [learningAreaId: number]: boolean };
  loadedForBook?: Dictionary<boolean>;
  error?: any;
  eduContentsLoaded: { [id: string]: boolean };
}

export const adapter: EntityAdapter<GoalTopicInterface> = createEntityAdapter<GoalTopicInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForLearningArea: {},
  eduContentsLoaded: {},
  loadedForBook: {},
});

export function reducer(state = initialState, action: GoalTopicsActions): State {
  switch (action.type) {
    case GoalTopicsActionTypes.AddGoalTopic: {
      return adapter.addOne(action.payload.goalTopic, state);
    }

    case GoalTopicsActionTypes.UpsertGoalTopic: {
      return adapter.upsertOne(action.payload.goalTopic, state);
    }

    case GoalTopicsActionTypes.AddGoalTopics: {
      return adapter.addMany(action.payload.goalTopics, state);
    }

    case GoalTopicsActionTypes.UpsertGoalTopics: {
      return adapter.upsertMany(action.payload.goalTopics, state);
    }

    case GoalTopicsActionTypes.UpdateGoalTopic: {
      return adapter.updateOne(action.payload.goalTopic, state);
    }

    case GoalTopicsActionTypes.UpdateGoalTopics: {
      return adapter.updateMany(action.payload.goalTopics, state);
    }

    case GoalTopicsActionTypes.DeleteGoalTopic: {
      return adapter.removeOne(action.payload.id, state);
    }

    case GoalTopicsActionTypes.DeleteGoalTopics: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case GoalTopicsActionTypes.GoalTopicsLoaded: {
      return adapter.setAll(action.payload.goalTopics, {
        ...state,
        loaded: true,
      });
    }

    case GoalTopicsActionTypes.GoalTopicsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case GoalTopicsActionTypes.ClearGoalTopics: {
      return adapter.removeAll(state);
    }
    case GoalTopicsActionTypes.LinkedEduContentMetadaLoaded: {
      return {
        ...state,
        eduContentsLoaded: {
          ...state.eduContentsLoaded,
          ...{ [action.payload.goalTopicId]: true },
        },
      };
    }

    case GoalTopicsActionTypes.AddGoalTopicsForLearningArea: {
      const newState = adapter.addMany(action.payload.goalTopics, state);

      return {
        ...newState,
        loadedForLearningArea: {
          ...newState.loadedForLearningArea,
          [action.payload.learningAreaId]: true,
        },
      };
    }

    case GoalTopicsActionTypes.AddGoalTopicsForBook: {
      const { bookId, goalTopics } = action.payload;
      return adapter.upsertMany(goalTopics, {
        ...state,
        loadedForBook: { ...state.loadedForBook, [bookId]: true },
      });
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
