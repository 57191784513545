import { inject, Injectable } from '@angular/core';
import { DalState, FavoriteQueries, PersonProductQueries, ProductQueries } from '@campus/dal';
import { FavoriteTypesEnum } from '@campus/environment';
import {
  BooksTileActionHandlerInterface,
  BooksTileComponentInterface,
  BooksTileRowInterface,
  BooksTileService,
  BooksTileServiceInterface,
} from '@campus/shared';
import { groupArrayByKeys } from '@campus/utils';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class PolpoFavoriteBooksService {
  private store: Store<DalState> = inject(Store);
  private booksTileService: BooksTileServiceInterface = inject(BooksTileService);

  public getFavoriteBooks$(
    booksTileActionHandler?: BooksTileActionHandlerInterface
  ): Observable<BooksTileComponentInterface[]> {
    return combineLatest([
      this.store.pipe(select(PersonProductQueries.getProductsWithMethod)),
      this.store.pipe(select(ProductQueries.getBooksByProduct)),
      this.store.pipe(select(ProductQueries.getMarketingHighlightBooksByProduct)),
      this.store.pipe(select(FavoriteQueries.getByType, { type: FavoriteTypesEnum.BOOK })),
    ]).pipe(
      map(([products, booksByProductId, marketingBooksByProductId, bookFavorites]) => {
        const favoritesByProductByBookId = groupArrayByKeys(bookFavorites, ['productId', 'eduContentBookId']);
        return products.reduce((acc, product) => {
          const booksForProduct = product.isMarketingHighlight
            ? marketingBooksByProductId[product.id]
            : booksByProductId[product.id];

          if (!booksForProduct?.length) return acc;

          const favoriteBooks = booksForProduct.filter(
            (book) => favoritesByProductByBookId[product.id] && favoritesByProductByBookId[product.id][book.id]
          );
          if (!favoriteBooks.length) return acc;

          const bookTile = booksTileActionHandler
            ? this.booksTileService.mapToBooksTileWithActions(
                product.id,
                product.description,
                product.method,
                favoriteBooks,
                booksTileActionHandler,
                ['toc', 'boeke'],
                bookFavorites,
                product
              )
            : this.booksTileService.mapToBooksTileWithoutActions(
                product.id,
                product.description,
                product.method,
                favoriteBooks
              );

          (bookTile.rows || []).sort((a: BooksTileRowInterface, b) => a.data.displayOrder - b.data.displayOrder);

          return [...acc, bookTile];
        }, []);
      }),
      shareReplay(1)
    );
  }
}
