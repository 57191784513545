import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { EduContentSourceInterface } from '../../+models';

export const loadEduContentSources = createAction(
  '[EduContentSource] Load EduContentSources',
  (userId: number = null, force: boolean = false) => ({
    userId,
    force,
  })
);

export const eduContentSourcesLoaded = createAction(
  '[EduContentSource] EduContentSources loaded',
  props<{ eduContentSources: EduContentSourceInterface[] }>()
);

export const eduContentSourcesLoadError = createAction('[EduContentSource] Load Error', props<{ error: any }>());

export const addEduContentSource = createAction(
  '[EduContentSource] Add EduContentSource',
  props<{ eduContentSource: EduContentSourceInterface }>()
);

export const upsertEduContentSource = createAction(
  '[EduContentSource] Upsert EduContentSource',
  props<{ eduContentSource: EduContentSourceInterface }>()
);

export const addEduContentSources = createAction(
  '[EduContentSource] Add EduContentSources',
  props<{ eduContentSources: EduContentSourceInterface[] }>()
);

export const upsertEduContentSources = createAction(
  '[EduContentSource] Upsert EduContentSources',
  props<{ eduContentSources: EduContentSourceInterface[] }>()
);

export const updateEduContentSource = createAction(
  '[EduContentSource] Update EduContentSource',
  props<{ eduContentSource: Update<EduContentSourceInterface> }>()
);

export const updateEduContentSources = createAction(
  '[EduContentSource] Update EduContentSources',
  props<{ eduContentSources: Update<EduContentSourceInterface>[] }>()
);

export const deleteEduContentSource = createAction(
  '[EduContentSource] Delete EduContentSource',
  props<{ id: number }>()
);

export const deleteEduContentSources = createAction(
  '[EduContentSource] Delete EduContentSources',
  props<{ ids: number[] }>()
);

export const clearEduContentSources = createAction('[EduContentSource] Clear EduContentSources');
