import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PurchaseInterface } from '../../+models';
import { PurchasesActions, PurchasesActionTypes } from './purchase.actions';

export const NAME = 'purchases';

export interface State extends EntityState<PurchaseInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<PurchaseInterface> = createEntityAdapter<PurchaseInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: PurchasesActions): State {
  switch (action.type) {
    case PurchasesActionTypes.AddPurchase: {
      return adapter.addOne(action.payload.purchase, state);
    }

    case PurchasesActionTypes.UpsertPurchase: {
      return adapter.upsertOne(action.payload.purchase, state);
    }

    case PurchasesActionTypes.AddPurchases: {
      return adapter.addMany(action.payload.purchases, state);
    }

    case PurchasesActionTypes.UpsertPurchases: {
      return adapter.upsertMany(action.payload.purchases, state);
    }

    case PurchasesActionTypes.UpdatePurchase: {
      return adapter.updateOne(action.payload.purchase, state);
    }

    case PurchasesActionTypes.UpdatePurchases: {
      return adapter.updateMany(action.payload.purchases, state);
    }

    case PurchasesActionTypes.DeletePurchase: {
      return adapter.removeOne(action.payload.id, state);
    }

    case PurchasesActionTypes.DeletePurchases: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case PurchasesActionTypes.PurchasesLoaded: {
      return adapter.setAll(action.payload.purchases, {
        ...state,
        loaded: true,
      });
    }

    case PurchasesActionTypes.PurchasesLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case PurchasesActionTypes.ClearPurchases: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
