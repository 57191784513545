import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonApi } from '../+api';
import { UnlockedBoekeGroupInterface } from '../+models';
import { UnlockedBoekeGroupServiceInterface } from './unlocked-boeke-group.service.interface';

@Injectable({
  providedIn: 'root',
})
export class UnlockedBoekeGroupService implements UnlockedBoekeGroupServiceInterface {
  constructor(private personApi: PersonApi) {}

  getAllForUser(userId: number): Observable<UnlockedBoekeGroupInterface[]> {
    return this.personApi
      .getData(userId, 'unlockedBoekeGroups')
      .pipe(map((res: { unlockedBoekeGroups: UnlockedBoekeGroupInterface[] }) => res.unlockedBoekeGroups));
  }
}
