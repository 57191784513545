/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { LearnosityApi as SDKLearnosityApi } from '@diekeure/polpo-api-angular-sdk';
import { LearnosityInitResponseInterface, LearnosityItemsConfigInterface } from '../../+external-interfaces';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class LearnosityApi extends ModelApiBase {
  private SDKLearnosityApi = inject(SDKLearnosityApi);

  constructor(api: PolpoApi) {
    super(api, 'Learnosity');
  }

  updateReport(
    sessionId: string,
    report: {
      session_id: string;
      user_id: string;
      activity_id: string;
    }
  ): Observable<LearnosityInitResponseInterface> {
    // @ts-expect-error TS 2556
    return this.SDKLearnosityApi.updateReport(...arguments);
  }

  previewActivity(learnosityReference: string): Observable<LearnosityItemsConfigInterface> {
    // @ts-expect-error TS 2556
    return this.SDKLearnosityApi.previewActivity(...arguments);
  }

  buildReportsRequest(id: number): Observable<LearnosityItemsConfigInterface> {
    // @ts-expect-error TS 2556
    return this.SDKLearnosityApi.buildReportsRequest(...arguments);
  }
}
