import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { CurriculumTreeInterface } from '../../+models';

export enum CurriculumTreesActionTypes {
  CurriculumTreesLoaded = '[CurriculumTrees] CurriculumTrees Loaded',
  CurriculumTreesLoadError = '[CurriculumTrees] Load Error',
  LoadCurriculumTrees = '[CurriculumTrees] Load CurriculumTrees',
  AddCurriculumTree = '[CurriculumTrees] Add CurriculumTree',
  UpsertCurriculumTree = '[CurriculumTrees] Upsert CurriculumTree',
  AddCurriculumTrees = '[CurriculumTrees] Add CurriculumTrees',
  UpsertCurriculumTrees = '[CurriculumTrees] Upsert CurriculumTrees',
  UpdateCurriculumTree = '[CurriculumTrees] Update CurriculumTree',
  UpdateCurriculumTrees = '[CurriculumTrees] Update CurriculumTrees',
  DeleteCurriculumTree = '[CurriculumTrees] Delete CurriculumTree',
  DeleteCurriculumTrees = '[CurriculumTrees] Delete CurriculumTrees',
  ClearCurriculumTrees = '[CurriculumTrees] Clear CurriculumTrees',
}

export class LoadCurriculumTrees implements Action {
  readonly type = CurriculumTreesActionTypes.LoadCurriculumTrees;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class CurriculumTreesLoaded implements Action {
  readonly type = CurriculumTreesActionTypes.CurriculumTreesLoaded;

  constructor(public payload: { curriculumTrees: CurriculumTreeInterface[] }) {}
}

export class CurriculumTreesLoadError implements Action {
  readonly type = CurriculumTreesActionTypes.CurriculumTreesLoadError;
  constructor(public payload: any) {}
}

export class AddCurriculumTree implements Action {
  readonly type = CurriculumTreesActionTypes.AddCurriculumTree;

  constructor(public payload: { curriculumTree: CurriculumTreeInterface }) {}
}

export class UpsertCurriculumTree implements Action {
  readonly type = CurriculumTreesActionTypes.UpsertCurriculumTree;

  constructor(public payload: { curriculumTree: CurriculumTreeInterface }) {}
}

export class AddCurriculumTrees implements Action {
  readonly type = CurriculumTreesActionTypes.AddCurriculumTrees;

  constructor(public payload: { curriculumTrees: CurriculumTreeInterface[] }) {}
}

export class UpsertCurriculumTrees implements Action {
  readonly type = CurriculumTreesActionTypes.UpsertCurriculumTrees;

  constructor(public payload: { curriculumTrees: CurriculumTreeInterface[] }) {}
}

export class UpdateCurriculumTree implements Action {
  readonly type = CurriculumTreesActionTypes.UpdateCurriculumTree;

  constructor(public payload: { curriculumTree: Update<CurriculumTreeInterface> }) {}
}

export class UpdateCurriculumTrees implements Action {
  readonly type = CurriculumTreesActionTypes.UpdateCurriculumTrees;

  constructor(public payload: { curriculumTrees: Update<CurriculumTreeInterface>[] }) {}
}

export class DeleteCurriculumTree implements Action {
  readonly type = CurriculumTreesActionTypes.DeleteCurriculumTree;

  constructor(public payload: { id: string }) {}
}

export class DeleteCurriculumTrees implements Action {
  readonly type = CurriculumTreesActionTypes.DeleteCurriculumTrees;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearCurriculumTrees implements Action {
  readonly type = CurriculumTreesActionTypes.ClearCurriculumTrees;
}

export type CurriculumTreesActions =
  | LoadCurriculumTrees
  | CurriculumTreesLoaded
  | CurriculumTreesLoadError
  | AddCurriculumTree
  | UpsertCurriculumTree
  | AddCurriculumTrees
  | UpsertCurriculumTrees
  | UpdateCurriculumTree
  | UpdateCurriculumTrees
  | DeleteCurriculumTree
  | DeleteCurriculumTrees
  | ClearCurriculumTrees;
