import { EduContentMetadataInterface } from './EduContentMetadata.interface';

export interface StorageInfoInterface {
  checksum?: string;
  name: string;
  storageName: string;
  eduFileId?: number;
  eduFile?: EduFileInterface;
}

export interface EduFileInterface {
  fileName?: string;
  file?: string;
  checksum?: string;
  type: string;
  id?: number;
  eduContentMetadataId?: number;
  eduContentMetadata?: EduContentMetadataInterface;
  artifactId?: number;
}

export enum EduFileTypeEnum {
  EXERCISE = 'exercise',
  SOLUTION = 'solution',
  TIMELINE = 'timeline',
  ARTIFACT = 'artifact',
  WHITEBOARD = 'whiteboard',
  SPEECH = 'speech',
  TEXT = 'text',
}
