import { Component, Directive, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[campusCardAvatar], [cardAvatar], campus-card-avatar',
})
export class CardAvatarDirective {
  @HostBinding('class.ui-card__header__avatar')
  hasCardHeaderAvatarClass = true;
}
@Directive({
  selector: '[campusCardTitle], [cardTitle], campus-card-title',
})
export class CardTitleDirective {
  @HostBinding('class')
  cardHeaderTitleClass = 'ui-card__header__title ui-headline-6';
}

@Directive({
  selector: '[campusCardImage], [cardImage], campus-card-image',
})
export class CardImageDirective {
  @HostBinding('class.ui-card__header__image')
  hasCardHeaderImageClass = true;
}

@Directive({
  selector: '[campusCardSubTitle], [cardSubTitle], campus-card-subtitle',
})
export class CardSubTitleDirective {
  @HostBinding('class')
  cardHeaderSubTitleClass = 'ui-card__header__subtitle ui-body-1';
}

@Directive({
  selector: '[campusCardOverline], [cardOverline], campus-card-overline',
})
export class CardOverlineDirective {
  @HostBinding('class')
  cardHeaderOverlineClass = 'ui-card__header__overline ui-overline';
}

@Directive({
  selector: '[campusCardHeaderActions], [cardHeaderActions], campus-card-header-actions',
})
export class CardHeaderActionsDirective {
  @HostBinding('class.ui-card__header__actions')
  hasCardHeaderActionsClass = true;
}

@Directive({
  selector: '[campusCardContent], [cardContent], campus-card-content',
})
export class CardContentDirective {
  @HostBinding('class')
  cardContentClass = 'ui-card__content ui-caption';
}

@Directive({
  selector: '[campusCardActions], [cardActions], campus-card-actions',
})
export class CardActionsDirective {
  @HostBinding('class.ui-card__actions')
  hasCardActionsClass = true;
}
@Directive({
  selector: '[campusCardSelectedIcon], [cardSelectedIcon], campus-card-selected-icon',
})
export class CardSelectedIconDirective {
  @HostBinding('class.ui-card--selected-icon')
  hasCardSelectedIconClass = true;
}

@Directive({
  selector: '[campusCardFooter], [cardFooter], campus-card-footer',
})
export class CardFooterDirective {
  @HostBinding('class.ui-card__footer') hasCardFooterClass = true;
}
@Directive({
  selector: '[campusCardBadge], [cardBadge], campus-card-badge',
})
export class CardBadgeDirective {
  @HostBinding('class.ui-card__badge') hasCardBadgeClass = true;

  @HostBinding('class.ui-card__badge--small')
  @Input()
  small: boolean;
}
@Component({
  selector: 'campus-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnChanges {
  private _selected = false;

  @HostBinding('class.ui-card')
  hasCardClass = true;

  @HostBinding('class.ui-card--flat')
  @Input()
  flat = false;

  @HostBinding('class.ui-card--hoverable')
  @Input()
  hoverable = false;

  @HostBinding('class.ui-card--selectable')
  @Input()
  selectable = false;

  @HostBinding('class.ui-card--with-badge')
  @Input()
  showBadge = false;

  @HostBinding('class.ui-card--selected')
  hasSelectedClass = false;

  @HostBinding('class')
  disabledClasses = [];

  @Input()
  selected = false;

  @Input() disabled: boolean = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectable || changes.selected) {
      this.hasSelectedClass = this.selectable && this.selected;
    }

    if (changes.disabled) {
      this.disabledClasses = this.getDisabledClasses();
    }
  }

  private getDisabledClasses() {
    return this.disabled ? ['opacity-medium', 'pointer-event-none', 'grayscale-full', 'filter'] : [];
  }
}
