<div
  [class.linear]="form === forms.LINEAR"
  [class.arc]="form === forms.ARC"
  [ngStyle]="form === forms.CIRCULAR || form === forms.ARC ? { 'width.px': diameter, 'height.px': diameter } : {}"
  [matTooltip]="tooltip"
  data-cy="ui-progress-container"
  class="ui-progress__container"
>
  <mat-progress-spinner
    *ngIf="form === forms.CIRCULAR"
    [diameter]="diameter"
    [mode]="mode"
    [value]="percentage"
    data-cy="ui-progress-spinner"
  >
  </mat-progress-spinner>

  <!--
        Ghost element needed,
        because mat-progress-spinner has no .mat-progress-spinner-background
        Fix one to 100% and position above each-other
    -->
  <mat-progress-spinner
    *ngIf="form === forms.CIRCULAR && mode === modes.DETERMINATE"
    [diameter]="diameter"
    value="100"
    class="ui-progress__circular__ghost"
    data-cy="ui-progress-ghost-spinner"
  >
  </mat-progress-spinner>

  <mat-progress-bar
    *ngIf="form === forms.LINEAR"
    [value]="invertedPercentage"
    [mode]="mode"
    data-cy="ui-progress-bar"
    class="success"
  ></mat-progress-bar>

  <div *ngIf="form == forms.ARC" class="ui-progress__arc">
    <svg viewBox="0 0 100 100" [attr.width]="diameter" [attr.height]="diameter">
      <linearGradient id="ui-progress__arc-gradient">
        <stop class="start" offset="0" />
        <stop class="stop" offset="1" />
      </linearGradient>
      <path class="ui-progress__arc-track ui-progress__arc-ghost" d="M20,90 A40,40 0 1,1 80,90" />
      <path
        class="ui-progress__arc-track ui-progress__arc-progress"
        d="M20,90 A40,40 0 1,1 80,90"
        [style.stroke-dashoffset]="(183.5 / 100) * invertedPercentage"
      />
    </svg>
    <campus-icon *ngIf="svgIcon" [svgIcon]="svgIcon" class="ui-caption ui-progress__arc-icon"></campus-icon>
    <span class="ui-caption ui-progress__arc-label" data-cy="ui-progress__arc-label">
      <span class="ui-progress__arc-amount">{{ count }}</span>
      <span class="ui-progress__arc-total">/{{ total }}</span>
    </span>
  </div>

  <span
    *ngIf="showPercentage && !(completedIcon && percentage === 100)"
    [@fadeLabel]
    class="ui-caption ui-progress__label"
    data-cy="ui-progress-percentage-label"
    >{{ percentage }}%{{ percentageSuffix ? ' ' + percentageSuffix : '' }}</span
  >
  <div
    *ngIf="form === forms.CIRCULAR && completedIcon && percentage === 100"
    [@explodeIcon]
    class="ui-progress__completed-icon ui-progress__label"
    data-cy="ui-progress-completed-icon"
    ><ng-content select="[campusCompletedProgressIcon], [completedProgressIcon], completed-progress-icon"></ng-content
  ></div>
</div>
