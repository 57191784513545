import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './method-product-group.reducer';

export const selectMethodProductGroupState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectMethodProductGroupState, (state: State) => state.error);

export const getLoaded = createSelector(selectMethodProductGroupState, (state: State) => state.loaded);

export const getAll = createSelector(selectMethodProductGroupState, selectAll);

export const getCount = createSelector(selectMethodProductGroupState, selectTotal);

export const getIds = createSelector(selectMethodProductGroupState, selectIds);

export const getAllEntities = createSelector(selectMethodProductGroupState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * methodProductGroup$: MethodProductGroupInterface[] = this.store.pipe(
    select(MethodProductGroupQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectMethodProductGroupState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * methodProductGroup$: MethodProductGroupInterface = this.store.pipe(
    select(MethodProductGroupQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectMethodProductGroupState,
  (state: State, props: { id: number }) => state.entities[props.id]
);
