<div
  class="column__filter__container"
  *ngIf="filterCriteria && filterCriteria.length !== 0; else noCriteria"
  [@slideInOut]="animationState()"
  (@slideInOut.done)="animationDone($event)"
>
  <mat-action-list *ngFor="let filterCriterion of filterCriteriaToToggle; let i = index" class="column__filter">
    <button
      mat-list-item
      *ngFor="let value of filterCriterion?.values"
      class="column__filter__value"
      [class.column__filter__value--selected]="value.selected"
      (click)="onFilterSelectionChange(value, !value.hasChild, filterCriterion.name)"
    >
      <div class="column__filter__value__button-content">
        <div class="column__filter__value__button-content__label"
          >{{ value.data[filterCriterion.displayProperty] }}
        </div>
        <div class="column__filter__value__button-content__icons">
          <campus-icon
            campusClickPreventDefault
            campusClickStopPropagation
            *ngIf="value.prediction && value.prediction !== 0"
            class="column__filter__value__button-content__icons__magnifier"
            (click)="onFilterSelectionChange(value, true, filterCriterion.name)"
            [svgIcon]="'magnifier'"
          ></campus-icon>
          <campus-icon
            campusClickPreventDefault
            campusClickStopPropagation
            *ngIf="value.hasChild"
            class="column__filter__value__button-content__icons__arrow"
            (click)="onFilterSelectionChange(value, false, filterCriterion.name)"
            [svgIcon]="'column-filter-arrow'"
          ></campus-icon>
        </div>
      </div>
    </button>
  </mat-action-list>
</div>

<ng-template #noCriteria>
  <div class="column__filter--no-criteria">Er is geen inhoudstafel om op te filteren.</div>
</ng-template>
