import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { YearInterface } from '../+models';
import { DAL_OPTIONS, DalOptions } from '../dal.module';
import { GoalRootInterface } from '../goal/goal.service.interface';
import { GoalGroupRootInterface, GoalGroupServiceInterface } from './goal-group.service.interface';

@Injectable({
  providedIn: 'root',
})
export class GoalGroupService implements GoalGroupServiceInterface {
  private http = inject(HttpClient);
  private dalOptions = inject<DalOptions>(DAL_OPTIONS);

  getGoalGroups(
    searchTerm?: string,
    filters?: { [key: string]: string | number[] | number },
    pagination?: { from?; amount? },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<{ results: GoalGroupRootInterface[]; count: number }> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/GoalGroups/filtered`;
    const params = new HttpParams({
      fromObject: {
        searchTerm: searchTerm || '',
        filters: JSON.stringify(filters || {}),
        columns: JSON.stringify(columns || null),
        sorting: JSON.stringify(sorting || {}),
        pagination: JSON.stringify(pagination || {}),
      },
    });

    return this.http.get<{ results: GoalGroupRootInterface[]; count: number }>(url, {
      params,
      withCredentials: true,
    });
  }

  getGoalGroupForMethodAndLearningArea(methodId: number, learningAreaId: number): Observable<GoalGroupRootInterface> {
    const url = `${this.dalOptions.apiBaseUrl}/api/GoalGroups/get-for-method-and-learning-area`;
    const params = new HttpParams({
      fromObject: {
        methodId: methodId.toString(),
        learningAreaId: learningAreaId.toString(),
      },
    });

    return this.http.get<GoalGroupRootInterface>(url, { params, withCredentials: true });

  };

  getGoalGroup(id: number): Observable<GoalGroupRootInterface & { years?: YearInterface[] }> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/GoalGroups/${id}`;
    return this.http.get<GoalGroupRootInterface>(url, { withCredentials: true });
  }

  createGoalGroup(goalGroup: GoalGroupRootInterface): Observable<number> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/GoalGroups`;
    return this.http.post<number>(url, goalGroup, { withCredentials: true });
  }

  updateGoalGroup(goalGroup: GoalGroupRootInterface): Observable<number> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/GoalGroups/${goalGroup.id}`;
    delete goalGroup.id;
    return this.http.patch<number>(url, goalGroup, { withCredentials: true });
  }

  getGoals(
    groupId: number,
    searchTerm?: string,
    filters?: { [key: string]: string | number[] | number },
    pagination?: { from?; amount? },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<{ results: GoalRootInterface[]; count: number }> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/GoalGroups/${groupId}/goals`;
    const params = new HttpParams({
      fromObject: {
        searchTerm: searchTerm || '',
        filters: JSON.stringify(filters || {}),
        columns: JSON.stringify(columns || null),
        sorting: JSON.stringify(sorting || {}),
        pagination: JSON.stringify(pagination || {}),
      },
    });

    return this.http.get<{ results: GoalRootInterface[]; count: number }>(url, {
      params,
      withCredentials: true,
    });
  }

  updateRelation(goalGroupId: number, body: any): Observable<number> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/GoalGroups/${goalGroupId}/relations`;
    return this.http.post<number>(url, body, { withCredentials: true });
  }

  checkUniqueGoalGroup(methodId: number, learningAreaId: number): Observable<boolean> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/GoalGroups/unique`;
    const params = new HttpParams({
      fromObject: {
        methodId: methodId.toString(),
        learningAreaId: learningAreaId.toString(),
      },
    });

    return this.http.get<boolean>(url, { params, withCredentials: true });
  }
}
