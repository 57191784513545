import { Action } from '@ngrx/store';
import { EduContentTOCLoopInterface } from '../../+models/EduContentTOCLoop.interface';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum EduContentTOCLoopsActionTypes {
  EduContentTOCLoopsLoaded = '[EduContentTOCloops] EduContentTOCLoops Loaded',
  EduContentTOCLoopsLoadError = '[EduContentTOCloops] Load Error',
  LoadEduContentTOCLoops = '[EduContentTOCloops] Load EduContentTOCLoops',
  StartAddEduContentTOCLoops = '[EduContentTOCloops] Start Add EduContentTOCLoop',
  AddEduContentTOCLoops = '[EduContentTOCloops] Add EduContentTOCLoops',
  AddEduContentTOCLoop = '[EduContentTOCloops] Add EduContentTOCLoop',
  DeleteEduContentTOCLoop = '[EduContentTOCloops] Delete EduContentTOCLoop',
  DeleteEduContentTOCLoops = '[EduContentTOCloops] Delete EduContentTOCLoops',
  SetEduContentTOCLoops = '[EduContentTOCloops] Set EduContentTOCLoops',
  StartUpdateEduContentTOCLoops = '[EduContentTOCloops] Start Update EduContentTOCLoops',
  ClearLoadedBooks = '[EduContentTOCloops] Clear loaded books',
  AddEduContentTOCLoopsForBook = '[EduContentTOCloops] Add EduContentTOCLoops for book',
}

export class LoadEduContentTOCLoops implements Action {
  readonly type = EduContentTOCLoopsActionTypes.LoadEduContentTOCLoops;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class EduContentTOCLoopsLoaded implements Action {
  readonly type = EduContentTOCLoopsActionTypes.EduContentTOCLoopsLoaded;

  constructor(public payload: { eduContentTOCLoops: EduContentTOCLoopInterface[] }) {}
}

export class EduContentTOCLoopsLoadError implements Action {
  readonly type = EduContentTOCLoopsActionTypes.EduContentTOCLoopsLoadError;
  constructor(public payload: any) {}
}

export class StartAddEduContentTOCLoops implements Action, FeedbackTriggeringAction {
  readonly type = EduContentTOCLoopsActionTypes.StartAddEduContentTOCLoops;

  constructor(
    public payload: {
      eduContentTOCLoops: EduContentTOCLoopInterface[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class AddEduContentTOCLoop implements Action {
  readonly type = EduContentTOCLoopsActionTypes.AddEduContentTOCLoop;

  constructor(public payload: { eduContentTOCLoop: EduContentTOCLoopInterface }) {}
}

export class AddEduContentTOCLoops implements Action {
  readonly type = EduContentTOCLoopsActionTypes.AddEduContentTOCLoops;

  constructor(public payload: { eduContentTOCLoops: EduContentTOCLoopInterface[] }) {}
}

export class DeleteEduContentTOCLoop implements Action {
  readonly type = EduContentTOCLoopsActionTypes.DeleteEduContentTOCLoop;

  constructor(public payload: { id: number }) {}
}

export class DeleteEduContentTOCLoops implements Action {
  readonly type = EduContentTOCLoopsActionTypes.DeleteEduContentTOCLoops;

  constructor(public payload: { ids: number[] }) {}
}

export class SetEduContentTOCLoops implements Action {
  readonly type = EduContentTOCLoopsActionTypes.SetEduContentTOCLoops;

  constructor(public payload: { created: EduContentTOCLoopInterface[]; deleted: number[] }) {}
}

export class StartUpdateEduContentTOCLoops implements Action, FeedbackTriggeringAction {
  readonly type = EduContentTOCLoopsActionTypes.StartUpdateEduContentTOCLoops;

  constructor(
    public payload: {
      eduContentTOCLoops: EduContentTOCLoopInterface[];
      tocId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class AddEduContentTOCLoopsForBook implements Action {
  readonly type = EduContentTOCLoopsActionTypes.AddEduContentTOCLoopsForBook;

  constructor(public payload: { bookId: number; eduContentTOCLoops: EduContentTOCLoopInterface[] }) {}
}

export class ClearLoadedBooks implements Action {
  readonly type = EduContentTOCLoopsActionTypes.ClearLoadedBooks;
}

export type EduContentTOCLoopsActions =
  | LoadEduContentTOCLoops
  | StartAddEduContentTOCLoops
  | AddEduContentTOCLoops
  | AddEduContentTOCLoop
  | DeleteEduContentTOCLoops
  | DeleteEduContentTOCLoop
  | EduContentTOCLoopsLoaded
  | EduContentTOCLoopsLoadError
  | SetEduContentTOCLoops
  | StartUpdateEduContentTOCLoops
  | ClearLoadedBooks
  | AddEduContentTOCLoopsForBook;
