import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { EduNetQueries } from '.';
import { EduNetServiceInterface, EDU_NET_SERVICE_TOKEN } from '../../metadata/edu-net.service.interface';
import { DalState } from '../dal.state.interface';
import { EduNetsActionTypes, EduNetsLoaded, EduNetsLoadError, LoadEduNets } from './edu-net.actions';

@Injectable()
export class EduNetEffects {
  loadEduNets$ = createEffect(() =>
    this.actions.pipe(
      ofType(EduNetsActionTypes.LoadEduNets),
      concatLatestFrom(() => this.store.select(EduNetQueries.getLoaded)),
      fetch({
        run: (action: LoadEduNets, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.eduNetService.getAll().pipe(map((eduNets) => new EduNetsLoaded({ eduNets })));
        },
        onError: (action: LoadEduNets, error) => {
          return new EduNetsLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(EDU_NET_SERVICE_TOKEN)
    private eduNetService: EduNetServiceInterface
  ) {}
}
