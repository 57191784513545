import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SchoolRoleMappingInterface } from '../../+models';
import { SchoolsActions, SchoolsActionTypes } from '../school/school.actions';
import { SchoolRoleMappingsActions, SchoolRoleMappingsActionTypes } from './school-role-mapping.actions';

export const NAME = 'schoolRoleMappings';

export interface State extends EntityState<SchoolRoleMappingInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<SchoolRoleMappingInterface> = createEntityAdapter<SchoolRoleMappingInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: SchoolRoleMappingsActions | SchoolsActions): State {
  switch (action.type) {
    case SchoolRoleMappingsActionTypes.AddSchoolRoleMapping: {
      return adapter.addOne(action.payload.schoolRoleMapping, state);
    }

    case SchoolRoleMappingsActionTypes.UpsertSchoolRoleMappings: {
      return adapter.upsertMany(action.payload.schoolRoleMappings, state);
    }

    case SchoolRoleMappingsActionTypes.DeleteSchoolRoleMapping: {
      return adapter.removeOne(action.payload.id, state);
    }

    case SchoolRoleMappingsActionTypes.DeleteSchoolRoleMappings: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case SchoolRoleMappingsActionTypes.SchoolRoleMappingsLoaded: {
      return adapter.setAll(action.payload.schoolRoleMappings, {
        ...state,
        loaded: true,
      });
    }

    case SchoolRoleMappingsActionTypes.UpdateSchoolRoleMapping: {
      return adapter.updateOne(action.payload.schoolRoleMapping, state);
    }

    case SchoolRoleMappingsActionTypes.SchoolRoleMappingsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case SchoolsActionTypes.UnlinkTeacher: {
      return adapter.removeOne(action.payload.schoolRoleMappingId, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
