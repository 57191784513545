import { createEntityAdapter, DictionaryNum, EntityAdapter, EntityState } from '@ngrx/entity';
import { MethodStyleInterface } from '../../+models';
import { MethodStylesActions, MethodStylesActionTypes } from './method-style.actions';

export const NAME = 'methodStyles';

export interface State extends EntityState<MethodStyleInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
  loadedForBook: DictionaryNum<boolean>;
}

export const adapter: EntityAdapter<MethodStyleInterface> = createEntityAdapter<MethodStyleInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForBook: {},
});

export function reducer(state = initialState, action: MethodStylesActions): State {
  switch (action.type) {
    case MethodStylesActionTypes.MethodStylesLoaded: {
      return adapter.setAll(action.payload.methodStyles, {
        ...state,
        loaded: true,
      });
    }

    case MethodStylesActionTypes.MethodStylesLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case MethodStylesActionTypes.AddMethodStyleForBook: {
      const { bookId, methodStyle } = action.payload;

      const updatedState = {
        ...state,
        loadedForBook: { ...state.loadedForBook, [bookId]: true },
      };

      return methodStyle ? adapter.addOne(methodStyle, updatedState) : updatedState;
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
