import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Injector,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  EnvironmentAssetsInterface,
  ENVIRONMENT_ASSETS_TOKEN,
  LottieActionConfigInterface,
  LottieConfigInterface,
  LottieEventType,
} from '@campus/environment';
import { AnimationItem, AnimationSegment } from 'lottie-web';

@Component({
  selector: 'campus-lottie',
  styleUrls: ['./lottie.component.scss'],
  templateUrl: './lottie.component.html',
  exportAs: 'lottie',
})
export class LottieComponent implements OnChanges {
  @HostBinding('class.ui-lottie')
  hasLottieClass = true;

  @Input() lottieConfig: LottieConfigInterface;

  @Input() lottieKey: string;

  @Input() preload: string;

  @Output() lottieCreated = new EventEmitter<LottieComponent>();

  boundWithTrigger: boolean;

  private _animationItem: AnimationItem;
  private _blockEventTypes: LottieEventType[] = [];

  constructor(private injector: Injector, private elementRef: ElementRef) {}

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: MouseEvent, fromParent?: boolean) {
    this.play('mouseenter', fromParent);
  }

  @HostListener('mouseout', ['$event'])
  onMouseOut(event: MouseEvent, fromParent?: boolean) {
    this.play('mouseout', fromParent);
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event: MouseEvent, fromParent?: boolean) {
    this.play('mouseleave', fromParent);
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent, fromParent?: boolean) {
    this.play('click', fromParent);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.lottieKey) {
      this.setLottieConfigByKey(this.lottieKey);
    }
    if (changes.lottieConfig) {
      if (this.lottieKey) {
        this.setLottieConfigByKey(this.lottieKey);
      }
    }

    if (changes.preload) {
      if (this.preload) {
        this.setLottieConfigByKey(this.preload, true);
      }
    }
  }

  private setLottieConfigByKey(key: string, preload?: boolean) {
    const assetsEnv = this.injector.get<EnvironmentAssetsInterface>(ENVIRONMENT_ASSETS_TOKEN);

    if (!assetsEnv.animations) {
      console.warn('Could not find any animations config in environments.');
      return;
    }

    const config = assetsEnv.animations[key];
    if (!config) {
      console.warn(
        `Could not find animation key ${key}. Choose one from ${Object.keys(assetsEnv.animations).join(', ')}`
      );
      return;
    }

    if (preload) {
      this.lottieConfig = { path: this.join(assetsEnv.basePath, config.path), autoplay: false };
    } else {
      this.lottieConfig = { ...config, ...this.lottieConfig, path: this.join(assetsEnv.basePath, config.path) };
    }
  }

  onAnimationCreated(animationItem: AnimationItem) {
    if (this.preload) {
      //when loaded, delete from DOM, not needed anymore
      this.elementRef.nativeElement.remove();
    }

    this._animationItem = animationItem;
    this.lottieCreated.emit(this);
  }

  public play(eventType: LottieEventType, fromParent: boolean, segments: AnimationSegment = null) {
    const config = this.getConfig(eventType);

    if (this.shouldBlockPlay(eventType, fromParent, config)) return;

    this.setPreventBeforeComplete(config);

    const playSegments = segments || this.getPlaySegments(config);

    this._animationItem.loop = config.loop || false;
    this._animationItem.setSpeed(config.speed || 1);

    if (playSegments) {
      this._animationItem.playSegments(playSegments, true);
    } else {
      this._animationItem.play();
    }
  }

  private join(...args: string[]) {
    const parts = args.map((arg) => arg.replace(/^(.+?)\/*?$/, '$1'));
    return parts.join('/');
  }

  private getConfig(eventType: LottieEventType): LottieActionConfigInterface {
    let config: LottieActionConfigInterface;
    if (!(this.lottieConfig && this.lottieConfig[eventType])) return;

    if (typeof this.lottieConfig[eventType] === 'function') {
      config = (this.lottieConfig[eventType] as () => LottieActionConfigInterface)();
    } else {
      config = this.lottieConfig[eventType] as LottieActionConfigInterface;
    }
    return config;
  }

  private shouldBlockPlay(
    eventType: LottieEventType,
    fromParent: boolean,
    config: LottieActionConfigInterface
  ): boolean {
    const blockPlayBy = [this.boundWithTrigger && !fromParent, !config, this._blockEventTypes.includes(eventType)];
    return blockPlayBy.some(Boolean);
  }

  private setPreventBeforeComplete(config: LottieActionConfigInterface): void {
    if (config.preventBeforeComplete) {
      this._blockEventTypes = config.preventBeforeComplete;

      this._animationItem.addEventListener('complete', () => {
        this._blockEventTypes = [];
        this._animationItem.removeEventListener('complete');
      });
    }
  }

  private getPlaySegments(config: LottieActionConfigInterface) {
    if (config.revert) {
      const { segment: revertOriginSegment } = this.getConfig(config.revert);
      const { currentFrame } = this._animationItem;

      let toFrame: number;
      if (Array.isArray(revertOriginSegment[0])) toFrame = revertOriginSegment[0][0];
      else toFrame = revertOriginSegment[0];

      return [currentFrame, toFrame] as AnimationSegment;
    }

    return config.segment;
  }
}
