import { Component, Inject, inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ENVIRONMENT_PLATFORM_TOKEN } from '@campus/environment';
import { WINDOW } from '../window/window';

interface WindowPostMessage {
  type: string;
  [key: string]: any;
}

@Component({
  selector: 'campus-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IframeComponent implements OnDestroy {
  url: SafeUrl;

  private platform = inject(ENVIRONMENT_PLATFORM_TOKEN);

  constructor(
    sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<IframeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(WINDOW) public window: Window
  ) {
    this.url = sanitizer.bypassSecurityTrustResourceUrl(data.url);
    this.eventHandler = this.eventHandler.bind(this); // required for eventListener reference
    this.window.addEventListener('message', this.eventHandler, false);
  }

  ngOnDestroy(): void {
    this.window.removeEventListener('message', this.eventHandler, false);
  }

  public onCloseClicked() {
    this.dialogRef.close();
  }

  private eventHandler(ev: MessageEvent) {
    switch (ev.data.type || ev.data) {
      case 'close':
        this.dialogRef.close();
        break;
      case 'REQUEST_USER_INFO':
        this.handleUserInfoRequest();
        break;
      case 'REQUEST_FEATURES':
        this.handleFeaturesRequest();
        break;
      case 'REQUEST_PLATFORM':
        this.handlePlatformRequest();
        break;
      default:
        break;
    }
  }

  private handleFeaturesRequest() {
    const permissions = this.data.permissions;
    this.postMessage({
      type: 'FEATURES',
      features: permissions,
    });
  }

  private handleUserInfoRequest() {
    const currentUser = this.data.currentUser;
    this.postMessage({
      type: 'USER_INFO',
      user_info: {
        username: currentUser.displayName,
        email: currentUser.email,
        isTeacher: !!currentUser.roles?.some((role) => role.name === 'teacher'),
      },
    });
  }

  private handlePlatformRequest() {
    this.postMessage({
      type: 'PLATFORM',
      platform: this.platform,
    });
  }

  private postMessage(message: WindowPostMessage) {
    this.dialogRef.componentInstance.window?.[0].postMessage(message, '*');
  }
}
