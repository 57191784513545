import { DateFunctions, groupArrayByKey, groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AssigneeTypesEnum,
  ClassGroupInterface,
  EduContent,
  EduContentBookInterface,
  GroupInterface,
  LearningAreaInterface,
  PersonInterface,
  TaskClassGroupInterface,
  TaskEduContentInterface,
  TaskGroupInterface,
  TaskInterface,
  TaskStatusEnum,
  TaskStudentInterface,
} from '../../+models';
import { AssigneeInterface } from '../../+models/Assignee.interface';
import { Task } from '../../+models/Task';
import { ClassGroupQueries } from '../class-group';
import { EduContentBookQueries } from '../edu-content-book';
import { GroupQueries } from '../group';
import { LearningAreaQueries } from '../learning-area';
import { LinkedPersonQueries } from '../linked-person';
import { TaskClassGroupQueries } from '../task-class-group';
import { getTaskClassGroupAssigneeByTask } from '../task-class-group/task-class-group.selectors';
import { TaskEduContentQueries } from '../task-edu-content';
import { TaskGroupQueries } from '../task-group';
import { getTaskGroupAssigneeByTask } from '../task-group/task-group.selectors';
import { TaskStudentQueries } from '../task-student';
import { getTaskStudentAssigneeByTask } from '../task-student/task-student.selectors';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './task.reducer';
import { TaskWithAssigneesInterface } from './TaskWithAssignees.interface';

export const selectTaskState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectTaskState, (state: State) => state.error);

export const getLoaded = createSelector(selectTaskState, (state: State) => state.loaded);

export const getAll = createSelector(selectTaskState, selectAll);

const isEvaluation = (task: TaskInterface): boolean => task.evaluationScoreListId && !task.isAdaptive;

export const getAllRegularTasks = createSelector(selectTaskState, (state: State) => {
  const tasks = selectAll(state) || [];
  return tasks.filter((task) => !isEvaluation(task));
});

export const getAllEvaluationTasks = createSelector(selectTaskState, (state: State) => {
  const tasks = selectAll(state) || [];
  return tasks.filter((task) => isEvaluation(task));
});

export const getCount = createSelector(selectTaskState, selectTotal);

export const getIds = createSelector(selectTaskState, selectIds);

export const getRegularIds = createSelector(getAllRegularTasks, (tasks: TaskInterface[]) =>
  tasks.map((task) => task.id)
);

export const getAllEntities = createSelector(selectTaskState, selectEntities);

export const getAllRegularEntities = createSelector(getAllRegularTasks, (regularTasks: TaskInterface[]) => {
  return groupArrayByKeys(regularTasks, ['id'], null, true);
});

export const getAllEvaluationEntities = createSelector(getAllEvaluationTasks, (evaluationTasks: TaskInterface[]) => {
  return groupArrayByKeys(evaluationTasks, ['id'], null, true) as Dictionary<TaskInterface>;
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * task$: TaskInterface[] = this.store.pipe(
    select(TaskQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectTaskState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => asTask(state.entities[id]));
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * task$: TaskInterface = this.store.pipe(
    select(TaskQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(selectTaskState, (state: State, props: { id: number }) =>
  asTask(state.entities[props.id])
);

/**
 * returns an object with tasks grouped by learning area id as key
 * @example
 * tasksByLearningArea$ = this.store.pipe(select(TaskQueries.getByLearningAreaId))
 */
export const getByLearningAreaId = createSelector(selectTaskState, (state: State) => {
  const byKey: Dictionary<Task[]> = {};
  (state.ids as number[]).forEach((id: number) => {
    const item = asTask(state.entities[id]);
    if (!byKey[item.learningAreaId]) {
      byKey[item.learningAreaId] = [];
    }
    byKey[item.learningAreaId].push(item);
  });
  return byKey;
});

export const getForLearningAreaId = createSelector(
  getByLearningAreaId,
  (tasksByLearningAreaId: Dictionary<Task[]>, props: { learningAreaId: number }) => {
    return tasksByLearningAreaId[props.learningAreaId] || [];
  }
);

export const getShared = createSelector(selectTaskState, (state: State, props: { userId: number }) => {
  const ids: number[] = <number[]>state.ids;
  return ids
    .filter((id) => state.entities[id].personId !== props.userId) //personId is the teacherId
    .map((id) => asTask(state.entities[id]));
});

export const getOwn = createSelector(selectTaskState, (state: State, props: { userId: number }) => {
  const ids: number[] = <number[]>state.ids;
  return ids
    .filter((id) => state.entities[id].personId === props.userId) //personId is the teacherId
    .map((id) => asTask(state.entities[id]));
});

export const getSharedLearningAreaIds = createSelector(selectTaskState, (state: State, props: { userId: number }) => {
  return new Set(
    Object.values(state.entities)
      .filter((task) => task.personId !== props.userId)
      .map((task) => task.learningAreaId)
  );
});

export const getSharedTaskIdsByLearningAreaId = createSelector(
  selectTaskState,
  (state: State, props: { userId: number; learningAreaId: number }) => {
    const ids: number[] = <number[]>state.ids;
    return ids.filter(
      (id) => state.entities[id].personId !== props.userId && state.entities[id].learningAreaId === props.learningAreaId
    );
  }
);

export const getAllByEvaluationScoreListId = createSelector(getAll, (tasks: TaskInterface[]) => {
  return groupArrayByKey(tasks, 'evaluationScoreListId');
});

function asTask(item: TaskInterface): Task {
  if (item) {
    return Object.assign<Task, TaskInterface>(new Task(), item);
  }
}

function addTaskDates(taskWithAssignees: TaskWithAssigneesInterface): TaskWithAssigneesInterface {
  const now = new Date();
  const currentSchoolYear = DateFunctions.getSchoolYear(now);
  const { assignees, archivedYear, archivedStart, archivedEnd } = taskWithAssignees;
  let status = TaskStatusEnum.PENDING;

  const maxDate = (dates) => (dates.length ? new Date(Math.max(...dates)) : undefined);
  const minDate = (dates) => (dates.length ? new Date(Math.min(...dates)) : undefined);

  let startDate = minDate(assignees.filter((a) => a.start).map((a) => +a.start));
  let endDate = maxDate(assignees.filter((a) => a.end).map((a) => +a.end));

  if (startDate && endDate) {
    if (startDate > now) {
      status = TaskStatusEnum.PENDING;
    } else if (endDate > now) {
      status = TaskStatusEnum.ACTIVE;
    } else {
      status = TaskStatusEnum.FINISHED;
    }
  }

  if (archivedYear && archivedYear < currentSchoolYear) {
    status = TaskStatusEnum.AUTO_ARCHIVED;
    startDate = archivedStart || undefined;
    endDate = archivedEnd || undefined;
  }

  return { ...taskWithAssignees, startDate, endDate, status };
}

function mapToTaskWithAssigneeInterface(
  task: TaskInterface,
  learningArea: LearningAreaInterface,
  taskEduContents: TaskEduContentInterface[],
  assigneesByTask: Dictionary<AssigneeInterface[]>
): TaskWithAssigneesInterface {
  return addTaskDates({
    ...task,
    learningArea: learningArea,
    eduContentAmount: taskEduContents ? taskEduContents.length : 0,
    taskEduContents: (taskEduContents || [])
      .sort((a, b) => a.index - b.index)
      .map((tEdu) => ({
        ...tEdu,
        eduContent: EduContent.toEduContent(tEdu.eduContent),
      })),
    assignees: assigneesByTask[task.id] || [],
  });
}

export const combinedAssigneesByTask = createSelector(
  getTaskClassGroupAssigneeByTask,
  getTaskGroupAssigneeByTask,
  getTaskStudentAssigneeByTask,
  (tCGA, tGA, tSA) => {
    const taskClassGroupAssigneesKeys = Object.keys(tCGA);
    const taskGroupAssigneesKeys = Object.keys(tGA);
    const taskStudentAssigneesKeys = Object.keys(tSA);

    const dict = [...taskClassGroupAssigneesKeys, ...taskGroupAssigneesKeys, ...taskStudentAssigneesKeys].reduce(
      (acc, key) => {
        if (!acc[key]) {
          acc[key] = [].concat(tCGA[key] || [], tGA[key] || [], tSA[key] || []);
        }
        return acc;
      },
      {}
    );

    return dict;
  }
);

export const getTasksWithAssignments = (type: 'evaluation' | 'regular' | 'all') => {
  const baseSelector = type === 'all' ? getAll : type === 'evaluation' ? getAllEvaluationTasks : getAllRegularTasks;
  return createSelector(
    baseSelector,
    LearningAreaQueries.getAllEntities,
    TaskEduContentQueries.getAllGroupedByTaskId,
    combinedAssigneesByTask,
    EduContentBookQueries.getAllEntities,

    (
      tasks: TaskInterface[],
      learningAreaDict: Dictionary<LearningAreaInterface>,
      taskEduContentByTask: Dictionary<TaskEduContentInterface[]>,
      assigneesByTask: Dictionary<AssigneeInterface[]>,
      eduContentBookDict: Dictionary<EduContentBookInterface>
    ) => {
      return tasks.map((task) => ({
        ...mapToTaskWithAssigneeInterface(
          task,
          learningAreaDict[task.learningAreaId],
          taskEduContentByTask[task.id],
          assigneesByTask
        ),
        eduContentBook: eduContentBookDict?.[task.eduContentBookId],
      }));
    }
  );
};
export const getAllTasksWithAssignments = getTasksWithAssignments('all');
export const getAllRegularTasksWithAssignments = getTasksWithAssignments('regular');
export const getAllEvaluationTasksWithAssignments = getTasksWithAssignments('evaluation');

export const getEvaluationTasksWithAssignmentsDict = createSelector(
  getAllEvaluationTasksWithAssignments,
  (evaluationTasks: TaskWithAssigneesInterface[]) => {
    return groupArrayByKeys(evaluationTasks, ['id'], null, true);
  }
);

export const getAllEvaluationTasksByTocId = createSelector(
  getAllEvaluationTasksWithAssignments,
  (evaluationTasks: TaskWithAssigneesInterface[]) => {
    return groupArrayByKey(evaluationTasks, 'eduContentTOCId') as Dictionary<TaskWithAssigneesInterface[]>;
  }
);

export const getTaskAssigneesByTaskByStudent = createSelector(
  getAllRegularEntities,
  GroupQueries.getAllEntitiesWithStudentIds,
  ClassGroupQueries.getAllEntities,
  TaskClassGroupQueries.getTaskClassGroupAssigneeByTask,
  TaskGroupQueries.getTaskGroupAssigneeByTask,
  TaskStudentQueries.getTaskStudentAssigneeByTask,
  (
    regularTasksByTaskId: Dictionary<TaskInterface>,
    groups: Dictionary<GroupInterface>,
    classGroups: Dictionary<ClassGroupInterface>,
    taskClassGroups: Dictionary<AssigneeInterface[]>,
    taskGroups: Dictionary<AssigneeInterface[]>,
    taskStudents: Dictionary<AssigneeInterface[]>
  ) => {
    type AssigneesByType = {
      [key in AssigneeTypesEnum]: AssigneeInterface[];
    };

    const dict: Dictionary<Dictionary<AssigneesByType>> = {};

    const studentInTaskAssigneeReducer = (taskId: string, studentId: number, assignee: AssigneeInterface) => {
      if (!regularTasksByTaskId[taskId]) return;
      if (!dict[taskId]) {
        dict[taskId] = {};
      }

      if (!dict[taskId][studentId]) {
        dict[taskId][studentId] = Object.values(AssigneeTypesEnum).reduce(
          (acc, key) => ({
            ...acc,
            [key]: [],
          }),
          {} as AssigneesByType
        );
      }

      dict[taskId][studentId][assignee.type].push(assignee);
    };

    Object.entries(taskClassGroups).forEach(([taskId, tcgArr]) => {
      tcgArr.forEach((tcg) => {
        if (!classGroups[tcg.relationId]) return;
        classGroups[tcg.relationId].studentIds.forEach((studentId) =>
          studentInTaskAssigneeReducer(taskId, studentId, tcg)
        );
      });
    });

    Object.entries(taskGroups).forEach(([taskId, tgArr]) => {
      tgArr.forEach((tg) => {
        if (!groups[tg.relationId]) return;
        groups[tg.relationId].studentIds.forEach((studentId) => studentInTaskAssigneeReducer(taskId, studentId, tg));
      });
    });

    Object.entries(taskStudents).forEach(([taskId, tsArr]) => {
      tsArr.forEach((ts) => studentInTaskAssigneeReducer(taskId, ts.relationId, ts));
    });

    return dict;
  }
);

//TODO: this could use getTaskAssigneesByTaskByStudent as a base
export const getStudentsInTask = createSelector(
  TaskClassGroupQueries.getByTaskId,
  ClassGroupQueries.getAllEntities,
  TaskGroupQueries.getByTaskId,
  GroupQueries.getAllEntitiesWithStudentIds,
  TaskStudentQueries.getByTaskId,
  LinkedPersonQueries.getAllEntities,

  (
    taskClassGroups: TaskClassGroupInterface[],
    classGroupDict: Dictionary<ClassGroupInterface>,
    taskGroups: TaskGroupInterface[],
    groupDict: Dictionary<GroupInterface>,
    taskStudents: TaskStudentInterface[],
    linkedPersonDict: Dictionary<PersonInterface>,
    props: { taskId: number }
  ): PersonInterface[] => {
    const studentsDict = {};

    taskClassGroups.forEach((tCG) => {
      const classGroup = classGroupDict[tCG.classGroupId];
      classGroup.studentIds.forEach((studentId) => {
        if (!studentsDict[studentId]) {
          studentsDict[studentId] = linkedPersonDict[studentId];
        }
      });
    });

    taskGroups.forEach((tG) => {
      const group = groupDict[tG.groupId];
      group.studentIds.forEach((studentId) => {
        if (!studentsDict[studentId]) {
          studentsDict[studentId] = linkedPersonDict[studentId];
        }
      });
    });

    taskStudents.forEach((tS) => {
      const studentId = tS.personId;
      if (!studentsDict[studentId]) {
        studentsDict[studentId] = linkedPersonDict[studentId];
      }
    });

    return Object.values(studentsDict);
  }
);
