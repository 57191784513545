import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonApi } from '../+api';
import { ContextInterface, ResultInterface, ResultStatusEnum } from '../+models';
import { ResultsServiceInterface } from './results.service.interface';

@Injectable({
  providedIn: 'root',
})
export class ResultsService implements ResultsServiceInterface {
  constructor(private personApi: PersonApi) {}

  getAllForUser(userId: number): Observable<ResultInterface[]> {
    return this.personApi
      .getData(userId, 'allResults')
      .pipe(map((res: { allResults: ResultInterface[] }) => res.allResults.map(castResult)));
  }

  public getResultForReview(userId: number, resultId: number): Observable<ResultInterface> {
    return this.personApi.resultForReview(userId, resultId).pipe(map(castResult));
  }

  /**
   * Checks the polpo-api if a result exists and returns it if it is in progress.
   * Creates a new result if it doesn't exist and returns it.
   * Returns an error if a result is completed and the user isn't allowed to repeat it.
   *
   * @param {number} userId
   * @param {number} taskId
   * @param {number} eduContentId
   * @returns {Observable<ResultInterface>}
   * @memberof ResultsService
   */
  public getResultForTask(userId: number, taskId: number, eduContentId: number): Observable<ResultInterface> {
    return this.personApi.resultForTask(userId, taskId, eduContentId).pipe(map(castResult));
  }

  /**
   * Checks the polpo-api if a result exists for unlockedContent and returns it if it is in progress.
   * Creates a new result if it doesn't exist (or is completed) and returns it.
   *
   */
  public getResultForUnlockedContent(
    userId: number,
    unlockedContentId: number,
    eduContentId: number
  ): Observable<ResultInterface> {
    return this.personApi.resultForUnlockedContent(userId, unlockedContentId, eduContentId).pipe(map(castResult));
  }

  /**
   * Checks the polpo-api if a result exists for unlockedFreePractice and returns it if it is in progress.
   * Creates a new result if it doesn't exist (or is completed) and returns it.
   *
   */
  public getResultForUnlockedFreePractice(
    userId: number,
    unlockedFreePracticeId: number,
    eduContentId: number
  ): Observable<ResultInterface> {
    return this.personApi
      .resultForUnlockedFreePractice(userId, unlockedFreePracticeId, eduContentId)
      .pipe(map(castResult));
  }

  /**
   * Checks the polpo-api if a result exists for context parameters and returns it if it is in progress.
   * Fails if it doesn't exist or is already completed.
   *
   */
  public getResultForContext(
    userId: number,
    eduContentId: number,
    context: ContextInterface
  ): Observable<ResultInterface> {
    return this.personApi.resultForContext(userId, eduContentId, context).pipe(map(castResult));
  }

  /**
   * Saves a result to the Api, returns the saved result
   */
  public saveResult(userId: number, result: ResultInterface): Observable<ResultInterface> {
    const standardizedResult = standardizeResult(result);

    return this.personApi
      .saveResult(
        userId,
        standardizedResult.id,
        standardizedResult.score,
        standardizedResult.time,
        standardizedResult.status,
        standardizedResult.cmi
      )
      .pipe(map(castResult));
  }
}

function standardizeResult(result: ResultInterface): ResultInterface {
  return {
    ...result,
    score: standardizeScore(result.score),
    status: standardizeStatus(result.status),
  };
}

function standardizeScore(score: number) {
  return score ? +score : score;
}

function standardizeStatus(status: ResultStatusEnum) {
  if ([ResultStatusEnum.STATUS_PASSED, ResultStatusEnum.STATUS_FAILED].includes(status)) {
    return ResultStatusEnum.STATUS_COMPLETED;
  }

  return status;
}

function castResult(result: ResultInterface) {
  return {
    ...result,
    created: result.created && new Date(result.created),
    lastUpdated: result.lastUpdated && new Date(result.lastUpdated),
  };
}
