import { EnvironmentSearchModesInterface } from '@campus/environment';
import { EmptyFilterFactory } from '@campus/search';
import { EduContentSearchResultComponent } from '../app/components/edu-content-search-result/edu-content-search-result.component';
import { ChapterLessonFilterFactory } from '../app/factories/chapter-lesson-filter/chapter-lesson-filter.factory';
import { GlobalFilterFactory } from '../app/factories/global-filter/global-filter.factory';
import { ManageTaskContentFilterFactory } from '../app/factories/manage-task-content-filter/manage-task-content-filter.factory';
import { SearchTermFilterFactory } from '../app/factories/search-term-filter/search-term-filter.factory';
import { TocFilterFactory } from '../app/factories/toc-filter/toc-filter.factory';

export const searchModes: EnvironmentSearchModesInterface = {
  'chapter-lesson': {
    name: 'chapter-lesson',
    label: 'Zoeken op <b>hoofdstuk</b>',
    dynamicFilters: false,
    searchFilterFactory: ChapterLessonFilterFactory,
    results: {
      component: EduContentSearchResultComponent,
      sortModes: [
        {
          description: 'alfabetisch',
          name: 'title.raw',
          icon: 'sort-alpha-down',
          sort: [
            'eduContentProductType.sequence',
            { showInFreePractice: { order: 'asc' } },
            'title.raw',
            'level.sequence',
          ],
        },
      ],
      pageSize: 1000,
      aggs: true,
    },
  },
  global: {
    name: 'global',
    label: 'Zoeken in alle inhoud',
    dynamicFilters: false,
    searchTerm: {
      domHost: 'searchTerm',
    },
    searchFilterFactory: GlobalFilterFactory,
    results: {
      component: EduContentSearchResultComponent,
      sortModes: [
        {
          description: 'sorteer op relevantie',
          name: '_score',
          icon: 'sort:numeric-down',
        },
        {
          description: 'sorteer alfabetisch',
          name: 'title.raw',
          icon: 'sort:alpha-down',
        },
        {
          description: 'sorteer op nieuwste wijzigingen',
          name: 'published',
          icon: 'sort:date',
        },
      ],
      pageSize: 20,
      aggs: true,
    },
  },
  'task-manage-content': {
    name: 'task-manage-content',
    label: 'Zoeken naar inhoud voor een taak',
    dynamicFilters: false,
    searchTerm: {
      domHost: 'searchTerm',
    },
    searchFilterFactory: ManageTaskContentFilterFactory,
    results: {
      component: EduContentSearchResultComponent,
      sortModes: [
        {
          description: 'relevantie',
          name: '_score',
          icon: 'sort-numeric-down',
        },
      ],
      pageSize: 20,
      aggs: true,
    },
  },
  toc: {
    name: 'toc',
    label: 'Zoeken op <b>inhoudstafel</b>',
    dynamicFilters: true,
    searchFilterFactory: TocFilterFactory,
    results: {
      component: EduContentSearchResultComponent,
      sortModes: [
        {
          description: 'alfabetisch',
          name: 'title.raw',
          icon: 'sort-alpha-down',
        },
      ],
      pageSize: 20,
    },
  },
  term: {
    name: 'term',
    label: '<b>Standaard</b> zoeken',
    dynamicFilters: false,
    searchTerm: {
      domHost: 'hostTop',
    },
    searchFilterFactory: SearchTermFilterFactory,
    results: {
      component: EduContentSearchResultComponent,
      sortModes: [
        {
          description: 'relevantie',
          name: '_score',
          icon: 'sort-numeric-down',
        },
        {
          description: 'alfabetisch',
          name: 'title.raw',
          icon: 'sort-alpha-down',
        },
        {
          description: 'laatst gewijzigd',
          name: 'published',
          icon: 'calendar-plus',
        },
      ],
      pageSize: 20,
    },
  },
  'result-methods': {
    name: 'result-methods',
    label: 'Zoeken op <b>inhoudsopgave</b>',
    dynamicFilters: false,
    searchTerm: {
      domHost: 'searchTerm',
    },
    searchFilterFactory: EmptyFilterFactory,
    results: {
      component: undefined,
      sortModes: [
        {
          description: 'alfabetisch',
          name: 'title.raw',
          icon: 'sort-alpha-down',
          sort: ['eduContentProductType.sequence', 'level.sequence', 'title.raw'],
        },
      ],
      pageSize: 1000,
    },
  },
  'bundle-manage-content': {
    name: 'bundle-manage-content',
    label: 'Zoeken naar inhoud voor een bundel',
    dynamicFilters: false,
    searchTerm: {
      domHost: 'searchTerm',
    },
    // TODO: create new factory when there is a difference between the two
    searchFilterFactory: ManageTaskContentFilterFactory,
    results: {
      component: EduContentSearchResultComponent,
      sortModes: [
        {
          description: 'relevantie',
          name: '_score',
          icon: 'sort-numeric-down',
        },
      ],
      pageSize: 20,
      aggs: true,
    },
  },
};
