import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { BundlePersonQueries } from '.';
import { BundlePersonService, BundlePersonServiceInterface } from '../../bundle-person';
import { DalState } from '../dal.state.interface';
import { bundlePersonsLoaded, bundlePersonsLoadError, loadBundlePersons } from './bundle-person.actions';

@Injectable()
export class BundlePersonEffects {
  loadBundlePersons$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadBundlePersons),
      concatLatestFrom(() => this.store.select(BundlePersonQueries.getLoaded)),
      fetch({
        run: (action: ReturnType<typeof loadBundlePersons>, loaded: boolean) => {
          if (!action.force && loaded) return;
          return this.bundlePersonService
            .getAllForUser(action.userId)
            .pipe(map((bundlePersons) => bundlePersonsLoaded({ bundlePersons })));
        },
        onError: (action: ReturnType<typeof loadBundlePersons>, error) => {
          return bundlePersonsLoadError({ error });
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(BundlePersonService)
    private bundlePersonService: BundlePersonServiceInterface
  ) {}
}
