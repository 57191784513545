import { Component, Input, ViewChild } from '@angular/core';
import { DropdownMenuComponent } from '../dropdown-menu/dropdown-menu.component';

export interface DropdownMenuItemInterface {
  id?: number | string;
  header?: string;
  description?: string;
  icon?: string;
  cssClass?: string;
  children?: DropdownMenuItemInterface[];
  handler?: () => void;
  image?: string;
  internalLink?: string;
  externalLink?: string;
  imageAltText?: string;
  dividerBefore?: boolean;
}
@Component({
  selector: 'campus-dropdown-menu-item',
  templateUrl: './dropdown-menu-item.component.html',
  styleUrls: ['./dropdown-menu-item.component.scss'],
})
export class DropdownMenuItemComponent implements DropdownMenuItemInterface {
  @Input() header: string;
  @Input() description: string;
  @Input() icon: string;
  @Input() image: string;
  @Input() imageAltText: string;
  @Input() children: DropdownMenuItemInterface[];
  @Input() handler: () => void = null;

  @ViewChild('dropdownChildren', { static: true }) dropdownChildren: DropdownMenuComponent;

  onClick($event: MouseEvent) {
    if (this.handler) {
      this.handler();
    }

    if (this.children?.length) {
      this.dropdownChildren.toggle();
      $event.stopPropagation();
    }
  }
}
