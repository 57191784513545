import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ResultFunctions } from '@campus/utils';

export enum ResultStatusEnum { //copied from dal
  STATUS_INCOMPLETE = 'incomplete',
  STATUS_COMPLETED = 'completed',
  STATUS_PASSED = 'passed',
  STATUS_FAILED = 'failed',
  STATUS_BROWSED = 'browsed',
  STATUS_NOT_ATTEMPTED = 'not attempted',
  // own extensions:
  STATUS_OPENED = 'opened',
}

export interface AlgebraKitSessionResultInterface {
  score?: number;
  level: number;
  status?: ResultStatusEnum;
  colorClass?: string;
}

@Component({
  selector: 'campus-score-level',
  styleUrls: ['./score-level.component.scss'],
  templateUrl: './score-level.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScoreLevelComponent implements OnChanges {
  @HostBinding('class.ui-score-level')
  hasScoreLevelClass = true;

  @HostBinding('attr.data-cy')
  dataCy = 'result-score-level';

  @HostBinding('class') public get colorClass(): string {
    return this.hasScore ? this.scoreLevel.colorClass : null;
  }

  @Input() scoreLevel: AlgebraKitSessionResultInterface;

  public ResultStatusEnum = ResultStatusEnum;
  public hasScore = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scoreLevel?.currentValue) {
      const currentScoreLevel = changes.scoreLevel.currentValue;
      this.hasScore = currentScoreLevel.score != null;

      const score = currentScoreLevel.status !== ResultStatusEnum.STATUS_COMPLETED ? null : currentScoreLevel.score;

      this.scoreLevel = {
        ...this.scoreLevel,
        colorClass: this.getColorClassFromResult(score),
      };
    }
  }

  private getColorClassFromResult(score) {
    const colorClass = ResultFunctions.getColorClass(score);
    if (colorClass === 'score--excellent') {
      return 'score--good';
    }
    if (!colorClass) return 'score--incomplete';
    return colorClass;
  }
}
