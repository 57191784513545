import { NgModule } from '@angular/core';
import {
  AlertReducer,
  AlgebrakitSessionReducer,
  BundleClassGroupReducer,
  BundleGroupReducer,
  BundlePersonReducer,
  BundleReducer,
  ClassGroupReducer,
  clearState,
  ContentStatusReducer,
  CredentialReducer,
  CurrentExerciseReducer,
  CurriculumReducer,
  CurriculumTreeReducer,
  CustomSerializer,
  DiaboloPhaseReducer,
  DidacticFunctionReducer,
  EduContentBookReducer,
  EduContentProductTypeReducer,
  EduContentReducer,
  EduContentTocEduContentReducer,
  EduContentTocEvaluationReducer,
  EduContentTocLearningPlanGoalReducer,
  EduContentTocLoopReducer,
  EduContentTocReducer,
  EduNetReducer,
  EffectFeedbackReducer,
  EvaluationReducer,
  EvaluationScoreListReducer,
  EvaluationScoreListSubjectReducer,
  EvaluationScoreReducer,
  EvaluationSubjectGoalReducer,
  EvaluationSubjectReducer,
  FavoriteReducer,
  GoalReducer,
  GoalTopicReducer,
  GoalYearReducer,
  GroupPersonReducer,
  GroupReducer,
  HistoryReducer,
  LearnerProfileConceptReducer,
  LearnerProfileStrandReducer,
  LearningAreaReducer,
  LearningDomainReducer,
  LearningPlanGoalProgressReducer,
  LearningPlanGoalReducer,
  LevelReducer,
  LicenseReducer,
  LinkedPersonReducer,
  LoopReducer,
  MethodLevelReducer,
  MethodNewsReducer,
  MethodProductGroupProductReducer,
  MethodProductGroupReducer,
  MethodReducer,
  MethodStyleReducer,
  NewsCategoryNewsReducer,
  NewsCategoryReducer,
  NewsReducer,
  OlfLearningDomainReducer,
  PersonPreferenceReducer,
  PersonProductReducer,
  ProductContentReducer,
  ProductReducer,
  PurchaseProductReducer,
  PurchaseReducer,
  ResultReducer,
  RoleReducer,
  RouterReducer,
  SchoolBillingAddressReducer,
  SchoolBillingReducer,
  SchoolProviderReducer,
  SchoolReducer,
  SchoolRoleMappingClassGroupReducer,
  SchoolRoleMappingReducer,
  SchoolTypeReducer,
  SectionContentReducer,
  SectionReducer,
  StudentContentStatusReducer,
  TaskClassGroupReducer,
  TaskEduContentReducer,
  TaskEduContentTaskGoalYearLevelReducer,
  TaskEvaluationSubjectReducer,
  TaskEvaluationSubjectScoreReducer,
  TaskGoalYearLevelReducer,
  TaskGroupReducer,
  TaskInstanceReducer,
  TaskLevelSettingReducer,
  TaskReducer,
  TaskStudentReducer,
  TeacherStudentReducer,
  UiReducer,
  UnlockedBoekeGroupReducer,
  UnlockedBoekeStudentReducer,
  UnlockedContentReducer,
  UnlockedCurriculumTreeReducer,
  UnlockedFreePracticeReducer,
  UserContentReducer,
  UserLessonReducer,
  UserReducer,
  YearReducer,
} from '@campus/dal';
import { NavigationActionTiming, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { handleUndo } from 'ngrx-undo';
import { environment } from '../environments/environment';

@NgModule({
  imports: [
    StoreModule.forRoot(
      {},
      {
        metaReducers: [handleUndo, clearState],
      }
    ),
    addFeature(RouterReducer),
    StoreRouterConnectingModule.forRoot({
      navigationActionTiming: NavigationActionTiming.PostActivation,
      serializer: CustomSerializer,
      stateKey: RouterReducer.NAME,
    }),
    addFeature(EduContentTocEvaluationReducer),
    addFeature(LearningAreaReducer),
    addFeature(LearningDomainReducer),
    addFeature(MethodReducer),
    addFeature(UserContentReducer),
    addFeature(UnlockedContentReducer),
    addFeature(StudentContentStatusReducer),
    addFeature(EduContentReducer),
    addFeature(BundleReducer),
    addFeature(UiReducer),
    addFeature(UnlockedBoekeGroupReducer),
    addFeature(UnlockedBoekeStudentReducer),
    addFeature(ContentStatusReducer),
    addFeature(UserReducer),
    addFeature(TaskReducer),
    addFeature(AlertReducer),
    addFeature(TaskInstanceReducer),
    addFeature(TaskEduContentReducer),
    addFeature(ResultReducer),
    addFeature(CurrentExerciseReducer),
    addFeature(TeacherStudentReducer),
    addFeature(LinkedPersonReducer),
    addFeature(CredentialReducer),
    addFeature(EffectFeedbackReducer),
    addFeature(FavoriteReducer),
    addFeature(HistoryReducer),
    addFeature(EduContentProductTypeReducer),
    addFeature(EduNetReducer),
    addFeature(SchoolTypeReducer),
    addFeature(YearReducer),
    addFeature(EduContentBookReducer),
    addFeature(DidacticFunctionReducer),
    addFeature(EduContentTocReducer),
    addFeature(OlfLearningDomainReducer),
    addFeature(DiaboloPhaseReducer),
    addFeature(LearningPlanGoalReducer),
    addFeature(LearningPlanGoalProgressReducer),
    addFeature(UserLessonReducer),
    addFeature(PersonPreferenceReducer),
    addFeature(SchoolReducer),
    addFeature(GroupReducer),
    addFeature(GroupPersonReducer),
    addFeature(ClassGroupReducer),
    addFeature(SchoolRoleMappingReducer),
    addFeature(SchoolRoleMappingClassGroupReducer),
    addFeature(RoleReducer),
    addFeature(SchoolReducer),
    addFeature(PersonProductReducer),
    addFeature(ProductReducer),
    addFeature(ProductContentReducer),
    addFeature(SectionReducer),
    addFeature(SectionContentReducer),
    addFeature(SchoolProviderReducer),
    addFeature(SchoolBillingReducer),
    addFeature(SchoolBillingAddressReducer),
    addFeature(EvaluationReducer),
    addFeature(EvaluationSubjectReducer),
    addFeature(EvaluationScoreReducer),
    addFeature(EvaluationScoreListSubjectReducer),
    addFeature(EvaluationSubjectGoalReducer),
    addFeature(EvaluationScoreListReducer),
    addFeature(NewsReducer),
    addFeature(NewsCategoryReducer),
    addFeature(NewsCategoryNewsReducer),
    addFeature(MethodNewsReducer),
    addFeature(MethodStyleReducer),

    //for method pages
    addFeature(EduContentTocLearningPlanGoalReducer),

    //FOR RESULT PAGES
    addFeature(AlgebrakitSessionReducer),
    addFeature(LevelReducer),
    addFeature(UnlockedFreePracticeReducer),
    addFeature(CurriculumReducer),
    addFeature(UnlockedCurriculumTreeReducer),
    addFeature(LoopReducer),
    addFeature(CurriculumTreeReducer),
    addFeature(LearnerProfileStrandReducer),
    addFeature(LearnerProfileConceptReducer),
    addFeature(TaskClassGroupReducer),
    addFeature(TaskGroupReducer),
    addFeature(TaskStudentReducer),
    addFeature(BundlePersonReducer),
    addFeature(BundleGroupReducer),
    addFeature(BundleClassGroupReducer),

    // FOR TASK PAGES
    addFeature(GoalTopicReducer),
    addFeature(GoalReducer),
    addFeature(GoalYearReducer),
    addFeature(TaskEvaluationSubjectScoreReducer),
    addFeature(TaskEvaluationSubjectScoreReducer),
    addFeature(TaskEvaluationSubjectReducer),
    addFeature(TaskGoalYearLevelReducer),
    addFeature(TaskLevelSettingReducer),
    addFeature(TaskEduContentTaskGoalYearLevelReducer),
    addFeature(MethodLevelReducer),
    addFeature(LicenseReducer),
    addFeature(EduContentTocLoopReducer),
    addFeature(EduContentTocEduContentReducer),

    // FOR SCHOOL ADMIN PAGES
    addFeature(MethodProductGroupReducer),
    addFeature(MethodProductGroupProductReducer),
    addFeature(PurchaseReducer),
    addFeature(PurchaseProductReducer),
    environment.enableDevTools
      ? StoreDevtoolsModule.instrument({
          maxAge: 25,
          logOnly: true,
        })
      : [],
  ],
})
export class AppStoreModule {}

export function addFeature(reducer) {
  return StoreModule.forFeature(reducer.NAME, reducer.reducer, {
    initialState: reducer.initialState,
  });
}
