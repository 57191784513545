import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClassGroupInterface, EduContentBookInterface } from '../../+models';
import { getById as getBookById } from '../edu-content-book/edu-content-book.selectors';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './class-group.reducer';

export const selectClassGroupState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectClassGroupState, (state: State) => state.error);

export const getLoaded = createSelector(selectClassGroupState, (state: State) => state.loaded);

export const getAll = createSelector(selectClassGroupState, selectAll);

export const getCount = createSelector(selectClassGroupState, selectTotal);

export const getIds = createSelector(selectClassGroupState, selectIds);

export const getAllEntities = createSelector(selectClassGroupState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * classGroup$: ClassGroupInterface[] = this.store.pipe(
    select(ClassGroupQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectClassGroupState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * classGroup$: ClassGroupInterface = this.store.pipe(
    select(ClassGroupQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectClassGroupState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const hasClassGroups = createSelector(
  getAll,
  (classGroups: ClassGroupInterface[]) => classGroups && !!classGroups.length
);

export const getClassGroupsForBook = createSelector(
  getBookById,
  getAll,
  (book: EduContentBookInterface, classGroups: ClassGroupInterface[], props): ClassGroupInterface[] => {
    const bookYearIds = book.years.map((year) => year.id);

    return classGroups
      .filter((classGroup) => {
        //One of the classGroups' years must be in the books' years
        return classGroup.years.some((year) => bookYearIds.includes(year.id));
      })
      .sort((a, b) => {
        if (!a.name) return -1;
        if (!b.name) return 1;
        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      });
  }
);

/**
 * @deprecated Uses static studentIds array
 */
export const getClassGroupsByStudentId = createSelector(getAll, (classGroups: ClassGroupInterface[]) => {
  return classGroups.reduce((acc, classGroup) => {
    classGroup.studentIds.forEach((studentId) => {
      if (!acc[studentId]) {
        acc[studentId] = [];
      }
      acc[studentId].push(classGroup);
    });
    return acc;
  }, {} as Dictionary<ClassGroupInterface[]>);
});

/**
 * @deprecated Uses static teacherIds array
 */
export const getClassGroupsByTeacherId = createSelector(getAll, (classGroups: ClassGroupInterface[]) => {
  return classGroups.reduce((acc, classGroup) => {
    classGroup.teacherIds.forEach((teacherId) => {
      if (!acc[teacherId]) {
        acc[teacherId] = [];
      }
      acc[teacherId].push(classGroup);
    });
    return acc;
  }, {} as Dictionary<ClassGroupInterface[]>);
});

export const getClassGroupsBySchoolIdById = createSelector(
  getAll,
  (classGroups: ClassGroupInterface[]) =>
    groupArrayByKeys(classGroups, ['schoolId', 'id'], null, true) as Dictionary<Dictionary<ClassGroupInterface>>
);
