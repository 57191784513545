/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { ArtifactApi as SDKArtifactApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { ArtifactInterface, EduContentInterface, StorageInfoInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class ArtifactApi extends ModelApiBase {
  private SDKArtifactApi = inject(SDKArtifactApi);

  constructor(api: PolpoApi) {
    super(api, 'Artifacts');
  }

  find(options?): Observable<ArtifactInterface[]> {
    return this.SDKArtifactApi.find(...arguments);
  }

  create(artifact: ArtifactInterface): Observable<ArtifactInterface> {
    // @ts-expect-error TS 2556
    return this.SDKArtifactApi.create(...arguments);
  }

  patchAttributes(id: number, updates: Partial<ArtifactInterface>): Observable<ArtifactInterface> {
    // @ts-expect-error TS 2556
    return this.SDKArtifactApi.patchAttributes(...arguments);
  }

  deleteById(id: number): Observable<boolean> {
    // @ts-expect-error TS 2556
    return this.SDKArtifactApi.deleteById(...arguments);
  }

  store(artifactId: number, formData: FormData) {
    const path = `${artifactId}/store`;

    return this.post<{ storageInfo: StorageInfoInterface }>(path, undefined, formData);
  }

  uploadImage(artifactId: number, formData: FormData) {
    const path = `image/${artifactId}/upload`;

    return this.post<{ file: string; storageName: string }>(path, undefined, formData);
  }

  getInstructions(artifactId: number) {
    const path = `${artifactId}/instructions`;

    return this.get<{ name: string; video: EduContentInterface; text: EduContentInterface }>(path);
  }
}
