import { LocationStrategy } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { WINDOW } from '@campus/browser';
import { Alert } from '@campus/dal';
import { NotificationItemInterface } from '@campus/ui';

@Pipe({
  name: 'AlertToNotificationItem',
})
export class AlertToNotificationItemPipe implements PipeTransform {
  constructor(@Inject(WINDOW) private window: Window, private readonly locationStrategy: LocationStrategy) {}

  /**
   * takes an alert object
   * returns a notification object
   */
  transform(alert: Alert): NotificationItemInterface {
    let link = alert.link;
    const currentDomain = this.window.location.origin;
    const baseHref = this.locationStrategy.getBaseHref();

    //todo clean up once API changed URLs
    if (link && link.startsWith(currentDomain)) {
      // backwards compatibility with old portal, don't remove
      link = link.replace('/#/', '/');

      // extract just the pathname
      link = new URL(link).pathname;

      //base href should not be included in the path
      if (link.startsWith(baseHref)) {
        link = link.slice(baseHref.length);
      }
      // remove possible leading slashes
      link = link.replace(/^\//, '');

      link = `/${link}`;
    }

    return {
      id: alert.id,
      titleText: alert.title,
      read: alert.read,
      accented: alert.type === 'marketing',
      icon: alert.icon,
      link: link,
      notificationText: alert.message,
      notificationDate: new Date(alert.sentAt || alert.validFrom),
    };
  }
}
