import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { ConceptYearInterface } from '../../+models';

export enum ConceptYearsActionTypes {
  ConceptYearsLoaded = '[ConceptYears] ConceptYears Loaded',
  ConceptYearsLoadError = '[ConceptYears] Load Error',
  LoadConceptYears = '[ConceptYears] Load ConceptYears',
  AddConceptYear = '[ConceptYears] Add ConceptYear',
  UpsertConceptYear = '[ConceptYears] Upsert ConceptYear',
  AddConceptYears = '[ConceptYears] Add ConceptYears',
  UpsertConceptYears = '[ConceptYears] Upsert ConceptYears',
  UpdateConceptYear = '[ConceptYears] Update ConceptYear',
  UpdateConceptYears = '[ConceptYears] Update ConceptYears',
  DeleteConceptYear = '[ConceptYears] Delete ConceptYear',
  DeleteConceptYears = '[ConceptYears] Delete ConceptYears',
  ClearConceptYears = '[ConceptYears] Clear ConceptYears',
}

export class LoadConceptYears implements Action {
  readonly type = ConceptYearsActionTypes.LoadConceptYears;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class ConceptYearsLoaded implements Action {
  readonly type = ConceptYearsActionTypes.ConceptYearsLoaded;

  constructor(public payload: { conceptYears: ConceptYearInterface[] }) {}
}

export class ConceptYearsLoadError implements Action {
  readonly type = ConceptYearsActionTypes.ConceptYearsLoadError;
  constructor(public payload: any) {}
}

export class AddConceptYear implements Action {
  readonly type = ConceptYearsActionTypes.AddConceptYear;

  constructor(public payload: { conceptYear: ConceptYearInterface }) {}
}

export class UpsertConceptYear implements Action {
  readonly type = ConceptYearsActionTypes.UpsertConceptYear;

  constructor(public payload: { conceptYear: ConceptYearInterface }) {}
}

export class AddConceptYears implements Action {
  readonly type = ConceptYearsActionTypes.AddConceptYears;

  constructor(public payload: { conceptYears: ConceptYearInterface[] }) {}
}

export class UpsertConceptYears implements Action {
  readonly type = ConceptYearsActionTypes.UpsertConceptYears;

  constructor(public payload: { conceptYears: ConceptYearInterface[] }) {}
}

export class UpdateConceptYear implements Action {
  readonly type = ConceptYearsActionTypes.UpdateConceptYear;

  constructor(public payload: { conceptYear: Update<ConceptYearInterface> }) {}
}

export class UpdateConceptYears implements Action {
  readonly type = ConceptYearsActionTypes.UpdateConceptYears;

  constructor(public payload: { conceptYears: Update<ConceptYearInterface>[] }) {}
}

export class DeleteConceptYear implements Action {
  readonly type = ConceptYearsActionTypes.DeleteConceptYear;

  constructor(public payload: { id: number }) {}
}

export class DeleteConceptYears implements Action {
  readonly type = ConceptYearsActionTypes.DeleteConceptYears;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearConceptYears implements Action {
  readonly type = ConceptYearsActionTypes.ClearConceptYears;
}

export type ConceptYearsActions =
  | LoadConceptYears
  | ConceptYearsLoaded
  | ConceptYearsLoadError
  | AddConceptYear
  | UpsertConceptYear
  | AddConceptYears
  | UpsertConceptYears
  | UpdateConceptYear
  | UpdateConceptYears
  | DeleteConceptYear
  | DeleteConceptYears
  | ClearConceptYears;
