import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaskEvaluationSubjectApi } from '../+api';
import { TaskEvaluationSubjectInterface } from '../+models';
import {
  SetTaskEvaluationSubjectsForTaskResponseInterface,
  TaskEvaluationSubjectServiceInterface,
} from './task-evaluation-subject.service.interface';

@Injectable({
  providedIn: 'root',
})
export class TaskEvaluationSubjectService implements TaskEvaluationSubjectServiceInterface {
  constructor(private taskEvaluationSubjectApi: TaskEvaluationSubjectApi) {}

  getTaskEvaluationSubjectsForTask(taskId: number): Observable<TaskEvaluationSubjectInterface[]> {
    throw new Error('Not implemented yet');
  }

  setTaskEvaluationSubjectsForTask(
    taskId: number,
    taskEvaluationSubjects: TaskEvaluationSubjectInterface[]
  ): Observable<SetTaskEvaluationSubjectsForTaskResponseInterface> {
    return this.taskEvaluationSubjectApi.setTaskEvaluationSubjectForTask(
      taskId,
      taskEvaluationSubjects
    ) as Observable<SetTaskEvaluationSubjectsForTaskResponseInterface>;
  }
}
