import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SchoolRoleMappingClassGroupInterface } from '../../+models';
import { SchoolRoleMappingQueries } from '../school-role-mapping';
import {
  NAME,
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
  State,
} from './school-role-mapping-class-group.reducer';

export const selectSchoolRoleMappingClassGroupState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectSchoolRoleMappingClassGroupState, (state: State) => state.error);

export const getLoaded = createSelector(selectSchoolRoleMappingClassGroupState, (state: State) => state.loaded);

export const getAll = createSelector(selectSchoolRoleMappingClassGroupState, selectAll);

export const getCount = createSelector(selectSchoolRoleMappingClassGroupState, selectTotal);

export const getIds = createSelector(selectSchoolRoleMappingClassGroupState, selectIds);

export const getAllEntities = createSelector(selectSchoolRoleMappingClassGroupState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * schoolRoleMappingClassGroup$: SchoolRoleMappingClassGroupInterface[] = this.store.pipe(
    select(SchoolRoleMappingClassGroupQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(
  selectSchoolRoleMappingClassGroupState,
  (state: State, props: { ids: number[] }) => {
    return props.ids.map((id) => state.entities[id]);
  }
);

/**
 * returns array of objects in the order of the given ids
 * @example
 * schoolRoleMappingClassGroup$: SchoolRoleMappingClassGroupInterface = this.store.pipe(
    select(SchoolRoleMappingClassGroupQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectSchoolRoleMappingClassGroupState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getByClassGroupByRole = createSelector(
  getAll,
  (schoolRoleMappingClassGroups: SchoolRoleMappingClassGroupInterface[]) =>
    groupArrayByKeys<SchoolRoleMappingClassGroupInterface>(
      schoolRoleMappingClassGroups,
      ['classGroupId', 'schoolRoleMappingId'],
      null,
      true
    ) as Dictionary<Dictionary<SchoolRoleMappingClassGroupInterface>>
);

export const getByClassGroupByRoleForSchool = createSelector(
  getAll,
  SchoolRoleMappingQueries.getAllEntities,
  (schoolRoleMappingClassGroups, schoolRoleMappings, props: { schoolId: number }) => {
    const withSRM = schoolRoleMappingClassGroups.map((srmcg) => ({
      ...srmcg,
      ...(schoolRoleMappings[srmcg.schoolRoleMappingId] && {
        schoolRoleMapping: schoolRoleMappings[srmcg.schoolRoleMappingId],
      }),
    }));

    return groupArrayByKeys<SchoolRoleMappingClassGroupInterface>(
      withSRM,
      ['classGroupId', 'schoolRoleMappingId'],
      (item: SchoolRoleMappingClassGroupInterface) =>
        item.schoolRoleMapping && item.schoolRoleMapping.schoolId === props.schoolId,
      true
    ) as Dictionary<Dictionary<SchoolRoleMappingClassGroupInterface>>;
  }
);

export const getBySchoolRoleMappingId = createSelector(
  selectSchoolRoleMappingClassGroupState,
  (state: State) =>
    groupArrayByKeys<SchoolRoleMappingClassGroupInterface>(Object.values(state.entities), [
      'schoolRoleMappingId',
    ]) as Dictionary<SchoolRoleMappingClassGroupInterface[]>
);
