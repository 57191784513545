import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaluationScoreServiceInterface, SetEvaluationScoresForTaskResponseInterface } from '.';
import { EvaluationScoreApi } from '../+api';
import { EvaluationScoreInterface } from '../+models';

@Injectable({
  providedIn: 'root',
})
export class EvaluationScoreService implements EvaluationScoreServiceInterface {
  constructor(private evaluationScoreApi: EvaluationScoreApi) {}

  getEvaluationScoresForBook(bookId: number): Observable<EvaluationScoreInterface[]> {
    return null;
  }

  setEvaluationScoresForTask(
    taskId: number,
    evaluationScores: EvaluationScoreInterface[]
  ): Observable<SetEvaluationScoresForTaskResponseInterface> {
    return this.evaluationScoreApi.setEvaluationScoresForTask(
      taskId,
      evaluationScores
    ) as Observable<SetEvaluationScoresForTaskResponseInterface>;
  }
}
