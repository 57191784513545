import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AllowedStrandGuard, DashboardGuard, MissingLearnerProfileGuard } from './can-activate';
import { AllowedBookGuard } from './can-activate/allowed-book.guard';
import { AuthenticationGuard } from './can-activate/authentication.guard';
import { CompleteProfileGuard } from './can-activate/complete-profile.guard';
import { CoupledTeacherGuard } from './can-activate/coupled-teacher.guard';
import { LicenseGuard } from './can-activate/license.guard';
import { PermissionGuard } from './can-activate/permission.guard';
import { RedirectGuard } from './can-activate/redirect.guard';
import { TermPrivacyGuard } from './can-activate/term-privacy.guard';
import { YearTransitionGuard } from './can-activate/year-transition.guard';

@NgModule({
  imports: [CommonModule],
  providers: [
    CoupledTeacherGuard,
    AuthenticationGuard,
    PermissionGuard,
    TermPrivacyGuard,
    AllowedBookGuard,
    AllowedStrandGuard,
    DashboardGuard,
    CompleteProfileGuard,
    LicenseGuard,
    YearTransitionGuard,
    MissingLearnerProfileGuard,
    RedirectGuard,
  ],
})
export class GuardsModule {}
