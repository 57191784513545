import { ArrayFunctions } from '@campus/utils';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EduContentTagInterface } from '../../+models';
import { EduContentTagsActions, EduContentTagsActionTypes } from './edu-content-tag.actions';

export const NAME = 'eduContentTags';

export interface State extends EntityState<EduContentTagInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<EduContentTagInterface> = createEntityAdapter<EduContentTagInterface>({
  sortComparer: ArrayFunctions.sortByKey('name'),
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: EduContentTagsActions): State {
  switch (action.type) {
    case EduContentTagsActionTypes.EduContentTagsLoaded: {
      return adapter.setAll(action.payload.eduContentTags, {
        ...state,
        loaded: true,
      });
    }

    case EduContentTagsActionTypes.EduContentTagsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
