import { groupArrayByKey, groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SectionContentInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './section-content.reducer';

export const selectSectionContentState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectSectionContentState, (state: State) => state.error);

export const getLoaded = createSelector(selectSectionContentState, (state: State) => state.loaded);

export const getAll = createSelector(selectSectionContentState, selectAll);

export const getCount = createSelector(selectSectionContentState, selectTotal);

export const getIds = createSelector(selectSectionContentState, selectIds);

export const getAllEntities = createSelector(selectSectionContentState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * SectionContent$: SectionContentInterface[] = this.store.pipe(
    select(SectionContentQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectSectionContentState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * sectionContent$: SectionContentInterface = this.store.pipe(
    select(SectionContentQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectSectionContentState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getSectionContentsBySectionId = createSelector(
  getAll,
  (sectionContents: SectionContentInterface[]): Dictionary<SectionContentInterface[]> => {
    return groupArrayByKeys(sectionContents, ['sectionId']);
  }
);

/**
 * returns a dictionary of SectionContent grouped by sectionId
 * @example
 * sectionContent$: Dictionary<SectionContentInterface[]> = this.store.pipe(
      select(SectionContentQueries.getBySectionId)
   ); 
 */
export const getBySectionId = createSelector(
  getAll,
  (sectionContents: SectionContentInterface[]): Dictionary<SectionContentInterface[]> => {
    return groupArrayByKey(sectionContents, 'sectionId');
  }
);

export const isBookLoaded = (props: { bookId: number }) =>
  createSelector(selectSectionContentState, (state: State) => !!state.loadedForBook[props.bookId]);

export const isTocLoaded = (props: { tocId: number }) =>
  createSelector(selectSectionContentState, (state: State) => !!state.loadedForToc[props.tocId]);
