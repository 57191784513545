import { EnvironmentInterface, FavoriteTypesEnum, HistoryTypesEnum } from '@campus/environment';
import { E2eModule } from '../app/e2e/e2e.module';
import { animations } from './animations';
import { icons } from './icons';
import { searchModes } from './search-modes';
import { svgs } from './svgs';
import { textMapping } from './text';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Partial<EnvironmentInterface> = {
  production: true,
  enableDevTools: true,
  release: 'PI11.S3.3.2066f3d1e212db567722714655293e9e6d83735d',
  platform: 'polpo',
  website: {
    url: 'https://polpo-api.lk2020.be/app',
    title: 'POLPO',
    favicon: 'assets/icons/favicon.ico',
    orderLicenseUrl: 'https://www.lk2020.be/bestel',
    activateLicenseUrl: 'https://www.lk2020.be/bestel/ik-heb-al-een-code',
  },
  afterLogout: {
    url: '/login',
  },
  login: {
    url: '/login',
    loginPresets: [
      { label: 'Student', username: 'student1', password: 'testje' },
      { label: 'Leerkracht', username: 'teacher1', password: 'testje' },
      { label: 'Beheerder (Sandrine)', username: 'schooladmin1', password: 'testje' },
    ],
  },
  profile: {
    url: '/settings/schools',
  },
  termPrivacy: {
    url: '/settings/profile/terms',
    privacyUrl: 'https://www.polpo.be/legals/privacy',
    useUrl: 'https://www.polpo.be/legals/gebruiksvoorwaarden',
    salesUrl: 'https://www.polpo.be/legals/verkoopsvoorwaarden',
  },
  iconMapping: icons,
  textMapping,
  svgMapping: svgs,
  api: {
    APIBase: 'https://polpo-api.lk2020.be',
  },
  features: {
    alerts: {
      enabled: false,
      hasAppBarDropDown: true,
      appBarPollingInterval: 3000,
    },
    messages: {
      enabled: false,
      hasAppBarDropDown: false,
    },
    errorManagement: {
      runMaintenanceCheckInInterceptor: true,
      managedStatusCodes: [500, 401, 404, 0],
      allowedErrors: [
        {
          status: 404,
          statusText: 'Not Found',
          urlRegex: 'http.*assets\\/icons.*.svg',
        },
        {
          status: 404,
          messageRegex: 'no_teacher_found_for_given_key',
        },
        {
          status: 401,
          urlRegex: 'http.*People\\/me.*',
        },
        {
          // if login page get's HTTP 401, it handles error on the login page
          status: 401,
          urlRegex: 'http.*\\/People/login*',
        },
        {
          status: 401,
          urlRegex: 'http.*\\/EduContents/[0-9]+/requestURL',
        },
      ],
    },
    globalSearch: {
      enabled: true,
    },
    collectionManagement: {
      useFilter: true,
    },
    favorites: {
      allowedFavoriteTypes: [
        FavoriteTypesEnum.BOEKE,
        FavoriteTypesEnum.BUNDLE,
        FavoriteTypesEnum.EDUCONTENT,
        FavoriteTypesEnum.BOOK,
      ],
    },
    history: {
      allowedHistoryTypes: [
        HistoryTypesEnum.BOEKE,
        HistoryTypesEnum.EDUCONTENT,
        HistoryTypesEnum.AREA,
        HistoryTypesEnum.TASK,
        HistoryTypesEnum.BUNDLE,
        HistoryTypesEnum.HISTORY,
        HistoryTypesEnum.METHOD,
      ],
    },
    tours: { enabled: false },
    groups: { enabled: true },
    methods: {
      showEduNetNames: false,
    },
    news: {
      showNewsFeedToggle: false,
    },
    freshworks: {
      widgetId: 11000000600,
      productId: 11000000564,
      url: 'https://ink.polpo.be/edu-contents-v2/{id}/general',
    },
  },
  sso: {
    facebook: {
      enabled: true,
      description: 'Facebook',
      logoIcon: 'facebook',
    },
    google: {
      enabled: true,
      description: 'Google',
      logoIcon: 'google',
    },
    smartschool: {
      enabled: true,
      description: 'Smartschool',
      logoIcon: 'smartschool',
      maxNumberAllowed: 10,
    },
    microsoft: {
      enabled: true,
      description: 'Microsoft',
      logoIcon: 'microsoft',
    },
    acm: {
      enabled: true,
      description: 'eID/Itsme',
      logoIcon: 'acm',
    },
    leerid: {
      enabled: true,
      provider: 'acm',
      description: 'LeerID',
      logoIcon: 'leerid',
    },
  },
  searchModes: searchModes,
  testing: {
    removeDataCyAttributes: false,
  },
  ui: {
    useInfoPanelStyle: true,
    contact: { order: { label: 'Mail de besteldienst', link: 'so@diekeure.be' } },
  },
  time: {
    msOffsetUntilUrgent: 1000 * 60 * 60 * 48,
  },
  assets: {
    basePath: 'https://polpo-api.lk2020.be/app/assets/',
    images: {
      methods: 'https://polpo-api.lk2020.be/app/assets/images/methods',
    },
    animations,
  },
  scoreMapping: [
    [70, 2],
    [50, 1],
    [0, 0],
  ],
  envModules: [E2eModule],
  googleAnalytics: {
    measurementId: '',
  },
  taskFeature: {
    showPaperAmount: false,
  },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
