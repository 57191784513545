import { InjectionToken, Injector } from '@angular/core';
import { EnvironmentPlatformType, ENVIRONMENT_PLATFORM_TOKEN } from '@campus/environment';
import { BooksTileService } from './books-tile.service';
import { KabasBooksTileService } from './books-tile.service.kabas';
import { PolpoBooksTileService } from './books-tile.service.polpo';

export const BOOKS_TILE_SERVICE_TOKEN = new InjectionToken<BooksTileService>('BooksTileService');

export const booksTileServiceFactory = (injector: Injector, platform: EnvironmentPlatformType) => {
  if (platform === 'kabas') return new KabasBooksTileService(injector);

  return new PolpoBooksTileService(injector);
};

export const booksTileServiceDeps = [Injector, ENVIRONMENT_PLATFORM_TOKEN];
