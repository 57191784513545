import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { GroupInterface } from '../+models';

export const GROUP_SERVICE_TOKEN = new InjectionToken('GroupService');

export interface GroupServiceInterface {
  getAllForUser(userId: number): Observable<GroupInterface[]>;
  addGroup(group: GroupInterface): Observable<GroupInterface>;
  updateGroup(groupId: number, changes: Partial<GroupInterface>): Observable<GroupInterface>;
  deleteGroup(groupId: number): Observable<boolean>;
}
