import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './edu-content-tag.reducer';

export const selectLevelState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectLevelState, (state: State) => state.error);

export const getLoaded = createSelector(selectLevelState, (state: State) => state.loaded);

export const getAll = createSelector(selectLevelState, selectAll);

export const getCount = createSelector(selectLevelState, selectTotal);

export const getIds = createSelector(selectLevelState, selectIds);

export const getAllEntities = createSelector(selectLevelState, selectEntities);
