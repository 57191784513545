import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TaskGoalYearLevelInterface } from '../../+models';
import { TaskGoalYearLevelsActions, TaskGoalYearLevelsActionTypes } from './task-goal-year-level.actions';

export const NAME = 'taskGoalYearLevels';

export interface State extends EntityState<TaskGoalYearLevelInterface> {
  // additional entities state properties
  loaded: boolean;
  loadedForTask: {
    [taskId: number]: boolean;
  };
  saving: boolean;
  error?: any;
}

export const adapter: EntityAdapter<TaskGoalYearLevelInterface> = createEntityAdapter<TaskGoalYearLevelInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForTask: {},
  saving: false,
});

export function reducer(state = initialState, action: TaskGoalYearLevelsActions): State {
  switch (action.type) {
    case TaskGoalYearLevelsActionTypes.AddTaskGoalYearLevel: {
      return adapter.addOne(action.payload.taskGoalYearLevel, state);
    }

    case TaskGoalYearLevelsActionTypes.UpsertTaskGoalYearLevel: {
      return adapter.upsertOne(action.payload.taskGoalYearLevel, state);
    }

    case TaskGoalYearLevelsActionTypes.AddTaskGoalYearLevelsForTask: {
      const newState = adapter.addMany(action.payload.taskGoalYearLevels, state);

      return {
        ...newState,
        loadedForTask: {
          ...newState.loadedForTask,
          [action.payload.taskId]: true,
        },
      };
    }
    case TaskGoalYearLevelsActionTypes.StartSetTaskGoalYearLevelsForTask: {
      return { ...state, saving: true };
    }

    case TaskGoalYearLevelsActionTypes.SetTaskGoalYearLevelsForTask: {
      const toRemove = Object.values(state.entities)
        .filter((entity) => entity.taskId === action.payload.taskId)
        .map((e) => e.id);

      const clearedState = adapter.removeMany(toRemove, state);
      const newState = adapter.addMany(action.payload.taskGoalYearLevels, clearedState);

      return {
        ...newState,
        loadedForTask: {
          ...state.loadedForTask,
          [action.payload.taskId]: true,
        },
        saving: false,
      };
    }

    case TaskGoalYearLevelsActionTypes.AddTaskGoalYearLevels: {
      return adapter.addMany(action.payload.taskGoalYearLevels, state);
    }

    case TaskGoalYearLevelsActionTypes.UpsertTaskGoalYearLevels: {
      return adapter.upsertMany(action.payload.taskGoalYearLevels, state);
    }

    case TaskGoalYearLevelsActionTypes.UpdateTaskGoalYearLevel: {
      return adapter.updateOne(action.payload.taskGoalYearLevel, state);
    }

    case TaskGoalYearLevelsActionTypes.UpdateTaskGoalYearLevels: {
      return adapter.updateMany(action.payload.taskGoalYearLevels, state);
    }

    case TaskGoalYearLevelsActionTypes.DeleteTaskGoalYearLevel: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TaskGoalYearLevelsActionTypes.DeleteTaskGoalYearLevels: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TaskGoalYearLevelsActionTypes.TaskGoalYearLevelsLoaded: {
      return adapter.setAll(action.payload.taskGoalYearLevels, { ...state, loaded: true });
    }

    case TaskGoalYearLevelsActionTypes.TaskGoalYearLevelsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case TaskGoalYearLevelsActionTypes.ClearTaskGoalYearLevels: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
