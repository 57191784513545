import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { CurriculumInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum CurriculumsActionTypes {
  CurriculumsLoaded = '[Curriculums] Curriculums Loaded',
  CurriculumsLoadError = '[Curriculums] Load Error',
  LoadCurriculums = '[Curriculums] Load Curriculums',
  AddCurriculum = '[Curriculums] Add Curriculum',
  UpsertCurriculum = '[Curriculums] Upsert Curriculum',
  AddCurriculums = '[Curriculums] Add Curriculums',
  UpsertCurriculums = '[Curriculums] Upsert Curriculums',
  UpdateCurriculum = '[Curriculums] Update Curriculum',
  UpdateCurriculums = '[Curriculums] Update Curriculums',
  DeleteCurriculum = '[Curriculums] Delete Curriculum',
  DeleteCurriculums = '[Curriculums] Delete Curriculums',
  ClearCurriculums = '[Curriculums] Clear Curriculums',
  PublishCurriculum = '[Curriculums] Publish Curriculum',
}

export class LoadCurriculums implements Action {
  readonly type = CurriculumsActionTypes.LoadCurriculums;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class CurriculumsLoaded implements Action {
  readonly type = CurriculumsActionTypes.CurriculumsLoaded;

  constructor(public payload: { curriculums: CurriculumInterface[] }) {}
}

export class CurriculumsLoadError implements Action {
  readonly type = CurriculumsActionTypes.CurriculumsLoadError;
  constructor(public payload: any) {}
}

export class AddCurriculum implements Action {
  readonly type = CurriculumsActionTypes.AddCurriculum;

  constructor(public payload: { curriculum: CurriculumInterface }) {}
}

export class UpsertCurriculum implements Action {
  readonly type = CurriculumsActionTypes.UpsertCurriculum;

  constructor(public payload: { curriculum: CurriculumInterface }) {}
}

export class AddCurriculums implements Action {
  readonly type = CurriculumsActionTypes.AddCurriculums;

  constructor(public payload: { curriculums: CurriculumInterface[] }) {}
}

export class UpsertCurriculums implements Action {
  readonly type = CurriculumsActionTypes.UpsertCurriculums;

  constructor(public payload: { curriculums: CurriculumInterface[] }) {}
}

export class UpdateCurriculum implements Action {
  readonly type = CurriculumsActionTypes.UpdateCurriculum;

  constructor(public payload: { curriculum: Update<CurriculumInterface> }) {}
}

export class UpdateCurriculums implements Action {
  readonly type = CurriculumsActionTypes.UpdateCurriculums;

  constructor(public payload: { curriculums: Update<CurriculumInterface>[] }) {}
}

export class DeleteCurriculum implements Action {
  readonly type = CurriculumsActionTypes.DeleteCurriculum;

  constructor(public payload: { id: string }) {}
}

export class DeleteCurriculums implements Action {
  readonly type = CurriculumsActionTypes.DeleteCurriculums;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearCurriculums implements Action {
  readonly type = CurriculumsActionTypes.ClearCurriculums;
}

export class PublishCurriculum implements FeedbackTriggeringAction {
  readonly type = CurriculumsActionTypes.PublishCurriculum;

  constructor(
    public payload: {
      learningAreaId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export type CurriculumsActions =
  | LoadCurriculums
  | CurriculumsLoaded
  | CurriculumsLoadError
  | AddCurriculum
  | UpsertCurriculum
  | AddCurriculums
  | UpsertCurriculums
  | UpdateCurriculum
  | UpdateCurriculums
  | DeleteCurriculum
  | DeleteCurriculums
  | ClearCurriculums
  | PublishCurriculum;
