<div mat-menu-item
  ><campus-icon data-cy="notification-icon" [svgIcon]="icon" *ngIf="icon" class="ui-notification__icon"></campus-icon>
  <campus-person-badge
    data-cy="notification-badge"
    *ngIf="person"
    class="ui-notification__badge"
    [person]="person"
    align="left"
    size="medium"
    [showText]="false"
  ></campus-person-badge>
  <div class="ui-notification__body">
    <div *ngIf="titleText" class="ui-notification__title" data-cy="notification-title">{{ titleText }}</div>
    <div class="ui-notification__text" data-cy="notification-text"
      >{{ notificationText }}<span *ngIf="accented && link">&nbsp;&rarr; <span>Lees meer&hellip;</span></span>
    </div>
  </div>
  <div class="ui-notification__end">
    <div *ngIf="notificationDate" class="ui-notification__date">{{ notificationDate | humanDateTime }}</div>

    <div class="ui-notification__buttons">
      <ng-content select="campus-button campus-icon"></ng-content>
    </div>
  </div>
</div>
