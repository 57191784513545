import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { MethodGoalInterface } from '../../+models';
import { MethodGoalsActions, MethodGoalsActionTypes } from './method-goal.actions';

export const NAME = 'methodGoals';

export interface State extends EntityState<MethodGoalInterface> {
  loaded: boolean;
  error?: any;
  loadedForBook: Dictionary<number[]>;
}

export const adapter: EntityAdapter<MethodGoalInterface> = createEntityAdapter<MethodGoalInterface>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loadedForBook: {},
});

export function reducer(state = initialState, action: MethodGoalsActions): State {
  switch (action.type) {
    case MethodGoalsActionTypes.MethodGoalsLoaded: {
      return adapter.setAll(action.payload.methodGoals, {
        ...state,
        loaded: true,
        loadedForBook: {},
      });
    }

    case MethodGoalsActionTypes.MethodGoalsLoadError: {
      return {
        ...state,
        error: action.payload,
        loaded: false,
        loadedForBook: {},
      };
    }

    case MethodGoalsActionTypes.AddMethodGoalsForBook: {
      const loadedForBookState = {
        ...state,
        loadedForBook: {
          ...state.loadedForBook,
          [action.payload.bookId]: action.payload.methodGoals.map((methodGoal) => methodGoal.id),
        },
      };

      return adapter.addMany(action.payload.methodGoals, loadedForBookState);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
