import { SearchStateInterface } from '../../interfaces';

// TODO #11805 ~~extract to Search~~ and add tests
export class SearchState implements SearchStateInterface {
  public searchTerm = '';
  public filterCriteriaSelections = new Map<string, (number | string)[]>();
  public filterCriteriaOptions = new Map<string, boolean>();
  public from: number;
  public sort: string | string[];
  public size: number;
  public aggs = false;

  constructor(props: Partial<SearchStateInterface> = {}) {
    Object.assign(this, props);
  }

  public setSearchTerm(searchTerm: string) {
    this.searchTerm = searchTerm;
    return this;
  }

  //TODO : FilterSelectionKeyEnum?
  public setSelection(key: string, value: number | string | number[] | string[]) {
    const arrValue = (value && !Array.isArray(value) ? [value] : value) as number[] | string[];

    this.filterCriteriaSelections.set(key, arrValue);
    return this;
  }

  //TODO : FilterOptionKeyEnum?
  public setOption(key: string, value: boolean) {
    this.filterCriteriaOptions.set(key, value);
    return this;
  }

  public setFrom(from: number) {
    this.from = from;
    return this;
  }

  public setSort(sort: string | string[]) {
    this.sort = sort;
    return this;
  }

  public setSize(size: number) {
    this.size = size;
    return this;
  }

  public mergeSearchState(otherSearchState: SearchStateInterface) {
    const ownFilterCriteriaSelections = this.toArray(this.filterCriteriaSelections);
    const ownFilterCriteriaOptions = this.toArray(this.filterCriteriaOptions);

    const otherFilterCriteriaSelections = this.toArray(otherSearchState.filterCriteriaSelections);
    const otherFilterCriteriaOptions = this.toArray(otherSearchState.filterCriteriaOptions);

    const filterCriteriaSelections = new Map([...ownFilterCriteriaSelections, ...otherFilterCriteriaSelections]);
    const filterCriteriaOptions = new Map([...ownFilterCriteriaOptions, ...otherFilterCriteriaOptions]);

    Object.assign(this, otherSearchState, {
      filterCriteriaSelections,
      filterCriteriaOptions,
    });

    return this;
  }

  public clone() {
    return new SearchState({
      ...this,
      filterCriteriaSelections: new Map(this.toArray(this.filterCriteriaSelections)),
      filterCriteriaOptions: new Map(this.toArray(this.filterCriteriaOptions)),
    });
  }

  private toArray<K, V>(mapToMap?: Map<K, V>) {
    if (mapToMap === undefined) return [];
    return Array.from(mapToMap.entries());
  }
}
