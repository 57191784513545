import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EnvironmentGoogleAnalyticsInterface, ENVIRONMENT_GOOGLE_ANALYTICS_TOKEN } from '@campus/environment';
import { filter, tap } from 'rxjs/operators';
import { GoogleAnalyticsServiceInterface } from './google-analytics.service.interface';

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService implements GoogleAnalyticsServiceInterface {
  constructor(
    @Inject(ENVIRONMENT_GOOGLE_ANALYTICS_TOKEN)
    private googleAnalytics: EnvironmentGoogleAnalyticsInterface,
    private router: Router
  ) {
    this.setupScript();
    this.registerNavigationEventListener();
  }

  setupScript() {
    const { requireConsent, measurementId } = this.googleAnalytics;

    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    document.head.appendChild(script);

    script.onload = () => {
      if (requireConsent) {
        const cookieSettings = JSON.parse(window.localStorage.getItem('cookies'));

        if (!cookieSettings || !cookieSettings.statistics) {
          return;
        }
      }

      window['dataLayer'] = window['dataLayer'] || [];
      this.gtag('config', this.googleAnalytics.measurementId);
      this.gtag('js', new Date());
    };
  }

  gtag(...args) {
    window['dataLayer'].push(arguments);
  }

  logPageView(url: string) {
    this.gtag('event', 'page_view', {
      page_title: url,
      page_path: url,
      send_to: this.googleAnalytics.measurementId,
    });
  }

  registerNavigationEventListener() {
    if (this.googleAnalytics.measurementId) {
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          tap((event: NavigationEnd) => {
            this.logPageView(event.urlAfterRedirects);
          })
        )
        .subscribe();
    }
  }
}
