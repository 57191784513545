import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch, optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonProductQueries } from '.';
import { DalActions } from '..';
import { PersonProductInterface } from '../../+models';
import { PersonProductServiceInterface, PERSON_PRODUCT_SERVICE_TOKEN } from '../../person-product';
import { DalState } from '../dal.state.interface';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import {
  AddPersonProduct,
  AddPersonProducts,
  DeletePersonProduct,
  DeletePersonProducts,
  LoadPersonProducts,
  PersonProductsActionTypes,
  PersonProductsLoaded,
  PersonProductsLoadError,
  StartAddPersonProduct,
  StartAddPersonProducts,
} from './person-product.actions';

@Injectable()
export class PersonProductEffects {
  loadPersonProducts$ = createEffect(() =>
    this.actions.pipe(
      ofType(PersonProductsActionTypes.LoadPersonProducts),
      concatLatestFrom(() => this.store.select(PersonProductQueries.getLoaded)),
      fetch({
        run: (action: LoadPersonProducts, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.personProductService
            .getAllForUser(action.payload.userId)
            .pipe(map((personProducts) => new PersonProductsLoaded({ personProducts })));
        },
        onError: (action: LoadPersonProducts, error) => {
          return new PersonProductsLoadError(error);
        },
      })
    )
  );

  startAddPersonProduct$ = createEffect(() =>
    this.actions.pipe(
      ofType(PersonProductsActionTypes.StartAddPersonProduct),
      pessimisticUpdate({
        run: (action: StartAddPersonProduct) => {
          return this.personProductService
            .addPersonProduct(action.payload.personProduct)
            .pipe(map((personProduct: PersonProductInterface) => new AddPersonProduct({ personProduct })));
        },
        onError: (action: StartAddPersonProduct, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het product aan te maken.'
            ),
          });
        },
      })
    )
  );

  startAddPersonProducts$ = createEffect(() =>
    this.actions.pipe(
      ofType(PersonProductsActionTypes.StartAddPersonProducts),
      pessimisticUpdate({
        run: (action: StartAddPersonProducts) => {
          return this.personProductService
            .addPersonProducts(action.payload.personProducts)
            .pipe(map((personProducts: PersonProductInterface[]) => new AddPersonProducts({ personProducts })));
        },
        onError: (action: StartAddPersonProducts, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het product toe te voegen.'
            ),
          });
        },
      })
    )
  );

  deletePersonProduct$ = createEffect(() =>
    this.actions.pipe(
      ofType(PersonProductsActionTypes.DeletePersonProduct),
      optimisticUpdate({
        run: (action: DeletePersonProduct) => {
          return this.personProductService.deletePersonProduct(action.payload.id).pipe(
            map(
              () =>
                new DalActions.ActionSuccessful({
                  successfulAction: '[PersonProduct] deleted',
                })
            )
          );
        },
        undoAction: (action: DeletePersonProduct, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het product te verwijderen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  deletePersonProducts$ = createEffect(() =>
    this.actions.pipe(
      ofType(PersonProductsActionTypes.DeletePersonProducts),
      optimisticUpdate({
        run: (action: DeletePersonProducts) => {
          return this.personProductService.deletePersonProducts(action.payload.ids).pipe(
            map(
              () =>
                new DalActions.ActionSuccessful({
                  successfulAction: '[PersonProducts] deleted',
                })
            )
          );
        },
        undoAction: (action: DeletePersonProducts, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de producten te verwijderen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  constructor(
    @Inject('uuid')
    private uuid,
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(PERSON_PRODUCT_SERVICE_TOKEN)
    private personProductService: PersonProductServiceInterface
  ) {}
}
