import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { pessimisticUpdate } from '@nrwl/angular';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GroupPersonServiceInterface, GROUP_PERSON_SERVICE_TOKEN } from '../../group-person';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import {
  GroupPersonsActionTypes,
  SetForGroup,
  SetForStudent,
  StartSetForGroup,
  StartSetForStudent,
} from './group-person.actions';

@Injectable()
export class GroupPersonEffects {
  startSetForStudent$ = createEffect(() =>
    this.actions.pipe(
      ofType(GroupPersonsActionTypes.StartSetForStudent),
      pessimisticUpdate({
        run: (action: StartSetForStudent) => {
          const { studentId, groupIds } = action.payload;
          return this.groupPersonService.linkGroups(studentId, groupIds).pipe(
            switchMap((groupPersons) => {
              const actions: Action[] = [
                new SetForStudent({ studentId, groupPersons }),
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    `De groep${groupIds.length === 1 ? ' werd' : 'en werden'} aangepast.`
                  ),
                }),
              ];
              return from(actions);
            })
          );
        },
        onError: (action: StartSetForStudent, error) => {
          const { groupIds } = action.payload;
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              `Het is niet gelukt om de groep${groupIds.length === 1 ? '' : 'en'} aan te passen.`
            ),
          });
        },
      })
    )
  );

  startSetForGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(GroupPersonsActionTypes.StartSetForGroup),
      pessimisticUpdate({
        run: (action: StartSetForGroup) => {
          const { groupId, studentIds } = action.payload;
          return this.groupPersonService.linkStudents(groupId, studentIds).pipe(
            switchMap((groupPersons) => {
              const actions: Action[] = [
                new SetForGroup({ groupId, groupPersons }),
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'De groep werd aangepast.'
                  ),
                }),
              ];
              return from(actions);
            })
          );
        },
        onError: (action: StartSetForGroup, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              `Het is niet gelukt om de groep aan te passen.`
            ),
          });
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    @Inject(GROUP_PERSON_SERVICE_TOKEN) private groupPersonService: GroupPersonServiceInterface,
    @Inject('uuid') private uuid: () => string
  ) {}
}
