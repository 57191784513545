import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { fetch, optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UnlockedContentQueries } from '.';
import { UnlockedContentInterface } from '../../+models/UnlockedContent.interface';
import {
  UnlockedContentServiceInterface,
  UNLOCKED_CONTENT_SERVICE_TOKEN,
  UpdateUnlockedContentResultInterface,
} from '../../bundle/unlocked-content.service.interface';
import { DalState } from '../dal.state.interface';
import { EffectFeedback, FeedbackTriggeringAction, Priority } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import {
  AddUnlockedContents,
  DeleteUnlockedContent,
  DeleteUnlockedContents,
  LoadUnlockedContents,
  StartAddUnlockedContents,
  UnlockedContentsActionTypes,
  UnlockedContentsLoaded,
  UnlockedContentsLoadError,
  UpdateUnlockedContents,
} from './unlocked-content.actions';

@Injectable()
export class UnlockedContentsEffects {
  loadUnlockedContents$ = createEffect(() =>
    this.actions.pipe(
      ofType(UnlockedContentsActionTypes.LoadUnlockedContents),
      concatLatestFrom(() => this.store.select(UnlockedContentQueries.getLoaded)),
      fetch({
        run: (action: LoadUnlockedContents, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.unlockedContentService
            .getAllForUser(action.payload.userId)
            .pipe(map((unlockedContents) => new UnlockedContentsLoaded({ unlockedContents })));
        },
        onError: (action: LoadUnlockedContents, error) => {
          return new UnlockedContentsLoadError(error);
        },
      })
    )
  );

  updateUnlockedContents$ = createEffect(() =>
    this.actions.pipe(
      ofType(UnlockedContentsActionTypes.UpdateUnlockedContents),
      optimisticUpdate({
        run: (action: UpdateUnlockedContents) => {
          const updates = action.payload.unlockedContents.map(
            (partialUnlockedContent) =>
              ({
                id: partialUnlockedContent.id,
                ...partialUnlockedContent.changes,
              } as UnlockedContentInterface)
          );
          return this.unlockedContentService.updateUnlockedContents(null, updates).pipe(
            map((update) => {
              return new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'De inhoud van de bundel werd bijgewerkt.'
                ),
              });
            })
          );
        },
        undoAction: (action: UpdateUnlockedContents, error: any) => {
          return from([
            undo(action),
            new AddEffectFeedback({
              effectFeedback: EffectFeedback.generateErrorFeedback(
                this.uuid(),
                action,
                'Het is niet gelukt om de inhoud van de bundel bij te werken.'
              ),
            }),
          ]);
        },
      })
    )
  );

  deleteUnlockedContent$ = createEffect(() =>
    this.actions.pipe(
      ofType(UnlockedContentsActionTypes.DeleteUnlockedContent),
      optimisticUpdate({
        run: (action: DeleteUnlockedContent) => {
          return this.unlockedContentService.remove(action.payload.id).pipe(
            map(
              (res) =>
                new AddEffectFeedback({
                  effectFeedback: new EffectFeedback({
                    id: this.uuid(),
                    triggerAction: action,
                    message: 'Het lesmateriaal is uit de bundel verwijderd.',
                    userActions: null,
                    type: 'success',
                    priority: Priority.NORM,
                  }),
                })
            )
          );
        },
        undoAction: (action: DeleteUnlockedContent, error: any) => {
          const undoAction = undo(action);

          const feedbackAction = new AddEffectFeedback({
            effectFeedback: new EffectFeedback({
              id: this.uuid(),
              triggerAction: action,
              message: 'Het is niet gelukt om het lesmateriaal uit de bundel te verwijderen.',
              userActions: [{ title: 'Opnieuw', userAction: action }],
              type: 'error',
              priority: Priority.HIGH,
            }),
          });

          return from<Action[]>([undoAction, feedbackAction]);
        },
      })
    )
  );

  deleteUnlockedContents$ = createEffect(() =>
    this.actions.pipe(
      ofType(UnlockedContentsActionTypes.DeleteUnlockedContents),
      optimisticUpdate({
        run: (action: DeleteUnlockedContents) => {
          return this.unlockedContentService.removeAll(action.payload.ids).pipe(
            map(
              (res) =>
                new AddEffectFeedback({
                  effectFeedback: new EffectFeedback({
                    id: this.uuid(),
                    triggerAction: action,
                    message: 'De lesmaterialen zijn uit de bundel verwijderd.',
                    userActions: null,
                    type: 'success',
                    priority: Priority.NORM,
                  }),
                })
            )
          );
        },
        undoAction: (action: DeleteUnlockedContents, error: any) => {
          const undoAction = undo(action);

          const feedbackAction = new AddEffectFeedback({
            effectFeedback: new EffectFeedback({
              id: this.uuid(),
              triggerAction: action,
              message: 'Het is niet gelukt om de lesmaterialen uit de bundel te verwijderen.',
              userActions: [{ title: 'Opnieuw', userAction: action }],
              type: 'error',
              priority: Priority.HIGH,
            }),
          });

          return from<Action[]>([undoAction, feedbackAction]);
        },
      })
    )
  );

  createUnlockedContents$ = createEffect(() =>
    this.actions.pipe(
      ofType(UnlockedContentsActionTypes.StartAddUnlockedContents),
      pessimisticUpdate({
        run: (action: StartAddUnlockedContents) => {
          return this.unlockedContentService
            .createUnlockedContents(action.payload.userId, action.payload.unlockedContents)
            .pipe(
              switchMap((unlockedContentCreateResults: UpdateUnlockedContentResultInterface) => {
                const actions = [];
                const { success, errors } = unlockedContentCreateResults;

                if (this.isFilled(success)) {
                  actions.push(new AddUnlockedContents({ unlockedContents: success }));

                  // show a snackbar if there is no other feedback (i.e. no errors)
                  if (!this.isFilled(errors)) {
                    const message = this.getUnlockedContentUpdateSuccessMessage(success.length, 'create');
                    actions.push(this.getUnlockedContentUpdateFeedbackAction(action, message, 'success'));
                  }
                }

                // show feedback for the ones still in use
                if (this.isFilled(errors)) {
                  const errorMessage = this.getUnlockedContentUpdateErrorMessage(errors.length, 'create');
                  actions.push(this.getUnlockedContentUpdateFeedbackAction(action, errorMessage, 'error'));
                }
                return from(actions);
              })
            );
        },

        onError: (action: StartAddUnlockedContents, error) => {
          return this.getUnlockedContentUpdateOnErrorFeedbackAction(action, 'create');
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(UNLOCKED_CONTENT_SERVICE_TOKEN)
    private unlockedContentService: UnlockedContentServiceInterface,
    @Inject('uuid') private uuid: () => string
  ) {}

  private isFilled = (arr) => Array.isArray(arr) && arr.length;

  private getUnlockedContentUpdateSuccessMessage(unlockedContentsLength: number, method: 'delete' | 'create'): string {
    const methodVerbs = {
      delete: 'verwijderd',
      create: 'toegevoegd',
    };

    return `Het materiaal werd ${methodVerbs[method]}.`;
  }

  private getUnlockedContentUpdateErrorMessage(errorLength: number, method: 'delete' | 'create'): string {
    const methodVerbs = {
      delete: 'verwijderd',
      create: 'toegevoegd',
    };

    const isSingular = errorLength === 1;

    const message = `Er ${isSingular ? 'werd' : 'werden'} ${errorLength} ${isSingular ? 'item' : 'items'} niet ${
      methodVerbs[method]
    }.`;

    return message;
  }

  private getUnlockedContentUpdateFeedbackAction(
    action: FeedbackTriggeringAction,
    message: string,
    type: 'error' | 'success'
  ) {
    const effectFeedback =
      type === 'success'
        ? EffectFeedback.generateSuccessFeedback(this.uuid(), action, message)
        : {
            ...EffectFeedback.generateErrorFeedback(this.uuid(), action, message),
            userActions: [], // don't add a retry button
          };

    return new AddEffectFeedback({
      effectFeedback,
    });
  }

  private getUnlockedContentUpdateOnErrorFeedbackAction(action: FeedbackTriggeringAction, method: 'delete' | 'create') {
    const methodVerbs = {
      delete: 'te verwijderen',
      create: 'toe te voegen',
    };
    const feedbackAction = new AddEffectFeedback({
      effectFeedback: EffectFeedback.generateErrorFeedback(
        this.uuid(),
        action,
        `Het is niet gelukt om het materiaal ${methodVerbs[method]}.`
      ),
    });
    return feedbackAction;
  }
}
