import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { LottieComponent } from './lottie.component';

@Directive({
  selector: '[campus-lottie-trigger-for], [campusLottieTriggerFor]',
  exportAs: 'lottieTrigger',
})
export class LottieTriggerDirective {
  private _lottie: LottieComponent;

  @Input('campusLottieTriggerFor')
  get lottie() {
    return this._lottie;
  }
  set lottie(value: LottieComponent | null) {
    this._lottie = value;
    this._lottie.boundWithTrigger = true;
  }

  @HostBinding('class.ui-lottie__trigger')
  hasLottieTriggerClass = true;

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: MouseEvent) {
    this.lottie.onMouseEnter(event, true);
  }

  @HostListener('mouseout', ['$event'])
  onMouseOut(event: MouseEvent) {
    this.lottie.onMouseOut(event, true);
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event: MouseEvent) {
    this.lottie.onMouseLeave(event, true);
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    this.lottie.onClick(event, true);
  }
}
