import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { LoopLearnerStatusInterface } from '../../+models';
import { LoopLearnerStatusesActions, LoopLearnerStatusesActionTypes } from './loop-learner-profile.actions';

export const NAME = 'loopLearnerStatuses';

export interface State extends EntityState<LoopLearnerStatusInterface> {
  // additional entities state properties
  loaded: boolean;
  loadedForBook: Dictionary<boolean>;
  loadedForToc: Dictionary<boolean>;
  error?: any;
}

export const adapter: EntityAdapter<LoopLearnerStatusInterface> = createEntityAdapter<LoopLearnerStatusInterface>({
  selectId: (entity) => entity.moduleId.split('?').pop(),
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForBook: {},
  loadedForToc: {},
});

export function reducer(state = initialState, action: LoopLearnerStatusesActions): State {
  switch (action.type) {
    case LoopLearnerStatusesActionTypes.LoopLearnerStatusesLoaded: {
      return adapter.setAll(action.payload.loopLearnerStatuses, {
        ...state,
        loaded: true,
      });
    }

    case LoopLearnerStatusesActionTypes.LoopLearnerStatusesLoadError: {
      const bookId = action.payload?.bookId;
      const tocId = action.payload?.tocId;
      if (bookId || tocId) {
        return {
          ...state,
          error: action.payload,
          loadedForBook: {
            ...state.loadedForBook,
            ...(bookId ? { [bookId]: false } : {}),
          },
          loadedForToc: {
            ...state.loadedForToc,
            ...(tocId ? { [tocId]: false } : {}),
          },
        };
      }
      return { ...state, error: action.payload, loaded: false };
    }

    case LoopLearnerStatusesActionTypes.UpsertLoopLearnerStatuses: {
      return adapter.upsertMany(action.payload.loopLearnerStatuses, state);
    }

    case LoopLearnerStatusesActionTypes.UpsertLoopLearnerStatus: {
      return adapter.upsertOne(action.payload.loopLearnerStatus, state);
    }

    case LoopLearnerStatusesActionTypes.AddLoopLearnerStatusesForBook: {
      const { bookId, loopLearnerStatuses } = action.payload;
      return adapter.addMany(loopLearnerStatuses, {
        ...state,
        loadedForBook: {
          ...state.loadedForBook,
          [bookId]: true,
        },
      });
    }
    case LoopLearnerStatusesActionTypes.AddLoopLearnerStatusesForTocs: {
      const { loopLearnerStatuses, tocId } = action.payload;
      return adapter.addMany(loopLearnerStatuses, {
        ...state,
        loadedForToc: {
          ...state.loadedForToc,
          [tocId]: true,
        },
      });
    }

    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities } = adapter.getSelectors();
