<div data-cy="button-toggle-filter-label" class="button-toggle-filter__title search-filter-title">{{
  criteria.label
}}</div>
<mat-button-toggle-group
  class="button-toggle-filter-component__button-group"
  [multiple]="multiple"
  [formControl]="toggleControl"
>
  <mat-button-toggle
    *ngFor="let option of options"
    [value]="option.value"
    [matTooltip]="option.tooltip"
    [disabled]="(option.value.prediction === 0 && !option.value.selected) || disabled"
    class="button-toggle-filter-component__button"
    [class.button-toggle-filter-component__button--disabled]="option.value.prediction === 0 && option.value.selected"
    data-cy="button-toggle-filter-value"
  >
    <campus-diabolo-phase
      *ngIf="criteria.displayProperty === 'isDiabolo'; else label"
      [diaboloPhaseIds]="option.viewValue"
      [attr.data-cy]="'toggle-diabolo-phase-' + option.viewValue"
    >
    </campus-diabolo-phase>
    <ng-template #label>
      <span class="ui-caption" *ngIf="criteria.displayProperty === 'label'; else icon">
        {{ option.viewValue }}
      </span>
    </ng-template>
    <ng-template #icon>
      <campus-icon [svgIcon]="option.viewValue"></campus-icon>
    </ng-template>
  </mat-button-toggle>
</mat-button-toggle-group>
