import { groupArrayByKey, groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BundleInterface, UnlockedContentInterface } from '../../+models';
import { UnlockedContent } from '../../+models/UnlockedContent';
import { BundleQueries } from '../bundle';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './unlocked-content.reducer';

export const selectUnlockedContentState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectUnlockedContentState, (state: State) => state.error);

export const getLoaded = createSelector(selectUnlockedContentState, (state: State) => state.loaded);

export const getAll = createSelector(selectUnlockedContentState, selectAll);

export const getCount = createSelector(selectUnlockedContentState, selectTotal);

export const getIds = createSelector(selectUnlockedContentState, selectIds);

export const getAllEntities = createSelector(selectUnlockedContentState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * unlockedContent$: UnlockedContentInterface[] = this.store.pipe(
    select(UnlockedContentQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectUnlockedContentState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => UnlockedContent.toUnlockedContent(state.entities[id]));
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * unlockedContent$: UnlockedContentInterface = this.store.pipe(
    select(UnlockedContentQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(selectUnlockedContentState, (state: State, props: { id: number }) =>
  UnlockedContent.toUnlockedContent(state.entities[props.id])
);

export const getByBundleIds = createSelector(selectUnlockedContentState, (state: State) => {
  const byKey = {};
  const ids = <number[]>state.ids;
  ids.forEach((id) => {
    const item = state.entities[id];
    if (!byKey[item.bundleId]) {
      byKey[item.bundleId] = [];
    }
    byKey[item.bundleId].push(UnlockedContent.toUnlockedContent(item));
  });
  return byKey;
});

export const getByBundleId = createSelector(selectUnlockedContentState, (state: State, props: { bundleId: number }) => {
  const ids = <number[]>state.ids;

  return ids.reduce((acc, id, idx, arr) => {
    return state.entities[id].bundleId === props.bundleId ? [...acc, state.entities[id]] : acc;
  }, []);
});

export const getByEduContentId = createSelector(
  selectUnlockedContentState,
  (state: State, props: { eduContentId: number }) => {
    const ids = <number[]>state.ids;

    return ids.reduce((acc, id, idx, arr) => {
      return state.entities[id].eduContentId === props.eduContentId ? [...acc, state.entities[id]] : acc;
    }, []);
  }
);

export const getAllByBundleAndEduContentId = createSelector(
  getAll,
  (unlockedContents: UnlockedContentInterface[]): Dictionary<Dictionary<UnlockedContentInterface>> => {
    return groupArrayByKeys(unlockedContents, ['bundleId', 'eduContentId'], null, true);
  }
);

export const getAllByBundleAndUserContentId = createSelector(
  getAll,
  (unlockedContents: UnlockedContentInterface[]): Dictionary<Dictionary<UnlockedContentInterface>> => {
    return groupArrayByKeys(unlockedContents, ['bundleId', 'userContentId'], null, true);
  }
);

export const getByUserContentId = createSelector(
  selectUnlockedContentState,
  (state: State, props: { userContentId: number }) => {
    const ids = <number[]>state.ids;

    return ids.reduce((acc, id, idx, arr) => {
      return state.entities[id].userContentId === props.userContentId ? [...acc, state.entities[id]] : acc;
    }, []);
  }
);

export const getByBundleAndEduContentId = createSelector(
  selectUnlockedContentState,
  (state: State, props: { bundleId: number; eduContentId: number }) => {
    const ids = <number[]>state.ids;

    const desiredId = ids.find((id) => {
      return state.entities[id].bundleId === props.bundleId && state.entities[id].eduContentId === props.eduContentId;
    });
    return UnlockedContent.toUnlockedContent(state.entities[desiredId]);
  }
);

export const getByBundleAndUserContentId = createSelector(
  selectUnlockedContentState,
  (state: State, props: { bundleId: number; userContentId: number }) => {
    const ids = <number[]>state.ids;

    const desiredId = ids.find((id) => {
      return state.entities[id].bundleId === props.bundleId && state.entities[id].userContentId === props.userContentId;
    });
    return UnlockedContent.toUnlockedContent(state.entities[desiredId]);
  }
);

export const getAllGroupedByBundleId = createSelector(getAll, (unlockedContents) => {
  const byBundleId = groupArrayByKey(unlockedContents, 'bundleId');

  return byBundleId as Dictionary<UnlockedContentInterface[]>;
});

export const getAllByEduContentId = createSelector(
  getAll,
  BundleQueries.getShared,
  (
    unlockedContents: UnlockedContentInterface[],
    sharedBundles: BundleInterface[]
  ): Dictionary<UnlockedContentInterface[]> => {
    // filters out unlocked content from shared, but not accepted, bundles
    const sharedIds = new Set(sharedBundles.map((b) => b.id));
    return groupArrayByKeys(
      unlockedContents,
      ['eduContentId'],
      (uC) => !!uC.eduContentId && !sharedIds.has(uC.bundleId)
    );
  }
);
