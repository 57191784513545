import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EduContentMetadataInterface } from '../../+models';
import { EduContentMetadatasActions, EduContentMetadatasActionTypes } from './edu-content-metadata.actions';

export const NAME = 'eduContentMetadatas';

export interface State extends EntityState<EduContentMetadataInterface> {
  // additional entities state properties
  loaded: boolean;
  loadedWithArtifact: boolean;
  loadedForBook: {
    [bookId: number]: boolean;
  };
  loadedDraftForBook: {
    [bookId: number]: boolean;
  };
  loadedPublishedForBook: { [bookId: number]: boolean };
  error?: any;
}

export const adapter: EntityAdapter<EduContentMetadataInterface> = createEntityAdapter<EduContentMetadataInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedWithArtifact: false,
  loadedForBook: {},
  loadedDraftForBook: {},
  loadedPublishedForBook: {},
});

export function reducer(state = initialState, action: EduContentMetadatasActions): State {
  switch (action.type) {
    case EduContentMetadatasActionTypes.AddEduContentMetadata: {
      return adapter.addOne(action.payload.eduContentMetadata, state);
    }

    case EduContentMetadatasActionTypes.AddEduContentMetadatasForBook: {
      const newState = adapter.addMany(action.payload.eduContentMetadatas, state);

      return {
        ...newState,
        loadedForBook: {
          ...newState.loadedForBook,
          [action.payload.bookId]: true,
        },
      };
    }

    case EduContentMetadatasActionTypes.AddEduContentMetadatasDraftForBook: {
      const newState = adapter.addMany(action.payload.eduContentMetadatas, state);

      return {
        ...newState,
        loadedDraftForBook: {
          ...newState.loadedDraftForBook,
          [action.payload.bookId]: true,
        },
      };
    }

    case EduContentMetadatasActionTypes.AddEduContentMetadatasPublishedForBook: {
      const newState = adapter.addMany(action.payload.eduContentMetadatas, state);

      return {
        ...newState,
        loadedPublishedForBook: {
          ...newState.loadedPublishedForBook,
          [action.payload.bookId]: true,
        },
      };
    }

    case EduContentMetadatasActionTypes.AddEduContentMetadatasWithArtifact: {
      return adapter.upsertMany(action.payload.eduContentMetadatas, { ...state, loadedWithArtifact: true });
    }

    case EduContentMetadatasActionTypes.UpsertEduContentMetadata: {
      return adapter.upsertOne(action.payload.eduContentMetadata, state);
    }

    case EduContentMetadatasActionTypes.AddEduContentMetadatas: {
      return adapter.addMany(action.payload.eduContentMetadatas, state);
    }

    case EduContentMetadatasActionTypes.UpsertEduContentMetadatas: {
      return adapter.upsertMany(action.payload.eduContentMetadatas, state);
    }

    case EduContentMetadatasActionTypes.UpdateEduContentMetadata: {
      return adapter.updateOne(action.payload.eduContentMetadata, state);
    }

    case EduContentMetadatasActionTypes.UpdateEduContentMetadatas: {
      return adapter.updateMany(action.payload.eduContentMetadatas, state);
    }

    case EduContentMetadatasActionTypes.DeleteEduContentMetadata: {
      return adapter.removeOne(action.payload.id, state);
    }

    case EduContentMetadatasActionTypes.DeleteEduContentMetadatas: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case EduContentMetadatasActionTypes.EduContentMetadatasLoaded: {
      return adapter.setAll(action.payload.eduContentMetadatas, {
        ...state,
        loaded: true,
      });
    }

    case EduContentMetadatasActionTypes.EduContentMetadatasLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case EduContentMetadatasActionTypes.ClearEduContentMetadatas: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
