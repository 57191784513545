import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { PersonPreferenceInterface } from '../+models';

export const PERSON_PREFERENCE_SERVICE_TOKEN = new InjectionToken('PersonPreferenceServiceInterface');

export interface PersonPreferenceServiceInterface {
  createPersonPreference(
    userId: number,
    personPreference: PersonPreferenceInterface
  ): Observable<PersonPreferenceInterface>;

  deletePersonPreference(personPreferenceId: number): Observable<boolean>;
  upsertPersonPreference(personPreference: PersonPreferenceInterface): Observable<PersonPreferenceInterface>;
  createTemplate(personPreference: PersonPreferenceInterface): Observable<PersonPreferenceInterface>;
}
