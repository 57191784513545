import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {
  AuthService,
  DalState,
  DataActions,
  LicenseInterface,
  LicenseQueries,
  PersonInterface,
  RolesEnum,
  UserQueries,
} from '@campus/dal';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMapTo, tap } from 'rxjs/operators';
import guardUtils from './guard-utils';

@Injectable()
export class LicenseGuard implements CanActivate {
  private currentUser$: Observable<PersonInterface>;
  private licensesLoaded$: Observable<boolean>;
  private activeLicenses$: Observable<LicenseInterface[]>;
  private isTeacher$: Observable<boolean>;

  constructor(private store: Store<DalState>, private router: Router, private authService: AuthService) {
    this.dispatchActions();
    this.initialiseInputStreams();
    this.intermediateStreams();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean> | boolean {
    return this.licensesLoaded$.pipe(
      filter((loaded) => !!loaded),
      switchMapTo(combineLatest([this.activeLicenses$, this.isTeacher$])),
      tap(([licenses, isTeacher]) => {
        if (isTeacher && !licenses.length) this.router.navigate(['/error/402']);
      }),
      map(([licenses, isTeacher]) => !isTeacher || !!licenses.length)
    );
  }

  private dispatchActions() {
    this.store.dispatch(
      new DataActions.LoadData({
        userId: this.authService.userId,
        fields: ['licenses'],
      })
    );
  }
  private initialiseInputStreams(): void {
    this.currentUser$ = this.store.pipe(select(UserQueries.getCurrentUser));
    this.licensesLoaded$ = this.store.pipe(select(LicenseQueries.getLoaded));
    this.activeLicenses$ = this.store.pipe(select(LicenseQueries.getActiveLicenses));
  }

  private intermediateStreams() {
    this.isTeacher$ = this.currentUser$.pipe(
      map((currentUser) => {
        if (!currentUser) return false;
        return guardUtils.containsRole(currentUser.roles, RolesEnum.Teacher);
      })
    );
  }
}
