import { Inject, Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { DalState, PersonServiceInterface, PERSON_SERVICE_TOKEN, UserQueries } from '@campus/dal';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UniquePublicKeyValidator implements AsyncValidator {
  constructor(
    @Inject(PERSON_SERVICE_TOKEN) private personService: PersonServiceInterface,
    private store: Store<DalState>
  ) {}

  validate(ctrl: AbstractControl): Observable<ValidationErrors | null> {
    if (!ctrl.value) return of(null);

    return this.store.pipe(
      delay(500),
      select(UserQueries.getCurrentUser),
      switchMap((user) => this.personService.checkUniquePublicKey(user.teacherInfo?.id || -1, ctrl.value)),
      map((isUnique) => (isUnique ? null : { notUniquePublicKey: true })),
      catchError(() => of({ serverError: true })),
      take(1)
    );
  }
}
