export class ResultFunctions {
  private static readonly scoreToStarsMapping: [number, number][] = [
    [100, 3],
    [75, 2],
    [50, 1],
    [0, 0],
  ];

  public static starsFromScore(score: number, total = 100, scoreToStarsMapping?: [number, number][]): number {
    if (!score || !total) return 0;

    // FYI scoreToStarsMapping is an optional provide
    // those are initialised to null when not provided, so default parameter values don't work
    scoreToStarsMapping ??= ResultFunctions.scoreToStarsMapping;

    const percentage = (score * 100) / total;

    const [, stars] = scoreToStarsMapping.find(([lowerBoundary]) => percentage >= lowerBoundary) ?? [0, 0];
    return stars;
  }

  public static getColorClass(score: number = null, total = 100, scoreToStarsMapping?: [number, number][]): string {
    if (score === null) return;

    scoreToStarsMapping ??= ResultFunctions.scoreToStarsMapping;

    const result = this.starsFromScore(score, total, scoreToStarsMapping);
    switch (result) {
      case 1:
        return 'score--passed';
      case 2:
        return 'score--good';
      case 3:
        return 'score--excellent';
      default:
        return 'score--failed';
    }
  }

  public static getStatus(score: number = null, total = 100, scoreToStarsMapping?: [number, number][]): string {
    if (score === null) return;

    scoreToStarsMapping ??= ResultFunctions.scoreToStarsMapping;

    const result = this.starsFromScore(score, total, scoreToStarsMapping);
    switch (result) {
      case 1:
        return 'passed';
      case 2:
        return 'good';
      case 3:
        return 'excellent';
      default:
        return 'failed';
    }
  }
}
