import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { SectionContentInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum SectionContentsActionTypes {
  SectionContentsLoaded = '[SectionContents] SectionContents Loaded',
  SectionContentsLoadError = '[SectionContents] Load Error',
  LoadSectionContents = '[SectionContents] Load SectionContents',
  AddSectionContent = '[SectionContents] Add SectionContent',
  AddSectionContentsForBook = '[SectionContents] Add SectionContents for Book',
  AddSectionContentsForToc = '[SectionContents] Add SectionContents for Toc',
  UpsertSectionContent = '[SectionContents] Upsert SectionContent',
  AddSectionContents = '[SectionContents] Add SectionContents',
  UpsertSectionContents = '[SectionContents] Upsert SectionContents',
  UpdateSectionContent = '[SectionContents] Update SectionContent',
  UpdateSectionContents = '[SectionContents] Update SectionContents',
  DeleteSectionContent = '[SectionContents] Delete SectionContent',
  DeleteSectionContents = '[SectionContents] Delete SectionContents',
  ClearSectionContents = '[SectionContents] Clear SectionContents',
  StartAddSectionContent = '[Sections] Start Add SectionContent',
}

export class LoadSectionContents implements Action {
  readonly type = SectionContentsActionTypes.LoadSectionContents;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class SectionContentsLoaded implements Action {
  readonly type = SectionContentsActionTypes.SectionContentsLoaded;

  constructor(public payload: { sectionContents: SectionContentInterface[] }) {}
}

export class SectionContentsLoadError implements Action {
  readonly type = SectionContentsActionTypes.SectionContentsLoadError;
  constructor(public payload: any) {}
}

export class AddSectionContent implements Action {
  readonly type = SectionContentsActionTypes.AddSectionContent;

  constructor(public payload: { sectionContent: SectionContentInterface }) {}
}

export class AddSectionContentsForBook implements Action {
  readonly type = SectionContentsActionTypes.AddSectionContentsForBook;

  constructor(public payload: { bookId: number; sectionContents: SectionContentInterface[] }) {}
}

export class AddSectionContentsForToc implements Action {
  readonly type = SectionContentsActionTypes.AddSectionContentsForToc;

  constructor(public payload: { tocId: number; sectionContents: SectionContentInterface[] }) {}
}

export class UpsertSectionContent implements Action {
  readonly type = SectionContentsActionTypes.UpsertSectionContent;

  constructor(public payload: { sectionContent: SectionContentInterface }) {}
}

export class AddSectionContents implements Action {
  readonly type = SectionContentsActionTypes.AddSectionContents;

  constructor(public payload: { sectionContents: SectionContentInterface[] }) {}
}

export class UpsertSectionContents implements Action {
  readonly type = SectionContentsActionTypes.UpsertSectionContents;

  constructor(public payload: { sectionContents: SectionContentInterface[] }) {}
}

export class UpdateSectionContent implements Action {
  readonly type = SectionContentsActionTypes.UpdateSectionContent;

  constructor(public payload: { sectionContent: Update<SectionContentInterface> }) {}
}

export class UpdateSectionContents implements Action {
  readonly type = SectionContentsActionTypes.UpdateSectionContents;

  constructor(public payload: { sectionContents: Update<SectionContentInterface>[] }) {}
}

export class DeleteSectionContent implements FeedbackTriggeringAction {
  readonly type = SectionContentsActionTypes.DeleteSectionContent;

  constructor(public payload: { id: number; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export class DeleteSectionContents implements Action {
  readonly type = SectionContentsActionTypes.DeleteSectionContents;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearSectionContents implements Action {
  readonly type = SectionContentsActionTypes.ClearSectionContents;
}

export class StartAddSectionContent implements FeedbackTriggeringAction {
  readonly type = SectionContentsActionTypes.StartAddSectionContent;

  constructor(
    public payload: {
      sectionContent: SectionContentInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export type SectionContentsActions =
  | LoadSectionContents
  | SectionContentsLoaded
  | SectionContentsLoadError
  | AddSectionContent
  | UpsertSectionContent
  | AddSectionContents
  | AddSectionContentsForBook
  | AddSectionContentsForToc
  | UpsertSectionContents
  | UpdateSectionContent
  | UpdateSectionContents
  | DeleteSectionContent
  | DeleteSectionContents
  | ClearSectionContents
  | StartAddSectionContent;
