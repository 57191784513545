<ng-container *ngIf="cappedChips?.length; else showCTA">
  <span class="[ label-small ]" *ngIf="infoLabel">{{ infoLabel }}</span>

  <campus-chip-group>
    <campus-suggestion-chip
      *ngFor="let chip of cappedChips"
      data-cy="tli-assignee-chip"
      [class.surface-variant]="chip.type === '--overflow'"
      [class.border-dashed]="chip.className === '--dashed'"
      [label]="chip.label"
    >
      <campus-icon *ngIf="chip.icon" chip-icon [svgIcon]="chip.icon"></campus-icon>
    </campus-suggestion-chip>
  </campus-chip-group>
</ng-container>

<ng-template #showCTA>
  <button filled-button *ngIf="ctaLabel" [leadingIcon]="ctaIcon">
    {{ ctaLabel }}
  </button>
</ng-template>
