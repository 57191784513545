import { Injectable } from '@angular/core';
import { notImplementedError } from '../shared/not-implemented.error';

export interface SDKToken {
  userId: string;
}

@Injectable()
export class ApiAuth {
  protected prefix = '';

  private currentUserId: string;

  public setUser(user: { id: string }) {
    this.currentUserId = user ? user.id : null;
  }

  public setToken(token: SDKToken): void {
    this.currentUserId = token.userId;
    // Leave void
    // Frontend doesn't take care of the authentication cookies
    // Api does
  }

  public getCurrentUserId(): any {
    return this.currentUserId;
  }

  public clear(): void {
    // Leave void
    // Frontend doesn't take care of the authentication cookies
    // Api does
  }

  public getAccessTokenId(): string {
    // FYI this function is called in the SDK
    return '';
  }

  //#region Not implemented
  public setRememberMe(value: boolean): void {
    throw notImplementedError;
  }

  public getToken(): SDKToken {
    throw notImplementedError;
  }

  public getCurrentUserData(): any {
    throw notImplementedError;
  }

  public save(): boolean {
    throw notImplementedError;
  }

  protected load(prop: string): any {
    throw notImplementedError;
  }

  protected persist(prop: string, value: any, expires?: Date): void {
    throw notImplementedError;
  }
  //#endregion
}
