import { trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Permissions } from '@campus/dal';
import { NotificationItemInterface, slideInFromTopAnimation } from '@campus/ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertToNotificationItemPipe } from '../../pipes/alert-to-notification/alert-to-notification-pipe';
import { QuickLinkTypeEnum } from '../quick-link/quick-link-type.enum';
import { HeaderViewModel } from './header.viewmodel';

@Component({
  selector: 'campus-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [trigger('slideIn', slideInFromTopAnimation)],
})
export class HeaderComponent implements OnInit {
  Permissions = { ...Permissions.settings, ...Permissions.cms };
  QuickLinkTypeEnum = QuickLinkTypeEnum;
  activeHistoryPanel = -1;
  activeFavoritesPanel = -1;

  enableAlerts: boolean;
  enableGlobalSearch: boolean;
  enableTours: boolean;
  showTours$: Observable<boolean>;

  @Input()
  breadcrumbPosition: 'top-left' | 'page-left';
  @Input()
  profileMenuPosition: 'top-right';
  @Input()
  currentBookPosition: 'top-right';
  @Input()
  profileMenuWithUserBadge = true;
  @Input()
  quicklinkPosition: 'page-right' | 'top-right';
  @Input()
  alertsPosition: 'top-right';

  profileMenuItems$ = this.headerViewModel.profileMenuItems$;
  alertsLoaded$ = this.headerViewModel.alertsLoaded$;
  alertNotifications$;
  unreadAlertCount$ = this.headerViewModel.unreadAlertCount$;
  currentUser$ = this.headerViewModel.currentUser$;
  favoriteBooks$ = this.headerViewModel.favoriteBooks$;
  selectedBook$ = this.headerViewModel.selectedBook$;
  globalBookDisabled$ = this.headerViewModel.globalBookDisabled$;

  constructor(
    private headerViewModel: HeaderViewModel,
    private router: Router,
    private alertToNotif: AlertToNotificationItemPipe
  ) {
    this.alertNotifications$ = this.headerViewModel.alertNotifications$.pipe(
      map((a) => a.map((value) => this.alertToNotif.transform(value)))
    );
  }

  ngOnInit(): void {
    this.loadFeatureToggles();
    this.loadPublicStreams();
  }

  private loadFeatureToggles() {
    this.enableAlerts = this.headerViewModel.enableAlerts;
    this.enableGlobalSearch = this.headerViewModel.enableGlobalSearch;
    this.enableTours = this.headerViewModel.enableTours;
  }
  private loadPublicStreams() {
    this.showTours$ = this.headerViewModel.showTours$;
  }

  onMenuClick() {
    this.headerViewModel.toggleSideNav();
  }

  onManageFavoritesClick(): void {
    this.activeHistoryPanel = -1;
    this.activeFavoritesPanel = 0;
  }

  onManageHistoryClick(): void {
    this.activeFavoritesPanel = -1;
    this.activeHistoryPanel = 0;
  }

  selectBook(bookId: number): void {
    this.headerViewModel.setSelectedBookId(bookId);
  }

  setAlertAsRead(alert: NotificationItemInterface) {
    if (!alert.read && alert.accented) {
      this.headerViewModel.setAlertAsRead(alert.id);
    }
  }

  navigateToPreferences() {
    this.router.navigate(['/preferences']);
  }

  trackById(index: number, alert: NotificationItemInterface): number {
    return alert.id;
  }
}
