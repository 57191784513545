import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { PersonApi, UnlockedContentApi } from '../+api';
import { UnlockedContentInterface } from '../+models';
import {
  UnlockedContentServiceInterface,
  UpdateUnlockedContentResultInterface,
} from './unlocked-content.service.interface';

@Injectable({
  providedIn: 'root',
})
export class UnlockedContentService implements UnlockedContentServiceInterface {
  getAllForUser(userId: number): Observable<UnlockedContentInterface[]> {
    return this.personApi
      .getData(userId, 'unlockedContents')
      .pipe(map((res: { unlockedContents: UnlockedContentInterface[] }) => res.unlockedContents));
  }

  remove(unlockedContentId: number): Observable<boolean> {
    return this.unlockedContentApi.deleteById(unlockedContentId).pipe(mapTo(true));
  }

  removeAll(unlockedContentIds: number[]): Observable<boolean> {
    return forkJoin(unlockedContentIds.map((id) => this.remove(id))).pipe(mapTo(true));
  }

  createUnlockedContents(
    userId: number,
    unlockedContents: Partial<UnlockedContentInterface>[]
  ): Observable<UpdateUnlockedContentResultInterface> {
    return this.unlockedContentApi.createUnlockedContents(
      unlockedContents
    ) as Observable<UpdateUnlockedContentResultInterface>;
  }

  updateUnlockedContents(
    userId: number,
    update: Partial<UnlockedContentInterface>[]
  ): Observable<UpdateUnlockedContentResultInterface> {
    return this.unlockedContentApi.updateUnlockedContents(update) as Observable<UpdateUnlockedContentResultInterface>;
  }

  constructor(private personApi: PersonApi, private unlockedContentApi: UnlockedContentApi) {}
}
