import { ArrayFunctions } from '@campus/utils';
import { createSelector } from '@ngrx/store';
import { getAll as getAllClassGroups } from '../class-group/class-group.selectors';

/**
 * Retrieves the current logged in student's years from his/her class groups.
 *
 * @returns An array of years.
 */
export const getStudentsYears = createSelector(getAllClassGroups, (classgroups) => {
  const years = ArrayFunctions.filterDuplicates(
    ArrayFunctions.flatten(classgroups.map((classgroup) => classgroup.years))
  );
  return years;
});
