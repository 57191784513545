import { InjectionToken } from '@angular/core';
import { ObjectFunctions } from '@campus/utils';
import { AlgebrakitSessionInterface } from '../../../+models';
import { DataResponseInterface } from '../../../data/data.service.interface';

type DataResponseMappingFunction<T extends DataResponseInterface> = (field: string, dataResponse: T) => T[keyof T];

export interface FieldMappersInterface {
  default: DataResponseMappingFunction<any>;
  [key: string]: DataResponseMappingFunction<any>;
}

export function formatData<T extends DataResponseInterface>(field: keyof T, data: T, fieldMappers): T[keyof T] {
  // apply any needed mappings first, like date conversions
  // the default mapping will just grab the relevant property without any extras
  const fieldMapper: DataResponseMappingFunction<T> = fieldMappers[field] || fieldMappers.default;
  const formattedData = fieldMapper(field.toString(), data);

  return formattedData;
}

export const DATA_FIELD_MAPPERS_TOKEN = new InjectionToken<FieldMappersInterface>('FieldMappersInterface');

export const defaultFieldMappers: FieldMappersInterface = {
  default: defaultMapper,
  allResults: castAs(Date)(['created', 'lastUpdated']),
  tasks: castAs(Date)(['archivedStart', 'archivedEnd']),
  taskStudents: castAs(Date)(['start', 'end']),
  taskGroups: castAs(Date)(['start', 'end']),
  taskClassGroups: castAs(Date)(['start', 'end']),
  taskInstances: castAs(Date)(['start', 'end']),
  bundles: castAs(Date)(['start', 'end', 'lastUpdated']),
  history: castAs(Date)(['created']),
  methodLevels: addIcons,
  atlasSchools: castAs(Date)(['lastUpdated']),
  atlasProducts: castAs(Date)(['lastUpdated']),
  purchases: castAs(Date)(['created', 'lastUpdated']),
  unlockedCurriculumTrees: castAs(Date)(['openedDate', 'lastUpdated']),
  persons: castAs(Date)(['created', 'lastLogin']),
  licenses: castAs(Date)(['activated', 'expired']),
  algebrakitSessions: normalizeAKitSessions,
  unlockedFreePractices: castAs(Date)(['lastUpdated']),
  sections: parseJSON()(['config']),
  personPreferences: parseJSON(true)(['value']),
  eduContentTocs: normalizeAndDecode()(['title'], ['title']),
  news: castAs(Date)(['publishDate', 'unpublishDate']),
};

function defaultMapper(field: string, dataResponse: DataResponseInterface) {
  return dataResponse[field];
}

function addIcons(field: string, dataResponse: DataResponseInterface) {
  return dataResponse[field].map((methodLevel) => ({
    ...methodLevel,
    icon: methodLevel.icon || `method-${methodLevel.methodId}-level-${methodLevel.levelId}`,
  }));
}

function normalizeAKitSessions(field: string, dataResponse: DataResponseInterface) {
  return dataResponse[field].map((session: AlgebrakitSessionInterface) => ({
    ...session,
    lastUpdated: new Date(session.lastUpdated),
    // Akit sessions are store with 0-based level (cfr specs of Akit API)
    // For frontend we show 1-based levels
    level: session.level + 1,
  }));
}

function castAs(castType) {
  return (props: string[]) => (field: string, dataResponse: DataResponseInterface) => {
    const castProps = ObjectFunctions.castAs(castType)(props);
    return Array.isArray(dataResponse[field]) ? dataResponse[field].map(castProps) : castProps(dataResponse[field]);
  };
}

function normalizeAndDecode() {
  return (propsForNormalize: string[], propsForDecode: string[]) =>
    (field: string, dataResponse: DataResponseInterface) => {
      const normalizedEduContentTocs = normalizeNumbers()(propsForNormalize)(field, dataResponse);
      const decodedEduContentTocs = decodeHtmlEntities()(propsForDecode)(field, { [field]: normalizedEduContentTocs });
      return decodedEduContentTocs;
    };
}

/**
 * Helper function to normalize numbers in a string
 * It replaces spaces between numbers with non-breaking spaces
 * eg 1 000 000 -> 1&nbsp;000&nbsp;000
 */
function normalizeNumbers() {
  return (props: string[]) => (field: string, dataResponse: DataResponseInterface) => {
    const items = dataResponse[field].map((item) => {
      props.forEach((prop) => {
        const pattern = new RegExp(/(\d) +(\d)/g);
        const text = item[prop];
        const nbsp = '$1' + String.fromCharCode(160) + '$2'; // non-breaking space
        const result: string = text.replace(pattern, nbsp);
        item[prop] = result;
      });
      return item;
    });
    return items;
  };
}

function decodeHtmlEntities() {
  return (props: string[]) => (field: string, dataResponse: DataResponseInterface) => {
    const parser = new DOMParser();
    return dataResponse[field].map((item) => {
      props.forEach((prop) => {
        const doc = parser.parseFromString(item[prop], 'text/html');
        item[prop] = doc.documentElement.textContent;
      });
      return item;
    });
  };
}

function parseJSON(defaultToOriginalValue: boolean = false) {
  return (props: string[]) => (field: string, dataResponse: DataResponseInterface) => {
    if (Array.isArray(dataResponse[field])) {
      return dataResponse[field].map((fieldItem) => {
        return parseFieldItemWithProps(fieldItem, props, defaultToOriginalValue);
      });
    } else {
      return parseFieldItemWithProps(dataResponse[field], props, defaultToOriginalValue);
    }
  };
}

function parseFieldItemWithProps(fieldItem, props, defaultToOriginalValue) {
  props.forEach((prop) => {
    try {
      fieldItem[prop] = fieldItem[prop] ? JSON.parse(fieldItem[prop]) : {};
    } catch (e) {
      fieldItem[prop] = defaultToOriginalValue ? fieldItem[prop] : {};
    }
  });

  return fieldItem;
}
