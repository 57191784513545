import { inject, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import {
  DalState,
  Permissions,
  PersonTermsService,
  PersonTermsServiceInterface,
  TermsDTOInterface,
  UserActions,
  UserQueries,
} from '@campus/dal';
import { PermissionService } from '@campus/shared';
import { TermsModalComponent, TermsModalDataInterface } from '@campus/ui';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class TermsPrivacyPopupGuard implements CanActivate {
  private permissionService: PermissionService = inject(PermissionService);
  private store: Store<DalState> = inject(Store);
  private matDialog = inject(MatDialog);
  private personTermsService: PersonTermsServiceInterface = inject(PersonTermsService);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.store.dispatch(new UserActions.LoadUser({}));

    return this.store.pipe(
      select(UserQueries.getPermissionsLoaded),
      filter((loaded) => !!loaded),
      switchMap(() => this.permissionService.hasPermission$(Permissions.settings.SHOW_PRIVACY_SETTINGS)),
      switchMap((hasShowPrivacySettingsPermissions) => {
        if (!hasShowPrivacySettingsPermissions) return of(true);

        return this.setupTermsSummaryStream$();
      })
    );
  }

  private setupTermsSummaryStream$() {
    return this.personTermsService.getSummary().pipe(
      tap((terms: TermsDTOInterface[]) => {
        const popupApprovalTerms = terms.filter((term) => term.shouldAccept);
        this.openTermsModalSequentially(popupApprovalTerms);
      }),
      map(() => true)
    );
  }

  private openTermsModalSequentially(terms: TermsDTOInterface[], index: number = 0) {
    if (index >= terms.length) return;

    const term = terms[index];
    const showSummary = term.shouldAccept === 'summary';
    const dialogData: TermsModalDataInterface = {
      id: term.termsId,
      title: showSummary ? term.title : term.changelogTitle,
      message: showSummary ? term.summary : term.changelog,
      icon: 'privacy',
    };

    this.openTermsModal(dialogData, () => {
      this.saveUpsertPersonTerms(term.termsId, true);
      this.openTermsModalSequentially(terms, index + 1);
    });
  }

  private openTermsModal(data: TermsModalDataInterface, confirmAction: () => void) {
    if (this.matDialog.openDialogs.length > 0 && this.isTermsDialogModalAlreadyOpen(data.id)) return;

    const dialogRef = this.matDialog.open(TermsModalComponent, {
      id: `terms-modal-${data.id}`,
      disableClose: true,
      data,
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) return confirmAction();
    });
  }

  private saveUpsertPersonTerms(termsId: number, accepted: boolean): void {
    this.personTermsService.upsertPersonTerms(termsId, accepted).pipe(take(1)).subscribe();
  }

  private isTermsDialogModalAlreadyOpen(termsId: number): boolean {
    return !!this.matDialog.openDialogs.find((dialog) => dialog.id === `terms-modal-${termsId}`);
  }
}
