import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CurriculumTreeInterface } from '../../+models';
import { CurriculumTreesActions, CurriculumTreesActionTypes } from './curriculum-tree.actions';

export const NAME = 'curriculumTrees';

export interface State extends EntityState<CurriculumTreeInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<CurriculumTreeInterface> = createEntityAdapter<CurriculumTreeInterface>({
  selectId: (curriculumTree: CurriculumTreeInterface) => curriculumTree.guid,
  sortComparer: (a, b) => byDisplayOrder(a, b) || byName(a, b) || byGuid(a, b),
});

function byDisplayOrder(a: CurriculumTreeInterface, b: CurriculumTreeInterface) {
  if (!a.displayOrder && !b.displayOrder) return 0;
  if (!a.displayOrder) return 1;
  if (!b.displayOrder) return -1;

  return a.displayOrder - b.displayOrder;
}

function byName(a: CurriculumTreeInterface, b: CurriculumTreeInterface) {
  return a.name.localeCompare(b.name);
}

function byGuid(a: CurriculumTreeInterface, b: CurriculumTreeInterface) {
  return a.guid.localeCompare(b.guid);
}

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: CurriculumTreesActions): State {
  switch (action.type) {
    case CurriculumTreesActionTypes.AddCurriculumTree: {
      return adapter.addOne(action.payload.curriculumTree, state);
    }

    case CurriculumTreesActionTypes.UpsertCurriculumTree: {
      return adapter.upsertOne(action.payload.curriculumTree, state);
    }

    case CurriculumTreesActionTypes.AddCurriculumTrees: {
      return adapter.addMany(action.payload.curriculumTrees, state);
    }

    case CurriculumTreesActionTypes.UpsertCurriculumTrees: {
      return adapter.upsertMany(action.payload.curriculumTrees, state);
    }

    case CurriculumTreesActionTypes.UpdateCurriculumTree: {
      return adapter.updateOne(action.payload.curriculumTree, state);
    }

    case CurriculumTreesActionTypes.UpdateCurriculumTrees: {
      return adapter.updateMany(action.payload.curriculumTrees, state);
    }

    case CurriculumTreesActionTypes.DeleteCurriculumTree: {
      return adapter.removeOne(action.payload.id, state);
    }

    case CurriculumTreesActionTypes.DeleteCurriculumTrees: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case CurriculumTreesActionTypes.CurriculumTreesLoaded: {
      return adapter.setAll(action.payload.curriculumTrees, {
        ...state,
        loaded: true,
      });
    }

    case CurriculumTreesActionTypes.CurriculumTreesLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case CurriculumTreesActionTypes.ClearCurriculumTrees: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
