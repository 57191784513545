import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch, pessimisticUpdate } from '@nrwl/angular';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UserLessonQueries } from '.';
import { UserLessonInterface } from '../../+models';
import { UserLessonServiceInterface, USER_LESSON_SERVICE_TOKEN } from '../../user-lesson/user-lesson.service.interface';
import { DalState } from '../dal.state.interface';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { StartAddManyLearningPlanGoalProgresses } from '../learning-plan-goal-progress/learning-plan-goal-progress.actions';
import {
  AddUserLesson,
  CreateUserLesson,
  CreateUserLessonWithLearningPlanGoalProgresses,
  LoadUserLessons,
  UserLessonsActionTypes,
  UserLessonsLoaded,
  UserLessonsLoadError,
} from './user-lesson.actions';

@Injectable()
export class UserLessonEffects {
  loadUserLessons$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserLessonsActionTypes.LoadUserLessons),
      concatLatestFrom(() => this.store.select(UserLessonQueries.getLoaded)),
      fetch({
        run: (action: LoadUserLessons, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.userLessonService
            .getAllForUser(action.payload.userId)
            .pipe(map((userLessons) => new UserLessonsLoaded({ userLessons })));
        },
        onError: (_action: LoadUserLessons, error) => {
          return new UserLessonsLoadError(error);
        },
      })
    )
  );

  createUserLesson$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserLessonsActionTypes.CreateUserLesson),
      pessimisticUpdate({
        run: (action: CreateUserLesson) => {
          return this.userLessonService.createForUser(action.payload.userId, action.payload.userLesson).pipe(
            switchMap((userLesson: UserLessonInterface) => {
              const actions: (AddEffectFeedback | AddUserLesson)[] = [new AddUserLesson({ userLesson })];

              const effectFeedback = EffectFeedback.generateSuccessFeedback(
                this.uuid(),
                action,
                `Les "${action.payload.userLesson.description}" toegevoegd.`
              );
              actions.push(new AddEffectFeedback({ effectFeedback }));
              return from(actions);
            })
          );
        },
        onError: (action: CreateUserLesson, _error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              `Het is niet gelukt om les "${action.payload.userLesson.description}" toe te voegen.`
            ),
          });
        },
      })
    )
  );

  createUserLessonWithLearningPlanGoalProgresses$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserLessonsActionTypes.CreateUserLessonWithLearningPlanGoalProgresses),
      pessimisticUpdate({
        run: (action: CreateUserLessonWithLearningPlanGoalProgresses) => {
          return this.userLessonService.createForUser(action.payload.userId, action.payload.userLesson).pipe(
            switchMap((userLesson: UserLessonInterface) => {
              const actions: (AddEffectFeedback | AddUserLesson | StartAddManyLearningPlanGoalProgresses)[] = [
                new AddUserLesson({ userLesson }),
                new StartAddManyLearningPlanGoalProgresses({
                  personId: action.payload.userId,
                  learningPlanGoalProgresses: action.payload.learningPlanGoalProgresses.map(
                    (learningPlanGoalprogress) => ({
                      ...learningPlanGoalprogress,
                      userLessonId: userLesson.id,
                    })
                  ),
                }),
              ];

              const effectFeedback = EffectFeedback.generateSuccessFeedback(
                this.uuid(),
                action,
                `Les "${action.payload.userLesson.description}" toegevoegd.`
              );
              actions.push(new AddEffectFeedback({ effectFeedback }));
              return from(actions);
            })
          );
        },
        onError: (action: CreateUserLessonWithLearningPlanGoalProgresses, _error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              `Het is niet gelukt om les "${action.payload.userLesson.description}" toe te voegen.`
            ),
          });
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(USER_LESSON_SERVICE_TOKEN)
    private userLessonService: UserLessonServiceInterface,
    @Inject('uuid') private uuid: () => string
  ) {}
}
