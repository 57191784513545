import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { fetch, optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { GroupQueries } from '.';
import { DalActions, DalState } from '..';
import { GroupInterface } from '../../+models';
import { GroupServiceInterface, GROUP_SERVICE_TOKEN } from '../../group/group.service.interface';
import { EffectFeedback, EffectFeedbackActions } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { TaskGroupActions } from '../task-group';
import {
  AddGroup,
  DeleteGroup,
  GroupsActionTypes,
  GroupsLoaded,
  GroupsLoadError,
  LoadGroups,
  NavigateToGroupDetail,
  StartAddGroup,
  UpdateGroup,
} from './group.actions';

@Injectable()
export class GroupEffects {
  loadGroups$ = createEffect(() =>
    this.actions.pipe(
      ofType(GroupsActionTypes.LoadGroups),
      concatLatestFrom(() => this.store.select(GroupQueries.getLoaded)),
      fetch({
        run: (action: LoadGroups, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.groupService
            .getAllForUser(action.payload.userId)
            .pipe(map((groups) => new GroupsLoaded({ groups })));
        },
        onError: (action: LoadGroups, error) => {
          return new GroupsLoadError(error);
        },
      })
    )
  );

  startAddGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(GroupsActionTypes.StartAddGroup),
      pessimisticUpdate({
        run: (action: StartAddGroup) => {
          return this.groupService.addGroup(action.payload.group).pipe(
            switchMap((group: GroupInterface) => {
              const actionsToDispatch: Action[] = [new AddGroup({ group })];

              if (action.payload.navigateAfterCreate) {
                actionsToDispatch.push(new NavigateToGroupDetail({ group }));
              }

              return from(actionsToDispatch);
            })
          );
        },
        onError: (action: StartAddGroup, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de groep aan te maken.'
            ),
          });
        },
      })
    )
  );

  updateGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(GroupsActionTypes.UpdateGroup),
      optimisticUpdate({
        run: (action: UpdateGroup) => {
          return this.groupService.updateGroup(+action.payload.group.id, action.payload.group.changes).pipe(
            map(() => {
              const effectFeedback = EffectFeedback.generateSuccessFeedback(
                this.uuid(),
                action,
                'De groep werd aangepast.'
              );
              return new EffectFeedbackActions.AddEffectFeedback({
                effectFeedback,
              });
            })
          );
        },
        undoAction: (action: UpdateGroup, error) => {
          const undoAction = undo(action);
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            'Het is niet gelukt om de groep aan te passen.'
          );
          const effectFeedbackAction = new EffectFeedbackActions.AddEffectFeedback({ effectFeedback });
          return from([undoAction, effectFeedbackAction]);
        },
      })
    )
  );

  deleteGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(GroupsActionTypes.DeleteGroup),
      optimisticUpdate({
        run: (action: DeleteGroup) => {
          return this.groupService.deleteGroup(action.payload.id).pipe(
            switchMap((_) => {
              return [
                TaskGroupActions.deleteTaskGroupFromGroup({ groupId: action.payload.id }),
                new DalActions.ActionSuccessful({
                  successfulAction: '[Group] deleted',
                }),
              ];
            })
          );
        },
        undoAction: (action: DeleteGroup, error) => {
          const undoAction = undo(action);
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            'Het is niet gelukt om de groep te verwijderen.'
          );
          const effectFeedbackAction = new EffectFeedbackActions.AddEffectFeedback({ effectFeedback });
          return from([undoAction, effectFeedbackAction]);
        },
      })
    )
  );

  redirectToGroup$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(GroupsActionTypes.NavigateToGroupDetail),
        tap((action: NavigateToGroupDetail) => {
          this.router.navigate([], {
            queryParams: { group: action.payload.group.id },
            queryParamsHandling: 'merge',
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions: Actions,
    @Inject('uuid') private uuid: () => string,
    private store: Store<DalState>,
    private router: Router,
    @Inject(GROUP_SERVICE_TOKEN)
    private groupService: GroupServiceInterface
  ) {}
}
