import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { NewsProductInterface } from '../../+models';
import { NewsProductsActions, NewsProductsActionTypes } from './news-product.actions';

export const NAME = 'newsProducts';

export interface State extends EntityState<NewsProductInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<NewsProductInterface> = createEntityAdapter<NewsProductInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: NewsProductsActions): State {
  switch (action.type) {
    case NewsProductsActionTypes.NewsProductsLoaded: {
      return adapter.setAll(action.payload.newsProducts, {
        ...state,
        loaded: true,
      });
    }

    case NewsProductsActionTypes.NewsProductsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case NewsProductsActionTypes.AddNewsProducts: {
      return adapter.addMany(action.payload.newsProducts, state);
    }

    case NewsProductsActionTypes.SetNewsProductsForNews: {
      const { newsId, newsProducts } = action.payload;

      const toRemove = (state.ids as number[]).filter((id) => {
        const entity = state.entities[id];
        return entity.newsId === newsId;
      });

      const clearedState = adapter.removeMany(toRemove, state);
      const newState = adapter.addMany(newsProducts, clearedState);

      return newState;
    }

    case NewsProductsActionTypes.RemoveNewsProductsForNews: {
      const { newsId } = action.payload;

      const toRemove = (state.ids as number[]).filter((id) => {
        const entity = state.entities[id];
        return entity.newsId === newsId;
      });
      return adapter.removeMany(toRemove, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
