import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { UnlockedCurriculumTreeInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum UnlockedCurriculumTreesActionTypes {
  UnlockedCurriculumTreesLoaded = '[UnlockedCurriculumTrees] UnlockedCurriculumTrees Loaded',
  UnlockedCurriculumTreesLoadError = '[UnlockedCurriculumTrees] Load Error',
  LoadUnlockedCurriculumTrees = '[UnlockedCurriculumTrees] Load UnlockedCurriculumTrees',
  AddUnlockedCurriculumTree = '[UnlockedCurriculumTrees] Add UnlockedCurriculumTree',
  StartAddManyUnlockedCurriculumTrees = '[UnlockedCurriculumTrees] Start Add Many UnlockedCurriculumTrees',
  UpsertUnlockedCurriculumTree = '[UnlockedCurriculumTrees] Upsert UnlockedCurriculumTree',
  AddUnlockedCurriculumTrees = '[UnlockedCurriculumTrees] Add UnlockedCurriculumTrees',
  UpsertUnlockedCurriculumTrees = '[UnlockedCurriculumTrees] Upsert UnlockedCurriculumTrees',
  UpdateUnlockedCurriculumTree = '[UnlockedCurriculumTrees] Update UnlockedCurriculumTree',
  UpdateUnlockedCurriculumTrees = '[UnlockedCurriculumTrees] Update UnlockedCurriculumTrees',
  DeleteUnlockedCurriculumTree = '[UnlockedCurriculumTrees] Delete UnlockedCurriculumTree',
  DeleteUnlockedCurriculumTrees = '[UnlockedCurriculumTrees] Delete UnlockedCurriculumTrees',
  ClearUnlockedCurriculumTrees = '[UnlockedCurriculumTrees] Clear UnlockedCurriculumTrees',
}

export class LoadUnlockedCurriculumTrees implements Action {
  readonly type = UnlockedCurriculumTreesActionTypes.LoadUnlockedCurriculumTrees;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class UnlockedCurriculumTreesLoaded implements Action {
  readonly type = UnlockedCurriculumTreesActionTypes.UnlockedCurriculumTreesLoaded;

  constructor(
    public payload: {
      unlockedCurriculumTrees: UnlockedCurriculumTreeInterface[];
    }
  ) {}
}

export class UnlockedCurriculumTreesLoadError implements Action {
  readonly type = UnlockedCurriculumTreesActionTypes.UnlockedCurriculumTreesLoadError;
  constructor(public payload: any) {}
}

export class AddUnlockedCurriculumTree implements Action {
  readonly type = UnlockedCurriculumTreesActionTypes.AddUnlockedCurriculumTree;

  constructor(public payload: { unlockedCurriculumTree: UnlockedCurriculumTreeInterface }) {}
}

export class UpsertUnlockedCurriculumTree implements Action {
  readonly type = UnlockedCurriculumTreesActionTypes.UpsertUnlockedCurriculumTree;

  constructor(public payload: { unlockedCurriculumTree: UnlockedCurriculumTreeInterface }) {}
}

export class AddUnlockedCurriculumTrees implements Action {
  readonly type = UnlockedCurriculumTreesActionTypes.AddUnlockedCurriculumTrees;

  constructor(
    public payload: {
      unlockedCurriculumTrees: UnlockedCurriculumTreeInterface[];
    }
  ) {}
}

export class UpsertUnlockedCurriculumTrees implements Action {
  readonly type = UnlockedCurriculumTreesActionTypes.UpsertUnlockedCurriculumTrees;

  constructor(
    public payload: {
      unlockedCurriculumTrees: UnlockedCurriculumTreeInterface[];
    }
  ) {}
}

export class UpdateUnlockedCurriculumTree implements Action {
  readonly type = UnlockedCurriculumTreesActionTypes.UpdateUnlockedCurriculumTree;

  constructor(
    public payload: {
      unlockedCurriculumTree: Update<UnlockedCurriculumTreeInterface>;
    }
  ) {}
}

export class UpdateUnlockedCurriculumTrees implements Action {
  readonly type = UnlockedCurriculumTreesActionTypes.UpdateUnlockedCurriculumTrees;

  constructor(
    public payload: {
      unlockedCurriculumTrees: Update<UnlockedCurriculumTreeInterface>[];
    }
  ) {}
}

export class DeleteUnlockedCurriculumTree implements Action {
  readonly type = UnlockedCurriculumTreesActionTypes.DeleteUnlockedCurriculumTree;

  constructor(public payload: { id: number }) {}
}

export class DeleteUnlockedCurriculumTrees implements FeedbackTriggeringAction {
  readonly type = UnlockedCurriculumTreesActionTypes.DeleteUnlockedCurriculumTrees;

  constructor(
    public payload: {
      userId: number;
      ids: number[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class ClearUnlockedCurriculumTrees implements Action {
  readonly type = UnlockedCurriculumTreesActionTypes.ClearUnlockedCurriculumTrees;
}

export class StartAddManyUnlockedCurriculumTrees implements FeedbackTriggeringAction {
  readonly type = UnlockedCurriculumTreesActionTypes.StartAddManyUnlockedCurriculumTrees;

  constructor(
    public payload: {
      userId: number;
      unlockedCurriculumTrees: UnlockedCurriculumTreeInterface[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export type UnlockedCurriculumTreesActions =
  | LoadUnlockedCurriculumTrees
  | UnlockedCurriculumTreesLoaded
  | UnlockedCurriculumTreesLoadError
  | AddUnlockedCurriculumTree
  | StartAddManyUnlockedCurriculumTrees
  | UpsertUnlockedCurriculumTree
  | AddUnlockedCurriculumTrees
  | UpsertUnlockedCurriculumTrees
  | UpdateUnlockedCurriculumTree
  | UpdateUnlockedCurriculumTrees
  | DeleteUnlockedCurriculumTree
  | DeleteUnlockedCurriculumTrees
  | ClearUnlockedCurriculumTrees;
