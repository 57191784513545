/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { BundleApi as SDKBundleApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { BundleInterface, UnlockedContentInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class BundleApi extends ModelApiBase {
  private SDKBundleApi = inject(SDKBundleApi);

  constructor(api: PolpoApi) {
    super(api, 'Bundles');
  }

  linkEduContent(bundleId: number, eduContentIds: number[]): Observable<UnlockedContentInterface[]> {
    // @ts-expect-error TS 2556
    return this.SDKBundleApi.linkEduContent(...arguments);
  }

  linkUserContent(bundleId: number, userContentIds: number[]): Observable<UnlockedContentInterface[]> {
    // @ts-expect-error TS 2556
    return this.SDKBundleApi.linkUserContent(...arguments);
  }

  createBundles(userId: number, bundle: BundleInterface): Observable<BundleInterface> {
    // @ts-expect-error TS 2556
    return this.SDKBundleApi.createBundles(...arguments);
  }

  patchAttributes(id: number, updates: Partial<BundleInterface>): Observable<BundleInterface> {
    // @ts-expect-error TS 2556
    return this.SDKBundleApi.patchAttributes(...arguments);
  }

  shareBundle(
    ownerId: number,
    bundleId: number,
    teacherIds: number[],
    message?: string
  ): Observable<{ message: string }> {
    // @ts-expect-error TS 2556
    return this.SDKBundleApi.shareBundle(...arguments);
  }

  setAssignees(bundleId: number, assigneeData: any): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKBundleApi.setAssignees(...arguments);
  }

  destroyBundles(ids: number[]) {
    // @ts-expect-error TS 2556
    return this.SDKBundleApi.destroyBundles(...arguments);
  }
}
