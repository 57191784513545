import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { fetch, optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from } from 'rxjs';
import { map, mapTo, switchMap, tap } from 'rxjs/operators';
import { EduContentTOCInterface, EvaluationWithSubjectsInterface } from '../../+models';
import {
  EduContentTocServiceInterface,
  EDU_CONTENT_TOC_SERVICE_TOKEN,
} from '../../edu-content-toc/edu-content-toc.service.interface';
import { DalState } from '../dal.state.interface';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import {
  AddEduContentTocsForBook,
  AddEvaluation,
  AddLoadedBook,
  DeleteEvaluation,
  EduContentTocsActionTypes,
  EduContentTocsLoadError,
  LoadEduContentTocsForBook,
  MoveEvaluation,
  NavigateToEvaluationDetail,
  StartUpdateEduContentToc,
  UpdateEduContentToc,
} from './edu-content-toc.actions';
import { isBookLoaded } from './edu-content-toc.selectors';

@Injectable()
export class EduContentTocEffects {
  loadEduContentTocsForBook$ = createEffect(() =>
    this.actions.pipe(
      ofType(EduContentTocsActionTypes.LoadEduContentTocsForBook),
      concatLatestFrom(() => this.store),
      fetch({
        run: (action: LoadEduContentTocsForBook, state) => {
          const { bookId } = action.payload;

          if (isBookLoaded(state, { bookId })) return;

          return this.eduContentTocService.getEduContentTocsForBook(bookId).pipe(
            map(
              (eduContentTocs: EduContentTOCInterface[]) =>
                new AddEduContentTocsForBook({
                  bookId,
                  eduContentTocs,
                })
            )
          );
        },
        onError: (action: LoadEduContentTocsForBook, error) => {
          return new EduContentTocsLoadError(error);
        },
      })
    )
  );

  // When AddEduContentTocsForBook is dispatched
  // also dispatch an action to add the book to the loadedBooks

  addLoadedBook$ = createEffect(() =>
    this.actions.pipe(
      ofType(EduContentTocsActionTypes.AddEduContentTocsForBook),
      map((action: AddEduContentTocsForBook) => {
        const addedBookId = action.payload.bookId;

        return new AddLoadedBook({
          bookId: addedBookId,
        });
      })
    )
  );

  addEvaluation$ = createEffect(() =>
    this.actions.pipe(
      ofType(EduContentTocsActionTypes.AddEvaluation),
      optimisticUpdate({
        run: (action: AddEvaluation) => {
          const { eduContentTocId } = action.payload;
          return this.eduContentTocService.addEvaluation(eduContentTocId).pipe(
            switchMap((evaluation: EvaluationWithSubjectsInterface) => {
              return from([
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'De evaluatie is toegevoegd.'
                  ),
                }),
                new NavigateToEvaluationDetail({ evaluation }),
              ]);
            })
          );
        },
        undoAction: (action: AddEvaluation, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de evaluatie toe te voegen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  deleteEvaluation$ = createEffect(() =>
    this.actions.pipe(
      ofType(EduContentTocsActionTypes.DeleteEvaluation),
      optimisticUpdate({
        run: (action: DeleteEvaluation) => {
          const { id } = action.payload;
          return this.eduContentTocService.deleteEvaluation(id).pipe(
            mapTo(
              new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'De evaluatie is verwijderd.'
                ),
              })
            )
          );
        },
        undoAction: (action: DeleteEvaluation, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de evaluatie te verwijderen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  moveEvaluation$ = createEffect(() =>
    this.actions.pipe(
      ofType(EduContentTocsActionTypes.MoveEvaluation),
      optimisticUpdate({
        run: (action: MoveEvaluation) => {
          const { oldEduContentTOCId, newEduContentTOCId } = action.payload;
          return this.eduContentTocService.moveEvaluation(oldEduContentTOCId, newEduContentTOCId).pipe(
            mapTo(
              new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'De evaluatie is aangepast.'
                ),
              })
            )
          );
        },
        undoAction: (action: MoveEvaluation, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de evaluatie toe te voegen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  redirectToEvaluation$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EduContentTocsActionTypes.NavigateToEvaluationDetail),
        tap((action: NavigateToEvaluationDetail) => {
          const { treeId, id } = action.payload.evaluation;

          this.router.navigate(['books', treeId, 'detail', 'evaluations'], {
            queryParams: { toc: id },
          });
        })
      ),
    { dispatch: false }
  );

  updateEduContentTOC$ = createEffect(() =>
    this.actions.pipe(
      ofType(EduContentTocsActionTypes.StartUpdateEduContentToc),
      pessimisticUpdate({
        run: (action: StartUpdateEduContentToc) => {
          const { id, changes } = action.payload.eduContentToc;
          return this.eduContentTocService.updateEduContentToc(+id, changes).pipe(
            switchMap((eduContentToc: EduContentTOCInterface) => {
              const actions: Action[] = [
                new UpdateEduContentToc({ eduContentToc: { id: +eduContentToc.id, changes: eduContentToc } }),
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'De inhoudstafel is aangepast.'
                  ),
                }),
              ];
              return from(actions);
            })
          );
        },
        onError: (action: StartUpdateEduContentToc, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de inhoudstafel aan te passen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(EDU_CONTENT_TOC_SERVICE_TOKEN)
    private eduContentTocService: EduContentTocServiceInterface,
    private router: Router,
    @Inject('uuid') private uuid: () => string
  ) {}
}
