import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonApi } from '../+api';
import { PurchaseProductInterface } from '../+models';
import { PurchaseProductServiceInterface } from './purchase-product.service.interface';

@Injectable({
  providedIn: 'root',
})
export class PurchaseProductService implements PurchaseProductServiceInterface {
  constructor(private personApi: PersonApi) {}

  getAllForUser(userId: number): Observable<PurchaseProductInterface[]> {
    return this.personApi
      .getData(userId, 'purchaseProducts')
      .pipe(map((res: { purchaseProducts: PurchaseProductInterface[] }) => res.purchaseProducts));
  }
}
