import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
} from '@angular/core';
import { ResultInterface, ResultStatusEnum } from '@campus/dal';
import { ENVIRONMENT_SCORE_MAPPING_TOKEN } from '@campus/environment';
import { ArrayFunctions, ResultFunctions } from '@campus/utils';

@Component({
  selector: 'campus-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScoreComponent implements OnChanges {
  @HostBinding('class.shared-score') hasPagesResultsScoreClass = true;
  @HostBinding('class') public get colorClass(): string {
    return this.result?.score != null ? this.result.colorClass : null;
  }

  @HostBinding('attr.data-cy')
  dataCy = 'result-score';

  @Input() result: ResultInterface;
  @Input() attempts: ResultInterface[] = [];
  anyIncomplete = false;
  hasScore = false;
  attemptedClasses: string[] = [];

  constructor(@Optional() @Inject(ENVIRONMENT_SCORE_MAPPING_TOKEN) private scoreToStars?: [number, number][]) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.result?.currentValue) {
      const currentResult = changes.result.currentValue;
      this.hasScore = currentResult.score != null;

      const score = currentResult.status !== ResultStatusEnum.STATUS_COMPLETED ? null : currentResult.score;

      this.result = {
        ...this.result,
        colorClass: this.getColorClassFromResult(score),
      };
      if (!this.attempts?.length) {
        this.anyIncomplete = currentResult.status !== ResultStatusEnum.STATUS_COMPLETED;
      }
    }
    if (changes.attempts?.currentValue) {
      this.attemptedClasses = this.mapAttempts();
    }
  }

  private getColorClassFromResult(score) {
    const colorClass = ResultFunctions.getColorClass(score, undefined, this.scoreToStars);
    if (colorClass === 'score--excellent') {
      return 'score--good';
    }
    if (!colorClass) return 'score--incomplete';
    return colorClass;
  }

  private mapAttempts() {
    if (!this.attempts) {
      this.anyIncomplete = false;
      return [];
    }

    const sortByLastUpdated = ArrayFunctions.sortByKey('lastUpdated', 'DESC');
    const sortedAttempts = this.attempts
      .filter(
        (attempt) =>
          attempt.status === ResultStatusEnum.STATUS_COMPLETED || attempt.status === ResultStatusEnum.STATUS_INCOMPLETE
      )
      .sort(sortByLastUpdated);

    this.anyIncomplete =
      sortedAttempts.some((attempt) => attempt.status !== ResultStatusEnum.STATUS_COMPLETED) ||
      this.result.status !== ResultStatusEnum.STATUS_COMPLETED;

    return sortedAttempts.map((attempt) => {
      const score = attempt.status !== ResultStatusEnum.STATUS_COMPLETED ? null : attempt.score;
      return this.getColorClassFromResult(score);
    });
  }
}
