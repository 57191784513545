import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { EvaluationSubjectGoalInterface } from '../../+models';

export enum EvaluationSubjectGoalsActionTypes {
  EvaluationSubjectGoalsLoaded = '[EvaluationSubjectGoals] EvaluationSubjectGoals Loaded',
  EvaluationSubjectGoalsLoadError = '[EvaluationSubjectGoals] Load Error',
  LoadEvaluationSubjectGoals = '[EvaluationSubjectGoals] Load EvaluationSubjectGoals',
  LoadEvaluationSubjectGoalsForBook = '[EvaluationSubjectGoals] Load EvaluationSubjectGoals For Book',
  AddEvaluationSubjectGoalsForBook = '[EvaluationSubjectGoals] Add EvaluationSubjectGoals For Book',
  AddEvaluationSubjectGoal = '[EvaluationSubjectGoals] Add EvaluationSubjectGoal',
  UpsertEvaluationSubjectGoal = '[EvaluationSubjectGoals] Upsert EvaluationSubjectGoal',
  AddEvaluationSubjectGoals = '[EvaluationSubjectGoals] Add EvaluationSubjectGoals',
  UpsertEvaluationSubjectGoals = '[EvaluationSubjectGoals] Upsert EvaluationSubjectGoals',
  UpdateEvaluationSubjectGoal = '[EvaluationSubjectGoals] Update EvaluationSubjectGoal',
  UpdateEvaluationSubjectGoals = '[EvaluationSubjectGoals] Update EvaluationSubjectGoals',
  DeleteEvaluationSubjectGoal = '[EvaluationSubjectGoals] Delete EvaluationSubjectGoal',
  DeleteEvaluationSubjectGoals = '[EvaluationSubjectGoals] Delete EvaluationSubjectGoals',
  ClearEvaluationSubjectGoals = '[EvaluationSubjectGoals] Clear EvaluationSubjectGoals',
  SetEvaluationSubjectGoalsForEvaluationSubject = '[EvaluationSubjectGoals] Set EvaluationSubjectGoals for EvaluationSubject',
}

export class LoadEvaluationSubjectGoals implements Action {
  readonly type = EvaluationSubjectGoalsActionTypes.LoadEvaluationSubjectGoals;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class EvaluationSubjectGoalsLoaded implements Action {
  readonly type = EvaluationSubjectGoalsActionTypes.EvaluationSubjectGoalsLoaded;

  constructor(public payload: { evaluationSubjectGoals: EvaluationSubjectGoalInterface[] }) {}
}

export class LoadEvaluationSubjectGoalsForBook implements Action {
  readonly type = EvaluationSubjectGoalsActionTypes.LoadEvaluationSubjectGoalsForBook;

  constructor(public payload: { bookId: number }) {}
}

export class AddEvaluationSubjectGoalsForBook implements Action {
  readonly type = EvaluationSubjectGoalsActionTypes.AddEvaluationSubjectGoalsForBook;

  constructor(public payload: { bookId: number; evaluationSubjectGoals: EvaluationSubjectGoalInterface[] }) {}
}

export class EvaluationSubjectGoalsLoadError implements Action {
  readonly type = EvaluationSubjectGoalsActionTypes.EvaluationSubjectGoalsLoadError;
  constructor(public payload: any) {}
}

export class AddEvaluationSubjectGoal implements Action {
  readonly type = EvaluationSubjectGoalsActionTypes.AddEvaluationSubjectGoal;

  constructor(public payload: { evaluationSubjectGoal: EvaluationSubjectGoalInterface }) {}
}

export class UpsertEvaluationSubjectGoal implements Action {
  readonly type = EvaluationSubjectGoalsActionTypes.UpsertEvaluationSubjectGoal;

  constructor(public payload: { evaluationSubjectGoal: EvaluationSubjectGoalInterface }) {}
}

export class AddEvaluationSubjectGoals implements Action {
  readonly type = EvaluationSubjectGoalsActionTypes.AddEvaluationSubjectGoals;

  constructor(public payload: { evaluationSubjectGoals: EvaluationSubjectGoalInterface[] }) {}
}

export class UpsertEvaluationSubjectGoals implements Action {
  readonly type = EvaluationSubjectGoalsActionTypes.UpsertEvaluationSubjectGoals;

  constructor(public payload: { evaluationSubjectGoals: EvaluationSubjectGoalInterface[] }) {}
}

export class UpdateEvaluationSubjectGoal implements Action {
  readonly type = EvaluationSubjectGoalsActionTypes.UpdateEvaluationSubjectGoal;

  constructor(public payload: { evaluationSubjectGoal: Update<EvaluationSubjectGoalInterface> }) {}
}

export class UpdateEvaluationSubjectGoals implements Action {
  readonly type = EvaluationSubjectGoalsActionTypes.UpdateEvaluationSubjectGoals;

  constructor(
    public payload: {
      evaluationSubjectGoals: Update<EvaluationSubjectGoalInterface>[];
    }
  ) {}
}

export class DeleteEvaluationSubjectGoal implements Action {
  readonly type = EvaluationSubjectGoalsActionTypes.DeleteEvaluationSubjectGoal;

  constructor(public payload: { id: number }) {}
}

export class DeleteEvaluationSubjectGoals implements Action {
  readonly type = EvaluationSubjectGoalsActionTypes.DeleteEvaluationSubjectGoals;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearEvaluationSubjectGoals implements Action {
  readonly type = EvaluationSubjectGoalsActionTypes.ClearEvaluationSubjectGoals;
}

export class SetEvaluationSubjectGoalsForEvaluationSubject implements Action {
  readonly type = EvaluationSubjectGoalsActionTypes.SetEvaluationSubjectGoalsForEvaluationSubject;

  constructor(
    public payload: { evaluationSubjectGoals: EvaluationSubjectGoalInterface[]; evaluationSubjectId: number }
  ) {}
}

export type EvaluationSubjectGoalsActions =
  | LoadEvaluationSubjectGoals
  | LoadEvaluationSubjectGoalsForBook
  | EvaluationSubjectGoalsLoaded
  | EvaluationSubjectGoalsLoadError
  | AddEvaluationSubjectGoal
  | AddEvaluationSubjectGoalsForBook
  | UpsertEvaluationSubjectGoal
  | AddEvaluationSubjectGoals
  | UpsertEvaluationSubjectGoals
  | UpdateEvaluationSubjectGoal
  | UpdateEvaluationSubjectGoals
  | DeleteEvaluationSubjectGoal
  | DeleteEvaluationSubjectGoals
  | ClearEvaluationSubjectGoals
  | SetEvaluationSubjectGoalsForEvaluationSubject;
