<div *ngIf="criteria.label" data-cy="chip-toggle-filter-label" class="chip-toggle-filter__title search-filter-title">{{
  criteria.label
}}</div>

<campus-chip-group>
  <ng-container *ngFor="let criteriaValue of criteria.values">
    <campus-filter-chip
      data-cy="chip-option"
      *ngIf="criteriaValue.visible !== false && criteriaValue.prediction !== 0"
      [selected]="criteriaValue.selected"
      (click)="selectCriteriaValue(criteriaValue)"
      [label]="criteriaValue.data[criteria.displayProperty]"
    >
    </campus-filter-chip>
  </ng-container>
</campus-chip-group>
