import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'campus-status-progress',
  templateUrl: './status-progress.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusProgressComponent {
  @HostBinding('class')
  defaultClasses = ['inline-block', 'icon-size-2xs', 'relative'];

  @HostBinding('attr.data-cy')
  dataCy = 'status-progress';

  @HostBinding('style.--progress')
  @Input()
  progress: number;

  @Input()
  color: 'excellent' | 'success' | 'warn' | 'error' | 'primary' | 'secondary';
}
