// TODO: replace these kabas svgs with polpo-specific ones
export const svgs = {
  favorites: 'assets/svgs/favorites.svg',
  'corner-triangle': 'assets/svgs/corner-triangle.svg',
  'task-educontent-hint': 'assets/svgs/task-educontent-hint.svg',
  'error:500': 'assets/svgs/error-500.svg',
  'error:503': 'assets/svgs/error-500.svg',
  'error:401': 'assets/svgs/error-401.svg',
  'error:402': 'assets/svgs/error-402.svg',
  'error:404': 'assets/svgs/error-404.svg',
  // empty states
  'empty:users': 'assets/svgs/empty-states/no-users.svg',
  'empty:content-1': 'assets/svgs/empty-states/no-content-1.svg',
  'empty:content-2': 'assets/svgs/empty-states/no-content-2.svg',
  'empty:content-3': 'assets/svgs/empty-states/no-content-3.svg',
  'empty:history': 'assets/svgs/empty-states/no-history.svg',
  'empty:schools': 'assets/svgs/empty-states/no-history.svg',
  'empty:favorites': 'assets/svgs/empty-states/no-favorites.svg',
  'empty:searchresults': 'assets/svgs/empty-states/no-searchresults.svg',
  'empty:almost-there': 'assets/svgs/empty-states/almost-there.svg',
  'empty:tasks': 'assets/svgs/empty-states/no-tasks.svg',
  'empty:taken': 'assets/svgs/empty-states/no-tasks.svg', //alias
  'empty:bundles': 'assets/svgs/empty-states/no-tasks.svg',
  'empty:bundels': 'assets/svgs/empty-states/no-tasks.svg', //alias
};
