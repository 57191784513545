import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NAME, State, selectAll, selectEntities, selectIds, selectTotal } from './didactic-function.reducer';

export const selectDidacticFunctionState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectDidacticFunctionState, (state: State) => state.error);

export const getLoaded = createSelector(selectDidacticFunctionState, (state: State) => state.loaded);

export const getAll = createSelector(selectDidacticFunctionState, selectAll);

export const getAllOrderedByName = createSelector(selectDidacticFunctionState, (state: State) =>
  Object.values(state.entities).sort((a, b) => a.name.localeCompare(b.name))
);

export const getCount = createSelector(selectDidacticFunctionState, selectTotal);

export const getIds = createSelector(selectDidacticFunctionState, selectIds);

export const getAllEntities = createSelector(selectDidacticFunctionState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * didacticFunction$: DidacticFunctionInterface[] = this.store.pipe(
    select(DidacticFunctionQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectDidacticFunctionState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * didacticFunction$: DidacticFunctionInterface = this.store.pipe(
    select(DidacticFunctionQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectDidacticFunctionState,
  (state: State, props: { id: number }) => state.entities[props.id]
);
