<div class="[ selector-wheel-items ] [ transition-transform transition-emphasized transition-extra-long-2 ]">
  <div
    *ngFor="let option of options; let i = index"
    class="[ selector-wheel-item ] [ white-space-nowrap ] [ transition-all transition-emphasized transition-extra-long-2 ]"
    [class.label-small]="i === selectedIndex"
    [class.selector-wheel-item-previous]="i < selectedIndex"
    [class.selector-wheel-item-next]="i > selectedIndex"
    >{{ option }}
  </div>
</div>
