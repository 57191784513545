/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { SchoolTypeApi as SDKSchoolTypeApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { SchoolTypeInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class SchoolTypeApi extends ModelApiBase {
  private SDKSchoolTypeApi = inject(SDKSchoolTypeApi);

  constructor(api: PolpoApi) {
    super(api, 'SchoolTypes');
  }

  find(options?): Observable<SchoolTypeInterface[]> {
    return this.SDKSchoolTypeApi.find(...arguments);
  }
}
