import { AssigneeInterface, TaskWithAssigneesInterface } from '../+state/task';
import { AlgebrakitSessionInterface } from './AlgebrakitSession.interface';
import { BundleInterface } from './Bundle.interface';
import { ClassGroupInterface } from './ClassGroup.interface';
import { ContentInterface } from './Content.interface';
import { ContentCollectionInterface } from './ContentCollection.interface';
import { EduContent } from './EduContent';
import { EduContentInterface } from './EduContent.interface';
import { EduContentBookInterface } from './EduContentBook.interface';
import { EduContentTOCInterface } from './EduContentTOC.interface';
import { EvaluationScoreListInterface } from './EvaluationScoreList.interface';
import { GroupInterface } from './Group.interface';
import { LearningAreaInterface } from './LearningArea.interface';
import { PersonInterface } from './Person.interface';
import { ResultInterface } from './Result.interface';
import { TaskInterface } from './Task.interface';
import { TaskClassGroupInterface } from './TaskClassGroup.interface';
import { TaskEduContentInterface } from './TaskEduContent.interface';
import { TaskGroupInterface } from './TaskGroup.interface';
import { TaskInstanceInterface } from './TaskInstance.interface';
import { TaskStatusEnum } from './TaskStatusEnum';
import { TaskStudentInterface } from './TaskStudent.interface';

export enum EvaluationStateEnum {
  EVALUATION_PAST = 'past',
  EVALUATION_FUTURE = 'future',
  EVALUATION_PAST_FINISHED = 'past-finished',
  EVALUATION_NOT_STARTED = 'not-started',
  EVALUATION_STARTED = 'started',
  EVALUATION_STOPPED = 'stopped',
  EVALUATION_NOT_CONFIGURED = 'not-configured',
}

export class Task implements TaskInterface, ContentCollectionInterface {
  name: string;
  description?: string;
  archivedAt?: Date;
  archivedYear?: number;
  id?: number;
  personId?: number;
  learningAreaId?: number;
  teacher?: PersonInterface;
  groups?: GroupInterface[];
  taskGroups?: TaskGroupInterface[];
  students?: PersonInterface[];
  taskStudents?: TaskStudentInterface[];
  classGroups?: ClassGroupInterface[];
  taskClassGroups?: TaskClassGroupInterface[];
  eduContents?: EduContentInterface[];
  taskEduContents?: TaskEduContentInterface[];
  results?: ResultInterface[];
  learningArea?: LearningAreaInterface;
  bundles?: BundleInterface[];
  taskInstances?: TaskInstanceInterface[];
  isAdaptive?: boolean;
  isOpen?: boolean;
  evaluationScoreListId?: number;
  wasStarted?: boolean;
  eduContentTOCId?: number;
  eduContentBookId?: number;

  get contents(): ContentInterface[] {
    if (!this.eduContents) return;
    return this.eduContents.map((item) => Object.assign<EduContent, EduContentInterface>(new EduContent(), item));
  }

  static isEvaluationTask(task: TaskInterface): boolean {
    return !!task.evaluationScoreListId && !task.isAdaptive;
  }

  static toTask(task: TaskInterface): Task {
    if (!task) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { contents, ...props } = task as any;
    return Object.assign(new Task(), props);
  }
}

export class TaskWithAssignees extends Task implements TaskWithAssigneesInterface {
  eduContentAmount: number;
  assignees: AssigneeInterface[];
  startDate?: Date;
  endDate?: Date;
  status?: TaskStatusEnum;
  sessions?: AlgebrakitSessionInterface[];
  archived?: boolean;
  adaptiveSource?: string;
  eduContentTOC?: EduContentTOCInterface;
  eduContentBook?: EduContentBookInterface;
  archivedStart?: Date;
  archivedEnd?: Date;
  evaluationScoreList?: EvaluationScoreListInterface;

  get evaluationState(): EvaluationStateEnum {
    return getEvaluationState(this, this.assignees?.[0]);
  }

  static toTaskWithAssignees(task: TaskWithAssigneesInterface): TaskWithAssignees {
    if (!task) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { contents, evaluationState, ...props } = task as any;
    return Object.assign(new TaskWithAssignees(), props);
  }
}

function getEvaluationState(
  task: TaskInterface,
  assignee: TaskClassGroupInterface | AssigneeInterface
): EvaluationStateEnum {
  if (!Task.isEvaluationTask(task)) {
    console.warn(`Task ${task.id} is not an evaluation task`);
    return;
  }

  if (!assignee) {
    return EvaluationStateEnum.EVALUATION_NOT_CONFIGURED;
  }

  const { isOpen, wasStarted } = task;
  const now = new Date();
  const { start, end } = assignee;

  if (end < now) {
    return wasStarted ? EvaluationStateEnum.EVALUATION_PAST_FINISHED : EvaluationStateEnum.EVALUATION_PAST;
  }
  if (start > now) {
    return EvaluationStateEnum.EVALUATION_FUTURE;
  }

  // today (start < now < end)
  if (isOpen) {
    return EvaluationStateEnum.EVALUATION_STARTED;
  }
  if (wasStarted) {
    return EvaluationStateEnum.EVALUATION_STOPPED;
  }
  return EvaluationStateEnum.EVALUATION_NOT_STARTED;
}
