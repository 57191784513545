import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { BulkUpdateResultInfoInterface } from '../+external-interfaces/bulk-update-result-info';
import { UnlockedContentInterface } from '../+models';

export const UNLOCKED_CONTENT_SERVICE_TOKEN = new InjectionToken('UnlockedContentService');

export interface UnlockedContentServiceInterface {
  getAllForUser(userId: number): Observable<UnlockedContentInterface[]>;
  remove(unlockedContentId: number): Observable<boolean>;
  removeAll(unlockedContentIds: number[]): Observable<boolean>;
  createUnlockedContents(
    userId: number,
    unlockedContents: Partial<UnlockedContentInterface>[]
  ): Observable<UpdateUnlockedContentResultInterface>;
  updateUnlockedContents(
    userId: number,
    update: Partial<UnlockedContentInterface>[]
  ): Observable<UpdateUnlockedContentResultInterface>;
}

export interface UpdateUnlockedContentResultInterface
  extends BulkUpdateResultInfoInterface<UnlockedContentInterface, unknown> {}
