<div class="quick-link__dialog" data-cy="quicklink-dialog">
  <campus-banner
    class="ui-banner--dialog"
    *ngIf="feedback$ | async as feedback"
    [id]="feedback.id"
    [message]="feedback.message"
    [icon]="feedback.icon"
    [actions]="feedback.userActions"
    (afterDismiss)="onBannerDismiss($event)"
  ></campus-banner>
  <campus-filter-text-input
    class="quick-link__filter"
    [source]="quickLinkCategories$ | async"
  ></campus-filter-text-input>

  <div class="quick-link__filter--cover"></div>

  <div *ngIf="filterTextInput.result$ | async as categories">
    <ng-container *ngIf="categories.length; else noContentTemplate">
      <ng-container *ngIf="categories[0].quickLinks.length; else noResultsTemplate">
        <ng-container *ngFor="let category of categories">
          <h6 *ngIf="category.title">{{ category.title }}</h6>
          <div
            class="quick-link__item"
            data-cy="quicklink-item"
            *ngFor="let quickLink of category.quickLinks"
            (click)="onActionClick(quickLink.defaultAction, quickLink)"
          >
            <campus-icon [svgIcon]="quickLink.icon" class="quick-link__item__icon"></campus-icon>

            <div class="quick-link__title ui-line-clamp-2" [matTooltip]="quickLink.defaultAction.tooltip"
              ><campus-content-editable
                [relatedItem]="quickLink"
                [text]="quickLink.name"
                (textChanged)="update(quickLink, $event)"
              ></campus-content-editable
            ></div>

            <div class="quick-link__actions quick-link__actions--links">
              <campus-button
                inline
                *ngFor="let action of quickLink.alternativeOpenActions"
                [matTooltip]="action.tooltip"
                [iconClass]="action.icon"
                (click)="onActionClick(action, quickLink)"
                campusClickStopPropagation
                class="quick-link__actions__link"
              >
                {{ action.label }}</campus-button
              >
            </div>

            <div class="quick-link__actions">
              <ng-container *ngFor="let action of quickLink.manageActions">
                <campus-button
                  inline
                  icon
                  class="quick-link__action"
                  [iconClass]="action.icon"
                  [matTooltip]="action.tooltip"
                  (click)="onActionClick(action, quickLink)"
                  campusClickStopPropagation
                ></campus-button>
              </ng-container>
            </div>
            <div
              class="quick-link__context-menu"
              [class.quick-link__context-menu--overflow]="
                quickLink.alternativeOpenActions?.length + quickLink.manageActions?.length > 1
              "
            >
              <campus-button
                inline
                icon
                quick-link__action
                [matMenuTriggerFor]="allActionDropDown"
                [iconClass]="'context_menu'"
                campusClickStopPropagation
              >
              </campus-button>
              <mat-menu #allActionDropDown="matMenu">
                <button
                  mat-menu-item
                  *ngFor="let action of quickLink.alternativeOpenActions"
                  (click)="onActionClick(action, quickLink)"
                >
                  <campus-icon [svgIcon]="action.icon"></campus-icon>{{ action.label }}</button
                >
                <button
                  mat-menu-item
                  *ngFor="let action of quickLink.manageActions"
                  (click)="onActionClick(action, quickLink)"
                >
                  <campus-icon [svgIcon]="action.icon"></campus-icon>{{ action.label }}</button
                >
              </mat-menu>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
<ng-template #noContentTemplate>
  <campus-empty-state
    [svgImage]="noContentMap[mode].svgImage"
    [title]="noContentMap[mode].title"
    [description]="noContentMap[mode].description"
  ></campus-empty-state>
</ng-template>

<ng-template #noResultsTemplate>
  <campus-empty-state
    svgImage="empty:searchresults"
    [title]="'Geen resultaten'"
    description="Deze zoekterm leverde geen resultaten op."
    ctaLabel="Filter verwijderen"
    ctaIcon="reset-filters"
    (clickCta)="filterTextInput.clear()"
  ></campus-empty-state>
</ng-template>
