import { Type } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { TaskEduContentTaskGoalYearLevelInterface } from '../../../+models';
import { TaskDataResponseInterface } from '../../../data';
import { EduContentActions } from '../../edu-content';
import { EvaluationScoreActions } from '../../evaluation-score';
import { EvaluationScoreListActions } from '../../evaluation-score-list';
import { EvaluationScoreListSubjectActions } from '../../evaluation-score-list-subject';
import { TaskEduContentActions } from '../../task-edu-content';
import { TaskEduContentTaskGoalYearLevelActions } from '../../task-edu-content-task-goal-year-level';
import { SetTaskEduContentTaskGoalYearLevelsForTask } from '../../task-edu-content-task-goal-year-level/task-edu-content-task-goal-year-level.actions';
import { TaskEvaluationSubjectActions } from '../../task-evaluation-subject';
import { TaskEvaluationSubjectScoreActions } from '../../task-evaluation-subject-score';
import { TaskGoalYearLevelActions } from '../../task-goal-year-level';
import { TaskLevelSettingActions } from '../../task-level-setting';

const fieldActionMap: Dictionary<Type<Action>> = {
  taskEvaluationSubjects: TaskEvaluationSubjectActions.SetTaskEvaluationSubjectsForTask,
  taskGoalYearLevels: TaskGoalYearLevelActions.SetTaskGoalYearLevelsForTask,
  taskLevelSettings: TaskLevelSettingActions.SetTaskLevelSettingsForTask,
  taskEduContentTaskGoalYearLevels: TaskEduContentTaskGoalYearLevelActions.SetTaskEduContentTaskGoalYearLevelsForTask,
  evaluationScores: EvaluationScoreActions.AddEvaluationScoresForTask,
  evaluationScoreListSubjects: EvaluationScoreListSubjectActions.AddEvaluationScoreListSubjectsForTask,
  evaluationScoreLists: EvaluationScoreListActions.AddEvaluationScoreListsForTask,
  eduContents: EduContentActions.AddEduContentsForTask,
  taskEduContents: TaskEduContentActions.AddTaskEduContentsForTask,
};

const fieldUpsertActionMap: Dictionary<Type<Action>> = {};

export class DataFieldActionFactory {
  /**
   * For a given field & data, return the appropriate 'Loaded' action containing the data.
   *
   * Example: field `"tasks"` with data `[{id: 1}]` returns `TasksLoaded({ tasks: [{id: 1}] })`
   */
  static getLoadedAction(taskId: number, field: keyof TaskDataResponseInterface, fieldData: any, data?: any): Action {
    const fieldActionExceptions: Dictionary<(fieldData, responseData) => Action> = {
      taskEvaluationSubjectIds: () => null,
      taskEvaluationSubjectScores: (taskEvaluationSubjectScores, responseData) => {
        const getAffected = () =>
          Array.from(new Set(taskEvaluationSubjectScores.map((tESS) => tESS.taskEvaluationSubjectId)));
        const { taskEvaluationSubjectIds = getAffected() } = responseData;

        return new TaskEvaluationSubjectScoreActions.SetTaskEvaluationSubjectScoresForTask({
          taskId,
          taskEvaluationSubjectIds,
          taskEvaluationSubjectScores,
        });
      },
      taskEduContentTaskGoalYearLevels: (taskEduContentTaskGoalYearLevels, responseData) => {
        return new SetTaskEduContentTaskGoalYearLevelsForTask({
          taskId,
          taskEduContentTaskGoalYearLevels,
          taskEduContentIds: taskEduContentTaskGoalYearLevels.map(
            (tECTGYL: TaskEduContentTaskGoalYearLevelInterface) => tECTGYL.taskEduContentId
          ),
          removedTaskEduContentTaskGoalYearLevelIds: [],
        });
      },
    };

    if (fieldActionExceptions[field]) {
      return fieldActionExceptions[field](fieldData, data);
    }

    const FieldLoaded = fieldActionMap[field];

    return new FieldLoaded({
      taskId,
      [field]: fieldData,
    });
  }

  /**
   * For a given field & data, return the appropriate 'Upsert' action containing the data.
   *
   * Example: field `"tasks"` with data `[{id: 1}]` returns `UpsertTasks({ tasks: [{id: 1}] })`
   */
  static getUpsertAction(taskId: number, field: keyof TaskDataResponseInterface, fieldData: any, data?: any): Action {
    const fieldActionExceptions: Dictionary<(fieldData, responseData) => Action> = {};

    if (fieldActionExceptions[field]) {
      return fieldActionExceptions[field](fieldData, data);
    }

    const FieldUpsert = fieldUpsertActionMap[field];

    return new FieldUpsert({
      taskId,
      [field]: data,
    });
  }
}
