import { InjectionToken } from '@angular/core';

export interface GoogleAnalyticsServiceInterface {
  logPageView(measurementId: string, url: string);
  registerNavigationEventListener();
}

export const GOOGLE_ANALYTICS_SERVICE_TOKEN = new InjectionToken<GoogleAnalyticsServiceInterface>(
  'GoogleAnalyticsServiceInterface'
);
