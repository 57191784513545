import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { MethodProductGroupProductQueries } from '.';
import {
  MethodProductGroupProductServiceInterface,
  METHOD_PRODUCT_GROUP_PRODUCT_SERVICE_TOKEN,
} from '../../method-product-group-product/method-product-group-product.service.interface';
import { DalState } from '../dal.state.interface';
import {
  LoadMethodProductGroupProducts,
  MethodProductGroupProductsActionTypes,
  MethodProductGroupProductsLoaded,
  MethodProductGroupProductsLoadError,
} from './method-product-group-product.actions';

@Injectable()
export class MethodProductGroupProductEffects {
  loadMethodProductGroupProducts$ = createEffect(() =>
    this.actions.pipe(
      ofType(MethodProductGroupProductsActionTypes.LoadMethodProductGroupProducts),
      concatLatestFrom(() => this.store.select(MethodProductGroupProductQueries.getLoaded)),
      fetch({
        run: (action: LoadMethodProductGroupProducts, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.methodProductGroupProductService.getAllForUser(action.payload.userId).pipe(
            map(
              (methodProductGroupProducts) =>
                new MethodProductGroupProductsLoaded({
                  methodProductGroupProducts,
                })
            )
          );
        },
        onError: (action: LoadMethodProductGroupProducts, error) => {
          return new MethodProductGroupProductsLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(METHOD_PRODUCT_GROUP_PRODUCT_SERVICE_TOKEN)
    private methodProductGroupProductService: MethodProductGroupProductServiceInterface
  ) {}
}
