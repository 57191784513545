<div class="info-panel">
  <div class="info-panel__title">
    <div class="info-panel__title__left">
      <campus-icon class="info-panel__title__left__icon" [svgIcon]="titleIcon" *ngIf="titleIcon"></campus-icon>
      <div class="info-panel__title__left__text">{{ titleText || 'Info' }}</div>
    </div>
    <div
      class="info-panel__title__right"
      [class.info-panel__title__right--linked]="titleRightRouterLink"
      [routerLink]="titleRightRouterLink"
      ><ng-content select="[title__right]"></ng-content
    ></div>
  </div>
  <div class="info-panel__body" [class.info-panel__body--rows]="displayAsRows">
    <ng-content></ng-content>
  </div>
</div>
