import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class NoWhiteSpaceValidator implements Validator {
  validate(ctrl: AbstractControl): ValidationErrors | null {
    if ([null, undefined, ''].includes(ctrl.value)) return null;

    const isWhitespace = ctrl.value.toString().trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
}
