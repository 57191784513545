import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BulkUpdateErrorInterface,
  BulkUpdateResultInfoInterface,
} from '../+external-interfaces/bulk-update-result-info';
import { ClassGroupInterface, SchoolRoleMappingClassGroupInterface } from '../+models';

export const CLASS_GROUP_SERVICE_TOKEN = new InjectionToken('ClassGroupService');

export interface ClassGroupServiceInterface {
  getAllForUser(userId: number): Observable<ClassGroupInterface[]>;

  createClassGroupForSchool(schoolId: number, classGroup: ClassGroupInterface): Observable<ClassGroupInterface>;

  updateClassGroupForSchool(
    schoolId: number,
    classGroupId: number,
    update: Partial<ClassGroupInterface>
  ): Observable<ClassGroupInterface>;

  removeClassGroupFromSchool(schoolId: number, classGroupId: number): Observable<boolean>;

  linkTeacherRolesClassGroupForSchool(
    schoolId: number,
    classGroupId: number,
    schoolRoleMappingIds: number[]
  ): Observable<LinkRolesClassGroupResultInterface>;

  linkStudentRolesClassGroupForSchool(
    schoolId: number,
    classGroupId: number,
    studentSchoolRoleMappingData: ClassGroupServiceStudentInterface[]
  ): Observable<LinkRolesClassGroupResultInterface>;

  unlinkRolesClassGroupForSchool(
    schoolId: number,
    classGroupId: number,
    schoolRoleMappingIds: number[]
  ): Observable<boolean>;
}

export interface ClassGroupServiceStudentInterface {
  schoolRoleMappingId: number;
  classNumber?: number;
}

export interface UpdateClassGroupResultInterface
  extends BulkUpdateResultInfoInterface<SchoolRoleMappingClassGroupInterface, BulkUpdateErrorInterface> {
  classGroup?: ClassGroupInterface;
}

export interface LinkRolesClassGroupResultInterface
  extends BulkUpdateResultInfoInterface<SchoolRoleMappingClassGroupInterface, BulkUpdateErrorInterface> {}
