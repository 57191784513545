import { Pipe, PipeTransform, TrackByFunction } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import {
  MultiCheckBoxTableItemColumnInterface,
  MultiCheckBoxTableItemInterface,
  MultiCheckBoxTableSubLevelInterface,
} from './multi-check-box-table.interface';

/**
 * This pipe is used to track the items by combination of item and column identifiers
 * in a multi check box table.
 */
@Pipe({ name: 'multiCheckBoxTrackByItem' })
export class MultiCheckBoxTrackByPipe<ItemType> implements PipeTransform {
  private _cache: Dictionary<TrackByFunction<MultiCheckBoxTableItemColumnInterface<ItemType>>> = {};

  transform(item: MultiCheckBoxTableItemInterface<ItemType>) {
    const itemKey = item.header['id'] || item.header['guid'] || item.header;

    if (this._cache[itemKey] === undefined) {
      this._cache[itemKey] = this._trackBy(itemKey, item.content);
    }
    return this._cache[itemKey];
  }

  private _trackBy(
    itemKey: string,
    content: Dictionary<boolean>
  ): TrackByFunction<MultiCheckBoxTableItemColumnInterface<ItemType>> {
    return (index: number, column: MultiCheckBoxTableItemColumnInterface<ItemType>) => {
      const columnKey = Object.keys(content)[index];

      return `${itemKey}-${columnKey}`;
    };
  }
}

/**
 * This pipe is used to track the sub levels by combination of item and column identifiers
 * in a multi check box table.
 */
@Pipe({ name: 'multiCheckBoxTrackBySubLevel' })
export class MultiCheckBoxTrackBySubLevelPipe<SubLevelItemType, ItemType> implements PipeTransform {
  private _cache: Dictionary<TrackByFunction<MultiCheckBoxTableItemColumnInterface<ItemType>>> = {};

  transform(
    subLevel: MultiCheckBoxTableSubLevelInterface<SubLevelItemType, ItemType>
  ): TrackByFunction<MultiCheckBoxTableItemColumnInterface<ItemType>> {
    const itemKey = subLevel.item['id'] || subLevel.item['guid'] || subLevel.item;

    if (this._cache[itemKey] === undefined) {
      this._cache[itemKey] = this._trackBy(itemKey, subLevel.content);
    }
    return this._cache[itemKey];
  }

  private _trackBy(
    itemKey: string,
    content: Dictionary<boolean>
  ): TrackByFunction<MultiCheckBoxTableItemColumnInterface<ItemType>> {
    return (index: number, column: MultiCheckBoxTableItemColumnInterface<ItemType>) => {
      const columnKey = Object.keys(content)[index];

      return `${itemKey}-${columnKey}`;
    };
  }
}
