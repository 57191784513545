import { Injectable } from '@angular/core';
import { TaskEduContentInterface, TaskInterface } from '@campus/dal';
import { StudentTaskServiceInterface } from './student-task.service.interface';

@Injectable({ providedIn: 'root' })
export class StudentTaskService implements StudentTaskServiceInterface {
  public isTaskFinished(task: TaskInterface): boolean {
    const { taskInstances = [], taskEduContents = [] } = task;
    const maxDate = new Date(Math.max(...taskInstances.map((ts) => +ts.end)));

    if (!taskEduContents.length) return true;
    if (maxDate <= new Date()) return true;

    const required = (tec: TaskEduContentInterface) => tec.required;
    const notRequired = (tec: TaskEduContentInterface) => !tec.required;
    const submitted = (tec: TaskEduContentInterface) => tec.submitted;

    const allSubmitted = taskEduContents.every(submitted);
    const onlyRequired = taskEduContents.every(required);
    if (onlyRequired) return allSubmitted;

    const onlyNotRequired = taskEduContents.every(notRequired);
    if (onlyNotRequired) return allSubmitted;

    const allRequiredSubmitted = taskEduContents.filter(required).every(submitted);
    return allRequiredSubmitted;
  }
}
