import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LicenseInterface } from '../../+models';
import { LicensesActions, LicensesActionTypes } from './license.actions';

export const NAME = 'licenses';

export interface State extends EntityState<LicenseInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<LicenseInterface> = createEntityAdapter<LicenseInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: LicensesActions): State {
  switch (action.type) {
    case LicensesActionTypes.AddLicense: {
      return adapter.addOne(action.payload.license, state);
    }

    case LicensesActionTypes.UpsertLicense: {
      return adapter.upsertOne(action.payload.license, state);
    }

    case LicensesActionTypes.AddLicenses: {
      return adapter.addMany(action.payload.licenses, state);
    }

    case LicensesActionTypes.UpsertLicenses: {
      return adapter.upsertMany(action.payload.licenses, state);
    }

    case LicensesActionTypes.UpdateLicense: {
      return adapter.updateOne(action.payload.license, state);
    }

    case LicensesActionTypes.UpdateLicenses: {
      return adapter.updateMany(action.payload.licenses, state);
    }

    case LicensesActionTypes.DeleteLicense: {
      return adapter.removeOne(action.payload.id, state);
    }

    case LicensesActionTypes.DeleteLicenses: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case LicensesActionTypes.LicensesLoaded: {
      return adapter.setAll(action.payload.licenses, {
        ...state,
        loaded: true,
      });
    }

    case LicensesActionTypes.LicensesLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case LicensesActionTypes.ClearLicenses: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
