import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { HelpModalDataInterface } from './help-modal-data.interface';

@Component({
  selector: 'campus-help-modal',
  templateUrl: './help-modal.component.html',
})
export class HelpModalComponent implements HelpModalDataInterface {
  title: string;
  message: string;
  disableHelp: boolean;
  helpLabel: string;
  cancelLabel: string;
  messageClass: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: HelpModalDataInterface,
    public dialogRef: MatDialogRef<HelpModalComponent>
  ) {
    this.title = data.title;
    this.message = data.message;
    this.disableHelp = data.disableHelp;
    this.helpLabel = data.helpLabel || 'Hulp nodig?';
    this.cancelLabel = data.cancelLabel || 'Sluiten';
    this.messageClass = data.messageClass || '[ label-large surface text-opacity-medium ]';
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    if (this.disableHelp) return; // workaround because stop propagation does not work
    this.dialogRef.close(true);
  }
}
