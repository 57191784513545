<h6 mat-dialog-title><span [innerHTML]="title" class="[ headline-small ]"></span></h6>
<div mat-dialog-content *ngIf="message">
  <p [class]="messageClass" data-cy="cm-content" [innerHTML]="message"></p>
</div>
<div mat-dialog-actions class="[ cluster justify-end ]">
  <div>
    <button
      inline-button
      data-cy="cm-confirm"
      campusClickStopPropagation
      [disabled]="disableHelp"
      (click)="confirm()"
    >{{ helpLabel }}</button
    >
    <button filled-button data-cy="cm-cancel" (click)="cancel()">{{ cancelLabel }}</button>
  </div>
</div>
