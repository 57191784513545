import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { GoalInterface } from '../../+models';
import { GoalYearInterface } from '../../+models/GoalYear.interface';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum GoalsActionTypes {
  GoalsLoaded = '[Goals] Goals Loaded',
  GoalsLoadError = '[Goals] Load Error',
  LoadGoals = '[Goals] Load Goals',
  LoadGoalsForLearningArea = '[Goals] Load Goals For Learning Area',
  AddGoalsForLearningArea = '[Goals] Add Goals for Learning Area',
  AddGoalsForBook = '[Goals] Add Goals for Book',
  StartAddGoal = '[Goals] Start Add Goal',
  AddGoal = '[Goals] Add Goal',
  UpsertGoal = '[Goals] Upsert Goal',
  AddGoals = '[Goals] Add Goals',
  UpsertGoals = '[Goals] Upsert Goals',
  UpdateGoal = '[Goals] Update Goal',
  UpdateGoals = '[Goals] Update Goals',
  DeleteGoal = '[Goals] Delete Goal',
  DeleteGoals = '[Goals] Delete Goals',
  ClearGoals = '[Goals] Clear Goals',
  LinkYearToGoal = '[Goals] Link Year To Goal',
  UnlinkYearFromGoal = '[Goals] Unlink Year From Goal',
  AppendYearInState = '[Goals] Append Year In State',
  UpdateGoalDisplayOrders = '[Goals] Update Display Orders Goals',
  NavigateToGoalPanel = '[Goals] Navigate To Goal Panel',
}

export class LoadGoals implements Action {
  readonly type = GoalsActionTypes.LoadGoals;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class LoadGoalsForLearningArea implements Action {
  readonly type = GoalsActionTypes.LoadGoalsForLearningArea;

  constructor(public payload: { learningAreaId: number }) {}
}

export class AddGoalsForLearningArea implements Action {
  readonly type = GoalsActionTypes.AddGoalsForLearningArea;

  constructor(public payload: { learningAreaId: number; goals: GoalInterface[] }) {}
}

export class AddGoalsForBook implements Action {
  readonly type = GoalsActionTypes.AddGoalsForBook;

  constructor(public payload: { bookId: number; goals: GoalInterface[] }) {}
}

export class GoalsLoaded implements Action {
  readonly type = GoalsActionTypes.GoalsLoaded;

  constructor(public payload: { goals: GoalInterface[] }) {}
}

export class GoalsLoadError implements Action {
  readonly type = GoalsActionTypes.GoalsLoadError;
  constructor(public payload: any) {}
}

export class StartAddGoal implements Action, FeedbackTriggeringAction {
  readonly type = GoalsActionTypes.StartAddGoal;

  constructor(
    public payload: {
      goal: GoalInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
      navigateAfterCreate?: boolean;
    }
  ) {}
}

export class AddGoal implements Action {
  readonly type = GoalsActionTypes.AddGoal;

  constructor(public payload: { goal: GoalInterface }) {}
}

export class UpsertGoal implements Action {
  readonly type = GoalsActionTypes.UpsertGoal;

  constructor(public payload: { goal: GoalInterface }) {}
}

export class AddGoals implements Action {
  readonly type = GoalsActionTypes.AddGoals;

  constructor(public payload: { goals: GoalInterface[] }) {}
}

export class UpsertGoals implements Action {
  readonly type = GoalsActionTypes.UpsertGoals;

  constructor(public payload: { goals: GoalInterface[] }) {}
}

export class UpdateGoal implements Action {
  readonly type = GoalsActionTypes.UpdateGoal;

  constructor(
    public payload: {
      goal: Update<GoalInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpdateGoals implements Action, FeedbackTriggeringAction {
  readonly type = GoalsActionTypes.UpdateGoals;

  constructor(
    public payload: { goals: Update<GoalInterface>[]; customFeedbackHandlers?: CustomFeedbackHandlersInterface }
  ) {}
}
export class UpdateGoalDisplayOrders implements Action, FeedbackTriggeringAction {
  readonly type = GoalsActionTypes.UpdateGoalDisplayOrders;

  constructor(
    public payload: {
      goals: Update<GoalInterface>[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class DeleteGoal implements Action, FeedbackTriggeringAction {
  readonly type = GoalsActionTypes.DeleteGoal;

  constructor(
    public payload: {
      id: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class DeleteGoals implements Action {
  readonly type = GoalsActionTypes.DeleteGoals;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearGoals implements Action {
  readonly type = GoalsActionTypes.ClearGoals;
}

export class LinkYearToGoal implements Action {
  readonly type = GoalsActionTypes.LinkYearToGoal;

  constructor(public payload: { goalId: number; yearId: number }) {}
}

export class UnlinkYearFromGoal implements Action {
  readonly type = GoalsActionTypes.UnlinkYearFromGoal;

  constructor(public payload: { goalId: number; yearId: number }) {}
}

export class AppendYearInState implements Action {
  readonly type = GoalsActionTypes.AppendYearInState;

  constructor(public payload: { goalYears: GoalYearInterface[] }) {}
}

export class NavigateToGoalPanel implements Action {
  readonly type = GoalsActionTypes.NavigateToGoalPanel;

  constructor(public payload: { goal: GoalInterface }) {}
}

export type GoalsActions =
  | LoadGoals
  | LoadGoalsForLearningArea
  | AddGoalsForLearningArea
  | GoalsLoaded
  | GoalsLoadError
  | StartAddGoal
  | AddGoal
  | UpsertGoal
  | AddGoals
  | UpsertGoals
  | UpdateGoal
  | UpdateGoals
  | DeleteGoal
  | DeleteGoals
  | ClearGoals
  | LinkYearToGoal
  | UnlinkYearFromGoal
  | UpdateGoalDisplayOrders
  | AppendYearInState
  | NavigateToGoalPanel
  | AddGoalsForBook;
