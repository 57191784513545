import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'campus-content-preview-dialog',
  templateUrl: './content-preview-dialog.component.html',
  styleUrls: ['./content-preview-dialog.component.scss'],
})
export class ContentPreviewDialogComponent implements OnInit {
  public loading: boolean;
  public errored: boolean;
  public titleText: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      url?: string;
      html?: string;
      title?: string;
    },
    private dialogRef: MatDialogRef<ContentPreviewDialogComponent>
  ) {}

  ngOnInit(): void {
    if (this.data?.url) this.initialiseForImage();
    if (this.data?.html) this.initialiseForHtml();
  }

  public imageLoaded() {
    this.loading = false;
  }

  public imageErrored() {
    this.loading = false;
    this.errored = true;
  }

  public close() {
    this.dialogRef.close();
  }

  private initialiseForImage() {
    this.loading = true;
    this.errored = false;
    this.titleText = 'Voorbeeld';
  }

  private initialiseForHtml() {
    this.loading = false;
    this.errored = false;
    this.titleText = this.data.title;
  }
}
