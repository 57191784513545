/**
 * @deprecated This is temporary code. Get these settings from the store.
 */
export interface ApiEnvironmentInterface {
  orderPeriod: { orderStart: Date; orderEnd: Date; sendAtlas: Date };
  schoolYearBoundaries: {
    schoolYearStart: { day: number; month: number };
    schoolYearEnd: { day: number; month: number };
    classesEnd: { day: number; month: number };
  };
  yearTransitionBoundaries: {
    start: Date;
    end: Date;
  },
  licenseBoundaries: {
    start: Date;
    end: Date;
    schoolyear: number;
  };
}

/**
 * @deprecated This is temporary code. Get these settings from the store.
 */
export class ApiEnvironment {
  public static get(): ApiEnvironmentInterface {
    try {
      const apiEnvRaw = JSON.parse(window.localStorage.getItem('apiEnvironment'));
      return {
        ...apiEnvRaw,
        orderPeriod: {
          orderStart: new Date(apiEnvRaw.orderPeriod.orderStart),
          orderEnd: new Date(apiEnvRaw.orderPeriod.orderEnd),
          sendAtlas: new Date(apiEnvRaw.orderPeriod.sendAtlas),
        },
        licenseBoundaries: {
          ...apiEnvRaw.licenseBoundaries,
          start: new Date(apiEnvRaw.licenseBoundaries.start),
          end: new Date(apiEnvRaw.licenseBoundaries.end),
        },
        yearTransitionBoundaries: {
          ...apiEnvRaw.yearTransitionBoundaries,
          start: new Date(apiEnvRaw.yearTransitionBoundaries.start),
          end: new Date(apiEnvRaw.yearTransitionBoundaries.end),
        }
      };
    } catch (e) {
      // return sane default ... for the unit tests (mocking localstorage is too hard
      // and in the refactor this will come from a selector)
      const orderStartDefaults = { month: 4, day: 15 };
      const date = new Date();
      const yearModifier = +(
        date.getMonth() < orderStartDefaults.month ||
        (date.getMonth() === orderStartDefaults.month && date.getDate() <= orderStartDefaults.day)
      );
      const startYear = date.getFullYear() - yearModifier;

      return {
        schoolYearBoundaries: {
          schoolYearStart: { month: 8, day: 1 },
          schoolYearEnd: { month: 7, day: 31 },
          classesEnd: { month: 5, day: 30 },
        },
        orderPeriod: {
          orderStart: new Date(startYear, orderStartDefaults.month, orderStartDefaults.day, 0, 0, 0, 0),
          orderEnd: new Date(startYear, 9, 31, 0, 0, 0, 0),
          sendAtlas: new Date(startYear, 9, 31, 0, 0, 0, 0),
        },
        licenseBoundaries: {
          start: new Date(startYear, 8, 15, 0, 0, 0, 0),
          end: new Date(startYear + 1, 8, 15, 0, 0, 0, 0),
          schoolyear: startYear,
        },
        yearTransitionBoundaries: {
          start: new Date(startYear, 7, 14, 0, 0, 0, 0),
          end: new Date(startYear, 7, 31, 0, 0, 0, 0),
        }
      };
    }
  }

  public static setFromLoginResponse(loginResponse) {
    window.localStorage.setItem('apiEnvironment', JSON.stringify(loginResponse.apiEnvironment));
  }
}
