import { Inject, Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import {
  AUTH_SERVICE_TOKEN,
  AuthServiceInterface,
  PERSON_SERVICE_TOKEN,
  PersonServiceInterface,
  StudentInterface,
} from '@campus/dal';

import { Dictionary } from '@ngrx/entity';
import { Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CheckMultipleUniqueLeerIdsValidator implements AsyncValidator {
  private studentsByLeerId: Dictionary<StudentInterface>;
  private selectedColumnIndex: number;

  public setSelectedColumnIndex(selectedColumnIndex: number) {
    this.selectedColumnIndex = selectedColumnIndex;
  }

  public setImportDataToValidate(studentsByLeerId: Dictionary<StudentInterface>, selectedColumnIndex: number) {
    this.studentsByLeerId = studentsByLeerId;
    this.selectedColumnIndex = selectedColumnIndex;
  }

  constructor(
    @Inject(AUTH_SERVICE_TOKEN) private authService: AuthServiceInterface,
    @Inject(PERSON_SERVICE_TOKEN) private personService: PersonServiceInterface
  ) {}

  validate(form: AbstractControl): Observable<ValidationErrors | null> {
    if (!this.studentsByLeerId || this.selectedColumnIndex == null) return of(null);

    const leerIdsFromFormNotInStore = form.value.students
      .map((student) => student[this.selectedColumnIndex])
      .filter((leerId) => leerId !== '')
      .filter((leerId) => !this.studentsByLeerId[leerId]);

    return this.personService.checkMultipleUniqueLeerIds(this.authService.userId, leerIdsFromFormNotInStore).pipe(
      take(1),
      tap((duplicateLeerIds) => {
        duplicateLeerIds.forEach((leerId) => {
          this.loopControls(form, this.selectedColumnIndex, leerId);
        });
      }),
      map((duplicateLeerIds) => (duplicateLeerIds.length ? { duplicateLeerIds } : null))
    );
  }

  loopControls(control: AbstractControl, selectedColumnIndex: number, leerId: string): void {
    if (control instanceof FormGroup || control instanceof FormArray) {
      Object.keys(control.controls).forEach((key: string) => {
        const childControl: AbstractControl = control.controls[key];

        if (childControl instanceof FormControl) {
          if (+key === selectedColumnIndex && childControl.value && childControl.value === leerId) {
            childControl.setErrors({ notUniqueLeerId: true });
          }
        } else {
          this.loopControls(childControl, selectedColumnIndex, leerId);
        }
      });
    }
  }
}
