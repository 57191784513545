import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch, pessimisticUpdate } from '@nrwl/angular';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TaskLevelSettingQueries } from '.';
import {
  TaskLevelSettingServiceInterface,
  TASK_LEVEL_SETTING_SERVICE_TOKEN,
} from '../../task-level-setting/task-level-setting.service.interface';
import { DalState } from '../dal.state.interface';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { CalculateTaskEduContentsForTask } from '../task-edu-content/task-edu-content.actions';
import {
  AddTaskLevelSettingsForTask,
  LoadTaskLevelSettingsForTask,
  SetTaskLevelSettingsForTask,
  StartSetTaskLevelSettingsForTask,
  TaskLevelSettingsActionTypes,
  TaskLevelSettingsLoadError,
} from './task-level-setting.actions';

@Injectable()
export class TaskLevelSettingEffects {
  loadTaskLevelSettingsForTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(TaskLevelSettingsActionTypes.LoadTaskLevelSettingsForTask),
      concatLatestFrom(() => this.store.select(TaskLevelSettingQueries.selectTaskLevelSettingState)),
      fetch({
        run: (action: LoadTaskLevelSettingsForTask, taskLevelSettingState) => {
          const { taskId } = action.payload;

          if (taskLevelSettingState.loadedForTask[taskId]) return;

          return this.taskLevelSettingService.getTaskLevelSettingsForTask(taskId).pipe(
            map(
              (taskLevelSettings) =>
                new AddTaskLevelSettingsForTask({
                  taskId,
                  taskLevelSettings,
                })
            )
          );
        },
        onError: (action: LoadTaskLevelSettingsForTask, error) => {
          return new TaskLevelSettingsLoadError(error);
        },
      })
    )
  );

  startSetTaskLevelSettingsForTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(TaskLevelSettingsActionTypes.StartSetTaskLevelSettingsForTask),
      pessimisticUpdate({
        run: (action: StartSetTaskLevelSettingsForTask) => {
          const { taskId, taskLevelSettings: tls } = action.payload;
          return this.taskLevelSettingService.setTaskLevelSettingsForTask(taskId, tls).pipe(
            switchMap((taskLevelSettings) => {
              return from([
                new CalculateTaskEduContentsForTask({ taskId }),
                new SetTaskLevelSettingsForTask({ taskId, taskLevelSettings }),
              ]);
            })
          );
        },
        onError: (action: StartSetTaskLevelSettingsForTask, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de instellingen op te slaan.'
            ),
          });
        },
      })
    )
  );

  constructor(
    @Inject('uuid') private uuid: () => string,
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(TASK_LEVEL_SETTING_SERVICE_TOKEN)
    private taskLevelSettingService: TaskLevelSettingServiceInterface
  ) {}
}
