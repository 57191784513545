import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { BadgeInterface } from '../../+models';
import { BadgesActions, BadgesActionTypes } from './badge.actions';

export const NAME = 'badges';

export interface State extends EntityState<BadgeInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<BadgeInterface> = createEntityAdapter<BadgeInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: BadgesActions): State {
  switch (action.type) {
    case BadgesActionTypes.AddBadge: {
      return adapter.addOne(action.payload.badge, state);
    }

    case BadgesActionTypes.UpsertBadge: {
      return adapter.upsertOne(action.payload.badge, state);
    }

    case BadgesActionTypes.AddBadges: {
      return adapter.addMany(action.payload.badges, state);
    }

    case BadgesActionTypes.UpsertBadges: {
      return adapter.upsertMany(action.payload.badges, state);
    }

    case BadgesActionTypes.UpdateBadge: {
      return adapter.updateOne(action.payload.badge, state);
    }

    case BadgesActionTypes.UpdateBadges: {
      return adapter.updateMany(action.payload.badges, state);
    }

    case BadgesActionTypes.DeleteBadge: {
      return adapter.removeOne(action.payload.id, state);
    }

    case BadgesActionTypes.DeleteBadges: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case BadgesActionTypes.BadgesLoaded: {
      return adapter.setAll(action.payload.badges, { ...state, loaded: true });
    }

    case BadgesActionTypes.BadgesLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case BadgesActionTypes.ClearBadges: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
