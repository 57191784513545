import { Inject, Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { PERSON_SERVICE_TOKEN, PersonServiceInterface } from '@campus/dal';
import { Observable, of } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';

export enum ValidationMapping {
  MIN_LENGTH = '*Het wachtwoord moet langer zijn dan ${value} karakters.',
  MAX_LENGTH = '*Het wachtwoord mag niet langer zijn dan ${value} karakters.',
  NO_USER_INFO = '*Het wachtwoord mag geen gegevens bevatten zoals je naam, voornaam of gebruikersnaam.',
  NO_CONTEXT = '*Het wachtwoord mag geen woorden uit de context bevatten zoals de naam van het platform.',
}

@Injectable({ providedIn: 'root' })
export class ValidPasswordValidator implements AsyncValidator {
  constructor(@Inject(PERSON_SERVICE_TOKEN) private personService: PersonServiceInterface) {}

  private getErrorMessages(errorMessages) {
    return errorMessages.message.errors
      .map((error) => {
        return ValidationMapping[error.reason].replace('${value}', error.value);
      })
      .join('\n');
  }

  validate(ctrl: AbstractControl, userId = -1): Observable<ValidationErrors | null> {
    return this.personService.validatePassword(userId, ctrl.value).pipe(
      mapTo(null),
      catchError((err) => of({ passwordInvalid: this.getErrorMessages(err) }))
    );
  }
}
