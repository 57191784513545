import { InjectionToken } from '@angular/core';
import { LearnosityAuthorConfigInterface, LearnosityItemsConfigInterface } from '@campus/dal';
import { AuthorAppInterface, ItemsAppInterface, LearnosityCallbacksInterface } from '../interfaces';

declare global {
  interface Window {
    LearnosityReports: {
      init: (object, string: string) => {};
    };
    LearnosityItems: LearnosityItemsWindowInterface;
    LearnosityAuthor: LearnosityAuthorWindowInterface;
    ReadSpeaker?: {
      ui?: {
        destroyActivePlayer?: () => void;
      };
    };
    rsCallbacks?: {
      readyListener?: () => void;
    }; // Readspeaker callback
    rsConf?: {
      Learnosity?: {
        customerParams?: {
          customerid?: string;
          region?: string;
          lang?: string;
          voice?: string;
        };
      };
      params?: string;
      ui?: any;
      general?: {
        uiLang?: string;
      };
    };
    itemsApp: ItemsAppInterface;
    getAuthorApp: () => AuthorAppInterface;
  }
}

export interface LearnosityItemsWindowInterface {
  init: (
    object: LearnosityItemsConfigInterface,
    string: string,
    callbacksObject?: LearnosityCallbacksInterface
  ) => ItemsAppInterface;
}

export interface LearnosityAuthorWindowInterface {
  init: (
    object: LearnosityAuthorConfigInterface,
    string: string,
    callbacksObject?: LearnosityCallbacksInterface
  ) => AuthorAppInterface;
}

export const LEARNOSITY_WINDOW = new InjectionToken('WindowToken', {
  providedIn: 'root',
  factory: () => window,
});
