import { Injectable } from '@angular/core';

import { argbFromHex, rgbaFromArgb, themeFromSourceColor } from '@material/material-color-utilities';
import { CustomPropertyInterface, StyleInterface } from '../../directives';
import { ThemeDataInterface } from '../../interfaces';

type CollectionType = 'edition' | 'ref';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public getStyling(style: StyleInterface, wrapper?: string, collectionType: CollectionType = 'edition') {
    const { theme } = style;
    if (!theme) return;

    const { baseColor, exceptions = {}, font, customProperties = {} } = theme;

    const themeMap = baseColor && this.generateTheme(baseColor, exceptions, collectionType);
    const themeFont = font && this.getThemeFont(font);
    const themeCustomProperties = customProperties && this.getCustomProperties(customProperties);

    return `
      ${themeFont?.import || ''}
      ${wrapper ? wrapper + '{\n' : ''}
        ${themeFont?.styleFontVariables || ''}
        ${themeMap ? this.generateThemeCssVariables(themeMap) : ''}
        ${themeCustomProperties}
      ${wrapper ? '}' : ''}
    `;
  }

  private generateThemeCssVariables(themeMap: Record<string, string>) {
    return Object.keys(themeMap).reduce((acc, key) => {
      return `${acc}${key}: ${themeMap[key]};\n`;
    }, '');
  }

  private getThemeFont(font: { name: string; url: string }) {
    return {
      import: `@import url('${font.url}');`,
      styleFontVariables: `--cds-ref-typeface-brand: ${font.name}, sans-serif;
        --cds-sys-typescale-headline-large-brand: var(--cds-ref-typeface-brand);
        --cds-sys-typescale-headline-medium-brand: var(--cds-ref-typeface-brand);
        --cds-sys-typescale-headline-small-brand: var(--cds-ref-typeface-brand);
        --cds-sys-typescale-title-large-brand: var(--cds-ref-typeface-brand);
        --cds-sys-typescale-title-medium-brand: var(--cds-ref-typeface-brand);
        --cds-sys-typescale-title-small-brand: var(--cds-ref-typeface-brand);
        --cds-sys-typescale-body-large-brand: var(--cds-ref-typeface-brand);
        --cds-sys-typescale-body-medium-brand: var(--cds-ref-typeface-brand);
        --cds-sys-typescale-body-small-brand: var(--cds-ref-typeface-brand);
        --cds-sys-typescale-label-large-brand: var(--cds-ref-typeface-brand);
        --cds-sys-typescale-label-medium-brand: var(--cds-ref-typeface-brand);
        --cds-sys-typescale-label-small-brand: var(--cds-ref-typeface-brand);
        --cds-sys-typescale-label-overline-brand: var(--cds-ref-typeface-brand);`,
    };
  }

  private getCustomProperties(customProperties: CustomPropertyInterface) {
    return Object.keys(customProperties).reduce((acc, key) => {
      return `${acc}${key}: ${customProperties[key]};\n`;
    }, '');
  }

  private generateTheme(
    color: string,
    exceptions?: { [toneRange: string]: { [tone: number]: string } },
    collectionType: CollectionType = 'edition'
  ) {
    const theme = themeFromSourceColor(argbFromHex(color.replace('#', '')), []);
    const themeData: Record<string, ThemeDataInterface> = {
      primary: {
        toneRange: [0, 5, 8, 10, 13, 17, 20, 23, 30, 31, 40, 50, 60, 70, 80, 82, 90, 92, 95, 98, 99, 100],
        prefix: 'primary',
        palette: theme.palettes.primary,
        exceptions: exceptions?.['primary'],
      },
      secondary: {
        toneRange: [0, 5, 8, 10, 13, 17, 20, 23, 30, 31, 40, 50, 60, 70, 80, 82, 90, 92, 95, 98, 99, 100],
        prefix: 'secondary',
        palette: theme.palettes.secondary,
        exceptions: exceptions?.['secondary'],
      },
      neutral: {
        toneRange: [0, 4, 6, 10, 12, 17, 20, 22, 30, 40, 50, 60, 70, 80, 90, 92, 94, 95, 96, 98, 99, 100],
        prefix: 'neutral',
        palette: theme.palettes.neutral,
        exceptions: exceptions?.['neutral'],
      },
      neutralVariant: {
        toneRange: [0, 10, 20, 30, 40, 50, 55, 60, 67, 70, 80, 82, 90, 99, 100],
        prefix: 'neutral-variant',
        palette: theme.palettes.neutralVariant,
        exceptions: exceptions?.['neutralVariant'],
      },
    };
    const themeMap = Object.keys(themeData).reduce((acc, paletteName) => {
      return {
        ...acc,
        ...this.generatePaletteForToneRange(themeData[paletteName], collectionType),
      };
    }, {});

    return themeMap;
  }

  private generatePaletteForToneRange(paletteData: ThemeDataInterface, collectionType: CollectionType = 'edition') {
    const { toneRange, prefix, palette, exceptions: overrides } = paletteData;
    return toneRange.reduce((toneAcc, tone) => {
      const toneValue = overrides?.[tone]
        ? rgbaFromArgb(argbFromHex(overrides?.[tone].replace('#', '')))
        : rgbaFromArgb(palette.tone(tone));
      const toneName = `--cds-${collectionType}-palette-${prefix}-${tone}`;

      return {
        ...toneAcc,
        [toneName]: `${toneValue.r}, ${toneValue.g}, ${toneValue.b}`,
      };
    }, {});
  }
}
