import { EnvironmentTextMappingInterface } from '@campus/environment';

export const textMapping: EnvironmentTextMappingInterface = {
  teacher: {
    searchResult: {
      zelfLeren: 'leerling',
    },
  },
  common: {
    moetje: 'verplicht',
    magje: 'vrijblijvend',
    moetjeNoun: 'verplichte oefening',
    moetjesNoun: 'verplichte oefeningen',
    magjeNoun: 'vrijblijvende oefening',
    magjesNoun: 'vrijblijvende oefeningen',
    emptyState: {
      noStudentsTitle: 'Geen studenten',
      noStudents: 'Er zijn nog geen studenten aan je profiel gekoppeld.',
    },
    schooladmin: 'boekverantwoordelijke of ICT-coördinator',
    teacher: 'leerkracht',
    student: 'leerling',
    superuser: 'administrator',
    editor: 'editor',
    author: 'auteur',
    editoradmin: 'editorbeheerder',
    promotor: 'promotor',
    marketing: 'marketing',
    algebrakit: 'algebrakit',
    helpdesk: 'helpdesk',
    sales: 'sales',
  },
  platform: 'polpo',
};
