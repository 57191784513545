import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { TaskLevelSettingInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum TaskLevelSettingsActionTypes {
  TaskLevelSettingsLoaded = '[TaskLevelSettings] TaskLevelSettings Loaded',
  TaskLevelSettingsLoadError = '[TaskLevelSettings] Load Error',
  LoadTaskLevelSettings = '[TaskLevelSettings] Load TaskLevelSettings',
  LoadTaskLevelSettingsForTask = '[TaskLevelSettings] Load TaskLevelSettings for Task',
  StartSetTaskLevelSettingsForTask = '[TaskLevelSettings] Start Set TaskLevelSettings for task',
  SetTaskLevelSettingsForTask = '[TaskLevelSettings] Set TaskLevelSettings for task',
  AddTaskLevelSettingsForTask = '[TaskLevelSettings] Add TaskLevelSettings for Task',
  AddTaskLevelSetting = '[TaskLevelSettings] Add TaskLevelSetting',
  UpsertTaskLevelSetting = '[TaskLevelSettings] Upsert TaskLevelSetting',
  AddTaskLevelSettings = '[TaskLevelSettings] Add TaskLevelSettings',
  UpsertTaskLevelSettings = '[TaskLevelSettings] Upsert TaskLevelSettings',
  UpdateTaskLevelSetting = '[TaskLevelSettings] Update TaskLevelSetting',
  UpdateTaskLevelSettings = '[TaskLevelSettings] Update TaskLevelSettings',
  DeleteTaskLevelSetting = '[TaskLevelSettings] Delete TaskLevelSetting',
  DeleteTaskLevelSettings = '[TaskLevelSettings] Delete TaskLevelSettings',
  ClearTaskLevelSettings = '[TaskLevelSettings] Clear TaskLevelSettings',
}

export class LoadTaskLevelSettings implements Action {
  readonly type = TaskLevelSettingsActionTypes.LoadTaskLevelSettings;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class LoadTaskLevelSettingsForTask implements Action {
  readonly type = TaskLevelSettingsActionTypes.LoadTaskLevelSettingsForTask;

  constructor(public payload: { taskId: number }) {}
}

export class AddTaskLevelSettingsForTask implements Action {
  readonly type = TaskLevelSettingsActionTypes.AddTaskLevelSettingsForTask;

  constructor(public payload: { taskId: number; taskLevelSettings: TaskLevelSettingInterface[] }) {}
}
export class StartSetTaskLevelSettingsForTask implements FeedbackTriggeringAction {
  readonly type = TaskLevelSettingsActionTypes.StartSetTaskLevelSettingsForTask;

  constructor(
    public payload: {
      taskId: number;
      taskLevelSettings: TaskLevelSettingInterface[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}
export class SetTaskLevelSettingsForTask implements Action {
  readonly type = TaskLevelSettingsActionTypes.SetTaskLevelSettingsForTask;

  constructor(public payload: { taskId: number; taskLevelSettings: TaskLevelSettingInterface[] }) {}
}

export class TaskLevelSettingsLoaded implements Action {
  readonly type = TaskLevelSettingsActionTypes.TaskLevelSettingsLoaded;

  constructor(public payload: { taskLevelSettings: TaskLevelSettingInterface[] }) {}
}

export class TaskLevelSettingsLoadError implements Action {
  readonly type = TaskLevelSettingsActionTypes.TaskLevelSettingsLoadError;
  constructor(public payload: any) {}
}

export class AddTaskLevelSetting implements Action {
  readonly type = TaskLevelSettingsActionTypes.AddTaskLevelSetting;

  constructor(public payload: { taskLevelSetting: TaskLevelSettingInterface }) {}
}

export class UpsertTaskLevelSetting implements Action {
  readonly type = TaskLevelSettingsActionTypes.UpsertTaskLevelSetting;

  constructor(public payload: { taskLevelSetting: TaskLevelSettingInterface }) {}
}

export class AddTaskLevelSettings implements Action {
  readonly type = TaskLevelSettingsActionTypes.AddTaskLevelSettings;

  constructor(public payload: { taskLevelSettings: TaskLevelSettingInterface[] }) {}
}

export class UpsertTaskLevelSettings implements Action {
  readonly type = TaskLevelSettingsActionTypes.UpsertTaskLevelSettings;

  constructor(public payload: { taskLevelSettings: TaskLevelSettingInterface[] }) {}
}

export class UpdateTaskLevelSetting implements Action {
  readonly type = TaskLevelSettingsActionTypes.UpdateTaskLevelSetting;

  constructor(public payload: { taskLevelSetting: Update<TaskLevelSettingInterface> }) {}
}

export class UpdateTaskLevelSettings implements Action {
  readonly type = TaskLevelSettingsActionTypes.UpdateTaskLevelSettings;

  constructor(public payload: { taskLevelSettings: Update<TaskLevelSettingInterface>[] }) {}
}

export class DeleteTaskLevelSetting implements Action {
  readonly type = TaskLevelSettingsActionTypes.DeleteTaskLevelSetting;

  constructor(public payload: { id: number }) {}
}

export class DeleteTaskLevelSettings implements Action {
  readonly type = TaskLevelSettingsActionTypes.DeleteTaskLevelSettings;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearTaskLevelSettings implements Action {
  readonly type = TaskLevelSettingsActionTypes.ClearTaskLevelSettings;
}

export type TaskLevelSettingsActions =
  | LoadTaskLevelSettings
  | TaskLevelSettingsLoaded
  | TaskLevelSettingsLoadError
  | AddTaskLevelSetting
  | UpsertTaskLevelSetting
  | AddTaskLevelSettings
  | UpsertTaskLevelSettings
  | UpdateTaskLevelSetting
  | UpdateTaskLevelSettings
  | DeleteTaskLevelSetting
  | DeleteTaskLevelSettings
  | ClearTaskLevelSettings
  | LoadTaskLevelSettingsForTask
  | AddTaskLevelSettingsForTask
  | StartSetTaskLevelSettingsForTask
  | SetTaskLevelSettingsForTask;
