import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ConceptInterface } from '../../+models';
import { ConceptsActions, ConceptsActionTypes } from './concept.actions';

export const NAME = 'concepts';

export interface State extends EntityState<ConceptInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<ConceptInterface> = createEntityAdapter<ConceptInterface>({
  selectId: (concept: ConceptInterface) => concept.guid,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: ConceptsActions): State {
  switch (action.type) {
    case ConceptsActionTypes.AddConcept: {
      return adapter.addOne(action.payload.concept, state);
    }

    case ConceptsActionTypes.UpsertConcept: {
      return adapter.upsertOne(action.payload.concept, state);
    }

    case ConceptsActionTypes.AddConcepts: {
      return adapter.addMany(action.payload.concepts, state);
    }

    case ConceptsActionTypes.UpsertConcepts: {
      return adapter.upsertMany(action.payload.concepts, state);
    }

    case ConceptsActionTypes.UpdateConcept: {
      return adapter.updateOne(action.payload.concept, state);
    }

    case ConceptsActionTypes.UpdateConcepts: {
      return adapter.updateMany(action.payload.concepts, state);
    }

    case ConceptsActionTypes.DeleteConcept: {
      return adapter.removeOne(action.payload.id, state);
    }

    case ConceptsActionTypes.DeleteConcepts: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case ConceptsActionTypes.ConceptsLoaded: {
      return adapter.setAll(action.payload.concepts, {
        ...state,
        loaded: true,
      });
    }

    case ConceptsActionTypes.ConceptsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case ConceptsActionTypes.ClearConcepts: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
