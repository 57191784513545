import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { ComponentsModule } from '@campus/components';
import { DalModule } from '@campus/dal';
import { SharedModule } from '@campus/shared';
import { SvgModule } from '@campus/svg';
import { UiModule } from '@campus/ui';
import { EduContentResultListItemComponent } from './components/edu-content-result-list-item/edu-content-result-list-item.component';
import { TocResultListItemGroupsComponent } from './components/toc-result-list-item-groups/toc-result-list-item-groups.component';
import { TocResultListItemComponent } from './components/toc-result-list-item/toc-result-list-item.component';
import { TocResultService } from './services/toc-result.service';

@NgModule({
  imports: [CommonModule, SvgModule, SharedModule, UiModule, MatBadgeModule, DalModule, ComponentsModule],
  declarations: [TocResultListItemComponent, TocResultListItemGroupsComponent, EduContentResultListItemComponent],
  exports: [TocResultListItemComponent, TocResultListItemGroupsComponent, EduContentResultListItemComponent],
  providers: [TocResultService],
})
export class PagesSharedModule {}
