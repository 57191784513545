import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AccessTokenInterface, PersonInterface } from '../+models';

export interface LoginCredentials {
  email: string;
  password: string;
  username: string;
}

export interface PersonalCodeCredentials {
  schoolCode: number;
  classGroupId: number;
  classNumber: number;
  childCode: string;
}

export interface RegistrationCredentials {
  email: string;
  password: string;
  userType: string;
}

export const AUTH_SERVICE_TOKEN = new InjectionToken<AuthServiceInterface>('AuthService');

export interface ValidateTokenResponse {
  code: 'ok' | 'token_invalid' | 'token_expired';
}

export interface AuthServiceInterface {
  userId: number;

  getCurrent(): Observable<any>;

  logout(): Observable<boolean>;

  resetPassword(email: string): Observable<boolean>;

  login(credentials: Partial<LoginCredentials>): Observable<any>;

  isLoggedIn(): boolean;

  getPermissions(): Observable<string[]>;

  validateToken(token: string, userId: number): Observable<ValidateTokenResponse>;

  loginWithPersonalCode(credentials: Partial<PersonalCodeCredentials>): Observable<any>;

  isUserInSync(stateUser: PersonInterface): Observable<boolean>;

  getAccessToken$(): Observable<AccessTokenInterface>;
}
