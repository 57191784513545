export function findOneInArray<T>(entities: T[], props: Partial<T>, includeIfEmpty = false) {
  return entities.find((entity) => {
    return Object.keys(props).every((prop) => (!props[prop] && !includeIfEmpty) || entity[prop] === props[prop]);
  });
}

export function findManyInArray<T>(entities: T[], props: Partial<T>, includeIfEmpty = false) {
  return entities.filter((entity) => {
    return Object.keys(props).every((prop) => (!props[prop] && !includeIfEmpty) || entity[prop] === props[prop]);
  });
}
