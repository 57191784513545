export class NumberFunctions {
  public static roundToPlaces(num, places = 2) {
    const roundingModifier = Math.pow(10, places);
    return Math.round((num + Number.EPSILON) * roundingModifier) / roundingModifier;
  }

  public static range(start: number, stop: number, step = 1) {
    if (step === 0) throw new Error('Step is zero');

    const length = (stop - start) / step + 1;
    if (length <= 0) throw new Error('Invalid array length');

    return Array.from({ length }, (_, i) => start + i * step);
  }
}
