import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { PersonApi } from '../+api';
import { FavoriteInterface } from '../+models';
import { FavoriteServiceInterface } from './favorite.service.interface';

@Injectable({
  providedIn: 'root',
})
export class FavoriteService implements FavoriteServiceInterface {
  constructor(private personApi: PersonApi) {}

  getAllForUser(userId: number): Observable<FavoriteInterface[]> {
    return this.personApi.getFavorites(userId).pipe(
      map((favorites) =>
        favorites.map(
          (favorite) =>
            ({
              ...favorite,
              created: new Date(favorite.created),
            } as FavoriteInterface)
        )
      )
    );
  }

  addFavorite(userId: number, favorite: FavoriteInterface): Observable<FavoriteInterface> {
    // note: will soft delete every other favorite with
    // the same type and relationId (e.g. learningAreaId)
    // (@see 'before save hook' in favorite model on API)
    return this.personApi.createFavorites(userId, favorite).pipe(
      map(
        (fav) =>
          ({
            ...fav,
            created: new Date(fav.created),
          } as FavoriteInterface)
      )
    );
  }

  updateFavorite(
    userId: number,
    favoriteId: number,
    changes: Partial<FavoriteInterface>
  ): Observable<FavoriteInterface> {
    return this.personApi
      .updateByIdFavorites(userId, favoriteId, changes)
      .pipe(map((favorite) => favorite as FavoriteInterface));
  }

  deleteFavorite(userId: number, favoriteId: number): Observable<boolean> {
    return this.personApi.destroyByIdFavorites(userId, favoriteId).pipe(mapTo(true));
  }
}
