import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

export enum SvgBackgroundLocation {
  LEFT = 'left',
  CENTER = 'center',
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  FULL = 'full',
}

@Component({
  selector: 'campus-svg-background',
  templateUrl: './svg-background.component.html',
  styleUrls: ['./svg-background.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgBackgroundComponent implements OnInit {
  @Input() location = SvgBackgroundLocation.CENTER;
  @Input() class = '';
  @Input() triggerRerenderBy: any;

  @HostBinding('class') get getClass() {
    const classes = this.class.split(' ');
    classes.push('ui-svg-background');
    classes.push(`ui-svg-background--${this.location.toLowerCase()}`);
    return classes.join(' ');
  }

  public svgKey: string;

  constructor() {}

  ngOnInit() {
    this.svgKey = 'background:' + this.location;
  }
}
