/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { TaskApi as SDKTaskApi } from '@diekeure/polpo-api-angular-sdk';
import { TaskInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class TaskApi extends ModelApiBase {
  private SDKTaskApi = inject(SDKTaskApi);

  constructor(api: PolpoApi) {
    super(api, 'Tasks');
  }

  getData(taskId: number, fields?: string, since?) {
    // @ts-expect-error TS 2556
    return this.SDKTaskApi.getData(...arguments);
  }

  setTaskLevelSettings(taskId: number, taskLevelSettings) {
    // @ts-expect-error TS 2556
    return this.SDKTaskApi.setTaskLevelSettings(...arguments);
  }

  linkEduContents(taskId: number, eduContentId: number) {
    // @ts-expect-error TS 2556
    return this.SDKTaskApi.linkEduContents(...arguments);
  }

  updateTasks(update: Partial<TaskInterface>[]) {
    // @ts-expect-error TS 2556
    return this.SDKTaskApi.updateTasks(...arguments);
  }

  duplicateTask(taskId: number) {
    // @ts-expect-error TS 2556
    return this.SDKTaskApi.duplicateTask(...arguments);
  }

  createForEvaluation(task: TaskInterface, evaluationSubjectIds: number[]) {
    // @ts-expect-error TS 2556
    return this.SDKTaskApi.createForEvaluation(...arguments);
  }

  destroyEvaluationTasks(taskIds: number[]) {
    // @ts-expect-error TS 2556
    return this.SDKTaskApi.destroyEvaluationTasks(...arguments);
  }

  destroyTasks(taskIds: number[]) {
    // @ts-expect-error TS 2556
    return this.SDKTaskApi.destroyTasks(...arguments);
  }

  startEvaluation(taskId: number) {
    // @ts-expect-error TS 2556
    return this.SDKTaskApi.startEvaluation(...arguments);
  }

  updateEvaluationScoreListSubjects(taskId: number, evaluationSubjectIds: number[]) {
    // @ts-expect-error TS 2556
    return this.SDKTaskApi.updateEvaluationScoreListSubjects(...arguments);
  }
}
