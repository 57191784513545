import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EduContentBookApi, EduContentTOCApi, PersonApi, SchoolApi, TaskApi } from '../+api';
import { BearerHttpService } from '../bearer-http';
import { DalOptions, DAL_OPTIONS } from '../dal.module';
import {
  BookDataResponseInterface,
  DataServiceInterface,
  PersonDataResponseInterface,
  SchoolDataResponseInterface,
  TaskDataResponseInterface,
  TocDataResponseInterface,
} from './data.service.interface';

@Injectable({
  providedIn: 'root',
})
export class DataService implements DataServiceInterface {
  private bearerHttpService = inject(BearerHttpService);
  private dalOptions = inject<DalOptions>(DAL_OPTIONS);
  private EduContentTocApi = inject(EduContentTOCApi);

  private apiBaseUrl = this.dalOptions.apiV2BaseUrl;

  constructor(
    private personApi: PersonApi,
    private schoolApi: SchoolApi,
    private taskApi: TaskApi,
    private bookApi: EduContentBookApi
  ) {}

  getAllForUser(
    userId: number,
    fields: Array<keyof PersonDataResponseInterface>,
    since?: number,
    v2?: boolean
  ): Observable<PersonDataResponseInterface> {
    if (fields.length === 0) return of({});
    if (v2) {
      const paramObj = {
        fields: JSON.stringify(fields),
      };

      if (since) {
        paramObj['since'] = since;
      }
      const params = new HttpParams({
        fromObject: paramObj,
      });

      return this.bearerHttpService.get(`${this.apiBaseUrl}/database/data`, params);
    }

    return this.personApi.getData(userId, fields, since);
  }

  getAllForSchool(schoolId: number): Observable<SchoolDataResponseInterface> {
    return this.schoolApi.getData(schoolId);
  }

  getAllForTask(taskId: number, fields: Array<keyof TaskDataResponseInterface>): Observable<TaskDataResponseInterface> {
    if (fields.length === 0) return of({});

    return this.taskApi.getData(taskId, fields.join());
  }

  getAllForBook(bookId: number, fields: Array<keyof BookDataResponseInterface>): Observable<BookDataResponseInterface> {
    if (fields.length === 0) return of({});

    return this.bookApi.getData(bookId, fields.join());
  }

  getAllForToc(tocId: number, fields: Array<keyof TocDataResponseInterface>): Observable<TocDataResponseInterface> {
    if (fields.length === 0) return of({});

    return this.EduContentTocApi.getData(tocId, fields.join());
  }
}
