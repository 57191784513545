import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EduContentMetadataInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './edu-content-metadata.reducer';

export const selectEduContentMetadataState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectEduContentMetadataState, (state: State) => state.error);

export const getLoaded = createSelector(selectEduContentMetadataState, (state: State) => state.loaded);

export const getAll = createSelector(selectEduContentMetadataState, selectAll);

export const getCount = createSelector(selectEduContentMetadataState, selectTotal);

export const getIds = createSelector(selectEduContentMetadataState, selectIds);

export const getAllEntities = createSelector(selectEduContentMetadataState, selectEntities);

export const getEduContentMetadatasLoadedForBook = createSelector(
  selectEduContentMetadataState,
  (state, props: { bookId: number }) => {
    return !!state.loadedForBook[props.bookId];
  }
);

export const getEduContentMetadatasLoadedPublishedForBook = createSelector(
  selectEduContentMetadataState,
  (state, props: { bookId: number }) => {
    return !!state.loadedPublishedForBook[props.bookId];
  }
);

export const getEduContentMetadatasLoadedDraftForBook = createSelector(
  selectEduContentMetadataState,
  (state, props: { bookId: number }) => {
    return !!state.loadedDraftForBook[props.bookId];
  }
);

export const getEduContentMetadatasLoadedWithArtifact = createSelector(
  selectEduContentMetadataState,
  (state) => !!state.loadedWithArtifact
);

export const getAllDraft = createSelector(getAll, (eduContentMetadatas: EduContentMetadataInterface[]) =>
  eduContentMetadatas.filter((metadata) => metadata.published === null)
);

export const getAllPublished = createSelector(getAll, (eduContentMetadatas: EduContentMetadataInterface[]) =>
  eduContentMetadatas.filter((metadata) => metadata.published !== null)
);

export const getEduContentMetadataByArtifactId = createSelector(
  getAllDraft,
  (eduContentMetadatas: EduContentMetadataInterface[]): Dictionary<EduContentMetadataInterface[]> =>
    groupArrayByKeys(eduContentMetadatas, ['artifactId'])
);
