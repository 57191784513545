import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { PurchaseProductQueries } from '.';
import { PurchaseProductServiceInterface, PURCHASE_PRODUCT_SERVICE_TOKEN } from '../../purchase-product';
import { DalState } from '../dal.state.interface';
import {
  LoadPurchaseProducts,
  PurchaseProductsActionTypes,
  PurchaseProductsLoaded,
  PurchaseProductsLoadError,
} from './purchase-product.actions';

@Injectable()
export class PurchaseProductEffects {
  loadPurchaseProducts$ = createEffect(() =>
    this.actions.pipe(
      ofType(PurchaseProductsActionTypes.LoadPurchaseProducts),
      concatLatestFrom(() => this.store.select(PurchaseProductQueries.getLoaded)),
      fetch({
        run: (action: LoadPurchaseProducts, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.purchaseProductService
            .getAllForUser(action.payload.userId)
            .pipe(map((purchaseProducts) => new PurchaseProductsLoaded({ purchaseProducts })));
        },
        onError: (action: LoadPurchaseProducts, error) => {
          return new PurchaseProductsLoadError(error);
        },
      })
    )
  );

  constructor(
    @Inject('uuid')
    private uuid,
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(PURCHASE_PRODUCT_SERVICE_TOKEN)
    private purchaseProductService: PurchaseProductServiceInterface
  ) {}
}
