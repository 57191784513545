import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { EvaluationScoreListInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum EvaluationScoreListActionTypes {
  EvaluationScoreListsLoaded = '[EvaluationScoreLists] EvaluationScoreLists Loaded',
  EvaluationScoreListsLoadError = '[EvaluationScoreLists] Load Error',
  LoadEvaluationScoreLists = '[EvaluationScoreLists] Load EvaluationScoreLists',
  AddEvaluationScoreList = '[EvaluationScoreLists] Add EvaluationScoreList',
  AddEvaluationScoreLists = '[EvaluationScoreLists] Add EvaluationScoreLists',
  AddEvaluationScoreListsForBook = '[EvaluationScoreLists] Add EvaluationScoreLists for Book',
  UpsertEvaluationScoreList = '[EvaluationScoreLists] Upsert EvaluationScoreList',
  UpsertEvaluationScoreLists = '[EvaluationScoreLists] Upsert EvaluationScoreLists',
  UpdateEvaluationScoreList = '[EvaluationScoreLists] Update EvaluationScoreList',
  UpdateEvaluationScoreLists = '[EvaluationScoreLists] Update EvaluationScoreLists',
  DeleteEvaluationScoreList = '[EvaluationScoreLists] Delete EvaluationScoreList',
  DeleteEvaluationScoreLists = '[EvaluationScoreLists] Delete EvaluationScoreLists',
  ClearEvaluationScoreLists = '[EvaluationScoreLists] Clear EvaluationScoreLists',
  AddEvaluationScoreListsForTask = '[EvaluationScoreLists] Add EvaluationScoreLists for Task',
}

export class LoadEvaluationScoreLists implements Action {
  readonly type = EvaluationScoreListActionTypes.LoadEvaluationScoreLists;

  constructor(public payload: { force?: boolean; bookId: number } = { bookId: null }) {}
}

export class EvaluationScoreListsLoaded implements Action {
  readonly type = EvaluationScoreListActionTypes.EvaluationScoreListsLoaded;

  constructor(public payload: { evaluationScoreLists: EvaluationScoreListInterface[] }) {}
}

export class EvaluationScoreListsLoadError implements Action {
  readonly type = EvaluationScoreListActionTypes.EvaluationScoreListsLoadError;
  constructor(public payload: any) {}
}

export class AddEvaluationScoreList implements Action {
  readonly type = EvaluationScoreListActionTypes.AddEvaluationScoreList;

  constructor(public payload: { evaluationScoreList: EvaluationScoreListInterface }) {}
}

export class UpsertEvaluationScoreList implements Action {
  readonly type = EvaluationScoreListActionTypes.UpsertEvaluationScoreList;

  constructor(public payload: { evaluationScoreList: EvaluationScoreListInterface }) {}
}

export class AddEvaluationScoreLists implements Action {
  readonly type = EvaluationScoreListActionTypes.AddEvaluationScoreLists;

  constructor(public payload: { evaluationScoreLists: EvaluationScoreListInterface[] }) {}
}

export class AddEvaluationScoreListsForBook implements Action {
  readonly type = EvaluationScoreListActionTypes.AddEvaluationScoreListsForBook;

  constructor(public payload: { evaluationScoreLists: EvaluationScoreListInterface[]; bookId: number }) {}
}

export class UpsertEvaluationScoreLists implements Action {
  readonly type = EvaluationScoreListActionTypes.UpsertEvaluationScoreLists;

  constructor(public payload: { evaluationScoreLists: EvaluationScoreListInterface[] }) {}
}

export class UpdateEvaluationScoreList implements FeedbackTriggeringAction {
  readonly type = EvaluationScoreListActionTypes.UpdateEvaluationScoreList;

  constructor(
    public payload: {
      evaluationScoreList: Update<EvaluationScoreListInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpdateEvaluationScoreLists implements Action {
  readonly type = EvaluationScoreListActionTypes.UpdateEvaluationScoreLists;

  constructor(public payload: { evaluationScoreLists: Update<EvaluationScoreListInterface>[] }) {}
}

export class DeleteEvaluationScoreList implements FeedbackTriggeringAction {
  readonly type = EvaluationScoreListActionTypes.DeleteEvaluationScoreList;

  constructor(public payload: { id: number; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export class DeleteEvaluationScoreLists implements FeedbackTriggeringAction {
  readonly type = EvaluationScoreListActionTypes.DeleteEvaluationScoreLists;

  constructor(public payload: { ids: number[]; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export class ClearEvaluationScoreLists implements Action {
  readonly type = EvaluationScoreListActionTypes.ClearEvaluationScoreLists;
}

export class AddEvaluationScoreListsForTask implements Action {
  readonly type = EvaluationScoreListActionTypes.AddEvaluationScoreListsForTask;

  constructor(public payload: { evaluationScoreLists: EvaluationScoreListInterface[]; taskId: number }) {}
}

export type EvaluationScoreListActions =
  | LoadEvaluationScoreLists
  | EvaluationScoreListsLoaded
  | EvaluationScoreListsLoadError
  | AddEvaluationScoreList
  | AddEvaluationScoreListsForBook
  | UpsertEvaluationScoreLists
  | AddEvaluationScoreLists
  | UpsertEvaluationScoreLists
  | UpdateEvaluationScoreList
  | UpdateEvaluationScoreLists
  | DeleteEvaluationScoreList
  | DeleteEvaluationScoreLists
  | ClearEvaluationScoreLists
  | AddEvaluationScoreListsForTask;
