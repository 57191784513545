<div
  class="[ fixed imposter ] [ p-l max-w-full w-3xl ] [ surface corner-l ] [ elevation-5 index-overlay no-elevation-layer ]"
>
  <div class="[ stack ] [ items-center text-center ] [ overflow-visible ]">
    <div class="[ title-large ]" *ngIf="activeStep < 4">Hoe interpreteer je resultaten als leerkracht?</div>
    <div class="[ title-large ]" *ngIf="activeStep >= 4">Wat ziet een leerling per doel?</div>

    <div class="[ relative ]">
      <div class="[ w-full overflow-hidden relative ]">
        <div class="[ flex flex-nowrap ]" [style.width.%]="steps.length * 100">
          <div
            class="[ w-full center-items ] [ transform transition-transform transition-long-3 ]"
            [style.--c-translate-x]="-100 * (activeStep - 1) + '%'"
          >
            <div class="[ stack ] [ items-center text-center ]">
              <div class="[ p-b-m ] [ transform scale-200 ]">
                <campus-percentage-meter
                  *ngIf="teacherProgressAnimation$ | async as anim"
                  [status]="anim.status"
                  [percentage]="anim.progress"
                  class="scale-100"
                  >{{ anim.progress }}%</campus-percentage-meter
                >
              </div>
              <div class="[ body-large ]">Bekwaamheid op een doel</div>

              <div class="[ body-small ]">
                Dit geeft een <strong>schatting van de bekwaamheid</strong> van een leerling <br />
                om een oefening gericht <strong>op dit doel</strong> met succes uit te voeren.
              </div>
            </div>
          </div>
          <div
            class="[ w-full center-items ] [ transform transition-transform transition-long-3 ]"
            [style.--c-translate-x]="-100 * (activeStep - 1) + '%'"
          >
            <div class="[ stack ] [ items-center text-center ]">
              <div class="[ p-b-m ] [ transform scale-200 ]">
                <campus-percentage-meter [percentage]="null">?</campus-percentage-meter>
              </div>
              <div class="[ body-large ]">Bekwaamheid op een doel</div>

              <div class="[ body-small ]">
                Dit geeft aan dat er voorlopig <strong>te weinig gegevens</strong> zijn <br />
                om de <strong>bekwaamheid op dit doel</strong> van een leerling in te schatten.
              </div>
            </div>
          </div>
          <div
            class="[ w-full center-items ] [ transform transition-transform transition-long-3 ]"
            [style.--c-translate-x]="-100 * (activeStep - 1) + '%'"
          >
            <div class="[ stack ] [ items-center text-center ]">
              <div class="[ p-b-m ] [ transform scale-200 translate-x-0 ]">
                <campus-score
                  *ngIf="teacherProgressAnimation$ | async as anim"
                  [result]="{ score: anim.progress, status: 'completed' }"
                  >?</campus-score
                >
              </div>
              <div class="[ body-large ]">Score op een oefening</div>

              <div class="[ body-small ]">
                Dit geeft aan <strong>hoeveel</strong> een leerling heeft <strong>gescoord</strong> op een oefening.<br />
                <span class="flex items-center gap-3xs">
                  Je kan de score bekijken via het leerpad met deze knop
                  <campus-icon svgIcon="learning-path"></campus-icon>
                </span>
              </div>
            </div>
          </div>
          <div
            class="[ w-full center-items ] [ transform transition-transform transition-long-3 ]"
            [style.--c-translate-x]="-100 * (activeStep - 1) + '%'"
          >
            <div class="[ stack ] [ items-center text-center ]">
              <div class="[ p-b-m ]">
                <campus-concept-button
                  *ngIf="studentProgressAnimation$ | async as anim"
                  [showAbility]="true"
                  [active]="true"
                  [done]="false"
                  [disabled]="false"
                  [mode]="anim.mode"
                  [ability]="anim.progress"
                  [selectorOptions]="selectorOptions"
                  [selectorIndex]="anim.selectorIndex"
                >
                  <campus-icon
                    concept-button-icon
                    [svgIcon]="anim.mode === LoopModeEnum.MASTER ? 'lightbulb' : 'diamond'"
                  ></campus-icon>
                  <concept-button-label>Naam van het doel</concept-button-label>
                </campus-concept-button>
              </div>
              <div class="[ body-large ]">Voortgang op het doel</div>

              <div class="[ body-small ]">
                <div class="[ stack stack-space-3xs ]">
                  <div>De ksnap-o-meter werkt met 2 bogen.</div>
                  <div>De eerste boog geeft aan als het doel voldoende is beheerst.</div>
                  <div>De tweede boog motiveert om een nog hoger niveau te halen.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="[ absolute left-0 top-1/2 ] [ transform -translate-1/2 ]">
        <button outline-icon-button (click)="previousStep()" [disabled]="activeStep === 1" icon="arrow-previous">
        </button>
      </div>
      <div class="[ absolute right-0 top-1/2 ] [ transform -translate-y-1/2 translate-x-1/2 ]">
        <button outline-icon-button (click)="nextStep()" [disabled]="activeStep === steps.length" icon="arrow-next">
        </button>
      </div>
    </div>

    <div class="[ cluster ]">
      <div>
        <button
          inline-icon-button
          *ngFor="let step of steps"
          class="[ border ] [ icon-size-m ] [ cursor-pointer ] [ transition-colors ]"
          [ngClass]="
            step === activeStep
              ? ['primary-container', 'border-primary']
              : step < activeStep
              ? ['secondary-container', 'border-secondary']
              : ['surface-variant']
          "
          (click)="goToStep(step)"
          [icon]="step < 4 ? 'teacher' : 'students'"
        >
        </button>
      </div>
    </div>

    <div class="[ flex flex-wrap items-center justify-end ] [ p-bs-xl border-top ] [ w-full gap-m ]">
      <div class="[ flex items-center gap-3xs ]">
        <input
          type="checkbox"
          name="remember"
          id="remember"
          class="[ m-b-0 m-i-3xs ] [ cursor-pointer ]"
          [value]="rememberHide"
          (change)="rememberHide = $event.target.checked"
        />
        <label for="remember" class="[ label-medium ] [ cursor-pointer ]">Niet meer tonen</label>
      </div>
      <button filled-button (click)="clickClose.emit(rememberHide)">Ik begrijp het!</button>
    </div>
  </div>
</div>
<div class="[ fixed right-0 left-0 top-0 bottom-0 ] [ index-scrim ] [ backdrop-blur-l ]"></div>
