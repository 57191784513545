import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@campus/browser';
import { ENVIRONMENT_API_TOKEN, EnvironmentApiInterface } from '@campus/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EduContentsClearResponseInterface, EduContentsUnlinkResponseInterface } from '.';
import { EduContentBookApi, PersonApi } from '../+api';
import { EduContentBookInterface } from '../+models';
import {
  EduContentBookServiceInterface,
  EduContentsClearedInterface,
  EduContentsUnlinkedInterface,
} from './edu-content-book.service.interface';

@Injectable({
  providedIn: 'root',
})
export class EduContentBookService implements EduContentBookServiceInterface {
  constructor(
    private personApi: PersonApi,
    private eduContentBookApi: EduContentBookApi,
    @Inject(WINDOW) private window: Window,
    @Inject(ENVIRONMENT_API_TOKEN)
    private environmentApi: EnvironmentApiInterface
  ) {}

  getAllForUser(userId: number): Observable<EduContentBookInterface[]> {
    return this.personApi
      .getData(userId, 'allowedEduContentBooks')
      .pipe(map((res: { allowedEduContentBooks: EduContentBookInterface[] }) => res.allowedEduContentBooks));
  }

  clearEduContents(bookId: number): Observable<EduContentsClearedInterface> {
    return (
      this.eduContentBookApi.clearEduContent(bookId) as Observable<{
        result: { errors: EduContentsClearResponseInterface[]; succeeded: EduContentsClearResponseInterface[] };
      }>
    ).pipe(
      map(({ result }) => ({
        errors: result.errors,
        success: result.succeeded,
      }))
    );
  }

  unlinkEduContents(bookId: number): Observable<EduContentsUnlinkedInterface> {
    return (
      this.eduContentBookApi.unlinkEduContentsRemote(bookId) as Observable<{
        result: { errors: EduContentsUnlinkResponseInterface[]; succeeded: EduContentsUnlinkResponseInterface[] };
      }>
    ).pipe(
      map(({ result }) => ({
        errors: result.errors,
        success: result.succeeded,
      }))
    );
  }

  openBoeke(bookId: number, productId: number, eduContentTocId?: number) {
    let params = new HttpParams();
    if (productId) {
      params = params.set('productId', productId);
    }
    if (eduContentTocId) {
      params = params.set('eduContentTocId', eduContentTocId);
    }
    const url = `${this.environmentApi.APIBase}/api/eduContentBooks/${bookId}/open-boeke`;

    const param = params.keys().length ? '?' + params.toString() : '';

    this.window.open(url + param);
  }

  updateEduContentBook(bookId: number, update: Partial<EduContentBookInterface>) {
    return this.eduContentBookApi.patchAttributes(bookId, update) as Observable<EduContentBookInterface>;
  }
}
