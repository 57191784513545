import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { ProductContentInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum ProductContentsActionTypes {
  ProductContentsLoaded = '[ProductContents] ProductContents Loaded',
  ProductContentsLoadError = '[ProductContents] Load Error',
  LoadProductContents = '[ProductContents] Load ProductContents',
  StartAddManyProductContents = '[ProductContents] Start Add ProductContents',
  AddProductContent = '[ProductContents] Add ProductContent',
  UpsertProductContent = '[ProductContents] Upsert ProductContent',
  AddProductContents = '[ProductContents] Add ProductContents',
  UpsertProductContents = '[ProductContents] Upsert ProductContents',
  UpdateProductContent = '[ProductContents] Update ProductContent',
  UpdateProductContents = '[ProductContents] Update ProductContents',
  DeleteProductContent = '[ProductContents] Delete ProductContent',
  DeleteProductContents = '[ProductContents] Delete ProductContents',
  ClearProductContents = '[ProductContents] Clear ProductContents',
}

export class LoadProductContents implements Action {
  readonly type = ProductContentsActionTypes.LoadProductContents;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class ProductContentsLoaded implements Action {
  readonly type = ProductContentsActionTypes.ProductContentsLoaded;

  constructor(public payload: { productContents: ProductContentInterface[] }) {}
}

export class ProductContentsLoadError implements Action {
  readonly type = ProductContentsActionTypes.ProductContentsLoadError;
  constructor(public payload: any) {}
}

export class StartAddManyProductContents implements Action {
  readonly type = ProductContentsActionTypes.StartAddManyProductContents;

  constructor(
    public payload: {
      userId: number;
      productContents: ProductContentInterface[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class AddProductContent implements Action {
  readonly type = ProductContentsActionTypes.AddProductContent;

  constructor(public payload: { productContent: ProductContentInterface }) {}
}

export class UpsertProductContent implements Action {
  readonly type = ProductContentsActionTypes.UpsertProductContent;

  constructor(public payload: { productContent: ProductContentInterface }) {}
}

export class AddProductContents implements Action {
  readonly type = ProductContentsActionTypes.AddProductContents;

  constructor(public payload: { productContents: ProductContentInterface[] }) {}
}

export class UpsertProductContents implements Action {
  readonly type = ProductContentsActionTypes.UpsertProductContents;

  constructor(public payload: { productContents: ProductContentInterface[] }) {}
}

export class UpdateProductContent implements Action {
  readonly type = ProductContentsActionTypes.UpdateProductContent;

  constructor(public payload: { productContent: Update<ProductContentInterface> }) {}
}

export class UpdateProductContents implements Action {
  readonly type = ProductContentsActionTypes.UpdateProductContents;

  constructor(public payload: { productContents: Update<ProductContentInterface>[] }) {}
}

export class DeleteProductContent {
  readonly type = ProductContentsActionTypes.DeleteProductContent;

  constructor(
    public payload: {
      id: number;
    }
  ) {}
}

export class DeleteProductContents implements FeedbackTriggeringAction {
  readonly type = ProductContentsActionTypes.DeleteProductContents;

  constructor(
    public payload: {
      ids: number[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class ClearProductContents implements Action {
  readonly type = ProductContentsActionTypes.ClearProductContents;
}

export type ProductContentsActions =
  | LoadProductContents
  | ProductContentsLoaded
  | ProductContentsLoadError
  | AddProductContent
  | StartAddManyProductContents
  | UpsertProductContent
  | AddProductContents
  | UpsertProductContents
  | UpdateProductContent
  | UpdateProductContents
  | DeleteProductContent
  | DeleteProductContents
  | ClearProductContents;
