import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { RoleInterface } from '../../+models';
import { RolesActions, RolesActionTypes } from './role.actions';

export const NAME = 'roles';

export interface State extends EntityState<RoleInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<RoleInterface> = createEntityAdapter<RoleInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: RolesActions): State {
  switch (action.type) {
    case RolesActionTypes.RolesLoaded: {
      return adapter.setAll(action.payload.roles, {
        ...state,
        loaded: true,
      });
    }

    case RolesActionTypes.RolesLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
