<div class="ui-card__badge-container" *ngIf="showBadge">
  <ng-content select="campus-card-badge"></ng-content>
</div>
<ng-content select="campus-card-selected-icon"></ng-content>

<ng-content select="campus-card-image"></ng-content>
<div class="ui-card__header">
  <ng-content select="campus-card-avatar"></ng-content>
  <div class="ui-card__header-text">
    <ng-content select="campus-card-overline"></ng-content>
    <ng-content select="campus-card-title"></ng-content>
    <ng-content select="campus-card-subtitle"></ng-content>
  </div>
  <ng-content select="campus-card-header-actions"></ng-content>
</div>
<ng-content select="campus-card-content"></ng-content>
<ng-content select="campus-card-actions"></ng-content>
<ng-content select="campus-card-footer"></ng-content>
