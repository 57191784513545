<div class="[ stack stack-space-m center-items content-center ] [ max-w-xl ]">
  <campus-icon *ngIf="icon" [svgIcon]="icon" class="[ secundary ]"></campus-icon>
  <p class="[ on-surface text-center headline-small ]">{{ title }}</p>

  <div *ngIf="message" class="[ overflow-auto max-h-2xs ]">
    <campus-froala-viewer class="[ on-surface body-medium ]" [content]="message"></campus-froala-viewer>
  </div>

  <div class="[ flex gap-s flex-row justify-self-end ]">
    <button *ngIf="cancelButtonLabel" campusClickStopPropagation inline-button (click)="cancel()">
      {{ cancelButtonLabel }}
    </button>

    <button
      *ngIf="confirmButtonLabel"
      campusClickStopPropagation
      filled-button
      (click)="confirm()"
      [class.error]="confirmButtonType === 'danger'"
    >
      {{ confirmButtonLabel }}
    </button>
  </div>
</div>
