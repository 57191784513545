<div class="ui-banner">
  <div class="ui-banner__wrapper">
    <campus-expansion-panels *ngIf="htmlMessage" [readonly]="!htmlMessage.content">
      <campus-expansion-panel class="ui-banner__expansion">
        <campus-expansion-panel-header>
          <div class="ui-banner__content">
            <campus-icon class="ui-banner__content__icon" *ngIf="icon" [svgIcon]="icon"></campus-icon>
            <div class="ui-banner__content__text" data-cy="banner-content" [innerHTML]="htmlMessage.title"></div>
          </div>
          <div class="ui-banner__buttons">
            <campus-icon
              *ngIf="htmlMessage.content"
              campus-expansion-panel-header-icon
              svgIcon="expand-more"
            ></campus-icon>
            <campus-button
              data-cy="banner-action"
              bordered
              flat
              *ngFor="let action of actions"
              (click)="onAction(action.userAction)"
              >{{ action.title }}</campus-button
            >
          </div>
        </campus-expansion-panel-header>
        <campus-expansion-panel-content>
          <div [innerHTML]="htmlMessage.content"></div>
        </campus-expansion-panel-content>
      </campus-expansion-panel>
    </campus-expansion-panels>
  </div>
</div>
