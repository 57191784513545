import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { EvaluationSubjectInterface } from '../../+models';
import { EduContentTocsActions, EduContentTocsActionTypes } from '../edu-content-toc/edu-content-toc.actions';
import { EvaluationSubjectActions, EvaluationSubjectsActionTypes } from './evaluation-subject.actions';

export const NAME = 'evaluationSubjects';

export interface State extends EntityState<EvaluationSubjectInterface> {
  // additional entities state properties
  loaded: boolean;
  loadedForBook: {
    [bookId: number]: boolean;
  };
  error?: any;
}

export const adapter: EntityAdapter<EvaluationSubjectInterface> = createEntityAdapter<EvaluationSubjectInterface>({
  sortComparer: (a, b) => a.displayOrder - b.displayOrder || a.id - b.id,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForBook: {},
});

export function reducer(state = initialState, action: EvaluationSubjectActions | EduContentTocsActions): State {
  switch (action.type) {
    case EvaluationSubjectsActionTypes.AddEvaluationSubject: {
      return adapter.addOne(action.payload.evaluationSubject, state);
    }

    case EvaluationSubjectsActionTypes.UpsertEvaluationSubject: {
      const { evaluationSubject } = action.payload;

      // remove nested state (available through evaluationSubjectGoal state)
      const withoutGoalIds = {
        ...evaluationSubject,
        goalIds: undefined,
      };
      return adapter.upsertOne(withoutGoalIds, state);
    }

    case EvaluationSubjectsActionTypes.AddEvaluationSubjects: {
      return adapter.addMany(action.payload.evaluationSubjects, state);
    }
    case EvaluationSubjectsActionTypes.AddEvaluationSubjectsForBook: {
      const newState = adapter.addMany(action.payload.evaluationSubjects, state);

      return {
        ...newState,
        loadedForBook: {
          ...newState.loadedForBook,
          [action.payload.bookId]: true,
        },
      };
    }

    case EvaluationSubjectsActionTypes.UpsertEvaluationSubjects: {
      return adapter.upsertMany(action.payload.evaluationSubjects, state);
    }

    case EvaluationSubjectsActionTypes.UpdateEvaluationSubject: {
      const { evaluationSubject } = action.payload;

      // remove nested state
      const withoutGoalIds = {
        ...evaluationSubject,
        changes: { ...evaluationSubject.changes, goalIds: undefined },
      };

      return adapter.updateOne(withoutGoalIds, state);
    }

    case EvaluationSubjectsActionTypes.UpdateEvaluationSubjects: {
      return adapter.updateMany(action.payload.evaluationSubjects, state);
    }

    case EvaluationSubjectsActionTypes.DeleteEvaluationSubject: {
      return adapter.removeOne(action.payload.id, state);
    }

    case EvaluationSubjectsActionTypes.DeleteEvaluationSubjects: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case EvaluationSubjectsActionTypes.EvaluationSubjectsLoaded: {
      return adapter.setAll(action.payload.evaluationSubjects, { ...state, loaded: true });
    }

    case EvaluationSubjectsActionTypes.EvaluationSubjectsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case EvaluationSubjectsActionTypes.ClearEvaluationSubjects: {
      return adapter.removeAll(state);
    }

    case EduContentTocsActionTypes.DeleteEvaluation: {
      // remove linked subjects
      const { id: eduContentTocId } = action.payload;
      const idsToDelete = Object.values(state.entities)
        .filter((evaluationSubject) => evaluationSubject.eduContentTOCId === eduContentTocId)
        .map((eS) => eS.id);
      return adapter.removeMany(idsToDelete, state);
    }

    case EduContentTocsActionTypes.MoveEvaluation: {
      const { oldEduContentTOCId, newEduContentTOCId } = action.payload;
      const updates: Update<EvaluationSubjectInterface>[] = Object.values(state.entities)
        .filter((entity) => entity.eduContentTOCId === oldEduContentTOCId)
        .map((e) => ({ id: e.id, changes: { eduContentTOCId: newEduContentTOCId } }));
      return adapter.updateMany(updates, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
