import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ConceptYearInterface } from '../../+models';
import { ConceptYearsActions, ConceptYearsActionTypes } from './concept-year.actions';

export const NAME = 'conceptYears';

export interface State extends EntityState<ConceptYearInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<ConceptYearInterface> = createEntityAdapter<ConceptYearInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: ConceptYearsActions): State {
  switch (action.type) {
    case ConceptYearsActionTypes.AddConceptYear: {
      return adapter.addOne(action.payload.conceptYear, state);
    }

    case ConceptYearsActionTypes.UpsertConceptYear: {
      return adapter.upsertOne(action.payload.conceptYear, state);
    }

    case ConceptYearsActionTypes.AddConceptYears: {
      return adapter.addMany(action.payload.conceptYears, state);
    }

    case ConceptYearsActionTypes.UpsertConceptYears: {
      return adapter.upsertMany(action.payload.conceptYears, state);
    }

    case ConceptYearsActionTypes.UpdateConceptYear: {
      return adapter.updateOne(action.payload.conceptYear, state);
    }

    case ConceptYearsActionTypes.UpdateConceptYears: {
      return adapter.updateMany(action.payload.conceptYears, state);
    }

    case ConceptYearsActionTypes.DeleteConceptYear: {
      return adapter.removeOne(action.payload.id, state);
    }

    case ConceptYearsActionTypes.DeleteConceptYears: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case ConceptYearsActionTypes.ConceptYearsLoaded: {
      return adapter.setAll(action.payload.conceptYears, {
        ...state,
        loaded: true,
      });
    }

    case ConceptYearsActionTypes.ConceptYearsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case ConceptYearsActionTypes.ClearConceptYears: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
