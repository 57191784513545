import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { GoalPrerequisiteInterface } from '../../+models';
import { GoalPrerequisiteActions, GoalPrerequisiteActionTypes } from './goal-prerequisite.actions';

export const NAME = 'goalPrerequisites';

export interface State extends EntityState<GoalPrerequisiteInterface> {
  // additional entities state properties
  loaded: boolean;
  loadedForLearningArea: Dictionary<boolean>;
  error?: any;
}

export const adapter: EntityAdapter<GoalPrerequisiteInterface> = createEntityAdapter<GoalPrerequisiteInterface>({
  selectId: (entity) => entity.id,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForLearningArea: {},
});

export function reducer(state = initialState, action: GoalPrerequisiteActions): State {
  switch (action.type) {
    case GoalPrerequisiteActionTypes.GoalPrerequisitesLoaded: {
      return adapter.setAll(action.payload.goalPrerequisites, {
        ...state,
        loaded: true,
      });
    }

    case GoalPrerequisiteActionTypes.GoalPrerequisitesLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case GoalPrerequisiteActionTypes.AddGoalPrerequisite: {
      return adapter.addOne(action.payload.goalPrerequisite, state);
    }

    case GoalPrerequisiteActionTypes.DeleteGoalPrerequisite: {
      return adapter.removeOne(action.payload.id, state);
    }

    case GoalPrerequisiteActionTypes.DeleteGoalPrerequisites: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case GoalPrerequisiteActionTypes.AddGoalPrerequisitesForLearningArea: {
      const newState = adapter.addMany(action.payload.goalPrerequisites, state);

      return {
        ...newState,
        loadedForLearningArea: {
          ...newState.loadedForLearningArea,
          [action.payload.learningAreaId]: true,
        },
      };
    }

    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities } = adapter.getSelectors();
