import { BundleInterface } from './Bundle.interface';
import { ContentInterface } from './Content.interface';
import { EduContent } from './EduContent';
import { EduContentInterface } from './EduContent.interface';
import { EduContentBookInterface } from './EduContentBook.interface';
import { HistoryInterface } from './History.interface';
import { TaskInterface } from './Task.interface';

export enum HistoryTypesEnum {
  AREA = 'area',
  SEARCH = 'search',
  EDUCONTENT = 'educontent',
  BUNDLE = 'bundle',
  TASK = 'task',
  BOEKE = 'boek-e',
  HISTORY = 'history',
  METHOD = 'method',
}

export function createHistoryFromEduContent(eduContent: EduContentInterface, created = new Date()): HistoryInterface {
  return {
    name: eduContent.publishedEduContentMetadata ? eduContent.publishedEduContentMetadata.title : '',
    type: eduContent.type === 'boek-e' ? HistoryTypesEnum.BOEKE : HistoryTypesEnum.EDUCONTENT,
    eduContentId: eduContent.id,
    created,
    learningAreaId: eduContent.publishedEduContentMetadata
      ? eduContent.publishedEduContentMetadata.learningAreaId
      : null,
  };
}

export function createHistoryFromBoeke(book: EduContentBookInterface, created = new Date()): HistoryInterface {
  return {
    name: book.title,
    type: HistoryTypesEnum.BOEKE,
    eduContentBookId: book.id,
    created,
    learningAreaId: book?.method?.learningAreaId,
  };
}

export function createHistoryFromTask(task: TaskInterface, created = new Date()): HistoryInterface {
  return {
    name: task.name,
    type: HistoryTypesEnum.TASK,
    taskId: task.id,
    created,
    learningAreaId: task.learningAreaId,
  };
}
export function createHistoryFromBundle(bundle: BundleInterface, created = new Date()): HistoryInterface {
  return {
    name: bundle.name,
    type: HistoryTypesEnum.BUNDLE,
    bundleId: bundle.id,
    created,
    learningAreaId: bundle.learningAreaId,
  };
}

export function createHistoryFromContent(content: ContentInterface): HistoryInterface {
  if (content instanceof EduContent) {
    return createHistoryFromEduContent(content);
  }

  // if it's not an instance of EduContent,
  // the provided content does not have the required properties for creating a history object
  return null;
}
