<div *ngIf="menuOpen" (click)="menuToggle.emit(false)" @fade class="ui-shell__menu-scrim"></div>

<div class="ui-shell__app-bar">
  <ng-content select="[campusShellLogo],campus-shell-logo"></ng-content>

  <campus-icon
    *ngIf="navigateTo; else defaultBackdropIcon"
    (click)="clickNavigateTo()"
    [svgIcon]="pageIcon"
    [attr.data-usetiful]="usetifulTag"
    data-cy="shell-navigate-to"
    class="ui-shell__icon ui-shell__icon--navigate-to"
    campusClickStopPropagation
    @fadeRotate
  ></campus-icon>

  <ng-template #defaultBackdropIcon>
    <campus-icon
      class="ui-shell__icon ui-shell__icon--desktop"
      data-cy="default-backdrop-icon"
      [svgIcon]="pageIcon"
    ></campus-icon>

    <campus-icon
      svgIcon="menu"
      class="ui-shell__icon ui-shell__icon--mobile"
      (click)="menuToggle.emit(!menuOpen)"
      campusClickStopPropagation
      data-cy="backdrop-menu-icon"
      @fadeRotate
    ></campus-icon>
  </ng-template>

  <h1 data-cy="shell-title" class="ui-shell__app-title ui-text-ellipsis">{{ pageTitle }}</h1>

  <div data-cy="backdrop-breadcrumb" class="ui-caption ui-shell__app-subtitle ui-breadcrumbs-host"></div>

  <ng-content select="[campusShellActions],campus-shell-actions"></ng-content>
</div>

<div class="ui-shell__menu" [class.ui-shell__menu--open]="menuOpen" [attr.usetiful-menu-open]="menuOpen">
  <nav class="ui-shell__nav" [campusLottieTriggerFor]="lottie">
    <ng-content select="[campusShellLeft],campus-shell-left"></ng-content>
  </nav>

  <campus-lottie lottieKey="menu-bg" class="ui-shell__menu-animation"></campus-lottie>
  <campus-lottie [lottieKey]="lottieIcon" class="ui-shell__menu-animation" #lottie="lottie"></campus-lottie>
</div>
<div class="ui-shell__toolbar [ background ] [ border-is index-content-on-sticky ]" id="ui-shell__toolbar"> </div>

<div
  class="ui-shell__body --scroll-viewport [ background ][ border-is ]"
  [style.--scroll-viewport-top]="scrollViewPortTop$ | async"
>
  <div class="ui-shell__body-header ui-sticky-left" id="ui-shell__body-header"> </div>

  <main class="[ background p-0 ] ui-shell__body-container">
    <campus-banner
      *ngIf="bannerFeedback"
      class="ui-banner--dialog"
      [id]="bannerFeedback.id"
      [message]="bannerFeedback.message"
      [icon]="bannerFeedback.icon"
      [actions]="bannerFeedback.userActions"
      (afterDismiss)="bannerDismiss.emit($event)"
    ></campus-banner>
    <ng-content select="[campusShellBody],campus-shell-body"></ng-content>
  </main>
</div>

<footer class="ui-shell__footer">
  <ng-content select="[campusShellBottom],campus-shell-bottom"></ng-content>
</footer>
