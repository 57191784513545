import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { LevelQueries } from '.';
import { LevelServiceInterface, LEVEL_SERVICE_TOKEN } from '../../metadata/level.service.interface';
import { DalState } from '../dal.state.interface';
import { LevelsActionTypes, LevelsLoaded, LevelsLoadError, LoadLevels } from './level.actions';

@Injectable()
export class LevelEffects {
  loadLevels$ = createEffect(() =>
    this.actions.pipe(
      ofType(LevelsActionTypes.LoadLevels),
      concatLatestFrom(() => this.store.select(LevelQueries.getLoaded)),
      fetch({
        run: (action: LoadLevels, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.levelService.getAll().pipe(map((levels) => new LevelsLoaded({ levels })));
        },
        onError: (action: LoadLevels, error) => {
          return new LevelsLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(LEVEL_SERVICE_TOKEN)
    private levelService: LevelServiceInterface
  ) {}
}
