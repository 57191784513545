import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MethodProductGroupInterface } from '../../+models';
import { MethodProductGroupsActions, MethodProductGroupsActionTypes } from './method-product-group.actions';

export const NAME = 'methodProductGroups';

export function sortBySequence(a: MethodProductGroupInterface, b: MethodProductGroupInterface): number {
  return a.sequence - b.sequence || a.id - b.id;
}

export interface State extends EntityState<MethodProductGroupInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<MethodProductGroupInterface> = createEntityAdapter<MethodProductGroupInterface>({
  sortComparer: sortBySequence,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: MethodProductGroupsActions): State {
  switch (action.type) {
    case MethodProductGroupsActionTypes.AddMethodProductGroup: {
      return adapter.addOne(action.payload.methodProductGroup, state);
    }

    case MethodProductGroupsActionTypes.UpsertMethodProductGroup: {
      return adapter.upsertOne(action.payload.methodProductGroup, state);
    }

    case MethodProductGroupsActionTypes.AddMethodProductGroups: {
      return adapter.addMany(action.payload.methodProductGroups, state);
    }

    case MethodProductGroupsActionTypes.UpsertMethodProductGroups: {
      return adapter.upsertMany(action.payload.methodProductGroups, state);
    }

    case MethodProductGroupsActionTypes.UpdateMethodProductGroup: {
      return adapter.updateOne(action.payload.methodProductGroup, state);
    }

    case MethodProductGroupsActionTypes.UpdateMethodProductGroups: {
      return adapter.updateMany(action.payload.methodProductGroups, state);
    }

    case MethodProductGroupsActionTypes.DeleteMethodProductGroup: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MethodProductGroupsActionTypes.DeleteMethodProductGroups: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case MethodProductGroupsActionTypes.MethodProductGroupsLoaded: {
      return adapter.setAll(action.payload.methodProductGroups, {
        ...state,
        loaded: true,
      });
    }

    case MethodProductGroupsActionTypes.MethodProductGroupsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case MethodProductGroupsActionTypes.ClearMethodProductGroups: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
