import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GroupPersonInterface } from '../../+models';
import { GroupInterface } from '../../+models/Group.interface';
import { GroupPersonQueries } from '../group-person';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './group.reducer';

export const selectGroupState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectGroupState, (state: State) => state.error);

export const getLoaded = createSelector(selectGroupState, (state: State) => state.loaded);

export const getAll = createSelector(selectGroupState, selectAll);

export const getCount = createSelector(selectGroupState, selectTotal);

export const getIds = createSelector(selectGroupState, selectIds);

export const getAllEntities = createSelector(selectGroupState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * group$: GroupInterface[] = this.store.pipe(
    select(GroupQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectGroupState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * group$: GroupInterface = this.store.pipe(
    select(GroupQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectGroupState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getGroupsByStudentId = createSelector(
  getAllEntities,
  GroupPersonQueries.getByPersonId,
  (groups: Dictionary<GroupInterface>, groupPersonDict: Dictionary<GroupPersonInterface[]>) => {
    return Object.keys(groupPersonDict).reduce((dict, personId) => {
      dict[personId] = groupPersonDict[personId].map(({ groupId }) => groups[groupId]);
      return dict;
    }, {} as Dictionary<GroupInterface[]>);
  }
);

export const getAllEntitiesWithStudentIds = createSelector(
  getAll,
  GroupPersonQueries.getByGroupId,
  (groups: GroupInterface[], groupPersonDict: Dictionary<GroupPersonInterface[]>): Dictionary<GroupInterface> => {
    return groups.reduce((dict, group) => {
      dict[group.id] = {
        ...group,
        studentIds: (groupPersonDict[group.id] || []).map(({ personId: studentId }) => studentId),
      };
      return dict;
    }, {} as Dictionary<GroupInterface>);
  }
);

export const getAllWithStudentIds = createSelector(
  getAll,
  GroupPersonQueries.getByGroupId,
  (groups: GroupInterface[], groupPersonDict: Dictionary<GroupPersonInterface[]>): GroupInterface[] => {
    return groups.map((group) => ({
      ...group,
      studentIds: (groupPersonDict[group.id] || []).map(({ personId: studentId }) => studentId),
    }));
  }
);

export const hasGroups = createSelector(getAll, (groups: GroupInterface[]) => groups && !!groups.length);
