import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { PurchaseInterface, SchoolBillingAddressInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum PurchasesActionTypes {
  PurchasesLoaded = '[Purchases] Purchases Loaded',
  PurchasesLoadError = '[Purchases] Load Error',
  LoadPurchases = '[Purchases] Load Purchases',
  LoadPurchasesForSchoolYear = '[Purchases] Load Purchases For School Year',
  StartAddManyPurchases = '[Purchases] Start Add Purchases',
  StartUpdatePurchase = '[Purchases] Start Update Purchase',
  AddPurchase = '[Purchases] Add Purchase',
  UpsertPurchase = '[Purchases] Upsert Purchase',
  AddPurchases = '[Purchases] Add Purchases',
  UpsertPurchases = '[Purchases] Upsert Purchases',
  UpdatePurchase = '[Purchases] Update Purchase',
  UpdatePurchases = '[Purchases] Update Purchases',
  DeletePurchase = '[Purchases] Delete Purchase',
  DeletePurchases = '[Purchases] Delete Purchases',
  ClearPurchases = '[Purchases] Clear Purchases',
  PlaceOrder = '[Purchases] Place Order',
  CreatePurchase = '[Purchases] CreatePurchase',
  FinalisePurchases = '[Purchases] Finalise Purchases',
}

export class LoadPurchases implements Action {
  readonly type = PurchasesActionTypes.LoadPurchases;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}
export class LoadPurchasesForSchoolYear implements Action {
  readonly type = PurchasesActionTypes.LoadPurchasesForSchoolYear;

  constructor(
    public payload: { force?: boolean; userId: number; schoolYear: number } = {
      userId: null,
      schoolYear: null,
    }
  ) {}
}

export class PurchasesLoaded implements Action {
  readonly type = PurchasesActionTypes.PurchasesLoaded;

  constructor(public payload: { purchases: PurchaseInterface[] }) {}
}

export class PurchasesLoadError implements Action {
  readonly type = PurchasesActionTypes.PurchasesLoadError;
  constructor(public payload: any) {}
}

export class StartAddManyPurchases implements Action {
  readonly type = PurchasesActionTypes.StartAddManyPurchases;

  constructor(
    public payload: {
      userId: number;
      schoolId: number;
      purchases: PurchaseInterface[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartUpdatePurchase implements Action {
  readonly type = PurchasesActionTypes.StartUpdatePurchase;

  constructor(
    public payload: {
      schoolId: number;
      purchase: Update<PurchaseInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class AddPurchase implements FeedbackTriggeringAction {
  readonly type = PurchasesActionTypes.AddPurchase;

  constructor(
    public payload: {
      purchase: PurchaseInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpsertPurchase implements Action {
  readonly type = PurchasesActionTypes.UpsertPurchase;

  constructor(public payload: { purchase: PurchaseInterface }) {}
}

export class AddPurchases implements Action {
  readonly type = PurchasesActionTypes.AddPurchases;

  constructor(public payload: { purchases: PurchaseInterface[] }) {}
}

export class UpsertPurchases implements Action {
  readonly type = PurchasesActionTypes.UpsertPurchases;

  constructor(public payload: { purchases: PurchaseInterface[] }) {}
}

export class UpdatePurchase implements FeedbackTriggeringAction {
  readonly type = PurchasesActionTypes.UpdatePurchase;

  constructor(
    public payload: {
      schoolId: number;
      purchase: Update<PurchaseInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpdatePurchases implements Action {
  readonly type = PurchasesActionTypes.UpdatePurchases;

  constructor(public payload: { purchases: Update<PurchaseInterface>[] }) {}
}

export class DeletePurchase implements FeedbackTriggeringAction {
  readonly type = PurchasesActionTypes.DeletePurchase;

  constructor(
    public payload: {
      id: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class DeletePurchases implements FeedbackTriggeringAction {
  readonly type = PurchasesActionTypes.DeletePurchases;

  constructor(
    public payload: {
      ids: number[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class ClearPurchases implements Action {
  readonly type = PurchasesActionTypes.ClearPurchases;
}

export class PlaceOrder implements FeedbackTriggeringAction {
  readonly type = PurchasesActionTypes.PlaceOrder;

  constructor(
    public payload: {
      schoolId: number;
      productIds: number[];
      fullDigital?: number[];
      customerOrderReference?: string;
      schoolBillingAddress?: SchoolBillingAddressInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class CreatePurchase implements FeedbackTriggeringAction {
  readonly type = PurchasesActionTypes.CreatePurchase;

  constructor(
    public payload: {
      schoolId: number;
      purchase: PurchaseInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}
export class FinalisePurchases implements FeedbackTriggeringAction {
  readonly type = PurchasesActionTypes.FinalisePurchases;

  constructor(
    public payload: {
      purchaseIds?: number[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export type PurchasesActions =
  | LoadPurchases
  | PurchasesLoaded
  | PurchasesLoadError
  | AddPurchase
  | StartAddManyPurchases
  | StartUpdatePurchase
  | UpsertPurchase
  | AddPurchases
  | UpsertPurchases
  | UpdatePurchase
  | UpdatePurchases
  | DeletePurchase
  | DeletePurchases
  | ClearPurchases
  | CreatePurchase
  | FinalisePurchases
  | PlaceOrder;
