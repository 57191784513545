import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { optimisticUpdate } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { EvaluationScoreListService } from '../../evaluation-score-list';
import { DalState } from '../dal.state.interface';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { EvaluationScoreListActionTypes, UpdateEvaluationScoreList } from './evaluation-score-list.actions';

@Injectable()
export class EvaluationScoreListEffects {
  updateEvaluationScoreList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EvaluationScoreListActionTypes.UpdateEvaluationScoreList),
      optimisticUpdate({
        run: (action: UpdateEvaluationScoreList) => {
          const { id, changes } = action.payload.evaluationScoreList;

          return this.evaluationScoreListService
            .updateEvaluationScoreList({
              id: id as number,
              ...changes,
            })
            .pipe(
              map(() => {
                const effectFeedback = EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'De scorelijst werd bijgewerkt.'
                );
                return new AddEffectFeedback({ effectFeedback });
              })
            );
        },
        undoAction: (action: UpdateEvaluationScoreList, error) => {
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            'Het is niet gelukt om de scorelijst bij te werken.'
          );
          return new AddEffectFeedback({
            effectFeedback,
          });
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private evaluationScoreListService: EvaluationScoreListService,
    @Inject('uuid')
    private uuid,
    private actions: Actions,
    private store: Store<DalState>
  ) {}
}
