import { Action } from '@ngrx/store';
import { BreadcrumbLinkInterface, ListFormat, NavItem } from '../../+external-interfaces';
import { PageHeaderInterface } from '../../+external-interfaces/page-header.interface';
import { RolesEnum } from '../../+models';
import { UiState } from './ui.reducer';

export enum UiActionTypes {
  LoadUi = '[Ui] Load Ui',
  UiLoaded = '[Ui] Ui Loaded',
  SaveUi = '[Ui] Ui Save To Storage',
  SetListFormat = '[Ui] Ui Set List Format',
  ToggleSideSheet = '[Ui] Ui Toggle Side Sheet',
  ToggleNavOpen = '[Ui] Ui Toggle Nav Open',
  SetNavOpen = '[Ui] Ui Set Nav Open',
  SetDenseMenu = '[Ui] Ui Set Dense Menu',
  SetDisableGlobalBookFilter = '[Ui] Ui Set Disable Global Book Filter',
  SetBreadcrumbs = '[Ui] Ui Set Breadcrumbs',
  SetIsRootComponent = '[Ui] Ui Set Is Root Component',
  SetHeaderDetails = '[Ui] Ui Set Header Details',
  UpdateNavItem = '[Ui] Ui Update NavItem',

  SaveSettings = '[Ui] Ui Save Settings',
  SetTourRoles = '[UI] Set Tour Roles',
  SetPageNavigationOpen = '[UI] Set Page Navigation Open',
}

export class LoadUi implements Action {
  readonly type = UiActionTypes.LoadUi;
  constructor(public payload: { force?: boolean } = {}) {}
}

export class UiLoaded implements Action {
  readonly type = UiActionTypes.UiLoaded;
  constructor(public payload: { state: UiState }) {}
}

export class SaveUi implements Action {
  readonly type = UiActionTypes.SaveUi;
}

export class SetListFormat implements Action {
  readonly type = UiActionTypes.SetListFormat;
  constructor(public payload: { listFormat: ListFormat }) {}
}

export class ToggleSideSheet implements Action {
  readonly type = UiActionTypes.ToggleSideSheet;
}

export class ToggleNavOpen implements Action {
  readonly type = UiActionTypes.ToggleNavOpen;
  constructor(public payload?: { open?: boolean }) {}
}

export class SetTourRoles implements Action {
  readonly type = UiActionTypes.SetTourRoles;
  constructor(public payload?: { tourRoles?: RolesEnum[] }) {}
}

export class SetNavOpen implements Action {
  readonly type = UiActionTypes.SetNavOpen;
  constructor(public payload: { open: boolean }) {}
}
export class SetDenseMenu implements Action {
  readonly type = UiActionTypes.SetDenseMenu;
  constructor(public payload: { denseMenu: boolean }) {}
}

export class SetDisableGlobalBookFilter implements Action {
  readonly type = UiActionTypes.SetDisableGlobalBookFilter;
  constructor(public payload: { disableGlobalBookFilter: boolean }) {}
}

export class SetBreadcrumbs implements Action {
  readonly type = UiActionTypes.SetBreadcrumbs;
  constructor(public payload: { breadcrumbs: BreadcrumbLinkInterface[] }) {}
}

export class SetIsRootComponent implements Action {
  readonly type = UiActionTypes.SetIsRootComponent;
  constructor(public payload: { isRootComponent: boolean }) {}
}

export class SetPageHeaderDetails implements Action {
  readonly type = UiActionTypes.SetHeaderDetails;
  constructor(public payload: { headerDetails: PageHeaderInterface }) {}
}

export class UpdateNavItem implements Action {
  readonly type = UiActionTypes.UpdateNavItem;
  constructor(public payload: { navItem: NavItem }) {}
}

export class SaveSettings implements Action {
  readonly type = UiActionTypes.SaveSettings;
  constructor(public payload: { [key: string]: any }) {}
}

export class SetPageNavigationOpen implements Action {
  readonly type = UiActionTypes.SetPageNavigationOpen;
  constructor(public payload: { open: boolean }) {}
}

export type UiAction =
  | LoadUi
  | UiLoaded
  | SaveUi
  | SetListFormat
  | ToggleSideSheet
  | ToggleNavOpen
  | SetNavOpen
  | SetDenseMenu
  | SetDisableGlobalBookFilter
  | SetTourRoles
  | SetBreadcrumbs
  | SetIsRootComponent
  | SetPageHeaderDetails
  | UpdateNavItem
  | SaveSettings
  | SetPageNavigationOpen;
