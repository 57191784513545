<div class="ui-breadcrumbs__holder">
  <div class="ui-breadcrumbs__holder__breadcrumbs">
    <a routerLink="{{ baseUrl }}">
      <campus-icon *ngIf="baseIcon; else baseText" [svgIcon]="baseIcon"></campus-icon>
      <ng-template #baseText>
        <span class="ui-breadcrumbs__crumb">{{ baseDisplayText }}</span>
      </ng-template>
    </a>
  </div>
  <div class="ui-breadcrumbs__holder__breadcrumbs" *ngIf="breadcrumbs.length > maxLength">
    <campus-icon
      *ngIf="separator; else character"
      [svgIcon]="separator"
      class="ui-breadcrumbs__separator"
    ></campus-icon>
    <span class="ui-breadcrumbs__crumb">{{ overflowedLinkString }}</span>
  </div>
  <div
    class="ui-breadcrumbs__holder__breadcrumbs"
    *ngFor="let breadcrumb of breadcrumbs | slice: -(maxLength - 1); let last = last"
  >
    <campus-icon
      *ngIf="separator; else character"
      [svgIcon]="separator"
      class="ui-breadcrumbs__separator"
    ></campus-icon>
    <a
      [routerLink]="!last || canClickLastItem ? breadcrumb.link : null"
      [queryParams]="breadcrumb.queryParams"
      class="ui-breadcrumbs__crumb"
      >{{ breadcrumb.displayText }}</a
    >
  </div>
</div>

<ng-template #character>
  <span class="ui-breadcrumbs__separator" [innerHTML]="separatorCharacter"></span>
</ng-template>
