import { LayoutModule } from '@angular/cdk/layout';
import { PortalModule } from '@angular/cdk/portal';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { BrowserModule, MaintenanceService, MAINTENANCE_CHECK_BASE_URL } from '@campus/browser';
import { ComponentsModule } from '@campus/components';
import { SearchModule } from '@campus/search';
import { UiModule } from '@campus/ui';
import { FilterService, FILTER_SERVICE_TOKEN, MapObjectConversionService, UtilsModule } from '@campus/utils';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HasPermissionDirective } from './auth/has-permission.directive';
import { PermissionService } from './auth/permission.service';
import {
  BooksTileComponent,
  ContentPreviewDialogComponent,
  EvaluationMethodSlideoutComponent,
  LearningPathComponent,
  LearningPlanGoalProgressManagementComponent,
  QuickLinkComponent,
  ResultGuideComponent,
  ScoreComponent,
  ToolbarActionsDirective,
  ToolbarComponent,
  ToolbarContainerDirective,
  ToolbarFabDirective,
  ToolbarListHeaderDirective,
  ToolbarNavigationDirective,
} from './components';
import { ChapterLessonSelectComponent } from './components/chapter-lesson-select';
import { CheckboxTreeComponent } from './components/checkbox-tree/checkbox-tree.component';
import { ConceptAbilityMeterComponent } from './components/concept-ability-meter/concept-ability-meter.component';
import { ConceptCardV2Component } from './components/concept-card-v2/concept-card-v2.component';
import { ConceptSegmentedAbilityMeterComponent } from './components/concept-segmented-ability-meter/concept-segmented-ability-meter.component';
import { EduContentResultLoaderComponent } from './components/edu-content-result-loader/edu-content-result-loader.component';
import { EvaluationStartedModalComponent } from './components/evaluation-started-modal/evaluation-started-modal.component';
import { EvaluationSubjectsSlideoutComponent } from './components/evaluations-subjects-slideout/evaluations-subjects-slideout.component';
import { FileUploadDropZoneComponent } from './components/file-upload-drop-zone/file-upload-drop-zone.component';
import { HeaderComponent } from './components/header/header.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { PageBarContainerComponent } from './components/page-bar-container/page-bar-container.component';
import { AbilityProgressGuideComponent } from './components/result-guide/ability-progress-guide.component';
import { ShareToComponent } from './components/share-to/share-to.component';
import { TaskCardComponent } from './components/task-card/task-card.component';
import { TocSelectComponent } from './components/toc-select';
import { CampusUIntTextFieldDirective, ClickOutsideDirective } from './directives';
import { CampusRouterlinkDirective } from './directives/campus-routerlink.directive';
import { CampusHttpInterceptor } from './interceptors/campus-http.interceptor';
import { AssetsPathPipe, MathJaxPipe, MethodsImagesPathPipe } from './pipes';
import { AlertToNotificationItemPipe } from './pipes/alert-to-notification/alert-to-notification-pipe';
import { EuroPipe } from './pipes/euro/euro-pipe';
import { MailToByCredentialPipe } from './pipes/mail-to/mail-to-credential-pipe';
import { MapPipe } from './pipes/map/map.pipe';
import { IsNumberPipe } from './pipes/number/is-number.pipe';
import { TextMappingPipe } from './pipes/text-mapping/text-mapping.pipe';
import {
  ContentOpenActionsStudentService,
  ShareToService,
  StudentTaskService,
  STUDENT_TASK_SERVICE_TOKEN,
} from './services';
import { EduContentCollectionManagerService } from './services/collection-manager/edu-content-collection-manager.service';
import { EDU_CONTENT_COLLECTION_MANAGER_SERVICE_TOKEN } from './services/collection-manager/edu-content-collection-manager.service.interface';
import { ContentOpenActionsService } from './services/content-actions/content-open-actions/content-open-actions.service';
import { CONTENT_OPEN_ACTIONS_SERVICE_TOKEN } from './services/content-actions/content-open-actions/content-open-actions.service.interface';
import { DefaultContentOpenerService } from './services/content-actions/content-open-actions/default-content-opener.service';
import { OPEN_STATIC_CONTENT_SERVICE_TOKEN } from './services/content/open-static-content.interface';
import { OpenStaticContentService } from './services/content/open-static-content.service';
import { FeedBackService, FEEDBACK_SERVICE_TOKEN } from './services/feedback';
import { SnackBarDefaultConfig, SNACKBAR_DEFAULT_CONFIG_TOKEN } from './services/feedback/snackbar.config';
import { GameLearningEventsService } from './services/game-learning-events/game-learning-events.service';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytics.service';
import { GOOGLE_ANALYTICS_SERVICE_TOKEN } from './services/google-analytics/google-analytics.service.interface';
import { MapperStudentStrandsService } from './services/practice/mapper.student-strands.service';
import { MAPPER_STUDENT_STRANDS_SERVICE_TOKEN } from './services/practice/mapper.student-strands.service.interface';
import { MapperStudentTaskService } from './services/task/mapper.student-task.service';
import { MAPPER_STUDENT_TASK_SERVICE_TOKEN } from './services/task/mapper.student-task.service.interface';

@NgModule({
  imports: [
    CommonModule,
    UiModule,
    PortalModule,
    LayoutModule,
    MatBadgeModule,
    RouterModule,
    MatSnackBarModule,
    MatDialogModule,
    MatSelectModule,
    MatButtonModule,
    MatListModule,
    MatTooltipModule,
    UtilsModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    HttpClientModule,
    MatInputModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    FormsModule,
    SearchModule,
    ComponentsModule,
    BrowserModule,
    CdkTreeModule,
    ImageCropperModule,
  ],
  declarations: [
    HeaderComponent,
    PageBarContainerComponent,
    LearningPathComponent,
    HasPermissionDirective,
    ClickOutsideDirective,
    MailToByCredentialPipe,
    CampusRouterlinkDirective,
    AlertToNotificationItemPipe,
    QuickLinkComponent,
    ContentPreviewDialogComponent,
    LearningPlanGoalProgressManagementComponent,
    ChapterLessonSelectComponent,
    AssetsPathPipe,
    TextMappingPipe,
    MethodsImagesPathPipe,
    FileUploadDropZoneComponent,
    EuroPipe,
    TaskCardComponent,
    TocSelectComponent,
    ToolbarComponent,
    ToolbarFabDirective,
    ToolbarActionsDirective,
    ToolbarNavigationDirective,
    ToolbarContainerDirective,
    ToolbarListHeaderDirective,
    BooksTileComponent,
    ScoreComponent,
    ShareToComponent,
    ResultGuideComponent,
    AbilityProgressGuideComponent,
    EvaluationSubjectsSlideoutComponent,
    EvaluationStartedModalComponent,
    MapPipe,
    EvaluationMethodSlideoutComponent,
    MathJaxPipe,
    IsNumberPipe,
    CheckboxTreeComponent,
    ImageCropperComponent,
    EduContentResultLoaderComponent,
    CampusUIntTextFieldDirective,
    ConceptAbilityMeterComponent,
    ConceptSegmentedAbilityMeterComponent,
    ConceptCardV2Component,
  ],
  exports: [
    TocSelectComponent,
    HeaderComponent,
    PortalModule,
    LayoutModule,
    PageBarContainerComponent,
    HasPermissionDirective,
    MailToByCredentialPipe,
    CampusRouterlinkDirective,
    ClickOutsideDirective,
    AlertToNotificationItemPipe,
    QuickLinkComponent,
    ContentPreviewDialogComponent,
    ChapterLessonSelectComponent,
    AssetsPathPipe,
    TextMappingPipe,
    MethodsImagesPathPipe,
    FileUploadDropZoneComponent,
    EuroPipe,
    LearningPathComponent,
    UtilsModule,
    TaskCardComponent,
    ToolbarComponent,
    ToolbarFabDirective,
    ToolbarActionsDirective,
    ToolbarNavigationDirective,
    ToolbarContainerDirective,
    ToolbarListHeaderDirective,
    BooksTileComponent,
    ScoreComponent,
    ShareToComponent,
    ResultGuideComponent,
    AbilityProgressGuideComponent,
    EvaluationSubjectsSlideoutComponent,
    EvaluationStartedModalComponent,
    MapPipe,
    EvaluationMethodSlideoutComponent,
    MathJaxPipe,
    IsNumberPipe,
    CheckboxTreeComponent,
    ImageCropperComponent,
    EduContentResultLoaderComponent,
    CampusUIntTextFieldDirective,
    ConceptAbilityMeterComponent,
    ConceptSegmentedAbilityMeterComponent,
    ConceptCardV2Component,
  ],
  providers: [
    AssetsPathPipe,
    TextMappingPipe,
    MapObjectConversionService,
    ShareToService,
    MaintenanceService,
    GameLearningEventsService,
    { provide: FILTER_SERVICE_TOKEN, useClass: FilterService },
    PermissionService,
    DefaultContentOpenerService,
    ContentOpenActionsStudentService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CampusHttpInterceptor,
      multi: true,
    },
    {
      provide: OPEN_STATIC_CONTENT_SERVICE_TOKEN,
      useClass: OpenStaticContentService,
    },
    { provide: FEEDBACK_SERVICE_TOKEN, useClass: FeedBackService },
    {
      provide: SNACKBAR_DEFAULT_CONFIG_TOKEN,
      useClass: SnackBarDefaultConfig,
    },
    {
      provide: EDU_CONTENT_COLLECTION_MANAGER_SERVICE_TOKEN,
      useClass: EduContentCollectionManagerService,
    },
    {
      provide: CONTENT_OPEN_ACTIONS_SERVICE_TOKEN,
      useClass: ContentOpenActionsService,
    },
    AlertToNotificationItemPipe,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: MAPPER_STUDENT_STRANDS_SERVICE_TOKEN,
      useClass: MapperStudentStrandsService,
    },
    {
      provide: MAPPER_STUDENT_TASK_SERVICE_TOKEN,
      useClass: MapperStudentTaskService,
    },
    { provide: STUDENT_TASK_SERVICE_TOKEN, useClass: StudentTaskService },
    {
      provide: GOOGLE_ANALYTICS_SERVICE_TOKEN,
      useClass: GoogleAnalyticsService,
    },
    {
      provide: MAINTENANCE_CHECK_BASE_URL,
      useValue: '',
    },
  ],
})
export class SharedModule {}
