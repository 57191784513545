import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PersonPreferenceInterface } from '../../+models';
import { PersonPreferencesActions, PersonPreferencesActionTypes } from './person-preference.actions';

export const NAME = 'personPreferences';

export interface State extends EntityState<PersonPreferenceInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<PersonPreferenceInterface> = createEntityAdapter<PersonPreferenceInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: PersonPreferencesActions): State {
  switch (action.type) {
    case PersonPreferencesActionTypes.PersonPreferencesLoaded: {
      return adapter.setAll(action.payload.personPreferences, {
        ...state,
        loaded: true,
      });
    }

    case PersonPreferencesActionTypes.PersonPreferencesLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case PersonPreferencesActionTypes.AddPersonPreference: {
      return adapter.addOne(action.payload.personPreference, state);
    }

    case PersonPreferencesActionTypes.DeletePersonPreference: {
      return adapter.removeOne(action.payload.id, state);
    }

    case PersonPreferencesActionTypes.UpsertPersonPreference: {
      return adapter.upsertOne(action.payload.personPreference, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
