import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NumberFunctions } from '@campus/utils';

export interface TaskCardCountInterface {
  label: string;
  amount: number;
  total: number;
}

export interface TaskCardActionInterface {
  handler(props: { task?: unknown; taskInstanceId?: number }): void;
  label: string;
  icon?: string;
  isPrimary?: boolean;
}

@Component({
  selector: 'campus-task-card',
  templateUrl: './task-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskCardComponent implements OnChanges {
  @Input()
  name: string;

  @Input()
  learningAreaName: string;

  @Input()
  required: TaskCardCountInterface;

  @Input()
  notRequired: TaskCardCountInterface;

  @Input()
  isUrgent = false;

  @Input()
  isFinished = false;

  @Input()
  dateLabel: string;

  @Input()
  actions: TaskCardActionInterface[];

  @HostBinding('class.cursor-pointer')
  cardAction: TaskCardActionInterface;

  @HostBinding('attr.data-cy')
  dataCy = 'task-card';

  totalSegments: number;
  filledSegments: number;

  inlineCardActions: TaskCardActionInterface[];
  primaryCardActions: TaskCardActionInterface[];

  requiredProgress: number;
  notRequiredProgress: number;

  @Output() actionClicked: EventEmitter<TaskCardActionInterface> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.required || changes.notRequired) {
      this.totalSegments = (this.notRequired?.total || 0) + (this.required?.total || 0);
      this.filledSegments = (this.notRequired?.amount || 0) + (this.required?.amount || 0);

      if (this.required) {
        this.requiredProgress = NumberFunctions.roundToPlaces((this.required.amount / this.required.total) * 100, 2);
      } else {
        this.requiredProgress = undefined;
      }
      if (this.notRequired) {
        this.notRequiredProgress = NumberFunctions.roundToPlaces(
          (this.notRequired.amount / this.notRequired.total) * 100,
          2
        );
      } else {
        this.notRequiredProgress = undefined;
      }
    }

    if (changes.actions) {
      if (this.actions.length === 1) {
        this.cardAction = this.actions[0];
        this.inlineCardActions = [];
        this.primaryCardActions = [];
      } else if (this.actions.length > 1) {
        this.inlineCardActions = this.actions.filter((action) => !action.isPrimary);
        this.primaryCardActions = this.actions.filter((action) => action.isPrimary);
        this.cardAction = null;
      }
    }
  }

  public clickAction(action: TaskCardActionInterface) {
    this.actionClicked.emit(action);
  }
}
