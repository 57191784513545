<div class="lpgp-management [ flex flex-column ] [ max-h-xl ] overflow-hidden">
  <p mat-dialog-title class="learning-plan-goal-progress-management__title"
    >Markeer een doel als behandeld voor {{ classGroup.name }}</p
  >
  <mat-dialog-content class="[ flex flex-column overflow-hidden ]">
    <code class="learning-plan-goal-progress-management__lpg"
      ><b>{{ learningPlanGoal.prefix }}</b> {{ learningPlanGoal.goal }}</code
    >

    <h3 class="mat-h3">Voeg je eigen project toe...</h3>
    <mat-form-field class="input-form">
      <input
        data-cy="lpgp-management-user-projects"
        type="text"
        placeholder="Eigen project"
        matInput
        [formControl]="inputFromControl"
        [matAutocomplete]="auto"
        class="input-full-width learning-plan-goal-progress-management__input"
      />
      <mat-autocomplete
        data-cy="lpgp-management-user-projects-autocomplete"
        #auto="matAutocomplete"
        [displayWith]="displayUserLesson"
      >
        <mat-option
          class="learning-plan-goal-progress-management__autocomplete-option"
          *ngFor="let userLesson of filteredUserLessons$ | async"
          [value]="userLesson"
        >
          {{ userLesson.description }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <h3 class="mat-h3">... of klik een les uit de methode aan</h3>
    <mat-selection-list
      class="[ overflow-auto ]"
      data-cy="lpgp-management-lessons"
      [disabled]="isSelectionListDisabled()"
      #selectionList
      (selectionChange)="selectionChanged()"
    >
      <mat-list-option
        *ngFor="let methodLesson of methodLessonsForBook$ | async"
        checkboxPosition="before"
        color="primary"
        [value]="methodLesson"
        class="learning-plan-goal-progress-management__list-option"
        >{{ methodLesson.values.join(' > ') }}</mat-list-option
      >
    </mat-selection-list>
  </mat-dialog-content>
  <mat-dialog-actions class="p-bs-s">
    <campus-button
      data-cy="lpgp-management-save-project"
      *ngIf="!isInputDisabled()"
      class="learning-plan-goal-progress-management__button learning-plan-goal-progress-management__button--save"
      primary
      [class.ui-button--disabled]="!isSelectionListDisabled()"
      (click)="saveForUserLesson()"
      >Opslaan (project)</campus-button
    >
    <campus-button
      data-cy="lpgp-management-save-lesson"
      *ngIf="isInputDisabled()"
      class="learning-plan-goal-progress-management__button learning-plan-goal-progress-management__button--save"
      primary
      (click)="saveForEduContentTOCselection()"
      >Opslaan (hoofdstuk)</campus-button
    >
    <campus-button
      data-cy="lpgp-management-close-dialog"
      class="learning-plan-goal-progress-management__button learning-plan-goal-progress-management__button--close"
      primary
      (click)="closeDialog()"
      >Sluiten</campus-button
    >
  </mat-dialog-actions>
</div>
