import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { EduContentTOCEvaluationInterface } from '../../+models';
import {
  EduContentTocEvaluationsActions,
  EduContentTocEvaluationsActionTypes,
} from './edu-content-toc-evaluation.actions';

export const NAME = 'eduContentTocEvaluations';

export interface State extends EntityState<EduContentTOCEvaluationInterface> {
  // additional entities state properties
  error?: any;
  loadedForBook: Dictionary<boolean>;
}

export const adapter: EntityAdapter<EduContentTOCEvaluationInterface> =
  createEntityAdapter<EduContentTOCEvaluationInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loadedForBook: {},
});

export function reducer(state = initialState, action: EduContentTocEvaluationsActions): State {
  switch (action.type) {
    case EduContentTocEvaluationsActionTypes.EduContentTocEvaluationsLoadError: {
      return { ...state, loadedForBook: {}, error: action.payload };
    }

    case EduContentTocEvaluationsActionTypes.AddEduContentTocEvaluationsForBook: {
      const { eduContentTocEvaluations, bookId } = action.payload;

      return adapter.upsertMany(eduContentTocEvaluations, {
        ...state,
        loadedForBook: { ...state.loadedForBook, [bookId]: true },
      });
    }

    case EduContentTocEvaluationsActionTypes.UpsertEduContentTocEvaluations: {
      const { eduContentTocEvaluations } = action.payload;

      return adapter.upsertMany(eduContentTocEvaluations, state);
    }

    case EduContentTocEvaluationsActionTypes.DeleteEduContentTocEvaluation: {
      return adapter.removeOne(action.payload.id, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectAll, selectEntities, selectTotal } = adapter.getSelectors();
