import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { DidacticFunctionQueries } from '.';
import {
  DidacticFunctionServiceInterface,
  DIDACTIC_FUNCTION_SERVICE_TOKEN,
} from '../../metadata/didactic-function.service.interface';
import { DalState } from '../dal.state.interface';
import {
  DidacticFunctionsActionTypes,
  DidacticFunctionsLoaded,
  DidacticFunctionsLoadError,
  LoadDidacticFunctions,
} from './didactic-function.actions';

@Injectable()
export class DidacticFunctionEffects {
  loadDidacticFunctions$ = createEffect(() =>
    this.actions.pipe(
      ofType(DidacticFunctionsActionTypes.LoadDidacticFunctions),
      concatLatestFrom(() => this.store.select(DidacticFunctionQueries.getLoaded)),
      fetch({
        run: (action: LoadDidacticFunctions, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.didacticFunctionService
            .getAll()
            .pipe(map((didacticFunctions) => new DidacticFunctionsLoaded({ didacticFunctions })));
        },
        onError: (action: LoadDidacticFunctions, error) => {
          return new DidacticFunctionsLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(DIDACTIC_FUNCTION_SERVICE_TOKEN)
    private didacticFunctionService: DidacticFunctionServiceInterface
  ) {}
}
