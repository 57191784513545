import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EduContentTOCEvaluationInterface, EduContentTOCInterface, EvaluationSubjectInterface } from '@campus/dal';
import { Dictionary } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { EvaluationSubjectsSlideoutViewModel } from './evaluation-subjects-slideout.viewmodel';

export interface EvaluationSubjectsGroupedByLearningDomain {
  id: number;
  name: string;
  evaluationSubjects: EvaluationSubjectInterface[];
}

export interface SelectedEduContentTocChangedEvent {
  tocId: number;
  evaluationId: number;
}

export interface SelectedHeaderInterface {
  checked: boolean;
  partiallyChecked: boolean;
}

@Component({
  selector: 'campus-evaluations-subjects-slideout',
  templateUrl: './evaluations-subjects-slideout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EvaluationSubjectsSlideoutViewModel],
})
export class EvaluationSubjectsSlideoutComponent implements OnChanges {
  @HostBinding('class')
  defaultClasses = ['flex', 'flex-column', 'min-w-xl', 'h-full'];

  @HostBinding('attr.data-cy')
  dataCy = 'slideout-evaluation-subjects';

  public selectedHeaders: Dictionary<SelectedHeaderInterface> = {};
  public filteredTocTree$: Observable<EduContentTOCInterface[]>;
  public currentGroupedEvaluationSubjects$: Observable<EvaluationSubjectsGroupedByLearningDomain[]>;
  public showGoalWarning$: Observable<boolean>;

  @Input() eduContentBookId: number;
  @Input() selectedEduContentTocId: number;
  @Input() scopeToSelectedToc: boolean;
  @Input() selectedEvaluationSubjects: Dictionary<boolean> = {};
  @Input() readOnly = false;

  @Output() selectedEvaluationSubjectsChanged: EventEmitter<number[]> = new EventEmitter();
  @Output() selectedEduContentTocChanged: EventEmitter<SelectedEduContentTocChangedEvent> = new EventEmitter();

  private viewModel = inject(EvaluationSubjectsSlideoutViewModel);
  private _cdr = inject(ChangeDetectorRef);

  private eduContentTocEvaluationsForSelectedTocId$: Observable<EduContentTOCEvaluationInterface[]>;
  private defaultSelectedEvaluationSubjects = {};

  constructor() {
    this.setupStreams();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.eduContentBookId) this.viewModel.setCurrentBookId(this.eduContentBookId);
    if (changes.selectedEduContentTocId) this.viewModel.setSelectedTocId(this.selectedEduContentTocId);
    if (changes.scopeToSelectedToc) this.viewModel.setScopeToSelectedToc(this.scopeToSelectedToc);
    if (changes.selectedEvaluationSubjects) {
      this.defaultSelectedEvaluationSubjects = { ...changes.selectedEvaluationSubjects.currentValue };
      this.updateGroupSelection();
    }
  }

  public selectTOC(toc: EduContentTOCInterface) {
    if (toc.depth === 0 || toc.id === this.selectedEduContentTocId) return;
    this.selectedEvaluationSubjects = {};
    this.selectedEduContentTocId = toc.id;
    this.viewModel.setSelectedTocId(this.selectedEduContentTocId);

    this.eduContentTocEvaluationsForSelectedTocId$.pipe(take(1)).subscribe((tocEvaluations) => {
      this.selectedEduContentTocChanged.emit({
        tocId: toc.id,
        evaluationId: tocEvaluations[0]?.evaluationId,
      });
    });
    this.selectCurrentEvaluationSubjects();
    this.emitSelection();
  }

  public selectCurrentEvaluationSubjects() {
    const isNew = !Object.keys(this.selectedEvaluationSubjects).length;
    if (isNew) {
      this.viewModel.evaluationSubjectsForSelectedTocDict$.pipe(take(1)).subscribe((dict) => {
        this.selectedEvaluationSubjects = dict;
        this.updateGroupSelection();
        this.emitSelection();
      });
    }
  }

  public onItemSelectionChange(
    event: MatCheckboxChange,
    evaluationSubjectId: number,
    group: EvaluationSubjectsGroupedByLearningDomain
  ): void {
    this.selectedEvaluationSubjects[evaluationSubjectId] = event.checked;
    this.updateGroupSelection();
    this.emitSelection();
  }

  public onGroupSelectionChange(event: MatCheckboxChange, group: EvaluationSubjectsGroupedByLearningDomain): void {
    if (event.checked) this.selectAll(group.evaluationSubjects);
    else this.removeAll(group.evaluationSubjects);

    this.updateGroupSelection();
    this.emitSelection();
  }

  public reset(): void {
    this.selectedEvaluationSubjects = { ...this.defaultSelectedEvaluationSubjects };
    this.updateGroupSelection();
  }

  public trackById(index: number, objectWithId: { id: number }): number {
    return objectWithId.id;
  }

  private setupStreams() {
    this.filteredTocTree$ = this.viewModel.filteredTocTree$;
    this.eduContentTocEvaluationsForSelectedTocId$ = this.viewModel.eduContentTocEvaluationsForSelectedTocId$;
    this.currentGroupedEvaluationSubjects$ = this.viewModel.evaluationSubjectsGroupedByLearningDomainForSelectedTocId$;
    this.showGoalWarning$ = this.getShowGoalWarning$();
  }

  private updateGroupSelection() {
    this.currentGroupedEvaluationSubjects$.pipe(take(1)).subscribe((groups) => {
      groups.forEach((group) => {
        const totalEvaluationSubjectsAmount = group.evaluationSubjects.length;
        const checkedEvaluationSubjectAmount = group.evaluationSubjects.filter((eS) => {
          return !!this.selectedEvaluationSubjects[eS.id];
        }).length;
        this.selectedHeaders[group.id] = {
          checked: !!checkedEvaluationSubjectAmount,
          partiallyChecked:
            checkedEvaluationSubjectAmount !== 0 && checkedEvaluationSubjectAmount !== totalEvaluationSubjectsAmount,
        };
      });
      this._cdr.markForCheck();
    });
  }

  private emitSelection(): void {
    const selectedEvaluationSubjectIds = Object.entries(this.selectedEvaluationSubjects)
      .filter(([_, value]) => !!value)
      .map(([key, _]) => +key);

    this.selectedEvaluationSubjectsChanged.emit(selectedEvaluationSubjectIds);
  }

  private selectAll(evaluationSubjects: EvaluationSubjectInterface[]) {
    evaluationSubjects.forEach((eS) => (this.selectedEvaluationSubjects[eS.id] = true));
  }

  private removeAll(evaluationSubjects: EvaluationSubjectInterface[]) {
    evaluationSubjects.forEach((eS) => (this.selectedEvaluationSubjects[eS.id] = false));
  }

  private getShowGoalWarning$(): Observable<boolean> {
    return this.currentGroupedEvaluationSubjects$.pipe(
      map((groups) => groups.some((group) => group.evaluationSubjects.some((subject) => !subject.goalIds?.length))),
      shareReplay(1)
    );
  }
}
