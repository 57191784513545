import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { LearningPlanGoalServiceInterface } from '.';
import { PersonApi } from '../+api';
import { LearningPlanGoalInterface } from '../+models';
import { DAL_OPTIONS, DalOptions } from '../dal.module';

@Injectable({
  providedIn: 'root',
})
export class LearningPlanGoalService implements LearningPlanGoalServiceInterface {
  private http = inject(HttpClient);
  private dalOptions = inject<DalOptions>(DAL_OPTIONS);

  constructor(private personApi: PersonApi) {}

  getLearningPlanGoals(
    searchTerm?: string,
    filters?: { [key: string]: string | number | number[] },
    pagination?: { from?; amount? },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<{ results: LearningPlanGoalInterface[]; count: number }> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/LearningPlanGoals/filtered`;
    const params = new HttpParams({
      fromObject: {
        searchTerm: searchTerm || '',
        filters: JSON.stringify(filters || {}),
        columns: JSON.stringify(columns || []),
        sorting: JSON.stringify(sorting || {}),
        pagination: JSON.stringify(pagination || {}),
      },
    });

    return this.http.get<{ results: LearningPlanGoalInterface[]; count: number }>(url, {
      params,
      withCredentials: true,
    });
  }
}
