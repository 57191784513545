import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { MaintenanceService, NO_INTERCEPT, WINDOW } from '@campus/browser';
import {
  EnvironmentErrorManagementFeatureAllowedErrorInterface,
  ENVIRONMENT_API_TOKEN,
  ENVIRONMENT_ERROR_MANAGEMENT_FEATURE_TOKEN,
} from '@campus/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export enum ErrorReason {
  ARCHIVE_PERSON_UNKNOWN = 'archive-person-unknown',
  WRONG_CHILD_CREDENTIALS = 'wrong-child-credentials',
}

@Injectable()
export class CampusHttpInterceptor implements HttpInterceptor {
  private router = inject(Router);
  private environmentApi = inject(ENVIRONMENT_API_TOKEN);
  private environmentErrorManagementFeature = inject(ENVIRONMENT_ERROR_MANAGEMENT_FEATURE_TOKEN);
  private maintenanceService = inject(MaintenanceService);
  private window = inject(WINDOW);

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isAPIRequest = request.url.startsWith(this.environmentApi.APIBase + '/api');
    const isMaintenancePage = this.router.routerState.snapshot.url.startsWith('/error/503');
    const checkMaintenanceMode = this.environmentErrorManagementFeature?.runMaintenanceCheckInInterceptor;

    if (isMaintenancePage && isAPIRequest) {
      return throwError(new Error('The API request was blocked as the user is on the maintenance error page.'));
    }

    if (request.context.get(NO_INTERCEPT)) {
      return next.handle(request);
    }

    request = this.attachArchiveHeaderIfNeeded(request);

    // run this parallel to the request handler, because we don't want to slow down existing requests
    // often, the response will be cached and will not result in an HTTP call
    if (!isMaintenancePage && checkMaintenanceMode) {
      this.maintenanceService.isMaintenanceMode().subscribe((isInMaintenance) => {
        if (isInMaintenance) {
          this.router.navigate(['/error', 503]);
        }
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (this.window.location.href.includes(this.environmentErrorManagementFeature.publicErrorRoute)) return;

        if (this.isManagedError(error) && !this.isAllowedError(error)) {
          const queryParams = this.getQueryParamsForError(error);

          const errorRoute = this.isOnPublicRoute(request.url)
            ? this.environmentErrorManagementFeature.publicErrorRoute
            : '/error';

          this.router.navigate([errorRoute, error.status], {
            queryParams,
          });
        }
        return throwError(error);
      })
    );
  }

  private isOnPublicRoute(route: string) {
    return this.environmentErrorManagementFeature.publicApiRoutes?.some((publicRoute) =>
      new RegExp(publicRoute, 'i').test(route)
    );
  }

  private getQueryParamsForError(error: HttpErrorResponse): Params {
    const queryParams: Params = {};

    const archiveYear = this.window.sessionStorage.getItem('archiveYear');
    if (error.status === 404 && archiveYear) {
      queryParams.reasonCode = ErrorReason.ARCHIVE_PERSON_UNKNOWN;
    }

    return queryParams;
  }

  private attachArchiveHeaderIfNeeded(request: HttpRequest<any>): HttpRequest<any> {
    const archiveYear = this.window.sessionStorage.getItem('archiveYear');
    if (!archiveYear) {
      return request;
    }

    return request.clone({
      headers: request.headers.set('x-archive', archiveYear),
    });
  }

  public isAllowedError(error: HttpErrorResponse): boolean {
    const isAllowed = this.environmentErrorManagementFeature.allowedErrors.some(
      (allowedError) =>
        checkProp(allowedError, 'status') &&
        checkProp(allowedError, 'name') &&
        checkProp(allowedError, 'statusText') &&
        checkUrlRegex(allowedError) &&
        checkMessageRegex(allowedError)
    );

    return isAllowed;

    function checkProp(
      allowedError: EnvironmentErrorManagementFeatureAllowedErrorInterface,
      prop: keyof EnvironmentErrorManagementFeatureAllowedErrorInterface
    ) {
      return !allowedError[prop] || allowedError[prop] === error[prop];
    }

    function checkUrlRegex(allowedError) {
      return !allowedError.urlRegex || new RegExp(allowedError.urlRegex, 'i').test(error.url);
    }

    function checkMessageRegex(allowedError) {
      return (
        !allowedError.messageRegex ||
        new RegExp(allowedError.messageRegex, 'i').test(error.message) ||
        (error.error &&
          error.error.error &&
          error?.error?.error?.message &&
          new RegExp(allowedError.messageRegex, 'i').test(error.error.error.message))
      );
    }
  }

  private isManagedError(error: HttpErrorResponse): boolean {
    return this.environmentErrorManagementFeature.managedStatusCodes.includes(error.status);
  }
}
