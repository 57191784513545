import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum EduContentHighScoreActionTypes {
  UpdateEduContentHighScore = '[EduContentHighScore] Update EduContentHighScore ',
}

export class UpdateEduContentHighScore implements FeedbackTriggeringAction {
  readonly type = EduContentHighScoreActionTypes.UpdateEduContentHighScore;

  constructor(
    public payload: {
      score: number;
      eduContentId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export type EduContentHighScoresActions = UpdateEduContentHighScore;
