import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { PersonProductInterface } from '../+models';

export const PERSON_PRODUCT_SERVICE_TOKEN = new InjectionToken<PersonProductServiceInterface>('PersonProductService');

export interface PersonProductServiceInterface {
  getAllForUser(userId: number): Observable<PersonProductInterface[]>;

  deletePersonProduct(personProductId: number): Observable<boolean>;
  deletePersonProducts(personProductIds: number[]): Observable<boolean>;
  addPersonProduct(personProduct: PersonProductInterface): Observable<PersonProductInterface>;
  addPersonProducts(personProducts: PersonProductInterface[]): Observable<PersonProductInterface[]>;
}
