import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'campus-selector-wheel',
  templateUrl: './selector-wheel.component.html',
})
export class SelectorWheelComponent {
  @HostBinding('class')
  defaultClasses = [
    'selector-wheel',
    'block',
    'overflow-hidden',
    'max-w-max',
    'list-none',
    'p-0',
    'label-small',
    'opacity-low',
  ];

  @Input()
  options: string[];

  @HostBinding('style.--selected-index')
  @Input()
  selectedIndex: number;
}
