import { Type } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { TocDataResponseInterface } from '../../../data';
import { EduContentActions } from '../../edu-content';
import { LoopLearnerStatusActions } from '../../loop-learner-profile';
import { SectionActions } from '../../section';
import { SectionContentActions } from '../../section-content';

const fieldActionMap: Dictionary<Type<Action>> = {
  loopLearnerStatuses: LoopLearnerStatusActions.AddLoopLearnerStatusesForToc,
  sections: SectionActions.AddSectionsForToc,
  sectionContents: SectionContentActions.AddSectionContentsForToc,
};

const fieldUpsertActionMap: Dictionary<Type<Action>> = {};

export class DataFieldActionFactory {
  /**
   * For a given field & data, return the appropriate 'Loaded' action containing the data.
   *
   * Example: field `"loopLearnerStatuses"` with data `[{id: 1}]` returns `AddLoopLearnerStatusesForToc({ loopLearnerStatuses: [{id: 1}], tocId: 123 })`
   */
  static getLoadedAction(tocId: number, field: keyof TocDataResponseInterface, fieldData: any, data?: any): Action {
    const fieldActionExceptions: Dictionary<(fieldData, responseData) => Action> = {
      sectionEduContents: (eduContents) => new EduContentActions.AddEduContentsForSectionForToc({ tocId, eduContents }),
    };

    if (fieldActionExceptions[field]) {
      return fieldActionExceptions[field](fieldData, data);
    }

    const FieldLoaded = fieldActionMap[field];

    return (
      FieldLoaded &&
      new FieldLoaded({
        tocId,
        [field]: fieldData,
      })
    );
  }

  /**
   * For a given field & data, return the appropriate 'Upsert' action containing the data.
   *
   * Example: field `"loopLearnerStatuses"` with data `[{id: 1}]` returns `UpsertLoopLearnerStatuses({ loopLearnerStatuses: [{id: 1}] })`
   */
  static getUpsertAction(tocId: number, field: keyof TocDataResponseInterface, fieldData: any, data?: any): Action {
    const fieldActionExceptions: Dictionary<(fieldData, responseData) => Action> = {};

    if (fieldActionExceptions[field]) {
      return fieldActionExceptions[field](fieldData, data);
    }

    const FieldUpsert = fieldUpsertActionMap[field];

    return (
      FieldUpsert &&
      new FieldUpsert({
        tocId,
        [field]: data,
      })
    );
  }
}
