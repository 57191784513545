import { Action } from '@ngrx/store';
import { GoalEduContentTOCInterface } from '../../+models/GoalEduContentTOC.interface';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback/effect-feedback.model';

export enum GoalEduContentTOCActionTypes {
  GoalEduContentTOCsLoaded = '[GoalEduContentTOCs] GoalEduContentTOCs Loaded',
  GoalEduContentTOCsLoadError = '[GoalEduContentTOCs] Load Error',
  LoadGoalEduContentTOCs = '[GoalEduContentTOCs] Load GoalEduContentTOCs',
  StartAddGoalEduContentTOC = '[GoalEduContentTOC] Start Add GoalEduContentTOC',
  DeleteGoalEduContentTOCs = '[GoalEduContentTOCs] Delete GoalEduContentTOCs',
  AddGoalEduContentTOCsForBook = '[GoalEduContentTOCs] Add GoalEduContentTOCs for Book',
  LoadGoalEduContentTOCsForBook = '[GoalEduContentTOCs] Load GoalEduContentTOCs for Book',
  DeleteGoalEduContentTOC = '[GoalEduContentTOCs] Delete GoalEduContentTOC',
  AddGoalEduContentTOC = '[GoalEduContentTOCs] Add GoalEduContentTOC',
}

export class LoadGoalEduContentTOCs implements Action {
  readonly type = GoalEduContentTOCActionTypes.LoadGoalEduContentTOCs;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class GoalEduContentTOCsLoaded implements Action {
  readonly type = GoalEduContentTOCActionTypes.GoalEduContentTOCsLoaded;

  constructor(public payload: { goalEduContentTOCs: GoalEduContentTOCInterface[] }) {}
}

export class GoalEduContentTOCsLoadError implements Action {
  readonly type = GoalEduContentTOCActionTypes.GoalEduContentTOCsLoadError;
  constructor(public payload: any) {}
}

export class DeleteGoalEduContentTOCs implements Action {
  readonly type = GoalEduContentTOCActionTypes.DeleteGoalEduContentTOCs;

  constructor(public payload: { ids: number[] }) {}
}

export class LoadGoalEduContentTOCsForBook implements Action {
  readonly type = GoalEduContentTOCActionTypes.LoadGoalEduContentTOCsForBook;

  constructor(public payload: { bookId: number; force?: boolean }) {}
}

export class AddGoalEduContentTOCsForBook implements Action {
  readonly type = GoalEduContentTOCActionTypes.AddGoalEduContentTOCsForBook;

  constructor(public payload: { bookId: number; goalEduContentTOCs: GoalEduContentTOCInterface[] }) {}
}
export class DeleteGoalEduContentTOC implements Action, FeedbackTriggeringAction {
  readonly type = GoalEduContentTOCActionTypes.DeleteGoalEduContentTOC;

  constructor(public payload: { id: number; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export class StartAddGoalEduContentTOC implements Action, FeedbackTriggeringAction {
  readonly type = GoalEduContentTOCActionTypes.StartAddGoalEduContentTOC;

  constructor(
    public payload: {
      goalEduContentTOC: GoalEduContentTOCInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class AddGoalEduContentTOC implements Action {
  readonly type = GoalEduContentTOCActionTypes.AddGoalEduContentTOC;

  constructor(public payload: { goalEduContentTOC: GoalEduContentTOCInterface }) {}
}

export type GoalEduContentTOCActions =
  | LoadGoalEduContentTOCs
  | DeleteGoalEduContentTOCs
  | DeleteGoalEduContentTOC
  | GoalEduContentTOCsLoaded
  | GoalEduContentTOCsLoadError
  | AddGoalEduContentTOCsForBook
  | LoadGoalEduContentTOCsForBook
  | StartAddGoalEduContentTOC
  | AddGoalEduContentTOC;
