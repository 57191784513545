import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { DalState, SchoolRoleMappingQueries } from '@campus/dal';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UniqueRootNumberValidator implements AsyncValidator {
  constructor(private store: Store<DalState>) {}

  private exclude: string;
  private schoolId: number;

  public setSchoolId(value: number) {
    this.schoolId = value;
  }
  public setExclude(value: string) {
    this.exclude = value;
  }

  validate(ctrl: AbstractControl): Observable<ValidationErrors | null> {
    if (this.exclude && this.exclude === ctrl.value) {
      return of(null);
    }
    if (!this.schoolId) {
      return of(null);
    }

    return this.store.select(SchoolRoleMappingQueries.getAll).pipe(
      take(1),
      map((schoolRoleMapping) => {
        const rootNumberExists = schoolRoleMapping.some(
          (srm) => srm.schoolId === this.schoolId && srm.rootNumber !== this.exclude && srm.rootNumber === ctrl.value
        );

        return rootNumberExists ? { uniqueRootNumber: true } : null;
      })
    );
  }
}
