import { InjectionToken } from '@angular/core';
import { TaskInterface, TaskWithAssigneesInterface } from '@campus/dal';
import { TaskActionInterface } from '../task-action.interface';

export type TaskActionType =
  | 'openTask'
  | 'openEvaluationTask'
  | 'archiveTask'
  | 'unarchiveTask'
  | 'openResultsForTask'
  | 'openResultsForEvaluationTask'
  | 'editTask'
  | 'openTocForTask'
  | 'startEvaluationTask'
  | 'stopEvaluationTask'
  | 'openWizard'
  | 'resumeTask';

export type TaskActionDictionary = Partial<Record<TaskActionType, TaskActionInterface>>;
export type PartialTaskActionDictionary = Partial<Record<TaskActionType, Partial<TaskActionInterface>>>;

export interface TaskActionsTeacherServiceInterface {
  taskActionDictionary: TaskActionDictionary;
  getActions(
    task: TaskWithAssigneesInterface,
    filter?: TaskActionType[],
    actionOrder?: TaskActionType[]
  ): TaskActionInterface[];
}

export interface TeacherTaskOpenerInterface {
  openTask?: (props: { task: TaskInterface }) => void;
  openEvaluationTask?: (props: { task: TaskInterface }) => void;
  archiveTask?: (props: { task: TaskWithAssigneesInterface }) => void;
  unarchiveTask?: (props: { task: TaskWithAssigneesInterface }) => void;
  openResultsForTask?: (props: { task: TaskInterface }) => void;
  openResultsForEvaluationTask?: (props: { task: TaskInterface }) => void;
  editTask?: (props: { task: TaskInterface }) => void;
  openTocForTask?: (props: { task: TaskInterface }) => void;
  startEvaluationTask?: (props: { task: TaskInterface }) => void;
  stopEvaluationTask?: (props: { task: TaskInterface }) => void;
  openWizard?: (props: { task: TaskInterface }) => void;
}

export const TEACHER_TASK_OPENER_TOKEN = new InjectionToken<TeacherTaskOpenerInterface>('TeacherTaskOpener');
