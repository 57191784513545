import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { BundleClassGroupInterface } from '../../+models';
import * as BundleClassGroupActions from './bundle-class-group.actions';

export const NAME = 'bundleClassGroups';

export interface State extends EntityState<BundleClassGroupInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<BundleClassGroupInterface> = createEntityAdapter<BundleClassGroupInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

const bundleClassGroupReducer = createReducer(
  initialState,
  on(BundleClassGroupActions.addBundleClassGroup, (state, { bundleClassGroup }) =>
    adapter.addOne(bundleClassGroup, state)
  ),
  on(BundleClassGroupActions.upsertBundleClassGroup, (state, { bundleClassGroup }) =>
    adapter.upsertOne(bundleClassGroup, state)
  ),
  on(BundleClassGroupActions.addBundleClassGroups, (state, { bundleClassGroups }) =>
    adapter.addMany(bundleClassGroups, state)
  ),
  on(BundleClassGroupActions.upsertBundleClassGroups, (state, { bundleClassGroups }) =>
    adapter.upsertMany(bundleClassGroups, state)
  ),
  on(BundleClassGroupActions.updateBundleClassGroup, (state, { bundleClassGroup }) =>
    adapter.updateOne(bundleClassGroup, state)
  ),
  on(BundleClassGroupActions.updateBundleClassGroups, (state, { bundleClassGroups }) =>
    adapter.updateMany(bundleClassGroups, state)
  ),
  on(BundleClassGroupActions.deleteBundleClassGroup, (state, { id }) => adapter.removeOne(id, state)),
  on(BundleClassGroupActions.deleteBundleClassGroups, (state, { ids }) => adapter.removeMany(ids, state)),
  on(BundleClassGroupActions.bundleClassGroupsLoaded, (state, { bundleClassGroups }) =>
    adapter.setAll(bundleClassGroups, { ...state, loaded: true })
  ),
  on(BundleClassGroupActions.bundleClassGroupsLoadError, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
  })),
  on(BundleClassGroupActions.clearBundleClassGroups, (state) => adapter.removeAll(state)),
  on(BundleClassGroupActions.updateBundleClassGroupsAccess, (state, { bundleId, bundleClassGroups }) => {
    const cleanedState = adapter.removeMany(
      (bundleClassGroup: BundleClassGroupInterface) => bundleClassGroup.bundleId === bundleId,
      state
    );
    const updatedState = adapter.addMany(bundleClassGroups, cleanedState);
    return updatedState;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return bundleClassGroupReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
