/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { TaskEduContentApi as SDKTaskEduContentApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { TaskEduContentInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class TaskEduContentApi extends ModelApiBase {
  private SDKTaskEduContentApi = inject(SDKTaskEduContentApi);

  constructor(api: PolpoApi) {
    super(api, 'TaskEduContents');
  }

  find(options?): Observable<TaskEduContentInterface[]> {
    return this.SDKTaskEduContentApi.find(...arguments);
  }

  deleteById(id: number): Observable<boolean> {
    // @ts-expect-error TS 2556
    return this.SDKTaskEduContentApi.deleteById(...arguments);
  }

  destroyTaskEduContents(taskEduContentIds: number[]) {
    // @ts-expect-error TS 2556
    return this.SDKTaskEduContentApi.destroyTaskEduContents(...arguments);
  }

  updateTaskEduContents(taskEduContents: Partial<TaskEduContentInterface>[]) {
    // @ts-expect-error TS 2556
    return this.SDKTaskEduContentApi.updateTaskEduContents(...arguments);
  }

  createTaskEduContents(taskEduContents: Partial<TaskEduContentInterface>[]) {
    // @ts-expect-error TS 2556
    return this.SDKTaskEduContentApi.createTaskEduContents(...arguments);
  }

  calculateTaskEduContentsForTask(taskId: number) {
    // @ts-expect-error TS 2556
    return this.SDKTaskEduContentApi.calculateTaskEduContentsForTask(...arguments);
  }
}
