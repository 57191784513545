import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { LicenseInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum LicensesActionTypes {
  LicensesLoaded = '[Licenses] Licenses Loaded',
  LicensesLoadError = '[Licenses] Load Error',
  LoadLicenses = '[Licenses] Load Licenses',
  StartAddManyLicenses = '[Licenses] Start Add Licenses',
  AddLicense = '[Licenses] Add License',
  UpsertLicense = '[Licenses] Upsert License',
  AddLicenses = '[Licenses] Add Licenses',
  UpsertLicenses = '[Licenses] Upsert Licenses',
  UpdateLicense = '[Licenses] Update License',
  UpdateLicenses = '[Licenses] Update Licenses',
  DeleteLicense = '[Licenses] Delete License',
  DeleteLicenses = '[Licenses] Delete Licenses',
  ClearLicenses = '[Licenses] Clear Licenses',
}

export class LoadLicenses implements Action {
  readonly type = LicensesActionTypes.LoadLicenses;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class LicensesLoaded implements Action {
  readonly type = LicensesActionTypes.LicensesLoaded;

  constructor(public payload: { licenses: LicenseInterface[] }) {}
}

export class LicensesLoadError implements Action {
  readonly type = LicensesActionTypes.LicensesLoadError;
  constructor(public payload: any) {}
}

export class StartAddManyLicenses implements Action {
  readonly type = LicensesActionTypes.StartAddManyLicenses;

  constructor(
    public payload: {
      userId: number;
      schoolId: number;
      licenses: LicenseInterface[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class AddLicense implements Action {
  readonly type = LicensesActionTypes.AddLicense;

  constructor(public payload: { license: LicenseInterface }) {}
}

export class UpsertLicense implements Action {
  readonly type = LicensesActionTypes.UpsertLicense;

  constructor(public payload: { license: LicenseInterface }) {}
}

export class AddLicenses implements Action {
  readonly type = LicensesActionTypes.AddLicenses;

  constructor(public payload: { licenses: LicenseInterface[] }) {}
}

export class UpsertLicenses implements Action {
  readonly type = LicensesActionTypes.UpsertLicenses;

  constructor(public payload: { licenses: LicenseInterface[] }) {}
}

export class UpdateLicense implements FeedbackTriggeringAction {
  readonly type = LicensesActionTypes.UpdateLicense;

  constructor(
    public payload: {
      license: Update<LicenseInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpdateLicenses implements Action {
  readonly type = LicensesActionTypes.UpdateLicenses;

  constructor(
    public payload: {
      licenses: Update<LicenseInterface>[];
    }
  ) {}
}

export class DeleteLicense {
  readonly type = LicensesActionTypes.DeleteLicense;

  constructor(
    public payload: {
      id: number;
    }
  ) {}
}

export class DeleteLicenses implements FeedbackTriggeringAction {
  readonly type = LicensesActionTypes.DeleteLicenses;

  constructor(
    public payload: {
      ids: number[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class ClearLicenses implements Action {
  readonly type = LicensesActionTypes.ClearLicenses;
}

export type LicensesActions =
  | LoadLicenses
  | LicensesLoaded
  | LicensesLoadError
  | AddLicense
  | StartAddManyLicenses
  | UpsertLicense
  | AddLicenses
  | UpsertLicenses
  | UpdateLicense
  | UpdateLicenses
  | DeleteLicense
  | DeleteLicenses
  | ClearLicenses;
