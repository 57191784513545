import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'campus-ability-arc',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbilityArcComponent implements OnChanges {
  @HostBinding('class')
  defaultClasses = [
    'absolute',
    'block',
    'top-0',
    'left-0',
    'w-full',
    'h-1/2',

    'corner-top-full',
    'corner-bottom-none',

    'origin-bottom',
    'transition-transform',
    'duration-long-1',
  ];

  @Input()
  angle = 0;

  @HostBinding('style.transform')
  rotateAngle: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.angle) {
      this.rotateAngle = 'rotate(' + this.angle + 'deg)';
    }
  }
}
