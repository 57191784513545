import { groupArrayByKey } from '@campus/utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EduContentTOCLoopInterface } from '../../+models';
import { NAME, selectAll, selectEntities, State } from './edu-content-toc-loop.reducer';

export const selectEduContentTocLoopState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectEduContentTocLoopState, (state: State) => state.error);

export const getLoaded = createSelector(selectEduContentTocLoopState, (state: State) => state.loaded);

export const getAll = createSelector(selectEduContentTocLoopState, selectAll);

export const getAllEntities = createSelector(selectEduContentTocLoopState, selectEntities);

export const getByEduContentTocId = createSelector(getAll, (eduContentTocLoops: EduContentTOCLoopInterface[]) => {
  return groupArrayByKey(eduContentTocLoops, 'eduContentTOCId');
});

export const isBookLoaded = (props: { bookId: number }) =>
  createSelector(selectEduContentTocLoopState, (state: State) => !!state.loadedForBook[props.bookId]);
