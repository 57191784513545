import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GroupPersonApi } from '../+api';
import { GroupPersonInterface } from '../+models';

@Injectable({
  providedIn: 'root',
})
export class GroupPersonService {
  constructor(private groupPersonApi: GroupPersonApi) {}

  public linkStudents(groupId: number, studentIds: number[]): Observable<GroupPersonInterface[]> {
    return this.groupPersonApi.linkStudents(groupId, studentIds);
  }

  public linkGroups(studentId: number, groupIds: number[]): Observable<GroupPersonInterface[]> {
    return this.groupPersonApi.linkGroups(studentId, groupIds);
  }
}
