import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EmptyAttribute, asEmptyAttribute } from '../../core/empty-attribute';
import { ButtonCoreComponent } from '../button-core/button-core.component';

@Component({
  selector: 'campus-fab-button, [fab-button]',
  templateUrl: './fab-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FabButtonComponent extends ButtonCoreComponent implements OnChanges {
  @HostBinding('class')
  defaultClasses = [
    ...this.coreClasses,
    'fab-button',

    'flex',
    'border-none',
    'outline-none',
    'primary-container',

    'corner-l',
    'shrink-0',

    'gap-2xs',

    'elevation-3',
    'hover:elevation-4',
    'active:elevation-3',
    'focus-visible:elevation-3',

    'transition-elevation',
  ];

  @HostBinding('attr.data-size')
  @Input()
  size: 'large' | 'small';

  @Input() icon: string;
  @Input() iconClass: string;

  @HostBinding('attr.aria-label')
  @Input()
  label: string;

  @HostBinding('attr.data-with-label')
  withLabel: EmptyAttribute;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.label) {
      this.withLabel = asEmptyAttribute(changes.label.currentValue);
    }
  }
}
