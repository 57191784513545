import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { BulkUpdateResultInfoInterface } from '../+external-interfaces/bulk-update-result-info';
import { EduContentBookInterface } from '../+models';

export const EDU_CONTENT_BOOK_SERVICE_TOKEN = new InjectionToken<EduContentBookServiceInterface>(
  'EduContentBookService'
);

// Note: from some reason, most of the EduContentBookMethods are located in the EduContentTocService
export interface EduContentBookServiceInterface {
  getAllForUser(userId: number): Observable<EduContentBookInterface[]>;
  clearEduContents(bookId: number): Observable<EduContentsClearedInterface>;
  unlinkEduContents(bookId: number): Observable<EduContentsUnlinkedInterface>;
  openBoeke(bookId: number, productId: number, eduContentTocId?: number): void;
  updateEduContentBook(bookId: number, update: Partial<EduContentBookInterface>): Observable<EduContentBookInterface>;
}

export interface EduContentsClearResponseInterface {
  eduContentId: number;
  operation: string;
}
export interface EduContentsUnlinkResponseInterface {
  eduContentMetadataId: number;
  operation: string;
  tocId: number;
  tocIds: number[];
}
export interface EduContentsClearedInterface
  extends BulkUpdateResultInfoInterface<EduContentsClearResponseInterface, EduContentsClearResponseInterface> {}

export interface EduContentsUnlinkedInterface
  extends BulkUpdateResultInfoInterface<EduContentsUnlinkResponseInterface, EduContentsUnlinkResponseInterface> {}
