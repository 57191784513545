import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SchoolBillingAddressInterface } from '../+models/SchoolBillingAddress.interface';

export const SCHOOL_BILLING_ADDRESS_SERVICE_TOKEN = new InjectionToken('SchoolBillingAddressServiceInterface');

export interface SchoolBillingAddressServiceInterface {
  getAllForUser(userId: number): Observable<SchoolBillingAddressInterface[]>;

  createSchoolBillingAddressForSchool(
    schoolId: number,
    schoolBillingAddress: SchoolBillingAddressInterface
  ): Observable<SchoolBillingAddressInterface>;

  updateSchoolBillingAddressForSchool(
    schoolId: number,
    schoolBillingAddressId: number,
    update: Partial<SchoolBillingAddressInterface>
  );

  deleteSchoolBillingAddressForSchool(schoolId: number, schoolBillingAddressId: number): Observable<boolean>;
}
