import { ChangeDetectionStrategy, Component, HostBinding, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CardCoreComponent } from '../card-core/card-core.component';

@Component({
  selector: 'campus-elevated-card, [elevated-card]',
  templateUrl: './../card-core/card-core.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElevatedCardComponent extends CardCoreComponent implements OnChanges, OnInit {
  defaultClasses = [...this.coreClasses, 'elevated-card', 'surface', 'hover:elevation-2', 'transition-input'];

  @HostBinding('class')
  classes = this.defaultClasses;

  ngOnInit() {
    this.classes = this.getClasses();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled) {
      this.classes = this.getClasses();
    }
  }

  private getClasses() {
    return [
      ...this.defaultClasses,
      ...(this.disabled
        ? ['elevation-0', 'surface-variant', 'opacity-low', 'pointer-event-none', 'grayscale-full', 'filter']
        : ['elevation-1']),
    ];
  }
}
