import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { GoalInterface } from '../../+models';
import { GoalsActions, GoalsActionTypes } from './goal.actions';

export const NAME = 'goals';

export interface State extends EntityState<GoalInterface> {
  // additional entities state properties
  loaded: boolean;
  loadedForLearningArea: {
    [learningAreaId: number]: boolean;
  };
  loadedForBook?: Dictionary<boolean>;
  error?: any;
}

export const adapter: EntityAdapter<GoalInterface> = createEntityAdapter<GoalInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForLearningArea: {},
  loadedForBook: {},
});

export function reducer(state = initialState, action: GoalsActions): State {
  switch (action.type) {
    case GoalsActionTypes.AddGoal: {
      return adapter.addOne(action.payload.goal, state);
    }

    case GoalsActionTypes.UpsertGoal: {
      return adapter.upsertOne(action.payload.goal, state);
    }

    case GoalsActionTypes.AddGoals: {
      return adapter.addMany(action.payload.goals, state);
    }

    case GoalsActionTypes.AddGoalsForLearningArea: {
      const newState = adapter.addMany(action.payload.goals, state);

      return {
        ...newState,
        loadedForLearningArea: {
          ...newState.loadedForLearningArea,
          [action.payload.learningAreaId]: true,
        },
      };
    }

    case GoalsActionTypes.AddGoalsForBook: {
      const { bookId, goals } = action.payload;
      return adapter.upsertMany(goals, {
        ...state,
        loadedForBook: { ...state.loadedForBook, [bookId]: true },
      });
    }

    case GoalsActionTypes.UpsertGoals: {
      return adapter.upsertMany(action.payload.goals, state);
    }

    case GoalsActionTypes.UpdateGoal: {
      return adapter.updateOne(action.payload.goal, state);
    }
    case GoalsActionTypes.UpdateGoalDisplayOrders:
    case GoalsActionTypes.UpdateGoals: {
      return adapter.updateMany(action.payload.goals, state);
    }

    case GoalsActionTypes.DeleteGoal: {
      return adapter.removeOne(action.payload.id, state);
    }

    case GoalsActionTypes.DeleteGoals: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case GoalsActionTypes.GoalsLoaded: {
      return adapter.setAll(action.payload.goals, { ...state, loaded: true });
    }

    case GoalsActionTypes.GoalsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case GoalsActionTypes.ClearGoals: {
      return adapter.removeAll(state);
    }

    case GoalsActionTypes.AppendYearInState: {
      const { goalYears } = action.payload;
      const entities = { ...state.entities };

      goalYears.reduce((acc, gY) => {
        if (!acc[gY.goalId]) return acc;

        const linkedYearIds = [...(acc[gY.goalId].yearIds || []), gY.yearId];
        acc[gY.goalId] = { ...acc[gY.goalId], yearIds: linkedYearIds };

        return acc;
      }, entities);
      return { ...state, entities };
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
