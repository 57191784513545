import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { EvaluationInterface } from '../../+models';
import { EvaluationsActions, EvaluationsActionTypes } from './evaluation.actions';

export const NAME = 'evaluations';

export interface State extends EntityState<EvaluationInterface> {
  // additional entities state properties
  loaded: boolean;
  loadedForBook: Dictionary<boolean>;
  error?: any;
}

export const adapter: EntityAdapter<EvaluationInterface> = createEntityAdapter<EvaluationInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForBook: {},
});

export function reducer(state = initialState, action: EvaluationsActions): State {
  switch (action.type) {
    case EvaluationsActionTypes.AddEvaluation: {
      return adapter.addOne(action.payload.evaluation, state);
    }

    case EvaluationsActionTypes.UpsertEvaluation: {
      return adapter.upsertOne(action.payload.evaluation, state);
    }

    case EvaluationsActionTypes.AddEvaluations: {
      return adapter.addMany(action.payload.evaluations, state);
    }

    case EvaluationsActionTypes.UpsertEvaluations: {
      return adapter.upsertMany(action.payload.evaluations, state);
    }

    case EvaluationsActionTypes.UpdateEvaluation: {
      return adapter.updateOne(action.payload.evaluation, state);
    }

    case EvaluationsActionTypes.UpdateEvaluations: {
      return adapter.updateMany(action.payload.evaluations, state);
    }

    case EvaluationsActionTypes.DeleteEvaluation: {
      return adapter.removeOne(action.payload.evaluationId, state);
    }

    case EvaluationsActionTypes.DeleteEvaluations: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case EvaluationsActionTypes.EvaluationsLoaded: {
      return adapter.setAll(action.payload.evaluations, { ...state, loaded: true });
    }

    case EvaluationsActionTypes.EvaluationsLoadError: {
      return { ...state, error: action.payload, loadedForBook: {} };
    }

    case EvaluationsActionTypes.ClearEvaluations: {
      return adapter.removeAll(state);
    }

    case EvaluationsActionTypes.AddEvaluationsForBook: {
      const { evaluations, bookId } = action.payload;

      const updatedState = adapter.addMany(evaluations, state);
      return {
        ...updatedState,
        loadedForBook: { ...updatedState.loadedForBook, [bookId]: true },
      };
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
