import { InjectionToken } from '@angular/core';
import { ContentInterface, EduContent, ResultInterface } from '@campus/dal';
import { StudentTaskWithContentInterface } from '../../../interfaces';
import { ContentActionInterface } from '../content-action.interface';

export type ContentActionType =
  | 'openEduContentAsExercise'
  | 'openEduContentAsSolution'
  | 'openEduContentAsPreview'
  | 'openEduContentAsStream'
  | 'openEduContentAsDownload'
  | 'openEduContentAsWebApp'
  | 'openEduContentAsLink'
  | 'openBoeke'
  | 'openPaperExercise'
  | 'openEduContentForReview'
  | 'openEduContentAsHtml'
  | 'openInstructions'
  | 'continueEduContentAsExercise'
  | 'retryEduContentAsExercise'
  | 'openEduContentAsStreamCompleted'
  | 'openEduContentAsDownloadCompleted'
  | 'openEduContentAsLinkCompleted'
  | 'openEduContentAsWebAppCompleted';

export type ContentActionDictionary = Partial<Record<ContentActionType, ContentActionInterface>>;
export type PartialContentActionDictionary = Partial<Record<ContentActionType, Partial<ContentActionInterface>>>;

export interface ContentOpenerInterface {
  openEduContentAsExercise?: (eduContent: EduContent, options?: OpenContentOptions) => void;
  openEduContentAsSolution?: (eduContent: EduContent, options?: OpenContentOptions) => void;
  openEduContentAsStream?: (eduContent: EduContent, options?: OpenContentOptions) => void;
  openEduContentAsDownload?: (eduContent: EduContent, options?: OpenContentOptions) => void;
  openEduContentAsLink?: (eduContent: EduContent, options?: OpenContentOptions) => void;
  openBoeke?: (eduContent: EduContent, options?: OpenContentOptions) => void;
  openEduContentForReview?: (eduContent: EduContent, options?: OpenContentOptions) => void;
  openPaperExercise?: (eduContent: EduContent, options?: OpenContentOptions) => void;
  openEduContentAsHtml?: (eduContent: EduContent, options?: OpenContentOptions) => void;
  openInstructions?: (eduContent: EduContent, options?: OpenContentOptions) => void;
}

export interface OpenContentOptions {
  /**
   * Only relevant for when a student opens exercises.
   */
  withFeedback?: boolean;
  isRepeatable?: boolean;
  forResult?: ResultInterface;
  inTocId?: number;
  disableMathjax?: boolean;
}

export interface ResultOpenerInterface {
  openEduContentFromResult(result: ResultInterface): void;
}

export const CONTENT_OPEN_ACTIONS_SERVICE_TOKEN = new InjectionToken<ContentOpenActionsServiceInterface>(
  'ContentOpenActionsService'
);

export const CONTENT_OPENER_TOKEN = new InjectionToken<ContentOpenerInterface>('ContentOpener');

export const STUDENT_CONTENT_OPENER_TOKEN = new InjectionToken<ContentOpenerInterface>('StudentContentOpener');

export interface ActionsForTaskInstanceEduContentOptionsInterface {
  isRepeatable?: boolean;
  withFeedback?: boolean;
}
export interface ContentOpenActionsServiceInterface {
  getActionsForContent(
    content: ContentInterface,
    result?: Pick<ResultInterface, 'status'>,
    options?: {
      forReview?: boolean;
      forDWB?: boolean;
    }
  ): ContentActionInterface[];
  getActionForContent(content: ContentInterface, actionType: ContentActionType): ContentActionInterface;
  getActionsForTaskInstanceEduContent(
    eduContent: EduContent,
    result: Pick<ResultInterface, 'status'>,
    taskInstance: Pick<StudentTaskWithContentInterface, 'end' | 'isFinished'>,
    options?: ActionsForTaskInstanceEduContentOptionsInterface
  ): ContentActionInterface[];
}
