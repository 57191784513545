import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SchoolRoleMappingClassGroupInterface } from '../../+models';
import { ClassGroupsActions, ClassGroupsActionTypes } from '../class-group/class-group.actions';
import {
  SchoolRoleMappingClassGroupsActions,
  SchoolRoleMappingClassGroupsActionTypes,
} from './school-role-mapping-class-group.actions';

export const NAME = 'schoolRoleMappingClassGroups';

export interface State extends EntityState<SchoolRoleMappingClassGroupInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<SchoolRoleMappingClassGroupInterface> =
  createEntityAdapter<SchoolRoleMappingClassGroupInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: SchoolRoleMappingClassGroupsActions | ClassGroupsActions): State {
  switch (action.type) {
    case SchoolRoleMappingClassGroupsActionTypes.AddSchoolRoleMappingClassGroup: {
      return adapter.addOne(action.payload.schoolRoleMappingClassGroup, state);
    }

    case SchoolRoleMappingClassGroupsActionTypes.DeleteSchoolRoleMappingClassGroup: {
      return adapter.removeOne(action.payload.id, state);
    }

    case SchoolRoleMappingClassGroupsActionTypes.DeleteSchoolRoleMappingClassGroups: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case SchoolRoleMappingClassGroupsActionTypes.SchoolRoleMappingClassGroupsLoaded: {
      return adapter.setAll(action.payload.schoolRoleMappingClassGroups, {
        ...state,
        loaded: true,
      });
    }

    case SchoolRoleMappingClassGroupsActionTypes.SchoolRoleMappingClassGroupsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case SchoolRoleMappingClassGroupsActionTypes.AddSchoolRoleMappingClassGroups: {
      return adapter.addMany(action.payload.schoolRoleMappingClassGroups, state);
    }

    case SchoolRoleMappingClassGroupsActionTypes.UnlinkRolesClassGroup: {
      const unlinkIdSet = new Set(action.payload.schoolRoleMappingIds);

      const ids = (state.ids as number[]).filter((id) => {
        const { classGroupId, schoolRoleMappingId } = state.entities[id];
        return classGroupId === action.payload.classGroupId && unlinkIdSet.has(schoolRoleMappingId);
      });

      return adapter.removeMany(ids, state);
    }

    case SchoolRoleMappingClassGroupsActionTypes.UpsertSchoolRoleMappingClassGroup: {
      return adapter.upsertOne(action.payload.schoolRoleMappingClassGroup, state);
    }

    case SchoolRoleMappingClassGroupsActionTypes.UpsertSchoolRoleMappingClassGroups: {
      return adapter.upsertMany(action.payload.schoolRoleMappingClassGroups, state);
    }

    case ClassGroupsActionTypes.DeleteClassGroup: {
      const ids = (state.ids as number[]).filter((id) => state.entities[id].classGroupId === action.payload.id);
      return adapter.removeMany(ids, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
