import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

export enum StatusColor {
  'pending' = 'secondary',
  'error' = 'error',
  'warn' = 'warn',
  'failed' = 'error',
  'passed' = 'warn',
  'good' = 'success',
  'excellent' = 'success',
}

@Component({
  selector: 'campus-percentage-meter',
  templateUrl: './percentage-meter.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PercentageMeterComponent implements OnChanges, OnInit {
  public height = 32;
  public strokeWidth = 3;
  public dashArray;
  public isPending = false;

  private defaultClasses = [
    'relative',
    'flex',
    'icon-size-l',
    'label-small',
    'items-center',
    'justify-center',
    'corner-full',
  ];

  @HostBinding('class')
  statusClasses = this.defaultClasses;

  @Input()
  status: keyof typeof StatusColor;

  @Input()
  percentage: number;

  ngOnInit() {
    this.statusClasses = this.getClasses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status) {
      this.isPending = this.status === 'pending';

      this.statusClasses = this.getClasses();
    }

    if (changes.status || changes.percentage) {
      this.setDashArray();
    }
  }

  setDashArray() {
    if (this.isPending) {
      this.dashArray = undefined;
      return;
    }

    this.strokeWidth = this.percentage ? 3 : 0;

    if (!this.percentage) {
      this.dashArray = `0 1`;
    }

    const borderLength = this.height * Math.PI * ((this.percentage || 0) / 100);
    this.dashArray = `${borderLength} ${100 - borderLength}`;
  }

  private getClasses() {
    if (this.status) {
      const statusColor = StatusColor[this.status];
      return [...this.defaultClasses, `${statusColor}-container`, `stroke-${statusColor}`];
    } else {
      return [...this.defaultClasses, 'surface-variant'];
    }
  }
}
