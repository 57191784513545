import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Permissions } from '@campus/dal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionService } from '@campus/shared';

@Injectable()
export class HomePageGuard implements CanActivate {
  constructor(private router: Router, private permissionService: PermissionService) {}

  canActivate(): Observable<UrlTree | boolean> | Promise<boolean> | boolean {
    return this.permissionService.hasPermission$(Permissions.feature.DASHBOARD).pipe(
      map((hasPermission) => hasPermission || this.redirectToMethods())
    );
  }

  private redirectToMethods() {
    return this.router.createUrlTree(['/methods']);
  }
}
