import { groupArrayByKey } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GoalYearInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './goal-year.reducer';

export const selectGoalYearState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectGoalYearState, (state: State) => state.error);

export const getLoaded = createSelector(selectGoalYearState, (state: State) => state.loaded);

export const getAll = createSelector(selectGoalYearState, selectAll);

export const getCount = createSelector(selectGoalYearState, selectTotal);

export const getIds = createSelector(selectGoalYearState, selectIds);

export const getAllEntities = createSelector(selectGoalYearState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * goalYear$: GoalYearInterface[] = this.store.pipe(
    select(GoalYearQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectGoalYearState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * goalYear$: GoalYearInterface = this.store.pipe(
    select(GoalYearQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectGoalYearState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getGoalYearsLoadedForLearningArea = (props: { learningAreaId: number }) =>
  createSelector(selectGoalYearState, (state) => {
    return !!state.loadedForLearningArea[props.learningAreaId];
  });

export const getByYearId = createSelector(getAll, (goalYears: GoalYearInterface[]): Dictionary<GoalYearInterface[]> => {
  return groupArrayByKey(goalYears, 'yearId');
});

export const getByGoalId = createSelector(getAll, (goalYears: GoalYearInterface[]): Dictionary<GoalYearInterface[]> => {
  return groupArrayByKey(goalYears, 'goalId');
});
