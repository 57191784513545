import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DidacticFunctionApi } from '../+api';
import { DidacticFunctionInterface } from '../+models/DidacticFunction.interface';
import { DidacticFunctionServiceInterface } from './didactic-function.service.interface';

@Injectable({
  providedIn: 'root',
})
export class DidacticFunctionService implements DidacticFunctionServiceInterface {
  constructor(private didacticFunctionApi: DidacticFunctionApi) {}

  getAll(): Observable<DidacticFunctionInterface[]> {
    return this.didacticFunctionApi.find();
  }
}
