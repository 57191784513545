import { ArrayFunctions } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EduContentBookInterface, ProductContentInterface, ProductInterface } from '../../+models';
import { EduContentBookQueries } from '../edu-content-book';
import { ProductContentQueries } from '../product-content';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './product.reducer';
const { unique } = ArrayFunctions;

export const selectProductState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectProductState, (state: State) => state.error);

export const getLoaded = createSelector(selectProductState, (state: State) => state.loaded);

export const getAll = createSelector(selectProductState, selectAll);

export const getAllAvailable = createSelector(getAll, (products: ProductInterface[]) =>
  products.filter((product) => !!product.isAvailable)
);

export const getCount = createSelector(selectProductState, selectTotal);

export const getIds = createSelector(selectProductState, selectIds);

export const getAllEntities = createSelector(selectProductState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * product$: ProductInterface[] = this.store.pipe(
    select(ProductQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectProductState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * product$: ProductInterface = this.store.pipe(
    select(ProductQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectProductState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

// get the books grouped by product id
export const getBooksByProduct = createSelector(
  getAll,
  ProductContentQueries.getByProductId,
  EduContentBookQueries.getAllEntities,
  (products, productContentByProductId, eduContentBookDict) => {
    return products.reduce((acc, product) => {
      if (acc[product.id]) return acc;

      acc[product.id] = _getUniqueBooksForProduct(
        productContentByProductId,
        eduContentBookDict,
        product.id,
        (book) => !book.isMarketingHighlight
      );
      return acc;
    }, {} as { [productId: number]: EduContentBookInterface[] });
  }
);

export const getMarketingHighlightBooksByProduct = createSelector(
  getAll,
  ProductContentQueries.getByProductId,
  EduContentBookQueries.getAllEntities,
  (products, productContentByProductId, eduContentBookDict) => {
    return products.reduce((acc, product) => {
      if (acc[product.id]) return acc;

      acc[product.id] = _getUniqueBooksForProduct(
        productContentByProductId,
        eduContentBookDict,
        product.id,
        (book) => book.isMarketingHighlight
      );
      return acc;
    }, {} as { [productId: number]: EduContentBookInterface[] });
  }
);

function _getUniqueBooksForProduct(
  productContentByProductId: Dictionary<ProductContentInterface[]>,
  eduContentBookDict: Dictionary<EduContentBookInterface>,
  productId: number,
  bookFilter?: (book: EduContentBookInterface) => boolean
) {
  return unique(
    (productContentByProductId[productId] || [])
      .filter((productContent) => {
        const book = eduContentBookDict[productContent.eduContentBookId];

        return productContent.licenseType === 'book' && book && (!bookFilter || bookFilter(book));
      })
      .map((productContent) => eduContentBookDict[productContent.eduContentBookId])
  );
}
