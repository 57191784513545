export enum BookTabEnum {
  LESSON = 'lesson',
  PRACTICE = 'practice',
  LEARNING_PLAN_GOALS = 'lpg',
  PROJECTS = 'projects',
  MANAGE_PRACTICE = 'manage_practice',
  DIGITAL_WORKBOOK = 'digital_workbook',
  DIGITAL_EVALUATIONS = 'digital_evaluations',
  KAI = 'kai',
}
