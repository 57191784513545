import { DateFunctions } from '@campus/utils';
import { AlgebrakitSessionInterface } from './AlgebrakitSession.interface';
import { AlgebrakitSessionDayInterface } from './AlgebrakitSessionDay.interface';
import { BundleInterface } from './Bundle.interface';
import { EduContentInterface } from './EduContent.interface';
import { MixedResultInterface } from './MixedResult.interface';
import { PersonInterface } from './Person.interface';
import { ResultStatusEnum } from './Result.interface';
import { TaskInterface } from './Task.interface';

export class AlgebrakitSessionDay implements AlgebrakitSessionDayInterface {
  sessions: AlgebrakitSessionInterface[];

  day: Date;
  sumOfTime: number;
  maxLastUpdated: Date;

  personId?: number;
  person?: PersonInterface;

  eduContentId?: number;
  eduContent?: EduContentInterface;

  taskId?: number;
  task?: TaskInterface;

  bundleId?: number;
  bundle?: BundleInterface;

  status?: ResultStatusEnum;

  static toAlgebraKitSessionDay(sessions: AlgebrakitSessionInterface[]): AlgebrakitSessionDayInterface {
    if (!(Array.isArray(sessions) && sessions.length)) return null;

    const { userId, user, eduContentId, eduContent, taskId, task, bundleId, bundle, lastUpdated } = sessions[0];

    const { sumOfTime, maxLastUpdated } = sessions.reduce(
      (acc, session) => {
        acc.sumOfTime += session.time || 0;
        acc.maxLastUpdated =
          !acc.maxLastUpdated || +acc.maxLastUpdated < +session.lastUpdated ? session.lastUpdated : acc.maxLastUpdated;
        return acc;
      },
      {
        sumOfTime: 0,
        maxLastUpdated: undefined as Date,
      }
    );

    const day = DateFunctions.midnight(lastUpdated);

    const algebrakitSessionDay: AlgebrakitSessionDayInterface = {
      sessions,
      day,
      sumOfTime,
      maxLastUpdated,
    };

    // FYI: easier to test this way
    if (userId) algebrakitSessionDay.personId = userId;
    if (user) algebrakitSessionDay.person = user;

    if (eduContentId) algebrakitSessionDay.eduContentId = eduContentId;
    if (eduContent) algebrakitSessionDay.eduContent = eduContent;

    if (taskId) algebrakitSessionDay.taskId = taskId;
    if (task) algebrakitSessionDay.task = task;

    if (bundleId) algebrakitSessionDay.bundleId = bundleId;
    if (bundle) algebrakitSessionDay.bundle = bundle;

    return algebrakitSessionDay;
  }

  static toMixedResult(sessionDay: AlgebrakitSessionDayInterface): MixedResultInterface<AlgebrakitSessionDayInterface> {
    const { eduContent, day: lastUpdated, sumOfTime: time, status, bundleId, taskId } = sessionDay;

    const inTask = !!taskId;
    const inBundle = !!bundleId;

    return {
      type: 'sessionDay',
      eduContent,
      lastUpdated,
      time,
      data: sessionDay,
      status,
      inTask,
      inBundle,
      attempts: sessionDay.sessions,
    };
  }
}
