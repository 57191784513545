/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { PersonProductApi as SDKPersonProductApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class PersonProductApi extends ModelApiBase {
  private SDKPersonProductApi = inject(SDKPersonProductApi);

  constructor(api: PolpoApi) {
    super(api, 'PersonProducts');
  }

  deletePersonProduct(personProductId: number): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKPersonProductApi.deletePersonProduct(...arguments);
  }

  deletePersonProducts(personProductIds: number[]): Observable<any> {
    return this.SDKPersonProductApi.deletePersonProducts(...arguments);
  }

  createPersonProduct(personProduct): Observable<any> {
    return this.SDKPersonProductApi.createPersonProduct(...arguments);
  }

  createPersonProducts(personProducts): Observable<any[]> {
    return this.SDKPersonProductApi.createPersonProducts(...arguments);
  }
}
