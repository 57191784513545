import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DalActions } from '..';
import { PersonPreferenceInterface } from '../../+models';
import { PersonPreferenceServiceInterface, PERSON_PREFERENCE_SERVICE_TOKEN } from '../../person-preference';
import { EffectFeedback, EffectFeedbackActions } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { UserActions } from '../user';
import {
  AddPersonPreference,
  DeletePersonPreference,
  PersonPreferencesActionTypes,
  StartAddPersonPreference,
  StartAddTemplate,
  StartUpsertPersonPreference,
  UpsertPersonPreference,
} from './person-preference.actions';

@Injectable()
export class PersonPreferenceEffects {
  startAddPersonPreference$ = createEffect(() =>
    this.actions.pipe(
      ofType(PersonPreferencesActionTypes.StartAddPersonPreference),
      pessimisticUpdate({
        run: (action: StartAddPersonPreference) => {
          const { userId, personPreference } = action.payload;
          return this.personPreferenceService.createPersonPreference(userId, personPreference).pipe(
            switchMap((newPersonPreference: PersonPreferenceInterface) => {
              const actionsToDispatch: Action[] = [new AddPersonPreference({ personPreference: newPersonPreference })];
              if (newPersonPreference.key === 'ALTERNATIVE_PLATFORM_USAGE') {
                actionsToDispatch.push(new UserActions.StartUpdateCompleteProfile());
              }

              return from(actionsToDispatch);
            })
          );
        },
        onError: (action: StartAddPersonPreference, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de voorkeur op te slaan.'
            ),
          });
        },
      })
    )
  );

  deletePersonPreference$ = createEffect(() =>
    this.actions.pipe(
      ofType(PersonPreferencesActionTypes.DeletePersonPreference),
      optimisticUpdate({
        run: (action: DeletePersonPreference) => {
          return this.personPreferenceService.deletePersonPreference(action.payload.id).pipe(
            map(() => {
              if (action.payload.updateCompleteProfile) {
                return new UserActions.StartUpdateCompleteProfile();
              }
              return new DalActions.ActionSuccessful({ successfulAction: '[PersonPreference] deleted' });
            })
          );
        },
        undoAction: (action: DeletePersonPreference, error) => {
          const undoAction = undo(action);
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            'Het is niet gelukt om de voorkeur te verwijderen.'
          );
          const effectFeedbackAction = new EffectFeedbackActions.AddEffectFeedback({ effectFeedback });
          return from([undoAction, effectFeedbackAction]);
        },
      })
    )
  );

  startUpsertPersonPreference$ = createEffect(() =>
    this.actions.pipe(
      ofType(PersonPreferencesActionTypes.StartUpsertPersonPreference),
      pessimisticUpdate({
        run: (action: StartUpsertPersonPreference) => {
          const { personPreference } = action.payload;

          return this.personPreferenceService.upsertPersonPreference(personPreference).pipe(
            switchMap((upsertedPersonPreference) =>
              from([
                new UpsertPersonPreference({ personPreference: upsertedPersonPreference }),
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(this.uuid(), action, 'Voorkeur is aangepast.'),
                }),
              ])
            )
          );
        },
        onError: (action: StartUpsertPersonPreference, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de voorkeur aan te passen.'
            ),
          });
        },
      })
    )
  );

  startAddTemplate$ = createEffect(() =>
    this.actions.pipe(
      ofType(PersonPreferencesActionTypes.StartAddTemplate),
      pessimisticUpdate({
        run: (action: StartAddTemplate) => {
          const { personPreference } = action.payload;

          return this.personPreferenceService.createTemplate(personPreference).pipe(
            switchMap((created) =>
              from([
                new AddPersonPreference({ personPreference: created }),
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'Het sjabloon werd toegevoegd.'
                  ),
                }),
              ])
            )
          );
        },
        onError: (action: StartAddTemplate, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het sjabloon toe te voegen.'
            ),
          });
        },
      })
    )
  );

  constructor(
    @Inject('uuid') private uuid: () => string,
    private actions: Actions,
    @Inject(PERSON_PREFERENCE_SERVICE_TOKEN)
    private personPreferenceService: PersonPreferenceServiceInterface
  ) {}
}
