import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { GroupPersonInterface } from '../+models';

export const GROUP_PERSON_SERVICE_TOKEN = new InjectionToken<GroupPersonServiceInterface>('GroupPersonService');

export interface GroupPersonServiceInterface {
  linkStudents(groupId: number, studentIds: number[]): Observable<GroupPersonInterface[]>;
  linkGroups(studentId: number, groupIds: number[]): Observable<GroupPersonInterface[]>;
}
