import { inject, Injectable, ProviderToken } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AddEvaluationResponseInterface, EvaluationService } from '../../evaluation';
import { DalState } from '../dal.state.interface';
import {
  DeleteEduContentTocEvaluation,
  UpsertEduContentTocEvaluations
} from '../edu-content-toc-evaluation/edu-content-toc-evaluation.actions';
import { UpsertEduContentToc } from '../edu-content-toc/edu-content-toc.actions';
import { EffectFeedback, EffectFeedbackActions } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { DeleteEvaluationSubjectGoals } from '../evaluation-subject-goal/evaluation-subject-goal.actions';
import { DeleteEvaluationSubjects } from '../evaluation-subject/evaluation-subject.actions';
import {
  AddEvaluation,
  DeleteEvaluation,
  EvaluationsActionTypes,
  NavigateToEvaluationDetail,
  StartAddEvaluation,
  UpdateEvaluation
} from './evaluation.actions';

@Injectable()
export class EvaluationEffects {
  actions = inject(Actions);
  store: Store<DalState> = inject(Store);
  evaluationService = inject(EvaluationService);
  router = inject(Router);
  uuid = inject('uuid' as unknown as ProviderToken<() => string>);

  startAddEvaluation$ = createEffect(() =>
    this.actions.pipe(
      ofType(EvaluationsActionTypes.StartAddEvaluation),
      pessimisticUpdate({
        run: (action: StartAddEvaluation) => {
          return this.evaluationService.addEvaluation(action.payload.evaluation, action.payload.tocId).pipe(
            switchMap((response: AddEvaluationResponseInterface) => {
              const {
                evaluation,
                eduContentTOCEvaluations: eduContentTocEvaluations,
                eduContentTOC: eduContentToc,
              } = response;
              const actionsToDispatch: Action[] = [
                new AddEvaluation({ evaluation }),
                new UpsertEduContentTocEvaluations({ eduContentTocEvaluations }),
                new UpsertEduContentToc({ eduContentToc }),
              ];

              if (action.payload.navigateAfterCreate) {
                actionsToDispatch.push(new NavigateToEvaluationDetail({ evaluation }));
              }

              return from(actionsToDispatch);
            })
          );
        },
        onError: (action: StartAddEvaluation, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de evaluatie aan te maken.'
            ),
          });
        },
      })
    )
  );

  updateEvaluation$ = createEffect(() =>
    this.actions.pipe(
      ofType(EvaluationsActionTypes.UpdateEvaluation),
      optimisticUpdate({
        run: (action: UpdateEvaluation) => {
          return this.evaluationService
            .updateEvaluation(+action.payload.evaluation.id, action.payload.evaluation.changes)
            .pipe(
              map(() => {
                const effectFeedback = EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'De evaluatie werd aangepast.'
                );
                return new EffectFeedbackActions.AddEffectFeedback({
                  effectFeedback,
                });
              })
            );
        },
        undoAction: (action: UpdateEvaluation, error) => {
          const undoAction = undo(action);
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            'Het is niet gelukt om de evaluatie aan te passen.'
          );
          const effectFeedbackAction = new EffectFeedbackActions.AddEffectFeedback({ effectFeedback });
          return from([undoAction, effectFeedbackAction]);
        },
      })
    )
  );

  deleteEvaluation$ = createEffect(() =>
    this.actions.pipe(
      ofType(EvaluationsActionTypes.DeleteEvaluation),
      optimisticUpdate({
        run: (action: DeleteEvaluation) => {
          const { evaluationId, tocId } = action.payload;
          return this.evaluationService.deleteEvaluation(evaluationId, tocId).pipe(
            switchMap((response) => {
              const {
                deletedEduContentTOCEvaluation,
                deletedEvaluationSubjectIds,
                deletedEvaluationSubjectGoalIds,
                eduContentTOC,
              } = response;
              return [
                new DeleteEduContentTocEvaluation({ id: deletedEduContentTOCEvaluation.id }),
                new DeleteEvaluationSubjects({ ids: deletedEvaluationSubjectIds }),
                new DeleteEvaluationSubjectGoals({ ids: deletedEvaluationSubjectGoalIds }),
                new UpsertEduContentToc({ eduContentToc: eduContentTOC }),
              ];
            })
          );
        },
        undoAction: (action: DeleteEvaluation, error) => {
          const undoAction = undo(action);
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            'Het is niet gelukt om de evaluatie te verwijderen.'
          );
          const effectFeedbackAction = new EffectFeedbackActions.AddEffectFeedback({ effectFeedback });
          return from([undoAction, effectFeedbackAction]);
        },
      })
    )
  );

  redirectToEvaluation$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(EvaluationsActionTypes.NavigateToEvaluationDetail),
        tap((action: NavigateToEvaluationDetail) => {
          this.router.navigate([], {
            queryParams: { evaluationId: action.payload.evaluation.id },
            queryParamsHandling: 'merge',
          });
        })
      ),
    { dispatch: false }
  );
}
