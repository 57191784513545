import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const WORD_SERVICE_TOKEN = new InjectionToken('WordService');

export interface WordServiceInterface {
  getWords(
    searchTerm?: string,
    filters?: { [key: string]: string | number | number[] },
    pagination?: { from?; amount? },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<{ results: object[]; count: number }>;
}
