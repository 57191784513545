import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { EduContentInterface } from '../../+models';

export enum EduContentsActionTypes {
  EduContentsLoaded = '[EduContents] EduContents Loaded',
  EduContentsLoadedForResults = '[EduContents] EduContents Loaded for Results',
  EduContentsLoadError = '[EduContents] Load Error',
  AddEduContent = '[EduContents] Add EduContent',
  UpsertEduContent = '[EduContents] Upsert EduContent',
  AddEduContents = '[EduContents] Add EduContents',
  UpsertEduContents = '[EduContents] Upsert EduContents',
  UpdateEduContent = '[EduContents] Update EduContent',
  UpdateEduContents = '[EduContents] Update EduContents',
  DeleteEduContent = '[EduContents] Delete EduContent',
  DeleteEduContents = '[EduContents] Delete EduContents',
  ClearEduContents = '[EduContents] Clear EduContents',
  LoadEduContentsForBook = '[EduContents] Load EduContents for Book',
  AddEduContentsForBook = '[EduContents] Add EduContents for Book',
  LoadGeneralFilesForBook = '[EduContents] Load General Files for Book',
  AddGeneralFilesForBook = '[EduContents] Add General Files for Book',
  AddEduContentsForTask = '[EduContents] Add EduContents for Task',
  AddGamesForBook = '[EduContents] Add Games for Book',
  AddEduContentsForSectionsForBook = '[EduContents] Add EduContents for Sections for Book',
  AddEduContentsForSectionForToc = '[EduContents] Add EduContents for Sections for Toc',
  StartAddEduContent = '[EduContents] Start Add EduContent',
  NavigateToEduContentDetail = '[EduContents] Navigate to EduContent detail',
  SetEduContentResultPending = '[EduContents] Set EduContent Result Pending',
}

export class EduContentsLoaded implements Action {
  readonly type = EduContentsActionTypes.EduContentsLoaded;

  constructor(
    public payload: {
      eduContents: EduContentInterface[];
    }
  ) {}
}

export class EduContentsLoadedForResults implements Action {
  readonly type = EduContentsActionTypes.EduContentsLoadedForResults;

  constructor(
    public payload: {
      eduContentsForResults: EduContentInterface[];
    }
  ) {}
}

export class EduContentsLoadError implements Action {
  readonly type = EduContentsActionTypes.EduContentsLoadError;
  constructor(public payload: any) {}
}

export class AddEduContent implements Action {
  readonly type = EduContentsActionTypes.AddEduContent;

  constructor(public payload: { eduContent: EduContentInterface }) {}
}

export class UpsertEduContent implements Action {
  readonly type = EduContentsActionTypes.UpsertEduContent;

  constructor(public payload: { eduContent: EduContentInterface; onlyWhenNew?: boolean }) {}
}

export class AddEduContents implements Action {
  readonly type = EduContentsActionTypes.AddEduContents;

  constructor(public payload: { eduContents: EduContentInterface[] }) {}
}

export class UpsertEduContents implements Action {
  readonly type = EduContentsActionTypes.UpsertEduContents;

  constructor(public payload: { eduContents: EduContentInterface[] }) {}
}

export class UpdateEduContent implements Action {
  readonly type = EduContentsActionTypes.UpdateEduContent;

  constructor(public payload: { eduContent: Update<EduContentInterface> }) {}
}

export class UpdateEduContents implements Action {
  readonly type = EduContentsActionTypes.UpdateEduContents;

  constructor(public payload: { eduContents: Update<EduContentInterface>[] }) {}
}

export class DeleteEduContent implements Action {
  readonly type = EduContentsActionTypes.DeleteEduContent;

  constructor(public payload: { id: number }) {}
}

export class DeleteEduContents implements Action {
  readonly type = EduContentsActionTypes.DeleteEduContents;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearEduContents implements Action {
  readonly type = EduContentsActionTypes.ClearEduContents;
}

export class LoadEduContentsForBook implements Action {
  readonly type = EduContentsActionTypes.LoadEduContentsForBook;

  constructor(public payload: { bookId: number; force?: boolean }) {}
}

export class AddEduContentsForBook implements Action {
  readonly type = EduContentsActionTypes.AddEduContentsForBook;

  constructor(public payload: { bookId: number; eduContents: EduContentInterface[] }) {}
}

export class LoadGeneralFilesForBook implements Action {
  readonly type = EduContentsActionTypes.LoadGeneralFilesForBook;

  constructor(public payload: { bookId: number; force?: boolean }) {}
}

export class AddGeneralFilesForBook implements Action {
  readonly type = EduContentsActionTypes.AddGeneralFilesForBook;

  constructor(public payload: { bookId: number; eduContents: EduContentInterface[] }) {}
}

export class AddEduContentsForTask implements Action {
  readonly type = EduContentsActionTypes.AddEduContentsForTask;

  constructor(public payload: { taskId: number; eduContents: EduContentInterface[] }) {}
}

export class AddGamesForBook implements Action {
  readonly type = EduContentsActionTypes.AddGamesForBook;

  constructor(public payload: { bookId: number; eduContents: EduContentInterface[] }) {}
}

export class AddEduContentsForSectionsForBook implements Action {
  readonly type = EduContentsActionTypes.AddEduContentsForSectionsForBook;

  constructor(public payload: { bookId: number; eduContents: EduContentInterface[] }) {}
}

export class AddEduContentsForSectionForToc implements Action {
  readonly type = EduContentsActionTypes.AddEduContentsForSectionForToc;

  constructor(public payload: { tocId: number; eduContents: EduContentInterface[] }) {}
}

export class StartAddEduContent implements Action {
  readonly type = EduContentsActionTypes.StartAddEduContent;
  constructor(
    public payload: {
      eduContentId?: number;
      personPreferenceId?: number;
      eduContentBookId?: number;
      navigateAfterCreate?: boolean;
    }
  ) {}
}

export class NavigateToEduContentDetail implements Action {
  readonly type = EduContentsActionTypes.NavigateToEduContentDetail;

  constructor(
    public payload: {
      eduContentId: number;
    }
  ) {}
}

export class SetEduContentResultPending implements Action {
  readonly type = EduContentsActionTypes.SetEduContentResultPending;

  constructor(public payload: { eduContentId: number; pending: boolean }) {}
}

export type EduContentsActions =
  | EduContentsLoaded
  | EduContentsLoadedForResults
  | EduContentsLoadError
  | AddEduContent
  | UpsertEduContent
  | AddEduContents
  | UpsertEduContents
  | UpdateEduContent
  | UpdateEduContents
  | DeleteEduContent
  | DeleteEduContents
  | ClearEduContents
  | LoadEduContentsForBook
  | AddEduContentsForBook
  | LoadGeneralFilesForBook
  | AddGeneralFilesForBook
  | AddEduContentsForTask
  | AddGamesForBook
  | AddEduContentsForSectionsForBook
  | AddEduContentsForSectionForToc
  | StartAddEduContent
  | NavigateToEduContentDetail
  | SetEduContentResultPending;
