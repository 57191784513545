import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'campus-froala-viewer',
  templateUrl: './froala-viewer.component.html',
  styleUrls: ['./froala-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FroalaViewerComponent {
  @Input() content: string;
}
