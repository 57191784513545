import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { fetch, optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PurchaseQueries } from '.';
import { LicenseInterface, PurchaseInterface } from '../../+models';
import { PurchaseServiceInterface, PURCHASE_SERVICE_TOKEN } from '../../purchase';
import { DalState } from '../dal.state.interface';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { UpsertLicenses } from '../license/license.actions';
import {
  AddPurchaseProducts,
  DeletePurchaseProducts,
  UpsertPurchaseProducts,
} from '../purchase-product/purchase-product.actions';
import { UpsertSchoolBilling, UpsertSchoolBillings } from '../school-billing/school-billing.actions';
import {
  AddPurchase,
  CreatePurchase,
  DeletePurchase,
  FinalisePurchases,
  LoadPurchases,
  LoadPurchasesForSchoolYear,
  PlaceOrder,
  PurchasesActionTypes,
  PurchasesLoaded,
  PurchasesLoadError,
  StartUpdatePurchase,
  UpdatePurchase,
  UpsertPurchases,
} from './purchase.actions';

@Injectable()
export class PurchaseEffects {
  loadPurchases$ = createEffect(() =>
    this.actions.pipe(
      ofType(PurchasesActionTypes.LoadPurchases),
      concatLatestFrom(() => this.store.select(PurchaseQueries.getLoaded)),
      fetch({
        run: (action: LoadPurchases, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.purchaseService
            .getAllForUser(action.payload.userId)
            .pipe(map((purchases) => new PurchasesLoaded({ purchases })));
        },
        onError: (action: LoadPurchases, error) => {
          return new PurchasesLoadError(error);
        },
      })
    )
  );

  loadPurchasesForSchoolYear$ = createEffect(() =>
    this.actions.pipe(
      ofType(PurchasesActionTypes.LoadPurchasesForSchoolYear),
      concatLatestFrom(() => this.store.select(PurchaseQueries.getLoaded)),
      fetch({
        run: (action: LoadPurchasesForSchoolYear, loaded) => {
          if (!action.payload.force && loaded) return;
          return this.purchaseService
            .getAllForYear(action.payload.schoolYear)
            .pipe(map((purchases) => new PurchasesLoaded({ purchases })));
        },
        onError: (action: LoadPurchasesForSchoolYear, error) => {
          return new PurchasesLoadError(error);
        },
      })
    )
  );

  placeOrder$ = createEffect(() =>
    this.actions.pipe(
      ofType(PurchasesActionTypes.PlaceOrder),
      optimisticUpdate({
        run: (action: PlaceOrder) => {
          const { productIds, fullDigital, schoolId, schoolBillingAddress, customerOrderReference } = action.payload;
          return this.purchaseService
            .placeOrder(schoolId, productIds, fullDigital, customerOrderReference, schoolBillingAddress)
            .pipe(
              map(castDates),
              switchMap(({ purchase, purchaseProducts, licenses, schoolBillings }) => {
                const effectFeedback = new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'De bestelling is geplaatst.'
                  ),
                });
                const actions: Action[] = [
                  new AddPurchase({ purchase }),
                  new AddPurchaseProducts({ purchaseProducts }),
                  new UpsertLicenses({ licenses }),
                  new UpsertSchoolBillings({ schoolBillings }),
                  effectFeedback,
                ];
                return from(actions);
              })
            );
        },
        undoAction: (action: PlaceOrder, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de bestelling te plaatsen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  deletePurchase$ = createEffect(() =>
    this.actions.pipe(
      ofType(PurchasesActionTypes.DeletePurchase),
      optimisticUpdate({
        run: (action: DeletePurchase) => {
          return this.purchaseService.deletePurchase(action.payload.id).pipe(
            switchMap(({ purchases, purchaseProducts, schoolBilling }) => {
              const effectFeedback = new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'De purchase werd verwijderd.'
                ),
              });

              const actions: Action[] = [
                new UpsertPurchases({ purchases }),
                new UpsertPurchaseProducts({ purchaseProducts }),
                new UpsertSchoolBilling({ schoolBilling }),
                effectFeedback,
              ];

              return from(actions);
            })
          );
        },
        undoAction: (action: DeletePurchase, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de aankoop te verwijderen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  createPurchase$ = createEffect(() =>
    this.actions.pipe(
      ofType(PurchasesActionTypes.CreatePurchase),
      optimisticUpdate({
        run: (action: CreatePurchase) => {
          return this.purchaseService.createPurchase(action.payload.schoolId, action.payload.purchase).pipe(
            map(castDates),
            switchMap(({ purchase, purchaseProducts, schoolBillings, licenses }) => {
              const effectFeedback = new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(this.uuid(), action, 'De aankoop werd gemaakt.'),
              });

              const actions: Action[] = [
                new AddPurchase({ purchase }),
                new AddPurchaseProducts({ purchaseProducts }),
                new UpsertLicenses({ licenses }),
                new UpsertSchoolBillings({ schoolBillings }),
                effectFeedback,
              ];
              return from(actions);
            })
          );
        },
        undoAction: (action: CreatePurchase, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de aankoop te maken.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  finalisePurchases$ = createEffect(() =>
    this.actions.pipe(
      ofType(PurchasesActionTypes.FinalisePurchases),
      pessimisticUpdate({
        run: (action: FinalisePurchases) => {
          return this.purchaseService.finalisePurchases(action.payload.purchaseIds).pipe(
            map(castDates),
            switchMap(({ purchases, purchaseProducts, schoolBillings }) => {
              const effectFeedback = new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'De aankopen werden geconsolideerd.'
                ),
              });
              const actions: Action[] = [
                new UpsertPurchases({ purchases }),
                new UpsertPurchaseProducts({ purchaseProducts }),
                new UpsertSchoolBillings({ schoolBillings }),
                effectFeedback,
              ];
              return from(actions);
            })
          );
        },
        onError: (action: FinalisePurchases, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de aankopen te consolideren.'
            ),
          });
          return errorFeedbackAction;
        },
      })
    )
  );

  startUpdatePurchase$ = createEffect(() =>
    this.actions.pipe(
      ofType(PurchasesActionTypes.StartUpdatePurchase),
      pessimisticUpdate({
        run: (action: StartUpdatePurchase) => {
          return this.purchaseService
            .updatePurchase(action.payload.schoolId, action.payload.purchase.id, action.payload.purchase.changes)
            .pipe(
              map(castDates),
              switchMap(({ purchase, purchaseProducts, licenses, purchaseProductIdsToDelete, schoolBillings }) => {
                const effectFeedback = new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'De aankoop werd bijgewerkt.'
                  ),
                });
                const actions: Action[] = [
                  new UpdatePurchase({
                    schoolId: action.payload.schoolId,
                    purchase: {
                      id: purchase.id,
                      changes: purchase,
                    },
                  }),
                  new UpsertPurchaseProducts({ purchaseProducts }),
                  new UpsertLicenses({ licenses }),
                  new DeletePurchaseProducts({
                    ids: purchaseProductIdsToDelete,
                  }),
                  new UpsertSchoolBillings({ schoolBillings }),
                  effectFeedback,
                ];
                return from(actions);
              })
            );
        },
        onError: (action: StartUpdatePurchase, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de aankoop bij te werken.'
            ),
          });
          return errorFeedbackAction;
        },
      })
    )
  );

  constructor(
    @Inject('uuid')
    private uuid,
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(PURCHASE_SERVICE_TOKEN)
    private purchaseService: PurchaseServiceInterface
  ) {}
}

function castDates<
  T extends {
    purchase?: PurchaseInterface;
    purchases?: PurchaseInterface[];
    licenses?: LicenseInterface[];
  }
>(response: T): T {
  response.purchase = response.purchase && {
    ...response.purchase,
    created: response.purchase.created && new Date(response.purchase.created),
    updated: response.purchase.updated && new Date(response.purchase.updated),
  };

  response.purchases =
    response.purchases &&
    response.purchases.map((purchase) => ({
      ...purchase,
      created: purchase.created && new Date(purchase.created),
      updated: purchase.updated && new Date(purchase.updated),
    }));

  response.licenses =
    response.licenses &&
    response.licenses.map((license) => ({
      ...license,
      activated: license.activated && new Date(license.activated),
      expired: license.expired && new Date(license.expired),
    }));

  return response;
}
