import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { EduContentTOCEduContentInterface } from '../../+models';
import {
  EduContentTocEduContentsActions,
  EduContentTocEduContentsActionTypes,
} from './edu-content-toc-edu-content.actions';

export const NAME = 'eduContentTocEduContents';

export interface State extends EntityState<EduContentTOCEduContentInterface> {
  // additional entities state properties
  error?: any;
  loadedForBook: Dictionary<boolean>;
}

export const adapter: EntityAdapter<EduContentTOCEduContentInterface> =
  createEntityAdapter<EduContentTOCEduContentInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loadedForBook: {},
});

export function reducer(state = initialState, action: EduContentTocEduContentsActions): State {
  switch (action.type) {
    case EduContentTocEduContentsActionTypes.EduContentTocEduContentsLoadError: {
      return { ...state, loadedForBook: {}, error: action.payload };
    }

    case EduContentTocEduContentsActionTypes.AddLoadedBook: {
      return {
        ...state,
        loadedForBook: { ...state.loadedForBook, [action.payload.bookId]: true },
      };
    }

    case EduContentTocEduContentsActionTypes.AddEduContentTocEduContentsForBook: {
      return adapter.addMany(action.payload.eduContentTocEduContents, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectAll, selectEntities, selectTotal } = adapter.getSelectors();
