import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  inject,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { LearnosityItemsConfigInterface } from '@campus/dal';
import { learnosityScriptVersion } from '../../constants/learnosity-version';
import { LEARNOSITY_WINDOW } from '../../services';
@Component({
  selector: 'campus-learnosity-reporting',
  templateUrl: './reporting.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportingComponent implements OnInit {
  @HostBinding('class') defaultClasses = ['ca', 'block'];

  private window = inject(LEARNOSITY_WINDOW);
  private renderer: Renderer2 = inject(Renderer2);
  private el: ElementRef = inject(ElementRef);

  private script = null;

  @Input() config: LearnosityItemsConfigInterface;

  ngOnInit(): void {
    if (document.getElementById('learnosity_reports_script_id')) {
      this.initializeLearnosity();
    } else {
      this.script = this.renderer.createElement('script');
      this.script.id = 'learnosity_reports_script_id';
      this.script.type = 'text/javascript';
      this.script.src = `//reports.learnosity.com/?${learnosityScriptVersion}`;
      this.script.onload = () => {
        this.initializeLearnosity();
      };
      this.renderer.appendChild(this.el.nativeElement.ownerDocument.head, this.script);
    }
  }

  initializeLearnosity() {
    this.window.LearnosityReports.init(this.config, '#learnosity_reports');
  }
}
