import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { GoalTopicServiceInterface, UpdateGoalTopicRelationChangesInterface } from '.';
import { GoalTopicApi } from '../+api';
import { GoalTopicInterface } from '../+models';
import { DAL_OPTIONS, DalOptions } from '../dal.module';

@Injectable({
  providedIn: 'root',
})
export class GoalTopicService implements GoalTopicServiceInterface {
  private http = inject(HttpClient);
  private dalOptions = inject<DalOptions>(DAL_OPTIONS);
  constructor(private goalTopicApi: GoalTopicApi) {}

  getGoalTopicsForLearningArea(learningAreaId: number): Observable<GoalTopicInterface[]> {
    // TODO SDK take a closer look at this, why is it get*Goals* instead of getGoalTopicsForLearningArea
    return this.goalTopicApi.getGoalsForLearningArea(learningAreaId, false);
  }

  getLinkedEduContentMetadata(goalTopicId: number) {
    return this.goalTopicApi.getLinkedEduContentMetadata(goalTopicId);
  }

  removeGoalTopic(goalTopicId: number) {
    return this.goalTopicApi.deleteById(goalTopicId).pipe(mapTo(true));
  }

  updateGoalTopic(goalTopicId: number, updates: Partial<GoalTopicInterface>) {
    return this.goalTopicApi.patchAttributes(goalTopicId, updates) as Observable<GoalTopicInterface>;
  }

  createGoalTopic(goalTopic: GoalTopicInterface) {
    return this.goalTopicApi.create(goalTopic);
  }

  getGoalTopics(
    searchTerm?: string,
    filters?: { [key: string]: string | number[] | number },
    pagination?: { from?; amount? },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<{ results: GoalTopicInterface[]; count: number }> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/GoalTopics/v2/filtered`;
    const params = new HttpParams({
      fromObject: {
        searchTerm: searchTerm || '',
        filters: JSON.stringify(filters || {}),
        columns: JSON.stringify(columns || null),
        sorting: JSON.stringify(sorting || {}),
        pagination: JSON.stringify(pagination || {}),
      },
    });

    return this.http.get<{ results: GoalTopicInterface[]; count: number }>(url, {
      params,
      withCredentials: true,
    });
  }

  deleteGoalTopic(goalTopicId: number) {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/GoalTopics/v2/${goalTopicId}`;

    return this.http.delete(url, { withCredentials: true });
  }

  getGoalTopic(goalTopicId: number): Observable<GoalTopicInterface> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/GoalTopics/v2/${goalTopicId}`;
    return this.http.get<GoalTopicInterface>(url, {
      withCredentials: true,
    });
  }

  patchGoalTopic(goalTopicId: number, changes?: Partial<GoalTopicInterface>): Observable<number> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/GoalTopics/v2/${goalTopicId}`;
    const body = { changes };
    return this.http.patch<number>(url, body, { withCredentials: true });
  }

  updateGoalTopicRelation(goalTopicId: number, body: UpdateGoalTopicRelationChangesInterface): Observable<number> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/GoalTopics/v2/${goalTopicId}/relations`;
    return this.http.post<number>(url, body, { withCredentials: true });
  }

  updateMultiple(goalTopics: Partial<GoalTopicInterface>[]): Observable<number[]> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/GoalTopics/v2/update-multiple`;
    const body = { goalTopics };

    return this.http.patch<number[]>(url, body, { withCredentials: true });
  }

  createGoalTopicV2(learningDomainId: number, goalGroupIds: number[], displayOrder: number) {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/GoalTopics/v2/`;

    return this.http.post<number>(url, { learningDomainId, goalGroupIds, displayOrder }, { withCredentials: true });
  }
}
