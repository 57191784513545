import { Action } from '@ngrx/store';
import { MethodGoalInterface } from '../../+models';

export enum MethodGoalsActionTypes {
  MethodGoalsLoaded = '[MethodGoals] MethodGoals Loaded',
  MethodGoalsLoadError = '[MethodGoals] Load Error',
  LoadMethodGoals = '[MethodGoals] Load MethodGoals',
  LoadMethodGoalsForBook = '[MethodGoals] Load MethodGoals For Book',
  AddMethodGoalsForBook = '[MethodGoals] Add MethodGoals For Book',
}

export class LoadMethodGoals implements Action {
  readonly type = MethodGoalsActionTypes.LoadMethodGoals;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class LoadMethodGoalsForBook implements Action {
  readonly type = MethodGoalsActionTypes.LoadMethodGoalsForBook;

  constructor(
    public payload: { bookId: number; userId: number } = {
      bookId: null,
      userId: null,
    }
  ) {}
}

export class AddMethodGoalsForBook implements Action {
  readonly type = MethodGoalsActionTypes.AddMethodGoalsForBook;

  constructor(
    public payload: {
      bookId: number;
      methodGoals: MethodGoalInterface[];
    }
  ) {}
}

export class MethodGoalsLoaded implements Action {
  readonly type = MethodGoalsActionTypes.MethodGoalsLoaded;

  constructor(public payload: { methodGoals: MethodGoalInterface[] }) {}
}

export class MethodGoalsLoadError implements Action {
  readonly type = MethodGoalsActionTypes.MethodGoalsLoadError;
  constructor(public payload: any) {}
}

export type MethodGoalsActions =
  | LoadMethodGoals
  | MethodGoalsLoaded
  | MethodGoalsLoadError
  | LoadMethodGoalsForBook
  | AddMethodGoalsForBook;
