import { NgModule } from '@angular/core';
import { MAINTENANCE_CHECK_BASE_URL } from '@campus/browser';
import {
  AlertService,
  ALERT_SERVICE_TOKEN,
  AuthService,
  AUTH_SERVICE_TOKEN,
  BundleService,
  BUNDLE_SERVICE_TOKEN,
  ClassGroupService,
  CLASS_GROUP_SERVICE_TOKEN,
  CredentialService,
  CREDENTIAL_SERVICE_TOKEN,
  CurrentExerciseActions,
  DalState,
  DataService,
  DATA_SERVICE_TOKEN,
  DiaboloPhaseService,
  DIABOLO_PHASE_SERVICE_TOKEN,
  DidacticFunctionService,
  DIDACTIC_FUNCTION_SERVICE_TOKEN,
  EduContentBookService,
  EduContentProductTypeService,
  EduContentService,
  EduContentTocService,
  EduNetService,
  EDU_CONTENT_BOOK_SERVICE_TOKEN,
  EDU_CONTENT_PRODUCT_TYPE_SERVICE_TOKEN,
  EDU_CONTENT_SERVICE_TOKEN,
  EDU_CONTENT_TOC_SERVICE_TOKEN,
  EDU_NET_SERVICE_TOKEN,
  EvaluationSubjectGoalService,
  EvaluationSubjectService,
  EVALUATION_SUBJECT_GOAL_SERVICE_TOKEN,
  EVALUATION_SUBJECT_SERVICE_TOKEN,
  FavoriteService,
  FAVORITE_SERVICE_TOKEN,
  GoalService,
  GoalYearService,
  GOAL_SERVICE_TOKEN,
  GOAL_YEAR_SERVICE_TOKEN,
  GroupPersonService,
  GroupService,
  GROUP_PERSON_SERVICE_TOKEN,
  GROUP_SERVICE_TOKEN,
  HistoryService,
  HISTORY_SERVICE_TOKEN,
  LearningAreaService,
  LEARNINGAREA_SERVICE_TOKEN,
  LearningDomainService,
  LearningPlanGoalService,
  LearningPlanService,
  LearningRecordService,
  LEARNING_DOMAIN_SERVICE_TOKEN,
  LEARNING_PLAN_GOAL_PROGRESS_SERVICE_TOKEN,
  LEARNING_PLAN_GOAL_SERVICE_TOKEN,
  LEARNING_PLAN_SERVICE_TOKEN,
  LEARNING_RECORD_SERVICE_TOKEN,
  LevelService,
  LEVEL_SERVICE_TOKEN,
  MethodLevelService,
  MethodProductGroupProductService,
  MethodProductGroupService,
  MethodService,
  METHOD_LEVEL_SERVICE_TOKEN,
  METHOD_PRODUCT_GROUP_PRODUCT_SERVICE_TOKEN,
  METHOD_PRODUCT_GROUP_SERVICE_TOKEN,
  METHOD_SERVICE_TOKEN,
  PersonPreferenceService,
  PersonProductService,
  PersonService,
  PERSON_PREFERENCE_SERVICE_TOKEN,
  PERSON_PRODUCT_SERVICE_TOKEN,
  PERSON_SERVICE_TOKEN,
  PurchaseProductService,
  PurchaseService,
  PURCHASE_PRODUCT_SERVICE_TOKEN,
  PURCHASE_SERVICE_TOKEN,
  ResultActions,
  ResultInterface,
  SchoolService,
  SchoolTypeService,
  SCHOOL_SERVICE_TOKEN,
  SCHOOL_TYPE_SERVICE_TOKEN,
  StudentContentStatusService,
  STUDENT_CONTENT_STATUS_SERVICE_TOKEN,
  TaskClassGroupService,
  TaskEduContentService,
  TaskEvaluationSubjectScoreService,
  TaskEvaluationSubjectService,
  TaskGoalYearLevelService,
  TaskGroupService,
  TaskInstanceService,
  TaskLevelSettingService,
  TaskService,
  TaskStudentService,
  TASK_CLASS_GROUP_SERVICE_TOKEN,
  TASK_EDU_CONTENT_SERVICE_TOKEN,
  TASK_EVALUATION_SUBJECT_SCORE_SERVICE_TOKEN,
  TASK_EVALUATION_SUBJECT_SERVICE_TOKEN,
  TASK_GOAL_YEAR_LEVEL_SERVICE_TOKEN,
  TASK_GROUP_SERVICE_TOKEN,
  TASK_INSTANCE_SERVICE_TOKEN,
  TASK_LEVEL_SETTING_SERVICE_TOKEN,
  TASK_SERVICE_TOKEN,
  TASK_STUDENT_SERVICE_TOKEN,
  UnlockedBoekeGroupService,
  UnlockedBoekeStudentService,
  UnlockedContentService,
  UnlockedFreePracticeService,
  UNLOCKED_BOEKE_GROUP_SERVICE_TOKEN,
  UNLOCKED_BOEKE_STUDENT_SERVICE_TOKEN,
  UNLOCKED_CONTENT_SERVICE_TOKEN,
  UNLOCKED_FREE_PRACTICE_SERVICE_TOKEN,
  UserLessonService,
  USER_LESSON_SERVICE_TOKEN,
  YearService,
  YEAR_SERVICE_TOKEN,
} from '@campus/dal';
import { ExerciseService, EXERCISE_TOKEN } from '@campus/exercise';
import { LearnosityOpenerService, RESULT_UPDATER_TOKEN } from '@campus/learnosity';
import { FAVORITE_BOOKS_SERVICE_TOKEN, PolpoFavoriteBooksService } from '@campus/pages/shared';
import { ScormExerciseService } from '@campus/scorm';
import {
  APP_NAVIGATION_TREE_TOKEN,
  booksTileServiceDeps,
  booksTileServiceFactory,
  BOOKS_TILE_SERVICE_TOKEN,
  NavigationItemService,
  NAVIGATION_ITEM_SERVICE_TOKEN,
} from '@campus/shared';
import { ObjectFunctions } from '@campus/utils';
import { Store } from '@ngrx/store';
import { environment } from '../environments/environment';
import { polpoConfig } from './app.config';
import {
  SearchTermFilterFactory,
  SEARCH_TERM_FILTER_FACTORY_TOKEN,
} from './factories/search-term-filter/search-term-filter.factory';
import { FavIconService, FAVICON_SERVICE_TOKEN } from './services/favicons';

@NgModule({
  providers: [
    //app level services
    { provide: NAVIGATION_ITEM_SERVICE_TOKEN, useClass: NavigationItemService },
    { provide: APP_NAVIGATION_TREE_TOKEN, useValue: polpoConfig.appNavtree },
    {
      provide: FAVICON_SERVICE_TOKEN,
      useClass: FavIconService,
    },
    {
      provide: SEARCH_TERM_FILTER_FACTORY_TOKEN,
      useClass: SearchTermFilterFactory,
    },

    {
      provide: NAVIGATION_ITEM_SERVICE_TOKEN,
      useClass: NavigationItemService,
    },
    // dal services
    { provide: EDU_CONTENT_SERVICE_TOKEN, useClass: EduContentService },
    {
      provide: UNLOCKED_BOEKE_STUDENT_SERVICE_TOKEN,
      useClass: UnlockedBoekeStudentService,
    },
    {
      provide: UNLOCKED_CONTENT_SERVICE_TOKEN,
      useClass: UnlockedContentService,
    },
    {
      provide: UNLOCKED_BOEKE_GROUP_SERVICE_TOKEN,
      useClass: UnlockedBoekeGroupService,
    },
    { provide: BUNDLE_SERVICE_TOKEN, useClass: BundleService },
    { provide: LEARNINGAREA_SERVICE_TOKEN, useClass: LearningAreaService },
    { provide: METHOD_SERVICE_TOKEN, useClass: MethodService },
    {
      provide: STUDENT_CONTENT_STATUS_SERVICE_TOKEN,
      useClass: StudentContentStatusService,
    },
    {
      provide: ALERT_SERVICE_TOKEN,
      useClass: AlertService,
    },
    { provide: PERSON_SERVICE_TOKEN, useClass: PersonService },
    { provide: AUTH_SERVICE_TOKEN, useClass: AuthService },
    { provide: TASK_SERVICE_TOKEN, useClass: TaskService },
    { provide: TASK_INSTANCE_SERVICE_TOKEN, useClass: TaskInstanceService },
    {
      provide: TASK_EDU_CONTENT_SERVICE_TOKEN,
      useClass: TaskEduContentService,
    },
    { provide: CREDENTIAL_SERVICE_TOKEN, useClass: CredentialService },
    { provide: EDU_NET_SERVICE_TOKEN, useClass: EduNetService },
    {
      provide: EDU_CONTENT_PRODUCT_TYPE_SERVICE_TOKEN,
      useClass: EduContentProductTypeService,
    },
    { provide: SCHOOL_TYPE_SERVICE_TOKEN, useClass: SchoolTypeService },
    { provide: SCHOOL_SERVICE_TOKEN, useClass: SchoolService },
    { provide: YEAR_SERVICE_TOKEN, useClass: YearService },
    { provide: LEARNING_PLAN_SERVICE_TOKEN, useClass: LearningPlanService },
    { provide: FAVORITE_SERVICE_TOKEN, useClass: FavoriteService },
    { provide: EDU_CONTENT_TOC_SERVICE_TOKEN, useClass: EduContentTocService },
    { provide: LEARNING_DOMAIN_SERVICE_TOKEN, useClass: LearningDomainService },
    { provide: HISTORY_SERVICE_TOKEN, useClass: HistoryService },
    { provide: DIABOLO_PHASE_SERVICE_TOKEN, useClass: DiaboloPhaseService },
    { provide: GROUP_SERVICE_TOKEN, useClass: GroupService },
    { provide: GROUP_PERSON_SERVICE_TOKEN, useClass: GroupPersonService },
    { provide: CLASS_GROUP_SERVICE_TOKEN, useClass: ClassGroupService },
    {
      provide: EDU_CONTENT_BOOK_SERVICE_TOKEN,
      useClass: EduContentBookService,
    },
    {
      provide: DATA_SERVICE_TOKEN,
      useClass: DataService,
    },
    {
      provide: EDU_CONTENT_BOOK_SERVICE_TOKEN,
      useClass: EduContentBookService,
    },
    {
      provide: DIDACTIC_FUNCTION_SERVICE_TOKEN,
      useClass: DidacticFunctionService,
    },
    {
      provide: CLASS_GROUP_SERVICE_TOKEN,
      useClass: ClassGroupService,
    },
    {
      provide: DIABOLO_PHASE_SERVICE_TOKEN,
      useClass: DiaboloPhaseService,
    },
    {
      provide: LEARNING_PLAN_GOAL_SERVICE_TOKEN,
      useClass: LearningPlanGoalService,
    },
    {
      provide: LEARNING_PLAN_GOAL_PROGRESS_SERVICE_TOKEN,
      useClass: LearningPlanGoalService,
    },
    {
      provide: USER_LESSON_SERVICE_TOKEN,
      useClass: UserLessonService,
    },
    { provide: PERSON_PREFERENCE_SERVICE_TOKEN, useClass: PersonPreferenceService },
    { provide: PERSON_PRODUCT_SERVICE_TOKEN, useClass: PersonProductService },
    { provide: LEVEL_SERVICE_TOKEN, useClass: LevelService },
    { provide: TASK_CLASS_GROUP_SERVICE_TOKEN, useClass: TaskClassGroupService },
    { provide: TASK_GROUP_SERVICE_TOKEN, useClass: TaskGroupService },
    { provide: TASK_STUDENT_SERVICE_TOKEN, useClass: TaskStudentService },
    // TASK PAGES
    { provide: GOAL_SERVICE_TOKEN, useClass: GoalService },
    { provide: GOAL_YEAR_SERVICE_TOKEN, useClass: GoalYearService },
    { provide: TASK_EVALUATION_SUBJECT_SERVICE_TOKEN, useClass: TaskEvaluationSubjectService },
    { provide: TASK_EVALUATION_SUBJECT_SCORE_SERVICE_TOKEN, useClass: TaskEvaluationSubjectScoreService },
    { provide: EVALUATION_SUBJECT_GOAL_SERVICE_TOKEN, useClass: EvaluationSubjectGoalService },
    { provide: GOAL_SERVICE_TOKEN, useClass: GoalService },
    { provide: GOAL_YEAR_SERVICE_TOKEN, useClass: GoalYearService },
    { provide: TASK_GOAL_YEAR_LEVEL_SERVICE_TOKEN, useClass: TaskGoalYearLevelService },
    { provide: TASK_LEVEL_SETTING_SERVICE_TOKEN, useClass: TaskLevelSettingService },
    { provide: EVALUATION_SUBJECT_SERVICE_TOKEN, useClass: EvaluationSubjectService },

    { provide: METHOD_LEVEL_SERVICE_TOKEN, useClass: MethodLevelService },
    { provide: UNLOCKED_FREE_PRACTICE_SERVICE_TOKEN, useClass: UnlockedFreePracticeService },
    {
      provide: BOOKS_TILE_SERVICE_TOKEN,
      useFactory: booksTileServiceFactory,
      deps: booksTileServiceDeps,
    },
    {
      provide: METHOD_PRODUCT_GROUP_SERVICE_TOKEN,
      useClass: MethodProductGroupService,
    },

    {
      provide: PURCHASE_SERVICE_TOKEN,
      useClass: PurchaseService,
    },
    {
      provide: PURCHASE_PRODUCT_SERVICE_TOKEN,
      useClass: PurchaseProductService,
    },
    {
      provide: METHOD_PRODUCT_GROUP_PRODUCT_SERVICE_TOKEN,
      useClass: MethodProductGroupProductService,
    },
    {
      provide: MAINTENANCE_CHECK_BASE_URL,
      useValue: environment.website.url + '/',
    },
    ExerciseService,
    { provide: EXERCISE_TOKEN, multi: true, useClass: LearnosityOpenerService },
    { provide: EXERCISE_TOKEN, multi: true, useClass: ScormExerciseService },
    {
      provide: RESULT_UPDATER_TOKEN,
      useFactory: (store: Store<DalState>) => (result: ResultInterface, finalUpdate?: boolean) => {
        const mappedResult = ObjectFunctions.castAs(Date)(['created', 'lastUpdated'])(result);

        store.dispatch(new ResultActions.UpsertResult({ result: mappedResult }));
        store.dispatch(new CurrentExerciseActions.SetCurrentResult({ result: mappedResult, finalUpdate }));
      },
      deps: [Store],
    },
    {
      provide: FAVORITE_BOOKS_SERVICE_TOKEN,
      useClass: PolpoFavoriteBooksService,
    },
    {
      provide: LEARNING_RECORD_SERVICE_TOKEN,
      useClass: LearningRecordService,
    },
  ],
})
export class AppTokenModule {}
