import {
  AfterViewInit,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { WINDOW } from '@campus/browser';
import { ListFormat, ListViewComponent } from '@campus/ui';
import { ArrayFunctions } from '@campus/utils';
import { SearchModeInterface, SearchResultInterface, SearchStateInterface, SortModeInterface } from '../../interfaces';
import { ResultItemBase } from './result.component.base';

// https://angular.io/guide/dynamic-component-loader

@Directive({
  selector: '[campusResultListHost], [result-list-host]',
})
export class ResultListDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

/**
 * Usage:
 * - Create a new component `MyResultItemComponent` in your app to display a result
 * - Let the component extend `ResultItemBase`:
 *     requires an `@Input() data: MyResultInterface;`
 * - Add the component to your NgModule with `entryComponents: [MyResultItemComponent]`
 *
 * @example
 * component:
 *   resultsPage: SearchResultInterface;
 *   myResultItemComponent: Type<SearchResultItemInterface> = MyResultItemComponent;
 *
 * template:
 *   <campus-results-list
 *     [resultsPage]="resultsPage$ | async"
 *     [searchMode]="searchMode"
 *     [searchState]="searchState$ | async"
 *     (sortBy)="onSortChanged($event)"
 *     (getNextPage)="onGetNextPage($event)"
 *   ></campus-results-list>
 * @example
 */
@Component({
  selector: 'campus-results-list',
  templateUrl: './results-list.component.html',
  styleUrls: ['./results-list.component.scss'],
})
export class ResultsListComponent implements OnDestroy, AfterViewInit {
  public count = 0;
  public sortModes: SortModeInterface[];
  public activeSortMode: SortModeInterface;
  public searchCompleted = true; // are there more results to fetch?

  // items contained by the resultlist view (used by SearchComponent)
  public items: QueryList<ResultItemBase>;

  private clearResultsTimer: number;
  private isLoadingResults = true; // has the previous page of results been loaded?
  private _searchMode: SearchModeInterface;
  private isInViewportObserver: IntersectionObserver;

  @Input() listFormat: ListFormat = ListFormat.LINE;
  @Input() itemSize = 100;
  @Input()
  set searchMode(searchMode: SearchModeInterface) {
    if (searchMode) {
      this._searchMode = searchMode;
      this.initialize();
    }
  }
  get searchMode() {
    return this._searchMode;
  }

  @Input()
  set searchState(searchState: SearchStateInterface) {
    if (searchState) {
      this.updateViewFromSearchState(searchState);
    }
  }

  @Input()
  set resultsPage(searchResult: SearchResultInterface) {
    if (searchResult) {
      this.count = searchResult.count;
      this.addResults(searchResult.results);
    }
  }

  @Output() sortBy: EventEmitter<SortModeInterface> = new EventEmitter();
  @Output() getNextPage: EventEmitter<void> = new EventEmitter();

  @ViewChild(ResultListDirective, { static: true })
  resultListHost: ResultListDirective;
  @ViewChild(ListViewComponent, { static: true }) listview: ListViewComponent<any>;
  @ViewChild('loadMore', { static: true }) loadMoreEl: ElementRef;

  constructor(private elRef: ElementRef, @Inject(WINDOW) private nativeWindow: Window) {}

  ngOnDestroy(): void {
    this.clearIntersectionObserver();
  }

  ngAfterViewInit(): void {
    this.items = this.listview.items as any;

    this.setupIntersectionObserver();
  }

  sortModeChanged(sortMode: SortModeInterface): void {
    if (this.activeSortMode !== sortMode) {
      this.sortBy.emit(sortMode);
    }
  }

  private setupIntersectionObserver() {
    // add a class `--scroll-viewport` to the scrollable viewport container
    // this class was added to the `ui-shell__body` element as the default
    const viewportEl: Element = this.elRef.nativeElement.closest('.--scroll-viewport');
    const rootMargin = `0px 0px ${this.itemSize * 4}px 0px`; // trigger at specified distance from bottom
    const options: IntersectionObserverInit = { root: viewportEl, rootMargin, threshold: 0 };

    this.isInViewportObserver = new this.nativeWindow.IntersectionObserver(this.toggleIntersection.bind(this), options);
    this.isInViewportObserver.observe(this.loadMoreEl.nativeElement);
  }

  private toggleIntersection(entries: IntersectionObserverEntry[]) {
    const isIntersecting = entries.some((entry) => entry.isIntersecting);

    if (!this.searchCompleted && !this.isLoadingResults && isIntersecting) {
      this.checkForMoreResults();
    }
  }

  private clearIntersectionObserver() {
    this.isInViewportObserver?.disconnect();
  }

  private initialize(): void {
    this.sortModes = this.searchMode.results.sortModes;
    if (!this.activeSortMode) {
      // default sortMode if not set by searchState
      this.activeSortMode = this.sortModes[0];
    }
  }

  private updateViewFromSearchState(searchState: SearchStateInterface): void {
    this.isLoadingResults = true;
    if (searchState.from === undefined || searchState.from === null) {
      // no search running
      this.clearResults();
      this.searchCompleted = true;
    } else if (searchState.from === 0) {
      this.searchCompleted = false;
      if (this.clearResultsTimer) {
        // a new search was triggered before the previous results arrived
        this.nativeWindow.clearInterval(this.clearResultsTimer);
      }
      // UX: don't clear results immediately to avoid flicker effects
      this.clearResultsTimer = this.nativeWindow.setTimeout(() => {
        this.clearResults();
      }, 1000);
    }

    if (searchState.sort) {
      this.activeSortMode = this.findSortModeBySortValue(searchState.sort);
    }
  }

  private findSortModeBySortValue(sortKeys: string | string[]): SortModeInterface {
    if (!Array.isArray(sortKeys)) {
      return this.sortModes.find((sortMode) => sortMode.name === sortKeys);
    }

    const activeSortMode = this.sortModes.find((sortMode) => ArrayFunctions.getArrayEquality(sortMode.sort, sortKeys));

    return activeSortMode;
  }

  private addResults(results: any[]): void {
    this.searchCompleted = results.length === 0;

    if (this.clearResultsTimer) {
      this.clearResults();
    }
    if (this.searchCompleted) {
      return;
    }

    // update template
    results.forEach((result) => this.createResultComponent(result));

    // update private state variables
    this.isLoadingResults = false; // ready to send request for new page of results

    // in case there's no scrollbar yet, we should manually trigger search
    // until results were added outside of the viewport
    this.triggerIntersectionObserver();
  }

  private triggerIntersectionObserver() {
    this.isInViewportObserver.unobserve(this.loadMoreEl.nativeElement);
    this.isInViewportObserver.observe(this.loadMoreEl.nativeElement);
  }

  private createResultComponent(result): void {
    const componentRef = this.resultListHost.viewContainerRef.createComponent(this.searchMode.results.component);
    const resultItem = componentRef.instance;
    resultItem.data = result;
    resultItem.listRef = this.listview;
  }

  private clearResults() {
    if (this.clearResultsTimer) {
      // cancel timer when results loaded before timeout
      this.nativeWindow.clearTimeout(this.clearResultsTimer);
      this.clearResultsTimer = null;
    }
    if (this.listview) this.listview.resetItems();
    if (this.resultListHost) this.resultListHost.viewContainerRef.clear();
  }

  private checkForMoreResults(): void {
    // disable multiple event triggers for the same page
    this.isLoadingResults = true;
    // ask to load next page of results
    this.getNextPage.emit();
  }
}
