import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SectionService } from '../../section';
import { SectionServiceInterface } from '../../section/section.service.interface';
import { DalState } from '../dal.state.interface';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { AddSectionContents, DeleteSectionContents } from '../section-content/section-content.actions';
import { getSectionContentsBySectionId } from '../section-content/section-content.selectors';
import { AddSections, DeleteSections, SectionsActionTypes, StartAddSections } from './section.actions';
import { getSectionsByEduContentTocId } from './section.selectors';

@Injectable()
export class SectionEffects {
  constructor(
    private actions$: Actions,
    private store: Store<DalState>,
    @Inject(SectionService)
    private sectionService: SectionServiceInterface,
    @Inject('uuid') private uuid: () => string
  ) {}

  startAddSections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SectionsActionTypes.StartAddSections),
      concatLatestFrom(() => [
        this.store.pipe(select(getSectionsByEduContentTocId)),
        this.store.pipe(select(getSectionContentsBySectionId)),
      ]),
      pessimisticUpdate({
        run: (action: StartAddSections, sectionsByTocId, sectionContentsBySectionId) => {
          return this.sectionService.replaceSections(action.payload.sections, action.payload.eduContentTocId).pipe(
            switchMap(({ sections, sectionContents }) => {
              const sectionIdsToDelete = (sectionsByTocId[action.payload.eduContentTocId] || []).map(
                (section) => section.id
              );

              const sectionContentIdsToDelete = sectionIdsToDelete
                .reduce((acc, sectionId) => {
                  if (sectionContentsBySectionId[sectionId]?.length) acc.push(...sectionContentsBySectionId[sectionId]);
                  return acc;
                }, [])
                .map((sectionContent) => sectionContent.id);

              const actionsToDispatch: Action[] = [
                new DeleteSections({ ids: sectionIdsToDelete }),
                new DeleteSectionContents({ ids: sectionContentIdsToDelete }),
                new AddSections({ sections }),
                new AddSectionContents({ sectionContents }),
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'De secties zijn aangepast.'
                  ),
                }),
              ];

              return from(actionsToDispatch);
            })
          );
        },
        onError: (action: StartAddSections, error: any) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de secties aan te passen.'
            ),
          });
        },
      })
    )
  );

  deleteSections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SectionsActionTypes.DeleteSection),
      optimisticUpdate({
        run: (action: DeleteSections) => {
          return this.sectionService.deleteSections(action.payload.ids).pipe(
            map(() => {
              return new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'De secties werden verwijderd.'
                ),
              });
            })
          );
        },
        undoAction: (action: DeleteSections, error: any) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de secties te verwijderen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );
}
