import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AtlasProductInterface } from './../../+models/AtlasProduct.interface';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './atlas-product.reducer';

export const selectAtlasProductState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectAtlasProductState, (state: State) => state.error);

export const getLoaded = createSelector(selectAtlasProductState, (state: State) => state.loaded);

export const getAll = createSelector(selectAtlasProductState, selectAll);

export const getCount = createSelector(selectAtlasProductState, selectTotal);

export const getIds = createSelector(selectAtlasProductState, selectIds);

export const getAllEntities = createSelector(selectAtlasProductState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * atlasProduct$: AtlasProductInterface[] = this.store.pipe(
    select(AtlasProductQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectAtlasProductState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * atlasProduct$: AtlasProductInterface = this.store.pipe(
    select(AtlasProductQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectAtlasProductState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getMaxLastUpdated = createSelector(getAll, (atlasProducts: AtlasProductInterface[]) =>
  atlasProducts.reduce((maxDate: Date, { lastUpdated }) => (lastUpdated > maxDate ? lastUpdated : maxDate), null)
);
