import { forwardRef, Inject, Injectable } from '@angular/core';
import { BundleInterface } from '@campus/dal';
import { BundleActionInterface } from '../bundle-action.interface';
import {
  BundleActionsTeacherServiceInterface,
  TeacherBundleOpenerInterface,
  TEACHER_BUNDLE_OPENER_TOKEN,
} from './bundle-actions-teacher.service.interface';

@Injectable({ providedIn: 'root' })
export class BundleActionsTeacherService implements BundleActionsTeacherServiceInterface {
  constructor(
    @Inject(forwardRef(() => TEACHER_BUNDLE_OPENER_TOKEN))
    private bundleOpener: TeacherBundleOpenerInterface
  ) {}

  public bundleActionDictionary: {
    [key: string]: BundleActionInterface;
  } = {
    openBundle: {
      label: 'Bekijken',
      icon: 'open-in-new',
      tooltip: 'Open de bundel',
      handler: this.bundleOpener.openBundle.bind(this.bundleOpener),
      isPrimary: true,
    },
    openResultsForBundle: {
      label: 'Resultaten',
      lottieKey: 'results',
      tooltip: 'Toon de resultaten voor de bundel',
      handler: this.bundleOpener.openResultsForBundle.bind(this.bundleOpener),
    },
    acceptSharedBundle: {
      label: 'Aanvaarden',
      icon: 'check',
      tooltip: 'Aanvaard de gedeelde bundel',
      handler: this.bundleOpener.acceptSharedBundle.bind(this.bundleOpener),
    },
    rejectSharedBundle: {
      label: 'Verwijderen',
      lottieKey: 'delete',
      tooltip: 'Verwijder de gedeelde bundel',
      handler: this.bundleOpener.rejectSharedBundle.bind(this.bundleOpener),
    },
  };

  public getActions(bundle: BundleInterface) {
    const keys = bundle.sharedPending
      ? ['openBundle', 'acceptSharedBundle', 'rejectSharedBundle']
      : ['openBundle', 'openResultsForBundle'];

    return keys.map((key) => this.bundleActionDictionary[key]);
  }
}
