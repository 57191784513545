import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { UnlockedFreePracticeInterface } from '../../+models';
import { GroupsActions, GroupsActionTypes } from '../group/group.actions';
import { UnlockedFreePracticesActions, UnlockedFreePracticesActionTypes } from './unlocked-free-practice.actions';

export const NAME = 'unlockedFreePractices';

export interface State extends EntityState<UnlockedFreePracticeInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<UnlockedFreePracticeInterface> =
  createEntityAdapter<UnlockedFreePracticeInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: UnlockedFreePracticesActions | GroupsActions): State {
  switch (action.type) {
    case UnlockedFreePracticesActionTypes.AddUnlockedFreePractice: {
      return adapter.addOne(action.payload.unlockedFreePractice, state);
    }

    case UnlockedFreePracticesActionTypes.UpsertUnlockedFreePractice: {
      return adapter.upsertOne(action.payload.unlockedFreePractice, state);
    }

    case UnlockedFreePracticesActionTypes.AddUnlockedFreePractices: {
      return adapter.addMany(action.payload.unlockedFreePractices, state);
    }

    case UnlockedFreePracticesActionTypes.UpsertUnlockedFreePractices: {
      return adapter.upsertMany(action.payload.unlockedFreePractices, state);
    }

    case UnlockedFreePracticesActionTypes.UpdateUnlockedFreePractice: {
      return adapter.updateOne(action.payload.unlockedFreePractice, state);
    }

    case UnlockedFreePracticesActionTypes.UpdateUnlockedFreePractices: {
      return adapter.updateMany(action.payload.unlockedFreePractices, state);
    }

    case UnlockedFreePracticesActionTypes.DeleteUnlockedFreePractice: {
      // delete = soft delete --> update one
      const update: Update<UnlockedFreePracticeInterface> = { id: action.payload.id, changes: { deleted: true } };
      return adapter.updateOne(update, state);
    }

    case UnlockedFreePracticesActionTypes.DeleteUnlockedFreePractices: {
      // delete = soft delete --> update many
      const updates: Update<UnlockedFreePracticeInterface>[] = action.payload.ids.map((ufpId) => ({
        id: ufpId,
        changes: { deleted: true },
      }));
      return adapter.updateMany(updates, state);
    }

    case UnlockedFreePracticesActionTypes.UnlockedFreePracticesLoaded: {
      return adapter.setAll(action.payload.unlockedFreePractices, {
        ...state,
        loaded: true,
      });
    }

    case UnlockedFreePracticesActionTypes.UnlockedFreePracticesLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case UnlockedFreePracticesActionTypes.ClearUnlockedFreePractices: {
      return adapter.removeAll(state);
    }

    case UnlockedFreePracticesActionTypes.ReplaceUnlockedFreePractices: {
      const ufps: UnlockedFreePracticeInterface[] = action.payload.unlockedFreePractices;
      const updatedState = adapter.upsertMany(ufps, state);
      return updatedState;
    }

    case GroupsActionTypes.DeleteGroup: {
      const idsForGroup = (state.ids as number[]).filter((id) => state.entities[id].groupId === action.payload.id);
      return adapter.removeMany(idsForGroup, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
