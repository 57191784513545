import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_API_TOKEN, EnvironmentApiInterface } from '@campus/environment';
import { Observable } from 'rxjs';
import { map, mapTo, switchMap } from 'rxjs/operators';
import { PersonApi } from '../+api';
import { AccessTokenInterface, PersonInterface } from '../+models';

import {
  AuthServiceInterface,
  LoginCredentials,
  PersonalCodeCredentials,
  RegistrationCredentials,
  ValidateTokenResponse,
} from './auth-service.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements AuthServiceInterface {
  get userId(): number {
    return this.personApi.getCurrentId();
  }

  constructor(
    private personApi: PersonApi,
    private http: HttpClient,
    @Inject(ENVIRONMENT_API_TOKEN) private envApi: EnvironmentApiInterface
  ) {}

  /**
   * gets the current logged in user, throws a 401 error if not logged in
   *
   * @returns {Observable<any>}
   * @memberof AuthService
   */
  getCurrent(): Observable<PersonInterface> {
    return this.personApi.getCurrent();
  }

  getAccessToken$(): Observable<AccessTokenInterface> {
    const byDate = (a: AccessTokenInterface, b: AccessTokenInterface) => +new Date(b.created) - +new Date(a.created);

    return this.getCurrent().pipe(
      switchMap((currentUser) => {
        return this.personApi.getAccessTokens(currentUser.id).pipe(
          map((accessTokens) => {
            return {
              currentUser,
              accessToken: accessTokens.sort(byDate)[0],
            };
          })
        );
      }),
      map(({ currentUser, accessToken }) => {
        return {
          id: accessToken.id,
          userId: accessToken.userId,
          user: currentUser,
        };
      })
    );
  }

  /**
   * checks if a Loopback-cookie exists
   *
   * @returns Boolean
   * @memberof AuthService
   */
  public isLoggedIn(): boolean {
    return !!this.personApi.getCurrentId();
  }

  resetPassword(email: string): Observable<boolean> {
    return this.personApi
      .resetPassword({
        email,
      })
      .pipe(mapTo(true));
  }

  /**
   * logs out the current user this method returns no data.
   *
   * @returns {Observable<boolean>}
   * @memberof AuthService
   */
  logout(): Observable<boolean> {
    return this.personApi.logout();
  }

  /**
   * logs in the current user,
   * The response body contains properties of the AccessToken created on login.
   * Depending on the value of `include` parameter, the body may contain additional properties:
   *
   * @param {Partial<LoginCredentials>} credentials
   * @returns {Observable<any>}
   * @memberof AuthService
   */
  login(credentials: Partial<LoginCredentials>): Observable<any> {
    return this.personApi.login(credentials);
  }

  loginWithPersonalCode(credentials: PersonalCodeCredentials): Observable<any> {
    return this.personApi.loginWithPersonalCode(credentials);
  }

  /**
   * Get permissions from API for current user
   *
   * @returns {Observable<string[]>}
   * @memberof AuthService
   */
  getPermissions(): Observable<string[]> {
    return this.personApi
      .getData(this.userId, 'permissions')
      .pipe(map((res: { permissions: string[] }) => res.permissions));
  }

  validateToken(token: string, userId: number): Observable<ValidateTokenResponse> {
    return this.personApi.validateToken(userId, token) as Observable<ValidateTokenResponse>;
  }

  isUserInSync(stateUser: PersonInterface): Observable<boolean> {
    return this.getCurrent().pipe(
      map((apiUser) => {
        const apiUserId = apiUser ? apiUser.id : null;
        const stateUserId = stateUser ? stateUser.id : null;

        return apiUserId === stateUserId;
      })
    );
  }

  register(registrationData: RegistrationCredentials): Observable<boolean> {
    const { email, password, userType } = registrationData;
    return this.personApi.register(email, password, userType).pipe(mapTo(true));
  }
}
