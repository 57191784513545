import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RoleInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './role.reducer';

export const selectRoleState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectRoleState, (state: State) => state.error);

export const getLoaded = createSelector(selectRoleState, (state: State) => state.loaded);

export const getAll = createSelector(selectRoleState, selectAll);

export const getCount = createSelector(selectRoleState, selectTotal);

export const getIds = createSelector(selectRoleState, selectIds);

export const getAllEntities = createSelector(selectRoleState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * role$: RoleInterface[] = this.store.pipe(
    select(RoleQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectRoleState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * role$: RoleInterface = this.store.pipe(
    select(RoleQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectRoleState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getByName = createSelector(getAll, (roles: RoleInterface[]): Dictionary<RoleInterface> => {
  return groupArrayByKeys<RoleInterface>(roles, ['name'], null, true);
});
