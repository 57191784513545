import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { UnlockedCurriculumTreeInterface } from '../../+models';
import { UnlockedCurriculumTreesActions, UnlockedCurriculumTreesActionTypes } from './unlocked-curriculum-tree.actions';

export const NAME = 'unlockedCurriculumTrees';

export interface State extends EntityState<UnlockedCurriculumTreeInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<UnlockedCurriculumTreeInterface> =
  createEntityAdapter<UnlockedCurriculumTreeInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: UnlockedCurriculumTreesActions): State {
  switch (action.type) {
    case UnlockedCurriculumTreesActionTypes.AddUnlockedCurriculumTree: {
      return adapter.addOne(action.payload.unlockedCurriculumTree, state);
    }

    case UnlockedCurriculumTreesActionTypes.UpsertUnlockedCurriculumTree: {
      return adapter.upsertOne(action.payload.unlockedCurriculumTree, state);
    }

    case UnlockedCurriculumTreesActionTypes.AddUnlockedCurriculumTrees: {
      return adapter.addMany(action.payload.unlockedCurriculumTrees, state);
    }

    case UnlockedCurriculumTreesActionTypes.UpsertUnlockedCurriculumTrees: {
      return adapter.upsertMany(action.payload.unlockedCurriculumTrees, state);
    }

    case UnlockedCurriculumTreesActionTypes.UpdateUnlockedCurriculumTree: {
      return adapter.updateOne(action.payload.unlockedCurriculumTree, state);
    }

    case UnlockedCurriculumTreesActionTypes.UpdateUnlockedCurriculumTrees: {
      return adapter.updateMany(action.payload.unlockedCurriculumTrees, state);
    }

    case UnlockedCurriculumTreesActionTypes.DeleteUnlockedCurriculumTree: {
      return adapter.removeOne(action.payload.id, state);
    }

    case UnlockedCurriculumTreesActionTypes.DeleteUnlockedCurriculumTrees: {
      const updates: Update<UnlockedCurriculumTreeInterface>[] = action.payload.ids.map((id) => ({
        id,
        changes: { deleted: true },
      }));
      return adapter.updateMany(updates, state);
    }

    case UnlockedCurriculumTreesActionTypes.UnlockedCurriculumTreesLoaded: {
      return adapter.setAll(action.payload.unlockedCurriculumTrees, {
        ...state,
        loaded: true,
      });
    }

    case UnlockedCurriculumTreesActionTypes.UnlockedCurriculumTreesLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case UnlockedCurriculumTreesActionTypes.ClearUnlockedCurriculumTrees: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
