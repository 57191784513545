import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  EduContentTocLearningPlanGoalActions,
  EduContentTocLearningPlanGoalsActionTypes,
} from './edu-content-toc-learning-plan-goal.actions';

export const NAME = 'EduContentTocLearningPlanGoals';

export interface State extends EntityState<number> {
  // additional entities state properties
  error?: any;
  loadedForToc: Dictionary<number[]>;
  loadedForBook: Dictionary<boolean>;
}

export const adapter: EntityAdapter<number> = createEntityAdapter<number>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loadedForToc: {},
  loadedForBook: {},
});

export function reducer(state = initialState, action: EduContentTocLearningPlanGoalActions): State {
  switch (action.type) {
    case EduContentTocLearningPlanGoalsActionTypes.AddLearningPlanGoalsIdsForToc: {
      const loadedForTocState = {
        ...state,
        loadedForToc: {
          ...state.loadedForToc,
          [action.payload.tocId]: action.payload.learningPlanGoalIds,
        },
      };
      return loadedForTocState;
    }

    case EduContentTocLearningPlanGoalsActionTypes.AddLearningPlanGoalsIdsForBook: {
      const loadedForTocState = {
        ...state,
        loadedForToc: {
          ...state.loadedForToc,
          ...action.payload.learningPlanGoalIdsByTocId,
        },
        loadedForBook: {
          ...state.loadedForBook,
          [action.payload.bookId]: true,
        },
      };
      return loadedForTocState;
    }

    case EduContentTocLearningPlanGoalsActionTypes.ClearLearningPlanGoalsIdsForToc: {
      const stateWithEmptyLoadedTocs = { ...state, loadedForToc: {}, loadedForBook: {} };
      return stateWithEmptyLoadedTocs;
    }

    default: {
      return state;
    }
  }
}
export const { selectEntities } = adapter.getSelectors();
