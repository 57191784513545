<table *ngIf="rowHeaderColumns && itemColumns" class="[ table border-all row-h-s ]">
  <thead>
    <tr class="[ surface text-left ]">
      <th
        data-cy="check-box-table-head-column-header"
        data-type="row"
        *ngFor="let columnHeader of rowHeaderColumns; let first = first; let last = last"
        [class.corner-top-is-m]="first"
        >{{ columnHeader.caption }}
      </th>
      <th
        data-cy="check-box-table-head-column-header"
        data-type="item"
        *ngFor="let columnHeader of itemColumns; let last = last; trackBy: trackByItemColumns"
        [class.corner-top-right-m]="last"
        >{{ columnHeader.item[columnHeader.label] }}
      </th>
    </tr>
    <tr data-cy="check-box-table-head-row-select-all" *ngIf="selectAllForColumnEnabled" class="[ surface text-left ]">
      <th [colSpan]="rowHeaderColumns.length">Alle</th>
      <th
        data-cy="check-box-table-head-column-header"
        *ngFor="let itemColumn of itemColumns; trackBy: trackByItemColumns"
      >
        <mat-slide-toggle
          color="primary"
          data-cy="check-box-table-select-all"
          #selectAllForColumnToggle
          campusClickPreventDefault
          (click)="clickSelectAllForColumn(itemColumn, selectAllForColumnToggle, itemColumn.isAllSelected)"
          [checked]="itemColumn.isAllSelected"
        ></mat-slide-toggle>
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="subLevels">
      <ng-container *ngFor="let subLevel of subLevels; trackBy: trackBySubLevel">
        <tr
          data-cy="check-box-table-body-row"
          data-type="sublevel"
          [ngClass]="
            subLevel.isHighlighted ? ['primary-container', 'border', 'border-2', 'border-primary'] : ['surface']
          "
        >
          <td data-cy="check-box-table-body-column-header" data-type="row" [colSpan]="rowHeaderColumns.length">
            <span class="[ label-large ]">
              <ng-container
                *ngTemplateOutlet="
                  row || defaultRowTemplate;
                  context: { $implicit: { label: subLevel.item[subLevel.label], data: subLevel } }
                "
              ></ng-container>
            </span>
          </td>
          <td
            data-cy="check-box-table-body-column-header"
            data-type="item"
            *ngFor="let itemHeader of itemColumns; trackBy: subLevel | multiCheckBoxTrackBySubLevel"
          >
            <mat-slide-toggle
              *ngIf="selectAllForSubLevelEnabled"
              data-cy="check-box-table-body-header-select-item"
              #subLevelToggle
              color="primary"
              campusClickPreventDefault
              (click)="clickSubLevelToggle(subLevel, itemHeader, subLevelToggle, itemHeader.isAllSelected)"
              [disabled]="itemHeader.isAllSelected"
              [checked]="itemHeader.isAllSelected || subLevel.content[itemHeader.item[itemHeader.key]]"
            ></mat-slide-toggle>
          </td>
        </tr>
        <ng-container
          *ngTemplateOutlet="
            itemRows;
            context: {
              subLevel: subLevel,
              items: subLevel.children,
              rowHeaderColumns: rowHeaderColumns,
              itemColumns: itemColumns
            }
          "
        ></ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="items">
      <ng-container
        *ngTemplateOutlet="
          itemRows;
          context: {
            subLevel: { content: {} },
            items: items,
            rowHeaderColumns: rowHeaderColumns,
            itemColumns: itemColumns
          }
        "
      ></ng-container>
    </ng-container>

    <ng-template
      #itemRows
      let-subLevel="subLevel"
      let-items="items"
      let-rowHeaderColumns="rowHeaderColumns"
      let-itemColumns="itemColumns"
    >
      <tr
        data-cy="check-box-table-body-row"
        data-type="item"
        *ngFor="let item of items; even as even; let lastRow = last; trackBy: trackByItems"
        [ngClass]="
          item.isHighlighted
            ? ['primary-container', 'border', 'border-2', 'border-primary']
            : even
            ? ['surface']
            : ['secondary-container', 'bg-opacity-glass']
        "
      >
        <td
          data-cy="check-box-table-body-data-header"
          *ngFor="let rowHeaderColumn of rowHeaderColumns; let first = first; trackBy: trackByRowHeaderColumns"
        >
          <ng-container
            *ngTemplateOutlet="
              row || defaultRowTemplate;
              context: { $implicit: { label: item.header[rowHeaderColumn.key], data: item } }
            "
          ></ng-container>
        </td>
        <td
          data-cy="check-box-table-body-data-item"
          *ngFor="let itemColumn of itemColumns; let last = last; trackBy: item | multiCheckBoxTrackByItem"
          data-usetiful="check-box-table-row-item"
        >
          <mat-slide-toggle
            data-cy="check-box-table-body-data-select-item"
            #slideToggle
            color="primary"
            [checked]="
              item.content[itemColumn.item[itemColumn.key]] ||
              subLevel.content[itemColumn.item[itemColumn.key]] ||
              itemColumn.isAllSelected
            "
            campusClickPreventDefault
            (click)="
              clickToggle(
                item.header,
                itemColumn.item,
                subLevel.item,
                slideToggle,
                subLevel.content[itemColumn.item[itemColumn.key]],
                itemColumn.isAllSelected
              )
            "
            [disabled]="itemColumn.isAllSelected || subLevel.content[itemColumn.item[itemColumn.key]]"
          ></mat-slide-toggle>
        </td>
      </tr>
    </ng-template>

    <ng-template #defaultRowTemplate let-data>
      {{ data.label }}
    </ng-template>
  </tbody>
</table>
