import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SchoolProviderInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './school-provider.reducer';

export const selectSchoolProviderState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectSchoolProviderState, (state: State) => state.error);

export const getLoaded = createSelector(selectSchoolProviderState, (state: State) => state.loaded);

export const getAll = createSelector(selectSchoolProviderState, selectAll);

export const getCount = createSelector(selectSchoolProviderState, selectTotal);

export const getIds = createSelector(selectSchoolProviderState, selectIds);

export const getAllEntities = createSelector(selectSchoolProviderState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * schoolProvider$: SchoolProviderInterface[] = this.store.pipe(
    select(SchoolProviderQueries.getByIds({ ids: [2, 1, 3] }))
  );
 */

export const getByIds = (props: { ids: number[] }) =>
  createSelector(selectSchoolProviderState, (state: State) => props.ids.map((id) => state.entities[id]));

/**
 * returns array of objects in the order of the given ids
 * @example
 * schoolProvider$: SchoolProviderInterface = this.store.pipe(
    select(SchoolProviderQueries.getById({ id: 3 }))
  );
 */

export const getById = (props: { id: number }) =>
  createSelector(selectSchoolProviderState, (state: State) => state.entities[props.id]);

export const getAllBySchoolId = createSelector(
  getAll,
  (schoolProviders: SchoolProviderInterface[]) =>
    groupArrayByKeys(schoolProviders, ['schoolId']) as Dictionary<SchoolProviderInterface[]>
);
