import { inject, Injectable } from '@angular/core';
import {
  AUTH_SERVICE_TOKEN,
  DalState,
  EduContentTOCEvaluationInterface,
  EduContentTocEvaluationQueries,
  EduContentTOCInterface,
  EduContentTocQueries,
  EvaluationSubjectGoalQueries,
  EvaluationSubjectInterface,
  EvaluationSubjectQueries,
  LearningDomainInterface,
  LearningDomainQueries,
  loadAndResolveEduContentTocEvaluationsForBook,
  loadAndResolveEvaluationSubjectGoalsForBook,
  loadAndResolveEvaluationSubjectsForBook,
  loadAndResolveTocsForBook,
} from '@campus/dal';
import { ArrayFunctions, findToc, groupArrayByKey } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { EvaluationSubjectsGroupedByLearningDomain } from './evaluations-subjects-slideout.component';

@Injectable()
export class EvaluationSubjectsSlideoutViewModel {
  private store: Store<DalState> = inject(Store);
  private authService = inject(AUTH_SERVICE_TOKEN);

  private currentBookId$ = new BehaviorSubject<number>(null);
  private selectedTocId$ = new BehaviorSubject<number>(null);
  private scopeToSelectedToc$ = new BehaviorSubject<boolean>(false);

  // source streams
  private learningDomainById$: Observable<Dictionary<LearningDomainInterface>>;
  private evaluationSubjectsForSelectedTocId$: Observable<EvaluationSubjectInterface[]>;
  private currentTocTree$: Observable<EduContentTOCInterface[]>;

  // presentation streams
  public selectedToc$: Observable<EduContentTOCInterface>;
  public filteredTocTree$: Observable<EduContentTOCInterface[]>;
  public evaluationSubjectsGroupedByLearningDomainForSelectedTocId$: Observable<
    EvaluationSubjectsGroupedByLearningDomain[]
  >;
  public eduContentTocEvaluationsForSelectedTocId$: Observable<EduContentTOCEvaluationInterface[]>;
  public evaluationSubjectsForSelectedTocDict$: Observable<Dictionary<boolean>>;

  constructor() {
    this.setSourceStreams();
    this.setPresentationStreams();
  }

  public setCurrentBookId(bookId: number) {
    this.currentBookId$.next(bookId);
    this.selectedTocId$.next(null);
  }

  public setSelectedTocId(tocId: number) {
    this.selectedTocId$.next(tocId);
  }

  public setScopeToSelectedToc(scopeToSelectedToc: boolean) {
    this.scopeToSelectedToc$.next(scopeToSelectedToc);
  }

  private setSourceStreams() {
    this.currentTocTree$ = this.getCurrentTocTree$();

    // intermediates also used for evaluationSubjectsByTocId$:
    this.selectedToc$ = this.getSelectedToc$();
    this.eduContentTocEvaluationsForSelectedTocId$ = this.getEduContentTocEvaluationsForSelectedTocId$();

    this.learningDomainById$ = this.store.select(LearningDomainQueries.getAllEntities);
    this.evaluationSubjectsForSelectedTocId$ = this.getEvaluationSubjectsForSelectedTocId$();
  }

  private setPresentationStreams() {
    this.filteredTocTree$ = this.getFilteredTocTree$();
    this.evaluationSubjectsGroupedByLearningDomainForSelectedTocId$ =
      this.getEvaluationSubjectsGroupedByLearningDomainForSelectedTocId$();
    this.evaluationSubjectsForSelectedTocDict$ = this.getEvaluationSubjectsForSelectedTocDict$();
  }

  private getEvaluationSubjectsForSelectedTocDict$(): Observable<Dictionary<boolean>> {
    return this.evaluationSubjectsForSelectedTocId$.pipe(
      map((evaluationSubjects) => {
        return evaluationSubjects.reduce((acc, evaluationSubject) => {
          acc[evaluationSubject.id] = true;
          return acc;
        }, {} as Dictionary<boolean>);
      })
    );
  }

  private getCurrentTocTree$(): Observable<EduContentTOCInterface[]> {
    return this.currentBookId$.pipe(
      filter((bookId) => !!bookId),
      map((bookId) => ({
        bookId,
      })),
      loadAndResolveTocsForBook(this.store, this.authService.userId),
      loadAndResolveEduContentTocEvaluationsForBook(this.store, this.authService.userId),
      loadAndResolveEvaluationSubjectsForBook(this.store, this.authService.userId),
      loadAndResolveEvaluationSubjectGoalsForBook(this.store, this.authService.userId),
      switchMap((props: { bookId: number }) => {
        return this.store.select(EduContentTocQueries.getTreeForBook(props));
      }),
      shareReplay(1)
    );
  }

  private getFilteredTocTree$(): Observable<EduContentTOCInterface[]> {
    return combineLatest([this.currentTocTree$, this.selectedToc$, this.scopeToSelectedToc$]).pipe(
      map(([tocTree, selectedToc, scopeToSelectedToc]) => {
        if (!scopeToSelectedToc) return this.filterTreeToEvaluations(tocTree);
        if (!selectedToc) return [];

        return [
          {
            ...selectedToc.parent,
            children: [selectedToc],
          },
        ];
      })
    );
  }

  private filterTreeToEvaluations(tocTree: EduContentTOCInterface[]) {
    return tocTree.map((toc) => this.onlyEvaluationTocs(toc)).filter((toc) => !!toc.children.length);
  }

  private onlyEvaluationTocs(toc: EduContentTOCInterface) {
    return {
      ...toc,
      children: (toc.children ?? []).filter((child) => {
        const filteredTocs = this.onlyEvaluationTocs(child);
        return filteredTocs.isEvaluation;
      }),
    };
  }

  private getSelectedToc$(): Observable<EduContentTOCInterface> {
    return combineLatest([this.currentTocTree$, this.selectedTocId$]).pipe(
      map(([tocTree, selectedTocId]) => {
        if (!selectedTocId) return null;

        return findToc(tocTree, selectedTocId);
      }),
      shareReplay(1)
    );
  }

  private getEvaluationSubjectsForSelectedTocId$(): Observable<EvaluationSubjectInterface[]> {
    return combineLatest([
      this.selectedToc$,
      this.eduContentTocEvaluationsForSelectedTocId$,
      this.store.select(EvaluationSubjectQueries.getEvaluationSubjectsByEvaluationId),
      this.store.select(EvaluationSubjectGoalQueries.getEvaluationSubjectGoalsByEvaluationSubjectId),
    ]).pipe(
      map(
        ([
          selectedToc,
          eduContentTocEvaluationsForSelectedTocId,
          evaluationSubjectsByEvaluationId,
          evaluationSubjectGoalsBySubjectId,
        ]) => {
          if (!selectedToc) {
            return [];
          }

          const evaluationSubjectForTocId = ArrayFunctions.flatten(
            eduContentTocEvaluationsForSelectedTocId.map(
              ({ evaluationId }) => evaluationSubjectsByEvaluationId[evaluationId] || []
            )
          );

          return evaluationSubjectForTocId.map((evaluationSubject) => ({
            ...evaluationSubject,
            goalIds: (evaluationSubjectGoalsBySubjectId[evaluationSubject.id] ?? []).map(({ goalId }) => goalId),
          }));
        }
      ),
      shareReplay(1)
    );
  }

  private getEvaluationSubjectsGroupedByLearningDomainForSelectedTocId$(): Observable<
    EvaluationSubjectsGroupedByLearningDomain[]
  > {
    return combineLatest([
      this.selectedTocId$,
      this.learningDomainById$,
      this.evaluationSubjectsForSelectedTocId$,
    ]).pipe(
      map(([selectedTocId, learningDomainById, evaluationSubjectsForSelectedTocId]) => {
        if (!selectedTocId) {
          return [];
        }

        const subjectsByLearningDomainId = groupArrayByKey(evaluationSubjectsForSelectedTocId, 'learningDomainId');
        const evaluationSubjectsGroupedByLearningDomains: {
          id: number;
          name: string;
          evaluationSubjects: EvaluationSubjectInterface[];
        }[] = Object.entries(subjectsByLearningDomainId).map(([learningDomainId, evaluationSubjects]) => {
          const { id, name } = learningDomainById[learningDomainId] || {};
          return { id, name, evaluationSubjects };
        });
        const sortedEvaluationSubjectsGroupedByLearningDomains = [...evaluationSubjectsGroupedByLearningDomains].sort(
          (a, b) => {
            // we know that each group has at least one evaluationSubject,
            // because we grouped the subjects by learningDomainId
            return a.evaluationSubjects[0].displayOrder - b.evaluationSubjects[0].displayOrder;
          }
        );

        return sortedEvaluationSubjectsGroupedByLearningDomains;
      })
    );
  }

  private getEduContentTocEvaluationsForSelectedTocId$(): Observable<EduContentTOCEvaluationInterface[]> {
    return combineLatest([this.selectedTocId$, this.store.select(EduContentTocEvaluationQueries.getAllByTocId)]).pipe(
      map(([selectedTocId, eduContentTocEvaluationsByTocId]) => {
        return eduContentTocEvaluationsByTocId[selectedTocId] ?? [];
      })
    );
  }
}
