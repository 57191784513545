import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DiaboloPhaseApi } from '../+api';
import { DiaboloPhaseInterface } from '../+models';

@Injectable({
  providedIn: 'root',
})
export class DiaboloPhaseService {
  constructor(private diaboloPhaseApi: DiaboloPhaseApi) {}

  getAll(): Observable<DiaboloPhaseInterface[]> {
    return this.diaboloPhaseApi.find();
  }
}
