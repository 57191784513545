import { trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { WINDOW } from '@campus/browser';
import {
  BreadcrumbLinkInterface,
  EffectFeedbackInterface,
  LearningRecordServiceInterface,
  LEARNING_RECORD_SERVICE_TOKEN,
  NavItem,
  PageHeaderInterface,
} from '@campus/dal';
import { EnvironmentWebsiteInterface, ENVIRONMENT_WEBSITE_TOKEN } from '@campus/environment';
import { GameLearningEventsService, GoogleAnalyticsService } from '@campus/shared';
import { fadeAnimation } from '@campus/ui';
import { Observable } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AppViewModel } from './app.viewmodel';
import { FavIconServiceInterface, FAVICON_SERVICE_TOKEN } from './services/favicons';

@Component({
  selector: 'campus-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger('fadeIn', fadeAnimation)],
})
export class AppComponent implements OnInit, AfterViewInit {
  /**
   * The link to the promo website, used on the logo
   */
  public website: EnvironmentWebsiteInterface = environment.website;

  public title = 'polpo-classroom-web';
  // streams
  public sideNavItems$: Observable<NavItem[]>;
  public sideNavOpen$: Observable<boolean>;
  public bannerFeedback$: Observable<EffectFeedbackInterface>;
  public pageHeaderDetails$: Observable<PageHeaderInterface>;

  public breadcrumbs$: Observable<BreadcrumbLinkInterface[]>;
  public denseMenu$: Observable<boolean>;
  public menuOpen: boolean;

  public isLoading$: Observable<boolean>;

  /**
   * The link to the promo website, used on the logo
   */
  public websiteUrl: string = environment.website.url;

  private gameLearningEventsService = inject(GameLearningEventsService);
  private learningRecordService = inject(LEARNING_RECORD_SERVICE_TOKEN);
  private router = inject(Router);
  private appViewModel = inject(AppViewModel);
  private titleService = inject(Title);
  private faviconService = inject(FAVICON_SERVICE_TOKEN);
  private window = inject(WINDOW);
  private googleAnalyticsService = inject(GoogleAnalyticsService);
  private environmentWebsite = inject(ENVIRONMENT_WEBSITE_TOKEN);

  constructor() {}

  ngOnInit(): void {
    this.learningRecordService.registerEventListener();
    this.gameLearningEventsService.registerEventListener();
    this.websiteUrl = this.environmentWebsite.url;
    this.sideNavItems$ = this.appViewModel.sideNavItems$;
    this.sideNavOpen$ = this.appViewModel.sideNavOpen$;
    this.bannerFeedback$ = this.appViewModel.bannerFeedback$;
    this.pageHeaderDetails$ = this.appViewModel.pageHeaderDetails$;

    this.breadcrumbs$ = this.appViewModel.breadcrumbs$;
    this.denseMenu$ = this.appViewModel.denseMenu$;

    this.sideNavOpen$ = this.appViewModel.sideNavOpen$;
    this.titleService.setTitle(this.environmentWebsite.title);
    this.faviconService.setFavicon(this.environmentWebsite.favicon, 'image/png');

    this.isLoading$ = this.router.events.pipe(
      filter((event) => !(event instanceof NavigationStart) || !this.window.location.href.includes('/login')),
      filter(
        (event) =>
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError ||
          event instanceof NavigationStart
      ),
      debounceTime(750),
      map((event) => event instanceof NavigationStart)
    );
  }

  ngAfterViewInit() {
    this.appViewModel.toggleSidebarOnNavigation();
  }

  public onSideBarToggle(open: boolean) {
    this.menuOpen = open;
  }

  public onBannerDismiss(event) {
    this.appViewModel.onFeedbackDismiss(event);
  }

  public onNavItemChanged(event) {
    this.appViewModel.onNavItemChanged(event);
  }
  public navigateToFaq() {
    this.window.open(this.website.faqUrl, '_blank');
  }
}
