/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { EduContentHighScoreApi as SDKEduContentHighScoreApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { EduContentHighScoreInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class EduContentHighScoreApi extends ModelApiBase {
  private SDKEduContentHighScoreApi = inject(SDKEduContentHighScoreApi);

  constructor(api: PolpoApi) {
    super(api, 'EduContentHighScores');
  }

  getHighScores(eduContentId: number, personId: number): Observable<EduContentHighScoreInterface[]> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentHighScoreApi.getHighScores(...arguments);
  }

  updateHighScore(eduContentId: number, personId: number, score: number): Observable<EduContentHighScoreInterface> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentHighScoreApi.updateHighScore(...arguments);
  }
}
