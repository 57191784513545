import { Dictionary } from '@ngrx/entity';
import { UpdateNum } from '@ngrx/entity/src/models';
import { Action } from '@ngrx/store';
import { ArtifactInterface } from '../../+models/Artifact.interface';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum ArtifactsActionTypes {
  LoadArtifacts = '[Artifacts] Load Artifacts',
  ArtifactsLoaded = '[Artifacts] Artifacts Loaded',
  ArtifactsLoadError = '[Artifacts] Load Error',
  AddArtifacts = '[Artifacts] Add Artifacts',
  StartAddArtifact = '[Artifact] Start Add Artifact',
  AddArtifact = '[Artifacts] Add Artifact',
  UpdateArtifact = '[Artifacts] Update Artifact',
  StartDeleteArtifact = '[Artifacts] Start Delete Artifact',
  DeleteArtifact = '[Artifacts] Delete Artifact',
  UploadArtifact = '[Artifacts] Upload Artifact',
  NavigateToArtifactDetail = '[Artifacts] Redirect After Create',
  StartUploadArtifactImage = '[Artifacts] Start Upload Artifact Image',
  UploadArtifactImage = '[Artifacts] Upload Artifact Image',
  AddArtifactsForBook = '[Artifacts] Add Artifacts for Book',
}

export class LoadArtifacts implements Action {
  readonly type = ArtifactsActionTypes.LoadArtifacts;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class ArtifactsLoaded implements Action {
  readonly type = ArtifactsActionTypes.ArtifactsLoaded;

  constructor(public payload: { artifacts: ArtifactInterface[] }) {}
}

export class ArtifactsLoadError implements Action {
  readonly type = ArtifactsActionTypes.ArtifactsLoadError;
  constructor(public payload: any) {}
}

export class StartAddArtifact implements Action, FeedbackTriggeringAction {
  readonly type = ArtifactsActionTypes.StartAddArtifact;

  constructor(
    public payload: {
      artifact: ArtifactInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
      navigateAfterCreate?: boolean;
    }
  ) {}
}

export class AddArtifact implements Action {
  readonly type = ArtifactsActionTypes.AddArtifact;

  constructor(public payload: { artifact: ArtifactInterface }) {}
}

export class AddArtifacts implements Action {
  readonly type = ArtifactsActionTypes.AddArtifacts;

  constructor(public payload: { artifacts: ArtifactInterface[] }) {}
}

export class UpdateArtifact implements FeedbackTriggeringAction {
  readonly type = ArtifactsActionTypes.UpdateArtifact;

  constructor(
    public payload: {
      artifact: UpdateNum<ArtifactInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartDeleteArtifact implements Action, FeedbackTriggeringAction {
  readonly type = ArtifactsActionTypes.StartDeleteArtifact;

  constructor(
    public payload: {
      id: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class DeleteArtifact implements Action {
  readonly type = ArtifactsActionTypes.DeleteArtifact;

  constructor(public payload: { id: number }) {}
}

export class UploadArtifact implements Action, FeedbackTriggeringAction {
  readonly type = ArtifactsActionTypes.UploadArtifact;

  constructor(
    public payload: {
      id: number;
      file: File;
      formFields?: Dictionary<any>;
      postMessage?: boolean;
      showFeedback?: boolean;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class NavigateToArtifactDetail implements Action {
  readonly type = ArtifactsActionTypes.NavigateToArtifactDetail;

  constructor(
    public payload: {
      artifact: ArtifactInterface;
    }
  ) {}
}

export class StartUploadArtifactImage implements FeedbackTriggeringAction {
  readonly type = ArtifactsActionTypes.StartUploadArtifactImage;

  constructor(
    public payload: {
      artifact: ArtifactInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UploadArtifactImage implements Action {
  readonly type = ArtifactsActionTypes.UploadArtifactImage;

  constructor(public payload: { id: number; image: string }) {}
}

export class AddArtifactsForBook implements Action {
  readonly type = ArtifactsActionTypes.AddArtifactsForBook;

  constructor(public payload: { bookId: number; artifacts: ArtifactInterface[] }) {}
}

export type ArtifactsActions =
  | LoadArtifacts
  | StartAddArtifact
  | AddArtifacts
  | AddArtifact
  | UpdateArtifact
  | StartDeleteArtifact
  | DeleteArtifact
  | UploadArtifact
  | ArtifactsLoaded
  | ArtifactsLoadError
  | NavigateToArtifactDetail
  | StartUploadArtifactImage
  | UploadArtifactImage
  | AddArtifactsForBook;
