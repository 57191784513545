import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LessonSheetInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './lesson-sheet.reducer';

export const selectLessonSheetState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectLessonSheetState, (state: State) => state.error);

export const getLoaded = createSelector(selectLessonSheetState, (state: State) => state.loaded);

export const getAll = createSelector(selectLessonSheetState, selectAll);

export const getCount = createSelector(selectLessonSheetState, selectTotal);

export const getIds = createSelector(selectLessonSheetState, selectIds);

export const getAllEntities = createSelector(selectLessonSheetState, selectEntities);

export const getByIds = (props: { ids: number[] }) =>
  createSelector(selectLessonSheetState, (state: State) => props.ids.map((id) => state.entities[id]));

export const getById = (props: { id: number }) =>
  createSelector(selectLessonSheetState, (state: State) => state.entities[props.id]);

export const getForEduContentTOC = (props: { eduContentTOCId: number }) =>
  createSelector(selectLessonSheetState, (state: State): LessonSheetInterface => {
    return Object.values(state.entities).find((entity) => entity.eduContentTOCId === props.eduContentTOCId);
  });

export const isBookLoaded = (props: { bookId: number }) =>
  createSelector(selectLessonSheetState, (state: State) => !!state.loadedForBook[props.bookId]);
