import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RegistrationApi } from '../+api';
import { RegistrationInterface } from '../+models';
import { RegistrationServiceInterface } from './registration.service.interface';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService implements RegistrationServiceInterface {
  constructor(private registrationApi: RegistrationApi) {}

  public createRegistration(registration: RegistrationInterface): Observable<RegistrationInterface> {
    return this.registrationApi.createRegistration(registration);
  }
}
