/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { GoalTopicApi as SDKGoalTopicApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { GoalTopicInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class GoalTopicApi extends ModelApiBase {
  private SDKGoalTopicApi = inject(SDKGoalTopicApi);

  constructor(api: PolpoApi) {
    super(api, 'GoalTopics');
  }

  create(goalTopic: GoalTopicInterface): Observable<GoalTopicInterface> {
    // @ts-expect-error TS 2556
    return this.SDKGoalTopicApi.create(...arguments);
  }

  deleteById(goalTopicId: number): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKGoalTopicApi.deleteById(...arguments);
  }

  getGoalsForLearningArea(learningAreaId: number, includeGoals = false): Observable<GoalTopicInterface[]> {
    // @ts-expect-error TS 2556
    return this.SDKGoalTopicApi.getGoalsForLearningArea(...arguments);
  }

  getLinkedEduContentMetadata(goalTopicId: number): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKGoalTopicApi.getLinkedEduContentMetadata(...arguments);
  }

  patchAttributes(goalTopicId: number, updates: Partial<GoalTopicInterface>): Observable<GoalTopicInterface> {
    // @ts-expect-error TS 2556
    return this.SDKGoalTopicApi.patchAttributes(...arguments);
  }
}
