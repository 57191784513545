import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { TaskStudentQueries } from '.';
import { TaskStudentServiceInterface, TASK_STUDENT_SERVICE_TOKEN } from '../../tasks/task-student.service.interface';
import { DalState } from '../dal.state.interface';
import { loadTaskStudents, taskStudentsLoaded, taskStudentsLoadError } from './task-student.actions';

@Injectable()
export class TaskStudentEffects {
  loadTaskStudents$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadTaskStudents),
      concatLatestFrom(() => this.store.select(TaskStudentQueries.getLoaded)),
      fetch({
        run: (action: ReturnType<typeof loadTaskStudents>, loaded: boolean) => {
          if (!action.force && loaded) return;
          return this.taskStudentService
            .getAllForUser(action.userId)
            .pipe(map((taskStudents) => taskStudentsLoaded({ taskStudents })));
        },
        onError: (action: ReturnType<typeof loadTaskStudents>, error) => {
          return taskStudentsLoadError({ error });
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(TASK_STUDENT_SERVICE_TOKEN)
    private taskStudentService: TaskStudentServiceInterface
  ) {}
}
