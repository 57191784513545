import { forwardRef, Inject, Injectable } from '@angular/core';
import { EduContent } from '@campus/dal';
import { ContentActionInterface } from '../..';
import {
  ContentBundleActionsServiceInterface,
  ContentBundleManagerInterface,
  CONTENT_BUNDLE_MANAGER_TOKEN,
} from './content-bundle-actions.service.interface';

@Injectable({
  providedIn: 'root',
})
export class ContentBundleActionsService implements ContentBundleActionsServiceInterface {
  constructor(
    @Inject(forwardRef(() => CONTENT_BUNDLE_MANAGER_TOKEN))
    private contentBundleManager: ContentBundleManagerInterface
  ) {}

  public contentBundleActionDictionary: {
    [key: string]: ContentActionInterface;
  } = {
    addToBundle: {
      label: 'Toevoegen aan bundel',
      icon: 'bundle:add',
      tooltip: 'Toevoegen aan bundel',
      handler: this.contentBundleManager.addEduContentToBundle.bind(this.contentBundleManager),
    },
    removeFromBundle: {
      label: 'Verwijderen uit bundel',
      icon: 'bundle:remove',
      tooltip: 'Verwijderen uit bundel',
      handler: this.contentBundleManager.removeEduContentFromBundle.bind(this.contentBundleManager),
    },
  };

  public getBundleActionsForEduContent(eduContent: EduContent, inBundle: boolean): ContentActionInterface[] {
    if (!eduContent.publishedEduContentMetadata.taskAllowed) {
      return [];
    }

    if (inBundle) {
      return [this.contentBundleActionDictionary.removeFromBundle];
    } else {
      return [this.contentBundleActionDictionary.addToBundle];
    }
  }
}
