import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { NewsCategoryInterface } from '../../+models';
import { NewsCategoryActions, NewsCategoryActionTypes } from './news-category.actions';

export const NAME = 'newsCategories';

export function sortNewsCategories(a: NewsCategoryInterface, b: NewsCategoryInterface): number {
  return a.displayOrder - b.displayOrder;
}

export interface State extends EntityState<NewsCategoryInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<NewsCategoryInterface> = createEntityAdapter<NewsCategoryInterface>({
  sortComparer: sortNewsCategories,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: NewsCategoryActions): State {
  switch (action.type) {
    case NewsCategoryActionTypes.NewsCategoriesLoaded: {
      return adapter.setAll(action.payload.newsCategories, { ...state, loaded: true });
    }

    case NewsCategoryActionTypes.NewsCategoriesLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case NewsCategoryActionTypes.ClearNewsCategories: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
