import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SectionApi } from '../+api';
import { SectionContentInterface, SectionInterface } from '../+models';
import { SectionServiceInterface } from './section.service.interface';

export interface APISectionInterface extends Omit<SectionInterface, 'config'> {
  config: string;
}

@Injectable({
  providedIn: 'root',
})
export class SectionService implements SectionServiceInterface {
  constructor(private sectionApi: SectionApi) {}

  deleteSections(sectionIds: number[]): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  replaceSections(
    sectionsToReplace: SectionInterface[],
    eduContentTocId: number
  ): Observable<{ sections: SectionInterface[]; sectionContents: SectionContentInterface[] }> {
    const toReplace = sectionsToReplace.map<APISectionInterface>((section) => {
      return {
        ...section,
        config: JSON.stringify(section.config),
      };
    });
    return this.sectionApi.replaceSections(toReplace, eduContentTocId).pipe(
      map((sectionsWithSectionContents) => {
        return sectionsWithSectionContents.reduce(
          (acc, sectionWithSectionContent) => {
            if (sectionWithSectionContent?.section) {
              acc.sections.push(sectionWithSectionContent.section);
            }
            acc.sectionContents.push(...sectionWithSectionContent.sectionContents);
            return acc;
          },
          { sections: [], sectionContents: [] }
        );
      })
    );
  }
}
