import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { Action, Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { forkJoin, from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  DataResponseInterface,
  DataServiceInterface,
  DATA_SERVICE_TOKEN,
  PersonDataResponseInterface,
} from '../../../data/data.service.interface';
import { DalState } from '../../dal.state.interface';
import { DATA_FIELD_MAPPERS_TOKEN, FieldMappersInterface, formatData } from '../mappers/data-field-mappers';
import { DataFieldActionFactory } from './person-data-field-action.factory';
import { DataActionTypes, DataLoaded, DataLoadError, LoadData } from './person-data.actions';

@Injectable()
export class DataEffects {
  private loadedSince: Dictionary<number> = {};

  loadData$ = createEffect(() =>
    this.actions.pipe(
      ofType(DataActionTypes.LoadData),
      concatLatestFrom(() => this.store),
      fetch({
        run: (action: LoadData, state) => {
          const { userId, fieldsSince = [], v2 } = action.payload;
          let { fields } = action.payload;

          // dictionary of fields to upsert, grouped by last loaded timestamp
          const toLoadSince: Array<keyof PersonDataResponseInterface> = [];

          let lowestSince = Date.now();

          // Filter out what isn't yet loaded or doesn't exist in the state
          if (!action.payload.force) {
            const now = Date.now();

            fields = fields.filter((field) => {
              const stateloaded = this.getStateLoaded(field, state);
              if (!stateloaded) {
                this.loadedSince[field] = now;
              }

              return !stateloaded;
            });

            fieldsSince.forEach((field) => {
              if (fields.includes(field)) {
                return; // initial load is being resolved instead
              }
              const loaded = this.getStateLoaded(field, state);
              if (now - this.loadedSince[field] < 30 * 1000) {
                return; // don't reload data less than 30s old
              }

              if (!loaded) {
                // should load full state instead
                if (!fields.includes(field)) {
                  fields.push(field);
                }
                return;
              }

              lowestSince = Math.min(lowestSince, this.loadedSince[field]);
              this.loadedSince[field] = now;
              // group fields to update, by last loaded timestamp
              toLoadSince.push(field);
            });
          }

          const getData$: Observable<PersonDataResponseInterface> = this.dataService.getAllForUser(
            userId,
            fields,
            undefined,
            v2
          );
          const partialData$ = this.dataService.getAllForUser(userId, toLoadSince, +lowestSince, v2);

          return forkJoin([getData$, partialData$]).pipe(
            switchMap(([dataForUser, partialDataForUser]) => {
              return from([
                ...this.getLoadActions(dataForUser),
                ...this.getUpsertActions(partialDataForUser),
                new DataLoaded(),
              ]);
            })
          );
        },
        onError: (action: LoadData, error) => {
          return new DataLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    @Inject(DATA_SERVICE_TOKEN)
    private dataService: DataServiceInterface,
    @Inject(DATA_FIELD_MAPPERS_TOKEN)
    private fieldMappers: FieldMappersInterface,
    private store: Store<DalState>
  ) {}

  private getStateName(field: keyof DataResponseInterface, state: DalState): string {
    let stateName: string = field;

    // Exceptions
    switch (field) {
      // the person state is actually called linkedPersons
      case 'persons':
        stateName = 'linkedPersons';
        break;
      case 'allResults':
        stateName = 'results';
        break;
      case 'eduContentsForResults':
        stateName = 'eduContents';
        break;
      case 'allowedEduContentBooks':
        stateName = 'eduContentBooks';
        break;
      case 'learningPlanGoalProgress':
        stateName = 'learningPlanGoalProgresses';
        break;
    }

    if (!state[stateName]) {
      throw new Error('loading unknown state');
    }

    return stateName;
  }

  private getStateLoaded(field: keyof DataResponseInterface, state: DalState): boolean {
    switch (field) {
      case 'allowedMethods':
        return state.methods && state.methods.allowedMethodsLoaded;
      case 'goalYears':
        return state.goals && state.goals.loaded;
      case 'eduContentsForResults':
        return state.eduContents && state.eduContents.loadedForResults;
    }

    const stateName = this.getStateName(field, state);
    return state[stateName].loaded;
  }

  private getLoadActions(data: DataResponseInterface): Action[] {
    return this.getActions(DataFieldActionFactory.getLoadedAction)(data);
  }

  private getUpsertActions(data: DataResponseInterface): Action[] {
    return this.getActions(DataFieldActionFactory.getUpsertAction)(data);
  }

  private getActions(getActionFn: (field: string, formattedData: any, data?: DataResponseInterface) => Action) {
    return (data: DataResponseInterface) =>
      Object.keys(data)
        .filter((field) => data[field] !== undefined)
        .map((field: keyof DataResponseInterface) => {
          const formattedData = formatData(field, data, this.fieldMappers);
          return getActionFn(field, formattedData, data);
        });
  }
}
