import { groupArrayByKey, groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CurriculumTreeConceptInterface, ResultInterface, ResultStatusEnum, UnlockedContentInterface } from '../../+models';
import { getCurriculumTreeConceptsForStrandGuid } from '../curriculum-tree-concept/curriculum-tree-concept.selectors';
import { UnlockedContentQueries } from '../unlocked-content';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './result.reducer';

export const selectResultState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectResultState, (state: State) => state.error);

export const getLoaded = createSelector(selectResultState, (state: State) => state.loaded);

export const getAll = createSelector(selectResultState, selectAll);

export const getCount = createSelector(selectResultState, selectTotal);

export const getIds = createSelector(selectResultState, selectIds);

export const getAllEntities = createSelector(selectResultState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * result$: ResultInterface[] = this.store.pipe(
    select(ResultQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectResultState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * result$: ResultInterface = this.store.pipe(
    select(ResultQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectResultState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

/**
 * returns dictionary of results grouped by a property
 * @example
 * results$: ResultInterface[] = this.store.pipe(
    select(ResultQueries.getResultsForLearningAreaIdGrouped,
      { learningAreaId: 1, groupProp: { bundleId: 0 } })
    -or-
    select(ResultQueries.getResultsForLearningAreaIdGrouped,
      { learningAreaId: 1, groupProp: { taskId: 0 } })
  );
 */
export const getResultsForLearningAreaIdGrouped = createSelector(
  selectResultState,
  (
    state: State,
    props: {
      learningAreaId: number;
      groupProp: Partial<ResultInterface>;
    }
  ) => {
    const ids: number[] = <number[]>state.ids;
    const groupKey = Object.keys(props.groupProp)[0];

    return ids.reduce((acc, id) => {
      // mapping
      const result = state.entities[id];
      // filtering
      if (result[groupKey] && result.learningAreaId === props.learningAreaId) {
        // grouping
        if (!acc[result[groupKey]]) {
          acc[result[groupKey]] = [];
        }
        acc[result[groupKey]].push(result);
      }
      return acc;
    }, {});
  }
);
export const getLearningAreaIds = createSelector(selectResultState, (state: State) => {
  return Array.from(new Set(Object.values(state.entities).map((result) => result.learningAreaId)));
});

export const getResultsGroupedByArea = createSelector(selectResultState, (state: State) => {
  const ids: number[] = <number[]>state.ids;
  return groupArrayByKey(Object.values(state.entities), 'learningAreaId');
});

export const getBestResultByEduContentId = (props: { taskId?: number }) =>
  createSelector(selectResultState, (state: State) => {
    const ids: number[] = <number[]>state.ids;
    return ids.reduce(
      (
        acc,
        id
      ): {
        [eduContentId: number]: ResultInterface;
      } => {
        const result = state.entities[id];
        if (props.taskId && result.taskId !== props.taskId) {
          return acc;
        }

        if (![ResultStatusEnum.STATUS_COMPLETED, ResultStatusEnum.STATUS_INCOMPLETE].includes(result.status)) {
          return acc;
        }
        const eduContentId = result.eduContentId;

        const isValidNumber = (value: unknown) => typeof value === 'number' && !isNaN(value);

        // treat 0 as a better value than undefined
        const asNumber = (score?: number) => (isValidNumber(score) ? score : -1);

        if (!acc[eduContentId] || asNumber(acc[eduContentId].score) < asNumber(result.score)) {
          acc[eduContentId] = result;
        }

        return acc;
      },
      {} as Dictionary<ResultInterface>
    );
  });

export const getLastResultByEduContentId = (props: { taskId?: number; bundleId?: number }) =>
  createSelector(
    selectResultState,
    UnlockedContentQueries.getByBundleIds,
    (state: State, unlockedContentsByBundleId: Dictionary<UnlockedContentInterface[]>) => {
      const unlockedContentForBundle = unlockedContentsByBundleId[props.bundleId] || [];

      const ids: number[] = <number[]>state.ids;
      return ids.reduce(
        (
          acc,
          id
        ): {
          [eduContentId: number]: ResultInterface;
        } => {
          const result = state.entities[id];

          const isNotInBundleOrTask = ['bundleId', 'taskId'].some((key) => props[key] && result[key] !== props[key]);
          const isInUnlockedContentsOfBundle = props.bundleId && unlockedContentForBundle.some(
            uC => uC.id === result.unlockedContentId
          );

          if (isNotInBundleOrTask && !isInUnlockedContentsOfBundle) {
            return acc;
          }

          if (
            ![
              ResultStatusEnum.STATUS_COMPLETED,
              ResultStatusEnum.STATUS_INCOMPLETE,
              ResultStatusEnum.STATUS_OPENED,
            ].includes(result.status)
          ) {
            return acc;
          }

          const eduContentId = result.eduContentId;

          if (!acc[eduContentId] || acc[eduContentId].created < result.created) {
            acc[eduContentId] = result;
          }

          return acc;
        },
        {}
      );
    }
  );

export const getResultsByTask = createSelector(getAll, (results) => groupArrayByKey(results, 'taskId'));

export const getResultsByConceptId = createSelector(getAll, (results) => groupArrayByKey(results, 'conceptId'));

export const getResultsByPersonIdByConceptId = createSelector(
  getAll,
  (results): Dictionary<Dictionary<ResultInterface[]>> => groupArrayByKeys(results, ['personId', 'conceptId'])
);

export const getResultsForStrandGuid = createSelector(
  getCurriculumTreeConceptsForStrandGuid,
  getResultsByConceptId,
  (
    ctConceptsForStrandGuid: CurriculumTreeConceptInterface[],
    resultsByConceptId: Dictionary<ResultInterface[]>,
    props: { strandGuid: string }
  ) => {
    return ctConceptsForStrandGuid.reduce((acc, ctConcept) => {
      acc.push(...(resultsByConceptId[ctConcept.conceptId] || []));

      return acc;
    }, []);
  }
);

export const getResultsIdsForTaskIds = createSelector(
  getAll,
  (results: ResultInterface[], props: { taskIds: number[] }) => {
    return results.reduce((acc, result) => {
      const isTaskIncluded = props.taskIds.includes(result.taskId);
      if (isTaskIncluded) {
        acc.push(result.id);
      }
      return acc;
    }, []);
  }
);

export const getResultsIdsForBundleIds = createSelector(
  getAll,
  (results: ResultInterface[], props: { bundleIds: number[] }) => {
    const bundleIdSet = new Set(props.bundleIds);

    return results.reduce((acc, result) => {
      const isBundleIncluded = bundleIdSet.has(result.bundleId);
      if (isBundleIncluded) {
        acc.push(result.id);
      }
      return acc;
    }, []);
  }
);

export const getResultsByUnlockedFreePracticeId = createSelector(getAll, (results: ResultInterface[]) =>
  groupArrayByKey(results, 'unlockedFreePracticeId')
);
