import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { BundleGroupInterface } from '../../+models';

export const loadBundleGroups = createAction(
  '[BundleGroup] Load BundleGroups',
  (userId: number = null, force: boolean = false) => ({
    userId,
    force,
  })
);

export const bundleGroupsLoaded = createAction(
  '[BundleGroup] BundleGroups loaded',
  props<{ bundleGroups: BundleGroupInterface[] }>()
);

export const bundleGroupsLoadError = createAction('[BundleGroup] Load Error', props<{ error: any }>());

export const addBundleGroup = createAction(
  '[BundleGroup] Add BundleGroup',
  props<{ bundleGroup: BundleGroupInterface }>()
);

export const upsertBundleGroup = createAction(
  '[BundleGroup] Upsert BundleGroup',
  props<{ bundleGroup: BundleGroupInterface }>()
);

export const addBundleGroups = createAction(
  '[BundleGroup] Add BundleGroups',
  props<{ bundleGroups: BundleGroupInterface[] }>()
);

export const upsertBundleGroups = createAction(
  '[BundleGroup] Upsert BundleGroups',
  props<{ bundleGroups: BundleGroupInterface[] }>()
);

export const updateBundleGroup = createAction(
  '[BundleGroup] Update BundleGroup',
  props<{ bundleGroup: Update<BundleGroupInterface> }>()
);

export const updateBundleGroups = createAction(
  '[BundleGroup] Update BundleGroups',
  props<{ bundleGroups: Update<BundleGroupInterface>[] }>()
);

export const deleteBundleGroup = createAction('[BundleGroup] Delete BundleGroup', props<{ id: number }>());

export const deleteBundleGroups = createAction('[BundleGroup] Delete BundleGroups', props<{ ids: number[] }>());

export const clearBundleGroups = createAction('[BundleGroup] Clear BundleGroups');

export const updateBundleGroupsAccess = createAction(
  '[BundleGroup] Update Access',
  props<{ bundleId: number; bundleGroups: BundleGroupInterface[] }>()
);
