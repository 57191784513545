import { inject, Injectable } from '@angular/core';
import {
  ContextDomainEnum,
  ContextInterface,
  EduContentInterface,
  ResultInterface,
  ResultsService,
  RolesEnum,
  ScormCmiMode,
} from '@campus/dal';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  BrowseExerciseConfigurationInterface,
  CustomExerciseServiceInterface,
  ExerciseConfiguration,
  ExercisePlayerOptionsInterface,
  ExerciseServiceInterface,
  NormalExerciseConfigurationInterface,
  ReviewExerciseConfigurationInterface,
  StudentReviewExerciseConfigurationInterface,
} from './exercise.interface';
import { EXERCISE_TOKEN } from './exercise.token';

@Injectable({
  providedIn: 'root',
})
export class ExerciseService implements ExerciseServiceInterface {
  private exerciseServices: CustomExerciseServiceInterface[] = inject<CustomExerciseServiceInterface[]>(EXERCISE_TOKEN);
  private compatibleExerciseService: CustomExerciseServiceInterface | undefined;

  private resultsService = inject(ResultsService);

  public loadExercise(data: ExerciseConfiguration) {
    this.compatibleExerciseService = this.getCompatibleExerciseService(data.eduContent);
    if (!this.compatibleExerciseService) {
      console.warn('No compatible exercise service found for this content.');

      return;
    }

    const shouldGetResult = this.compatibleExerciseService.shouldGetResult(data);

    if (!shouldGetResult) {
      this.compatibleExerciseService.loadExercise(data);
    } else {
      this.getResult$(data as NormalExerciseConfigurationInterface)
        .pipe(take(1))
        .subscribe((result: ResultInterface) => {
          const updatedData = { ...data, result };
          this.compatibleExerciseService.loadExercise(updatedData);
        });
    }
  }

  public openExerciseAsTeacher(
    eduContent: EduContentInterface,
    context: ContextInterface,
    playerOptions: ExercisePlayerOptionsInterface = {}
  ): void {
    const config: NormalExerciseConfigurationInterface = {
      mode: ScormCmiMode.CMI_MODE_NORMAL,
      eduContent,
      context,
      playerOptions: {
        withFeedback: false,
        localSession: true,
        ...playerOptions, // override default options
      },
    };
    this.loadExercise(config);
  }

  public browseExercise(eduContent: EduContentInterface, context: ContextInterface): void {
    const config: BrowseExerciseConfigurationInterface = { mode: ScormCmiMode.CMI_MODE_BROWSE, eduContent, context };
    this.loadExercise(config);
  }

  public reviewExerciseAsTeacher(eduContent: EduContentInterface, result: ResultInterface): void {
    const config: ReviewExerciseConfigurationInterface = {
      mode: ScormCmiMode.CMI_MODE_REVIEW,
      eduContent,
      result,
      role: RolesEnum.Teacher,
    };
    this.loadExercise(config);
  }

  public reviewExerciseAsStudent(eduContent: EduContentInterface, result: ResultInterface): void {
    const config: StudentReviewExerciseConfigurationInterface = {
      mode: ScormCmiMode.CMI_MODE_REVIEW,
      eduContent,
      result,
      context: {
        contextDomain: ContextDomainEnum.RESULT,
        taskId: result.taskId,
        unlockedContentId: result.unlockedContentId,
        unlockedFreePracticeId: result.unlockedFreePracticeId,
      },
      role: RolesEnum.Student,
    };
    this.loadExercise(config);
  }

  private getCompatibleExerciseService(content: EduContentInterface) {
    const compatibleExerciseService = this.exerciseServices.find((service) => service.isContentCompatible(content));

    return compatibleExerciseService;
  }

  private getResult$(data: NormalExerciseConfigurationInterface): Observable<ResultInterface> {
    let result$: Observable<ResultInterface> | undefined;

    const { userId, eduContent, context } = data;
    const { id: eduContentId } = eduContent;

    const zeroOrMoreThanOneParam = Object.keys(context).length !== 1;
    if (!context && zeroOrMoreThanOneParam) {
      throw new Error('Provide a context');
    }

    const { taskId, unlockedContentId, unlockedFreePracticeId, yearId, loop, strandId } = context;
    const resultForAdaptiveContext = yearId || loop || strandId;

    if (taskId) {
      result$ = this.resultsService.getResultForTask(userId, taskId, eduContentId);
    } else if (unlockedContentId) {
      result$ = this.resultsService.getResultForUnlockedContent(userId, unlockedContentId, eduContentId);
    } else if (unlockedFreePracticeId) {
      result$ = this.resultsService.getResultForUnlockedFreePractice(userId, unlockedFreePracticeId, eduContentId);
    } else if (resultForAdaptiveContext) {
      result$ = this.resultsService.getResultForContext(userId, eduContentId, context);
    }

    if (!result$) {
      throw new Error('Unable to determine the result');
    }

    return result$;
  }
}
