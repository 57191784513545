import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Permissions, RolesEnum, SettingsPermissions } from '@campus/dal';
import {
  AuthenticationGuard,
  CompleteProfileGuard,
  CoupledTeacherGuard,
  LicenseGuard,
  PermissionGuard,
  RedirectGuard,
  TermPrivacyGuard,
} from '@campus/guards';
import { devRoutes } from '../environments/dev.route';
import { AppResolver } from './app.resolver';
import { LoadingComponent } from './components/loading/loading.component';
import { HomePageGuard } from './guard/home-page.guard';
import { TermsPrivacyPopupGuard } from './guard/terms-privacy-popup/terms-privacy-popup.guard';

const routes: Routes = [
  {
    path: '',
    resolve: { isResolved: AppResolver },
    runGuardsAndResolvers: 'always',
    canActivate: [AuthenticationGuard, TermPrivacyGuard, CompleteProfileGuard, TermsPrivacyPopupGuard],

    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadChildren: () => import('@campus/pages/home').then((m) => m.PagesHomeModule),
        canActivate: [LicenseGuard, HomePageGuard],
        data: {
          breadcrumbText: 'Dashboard',
          headerDetails: {
            title: 'Dashboard',
            icon: 'home',
          },
          tourRoles: [RolesEnum.Schooladmin, RolesEnum.Teacher],
        },
      },
      {
        path: 'methods',
        loadChildren: () => import('@campus/pages/methods').then((m) => m.PagesMethodsModule),
        data: {
          breadcrumbText: 'Methodes',
          headerDetails: { title: 'Methodes', icon: 'methods' },
          tourRoles: [RolesEnum.Schooladmin, RolesEnum.Teacher],
          requiredPermissions: [`!${SettingsPermissions.HIDE_IN_ARCHIVE}`],
        },
        canActivate: [LicenseGuard, PermissionGuard],
      },
      {
        path: 'tasks',
        loadChildren: () => import('@campus/pages/kabas-tasks').then((m) => m.PagesKabasTasksModule),
        data: {
          breadcrumbText: 'Taken',
          skip: true,
          headerDetails: { title: 'Taken', icon: 'tasks' },
          tourRoles: [],
          requiredPermissions: [`!${SettingsPermissions.HIDE_IN_ARCHIVE}`],
        },
        canActivate: [LicenseGuard, CoupledTeacherGuard, PermissionGuard], // TODO: still necessary?
      },
      {
        path: 'students',
        loadChildren: () => import('@campus/pages/students').then((m) => m.PagesStudentsModule),
        data: {
          breadcrumbText: 'Studenten',
          headerDetails: { title: 'Studenten', icon: 'students' },
          tourRoles: [],
          requiredPermissions: [`!${SettingsPermissions.HIDE_IN_ARCHIVE}`],
        },
        canActivate: [PermissionGuard],
      },
      {
        path: 'resultsV2',
        loadChildren: () => import('@campus/pages/resultsV2').then((m) => m.PagesResultsV2Module),
        data: {
          breadcrumbText: 'Resultaten',
          headerDetails: { title: 'Resultaten', icon: 'results' },
          requiredPermissions: [Permissions.settings.MANAGE_RESULTS_V2],
          tourRoles: [RolesEnum.Schooladmin, RolesEnum.Teacher],
        },
        canActivate: [PermissionGuard],
      },
      {
        path: 'schools',
        loadChildren: () => import('@campus/pages/schools').then((m) => m.PagesSchoolsModule),
        data: {
          breadcrumbText: 'Mijn scholen',
          headerDetails: { title: 'Mijn scholen', icon: 'schools' },
        },
      },
      {
        path: 'messages',
        loadChildren: () => import('@campus/pages/messages').then((m) => m.PagesMessagesModule),
        data: {
          breadcrumbText: 'Berichten',
          headerDetails: { title: 'Berichten', icon: 'messages' },
          tourRoles: [],
        },
      },
      {
        path: 'search',
        loadChildren: () => import('@campus/pages/global-search').then((m) => m.PagesGlobalSearchModule),
        data: {
          breadcrumbText: 'Zoekresultaten',
          denseMenu: true,
          headerDetails: { title: 'Zoeken', icon: 'magnifier' },
          requiredPermissions: [`!${SettingsPermissions.HIDE_IN_ARCHIVE}`],
        },
        canActivate: [PermissionGuard],
      },
      {
        path: 'settings',
        data: {
          breadcrumbText: 'Instellingen',
          headerDetails: { title: 'Instellingen', icon: 'settings' },
          tourRoles: [],
          requiredPermissions: [`!${SettingsPermissions.HIDE_IN_ARCHIVE}`],
        },
        children: [
          {
            path: '',
            loadChildren: () => import('@campus/pages/settings/dashboard').then((m) => m.PagesSettingsDashboardModule),
            data: { breadcrumbText: 'Dashboard', denseMenu: true },
          },
        ],
        canActivate: [PermissionGuard],
      },
      {
        path: 'bundles',
        loadChildren: () => import('@campus/pages/bundles').then((m) => m.PagesBundlesModule),
        data: {
          breadcrumbText: 'Bundels',
          headerDetails: { title: 'Mijn bundels', icon: 'bundles' },
          tourRoles: [RolesEnum.Schooladmin, RolesEnum.Teacher],
          requiredPermissions: [`!${SettingsPermissions.HIDE_IN_ARCHIVE}`],
        },
        canActivate: [LicenseGuard, CoupledTeacherGuard, PermissionGuard],
      },
    ],
  },
  {
    path: 'error',
    loadChildren: () => import('@campus/pages/error').then((m) => m.PagesErrorModule),
    data: {
      breadcrumbText: 'Foutmelding',
      headerDetails: { title: 'Fout', icon: 'error' },
    },
  },
  {
    path: 'loading',
    component: LoadingComponent,
    data: {
      headerDetails: { title: 'Even geduld' },
    },
  },
  {
    path: 'login',
    loadChildren: () => import('@campus/pages/login').then((m) => m.PagesLoginModule),
    data: {
      breadcrumbText: 'Login',
      headerDetails: { title: 'Login', icon: 'lock' },
      tourRoles: [],
    },
  },
  {
    path: 'logout',
    loadChildren: () => import('@campus/pages/logout').then((m) => m.PagesLogoutModule),
    data: { tourRoles: [] },
  },
  {
    path: 'external',
    canActivate: [RedirectGuard],
    children: [],
    pathMatch: 'full',
  },
  {
    path: 'content',
    loadChildren: () => import('@campus/pages/public-content').then((m) => m.PagesPublicContentModule),
    data: {
      skip: true,
    },
  },
  ...devRoutes,
  {
    path: '**',
    redirectTo: 'error/404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
