<ng-container *ngIf="pageHeaderDetails$ | async as pageHeaderDetails">
  <campus-shell
    [bannerFeedback]="bannerFeedback$ | async"
    (bannerDismiss)="onBannerDismiss($event)"
    [pageTitle]="pageHeaderDetails.title"
    [usetifulTag]="pageHeaderDetails.usetifulTag"
    [pageIcon]="pageHeaderDetails.icon"
    [navigateTo]="pageHeaderDetails.navigateTo"
    [denseMenu]="denseMenu$ | async"
    [menuOpen]="menuOpen"
    (menuToggle)="onSideBarToggle($event)"
  >
    <campus-shell-logo>
      <a [href]="websiteUrl">
        <campus-icon svgIcon="logo" alt="Polpo" class="polpo__logo"></campus-icon>
      </a>
    </campus-shell-logo>

    <campus-shell-actions>
      <campus-header
        breadcrumbPosition="page-left"
        quicklinkPosition="top-right"
        alertsPosition="top-right"
        profileMenuPosition="top-right"
        [profileMenuWithUserBadge]="true"
      ></campus-header>
    </campus-shell-actions>

    <campus-shell-body>
      <div id="page-bar-container"></div>
      <router-outlet></router-outlet>
    </campus-shell-body>

    <campus-shell-left>
      <campus-tree-nav [treeNav]="sideNavItems$ | async" (navItemChanged)="onNavItemChanged($event)"></campus-tree-nav>
      <li class="ui-tree-nav__node polpo__menu__faq-button">
        <a (click)="navigateToFaq()" class="ui-tree-nav__node-item ui-body-1">
          <campus-icon class="ui-tree-nav__node-icon icon-current" svgIcon="help"></campus-icon>
          <span class="ui-tree-nav__node-label">veelgestelde vragen</span>
        </a>
      </li>
    </campus-shell-left>

    <campus-shell-bottom>
      <div class="polpo__footer">
        <div class="polpo__footer__part polpo__footer__part--left"></div>
        <div class="polpo__footer__part__overlap polpo__footer__part__overlap--left"></div>
        <div class="polpo__footer__part polpo__footer__part--middle"></div>
        <div class="polpo__footer__part__overlap polpo__footer__part__overlap--right"></div>
        <div class="polpo__footer__part polpo__footer__part--right"></div>
      </div>
    </campus-shell-bottom>
  </campus-shell>

  <campus-breadcrumbs-template *ngIf="breadcrumbs$ | async as breadCrumbs">
    <campus-breadcrumbs
      *ngIf="breadCrumbs.length"
      [breadcrumbs]="breadCrumbs"
      [separatorCharacter]="'&raquo;'"
      [baseDisplayText]="website.title"
      data-usetiful="breadcrumbs"
    >
    </campus-breadcrumbs>
  </campus-breadcrumbs-template>
</ng-container>

<div data-cy="polpo-loading" class="polpo-loading" *ngIf="isLoading$ | async" @fadeIn>
  <campus-lottie lottieKey="loading"></campus-lottie>
</div>
