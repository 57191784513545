import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NAME, selectAll, selectEntities, State } from './artifact.reducer';

export const selectArtifactState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectArtifactState, (state: State) => state.error);

export const getLoaded = createSelector(selectArtifactState, (state: State) => state.loaded);

export const getAll = createSelector(selectArtifactState, selectAll);

export const getAllEntities = createSelector(selectArtifactState, selectEntities);

export const getById = (props: { id: number }) =>
  createSelector(selectArtifactState, (state: State) => state.entities[props.id]);

export const isArtifactsForBookLoaded = (props: { bookId: number }) =>
  createSelector(selectArtifactState, (state: State) => !!state.loadedArtifactsForBook[props.bookId]);
