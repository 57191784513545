import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

export enum AbilityModeEnum {
  INTAKE = 'intake',
  MASTER = 'master',
  EXPERT = 'expert',
}
@Component({
  selector: 'campus-ability-progress',
  templateUrl: './ability-progress.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AbilityProgressComponent implements OnChanges {
  @Input() mode: AbilityModeEnum;
  @Input() progress: number;

  @Input() type: 'full' | 'half' = 'full';

  @HostBinding('class.clip-bottom-half')
  shouldClip = false;

  @HostBinding('class')
  defaultClasses = ['block', 'p-3xs'];

  bestMode = false;

  modes: typeof AbilityModeEnum = AbilityModeEnum;
  masterAngle = 0;
  expertAngle = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.type) {
      this.shouldClip = this.type === 'half';
    }
    if (changes.mode) {
      this.bestMode = this.mode === AbilityModeEnum.EXPERT;
    }
    if (changes.mode || changes.progress) {
      this.masterAngle = this.bestMode ? this.percentToAngle(100) : this.percentToAngle(this.progress);
      this.expertAngle = this.bestMode ? this.percentToAngle(this.progress) : this.percentToAngle(0);
    }
  }

  private percentToAngle(percent = 0): number {
    return -180 + (percent / 100) * 180;
  }
}
