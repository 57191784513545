import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SurveyAnswerInterface } from '../../+models';
import { SurveyAnswerActionTypes, SurveyAnswersActions } from './survey-answer.actions';

export const NAME = 'surveyAnswers';

export interface State extends EntityState<SurveyAnswerInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<SurveyAnswerInterface> = createEntityAdapter<SurveyAnswerInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: SurveyAnswersActions): State {
  switch (action.type) {
    case SurveyAnswerActionTypes.AddSurveyAnswer: {
      return adapter.addOne(action.payload.surveyAnswer, state);
    }

    case SurveyAnswerActionTypes.UpsertSurveyAnswer: {
      return adapter.upsertOne(action.payload.surveyAnswer, state);
    }

    case SurveyAnswerActionTypes.AddSurveyAnswers: {
      return adapter.addMany(action.payload.surveyAnswers, state);
    }

    case SurveyAnswerActionTypes.UpsertSurveyAnswers: {
      return adapter.upsertMany(action.payload.surveyAnswers, state);
    }

    case SurveyAnswerActionTypes.UpdateSurveyAnswer: {
      return adapter.updateOne(action.payload.surveyAnswer, state);
    }

    case SurveyAnswerActionTypes.UpdateSurveyAnswers: {
      return adapter.updateMany(action.payload.surveyAnswers, state);
    }

    case SurveyAnswerActionTypes.DeleteSurveyAnswer: {
      return adapter.removeOne(action.payload.id, state);
    }

    case SurveyAnswerActionTypes.DeleteSurveyAnswers: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case SurveyAnswerActionTypes.SurveyAnswersLoaded: {
      return adapter.setAll(action.payload.surveyAnswers, {
        ...state,
        loaded: true,
      });
    }

    case SurveyAnswerActionTypes.SurveyAnswersLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case SurveyAnswerActionTypes.ClearSurveyAnswers: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
