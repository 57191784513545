import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { EduContentTOCEduContentMetadataInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum EduContentTOCEduContentMetadataActionTypes {
  EduContentTOCEduContentMetadataLoaded = '[EduContentTocEduContentMetadata]EduContentTOCEduContentMetadata Loaded',
  EduContentTOCEduContentMetadataLoadError = '[EduContentTocEduContentMetadata] Load Error',
  LoadEduContentTOCEduContentMetadata = '[EduContentTocEduContentMetadata] LoadEduContentTOCEduContentMetadata',
  AddEduContentTOCEduContentMetadata = '[EduContentTocEduContentMetadata] AddEduContentTocEduContentMetadata',
  UpsertEduContentTOCEduContentMetadata = '[EduContentTocEduContentMetadata] Upsert EduContentTocEduContentMetadata',
  AddEduContentTOCEduContentMetadatas = '[EduContentTocEduContentMetadata] AddEduContentTOCEduContentMetadatas',
  AddEduContentTOCEduContentMetadatasForBook = '[EduContentTocEduContentMetadata] AddEduContentTOCEduContentMetadatasForBook',
  UpsertEduContentTOCEduContentMetadatas = '[EduContentTocEduContentMetadata] UpsertEduContentTOCEduContentMetadatas',
  UpdateEduContentTOCEduContentMetadata = '[EduContentTocEduContentMetadata] Update EduContentTocEduContentMetadata',
  UpdateEduContentTOCEduContentMetadatas = '[EduContentTocEduContentMetadata] UpdateEduContentTOCEduContentMetadatas',
  DeleteEduContentTOCEduContentMetadata = '[EduContentTocEduContentMetadata] Delete EduContentTocEduContentMetadata',
  DeleteEduContentTOCEduContentMetadatas = '[EduContentTocEduContentMetadata] DeleteEduContentTOCEduContentMetadatas',
  DeleteEduContentTOCEduContentMetadatasByTocIdAndMetadataId = '[EduContentTocEduContentMetadata] DeleteEduContentTOCEduContentMetadatasByTocIdAndMetadataId',
  ClearEduContentTOCEduContentMetadatas = '[EduContentTocEduContentMetadata] ClearEduContentTOCEduContentMetadatas',
  UpdateEduContentMetadataDisplayOrder = '[EduContentTocEduContentMetadata] Update EduContentMetadata display order',
}

export class LoadEduContentTOCEduContentMetadata implements Action {
  readonly type = EduContentTOCEduContentMetadataActionTypes.LoadEduContentTOCEduContentMetadata;

  constructor(public payload: { force?: boolean; eduContentTocId: number } = { eduContentTocId: null }) {}
}

export class EduContentTOCEduContentMetadataLoaded implements Action {
  readonly type = EduContentTOCEduContentMetadataActionTypes.EduContentTOCEduContentMetadataLoaded;

  constructor(public payload: { eduContentTOCEduContentMetadata: EduContentTOCEduContentMetadataInterface[] }) {}
}

export class EduContentTOCEduContentMetadataLoadError implements Action {
  readonly type = EduContentTOCEduContentMetadataActionTypes.EduContentTOCEduContentMetadataLoadError;
  constructor(public payload: any) {}
}

export class AddEduContentTOCEduContentMetadata implements Action {
  readonly type = EduContentTOCEduContentMetadataActionTypes.AddEduContentTOCEduContentMetadata;

  constructor(public payload: { eduContentTOCEduContentMetadata: EduContentTOCEduContentMetadataInterface }) {}
}

export class UpsertEduContentTOCEduContentMetadata implements Action {
  readonly type = EduContentTOCEduContentMetadataActionTypes.UpsertEduContentTOCEduContentMetadata;

  constructor(public payload: { eduContentTOCEduContentMetadata: EduContentTOCEduContentMetadataInterface }) {}
}

export class AddEduContentTOCEduContentMetadatas implements Action {
  readonly type = EduContentTOCEduContentMetadataActionTypes.AddEduContentTOCEduContentMetadatas;

  constructor(public payload: { eduContentTOCEduContentMetadata: EduContentTOCEduContentMetadataInterface[] }) {}
}

export class AddEduContentTOCEduContentMetadatasForBook implements Action {
  readonly type = EduContentTOCEduContentMetadataActionTypes.AddEduContentTOCEduContentMetadatasForBook;

  constructor(
    public payload: { bookId: number; eduContentTOCEduContentMetadata: EduContentTOCEduContentMetadataInterface[] }
  ) {}
}

export class UpsertEduContentTOCEduContentMetadatas implements Action {
  readonly type = EduContentTOCEduContentMetadataActionTypes.UpsertEduContentTOCEduContentMetadatas;

  constructor(public payload: { eduContentTOCEduContentMetadata: EduContentTOCEduContentMetadataInterface[] }) {}
}

export class UpdateEduContentTOCEduContentMetadata implements Action {
  readonly type = EduContentTOCEduContentMetadataActionTypes.UpdateEduContentTOCEduContentMetadata;

  constructor(public payload: { eduContentTOCEduContentMetadata: Update<EduContentTOCEduContentMetadataInterface> }) {}
}

export class UpdateEduContentTOCEduContentMetadatas implements Action {
  readonly type = EduContentTOCEduContentMetadataActionTypes.UpdateEduContentTOCEduContentMetadatas;

  constructor(
    public payload: { eduContentTOCEduContentMetadata: Update<EduContentTOCEduContentMetadataInterface>[] }
  ) {}
}

export class DeleteEduContentTOCEduContentMetadata implements Action {
  readonly type = EduContentTOCEduContentMetadataActionTypes.DeleteEduContentTOCEduContentMetadata;

  constructor(public payload: { id: number }) {}
}

export class DeleteEduContentTOCEduContentMetadatas implements Action {
  readonly type = EduContentTOCEduContentMetadataActionTypes.DeleteEduContentTOCEduContentMetadatas;

  constructor(public payload: { ids: number[] }) {}
}

export class DeleteEduContentTOCEduContentMetadatasByTocIdAndMetadataId implements Action {
  readonly type = EduContentTOCEduContentMetadataActionTypes.DeleteEduContentTOCEduContentMetadatasByTocIdAndMetadataId;

  constructor(
    public payload: { eduContentTocEduContentMetadataPartials: { eduContentMetadataId: number; tocId: number }[] }
  ) {}
}

export class ClearEduContentTOCEduContentMetadatas implements Action {
  readonly type = EduContentTOCEduContentMetadataActionTypes.ClearEduContentTOCEduContentMetadatas;
}

export class UpdateEduContentMetadataDisplayOrder implements Action, FeedbackTriggeringAction {
  readonly type = EduContentTOCEduContentMetadataActionTypes.UpdateEduContentMetadataDisplayOrder;

  constructor(
    public payload: {
      eduContentTocId: number;
      displayOrderByEduContentMetadataId: { [eduContentMetadataId: number]: number };
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}
export type EduContentTOCEduContentMetadataActions =
  | LoadEduContentTOCEduContentMetadata
  | EduContentTOCEduContentMetadataLoaded
  | EduContentTOCEduContentMetadataLoadError
  | AddEduContentTOCEduContentMetadata
  | UpsertEduContentTOCEduContentMetadata
  | AddEduContentTOCEduContentMetadatas
  | AddEduContentTOCEduContentMetadatasForBook
  | UpsertEduContentTOCEduContentMetadatas
  | UpdateEduContentTOCEduContentMetadata
  | UpdateEduContentTOCEduContentMetadatas
  | DeleteEduContentTOCEduContentMetadata
  | DeleteEduContentTOCEduContentMetadatas
  | DeleteEduContentTOCEduContentMetadatasByTocIdAndMetadataId
  | ClearEduContentTOCEduContentMetadatas
  | UpdateEduContentMetadataDisplayOrder;
