import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { BundleGroupQueries } from '.';
import { BundleGroupService, BundleGroupServiceInterface } from '../../bundle-group';
import { DalState } from '../dal.state.interface';
import { bundleGroupsLoaded, bundleGroupsLoadError, loadBundleGroups } from './bundle-group.actions';

@Injectable()
export class BundleGroupEffects {
  loadBundleGroups$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadBundleGroups),
      concatLatestFrom(() => this.store.select(BundleGroupQueries.getLoaded)),
      fetch({
        run: (action: ReturnType<typeof loadBundleGroups>, loaded: boolean) => {
          if (!action.force && loaded) return;
          return this.bundleGroupService
            .getAllForUser(action.userId)
            .pipe(map((bundleGroups) => bundleGroupsLoaded({ bundleGroups })));
        },
        onError: (action: ReturnType<typeof loadBundleGroups>, error) => {
          return bundleGroupsLoadError({ error });
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(BundleGroupService)
    private bundleGroupService: BundleGroupServiceInterface
  ) {}
}
