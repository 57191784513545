import { Inject, Injectable } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { fetch, optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from } from 'rxjs';
import { map, mapTo, switchMap } from 'rxjs/operators';
import { TaskEduContentQueries } from '.';
import { TaskEduContentInterface } from '../../+models';
import {
  CalculateTaskEduContentResponseInterface,
  TaskEduContentServiceInterface,
  TASK_EDU_CONTENT_SERVICE_TOKEN,
  UpdateTaskEduContentResultInterface,
} from '../../tasks/task-edu-content.service.interface';
import { TaskServiceInterface, TASK_SERVICE_TOKEN } from '../../tasks/task.service.interface';
import { DalState } from '../dal.state.interface';
import { EduContentActions } from '../edu-content';
import { EffectFeedback, FeedbackTriggeringAction, Priority } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { SetTaskEduContentTaskGoalYearLevelsForTask } from '../task-edu-content-task-goal-year-level/task-edu-content-task-goal-year-level.actions';
import {
  AddTaskEduContent,
  AddTaskEduContents,
  CalculateTaskEduContentsForTask,
  DeleteTaskEduContent,
  DeleteTaskEduContents,
  LinkTaskEduContent,
  LoadTaskEduContents,
  StartAddTaskEduContents,
  StartDeleteTaskEduContents,
  TaskEduContentsActionTypes,
  TaskEduContentsLoaded,
  TaskEduContentsLoadError,
  UpdateTaskEduContents,
} from './task-edu-content.actions';

@Injectable()
export class TaskEduContentEffects {
  loadTaskEduContents$ = createEffect(() =>
    this.actions.pipe(
      ofType(TaskEduContentsActionTypes.LoadTaskEduContents),
      concatLatestFrom(() => this.store.select(TaskEduContentQueries.getLoaded)),
      fetch({
        run: (action: LoadTaskEduContents, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.taskEduContentService
            .getAllForUser(action.payload.userId)
            .pipe(map((taskEduContents) => new TaskEduContentsLoaded({ taskEduContents })));
        },
        onError: (action: LoadTaskEduContents, error) => {
          return new TaskEduContentsLoadError(error);
        },
      })
    )
  );

  linkTaskEduContent$ = createEffect(() =>
    this.actions.pipe(
      ofType(TaskEduContentsActionTypes.LinkTaskEduContent),
      pessimisticUpdate({
        run: (action: LinkTaskEduContent) => {
          return this.taskService.linkEduContent(action.payload.taskId, action.payload.eduContentId).pipe(
            switchMap((taskEduContent) => [
              new AddEffectFeedback({
                effectFeedback: new EffectFeedback({
                  id: this.uuid(),
                  triggerAction: action,
                  message: 'Het materiaal werd aan de taak toegevoegd.',
                  type: 'success',
                  priority: Priority.NORM,
                }),
              }),
              new AddTaskEduContent({
                taskEduContent,
              }),
            ])
          );
        },
        onError: (action: LinkTaskEduContent, error) => {
          return new AddEffectFeedback({
            effectFeedback: new EffectFeedback({
              id: this.uuid(),
              triggerAction: action,
              message: 'Het is niet gelukt om het materiaal aan de taak toe te voegen.',
              userActions: [
                {
                  title: 'Opnieuw proberen',
                  userAction: action,
                },
              ],
              type: 'error',
              priority: Priority.HIGH,
            }),
          });
        },
      })
    )
  );

  // not used in Kabas -> use deleteTaskEduContents$ (bulk, pessimistic)

  deleteTaskEduContent$ = createEffect(() =>
    this.actions.pipe(
      ofType(TaskEduContentsActionTypes.DeleteTaskEduContent),
      optimisticUpdate({
        run: (action: DeleteTaskEduContent) => {
          return this.taskEduContentService.remove(action.payload.id).pipe(
            mapTo(
              new AddEffectFeedback({
                effectFeedback: new EffectFeedback({
                  id: this.uuid(),
                  triggerAction: action,
                  message: 'Het materiaal is uit de taak verwijderd.',
                }),
              })
            )
          );
        },
        undoAction: (action: DeleteTaskEduContent, error: any) => {
          // Something went wrong: could be a 401 or 404 ...
          const undoAction = undo(action);

          const effectFeedback = new EffectFeedback({
            id: this.uuid(),
            triggerAction: action,
            message: 'Het is niet gelukt om het materiaal uit de taak te verwijderen.',
            userActions: [{ title: 'Opnieuw', userAction: action }],
            type: 'error',
            priority: Priority.HIGH,
          });

          const feedbackAction = new AddEffectFeedback({
            effectFeedback,
          });

          // undo the failed action and trigger feedback for user
          return from<Action[]>([undoAction, feedbackAction]);
        },
      })
    )
  );

  updateTaskEduContents$ = createEffect(() =>
    this.actions.pipe(
      ofType(TaskEduContentsActionTypes.UpdateTaskEduContents),
      optimisticUpdate({
        run: (action: UpdateTaskEduContents) => {
          const updates = action.payload.taskEduContents.map(
            (partialTaskEduContent) =>
              ({
                id: partialTaskEduContent.id,
                ...partialTaskEduContent.changes,
              } as TaskEduContentInterface)
          );
          return this.taskEduContentService.updateTaskEduContents(null, updates).pipe(
            map((update) => {
              return new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'De inhoud van de taak werd bijgewerkt.'
                ),
              });
            })
          );
        },
        undoAction: (action: UpdateTaskEduContents, error: any) => {
          return from([
            undo(action),
            new AddEffectFeedback({
              effectFeedback: EffectFeedback.generateErrorFeedback(
                this.uuid(),
                action,
                'Het is niet gelukt om de inhoud van de taak bij te werken.'
              ),
            }),
          ]);
        },
      })
    )
  );

  deleteTaskEduContents$ = createEffect(() =>
    this.actions.pipe(
      ofType(TaskEduContentsActionTypes.StartDeleteTaskEduContents),
      pessimisticUpdate({
        run: (action: StartDeleteTaskEduContents) => {
          return this.taskEduContentService
            .deleteTaskEduContents(action.payload.userId, action.payload.taskEduContentIds)
            .pipe(
              switchMap((taskDestroyResult: UpdateTaskEduContentResultInterface) => {
                const actions = [];
                const { success, errors } = taskDestroyResult;

                // remove the destroyed ones from the store
                if (this.isFilled(success)) {
                  actions.push(
                    new DeleteTaskEduContents({
                      ids: success.map((taskEduContent) => taskEduContent.id),
                    })
                  );

                  // show a snackbar if there is no other feedback (i.e. no errors)
                  if (!this.isFilled(errors)) {
                    const message = this.getTaskEduContentUpdateSuccessMessage(success.length, 'delete');
                    actions.push(this.getTaskEduContentUpdateFeedbackAction(action, message, 'success'));
                  }
                }

                // show feedback for the ones still in use
                if (this.isFilled(errors)) {
                  const errorMessage = this.getTaskEduContentUpdateErrorMessageHTML(taskDestroyResult, 'delete');
                  actions.push(this.getTaskEduContentUpdateFeedbackAction(action, errorMessage, 'error'));
                }
                return from(actions);
              })
            );
        },
        onError: (action: StartDeleteTaskEduContents, error) => {
          return this.getTaskEduContentUpdateOnErrorFeedbackAction(action, 'delete');
        },
      })
    )
  );

  createTaskEduContents$ = createEffect(() =>
    this.actions.pipe(
      ofType(TaskEduContentsActionTypes.StartAddTaskEduContents),
      pessimisticUpdate({
        run: (action: StartAddTaskEduContents) => {
          return this.taskEduContentService
            .createTaskEduContent(action.payload.userId, action.payload.taskEduContents)
            .pipe(
              switchMap((taskEduContentCreateResults: UpdateTaskEduContentResultInterface) => {
                const actions = [];
                const { success, errors } = taskEduContentCreateResults;

                if (this.isFilled(success)) {
                  actions.push(new AddTaskEduContents({ taskEduContents: success }));

                  // show a snackbar if there is no other feedback (i.e. no errors)
                  if (!this.isFilled(errors)) {
                    const message = this.getTaskEduContentUpdateSuccessMessage(success.length, 'create');
                    actions.push(this.getTaskEduContentUpdateFeedbackAction(action, message, 'success'));
                  }
                }

                // show feedback for the ones still in use
                if (this.isFilled(errors)) {
                  const errorMessage = this.getTaskEduContentUpdateErrorMessageHTML(
                    taskEduContentCreateResults,
                    'create'
                  );
                  actions.push(this.getTaskEduContentUpdateFeedbackAction(action, errorMessage, 'error'));
                }
                return from(actions);
              })
            );
        },

        onError: (action: StartAddTaskEduContents, error) => {
          return this.getTaskEduContentUpdateOnErrorFeedbackAction(action, 'create');
        },
      })
    )
  );

  calculateTaskEduContentsForTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(TaskEduContentsActionTypes.CalculateTaskEduContentsForTask),
      concatLatestFrom(() => this.store.select(TaskEduContentQueries.getAllGroupedByTaskId)),
      pessimisticUpdate({
        run: (action: CalculateTaskEduContentsForTask, taskEduContentByTaskId) => {
          const { taskId } = action.payload;
          return this.taskEduContentService.calculateTaskEduContentForTask(taskId).pipe(
            switchMap((response: CalculateTaskEduContentResponseInterface) => {
              const currentTaskEduContentIdsForTask = (taskEduContentByTaskId[taskId] || []).map((tec) => {
                return tec.id;
              });

              return from([
                new AddTaskEduContents({ taskEduContents: response.taskEduContents }),
                new SetTaskEduContentTaskGoalYearLevelsForTask({
                  taskId,
                  taskEduContentTaskGoalYearLevels: response.taskEduContentTaskGoalYearLevels,
                  taskEduContentIds: currentTaskEduContentIdsForTask,
                  removedTaskEduContentTaskGoalYearLevelIds: response.removedTaskEduContentTaskGoalYearLevelIds,
                }),
                new EduContentActions.AddEduContents({ eduContents: response.eduContents }),
              ]);
            })
          );
        },

        onError: (action: CalculateTaskEduContentsForTask, error) => {
          const effectFeedback = new EffectFeedback({
            id: this.uuid(),
            triggerAction: action,
            message: 'Het is niet gelukt om materiaal toe te voegen aan deze taak op maat.',
            userActions: [{ title: 'Probeer opnieuw', userAction: action }],
            type: 'error',
            priority: Priority.HIGH,
          });

          return new AddEffectFeedback({ effectFeedback });
        },
      })
    )
  );

  private isFilled = (arr) => Array.isArray(arr) && arr.length;

  private getTaskEduContentUpdateSuccessMessage(taskEduContentsLength: number, method: 'delete' | 'create'): string {
    const methodVerbs = {
      delete: 'verwijderd',
      create: 'toegevoegd',
    };

    return `Het materiaal werd ${methodVerbs[method]}.`;
  }

  private getTaskEduContentUpdateErrorMessageHTML(
    taskUpdateInfo: UpdateTaskEduContentResultInterface,
    method: 'delete' | 'create'
  ) {
    const { success, errors } = taskUpdateInfo;
    const methodVerbs = {
      delete: 'verwijderd',
      create: 'toegevoegd',
    };
    const verb = methodVerbs[method];

    const html = [];

    if (!success.length) {
      html.push(`<p>Er werd geen materiaal ${verb}.</p>`);
    } else if (success.length === 1) {
      html.push(`<p>Het materiaal werd ${verb}.</p>`);
    } else {
      html.push(`<p>Er werden ${success.length} materiaal items ${verb}.</p>`);
    }
    html.push('<p>De volgende taken zijn nog in gebruik:</p>');
    html.push('<ul>');
    html.push(
      ...errors.map(
        (error) =>
          `<li><strong>${error.task}</strong> is nog in gebruik door ${
            error.user
          } tot ${error.activeUntil.toLocaleDateString(this.dateLocale)}.</li>`
      )
    );
    html.push('</ul>');

    return html.join('');
  }

  private getTaskEduContentUpdateFeedbackAction(
    action: FeedbackTriggeringAction,
    message: string,
    type: 'error' | 'success'
  ): any {
    const effectFeedback =
      type === 'success'
        ? EffectFeedback.generateSuccessFeedback(this.uuid(), action, message)
        : {
            ...EffectFeedback.generateErrorFeedback(this.uuid(), action, message),
            userActions: [], // don't add a retry button
          };

    return new AddEffectFeedback({
      effectFeedback,
    });
  }

  private getTaskEduContentUpdateOnErrorFeedbackAction(
    action: FeedbackTriggeringAction,
    method: 'archive' | 'dearchive' | 'delete' | 'create'
  ) {
    const methodVerbs = {
      archive: 'te archiveren',
      dearchive: 'te dearchiveren',
      delete: 'te verwijderen',
      create: 'toe te voegen',
    };
    const feedbackAction = new AddEffectFeedback({
      effectFeedback: EffectFeedback.generateErrorFeedback(
        this.uuid(),
        action,
        `Het is niet gelukt om het materiaal ${methodVerbs[method]}.`
      ),
    });
    return feedbackAction;
  }

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(TASK_EDU_CONTENT_SERVICE_TOKEN)
    private taskEduContentService: TaskEduContentServiceInterface,
    @Inject(TASK_SERVICE_TOKEN) private taskService: TaskServiceInterface,
    @Inject('uuid') private uuid: () => string,
    @Inject(MAT_DATE_LOCALE) private dateLocale
  ) {}
}
