import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { PermissionService } from './permission.service';

@Directive({
  selector: '[campusHasPermission], [hasPermission]',
})
export class HasPermissionDirective implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  @Input('hasPermission') permissions: string | string[];
  @Input() hasPermissionElse?: TemplateRef<unknown>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.applyPermission();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private applyPermission(): void {
    this.subscriptions.add(
      this.permissionService.hasPermission$(this.permissions).subscribe((hasPermission) => {
        this.viewContainer.clear();
        if (hasPermission) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else if (this.hasPermissionElse) {
          this.viewContainer.createEmbeddedView(this.hasPermissionElse);
        }
      })
    );
  }
}
