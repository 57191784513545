import { Pipe, PipeTransform } from '@angular/core';
import { DateFunctions, TimeFunctions } from '@campus/utils';
import { HumanDateTimeArgsInterface } from './human-date-time.pipe.interface';
import { humanDateTimeDefaultArgs } from './human-date-time.pipe.presets';

@Pipe({
  name: 'humanDateTime',
})
export class HumanDateTimePipe implements PipeTransform {
  /**
   * takes a date object and transforms it to a human readable string
   * returns empty string when date is undefined or null
   */
  transform(value: Date | number | string, args: HumanDateTimeArgsInterface = humanDateTimeDefaultArgs): string {
    if (!value && value !== 0) {
      return args.ifEmpty || '';
    }

    if (!(value instanceof Date)) {
      value = new Date(value);
    }

    const { referenceDate = new Date(), rules, locale = 'nl-BE', datePrefix, addDate, format } = args;

    const referenceBounderies = DateFunctions.getSchoolYearBoundaries(referenceDate);
    const isInReferencedSchoolYear = DateFunctions.dateIsInRange(value, referenceBounderies);
    const ms = value.getTime();

    if (format) {
      switch (format) {
        case 'longDate':
          return value.toLocaleDateString(locale, {
            ...(!isInReferencedSchoolYear ? { year: 'numeric' } : {}),
            month: 'long',
            day: 'numeric',
          });
        case 'shortDate':
          return value.toLocaleDateString(locale, {
            month: 'short',
            day: 'numeric',
            ...(!isInReferencedSchoolYear ? { year: '2-digit' } : {}),
          });
        case 'numericDate':
          return value.toLocaleDateString(locale, {
            ...(!isInReferencedSchoolYear ? { year: '2-digit' } : {}),
            month: 'numeric',
            day: 'numeric',
          });
        case 'longDateTime':
          return (
            value.toLocaleDateString(locale, {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }) +
            ' ' +
            value.toLocaleTimeString(locale, {
              hour: '2-digit',
              minute: '2-digit',
            })
          );
        case 'shortDateTime':
          return value.toLocaleString(locale, {
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          });
        case 'time':
          return value.toLocaleTimeString(locale, {
            hour: '2-digit',
            minute: '2-digit',
          });
        case 'numericDateTime':
          return (
            value.toLocaleDateString(locale, {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }) +
            ' ' +
            value.toLocaleTimeString(locale, {
              hour: '2-digit',
              minute: '2-digit',
            })
          );
        case 'duration':
          return TimeFunctions.getMin(ms, true);
      }
    }

    for (const rule of rules) {
      const valueMilliSeconds = value.getTime();
      const referenceMilliSeconds = referenceDate.getTime();

      if (rule.condition(valueMilliSeconds, referenceMilliSeconds)) {
        return (
          rule.value(valueMilliSeconds, referenceMilliSeconds) +
          (addDate ? ' (' + value.toLocaleDateString(locale) + ')' : '')
        );
      }
    }

    const prefix = datePrefix ? datePrefix + ' ' : '';

    return prefix + value.toLocaleDateString(locale);
  }
}
