import { Injectable, InjectionToken } from '@angular/core';
import { ObjectFunctions } from '@campus/utils';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { PersonApi } from '../+api';
import { PassportUserCredentialInterface } from '../+models';

export const CREDENTIAL_SERVICE_TOKEN = new InjectionToken('CredentialService');

export interface CredentialServiceInterface {
  getAllForUser(userId: number): Observable<PassportUserCredentialInterface[]>;
  unlinkCredential(credential: PassportUserCredentialInterface): Observable<boolean>;
  useCredentialProfilePicture(credential: PassportUserCredentialInterface): Observable<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class CredentialService implements CredentialServiceInterface {
  constructor(private personApi: PersonApi) {}

  public getAllForUser(userId: number): Observable<PassportUserCredentialInterface[]> {
    const { castAs } = ObjectFunctions;

    return this.personApi
      .getCredentials(userId)
      .pipe(map((credentials) => credentials.map(castAs(Date)(['created', 'modified']))));
  }

  unlinkCredential(credential: PassportUserCredentialInterface): Observable<boolean> {
    return this.personApi.destroyByIdCredentials(credential.userId, credential.id).pipe(mapTo(true));
  }
  useCredentialProfilePicture(credential: PassportUserCredentialInterface): Observable<boolean> {
    return this.personApi.useAvatarFromCredential(credential.userId, credential.id).pipe(mapTo(true));
  }
}
