import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SchoolProviderService, SchoolProviderServiceInterface } from '../../school-provider';
import { UpsertCredentials } from '../credential/credential.actions';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { UpsertLinkedPersons } from '../linked-person/linked-person.actions';
import { DeleteSchoolRoleMappings, UpsertSchoolRoleMappings } from '../school-role-mapping/school-role-mapping.actions';
import {
  AddSchoolProvider,
  DeleteSchoolProvider,
  SchoolProvidersActionTypes,
  StartAddSchoolProvider,
} from './school-provider.actions';

@Injectable()
export class SchoolProviderEffects {
  startAddSchoolProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchoolProvidersActionTypes.StartAddSchoolProvider),
      pessimisticUpdate({
        run: (action: StartAddSchoolProvider) => {
          const { schoolId, schoolProvider } = action.payload;
          return this.schoolProviderService.createSchoolProvider(schoolId, schoolProvider).pipe(
            switchMap(({ schoolProvider, credentials = [], schoolRoleMappings = [], persons = [] }) => {
              const actionsToDispatch: Action[] = [
                new AddSchoolProvider({ schoolProvider }),
                new UpsertCredentials({ credentials }),
                new UpsertSchoolRoleMappings({ schoolRoleMappings }),
                new UpsertLinkedPersons({ persons }),
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'Het smartschooldomein is gekoppeld.'
                  ),
                }),
              ];

              return from(actionsToDispatch);
            })
          );
        },
        onError: (action: StartAddSchoolProvider, error: any) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het smartschooldomein te koppelen.'
            ),
          });
        },
      })
    )
  );

  deleteSchoolProviders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchoolProvidersActionTypes.DeleteSchoolProvider),
      optimisticUpdate({
        run: (action: DeleteSchoolProvider) => {
          const { schoolId, id } = action.payload;

          return this.schoolProviderService.deleteSchoolProvider(schoolId, id).pipe(
            switchMap((deletedSchoolRoleMappingIds) => {
              const actions: Action[] = [
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'Het smartschooldomein werd losgekoppeld.'
                  ),
                }),
              ];

              if (deletedSchoolRoleMappingIds?.length) {
                actions.push(new DeleteSchoolRoleMappings({ ids: deletedSchoolRoleMappingIds }));
              }

              return from(actions);
            })
          );
        },
        undoAction: (action: DeleteSchoolProvider, error: any) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het smartschooldomein los te koppelen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    @Inject(SchoolProviderService)
    private schoolProviderService: SchoolProviderServiceInterface,
    @Inject('uuid') private uuid: () => string
  ) {}
}
