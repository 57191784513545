import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

export interface ProductRowActionInterface {
  handler?: (id: number) => void;
  label?: string;
  icon?: string;
  tooltip?: string;
}

export interface ProductRowInterface {
  guid?: number;
  label?: string;
  actions?: ProductRowActionInterface[];
}
@Component({
  selector: 'campus-product',
  templateUrl: './product.component.html',
})
export class ProductComponent {
  @HostBinding('attr.data-cy')
  dataCy = 'product-card';

  @Input() badgeLabel: string;
  @Input() badgeIcon: string;

  @Input() imageUrl: string;
  @Input() title: string;
  @Input() learningAreaName: string;
  @Input() highlight: boolean;
  @Input() rowActionsLayout: 'stacked' | 'clustered' = 'stacked';
  @Input() menuTriggerLabel = 'kies jaar';
  @Input() truncateLabels = true;
  @Input() data: { id: number };
  @Input() keepMenuOpen = false;

  @Input() rowDisplayType: 'multiple' | 'menu' | 'actions' = 'menu';

  @Input() rows: ProductRowInterface[] = [];
  @Input() selected: ProductRowInterface[] = [];

  @Output() rowClick = new EventEmitter<ProductRowInterface>();

  selectionChanged(row: ProductRowInterface, event?: PointerEvent) {
    if (event && this.keepMenuOpen) {
      event.stopPropagation();
    }

    this.rowClick.emit(row);
  }

  public trackByGuid(index: number, row: ProductRowInterface): number {
    return row.guid;
  }
}
