import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { SurveyInterface } from '../../+models';

export enum SurveysActionTypes {
  SurveysLoaded = '[Surveys] Surveys Loaded',
  SurveysLoadError = '[Surveys] Load Error',
  LoadSurveys = '[Surveys] Load Surveys',
  AddSurvey = '[Surveys] Add Survey',
  UpsertSurvey = '[Surveys] Upsert Survey',
  AddSurveys = '[Surveys] Add Surveys',
  UpsertSurveys = '[Surveys] Upsert Surveys',
  UpdateSurvey = '[Surveys] Update Survey',
  UpdateSurveys = '[Surveys] Update Surveys',
  DeleteSurvey = '[Surveys] Delete Survey',
  DeleteSurveys = '[Surveys] Delete Surveys',
  ClearSurveys = '[Surveys] Clear Surveys',
}

export class LoadSurveys implements Action {
  readonly type = SurveysActionTypes.LoadSurveys;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class SurveysLoaded implements Action {
  readonly type = SurveysActionTypes.SurveysLoaded;

  constructor(public payload: { surveys: SurveyInterface[] }) {}
}

export class SurveysLoadError implements Action {
  readonly type = SurveysActionTypes.SurveysLoadError;
  constructor(public payload: any) {}
}

export class AddSurvey implements Action {
  readonly type = SurveysActionTypes.AddSurvey;

  constructor(public payload: { survey: SurveyInterface }) {}
}

export class UpsertSurvey implements Action {
  readonly type = SurveysActionTypes.UpsertSurvey;

  constructor(public payload: { survey: SurveyInterface }) {}
}

export class AddSurveys implements Action {
  readonly type = SurveysActionTypes.AddSurveys;

  constructor(public payload: { surveys: SurveyInterface[] }) {}
}

export class UpsertSurveys implements Action {
  readonly type = SurveysActionTypes.UpsertSurveys;

  constructor(public payload: { surveys: SurveyInterface[] }) {}
}

export class UpdateSurvey implements Action {
  readonly type = SurveysActionTypes.UpdateSurvey;

  constructor(public payload: { survey: Update<SurveyInterface> }) {}
}

export class UpdateSurveys implements Action {
  readonly type = SurveysActionTypes.UpdateSurveys;

  constructor(public payload: { surveys: Update<SurveyInterface>[] }) {}
}

export class DeleteSurvey implements Action {
  readonly type = SurveysActionTypes.DeleteSurvey;

  constructor(public payload: { id: number }) {}
}

export class DeleteSurveys implements Action {
  readonly type = SurveysActionTypes.DeleteSurveys;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearSurveys implements Action {
  readonly type = SurveysActionTypes.ClearSurveys;
}

export type SurveysActions =
  | LoadSurveys
  | SurveysLoaded
  | SurveysLoadError
  | AddSurvey
  | UpsertSurvey
  | AddSurveys
  | UpsertSurveys
  | UpdateSurvey
  | UpdateSurveys
  | DeleteSurvey
  | DeleteSurveys
  | ClearSurveys;
