import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { DalState, LinkedPersonQueries } from '@campus/dal';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PersonAlreadyLinkedValidator implements AsyncValidator {
  constructor(private store: Store<DalState>) {}

  validate(ctrl: AbstractControl): Observable<ValidationErrors | null> {
    return this.store.pipe(
      select(LinkedPersonQueries.getAll),
      map((linkedPersons) => {
        return linkedPersons.some(
          (linkedPerson) => linkedPerson.teacherInfo && linkedPerson.teacherInfo.publicKey === ctrl.value
        )
          ? { teacherAlreadyCoupled: true }
          : null;
      }),
      take(1)
    );
  }
}
