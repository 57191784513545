import { Injectable } from '@angular/core';
import {
  EduContent,
  EduContentProductTypeInterface,
  LevelInterface,
  SectionContentInterface,
  SectionInterface,
} from '@campus/dal';
import { EduContentSearchResultInterface } from '@campus/shared';
import { ArrayFunctions, KeyWithType } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { ProductTypeCategory } from '../interfaces/toc-result-service.interface';
const { sortByKey } = ArrayFunctions;

@Injectable({
  providedIn: 'root',
})
export class TocResultService {
  public getProductTypeCategories(
    eduContents: EduContentSearchResultInterface[] | EduContent[],
    mapFn?: (eduContent: EduContentSearchResultInterface) => EduContentSearchResultInterface
  ): ProductTypeCategory[] {
    if (!eduContents?.length) return [];

    const isEduContent = eduContents[0] instanceof EduContent;

    const contentToMap = isEduContent
      ? (eduContents as EduContent[]).map((eduContent) => ({ eduContent }))
      : (eduContents as EduContentSearchResultInterface[]);

    const productTypeGroups = this.toProductTypeGroup(contentToMap);

    const categoryMapFn = (category) => ({
      ...category,
      showSubCategories: category.isPracticeMaterial,
    });

    return this.toCategories(productTypeGroups, 'levels', 'eduContents', {
      mapFn,
      categorySortComparer: sortByKey('displayOrder'),
      subCategorySortComparer: sortByKey('displayOrder'),
      categoryMapFn,
    });
  }

  public getSectionCategories(
    sections: SectionInterface[],
    sectionContentBySectionId: { [sectionId: number]: SectionContentInterface[] },
    eduContentDict: Dictionary<EduContent>,
    mapFn?: (eduContent: EduContentSearchResultInterface) => EduContentSearchResultInterface
  ) {
    if (!sections?.length) return [];

    const sectionGroups = this.toSectionGroup(sections, sectionContentBySectionId, eduContentDict);

    return this.toCategories(sectionGroups, 'sectionContents', 'eduContentSearch', {
      mapFn,
      categorySortComparer: sortByKey('displayOrder'),
      subCategorySortComparer: sortByKey('displayOrder'),
    });
  }

  private toProductTypeGroup(eduContentSearches: EduContentSearchResultInterface[]) {
    const { productTypeById: productTypesById, levelByProductTypeIdByLevelId: levelsByProductTypeIdByLevelId } =
      eduContentSearches.reduce(
        (acc, eduContentSearch) => {
          const { eduContent } = eduContentSearch;

          const {
            publishedEduContentMetadata: { eduContentProductType },
          } = eduContent;

          // Quick fix: no level is passed as null in a searchResult
          const level = eduContent.level || { id: 0, name: null };

          if (!acc.productTypeById[eduContentProductType.id]) {
            acc.productTypeById[eduContentProductType.id] = eduContentProductType;
          }

          if (!acc.levelByProductTypeIdByLevelId[eduContentProductType.id]) {
            acc.levelByProductTypeIdByLevelId[eduContentProductType.id] = {};
          }

          const showSubCategories = eduContentProductType.isPracticeMaterial;
          const levelId = showSubCategories ? level.id : 0;

          if (!acc.levelByProductTypeIdByLevelId[eduContentProductType.id][levelId]) {
            acc.levelByProductTypeIdByLevelId[eduContentProductType.id][levelId] = {
              ...level,
              eduContents: [],
            };
          }

          acc.levelByProductTypeIdByLevelId[eduContentProductType.id][levelId].eduContents.push(eduContentSearch);

          return acc;
        },
        {
          productTypeById: {} as Dictionary<EduContentProductTypeInterface>,
          levelByProductTypeIdByLevelId: {} as Dictionary<
            Dictionary<LevelInterface & { eduContents: EduContentSearchResultInterface[] }>
          >,
        }
      );

    return Object.values(productTypesById).map((productType) => {
      const levels = Object.values(levelsByProductTypeIdByLevelId[productType.id]);
      return { ...productType, levels };
    });
  }

  private toSectionGroup(
    sections: SectionInterface[],
    sectionContentsBySectionId: Dictionary<SectionContentInterface[]>,
    eduContentDict: Dictionary<EduContent>
  ) {
    if (!sections) return [];

    return sections
      .map((section) => {
        const sectionContents = sectionContentsBySectionId[section.id] || [];
        const sectionContentsWithEduContents = sectionContents
          .map((sectionContent) => {
            const eduContent = eduContentDict[sectionContent.eduContentId];
            if (!eduContent) {
              //adding console warn, to get notified
              //about removed eduContent that is still present in the section json data
              console.warn(
                `EduContent with id ${sectionContent.eduContentId} not found. It is still present in the section json data with sectionId ${section.id}.`
              );
              return;
            }
            return {
              ...sectionContent,
              eduContentSearch: { id: sectionContent.id, eduContent } as EduContentSearchResultInterface,
            };
          })
          .filter(Boolean);
        return { ...section, sectionContents: sectionContentsWithEduContents };
      })
      .filter((section) => section.sectionContents.length > 0);
  }

  private toCategories<
    T,
    U extends KeyWithType<T, Array<object>>,
    V extends T[U],
    // @ts-expect-error -> V[number]
    W extends keyof V[number]
  >(
    grouped: T[],
    subCategoryKey: U,
    contentKey: W,
    options: {
      mapFn?: (eduContent: EduContentSearchResultInterface) => EduContentSearchResultInterface;
      categorySortComparer?: (a: T, b: T) => number;
      subCategorySortComparer?: (a: V, b: V) => number;
      categoryMapFn?: (category: T) => object;
    } = {}
  ) {
    const categories = grouped.map((group) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const subCats = group[subCategoryKey] as unknown as any[];

      const { subCategories, contentCount } = subCats.reduce(
        (acc, subC) => {
          const content: EduContentSearchResultInterface[] = Array.isArray(subC[contentKey])
            ? subC[contentKey]
            : [subC[contentKey]];

          const mapped = options.mapFn ? content.map(options.mapFn) : content;
          const count = content.length;

          acc.contentCount += count;
          acc.subCategories.push({ ...subC, contents: mapped, contentCount: count });

          return acc;
        },
        { subCategories: [], contentCount: 0 }
      );

      // FYI Feel free to add mapping functions to the options for these, when needed
      const id = group['id'];
      const label = group['name'] ?? '';
      const displayOrder = group['displayOrder'] ?? group['sequence'] ?? 0;
      const icon = group['icon'];

      if (options.subCategorySortComparer) {
        subCategories.sort(options.subCategorySortComparer);
      }

      let category = {
        ...group,
        id,
        contentCount,
        label,
        icon,
        showSubCategories: true,
        displayOrder,
        subCategories,
      };

      if (options.categoryMapFn) {
        category = options.categoryMapFn(category) as typeof category;
      }

      return category;
    });

    if (options.categorySortComparer) {
      categories.sort(options.categorySortComparer);
    }

    return categories;
  }

  public getAddBasketFn(basketSet: Set<number | string>, selectedSet: Set<number | string>) {
    return (eduContentSearch: EduContentSearchResultInterface) => {
      const { eduContent } = eduContentSearch;
      if (!eduContent) return eduContentSearch;

      return {
        ...eduContentSearch,
        inBasket: basketSet.has(eduContent.id),
        checked: basketSet.has(eduContent.id) || selectedSet.has(eduContent.id),
      };
    };
  }
}
