import { Action } from '@ngrx/store';
import { MethodOptionInterface } from '../../+models/MethodOption.interface';

export enum MethodOptionsActionTypes {
  LoadMethodOptions = '[MethodOptions] Load MethodOptions',
  MethodOptionsLoaded = '[MethodOptions] MethodOptions Loaded',
  MethodOptionsLoadError = '[MethodOptions] MethodOptions Load Error',
  AddMethodOptionsForBook = '[MethodOptions] Add MethodOptions For Book',
}

export class LoadMethodOptions implements Action {
  readonly type = MethodOptionsActionTypes.LoadMethodOptions;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class MethodOptionsLoaded implements Action {
  readonly type = MethodOptionsActionTypes.MethodOptionsLoaded;

  constructor(public payload: { methodOptions: MethodOptionInterface[] }) {}
}

export class MethodOptionsLoadError implements Action {
  readonly type = MethodOptionsActionTypes.MethodOptionsLoadError;
  constructor(public payload: Error) {}
}

export class AddMethodOptionsForBook implements Action {
  readonly type = MethodOptionsActionTypes.AddMethodOptionsForBook;

  constructor(public payload: { methodOptions: MethodOptionInterface[]; bookId: number }) {}
}

export type MethodOptionsActions =
  | LoadMethodOptions
  | MethodOptionsLoaded
  | MethodOptionsLoadError
  | AddMethodOptionsForBook;
