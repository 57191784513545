import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch, optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from } from 'rxjs';
import { map, mapTo, switchMap } from 'rxjs/operators';
import { EvaluationSubjectQueries } from '.';
import {
  AddEvaluationSubjectResponseInterface,
  EvaluationSubjectServiceInterface,
  EVALUATION_SUBJECT_SERVICE_TOKEN,
  UpdateEvaluationSubjectResponseInterface,
} from '../../evaluation-subject/evaluation-subject.service.interface';
import { DalState } from '../dal.state.interface';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { EvaluationSubjectGoalActions } from '../evaluation-subject-goal';
import { AddEvaluationSubjectGoals } from '../evaluation-subject-goal/evaluation-subject-goal.actions';
import {
  AddEvaluationSubject,
  AddEvaluationSubjectsForBook,
  DeleteEvaluationSubject,
  EvaluationSubjectsActionTypes,
  EvaluationSubjectsLoadError,
  LoadEvaluationSubjectsForBook,
  StartAddEvaluationSubject,
  UpdateEvaluationSubject,
} from './evaluation-subject.actions';

@Injectable()
export class EvaluationSubjectEffects {
  loadEvaluationSubjectsForBook$ = createEffect(() =>
    this.actions.pipe(
      ofType(EvaluationSubjectsActionTypes.LoadEvaluationSubjectsForBook),
      concatLatestFrom(() => this.store.select(EvaluationSubjectQueries.selectEvaluationSubjectState)),
      fetch({
        run: (action: LoadEvaluationSubjectsForBook, evalationSubjectState) => {
          const { bookId } = action.payload;

          if (evalationSubjectState.loadedForBook[bookId]) return;

          return this.evaluationSubjectService.getEvaluationSubjectsForBook(bookId).pipe(
            map(
              (evaluationSubjects) =>
                new AddEvaluationSubjectsForBook({
                  bookId,
                  evaluationSubjects,
                })
            )
          );
        },
        onError: (action: LoadEvaluationSubjectsForBook, error) => {
          return new EvaluationSubjectsLoadError(error);
        },
      })
    )
  );

  updateEvaluationSubject = createEffect(() =>
    this.actions.pipe(
      ofType(EvaluationSubjectsActionTypes.UpdateEvaluationSubject),
      optimisticUpdate({
        run: (action: UpdateEvaluationSubject) => {
          const { changes } = action.payload.evaluationSubject;
          return this.evaluationSubjectService.updateEvaluationSubject(changes.id, changes).pipe(
            switchMap(
              ({
                evaluationSubject, // already optimistically updated
                evaluationSubjectGoals,
              }: UpdateEvaluationSubjectResponseInterface) => {
                // pessimistically update the linked state
                return from([
                  new EvaluationSubjectGoalActions.SetEvaluationSubjectGoalsForEvaluationSubject({
                    evaluationSubjectGoals,
                    evaluationSubjectId: evaluationSubject.id,
                  }),
                  new AddEffectFeedback({
                    effectFeedback: EffectFeedback.generateSuccessFeedback(
                      this.uuid(),
                      action,
                      'Het onderwerp is aangepast.'
                    ),
                  }),
                ]);
              }
            )
          );
        },
        undoAction: (action: UpdateEvaluationSubject, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het doel aan te passen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  startAddEvaluationSubject$ = createEffect(() =>
    this.actions.pipe(
      ofType(EvaluationSubjectsActionTypes.StartAddEvaluationSubject),
      pessimisticUpdate({
        run: (action: StartAddEvaluationSubject) => {
          return this.evaluationSubjectService.addEvaluationSubject(action.payload.evaluationSubject).pipe(
            switchMap(({ evaluationSubject, evaluationSubjectGoals }: AddEvaluationSubjectResponseInterface) => {
              return from([
                new AddEvaluationSubject({ evaluationSubject }),
                new AddEvaluationSubjectGoals({ evaluationSubjectGoals }),
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'Het onderwerp is toegevoegd.'
                  ),
                }),
              ]);
            })
          );
        },
        onError: (action: StartAddEvaluationSubject, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het onderwerp toe te voegen.'
            ),
          });
        },
      })
    )
  );

  deleteEvaluationSubject$ = createEffect(() =>
    this.actions.pipe(
      ofType(EvaluationSubjectsActionTypes.DeleteEvaluationSubject),
      optimisticUpdate({
        run: (action: DeleteEvaluationSubject) => {
          const { id } = action.payload;
          // also removes the evalationSubjectGoals of the deleted evalation
          // see the evaluationSubjectGoals reducer
          return this.evaluationSubjectService.deleteEvaluationSubject(id).pipe(
            mapTo(
              new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'Het onderwerp is verwijderd.'
                ),
              })
            )
          );
        },
        undoAction: (action: DeleteEvaluationSubject, error) => {
          // will also undo the deleted evaluationSubjectGoals🔥
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het onderwerp te verwijderen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  constructor(
    @Inject('uuid')
    private uuid,
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(EVALUATION_SUBJECT_SERVICE_TOKEN)
    private evaluationSubjectService: EvaluationSubjectServiceInterface
  ) {}
}
