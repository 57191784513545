import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { GoalEduContentTOCInterface } from '../../+models/GoalEduContentTOC.interface';
import { GoalEduContentTOCActions, GoalEduContentTOCActionTypes } from './goal-edu-content-toc.actions';

export const NAME = 'goalEduContentTOCs';

export interface State extends EntityState<GoalEduContentTOCInterface> {
  // additional entities state properties
  loadedForBook: Dictionary<boolean>;
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<GoalEduContentTOCInterface> = createEntityAdapter<GoalEduContentTOCInterface>({
  selectId: (entity) => entity.id,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loadedForBook: {}, // used in INK
  loaded: false,
});

export function reducer(state = initialState, action: GoalEduContentTOCActions): State {
  switch (action.type) {
    case GoalEduContentTOCActionTypes.GoalEduContentTOCsLoaded: {
      return adapter.setAll(action.payload.goalEduContentTOCs, {
        ...state,
        loaded: true,
      });
    }

    case GoalEduContentTOCActionTypes.GoalEduContentTOCsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case GoalEduContentTOCActionTypes.DeleteGoalEduContentTOCs: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case GoalEduContentTOCActionTypes.AddGoalEduContentTOCsForBook: {
      const { bookId, goalEduContentTOCs } = action.payload;
      return adapter.upsertMany(goalEduContentTOCs, {
        ...state,
        loadedForBook: { ...state.loadedForBook, [bookId]: true },
      });
    }

    case GoalEduContentTOCActionTypes.DeleteGoalEduContentTOC: {
      return adapter.removeOne(action.payload.id, state);
    }

    case GoalEduContentTOCActionTypes.AddGoalEduContentTOC: {
      return adapter.addOne(action.payload.goalEduContentTOC, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities } = adapter.getSelectors();
