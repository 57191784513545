import { ArrayFunctions } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EduContent, EduContentInterface } from '../../+models';
import { NAME, selectAll, selectIds, selectTotal, State } from './edu-content.reducer';

export const selectEduContentState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectEduContentState, (state: State) => state.error);

// Deprecated, eduContents are always upserted
// export const getLoaded = createSelector(selectEduContentState, (state: State) => state.loaded);

export const getLoadedForResults = createSelector(selectEduContentState, (state: State) => state.loadedForResults);

export const getAll = createSelector(selectEduContentState, selectAll);

export const getCount = createSelector(selectEduContentState, selectTotal);

export const getIds = createSelector(selectEduContentState, selectIds);

export const getAllEntities = createSelector(selectEduContentState, (state: State) => {
  return (<number[]>state.ids).reduce((acc, id) => {
    acc[id] = EduContent.toEduContent(state.entities[id]);
    return acc;
  }, {} as Dictionary<EduContent>);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * eduContent$: EduContentInterface[] = this.store.pipe(
    select(EduContentQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectEduContentState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => EduContent.toEduContent(state.entities[id]));
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * eduContent$: EduContentInterface = this.store.pipe(
    select(EduContentQueries.getById, { id: 3 })
  );
 */
export const getById = (id: number) =>
  createSelector(selectEduContentState, (state: State) => EduContent.toEduContent(state.entities[id]));

export const getBoekeByBookId = createSelector(
  getAll,
  (eduContents: EduContentInterface[], props: { bookId: number }) => {
    return EduContent.toEduContent(
      eduContents.find(
        (eduContent) =>
          eduContent.publishedEduContentMetadata &&
          eduContent.publishedEduContentMetadata.eduContentBookId === props.bookId &&
          eduContent.type === 'boek-e'
      )
    );
  }
);

export const isBookLoaded = createSelector(
  selectEduContentState,
  (state: State, props: { bookId: number }) => !!state.loadedForBook[+props.bookId]
);

export const isGeneralFilesForBookLoaded = createSelector(
  selectEduContentState,
  (state: State, props: { bookId: number }) => !!state.loadedGeneralFilesForBook[+props.bookId]
);

export const getGeneralFilesForBook = createSelector(
  selectEduContentState,
  (state: State, props: { bookId: number }) => {
    const generalFileIds = state.loadedGeneralFilesForBook[+props.bookId];
    return generalFileIds.map((id) => state.entities[id]).filter((file) => !!file);
  }
);

export const isGamesForBookLoaded = (props: { bookId: number }) =>
  createSelector(selectEduContentState, (state: State) => !!state.loadedGamesForBook[props.bookId]);

export const isLoadedForSectionsForBook = (props: { bookId: number }) =>
  createSelector(selectEduContentState, (state: State) => !!state.loadedForSectionsForBook[+props.bookId]);

export const isLoadedForSectionsForToc = (props: { tocId: number }) =>
  createSelector(selectEduContentState, (state: State) => !!state.loadedForSectionsForToc[+props.tocId]);

export const isLoadedForTask = (props: { taskId: number }) =>
  createSelector(selectEduContentState, (state: State) => !!state.loadedForTask[+props.taskId]);

export const getGamesForBook = (props: { bookId: number }) =>
  createSelector(selectEduContentState, (state: State) => {
    const gameIds = state.loadedGamesForBook[+props.bookId];
    return gameIds.map((id) => state.entities[id]).filter((game) => !!game);
  });

export const getAllGames = createSelector(selectEduContentState, (state: State) => {
  const gameIds = ArrayFunctions.flatten(Object.values(state.loadedGamesForBook));
  return gameIds.map((id) => EduContent.toEduContent(state.entities[id])).filter((game) => !!game);
});

export const isEduContentResultPending = (props: { eduContentId: number }) =>
  createSelector(selectEduContentState, (state: State) => !!state.pendingResults[props.eduContentId]);
