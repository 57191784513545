import { Injectable, InjectionToken } from '@angular/core';
import { MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';

export const SNACKBAR_DEFAULT_CONFIG_TOKEN = new InjectionToken('SnackBarDefaultConfig');
@Injectable()
export class SnackBarDefaultConfig extends MatSnackBarConfig {
  private setDefaults() {
    this.duration = 3000;
  }

  constructor() {
    super();
    this.setDefaults();
  }
}
