import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AtlasSchoolInterface } from '../../+models';
import { AtlasSchoolsActions, AtlasSchoolsActionTypes } from './atlas-school.actions';

export const NAME = 'atlasSchools';

export interface State extends EntityState<AtlasSchoolInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<AtlasSchoolInterface> = createEntityAdapter<AtlasSchoolInterface>({
  selectId: (entity) => entity.klnr,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: AtlasSchoolsActions): State {
  switch (action.type) {
    case AtlasSchoolsActionTypes.AddAtlasSchool: {
      return adapter.addOne(action.payload.atlasSchool, state);
    }

    case AtlasSchoolsActionTypes.UpsertAtlasSchool: {
      return adapter.upsertOne(action.payload.atlasSchool, state);
    }

    case AtlasSchoolsActionTypes.AddAtlasSchools: {
      return adapter.addMany(action.payload.atlasSchools, state);
    }

    case AtlasSchoolsActionTypes.UpsertAtlasSchools: {
      return adapter.upsertMany(action.payload.atlasSchools, state);
    }

    case AtlasSchoolsActionTypes.UpdateAtlasSchool: {
      return adapter.updateOne(action.payload.atlasSchool, state);
    }

    case AtlasSchoolsActionTypes.UpdateAtlasSchools: {
      return adapter.updateMany(action.payload.atlasSchools, state);
    }

    case AtlasSchoolsActionTypes.DeleteAtlasSchool: {
      return adapter.removeOne(action.payload.id, state);
    }

    case AtlasSchoolsActionTypes.DeleteAtlasSchools: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case AtlasSchoolsActionTypes.AtlasSchoolsLoaded: {
      return adapter.setAll(action.payload.atlasSchools, {
        ...state,
        loaded: true,
      });
    }

    case AtlasSchoolsActionTypes.AtlasSchoolsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case AtlasSchoolsActionTypes.ClearAtlasSchools: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
