import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { TaskEduContentTaskGoalYearLevelInterface } from '../../+models';

export enum TaskEduContentTaskGoalYearLevelsActionTypes {
  TaskEduContentTaskGoalYearLevelsLoaded = '[TaskEduContentTaskGoalYearLevels] TaskEduContentTaskGoalYearLevels Loaded',
  TaskEduContentTaskGoalYearLevelsLoadError = '[TaskEduContentTaskGoalYearLevels] Load Error',
  LoadTaskEduContentTaskGoalYearLevels = '[TaskEduContentTaskGoalYearLevels] Load TaskEduContentTaskGoalYearLevels',
  LoadTaskEduContentTaskGoalYearLevelsForTask = '[TaskEduContentTaskGoalYearLevels] Load TaskEduContentTaskGoalYearLevels for Task',
  StartSetTaskEduContentTaskGoalYearLevelsForTask = '[TaskEduContentTaskGoalYearLevels] Start Set TaskEduContentTaskGoalYearLevels for task',
  SetTaskEduContentTaskGoalYearLevelsForTask = '[TaskEduContentTaskGoalYearLevels] Set TaskEduContentTaskGoalYearLevels for task',
  AddTaskEduContentTaskGoalYearLevelsForTask = '[TaskEduContentTaskGoalYearLevels] Add TaskEduContentTaskGoalYearLevels for Task',
  AddTaskEduContentTaskGoalYearLevel = '[TaskEduContentTaskGoalYearLevels] Add TaskEduContentTaskGoalYearLevel',
  UpsertTaskEduContentTaskGoalYearLevel = '[TaskEduContentTaskGoalYearLevels] Upsert TaskEduContentTaskGoalYearLevel',
  AddTaskEduContentTaskGoalYearLevels = '[TaskEduContentTaskGoalYearLevels] Add TaskEduContentTaskGoalYearLevels',
  UpsertTaskEduContentTaskGoalYearLevels = '[TaskEduContentTaskGoalYearLevels] Upsert TaskEduContentTaskGoalYearLevels',
  UpdateTaskEduContentTaskGoalYearLevel = '[TaskEduContentTaskGoalYearLevels] Update TaskEduContentTaskGoalYearLevel',
  UpdateTaskEduContentTaskGoalYearLevels = '[TaskEduContentTaskGoalYearLevels] Update TaskEduContentTaskGoalYearLevels',
  DeleteTaskEduContentTaskGoalYearLevel = '[TaskEduContentTaskGoalYearLevels] Delete TaskEduContentTaskGoalYearLevel',
  DeleteTaskEduContentTaskGoalYearLevels = '[TaskEduContentTaskGoalYearLevels] Delete TaskEduContentTaskGoalYearLevels',
  ClearTaskEduContentTaskGoalYearLevels = '[TaskEduContentTaskGoalYearLevels] Clear TaskEduContentTaskGoalYearLevels',
}

export class LoadTaskEduContentTaskGoalYearLevels implements Action {
  readonly type = TaskEduContentTaskGoalYearLevelsActionTypes.LoadTaskEduContentTaskGoalYearLevels;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class LoadTaskEduContentTaskGoalYearLevelsForTask implements Action {
  readonly type = TaskEduContentTaskGoalYearLevelsActionTypes.LoadTaskEduContentTaskGoalYearLevelsForTask;

  constructor(public payload: { taskId: number }) {}
}

export class AddTaskEduContentTaskGoalYearLevelsForTask implements Action {
  readonly type = TaskEduContentTaskGoalYearLevelsActionTypes.AddTaskEduContentTaskGoalYearLevelsForTask;

  constructor(
    public payload: { taskId: number; taskEduContentTaskGoalYearLevels: TaskEduContentTaskGoalYearLevelInterface[] }
  ) {}
}
export class StartSetTaskEduContentTaskGoalYearLevelsForTask implements Action {
  readonly type = TaskEduContentTaskGoalYearLevelsActionTypes.StartSetTaskEduContentTaskGoalYearLevelsForTask;

  constructor(
    public payload: { taskId: number; taskEduContentTaskGoalYearLevels: TaskEduContentTaskGoalYearLevelInterface[] }
  ) {}
}
export class SetTaskEduContentTaskGoalYearLevelsForTask implements Action {
  readonly type = TaskEduContentTaskGoalYearLevelsActionTypes.SetTaskEduContentTaskGoalYearLevelsForTask;

  constructor(
    public payload: {
      taskId: number;
      taskEduContentTaskGoalYearLevels: TaskEduContentTaskGoalYearLevelInterface[];
      taskEduContentIds: number[];
      removedTaskEduContentTaskGoalYearLevelIds: number[];
    }
  ) {}
}

export class TaskEduContentTaskGoalYearLevelsLoaded implements Action {
  readonly type = TaskEduContentTaskGoalYearLevelsActionTypes.TaskEduContentTaskGoalYearLevelsLoaded;

  constructor(public payload: { taskEduContentTaskGoalYearLevels: TaskEduContentTaskGoalYearLevelInterface[] }) {}
}

export class TaskEduContentTaskGoalYearLevelsLoadError implements Action {
  readonly type = TaskEduContentTaskGoalYearLevelsActionTypes.TaskEduContentTaskGoalYearLevelsLoadError;
  constructor(public payload: any) {}
}

export class AddTaskEduContentTaskGoalYearLevel implements Action {
  readonly type = TaskEduContentTaskGoalYearLevelsActionTypes.AddTaskEduContentTaskGoalYearLevel;

  constructor(public payload: { taskEduContentTaskGoalYearLevel: TaskEduContentTaskGoalYearLevelInterface }) {}
}

export class UpsertTaskEduContentTaskGoalYearLevel implements Action {
  readonly type = TaskEduContentTaskGoalYearLevelsActionTypes.UpsertTaskEduContentTaskGoalYearLevel;

  constructor(public payload: { taskEduContentTaskGoalYearLevel: TaskEduContentTaskGoalYearLevelInterface }) {}
}

export class AddTaskEduContentTaskGoalYearLevels implements Action {
  readonly type = TaskEduContentTaskGoalYearLevelsActionTypes.AddTaskEduContentTaskGoalYearLevels;

  constructor(public payload: { taskEduContentTaskGoalYearLevels: TaskEduContentTaskGoalYearLevelInterface[] }) {}
}

export class UpsertTaskEduContentTaskGoalYearLevels implements Action {
  readonly type = TaskEduContentTaskGoalYearLevelsActionTypes.UpsertTaskEduContentTaskGoalYearLevels;

  constructor(public payload: { taskEduContentTaskGoalYearLevels: TaskEduContentTaskGoalYearLevelInterface[] }) {}
}

export class UpdateTaskEduContentTaskGoalYearLevel implements Action {
  readonly type = TaskEduContentTaskGoalYearLevelsActionTypes.UpdateTaskEduContentTaskGoalYearLevel;

  constructor(public payload: { taskEduContentTaskGoalYearLevel: Update<TaskEduContentTaskGoalYearLevelInterface> }) {}
}

export class UpdateTaskEduContentTaskGoalYearLevels implements Action {
  readonly type = TaskEduContentTaskGoalYearLevelsActionTypes.UpdateTaskEduContentTaskGoalYearLevels;

  constructor(
    public payload: { taskEduContentTaskGoalYearLevels: Update<TaskEduContentTaskGoalYearLevelInterface>[] }
  ) {}
}

export class DeleteTaskEduContentTaskGoalYearLevel implements Action {
  readonly type = TaskEduContentTaskGoalYearLevelsActionTypes.DeleteTaskEduContentTaskGoalYearLevel;

  constructor(public payload: { id: number }) {}
}

export class DeleteTaskEduContentTaskGoalYearLevels implements Action {
  readonly type = TaskEduContentTaskGoalYearLevelsActionTypes.DeleteTaskEduContentTaskGoalYearLevels;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearTaskEduContentTaskGoalYearLevels implements Action {
  readonly type = TaskEduContentTaskGoalYearLevelsActionTypes.ClearTaskEduContentTaskGoalYearLevels;
}

export type TaskEduContentTaskGoalYearLevelsActions =
  | LoadTaskEduContentTaskGoalYearLevels
  | TaskEduContentTaskGoalYearLevelsLoaded
  | TaskEduContentTaskGoalYearLevelsLoadError
  | AddTaskEduContentTaskGoalYearLevel
  | UpsertTaskEduContentTaskGoalYearLevel
  | AddTaskEduContentTaskGoalYearLevels
  | UpsertTaskEduContentTaskGoalYearLevels
  | UpdateTaskEduContentTaskGoalYearLevel
  | UpdateTaskEduContentTaskGoalYearLevels
  | DeleteTaskEduContentTaskGoalYearLevel
  | DeleteTaskEduContentTaskGoalYearLevels
  | ClearTaskEduContentTaskGoalYearLevels
  | LoadTaskEduContentTaskGoalYearLevelsForTask
  | AddTaskEduContentTaskGoalYearLevelsForTask
  | StartSetTaskEduContentTaskGoalYearLevelsForTask
  | SetTaskEduContentTaskGoalYearLevelsForTask;
