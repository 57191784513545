import { groupArrayByKey, groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EduContentTOCEduContentMetadataInterface } from '../../+models';
import {
  NAME,
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
  State,
} from './edu-content-toc-edu-content-metadata.reducer';

export const selectEduContentTOCEduContentMetadataState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectEduContentTOCEduContentMetadataState, (state: State) => state.error);

export const getLoaded = createSelector(selectEduContentTOCEduContentMetadataState, (state: State) => []);

export const getAll = createSelector(selectEduContentTOCEduContentMetadataState, selectAll);

export const getCount = createSelector(selectEduContentTOCEduContentMetadataState, selectTotal);

export const getIds = createSelector(selectEduContentTOCEduContentMetadataState, selectIds);

export const getAllEntities = createSelector(selectEduContentTOCEduContentMetadataState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * goalYear$: EduContentTOCEduContentMetadataInterface[] = this.store.pipe(
    select(EduContentTOCEduContentMetadataQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(
  selectEduContentTOCEduContentMetadataState,
  (state: State, props: { ids: number[] }) => {
    return props.ids.map((id) => state.entities[id]);
  }
);

/**
 * returns array of objects in the order of the given ids
 * @example
 * goalYear$: EduContentTOCEduContentMetadataInterface = this.store.pipe(
    select(EduContentTOCEduContentMetadataQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectEduContentTOCEduContentMetadataState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const isBookLoaded = (props: { bookId: number }) =>
  createSelector(selectEduContentTOCEduContentMetadataState, (state: State) => !!state.loadedForBook[props.bookId]);

export const getByTocId = createSelector(getAll, (eduContentTocEduContentMetadata) =>
  groupArrayByKey(eduContentTocEduContentMetadata, 'eduContentTOCId')
);

export const getByTocIdByMetadataId = createSelector(
  getAll,
  (eduContentTocEduContentMetadata): Dictionary<Dictionary<EduContentTOCEduContentMetadataInterface>> =>
    groupArrayByKeys(eduContentTocEduContentMetadata, ['eduContentTOCId', 'eduContentMetadataId'], null, true)
);

export const isEduContentTocLoaded = createSelector(
  selectEduContentTOCEduContentMetadataState,
  (state: State, props: { eduContentTocId: number }) => {
    return Object.values(state.entities).some(
      (eduContentTocEduContentMetadata) => eduContentTocEduContentMetadata.eduContentTOCId === props.eduContentTocId
    );
  }
);

export const getByMetadataId = createSelector(getAll, (eduContentTocEduContentMetadata) =>
  groupArrayByKey(eduContentTocEduContentMetadata, 'eduContentMetadataId')
);
