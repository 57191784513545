/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { GoalYearApi as SDKGoalYearApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { GoalYearInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class GoalYearApi extends ModelApiBase {
  private SDKGoalYearApi = inject(SDKGoalYearApi);

  constructor(api: PolpoApi) {
    super(api, 'GoalYears');
  }

  find(options?): Observable<GoalYearInterface[]> {
    return this.SDKGoalYearApi.find(...arguments);
  }

  getGoalYearsForLearningArea(learningAreaId: number): Observable<GoalYearInterface[]> {
    return this.SDKGoalYearApi.getGoalYearsForLearningArea(learningAreaId);
  }
}
