import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@campus/browser';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LearningRecordServiceInterface } from '.';
import { RegistrationApi } from '../+api';
import { LearningRecordInterface } from '../+models';
import { DalState } from '../+state';
import { LearningRecordActions } from '../+state/learning-record';
import { DalOptions, DAL_OPTIONS } from '../dal.module';
import { LearningEventData } from './learning-record-service.interface';

@Injectable({
  providedIn: 'root',
})
export class LearningRecordService implements LearningRecordServiceInterface {
  public updateInProgress$: Observable<boolean>;

  private _updateInProgress$ = new BehaviorSubject<boolean>(false);
  private store: Store<DalState> = inject(Store);
  private registrationApi: RegistrationApi = inject(RegistrationApi);
  private window: Window = inject(WINDOW);
  private dalOptions: DalOptions = inject<DalOptions>(DAL_OPTIONS);

  constructor() {
    this.updateInProgress$ = this._updateInProgress$.asObservable();
  }

  public saveLearningRecord(learningRecord: LearningRecordInterface): Observable<LearningRecordInterface> {
    this._updateInProgress$.next(true);
    return this.registrationApi
      .createLearningRecord(learningRecord.registrationId, learningRecord)
      .pipe(tap(() => this._updateInProgress$.next(false))) as Observable<LearningRecordInterface>;
  }

  public registerEventListener() {
    this.window.addEventListener(
      'message',
      (event) => {
        if (event.origin !== this.dalOptions.apiBaseUrl && event.origin !== this.window.location.origin) {
          return;
        }

        const { data }: { data: LearningEventData } = event;
        if (!data || data.type !== 'LEARNING_EVENT') {
          return;
        }

        const learningRecord = this.eventDataToLearningRecord(data);

        this.store.dispatch(new LearningRecordActions.SaveLearningRecord({ learningRecord }));
      },
      false
    );
  }

  private eventDataToLearningRecord(data: LearningEventData): LearningRecordInterface {
    const { registrationId, verb, payload } = data;

    const learningRecord: LearningRecordInterface = {
      registrationId,
      verb,
      ...payload,
    };

    return learningRecord;
  }
}
