import { Dictionary } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { PrimitivePropertiesKeys } from '../types/generic.types';

export function groupStreamByKey<T, K extends PrimitivePropertiesKeys<T>>(
  stream$: Observable<T[]>,
  key: K
): Observable<Dictionary<T[]>> {
  return stream$.pipe(
    map((arr: T[]): Dictionary<T[]> => {
      return groupArrayByKey(arr, key);
    }),
    shareReplay(1)
  );
}

export function groupArrayByKey<T, K extends PrimitivePropertiesKeys<T>>(
  arr: T[],
  key: K,
  ignoreEmpty = true
): Dictionary<T[]> {
  const byKey = {} as any;
  arr.forEach((item) => {
    if (!item) {
      console.warn('undefined record found in array');
      return;
    }
    const prop = item[key];
    if (ignoreEmpty && (prop === null || prop === undefined)) {
      return;
    }
    if (!byKey[prop]) {
      byKey[prop] = [];
    }
    byKey[prop].push(item);
  });
  return byKey;
}

export function groupArrayByKeys<T, U extends T = T>(
  arr: T[],
  keys: PrimitivePropertiesKeys<U>[],
  filterFn?: (item: T) => boolean,
  lastAsDict?: boolean,
  mapFn?: (item: T) => U
) {
  return arr.reduce((acc, item) => {
    if (filterFn && !filterFn(item)) return acc;
    if (mapFn) item = mapFn(item);

    return addToDict<U>(acc, item as U, keys, lastAsDict);
  }, {});
}

function addToDict<T>(dict, item: T, keys: PrimitivePropertiesKeys<T>[], lastAsDict: boolean) {
  const [key, ...remainingKeys] = keys;
  const prop = item[key];
  const isFinalKey = remainingKeys.length === 0;

  if (prop === null || prop === undefined) {
    return dict;
  }

  if (isFinalKey) {
    if (lastAsDict) {
      if (dict[prop]) {
        console.warn(`key '${String(key)}' with value '${prop}' is not unique`);
        return dict;
      }
      dict[prop] = item;
      return dict;
    }
    if (!dict[prop]) {
      dict[prop] = [];
    }
    dict[prop].push(item);
  } else {
    if (!dict[prop]) {
      dict[prop] = {};
    }
    dict[prop] = addToDict<T>(dict[prop], item, remainingKeys, lastAsDict);
  }
  return dict;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toDictionary<T>(items: T[], key: keyof T = 'id' as any): Dictionary<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return groupArrayByKeys(items, [key] as any, undefined, true);
}
