import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { EduContentBookServiceInterface, EDU_CONTENT_BOOK_SERVICE_TOKEN, EffectFeedbackInterface } from '@campus/dal';
import { ContentEditableComponent, FilterTextInputComponent } from '@campus/ui';
import { FilterServiceInterface, FILTER_SERVICE_TOKEN } from '@campus/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuickLinkTypeEnum } from './quick-link-type.enum';
import { QuickLinkActionInterface, QuickLinkCategoryInterface, QuickLinkInterface } from './quick-link.interface';
import { QuickLinkViewModel } from './quick-link.viewmodel';

@Component({
  selector: 'campus-quick-link',
  templateUrl: './quick-link.component.html',
  styleUrls: ['./quick-link.component.scss'],
  providers: [QuickLinkViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickLinkComponent implements OnInit {
  public quickLinkCategories$: Observable<QuickLinkCategoryInterface[]>;
  public feedback$: Observable<EffectFeedbackInterface>;

  @Input() mode: QuickLinkTypeEnum = QuickLinkTypeEnum.HISTORY;

  @Output() close: EventEmitter<void> = new EventEmitter();

  @ViewChildren(ContentEditableComponent)
  private contentEditables: QueryList<ContentEditableComponent>;
  private activeContentEditable: ContentEditableComponent;

  @ViewChild(FilterTextInputComponent, { static: true })
  filterTextInput: FilterTextInputComponent<QuickLinkCategoryInterface[], QuickLinkCategoryInterface>;

  private actionHandlers = new Map<string, (quickLink: QuickLinkInterface) => void>([
    ['openEduContentAsExercise', (input: QuickLinkInterface): void => this.openEduContentAsExercise(input)],
    ['openEduContentAsSolution', (input: QuickLinkInterface): void => this.openEduContentAsSolution(input)],
    ['openEduContentAsStream', (input: QuickLinkInterface): void => this.openEduContentAsStream(input)],
    ['openEduContentAsDownload', (input: QuickLinkInterface): void => this.openEduContentAsDownload(input)],
    ['openEduContentAsLink', (input: QuickLinkInterface): void => this.openEduContentAsLink(input)],
    ['openBundle', (input: QuickLinkInterface): void => this.openBundle(input)],
    ['openTask', (input: QuickLinkInterface): void => this.openTask(input)],
    ['openArea', (input: QuickLinkInterface): void => this.openArea(input)],
    ['openBoeke', (input: QuickLinkInterface): void => this.openBoeke(input)],
    ['openSearch', (input: QuickLinkInterface): void => this.openSearch(input)],
    ['edit', (input: QuickLinkInterface): void => this.enableEditing(input)],
    ['remove', (input: QuickLinkInterface): void => this.remove(input)],
    ['openMethod', (input: QuickLinkInterface): void => this.openMethod(input)],
    ['openBook', (input: QuickLinkInterface): void => this.openBook(input)],
    ['openEduContentAsHtml', (input: QuickLinkInterface): void => this.openEduContentAsHtml(input)],
  ]);

  public noContentMap = {
    [QuickLinkTypeEnum.HISTORY]: {
      svgImage: 'empty:history',
      title: 'Geen geschiedenis',
      description:
        'Verken eerst het platform. Nadat je o.a. taken hebt gemaakt of methodes hebt bekeken, zal hier een lijst met je laatste acties worden getoond.',
    },
    [QuickLinkTypeEnum.FAVORITES]: {
      svgImage: 'empty:favorites',
      title: 'Geen favorieten',
      description: 'Markeer methodes als favoriet om ze in deze lijst te zien.',
    },
  };

  constructor(
    private quickLinkViewModel: QuickLinkViewModel,
    @Inject(FILTER_SERVICE_TOKEN) private filterService: FilterServiceInterface,
    @Inject(EDU_CONTENT_BOOK_SERVICE_TOKEN)
    private eduContentBookService: EduContentBookServiceInterface
  ) {}

  ngOnInit() {
    this.setupStreams();
    this.filterTextInput.setFilterableItem(this);
  }

  filterFn(source: QuickLinkCategoryInterface[], searchText: string): QuickLinkCategoryInterface[] {
    if (searchText.trim().length > 0) {
      const results = this.filterService
        .filter(
          //eliminate categories
          ([] as QuickLinkInterface[]).concat(...source.map((cat) => cat.quickLinks)),
          { name: searchText }
        )
        .sort(this.quickLinkSorter);

      const contentData: QuickLinkCategoryInterface = {
        type: 'Gevonden items',
        title: 'Gevonden items',
        order: 1,
        quickLinks: results,
      };
      return [contentData];
    } else {
      return source;
    }
  }

  public onActionClick(action: QuickLinkActionInterface, quickLink: QuickLinkInterface) {
    if (this.actionHandlers.has(action.handler)) {
      this.actionHandlers.get(action.handler)(quickLink);
    }
  }

  public closeDialog() {
    this.close.emit();
  }

  public openEduContentAsExercise(quickLink: QuickLinkInterface) {
    this.quickLinkViewModel.openExercise(quickLink.eduContent);
  }

  public openEduContentAsSolution(quickLink: QuickLinkInterface) {
    this.quickLinkViewModel.openEduContentAsSolution(quickLink.eduContent);
  }

  public openEduContentAsStream(quickLink: QuickLinkInterface) {
    this.quickLinkViewModel.openStaticContent(quickLink.eduContent, true);
    this.closeDialog();
  }

  public openEduContentAsDownload(quickLink: QuickLinkInterface) {
    this.quickLinkViewModel.openStaticContent(quickLink.eduContent);
  }

  public openEduContentAsLink(quickLink: QuickLinkInterface) {
    this.quickLinkViewModel.openStaticContent(quickLink.eduContent);
  }

  public openBundle(quickLink: QuickLinkInterface) {
    this.quickLinkViewModel.openBundle(quickLink.bundle);
    this.closeDialog();
  }

  public openTask(quickLink: QuickLinkInterface) {
    this.quickLinkViewModel.openTask(quickLink.task);
    this.closeDialog();
  }

  public openArea(quickLink: QuickLinkInterface) {
    this.quickLinkViewModel.openArea(quickLink.learningArea);
    this.closeDialog();
  }

  public openSearch(quickLink: QuickLinkInterface) {
    this.quickLinkViewModel.openSearch(quickLink, this.mode);
    this.closeDialog();
  }

  public openMethod(quickLink: QuickLinkInterface) {
    this.quickLinkViewModel.openMethod(quickLink, this.mode);
    this.closeDialog();
  }

  public openBook(quickLink: QuickLinkInterface) {
    this.quickLinkViewModel.openBook(quickLink, this.mode);
    this.closeDialog();
  }

  public openBoeke(quickLink: QuickLinkInterface) {
    const { eduContentBookId, productId } = quickLink;
    this.eduContentBookService.openBoeke(eduContentBookId, productId);
  }

  public openEduContentAsHtml(quickLink: QuickLinkInterface) {
    this.quickLinkViewModel.openStaticContent(quickLink.eduContent, false, true);
    this.closeDialog();
  }

  public update(quickLink: QuickLinkInterface, newName: string) {
    this.quickLinkViewModel.update(quickLink.id, newName, this.mode);
  }

  public enableEditing(quickLink: QuickLinkInterface) {
    if (this.activeContentEditable) {
      this.activeContentEditable.active = false;
    }

    const contentEditable = this.contentEditables.find((editable) => editable.relatedItem === quickLink);

    if (contentEditable) {
      this.activeContentEditable = contentEditable;
      this.activeContentEditable.active = true;
    }
  }

  public remove(quickLink: QuickLinkInterface) {
    this.quickLinkViewModel.remove(quickLink.id, this.mode);
  }

  public onBannerDismiss(event) {
    this.quickLinkViewModel.onFeedbackDismiss(event);
  }

  private setupStreams() {
    this.quickLinkCategories$ = this.quickLinkViewModel.getQuickLinkCategories$(this.mode).pipe(
      map((categories) => {
        if (this.mode === QuickLinkTypeEnum.FAVORITES) {
          return categories.sort((a, b) => this.quickLinkDataCategorySorter(a, b)); // order categories
        }
        if (this.mode === QuickLinkTypeEnum.HISTORY) {
          // dummy category for backwards compatibility, we only want the sorted quicklinks for history
          const quickLinks = [].concat(...categories.map((cat) => cat.quickLinks)).sort(this.quickLinkSorter);
          return [{ type: '', title: '', quickLinks, order: 0 }];
        }
        return [];
      })
    );

    this.feedback$ = this.quickLinkViewModel.getFeedback$();
  }

  private quickLinkDataCategorySorter(a: QuickLinkCategoryInterface, b: QuickLinkCategoryInterface): number {
    if (a.order === -1) return 1;
    if (b.order === -1) return -1;

    return a.order - b.order;
  }

  private quickLinkSorter(a: QuickLinkInterface, b: QuickLinkInterface): number {
    return b.created.getTime() - a.created.getTime(); // sorting descending
  }
}
