import { ChangeDetectorRef, Inject, Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { AUTH_SERVICE_TOKEN, AuthServiceInterface, PERSON_SERVICE_TOKEN, PersonServiceInterface } from '@campus/dal';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UniqueUsernameValidator implements AsyncValidator {
  constructor(
    @Inject(PERSON_SERVICE_TOKEN) private personService: PersonServiceInterface,
    @Inject(AUTH_SERVICE_TOKEN) private authService: AuthServiceInterface,
    private cdRef: ChangeDetectorRef
  ) {}

  validate(ctrl: AbstractControl): Observable<ValidationErrors | null> {
    // return of null means there is no error, so the username is unique
    // return of true means there is an error, so the username is not unique
    return of(ctrl.value).pipe(
      delay(500), // prevents making unnecessary calls
      switchMap(() =>
        this.personService.checkUniqueUsername(this.authService.userId, ctrl.value).pipe(
          map((isUnique) => (isUnique ? null : { notUniqueUsername: true })),
          catchError(() => of({ serverError: true })),
          delay(0), // makes sure the ctrl validity is updated
          tap((_) => {
            this.cdRef.markForCheck();
          }) // makes sure the UI is updated once the validation is done
        )
      )
    );
  }
}
