import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT_API_TOKEN } from '@campus/environment';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { PersonPreferenceServiceInterface } from '.';
import { PersonApi, PersonPreferenceApi } from '../+api';
import { PersonPreferenceInterface, PreferenceKey } from '../+models';

@Injectable({
  providedIn: 'root',
})
export class PersonPreferenceService implements PersonPreferenceServiceInterface {
  private http = inject(HttpClient);
  private environment = inject(ENVIRONMENT_API_TOKEN);

  private isValueJSON: { [key in PreferenceKey]?: boolean } = {
    [PreferenceKey.TEMPLATE]: true,
    [PreferenceKey.BOOKS]: true,
    [PreferenceKey.SEARCH]: true,
  };

  constructor(private personPreferenceApi: PersonPreferenceApi, private personApi: PersonApi) {}

  createPersonPreference(
    userId: number,
    personPreference: PersonPreferenceInterface
  ): Observable<PersonPreferenceInterface> {
    return this.personApi
      .createPersonPreferences(userId, [this.stringifyValue(personPreference)])
      .pipe(map((personPreferences) => personPreferences[0]));
  }

  deletePersonPreference(personPreferenceId: number): Observable<boolean> {
    return this.personPreferenceApi.deleteById(personPreferenceId).pipe(mapTo(true));
  }

  upsertPersonPreference(personPreference: PersonPreferenceInterface): Observable<PersonPreferenceInterface> {
    return (
      this.personPreferenceApi.upsertPersonPreference(
        this.stringifyValue(personPreference)
      ) as Observable<PersonPreferenceInterface>
    ).pipe(map((upsertedPersonPreference) => this.parseValue(upsertedPersonPreference)));
  }

  createTemplate(personPreference: PersonPreferenceInterface): Observable<PersonPreferenceInterface> {
    const url = `${this.environment.APIBase}/api/PersonPreferences/create-template`;
    return this.http.post<PersonPreferenceInterface>(url, personPreference, { withCredentials: true });
  }

  private stringifyValue(personPreference: PersonPreferenceInterface) {
    if (!this.isValueJSON[personPreference.key]) {
      return personPreference;
    }

    return {
      ...personPreference,
      value: JSON.stringify(personPreference.value),
    };
  }

  private parseValue(personPreference: PersonPreferenceInterface) {
    if (!this.isValueJSON[personPreference.key]) {
      return personPreference;
    }

    return {
      ...personPreference,
      value: JSON.parse(personPreference.value),
    };
  }
}
