import { Dictionary } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { DataResponseInterface } from '../../../data/data.service.interface';
import { AchievementActions } from '../../achievement';
import { AlgebrakitSessionActions } from '../../algebrakit-session';
import { AtlasProductActions } from '../../atlas-product';
import { AtlasSchoolActions } from '../../atlas-school';
import { BadgeActions } from '../../badge';
import { BundleActions } from '../../bundle';
import { BundleClassGroupActions } from '../../bundle-class-group';
import { BundleGroupActions } from '../../bundle-group';
import { BundlePersonActions } from '../../bundle-person';
import { ClassGroupActions } from '../../class-group';
import { ConceptActions } from '../../concept';
import { ConceptYearActions } from '../../concept-year';
import { CredentialActions } from '../../credential';
import { CurriculumActions } from '../../curriculum';
import { CurriculumTreeActions } from '../../curriculum-tree';
import { CurriculumTreeConceptActions } from '../../curriculum-tree-concept';
import { CurriculumTreeYearActions } from '../../curriculum-tree-year';
import { DiaboloPhaseActions } from '../../diabolo-phase';
import { DidacticFunctionActions } from '../../didactic-function';
import { EditorStatusActions } from '../../editor-status';
import { EduContentActions } from '../../edu-content';
import { EduContentBookActions } from '../../edu-content-book';
import { EduContentProductTypeActions } from '../../edu-content-product-type';
import { EduContentSourceActions } from '../../edu-content-source';
import { EduContentTagActions } from '../../edu-content-tag';
import { EduContentTocLoopActions } from '../../edu-content-toc-loop';
import { EduNetActions } from '../../edu-net';
import { EvaluationActions } from '../../evaluation';
import { EvaluationScoreActions } from '../../evaluation-score';
import { EvaluationScoreListActions } from '../../evaluation-score-list';
import { EvaluationScoreListSubjectActions } from '../../evaluation-score-list-subject';
import { GoalActions } from '../../goal';
import { GoalPrerequisiteActions } from '../../goal-prerequisite';
import { GoalTopicActions } from '../../goal-topic';
import { GroupActions } from '../../group';
import { GroupPersonActions } from '../../group-person';
import { HistoryActions } from '../../history';
import { InkMethodActions } from '../../ink-method';
import { LearnerProfileConceptActions } from '../../learner-profile-concept';
import { LearnerProfileStrandActions } from '../../learner-profile-strand';
import { LearningAreaActions } from '../../learning-area';
import { LearningDomainActions } from '../../learning-domain';
import { LearningPlanGoalProgressActions } from '../../learning-plan-goal-progress';
import { LevelActions } from '../../level';
import { LicenseActions } from '../../license';
import { LinkedPersonActions } from '../../linked-person';
import { LoopActions } from '../../loop';
import { MethodActions } from '../../method';
import { MethodGoalActions } from '../../method-goal';
import { MethodLevelActions } from '../../method-level';
import { MethodNewsActions } from '../../method-news';
import { MethodOptionsActions } from '../../method-option';
import { MethodProductGroupActions } from '../../method-product-group';
import { MethodProductGroupProductActions } from '../../method-product-group-product';
import { NewsActions } from '../../news';
import { NewsCategoryActions } from '../../news-category';
import { NewsCategoryNewsActions } from '../../news-category-news';
import { NewsProductActions } from '../../news-product';
import { NewsRoleActions } from '../../news-role';
import { OlfLearningDomainActions } from '../../olf-learning-domain';
import { PersonPreferenceActions } from '../../person-preference';
import { PersonProductActions } from '../../person-product';
import { ProductActions } from '../../product';
import { ProductContentActions } from '../../product-content';
import { PurchaseActions } from '../../purchase';
import { PurchaseProductActions } from '../../purchase-product';
import { ResultActions } from '../../result';
import { RoleActions } from '../../role';
import { SchoolActions } from '../../school';
import { SchoolBillingActions } from '../../school-billing';
import { SchoolBillingAddressActions } from '../../school-billing-address';
import { SchoolProviderActions } from '../../school-provider';
import { SchoolRoleMappingActions } from '../../school-role-mapping';
import { SchoolRoleMappingClassGroupActions } from '../../school-role-mapping-class-group';
import { SurveyActions } from '../../survey';
import { SurveyAnswerActions } from '../../survey-answer';
import { SurveyQuestionActions } from '../../survey-question';
import { TaskActions } from '../../task';
import { TaskClassGroupActions } from '../../task-class-group';
import { TaskEduContentActions } from '../../task-edu-content';
import { TaskGroupActions } from '../../task-group';
import { TaskInstanceActions } from '../../task-instance';
import { TaskStudentActions } from '../../task-student';
import { TeacherStudentActions } from '../../teacher-student';
import { UnlockedBoekeGroupActions } from '../../unlocked-boeke-group';
import { UnlockedBoekeStudentActions } from '../../unlocked-boeke-student';
import { UnlockedContentActions } from '../../unlocked-content';
import { UnlockedCurriculumTreeActions } from '../../unlocked-curriculum-tree';
import { UnlockedFreePracticeActions } from '../../unlocked-free-practice';
import { UserActions } from '../../user';
import { UserContentActions } from '../../user-content';
import { UserLessonActions } from '../../user-lesson';
import { YearActions } from '../../year';

const fieldActionMap: Dictionary<any> = {
  algebrakitSessions: AlgebrakitSessionActions.AlgebrakitSessionsLoaded,
  eduContentTOCLoops: EduContentTocLoopActions.EduContentTOCLoopsLoaded,
  eduContentsForResults: EduContentActions.EduContentsLoadedForResults,
  userContents: UserContentActions.UserContentsLoaded,
  unlockedContents: UnlockedContentActions.UnlockedContentsLoaded,
  bundles: BundleActions.BundlesLoaded,
  unlockedBoekeStudents: UnlockedBoekeStudentActions.UnlockedBoekeStudentsLoaded,
  unlockedBoekeGroups: UnlockedBoekeGroupActions.UnlockedBoekeGroupsLoaded,
  tasks: TaskActions.TasksLoaded,
  taskEduContents: TaskEduContentActions.TaskEduContentsLoaded,
  taskInstances: TaskInstanceActions.TaskInstancesLoaded,
  teacherStudents: TeacherStudentActions.TeacherStudentsLoaded,
  persons: LinkedPersonActions.LinkedPersonsLoaded,
  methods: MethodActions.MethodsLoaded,
  inkMethods: InkMethodActions.InkMethodsLoaded,
  userLessons: UserLessonActions.UserLessonsLoaded,
  classGroups: ClassGroupActions.ClassGroupsLoaded,
  unlockedFreePractices: UnlockedFreePracticeActions.UnlockedFreePracticesLoaded,
  methodLevels: MethodLevelActions.MethodLevelsLoaded,
  groups: GroupActions.GroupsLoaded,
  groupPersons: GroupPersonActions.GroupPersonsLoaded,
  history: HistoryActions.HistoryLoaded,
  eduContentBooks: EduContentBookActions.EduContentBooksLoaded,
  methodGoals: MethodGoalActions.MethodGoalsLoaded,
  schools: SchoolActions.SchoolsLoaded,
  roles: RoleActions.RolesLoaded,
  schoolRoleMappingClassGroups: SchoolRoleMappingClassGroupActions.SchoolRoleMappingClassGroupsLoaded,
  schoolRoleMappings: SchoolRoleMappingActions.SchoolRoleMappingsLoaded,
  products: ProductActions.ProductsLoaded,
  productContents: ProductContentActions.ProductContentsLoaded,
  methodProductGroups: MethodProductGroupActions.MethodProductGroupsLoaded,
  methodProductGroupProducts: MethodProductGroupProductActions.MethodProductGroupProductsLoaded,
  licenses: LicenseActions.LicensesLoaded,
  purchases: PurchaseActions.PurchasesLoaded,
  purchaseProducts: PurchaseProductActions.PurchaseProductsLoaded,
  atlasProducts: AtlasProductActions.AtlasProductsLoaded,
  atlasSchools: AtlasSchoolActions.AtlasSchoolsLoaded,
  schoolBillings: SchoolBillingActions.SchoolBillingsLoaded,
  loops: LoopActions.LoopsLoaded,
  unlockedCurriculumTrees: UnlockedCurriculumTreeActions.UnlockedCurriculumTreesLoaded,
  badges: BadgeActions.BadgesLoaded,
  achievements: AchievementActions.AchievementsLoaded,
  curriculums: CurriculumActions.CurriculumsLoaded,
  curriculumTrees: CurriculumTreeActions.CurriculumTreesLoaded,
  curriculumTreeConcepts: CurriculumTreeConceptActions.CurriculumTreeConceptsLoaded,
  curriculumTreeYears: CurriculumTreeYearActions.CurriculumTreeYearsLoaded,
  concepts: ConceptActions.ConceptsLoaded,
  conceptYears: ConceptYearActions.ConceptYearsLoaded,
  surveys: SurveyActions.SurveysLoaded,
  surveyQuestions: SurveyQuestionActions.SurveyQuestionsLoaded,
  surveyAnswers: SurveyAnswerActions.SurveyAnswersLoaded,
  learnerProfileStrands: LearnerProfileStrandActions.LearnerProfileStrandsLoaded,
  learnerProfileConcepts: LearnerProfileConceptActions.LearnerProfileConceptsLoaded,
  schoolBillingAddresses: SchoolBillingAddressActions.SchoolBillingAddressLoaded,
  learningAreas: LearningAreaActions.LearningAreasLoaded,
  diaboloPhases: DiaboloPhaseActions.DiaboloPhasesLoaded,
  years: YearActions.YearsLoaded,
  levels: LevelActions.LevelsLoaded,
  eduContentProductTypes: EduContentProductTypeActions.EduContentProductTypesLoaded,
  didacticFunctions: DidacticFunctionActions.DidacticFunctionsLoaded,
  eduNets: EduNetActions.EduNetsLoaded,
  learningDomains: LearningDomainActions.LearningDomainsLoaded,
  olfLearningDomains: OlfLearningDomainActions.OlfLearningDomainsLoaded,
  goalTopics: GoalTopicActions.GoalTopicsLoaded,
  goals: GoalActions.GoalsLoaded,
  editorStatuses: EditorStatusActions.EditorStatusesLoaded,
  personPreferences: PersonPreferenceActions.PersonPreferencesLoaded,
  personProducts: PersonProductActions.PersonProductsLoaded,
  goalPrerequisites: GoalPrerequisiteActions.GoalPrerequisitesLoaded,
  credentials: CredentialActions.CredentialsLoaded,
  evaluations: EvaluationActions.EvaluationsLoaded,
  evaluationScoreLists: EvaluationScoreListActions.EvaluationScoreListsLoaded,
  evaluationScores: EvaluationScoreActions.EvaluationScoresLoaded,
  evaluationScoreListSubjects: EvaluationScoreListSubjectActions.EvaluationScoreListSubjectsLoaded,
  news: NewsActions.NewsLoaded,
  newsCategories: NewsCategoryActions.NewsCategoriesLoaded,
  newsCategoryNews: NewsCategoryNewsActions.NewsCategoryNewsLoaded,
  methodNews: MethodNewsActions.MethodNewsLoaded,
  newsProducts: NewsProductActions.NewsProductsLoaded,
  newsRoles: NewsRoleActions.NewsRolesLoaded,
  eduContentTags: EduContentTagActions.EduContentTagsLoaded,
  methodOptions: MethodOptionsActions.MethodOptionsLoaded,
  eduContents: EduContentActions.UpsertEduContents,
  schoolProviders: SchoolProviderActions.SchoolProvidersLoaded,
};

const fieldUpsertActionMap: Dictionary<any> = {
  taskInstances: TaskInstanceActions.UpsertTaskInstances,
  tasks: TaskActions.UpsertTasks,
  eduContents: EduContentActions.UpsertEduContents,
  taskEduContents: TaskEduContentActions.UpsertTaskEduContents,
};

export class DataFieldActionFactory {
  /**
   * For a given field & data, return the appropriate 'Loaded' action containing the data.
   *
   * Example: field `"tasks"` with data `[{id: 1}]` returns `TasksLoaded({ tasks: [{id: 1}] })`
   */
  static getLoadedAction(field: keyof DataResponseInterface, data: any): Action {
    const fieldActionExceptions: Dictionary<(data) => Action> = {
      permissions: (permissions) => new UserActions.PermissionsLoaded(permissions),
      allowedMethods: (methodIds) => new MethodActions.AllowedMethodsLoaded({ methodIds }),
      allowedEduContentBooks: (eduContentBooks) =>
        new EduContentBookActions.EduContentBooksLoaded({
          eduContentBooks,
        }),
      learningPlanGoalProgress: (learningPlanGoalProgresses) =>
        new LearningPlanGoalProgressActions.LearningPlanGoalProgressesLoaded({
          learningPlanGoalProgresses,
        }),
      allResults: (results) => new ResultActions.ResultsLoaded({ results }),
      goalYears: (goalYears) => new GoalActions.AppendYearInState({ goalYears }), // TODO: refactor and use GoalYear state see #10036
      taskGroups: (taskGroups) => TaskGroupActions.taskGroupsLoaded({ taskGroups }),
      taskClassGroups: (taskClassGroups) => TaskClassGroupActions.taskClassGroupsLoaded({ taskClassGroups }),
      taskStudents: (taskStudents) => TaskStudentActions.taskStudentsLoaded({ taskStudents }),
      bundleGroups: (bundleGroups) => BundleGroupActions.bundleGroupsLoaded({ bundleGroups }),
      bundleClassGroups: (bundleClassGroups) => BundleClassGroupActions.bundleClassGroupsLoaded({ bundleClassGroups }),
      bundlePersons: (bundlePersons) => BundlePersonActions.bundlePersonsLoaded({ bundlePersons }),
      credentials: (credentials) => new CredentialActions.CredentialsLoaded({ credentials }),
      algebrakitSessions: (algebrakitSessions) =>
        new AlgebrakitSessionActions.AlgebrakitSessionsLoaded({ algebrakitSessions }),
      evaluations: (evaluations) => new EvaluationActions.EvaluationsLoaded({ evaluations }),
      eduContentSources: (eduContentSources) => EduContentSourceActions.eduContentSourcesLoaded({ eduContentSources }),
    };

    if (fieldActionExceptions[field]) {
      return fieldActionExceptions[field](data);
    }

    const FieldLoaded = fieldActionMap[field];

    return new FieldLoaded({
      [field]: data,
    });
  }

  /**
   * For a given field & data, return the appropriate 'Upsert' action containing the data.
   *
   * Example: field `"tasks"` with data `[{id: 1}]` returns `UpsertTasks({ tasks: [{id: 1}] })`
   */
  static getUpsertAction(field: keyof DataResponseInterface, data: any): Action {
    const fieldActionExceptions: Dictionary<(data) => Action> = {
      allResults: (results) => new ResultActions.UpsertResults({ results }),
      eduContentsForResults: (eduContents) => new EduContentActions.UpsertEduContents({ eduContents }),
      taskGroups: (taskGroups) => TaskGroupActions.upsertTaskGroups({ taskGroups }),
      taskClassGroups: (taskClassGroups) => TaskClassGroupActions.upsertTaskClassGroups({ taskClassGroups }),
      taskStudents: (taskStudents) => TaskStudentActions.upsertTaskStudents({ taskStudents }),
    };

    if (fieldActionExceptions[field]) {
      return fieldActionExceptions[field](data);
    }

    const FieldUpsert = fieldUpsertActionMap[field];

    return new FieldUpsert({
      [field]: data,
    });
  }
}
