<div class="shared-toolbar__content [ corner-inherit ]" data-cy="toolbar-navigation">
  <ng-content select="campus-toolbar-navigation"></ng-content>
  <ng-content select="campus-toolbar-actions"></ng-content>
  <div
    [ngClass]="[
      '[ absolute inset-be-0 inset-is-m ]',
      '[ transform translate-y-1/2 ]',
      '[ index-fab ]',
      'shared-toolbar__fab'
    ]"
    *ngIf="hasFab"
  >
    <ng-content select="[campusToolbarFab], campus-toolbar-fab"></ng-content>
  </div>
  <ng-content select="[campusToolbarContainer], campus-toolbar-container"></ng-content>
  <div class="[ w-full ] [ absolute ] [ bottom-0 ]" [@fadeOut] *ngIf="withProgressBar && showProgressBar">
    <campus-progress
      class="[ w-full ]"
      [form]="ProgressFormEnum.LINEAR"
      [mode]="progressMode"
      [staticMode]="true"
      [count]="1"
      [total]="1"
    ></campus-progress>
  </div>
</div>
<ng-content select="[campusToolbarListHeader], campus-toolbar-list-header"></ng-content>
