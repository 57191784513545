import { DateFunctions, groupArrayByKey, groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AlgebrakitSessionInterface,
  EduContent,
  EvaluationScoreListInterface,
  LearningAreaInterface,
  PersonInterface,
  ResultInterface,
  TaskEduContentInterface,
  TaskInterface,
} from '../../+models';
import { TaskInstance } from '../../+models/TaskInstance';
import { TaskInstanceInterface } from '../../+models/TaskInstance.interface';
import { AlgebrakitSessionQueries } from '../algebrakit-session';
import { EduContentQueries } from '../edu-content';
import { EvaluationScoreListQueries } from '../evaluation-score-list';
import { LearningAreaQueries } from '../learning-area';
import { LinkedPersonQueries } from '../linked-person';
import { ResultQueries } from '../result';
import { TaskQueries } from '../task';
import { TaskEduContentQueries } from '../task-edu-content';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './task-instance.reducer';

export const selectTaskInstanceState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectTaskInstanceState, (state: State) => state.error);

export const getLoaded = createSelector(selectTaskInstanceState, (state: State) => state.loaded);

export const getAll = createSelector(selectTaskInstanceState, selectAll);

export const getCount = createSelector(selectTaskInstanceState, selectTotal);

export const getIds = createSelector(selectTaskInstanceState, selectIds);

export const getAllEntities = createSelector(selectTaskInstanceState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * taskInstance$: TaskInstanceInterface[] = this.store.pipe(
    select(TaskInstanceQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectTaskInstanceState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => TaskInstance.toTaskInstance(state.entities[id]));
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * taskInstance$: TaskInstanceInterface = this.store.pipe(
    select(TaskInstanceQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(selectTaskInstanceState, (state: State, props: { id: number }) =>
  TaskInstance.toTaskInstance(state.entities[props.id])
);

/**
 * gets all taskInstances grouped by taskId
 */
export const getAllGroupedByTaskId = createSelector(selectTaskInstanceState, (state: State) => {
  return groupArrayByKey(Object.values(state.entities).map(TaskInstance.toTaskInstance), 'taskId');
});

/**
 * gets all taskInstances for a given taskId
 */
export const getAllByTaskId = createSelector(selectTaskInstanceState, (state: State, props: { taskId: number }) => {
  return (<number[]>state.ids)
    .filter((id) => state.entities[id].taskId === props.taskId)
    .map((id) => TaskInstance.toTaskInstance(state.entities[id]));
});

export const getActiveTaskIds = createSelector(selectTaskInstanceState, (state: State, props: { date: Date }) => {
  return new Set(
    (<number[]>state.ids).reduce(
      (acc, id) =>
        state.entities[id].end > props.date && props.date > state.entities[id].start
          ? [...acc, state.entities[id].taskId]
          : acc,
      []
    )
  );
});

export const getTaskInstanceWithTaskById = createSelector(
  getById,
  TaskQueries.getAllEntities,
  ResultQueries.getResultsByTask,
  TaskEduContentQueries.getAllGroupedByTaskId,
  EduContentQueries.getAllEntities,
  LearningAreaQueries.getAllEntities,
  LinkedPersonQueries.getAllEntities,
  AlgebrakitSessionQueries.getAlgebrakitSessionsByTaskId,
  (
    taskInstance: TaskInstance,
    taskDict: Dictionary<TaskInterface>,
    resultsByTask: Dictionary<ResultInterface[]>,
    taskEduContentByTask: Dictionary<TaskEduContentInterface[]>,
    eduContentDict: Dictionary<EduContent>,
    learningAreaDict: Dictionary<LearningAreaInterface>,
    linkedPersons: Dictionary<PersonInterface>,
    akitSessionsByTaskId: Dictionary<AlgebrakitSessionInterface[]>,
    props: { id: number }
  ) => {
    return {
      ...taskInstance,
      assigner: linkedPersons[taskInstance.assignerId],
      task: {
        ...taskDict[taskInstance.taskId],
        results: resultsByTask[taskInstance.taskId] || [],
        sessions: (akitSessionsByTaskId[taskInstance.taskId] || []).map((session) => ({
          ...session,
          eduContent: eduContentDict[session.eduContentId],
        })),
        taskEduContents: (taskEduContentByTask[taskInstance.taskId] || []).map((tE) => ({
          ...tE,
          eduContent: eduContentDict[tE.eduContentId],
        })),
        learningArea: learningAreaDict[taskDict[taskInstance.taskId].learningAreaId],
      },
    };
  }
);

export const getTaskStudentTaskInstances = createSelector(
  getAll,
  TaskQueries.getAllEntities,
  ResultQueries.getResultsByTask,
  TaskEduContentQueries.getAllGroupedByTaskId,
  LearningAreaQueries.getAllEntities,
  EduContentQueries.getAllEntities,
  AlgebrakitSessionQueries.getAlgebrakitSessionsByTaskId,
  EvaluationScoreListQueries.getAllEntities,
  (
    taskInstances: TaskInstanceInterface[],
    tasksById: Dictionary<TaskInterface>,
    resultsByTaskId: Dictionary<ResultInterface[]>,
    taskEduContentByTaskId: Dictionary<TaskEduContentInterface[]>,
    learningAreaById: Dictionary<LearningAreaInterface>,
    eduContentDict: Dictionary<EduContent>,
    akitSessionsByTaskId: Dictionary<AlgebrakitSessionInterface[]>,
    evaluationScoreListDict: Dictionary<EvaluationScoreListInterface>
  ) => {
    return taskInstances
      .map((ti) => {
        const task = tasksById[ti.taskId];
        if (!task) return;

        const taskInstance: TaskInstanceInterface = {
          ...ti,
          task: {
            ...task,
            results: (resultsByTaskId[ti.taskId] || []).map((res) => ({
              ...res,
              eduContent: eduContentDict[res.eduContentId],
            })),
            sessions: (akitSessionsByTaskId[ti.taskId] || []).map((session) => ({
              ...session,
              eduContent: eduContentDict[session.eduContentId],
            })),
            taskEduContents: taskEduContentByTaskId[ti.taskId] || [],
            learningArea: learningAreaById[task.learningAreaId],
            evaluationScoreList: evaluationScoreListDict[task.evaluationScoreListId],
          },
        };
        return taskInstance;
      })
      .filter((t) => !!t);
  }
);

export const getTaskByTaskInstanceId = createSelector(
  getById,
  TaskQueries.getAllEntities,
  (taskInstance: TaskInstanceInterface, taskDictionary: Dictionary<TaskInterface>, props: { id: number }) => {
    return taskDictionary[taskInstance.taskId];
  }
);

export const getTaskInstancesActiveInPeriod = createSelector(
  getAll,
  (taskInstances: TaskInstanceInterface[], props: { start: Date; end: Date }) => {
    return taskInstances.filter((tI) => {
      const { start, end } = tI;
      return DateFunctions.rangeIntersects({ start, end }, props);
    });
  }
);

export const getTaskInstancesByTaskByStudent = createSelector(
  // Note: we take the state instead of getAll here, because getAll would take the props
  // and lose its memoization since it would be passed the props of this selector,
  // but the props don't actually matter for getAll
  // Source: a bug that caused expansion panels to close when reviewing exercises
  selectTaskInstanceState,
  (state: State) => {
    const taskInstances = Object.values(state.entities);

    return groupArrayByKeys(taskInstances, ['taskId', 'personId']);
  }
);
