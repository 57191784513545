import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SchoolTypeApi } from '../+api';
import { SchoolTypeInterface } from '../+models';
import { SchoolTypeServiceInterface } from './school-type.service.interface';

@Injectable({
  providedIn: 'root',
})
export class SchoolTypeService implements SchoolTypeServiceInterface {
  constructor(private schoolTypeApi: SchoolTypeApi) {}

  getAll(): Observable<SchoolTypeInterface[]> {
    return this.schoolTypeApi.find();
  }
}
