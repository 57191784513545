import { Injectable } from '@angular/core';
import {
  DalState,
  EduContentTOCInterface,
  EduContentTocLearningPlanGoalQueries,
  EduContentTocQueries,
  LearningPlanGoalProgressActions,
  UserLessonActions,
  UserLessonInterface,
  UserLessonQueries,
  UserQueries,
} from '@campus/dal';
import { findParent } from '@campus/utils';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

export interface MethodLessonInterface {
  eduContentTocId: number;
  values: string[];
}

@Injectable({ providedIn: 'root' })
export class LearningPlanGoalProgressManagementViewModel {
  public userLessons$: Observable<UserLessonInterface[]>;

  constructor(private store: Store<DalState>) {
    this.initialize();
  }

  private initialize() {
    this.setupStreams();
  }

  private setupStreams() {
    this.userLessons$ = this.store.pipe(select(UserLessonQueries.getAll));
  }

  public getMethodLessonsForBook(bookId: number, learningPlanGoalId: number): Observable<MethodLessonInterface[]> {
    const tocsByBookId$ = this.store.pipe(select(EduContentTocQueries.getTocsByBook));

    return tocsByBookId$
      .pipe(
        switchMap((tocsByBookId) => {
          const tocIds = (tocsByBookId[bookId] || []).map((toc) => toc.id);
          return combineLatest([
            of(tocsByBookId[bookId]),
            this.store.pipe(select(EduContentTocLearningPlanGoalQueries.getByIdsForToc, { ids: tocIds })),
          ]);
        })
      )
      .pipe(
        map(([tocs, learningPlanGoalsByToc]) => {
          const chapterTocs = tocs.filter((toc) => toc.depth === 0);
          const tocDict = tocs.reduce((acc, toc) => {
            acc[toc.id] = toc;
            return acc;
          }, {});

          const tocsWithLearningPlanGoals = Object.entries(learningPlanGoalsByToc)
            .filter(([_, value]) => value.includes(learningPlanGoalId))
            .map(([key]) => tocDict[key]);

          const methodLessonsForBook = (tocsWithLearningPlanGoals || []).map((currentToc) => {
            const display = {
              eduContentTocId: currentToc.id,
              values: [currentToc.title],
            };

            let parent = findParent<EduContentTOCInterface>(currentToc, chapterTocs);
            while (parent) {
              display.values = [parent.title, ...display.values];
              parent = findParent<EduContentTOCInterface>(parent, chapterTocs);
            }

            return display;
          });
          return methodLessonsForBook;
        })
      );
  }

  public createLearningPlanGoalProgressForUserLesson(
    learningPlanGoalId: number,
    classGroupId: number,
    description: string,
    eduContentBookId: number
  ): void {
    combineLatest([
      this.store.pipe(select(UserLessonQueries.getAll)),
      this.store.pipe(select(UserQueries.getCurrentUser)),
    ])
      .pipe(take(1))
      .subscribe(([userLessons, user]) => {
        const foundUserLesson = userLessons.find(
          (userLesson) => userLesson.description === description && userLesson.personId === user.id // Don't add to the userLesson of a colleague, create a new one
        );

        if (foundUserLesson) {
          this.store.dispatch(
            new LearningPlanGoalProgressActions.StartAddManyLearningPlanGoalProgresses({
              personId: user.id,
              learningPlanGoalProgresses: [
                {
                  classGroupId,
                  learningPlanGoalId,
                  userLessonId: foundUserLesson.id,
                  eduContentBookId,
                },
              ],
            })
          );
        } else {
          this.store.dispatch(
            new UserLessonActions.CreateUserLessonWithLearningPlanGoalProgresses({
              userId: user.id,
              userLesson: { description },
              learningPlanGoalProgresses: [
                {
                  classGroupId,
                  learningPlanGoalId,
                  eduContentBookId,
                },
              ],
            })
          );
        }
      });
  }

  public createLearningPlanGoalProgressForEduContentTOCs(
    learningPlanGoalId: number,
    classGroupId: number,
    eduContentTOCids: number[],
    eduContentBookId: number
  ): void {
    this.store.pipe(select(UserQueries.getCurrentUser), take(1)).subscribe((user) => {
      this.store.dispatch(
        new LearningPlanGoalProgressActions.StartAddManyLearningPlanGoalProgresses({
          personId: user.id,
          learningPlanGoalProgresses: eduContentTOCids.map((eduContentTOCId) => ({
            classGroupId,
            learningPlanGoalId,
            eduContentTOCId,
            eduContentBookId,
          })),
        })
      );
    });
  }
}
