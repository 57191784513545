import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ProductContentInterface } from '../../+models';
import { ProductsActions, ProductsActionTypes } from '../product/product.actions';
import { ProductContentsActions, ProductContentsActionTypes } from './product-content.actions';

export const NAME = 'productContents';

export interface State extends EntityState<ProductContentInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<ProductContentInterface> = createEntityAdapter<ProductContentInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: ProductContentsActions | ProductsActions): State {
  switch (action.type) {
    case ProductContentsActionTypes.AddProductContent: {
      return adapter.addOne(action.payload.productContent, state);
    }

    case ProductContentsActionTypes.UpsertProductContent: {
      return adapter.upsertOne(action.payload.productContent, state);
    }

    case ProductContentsActionTypes.AddProductContents: {
      return adapter.addMany(action.payload.productContents, state);
    }

    case ProductContentsActionTypes.UpsertProductContents: {
      return adapter.upsertMany(action.payload.productContents, state);
    }

    case ProductContentsActionTypes.UpdateProductContent: {
      return adapter.updateOne(action.payload.productContent, state);
    }

    case ProductContentsActionTypes.UpdateProductContents: {
      return adapter.updateMany(action.payload.productContents, state);
    }

    case ProductContentsActionTypes.DeleteProductContent: {
      return adapter.removeOne(action.payload.id, state);
    }

    case ProductsActionTypes.DeleteLinkedProductContent: {
      return adapter.removeOne(action.payload.productContentId, state);
    }

    case ProductContentsActionTypes.DeleteProductContents: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case ProductContentsActionTypes.ProductContentsLoaded: {
      return adapter.setAll(action.payload.productContents, {
        ...state,
        loaded: true,
      });
    }

    case ProductContentsActionTypes.ProductContentsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case ProductContentsActionTypes.ClearProductContents: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
