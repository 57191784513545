import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CurriculumInterface, EduContentBookInterface } from '../../+models';
import { getById as getBookById } from '../edu-content-book/edu-content-book.selectors';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './curriculum.reducer';

export const selectCurriculumState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectCurriculumState, (state: State) => state.error);

export const getLoaded = createSelector(selectCurriculumState, (state: State) => state.loaded);

export const getAll = createSelector(selectCurriculumState, selectAll);

export const getCount = createSelector(selectCurriculumState, selectTotal);

export const getIds = createSelector(selectCurriculumState, selectIds);

export const getAllEntities = createSelector(selectCurriculumState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * curriculum$: CurriculumInterface[] = this.store.pipe(
    select(CurriculumQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectCurriculumState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * curriculum$: CurriculumInterface = this.store.pipe(
    select(CurriculumQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectCurriculumState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getCurriculumForBookId = createSelector(
  getAll,
  getBookById,
  (curricula: CurriculumInterface[], book: EduContentBookInterface, props: { id: number }) =>
    curricula.find((c) => c.methodId === book.methodId)
);
