import { CurriculumInterface } from './Curriculum.interface';

export interface LoopInterface {
  id?: number;
  code?: string | LoopCodeEnum;
  label?: string;
  curriculumId?: string;
  curriculum?: CurriculumInterface;
  sequence?: number;
}

export enum LoopCodeEnum {
  INTAKE = 'intake',
  MASTERING = 'mastering',
  REHEARSAL = 'rehearsal',
  STARTING = 'starting',
}
