import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class PersonalCodeValidator implements Validator {
  validate(ctrl: AbstractControl): ValidationErrors | null {
    if ([null, undefined, ''].includes(ctrl.value)) return null;

    const text = ctrl.value.toString();

    const noWhiteSpaceRegEx = new RegExp(/^\S*$/);
    const isValid = noWhiteSpaceRegEx.test(text);

    return isValid ? null : { whitespace: true };
  }
}
