import { Action } from '@ngrx/store';
import { EduContentTagInterface } from '../../+models';

export enum EduContentTagsActionTypes {
  EduContentTagsLoaded = '[EduContentTags] EduContentTags Loaded',
  EduContentTagsLoadError = '[EduContentTags] Load Error',
  LoadEduContentTags = '[EduContentTags] Load EduContentTags',
}

export class EduContentTagsLoaded implements Action {
  readonly type = EduContentTagsActionTypes.EduContentTagsLoaded;

  constructor(public payload: { eduContentTags: EduContentTagInterface[] }) {}
}

export class EduContentTagsLoadError implements Action {
  readonly type = EduContentTagsActionTypes.EduContentTagsLoadError;
  constructor(public payload: any) {}
}

export type EduContentTagsActions = EduContentTagsLoaded | EduContentTagsLoadError;
