export class ScormFunctions {
  public static cmiTimeStringToMilliseconds(cmiTimeString: string): number {
    const [hours, minutes, seconds] = cmiTimeString.split(':');
    const timespan =
      parseInt(hours, 10) * 3600000 + parseInt(minutes, 10) * 60000 + Math.round(parseFloat(seconds) * 1000);

    return timespan;
  }

  public static millisecondsToCmiTimeString(milliseconds: number) {
    // see cmi.core.total_time in http://xml.coverpages.org/SCORM-12-RunTimeEnv.pdf

    const msInHour = 60 * 60 * 1000;
    const msInMinutes = 60 * 1000;

    const hours = milliseconds / msInHour;
    const wholeHours = Math.floor(hours);
    milliseconds = (hours % 1) * msInHour;

    const minutes = milliseconds / msInMinutes;
    const wholeMinutes = Math.floor(minutes);
    milliseconds = (minutes % 1) * msInMinutes;

    const seconds = milliseconds / 1000;
    const wholeSeconds = Math.floor(seconds);
    milliseconds = Math.round((seconds % 1) * 100 + Number.EPSILON);

    const timeStringParts = {
      hours: wholeHours.toString().padStart(4, '0'),
      minutes: wholeMinutes.toString().padStart(2, '0'),
      seconds: wholeSeconds.toString().padStart(2, '0'),
      milliseconds: milliseconds.toString().padStart(2, '0'),
    };

    return `${timeStringParts.hours}:${timeStringParts.minutes}:${timeStringParts.seconds}.${timeStringParts.milliseconds}`;
  }
}
