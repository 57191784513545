import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MethodProductGroupProductInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './method-product-group-product.reducer';

export const selectMethodProductGroupProductState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectMethodProductGroupProductState, (state: State) => state.error);

export const getLoaded = createSelector(selectMethodProductGroupProductState, (state: State) => state.loaded);

export const getAll = createSelector(selectMethodProductGroupProductState, selectAll);

export const getCount = createSelector(selectMethodProductGroupProductState, selectTotal);

export const getIds = createSelector(selectMethodProductGroupProductState, selectIds);

export const getAllEntities = createSelector(selectMethodProductGroupProductState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * methodProductGroupProduct$: MethodProductGroupProductInterface[] = this.store.pipe(
    select(MethodProductGroupProductQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(
  selectMethodProductGroupProductState,
  (state: State, props: { ids: number[] }) => {
    return props.ids.map((id) => state.entities[id]);
  }
);

/**
 * returns array of objects in the order of the given ids
 * @example
 * methodProductGroupProduct$: MethodProductGroupProductInterface = this.store.pipe(
    select(MethodProductGroupProductQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectMethodProductGroupProductState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getByProductId = createSelector(
  getAll,
  (methodProductGroupProducts: MethodProductGroupProductInterface[]) =>
    groupArrayByKeys(methodProductGroupProducts, ['productId']) as Dictionary<MethodProductGroupProductInterface[]>
);
