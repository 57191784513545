import { registerLocaleData } from '@angular/common';
import localeBe from '@angular/common/locales/nl-BE';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import {
  MatLegacyTooltipModule as MatTooltipModule,
  MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS,
} from '@angular/material/legacy-tooltip';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@campus/components';
import {
  AuthService,
  AuthServiceInterface,
  DalModule,
  DalState,
  loadAndResolveMethodStyleForBook,
  MethodStyleQueries,
} from '@campus/dal';
import { EnvironmentModule } from '@campus/environment';
import { GuardsModule } from '@campus/guards';
import { SearchModule } from '@campus/search';
import { SharedModule } from '@campus/shared';
import { METHOD_STYLE_TOKEN } from '@campus/theming';
import { UiModule } from '@campus/ui';
import { UtilsModule } from '@campus/utils';
import { Store } from '@ngrx/store';
import player from 'lottie-web';
import { configureBufferSize } from 'ngrx-undo';
import { LottieModule } from 'ngx-lottie';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AppEffectsModule } from './app-effects.module';
import { AppRoutingModule } from './app-routing.module';
import { AppStoreModule } from './app-store.module';
import { AppTokenModule } from './app-token.module';
import { AppComponent } from './app.component';
import {
  EduContentSearchResultComponent,
  TocInfoPipe,
} from './components/edu-content-search-result/edu-content-search-result.component';
import { LoadingComponent } from './components/loading/loading.component';
import { HomePageGuard } from './guard/home-page.guard';
import { TermsPrivacyPopupGuard } from './guard/terms-privacy-popup/terms-privacy-popup.guard';

// if you want to update the buffer (which defaults to 100)
configureBufferSize(150);

registerLocaleData(localeBe, 'nl-BE');

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

const disableAnimations =
  !('animate' in document.documentElement) || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));

@NgModule({
  declarations: [AppComponent, EduContentSearchResultComponent, TocInfoPipe, LoadingComponent],
  imports: [
    environment.envModules || [],
    UiModule,
    SearchModule,
    UtilsModule,
    MatTooltipModule,
    BrowserModule,
    MatDialogModule,
    SharedModule,
    EnvironmentModule.forRoot({
      environmentAlertsFeature: environment.features.alerts,
      environmentMessagesFeature: environment.features.messages,
      environmentErrorManagementFeature: environment.features.errorManagement,
      environmentGlobalSearch: environment.features.globalSearch,
      environmentCollectionManagementFeature: environment.features.collectionManagement,
      environmentIconMapping: environment.iconMapping,
      environmentTextMapping: environment.textMapping,
      environmentWebsite: environment.website,
      environmentLogout: environment.afterLogout,
      environmentLogin: environment.login,
      environmentProfile: environment.profile,
      environmentTermPrivacy: environment.termPrivacy,
      environmentApi: environment.api,
      environmentSsoSettings: environment.sso,
      environmentTesting: environment.testing,
      environmentUi: environment.ui,
      environmentAssets: environment.assets,
      environmentFavoritesFeature: environment.features.favorites,
      environmentHistoryFeature: environment.features.history,
      environmentSearchModes: environment.searchModes,
      environmentPlatform: environment.platform,
      environmentToursFeature: environment.features.tours,
      environmentSvgMapping: environment.svgMapping,
      environmentGroupsFeature: environment.features.groups,
      environmentScoreMapping: environment.scoreMapping,
      environmentTime: environment.time,
      environmentMethodsFeature: environment.features.methods,
      environmentNewsFeature: environment.features.news,
      environmentFreshworksFeature: environment.features.freshworks,
      environmentGoogleAnalytics: environment.googleAnalytics,
      environmentTaskFeature: environment.taskFeature,
    }),
    BrowserAnimationsModule.withConfig({ disableAnimations }),
    DalModule.forRoot({ apiBaseUrl: environment.api.APIBase }),
    GuardsModule,
    AppRoutingModule,
    AppTokenModule,
    AppEffectsModule,
    AppStoreModule,
    LottieModule.forRoot({ player: playerFactory }),
    ComponentsModule,
  ],
  providers: [
    Title,
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthServiceInterface) => () => authService.getCurrent().toPromise(),
      multi: true,
      deps: [AuthService],
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'nl-BE' },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { position: 'above' } },
    HomePageGuard,
    TermsPrivacyPopupGuard,
    {
      provide: METHOD_STYLE_TOKEN,
      useFactory: (store: Store<DalState>) => (bookId: number) => {
        return of({ bookId }).pipe(
          loadAndResolveMethodStyleForBook(bookId)(store, undefined),
          switchMap(() => store.select(MethodStyleQueries.getByEduContentBookId(bookId)))
        );
      },
      deps: [Store],
    },
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule],
})
export class AppModule {}
