<campus-icon *ngIf="icon" [svgIcon]="icon" class="fab-button-icon icon-current" [class]="iconClass"></campus-icon>
<span *ngIf="label" class="[ label-large ][ on-primary-container ]">{{ label }}</span>
<campus-state-layer [for]="element" class="bg-on-primary-container"></campus-state-layer>
<campus-focus-ring [for]="element"></campus-focus-ring>

<campus-tooltip
  *ngIf="plainTooltip"
  [plainTooltip]="plainTooltip"
  [tooltipDelay]="250"
  [anchor]="element"
  class="[ visually-hidden ]"
>
</campus-tooltip>
