import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EvaluationScoreListInterface } from '../../+models';
import { EvaluationScoreListActions, EvaluationScoreListActionTypes } from './evaluation-score-list.actions';

export const NAME = 'evaluationScoreLists';

export interface State extends EntityState<EvaluationScoreListInterface> {
  // additional entities state properties
  loaded: boolean;
  loadedForBook: {
    [bookId: number]: boolean;
  };
  loadedForTask: {
    [taskId: number]: boolean;
  };
  error?: any;
}

export const adapter: EntityAdapter<EvaluationScoreListInterface> = createEntityAdapter<EvaluationScoreListInterface>({
  // sortComparer: (a, b) => a.displayOrder - b.displayOrder || a.id - b.id,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForBook: {},
  loadedForTask: {},
});

export function reducer(state = initialState, action: EvaluationScoreListActions): State {
  switch (action.type) {
    case EvaluationScoreListActionTypes.AddEvaluationScoreList: {
      return adapter.addOne(action.payload.evaluationScoreList, state);
    }

    case EvaluationScoreListActionTypes.EvaluationScoreListsLoaded: {
      return adapter.setAll(action.payload.evaluationScoreLists, { ...state, loaded: true });
    }

    case EvaluationScoreListActionTypes.EvaluationScoreListsLoadError: {
      return { ...state, error: action.payload, loadedForBook: {}, loadedForTask: {} };
    }

    case EvaluationScoreListActionTypes.AddEvaluationScoreListsForTask: {
      const { evaluationScoreLists, taskId } = action.payload;
      return adapter.upsertMany(evaluationScoreLists, {
        ...state,
        loadedForTask: { ...state.loadedForTask, [taskId]: true },
      });
    }

    case EvaluationScoreListActionTypes.UpdateEvaluationScoreList: {
      return adapter.updateOne(action.payload.evaluationScoreList, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
