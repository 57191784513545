import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TaskEduContentTaskGoalYearLevelInterface } from '../../+models';
import {
  TaskEduContentTaskGoalYearLevelsActions,
  TaskEduContentTaskGoalYearLevelsActionTypes,
} from './task-edu-content-task-goal-year-level.actions';

export const NAME = 'taskEduContentTaskGoalYearLevels';

export interface State extends EntityState<TaskEduContentTaskGoalYearLevelInterface> {
  // additional entities state properties
  loaded: boolean;
  loadedForTask: {
    [taskId: number]: boolean;
  };
  saving: boolean;
  error?: any;
}

export const adapter: EntityAdapter<TaskEduContentTaskGoalYearLevelInterface> =
  createEntityAdapter<TaskEduContentTaskGoalYearLevelInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForTask: {},
  saving: false,
});

export function reducer(state = initialState, action: TaskEduContentTaskGoalYearLevelsActions): State {
  switch (action.type) {
    case TaskEduContentTaskGoalYearLevelsActionTypes.AddTaskEduContentTaskGoalYearLevel: {
      return adapter.addOne(action.payload.taskEduContentTaskGoalYearLevel, state);
    }

    case TaskEduContentTaskGoalYearLevelsActionTypes.UpsertTaskEduContentTaskGoalYearLevel: {
      return adapter.upsertOne(action.payload.taskEduContentTaskGoalYearLevel, state);
    }

    case TaskEduContentTaskGoalYearLevelsActionTypes.AddTaskEduContentTaskGoalYearLevelsForTask: {
      const newState = adapter.addMany(action.payload.taskEduContentTaskGoalYearLevels, state);

      return {
        ...newState,
        loadedForTask: {
          ...newState.loadedForTask,
          [action.payload.taskId]: true,
        },
      };
    }
    case TaskEduContentTaskGoalYearLevelsActionTypes.StartSetTaskEduContentTaskGoalYearLevelsForTask: {
      return { ...state, saving: true };
    }

    case TaskEduContentTaskGoalYearLevelsActionTypes.SetTaskEduContentTaskGoalYearLevelsForTask: {
      const toRemoveMatchingTaskEduContentId = Object.values(state.entities)
        .filter((entity) => action.payload.taskEduContentIds.includes(entity.taskEduContentId))
        .map((e) => e.id);

      const clearedState = adapter.removeMany(
        [...toRemoveMatchingTaskEduContentId, ...action.payload.removedTaskEduContentTaskGoalYearLevelIds],
        state
      );
      const newState = adapter.addMany(action.payload.taskEduContentTaskGoalYearLevels, clearedState);

      return {
        ...newState,
        loadedForTask: {
          ...state.loadedForTask,
          [action.payload.taskId]: true,
        },
        saving: false,
      };
    }

    case TaskEduContentTaskGoalYearLevelsActionTypes.AddTaskEduContentTaskGoalYearLevels: {
      return adapter.addMany(action.payload.taskEduContentTaskGoalYearLevels, state);
    }

    case TaskEduContentTaskGoalYearLevelsActionTypes.UpsertTaskEduContentTaskGoalYearLevels: {
      return adapter.upsertMany(action.payload.taskEduContentTaskGoalYearLevels, state);
    }

    case TaskEduContentTaskGoalYearLevelsActionTypes.UpdateTaskEduContentTaskGoalYearLevel: {
      return adapter.updateOne(action.payload.taskEduContentTaskGoalYearLevel, state);
    }

    case TaskEduContentTaskGoalYearLevelsActionTypes.UpdateTaskEduContentTaskGoalYearLevels: {
      return adapter.updateMany(action.payload.taskEduContentTaskGoalYearLevels, state);
    }

    case TaskEduContentTaskGoalYearLevelsActionTypes.DeleteTaskEduContentTaskGoalYearLevel: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TaskEduContentTaskGoalYearLevelsActionTypes.DeleteTaskEduContentTaskGoalYearLevels: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TaskEduContentTaskGoalYearLevelsActionTypes.TaskEduContentTaskGoalYearLevelsLoaded: {
      return adapter.setAll(action.payload.taskEduContentTaskGoalYearLevels, { ...state, loaded: true });
    }

    case TaskEduContentTaskGoalYearLevelsActionTypes.TaskEduContentTaskGoalYearLevelsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case TaskEduContentTaskGoalYearLevelsActionTypes.ClearTaskEduContentTaskGoalYearLevels: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
