import { NgModule } from '@angular/core';
import {
  AlertsEffects,
  AlgebrakitSessionEffects,
  BookDataEffects,
  BundleGroupEffects,
  BundlePersonEffects,
  BundlesEffects,
  ClassGroupEffects,
  ContentStatusesEffects,
  CredentialEffects,
  CurrentExerciseEffects,
  DataEffects,
  DiaboloPhaseEffects,
  DidacticFunctionEffects,
  EduContentBookEffects,
  EduContentProductTypeEffects,
  EduContentsEffects,
  EduContentTocEduContentEffects,
  EduContentTocEffects,
  EduContentTocEvaluationEffects,
  EduNetEffects,
  EffectFeedbackEffects,
  EvaluationEffects,
  EvaluationScoreEffects,
  EvaluationScoreListEffects,
  EvaluationScoreListSubjectEffects,
  EvaluationSubjectEffects,
  EvaluationSubjectGoalEffects,
  FavoriteEffects,
  GoalEffects,
  GoalTopicEffects,
  GoalYearEffects,
  GroupEffects,
  GroupPersonEffects,
  HistoryEffects,
  LearnerProfileConceptEffects,
  LearningAreasEffects,
  LearningDomainEffects,
  LearningPlanGoalProgressEffects,
  LearningRecordEffects,
  LevelEffects,
  LicenseEffects,
  LinkedPersonEffects,
  MethodEffects,
  MethodLevelEffects,
  MethodProductGroupEffects,
  MethodProductGroupProductEffects,
  MethodStyleEffects,
  PersonPreferenceEffects,
  PersonProductEffects,
  ProductEffects,
  PurchaseEffects,
  PurchaseProductEffects,
  ResultEffects,
  RoleEffects,
  SchoolEffects,
  SchoolProviderEffects,
  SchoolRoleMappingClassGroupEffects,
  SchoolRoleMappingEffects,
  SchoolTypeEffects,
  SectionContentEffects,
  SectionEffects,
  StudentContentStatusesEffects,
  TaskClassGroupEffects,
  TaskDataEffects,
  TaskEduContentEffects,
  TaskEffects,
  TaskEvaluationSubjectEffects,
  TaskEvaluationSubjectScoreEffects,
  TaskGoalYearLevelEffects,
  TaskGroupEffects,
  TaskInstanceEffects,
  TaskLevelSettingEffects,
  TaskStudentEffects,
  TeacherStudentEffects,
  TocDataEffects,
  UiEffects,
  UnlockedBoekeGroupsEffects,
  UnlockedBoekeStudentsEffects,
  UnlockedContentsEffects,
  UnlockedFreePracticeEffects,
  UserContentsEffects,
  UserEffects,
  UserLessonEffects,
  YearEffects,
} from '@campus/dal';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([
      EffectFeedbackEffects,
      BundlesEffects,
      BundleGroupEffects,
      BundlePersonEffects,
      UserEffects,
      EduContentsEffects,
      UiEffects,
      LearningAreasEffects,
      MethodEffects,
      UserContentsEffects,
      StudentContentStatusesEffects,
      UnlockedBoekeGroupsEffects,
      UnlockedContentsEffects,
      UnlockedBoekeStudentsEffects,
      ContentStatusesEffects,
      TaskEffects,
      TaskInstanceEffects,
      EduContentTocEduContentEffects,
      AlertsEffects,
      TaskEduContentEffects,
      ResultEffects,
      LearningDomainEffects,
      CurrentExerciseEffects,
      TeacherStudentEffects,
      LinkedPersonEffects,
      CredentialEffects,
      FavoriteEffects,
      HistoryEffects,
      EduContentProductTypeEffects,
      EduNetEffects,
      SchoolTypeEffects,
      YearEffects,
      DataEffects,
      EduContentBookEffects,
      EduContentTocEffects,
      BookDataEffects,
      DidacticFunctionEffects,
      DiaboloPhaseEffects,
      LearningPlanGoalProgressEffects,
      UserLessonEffects,
      PersonPreferenceEffects,
      SchoolEffects,
      SchoolRoleMappingEffects,
      GroupEffects,
      GroupPersonEffects,
      SchoolRoleMappingClassGroupEffects,
      ClassGroupEffects,
      RoleEffects,
      SchoolEffects,
      ProductEffects,
      PersonProductEffects,
      LevelEffects,
      TaskClassGroupEffects,
      TaskGroupEffects,
      TaskStudentEffects,
      TaskLevelSettingEffects,
      EvaluationSubjectEffects,
      TaskEvaluationSubjectEffects,
      TaskEvaluationSubjectScoreEffects,
      TaskGoalYearLevelEffects,
      GoalEffects,
      GoalTopicEffects,
      GoalYearEffects,
      MethodLevelEffects,
      TaskDataEffects,
      LicenseEffects,
      UnlockedFreePracticeEffects,
      AlgebrakitSessionEffects,
      LearnerProfileConceptEffects,
      SectionEffects,
      SectionContentEffects,
      SchoolProviderEffects,
      PurchaseEffects,
      PurchaseProductEffects,
      MethodProductGroupEffects,
      MethodProductGroupProductEffects,
      EduContentTocEvaluationEffects,
      EvaluationEffects,
      EvaluationSubjectGoalEffects,
      EvaluationScoreListEffects,
      EvaluationScoreEffects,
      EvaluationScoreListSubjectEffects,
      MethodStyleEffects,
      TocDataEffects,
      LearningRecordEffects,
    ]),
  ],
})
export class AppEffectsModule {}
