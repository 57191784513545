import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch, optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { MethodProductGroupQueries } from '.';
import { MethodProductGroupServiceInterface, METHOD_PRODUCT_GROUP_SERVICE_TOKEN } from '../../method-product-group';
import { DalState } from '../dal.state.interface';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import {
  AddMethodProductGroup,
  DeleteMethodProductGroup,
  LoadMethodProductGroups,
  MethodProductGroupsActionTypes,
  MethodProductGroupsLoaded,
  MethodProductGroupsLoadError,
  StartAddMethodProductGroup,
  UpdateMethodProductGroup,
} from './method-product-group.actions';

@Injectable()
export class MethodProductGroupEffects {
  loadMethodProductGroups$ = createEffect(() =>
    this.actions.pipe(
      ofType(MethodProductGroupsActionTypes.LoadMethodProductGroups),
      concatLatestFrom(() => this.store.select(MethodProductGroupQueries.getLoaded)),
      fetch({
        run: (action: LoadMethodProductGroups, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.methodProductGroupService.getAllForUser(action.payload.userId).pipe(
            map(
              (methodProductGroups) =>
                new MethodProductGroupsLoaded({
                  methodProductGroups,
                })
            )
          );
        },
        onError: (action: LoadMethodProductGroups, error) => {
          return new MethodProductGroupsLoadError(error);
        },
      })
    )
  );

  startAddMethodProductGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(MethodProductGroupsActionTypes.StartAddMethodProductGroup),
      pessimisticUpdate({
        run: (action: StartAddMethodProductGroup) => {
          return this.methodProductGroupService
            .createMethodProductGroup(action.payload.methodProductGroup)
            .pipe(map((methodProductGroup) => new AddMethodProductGroup({ methodProductGroup })));
        },
        onError: (action: StartAddMethodProductGroup, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de productgroep toe te voegen.'
            ),
          });
        },
      })
    )
  );

  updateMethodProductGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(MethodProductGroupsActionTypes.UpdateMethodProductGroup),
      optimisticUpdate({
        run: (action: UpdateMethodProductGroup) => {
          const { id, changes } = action.payload.methodProductGroup;
          return this.methodProductGroupService.updateMethodProductGroup(+id, changes).pipe(
            map(() => {
              return new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'De productgroep werd bijgewerkt.'
                ),
              });
            })
          );
        },
        undoAction: (action: UpdateMethodProductGroup, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de productgroep bij te werken.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  removeMethodProductGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(MethodProductGroupsActionTypes.DeleteMethodProductGroup),
      optimisticUpdate({
        run: (action: DeleteMethodProductGroup) => {
          return this.methodProductGroupService.deleteMethodProductGroup(action.payload.id).pipe(
            map(() => {
              return new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'De productgroep werd verwijderd.'
                ),
              });
            })
          );
        },
        undoAction: (action: DeleteMethodProductGroup, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de productgroep te verwijderen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  constructor(
    @Inject('uuid')
    private uuid,
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(METHOD_PRODUCT_GROUP_SERVICE_TOKEN)
    private methodProductGroupService: MethodProductGroupServiceInterface
  ) {}
}
