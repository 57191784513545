import { inject, Injectable, ProviderToken } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LearningEventVerbEnum, LEARNING_RECORD_SERVICE_TOKEN } from '../../learning-record';
import { AUTH_SERVICE_TOKEN } from '../../persons/auth-service.interface';
import { CurrentExerciseActions } from '../current-exercise';
import { DalState } from '../dal.state.interface';
import { LoadData } from '../data/person/person-data.actions';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { EffectFeedback } from '../effect-feedback/effect-feedback.model';
import { LearningRecordActionTypes, SaveLearningRecord } from './learning-record.actions';

@Injectable()
export class LearningRecordEffects {
  uuid = inject('uuid' as unknown as ProviderToken<() => string>);
  actions = inject(Actions);
  store: Store<DalState> = inject(Store);
  learningRecordService = inject(LEARNING_RECORD_SERVICE_TOKEN);
  authService = inject(AUTH_SERVICE_TOKEN);

  constructor() {}

  saveLearningRecord$ = createEffect(() =>
    this.actions.pipe(
      ofType(LearningRecordActionTypes.SaveLearningRecord),
      fetch({
        run: (action: SaveLearningRecord) => {
          const { learningRecord } = action.payload;
          const isCompleted = learningRecord.verb === LearningEventVerbEnum.COMPLETED; // used to update game coins via schoolRoleMappings
          const isFinalUpdate =
            isCompleted ||
            (learningRecord.verb === LearningEventVerbEnum.ATTEMPTED && learningRecord.duration !== undefined);

          return this.learningRecordService.saveLearningRecord(learningRecord).pipe(
            switchMap(() =>
              from([
                ...(isCompleted
                  ? [
                      new LoadData({
                        userId: this.authService.userId,
                        force: true,
                        fields: ['schoolRoleMappings'],
                      }),
                    ]
                  : []),
                new CurrentExerciseActions.SetReadyForNextRecommendation({ readyForNextRecommendation: isFinalUpdate }),
              ])
            )
          );
        },
        onError: (action: SaveLearningRecord, error) => {
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            'Het is niet gelukt om het leerverslag bij te werken.'
          );
          return new AddEffectFeedback({ effectFeedback });
        },
      })
    )
  );
}
