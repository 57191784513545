import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { SchoolInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum SchoolsActionTypes {
  SchoolsLoaded = '[Schools] Schools Loaded',
  SchoolsLoadError = '[Schools] Load Error',
  LoadSchools = '[Schools] Load Schools',
  StartAddSchool = '[Schools] Start Add School',
  StartAddAndLinkSchool = '[Schools] Start Add and Link School',
  AddSchool = '[Schools] Add School',
  AddSchools = '[Schools] Add Schools',
  StartDeleteSchool = '[Schools] Start Delete School',
  DeleteSchool = '[Schools] Delete School',
  NavigateToSchoolDetail = '[Schools] Navigate To School Detail',
  NavigateToSchoolOverview = '[Schools] Navigate To School Overview',
  UpdateSchool = '[Schools] Update School',
  UnlinkTeacher = '[Schools] Unlink Teacher',
  LinkSchoolsToTeacher = '[Schools] Link Schools To Teacher',
  UpdateTransition = '[Schools] Update Transition by Type',
  UnlinkStudentsInSchool = '[Schools] Unlink All Students In School',
}

export class LoadSchools implements Action {
  readonly type = SchoolsActionTypes.LoadSchools;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class SchoolsLoaded implements Action {
  readonly type = SchoolsActionTypes.SchoolsLoaded;

  constructor(public payload: { schools: SchoolInterface[] }) {}
}

export class SchoolsLoadError implements Action {
  readonly type = SchoolsActionTypes.SchoolsLoadError;
  constructor(public payload: any) {}
}

export class AddSchool implements Action {
  readonly type = SchoolsActionTypes.AddSchool;

  constructor(public payload: { school: SchoolInterface }) {}
}

export class AddSchools implements Action {
  readonly type = SchoolsActionTypes.AddSchools;

  constructor(public payload: { schools: SchoolInterface[] }) {}
}

export class DeleteSchool implements Action {
  readonly type = SchoolsActionTypes.DeleteSchool;

  constructor(public payload: { id: number }) {}
}

export class StartAddSchool implements FeedbackTriggeringAction {
  readonly type = SchoolsActionTypes.StartAddSchool;

  constructor(
    public payload: {
      school: SchoolInterface;
      navigateAfterCreate?: boolean;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartAddAndLinkSchool implements FeedbackTriggeringAction {
  readonly type = SchoolsActionTypes.StartAddAndLinkSchool;

  constructor(
    public payload: {
      personId: number;
      school: SchoolInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartDeleteSchool implements FeedbackTriggeringAction {
  readonly type = SchoolsActionTypes.StartDeleteSchool;

  constructor(
    public payload: {
      schoolId: number;
      navigateAfterDelete?: boolean;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class NavigateToSchoolDetail implements Action {
  readonly type = SchoolsActionTypes.NavigateToSchoolDetail;

  constructor(
    public payload: {
      school: SchoolInterface;
    }
  ) {}
}

export class NavigateToSchoolOverview implements Action {
  readonly type = SchoolsActionTypes.NavigateToSchoolOverview;

  constructor() {}
}

export class UpdateSchool implements FeedbackTriggeringAction {
  readonly type = SchoolsActionTypes.UpdateSchool;

  constructor(
    public payload: {
      school: Update<SchoolInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UnlinkTeacher implements FeedbackTriggeringAction {
  readonly type = SchoolsActionTypes.UnlinkTeacher;

  constructor(
    public payload: {
      personId: number;
      schoolRoleMappingId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class LinkSchoolsToTeacher implements FeedbackTriggeringAction {
  readonly type = SchoolsActionTypes.LinkSchoolsToTeacher;

  constructor(
    public payload: {
      personId: number;
      schools: SchoolInterface[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpdateTransition implements FeedbackTriggeringAction {
  readonly type = SchoolsActionTypes.UpdateTransition;

  constructor(
    public payload: {
      schoolId: number;
      type: 'students' | 'licenses';
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UnlinkStudentsInSchool implements FeedbackTriggeringAction {
  readonly type = SchoolsActionTypes.UnlinkStudentsInSchool;

  constructor(
    public payload: {
      schoolId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export type SchoolsActions =
  | LoadSchools
  | SchoolsLoaded
  | SchoolsLoadError
  | StartAddSchool
  | StartAddAndLinkSchool
  | AddSchool
  | AddSchools
  | NavigateToSchoolDetail
  | NavigateToSchoolOverview
  | StartDeleteSchool
  | DeleteSchool
  | UpdateSchool
  | UnlinkTeacher
  | LinkSchoolsToTeacher
  | UpdateTransition
  | UnlinkStudentsInSchool;
