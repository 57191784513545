<div class="[ flex gap-m w-full ]">
  <div class="[ max-w-s min-w-2xs w-1/2 max-h-full overflow-x-auto ] [ border-ie ]">
    <campus-toc-select
      [parentCollapseOnly]="true"
      [toc]="filteredTocTree$ | async"
      [selectedTocId]="selectedEduContentTocId"
      (clickToc)="selectTOC($event)"
    >
    </campus-toc-select>
  </div>
  <div class="[ grow ] [ p-m ]">
    <ng-container *ngIf="currentGroupedEvaluationSubjects$ | async as currentGroupedEvaluationSubjects">
      <div
        *ngIf="showGoalWarning$ | async"
        class="[ flex items-center ][ p-2xs m-be-m ][ border border-warn corner-s ][ body-small text-warn ]"
      >
        <div class="[ shrink-0 ][ p-ie-2xs ]"><campus-icon svgIcon="warning"></campus-icon></div>
        <div>
          Voor deze onderwerpen hebben we geen digitaal materiaal.
          <ng-container *ngIf="!readOnly"
            ><br />Vink het onderwerp af indien je dit onderwerp in de volgende stap niet wenst te zien.</ng-container
          >
        </div>
      </div>

      <div *ngFor="let evaluationSubjectGroup of currentGroupedEvaluationSubjects; trackBy: trackById">
        <div class="[ stack gap-3xs ] [ m-be-l overflow-visible ]" data-cy="evaluation-subject-group">
          <div>
            <mat-checkbox
              [checked]="selectedHeaders[evaluationSubjectGroup.id]?.checked"
              [indeterminate]="selectedHeaders[evaluationSubjectGroup.id]?.partiallyChecked"
              (change)="onGroupSelectionChange($event, evaluationSubjectGroup)"
              [disabled]="readOnly"
              data-cy="checkbox-evaluation-subject-group"
            >
              <span class="[ title-small ]" data-cy="evaluation-subject-group-title">{{
                evaluationSubjectGroup.name
              }}</span>
            </mat-checkbox>
          </div>
          <div
            class="[ cluster ]"
            *ngFor="let evaluationSubject of evaluationSubjectGroup.evaluationSubjects; trackBy: trackById"
          >
            <div class="[ border corner-s ][ flex flex-nowrap justify-between ]" data-cy="evaluation-subject">
              <mat-checkbox
                [checked]="!!selectedEvaluationSubjects[evaluationSubject.id]"
                (change)="onItemSelectionChange($event, evaluationSubject.id, evaluationSubjectGroup)"
                [disabled]="readOnly"
                data-cy="checkbox-evaluation-subject"
              >
                <span class="[ flex items-center ][ white-space-normal ]" data-cy="evaluation-subject-title">
                  {{ evaluationSubject.displayOrder }}. {{ evaluationSubject.description }}
                </span>
              </mat-checkbox>
              <campus-icon
                *ngIf="evaluationSubject.goalIds.length === 0"
                class="[ shrink-0 ][ text-warn ][ m-is-2xs ]"
                svgIcon="warning"
                data-cy="evaluation-subject-warning"
              ></campus-icon>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
