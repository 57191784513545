import { InjectionToken } from '@angular/core';
import { ContentInterface, EduContent } from '@campus/dal';
import { Observable } from 'rxjs';

export const EDU_CONTENT_COLLECTION_MANAGER_SERVICE_TOKEN = new InjectionToken('EduContentCollectionManagerService');

export interface EduContentCollectionManagerServiceInterface {
  manageBundlesForContent(content: ContentInterface | ContentInterface[], learningAreaId?: number): Observable<boolean>;

  manageTasksForContent(content: EduContent | EduContent[]): Observable<boolean>;
}
