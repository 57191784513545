import { Action } from '@ngrx/store';
import { LearningPlanGoalInterface } from '../../+models';

export enum LearningPlanGoalsActionTypes {
  AddLearningPlanGoalsForBook = '[LearningPlanGoals] Add LearningPlanGoals for Book',
  ClearLearningPlanGoals = '[LearningPlanGoals] Clear LearningPlanGoals',
}

export class AddLearningPlanGoalsForBook implements Action {
  readonly type = LearningPlanGoalsActionTypes.AddLearningPlanGoalsForBook;

  constructor(
    public payload: {
      bookId: number;
      learningPlanGoals: LearningPlanGoalInterface[];
    }
  ) {}
}

export class ClearLearningPlanGoals implements Action {
  readonly type = LearningPlanGoalsActionTypes.ClearLearningPlanGoals;
}

export type LearningPlanGoalsActions = AddLearningPlanGoalsForBook | ClearLearningPlanGoals;
