import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { TableApiRequestInterface } from '../+external-interfaces';
import { GoalEvaluationsRowInterface, GoalInterface, GoalStrandsRowInterface } from '../+models';

export const GOAL_SERVICE_TOKEN = new InjectionToken('GoalService');

export interface GoalRootInterface {
  id?: number;
  name?: string;
  description?: string;
  displayOrder?: number;
  exportToCurriculum?: boolean;
  guid?: string;
  lastUpdated: Date;
  deleted?: boolean;
  goalTopicId?: number;
  years: number[];
  prerequisites: number[];
  postrequisites: number[];
  goalGroups: number[];
  prerequisitesCount: number;
  postrequisitesCount: number;
}

export interface UpdateGoalRelationChangesInterface {
  key: string;
  add?: (number | object)[];
  remove?: (number | object)[];
  update?: object[];
}

export interface GoalsWithLearningPlansByLearningDomainResponseInterface {
  id: number;
  name: string;
  [key: string]: string | number;
}

export interface CreateGoalParamsInterface {
  // Note: The learningDomain is set through the goalTopic, so passing it here is not supported and pointless.
  // learningDomainId: number | null;
  goalTopicId?: number | null;
  eduContentTOCs?: number[] | null;
}

export interface GoalServiceInterface {
  createGoal(groupId: number | null, params?: CreateGoalParamsInterface): Observable<number>;
  getGoalsForLearningArea(leraningAreaId: number): Observable<GoalInterface[]>;
  removeGoal(goalId: number): Observable<boolean>;
  upsertGoal(goal: GoalInterface): Observable<GoalInterface>;
  updateDisplayOrders(goals: Pick<GoalInterface, 'id' | 'displayOrder'>[]): Observable<GoalInterface[]>;
  getGoals(
    searchTerm?: string,
    filters?: { [key: string]: string | number[] | number },
    pagination?: { from?; amount? },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<{ results: GoalRootInterface[]; count: number }>;
  getRelevantGoalsForGoal(
    goalId: number,
    searchTerm?: string,
    filters?: { [key: string]: string | number[] | number },
    pagination?: { from?; amount? },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<{ results: object[]; count: number }>;
  getGoal(goalId: number): Observable<GoalInterface>;
  patchGoal(goalId: number, changes?: Partial<GoalInterface>): Observable<number>;
  updateGoalRelation(goalId: number, body: UpdateGoalRelationChangesInterface): Observable<number>;
  deleteGoal(goalId: number): Observable<number>;
  getGoalsWithLearningPlansByLearningDomain(
    searchTerm?: string,
    filters?: { [key: string]: string | number[] | number },
    pagination?: { from?; amount? },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<{
    results: GoalsWithLearningPlansByLearningDomainResponseInterface[];
    count: number;
  }>;
  updateMultiple(goals: Partial<GoalInterface>[]): Observable<number[]>;
  getGoalStrands$(
    goalGroupId: number,
    options: TableApiRequestInterface
  ): Observable<{
    results: GoalStrandsRowInterface[];
    count: number;
  }>;

  getGoalEvaluations$(
    goalGroupId: number,
    options: TableApiRequestInterface
  ): Observable<{
    results: GoalEvaluationsRowInterface[];
    count: number;
  }>;
}
