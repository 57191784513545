import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { LearningDomainQueries } from '.';
import { LearningDomainServiceInterface, LEARNING_DOMAIN_SERVICE_TOKEN } from '../../learning-domain';
import { DalState } from '../dal.state.interface';
import {
  LearningDomainsActionTypes,
  LearningDomainsLoaded,
  LearningDomainsLoadError,
  LoadLearningDomains,
} from './learning-domain.actions';

@Injectable()
export class LearningDomainEffects {
  loadLearningDomains$ = createEffect(() =>
    this.actions.pipe(
      ofType(LearningDomainsActionTypes.LoadLearningDomains),
      concatLatestFrom(() => this.store.select(LearningDomainQueries.getLoaded)),
      fetch({
        run: (action: LoadLearningDomains, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.learningDomainService
            .getAll()
            .pipe(map((learningDomains) => new LearningDomainsLoaded({ learningDomains })));
        },
        onError: (action: LoadLearningDomains, error) => {
          return new LearningDomainsLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(LEARNING_DOMAIN_SERVICE_TOKEN)
    private learningDomainService: LearningDomainServiceInterface
  ) {}
}
