import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { Action, Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  DataServiceInterface,
  DATA_SERVICE_TOKEN,
  TocDataResponseInterface,
} from '../../../data/data.service.interface';
import { DalState } from '../../dal.state.interface';

import { DATA_FIELD_MAPPERS_TOKEN, FieldMappersInterface, formatData } from '../mappers/data-field-mappers';
import { DataFieldActionFactory } from './toc-data-field-action.factory';
import { LoadTocData, TocDataActionTypes, TocDataLoaded, TocDataLoadError } from './toc-data.actions';

@Injectable()
export class TocDataEffects {
  loadTocData$ = createEffect(() =>
    this.actions.pipe(
      ofType(TocDataActionTypes.LoadTocData),
      concatLatestFrom(() => this.store),
      fetch({
        run: (action: LoadTocData, state) => {
          const { tocId } = action.payload;
          let { fields } = action.payload;

          // Filter out what isn't yet loaded or doesn't exist in the state
          if (!action.payload.force) {
            fields = fields.filter((field) => {
              const stateName = this.getStateName(field, state);
              const affectedState = state[stateName];

              if (!affectedState) return false;

              const loadedFlag = this.getLoadedFlag(field);

              const checkLoaded = (_state: DalState, _tocId: number): boolean => !_state[loadedFlag]?.[_tocId];
              return checkLoaded(affectedState, tocId);
            });
          }

          return this.dataService
            .getAllForToc(tocId, fields)
            .pipe(
              switchMap((dataForToc) =>
                from([...this.getLoadActions(tocId, dataForToc).filter(Boolean), new TocDataLoaded({ tocId })])
              )
            );
        },
        onError: (action: LoadTocData, error) => {
          return new TocDataLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    @Inject(DATA_SERVICE_TOKEN)
    private dataService: DataServiceInterface,
    @Inject(DATA_FIELD_MAPPERS_TOKEN)
    private fieldMappers: FieldMappersInterface,
    private store: Store<DalState>
  ) {}

  private getStateName(field: keyof TocDataResponseInterface, state: DalState): string {
    const stateNameExceptions: Dictionary<string> = {
      sectionEduContents: 'eduContents',
    };

    const stateName = stateNameExceptions[field] || field;

    if (!state[stateName]) {
      throw new Error(`loading unknown state: ${stateName}`);
    }

    return stateName;
  }

  private getLoadedFlag(field: keyof TocDataResponseInterface): string {
    const loadedFlagExceptions: Dictionary<string> = { sectionEduContents: 'loadedForSectionsForToc' };

    const loadedFlag = loadedFlagExceptions[field] || 'loadedForToc';

    return loadedFlag;
  }

  private getLoadActions(tocId: number, data: TocDataResponseInterface): Action[] {
    return this.getActions(DataFieldActionFactory.getLoadedAction)(tocId, data);
  }

  private getUpsertActions(tocId: number, data: TocDataResponseInterface): Action[] {
    return this.getActions(DataFieldActionFactory.getUpsertAction)(tocId, data);
  }

  private getActions(
    getActionFn: (tocId: number, field: string, formattedData: any, data?: TocDataResponseInterface) => Action
  ) {
    return (tocId: number, data: TocDataResponseInterface) =>
      Object.keys(data)
        .filter((field) => data[field] !== undefined)
        .map((field: keyof TocDataResponseInterface) => {
          const formattedData = formatData(field, data, this.fieldMappers);
          return getActionFn(tocId, field, formattedData, data);
        });
  }
}
