import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  NAME,
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
  State,
} from './task-evaluation-subject-score.reducer';

export const selectTaskEvaluationSubjectScoreState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectTaskEvaluationSubjectScoreState, (state: State) => state.error);

export const getLoaded = createSelector(selectTaskEvaluationSubjectScoreState, (state: State) => state.loaded);

export const getAll = createSelector(selectTaskEvaluationSubjectScoreState, selectAll);

export const getCount = createSelector(selectTaskEvaluationSubjectScoreState, selectTotal);

export const getIds = createSelector(selectTaskEvaluationSubjectScoreState, selectIds);

export const getAllEntities = createSelector(selectTaskEvaluationSubjectScoreState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * taskEvaluationSubjectScore$: TaskEvaluationSubjectScoreInterface[] = this.store.pipe(
    select(TaskEvaluationSubjectScoreQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(
  selectTaskEvaluationSubjectScoreState,
  (state: State, props: { ids: number[] }) => {
    return props.ids.map((id) => state.entities[id]);
  }
);

/**
 * returns array of objects in the order of the given ids
 * @example
 * taskEvaluationSubjectScore$: TaskEvaluationSubjectScoreInterface = this.store.pipe(
    select(TaskEvaluationSubjectScoreQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectTaskEvaluationSubjectScoreState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getTaskEvaluationSubjectScoreLoadedForTask = createSelector(
  selectTaskEvaluationSubjectScoreState,
  (state: State, props: { taskId: number }) => {
    return !!state.loadedForTask[props.taskId];
  }
);

export const isSaving = createSelector(selectTaskEvaluationSubjectScoreState, (state: State) => state.saving);
