<ng-container *ngIf="favoriteBooks?.length">
  <ng-container
    *ngTemplateOutlet="productList; context: { title: 'Favoriete uitgaves', bookTiles: favoriteBooks }"
  ></ng-container>
</ng-container>

<ng-container *ngIf="otherBooks?.length">
  <ng-container
    *ngTemplateOutlet="productList; context: { title: 'Andere uitgaves', bookTiles: otherBooks }"
  ></ng-container>
</ng-container>

<ng-template #productList let-title="title" let-bookTiles="bookTiles">
  <div class="[ title-medium ]">{{ title }}</div>

  <campus-product-list view="cluster">
    <campus-product
      *ngFor="let tile of bookTiles; trackBy: trackByGuid"
      [attr.data-guid]="tile.guid"
      [title]="tile.title"
      [learningAreaName]="tile.data?.method?.learningArea?.name"
      [imageUrl]="tile.logoUrl | methodsImagesPath"
      [rows]="tile.rows"
      (rowClick)="onBookClick($event.data, tile)"
    ></campus-product>
  </campus-product-list>
</ng-template>
