import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch, pessimisticUpdate } from '@nrwl/angular';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TaskEvaluationSubjectScoreQueries } from '.';
import {
  SetTaskEvaluationSubjectScoresForTaskResponseInterface,
  TaskEvaluationSubjectScoreServiceInterface,
  TASK_EVALUATION_SUBJECT_SCORE_SERVICE_TOKEN,
} from '../../task-evaluation-subject-score/task-evaluation-subject-score.service.interface';
import { DalState } from '../dal.state.interface';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { UpsertGoalTopics } from '../goal-topic/goal-topic.actions';
import { UpsertGoalYears } from '../goal-year/goal-year.actions';
import { UpsertGoals } from '../goal/goal.actions';
import { SetTaskGoalYearLevelsForTask } from '../task-goal-year-level/task-goal-year-level.actions';
import {
  AddTaskEvaluationSubjectScoresForTask,
  LoadTaskEvaluationSubjectScoresForTask,
  SetTaskEvaluationSubjectScoresForTask,
  StartSetTaskEvaluationSubjectScoresForTask,
  TaskEvaluationSubjectScoresActionTypes,
  TaskEvaluationSubjectScoresLoadError,
} from './task-evaluation-subject-score.actions';

@Injectable()
export class TaskEvaluationSubjectScoreEffects {
  loadTaskEvaluationSubjectScoresForTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(TaskEvaluationSubjectScoresActionTypes.LoadTaskEvaluationSubjectScoresForTask),
      concatLatestFrom(() =>
        this.store.select(TaskEvaluationSubjectScoreQueries.selectTaskEvaluationSubjectScoreState)
      ),
      fetch({
        run: (action: LoadTaskEvaluationSubjectScoresForTask, taskEvaluationSubjectScoreState) => {
          const { taskId } = action.payload;

          if (taskEvaluationSubjectScoreState.loadedForTask[taskId]) return;

          return this.taskEvaluationSubjectScoreService.getTaskEvaluationSubjectScoresForTask(taskId).pipe(
            map(
              (taskEvaluationSubjectScores) =>
                new AddTaskEvaluationSubjectScoresForTask({
                  taskId,
                  taskEvaluationSubjectScores,
                })
            )
          );
        },
        onError: (action: LoadTaskEvaluationSubjectScoresForTask, error) => {
          return new TaskEvaluationSubjectScoresLoadError(error);
        },
      })
    )
  );

  setTaskEvaluationSubjectScoresForTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(TaskEvaluationSubjectScoresActionTypes.StartSetTaskEvaluationSubjectScoresForTask),
      pessimisticUpdate({
        run: (action: StartSetTaskEvaluationSubjectScoresForTask) => {
          const { taskId, taskEvaluationSubjectScores, taskEvaluationSubjectIds } = action.payload;
          return this.taskEvaluationSubjectScoreService
            .setTaskEvaluationSubjectScoresForTask(taskId, taskEvaluationSubjectScores)
            .pipe(
              switchMap(
                ({
                  taskEvaluationSubjectScores: taskEvaluationSubjectScoresResponse,
                  taskGoalYearLevels,
                  goals,
                  goalYears,
                  goalTopics,
                }: SetTaskEvaluationSubjectScoresForTaskResponseInterface) => {
                  return from([
                    new SetTaskEvaluationSubjectScoresForTask({
                      taskId,
                      taskEvaluationSubjectScores: taskEvaluationSubjectScoresResponse,
                      taskEvaluationSubjectIds,
                    }),
                    new SetTaskGoalYearLevelsForTask({ taskId, taskGoalYearLevels }),
                    new UpsertGoals({ goals }),
                    new UpsertGoalYears({ goalYears }),
                    new UpsertGoalTopics({ goalTopics }),
                  ]);
                }
              )
            );
        },
        onError: (action: StartSetTaskEvaluationSubjectScoresForTask, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de scores te bewaren.'
            ),
          });
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(TASK_EVALUATION_SUBJECT_SCORE_SERVICE_TOKEN)
    private taskEvaluationSubjectScoreService: TaskEvaluationSubjectScoreServiceInterface,
    @Inject('uuid') private uuid: () => string
  ) {}
}
