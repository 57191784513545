import { InjectionToken } from '@angular/core';
import {
  BadgeInterface,
  ConceptInterface,
  LearnerProfileStrandInterface,
  LoopInterface,
  ResultInterface,
  UnlockedCurriculumTreeInterface,
} from '@campus/dal';
import { Dictionary } from '@ngrx/entity';
import { StrandInterface } from '../../interfaces';

export interface MapperStudentStrandsServiceInterface {
  mapToStudentStrand(
    yearId: number,
    strand: StrandInterface,
    unlockedCurriculumTrees: UnlockedCurriculumTreeInterface[],
    learnerProfile: LearnerProfileStrandInterface,
    loops: LoopInterface[],
    badges: BadgeInterface[],
    concepts: ConceptInterface[],
    resultsByConcept: Dictionary<ResultInterface[]>
  ): StrandInterface;
}

export const MAPPER_STUDENT_STRANDS_SERVICE_TOKEN = new InjectionToken<MapperStudentStrandsServiceInterface>(
  'MapperStudentStrandsService'
);
