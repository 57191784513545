import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { HistoryApi, PersonApi } from '../+api';
import { HistoryInterface } from '../+models';
import { HistoryServiceInterface } from './history.service.interface';

@Injectable({
  providedIn: 'root',
})
export class HistoryService implements HistoryServiceInterface {
  constructor(private personApi: PersonApi, private historyApi: HistoryApi) {}

  getAllForUser(userId: number): Observable<HistoryInterface[]> {
    return this.personApi.getData(userId, 'history').pipe(
      map((res: { history: HistoryInterface[] }) => {
        res.history.forEach((item) => (item.created = new Date(item.created)));
        return res.history;
      })
    );
  }

  upsertHistory(history: HistoryInterface): Observable<HistoryInterface> {
    return this.historyApi
      .upsertByInstance(
        history.name,
        history.type,
        history.learningAreaId,
        history.criteria || '',
        history.eduContentId || 0,
        history.bundleId || 0,
        history.taskId || 0,
        history.eduContentBookId || 0
      )
      .pipe(map((item) => ({ ...item, created: new Date(item.created) } as HistoryInterface)));
  }

  deleteHistory(userId: number, historyId: number): Observable<boolean> {
    return this.personApi.destroyByIdHistory(userId, historyId).pipe(mapTo(true));
  }
}
