import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { EduContentMetadataEvaluationSubjectInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum EduContentMetadataEvaluationSubjectsActionTypes {
  EduContentMetadataEvaluationSubjectsLoaded = '[EduContentMetadataEvaluationSubjects] EduContentMetadataEvaluationSubjects Loaded',
  EduContentMetadataEvaluationSubjectsLoadError = '[EduContentMetadataEvaluationSubjects] Load Error',
  LoadEduContentMetadataEvaluationSubjects = '[EduContentMetadataEvaluationSubjects] Load EduContentMetadataEvaluationSubjects',
  AddEduContentMetadataEvaluationSubject = '[EduContentMetadataEvaluationSubjects] Add AddEduContentMetadataEvaluationSubject',
  AddEduContentMetadataEvaluationSubjects = '[EduContentMetadataEvaluationSubjects] Add EduContentMetadataEvaluationSubjects',
  AddEduContentMetadataEvaluationSubjectsForBook = '[EduContentMetadataEvaluationSubjects] Add EduContentMetadataEvaluationSubjects for Book',
  UpsertEduContentMetadataEvaluationSubject = '[EduContentMetadataEvaluationSubjects] Upsert EduContentMetadataEvaluationSubject',
  UpsertEduContentMetadataEvaluationSubjects = '[EduContentMetadataEvaluationSubjects] Upsert EduContentMetadataEvaluationSubjects',
  UpdateEduContentMetadataEvaluationSubject = '[EduContentMetadataEvaluationSubjects] Update EduContentMetadataEvaluationSubject',
  UpdateEduContentMetadataEvaluationSubjects = '[EduContentMetadataEvaluationSubjects] Update EduContentMetadataEvaluationSubjects',
  DeleteEduContentMetadataEvaluationSubject = '[EduContentMetadataEvaluationSubjects] Delete EduContentMetadataEvaluationSubject',
  DeleteEduContentMetadataEvaluationSubjects = '[EduContentMetadataEvaluationSubjects] Delete EduContentMetadataEvaluationSubjects',
  ClearEduContentMetadataEvaluationSubjects = '[EduContentMetadataEvaluationSubjects] Clear EduContentMetadataEvaluationSubjects',
  StartAddEduContentMetadataEvaluationSubject = '[EduContentMetadataEvaluationSubjects] Start Add EduContentMetadataEvaluationSubject',
  StartAddEduContentMetadataEvaluationSubjects = '[EduContentMetadataEvaluationSubjects] Start Add EduContentMetadataEvaluationSubjects',
}

export class LoadEduContentMetadataEvaluationSubjects implements Action {
  readonly type = EduContentMetadataEvaluationSubjectsActionTypes.LoadEduContentMetadataEvaluationSubjects;

  constructor(public payload: { force?: boolean; bookId: number } = { bookId: null }) {}
}

export class EduContentMetadataEvaluationSubjectsLoaded implements Action {
  readonly type = EduContentMetadataEvaluationSubjectsActionTypes.EduContentMetadataEvaluationSubjectsLoaded;

  constructor(
    public payload: { eduContentMetadataEvaluationSubjects: EduContentMetadataEvaluationSubjectInterface[] }
  ) {}
}

export class EduContentMetadataEvaluationSubjectsLoadError implements Action {
  readonly type = EduContentMetadataEvaluationSubjectsActionTypes.EduContentMetadataEvaluationSubjectsLoadError;
  constructor(public payload: any) {}
}

export class AddEduContentMetadataEvaluationSubject implements Action {
  readonly type = EduContentMetadataEvaluationSubjectsActionTypes.AddEduContentMetadataEvaluationSubject;

  constructor(public payload: { eduContentMetadataEvaluationSubject: EduContentMetadataEvaluationSubjectInterface }) {}
}

export class UpsertEduContentMetadataEvaluationSubject implements Action {
  readonly type = EduContentMetadataEvaluationSubjectsActionTypes.UpsertEduContentMetadataEvaluationSubject;

  constructor(public payload: { eduContentMetadataEvaluationSubject: EduContentMetadataEvaluationSubjectInterface }) {}
}

export class AddEduContentMetadataEvaluationSubjects implements Action {
  readonly type = EduContentMetadataEvaluationSubjectsActionTypes.AddEduContentMetadataEvaluationSubjects;

  constructor(
    public payload: { eduContentMetadataEvaluationSubjects: EduContentMetadataEvaluationSubjectInterface[] }
  ) {}
}

export class AddEduContentMetadataEvaluationSubjectsForBook implements Action {
  readonly type = EduContentMetadataEvaluationSubjectsActionTypes.AddEduContentMetadataEvaluationSubjectsForBook;

  constructor(
    public payload: {
      eduContentMetadataEvaluationSubjects: EduContentMetadataEvaluationSubjectInterface[];
      bookId: number;
    }
  ) {}
}

export class UpsertEduContentMetadataEvaluationSubjects implements Action {
  readonly type = EduContentMetadataEvaluationSubjectsActionTypes.UpsertEduContentMetadataEvaluationSubjects;

  constructor(
    public payload: { eduContentMetadataEvaluationSubjects: EduContentMetadataEvaluationSubjectInterface[] }
  ) {}
}

export class UpdateEduContentMetadataEvaluationSubject implements Action {
  readonly type = EduContentMetadataEvaluationSubjectsActionTypes.UpdateEduContentMetadataEvaluationSubject;

  constructor(
    public payload: { eduContentMetadataEvaluationSubject: Update<EduContentMetadataEvaluationSubjectInterface> }
  ) {}
}

export class UpdateEduContentMetadataEvaluationSubjects implements Action {
  readonly type = EduContentMetadataEvaluationSubjectsActionTypes.UpdateEduContentMetadataEvaluationSubjects;

  constructor(
    public payload: { eduContentMetadataEvaluationSubjects: Update<EduContentMetadataEvaluationSubjectInterface>[] }
  ) {}
}

export class DeleteEduContentMetadataEvaluationSubject implements FeedbackTriggeringAction {
  readonly type = EduContentMetadataEvaluationSubjectsActionTypes.DeleteEduContentMetadataEvaluationSubject;

  constructor(public payload: { id: number; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export class DeleteEduContentMetadataEvaluationSubjects implements FeedbackTriggeringAction {
  readonly type = EduContentMetadataEvaluationSubjectsActionTypes.DeleteEduContentMetadataEvaluationSubjects;

  constructor(public payload: { ids: number[]; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export class ClearEduContentMetadataEvaluationSubjects implements Action {
  readonly type = EduContentMetadataEvaluationSubjectsActionTypes.ClearEduContentMetadataEvaluationSubjects;
}

export class StartAddEduContentMetadataEvaluationSubject implements FeedbackTriggeringAction {
  readonly type = EduContentMetadataEvaluationSubjectsActionTypes.StartAddEduContentMetadataEvaluationSubject;

  constructor(
    public payload: {
      eduContentMetadataEvaluationSubject: EduContentMetadataEvaluationSubjectInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartAddEduContentMetadataEvaluationSubjects implements FeedbackTriggeringAction {
  readonly type = EduContentMetadataEvaluationSubjectsActionTypes.StartAddEduContentMetadataEvaluationSubjects;

  constructor(
    public payload: {
      EduContentMetadataEvaluationSubjects: EduContentMetadataEvaluationSubjectInterface[];
      eduContentTocId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export type EduContentMetadataEvaluationSubjectsActions =
  | LoadEduContentMetadataEvaluationSubjects
  | EduContentMetadataEvaluationSubjectsLoaded
  | EduContentMetadataEvaluationSubjectsLoadError
  | AddEduContentMetadataEvaluationSubject
  | AddEduContentMetadataEvaluationSubjectsForBook
  | UpsertEduContentMetadataEvaluationSubject
  | AddEduContentMetadataEvaluationSubjects
  | UpsertEduContentMetadataEvaluationSubjects
  | UpdateEduContentMetadataEvaluationSubject
  | UpdateEduContentMetadataEvaluationSubjects
  | DeleteEduContentMetadataEvaluationSubject
  | DeleteEduContentMetadataEvaluationSubjects
  | ClearEduContentMetadataEvaluationSubjects
  | StartAddEduContentMetadataEvaluationSubject;
