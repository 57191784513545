import { ChangeDetectionStrategy, Component, Inject, Input, Output } from '@angular/core';
import {
  FileReaderError,
  FileReaderResult,
  FileReaderServiceInterface,
  FILEREADER_SERVICE_TOKEN,
} from '@campus/browser';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

export type DropZoneFileReaderMethod = keyof FileReader | 'readAsFile';

@Component({
  selector: 'campus-file-upload-drop-zone',
  templateUrl: './file-upload-drop-zone.component.html',
  styleUrls: ['./file-upload-drop-zone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadDropZoneComponent {
  @Output() public selectedFile$: Observable<FileReaderResult>;
  @Output() public loadError$: Observable<string>;

  public isDragging: boolean;
  public fileReaderProgress$: Observable<number>;
  public currentFile: File;

  private fileReaderInstance: FileReaderServiceInterface;

  @Input() public acceptedFileTypes: string[];
  @Input() public acceptedFilesRegex: RegExp;

  @Input() svgIcon = 'home'; // TODO: change default icon
  @Input() title = 'Importeren';
  @Input() description = 'Sleep een bestand hierheen of selecteer een bestand op je computer';
  @Input() ctaLabel = 'Selecteer een bestand';
  @Input() ctaIcon = '';
  @Input() disabled = false;

  @Input() fileReaderMethod: DropZoneFileReaderMethod = 'readAsDataURL';

  constructor(
    @Inject(FILEREADER_SERVICE_TOKEN)
    private fileReaderService: FileReaderServiceInterface
  ) {
    this.fileReaderInstance = fileReaderService.getFileReader();
    this.setupStreams();
  }

  openFilePicker(filePicker: HTMLElement) {
    filePicker.click();
  }

  selectFileListener(event: Event): void {
    const el = event.target as HTMLInputElement;
    this.currentFile = el.files[0];
    if (!this.currentFile) {
      return;
    }
    this.loadFile(this.currentFile);

    el.value = ''; // clear selected file from input
  }

  dropFileListener(event: DragEvent): void {
    // block browser opening file
    // Note: you also need to prevent the default of the dragover event
    // This is done in the template at the moment
    event.preventDefault();
    if (this.disabled) return;
    this.isDragging = false;

    this.currentFile = event.dataTransfer.files[0];

    this.loadFile(this.currentFile);
  }

  loadFile(file: File): void {
    const isFileAllowed = this.fileReaderInstance.isFileTypeAllowed(file, this.acceptedFilesRegex);
    if (!isFileAllowed) return;

    this.fileReaderInstance[this.fileReaderMethod](file);
  }

  private setupStreams() {
    this.selectedFile$ = this.fileReaderInstance.loaded$.pipe(
      filter((file) => !!file),
      tap(() => this.reset())
    );

    this.loadError$ = this.fileReaderInstance.error$.pipe(map(this.getErrorMessage));
    this.fileReaderProgress$ = this.fileReaderInstance.progress$;
  }

  private reset() {
    this.currentFile = null;
    this.fileReaderInstance.reset();
  }

  private getErrorMessage(err: FileReaderError): string {
    // TODO: make configurable
    switch (err) {
      case FileReaderError.INVALID_FILETYPE:
        return 'Dit bestandstype wordt niet ondersteund.';
      case FileReaderError.READ_ERROR:
        return 'Er was een probleem bij het lezen van het bestand. Probeer het opnieuw of selecteer een ander bestand.';
    }
    return err;
  }
}
