import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgRegistry } from '@campus/svg';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map, tap } from 'rxjs/operators';
import { MethodLevelQueries } from '.';
import { MethodLevelInterface } from '../../+models';
import { METHOD_LEVEL_SERVICE_TOKEN, MethodLevelServiceInterface } from '../../metadata/method-level.service.interface';
import { DalState } from '../dal.state.interface';
import {
  LoadMethodLevels,
  MethodLevelsActionTypes,
  MethodLevelsLoadError,
  MethodLevelsLoaded,
} from './method-level.actions';

@Injectable()
export class MethodLevelEffects {
  loadMethodLevels$ = createEffect(() =>
    this.actions.pipe(
      ofType(MethodLevelsActionTypes.LoadMethodLevels),
      concatLatestFrom(() => this.store.select(MethodLevelQueries.getLoaded)),
      fetch({
        run: (action: LoadMethodLevels, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.methodLevelService.getAllForUser(action.payload.userId).pipe(
            map((methodLevels) =>
              methodLevels.map((methodLevel) => {
                return {
                  ...methodLevel,
                  icon: methodLevel.icon || `method-${methodLevel.methodId}-level-${methodLevel.levelId}`, //TODO Is this still needed?
                };
              })
            ),
            map((methodLevels) => new MethodLevelsLoaded({ methodLevels }))
          );
        },
        onError: (action: LoadMethodLevels, error) => {
          return new MethodLevelsLoadError(error);
        },
      })
    )
  );

  methodLevelsLoaded$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(MethodLevelsActionTypes.MethodLevelsLoaded),
        tap((action: MethodLevelsLoaded) => {
          action.payload.methodLevels.forEach((methodLevel) => {
            const iconEntry = this._getIconRegistryEntry(methodLevel);
            this.iconRegistry.addSvgImage(iconEntry.key, iconEntry.path);
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    private iconRegistry: SvgRegistry,
    private domSanitizer: DomSanitizer,
    @Inject(METHOD_LEVEL_SERVICE_TOKEN)
    private methodLevelService: MethodLevelServiceInterface
  ) {}

  private _getIconRegistryEntry(methodLevel: MethodLevelInterface) {
    const key = methodLevel.icon;
    const path = this.domSanitizer.bypassSecurityTrustResourceUrl(
      `assets/icons/methodlevels/${methodLevel.icon.endsWith('.svg') ? methodLevel.icon : methodLevel.icon + '.svg'}`
    );

    return { key, path };
  }
}
