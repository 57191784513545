import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PersonPreferenceInterface } from '../../+models';
import { NAME, State, selectAll, selectEntities, selectIds, selectTotal } from './person-preference.reducer';

export const selectPersonPreferenceState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectPersonPreferenceState, (state: State) => state.error);

export const getLoaded = createSelector(selectPersonPreferenceState, (state: State) => state.loaded);

export const getAll = createSelector(selectPersonPreferenceState, selectAll);

export const getCount = createSelector(selectPersonPreferenceState, selectTotal);

export const getIds = createSelector(selectPersonPreferenceState, selectIds);

export const getAllEntities = createSelector(selectPersonPreferenceState, selectEntities);

export const getByKey = createSelector(
  getAll,
  (personPreferences) => groupArrayByKeys(personPreferences, ['key']) as Dictionary<PersonPreferenceInterface[]>
);
