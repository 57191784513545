import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CurriculumInterface, LoopCodeEnum, LoopInterface } from '../../+models';
import { CurriculumQueries } from '../curriculum';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './loop.reducer';

export const selectLoopState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectLoopState, (state: State) => state.error);

export const getLoaded = createSelector(selectLoopState, (state: State) => state.loaded);

export const getAll = createSelector(selectLoopState, selectAll);

export const getCount = createSelector(selectLoopState, selectTotal);

export const getIds = createSelector(selectLoopState, selectIds);

export const getAllEntities = createSelector(selectLoopState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * loop$: LoopInterface[] = this.store.pipe(
    select(LoopQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectLoopState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * loop$: LoopInterface = this.store.pipe(
    select(LoopQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectLoopState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getForBookId = createSelector(
  getAll,
  CurriculumQueries.getCurriculumForBookId,
  (loops: LoopInterface[], curriculum: CurriculumInterface, props: { id: number }) =>
    loops.filter((loop) => loop.curriculumId === curriculum.guid)
);

export const getForCurriculumGuidAndLoop = createSelector(
  getAll,
  (loops: LoopInterface[], props: { curriculumId: string; loop: LoopCodeEnum }) =>
    loops.find((loop) => loop.curriculumId === props.curriculumId && loop.code === props.loop)
);

export const getByCurriculumId = createSelector(
  getAll,
  (loops: LoopInterface[]) => groupArrayByKeys(loops, ['curriculumId']) as Dictionary<LoopInterface[]>
);
