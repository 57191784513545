/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { StudentContentStatusApi as SDKStudentContentStatusApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { StudentContentStatusInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class StudentContentStatusApi extends ModelApiBase {
  private SDKStudentContentStatusApi = inject(SDKStudentContentStatusApi);

  constructor(api: PolpoApi) {
    super(api, 'StudentContentStatusses');
  }

  findById(statusId: number): Observable<StudentContentStatusInterface> {
    // @ts-expect-error TS 2556
    return this.SDKStudentContentStatusApi.findById(...arguments);
  }

  patchAttributes(statusId: number, data: any): Observable<StudentContentStatusInterface> {
    // @ts-expect-error TS 2556
    return this.SDKStudentContentStatusApi.patchAttributes(...arguments);
  }
}
