import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { ArtifactApi } from '../+api';
import { EduContentInterface, StorageInfoInterface } from '../+models';

import { ArtifactInterface } from '../+models/Artifact.interface';

export interface ArtifactInstructionInterface {
  name: string;
  video?: EduContentInterface;
  text?: EduContentInterface;
}

@Injectable({
  providedIn: 'root',
})
export class ArtifactService {
  constructor(private artifactApi: ArtifactApi) {}

  getAll(): Observable<ArtifactInterface[]> {
    return this.artifactApi.find();
  }

  create(artifact: ArtifactInterface): Observable<ArtifactInterface> {
    return this.artifactApi.create(artifact);
  }

  update(id: number, updates: Partial<ArtifactInterface>): Observable<ArtifactInterface> {
    return this.artifactApi
      .patchAttributes(id, updates)
      .pipe(map((artifact) => artifact as unknown as ArtifactInterface));
  }

  delete(id: number): Observable<boolean> {
    return this.artifactApi.deleteById(id).pipe(mapTo(true));
  }

  uploadFile(artifactId: number, file: File, formFields: Dictionary<any> = {}): Observable<StorageInfoInterface> {
    const formData: FormData = new FormData();
    if (file) {
      formData.set('file', file, file.name);
    }
    Object.entries(formFields).forEach(([key, value]) => formData.set(key, value));

    return this.artifactApi.store(artifactId, formData).pipe(map((response) => response.storageInfo));
  }

  uploadImage(
    artifactId: number,
    name: string,
    image: File
  ): Observable<{ id: number; file: string; storageName: string }> {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('file', image);

    return this.artifactApi
      .uploadImage(artifactId, formData)
      .pipe(map((response) => ({ ...response, id: artifactId })));
  }

  getInstructions(artifactId: number): Observable<ArtifactInstructionInterface> {
    return this.artifactApi.getInstructions(artifactId);
  }
}
