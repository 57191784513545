/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { LearningAreaApi as SDKLearningAreaApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { LearningAreaInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class LearningAreaApi extends ModelApiBase {
  private SDKLearningAreaApi = inject(SDKLearningAreaApi);

  constructor(api: PolpoApi) {
    super(api, 'LearningAreas');
  }

  find(options?): Observable<LearningAreaInterface[]> {
    return this.SDKLearningAreaApi.find(...arguments);
  }
}
