import { Update } from '@ngrx/entity';
import { UpdateNum } from '@ngrx/entity/src/models';
import { Action } from '@ngrx/store';
import { LessonSheetInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum LessonSheetsActionTypes {
  LoadLessonSheets = '[LessonSheets] Load LessonSheets',
  LessonSheetsLoaded = '[LessonSheets] LessonSheets Loaded',
  LessonSheetsLoadError = '[LessonSheets] LessonSheets Load Error',
  CreateLessonSheet = '[LessonSheets] Create LessonSheet',
  AddLessonSheet = '[LessonSheets] Add LessonSheet',
  AddLessonSheets = '[LessonSheets] Add LessonSheets',
  StartUpdateLessonSheet = '[LessonSheets] Start Update LessonSheet',
  UpdateLessonSheet = '[LessonSheets] Update LessonSheet',
  UpdateLessonSheets = '[LessonSheets] Update LessonSheets',
  ExportLessonSheet = '[LessonSheets] Export LessonSheet',
  ExportLessonSheetsForEduContentBook = '[LessonSheets] Export LessonSheets For EduContentBook',
  StartDeleteLessonSheet = '[LessonSheets] Start Delete LessonSheet',
  DeleteLessonSheet = '[LessonSheets] Delete LessonSheet',
  SyncLessonSheetMappings = '[LessonSheets] Sync LessonSheet Mappings',
  AddLessonSheetsForBook = '[LessonSheets] Add LessonSheets For Book',
}

export class LoadLessonSheets implements Action {
  readonly type = LessonSheetsActionTypes.LoadLessonSheets;

  constructor(public payload: { force?: boolean } = {}) {}
}

export class LessonSheetsLoaded implements Action {
  readonly type = LessonSheetsActionTypes.LessonSheetsLoaded;

  constructor(public payload: { lessonSheets: LessonSheetInterface[] }) {}
}

export class LessonSheetsLoadError implements Action {
  readonly type = LessonSheetsActionTypes.LessonSheetsLoadError;
  constructor(public payload: any) {}
}

export class AddLessonSheet implements Action {
  readonly type = LessonSheetsActionTypes.AddLessonSheet;

  constructor(public payload: { lessonSheet: LessonSheetInterface }) {}
}

export class CreateLessonSheet implements Action, FeedbackTriggeringAction {
  readonly type = LessonSheetsActionTypes.CreateLessonSheet;

  constructor(
    public payload: {
      tocId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class AddLessonSheets implements Action {
  readonly type = LessonSheetsActionTypes.AddLessonSheets;

  constructor(public payload: { lessonSheets: LessonSheetInterface[] }) {}
}

export class StartUpdateLessonSheet implements Action, FeedbackTriggeringAction {
  readonly type = LessonSheetsActionTypes.StartUpdateLessonSheet;

  constructor(
    public payload: {
      lessonSheet: UpdateNum<LessonSheetInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpdateLessonSheet implements Action {
  readonly type = LessonSheetsActionTypes.UpdateLessonSheet;

  constructor(
    public payload: {
      lessonSheet: UpdateNum<LessonSheetInterface>;
    }
  ) {}
}

export class UpdateLessonSheets implements Action {
  readonly type = LessonSheetsActionTypes.UpdateLessonSheets;

  constructor(public payload: { lessonSheets: Update<LessonSheetInterface>[] }) {}
}

export class ExportLessonSheet implements Action, FeedbackTriggeringAction {
  readonly type = LessonSheetsActionTypes.ExportLessonSheet;

  constructor(
    public payload: {
      lessonSheetId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartDeleteLessonSheet implements Action, FeedbackTriggeringAction {
  readonly type = LessonSheetsActionTypes.StartDeleteLessonSheet;

  constructor(public payload: { lessonSheetId: number; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export class DeleteLessonSheet implements Action {
  readonly type = LessonSheetsActionTypes.DeleteLessonSheet;

  constructor(
    public payload: {
      lessonSheetId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class ExportLessonSheetsForEduContentBook implements Action, FeedbackTriggeringAction {
  readonly type = LessonSheetsActionTypes.ExportLessonSheetsForEduContentBook;

  constructor(
    public payload: {
      eduContentBookId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class SyncLessonSheetMappings implements Action, FeedbackTriggeringAction {
  readonly type = LessonSheetsActionTypes.SyncLessonSheetMappings;

  constructor(
    public payload: {
      lessonSheetId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class AddLessonSheetsForBook implements Action {
  readonly type = LessonSheetsActionTypes.AddLessonSheetsForBook;

  constructor(public payload: { lessonSheets: LessonSheetInterface[]; bookId: number }) {}
}

export type LessonSheetActions =
  | LoadLessonSheets
  | LessonSheetsLoaded
  | LessonSheetsLoadError
  | CreateLessonSheet
  | AddLessonSheet
  | AddLessonSheets
  | StartUpdateLessonSheet
  | UpdateLessonSheet
  | UpdateLessonSheets
  | ExportLessonSheet
  | StartDeleteLessonSheet
  | DeleteLessonSheet
  | ExportLessonSheetsForEduContentBook
  | SyncLessonSheetMappings
  | AddLessonSheetsForBook;
