import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@campus/components';
import { SvgModule } from '@campus/svg';
import { ThemingModule } from '@campus/theming';
import { UiModule } from '@campus/ui';
import { ActivityPreviewComponent } from './components/activity-preview/activity-preview.component';
import { AssessementComponent } from './components/assessement/assessement.component';
import { LearnosityAuthorComponent } from './components/author/author.component';
import { FreshworksComponent } from './components/freshworks/freshworks.component';
import { LearnosityDialogComponent } from './components/learnosity-dialog/learnosity-dialog.component';
import { LearnositySlideoutComponent } from './components/learnosity-slideout/learnosity-slideout.component';
import { ReportingComponent } from './components/reporting/reporting.component';
import { icons } from './icons';

@NgModule({
  imports: [
    CommonModule,
    UiModule,
    ComponentsModule,
    ThemingModule,
    SvgModule.forRoot({
      iconMapping: icons,
    }),
  ],
  declarations: [
    AssessementComponent,
    ReportingComponent,
    ActivityPreviewComponent,
    LearnositySlideoutComponent,
    LearnosityAuthorComponent,
    LearnosityDialogComponent,
    FreshworksComponent,
  ],
  exports: [
    AssessementComponent,
    ReportingComponent,
    ActivityPreviewComponent,
    LearnositySlideoutComponent,
    LearnosityAuthorComponent,
    FreshworksComponent,
  ],
})
export class LearnosityModule {}
