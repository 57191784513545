import { inject, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { WindowService, WindowServiceInterface } from '@campus/browser';
import {
  EduContent,
  EduContentInterface,
  LearnosityApi,
  LearnosityAssessementApiService,
  LearnosityItemsConfigInterface,
  LearnosityItemsState,
  LearnosityItemsType,
  RolesEnum,
  ScormCmiMode,
} from '@campus/dal';
import {
  BrowseExerciseConfigurationInterface,
  CustomExerciseServiceInterface,
  ExerciseConfiguration,
  NormalExerciseConfigurationInterface,
  ReviewExerciseConfigurationInterface,
  StudentReviewExerciseConfigurationInterface,
} from '@campus/exercise';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  LearnosityDialogComponent,
  LearnosityDialogComponentType,
} from '../components/learnosity-dialog/learnosity-dialog.component';
import { RESULT_UPDATER_TOKEN } from '../interfaces';

export enum LearnosityStateEnum {
  INITIAL = 'initial', // automatically applied by Learnosity
  PREVIEW = 'preview',
  RESUME = 'resume', // automatically applied by Learnosity
  REVIEW = 'review',
}

@Injectable({ providedIn: 'root' })
export class LearnosityOpenerService implements CustomExerciseServiceInterface {
  public readonly learnosityDialogId = 'learnosity-assessment-dialog';

  private learnosityAssessmentService = inject(LearnosityAssessementApiService);
  private learnosityApi = inject(LearnosityApi);

  private dialog = inject(MatDialog);
  private windowService: WindowServiceInterface = inject(WindowService);
  private resultUpdater = inject(RESULT_UPDATER_TOKEN, { optional: true });

  public isContentCompatible(content: EduContentInterface): boolean {
    const eduContent = content instanceof EduContent ? content : EduContent.toEduContent(content);
    return eduContent.isLearnosity;
  }

  public shouldGetResult(data: ExerciseConfiguration): boolean {
    return false; // result is created when the exercise is started
  }

  public loadExercise(data: ExerciseConfiguration) {
    const { mode } = data;

    switch (mode) {
      case ScormCmiMode.CMI_MODE_NORMAL:
        // new result
        // modes: without feedback, with feedback, resume (handled by learnosity)
        // save scores
        return this.start(data);
      case ScormCmiMode.CMI_MODE_BROWSE: // intended for previewing exercises, however LUDO uses custom 'CMI_MODE_PREVIEW' instead
        // no result,
        // don't save scores
        return this.preview(data);
      case ScormCmiMode.CMI_MODE_REVIEW:
        // review an existing result
        // teacher: through reports
        // student: through assessment
        // don't save scores
        return this.review(data);
      default:
        console.warn(`Mode <<${mode}>> is not supported by the LearnosityOpenerService`);
    }
  }

  private openExercise(
    config: LearnosityItemsConfigInterface,
    component: LearnosityDialogComponentType,
    eduContent?: EduContentInterface
  ) {
    if (!config) {
      console.warn('Cannot open learnosity exercise without config');
      return;
    }

    if (this.dialog.getDialogById(this.learnosityDialogId)) {
      console.warn('A learnosity exercise is already open. Close the existing dialog first.');
      return;
    }
    const data = {
      config,
      component,
      eduContent,
    };
    const { result } = config;
    if (result) this.resultUpdater?.(result);

    const dialogConfig = {
      data,
      panelClass: 'ui-learnosity--fullscreen',
      id: this.learnosityDialogId,
      ignoreDocumentScrollBlocked: true,
    };

    this.windowService.openDialog(LearnosityDialogComponent, dialogConfig);
  }

  private preview(config: BrowseExerciseConfigurationInterface) {
    const { eduContent } = config;
    const config$ = this.getPreviewConfig(config);

    config$.pipe(take(1)).subscribe((config) => {
      this.openExercise(config, 'activity-preview', eduContent);
    });
  }

  private review(config: ReviewExerciseConfigurationInterface) {
    // educontent.id
    // eduContent.learnosityReference
    // result.learnositySessionId
    // state: 'review' -> mode

    const { role } = config;

    switch (role) {
      case RolesEnum.Student:
        this.reviewAsStudent(config);
        break;
      case RolesEnum.Teacher:
        this.reviewAsTeacher(config);
        break;

      default:
        console.warn(`Review as role <<${role}>> is not supported by the LearnosityOpenerService`);
        break;
    }
  }

  private reviewAsStudent(config: ReviewExerciseConfigurationInterface) {
    const { eduContent, result } = config;
    const config$ = this.getConfig(config);

    config$.pipe(take(1)).subscribe((config) => {
      this.openExercise({ ...config, result }, 'assessment', eduContent);
    });
  }

  private reviewAsTeacher(config: ReviewExerciseConfigurationInterface) {
    const { eduContent } = config;
    const config$ = this.getReportsConfig(config);

    config$.pipe(take(1)).subscribe((config) => {
      this.openExercise(config, 'reporting', eduContent);
    });
  }

  private start(data: NormalExerciseConfigurationInterface) {
    const { playerOptions, eduContent } = data;

    const config$ = playerOptions?.localSession ? this.getLocalConfig(data) : this.getConfig(data);
    config$.pipe(take(1)).subscribe((config) => {
      this.openExercise(config, 'assessment', eduContent);
    });
  }

  private getConfig(
    configuration:
      | NormalExerciseConfigurationInterface
      | ReviewExerciseConfigurationInterface
      | StudentReviewExerciseConfigurationInterface
  ): Observable<LearnosityItemsConfigInterface> {
    const { eduContent, result } = configuration;
    const settings = this.getSettingsFromConfig(configuration);

    let context = {};
    if ('context' in configuration) {
      context = configuration.context;
    }

    const learnosityConfig$ = this.learnosityAssessmentService.getLearnosityItemsConfig(
      eduContent.id,
      EduContent.toEduContent(eduContent).learnosityReference,
      result?.learnositySessionId,
      settings,
      context
    );

    return learnosityConfig$;
  }

  private getLocalConfig(
    configuration: NormalExerciseConfigurationInterface
  ): Observable<LearnosityItemsConfigInterface> {
    const { eduContent } = configuration;
    const settings = this.getSettingsFromConfig(configuration);

    const learnosityConfig$ = this.learnosityAssessmentService.getLearnosityLocalItemsConfig(
      eduContent.id,
      EduContent.toEduContent(eduContent).learnosityReference,
      settings
    );

    return learnosityConfig$;
  }

  private getPreviewConfig(config: BrowseExerciseConfigurationInterface): Observable<LearnosityItemsConfigInterface> {
    const { eduContent } = config;
    const learnosityReference = EduContent.toEduContent(eduContent).learnosityReference;
    const learnosityConfig$ = this.learnosityApi.previewActivity(learnosityReference);

    return learnosityConfig$;
  }

  private getReportsConfig(config: ReviewExerciseConfigurationInterface): Observable<LearnosityItemsConfigInterface> {
    const { result } = config;
    const { id } = result;
    const learnosityConfig$ = this.learnosityApi.buildReportsRequest(id);
    return learnosityConfig$;
  }

  private getSettingsFromConfig(config: ExerciseConfiguration): {
    state: LearnosityItemsState;
    type?: LearnosityItemsType;
  } {
    // @ts-ignore
    const { mode, playerOptions } = config;

    const state = this.mapScormModeToLearnosityState(mode);
    const type: LearnosityItemsType = playerOptions?.localSession ? 'local_practice' : undefined;

    return { state, ...(type ? { type } : {}) };
  }

  /**
   * Maps the cmi mode to a state supported by the Learnosity API
   *
   * @private
   * @param {string} mode
   * @return {*}  {string}
   * @memberof LearnosityOpenerService
   */
  private mapScormModeToLearnosityState(mode: ScormCmiMode): LearnosityItemsState {
    // https://help.learnosity.com/hc/en-us/articles/360000755438-Switching-Between-Testing-and-Reviewing-With-States
    switch (mode) {
      case ScormCmiMode.CMI_MODE_NORMAL:
        return null; // applied automatically by Learnosity
      case ScormCmiMode.CMI_MODE_BROWSE:
        return LearnosityStateEnum.PREVIEW;
      default:
        // scorm mode is the same as learnosity state
        return mode;
    }
  }
}
