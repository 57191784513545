<div
  class="[ p-s ] shared-dropzone"
  [class.dragging]="isDragging && !disabled"
  (drop)="dropFileListener($event)"
  (dragover)="$event.preventDefault()"
  (dragenter)="isDragging = true"
  (dragleave)="isDragging = false"
>
  <ng-container *ngIf="fileReaderProgress$ | async as progress; else newUpload">
    <campus-icon class="shared-dropzone__image" [svgIcon]="svgIcon"></campus-icon>
    <span class="ui-body-2">{{ currentFile?.name }}</span>

    <ng-container *ngIf="progress > 0; else isLoading">
      <campus-progress [count]="progress" [showPercentage]="true" mode="determinate" form="linear"></campus-progress>
    </ng-container>
    <ng-template #isLoading>
      <campus-progress mode="indeterminate" form="linear"></campus-progress>
    </ng-template>
  </ng-container>

  <ng-template #newUpload>
    <ng-content select="[dropzone-before]"></ng-content>
    <campus-empty-state
      class="[ m-bs-s ]"
      [svgIcon]="svgIcon"
      [title]="title"
      [description]="description"
      [ctaLabel]="ctaLabel"
      [ctaIcon]="ctaIcon"
      [disabled]="disabled"
      (clickCta)="openFilePicker(fileInput)"
      [dense]="true"
    >
    </campus-empty-state>
    <input
      #fileInput
      type="file"
      style="display: none"
      [accept]="acceptedFileTypes | join"
      [disabled]="disabled"
      clickStopPropagation
      (change)="selectFileListener($event)"
      data-cy="input-file-upload-drop-zone"
    />
    <ng-content select="[dropzone-after]"></ng-content>
    <div *ngIf="loadError$ | async as error"
      ><span class="ui-text-warning">{{ error }}</span></div
    >
  </ng-template>
</div>
