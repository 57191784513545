import { groupArrayByKey } from '@campus/utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EvaluationSubjectInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './evaluation-subject.reducer';

export const selectEvaluationSubjectState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectEvaluationSubjectState, (state: State) => state.error);

export const getLoaded = createSelector(selectEvaluationSubjectState, (state: State) => state.loaded);

export const getAll = createSelector(selectEvaluationSubjectState, selectAll);

export const getCount = createSelector(selectEvaluationSubjectState, selectTotal);

export const getIds = createSelector(selectEvaluationSubjectState, selectIds);

export const getAllEntities = createSelector(selectEvaluationSubjectState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * evaluationSubject$: EvaluationSubjectInterface[] = this.store.pipe(
    select(EvaluationSubjectQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectEvaluationSubjectState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * evaluationSubject$: EvaluationSubjectInterface = this.store.pipe(
    select(EvaluationSubjectQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectEvaluationSubjectState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getEvaluationSubjectsByEvaluationId = createSelector(
  getAll,
  (evaluationsSubjects: EvaluationSubjectInterface[]) => {
    return groupArrayByKey(evaluationsSubjects, 'evaluationId');
  }
);

export const getEvaluationSubjectsByLearningDomainId = createSelector(
  getAll,
  (evaluationsSubjects: EvaluationSubjectInterface[]) => {
    return groupArrayByKey(evaluationsSubjects, 'learningDomainId');
  }
);

export const getEvaluationSubjectsLoadedForBook = createSelector(
  selectEvaluationSubjectState,
  (state, props: { bookId: number }) => {
    return !!state.loadedForBook[props.bookId];
  }
);

// will be deprecated once #16248 is completed
export const getByEduContentTOCId = createSelector(getAll, (evaluationSubjects: EvaluationSubjectInterface[]) => {
  return groupArrayByKey(evaluationSubjects, 'eduContentTOCId');
});

export const isBookLoaded = (props: { bookId: number }) =>
  createSelector(selectEvaluationSubjectState, (state: State) => !!state.loadedForBook[props.bookId]);
