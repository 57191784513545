<iframe [src]="url" frameborder="0"></iframe>

<div data-cy="close-iframe" class="ui-iframe-close" (click)="onCloseClicked()">
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 24 24"
    xml:space="preserve"
  >
    <style type="text/css">
      .st0close {
        fill: none;
      }
    </style>
    <path d="M19,6.4L17.6,5L12,10.6L6.4,5L5,6.4l5.6,5.6L5,17.6L6.4,19l5.6-5.6l5.6,5.6l1.4-1.4L13.4,12L19,6.4z" />
    <path class="st0close" d="M0,0h24v24H0V0z" />
  </svg>
</div>
