import { BundlesPermissions, MethodsPermissions, SettingsPermissions, TasksPermissions } from '@campus/dal';
import { AppNavTreeInterface } from '@campus/shared';
import { NavItem } from '@campus/ui';

const standardSideNavItems: NavItem[] = [
  {
    title: 'dashboard',
    link: '/home',
    icon: 'home',
    requiredPermissions: ['dashboard'],
    usetifulIdentifier: 'nav-dashboard',
  },
  {
    title: 'methodes',
    icon: 'methods',
    link: '/methods',
    requiredPermissions: [['manageMethods', 'openMethods'], `!${SettingsPermissions.HIDE_IN_ARCHIVE}`],
    usetifulIdentifier: 'nav-methods',
  },
  {
    title: 'taken',
    icon: 'task',
    link: '/tasks',
    requiredPermissions: [['manageTasks', 'openPolpoTasks'], `!${SettingsPermissions.HIDE_IN_ARCHIVE}`],
    usetifulIdentifier: 'nav-tasks',
  },
  {
    title: 'bundels',
    icon: 'bundles',
    link: '/bundles',
    requiredPermissions: [['manageBundles', 'openBundles'], `!${SettingsPermissions.HIDE_IN_ARCHIVE}`],
    usetifulIdentifier: 'nav-bundles',
  },
  {
    title: 'resultaten',
    icon: 'results',
    link: '/resultsV2',
    requiredPermissions: ['manageResultsV2'],
    usetifulIdentifier: 'nav-results',
  },
  {
    title: 'mijn scholen',
    link: '/schools',
    requiredPermissions: ['manageSchools'],
    icon: 'schools',
    usetifulIdentifier: 'nav-schools',
  },
  {
    title: 'leerlingen / groepen',
    icon: 'assignees',
    link: '/students',
    requiredPermissions: ['students', `!${SettingsPermissions.HIDE_IN_ARCHIVE}`],
    usetifulIdentifier: 'nav-students',
  },
];

const standardSettingsNavItems: NavItem[] = [
  {
    title: 'Mijn gegevens',
    icon: 'profile',
    link: '/settings/profile',
    requiredPermissions: [SettingsPermissions.UPDATE_PROFILE],
    usetifulIdentifier: 'nav-profile-settings',
  },
  {
    title: 'Verander wachtwoord',
    icon: 'lock',
    link: '/settings/password/reset',
    requiredPermissions: [SettingsPermissions.UPDATE_PROFILE],
    usetifulIdentifier: 'nav-change-password',
  },
  {
    title: 'Verander profielfoto',
    icon: 'avatar',
    link: '/settings/profile/avatar',
    requiredPermissions: [SettingsPermissions.UPDATE_AVATAR],
    usetifulIdentifier: 'nav-avatar',
  },
  {
    title: 'Mijn koppelingen',
    icon: 'credentials',
    link: '/settings/credentials',
    requiredPermissions: [SettingsPermissions.ADD_CREDENTIALS],
    usetifulIdentifier: 'nav-credentials',
  },
  {
    title: 'Mijn scholen',
    icon: 'schools',
    link: '/settings/schools',
    requiredPermissions: [SettingsPermissions.LINK_SCHOOLS],
    usetifulIdentifier: 'nav-schools',
  },
  {
    title: 'Mijn leerkrachten',
    icon: 'coupled-teachers',
    link: '/settings/coupled-teachers',
    requiredPermissions: [SettingsPermissions.LINK_TEACHERS, SettingsPermissions.UNLINK_TEACHERS],
    usetifulIdentifier: 'nav-teachers',
  },
  {
    title: 'Mijn methodes',
    icon: 'my-methods',
    link: '/settings/my-methods',
    requiredPermissions: [SettingsPermissions.MANAGE_MY_METHODS],
    usetifulIdentifier: 'nav-my-methods',
  },
  {
    title: 'Privacy',
    icon: '', // TODO: get icon
    link: '/settings/profile/termsV2',
    requiredPermissions: [SettingsPermissions.SHOW_PRIVACY_SETTINGS],
  },
];

const standardProfileMenuNavItems: NavItem[] = [
  {
    title: 'Profiel',
    icon: 'profile',
    link: '/settings/profile',
    requiredPermissions: ['updateProfile', `!${SettingsPermissions.HIDE_IN_ARCHIVE}`],
  },
  {
    title: 'Smartschool',
    icon: 'smartschool',
  },
  {
    title: 'Huidig jaar',
    icon: 'unarchive',
    id: 'current-year-selection',
    requiredPermissions: [SettingsPermissions.CAN_VIEW_ARCHIVE],
  },
  {
    title: 'Archiefjaar',
    icon: 'archive',
    id: 'archive-year-selection',
    requiredPermissions: [SettingsPermissions.CAN_VIEW_ARCHIVE],
  },
  {
    title: 'Afmelden',
    icon: 'lock',
    link: '/logout',
    dividerBefore: true,
  },
];
const standardDashboardNavItems: NavItem[] = [
  {
    title: 'Methodes',
    description: 'Bordboeken, lesmateriaal en leerplandoelen openen.',
    link: '/methods',
    icon: 'methods',
    requiredPermissions: [MethodsPermissions.MANAGE_METHODS, `!${SettingsPermissions.HIDE_IN_ARCHIVE}`],
  },
  {
    title: 'Taken',
    description: 'Klassikale en individuele taken klaarzetten.',
    link: '/tasks/manage',
    icon: 'tasks',
    requiredPermissions: [SettingsPermissions.MANAGE_TASKS, `!${SettingsPermissions.HIDE_IN_ARCHIVE}`],
  },
  {
    title: 'Taken',
    link: '/tasks',
    description: 'Maak taken die voor jou zijn klaargezet.',
    icon: 'tasks',
    requiredPermissions: [TasksPermissions.OPEN_TASKS],
  },
  {
    title: 'Resultaten',
    description: 'Resultaten van oefeningen per taak, bundel of per leerling raadplegen en exporteren.',
    icon: 'results',
    link: '/resultsV2',
    requiredPermissions: [SettingsPermissions.MANAGE_RESULTS_V2],
  },
  {
    title: 'Bundels',
    description: 'Maak bundels die voor jou zijn klaargezet.',
    link: '/bundles',
    icon: 'bundles',
    requiredPermissions: [BundlesPermissions.OPEN_BUNDLES],
  },
  {
    title: 'Methodes',
    description: 'Bordboeken, lesmateriaal en leerplandoelen openen.',
    link: '/methods',
    icon: 'methods',
    requiredPermissions: [MethodsPermissions.OPEN_METHODS],
  },
];

export interface AppConfigInterface {
  appNavtree: AppNavTreeInterface;
}
export const polpoConfig: AppConfigInterface = {
  appNavtree: {
    dashboardNav: standardDashboardNavItems,
    sideNav: standardSideNavItems,
    settingsNav: standardSettingsNavItems,
    profileMenuNav: standardProfileMenuNavItems,
  },
};
