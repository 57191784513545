/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { inject, Injectable } from '@angular/core';
import { EduContentTOCApi as SDKEduContentTOCApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { EduContentTOCInterface } from '../../+models';
import { TocDataResponseInterface } from '../../data';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class EduContentTOCApi extends ModelApiBase {
  private SDKEduContentTOCApi = inject(SDKEduContentTOCApi);

  constructor(api: PolpoApi) {
    super(api, 'EduContentTOCs');
  }

  tree(bookId: number): Observable<EduContentTOCInterface[]> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentTOCApi.tree(...arguments);
  }

  getEduContentTocsForBook(bookId: number): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentTOCApi.getEduContentTocsForBook(...arguments);
  }

  patchAttributes(eduContentTOCId: number, update): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentTOCApi.patchAttributes(...arguments);
  }

  deleteEvaluation(eduContentTOCId: number): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentTOCApi.deleteEvaluation(...arguments);
  }

  moveEvaluation(oldEduContentTOCId: number, newEduContentTOCId: number): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentTOCApi.moveEvaluation(...arguments);
  }

  updateEduContentTOC(eduContentTOCId: number, eduContentTOC): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentTOCApi.updateEduContentTOC(...arguments);
  }

  updateEduContentMetadataDisplayOrder(eduContentTOCId: number, displayOrderByEduContentMetadataId): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentTOCApi.updateEduContentMetadataDisplayOrder(...arguments);
  }

  getData(eduContentTOCId: number, fields?: string | string[]) {
    const path = `${eduContentTOCId}/data`;

    const params = this._getHttpParams({
      fields,
    });

    return this.get<TocDataResponseInterface>(path, params);
  }
}
