import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EduNetApi } from '../+api';
import { EduNetInterface } from '../+models';
import { EduNetServiceInterface } from './edu-net.service.interface';

@Injectable({
  providedIn: 'root',
})
export class EduNetService implements EduNetServiceInterface {
  constructor(private eduNetApi: EduNetApi) {}

  getAll(): Observable<EduNetInterface[]> {
    return this.eduNetApi.find();
  }
}
