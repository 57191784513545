import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LearningDomainApi } from '../+api';

import { LearningDomainInterface } from '../+models';
import { DAL_OPTIONS, DalOptions } from '../dal.module';
import { LearningDomainServiceInterface } from './learning-domain.service.interface';

@Injectable({
  providedIn: 'root',
})
export class LearningDomainService implements LearningDomainServiceInterface {
  private http = inject(HttpClient);
  private dalOptions = inject<DalOptions>(DAL_OPTIONS);

  constructor(private learningDomainApi: LearningDomainApi) {}

  getAll(): Observable<LearningDomainInterface[]> {
    return this.learningDomainApi.find();
  }

  getLearningDomainsWithTopics(goalGroupId: number): Observable<LearningDomainInterface[]> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/LearningDomains/learningdomains-with-goaltopics`;

    return this.http.get<LearningDomainInterface[]>(url, {
      params: {
        goalGroupId: goalGroupId.toString(),
      },
      withCredentials: true,
    });
  }
}
