import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CurriculumTreeConceptInterface, CurriculumTreeInterface } from '../../+models';
import { getTopicsByStrandGuid } from '../curriculum-tree/curriculum-tree.selectors';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './curriculum-tree-concept.reducer';

export const selectCurriculumTreeConceptState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectCurriculumTreeConceptState, (state: State) => state.error);

export const getLoaded = createSelector(selectCurriculumTreeConceptState, (state: State) => state.loaded);

export const getAll = createSelector(selectCurriculumTreeConceptState, selectAll);

export const getCount = createSelector(selectCurriculumTreeConceptState, selectTotal);

export const getIds = createSelector(selectCurriculumTreeConceptState, selectIds);

export const getAllEntities = createSelector(selectCurriculumTreeConceptState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * curriculumTreeConcept$: CurriculumTreeConceptInterface[] = this.store.pipe(
    select(CurriculumTreeConceptQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectCurriculumTreeConceptState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * curriculumTreeConcept$: CurriculumTreeConceptInterface = this.store.pipe(
    select(CurriculumTreeConceptQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectCurriculumTreeConceptState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getCurriculumTreeConceptsForStrandGuid = createSelector(
  getAll,
  getTopicsByStrandGuid,
  (
    curriculumTreeConcepts: CurriculumTreeConceptInterface[],
    topicsByStrandGuid: Dictionary<CurriculumTreeInterface[]>,
    props: { strandGuid: string }
  ) => {
    const isTopicInStrand = topicsByStrandGuid[props.strandGuid].reduce((acc, topic) => {
      acc[topic.guid] = true;

      return acc;
    }, {});

    return curriculumTreeConcepts.reduce((acc, ctConcept) => {
      if (ctConcept.curriculumTreeId === props.strandGuid || isTopicInStrand[ctConcept.curriculumTreeId]) {
        acc.push(ctConcept);
      }

      return acc;
    }, []);
  }
);
