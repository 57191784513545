import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './level.reducer';

export const selectLevelState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectLevelState, (state: State) => state.error);

export const getLoaded = createSelector(selectLevelState, (state: State) => state.loaded);

export const getAll = createSelector(selectLevelState, selectAll);

export const getCount = createSelector(selectLevelState, selectTotal);

export const getIds = createSelector(selectLevelState, selectIds);

export const getAllEntities = createSelector(selectLevelState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * level$: LevelInterface[] = this.store.pipe(
    select(LevelQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectLevelState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * level$: LevelInterface = this.store.pipe(
    select(LevelQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectLevelState,
  (state: State, props: { id: number }) => state.entities[props.id]
);
