import { EnvironmentInterface, FavoriteTypesEnum, HistoryTypesEnum } from '@campus/environment';
import { animations } from './animations';
import * as defaults from './environment.defaults';
import { icons } from './icons';
import { searchModes } from './search-modes';
import { svgs } from './svgs';
import { textMapping } from './text';

export const environment: Partial<EnvironmentInterface> = {
  production: true,
  enableDevTools: true,
  stage: 'staging',
  release: 'PI11.S3.3.2066f3d1e212db567722714655293e9e6d83735d',
  platform: 'polpo',

  website: {
    url: 'https://www.staging.polpo.be',
    title: 'POLPO - staging',
    favicon: 'assets/icons/favicon.ico',
    faqUrl: 'https://www.staging.polpo.be/faq',
    orderLicenseUrl: 'https://www.staging.polpo.be/bestel',
    activateLicenseUrl: 'https://www.staging.polpo.be/bestel/ik-heb-al-een-code',
    loadingUrl: 'https://api.staging.polpo.be/app/loading',
  },
  login: {
    url: 'https://api.staging.polpo.be/app/login',
    loginPresets: [
      { label: 'Student', username: 'student1', password: 'testje' },
      { label: 'Leerkracht', username: 'teacher1', password: 'testje' },
      { label: 'Beheerder (Sandrine)', username: 'schooladmin1', password: 'testje' },
    ],
  },
  profile: {
    url: '/settings/schools',
  },
  afterLogout: {
    url: 'https://api.staging.polpo.be/app/login',
    delay: 3000,
  },
  termPrivacy: {
    ...defaults.environment.termPrivacy,
  },
  iconMapping: icons,
  textMapping,
  svgMapping: svgs,
  api: {
    APIBase: 'https://api.staging.polpo.be',
  },
  features: {
    alerts: {
      enabled: false,
      hasAppBarDropDown: true,
      appBarPollingInterval: 3000,
    },
    messages: {
      enabled: false,
      hasAppBarDropDown: false,
    },
    errorManagement: {
      runMaintenanceCheckInInterceptor: true,
      managedStatusCodes: [500, 401, 404, 0],
      allowedErrors: [
        {
          status: 404,
          statusText: 'Not Found',
          urlRegex: 'http.*assets\\/icons.*.svg',
        },
        {
          status: 404,
          messageRegex: 'no_teacher_found_for_given_key',
        },
        {
          status: 401,
          urlRegex: 'http.*People\\/me.*',
        },
        {
          status: 401,
          urlRegex: 'http.*\\/EduContents/[0-9]+/requestURL',
        },
      ],
    },
    globalSearch: {
      enabled: true,
    },
    collectionManagement: {
      useFilter: false,
    },
    favorites: {
      allowedFavoriteTypes: [
        FavoriteTypesEnum.BOEKE,
        FavoriteTypesEnum.BUNDLE,
        FavoriteTypesEnum.EDUCONTENT,
        FavoriteTypesEnum.BOOK,
      ],
    },
    history: {
      allowedHistoryTypes: [
        HistoryTypesEnum.BOEKE,
        HistoryTypesEnum.EDUCONTENT,
        HistoryTypesEnum.AREA,
        HistoryTypesEnum.TASK,
        HistoryTypesEnum.BUNDLE,
        HistoryTypesEnum.HISTORY,
        HistoryTypesEnum.METHOD,
      ],
    },
    tours: { enabled: true },
    groups: { enabled: true },
    methods: {
      showEduNetNames: false,
    },
    news: {
      showNewsFeedToggle: false,
    },
    freshworks: {
      widgetId: 11000000600,
      productId: 11000000564,
      url: 'https://ink.staging.polpo.be/edu-contents-v2/{id}/general',
    },
  },
  sso: {
    facebook: {
      enabled: true,
      description: 'Facebook',
      logoIcon: 'facebook',
    },
    google: {
      enabled: true,
      description: 'Google',
      logoIcon: 'google',
    },
    smartschool: {
      enabled: true,
      description: 'Smartschool',
      logoIcon: 'smartschool',
      maxNumberAllowed: 10,
    },
    microsoft: {
      enabled: true,
      description: 'Microsoft',
      logoIcon: 'microsoft',
    },
    acm: {
      enabled: true,
      description: 'eID/Itsme',
      logoIcon: 'acm',
    },
    leerid: {
      enabled: true,
      provider: 'acm',
      description: 'LeerID',
      logoIcon: 'leerid',
    },
  },
  searchModes: searchModes,
  testing: {
    removeDataCyAttributes: false,
  },
  ui: {
    useInfoPanelStyle: true,
    miniCartTimeout: 3000,
    showFixedPriceSuffix: false,
    contact: {
      order: { label: 'Mail de besteldienst', link: 'mailto:so@mailinator.be' },
      helpdesk: {
        label: 'polpo@diekeure.be',
        link: 'mailto:polpo@mailinator.be',
      },
      terms: {
        label: 'de verkoopsvoorwaarden',
        link: 'https://www.staging.polpo.be/legals/verkoopsvoorwaarden',
      },
    },
  },
  time: {
    msOffsetUntilUrgent: 1000 * 60 * 60 * 48,
  },
  assets: {
    basePath: 'https://api.staging.polpo.be/app/assets/',
    images: {
      methods: 'https://www.polpo.be/assets/images/methods',
      illustrations: 'https://api.staging.polpo.be/app/assets/images/illustrations',
      news: 'https://avatar.staging.polpo.be/',
      educontent: 'https://avatar.staging.polpo.be/',
    },
    animations,
  },
  scoreMapping: [
    [70, 2],
    [50, 1],
    [0, 0],
  ],
  googleAnalytics: {
    measurementId: '',
  },
  taskFeature: {
    showPaperAmount: false,
  },
};
