import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { BundleClassGroupInterface } from '../../+models';

export const loadBundleClassGroups = createAction(
  '[BundleClassGroups] Load BundleClassGroups',
  (userId: number = null, force: boolean = false) => ({
    userId,
    force,
  })
);

export const bundleClassGroupsLoaded = createAction(
  '[BundleClassGroups] BundleClassGroups Loaded',
  props<{ bundleClassGroups: BundleClassGroupInterface[] }>()
);

export const bundleClassGroupsLoadError = createAction('[BundleClassGroups] Load Error', props<{ error: any }>());

export const addBundleClassGroup = createAction(
  '[BundleClassGroups] Add BundleClassGroup',
  props<{ bundleClassGroup: BundleClassGroupInterface }>()
);

export const upsertBundleClassGroup = createAction(
  '[BundleClassGroups] Upsert BundleClassGroup',
  props<{ bundleClassGroup: BundleClassGroupInterface }>()
);

export const addBundleClassGroups = createAction(
  '[BundleClassGroups] Add BundleClassGroups',
  props<{ bundleClassGroups: BundleClassGroupInterface[] }>()
);

export const upsertBundleClassGroups = createAction(
  '[BundleClassGroups] Upsert BundleClassGroups',
  props<{ bundleClassGroups: BundleClassGroupInterface[] }>()
);
export const updateBundleClassGroup = createAction(
  '[BundleClassGroups] Update BundleClassGroup',
  props<{ bundleClassGroup: Update<BundleClassGroupInterface> }>()
);

export const updateBundleClassGroups = createAction(
  '[BundleClassGroups] Update BundleClassGroups',
  props<{ bundleClassGroups: Update<BundleClassGroupInterface>[] }>()
);

export const deleteBundleClassGroup = createAction(
  '[BundleClassGroups] Delete BundleClassGroup',
  props<{ id: number }>()
);

export const deleteBundleClassGroups = createAction(
  '[BundleClassGroups] Delete BundleClassGroups',
  props<{ ids: number[] }>()
);

export const clearBundleClassGroups = createAction('[BundleClassGroups] Clear BundleClassGroups');

export const updateBundleClassGroupsAccess = createAction(
  '[BundleClassGroups] Update Access',
  props<{ bundleId: number; bundleClassGroups: BundleClassGroupInterface[] }>()
);
