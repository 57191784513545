import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchResultInterface, SearchStateInterface, TableApiResponseInterface } from '../+external-interfaces';
import {
  EduContentBookInterface,
  EduContentInterface,
  EduContentMetadataInterface,
  EduContentTypeEnum,
  EvaluationSubjectInterface,
  MethodOptionEduContentMetadataInterface,
  PrintSize,
} from '../+models';

export const EDU_CONTENT_SERVICE_TOKEN = new InjectionToken<EduContentServiceInterface>('EduContentService');
export interface DraftEduContentMetadataInterface extends Omit<EduContentMetadataInterface, 'methodOptions'> {
  eduContentBookIds?: number[];
  eduContentBooks?: EduContentBookInterface[];
  eduContentTOCs?: number[];
  methodOptions?: MethodOptionEduContentMetadataInterface[];
  showInSearch?: boolean;
  evaluationSubjectId?: number;
  evaluationSubject?: EvaluationSubjectInterface;
  articulateLMS?: boolean;
  isRecommendable?: boolean;
  editorName?: string;
  publicUrl?: string;
}

export interface EduContentDraftInterface extends EduContentInterface {
  draftEduContentMetadataId?: number;
  draftEduContentMetadata?: DraftEduContentMetadataInterface;
  eduContentType?: EduContentTypeEnum;
  eduContentTypeOptions?: EduContentTypeEnum[];
}

export interface UpdateDraftRelationChangesInterface {
  key: string;
  add?: (number | object)[];
  remove?: (number | object)[];
  update?: object[];
}

export interface InstructionContentInterface {
  id: number;
  eduContentId: number;
  title: string;
  eduContentType: string;
  eduContentProductType: string;
}

export interface ErrorResponseInterface {
  error: {
    code?: string;
    message?: string;
    name?: string;
    statusCode?: number;
  };
  id?: number;
}

export interface SuccessResponseInterface {
  value: {
    checksum?: string;
    editorId?: number;
    eduContentId?: number;
    eduContentMetadataId?: number;
  };
  id?: number;
}

export interface EduContentServiceInterface {
  // Use this in kabas
  getAllForUser(userId: number): Observable<EduContentInterface[]>;

  // Only use this in the INK
  getAllForBook(bookId: number): Observable<EduContentInterface[]>;

  getGeneralEduContentForBookId(bookId: number): Observable<EduContentInterface[]>;
  search(state: SearchStateInterface): Observable<SearchResultInterface>;
  autoComplete(state: SearchStateInterface): Observable<string[]>;
  printEduContent(eduContentId: number, ufpId?: number, taskId?: number, withNames?: boolean, size?: PrintSize): void;
  printCombinedEduContents(eduContentIds: number[], withSolutions?: boolean, size?: PrintSize): void;
  getLatestEduContents(): Observable<EduContentInterface[]>;
  getMyEduContents(): Observable<EduContentInterface[]>;
  getEduContents(
    searchTerm?: string,
    filters?: { [key: string]: string | number[] | number },
    pagination?: { from?; amount? },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<TableApiResponseInterface<EduContentMetadataInterface>>;

  patchEduContent(eduContentId: number, changes?: Partial<EduContentDraftInterface>): Observable<number>;
  bulkUpdateEduContents(
    ids: number[],
    personPreferenceId: number,
    fields: string[],
    changes: { [key: string]: any },
    options?: { [key: string]: any }
  );
  getEduContentDraft(eduContentId: number): Observable<EduContentDraftInterface>;
  getPublishedEduContent(eduContentId: number): Observable<EduContentDraftInterface[]>;
  updateEduContentDraftRelation(eduContentId: number, body: UpdateDraftRelationChangesInterface);
  applyTemplateToEduContentDraft(eduContentId: number, personPreferenceId: number): Observable<number>;
  createEduContentDraft(
    personPreferenceId?: number,
    eduContentBookId?: number,
    eduContentId?: number
  ): Observable<number>;
  publishEduContentDrafts(eduContentIds: number[], message?: string);
  unlockDraft(eduContentId: number): Observable<number>;
  getInstructionContentsForMethod(
    methodId: number
  ): Observable<{ results: InstructionContentInterface[]; count: number }>;
  unpublishEduContent(eduContentId: number[], ignoreInUse?: boolean): Observable<number[]>;
  removeEduContent(
    eduContentId: number[],
    ignoreInUse?: boolean
  ): Observable<{ errored: ErrorResponseInterface[]; resolved: SuccessResponseInterface[] }>;
  updateQuestions(eduContentId: number, activityItems: string[]): Observable<void>;
}
