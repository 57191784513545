import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { SectionInterface } from '../../+models';
import { SectionsActions, SectionsActionTypes } from './section.actions';

export const NAME = 'sections';

export interface State extends EntityState<SectionInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
  loadedForBook: Dictionary<boolean>;
  loadedForToc: Dictionary<boolean>;
}

export const adapter: EntityAdapter<SectionInterface> = createEntityAdapter<SectionInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForBook: {},
  loadedForToc: {},
});

export function reducer(state = initialState, action: SectionsActions): State {
  switch (action.type) {
    case SectionsActionTypes.AddSection: {
      const section =
        typeof action.payload.section.config === 'string'
          ? { ...action.payload.section, config: JSON.parse(action.payload.section.config) }
          : action.payload.section;
      return adapter.addOne(section, state);
    }

    case SectionsActionTypes.UpsertSection: {
      return adapter.upsertOne(action.payload.section, state);
    }

    case SectionsActionTypes.AddSections: {
      const sections = action.payload.sections.map((section) => {
        if (typeof section.config === 'string') {
          return { ...section, config: JSON.parse(section.config) };
        }
        return section;
      });
      return adapter.addMany(sections, state);
    }

    case SectionsActionTypes.UpsertSections: {
      return adapter.upsertMany(action.payload.sections, state);
    }

    case SectionsActionTypes.UpdateSection: {
      return adapter.updateOne(action.payload.section, state);
    }

    case SectionsActionTypes.UpdateSections: {
      return adapter.updateMany(action.payload.sections, state);
    }

    case SectionsActionTypes.DeleteSection: {
      return adapter.removeOne(action.payload.id, state);
    }

    case SectionsActionTypes.DeleteSections: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case SectionsActionTypes.SectionsLoaded: {
      return adapter.setAll(action.payload.sections, { ...state, loaded: true });
    }

    case SectionsActionTypes.SectionsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case SectionsActionTypes.ClearSections: {
      return adapter.removeAll(state);
    }

    case SectionsActionTypes.AddSectionsForToc: {
      const newState = adapter.addMany(action.payload.sections, state);
      return {
        ...newState,
        loadedForToc: {
          ...newState.loadedForToc,
          [action.payload.tocId]: true,
        },
      };
    }

    case SectionsActionTypes.AddSectionsForBook: {
      const newState = adapter.addMany(action.payload.sections, state);
      return {
        ...newState,
        loadedForBook: {
          ...newState.loadedForBook,
          [action.payload.bookId]: true,
        },
      };
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
