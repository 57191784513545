<div class="checkbox-line-filter-component__title search-filter-title" data-cy="checkbox-line-filter-label"
  >{{ filterCriteria.label }}</div
>

<div class="checkbox-line-filter-component__checkbox-holder">
  <mat-checkbox
    *ngFor="let value of filteredFilterCriteria.values"
    class="checkbox-line-filter-component__checkbox"
    [class.checkbox-line-filter-component__checkbox--disabled]="
      !value.prediction && value.selected
    "
    [disabled]="value.prediction === 0 && !value.selected"
    [checked]="value.selected"
    [value]="value"
    (change)="itemChanged(value)"
    data-cy="checkbox-line-filter-value"
    color="primary"
    >{{ getDisplayValue(value) }}</mat-checkbox
  >
</div>
