export class TimeFunctions {
  public static getMinSec(milliSeconds: number): string {
    return `${Math.floor(milliSeconds / 1000 / 60)}m ${Math.floor((milliSeconds / 1000) % 60)}s`;
  }

  public static getMin(milliSeconds: number, addMinuteString: boolean = false, includeZero: boolean = true): string {
    if (!milliSeconds && includeZero) {
      return `0${addMinuteString ? ' min.' : ''}`;
    }
    if (milliSeconds < 60000) {
      return `< 1${addMinuteString ? ' min.' : ''}`;
    }
    return `${Math.floor(milliSeconds / 1000 / 60)}${addMinuteString ? ' min.' : ''}`;
  }
}
