/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { EvaluationSubjectGoalApi as SDKEvaluationSubjectGoalApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { EvaluationSubjectGoalInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class EvaluationSubjectGoalApi extends ModelApiBase {
  private SDKEvaluationSubjectGoalApi = inject(SDKEvaluationSubjectGoalApi);

  constructor(api: PolpoApi) {
    super(api, 'EvaluationSubjectGoals');
  }

  getEvaluationSubjectGoalsForBook(bookId): Observable<EvaluationSubjectGoalInterface[]> {
    // @ts-expect-error TS 2556
    return this.SDKEvaluationSubjectGoalApi.getEvaluationSubjectGoalsForBook(...arguments);
  }
}
