import { EduContent, FavoriteInterface, FavoriteTypesEnum, HistoryInterface, HistoryTypesEnum } from '@campus/dal';

export interface QuickLinkCategoryInterface {
  type: FavoriteTypesEnum | string; //history has string as type type
  title: string;
  quickLinks: QuickLinkInterface[];
  order: number;
}

export interface QuickLinkInterface extends FavoriteInterface, HistoryInterface {
  defaultAction: QuickLinkActionInterface;
  alternativeOpenActions: QuickLinkActionInterface[];
  manageActions: QuickLinkActionInterface[];
  // override eduContent property -> is always cast to EduContent
  eduContent?: EduContent;
  icon?: string;
}

export interface QuickLinkActionInterface {
  actionType: 'open' | 'manage';
  label: string;
  icon: string;
  tooltip: string;
  handler: string;
}

export const QuickLinkCategoryMap: Map<FavoriteTypesEnum | string, { label: string; order: number }> = new Map<
  FavoriteTypesEnum | string,
  { label: string; order: number }
>([
  // Favorites
  [FavoriteTypesEnum.BOEKE, { label: 'Bordboeken', order: 0 }],
  [FavoriteTypesEnum.EDUCONTENT, { label: 'Lesmateriaal', order: 1 }],
  [FavoriteTypesEnum.SEARCH, { label: 'Zoekopdrachten', order: 2 }],
  [FavoriteTypesEnum.BUNDLE, { label: 'Bundels', order: 3 }],
  [FavoriteTypesEnum.BOOK, { label: 'Uitgaven', order: 4 }],
  // History
  [HistoryTypesEnum.BOEKE, { label: 'Bordboeken', order: 0 }],
  [HistoryTypesEnum.EDUCONTENT, { label: 'Lesmateriaal', order: 1 }],
  [HistoryTypesEnum.HISTORY, { label: 'Zoekopdrachten', order: 2 }],
  [HistoryTypesEnum.BUNDLE, { label: 'Bundels', order: 3 }],
  [HistoryTypesEnum.TASK, { label: 'Taken', order: 4 }],
  [HistoryTypesEnum.METHOD, { label: 'Methode', order: 5 }],
]);

export const quickLinkActionDictionary = {
  openEduContentAsExercise: {
    actionType: 'open',
    label: 'Openen',
    icon: 'open-in-new',
    tooltip: 'Open oefening zonder oplossingen',
    handler: 'openEduContentAsExercise',
  } as QuickLinkActionInterface,

  openEduContentAsSolution: {
    actionType: 'open',
    label: 'Toon oplossing',
    icon: 'show-solution',
    tooltip: 'Open oefening met oplossingen',
    handler: 'openEduContentAsSolution',
  } as QuickLinkActionInterface,

  openEduContentAsStream: {
    actionType: 'open',
    label: 'Openen',
    icon: 'open-in-new',
    tooltip: 'Open het lesmateriaal',
    handler: 'openEduContentAsStream',
  } as QuickLinkActionInterface,

  openEduContentAsDownload: {
    actionType: 'open',
    label: 'Openen',
    icon: 'open-in-new',
    tooltip: 'Download het lesmateriaal',
    handler: 'openEduContentAsDownload',
  } as QuickLinkActionInterface,

  openEduContentAsLink: {
    actionType: 'open',
    label: 'Openen',
    icon: 'open-in-new',
    tooltip: 'Open de link',
    handler: 'openEduContentAsLink',
  } as QuickLinkActionInterface,

  openEduContentAsHtml: {
    actionType: 'open',
    label: 'Lezen',
    icon: 'preview',
    tooltip: 'Lees de tekst',
    handler: 'openEduContentAsHtml',
  } as QuickLinkActionInterface,

  openBundle: {
    actionType: 'open',
    label: 'Openen',
    icon: 'bundle',
    tooltip: 'Navigeer naar de bundel pagina',
    handler: 'openBundle',
  } as QuickLinkActionInterface,

  openTask: {
    actionType: 'open',
    label: 'Openen',
    icon: 'task',
    tooltip: 'Navigeer naar de taken pagina',
    handler: 'openTask',
  } as QuickLinkActionInterface,

  openArea: {
    actionType: 'open',
    label: 'Openen',
    icon: 'bookshelf',
    tooltip: 'Navigeer naar de leergebied pagina',
    handler: 'openArea',
  } as QuickLinkActionInterface,

  openBoeke: {
    actionType: 'open',
    label: 'Openen',
    icon: 'boardbook',
    tooltip: 'Open het bordboek',
    handler: 'openBoeke',
  } as QuickLinkActionInterface,

  openSearch: {
    actionType: 'open',
    label: 'Openen',
    icon: 'magnifier',
    tooltip: 'Open de zoekopdracht',
    handler: 'openSearch',
  } as QuickLinkActionInterface,

  edit: {
    actionType: 'manage',
    label: 'Bewerken',
    icon: 'edit',
    tooltip: 'Pas de naam van het item aan',
    handler: 'edit',
  } as QuickLinkActionInterface,

  remove: {
    actionType: 'manage',
    label: 'Verwijderen',
    icon: 'delete',
    tooltip: 'Verwijder het item',
    handler: 'remove',
  } as QuickLinkActionInterface,

  openMethod: {
    actionType: 'open',
    label: 'Openen',
    icon: 'toc',
    tooltip: 'Open methode',
    handler: 'openMethod',
  } as QuickLinkActionInterface,
  openBook: {
    actionType: 'open',
    label: 'Openen',
    icon: 'toc',
    tooltip: 'Open uitgave',
    handler: 'openBook',
  } as QuickLinkActionInterface,
};
