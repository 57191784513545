import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { GroupApi, PersonApi } from '../+api';
import { GroupInterface } from '../+models';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  constructor(private personApi: PersonApi, private groupApi: GroupApi) {}

  public getAllForUser(userId: number): Observable<GroupInterface[]> {
    return this.personApi
      .getData(userId, 'groups') //TODO differentiate between owned groups and assigned groups?
      .pipe(map((res: { groups: GroupInterface[] }) => res.groups));
  }

  public addGroup(group: GroupInterface): Observable<GroupInterface> {
    return this.groupApi.create(group);
  }

  public updateGroup(groupId: number, changes: Partial<GroupInterface>): Observable<GroupInterface> {
    return this.groupApi.patchAttributes(groupId, changes) as Observable<GroupInterface>;
  }

  public deleteGroup(groupId: number): Observable<boolean> {
    return this.groupApi.deleteById(groupId).pipe(mapTo(true));
  }
}
