import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@campus/browser';
import { ReplaySubject } from 'rxjs';

declare global {
  interface Window {
    MathJax: {
      options: {
        ignoreHtmlClass: string;
        processHtmlClass: string;
      };
      typesetPromise?: (code?) => void;
      startup?: {
        promise: Promise<any>;
        document: {
          clear: () => void;
          updateDocument: () => void;
        };
      };
      tex2chtml?: (code: string, options?: any) => any;
    };
  }
}

export interface MathJaxConfig {
  source: string;
  integrety: string;
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class MathJaxService {
  private initializePromise: Promise<void>;
  private ready$: ReplaySubject<boolean> = new ReplaySubject<boolean>(0);
  private window = inject(WINDOW);

  private mathJax: MathJaxConfig = {
    source: 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/3.2.2/es5/tex-chtml.min.js',
    integrety: 'sha512-T8xxpazDtODy3WOP/c6hvQI2O9UPdARlDWE0CvH1Cfqc0TXZF6GZcEKL7tIR8VbfS/7s/J6C+VOqrD6hIo++vQ==',
    id: 'MathJaxScript',
  };

  constructor() {
    this.registerMathJaxAsync(this.mathJax).then(() => {
      this.ready$.next(true);
    });
  }

  protected async registerMathJaxAsync(mathJax: MathJaxConfig): Promise<void> {
    if (document.getElementById(mathJax.id) || this.initializePromise) {
      return this.initializePromise;
    }
    this.window.MathJax = {
      options: {
        ignoreHtmlClass: 'no-mathjax',
        processHtmlClass: 'process-mathjax',
      },
    };

    this.initializePromise = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = mathJax.source;
      script.integrity = mathJax.integrety;
      script.id = mathJax.id;
      script.crossOrigin = 'anonymous';
      script.async = false;
      script.onload = () => {
        resolve();
      };
      script.onerror = () => {
        reject();
      };
      document.getElementsByTagName('head')[0].appendChild(script);
    });

    return this.initializePromise;
  }

  public ready() {
    return this.ready$;
  }

  public async render(input: string) {
    if (input === null || input === undefined) return '';

    if (!this.window.MathJax) {
      await this.registerMathJaxAsync(this.mathJax);
    }

    const element = document.createElement('div');
    await this.window.MathJax.startup.promise;
    element.innerHTML = '';
    const regex = /\${1,2}(.*?)\${1,2}/g;
    const output = input.replace(regex, (match, latex) => {
      const inline = !(match.startsWith('$$') && match.endsWith('$$'));
      const html = this.window.MathJax.tex2chtml(latex, { display: inline });

      return html.outerHTML;
    });

    this.window.MathJax.startup.document.clear();
    this.window.MathJax.startup.document.updateDocument();

    return output;
  }
}
