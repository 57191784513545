import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PersonApi } from '../+api';
import { AlgebrakitSessionInterface } from '../+models';
import { AlgebrakitSessionServiceInterface } from './algebrakit-session.service.interface';

@Injectable({
  providedIn: 'root',
})
export class AlgebrakitSessionService implements AlgebrakitSessionServiceInterface {
  constructor(private personApi: PersonApi) {}

  getAll(userId: number) {
    return this.personApi.getData(userId, 'algebrakitSessions').pipe(
      map((res: { algebrakitSessions: AlgebrakitSessionInterface[] }) =>
        // Akit sessions are store with 0-based level (cfr specs of Akit API)
        // For frontend we show 1-based levels
        res.algebrakitSessions.map((session) => ({ ...session, level: session.level + 1 }))
      )
    );
  }
}
