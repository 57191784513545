import { findManyInArray, findOneInArray } from '@campus/utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MethodGoalInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './method-goal.reducer';

export const selectMethodGoalState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectMethodGoalState, (state: State) => state.error);

export const getLoaded = createSelector(selectMethodGoalState, (state: State) => state.loaded);

export const getAll = createSelector(selectMethodGoalState, selectAll);

export const getCount = createSelector(selectMethodGoalState, selectTotal);

export const getIds = createSelector(selectMethodGoalState, selectIds);

export const getAllEntities = createSelector(selectMethodGoalState, selectEntities);

export const getByIds = createSelector(selectMethodGoalState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});
export const getById = createSelector(
  selectMethodGoalState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const findOne = createSelector(selectMethodGoalState, (state: State, props: Partial<MethodGoalInterface>) =>
  findOneInArray(Object.values(state.entities), props)
);

export const findMany = createSelector(selectMethodGoalState, (state: State, props: Partial<MethodGoalInterface>) =>
  findManyInArray(Object.values(state.entities), props)
);

export const isBookLoaded = (props: { bookId: number }) =>
  createSelector(selectMethodGoalState, (state: State) => !!state.loadedForBook[props.bookId]);

export const getByBookId = createSelector(selectMethodGoalState, (state: State, props: { bookId: number }) =>
  (state.loadedForBook[props.bookId] || []).map((id) => state.entities[id])
);
