import {
  ChangeDetectorRef,
  Directive,
  HostBinding,
  inject,
  InjectionToken,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ThemeService } from './../services/theme/theme.service';

interface FontInterface {
  url: string;
  name: string;
}

interface ExceptionsInterface {
  [key: number]: string;
}

export interface CustomPropertyInterface {
  [key: string]: string;
}

interface ThemeInterface {
  baseColor?: string;
  exceptions?: {
    primary?: ExceptionsInterface;
    neutral?: ExceptionsInterface;
    neutralVariant?: ExceptionsInterface;
  };
  font?: FontInterface;
  customProperties?: CustomPropertyInterface;
}
export interface StyleInterface {
  theme?: ThemeInterface;
  rte?: object[];
}

interface MethodStyleInterface {
  id?: number;
  style: StyleInterface;
  methodId?: number;
  // method?: MethodInterface;
  eduContentBookId?: number;
  // eduContentBook?: EduContentBookInterface;
}

export const METHOD_STYLE_TOKEN = new InjectionToken<GetMethodStyle>('MethodStyleToken');
export type GetMethodStyle = (eduContentBookId: number) => Observable<MethodStyleInterface>;

@Directive({
  selector: '[campusBookTheme],[book-theme]',
})
export class BookThemeDirective implements OnChanges {
  @HostBinding('class') className: string;

  //#region Inputs
  @Input() eduContentBookId: number;
  //#endregion

  //#region Dependencies
  private renderer = inject(Renderer2);
  private themeService: ThemeService = inject(ThemeService);
  private styleProviderFunction = inject(METHOD_STYLE_TOKEN);

  private _cd = inject(ChangeDetectorRef);

  //#endregion

  //#region Private properties
  private styleElement?: HTMLStyleElement;
  //#endregion

  //#region Lifecycle hooks
  ngOnChanges(changes: SimpleChanges) {
    this._removeStyle();

    if (changes.eduContentBookId?.currentValue) {
      this._addStyle(changes.eduContentBookId.currentValue);
    }
  }

  ngOndestroy() {
    this._removeStyle();
  }
  //#endregion

  //#region Private methods
  private _removeStyle() {
    if (!this.styleElement) return;

    this.renderer.removeChild(document.head, this.styleElement);
    this.styleElement = undefined;
    this.className = '';
  }

  private _addStyle(bookId: number) {
    this.styleProviderFunction(bookId)
      .pipe(take(1))
      .subscribe((methodStyle: MethodStyleInterface) => {
        if (!methodStyle) return;

        this.className = this._getClassName(bookId);
        const styling = this.themeService.getStyling(methodStyle.style, `.${this.className}`);

        const styleElement = this.renderer.createElement('style') as HTMLStyleElement;
        this.renderer.setAttribute(styleElement, 'type', 'text/css');
        this.renderer.setProperty(styleElement, 'textContent', styling);
        this.renderer.appendChild(document.head, styleElement);
        this.styleElement = styleElement;

        this._cd.markForCheck();
      });
  }

  private _getClassName(bookId: number) {
    return `cds-edition-theme-${bookId}`;
  }
  //#endregion
}
