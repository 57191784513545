import { DateFunctions } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PersonInterface, TeacherInterface } from '../../+models';
import { getTeachers } from '../linked-person/person-role.selector';
import { getAllEvaluationTasksWithAssignments } from '../task/task.selectors';
import { NAME, State } from './user.reducer';

// Lookup the 'User' feature state managed by NgRx
export const getUserState = createFeatureSelector<State>(NAME);

export const getLoaded = createSelector(getUserState, (state: State) => state.loaded);
export const getError = createSelector(getUserState, (state: State) => state.error);

export const getCurrentUser = createSelector(getUserState, (state: State) => state.currentUser);

export const getPermissionsLoaded = createSelector(getUserState, (state: State) => state.permissionsLoaded);

export const getPermissions = createSelector(getUserState, (state: State) => state.permissions);
export const getPermissionsDict = createSelector(getUserState, (state: State): Dictionary<boolean> => {
  return state.permissions.reduce((acc, permission) => {
    return {
      ...acc,
      [permission]: true,
    };
  }, {});
});

export const getStudentWelcomeMessage = createSelector(getCurrentUser, (user: PersonInterface) => {
  const timeOfDayGreeting = DateFunctions.timeOfDayGreeting(new Date());
  return `${timeOfDayGreeting} ${user.firstName}!\nWat wil je vandaag leren?`;
});

export const getTeachersForEvaluationTasks = createSelector(
  getAllEvaluationTasksWithAssignments,
  getCurrentUser,
  getTeachers,
  (evaluationTasksWithAssignments, currentUser, teachers) => {
    const teacherIds = new Set(evaluationTasksWithAssignments.map((task) => task.personId));
    const teachersFromIds = teachers.filter((teacher) => teacherIds.has(teacher.id));

    if (!teachersFromIds.some((teacher) => teacher.id === currentUser.id)) {
      teachersFromIds.push(currentUser as TeacherInterface);
    }

    return teachersFromIds;
  }
);
