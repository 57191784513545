import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { PurchaseProductInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum PurchaseProductsActionTypes {
  PurchaseProductsLoaded = '[PurchaseProducts] PurchaseProducts Loaded',
  PurchaseProductsLoadError = '[PurchaseProducts] Load Error',
  LoadPurchaseProducts = '[PurchaseProducts] Load PurchaseProducts',
  StartAddManyPurchaseProducts = '[PurchaseProducts] Start Add PurchaseProducts',
  AddPurchaseProduct = '[PurchaseProducts] Add PurchaseProduct',
  UpsertPurchaseProduct = '[PurchaseProducts] Upsert PurchaseProduct',
  AddPurchaseProducts = '[PurchaseProducts] Add PurchaseProducts',
  UpsertPurchaseProducts = '[PurchaseProducts] Upsert PurchaseProducts',
  UpdatePurchaseProduct = '[PurchaseProducts] Update PurchaseProduct',
  UpdatePurchaseProducts = '[PurchaseProducts] Update PurchaseProducts',
  DeletePurchaseProduct = '[PurchaseProducts] Delete PurchaseProduct',
  DeletePurchaseProducts = '[PurchaseProducts] Delete PurchaseProducts',
  ClearPurchaseProducts = '[PurchaseProducts] Clear PurchaseProducts',
}

export class LoadPurchaseProducts implements Action {
  readonly type = PurchaseProductsActionTypes.LoadPurchaseProducts;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class PurchaseProductsLoaded implements Action {
  readonly type = PurchaseProductsActionTypes.PurchaseProductsLoaded;

  constructor(public payload: { purchaseProducts: PurchaseProductInterface[] }) {}
}

export class PurchaseProductsLoadError implements Action {
  readonly type = PurchaseProductsActionTypes.PurchaseProductsLoadError;
  constructor(public payload: any) {}
}

export class StartAddManyPurchaseProducts implements Action {
  readonly type = PurchaseProductsActionTypes.StartAddManyPurchaseProducts;

  constructor(
    public payload: {
      userId: number;
      purchaseProducts: PurchaseProductInterface[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class AddPurchaseProduct implements Action {
  readonly type = PurchaseProductsActionTypes.AddPurchaseProduct;

  constructor(public payload: { purchaseProduct: PurchaseProductInterface }) {}
}

export class UpsertPurchaseProduct implements Action {
  readonly type = PurchaseProductsActionTypes.UpsertPurchaseProduct;

  constructor(public payload: { purchaseProduct: PurchaseProductInterface }) {}
}

export class AddPurchaseProducts implements Action {
  readonly type = PurchaseProductsActionTypes.AddPurchaseProducts;

  constructor(public payload: { purchaseProducts: PurchaseProductInterface[] }) {}
}

export class UpsertPurchaseProducts implements Action {
  readonly type = PurchaseProductsActionTypes.UpsertPurchaseProducts;

  constructor(public payload: { purchaseProducts: PurchaseProductInterface[] }) {}
}

export class UpdatePurchaseProduct implements Action {
  readonly type = PurchaseProductsActionTypes.UpdatePurchaseProduct;

  constructor(public payload: { purchaseProduct: Update<PurchaseProductInterface> }) {}
}

export class UpdatePurchaseProducts implements Action {
  readonly type = PurchaseProductsActionTypes.UpdatePurchaseProducts;

  constructor(public payload: { purchaseProducts: Update<PurchaseProductInterface>[] }) {}
}

export class DeletePurchaseProduct {
  readonly type = PurchaseProductsActionTypes.DeletePurchaseProduct;

  constructor(
    public payload: {
      id: number;
    }
  ) {}
}

export class DeletePurchaseProducts implements FeedbackTriggeringAction {
  readonly type = PurchaseProductsActionTypes.DeletePurchaseProducts;

  constructor(
    public payload: {
      ids: number[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class ClearPurchaseProducts implements Action {
  readonly type = PurchaseProductsActionTypes.ClearPurchaseProducts;
}

export type PurchaseProductsActions =
  | LoadPurchaseProducts
  | PurchaseProductsLoaded
  | PurchaseProductsLoadError
  | AddPurchaseProduct
  | StartAddManyPurchaseProducts
  | UpsertPurchaseProduct
  | AddPurchaseProducts
  | UpsertPurchaseProducts
  | UpdatePurchaseProduct
  | UpdatePurchaseProducts
  | DeletePurchaseProduct
  | DeletePurchaseProducts
  | ClearPurchaseProducts;
