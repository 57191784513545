import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EduContentHighScoreApi } from '../+api';
import { EduContentHighScoreInterface } from '../+models/EduContentHighScore.interface';
import { AUTH_SERVICE_TOKEN, AuthServiceInterface } from '../persons';
import { EduContentHighScoreServiceInterface } from './edu-content-high-score.service.interface';

@Injectable({
  providedIn: 'root',
})
export class EduContentHighScoreService implements EduContentHighScoreServiceInterface {
  constructor(
    private eduContentHighScoreApi: EduContentHighScoreApi,
    @Inject(AUTH_SERVICE_TOKEN) private authService: AuthServiceInterface
  ) {}

  public getHighScoreForEduContent(eduContentId: number): Observable<EduContentHighScoreInterface> {
    return this.eduContentHighScoreApi
      .getHighScores(eduContentId, this.authService.userId)
      .pipe(map((highScores) => highScores[0]));
  }

  public updateHighScoreForEduContent(eduContentId: number, score: number): Observable<EduContentHighScoreInterface> {
    return this.eduContentHighScoreApi.updateHighScore(eduContentId, this.authService.userId, score);
  }
}
