import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AtlasProductInterface } from '../../+models';
import { AtlasProductsActions, AtlasProductsActionTypes } from './atlas-product.actions';

export const NAME = 'atlasProducts';

export interface State extends EntityState<AtlasProductInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<AtlasProductInterface> = createEntityAdapter<AtlasProductInterface>({
  selectId: (entity) => entity.stockNr,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: AtlasProductsActions): State {
  switch (action.type) {
    case AtlasProductsActionTypes.AtlasProductsLoaded: {
      return adapter.setAll(action.payload.atlasProducts, {
        ...state,
        loaded: true,
      });
    }

    case AtlasProductsActionTypes.AtlasProductsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
