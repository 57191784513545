import { makeTree, NumberFunctions } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EduContentTOCInterface } from '../../+models';
import { UnlockedFreePracticeQueries, UnlockedFreePracticeReducer } from '../unlocked-free-practice';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './edu-content-toc.reducer';

export const selectEduContentTocState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectEduContentTocState, (state: State) => state.error);

export const getAll = createSelector(selectEduContentTocState, selectAll);

export const getCount = createSelector(selectEduContentTocState, selectTotal);

export const getIds = createSelector(selectEduContentTocState, selectIds);

export const getAllEntities = createSelector(selectEduContentTocState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * eduContentToc$: EduContentTocInterface[] = this.store.pipe(
    select(EduContentTocQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectEduContentTocState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * eduContentToc$: EduContentTocInterface = this.store.pipe(
    select(EduContentTocQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectEduContentTocState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const isBookLoaded = createSelector(
  selectEduContentTocState,
  (state: State, props: { bookId: number }) => !!state.loadedForBook[props.bookId]
);

export const getTocsByBook = createSelector(selectEduContentTocState, (state: State) =>
  (state.ids as number[]).reduce((acc, currentTocId) => {
    const currentToc = state.entities[currentTocId];
    if (!acc[currentToc.treeId]) {
      acc[currentToc.treeId] = [];
    }
    acc[currentToc.treeId].push(currentToc);
    return acc;
  }, {} as Dictionary<EduContentTOCInterface[]>)
);

export const getTocsForBook = (props: { bookId: number }) =>
  createSelector(getTocsByBook, (tocsByBook: Dictionary<EduContentTOCInterface[]>) => tocsByBook[props.bookId] || []);

export const getTreeForBook = (props: { bookId: number; isFreePracticeEnabled?: boolean }) =>
  createSelector(getTocsForBook(props), (tocs: EduContentTOCInterface[]) => {
    if (props.isFreePracticeEnabled) {
      tocs = tocs.filter((toc) => toc.isFreePracticeEnabled);
    }
    // The tocs must be ordered by their lfts in order for makeTree to work
    const sortedTocs = tocs.sort((aToc, bToc) => aToc.lft - bToc.lft);

    return makeTree<EduContentTOCInterface>(sortedTocs);
  });

export const getTreeByBook = createSelector(getTocsByBook, (tocsByBook) => {
  return Object.keys(tocsByBook).reduce((acc, bookId) => {
    const tocs = tocsByBook[bookId];
    // The tocs must be ordered by their lfts in order for makeTree to work
    const sortedTocs = tocs.sort((aToc, bToc) => aToc.lft - bToc.lft);

    const tree = makeTree<EduContentTOCInterface>(sortedTocs);
    acc[bookId] = tree;
    return acc;
  }, {} as Dictionary<EduContentTOCInterface[]>);
});

export const getChaptersForBook = createSelector(selectEduContentTocState, (state: State, props: { bookId: number }) =>
  (state.ids as number[]).reduce((acc, currentTocId) => {
    const currentToc = state.entities[currentTocId];
    if (currentToc.treeId === props.bookId && currentToc.depth === 0) {
      acc.push(currentToc);
    }

    return acc;
  }, [])
);

// returns the direct descendant tocs for a given toc, by tocId
export const getTocsForToc = createSelector(
  selectEduContentTocState,
  (state: State, props: { tocId: number; isFreePracticeEnabled?: boolean }) => {
    const parentToc = state.entities[props.tocId];

    return (state.ids as number[]).reduce((acc, currentTocId) => {
      const currentToc = state.entities[currentTocId];

      if (props.isFreePracticeEnabled && !currentToc.isFreePracticeEnabled) {
        return acc;
      }

      if (
        currentToc.treeId === parentToc.treeId && // same book
        currentToc.lft > parentToc.lft &&
        currentToc.rgt < parentToc.rgt && //is a child of parentToc
        currentToc.depth === parentToc.depth + 1 // correct depth
      ) {
        acc.push(currentToc);
      }
      return acc;
    }, [] as EduContentTOCInterface[]);
  }
);

// returns all descendant tocs for a given toc, by tocId, optionally including that toc itself
export const getAllTocsForToc = (props: { tocId: number; includeSelf?: boolean }) =>
  createSelector(
    getAllEntities,
    getTocsByBook,
    (
      tocDict: Dictionary<EduContentTOCInterface>,
      tocsByBook: Dictionary<EduContentTOCInterface[]>
    ): EduContentTOCInterface[] => {
      const { tocId, includeSelf } = props;
      if (!tocDict[tocId]) return [];
      const { treeId, lft, rgt } = tocDict[tocId];

      return tocsByBook[treeId].filter((toc) => {
        if (includeSelf && toc.id === tocId) {
          return true;
        }

        return toc.lft > lft && toc.rgt < rgt;
      });
    }
  );

// returns all ancestors tocs for a given toc, by tocId, optionally including that toc itself
export const getAllAncestorsForToc = (props: { tocId: number; includeSelf?: boolean }) =>
  createSelector(
    getTocsByBook,
    getAllEntities,
    (tocsByBook: Dictionary<EduContentTOCInterface[]>, tocDict): EduContentTOCInterface[] => {
      const { tocId, includeSelf } = props;
      if (!tocDict[tocId]) return [];

      const { treeId, lft, rgt } = tocDict[tocId];
      return (tocsByBook[treeId] || []).filter((toc) => {
        if (includeSelf && toc.id === tocId) {
          return true;
        }

        return toc.lft < lft && toc.rgt > rgt;
      });
    }
  );

export const getEvaluationsForBook = (props: { bookId: number }) =>
  createSelector(getTocsForBook(props), (tocs: EduContentTOCInterface[]) => {
    const evaluations = tocs.filter((toc) => toc.isEvaluation === true);
    return evaluations;
  });
export const getEvaluationsByBook = createSelector(
  getTocsByBook,
  (tocsByBook: Dictionary<EduContentTOCInterface[]>) => {
    const evaluationsByBook = Object.keys(tocsByBook).reduce((acc, bookId) => {
      acc[bookId] = tocsByBook[bookId].filter((toc) => toc.isEvaluation === true);
      return acc;
    }, {});
    return evaluationsByBook;
  }
);

export const getParentByTocId = createSelector(getTocsByBook, (tocsByBook: Dictionary<EduContentTOCInterface[]>) => {
  const parentByTocId: Dictionary<EduContentTOCInterface> = {};

  const isChild = (parent: EduContentTOCInterface, child: EduContentTOCInterface) =>
    parent.depth === child.depth - 1 && parent.lft < child.lft && child.rgt < parent.rgt;

  // because tocs are sorted by lft, a toc is either:
  // - a root element (depth === 0)
  // - a child of the previous toc
  // - a child of one of the parents of the previous toc (check parents recursively)
  const findParentToc = (child: EduContentTOCInterface, parent: EduContentTOCInterface) => {
    if (child.depth === 0) {
      return null;
    }

    if (!parent) {
      console.warn(`no parent found in nested set for toc ${child.id}`);
      return null;
    }

    if (isChild(parent, child)) {
      return parent;
    }

    return findParentToc(child, parentByTocId[parent.id]);
  };

  Object.values(tocsByBook).forEach((tocs) => {
    tocs.forEach((toc, i) => {
      parentByTocId[toc.id] = findParentToc(toc, tocs[i - 1]);
    });
  });

  return parentByTocId;
});

export type UnlockedEduContentToc = EduContentTOCInterface & { unlockedFreePracticeIds: number[] };
export const getUnlockedTocsByBook = createSelector(
  getAll,
  UnlockedFreePracticeQueries.selectUnlockedFreePracticeState,
  (tocs: EduContentTOCInterface[], unlockedFreePracticeState: UnlockedFreePracticeReducer.State) => {
    const addToMap = <T>(map: Map<number, T[]>, key: number, values: T | T[]) => {
      if (!map.has(key)) map.set(key, []);
      values = Array.isArray(values) ? values : [values];
      map.get(key).push(...values);
    };
    const addToSet = <T>(items: T[], set: Set<T>) => items.forEach((item) => set.add(item));

    const ufps = unlockedFreePracticeState.ids.map((id) => unlockedFreePracticeState.entities[id]);

    const { unlockedBooks, unlockedTocs } = ufps.reduce(
      (acc, ufp) => {
        if (!ufp.eduContentTOCId) {
          addToMap(acc.unlockedBooks, ufp.eduContentBookId, ufp.id);
          return acc;
        }

        addToMap(acc.unlockedTocs, ufp.eduContentTOCId, ufp.id);
        return acc;
      },
      { unlockedBooks: new Map<number, number[]>(), unlockedTocs: new Map<number, number[]>() }
    );

    // FYI: this assumes tocs are ordered by lft
    // and they are sorted in the reducer
    // This means that parents will always be processed before their children
    const { unlockedTocsByBook } = tocs.reduce(
      (acc, toc) => {
        if (!acc.unlockedTocsByBook[toc.treeId]) acc.unlockedTocsByBook[toc.treeId] = [];

        const unlockedFreePracticeIds = new Set<number>();

        // Entire book unlocked
        if (unlockedBooks.has(toc.treeId)) {
          addToSet(unlockedBooks.get(toc.treeId), unlockedFreePracticeIds);
        }

        if (!acc.lftByBook[toc.treeId]) acc.lftByBook[toc.treeId] = new Map<number, number[]>();

        // Already unlocked by a parent
        if (acc.lftByBook[toc.treeId].has(toc.lft)) {
          const ids = acc.lftByBook[toc.treeId].get(toc.lft);
          addToSet(ids, unlockedFreePracticeIds);
        }

        // Direct unlock
        if (unlockedTocs.has(toc.id)) {
          const ids = unlockedTocs.get(toc.id);

          NumberFunctions.range(toc.lft, toc.rgt).forEach((lft) => addToMap(acc.lftByBook[toc.treeId], lft, ids));
          addToSet(ids, unlockedFreePracticeIds);
        }

        if (unlockedFreePracticeIds.size > 0) {
          acc.unlockedTocsByBook[toc.treeId].push({ ...toc, unlockedFreePracticeIds: [...unlockedFreePracticeIds] });
        }

        return acc;
      },
      { unlockedTocsByBook: {}, lftByBook: {} }
    );

    return unlockedTocsByBook as Dictionary<UnlockedEduContentToc[]>;
  }
);
