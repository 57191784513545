import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { AtlasSchoolInterface } from '../../+models';

export enum AtlasSchoolsActionTypes {
  AtlasSchoolsLoaded = '[AtlasSchools] AtlasSchools Loaded',
  AtlasSchoolsLoadError = '[AtlasSchools] Load Error',
  LoadAtlasSchools = '[AtlasSchools] Load AtlasSchools',
  AddAtlasSchool = '[AtlasSchools] Add AtlasSchool',
  UpsertAtlasSchool = '[AtlasSchools] Upsert AtlasSchool',
  AddAtlasSchools = '[AtlasSchools] Add AtlasSchools',
  UpsertAtlasSchools = '[AtlasSchools] Upsert AtlasSchools',
  UpdateAtlasSchool = '[AtlasSchools] Update AtlasSchool',
  UpdateAtlasSchools = '[AtlasSchools] Update AtlasSchools',
  DeleteAtlasSchool = '[AtlasSchools] Delete AtlasSchool',
  DeleteAtlasSchools = '[AtlasSchools] Delete AtlasSchools',
  ClearAtlasSchools = '[AtlasSchools] Clear AtlasSchools',
}

export class LoadAtlasSchools implements Action {
  readonly type = AtlasSchoolsActionTypes.LoadAtlasSchools;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class AtlasSchoolsLoaded implements Action {
  readonly type = AtlasSchoolsActionTypes.AtlasSchoolsLoaded;

  constructor(public payload: { atlasSchools: AtlasSchoolInterface[] }) {}
}

export class AtlasSchoolsLoadError implements Action {
  readonly type = AtlasSchoolsActionTypes.AtlasSchoolsLoadError;
  constructor(public payload: any) {}
}

export class AddAtlasSchool implements Action {
  readonly type = AtlasSchoolsActionTypes.AddAtlasSchool;

  constructor(public payload: { atlasSchool: AtlasSchoolInterface }) {}
}

export class UpsertAtlasSchool implements Action {
  readonly type = AtlasSchoolsActionTypes.UpsertAtlasSchool;

  constructor(public payload: { atlasSchool: AtlasSchoolInterface }) {}
}

export class AddAtlasSchools implements Action {
  readonly type = AtlasSchoolsActionTypes.AddAtlasSchools;

  constructor(public payload: { atlasSchools: AtlasSchoolInterface[] }) {}
}

export class UpsertAtlasSchools implements Action {
  readonly type = AtlasSchoolsActionTypes.UpsertAtlasSchools;

  constructor(public payload: { atlasSchools: AtlasSchoolInterface[] }) {}
}

export class UpdateAtlasSchool implements Action {
  readonly type = AtlasSchoolsActionTypes.UpdateAtlasSchool;

  constructor(public payload: { atlasSchool: Update<AtlasSchoolInterface> }) {}
}

export class UpdateAtlasSchools implements Action {
  readonly type = AtlasSchoolsActionTypes.UpdateAtlasSchools;

  constructor(public payload: { atlasSchools: Update<AtlasSchoolInterface>[] }) {}
}

export class DeleteAtlasSchool implements Action {
  readonly type = AtlasSchoolsActionTypes.DeleteAtlasSchool;

  constructor(public payload: { id: number }) {}
}

export class DeleteAtlasSchools implements Action {
  readonly type = AtlasSchoolsActionTypes.DeleteAtlasSchools;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearAtlasSchools implements Action {
  readonly type = AtlasSchoolsActionTypes.ClearAtlasSchools;
}

export type AtlasSchoolsActions =
  | LoadAtlasSchools
  | AtlasSchoolsLoaded
  | AtlasSchoolsLoadError
  | AddAtlasSchool
  | UpsertAtlasSchool
  | AddAtlasSchools
  | UpsertAtlasSchools
  | UpdateAtlasSchool
  | UpdateAtlasSchools
  | DeleteAtlasSchool
  | DeleteAtlasSchools
  | ClearAtlasSchools;
