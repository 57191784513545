import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SchoolProviderInterface } from '../../+models';
import { SchoolProvidersActions, SchoolProvidersActionTypes } from './school-provider.actions';

export const NAME = 'schoolProviders';

export interface State extends EntityState<SchoolProviderInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<SchoolProviderInterface> = createEntityAdapter<SchoolProviderInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: SchoolProvidersActions): State {
  switch (action.type) {
    case SchoolProvidersActionTypes.AddSchoolProvider: {
      return adapter.addOne(action.payload.schoolProvider, state);
    }

    case SchoolProvidersActionTypes.UpsertSchoolProvider: {
      return adapter.upsertOne(action.payload.schoolProvider, state);
    }

    case SchoolProvidersActionTypes.AddSchoolProviders: {
      return adapter.addMany(action.payload.schoolProviders, state);
    }

    case SchoolProvidersActionTypes.UpsertSchoolProviders: {
      return adapter.upsertMany(action.payload.schoolProviders, state);
    }

    case SchoolProvidersActionTypes.UpdateSchoolProvider: {
      return adapter.updateOne(action.payload.schoolProvider, state);
    }

    case SchoolProvidersActionTypes.UpdateSchoolProviders: {
      return adapter.updateMany(action.payload.schoolProviders, state);
    }

    case SchoolProvidersActionTypes.DeleteSchoolProvider: {
      return adapter.removeOne(action.payload.id, state);
    }

    case SchoolProvidersActionTypes.DeleteSchoolProviders: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case SchoolProvidersActionTypes.SchoolProvidersLoaded: {
      return adapter.setAll(action.payload.schoolProviders, { ...state, loaded: true });
    }

    case SchoolProvidersActionTypes.SchoolProvidersLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case SchoolProvidersActionTypes.ClearSchoolProviders: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
