import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonApi } from '../+api';
import { BundlePersonInterface } from '../+models';
import { BundlePersonServiceInterface } from './bundle-person.service.interface';

@Injectable({
  providedIn: 'root',
})
export class BundlePersonService implements BundlePersonServiceInterface {
  constructor(private personApi: PersonApi) {}

  public getAllForUser(userId: number): Observable<BundlePersonInterface[]> {
    return this.personApi
      .getData(userId, 'bundlePersons')
      .pipe(map((res: { bundlePersons: BundlePersonInterface[] }) => res.bundlePersons));
  }
}
