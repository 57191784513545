import { InjectionToken } from '@angular/core';

export const OLF_SERVICE_TOKEN = new InjectionToken('OlfService');

export interface OlfServiceInterface {
  download(eduContentBookTitle: string, eduContentBookId: number, includeEmptyLessons: boolean);
  downloadYearplan(eduContentBookTitle: string, eduContentBookId: number);
  downloadConnectedGoals(eduContentBookTitle: string, eduContentBookId: number);
  downloadNotConnectedGoals(eduContentBookTitle: string, eduContentBookId: number);
}
