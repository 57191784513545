import { Action } from '@ngrx/store';
import { DidacticFunctionInterface } from '../../+models/DidacticFunction.interface';

export enum DidacticFunctionsActionTypes {
  DidacticFunctionsLoaded = '[DidacticFunctions] DidacticFunctions Loaded',
  DidacticFunctionsLoadError = '[DidacticFunctions] Load Error',
  LoadDidacticFunctions = '[DidacticFunctions] Load DidacticFunctions',
}

export class LoadDidacticFunctions implements Action {
  readonly type = DidacticFunctionsActionTypes.LoadDidacticFunctions;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class DidacticFunctionsLoaded implements Action {
  readonly type = DidacticFunctionsActionTypes.DidacticFunctionsLoaded;

  constructor(public payload: { didacticFunctions: DidacticFunctionInterface[] }) {}
}

export class DidacticFunctionsLoadError implements Action {
  readonly type = DidacticFunctionsActionTypes.DidacticFunctionsLoadError;
  constructor(public payload: any) {}
}

export type DidacticFunctionsActions = LoadDidacticFunctions | DidacticFunctionsLoaded | DidacticFunctionsLoadError;
