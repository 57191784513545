import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { LearnerProfileConceptInterface } from '../../+models';

export enum LearnerProfileConceptsActionTypes {
  LearnerProfileConceptsLoaded = '[LearnerProfileConcepts] LearnerProfiles Loaded (per concept)',
  LearnerProfileConceptsLoadError = '[LearnerProfileConcepts] Load Error',
  LoadLearnerProfileConcepts = '[LearnerProfileConcepts] Load LearnerProfiles (per concept)',
  AddLearnerProfileConcept = '[LearnerProfileConcepts] Add LearnerProfileConcept',
  UpsertLearnerProfileConcept = '[LearnerProfileConcepts] Upsert LearnerProfileConcept',
  AddLearnerProfileConcepts = '[LearnerProfileConcepts] Add LearnerProfileConcepts',
  LoadLearnerProfileConceptsForCourse = '[LearnerProfileConcepts] Load LearnerProfileConcepts for Course',
  AddLearnerProfileConceptsForCourse = '[LearnerProfileConcepts] Add LearnerProfileConcepts for Course',
  UpsertLearnerProfileConcepts = '[LearnerProfileConcepts] Upsert LearnerProfileConcepts',
  UpdateLearnerProfileConcept = '[LearnerProfileConcepts] Update LearnerProfileConcept',
  UpdateLearnerProfileConcepts = '[LearnerProfileConcepts] Update LearnerProfileConcepts',
  DeleteLearnerProfileConcept = '[LearnerProfileConcepts] Delete LearnerProfileConcept',
  DeleteLearnerProfileConcepts = '[LearnerProfileConcepts] Delete LearnerProfileConcepts',
  ClearLearnerProfileConcepts = '[LearnerProfileConcepts] Clear LearnerProfileConcepts',
}

export class LoadLearnerProfileConcepts implements Action {
  readonly type = LearnerProfileConceptsActionTypes.LoadLearnerProfileConcepts;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class LearnerProfileConceptsLoaded implements Action {
  readonly type = LearnerProfileConceptsActionTypes.LearnerProfileConceptsLoaded;

  constructor(public payload: { learnerProfileConcepts: LearnerProfileConceptInterface[] }) {}
}

export class LearnerProfileConceptsLoadError implements Action {
  readonly type = LearnerProfileConceptsActionTypes.LearnerProfileConceptsLoadError;
  constructor(public payload: any) {}
}

export class AddLearnerProfileConcept implements Action {
  readonly type = LearnerProfileConceptsActionTypes.AddLearnerProfileConcept;

  constructor(public payload: { learnerProfileConcept: LearnerProfileConceptInterface }) {}
}

export class UpsertLearnerProfileConcept implements Action {
  readonly type = LearnerProfileConceptsActionTypes.UpsertLearnerProfileConcept;

  constructor(public payload: { learnerProfileConcept: LearnerProfileConceptInterface }) {}
}

export class AddLearnerProfileConcepts implements Action {
  readonly type = LearnerProfileConceptsActionTypes.AddLearnerProfileConcepts;

  constructor(public payload: { learnerProfileConcepts: LearnerProfileConceptInterface[] }) {}
}

export class LoadLearnerProfileConceptsForCourse implements Action {
  readonly type = LearnerProfileConceptsActionTypes.LoadLearnerProfileConceptsForCourse;

  constructor(
    public payload: {
      curriculum: string;
      course: string;
      force?: boolean;
    }
  ) {}
}

export class AddLearnerProfileConceptsForCourse implements Action {
  readonly type = LearnerProfileConceptsActionTypes.AddLearnerProfileConceptsForCourse;

  constructor(
    public payload: {
      learnerProfileConcepts: LearnerProfileConceptInterface[];
      course: string;
    }
  ) {}
}

export class UpsertLearnerProfileConcepts implements Action {
  readonly type = LearnerProfileConceptsActionTypes.UpsertLearnerProfileConcepts;

  constructor(public payload: { learnerProfileConcepts: LearnerProfileConceptInterface[] }) {}
}

export class UpdateLearnerProfileConcept implements Action {
  readonly type = LearnerProfileConceptsActionTypes.UpdateLearnerProfileConcept;

  constructor(
    public payload: {
      learnerProfileConcept: Update<LearnerProfileConceptInterface>;
    }
  ) {}
}

export class UpdateLearnerProfileConcepts implements Action {
  readonly type = LearnerProfileConceptsActionTypes.UpdateLearnerProfileConcepts;

  constructor(
    public payload: {
      learnerProfileConcepts: Update<LearnerProfileConceptInterface>[];
    }
  ) {}
}

export class DeleteLearnerProfileConcept implements Action {
  readonly type = LearnerProfileConceptsActionTypes.DeleteLearnerProfileConcept;

  constructor(public payload: { id: string }) {}
}

export class DeleteLearnerProfileConcepts implements Action {
  readonly type = LearnerProfileConceptsActionTypes.DeleteLearnerProfileConcepts;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearLearnerProfileConcepts implements Action {
  readonly type = LearnerProfileConceptsActionTypes.ClearLearnerProfileConcepts;
}

export type LearnerProfileConceptsActions =
  | LoadLearnerProfileConcepts
  | LearnerProfileConceptsLoaded
  | LearnerProfileConceptsLoadError
  | AddLearnerProfileConcept
  | UpsertLearnerProfileConcept
  | AddLearnerProfileConcepts
  | LoadLearnerProfileConceptsForCourse
  | AddLearnerProfileConceptsForCourse
  | UpsertLearnerProfileConcepts
  | UpdateLearnerProfileConcept
  | UpdateLearnerProfileConcepts
  | DeleteLearnerProfileConcept
  | DeleteLearnerProfileConcepts
  | ClearLearnerProfileConcepts;
