/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { EvaluationScoreListApi as SDKEvaluationScoreListApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { EvaluationScoreListInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class EvaluationScoreListApi extends ModelApiBase {
  private SDKEvaluationScoreListApi = inject(SDKEvaluationScoreListApi);

  constructor(api: PolpoApi) {
    super(api, 'EvaluationScoreLists');
  }

  updateEvaluationScoreList(evaluationScoreListUpdate: Partial<EvaluationScoreListInterface>): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEvaluationScoreListApi.updateEvaluationScoreList(...arguments);
  }
}
