import { Dictionary, EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { MethodOptionInterface } from '../../+models';
import { MethodOptionsActionTypes, MethodOptionsActions } from './method-option.actions';

export const NAME = 'methodOptions';

export interface State extends EntityState<MethodOptionInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: Error;
  loadedForBook: Dictionary<boolean>;
}

const sortByName = (a: MethodOptionInterface, b: MethodOptionInterface): number => a.key.localeCompare(b.key);

export const adapter: EntityAdapter<MethodOptionInterface> = createEntityAdapter<MethodOptionInterface>({
  sortComparer: (a, b) => sortByName(a, b),
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForBook: {},
});

export function reducer(state = initialState, action: MethodOptionsActions): State {
  switch (action.type) {
    case MethodOptionsActionTypes.MethodOptionsLoaded: {
      return adapter.setAll(action.payload.methodOptions, { ...state, loaded: true });
    }

    case MethodOptionsActionTypes.MethodOptionsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case MethodOptionsActionTypes.LoadMethodOptions: {
      return { ...state, loaded: false };
    }

    case MethodOptionsActionTypes.AddMethodOptionsForBook: {
      const { bookId, methodOptions } = action.payload;

      return adapter.addMany(methodOptions, {
        ...state,
        loadedForBook: { ...state.loadedForBook, [bookId]: true },
      });
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
