import { trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from '@angular/core';
import { ProgressFormEnum, ProgressModeEnum, fadeScaleAnimation, fadeScaleAnimationWithoutRotating } from '@campus/ui';

@Component({
  selector: 'campus-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger('fadeIn', fadeScaleAnimation), trigger('fadeOut', fadeScaleAnimationWithoutRotating)],
})
export class ToolbarComponent implements OnChanges {
  private cd = inject(ChangeDetectorRef);

  @HostBinding('class')
  defaultClasses = ['shared-toolbar', 'corner-inherit'];

  @HostBinding('class.shared-toolbar--with-fab')
  @Input()
  hasFab;

  @Input()
  @HostBinding('class.shared-toolbar--sticky')
  sticky: boolean;

  @HostBinding('class.shared-toolbar--elevate-on-scroll')
  @Input()
  elevateOnScroll: boolean;

  @Input() withProgressBar: boolean;
  @Input() isLoading: boolean;

  private timeout: ReturnType<typeof setTimeout> = null;

  public ProgressFormEnum = ProgressFormEnum;
  public progressMode: ProgressModeEnum;
  public showProgressBar: boolean;

  private showProgress() {
    this.showProgressBar = true;
    this.cd.markForCheck();
  }

  private hideProgress() {
    this.showProgressBar = false;
    this.cd.markForCheck();
  }

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading?.previousValue === undefined) return;
    if (changes.isLoading.previousValue && !changes.isLoading.currentValue) {
      this.progressMode = ProgressModeEnum.DETERMINATE;
      this.timeout = setTimeout(() => {
        this.hideProgress();
      }, 1000);
    } else {
      clearInterval(this.timeout);
      this.progressMode = ProgressModeEnum.INDETERMINATE;
      this.showProgress();
    }
  }
}
