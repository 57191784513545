import { Inject, Injectable } from '@angular/core';
import { EnvironmentApiInterface, ENVIRONMENT_API_TOKEN } from '@campus/environment';
import { Store } from '@ngrx/store';
import { combineLatest, fromEvent, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import io, { Socket } from 'socket.io-client';
import { DalState } from '../+state';
import { ClassGroupQueries } from '../+state/class-group';

export enum SocketAction {
  CONNECT = 'onConnect',
  START_EVALUATION = 'startEvaluation',
  STOP_EVALUATION = 'stopEvaluation',
}

export enum SocketEvent {
  CONNECT = 'connect',
  EVALUATION_STARTED = 'evaluationStarted',
  EVALUATION_STOPPED = 'evaluationStopped',
}

@Injectable({ providedIn: 'root' })
export class SocketService {
  private socket: Socket;
  private classgroupIds$: Observable<number[]>;

  constructor(private store: Store<DalState>, @Inject(ENVIRONMENT_API_TOKEN) private api: EnvironmentApiInterface) {
    this.setupStreams();
    this.connect();
  }

  private setupStreams() {
    this.classgroupIds$ = this.store.select(ClassGroupQueries.getAll).pipe(
      filter((cg) => !!cg && !!cg.length),
      map((classGroups) => classGroups.map((cg) => cg.id))
    );
  }

  connect() {
    if (this.socket) return this.socket;

    this.socket = io(this.api.APIBase);

    combineLatest([this.classgroupIds$, fromEvent(this.socket, SocketEvent.CONNECT)]).subscribe(([classGroups, _]) => {
      this.socket.emit(SocketAction.CONNECT, classGroups);
    });

    return this.socket;
  }

  emit(event: SocketAction, data: any) {
    this.socket.emit(event, data);
  }

  receive(event: SocketEvent): Observable<any> {
    const socket = this.connect();
    return fromEvent(socket, event);
  }
}
