import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { MethodGoalInterface } from '../+models';

export const METHOD_GOAL_SERVICE_TOKEN = new InjectionToken('MethodGoalService');

export interface MethodGoalServiceInterface {
  getAllForUser(userId: number): Observable<MethodGoalInterface[]>;
  getMethodGoalsForBook(userId: number, bookId: number): Observable<MethodGoalInterface[]>;
}
