import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MethodInterface } from '../../+models';
import { MethodsActions, MethodsActionTypes } from './method.actions';

export const NAME = 'methods';

export interface State extends EntityState<MethodInterface> {
  // additional entities state properties
  loaded: boolean;
  allowedMethods?: number[];
  allowedMethodsLoaded?: boolean;
  allowedMethodsError?: any;
  error?: any;
}

const sortByAdaptive = (a: MethodInterface, b: MethodInterface): number => {
  return +b.isAdaptive - +a.isAdaptive;
};

const sortByName = (a: MethodInterface, b: MethodInterface): number => a.name.localeCompare(b.name);

export const adapter: EntityAdapter<MethodInterface> = createEntityAdapter<MethodInterface>({
  sortComparer: (a, b) => sortByAdaptive(a, b) || sortByName(a, b),
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  allowedMethods: [],
  allowedMethodsLoaded: false,
  loaded: false,
});

export function reducer(state = initialState, action: MethodsActions): State {
  switch (action.type) {
    case MethodsActionTypes.MethodsLoaded: {
      return adapter.setAll(action.payload.methods, { ...state, loaded: true });
    }

    case MethodsActionTypes.AllowedMethodsLoaded: {
      return {
        ...state,
        allowedMethods: action.payload.methodIds,
        allowedMethodsLoaded: true,
      };
    }

    case MethodsActionTypes.AllowedMethodsLoadError: {
      return {
        ...state,
        allowedMethodsError: action.payload,
        allowedMethodsLoaded: false,
      };
    }

    case MethodsActionTypes.MethodsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case MethodsActionTypes.ClearMethods: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
