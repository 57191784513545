import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'campus-diabolo-phase',
  template: '<campus-icon svgIcon="diabolo"></campus-icon>',
})
export class DiaboloPhaseComponent {
  @HostBinding('class.ui-diabolo-phase')
  isDiaboloPhaseClass = true;

  @Input() diaboloPhaseIds: number[] = [];

  @HostBinding('class.ui-diabolo-phase--intro')
  get phaseIntro() {
    return this.diaboloPhaseIds.includes(1);
  }

  @HostBinding('class.ui-diabolo-phase--center')
  get phaseCenter() {
    return this.diaboloPhaseIds.includes(2);
  }

  @HostBinding('class.ui-diabolo-phase--outro')
  get phaseOutro() {
    return this.diaboloPhaseIds.includes(3);
  }
}
