import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { EduContentTOCInterface } from '../../+models';
import { EduContentTocsActions, EduContentTocsActionTypes } from './edu-content-toc.actions';

export const NAME = 'eduContentTocs';

export interface State extends EntityState<EduContentTOCInterface> {
  // additional entities state properties
  error?: any;
  loadedForBook: Dictionary<boolean>;
}

export const adapter: EntityAdapter<EduContentTOCInterface> = createEntityAdapter<EduContentTOCInterface>({
  sortComparer: byLft,
});

function byLft(a: EduContentTOCInterface, b: EduContentTOCInterface) {
  return a.lft - b.lft;
}

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loadedForBook: {},
});

export function reducer(state = initialState, action: EduContentTocsActions): State {
  switch (action.type) {
    case EduContentTocsActionTypes.AddEduContentToc: {
      return adapter.addOne(action.payload.eduContentToc, state);
    }

    case EduContentTocsActionTypes.UpsertEduContentToc: {
      return adapter.upsertOne(action.payload.eduContentToc, state);
    }

    case EduContentTocsActionTypes.AddEduContentTocsForBook: {
      return adapter.addMany(action.payload.eduContentTocs, state);
    }

    case EduContentTocsActionTypes.AddLoadedBook: {
      return {
        ...state,
        loadedForBook: { ...state.loadedForBook, [action.payload.bookId]: true },
      };
    }

    case EduContentTocsActionTypes.UpsertEduContentTocs: {
      return adapter.upsertMany(action.payload.eduContentTocs, state);
    }

    case EduContentTocsActionTypes.UpdateEduContentToc: {
      return adapter.updateOne(action.payload.eduContentToc, state);
    }

    case EduContentTocsActionTypes.AddEvaluation: {
      const { eduContentTocId: id } = action.payload;
      const update = {
        id,
        changes: { isEvaluation: true },
      };
      return adapter.updateOne(update, state);
    }

    case EduContentTocsActionTypes.DeleteEvaluation: {
      const { id } = action.payload;
      const update = {
        id,
        changes: { isEvaluation: false },
      };
      return adapter.updateOne(update, state);
    }

    case EduContentTocsActionTypes.MoveEvaluation: {
      const { oldEduContentTOCId, newEduContentTOCId } = action.payload;
      const updates = [
        {
          id: oldEduContentTOCId,
          changes: { isEvaluation: false },
        },
        {
          id: newEduContentTOCId,
          changes: { isEvaluation: true },
        },
      ];
      return adapter.updateMany(updates, state);
    }

    case EduContentTocsActionTypes.UpdateEduContentTocs: {
      return adapter.updateMany(action.payload.eduContentTocs, state);
    }

    case EduContentTocsActionTypes.DeleteEduContentToc: {
      return adapter.removeOne(action.payload.id, state);
    }

    case EduContentTocsActionTypes.DeleteEduContentTocs: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case EduContentTocsActionTypes.EduContentTocsLoadError: {
      return {
        ...state,
        loadedForBook: {},
        error: action.payload,
      };
    }

    case EduContentTocsActionTypes.ClearEduContentTocs: {
      return adapter.removeAll(state);
    }

    case EduContentTocsActionTypes.ClearLoadedBooks: {
      return { ...state, loadedForBook: {} };
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
