import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';
@Injectable({ providedIn: 'root' })
export class PositiveNumberValidator implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    if ([undefined, null, ''].includes(control.value)) return null;

    if (!Number.isInteger(+control.value) || +control.value < 1) {
      return { notPositiveNumber: true };
    }

    return null;
  }
}
