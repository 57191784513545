import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaluationScoreListApi } from '../+api';
import { EvaluationScoreListInterface } from '../+models';
import { EvaluationScoreListServiceInterface } from './evaluation-score-list.service.interface';

@Injectable({
  providedIn: 'root',
})
export class EvaluationScoreListService implements EvaluationScoreListServiceInterface {
  constructor(private evaluationScoreListApi: EvaluationScoreListApi) {}

  updateEvaluationScoreList(
    evaluationScoreListUpdate: Partial<EvaluationScoreListInterface>
  ): Observable<EvaluationScoreListInterface> {
    return this.evaluationScoreListApi.updateEvaluationScoreList(
      evaluationScoreListUpdate
    ) as Observable<EvaluationScoreListInterface>;
  }
}
