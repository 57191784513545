<div
  class="[ bubble-wrap ] [ absolute w-full top-O transform -translate-y-full relative ] [ child-transition-spacing child-transition-long-4 ] [ index-content-on-surface ]"
>
  <ng-content select="campus-speech-bubble" #bubble></ng-content>
</div>

<campus-speech-bubble
  #speech
  *ngIf="speechBubble"
  side="bottom"
  triangle="end"
  class="[ transform translate-y-0 scale-90 ]"
>
  <span [innerHTML]="speechBubble"></span>
</campus-speech-bubble>

<div
  class="[ stack stack-space-xs ] [ place-items-center ][ transition transition-transform ]"
  [ngClass]="active ? ['transform', 'scale-125'] : []"
>
  <div class="[ relative ] [ concept-button ]">
    <campus-ability-progress
      *ngIf="showAbility && !disabled"
      type="half"
      [mode]="mode"
      [progress]="ability"
    ></campus-ability-progress>
    <div class="[ imposter ] [ overflow-visible ]">
      <button
        outline-icon-button
        [class.disabled]="disabled"
        (click)="buttonClicked.emit($event)"
        class="[ block surface border-2 border-secondary ] [ group-hover:elevation-1 active:elevation-0 ]"
      >
        <ng-content
          select="[concept-button-icon], [button-icon]"
          ngProjectAs="[concept-button-icon], [button-icon]"
        ></ng-content>
      </button>

      <campus-progress-bar
        *ngIf="showProgress && !disabled"
        [progress]="progress"
        class="[ surface absolute bottom-none left-none right-none ]"
      ></campus-progress-bar>

      <div
        *ngIf="done"
        class="[ center-items ] [ absolute bottom-none right-none ] [ -m-3xs ] [ success corner-full ] [ animate animate-tada animate-extra-long-1 ]"
      >
        <div class="[ clip-check w-full h-full ] [ surface corner-full ]"></div>
      </div>

      <campus-selector-wheel
        *ngIf="showAbility && !disabled"
        [options]="selectorOptions"
        [selectedIndex]="selectorIndex"
        class="[ absolute left-0 ] [ concept-button-wheel ][ transition transition-opacity ]"
        [class.opacity-none]="active"
      >
      </campus-selector-wheel>
    </div>
    <span (cdkObserveContent)="observeKaiChange($event)">
      <ng-content select="[concept-button-kai]" #kai></ng-content>
    </span>
  </div>
  <ng-content select="concept-button-label" #label></ng-content>
</div>
