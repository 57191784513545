import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaluationApi } from '../+api';
import { EvaluationInterface, FilteredEvaluationInterface } from '../+models';
import { DalOptions, DAL_OPTIONS } from '../dal.module';
import {
  AddEvaluationResponseInterface,
  DeleteEvaluationResponseInterface,
  EvaluationServiceInterface,
} from './evaluation.service.interface';

@Injectable({
  providedIn: 'root',
})
export class EvaluationService implements EvaluationServiceInterface {
  private evaluationApi = inject(EvaluationApi);
  private http = inject(HttpClient);
  private dalOptions = inject<DalOptions>(DAL_OPTIONS);

  addEvaluation(evaluation: EvaluationInterface, tocId: number): Observable<AddEvaluationResponseInterface> {
    return this.evaluationApi.addEvaluationForToc(tocId, evaluation) as Observable<AddEvaluationResponseInterface>;
  }

  updateEvaluation(evaluationId: number, changes: Partial<EvaluationInterface>) {
    return this.evaluationApi.patchAttributes(evaluationId, changes);
  }

  deleteEvaluation(evaluationId: number, tocId: number): Observable<DeleteEvaluationResponseInterface> {
    return this.evaluationApi.deleteEvaluationForToc(
      tocId,
      evaluationId
    ) as Observable<DeleteEvaluationResponseInterface>;
  }

  getEvaluations(
    searchTerm?: string,
    filters?: { [key: string]: string | number | number[] },
    pagination?: { from?; amount? },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<{ results: FilteredEvaluationInterface[]; count: number }> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/Evaluations/filtered`;
    const params = new HttpParams({
      fromObject: {
        searchTerm: searchTerm || '',
        filters: JSON.stringify(filters),
        columns: JSON.stringify(columns),
        sorting: JSON.stringify(sorting),
        pagination: JSON.stringify(pagination),
      },
    });

    return this.http.get<{ results: FilteredEvaluationInterface[]; count: number }>(url, {
      params,
      withCredentials: true,
    });
  }
}
