import { Action } from '@ngrx/store';
import { NewsProductInterface } from '../../+models';

export enum NewsProductsActionTypes {
  NewsProductsLoaded = '[News Products] News Products Loaded',
  NewsProductsLoadError = '[News Products] Load Error',
  AddNewsProducts = '[News Products] Add News Products',
  SetNewsProductsForNews = '[News Products] Set news Products for news',
  RemoveNewsProductsForNews = '[News Products] Remove news Products for news',
}

export class NewsProductsLoaded implements Action {
  readonly type = NewsProductsActionTypes.NewsProductsLoaded;

  constructor(public payload: { newsProducts: NewsProductInterface[] }) {}
}

export class NewsProductsLoadError implements Action {
  readonly type = NewsProductsActionTypes.NewsProductsLoadError;
  constructor(public payload: any) {}
}

export class AddNewsProducts implements Action {
  readonly type = NewsProductsActionTypes.AddNewsProducts;
  constructor(public payload: { newsProducts: NewsProductInterface[] }) {}
}

export class SetNewsProductsForNews implements Action {
  readonly type = NewsProductsActionTypes.SetNewsProductsForNews;
  constructor(public payload: { newsId: number; newsProducts: NewsProductInterface[] }) {}
}

export class RemoveNewsProductsForNews implements Action {
  readonly type = NewsProductsActionTypes.RemoveNewsProductsForNews;
  constructor(public payload: { newsId: number }) {}
}

export type NewsProductsActions =
  | NewsProductsLoaded
  | NewsProductsLoadError
  | AddNewsProducts
  | SetNewsProductsForNews
  | RemoveNewsProductsForNews;
