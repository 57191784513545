import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { TaskClassGroupQueries } from '.';
import {
  TaskClassGroupServiceInterface,
  TASK_CLASS_GROUP_SERVICE_TOKEN,
} from '../../tasks/task-class-group.service.interface';
import { DalState } from '../dal.state.interface';
import { loadTaskClassGroups, taskClassGroupsLoaded, taskClassGroupsLoadError } from './task-class-group.actions';

@Injectable()
export class TaskClassGroupEffects {
  loadTaskClassGroups$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadTaskClassGroups),
      concatLatestFrom(() => this.store.select(TaskClassGroupQueries.getLoaded)),
      fetch({
        run: (action: ReturnType<typeof loadTaskClassGroups>, loaded: boolean) => {
          if (!action.force && loaded) return;
          return this.taskClassGroupService
            .getAllForUser(action.userId)
            .pipe(map((taskClassGroups) => taskClassGroupsLoaded({ taskClassGroups })));
        },
        onError: (action: ReturnType<typeof loadTaskClassGroups>, error) => {
          return taskClassGroupsLoadError({ error });
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(TASK_CLASS_GROUP_SERVICE_TOKEN)
    private taskClassGroupService: TaskClassGroupServiceInterface
  ) {}
}
