import { formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { SearchFilterComponentInterface } from '../../interfaces/search-filter-component-interface';
import {
  SearchFilterCriteriaInterface,
  SearchFilterCriteriaValuesInterface,
} from '../../interfaces/search-filter-criteria.interface';
export enum RadioOptionValueType {
  FilterCriteriaValue,
  CustomRange,
  NoFilter,
}

export interface RadioOption {
  value: RadioOptionValue;
  viewValue: string | number;
  selected?: boolean;
  disabled?: boolean;
}

export interface RadioOptionValue {
  type: RadioOptionValueType;
  contents?: SearchFilterCriteriaValuesInterface;
  label?: string;
}

export interface DateFilterComponentFormValues {
  dateSelection?: RadioOptionValue;
  startDate?: Date;
  endDate?: Date;
}

@Component({
  selector: 'campus-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateFilterComponent implements SearchFilterComponentInterface, OnInit, OnDestroy, OnChanges {
  optionValueTypes = RadioOptionValueType;

  radioOptions: RadioOption[];
  customRangeOptionValue: RadioOptionValue = {
    type: RadioOptionValueType.CustomRange,
    contents: { data: { gte: null, lte: null, option: 'custom' } },
  };

  startDate: UntypedFormControl = new UntypedFormControl();

  endDate: UntypedFormControl = new UntypedFormControl();
  dateSelection: UntypedFormControl = new UntypedFormControl('', [
    this.customRangeValidator(this.startDate, this.endDate),
  ]);

  count = 0;
  customDisplayLabel = '';

  private subscriptions: Subscription = new Subscription();
  private formValues: DateFilterComponentFormValues = {};
  private defaultCriteria: SearchFilterCriteriaInterface = {
    name: 'dateRange',
    label: '',
    keyProperty: '',
    displayProperty: 'viewValue',
    values: [],
  };
  criteria: SearchFilterCriteriaInterface = this.defaultCriteria;

  @ViewChild(MatMenuTrigger, { static: true })
  matMenuTrigger: MatMenuTrigger;

  @Input() resetLabel: string;
  @Input() options: RadioOption[];
  @Input() filterCriteria: SearchFilterCriteriaInterface = this.defaultCriteria;
  @Input() hideBadge: boolean;
  @Input() clearable: boolean;
  @Input() minStartDate: Date = null;
  @Input() maxEndDate: Date = null;

  @Output() filterSelectionChange: EventEmitter<SearchFilterCriteriaInterface[]> = new EventEmitter();

  @HostBinding('class.date-filter-component')
  dateFilterComponentClass = true;

  constructor(
    @Inject(MAT_DATE_LOCALE) private locale: string,
    @Inject(ChangeDetectorRef) private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.criteria = this.filterCriteria;
    this.subscriptions.add(this.matMenuTrigger.menuClosed.subscribe(this.cancelFormValues.bind(this)));

    this.subscriptions.add(
      this.startDate.valueChanges
        .pipe(startWith(this.startDate.value), distinctUntilChanged())
        .subscribe(this.onDateChange.bind(this, this.startDate))
    );

    this.subscriptions.add(
      this.endDate.valueChanges
        .pipe(startWith(this.endDate.value), distinctUntilChanged())
        .subscribe(this.onDateChange.bind(this, this.endDate))
    );

    this.subscriptions.add(
      this.dateSelection.valueChanges
        .pipe(startWith(this.dateSelection.value), distinctUntilChanged())
        .subscribe((optionValue: RadioOptionValue) => {
          switch (optionValue.type) {
            case RadioOptionValueType.CustomRange:
              // Trigger it ourselves to prevent two consecutive date
              // change events from firing when both inputs are enabled
              this.onDateChange();
              break;
            case RadioOptionValueType.FilterCriteriaValue:
              this.criteria.values = [optionValue.contents];
              this.resetDates();
              break;
            default:
              this.criteria.values = [];
              this.resetDates();
              break;
          }
        })
    );

    this.checkSelected();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterCriteria) {
      this.criteria = this.filterCriteria;
    }

    if (changes.resetLabel || changes.options) {
      this.radioOptions = this.getRadioOptions();
      // set datepicker values if there is a custom range option
      const customRangeRadio = this.radioOptions.find((opt) => opt.value.type === RadioOptionValueType.CustomRange);
      if (customRangeRadio) this.setCustomRangeValues(customRangeRadio.value);
    }

    if (changes.options) {
      this.checkSelected();
    }
  }

  public applyFilter(): void {
    // Order is important here, must store values before closing the mat-menu
    this.storeFormValues();

    this.filterSelectionChange.emit([this.criteria]);
    this.updateView();

    this.matMenuTrigger.closeMenu();
  }
  public cancelFilter(): void {
    this.matMenuTrigger.closeMenu();
  }

  public clickDateInput() {
    const value = {
      ...this.customRangeOptionValue,
      contents: {
        data: {
          lte: this.endDate.value,
          gte: this.startDate.value,
          option: 'custom',
        },
      },
    };
    this.dateSelection.setValue(value);

    this.selectCustomRangeOption();
  }

  /**
   * Fed to the date pickers so they know which dates to highlight
   */
  public applyClassToDateInRange(date: Date) {
    const startDateValue: Date = this.startDate.value;
    const endDateValue: Date = this.endDate.value;

    const sameDay = (a: Date, b: Date) => {
      return a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();
    };

    if (startDateValue && endDateValue && date >= startDateValue && date <= endDateValue) {
      if (sameDay(date, startDateValue) && sameDay(date, endDateValue)) {
        return 'date-filter-component__menu-panel__date-range__day--in-range-single';
      } else if (sameDay(date, startDateValue)) {
        return 'date-filter-component__menu-panel__date-range__day--in-range-first';
      } else if (sameDay(date, endDateValue)) {
        return 'date-filter-component__menu-panel__date-range__day--in-range-last';
      } else {
        return 'date-filter-component__menu-panel__date-range__day--in-range';
      }
    } else if ((startDateValue && sameDay(date, startDateValue)) || (endDateValue && sameDay(date, endDateValue))) {
      return 'date-filter-component__menu-panel__date-range__day--in-range-single';
    }
  }

  public onDateChange(trigger?: UntypedFormControl) {
    let startDateValue: Date = this.startDate.value && new Date(this.startDate.value);
    let endDateValue: Date = this.endDate.value && new Date(this.endDate.value);

    // startDate or endDate can be null if it's an invalid date
    if (startDateValue || endDateValue) {
      if (startDateValue && endDateValue) {
        // If we pick an end date lower than the start date, the start date becomes the end date
        if (trigger === this.endDate && endDateValue < startDateValue) {
          startDateValue = new Date(endDateValue);
        }

        // If we pick a start date higher than the end date, the end date becomes the start date
        if (trigger === this.startDate && startDateValue > endDateValue) {
          endDateValue = new Date(startDateValue);
        }
      }

      // normalize the times to actually be the very start or end of the day
      if (startDateValue) {
        startDateValue.setHours(0, 0, 0, 0);
      }

      if (endDateValue) {
        endDateValue.setHours(23, 59, 59, 0);
      }

      this.startDate.setValue(startDateValue, { emitEvent: false });
      this.endDate.setValue(endDateValue, { emitEvent: false });

      this.criteria.values = [
        {
          data: {
            gte: startDateValue,
            lte: endDateValue,
            option: 'custom',
          },
        },
      ];
    } else {
      this.criteria.values = [];
    }

    this.dateSelection.updateValueAndValidity();
  }

  public updateView(): void {
    const selection = this.dateSelection.value || {};
    if (selection.label) {
      this.customDisplayLabel = selection.label;
    } else {
      const startDateValue: Date = this.criteria.values[0] && this.criteria.values[0].data.gte;
      const endDateValue: Date = this.criteria.values[0] && this.criteria.values[0].data.lte;

      if (startDateValue || endDateValue) {
        if (startDateValue && endDateValue) {
          this.customDisplayLabel =
            formatDate(startDateValue, 'd/MM/yy', this.locale) +
            ' - ' +
            formatDate(endDateValue, 'd/MM/yy', this.locale);
        } else if (startDateValue) {
          this.customDisplayLabel = 'vanaf ' + formatDate(startDateValue, 'd/MM/yy', this.locale);
        } else if (endDateValue) {
          this.customDisplayLabel = 't.e.m. ' + formatDate(endDateValue, 'd/MM/yy', this.locale);
        }
      } else {
        this.customDisplayLabel = null;
      }
    }

    const hasDates: boolean =
      !!this.criteria.values.length &&
      !!this.criteria.values[0] &&
      !!(this.criteria.values[0].data.gte || this.criteria.values[0].data.lte);

    this.count = +hasDates;

    this.cd.markForCheck();
  }

  public reset(emit = true): void {
    this.dateSelection.setValue({}, { emitEvent: true });
    this.criteria.values = [];
    this.resetDates();
    this.updateView();
    if (emit) this.filterSelectionChange.emit([this.criteria]);
  }

  private storeFormValues(): void {
    this.formValues = {
      dateSelection: this.dateSelection.value,
      startDate: this.startDate.value,
      endDate: this.endDate.value,
    };
  }

  private cancelFormValues(): void {
    this.dateSelection.setValue(this.formValues.dateSelection, {
      emitEvent: false,
    });
    this.startDate.setValue(this.formValues.startDate, {
      emitEvent: false,
    });
    this.endDate.setValue(this.formValues.endDate, { emitEvent: false });
  }

  private getRadioOptions(): RadioOption[] {
    let options = this.options;
    if (this.resetLabel) {
      options = [
        {
          viewValue: this.resetLabel,
          value: {
            type: RadioOptionValueType.NoFilter,
          },
        },
        ...options,
      ];
    }

    return options;
  }

  private selectCustomRangeOption(): void {
    const customRadioOption = this.radioOptions.find((opt) => opt.value.type === RadioOptionValueType.CustomRange);

    if (customRadioOption) {
      // by setting the value of the custom radio option the same as the dateSelection form
      // the radio option will be checked (see checked attribute on mat-radio-button in the template)
      customRadioOption.value = this.dateSelection.value;
    }
  }

  private resetDates() {
    this.startDate.reset(null, { emitEvent: false });
    this.endDate.reset(null, { emitEvent: false });
  }

  private checkSelected() {
    const defaultSelected = this.radioOptions && this.radioOptions.find((option) => option.selected);
    if (defaultSelected) {
      this.dateSelection.setValue(defaultSelected.value);
      this.storeFormValues();
      this.updateView();
    }
  }

  private setCustomRangeValues(customRangeValue: RadioOptionValue): void {
    if (customRangeValue.contents.data.gte) {
      this.startDate.setValue(new Date(customRangeValue.contents.data.gte));
    }
    if (customRangeValue.contents.data.lte) {
      this.endDate.setValue(new Date(customRangeValue.contents.data.lte));
    }
  }

  customRangeValidator(startDateCtrl: UntypedFormControl, endDateCrl: UntypedFormControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // only do this check when the custom range radio is selected
      if (control.value.type === RadioOptionValueType.CustomRange) {
        // check if startDate and endDate is filled in
        if (startDateCtrl.value && endDateCrl.value) {
          return null;
        } else {
          return { customRange: 'Start and end date is required' };
        }
      } else {
        return null;
      }
    };
  }
}
