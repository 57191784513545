import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { CurriculumInterface } from '../+models';

export const CURRICULUM_SERVICE_TOKEN = new InjectionToken<CurriculumServiceInterface>('CurriculumServiceInterface');

export interface CurriculumServiceInterface {
  getAllForUser(userId: number): Observable<CurriculumInterface[]>;
  publishCurriculum(learningAreaId): Observable<CurriculumInterface>;
}
