import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { SectionContentInterface } from '../../+models';
import { SectionContentsActions, SectionContentsActionTypes } from './section-content.actions';

export const NAME = 'sectionContents';

export interface State extends EntityState<SectionContentInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
  loadedForBook: Dictionary<boolean>;
  loadedForToc: Dictionary<boolean>;
}

export const adapter: EntityAdapter<SectionContentInterface> = createEntityAdapter<SectionContentInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForBook: {},
  loadedForToc: {},
});

export function reducer(state = initialState, action: SectionContentsActions): State {
  switch (action.type) {
    case SectionContentsActionTypes.AddSectionContent: {
      return adapter.addOne(action.payload.sectionContent, state);
    }

    case SectionContentsActionTypes.UpsertSectionContent: {
      return adapter.upsertOne(action.payload.sectionContent, state);
    }

    case SectionContentsActionTypes.AddSectionContents: {
      return adapter.addMany(action.payload.sectionContents, state);
    }

    case SectionContentsActionTypes.UpsertSectionContents: {
      return adapter.upsertMany(action.payload.sectionContents, state);
    }

    case SectionContentsActionTypes.UpdateSectionContent: {
      return adapter.updateOne(action.payload.sectionContent, state);
    }

    case SectionContentsActionTypes.UpdateSectionContents: {
      return adapter.updateMany(action.payload.sectionContents, state);
    }

    case SectionContentsActionTypes.DeleteSectionContent: {
      return adapter.removeOne(action.payload.id, state);
    }

    case SectionContentsActionTypes.DeleteSectionContents: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case SectionContentsActionTypes.SectionContentsLoaded: {
      return adapter.setAll(action.payload.sectionContents, { ...state, loaded: true });
    }

    case SectionContentsActionTypes.SectionContentsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case SectionContentsActionTypes.ClearSectionContents: {
      return adapter.removeAll(state);
    }

    case SectionContentsActionTypes.AddSectionContentsForToc: {
      const { tocId, sectionContents } = action.payload;

      return adapter.addMany(sectionContents, {
        ...state,
        loadedForToc: { ...state.loadedForToc, [tocId]: true },
      });
    }

    case SectionContentsActionTypes.AddSectionContentsForBook: {
      const { bookId, sectionContents } = action.payload;

      return adapter.addMany(sectionContents, {
        ...state,
        loadedForBook: { ...state.loadedForBook, [bookId]: true },
      });
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
