import { groupArrayByKey } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssigneeInterface, AssigneeTypesEnum } from '../../+models';
import { TaskGroupInterface } from '../../+models/TaskGroup.interface';
import { selectGroupState } from '../group/group.selectors';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './task-group.reducer';

export const selectTaskGroupState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectTaskGroupState, (state: State) => state.error);

export const getLoaded = createSelector(selectTaskGroupState, (state: State) => state.loaded);

export const getAll = createSelector(selectTaskGroupState, selectAll);

export const getCount = createSelector(selectTaskGroupState, selectTotal);

export const getIds = createSelector(selectTaskGroupState, selectIds);

export const getAllEntities = createSelector(selectTaskGroupState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * taskGroup$: TaskGroupInterface[] = this.store.pipe(
    select(TaskGroupQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectTaskGroupState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * taskGroup$: TaskGroupInterface = this.store.pipe(
    select(TaskGroupQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectTaskGroupState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getTaskGroupAssigneeByTask = createSelector(getAll, selectGroupState, (taskGroups, groupState) =>
  taskGroups.reduce((dict, tg) => {
    if (!dict[tg.taskId]) {
      dict[tg.taskId] = [];
    }
    dict[tg.taskId].push({
      id: tg.id,
      type: AssigneeTypesEnum.GROUP,
      relationId: tg.groupId,
      label: groupState.entities[tg.groupId]?.name ?? '<groep niet gevonden>',
      start: tg.start,
      end: tg.end,
    });

    return dict;
  }, {} as Dictionary<AssigneeInterface[]>)
);

export const getByTaskId = createSelector(
  selectTaskGroupState,
  (state: State, props: { taskId: number }): TaskGroupInterface[] =>
    (state.ids as (string | number)[]).reduce((acc, id) => {
      if (state.entities[id].taskId === props.taskId) {
        acc.push(state.entities[id]);
      }
      return acc;
    }, [])
);

export const getAllGroupedByTaskId = createSelector(selectTaskGroupState, (state: State) => {
  return groupArrayByKey(Object.values(state.entities), 'taskId');
});
