import { Inject, Injectable } from '@angular/core';
import { PassportUserCredentialInterface, PersonInterface } from '@campus/dal';
import { NavItem } from '@campus/ui';
import { PermissionService } from '../../auth';
import {
  AppNavTreeInterface,
  AppNavTreeKeys,
  APP_NAVIGATION_TREE_TOKEN,
  NavigationItemServiceInterface,
} from './navigation-item.service.interface';

@Injectable({
  providedIn: 'root',
})
export class NavigationItemService implements NavigationItemServiceInterface {
  constructor(
    @Inject(APP_NAVIGATION_TREE_TOKEN)
    private appNavigationTree: AppNavTreeInterface,
    private permissionService: PermissionService
  ) {}

  getNavItemsForTree(
    tree: AppNavTreeKeys,
    userPermissions: string[],
    credentials?: PassportUserCredentialInterface[],
    user?: PersonInterface
  ): NavItem[] {
    const navItems = this.appNavigationTree[tree].filter((navItem) => {
      if (navItem.title.toLowerCase() === 'smartschool') {
        if (!credentials) return false;
        return credentials.some((cred) => cred.provider === 'smartschool');
      }

      const hidePermissionFilter = !(
        navItem.hideWhenRequiredPermissions &&
        this.permissionService.hasPermission(navItem.hideWhenRequiredPermissions, userPermissions)
      );

      const requiredPermissionFilter =
        !navItem.requiredPermissions ||
        this.permissionService.hasPermission(navItem.requiredPermissions, userPermissions);

      const filters = [hidePermissionFilter, requiredPermissionFilter];

      if (navItem.userSpecificFilter) {
        const userSpecificFilter = navItem.userSpecificFilter(user);
        filters.push(userSpecificFilter);
      }

      return filters.every((f) => f);
    });

    return navItems.reduce((acc, navItem) => {
      if (navItem.title.toLowerCase() === 'smartschool') {
        const smartschoolNavItems = this.getSmartschoolProfileMenuItems(credentials, navItem);
        acc.push(...smartschoolNavItems);
        return acc;
      }

      //id added for the trackby in the navtree
      acc.push({ ...navItem, id: navItem.id || navItem.title });
      return acc;
    }, [] as NavItem[]);
  }

  private getSmartschoolProfileMenuItems(
    credentials: PassportUserCredentialInterface[],
    mainValues: NavItem
  ): NavItem[] {
    const smartschoolCredentials = credentials.filter((cred) => cred.provider === 'smartschool');

    if (smartschoolCredentials.length === 0) return [];

    const smartschoolLinks = smartschoolCredentials.map((smartschoolCredential) => {
      const title = this.extractPlatformName(smartschoolCredential.profile.platform);

      return {
        ...mainValues,
        id: title,
        title,
        link: smartschoolCredential.profile.platform || 'https://www.smartschool.be',
        cssClass: 'smartschool',
      };
    });
    smartschoolLinks[0].dividerBefore = true;
    return smartschoolLinks;
  }
  private extractPlatformName(platformUrl: string) {
    return platformUrl ? platformUrl.replace('.smartschool.be', '').replace('https://', '') : 'smartschool';
  }
}
