import { EduContentMetadataInterface } from './EduContentMetadata.interface';

export enum EduContentSourceEnum {
  OTHER = 1,
  AUTEURSTOOL = 2,
  ALGEBRAKIT = 3,
  ARTICULATE = 4,
  LEARNOSITY = 5,
}

export interface EduContentSourceInterface {
  name: string;
  link?: string;
  id?: number;
  eduContentMetadata?: EduContentMetadataInterface[];
  usesScorm?: boolean;
}
