import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TaskEduContentTaskGoalYearLevelInterface } from '../../+models/TaskEduContentTaskGoalYearLevel.interface';
import {
  NAME,
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
  State,
} from './task-edu-content-task-goal-year-level.reducer';

export const selectTaskEduContentTaskGoalYearLevelState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectTaskEduContentTaskGoalYearLevelState, (state: State) => state.error);

export const getLoaded = createSelector(selectTaskEduContentTaskGoalYearLevelState, (state: State) => state.loaded);

export const getAll = createSelector(selectTaskEduContentTaskGoalYearLevelState, selectAll);

export const getCount = createSelector(selectTaskEduContentTaskGoalYearLevelState, selectTotal);

export const getIds = createSelector(selectTaskEduContentTaskGoalYearLevelState, selectIds);

export const getAllEntities = createSelector(selectTaskEduContentTaskGoalYearLevelState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * taskEduContentTaskGoalYearLevel$: TaskEduContentTaskGoalYearLevelInterface[] = this.store.pipe(
    select(TaskEduContentTaskGoalYearLevelQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(
  selectTaskEduContentTaskGoalYearLevelState,
  (state: State, props: { ids: number[] }) => {
    return props.ids.map((id) => state.entities[id]);
  }
);

/**
 * returns array of objects in the order of the given ids
 * @example
 * taskEduContentTaskGoalYearLevel$: TaskEduContentTaskGoalYearLevelInterface = this.store.pipe(
    select(TaskEduContentTaskGoalYearLevelQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectTaskEduContentTaskGoalYearLevelState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getTaskEduContentTaskGoalYearLevelLoadedForTask = createSelector(
  selectTaskEduContentTaskGoalYearLevelState,
  (state: State, props: { taskId: number }) => {
    return !!state.loadedForTask[props.taskId];
  }
);

export const getByTaskGoalYearLevelId = createSelector(
  getAll,
  (tETGYLs: TaskEduContentTaskGoalYearLevelInterface[]) => {
    return tETGYLs.reduce((acc, tETGYL) => {
      acc[tETGYL.taskGoalYearLevelId] = tETGYL;
      return acc;
    }, {});
  }
);
