import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { EduContentBookInterface, FavoriteInterface } from '@campus/dal';
import { Dictionary } from '@ngrx/entity';
import { BooksTileComponentInterface } from '../books-tile';

@Component({
  selector: 'campus-evaluation-method-slideout',
  templateUrl: './evaluation-method-slideout.component.html',
  styleUrls: ['./evaluation-method-slideout.component.scss'],
})
export class EvaluationMethodSlideoutComponent implements OnChanges {
  @Input() bookTiles: BooksTileComponentInterface[];
  @Input() favoriteBooksByBookId: Dictionary<FavoriteInterface>;

  @Output() bookClick = new EventEmitter<EduContentBookInterface>();

  public favoriteBooks: BooksTileComponentInterface[];
  public otherBooks: BooksTileComponentInterface[];

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.bookTiles || changes.favoriteBooksByBookId) {
      const { favoriteBooks, otherBooks } = this.bookTiles.reduce(
        (acc, tile) => {
          const favoriteRows = tile.rows.filter((row) => !!this.favoriteBooksByBookId[row.data.id]);
          const regularRows = tile.rows.filter((row) => !this.favoriteBooksByBookId[row.data.id]);
          if (favoriteRows.length) {
            acc.favoriteBooks.push({ ...tile, rows: favoriteRows });
          }
          if (regularRows.length) {
            acc.otherBooks.push({ ...tile, rows: regularRows });
          }
          return acc;
        },
        { favoriteBooks: [], otherBooks: [] }
      );
      this.favoriteBooks = favoriteBooks;
      this.otherBooks = otherBooks;
    }
  }

  public trackByGuid(index: number, booktile: BooksTileComponentInterface): number {
    return booktile.guid;
  }

  public onBookClick(book: EduContentBookInterface, tile: BooksTileComponentInterface) {
    const method = tile.data.method;
    const bookWithMethod = { ...book, ...{ method } };

    this.bookClick.emit(bookWithMethod);
  }
}
