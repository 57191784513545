import { Injectable, Injector } from '@angular/core';
import { DalState, EduContentBookInterface, FavoriteInterface, MethodInterface, ProductInterface } from '@campus/dal';
import { ArrayFunctions } from '@campus/utils';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  BooksTileActionInterface,
  BooksTileComponentInterface,
  BooksTileRowInterface,
} from '../../components/books-tile';

export interface BooksTileActionHandlerInterface {
  openToc?(eduContentBookId: number, productId: number): void;
  openEduContentBook?(eduContentBookId: number, productId?: number): void;
  toggleBoekeFavorite?(eduContentBookId: number, productId: number): void;
  openEduContents?(eduContentBookId: number, productId?: number): void;
  openGoals?(eduContentBookId: number, productId?: number): void;
  openTableOfContents?(eduContentBookId: number): void;
  openSections?(eduContentBookId: number): void;
  openEvaluations?(eduContentBookId: number): void;
  openConcordance?(eduContentBookId: number): void;
}

export interface BooksTileServiceConfigInterface {
  filterUnlockedBooks?: boolean;
}

export interface BooksTileServiceOverrideInterface {
  rows?: {
    forceTitle?: boolean;
  };
}

export type BooksTileActionType =
  | 'toc'
  | 'boeke'
  | 'favorite'
  | 'edu-contents'
  | 'goals'
  | 'table-of-contents'
  | 'sections'
  | 'evaluations'
  | 'concordance';

export interface BooksTileServiceInterface {
  getBookTiles$(
    tileFilter?: (data) => boolean,
    config?: BooksTileServiceConfigInterface
  ): Observable<BooksTileComponentInterface[]>;

  mapToBooksTileWithoutActions(
    guid: number,
    title: string,
    method: MethodInterface,
    books: EduContentBookInterface[]
  ): BooksTileComponentInterface;

  mapToBooksTileWithActions(
    guid: number,
    title: string,
    method: MethodInterface,
    books: EduContentBookInterface[],
    actionHandler: BooksTileActionHandlerInterface,
    allowedActions: BooksTileActionType[],
    favoriteBooks: FavoriteInterface[],
    product?: ProductInterface
  ): BooksTileComponentInterface;
}

@Injectable({
  providedIn: 'root',
})
export class BooksTileService implements BooksTileServiceInterface {
  protected store: Store<DalState>;
  constructor(protected injector: Injector) {}

  getBookTiles$(
    tileFilter?: (data) => boolean,
    config: BooksTileServiceConfigInterface = {}
  ): Observable<BooksTileComponentInterface[]> {
    throw new Error('Method not implemented.');
  }

  mapToBooksTileWithoutActions(
    guid: number,
    title: string,
    method: MethodInterface,
    books: EduContentBookInterface[],
    product?: ProductInterface,
    overrideSettings?: BooksTileServiceOverrideInterface
  ): BooksTileComponentInterface {
    return this.mapToBooksTile(guid, title, method, books, [], null, [], product, overrideSettings);
  }

  mapToBooksTileWithActions(
    guid: number,
    title: string,
    method: MethodInterface,
    books: EduContentBookInterface[],
    actionHandler: BooksTileActionHandlerInterface,
    allowedActions: BooksTileActionType[],
    favoriteBooks: FavoriteInterface[] = [],
    product: ProductInterface = null
  ): BooksTileComponentInterface {
    return this.mapToBooksTile(guid, title, method, books, favoriteBooks, actionHandler, allowedActions, product);
  }

  private mapToBooksTile(
    guid: number,
    title: string,
    method: MethodInterface,
    books: EduContentBookInterface[],
    favoriteBooks: FavoriteInterface[] = [],
    actionHandler: BooksTileActionHandlerInterface = null,
    allowedActions: BooksTileActionType[] = [],
    product: ProductInterface = null,
    overrideSettings: BooksTileServiceOverrideInterface = {}
  ): BooksTileComponentInterface {
    const booksTileData: BooksTileComponentInterface = {
      guid,
      isAdaptive: method.isAdaptive,
      adaptiveTaskEnabled: method.adaptiveTaskEnabled,
      badge: null,
      logoUrl: method?.logoUrl,
      title: title,
      headerIcon: null,
      rows: [],
      selected: [],
      data: product || { method },
    };

    if (method.isAdaptive) {
      booksTileData.badge = {
        lottie: 'kai-adaptive',
      };
    }

    const allRowsAreDemo = books.length && books.every((book) => book.isDemo);
    if (allRowsAreDemo) {
      booksTileData.headerIcon = {
        label: 'ontdek',
        icon: 'lightbulb',
        tooltip: 'Ontdekpakket',
      };
    }

    const allRowsAreIsMarketingHighlight = books.length && books.every((book) => book.isMarketingHighlight);
    if (allRowsAreIsMarketingHighlight) {
      booksTileData.headerIcon = {
        label: 'keurieus',
        icon: 'lightbulb',
        tooltip: 'Keurieusmaand',
      };
    }

    booksTileData.rows = books.map((book) =>
      this.mapBookToRow(
        book,
        actionHandler,
        favoriteBooks,
        allRowsAreDemo,
        allRowsAreIsMarketingHighlight,
        allowedActions,
        product,
        overrideSettings.rows
      )
    );
    booksTileData.rows.sort(ArrayFunctions.sortByKey('label'));

    return booksTileData;
  }

  private mapBookToRow(
    book: EduContentBookInterface,
    actionHandler: BooksTileActionHandlerInterface,
    favoriteBooks: FavoriteInterface[],
    allRowsAreDemo = false,
    allRowsAreIsMarketingHighlight = false,
    allowedActions: BooksTileActionType[] = [],
    product: ProductInterface,
    overrideSettings: BooksTileServiceOverrideInterface['rows'] = {}
  ): BooksTileRowInterface {
    const actionTypes: BooksTileActionType[] = [];
    const row: BooksTileRowInterface = {
      guid: book.id,
      label: overrideSettings?.forceTitle ? book.title : book.caption || book.title,
      actions: [],
      data: book,
    };

    if (!allRowsAreDemo && book.isDemo) {
      row.icon = {
        icon: 'lightbulb',
        label: 'ontdek',
        className: 'ui-demo-badge',
      };
    }
    if (!allRowsAreIsMarketingHighlight && book.isMarketingHighlight) {
      row.icon = {
        icon: 'lightbulb',
        label: 'keurieus',
        className: 'ui-demo-badge',
      };
    }

    if (book.showTocOnPortal && allowedActions.includes('toc')) {
      actionTypes.push('toc');
    }

    if (allowedActions.includes('table-of-contents')) {
      actionTypes.push('table-of-contents');
    }

    if (allowedActions.includes('edu-contents')) {
      actionTypes.push('edu-contents');
    }

    if (book.showBookOnPortal && allowedActions.includes('boeke')) {
      actionTypes.push('boeke');
    }

    if (allowedActions.includes('favorite')) {
      actionTypes.push('favorite');
    }

    if (book.isAdaptive && allowedActions.includes('goals')) {
      actionTypes.push('goals');
    }
    if (book.hasEvaluations && allowedActions.includes('evaluations')) {
      actionTypes.push('evaluations');
    }

    if (book.showTocSectionsForStudent && allowedActions.includes('sections')) {
      actionTypes.push('sections');
    }
    if (allowedActions.includes('concordance')) {
      actionTypes.push('concordance');
    }

    const isFavorite = favoriteBooks.some(
      (favoriteBook) =>
        favoriteBook.eduContentBookId === book.id && (!product?.id || favoriteBook.productId === product.id)
    );

    return {
      ...row,
      actions: actionTypes.map((actionType) =>
        this.actionTypeToAction(actionType, actionHandler, row.label, isFavorite)
      ),
    };
  }

  private actionTypeToAction(
    actionType: BooksTileActionType,
    actionHandler: BooksTileActionHandlerInterface,
    partialToolTip?: string,
    isFavorite?: boolean
  ): BooksTileActionInterface {
    const actionMap = {
      toc: {
        icon: { icon: 'toc' },
        handler: actionHandler?.openToc?.bind(actionHandler),
        tooltip: `Bekijk lesmateriaal`,
      },
      boeke: {
        icon: { icon: 'boardbook' },
        handler: actionHandler?.openEduContentBook?.bind(actionHandler),
        tooltip: `Open bordboek`,
      },
      favorite: {
        icon: {
          icon: isFavorite ? 'favorite-filled' : 'favorite',
          className: isFavorite ? 'is-favorite' : null,
        },
        handler: actionHandler?.toggleBoekeFavorite?.bind(actionHandler),
        tooltip: `${partialToolTip} als favoriet ${isFavorite ? 'verwijderen' : 'zetten'}`,
      },
      'edu-contents': {
        icon: { icon: 'educontents' },
        handler: actionHandler?.openEduContents?.bind(actionHandler),
        tooltip: 'Bekijk leerobjecten',
      },
      goals: {
        icon: { icon: 'goals' },
        handler: actionHandler?.openGoals?.bind(actionHandler),
        tooltip: 'Open de doelen',
      },
      'table-of-contents': {
        icon: { icon: 'toc' },
        handler: actionHandler?.openTableOfContents?.bind(actionHandler),
        tooltip: 'Open de inhoudstafel',
      },
      sections: {
        icon: { icon: 'sections' },
        handler: actionHandler?.openSections?.bind(actionHandler),
        tooltip: 'Bekijk de secties',
      },
      evaluations: {
        icon: { icon: 'evaluations-outlined' },
        handler: actionHandler?.openEvaluations?.bind(actionHandler),
        tooltip: 'Bekijk de evaluaties',
      },
      concordance: {
        icon: { icon: 'concordances' },
        handler: actionHandler?.openConcordance?.bind(actionHandler),
        tooltip: 'Open concordantie',
      },
    };

    return actionMap[actionType];
  }
}
