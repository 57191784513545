import { Injectable } from '@angular/core';
import { WindowService } from '@campus/browser';
import {
  ContextDomainEnum,
  ContextInterface,
  CurrentExerciseActions,
  CurrentExerciseInterface,
  CurrentExerciseOptionsInterface,
  CurrentExerciseQueries,
  DalState,
  EduContent,
  EduContentInterface,
  EduContentTypeEnum,
  PersonInterface,
  ResultActions,
  ResultInterface,
  ResultStatusEnum,
  ScormCmiInterface,
  UserQueries,
} from '@campus/dal';
import {
  CustomExerciseServiceInterface,
  ExerciseConfiguration,
  ExercisePlayerOptionsInterface,
} from '@campus/exercise';
import { select, Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, filter, map, withLatestFrom } from 'rxjs/operators';
import { ScormApiService, ScormApiServiceInterface, ScormCmiMode, ScormStatus } from '../scorm-api';
import { ScormFunctions } from './scorm.functions';

@Injectable({
  providedIn: 'root',
})
export class ScormExerciseService implements CustomExerciseServiceInterface {
  constructor(private windowService: WindowService, private scormApi: ScormApiService, private store: Store<DalState>) {
    this.listenToStoreForNewExercise();
    this.listenToScormForUpdates();
  }
  //#region CustomExerciseServiceInterface
  isContentCompatible(content: EduContentInterface): boolean {
    const eduContent = content instanceof EduContent ? content : EduContent.toEduContent(content);
    return eduContent.isExercise && !eduContent.isLearnosity;
  }

  loadExercise(data: ExerciseConfiguration) {
    const { mode, eduContent } = data;
    const eduContentInstance = eduContent instanceof EduContent ? eduContent : EduContent.toEduContent(eduContent);

    switch (mode) {
      case ScormCmiMode.CMI_MODE_NORMAL: {
        const { userId, playerOptions, context } = data;
        return this.startExerciseFromContext(userId, eduContentInstance, context, playerOptions);
      }
      case ScormCmiMode.CMI_MODE_REVIEW: {
        const { result } = data;
        return this.reviewExerciseFromResult(result, eduContentInstance);
      }
      case ScormCmiMode.CMI_MODE_BROWSE: {
        const { context } = data;
        return this.previewExerciseFromContext(eduContentInstance, context);
      }

      default:
        console.warn(`Mode <<${mode}>> is not supported by the ScormExerciseService`);
    }
  }

  shouldGetResult(data: ExerciseConfiguration): boolean {
    return false; // for now, don't use this because the scorm-exercise flow gets the result in the dal exercise service
  }
  //#endregion

  private previewExerciseFromContext(eduContent: EduContent, context: ContextInterface): void {
    // LUDO has a special 'preview' mode that doesn't exist in SCORM, 'browse' would serve
    // the purpose of 'preview' in other, more SCORM-compliant packages like Articulate
    const isLudoExercise = this.isLudoExercise(eduContent);
    const isReadSpeakerEnabled = eduContent.isReadSpeakerEnabled;

    this.loadNewExerciseToStore(
      null,
      eduContent.id,
      false,
      null,
      null,
      isLudoExercise ? ScormCmiMode.CMI_MODE_PREVIEW : ScormCmiMode.CMI_MODE_BROWSE,
      null,
      null,
      context,
      { isReadSpeakerEnabled }
    );
  }

  private startExerciseFromContext(
    userId: number,
    eduContent: EduContent,
    context?: ContextInterface,
    playerOptions: ExercisePlayerOptionsInterface = {}
  ): void {
    const { withFeedback, localSession } = playerOptions;
    // LUDO abuses 'browse' mode to let the user scroll through the exercise while still saving results,
    // the purpose of 'browse', however is to preview exercises, according to the SCORM standard
    //
    // so, passing 'browse' to any other SCORM-compliant package that isn't LUDO, would be weird

    const isReadSpeakerEnabled = eduContent.isReadSpeakerEnabled;
    const saveToApi = !localSession; // localSession = true means that a teacher want's to open the exercise like a student would

    const getScormCmiMode = (withFeedback: boolean) => {
      const isLudoExercise = this.isLudoExercise(eduContent);
      if (withFeedback || (!withFeedback && !isLudoExercise)) return ScormCmiMode.CMI_MODE_NORMAL;
      // no feedback mode from here on
      if (isLudoExercise) {
        // localSession = true means that a teacher want's to open the exercise like a student would
        return localSession ? ScormCmiMode.CMI_MODE_NORMAL : ScormCmiMode.CMI_MODE_BROWSE;
      }
      return ScormCmiMode.CMI_MODE_NORMAL;
    };

    this.loadNewExerciseToStore(
      userId,
      eduContent.id,
      saveToApi,
      null,
      null,
      getScormCmiMode(withFeedback),
      null,
      null,
      context,
      {
        isReadSpeakerEnabled,
      }
    );
  }

  private reviewExerciseFromResult(result: ResultInterface, eduContent: EduContent): void {
    const isReadSpeakerEnabled = eduContent?.isReadSpeakerEnabled;

    this.loadNewExerciseToStore(
      result.personId,
      result.eduContentId,
      false,
      result.taskId,
      result.unlockedContentId,
      ScormCmiMode.CMI_MODE_REVIEW,
      result,
      null,
      // TODO: invent a ResultToContext converter
      {
        contextDomain: ContextDomainEnum.RESULT,
        taskId: result.taskId,
        unlockedContentId: result.unlockedContentId,
        unlockedFreePracticeId: result.unlockedFreePracticeId,
      },
      { isReadSpeakerEnabled }
    );
  }

  /**
   * Only open an new window, when the current exercise changes the eduContent
   */
  private listenToStoreForNewExercise() {
    const newCurrentExercise$ = this.store.pipe(
      select(CurrentExerciseQueries.getCurrentExercise),
      distinctUntilChanged((x, y) => x.eduContentId === y.eduContentId),
      filter((ex) => !!ex.eduContentId)
    );
    const permissions$ = this.store.pipe(
      select(UserQueries.getPermissionsDict),
      distinctUntilChanged(),
      map((permissionsDict) => {
        return (Object.keys(permissionsDict) || [])
          .filter((key) => key.startsWith('ludo-'))
          .map((key) => key.replace('ludo-', ''));
      })
    );
    const currentUser$ = this.store.pipe(select(UserQueries.getCurrentUser), distinctUntilChanged());

    newCurrentExercise$
      .pipe(withLatestFrom(permissions$, currentUser$))
      .subscribe(([newCurrentExercise, permissions, currentUser]) => {
        this.scormApi.init(
          newCurrentExercise.result && newCurrentExercise.result.cmi,
          newCurrentExercise.cmiMode,
          newCurrentExercise.eduContentId
        );

        if (!newCurrentExercise.options?.isReadSpeakerEnabled) {
          permissions = permissions.filter((permission) => permission !== 'readspeaker');
        }

        this.openNewUrl(newCurrentExercise.url, permissions, currentUser);
      });
  }

  private listenToScormForUpdates() {
    this.scormApi.cmi$
      .pipe(
        filter((cmi) => !!cmi),
        debounceTime(1000),
        withLatestFrom(this.store.pipe(select(CurrentExerciseQueries.getCurrentExercise))),
        // saveToApi false -> preview, review, teacher open
        // no need to update store
        // result will be undefined
        filter(([cmi, state]) => state.saveToApi)
      )
      .subscribe(([cmi, state]) => {
        //update values in state and return
        this.updateExerciseToStore(
          state.result.personId,
          {
            ...state,
            result: {
              ...state.result,
              cmi: cmi,
            },
          },
          this.scormApi
        );
      });
  }

  private openNewUrl(url: string, permissions = [], currentUser?: PersonInterface) {
    if (url) {
      this.openWindow(url, permissions, currentUser);
    }
  }

  private loadNewExerciseToStore(
    userId: number,
    eduContentId: number,
    saveToApi: boolean,
    taskId: number = null,
    unlockedContentId: number = null,
    mode: ScormCmiMode,
    result?: ResultInterface,
    unlockedFreePracticeId: number = null,
    context?: ContextInterface,
    options?: CurrentExerciseOptionsInterface
  ) {
    this.store.dispatch(
      new CurrentExerciseActions.LoadExercise({
        userId,
        eduContentId,
        saveToApi,
        taskId,
        unlockedContentId,
        cmiMode: mode,
        result,
        unlockedFreePracticeId,
        context,
        options,
      })
    );
  }

  private openWindow(url: string, permissions: string[], currentUser?: PersonInterface) {
    this.windowService.openWindow('scorm', url, true, permissions, currentUser);
  }

  private updateExerciseToStore(
    userId: number,
    currentExercise: CurrentExerciseInterface,
    scormApi?: Pick<ScormApiServiceInterface, 'hasFinished'>
  ) {
    const cmi: ScormCmiInterface = JSON.parse(currentExercise.result.cmi);
    const updatedExerciseResult = this.updateResult({ ...currentExercise.result }, cmi, scormApi);

    const exerciseCompleted = cmi ? ScormStatus.isCompleted(cmi.core.lesson_status) : false;

    this.store.dispatch(
      new CurrentExerciseActions.SaveCurrentExercise({
        userId: userId,
        exercise: {
          ...currentExercise,
          result: updatedExerciseResult,
        },
        // since the exercise is constantly saved, we only want to display feedback when the exercise is completed
        customFeedbackHandlers: { useCustomSuccessHandler: !exerciseCompleted },
      })
    );

    // this won't update the state if a student does not complete an exercise
    // the alternative is always updating the results store, but that would be execessive
    if (exerciseCompleted && currentExercise.saveToApi) {
      this.store.dispatch(
        new ResultActions.UpsertResult({
          result: {
            ...currentExercise.result,
            // these are set by the api
            // assigning temp values until results are re-loaded
            status: ResultStatusEnum.STATUS_COMPLETED,
            lastUpdated: new Date(),
          },
        })
      );
    }
  }

  private updateResult(
    result: ResultInterface,
    cmi: ScormCmiInterface,
    scormApi: Pick<ScormApiServiceInterface, 'hasFinished'> | null
  ): ResultInterface {
    // don't modify the original
    result = { ...result };

    let totalTimeMs = 0;
    let sessionTimeMs = 0;

    if (cmi) {
      // fallback to result.time for Articulate
      // LUDO sets total_time directly, but Articulate needs to know what the previous total was,
      // which isn't contained in cmi.core.total_time at all, so we grab it from the result
      totalTimeMs = ScormFunctions.cmiTimeStringToMilliseconds(cmi.core.total_time) || result.time;

      if (scormApi && scormApi.hasFinished) {
        sessionTimeMs = ScormFunctions.cmiTimeStringToMilliseconds(cmi.core.session_time);
      }
    }

    result.score = cmi ? cmi.core.score.raw : 0;
    result.status = cmi ? (cmi.core.lesson_status as unknown as ResultStatusEnum) : ResultStatusEnum.STATUS_INCOMPLETE;
    result.time = cmi ? totalTimeMs + sessionTimeMs : 0;

    return result;
  }

  private isLudoExercise(eduContent: EduContent): boolean {
    return eduContent.type === EduContentTypeEnum.EXERCISE;
  }
}
