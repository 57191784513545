import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EduContentBookApi, EduContentTOCApi } from '../+api';
import {
  EduContentBookInterface,
  EduContentTOCEduContentInterface,
  EduContentTOCEduContentMetadataInterface,
  EduContentTOCInterface,
  EvaluationWithSubjectsInterface,
} from '../+models';
import { EduContentTocServiceInterface } from './edu-content-toc.service.interface';

@Injectable({
  providedIn: 'root',
})
export class EduContentTocService implements EduContentTocServiceInterface {
  constructor(private eduContentTOCApi: EduContentTOCApi, private eduContentBookApi: EduContentBookApi) {}

  getBooksByMethodIds(methodIds: number[]): Observable<EduContentBookInterface[]> {
    return this.eduContentBookApi.find({
      where: { methodId: { inq: methodIds } },
      include: [{ relation: 'years' }],
    });
  }

  getTree(bookId: number): Observable<EduContentTOCInterface[]> {
    return this.eduContentTOCApi.tree(bookId);
  }

  getBooksByIds(bookIds: number[]): Observable<EduContentBookInterface[]> {
    return this.eduContentBookApi.find({
      where: { id: { inq: bookIds } },
      include: [{ relation: 'years' }],
    });
  }

  getEduContentTocEduContentForBookId(bookId: number): Observable<EduContentTOCEduContentInterface[]> {
    return this.eduContentBookApi.getUnlockedExercisesRemote(bookId);
  }

  getEduContentTocsForBook(bookId: number): Observable<EduContentTOCInterface[]> {
    return this.eduContentTOCApi.getEduContentTocsForBook(bookId).pipe(map((res) => res.eduContentTOCs));
  }

  addEvaluation(eduContentTOCId: number): Observable<EvaluationWithSubjectsInterface> {
    return this.eduContentTOCApi.patchAttributes(eduContentTOCId, {
      isEvaluation: true,
    }) as Observable<EvaluationWithSubjectsInterface>;
  }

  deleteEvaluation(eduContentTOCId: number): Observable<boolean> {
    return this.eduContentTOCApi.deleteEvaluation(eduContentTOCId);
  }

  moveEvaluation(oldEduContentTOCId: number, newEduContentTOCId: number): Observable<EvaluationWithSubjectsInterface> {
    return this.eduContentTOCApi.moveEvaluation(
      oldEduContentTOCId,
      newEduContentTOCId
    ) as Observable<EvaluationWithSubjectsInterface>;
  }

  updateEduContentToc(
    eduContentTOCId: number,
    eduContentTOC: Partial<EduContentTOCInterface>
  ): Observable<EduContentTOCInterface> {
    return this.eduContentTOCApi.updateEduContentTOC(
      eduContentTOCId,
      eduContentTOC
    ) as Observable<EduContentTOCInterface>;
  }

  updateEduContentMetadataDisplayOrder(
    eduContentTOCId: number,
    displayOrderByEduContentMetadataId: Dictionary<number>
  ): Observable<EduContentTOCEduContentMetadataInterface[]> {
    return this.eduContentTOCApi.updateEduContentMetadataDisplayOrder(
      eduContentTOCId,
      displayOrderByEduContentMetadataId
    );
  }
}
