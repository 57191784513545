import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EnvironmentApiInterface, ENVIRONMENT_API_TOKEN } from '@campus/environment';
import { Observable } from 'rxjs';
import {
  LearnosityItemsApiInterface,
  LearnosityItemsConfigInterface,
  LearnosityItemsSettingsInterface,
  LearnositySessionResponsesInterface,
} from '../+external-interfaces';
import { ContextInterface, ResultInterface } from '../+models';

export interface IntermediateReportInterface {
  user_id: number;
  data: {
    learnosityResponses: LearnositySessionResponsesInterface;
    result: ResultInterface;
  };
}

@Injectable({ providedIn: 'root' })
export class LearnosityAssessementApiService implements LearnosityItemsApiInterface {
  private http = inject(HttpClient);
  private api: EnvironmentApiInterface = inject(ENVIRONMENT_API_TOKEN);

  private basePath = `${this.api.APIBase}/api/Learnosity`;

  constructor() {}

  getLearnosityItemsConfig(
    eduContentId: number,
    learnosityReference: string,
    sessionId: string,
    settings?: LearnosityItemsSettingsInterface,
    context?: ContextInterface
  ): Observable<LearnosityItemsConfigInterface> {
    const url = `${this.basePath}/items/request/${eduContentId}/reference/${learnosityReference}/session/${sessionId}`;
    const params = new HttpParams({
      fromObject: {
        ...(settings ? { settings: JSON.stringify(settings) } : {}),
        ...(context ? { context: JSON.stringify(context) } : {}),
      },
    });

    return this.http.get(url, {
      params,
    });
  }

  getLearnosityLocalItemsConfig(
    eduContentId: number,
    learnosityReference: string,
    settings?: LearnosityItemsSettingsInterface
  ): Observable<LearnosityItemsConfigInterface> {
    const params = new HttpParams({ fromString: `settings=${JSON.stringify(settings)}` });
    return this.http.get(`${this.basePath}/items/request/${eduContentId}/reference/${learnosityReference}`, {
      params,
      withCredentials: true,
    });
  }

  getIntermediateReport(
    learnosityInitResponse: LearnosityItemsConfigInterface
  ): Observable<IntermediateReportInterface> {
    const { session_id: sessionId, user_id: userId, activity_id: activityId } = learnosityInitResponse.request;

    const url = `${this.basePath}/reports/intermediate/${sessionId}`;
    const params = new HttpParams({
      fromObject: {
        sessionId,
        userId,
        activityId,
      },
    });

    return this.http.get<IntermediateReportInterface>(url, {
      params,
    });
  }
}
