import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { UnlockedFreePracticeServiceInterface } from '.';
import { PersonApi } from '../+api';
import { UnlockedFreePracticeInterface } from '../+models';

@Injectable({
  providedIn: 'root',
})
export class UnlockedFreePracticeService implements UnlockedFreePracticeServiceInterface {
  constructor(private personApi: PersonApi) {}

  getAllForUser(userId): Observable<UnlockedFreePracticeInterface[]> {
    return this.personApi
      .getData(userId, 'unlockedFreePractices')
      .pipe(map((res: { unlockedFreePractices: UnlockedFreePracticeInterface[] }) => res.unlockedFreePractices));
  }

  createUnlockedFreePractices(
    userId: number,
    unlockedFreePractices: UnlockedFreePracticeInterface[]
  ): Observable<UnlockedFreePracticeInterface[]> {
    return this.personApi.createUnlockedFreePractices(userId, unlockedFreePractices);
  }

  replaceUnlockedFreePracticesForBook(
    userId: number,
    productId: number,
    bookId: number,
    groupIds: number[]
  ): Observable<UnlockedFreePracticeInterface[]> {
    return this.personApi.replaceUnlockedFreePracticesForBook(userId, productId, bookId, groupIds);
  }

  deleteUnlockedFreePractices(userId: number, unlockedFreePracticeIds: number[]): Observable<boolean> {
    return this.personApi.deleteManyUnlockedFreePracticeRemote(userId, unlockedFreePracticeIds).pipe(mapTo(true));
  }
}
