import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { fetch, optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { combineLatest, from, Observable } from 'rxjs';
import { map, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { GoalQueries } from '.';
import { GoalInterface } from '../../+models';
import { GoalServiceInterface, GOAL_SERVICE_TOKEN } from '../../goal/goal.service.interface';
import { DalState } from '../dal.state.interface';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { EvaluationSubjectGoalActions, EvaluationSubjectGoalQueries } from '../evaluation-subject-goal';
import { GoalEduContentTOCActions, GoalEduContentTOCQueries } from '../goal-edu-content-toc';
import { GoalPrerequisiteActions, GoalPrerequisiteQueries } from '../goal-prerequisite';
import { GoalYearActions, GoalYearQueries } from '../goal-year';
import {
  AddGoal,
  AddGoalsForLearningArea,
  DeleteGoal,
  GoalsActionTypes,
  GoalsLoadError,
  LoadGoalsForLearningArea,
  NavigateToGoalPanel,
  StartAddGoal,
  UpdateGoal,
  UpdateGoalDisplayOrders,
} from './goal.actions';

@Injectable()
export class GoalEffects {
  startAddGoal$ = createEffect(() =>
    this.actions.pipe(
      ofType(GoalsActionTypes.StartAddGoal),
      pessimisticUpdate({
        run: (action: StartAddGoal) => {
          return this.goalService.upsertGoal(action.payload.goal).pipe(
            switchMap((goal: GoalInterface) => {
              const actionsToDispatch: Action[] = [
                new AddGoal({ goal }),
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'Het doel is toegevoegd.'
                  ),
                }),
              ];

              if (action.payload.navigateAfterCreate) {
                actionsToDispatch.push(new NavigateToGoalPanel({ goal }));
              }

              return from(actionsToDispatch);
            })
          );
        },
        onError: (action: StartAddGoal, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het doel toe te voegen.'
            ),
          });
        },
      })
    )
  );

  loadGoalsForLearningArea$ = createEffect(() =>
    this.actions.pipe(
      ofType(GoalsActionTypes.LoadGoalsForLearningArea),
      concatLatestFrom(() => this.store.select(GoalQueries.selectGoalState)),
      fetch({
        run: (action: LoadGoalsForLearningArea, goalState) => {
          const { learningAreaId } = action.payload;

          if (goalState.loadedForLearningArea[learningAreaId]) return;

          return this.goalService.getGoalsForLearningArea(learningAreaId).pipe(
            map(
              (goals) =>
                new AddGoalsForLearningArea({
                  learningAreaId,
                  goals,
                })
            )
          );
        },
        onError: (action: LoadGoalsForLearningArea, error) => {
          return new GoalsLoadError(error);
        },
      })
    )
  );

  updateGoal$ = createEffect(() =>
    this.actions.pipe(
      ofType(GoalsActionTypes.UpdateGoal),
      optimisticUpdate({
        run: (action: UpdateGoal) => {
          const { changes } = action.payload.goal;
          return this.goalService.upsertGoal(changes as GoalInterface).pipe(
            mapTo(
              new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(this.uuid(), action, 'Het doel is aangepast.'),
              })
            )
          );
        },
        undoAction: (action: UpdateGoal, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het doel aan te passen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  updateGoalDisplayOrders$ = createEffect(() =>
    this.actions.pipe(
      ofType(GoalsActionTypes.UpdateGoalDisplayOrders),
      optimisticUpdate({
        run: (action: UpdateGoalDisplayOrders) => {
          const { goals } = action.payload;
          return this.goalService.updateDisplayOrders(goals.map((g) => ({ id: +g.id, ...g.changes }))).pipe(
            mapTo(
              new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'De volgorde is aangepast.'
                ),
              })
            )
          );
        },
        undoAction: (action: UpdateGoalDisplayOrders, error: any) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om volgorde aan te passen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  deleteGoal$ = createEffect(() =>
    this.actions.pipe(
      ofType(GoalsActionTypes.DeleteGoal),
      optimisticUpdate({
        run: (action: DeleteGoal) => {
          return this.goalService.removeGoal(action.payload.id).pipe(
            switchMap((_) => this.getDeleteGoalRelationActions(action.payload.id)),
            switchMap((removeActions) => {
              return from([
                ...removeActions,
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'Het doel is verwijderd.'
                  ),
                }),
              ]);
            })
          );
        },
        undoAction: (action: DeleteGoal, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het doel te verwijderen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  redirectToGoalPanel$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(GoalsActionTypes.NavigateToGoalPanel),
        tap((action: NavigateToGoalPanel) => {
          this.router.navigate([], {
            queryParams: { goalId: action.payload.goal.id },
            queryParamsHandling: 'merge',
          });
        })
      ),
    { dispatch: false }
  );

  private getDeleteGoalRelationActions(goalId: number): Observable<Action[]> {
    return combineLatest([
      this.store.pipe(select(GoalEduContentTOCQueries.getAllByGoalId)),
      this.store.pipe(select(GoalYearQueries.getByGoalId)),
      this.store.pipe(select(GoalPrerequisiteQueries.getAllByGoalPrerequisite)),
      this.store.pipe(select(GoalPrerequisiteQueries.getAllByGoalPostrequisite)),
      this.store.pipe(select(EvaluationSubjectGoalQueries.getByGoalId)),
    ]).pipe(
      take(1),
      map(
        ([
          goalEduContentTocsByGoalId,
          goalYearsByGoalId,
          goalPrerequisitesByGoalId,
          goalPostrequisitesByGoalId,
          evaluationSubjectGoalsByGoalId,
        ]) => {
          const actions: Action[] = [];

          const goalEduContentTOCIds = (goalEduContentTocsByGoalId[goalId] || []).map(
            (goalEduContentToc) => goalEduContentToc.id
          );
          const goalYearIds = (goalYearsByGoalId[goalId] || []).map((goalYear) => goalYear.id);

          const goalPrerequisiteIds = Array.from(
            new Set(
              [...(goalPrerequisitesByGoalId[goalId] || []), ...(goalPostrequisitesByGoalId[goalId] || [])].map(
                (goalPrerequisite) => goalPrerequisite.id
              )
            )
          );

          const evaluationSubjectGoalIds = (evaluationSubjectGoalsByGoalId[goalId] || []).map(
            (evaluationSubjectGoal) => evaluationSubjectGoal.id
          );

          if (goalEduContentTOCIds?.length) {
            actions.push(
              new GoalEduContentTOCActions.DeleteGoalEduContentTOCs({
                ids: goalEduContentTOCIds,
              })
            );
          }

          if (goalYearIds?.length) {
            actions.push(
              new GoalYearActions.DeleteGoalYears({
                ids: goalYearIds,
              })
            );
          }

          if (goalPrerequisiteIds?.length) {
            actions.push(
              new GoalPrerequisiteActions.DeleteGoalPrerequisites({
                ids: goalPrerequisiteIds,
              })
            );
          }

          if (evaluationSubjectGoalIds?.length) {
            actions.push(
              new EvaluationSubjectGoalActions.DeleteEvaluationSubjectGoals({
                ids: evaluationSubjectGoalIds,
              })
            );
          }

          return actions;
        }
      )
    );
  }

  constructor(
    private actions: Actions,
    private router: Router,
    private store: Store<DalState>,
    @Inject(GOAL_SERVICE_TOKEN)
    private goalService: GoalServiceInterface,
    @Inject('uuid') private uuid: () => string
  ) {}
}
