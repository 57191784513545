import { Action } from '@ngrx/store';
import { BookDataResponseInterface } from '../../../data/data.service.interface';

export enum BookDataActionTypes {
  LoadBookData = '[Data] Load Book Data',
  BookDataLoaded = '[Data] Book Data Loaded',
  BookDataLoadError = '[Data] Book Data Load Error',
}

export class BookDataLoadError implements Action {
  readonly type = BookDataActionTypes.BookDataLoadError;
  constructor(public payload: any) {}
}

export class LoadBookData implements Action {
  readonly type = BookDataActionTypes.LoadBookData;

  constructor(
    public payload: {
      bookId: number;
      fields: Array<keyof BookDataResponseInterface>;
      force?: boolean;
    }
  ) {}
}

export class BookDataLoaded implements Action {
  readonly type = BookDataActionTypes.BookDataLoaded;

  constructor(
    public payload: {
      bookId: number;
    }
  ) {}
}
