import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { TermsModalDataInterface } from './terms-modal-data.interface';

@Component({
  selector: 'campus-terms-modal',
  templateUrl: './terms-modal.component.html',
})
export class TermsModalComponent implements TermsModalDataInterface {
  id: number;
  title: string;
  message: string;
  icon: string;
  confirmButtonType: 'default' | 'danger';
  confirmButtonLabel: string;
  cancelButtonLabel: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: TermsModalDataInterface,
    public dialogRef: MatDialogRef<TermsModalComponent>
  ) {
    this.id = data.id;
    this.title = data.title;
    this.message = data.message;
    this.icon = data.icon;
    this.confirmButtonType = data.confirmButtonType || 'default';
    this.confirmButtonLabel = data.confirmButtonLabel || 'Ik ga akkoord';
    this.cancelButtonLabel = data.cancelButtonLabel;
  }

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
