import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { DiaboloPhaseQueries } from '.';
import {
  DiaboloPhaseServiceInterface,
  DIABOLO_PHASE_SERVICE_TOKEN,
} from '../../metadata/diabolo-phase.service.interface';
import { DalState } from '../dal.state.interface';
import {
  DiaboloPhasesActionTypes,
  DiaboloPhasesLoaded,
  DiaboloPhasesLoadError,
  LoadDiaboloPhases,
} from './diabolo-phase.actions';

@Injectable()
export class DiaboloPhaseEffects {
  loadDiaboloPhases$ = createEffect(() =>
    this.actions.pipe(
      ofType(DiaboloPhasesActionTypes.LoadDiaboloPhases),
      concatLatestFrom(() => this.store.select(DiaboloPhaseQueries.getLoaded)),
      fetch({
        run: (action: LoadDiaboloPhases, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.diaboloPhaseService
            .getAll()
            .pipe(map((diaboloPhases) => new DiaboloPhasesLoaded({ diaboloPhases })));
        },
        onError: (action: LoadDiaboloPhases, error) => {
          return new DiaboloPhasesLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(DIABOLO_PHASE_SERVICE_TOKEN)
    private diaboloPhaseService: DiaboloPhaseServiceInterface
  ) {}
}
