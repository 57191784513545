import { trigger } from '@angular/animations';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Params, QueryParamsHandling } from '@angular/router';
import { CollapsibleAnimationState, collapseAnimation, fadeSlamAnimation } from '../animations';

/**
 * Json node data with nested structure. Each node has a title, an icon and optionally a list children
 *
 * @export
 * @interface NavItem
 */
export interface NavItem {
  id?: number | string;
  title: string;
  description?: string;
  icon?: string;
  link?: any[] | string;
  children?: NavItem[];
  expanded?: boolean;
  requiredPermissions?: (string | string[])[];
  hideWhenRequiredPermissions?: (string | string[])[];
  isActive?: boolean;
  badge?: string;
  usetifulIdentifier?: string;
  cssClass?: string;
  dividerBefore?: boolean;
  data?: any; // data associated with this navItem
  queryParams?: Params;
  permissions?: string[];
  userSpecificFilter?: (person: any) => boolean; // cannot PersonInterface import here
  queryParamsHandling?: QueryParamsHandling;
}

/**
 * Navigation tree
 *
 * @example
 *   <campus-nav-tree [treeNav]="myNavigationTree|async"></campus-nav-tree>
 * @example
 *
 * @export
 * @class TreeNavComponent
 */
@Component({
  selector: 'campus-tree-nav',
  templateUrl: './tree-nav.component.html',
  styleUrls: ['./tree-nav.component.scss'],
  animations: [
    trigger('toggleOpened', collapseAnimation),
    trigger('fadeIn', fadeSlamAnimation({ delay: '1s', dustColor: '255,255,255' })),
  ],
})
export class TreeNavComponent {
  @HostBinding('class.ui-tree-nav') hasTreeNavClass = true;
  CollapsibleAnimationState = CollapsibleAnimationState;

  /**
   * Current tree nav
   *
   * @private
   * @type {NavItem[]}
   * @memberof TreeNavComponent
   */
  private _treeNav: NavItem[];

  @Input()
  set treeNav(treeNav: NavItem[]) {
    this._treeNav = treeNav;
    this.updateTreeNav();
  }

  @Input() indicatorsByNodeId: {[key: string]: boolean} = {};

  @Input()
  mode: 'vertical' | 'horizontal' = 'vertical';

  // determines whether the tree nav should take care of navigation (via routerLink) or the parent component
  @Input()
  handleNavigation = true;

  @Input()
  animate: boolean;

  @Output() navItemChanged = new EventEmitter<NavItem>();

  public activeNode: NavItem;

  /**
   * Datasource for CdkTree from Angular Material CDK
   *
   * @type {MatTreeNestedDataSource<NavItem>}
   * @memberof TreeNavComponent
   */
  nestedDataSource: MatTreeNestedDataSource<NavItem>;
  /**
   * Nested tree control for CdkTree from Angular Material CDK
   *
   * @type {NestedTreeControl<NavItem>}
   * @memberof TreeNavComponent
   */
  nestedTreeControl: NestedTreeControl<NavItem>;

  constructor() {
    this.nestedDataSource = new MatTreeNestedDataSource();
    this.nestedTreeControl = new NestedTreeControl<NavItem>(this.getChildren);
  }

  public toggleNode(node: NavItem) {
    this.navItemChanged.emit({ ...node, expanded: !node.expanded });
  }

  /**
   * Function that should return true if the nested node template should be used for the provided index and node data
   * https://material.angular.io/cdk/tree/api#CdkTreeNodeDef
   *
   * @param {number} _
   * @param {NavItem} nodeData
   * @returns {boolean}
   * @memberof TreeNavComponent
   */
  hasChildren = (_: number, nodeData: NavItem): boolean => nodeData.children && nodeData.children.length > 0;

  /**
   * Get children of selected node
   *
   * @private
   * @param {NavItem} node
   * @returns {NavItem[]}
   * @memberof TreeNavComponent
   */
  private getChildren = (node: NavItem): NavItem[] => node.children;

  /**
   * Update tree to current state
   *
   * @private
   * @returns {void}
   * @memberof TreeNavComponent
   */
  private updateTreeNav(): void {
    this.nestedDataSource.data = this._treeNav;
    this.nestedTreeControl.dataNodes = this._treeNav;
    this.activeNode = this.findActiveNode(this._treeNav);

    this.setExpanded(this.nestedTreeControl.dataNodes);
  }

  private findActiveNode(children: NavItem[]): NavItem {
    let found: NavItem;

    children.some((node) => {
      if (node.isActive) {
        return (found = node);
      }
      if (node.children) {
        const foundChild = this.findActiveNode(node.children);
        if (foundChild) {
          return (found = foundChild);
        }
      }
    });

    return found;
  }

  /**
   * Open tree nodes where 'expanded' property is true
   *
   * @private
   * @param {NavItem[]} nodes
   * @returns {void}
   * @memberof TreeNavComponent
   */
  private setExpanded(nodes: NavItem[]): void {
    if (!nodes) {
      return;
    }
    nodes.forEach((node) => {
      if (node.expanded || node.isActive || (node.children || []).some((child) => child.isActive)) {
        this.nestedTreeControl.expand(node);
      }
      this.setExpanded(node.children);
    });
  }

  trackById(index: number, value: NavItem) {
    const childIds = (value?.children || []).map((child) => child.id).join('-');
    const queryParams = Object.entries(value.queryParams || {})
      .map(([key, val]) => `${key}-${val}`)
      .join('-');

    return [value.id, childIds, value.badge, queryParams].filter((id) => !!id).join('-');
  }
}
