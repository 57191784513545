import { LottieConfigInterface } from '@campus/environment';

export const animations: { [key: string]: LottieConfigInterface } = {
  logout: {
    path: 'animations/logout.json',
    autoplay: true,
    loop: true,
    initialSegment: [0, 90],
    mouseenter: {
      speed: 0.25,
    },
    mouseleave: { speed: 1 },
  },
  redirecting: {
    path: 'animations/redirecting.json',
    autoplay: true,
    loop: true,
    mouseenter: {
      speed: 0.25,
    },
    mouseleave: { speed: 1 },
  },
  loading: {
    path: 'animations/polpo-loading.json',
    autoplay: true,
    loop: true,
    mouseenter: {
      speed: 0.25,
    },
    mouseleave: { speed: 1 },
  },
  'menu-bg': {
    path: 'animations/menu/menu_bg.json',
    loop: true,
    autoplay: true,
  },
  'menu:bundles': {
    path: 'animations/menu/bundles.json',
    autoplay: false,
    mouseenter: {
      loop: true,
    },
    mouseleave: {
      loop: false,
    },
  },
  'menu:results': {
    path: 'animations/menu/results.json',
    autoplay: false,
    mouseenter: {
      loop: true,
    },
    mouseleave: {
      loop: false,
    },
  },
  'menu:home': {
    path: 'animations/menu/home.json',
    autoplay: false,
    mouseenter: {
      loop: true,
    },
    mouseleave: {
      loop: false,
    },
  },
  'menu:students': {
    path: 'animations/menu/students.json',
    autoplay: false,
    mouseenter: {
      loop: true,
    },
    mouseleave: {
      loop: false,
    },
  },
  'menu:methods': {
    path: 'animations/menu/methods.json',
    autoplay: false,
    mouseenter: {
      loop: true,
    },
    mouseleave: {
      loop: false,
    },
  },
  'menu:tasks': {
    path: 'animations/menu/tasks.json',
    autoplay: false,
    mouseenter: {
      loop: true,
    },
    mouseleave: {
      loop: false,
    },
  },
  delete: {
    path: 'animations/icons/trash.json',
    loop: false,
    autoplay: false,
    initialSegment: [0, 0],
    mouseenter: {
      loop: false,
      segment: [0, 5],
    },
    mouseleave: {
      loop: false,
      revert: 'mouseenter',
    },
    click: {
      loop: false,
      preventBeforeComplete: ['mouseleave', 'click'],
      segment: [6, 13],
    },
  },
  results: {
    path: 'animations/icons/results.json',
    loop: false,
    autoplay: false,
    initialSegment: [0, 0],
    mouseenter: {
      loop: true,
      segment: [0, 16],
    },
    mouseleave: {
      loop: false,
      revert: 'mouseenter',
    },
  },
  share: {
    path: 'animations/icons/share.json',
    loop: false,
    autoplay: false,
    initialSegment: [59, 59],
    mouseenter: {
      loop: false,
      segment: [0, 59],
    },
    mouseleave: {
      loop: false,
      segment: [59, 59],
    },
  },
  favorite: {
    path: 'animations/favorite.json',
    autoplay: false,
    loop: false,
  },
};
