<form class="ui-date-range-picker__form" [formGroup]="dateRangeForm" [class.warning]="dateRangeForm.invalid">
  <div class="ui-date-range-picker__section">
    <mat-form-field class="ui-date-range-picker__mat-form-field--date">
      <mat-label>Vanaf</mat-label>
      <input
        [readonly]="readonly"
        autocomplete="off"
        matInput
        [matDatepicker]="startDatePicker"
        formControlName="startDate"
        (click)="startDatePicker.open()"
      />
      <mat-datepicker #startDatePicker [dateClass]="applyClassToDateInRange.bind(this)"></mat-datepicker>
      <campus-icon matSuffix svgIcon="arrow-drop-down" (click)="startDatePicker.open()"></campus-icon>
    </mat-form-field>
    <mat-form-field class="ui-date-range-picker__mat-form-field--time" *ngIf="useTime">
      <mat-label>Tijd</mat-label>
      <input [readonly]="readonly" formControlName="startTime" autocomplete="off" matInput type="time" />
    </mat-form-field>
  </div>
  <div *ngIf="useEnd" class="ui-date-range-picker__section">
    <mat-form-field class="ui-date-range-picker__mat-form-field--date">
      <mat-label>t.e.m.</mat-label>
      <input
        [readonly]="readonly"
        autocomplete="off"
        matInput
        [matDatepicker]="endDatePicker"
        [min]="getMinEndDate"
        formControlName="endDate"
        (click)="endDatePicker.open()"
      />
      <mat-datepicker #endDatePicker [dateClass]="applyClassToDateInRange.bind(this)"></mat-datepicker>
      <campus-icon matSuffix svgIcon="arrow-drop-down" (click)="endDatePicker.open()"></campus-icon>
    </mat-form-field>
    <mat-form-field class="ui-date-range-picker__mat-form-field--time" *ngIf="useTime">
      <mat-label>Tijd</mat-label>
      <input [readonly]="readonly" autocomplete="off" formControlName="endTime" matInput type="time" />
    </mat-form-field>
  </div>
</form>
