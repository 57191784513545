import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { PersonApi, UnlockedCurriculumTreeApi } from '../+api';
import { UnlockedCurriculumTreeInterface } from '../+models';
import { UnlockedCurriculumTreeServiceInterface } from './unlocked-curriculum-tree.service.interface';

@Injectable({
  providedIn: 'root',
})
export class UnlockedCurriculumTreeService implements UnlockedCurriculumTreeServiceInterface {
  constructor(private personApi: PersonApi, private unlockedCurriculumTreeApi: UnlockedCurriculumTreeApi) {}

  createUnlockedCurriculumTrees(
    userId: number,
    unlockedCurriculumTrees: UnlockedCurriculumTreeInterface[]
  ): Observable<UnlockedCurriculumTreeInterface[]> {
    return this.unlockedCurriculumTreeApi.createUnlockedCurriculumTrees(unlockedCurriculumTrees);
  }

  getAllForUser(userId: number): Observable<UnlockedCurriculumTreeInterface[]> {
    return this.personApi
      .getData(userId, 'unlockedCurriculumTrees')
      .pipe(map((res: { unlockedCurriculumTrees: UnlockedCurriculumTreeInterface[] }) => res.unlockedCurriculumTrees));
  }

  deleteUnlockedCurriculumTrees(userId: number, unlockedCurriculumTreeIds: number[]): Observable<boolean> {
    return this.unlockedCurriculumTreeApi.deleteUnlockedCurriculumTrees(unlockedCurriculumTreeIds).pipe(mapTo(true));
  }
}
