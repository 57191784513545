import { ContentInterface } from './Content.interface';
import { EduContentInterface, EduContentTypeEnum } from './EduContent.interface';
import { EduContentMetadataInterface } from './EduContentMetadata.interface';
import { EduContentNoteInterface } from './EduContentNote.interface';
import { EduContentSourceEnum } from './EduContentSource.interface';
import { EduFileTypeEnum } from './EduFile.interface';
import { FavoriteInterface } from './Favorite.interface';
import { LearningAreaInterface } from './LearningArea.interface';
import { LevelInterface } from './Level.interface';
import { MethodLevelInterface } from './MethodLevel.interface';
import { ProductContentInterface } from './ProductContent.interface';
import { ResultInterface } from './Result.interface';
import { TaskInterface } from './Task.interface';
import { UnlockedBoekeGroupInterface } from './UnlockedBoekeGroup.interface';
import { UnlockedBoekeStudentInterface } from './UnlockedBoekeStudent.interface';
import { UnlockedContentInterface } from './UnlockedContent.interface';

export type EduContentMetadataKey = 'publishedEduContentMetadata' | 'draftEduContentMetadata';

export class EduContent implements EduContentInterface, ContentInterface {
  contentType: string;
  id?: number;
  type: EduContentTypeEnum;
  publishedEduContentMetadataId?: number;
  productContents?: ProductContentInterface[];
  unlockedContents?: UnlockedContentInterface[];
  unlockedBoekeStudents?: UnlockedBoekeStudentInterface[];
  unlockedBoekeGroups?: UnlockedBoekeGroupInterface[];
  tasks?: TaskInterface[];
  results?: ResultInterface[];
  eduContentMetadata?: EduContentMetadataInterface[];
  publishedEduContentMetadata?: EduContentMetadataInterface;
  notes?: EduContentNoteInterface[];
  favorites?: FavoriteInterface[];
  minimal?: EduContentInterface;

  get name(): string {
    return this.metadata?.title || '';
  }
  get productType(): string {
    return this.metadata?.eduContentProductType?.icon || '';
  }
  get productTypeName(): string {
    return this.metadata?.eduContentProductType?.name || '';
  }
  get productTypeCode(): string {
    return this.metadata?.eduContentProductType?.code || '';
  }
  get icon(): string {
    return 'file-type:' + (this.fileExtension || this.productType);
  }
  get fileExtension(): string {
    return this.metadata?.fileExt;
  }
  get fileTypeLabel(): string {
    return this.metadata?.fileLabel;
  }
  get previewImage(): string {
    return this.metadata?.thumbSmall || '';
  }
  get description(): string {
    return this.metadata?.description || '';
  }
  get taskAllowed(): boolean {
    return !!this.metadata?.taskAllowed;
  }
  get methodLogos(): string[] {
    // always returns an array with an element
    // but element can be undefined
    if (!this.metadata?.methods?.[0]) return [];

    return this.metadata.methods.map((m) => 'method:' + m.icon);
  }
  get methodNames(): string[] {
    if (!this.metadata) return;

    // always returns an array with an element
    // but element can be undefined
    if (!this.metadata.methods?.[0]) return [];

    const years = this.metadata.years;
    if (!years?.length) return this.metadata.methods.map((m) => m.name);

    return this.metadata.methods.reduce((arr, method) => {
      arr.push(years.map((year) => `${method.name} ${year.name.substring(1)}`));
      return arr;
    }, []);
  }
  get streamable(): boolean {
    return this.metadata?.streamable;
  }

  get image(): string {
    return this.metadata?.image;
  }

  get isVideo(): boolean {
    return this.fileExtension === 'video';
  }

  get diaboloPhaseIds(): number[] {
    if (!this.metadata) return [];

    const { diaboloPhaseIds, diaboloPhases } = this.metadata;

    if (Array.isArray(diaboloPhaseIds)) {
      return diaboloPhaseIds;
    }
    if (Array.isArray(diaboloPhases)) {
      return diaboloPhases.map((diaboloPhase) => diaboloPhase.id);
    }
    return [];
  }
  get methodIds(): number[] {
    if (!this.metadata) return [];

    const { methodIds, methods } = this.metadata;

    if (Array.isArray(methodIds)) {
      return methodIds;
    }
    if (Array.isArray(methods)) {
      return methods.map((method) => method.id);
    }
    return [];
  }
  get levelId(): number {
    return this.metadata?.levelId;
  }

  get level(): LevelInterface {
    return this.metadata?.level;
  }

  get methodLevel(): MethodLevelInterface {
    return this.metadata?.methodLevel;
  }

  get hasSolutionFile(): boolean {
    if (!this.metadata) return false;

    return !!this.metadata.eduFiles?.some((eduFile) => eduFile.type === EduFileTypeEnum.SOLUTION);
  }

  get isScorable(): boolean {
    return this.metadata?.quotable;
  }

  get usesScorm(): boolean {
    return !!this.metadata?.eduContentSource?.usesScorm;
  }

  get isExercise(): boolean {
    return this.type === EduContentTypeEnum.EXERCISE || this.usesScorm;
  }

  get hasFeedbackFeature(): boolean {
    return this.type === EduContentTypeEnum.EXERCISE && this.isScorable;
  }

  get isPaperExercise(): boolean {
    return this.type === EduContentTypeEnum.PAPER_EXERCISE;
  }

  get isRichText(): boolean {
    return this.type === EduContentTypeEnum.TEXT;
  }

  get learningAreaId(): number {
    return this.metadata?.learningAreaId;
  }

  get learningArea(): LearningAreaInterface {
    return this.metadata?.learningArea;
  }

  get learningDomainNames(): string[] {
    if (!this.metadata) return [];
    if (!this.metadata.learningDomains) return [];

    return this.metadata?.learningDomains?.map((ld) => ld.name);
  }

  get eduNetNames(): string[] {
    const codeThatCanAppearTwice = 'KathOndVla';
    const nameWithPriority = 'zill';

    const eduNets = this.metadata.eduNets || [];
    const uniqueEduNetCodes = new Set(eduNets.map((eduNet) => eduNet.code));

    if (uniqueEduNetCodes.size > 2) {
      return [];
    }

    //This check because 'Zill' has priority over 'Vrij gesubsidieerd onderwijs' to be shown
    if (eduNets.filter((eduNet) => eduNet.code === codeThatCanAppearTwice).length === 2) {
      return eduNets
        .filter(
          (eduNet) => eduNet.code !== codeThatCanAppearTwice || eduNet.name.toLocaleLowerCase() === nameWithPriority
        )
        .map((eduNet) => eduNet.name);
    } else {
      return eduNets.map((eduNet) => eduNet.name);
    }
  }

  get eduContentSourceId(): number {
    return this.metadata?.eduContentSourceId;
  }

  get isAlgebraKit(): boolean {
    return this.eduContentSourceId === EduContentSourceEnum.ALGEBRAKIT;
  }

  get isArticulate(): boolean {
    return this.eduContentSourceId === EduContentSourceEnum.ARTICULATE;
  }

  get isLearnosity(): boolean {
    return this.eduContentSourceId === EduContentSourceEnum.LEARNOSITY;
  }

  get hasInstructions(): boolean {
    const artifact = this.publishedEduContentMetadata?.artifact;
    const hasInstructions = !!(artifact && (artifact.instructionTextId || artifact.instructionVideoId));

    return hasInstructions;
  }

  get data(): string {
    return this.metadata?.data;
  }

  get shortTitle(): string {
    return this.metadata?.shortTitle;
  }

  get isReadSpeakerEnabled(): boolean {
    if (!this.metadata) return false;

    const { methods } = this.metadata;
    if (!Array.isArray(methods)) return false;

    return methods.some((method) => method.isReadSpeakerEnabled);
  }

  get learnosityReference(): string {
    if (!this.isLearnosity) return null;
    return this.metadata?.sourceRef;
  }

  get metadata() {
    return this[this.metadatakey];
  }

  private metadatakey: EduContentMetadataKey;

  constructor(metadataKey: EduContentMetadataKey = 'publishedEduContentMetadata') {
    this.metadatakey = metadataKey;
  }

  static toEduContent(eduContent: EduContentInterface) {
    return eduContent && Object.assign<EduContent, EduContentInterface>(new EduContent(), eduContent);
  }

  static fromDraft(eduContent: EduContentInterface) {
    const metadataKey = 'draftEduContentMetadata';
    return eduContent && Object.assign<EduContent, EduContentInterface>(new EduContent(metadataKey), eduContent);
  }

  getSequenceForTocId(eduContentTocId) {
    if (!this.metadata?.eduContentTOCEduContentMetadatas) return;

    const found = this.metadata.eduContentTOCEduContentMetadatas.find(
      (eduContentTOCEduContentMetadata) => eduContentTOCEduContentMetadata.eduContentTOCId === eduContentTocId
    );
    if (!found) return;

    return found.displayOrder;
  }
}
