/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { TaskGoalYearLevelApi as SDKTaskGoalYearLevelApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { TaskGoalYearLevelInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class TaskGoalYearLevelApi extends ModelApiBase {
  private SDKTaskGoalYearLevelApi = inject(SDKTaskGoalYearLevelApi);

  constructor(api: PolpoApi) {
    super(api, 'TaskGoalYearLevels');
  }

  setTaskGoalYearLevelsForTask(
    taskId: number,
    taskGoalYearLevels: TaskGoalYearLevelInterface[]
  ): Observable<TaskGoalYearLevelInterface[]> {
    // @ts-expect-error TS 2556
    return this.SDKTaskGoalYearLevelApi.setTaskGoalYearLevelsForTask(taskId, taskGoalYearLevels);
  }
}
