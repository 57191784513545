import { groupArrayByKey } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssigneeInterface, AssigneeTypesEnum, ClassGroupInterface } from '../../+models';
import { BundleClassGroupInterface } from '../../+models/BundleClassGroup.interface';
import { selectClassGroupState } from '../class-group/class-group.selectors';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './bundle-class-group.reducer';

export const selectBundleClassGroupState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectBundleClassGroupState, (state: State) => state.error);

export const getLoaded = createSelector(selectBundleClassGroupState, (state: State) => state.loaded);

export const getAll = createSelector(selectBundleClassGroupState, selectAll);

export const getCount = createSelector(selectBundleClassGroupState, selectTotal);

export const getIds = createSelector(selectBundleClassGroupState, selectIds);

export const getAllEntities = createSelector(selectBundleClassGroupState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * bundleClassGroup$: BundleClassGroupInterface[] = this.store.pipe(
    select(BundleClassGroupQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectBundleClassGroupState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * bundleClassGroup$: BundleClassGroupInterface = this.store.pipe(
    select(BundleClassGroupQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectBundleClassGroupState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getBundleClassGroupAssigneeByBundle = createSelector(
  getAll,
  selectClassGroupState,
  (bundleClassGroups, classGroupState) =>
    bundleClassGroups
      .sort((bgA: BundleClassGroupInterface, bgB: BundleClassGroupInterface): number => {
        const classGroupA: ClassGroupInterface = classGroupState.entities[bgA.classGroupId];
        const classGroupB: ClassGroupInterface = classGroupState.entities[bgB.classGroupId];
        if (!classGroupA?.name) return 1;
        if (!classGroupB?.name) return -1;

        return classGroupA.name.localeCompare(classGroupB.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      })
      .reduce((dict, bCG) => {
        const classGroup = classGroupState.entities[bCG.classGroupId];

        if (!dict[bCG.bundleId]) {
          dict[bCG.bundleId] = [];
        }
        dict[bCG.bundleId].push({
          id: bCG.id,
          type: AssigneeTypesEnum.CLASSGROUP,
          relationId: bCG.classGroupId,
          label: classGroup.name,
        });

        return dict;
      }, {} as Dictionary<AssigneeInterface[]>)
);

export const getByBundleId = createSelector(
  selectBundleClassGroupState,
  (state: State, props: { bundleId: number }): BundleClassGroupInterface[] =>
    (state.ids as (string | number)[]).reduce((acc, id) => {
      if (state.entities[id].bundleId === props.bundleId) {
        acc.push(state.entities[id]);
      }
      return acc;
    }, [])
);

export const getAllGroupedByBundleId = createSelector(selectBundleClassGroupState, (state: State) => {
  return groupArrayByKey(Object.values(state.entities), 'bundleId');
});
