import { Action } from '@ngrx/store';
import { TeacherInfoInterface } from '../../+models';
import { PersonInterface } from '../../+models/Person.interface';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum UserActionTypes {
  LoadUser = '[User] Load User',
  UserLoaded = '[User] User Loaded',
  UserLoadError = '[User] User Load Error',
  RemoveUser = '[User] Remove User',
  UserRemoved = '[User] User Removed',
  UserRemoveError = '[User] User Remove Error',
  LogInUser = '[User] Log In User',
  LoginUserWithPersonalCode = '[User] Login User With PersonalCode',
  StartUpdateUser = '[User] Start Update User',
  UpdateUser = '[User] Update User',
  LoadPermissions = '[User] Load Permissions',
  PermissionsLoaded = '[User] Permissions Loaded',
  PermissionsLoadError = '[User] Permissions Load error',
  UpdatePassword = '[User] Update password',
  StartUpdateCompleteProfile = '[User] Start Update completeProfile',
  UpdateCompleteProfile = '[User] Update completeProfile',
  StartUpsertTeacherInfo = '[User] StartUpdateTeacherInfo',
  UpsertTeacherInfo = '[User] UpdateTeacherInfo',
  SendForgotPasswordEmail = '[User] Send Forgot Password Email',
}

export class LogInUser implements FeedbackTriggeringAction {
  readonly type = UserActionTypes.LogInUser;
  constructor(
    public payload: {
      username?: string;
      email?: string;
      password: string;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class LogInUserWithPersonalCode implements FeedbackTriggeringAction {
  readonly type = UserActionTypes.LoginUserWithPersonalCode;
  constructor(
    public payload: {
      schoolCode: number;
      classGroupId: number;
      classNumber: number;
      childCode: string;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class LoadUser implements Action {
  readonly type = UserActionTypes.LoadUser;
  constructor(public payload: { force?: boolean }) {}
}

export class UserLoadError implements Action {
  readonly type = UserActionTypes.UserLoadError;
  constructor(public payload: { error: any }) {}
}

export class UserLoaded implements Action {
  readonly type = UserActionTypes.UserLoaded;
  constructor(public payload: PersonInterface) {}
}

// the RemoveUser action will trigger the clear-state meta-reducer which will reset the store to it's initial state
export class RemoveUser implements Action {
  readonly type = UserActionTypes.RemoveUser;
  constructor() {}
}

export class UserRemoved implements Action {
  readonly type = UserActionTypes.UserRemoved;
  constructor() {}
}

export class UserRemoveError implements Action {
  readonly type = UserActionTypes.UserRemoveError;
  constructor(public payload: { error: any }) {}
}

export class StartUpdateUser implements FeedbackTriggeringAction {
  readonly type = UserActionTypes.StartUpdateUser;

  constructor(
    public payload: {
      userId: number;
      changedProps: Partial<PersonInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}
export class UpdateUser implements Action {
  readonly type = UserActionTypes.UpdateUser;
  constructor(
    public payload: {
      userId: number;
      changedProps: Partial<PersonInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpdatePassword implements FeedbackTriggeringAction {
  readonly type = UserActionTypes.UpdatePassword;
  constructor(
    public payload: {
      userId: number;
      password: string;
      currentPassword?: string;
      accessToken?: string;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class LoadPermissions implements Action {
  readonly type = UserActionTypes.LoadPermissions;
  constructor(public payload: { force?: boolean }) {}
}

export class PermissionsLoaded implements Action {
  readonly type = UserActionTypes.PermissionsLoaded;
  constructor(public payload: string[]) {}
}

export class PermissionsLoadError implements Action {
  readonly type = UserActionTypes.PermissionsLoadError;
  constructor(public payload: { error: any }) {}
}

export class StartUpdateCompleteProfile implements Action {
  readonly type = UserActionTypes.StartUpdateCompleteProfile;
  constructor() {}
}

export class UpdateCompleteProfile implements Action {
  readonly type = UserActionTypes.UpdateCompleteProfile;
  constructor(
    public payload: {
      userId: number;
      changedProps: Partial<PersonInterface>;
    }
  ) {}
}
export class StartUpsertTeacherInfo implements FeedbackTriggeringAction {
  readonly type = UserActionTypes.StartUpsertTeacherInfo;
  constructor(
    public payload: {
      teacherInfo: TeacherInfoInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}
export class UpsertTeacherInfo implements Action {
  readonly type = UserActionTypes.UpsertTeacherInfo;
  constructor(public payload: TeacherInfoInterface) {}
}

export class SendForgotPasswordEmail implements FeedbackTriggeringAction {
  readonly type = UserActionTypes.SendForgotPasswordEmail;
  constructor(
    public payload: {
      email: string;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export type UserAction =
  | LoadUser
  | UserLoaded
  | UserLoadError
  | RemoveUser
  | UserRemoved
  | UserRemoveError
  | LogInUser
  | UpdateUser
  | StartUpdateUser
  | LoadPermissions
  | PermissionsLoaded
  | PermissionsLoadError
  | UpdatePassword
  | StartUpdateCompleteProfile
  | UpdateCompleteProfile
  | StartUpsertTeacherInfo
  | UpsertTeacherInfo
  | SendForgotPasswordEmail;
