import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { TaskGroupQueries } from '.';
import { TaskGroupServiceInterface, TASK_GROUP_SERVICE_TOKEN } from '../../tasks/task-group.service.interface';
import { DalState } from '../dal.state.interface';
import { loadTaskGroups, taskGroupsLoaded, taskGroupsLoadError } from './task-group.actions';

@Injectable()
export class TaskGroupEffects {
  loadTaskGroups$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadTaskGroups),
      concatLatestFrom(() => this.store.select(TaskGroupQueries.getLoaded)),
      fetch({
        run: (action: ReturnType<typeof loadTaskGroups>, loaded: boolean) => {
          if (!action.force && loaded) return;
          return this.taskGroupService
            .getAllForUser(action.userId)
            .pipe(map((taskGroups) => taskGroupsLoaded({ taskGroups })));
        },
        onError: (action: ReturnType<typeof loadTaskGroups>, error) => {
          return taskGroupsLoadError({ error });
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(TASK_GROUP_SERVICE_TOKEN)
    private taskGroupService: TaskGroupServiceInterface
  ) {}
}
