import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ResultQueries } from '.';
import { DalState } from '..';
import { ResultsService } from '../../results';
import { BundlesActionTypes, DeleteBundles } from '../bundle/bundle.actions';
import { DeleteTasks, TasksActionTypes } from '../task/task.actions';
import { DeleteResults, LoadResults, ResultsActionTypes, ResultsLoaded, ResultsLoadError } from './result.actions';
import { getResultsIdsForBundleIds, getResultsIdsForTaskIds } from './result.selectors';

@Injectable()
export class ResultEffects {
  loadResults$ = createEffect(() =>
    this.actions.pipe(
      ofType(ResultsActionTypes.LoadResults),
      concatLatestFrom(() => this.store.select(ResultQueries.getLoaded)),
      fetch({
        run: (action: LoadResults, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.resultService
            .getAllForUser(action.payload.userId)
            .pipe(map((results) => new ResultsLoaded({ results })));
        },
        onError: (action: LoadResults, error) => {
          return new ResultsLoadError(error);
        },
      })
    )
  );

  deleteResultsOnTaskDeletion$ = createEffect(() =>
    this.actions.pipe(
      ofType(TasksActionTypes.DeleteTasks),
      switchMap((action: DeleteTasks): Observable<any> => {
        return this.store.pipe(
          select(getResultsIdsForTaskIds, {
            taskIds: action.payload.ids,
          }),
          map((resultIds) => {
            return new DeleteResults({
              ids: resultIds,
            });
          })
        );
      })
    )
  );

  deleteResultsOnBundleDeletion$ = createEffect(() =>
    this.actions.pipe(
      ofType(BundlesActionTypes.DeleteBundles),
      switchMap((action: DeleteBundles): Observable<Action> => {
        return this.store.pipe(
          select(getResultsIdsForBundleIds, {
            bundleIds: action.payload.ids,
          }),
          map((resultIds) => {
            return new DeleteResults({
              ids: resultIds,
            });
          })
        );
      })
    )
  );

  constructor(private actions: Actions, private store: Store<DalState>, private resultService: ResultsService) {}
}
