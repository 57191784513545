<ng-content select="[concept-button-icon]"></ng-content>
<campus-icon *ngIf="icon" [svgIcon]="icon"></campus-icon>
<campus-state-layer
  [for]="element"
  type="circular"
  [ngClass]="selected ? 'bg-on-surface-variant' : 'bg-on-surface-inverse'"
></campus-state-layer>
<campus-focus-ring [for]="element"></campus-focus-ring>

<campus-tooltip
  *ngIf="plainTooltip"
  [plainTooltip]="plainTooltip"
  [tooltipDelay]="250"
  [anchor]="element"
  class="[ visually-hidden ]"
>
</campus-tooltip>
