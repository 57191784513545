<campus-info-panel [titleText]="titleText" [displayAsRows]="false" class="content-preview__info-panel">
  <mat-dialog-content class="content-preview__dialog" [class.content-preview__dialog--loading]="loading">
    <mat-spinner *ngIf="loading"></mat-spinner>
    <div class="content-preview__info-panel__load-error" *ngIf="errored">De afbeelding kon niet worden geladen.</div>
    <img
      *ngIf="data?.url"
      class="content-preview__dialog__image"
      [hidden]="loading || errored"
      (error)="imageErrored()"
      (load)="imageLoaded()"
      [src]="data.url"
    />
    <campus-froala-viewer
      class="[ container ]"
      *ngIf="data?.html"
      [content]="data.html | safeHtml"
    ></campus-froala-viewer>
  </mat-dialog-content>
</campus-info-panel>

<mat-dialog-actions class="content-preview__dialog__actions">
  <campus-button
    data-cy="close-dialog"
    primary
    class="content-preview__dialog__actions__button__close"
    (click)="close()"
    >Sluiten</campus-button
  >
</mat-dialog-actions>
