import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { EvaluationScoreListSubjectInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum EvaluationScoreListSubjectsActionTypes {
  EvaluationScoreListSubjectsLoaded = '[EvaluationScoreListSubjects] EvaluationScoreListSubjects Loaded',
  EvaluationScoreListSubjectsLoadError = '[EvaluationScoreListSubjects] Load Error',
  AddEvaluationScoreListSubject = '[EvaluationScoreListSubjects] Add EvaluationScoreListSubject',
  AddEvaluationScoreListSubjects = '[EvaluationScoreListSubjects] Add EvaluationScoreListSubjects',
  AddEvaluationScoreListSubjectsForTask = '[EvaluationScoreListSubjects] Add EvaluationScoreListSubjects for Task',
  UpsertEvaluationScoreListSubject = '[EvaluationScoreListSubjects] Upsert EvaluationScoreListSubject',
  UpsertEvaluationScoreListSubjects = '[EvaluationScoreListSubjects] Upsert EvaluationScoreListSubjects',
  UpdateEvaluationScoreListSubject = '[EvaluationScoreListSubjects] Update EvaluationScoreListSubject',
  UpdateEvaluationScoreListSubjects = '[EvaluationScoreListSubjects] Update EvaluationScoreListSubjects',
  DeleteEvaluationScoreListSubject = '[EvaluationScoreListSubjects] Delete EvaluationScoreListSubject',
  DeleteEvaluationScoreListSubjects = '[EvaluationScoreListSubjects] Delete EvaluationScoreListSubjects',
  ClearEvaluationScoreListSubjects = '[EvaluationScoreListSubjects] Clear EvaluationScoreListSubjects',
  SetEvaluationScoreListSubjectsForScoreList = '[EvaluationScoreListSubjects] Set EvaluationScoreListSubjects for Score List',
}

export class EvaluationScoreListSubjectsLoaded implements Action {
  readonly type = EvaluationScoreListSubjectsActionTypes.EvaluationScoreListSubjectsLoaded;

  constructor(public payload: { evaluationScoreListSubjects: EvaluationScoreListSubjectInterface[] }) {}
}

export class EvaluationScoreListSubjectsLoadError implements Action {
  readonly type = EvaluationScoreListSubjectsActionTypes.EvaluationScoreListSubjectsLoadError;
  constructor(public payload: any) {}
}

export class AddEvaluationScoreListSubject implements Action {
  readonly type = EvaluationScoreListSubjectsActionTypes.AddEvaluationScoreListSubject;

  constructor(public payload: { evaluationScoreListSubject: EvaluationScoreListSubjectInterface }) {}
}

export class UpsertEvaluationScoreListSubject implements Action {
  readonly type = EvaluationScoreListSubjectsActionTypes.UpsertEvaluationScoreListSubject;

  constructor(public payload: { evaluationScoreListSubject: EvaluationScoreListSubjectInterface }) {}
}

export class AddEvaluationScoreListSubjects implements Action {
  readonly type = EvaluationScoreListSubjectsActionTypes.AddEvaluationScoreListSubjects;

  constructor(public payload: { evaluationScoreListSubjects: EvaluationScoreListSubjectInterface[] }) {}
}

export class AddEvaluationScoreListSubjectsForTask implements Action {
  readonly type = EvaluationScoreListSubjectsActionTypes.AddEvaluationScoreListSubjectsForTask;

  constructor(public payload: { evaluationScoreListSubjects: EvaluationScoreListSubjectInterface[]; taskId: number }) {}
}

export class UpsertEvaluationScoreListSubjects implements Action {
  readonly type = EvaluationScoreListSubjectsActionTypes.UpsertEvaluationScoreListSubjects;

  constructor(public payload: { evaluationScoreListSubjects: EvaluationScoreListSubjectInterface[] }) {}
}

export class UpdateEvaluationScoreListSubject implements Action {
  readonly type = EvaluationScoreListSubjectsActionTypes.UpdateEvaluationScoreListSubject;

  constructor(public payload: { evaluationScoreListSubject: Update<EvaluationScoreListSubjectInterface> }) {}
}

export class UpdateEvaluationScoreListSubjects implements Action {
  readonly type = EvaluationScoreListSubjectsActionTypes.UpdateEvaluationScoreListSubjects;

  constructor(public payload: { evaluationScoreListSubjects: Update<EvaluationScoreListSubjectInterface>[] }) {}
}

export class DeleteEvaluationScoreListSubject implements FeedbackTriggeringAction {
  readonly type = EvaluationScoreListSubjectsActionTypes.DeleteEvaluationScoreListSubject;

  constructor(public payload: { id: number; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export class DeleteEvaluationScoreListSubjects implements FeedbackTriggeringAction {
  readonly type = EvaluationScoreListSubjectsActionTypes.DeleteEvaluationScoreListSubjects;

  constructor(public payload: { ids: number[]; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export class ClearEvaluationScoreListSubjects implements Action {
  readonly type = EvaluationScoreListSubjectsActionTypes.ClearEvaluationScoreListSubjects;
}

export class SetEvaluationScoreListSubjectsForScoreList implements Action {
  readonly type = EvaluationScoreListSubjectsActionTypes.SetEvaluationScoreListSubjectsForScoreList;

  constructor(
    public payload: {
      evaluationScoreListId: number;
      evaluationScoreListSubjects: EvaluationScoreListSubjectInterface[];
    }
  ) {}
}

export type EvaluationScoreListSubjectsActions =
  | EvaluationScoreListSubjectsLoaded
  | EvaluationScoreListSubjectsLoadError
  | AddEvaluationScoreListSubject
  | AddEvaluationScoreListSubjectsForTask
  | UpsertEvaluationScoreListSubject
  | AddEvaluationScoreListSubjects
  | UpsertEvaluationScoreListSubjects
  | UpdateEvaluationScoreListSubject
  | UpdateEvaluationScoreListSubjects
  | DeleteEvaluationScoreListSubject
  | DeleteEvaluationScoreListSubjects
  | ClearEvaluationScoreListSubjects
  | SetEvaluationScoreListSubjectsForScoreList;
