<svg width="0" height="0">
  <linearGradient id="expert-gradient">
    <stop class="start" offset="0" />
    <stop class="stop" offset="1" />
  </linearGradient>
  <linearGradient id="knob-gradient" gradientTransform="rotate(45)">
    <stop class="start" offset="0" />
    <stop class="stop" offset="1" />
  </linearGradient>
</svg>
<div class="ui-ability-meter__container" #meter>
  <div class="ui-ability-meter__back"></div>
  <div class="ui-ability-meter__trail"></div>
  <svg
    [attr.viewport]="'0 0 ' + expertArcStroke.size + ' ' + expertArcStroke.size"
    *ngIf="expertArcStroke"
    class="ui-ability-meter__expert-arc"
  >
    <defs>
      <filter id="blur-shadow" x="-55%" y="-55%" width="220%" height="220%">
        <feDropShadow
          [attr.dx]="shadowDirection.x * -1"
          [attr.dy]="shadowDirection.x * -1"
          flood-color="#000000"
          flood-opacity="0.38"
          stdDeviation="2"
        />
      </filter>
    </defs>

    <circle
      [attr.cx]="expertArcStroke.center"
      [attr.cy]="expertArcStroke.center"
      [attr.r]="expertArcStroke.radius"
      [attr.stroke-dasharray]="expertArcStroke.dashArray"
      [attr.stroke-dashoffset]="expertArcStroke.dashOffset"
      [attr.transform]="expertArcStroke.transform"
      [attr.stroke-width]="expertArcStroke.strokeWidth"
      class="arc"
      filter="url(#blur-shadow)"
      stroke="url(#expert-gradient)"
    ></circle>
  </svg>
  <div
    class="ui-ability-meter__master-arc"
    [class.master--complete]="this.bestMode && progress === 100"
    [style.transform]="'rotate(' + masterAngle + 'deg)'"
    [ngStyle]="{
      'box-shadow':
        this.bestMode && progress === 100 ? 'none' : masterShadow.x + 'px ' + masterShadow.y + 'px  6px rgba(0,0,0,0.6)'
    }"
  ></div>
  <div class="ui-ability-meter__front" *ngIf="size === 'large'">{{ label }}</div>
</div>

<div class="ui-ability-meter__knob">
  <campus-icon
    [svgIcon]="this.bestMode ? 'diamond-filled' : 'lightbulb-filled'"
    class="ui-ability-meter__knob__background"
  ></campus-icon>
  <campus-icon [svgIcon]="this.bestMode ? 'diamond' : 'lightbulb'" class="ui-ability-meter__knob__icon"></campus-icon>
</div>
