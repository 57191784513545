import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ProductInterface } from '../../+models';
import { ProductsActions, ProductsActionTypes } from './product.actions';

export const NAME = 'products';

export interface State extends EntityState<ProductInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<ProductInterface> = createEntityAdapter<ProductInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: ProductsActions): State {
  switch (action.type) {
    case ProductsActionTypes.AddProduct: {
      return adapter.addOne(action.payload.product, state);
    }

    case ProductsActionTypes.UpsertProduct: {
      return adapter.upsertOne(action.payload.product, state);
    }

    case ProductsActionTypes.AddProducts: {
      return adapter.addMany(action.payload.products, state);
    }

    case ProductsActionTypes.UpsertProducts: {
      return adapter.upsertMany(action.payload.products, state);
    }

    case ProductsActionTypes.UpdateProduct: {
      return adapter.updateOne(action.payload.product, state);
    }

    case ProductsActionTypes.UpdateProducts: {
      return adapter.updateMany(action.payload.products, state);
    }

    case ProductsActionTypes.DeleteProduct: {
      return adapter.removeOne(action.payload.id, state);
    }

    case ProductsActionTypes.DeleteProducts: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case ProductsActionTypes.ProductsLoaded: {
      return adapter.setAll(action.payload.products, {
        ...state,
        loaded: true,
      });
    }

    case ProductsActionTypes.ProductsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case ProductsActionTypes.ClearProducts: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
