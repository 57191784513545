import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonApi } from '../+api';
import { BundleGroupInterface } from '../+models';
import { BundleGroupServiceInterface } from './bundle-group.service.interface';

@Injectable({
  providedIn: 'root',
})
export class BundleGroupService implements BundleGroupServiceInterface {
  constructor(private personApi: PersonApi) {}

  public getAllForUser(userId: number): Observable<BundleGroupInterface[]> {
    return this.personApi
      .getData(userId, 'bundleGroups')
      .pipe(map((res: { bundleGroups: BundleGroupInterface[] }) => res.bundleGroups));
  }
}
