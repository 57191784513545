import { Action } from '@ngrx/store';
import { RoleInterface } from '../../+models';

export enum RolesActionTypes {
  RolesLoaded = '[Roles] Roles Loaded',
  RolesLoadError = '[Roles] Load Error',
}

export class RolesLoaded implements Action {
  readonly type = RolesActionTypes.RolesLoaded;

  constructor(public payload: { roles: RoleInterface[] }) {}
}

export class RolesLoadError implements Action {
  readonly type = RolesActionTypes.RolesLoadError;
  constructor(public payload: any) {}
}

export type RolesActions = RolesLoaded | RolesLoadError;
