<h6 mat-dialog-title><span [innerHTML]="title" class="[ headline-small ]"></span></h6>
<div mat-dialog-content *ngIf="message">
  <p [class]="messageClass" data-cy="cm-content" [innerHTML]="message"></p>
</div>
<div mat-dialog-actions class="[ cluster justify-end ]">
  <div>
    <button *ngIf="cancelType === 'default'" outline-button data-cy="cm-cancel" (click)="cancel()">{{
      cancelLabel
    }}</button>
    <button *ngIf="cancelType === 'inline'" inline-button data-cy="cm-cancel" (click)="cancel()">{{
      cancelLabel
    }}</button>

    <button
      *ngIf="confirmType === 'default'"
      outline-button
      data-cy="cm-confirm"
      campusClickStopPropagation
      [disabled]="disableConfirm"
      [leadingIcon]="this.confirmIcon"
      (click)="confirm()"
      >{{ confirmLabel }}</button
    >
    <button
      *ngIf="confirmType === 'primary'"
      filled-button
      data-cy="cm-confirm"
      campusClickStopPropagation
      [disabled]="disableConfirm"
      [leadingIcon]="this.confirmIcon"
      (click)="confirm()"
      >{{ confirmLabel }}</button
    >
    <button
      *ngIf="confirmType === 'danger'"
      filled-button
      class="error"
      data-cy="cm-confirm"
      campusClickStopPropagation
      [disabled]="disableConfirm"
      [leadingIcon]="this.confirmIcon"
      (click)="confirm()"
      >{{ confirmLabel }}</button
    >
  </div>
</div>
