import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { SchoolBillingInterface } from '../../+models/SchoolBilling.interface';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum SchoolBillingsActionTypes {
  SchoolBillingsLoaded = '[SchoolBillings] SchoolBillings Loaded',
  SchoolBillingsLoadError = '[SchoolBillings] Load Error',
  LoadSchoolBillings = '[SchoolBillings] Load SchoolBillings',
  AddSchoolBilling = '[SchoolBillings] Add SchoolBilling',
  UpsertSchoolBilling = '[SchoolBillings] Upsert SchoolBilling',
  AddSchoolBillings = '[SchoolBillings] Add SchoolBillings',
  UpsertSchoolBillings = '[SchoolBillings] Upsert SchoolBillings',
  UpdateSchoolBilling = '[SchoolBillings] Update SchoolBilling',
  UpdateSchoolBillings = '[SchoolBillings] Update SchoolBillings',
  DeleteSchoolBilling = '[SchoolBillings] Delete SchoolBilling',
  DeleteSchoolBillings = '[SchoolBillings] Delete SchoolBillings',
  ClearSchoolBillings = '[SchoolBillings] Clear SchoolBillings',
  StartRecalcSchoolBillings = '[SchoolBillings] Start Recalc SchoolBillings, feedback to follow',
}

export class LoadSchoolBillings implements Action {
  readonly type = SchoolBillingsActionTypes.LoadSchoolBillings;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class SchoolBillingsLoaded implements Action {
  readonly type = SchoolBillingsActionTypes.SchoolBillingsLoaded;

  constructor(public payload: { schoolBillings: SchoolBillingInterface[] }) {}
}

export class SchoolBillingsLoadError implements Action {
  readonly type = SchoolBillingsActionTypes.SchoolBillingsLoadError;
  constructor(public payload: any) {}
}

export class AddSchoolBilling implements Action {
  readonly type = SchoolBillingsActionTypes.AddSchoolBilling;

  constructor(public payload: { schoolBilling: SchoolBillingInterface }) {}
}

export class UpsertSchoolBilling implements Action {
  readonly type = SchoolBillingsActionTypes.UpsertSchoolBilling;

  constructor(public payload: { schoolBilling: SchoolBillingInterface }) {}
}

export class AddSchoolBillings implements Action {
  readonly type = SchoolBillingsActionTypes.AddSchoolBillings;

  constructor(public payload: { schoolBillings: SchoolBillingInterface[] }) {}
}

export class UpsertSchoolBillings implements Action {
  readonly type = SchoolBillingsActionTypes.UpsertSchoolBillings;

  constructor(public payload: { schoolBillings: SchoolBillingInterface[] }) {}
}

export class UpdateSchoolBilling implements Action {
  readonly type = SchoolBillingsActionTypes.UpdateSchoolBilling;

  constructor(public payload: { schoolBilling: Update<SchoolBillingInterface> }) {}
}

export class UpdateSchoolBillings implements Action {
  readonly type = SchoolBillingsActionTypes.UpdateSchoolBillings;

  constructor(public payload: { schoolBillings: Update<SchoolBillingInterface>[] }) {}
}

export class DeleteSchoolBilling implements Action {
  readonly type = SchoolBillingsActionTypes.DeleteSchoolBilling;

  constructor(public payload: { id: number }) {}
}

export class DeleteSchoolBillings implements Action {
  readonly type = SchoolBillingsActionTypes.DeleteSchoolBillings;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearSchoolBillings implements Action {
  readonly type = SchoolBillingsActionTypes.ClearSchoolBillings;
}

export class StartRecalcSchoolBillings implements FeedbackTriggeringAction {
  readonly type = SchoolBillingsActionTypes.StartRecalcSchoolBillings;

  constructor(
    public payload: {
      schoolId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export type SchoolBillingsActions =
  | LoadSchoolBillings
  | SchoolBillingsLoaded
  | SchoolBillingsLoadError
  | AddSchoolBilling
  | UpsertSchoolBilling
  | AddSchoolBillings
  | UpsertSchoolBillings
  | UpdateSchoolBilling
  | UpdateSchoolBillings
  | DeleteSchoolBilling
  | DeleteSchoolBillings
  | ClearSchoolBillings
  | StartRecalcSchoolBillings;
