import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './survey.reducer';

export const selectSurveyState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectSurveyState, (state: State) => state.error);

export const getLoaded = createSelector(selectSurveyState, (state: State) => state.loaded);

export const getAll = createSelector(selectSurveyState, selectAll);

export const getCount = createSelector(selectSurveyState, selectTotal);

export const getIds = createSelector(selectSurveyState, selectIds);

export const getAllEntities = createSelector(selectSurveyState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * survey$: SurveyInterface[] = this.store.pipe(
    select(SurveyQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectSurveyState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * survey$: SurveyInterface = this.store.pipe(
    select(SurveyQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectSurveyState,
  (state: State, props: { id: number }) => state.entities[props.id]
);
