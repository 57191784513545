import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { dateTimeRangeValidator } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, startWith } from 'rxjs/operators';

export interface DateRangeValue {
  start?: Date;
  end?: Date;
  option?: string;
}

interface DateRangeFormValues {
  startDate: Date;
  endDate: Date;
  startTime: string;
  endTime: string;
}

@Component({
  selector: 'campus-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit, OnChanges, OnDestroy {
  @HostBinding('class.ui-date-range-picker')
  isDateRangerPickerClass = true;
  // Is the start date and time required?
  @Input()
  public requireStart = true;

  // Is the end date and time required?
  @Input()
  public requireEnd = true;

  @Input()
  public useEnd = true;

  // Are time input fields used?
  @Input()
  public useTime = true;

  // Should there be a vertical layout for the two range controls?
  @Input()
  @HostBinding('class.ui-date-range-picker--vertical')
  public vertical = false;

  @Input()
  public initialStartDate: Date = null;

  @Input()
  public initialStartTime: string = null;

  @Input()
  public initialEndDate: Date = null;

  @Input()
  public initialEndTime: string = null;

  @Input()
  public readonly = false;

  @Input()
  public minEndDate: Date = null;

  @Input()
  public skipValidation = false;

  @Output()
  public valueChanged = new EventEmitter<DateRangeValue>();

  @Output()
  public validChanged = new EventEmitter<boolean>();

  public dateRangeForm: UntypedFormGroup;

  get getMinEndDate(): Date {
    const startDate = this.dateRangeForm.get('startDate').value;
    if (!startDate) {
      return this.minEndDate;
    }
    if (!this.minEndDate) {
      return startDate;
    }
    return startDate > this.minEndDate ? startDate : this.minEndDate;
  }

  private subscriptions: Subscription = new Subscription();

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.setupForm();
    this.validChanged.emit(this.dateRangeForm.valid);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dateRangeForm && !this.skipValidation) {
      if (changes.requireStart || changes.requireEnd || changes.useTime) {
        const validators = this.getValidators();
        this.applyValidators(validators);
      }
    }

    if (this.dateRangeForm) {
      const fromDate = (val) => (val instanceof Date ? val.getTime() : val);
      const hasChanged = (change: SimpleChange) =>
        change && fromDate(change.previousValue) !== fromDate(change.currentValue);

      const updates: Dictionary<any> = {};
      if (hasChanged(changes.initialStartDate)) updates.startDate = this.initialStartDate;
      if (hasChanged(changes.initialStartTime)) updates.startTime = this.initialStartTime;
      if (hasChanged(changes.initialEndDate)) updates.endDate = this.initialEndDate;
      if (hasChanged(changes.initialEndTime)) updates.endTime = this.initialEndTime;
      if (Object.keys(updates).length) {
        this.dateRangeForm.patchValue(updates);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getValidators() {
    if (this.skipValidation) {
      return { startDateValidators: [], startTimeValidators: [], endDateValidators: [], endTimeValidators: [] };
    }

    const startDateValidators = this.requireStart ? [Validators.required] : [];
    const startTimeValidators = this.useTime && this.requireStart ? [Validators.required] : [];
    const endDateValidators = this.requireEnd ? [Validators.required] : [];
    const endTimeValidators = this.useTime && this.requireEnd ? [Validators.required] : [];

    return {
      startDateValidators,
      startTimeValidators,
      endDateValidators,
      endTimeValidators,
    };
  }

  private applyValidators(validators: {
    startDateValidators: ValidatorFn[];
    startTimeValidators: ValidatorFn[];
    endDateValidators: ValidatorFn[];
    endTimeValidators: ValidatorFn[];
  }) {
    this.dateRangeForm.get('startDate').setValidators(validators.startDateValidators);
    this.dateRangeForm.get('startTime').setValidators(validators.startTimeValidators);
    this.dateRangeForm.get('endDate').setValidators(validators.endDateValidators);
    this.dateRangeForm.get('endTime').setValidators(validators.endTimeValidators);
  }

  private setupForm() {
    const validators = this.getValidators();

    this.dateRangeForm = this.formBuilder.group(
      {
        startDate: [this.initialStartDate, [...validators.startDateValidators]],
        startTime: [this.initialStartTime, [...validators.startTimeValidators]],
        endDate: [this.initialEndDate, [...validators.endDateValidators]],
        endTime: [this.initialEndTime, [...validators.endTimeValidators]],
      },
      {
        validators: dateTimeRangeValidator('startDate', 'startTime', 'endDate', 'endTime'),
      }
    );

    this.subscriptions.add(
      this.dateRangeForm.valueChanges
        .pipe(startWith(this.dateRangeForm.value), distinctUntilChanged())
        .subscribe((values: DateRangeFormValues) => {
          this.validateFormValues(values);
        })
    );
  }

  private validateFormValues(values: DateRangeFormValues) {
    if (!this.skipValidation) {
      this.validChanged.emit(this.dateRangeForm.valid);
    }

    if (this.dateRangeForm.valid || this.skipValidation) {
      this.emitFormValues(values);
    }
  }

  private emitFormValues(values: DateRangeFormValues) {
    // Incorporate the times into the dates to create the final value
    // Dates can be optional, so they could be null
    const fullStartDate = values.startDate ? new Date(values.startDate) : null;
    const fullEndDate = values.endDate ? new Date(values.endDate) : null;

    if (fullStartDate) {
      this.applyTimeToDate(values.startTime, fullStartDate);
    }

    if (fullEndDate) {
      this.applyTimeToDate(values.endTime, fullEndDate);
    }

    const result: DateRangeValue = {
      start: fullStartDate,
      end: fullEndDate,
    };

    this.valueChanged.emit(result);
  }

  private applyTimeToDate(time: string, date: Date) {
    // Times can be optional, so we default to zero if we don't have a time
    const splitTime = time ? time.split(':') : [0, 0];

    // Time input values are strings of the format HH:mm:ss.sss, see:
    // https://html.spec.whatwg.org/multipage/common-microsyntaxes.html#times

    date.setHours(+splitTime[0]);
    date.setMinutes(+splitTime[1]);
  }

  private isSameDay(a: Date, b: Date) {
    if (!a || !b) {
      return false;
    } else {
      return a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();
    }
  }

  /**
   * Fed to the date pickers so they know which dates to highlight
   *
   * @param date The current date the datePicker is iterating over
   */
  public applyClassToDateInRange(date: Date) {
    const startDateValue: Date = this.dateRangeForm.get('startDate').value;
    const endDateValue: Date = this.dateRangeForm.get('endDate').value;

    const isFirstOfRange = this.isSameDay(date, startDateValue);
    const isLastOfRange = this.isSameDay(date, endDateValue);

    if (startDateValue && endDateValue && date >= startDateValue && date <= endDateValue) {
      if (isFirstOfRange && isLastOfRange) {
        return 'ui-date-range-picker__day--in-range-single';
      } else if (isFirstOfRange) {
        return 'ui-date-range-picker__day--in-range-first';
      } else if (isLastOfRange) {
        return 'ui-date-range-picker__day--in-range-last';
      } else {
        return 'ui-date-range-picker__day--in-range';
      }
    } else if ((startDateValue && isFirstOfRange) || (endDateValue && isLastOfRange)) {
      return 'ui-date-range-picker__day--in-range-single';
    }
  }
}
