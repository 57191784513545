import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { EvaluationScoreInterface } from '../../+models';
import { EvaluationScoresActions, EvaluationScoresActionTypes } from './evaluation-score.actions';

export const NAME = 'evaluationScores';

export interface State extends EntityState<EvaluationScoreInterface> {
  // additional entities state properties
  loaded: boolean;
  loadedForTask: Dictionary<boolean>;
  saving: boolean;
  error?: any;
}

export const adapter: EntityAdapter<EvaluationScoreInterface> = createEntityAdapter<EvaluationScoreInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForTask: {},
  saving: false,
});

export function reducer(state = initialState, action: EvaluationScoresActions): State {
  switch (action.type) {
    case EvaluationScoresActionTypes.AddEvaluationScore: {
      return adapter.addOne(action.payload.evaluationScore, state);
    }

    case EvaluationScoresActionTypes.UpsertEvaluationScore: {
      return adapter.upsertOne(action.payload.evaluationScore, state);
    }

    case EvaluationScoresActionTypes.AddEvaluationScores: {
      return adapter.addMany(action.payload.evaluationScores, state);
    }

    case EvaluationScoresActionTypes.UpsertEvaluationScores: {
      return adapter.upsertMany(action.payload.evaluationScores, state);
    }

    case EvaluationScoresActionTypes.UpdateEvaluationScore: {
      return adapter.updateOne(action.payload.evaluationScore, state);
    }

    case EvaluationScoresActionTypes.UpdateEvaluationScores: {
      return adapter.updateMany(action.payload.evaluationScores, state);
    }

    case EvaluationScoresActionTypes.DeleteEvaluationScore: {
      return adapter.removeOne(action.payload.id, state);
    }

    case EvaluationScoresActionTypes.DeleteEvaluationScores: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case EvaluationScoresActionTypes.EvaluationScoresLoaded: {
      return adapter.setAll(action.payload.evaluationScores, { ...state, loaded: true });
    }

    case EvaluationScoresActionTypes.EvaluationScoresLoadError: {
      return { ...state, error: action.payload, loadedForTask: {} };
    }

    case EvaluationScoresActionTypes.ClearEvaluationScores: {
      return adapter.removeAll(state);
    }

    case EvaluationScoresActionTypes.AddEvaluationScoresForTask: {
      const { evaluationScores, taskId } = action.payload;
      return adapter.upsertMany(evaluationScores, {
        ...state,
        loadedForTask: { ...state.loadedForTask, [taskId]: true },
      });
    }

    case EvaluationScoresActionTypes.StartSetEvaluationScoresForTask: {
      return { ...state, saving: true };
    }

    case EvaluationScoresActionTypes.SetEvaluationScoresForTask: {
      const { evaluationScores, taskId, evaluationScoreListSubjectIds } = action.payload;
      const evaluationScoreListSubjectsSet = new Set(evaluationScoreListSubjectIds);

      const toRemove = (state.ids as number[]).filter((id) => {
        const entity = state.entities[id];
        return evaluationScoreListSubjectsSet.has(entity.evaluationScoreListSubjectId);
      });

      const clearedState = adapter.removeMany(toRemove, state);
      const newState = adapter.addMany(evaluationScores, clearedState);

      return {
        ...newState,
        loadedForTask: {
          ...state.loadedForTask,
          [taskId]: true,
        },
        saving: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
