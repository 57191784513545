import { Action } from '@ngrx/store';
import { GoalPrerequisiteInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum GoalPrerequisiteActionTypes {
  GoalPrerequisitesLoaded = '[GoalPrerequisites] GoalPrerequisites Loaded',
  GoalPrerequisitesLoadError = '[GoalPrerequisites] Load Error',
  LoadGoalPrerequisites = '[GoalPrerequisites] Load GoalPrerequisites',
  StartAddGoalPrerequisite = '[GoalPrerequisites] Start Add GoalPrerequisite',
  AddGoalPrerequisite = '[GoalPrerequisites] Add GoalPrerequisite',
  DeleteGoalPrerequisite = '[GoalPrerequisites] Delete GoalPrerequisite',
  DeleteGoalPrerequisites = '[GoalPrerequisites] Delete GoalPrerequisites',
  LoadGoalPrerequisitesForLearningArea = '[GoalPrerequisites] Load GoalPrerequisites For LearningArea',
  AddGoalPrerequisitesForLearningArea = '[GoalPrerequisites] Add GoalPrerequisites For LearningArea',
}

export class LoadGoalPrerequisites implements Action {
  readonly type = GoalPrerequisiteActionTypes.LoadGoalPrerequisites;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class GoalPrerequisitesLoaded implements Action {
  readonly type = GoalPrerequisiteActionTypes.GoalPrerequisitesLoaded;

  constructor(public payload: { goalPrerequisites: GoalPrerequisiteInterface[] }) {}
}

export class GoalPrerequisitesLoadError implements Action {
  readonly type = GoalPrerequisiteActionTypes.GoalPrerequisitesLoadError;
  constructor(public payload: any) {}
}

export class StartAddGoalPrerequisite implements Action, FeedbackTriggeringAction {
  readonly type = GoalPrerequisiteActionTypes.StartAddGoalPrerequisite;

  constructor(
    public payload: {
      goalPrerequisite: GoalPrerequisiteInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class AddGoalPrerequisite implements Action {
  readonly type = GoalPrerequisiteActionTypes.AddGoalPrerequisite;

  constructor(public payload: { goalPrerequisite: GoalPrerequisiteInterface }) {}
}

export class DeleteGoalPrerequisite implements Action, FeedbackTriggeringAction {
  readonly type = GoalPrerequisiteActionTypes.DeleteGoalPrerequisite;

  constructor(public payload: { id: number; customFeedbackHandlers?: CustomFeedbackHandlersInterface }) {}
}

export class DeleteGoalPrerequisites implements Action {
  readonly type = GoalPrerequisiteActionTypes.DeleteGoalPrerequisites;

  constructor(public payload: { ids: number[] }) {}
}

export class LoadGoalPrerequisitesForLearningArea implements Action {
  readonly type = GoalPrerequisiteActionTypes.LoadGoalPrerequisitesForLearningArea;

  constructor(public payload: { learningAreaId: number; force?: boolean }) {}
}

export class AddGoalPrerequisitesForLearningArea implements Action {
  readonly type = GoalPrerequisiteActionTypes.AddGoalPrerequisitesForLearningArea;

  constructor(public payload: { learningAreaId: number; goalPrerequisites: GoalPrerequisiteInterface[] }) {}
}

export type GoalPrerequisiteActions =
  | LoadGoalPrerequisites
  | StartAddGoalPrerequisite
  | AddGoalPrerequisite
  | DeleteGoalPrerequisite
  | DeleteGoalPrerequisites
  | GoalPrerequisitesLoaded
  | GoalPrerequisitesLoadError
  | LoadGoalPrerequisitesForLearningArea
  | AddGoalPrerequisitesForLearningArea;
