import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { fetch, pessimisticUpdate } from '@nrwl/angular';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TeacherStudentQueries } from '.';
import { LinkedPersonService } from '../../persons';
import { DalState } from '../dal.state.interface';
import { EffectFeedback, EffectFeedbackActions, Priority } from '../effect-feedback';
import { DeleteGroupPersons } from '../group-person/group-person.actions';
import { AddLinkedPerson, DeleteLinkedPerson, DeleteLinkedPersons } from '../linked-person/linked-person.actions';
import {
  DeleteTeacherStudent,
  DeleteTeacherStudents,
  LinkTeacherStudent,
  LoadTeacherStudents,
  TeacherStudentActionTypes,
  TeacherStudentsLoaded,
  TeacherStudentsLoadError,
  UnlinkStudents,
  UnlinkTeacherStudent,
} from './teacher-student.actions';

@Injectable()
export class TeacherStudentEffects {
  loadTeacherStudents$ = createEffect(() =>
    this.actions.pipe(
      ofType(TeacherStudentActionTypes.LoadTeacherStudents),
      concatLatestFrom(() => this.store.select(TeacherStudentQueries.getLoaded)),
      fetch({
        run: (action: LoadTeacherStudents, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.linkedPersonService
            .getTeacherStudentsForUser(action.payload.userId)
            .pipe(map((teacherStudents) => new TeacherStudentsLoaded({ teacherStudents })));
        },
        onError: (action: LoadTeacherStudents, error) => {
          return new TeacherStudentsLoadError(error);
        },
      })
    )
  );

  linkTeacher$ = createEffect(() =>
    this.actions.pipe(
      ofType(TeacherStudentActionTypes.LinkTeacherStudent),
      pessimisticUpdate({
        run: (action: LinkTeacherStudent) => {
          const userId = action.payload.userId;

          return this.linkedPersonService.linkStudentToTeacher(action.payload.publicKey).pipe(
            switchMap((teachers) => {
              const effectFeedback = EffectFeedback.generateSuccessFeedback(
                this.uuid(),
                action,
                'Leerkracht is gekoppeld.'
              );

              const actions = [].concat(
                // update state for active page
                teachers.map((person) => new AddLinkedPerson({ person })),
                new LoadTeacherStudents({ userId, force: true }),
                new EffectFeedbackActions.AddEffectFeedback({
                  effectFeedback,
                })
              );
              return from<Action[]>(actions);
            })
          );
        },
        onError: (action: LinkTeacherStudent, error) => {
          const effectFeedback = EffectFeedback.generateErrorFeedback(this.uuid(), action, error.message);
          return new EffectFeedbackActions.AddEffectFeedback({
            effectFeedback,
          });
        },
      })
    )
  );

  unlinkTeacher$ = createEffect(() =>
    this.actions.pipe(
      ofType(TeacherStudentActionTypes.UnlinkTeacherStudent),
      concatLatestFrom(() => this.store.select(TeacherStudentQueries.selectTeacherStudentState)),
      pessimisticUpdate({
        run: (action: UnlinkTeacherStudent, teacherStudentState) => {
          const userId = action.payload.userId;
          const teacherId = action.payload.teacherId;
          // add teacherStudentId to payload instead of searching state?
          const teacherStudentId = (teacherStudentState.ids as number[]).find(
            (id) => teacherStudentState.entities[id].teacherId === teacherId
          );

          return this.linkedPersonService.unlinkStudentFromTeacher(userId, teacherStudentId).pipe(
            switchMap(() => {
              const effectFeedback = new EffectFeedback({
                id: this.uuid(),
                triggerAction: action,
                message: 'Leerkracht is ontkoppeld.',
              });
              const actions = [].concat(
                // update state for active page
                new DeleteLinkedPerson({ id: teacherId }),
                new DeleteTeacherStudent({ id: teacherStudentId }),
                new EffectFeedbackActions.AddEffectFeedback({
                  effectFeedback,
                })
              );
              return from<Action[]>(actions);
            })
          );
        },
        onError: (action: UnlinkTeacherStudent, error) => {
          const effectFeedback = new EffectFeedback({
            id: this.uuid(),
            triggerAction: action,
            message: 'Het is niet gelukt om de leerkracht te ontkoppelen.',
            type: 'error',
            userActions: [{ title: 'Probeer opnieuw', userAction: action }],
            priority: Priority.HIGH,
          });
          return new EffectFeedbackActions.AddEffectFeedback({
            effectFeedback,
          });
        },
      })
    )
  );

  unlinkStudents$ = createEffect(() =>
    this.actions.pipe(
      ofType(TeacherStudentActionTypes.UnlinkStudents),
      concatLatestFrom(() => this.store),
      pessimisticUpdate({
        run: (action: UnlinkStudents, state) => {
          const { teacherId, studentIds } = action.payload;

          return this.linkedPersonService.unlinkStudents(teacherId, studentIds).pipe(
            switchMap(() => {
              const studentIdSet = new Set(studentIds);
              const teacherStudentIds = (state.teacherStudents.ids as number[]).filter((id) =>
                studentIdSet.has(state.teacherStudents.entities[id].studentId)
              );
              const groupPersonIds = (state.groupPersons.ids as number[]).filter((id) =>
                studentIdSet.has(state.groupPersons.entities[id].personId)
              );
              const effectFeedback = new EffectFeedback({
                id: this.uuid(),
                triggerAction: action,
                message:
                  studentIds.length === 1
                    ? 'Leerling is ontkoppeld.'
                    : `${studentIds.length} leerlingen zijn ontkoppeld.`,
              });
              const actions = [].concat(
                // update state for active page
                new DeleteLinkedPersons({ ids: studentIds }),
                new DeleteTeacherStudents({ ids: teacherStudentIds }),
                new DeleteGroupPersons({ ids: groupPersonIds }),
                new EffectFeedbackActions.AddEffectFeedback({ effectFeedback })
              );
              return from<Action[]>(actions);
            })
          );
        },
        onError: (action: UnlinkStudents, error) => {
          const { studentIds } = action.payload;
          const effectFeedback = new EffectFeedback({
            id: this.uuid(),
            triggerAction: action,
            message: `Het is niet gelukt om de leerling${studentIds.length === 1 ? '' : 'en'} te ontkoppelen.`,
            type: 'error',
            userActions: [{ title: 'Probeer opnieuw', userAction: action }],
            priority: Priority.HIGH,
          });
          return new EffectFeedbackActions.AddEffectFeedback({
            effectFeedback,
          });
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    private linkedPersonService: LinkedPersonService,
    @Inject('uuid') private uuid: () => string
  ) {}
}
