import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AlgebrakitSessionInterface } from '../../+models';
import { AlgebraKitSessionActions, AlgebrakitSessionActionTypes } from './algebrakit-session.actions';

export const NAME = 'algebrakitSessions';

export interface State extends EntityState<AlgebrakitSessionInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<AlgebrakitSessionInterface> = createEntityAdapter<AlgebrakitSessionInterface>({
  selectId: (entity) => entity.id,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: AlgebraKitSessionActions): State {
  switch (action.type) {
    case AlgebrakitSessionActionTypes.AlgebrakitSessionsLoaded: {
      return adapter.setAll(action.payload.algebrakitSessions, {
        ...state,
        loaded: true,
      });
    }

    case AlgebrakitSessionActionTypes.AlgebrakitSessionsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case AlgebrakitSessionActionTypes.DeleteAlgebraKitSessions: {
      return adapter.removeMany(action.payload.ids, state);
    }

    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities } = adapter.getSelectors();
