import { ChangeDetectorRef, inject, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { MathJaxService } from '../../services';

@Pipe({
  name: 'mathjax',
  pure: false,
})
export class MathJaxPipe implements PipeTransform, OnDestroy {
  private service = inject(MathJaxService);
  private cdRef = inject(ChangeDetectorRef);
  private subscriptions = new Subscription();
  private result?: SafeHtml;
  private sanitizer = inject(DomSanitizer);
  private _value?: string;

  transform(value: string) {
    if (this._value !== value) {
      this._value = value;

      this.subscriptions.add(
        this.render(value).subscribe((result) => {
          this.result = this.sanitizer.bypassSecurityTrustHtml(result);
          this.cdRef.markForCheck();
        })
      );
    }

    return this.result || value;
  }

  private render(value: string): Observable<string> {
    return this.service.ready().pipe(
      switchMap(() => {
        return this.service.render(value);
      }),
      take(1)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
