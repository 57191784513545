import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { ConceptInterface } from '../../+models';

export enum ConceptsActionTypes {
  ConceptsLoaded = '[Concepts] Concepts Loaded',
  ConceptsLoadError = '[Concepts] Load Error',
  LoadConcepts = '[Concepts] Load Concepts',
  AddConcept = '[Concepts] Add Concept',
  UpsertConcept = '[Concepts] Upsert Concept',
  AddConcepts = '[Concepts] Add Concepts',
  UpsertConcepts = '[Concepts] Upsert Concepts',
  UpdateConcept = '[Concepts] Update Concept',
  UpdateConcepts = '[Concepts] Update Concepts',
  DeleteConcept = '[Concepts] Delete Concept',
  DeleteConcepts = '[Concepts] Delete Concepts',
  ClearConcepts = '[Concepts] Clear Concepts',
}

export class LoadConcepts implements Action {
  readonly type = ConceptsActionTypes.LoadConcepts;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class ConceptsLoaded implements Action {
  readonly type = ConceptsActionTypes.ConceptsLoaded;

  constructor(public payload: { concepts: ConceptInterface[] }) {}
}

export class ConceptsLoadError implements Action {
  readonly type = ConceptsActionTypes.ConceptsLoadError;
  constructor(public payload: any) {}
}

export class AddConcept implements Action {
  readonly type = ConceptsActionTypes.AddConcept;

  constructor(public payload: { concept: ConceptInterface }) {}
}

export class UpsertConcept implements Action {
  readonly type = ConceptsActionTypes.UpsertConcept;

  constructor(public payload: { concept: ConceptInterface }) {}
}

export class AddConcepts implements Action {
  readonly type = ConceptsActionTypes.AddConcepts;

  constructor(public payload: { concepts: ConceptInterface[] }) {}
}

export class UpsertConcepts implements Action {
  readonly type = ConceptsActionTypes.UpsertConcepts;

  constructor(public payload: { concepts: ConceptInterface[] }) {}
}

export class UpdateConcept implements Action {
  readonly type = ConceptsActionTypes.UpdateConcept;

  constructor(public payload: { concept: Update<ConceptInterface> }) {}
}

export class UpdateConcepts implements Action {
  readonly type = ConceptsActionTypes.UpdateConcepts;

  constructor(public payload: { concepts: Update<ConceptInterface>[] }) {}
}

export class DeleteConcept implements Action {
  readonly type = ConceptsActionTypes.DeleteConcept;

  constructor(public payload: { id: string }) {}
}

export class DeleteConcepts implements Action {
  readonly type = ConceptsActionTypes.DeleteConcepts;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearConcepts implements Action {
  readonly type = ConceptsActionTypes.ClearConcepts;
}

export type ConceptsActions =
  | LoadConcepts
  | ConceptsLoaded
  | ConceptsLoadError
  | AddConcept
  | UpsertConcept
  | AddConcepts
  | UpsertConcepts
  | UpdateConcept
  | UpdateConcepts
  | DeleteConcept
  | DeleteConcepts
  | ClearConcepts;
