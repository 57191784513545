<campus-elevated-card (click)="cardAction && clickAction(cardAction)">
  <campus-card-overline-head data-cy="task-card-learning-area" *ngIf="learningAreaName">
    {{ learningAreaName }}
  </campus-card-overline-head>
  <campus-card-headline data-cy="task-card-name">{{ name }}</campus-card-headline>

  <campus-card-hint data-cy="task-card-hint">
    <div *ngIf="isFinished; else urgent" class="[ absolute right-none -m-3xs ] [ success ] [ corner-full ]">
      <div data-cy="task-card-hint-finished" class="[ surface clip-check icon-size-m ]"></div>
    </div>
  </campus-card-hint>

  <ng-template #urgent>
    <campus-svg
      data-cy="task-card-hint-urgent"
      *ngIf="isUrgent"
      svgImage="urgent"
      class="[ absolute right-none -m-xs ][ icon-size-l ]"
    ></campus-svg>
  </ng-template>

  <campus-card-text data-cy="task-card-text">
    <div class="[ stack ]">
      <div class="[ cluster label-small ]">
        <div>
          <div *ngIf="required" class="[ inline-flex flex-nowrap items-center gap-3xs ]" data-cy="task-card-required">
            {{ required.total }} {{ required.label }}
            <campus-status-progress
              *ngIf="required.total"
              [progress]="requiredProgress"
              color="primary"
            ></campus-status-progress>
          </div>

          <div
            *ngIf="notRequired"
            class="[ inline-flex flex-nowrap items-center gap-3xs ]"
            data-cy="task-card-optional"
          >
            {{ notRequired.total }} {{ notRequired.label }}
            <campus-status-progress
              *ngIf="notRequired.total"
              [progress]="notRequiredProgress"
              color="warn"
            ></campus-status-progress>
          </div>
        </div>
      </div>
      <div [ngClass]="isUrgent ? ['weight-extrabold'] : ['weight-regular', 'text-lowercase']" data-cy="task-card-date">
        {{ dateLabel }}
      </div>
    </div>
  </campus-card-text>

  <campus-card-action-area data-cy="task-card-actions" class="[ cluster ]">
    <div class="[ justify-end ]">
      <button
        inline-button
        *ngFor="let action of inlineCardActions"
        (click)="clickAction(action)"
        [leadingIcon]="action.icon"
      >
        {{ action.label }}
      </button>
      <button
        elevated-button
        *ngFor="let action of primaryCardActions"
        (click)="clickAction(action)"
        [leadingIcon]="action.icon"
      >
        {{ action.label }}
      </button>
    </div>
  </campus-card-action-area>

  <campus-card-progress data-cy="task-card-progress">
    <campus-segmented-progress-bar
      [totalSegments]="totalSegments"
      [filledSegments]="filledSegments"
    ></campus-segmented-progress-bar>
  </campus-card-progress>
</campus-elevated-card>
