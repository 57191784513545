import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { pessimisticUpdate } from '@nrwl/angular';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UUID_TOKEN } from '../../dal.module';
import { EvaluationScoreService, SetEvaluationScoresForTaskResponseInterface } from '../../evaluation-score';
import { DalState } from '../dal.state.interface';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { UpsertGoalTopics } from '../goal-topic/goal-topic.actions';
import { UpsertGoalYears } from '../goal-year/goal-year.actions';
import { UpsertGoals } from '../goal/goal.actions';
import { SetTaskGoalYearLevelsForTask } from '../task-goal-year-level/task-goal-year-level.actions';
import {
  EvaluationScoresActions,
  EvaluationScoresActionTypes,
  SetEvaluationScoresForTask,
  StartSetEvaluationScoresForTask,
} from './evaluation-score.actions';

@Injectable()
export class EvaluationScoreEffects {
  actions: Actions<EvaluationScoresActions> = inject(Actions);
  store: Store<DalState> = inject(Store);
  evaluationScoreService = inject(EvaluationScoreService);
  uuid = inject(UUID_TOKEN);

  setEvaluationScoresForTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(EvaluationScoresActionTypes.StartSetEvaluationScoresForTask),
      pessimisticUpdate({
        run: (action: StartSetEvaluationScoresForTask) => {
          const { taskId, evaluationScores, evaluationScoreListSubjectIds } = action.payload;
          return this.evaluationScoreService.setEvaluationScoresForTask(taskId, evaluationScores).pipe(
            switchMap(
              ({
                evaluationScores: evaluationScoresResponse,
                taskGoalYearLevels,
                goals,
                goalYears,
                goalTopics,
              }: SetEvaluationScoresForTaskResponseInterface) => {
                return from([
                  new SetEvaluationScoresForTask({
                    taskId,
                    evaluationScores: evaluationScoresResponse,
                    evaluationScoreListSubjectIds,
                  }),
                  new SetTaskGoalYearLevelsForTask({ taskId, taskGoalYearLevels }),
                  new UpsertGoals({ goals }),
                  new UpsertGoalYears({ goalYears }),
                  new UpsertGoalTopics({ goalTopics }),
                ]);
              }
            )
          );
        },
        onError: (action: StartSetEvaluationScoresForTask, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de scores te bewaren.'
            ),
          });
        },
      })
    )
  );
}
