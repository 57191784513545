import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { SurveyAnswerInterface } from '../../+models';

export enum SurveyAnswerActionTypes {
  SurveyAnswersLoaded = '[SurveyAnswers] SurveyAnswers Loaded',
  SurveyAnswersLoadError = '[SurveyAnswers] Load Error',
  LoadSurveyAnswers = '[SurveyAnswers] Load SurveyAnswers',
  AddSurveyAnswer = '[SurveyAnswers] Add SurveyAnswer',
  UpsertSurveyAnswer = '[SurveyAnswers] Upsert SurveyAnswer',
  AddSurveyAnswers = '[SurveyAnswers] Add SurveyAnswers',
  UpsertSurveyAnswers = '[SurveyAnswers] Upsert SurveyAnswers',
  UpdateSurveyAnswer = '[SurveyAnswers] Update SurveyAnswer',
  UpdateSurveyAnswers = '[SurveyAnswers] Update SurveyAnswers',
  DeleteSurveyAnswer = '[SurveyAnswers] Delete SurveyAnswer',
  DeleteSurveyAnswers = '[SurveyAnswers] Delete SurveyAnswers',
  ClearSurveyAnswers = '[SurveyAnswers] Clear SurveyAnswers',
}

export class LoadSurveyAnswers implements Action {
  readonly type = SurveyAnswerActionTypes.LoadSurveyAnswers;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class SurveyAnswersLoaded implements Action {
  readonly type = SurveyAnswerActionTypes.SurveyAnswersLoaded;

  constructor(public payload: { surveyAnswers: SurveyAnswerInterface[] }) {}
}

export class SurveyAnswersLoadError implements Action {
  readonly type = SurveyAnswerActionTypes.SurveyAnswersLoadError;
  constructor(public payload: any) {}
}

export class AddSurveyAnswer implements Action {
  readonly type = SurveyAnswerActionTypes.AddSurveyAnswer;

  constructor(public payload: { surveyAnswer: SurveyAnswerInterface }) {}
}

export class UpsertSurveyAnswer implements Action {
  readonly type = SurveyAnswerActionTypes.UpsertSurveyAnswer;

  constructor(public payload: { surveyAnswer: SurveyAnswerInterface }) {}
}

export class AddSurveyAnswers implements Action {
  readonly type = SurveyAnswerActionTypes.AddSurveyAnswers;

  constructor(public payload: { surveyAnswers: SurveyAnswerInterface[] }) {}
}

export class UpsertSurveyAnswers implements Action {
  readonly type = SurveyAnswerActionTypes.UpsertSurveyAnswers;

  constructor(public payload: { surveyAnswers: SurveyAnswerInterface[] }) {}
}

export class UpdateSurveyAnswer implements Action {
  readonly type = SurveyAnswerActionTypes.UpdateSurveyAnswer;

  constructor(public payload: { surveyAnswer: Update<SurveyAnswerInterface> }) {}
}

export class UpdateSurveyAnswers implements Action {
  readonly type = SurveyAnswerActionTypes.UpdateSurveyAnswers;

  constructor(public payload: { surveyAnswers: Update<SurveyAnswerInterface>[] }) {}
}

export class DeleteSurveyAnswer implements Action {
  readonly type = SurveyAnswerActionTypes.DeleteSurveyAnswer;

  constructor(public payload: { id: number }) {}
}

export class DeleteSurveyAnswers implements Action {
  readonly type = SurveyAnswerActionTypes.DeleteSurveyAnswers;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearSurveyAnswers implements Action {
  readonly type = SurveyAnswerActionTypes.ClearSurveyAnswers;
}

export type SurveyAnswersActions =
  | LoadSurveyAnswers
  | SurveyAnswersLoaded
  | SurveyAnswersLoadError
  | AddSurveyAnswer
  | UpsertSurveyAnswer
  | AddSurveyAnswers
  | UpsertSurveyAnswers
  | UpdateSurveyAnswer
  | UpdateSurveyAnswers
  | DeleteSurveyAnswer
  | DeleteSurveyAnswers
  | ClearSurveyAnswers;
