import { findOneInArray, groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UnlockedCurriculumTreeInterface } from '../../+models';

import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './unlocked-curriculum-tree.reducer';

export const selectUnlockedCurriculumTreeState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectUnlockedCurriculumTreeState, (state: State) => state.error);

export const getLoaded = createSelector(selectUnlockedCurriculumTreeState, (state: State) => state.loaded);

export const getAll = createSelector(selectUnlockedCurriculumTreeState, selectAll);

export const getCount = createSelector(selectUnlockedCurriculumTreeState, selectTotal);

export const getIds = createSelector(selectUnlockedCurriculumTreeState, selectIds);

export const getAllEntities = createSelector(selectUnlockedCurriculumTreeState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * unlockedCurriculumTree$: UnlockedCurriculumTreeInterface[] = this.store.pipe(
    select(UnlockedCurriculumTreeQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectUnlockedCurriculumTreeState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * unlockedCurriculumTree$: UnlockedCurriculumTreeInterface = this.store.pipe(
    select(UnlockedCurriculumTreeQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectUnlockedCurriculumTreeState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const findOne = createSelector(
  selectUnlockedCurriculumTreeState,
  (state: State, props: Partial<UnlockedCurriculumTreeInterface>) =>
    findOneInArray(Object.values(state.entities), props)
);

export const getByCurriculumTreeId = createSelector(
  getAll,
  (uCTs) => groupArrayByKeys(uCTs, ['curriculumTreeId']) as Dictionary<UnlockedCurriculumTreeInterface[]>
);

export const getByCurriculumTreeIdByLoopIdByClassGroupId = createSelector(getAll, (uCTs) =>
  groupArrayByKeys(uCTs, ['curriculumTreeId', 'loopId', 'classGroupId'], null, true)
);

export const getAllActive = createSelector(getAll, (uCTs) => uCTs.filter((utc) => !utc.deleted));
