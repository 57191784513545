import { Action } from '@ngrx/store';
import { ScormCmiMode } from '../../+external-interfaces/scorm-api.interface';
import { ContextInterface, ResultInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';
import { CurrentExerciseInterface } from './current-exercise.reducer';

export enum CurrentExerciseActionTypes {
  LoadExercise = '[Current Exercise] Load Exercise',
  CurrentExerciseLoaded = '[Current Exercise] Current Exercise Loaded',
  CurrentExerciseError = '[Current Exercise] Current Exercise Error',
  SaveCurrentExercise = '[Current Exercise] Save Current Exercise',
  SetCurrentResult = '[Current Exercise] Set Current Result',
  SetReadyForNextRecommendation = '[Current Exercise] Set ready for next recommendation',
  ClearCurrentExercise = '[Current Exercise] Clear Current Exercise',
}

export interface CurrentExerciseOptionsInterface {
  isReadSpeakerEnabled: boolean;
  cmiMode?: ScormCmiMode;
}

export class LoadExercise implements FeedbackTriggeringAction {
  readonly type = CurrentExerciseActionTypes.LoadExercise;
  constructor(
    public payload: {
      userId: number;
      eduContentId: number;
      saveToApi: boolean;
      cmiMode: ScormCmiMode;
      taskId?: number;
      unlockedContentId?: number;
      result?: ResultInterface;
      unlockedFreePracticeId?: number;
      context?: ContextInterface;
      options?: CurrentExerciseOptionsInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}
export class CurrentExerciseLoaded implements Action {
  readonly type = CurrentExerciseActionTypes.CurrentExerciseLoaded;

  constructor(public payload: CurrentExerciseInterface) {}
}

export class CurrentExerciseError implements Action {
  readonly type = CurrentExerciseActionTypes.CurrentExerciseError;

  constructor(public payload: any) {}
}

export class SaveCurrentExercise implements FeedbackTriggeringAction {
  readonly type = CurrentExerciseActionTypes.SaveCurrentExercise;

  constructor(
    public payload: {
      userId: number;
      exercise: CurrentExerciseInterface;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class SetCurrentResult {
  readonly type = CurrentExerciseActionTypes.SetCurrentResult;

  constructor(
    public payload: {
      result?: ResultInterface;
      finalUpdate?: boolean;
    }
  ) {}
}
export class SetReadyForNextRecommendation {
  readonly type = CurrentExerciseActionTypes.SetReadyForNextRecommendation;

  constructor(
    public payload: {
      readyForNextRecommendation: boolean;
    }
  ) {}
}
export class ClearCurrentExercise implements Action {
  readonly type = CurrentExerciseActionTypes.ClearCurrentExercise;
}
export type CurrentExerciseActions =
  | LoadExercise
  | CurrentExerciseLoaded
  | CurrentExerciseError
  | SaveCurrentExercise
  | ClearCurrentExercise
  | SetCurrentResult
  | SetReadyForNextRecommendation;
