<mat-form-field
  appearance="outline"
  [matMenuTriggerFor]="datepickerMenu"
  [matBadge]="count"
  [matBadgeHidden]="count === 0 || hideBadge"
  matBadgePosition="above after"
  class="mat-form-field-no-hint"
>
  <input matInput data-cy="date-filter-input" [readonly]="true" [value]="customDisplayLabel" />

  <button
    matSuffix
    *ngIf="clearable"
    inline-icon-button
    class="date-filter__reset-icon"
    [ngClass]="{ 'date-filter__reset-icon--visible': customDisplayLabel }"
    aria-label="Wissen"
    clickStopPropagation
    (click)="reset()"
    data-cy="filter-clear"
    icon="close"
  >
  </button>
  <campus-icon matSuffix svgIcon="arrow-drop-down" class="date-filter__drop-down-icon" data-cy="filter-open">
  </campus-icon>
  <mat-label>{{ criteria.label || 'Kies een periode' }}</mat-label>
  <mat-menu #datepickerMenu="matMenu" class="date-filter-component date-filter-component__menu-panel">
    <div clickStopPropagation data-cy="date-panel">
      <h3 class="date-filter-component__menu-panel__title ui-subheader">Selecteer een periode</h3>
      <mat-radio-group [formControl]="dateSelection" aria-label="Select an option">
        <mat-radio-button
          data-cy="date-option"
          *ngFor="let option of radioOptions"
          [value]="option.value"
          [checked]="option.selected"
          [disabled]="option.disabled"
        >
          <ng-container *ngIf="option.value.type !== optionValueTypes.CustomRange; else customRange">
            {{ option.viewValue }}
          </ng-container>
          <ng-template #customRange>
            <!-- TODO: why isn't this a campus-date-range-picker ??? -->
            <div class="date-filter-component__menu-panel__date-range">
              <mat-form-field>
                <mat-label>Vanaf</mat-label>
                <input
                  data-cy="date-start-input"
                  autocomplete="off"
                  [value]="option.value.contents.data.gte"
                  matInput
                  [matDatepicker]="startDatePicker"
                  [min]="minStartDate"
                  [max]="maxEndDate"
                  [formControl]="startDate"
                  (click)="startDatePicker.open(); clickDateInput()"
                />
                <mat-datepicker #startDatePicker [dateClass]="applyClassToDateInRange.bind(this)"></mat-datepicker>
              </mat-form-field>
              <mat-form-field>
                <mat-label>t.e.m.</mat-label>
                <input
                  data-cy="date-end-input"
                  autocomplete="off"
                  [value]="option.value.contents.data.lte"
                  matInput
                  [matDatepicker]="endDatePicker"
                  [min]="minStartDate"
                  [max]="maxEndDate"
                  [formControl]="endDate"
                  (click)="endDatePicker.open(); clickDateInput()"
                />
                <mat-datepicker #endDatePicker [dateClass]="applyClassToDateInRange.bind(this)"></mat-datepicker>
              </mat-form-field>
            </div>
          </ng-template>
        </mat-radio-button>
      </mat-radio-group>

      <div class="date-filter-component__menu-panel__actions">
        <campus-button inline (click)="cancelFilter()">Annuleren</campus-button>
        <campus-button
          [disabled]="!dateSelection.valid"
          data-cy="date-confirm"
          bordered
          secondary
          flat
          (click)="applyFilter()"
          >Filteren</campus-button
        >
      </div>
    </div>
  </mat-menu>
</mat-form-field>
