import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { EduContentProductTypeQueries } from '.';
import {
  EduContentProductTypeServiceInterface,
  EDU_CONTENT_PRODUCT_TYPE_SERVICE_TOKEN,
} from '../../metadata/edu-content-product-type.service.interface';
import { DalState } from '../dal.state.interface';
import {
  EduContentProductTypesActionTypes,
  EduContentProductTypesLoaded,
  EduContentProductTypesLoadError,
  LoadEduContentProductTypes,
} from './edu-content-product-type.actions';

@Injectable()
export class EduContentProductTypeEffects {
  loadEduContentProductTypes$ = createEffect(() =>
    this.actions.pipe(
      ofType(EduContentProductTypesActionTypes.LoadEduContentProductTypes),
      concatLatestFrom(() => this.store.select(EduContentProductTypeQueries.getLoaded)),
      fetch({
        run: (action: LoadEduContentProductTypes, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.eduContentProductTypeService
            .getAll()
            .pipe(map((eduContentProductTypes) => new EduContentProductTypesLoaded({ eduContentProductTypes })));
        },
        onError: (action: LoadEduContentProductTypes, error) => {
          return new EduContentProductTypesLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(EDU_CONTENT_PRODUCT_TYPE_SERVICE_TOKEN)
    private eduContentProductTypeService: EduContentProductTypeServiceInterface
  ) {}
}
