import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch, pessimisticUpdate } from '@nrwl/angular';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TaskEvaluationSubjectQueries } from '.';
import {
  TaskEvaluationSubjectServiceInterface,
  TASK_EVALUATION_SUBJECT_SERVICE_TOKEN,
} from '../../task-evaluation-subject/task-evaluation-subject.service.interface';
import { DalState } from '../dal.state.interface';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { SetTaskGoalYearLevelsForTask } from '../task-goal-year-level/task-goal-year-level.actions';
import {
  AddTaskEvaluationSubjectsForTask,
  LoadTaskEvaluationSubjectsForTask,
  SetTaskEvaluationSubjectsForTask,
  StartSetTaskEvaluationSubjectsForTask,
  TaskEvaluationSubjectsActionTypes,
  TaskEvaluationSubjectsLoadError,
} from './task-evaluation-subject.actions';

@Injectable()
export class TaskEvaluationSubjectEffects {
  loadTaskEvaluationSubjectsForTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(TaskEvaluationSubjectsActionTypes.LoadTaskEvaluationSubjectsForTask),
      concatLatestFrom(() => this.store.select(TaskEvaluationSubjectQueries.selectTaskEvaluationSubjectState)),
      fetch({
        run: (action: LoadTaskEvaluationSubjectsForTask, taskEvaluationSubjectState) => {
          const { taskId } = action.payload;

          if (taskEvaluationSubjectState.loadedForTask[taskId]) {
            return;
          }

          return this.taskEvaluationSubjectService.getTaskEvaluationSubjectsForTask(taskId).pipe(
            map(
              (taskEvaluationSubjects) =>
                new AddTaskEvaluationSubjectsForTask({
                  taskId,
                  taskEvaluationSubjects,
                })
            )
          );
        },
        onError: (action: LoadTaskEvaluationSubjectsForTask, error) => {
          return new TaskEvaluationSubjectsLoadError(error);
        },
      })
    )
  );

  startSetTaskEvaluationSubjectForTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(TaskEvaluationSubjectsActionTypes.StartSetTaskEvaluationSubjectsForTask),
      pessimisticUpdate({
        run: (action: StartSetTaskEvaluationSubjectsForTask) => {
          const { taskId, taskEvaluationSubjects } = action.payload;
          return this.taskEvaluationSubjectService
            .setTaskEvaluationSubjectsForTask(taskId, taskEvaluationSubjects)
            .pipe(
              switchMap(({ taskGoalYearLevels, taskEvaluationSubjects: tEvaluationSubjects }) => {
                return from([
                  new SetTaskEvaluationSubjectsForTask({
                    taskId,
                    taskEvaluationSubjects: tEvaluationSubjects,
                  }),
                  new SetTaskGoalYearLevelsForTask({
                    taskId,
                    taskGoalYearLevels,
                  }),
                ]);
              })
            );
        },
        onError: (action: StartSetTaskEvaluationSubjectsForTask, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de onderwerpen te bewaren.'
            ),
          });
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(TASK_EVALUATION_SUBJECT_SERVICE_TOKEN)
    private taskEvaluationSubjectService: TaskEvaluationSubjectServiceInterface,
    @Inject('uuid') private uuid: () => string
  ) {}
}
