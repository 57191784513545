/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { EduContentApi as SDKEduContentApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class EduContentApi extends ModelApiBase {
  private SDKEduContentApi = inject(SDKEduContentApi);

  constructor(api: PolpoApi) {
    super(api, 'EduContents');
  }

  requestURLRemote(
    contentId: number,
    stream?,
    taskId?: number,
    unlockedContentId?: number,
    unlockedFreePracticeId?: number,
    context?
  ): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentApi.requestURLRemote(...arguments);
  }

  getLatestEduContents(): Observable<any> {
    return this.SDKEduContentApi.getLatestEduContents(...arguments);
  }

  getMyEduContents(): Observable<any> {
    return this.SDKEduContentApi.getMyEduContents(...arguments);
  }

  getGeneralEduContentForBookId(bookId: number): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentApi.getGeneralEduContentForBookId(...arguments);
  }

  getEduContents(filters, pagination, columns, sorting): Observable<any> {
    return this.SDKEduContentApi.getEduContents(...arguments);
  }

  search(state): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentApi.search(...arguments);
  }

  autocomplete(state): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentApi.autocomplete(...arguments);
  }

  updateEduContentDraft(eduContentId, data): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentApi.updateEduContentDraft(...arguments);
  }

  getEduContentDraft(eduContentId: number): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentApi.getEduContentDraft(...arguments);
  }

  updateEduContentDraftRelation(eduContentId: number, body): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKEduContentApi.updateEduContentDraftRelation(...arguments);
  }
}
