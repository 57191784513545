import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EduContentMetadataEvaluationSubjectInterface } from '../../+models';

import {
  NAME,
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
  State,
} from './edu-content-metadata-evaluation-subject.reducer';

export const selectEduContentMetadataEvaluationSubjectState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectEduContentMetadataEvaluationSubjectState, (state: State) => state.error);

export const getLoaded = createSelector(selectEduContentMetadataEvaluationSubjectState, (state: State) => state.loaded);

export const getAll = createSelector(selectEduContentMetadataEvaluationSubjectState, selectAll);

export const getCount = createSelector(selectEduContentMetadataEvaluationSubjectState, selectTotal);

export const getIds = createSelector(selectEduContentMetadataEvaluationSubjectState, selectIds);

export const getAllEntities = createSelector(selectEduContentMetadataEvaluationSubjectState, selectEntities);

export const getByIds = createSelector(
  selectEduContentMetadataEvaluationSubjectState,
  (state: State, props: { ids: number[] }) => {
    return props.ids.map((id) => state.entities[id]);
  }
);

export const getById = createSelector(
  selectEduContentMetadataEvaluationSubjectState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getByEduContentMetadataId = createSelector(
  getAll,
  (
    ecmEvaluationSubjects: EduContentMetadataEvaluationSubjectInterface[]
  ): Dictionary<EduContentMetadataEvaluationSubjectInterface[]> =>
    groupArrayByKeys(ecmEvaluationSubjects, ['eduContentMetadataId'])
);

export const isBookLoaded = (props: { bookId: number }) =>
  createSelector(selectEduContentMetadataEvaluationSubjectState, (state: State) => !!state.loadedForBook[props.bookId]);
