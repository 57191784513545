import { Action } from '@ngrx/store';
import { AtlasProductInterface } from '../../+models';

export enum AtlasProductsActionTypes {
  AtlasProductsLoaded = '[AtlasProducts] AtlasProducts Loaded',
  AtlasProductsLoadError = '[AtlasProducts] Load Error',
  LoadAtlasProducts = '[AtlasProducts] Load AtlasProducts',
}

export class LoadAtlasProducts implements Action {
  readonly type = AtlasProductsActionTypes.LoadAtlasProducts;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class AtlasProductsLoaded implements Action {
  readonly type = AtlasProductsActionTypes.AtlasProductsLoaded;

  constructor(public payload: { atlasProducts: AtlasProductInterface[] }) {}
}

export class AtlasProductsLoadError implements Action {
  readonly type = AtlasProductsActionTypes.AtlasProductsLoadError;
  constructor(public payload: any) {}
}

export type AtlasProductsActions = LoadAtlasProducts | AtlasProductsLoaded | AtlasProductsLoadError;
