import { forwardRef, Inject, Injectable, Optional } from '@angular/core';
import {
  BestResultInterface,
  ContentInterface,
  EduContent,
  EduContentTypeEnum,
  Result,
  ResultInterface,
  ResultStatusEnum,
} from '@campus/dal';
import { StudentTaskWithContentInterface } from '../../../interfaces';
import { ContentActionInterface } from '../content-action.interface';
import {
  ActionsForTaskInstanceEduContentOptionsInterface,
  ContentActionDictionary,
  ContentActionType,
  ContentOpenActionsServiceInterface,
  ContentOpenerInterface,
  STUDENT_CONTENT_OPENER_TOKEN,
} from './content-open-actions.service.interface';
import { DefaultContentOpenerService } from './default-content-opener.service';

@Injectable({
  providedIn: 'root',
})
export class ContentOpenActionsStudentService implements ContentOpenActionsServiceInterface {
  constructor(
    @Inject(forwardRef(() => STUDENT_CONTENT_OPENER_TOKEN))
    @Optional()
    private contentOpener: ContentOpenerInterface,
    private defaultContentOpener: DefaultContentOpenerService
  ) {}

  private contentActionDictionary: ContentActionDictionary = {
    openEduContentAsExercise: {
      label: 'Openen',
      icon: 'open-in-new',
      tooltip: 'Open oefening',
      handler: this.getHandler('openEduContentAsExercise'),
      isPrimary: true,
    },
    continueEduContentAsExercise: {
      label: 'Verder werken',
      icon: 'continue',
      tooltip: 'Open oefening',
      showAsBadge: true,
      handler: this.getHandler('openEduContentAsExercise'),
      isPrimary: true,
    },
    retryEduContentAsExercise: {
      label: 'Maak opnieuw',
      icon: 'refresh',
      tooltip: 'Open oefening',
      showAsBadge: true,
      handler: this.getHandler('openEduContentAsExercise'),
      isPrimary: true,
    },
    openEduContentForReview: {
      label: 'Herbekijk oefening',
      icon: 'open-in-new',
      tooltip: 'Open oefening met oplossingen',
      handler: this.getHandler('openEduContentForReview'),
      isPrimary: true,
    },
    openEduContentAsStream: {
      label: 'Bekijken',
      icon: 'open-in-new',
      tooltip: 'Bekijk de video',
      handler: this.getHandler('openEduContentAsStream'),
      isPrimary: true,
    },
    openEduContentAsStreamCompleted: {
      label: 'Opnieuw bekijken',
      icon: 'open-in-new',
      tooltip: 'Bekijk de video opnieuw',
      handler: this.getHandler('openEduContentAsStream'),
      isPrimary: true,
    },
    openEduContentAsDownload: {
      label: 'Openen',
      icon: 'open-in-new',
      tooltip: 'Open het lesmateriaal',
      handler: this.getHandler('openEduContentAsDownload'),
      isPrimary: true,
    },
    openEduContentAsDownloadCompleted: {
      label: 'Openen',
      icon: 'open-in-new',
      tooltip: 'Open het lesmateriaal opnieuw',
      handler: this.getHandler('openEduContentAsDownload'),
      isPrimary: true,
    },
    openEduContentAsLink: {
      label: 'Openen',
      icon: 'open-in-new',
      tooltip: 'Open de link',
      handler: this.getHandler('openEduContentAsLink'),
      isPrimary: true,
    },
    openEduContentAsLinkCompleted: {
      label: 'Opnieuw openen',
      icon: 'open-in-new',
      tooltip: 'Open de link opnieuw',
      handler: this.getHandler('openEduContentAsLink'),
      isPrimary: true,
    },
    openEduContentAsWebApp: {
      label: 'Openen',
      icon: 'open-in-new',
      tooltip: 'Open de web-app',
      handler: this.getHandler('openEduContentAsLink'),
      isPrimary: true,
    },
    openEduContentAsWebAppCompleted: {
      label: 'Opnieuw openen',
      icon: 'open-in-new',
      tooltip: 'Open de web-app opnieuw',
      handler: this.getHandler('openEduContentAsLink'),
      isPrimary: true,
    },
    openBoeke: {
      label: 'Openen',
      icon: 'open-in-new',
      tooltip: 'Open het bordboek',
      handler: this.getHandler('openBoeke'),
      isPrimary: true,
    },
    openPaperExercise: {
      label: 'Openen',
      icon: 'open-in-new',
      tooltip: 'Open de oefening',
      handler: this.getHandler('openPaperExercise'),
      isPrimary: true,
    },
    openEduContentAsHtml: {
      label: 'Lezen',
      icon: 'preview',
      tooltip: 'Lees de tekst',
      handler: this.getHandler('openEduContentAsHtml'),
      isPrimary: true,
    },
    openInstructions: {
      label: 'Uitleg bekijken',
      icon: 'open-in-new',
      tooltip: 'Open de uitleg',
      handler: this.getHandler('openInstructions'),
    },
  };

  /**
   * Gets all actions for the provided eduContent
   *
   * @param {EduContent} eduContent
   * @returns {ContentActionInterface[]}
   * @memberof ContentOpenActionsStudentService
   */
  getActionsForContent(
    content: ContentInterface,
    result?: ResultInterface,
    options: { forReview?: boolean; forDWB?: boolean } = { forReview: false, forDWB: false }
  ): ContentActionInterface[] {
    return this.getEduContentActions(content, result, options);
  }

  getActionForContent(content: ContentInterface, actionType: ContentActionType): ContentActionInterface {
    const actions = this.getActionsForContent(content);
    const action = actions.find((_action) => _action === this.contentActionDictionary[actionType]);
    return action;
  }

  getActionsForTaskInstanceEduContent(
    eduContent: EduContent,
    result: BestResultInterface,
    taskInstance: Pick<StudentTaskWithContentInterface, 'end' | 'isFinished'>,
    options?: ActionsForTaskInstanceEduContentOptionsInterface
  ): ContentActionInterface[] {
    if (eduContent.isExercise) {
      const isTaskInstanceInPast = taskInstance.isFinished || taskInstance.end < new Date();
      const attempts = result?.attempts || [result]; // if not bestResult, it's a regular result
      const mostRecentAttempt = Result.getMostRecentResult(attempts);
      const isTaskEduContentCompleted = attempts.some(
        (attempt) => attempt?.status === ResultStatusEnum.STATUS_COMPLETED
      );
      const isMostRecentAttemptCompleted = mostRecentAttempt?.status === ResultStatusEnum.STATUS_COMPLETED;
      const isMostRecentAttemptIncomplete = mostRecentAttempt?.status === ResultStatusEnum.STATUS_INCOMPLETE;

      // If an exercise is ended show the review button
      if (isTaskInstanceInPast) {
        return [this.contentActionDictionary.openEduContentForReview];
      } else if (isTaskEduContentCompleted) {
        const feedbackAndOrRepeatableActions = [];

        if (options?.isRepeatable) {
          feedbackAndOrRepeatableActions.push(
            isMostRecentAttemptCompleted
              ? this.contentActionDictionary.retryEduContentAsExercise
              : this.contentActionDictionary.continueEduContentAsExercise
          );
        }
        if (options?.withFeedback) {
          feedbackAndOrRepeatableActions.push(this.contentActionDictionary.openEduContentForReview);
        }

        return feedbackAndOrRepeatableActions;
      } else if (isMostRecentAttemptIncomplete) {
        return [this.contentActionDictionary.continueEduContentAsExercise];
      } else {
        return [this.contentActionDictionary.openEduContentAsExercise];
      }
    }

    return this.getEduContentActions(eduContent, result);
  }

  private getEduContentActions(
    content: ContentInterface,
    result: ResultInterface,
    options: {
      forReview?: boolean;
      forDWB?: boolean;
    } = { forReview: false, forDWB: false }
  ): ContentActionInterface[] {
    if (!content) return [];

    const completed =
      result && [ResultStatusEnum.STATUS_COMPLETED, ResultStatusEnum.STATUS_OPENED].includes(result.status);

    // Special case that covers eduContent.type exercise or usesScorm, skipping the switch case below:
    if (content instanceof EduContent && (content as EduContent).isExercise) {
      return options.forDWB
        ? this.getExerciseContentActionsForDWB(result, completed)
        : this.getExerciseContentActions(result, completed, options.forReview);
    }

    switch (content.type) {
      case EduContentTypeEnum.BOEKE:
        return [this.contentActionDictionary.openBoeke];
      case EduContentTypeEnum.PAPER_EXERCISE:
        return [this.contentActionDictionary.openPaperExercise];
      case EduContentTypeEnum.LINK:
        return completed
          ? [this.contentActionDictionary.openEduContentAsLinkCompleted]
          : [this.contentActionDictionary.openEduContentAsLink];
      case EduContentTypeEnum.WEB_APP: {
        const webAppActions = [];
        if (completed) webAppActions.push(this.contentActionDictionary.openEduContentAsWebAppCompleted);
        else webAppActions.push(this.contentActionDictionary.openEduContentAsWebApp);

        if (content instanceof EduContent && content.hasInstructions) {
          webAppActions.push(this.contentActionDictionary.openInstructions);
        }
        return webAppActions;
      }
      case EduContentTypeEnum.TEXT:
        return [this.contentActionDictionary.openEduContentAsHtml];
    }

    if (content instanceof EduContent && content.streamable) {
      return completed
        ? [this.contentActionDictionary.openEduContentAsStreamCompleted]
        : [this.contentActionDictionary.openEduContentAsStream];
    } else {
      return completed
        ? [this.contentActionDictionary.openEduContentAsDownloadCompleted]
        : [this.contentActionDictionary.openEduContentAsDownload];
    }
  }

  private getExerciseContentActions(result: ResultInterface, completed: boolean, forReview?: boolean) {
    if (forReview) {
      return [this.contentActionDictionary.openEduContentForReview];
    }
    if (completed) {
      return [this.contentActionDictionary.retryEduContentAsExercise];
    }
    if (result && result.status === ResultStatusEnum.STATUS_INCOMPLETE) {
      return [this.contentActionDictionary.continueEduContentAsExercise];
    }
    return [this.contentActionDictionary.openEduContentAsExercise];
  }

  private getExerciseContentActionsForDWB(result: ResultInterface, completed: boolean) {
    if (completed) {
      return [
        this.contentActionDictionary.retryEduContentAsExercise,
        this.contentActionDictionary.openEduContentForReview,
      ];
    }
    if (result && result.status === ResultStatusEnum.STATUS_INCOMPLETE) {
      return [this.contentActionDictionary.continueEduContentAsExercise];
    }
    return [this.contentActionDictionary.openEduContentAsExercise];
  }

  private getHandler(name: keyof ContentOpenerInterface) {
    if (this.contentOpener?.[name]) {
      return this.contentOpener[name].bind(this.contentOpener);
    }

    return this.defaultContentOpener[name]?.bind(this.defaultContentOpener);
  }
}
