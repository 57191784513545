import { CurriculumTreeInterface } from './CurriculumTree.interface';
import { LoopInterface } from './Loop.interface';

export interface BadgeInterface {
  id?: number;
  code?: string | BadgeCodeEnum;
  label?: string;
  loopId?: number;
  loop?: LoopInterface;
  curriculumTreeId?: string;
  curriculumTree?: CurriculumTreeInterface;
  image?: string;
  sequence?: number;
}

export enum BadgeCodeEnum {
  INTAKE = 'intake',
  MASTER = 'novice', // deprecated
  NOVICE = 'novice',
  EXPERT = 'expert',
}
