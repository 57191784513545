import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './task-evaluation-subject.reducer';

export const selectTaskEvaluationSubjectState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectTaskEvaluationSubjectState, (state: State) => state.error);

export const getLoaded = createSelector(selectTaskEvaluationSubjectState, (state: State) => state.loaded);

export const getAll = createSelector(selectTaskEvaluationSubjectState, selectAll);

export const getCount = createSelector(selectTaskEvaluationSubjectState, selectTotal);

export const getIds = createSelector(selectTaskEvaluationSubjectState, selectIds);

export const getAllEntities = createSelector(selectTaskEvaluationSubjectState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * taskEvaluationSubject$: TaskEvaluationSubjectInterface[] = this.store.pipe(
    select(TaskEvaluationSubjectQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectTaskEvaluationSubjectState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * taskEvaluationSubject$: TaskEvaluationSubjectInterface = this.store.pipe(
    select(TaskEvaluationSubjectQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectTaskEvaluationSubjectState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getTaskEvaluationSubjectLoadedForTask = createSelector(
  selectTaskEvaluationSubjectState,
  (state, props: { taskId: number }) => {
    return !!state.loadedForTask[props.taskId];
  }
);

export const isSaving = createSelector(selectTaskEvaluationSubjectState, (state: State) => state.saving);
