import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch, optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { HistoryQueries } from '.';
import { HistoryServiceInterface, HISTORY_SERVICE_TOKEN } from '../../history/history.service.interface';
import { ActionSuccessful } from '../dal.actions';
import { DalState } from '../dal.state.interface';
import { EffectFeedback, EffectFeedbackActions } from '../effect-feedback';
import {
  DeleteHistory,
  HistoryActionTypes,
  HistoryLoaded,
  HistoryLoadError,
  LoadHistory,
  StartUpsertHistory,
  UpsertHistory,
} from './history.actions';

@Injectable()
export class HistoryEffects {
  loadHistory$ = createEffect(() =>
    this.actions.pipe(
      ofType(HistoryActionTypes.LoadHistory),
      concatLatestFrom(() => this.store.select(HistoryQueries.getLoaded)),
      fetch({
        run: (action: LoadHistory, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.historyService
            .getAllForUser(action.payload.userId)
            .pipe(map((history) => new HistoryLoaded({ history })));
        },
        onError: (action: LoadHistory, error) => {
          return new HistoryLoadError(error);
        },
      })
    )
  );

  startUpsertHistory$ = createEffect(() =>
    this.actions.pipe(
      ofType(HistoryActionTypes.StartUpsertHistory),
      pessimisticUpdate({
        run: (action: StartUpsertHistory) => {
          return this.historyService
            .upsertHistory(action.payload.history)
            .pipe(map((history) => new UpsertHistory({ history })));
        },
        onError: (action: StartUpsertHistory, error) => {
          // Feedback for failed add to history ?
        },
      })
    )
  );

  deleteHistory$ = createEffect(() =>
    this.actions.pipe(
      ofType(HistoryActionTypes.DeleteHistory),
      optimisticUpdate({
        run: (action: DeleteHistory) => {
          return this.historyService
            .deleteHistory(action.payload.userId, action.payload.id)
            .pipe(mapTo(new ActionSuccessful({ successfulAction: 'delete history' })));
        },
        undoAction: (action: DeleteHistory, error) => {
          const undoAction = undo(action);
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            'Het is niet gelukt om het item uit jouw geschiedenis te verwijderen.'
          );

          const effectFeedbackAction = new EffectFeedbackActions.AddEffectFeedback({ effectFeedback });
          return from([undoAction, effectFeedbackAction]);
        },
      })
    )
  );

  constructor(
    @Inject('uuid') private uuid: () => string,
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(HISTORY_SERVICE_TOKEN)
    private historyService: HistoryServiceInterface
  ) {}
}
