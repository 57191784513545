import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { LearningAreaQueries } from '.';
import {
  LearningAreaServiceInterface,
  LEARNINGAREA_SERVICE_TOKEN,
} from '../../learning-area/learning-area.service.interface';
import { DalState } from '../dal.state.interface';
import {
  LearningAreasActionTypes,
  LearningAreasLoaded,
  LearningAreasLoadError,
  LoadLearningAreas,
} from './learning-area.actions';

@Injectable()
export class LearningAreasEffects {
  loadLearningAreas$ = createEffect(() =>
    this.actions.pipe(
      ofType(LearningAreasActionTypes.LoadLearningAreas),
      concatLatestFrom(() => this.store.select(LearningAreaQueries.getLoaded)),
      fetch({
        run: (action: LoadLearningAreas, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.learningAreaService
            .getAll()
            .pipe(map((learningAreas) => new LearningAreasLoaded({ learningAreas })));
        },
        onError: (action: LoadLearningAreas, error) => {
          return new LearningAreasLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(LEARNINGAREA_SERVICE_TOKEN)
    private learningAreaService: LearningAreaServiceInterface
  ) {}
}
