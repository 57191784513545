import { Injectable } from '@angular/core';
import { combineLatest, concat, Observable, of } from 'rxjs';
import { map, take, toArray } from 'rxjs/operators';
import { ScormCmiMode } from '../+external-interfaces/scorm-api.interface';
import { ContextInterface, ResultInterface } from '../+models';
import { ResultsService } from '../results/results.service';
import { CurrentExerciseInterface } from './../+state/current-exercise/current-exercise.reducer';
import { ContentRequestService } from './../content-request/content-request.service';
import { ExerciseServiceInterface } from './exercise.service.interface';

@Injectable({
  providedIn: 'root',
})
export class ExerciseService implements ExerciseServiceInterface {
  public loadExercise(
    userId: number,
    eduContentId: number,
    saveToApi: boolean,
    mode: ScormCmiMode,
    taskId?: number,
    unlockedContentId?: number,
    result?: ResultInterface,
    unlockedFreePracticeId?: number,
    context?: ContextInterface
  ): Observable<CurrentExerciseInterface> {
    if (result) {
      return this.reviewExercise(userId, result, context);
    }

    if (!saveToApi) {
      // if no saves to api + not review => preview!
      return this.previewExercise(eduContentId, mode, context);
    }

    return this.startExercise(
      userId,
      eduContentId,
      saveToApi,
      mode,
      taskId,
      unlockedContentId,
      unlockedFreePracticeId,
      context
    );
  }

  public saveExercise(exercise: CurrentExerciseInterface): Observable<CurrentExerciseInterface> {
    const userId = exercise.result.personId;
    const result$ = this.resultsService.saveResult(userId, exercise.result).pipe(
      map((result) => {
        return {
          ...exercise,
          result: result,
        } as CurrentExerciseInterface;
      })
    );

    return result$;
  }

  private reviewExercise(
    userId: number,
    result: ResultInterface,
    context?: ContextInterface
  ): Observable<CurrentExerciseInterface> {
    let result$: Observable<ResultInterface>;

    const tempUrl$ = this.contentRequestService.requestUrl(result.eduContentId, context);

    if (result.cmi) {
      result$ = of(result);
    } else {
      result$ = this.resultsService.getResultForReview(userId, result.id);
    }

    const exercise$ = combineLatest([result$, tempUrl$]).pipe(
      map(([newResult, url]) => {
        return {
          eduContentId: result.eduContentId,
          cmiMode: ScormCmiMode.CMI_MODE_REVIEW,
          result: newResult,
          saveToApi: false,
          url: url,
        } as CurrentExerciseInterface;
      })
    );

    return exercise$;
  }

  private previewExercise(
    eduContentId: number,
    mode: ScormCmiMode,
    context?: ContextInterface
  ): Observable<CurrentExerciseInterface> {
    const tempUrl$ = this.contentRequestService.requestUrl(eduContentId, context);

    const exercise$ = tempUrl$.pipe(
      take(1),
      map((url) => {
        return {
          eduContentId: eduContentId,
          cmiMode: mode,
          saveToApi: false,
          url: url,
        } as CurrentExerciseInterface;
      })
    );

    return exercise$;
  }

  private startExercise(
    userId: number,
    eduContentId: number,
    saveToApi: boolean,
    mode: ScormCmiMode,
    taskId?: number,
    unlockedContentId?: number,
    unlockedFreePracticeId?: number,
    context?: ContextInterface
  ): Observable<CurrentExerciseInterface> {
    let result$: Observable<ResultInterface>;

    const params = [taskId, unlockedContentId, unlockedFreePracticeId];
    const zeroOrMoreThanOneParam = params.filter(Number).length !== 1;
    if (!context && zeroOrMoreThanOneParam) {
      throw new Error('Provide either a context, a taskId, an unlockedContentId or an unlockedFreePracticeId');
    }

    // override params with context
    if (context) {
      // refactor in progress: there should be 1 getResult call that uses the context to find
      // the correct result.
      if (context.taskId) taskId = context.taskId;
      if (context.unlockedFreePracticeId) unlockedFreePracticeId = context.unlockedFreePracticeId;
      if (context.unlockedContentId) unlockedContentId = context.unlockedContentId;
    }

    if (taskId) {
      result$ = this.resultsService.getResultForTask(userId, taskId, eduContentId);
    } else if (unlockedContentId) {
      result$ = this.resultsService.getResultForUnlockedContent(userId, unlockedContentId, eduContentId);
    } else if (unlockedFreePracticeId) {
      result$ = this.resultsService.getResultForUnlockedFreePractice(userId, unlockedFreePracticeId, eduContentId);
    } else if (context) {
      // get result for adaptive context (yearId, loop, strandId) via registration
      result$ = this.resultsService.getResultForContext(userId, eduContentId, context);
    }

    const tempUrl$ = this.contentRequestService.requestUrl(eduContentId, context);
    // requestUrl could make a new result
    // we make the calls sequential, so the correct result is fetched
    const currentExercise$ = concat(tempUrl$, result$).pipe(
      toArray(),
      take(1),
      map(([url, resultFromApi]) => {
        return {
          eduContentId: (resultFromApi as ResultInterface).eduContentId,
          cmiMode: mode,
          result: resultFromApi,
          saveToApi: saveToApi,
          url: url,
        } as CurrentExerciseInterface;
      })
    );

    return currentExercise$;
  }

  constructor(private resultsService: ResultsService, private contentRequestService: ContentRequestService) {}
}
