import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { MethodProductGroupProductInterface } from '../+models';

export const METHOD_PRODUCT_GROUP_PRODUCT_SERVICE_TOKEN = new InjectionToken(
  'MethodProductGroupProductServiceInterface'
);

export interface MethodProductGroupProductServiceInterface {
  getAllForUser(userId: number): Observable<MethodProductGroupProductInterface[]>;
}
