import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { TaskEvaluationSubjectInterface } from '../../+models';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum TaskEvaluationSubjectsActionTypes {
  TaskEvaluationSubjectsLoaded = '[TaskEvaluationSubjects] TaskEvaluationSubjects Loaded',
  TaskEvaluationSubjectsLoadError = '[TaskEvaluationSubjects] Load Error',
  LoadTaskEvaluationSubjects = '[TaskEvaluationSubjects] Load TaskEvaluationSubjects',
  LoadTaskEvaluationSubjectsForTask = '[TaskEvaluationSubjects] Load TaskEvaluationSubjects for Task',
  StartSetTaskEvaluationSubjectsForTask = '[TaskEvaluationSubjects] Start Set TaskEvaluationSubjects for task',
  SetTaskEvaluationSubjectsForTask = '[TaskEvaluationSubjects] Set TaskEvaluationSubjects for task',
  AddTaskEvaluationSubjectsForTask = '[TaskEvaluationSubjects] Add TaskEvaluationSubjects for Task',
  AddTaskEvaluationSubject = '[TaskEvaluationSubjects] Add TaskEvaluationSubject',
  UpsertTaskEvaluationSubject = '[TaskEvaluationSubjects] Upsert TaskEvaluationSubject',
  AddTaskEvaluationSubjects = '[TaskEvaluationSubjects] Add TaskEvaluationSubjects',
  UpsertTaskEvaluationSubjects = '[TaskEvaluationSubjects] Upsert TaskEvaluationSubjects',
  UpdateTaskEvaluationSubject = '[TaskEvaluationSubjects] Update TaskEvaluationSubject',
  UpdateTaskEvaluationSubjects = '[TaskEvaluationSubjects] Update TaskEvaluationSubjects',
  DeleteTaskEvaluationSubject = '[TaskEvaluationSubjects] Delete TaskEvaluationSubject',
  DeleteTaskEvaluationSubjects = '[TaskEvaluationSubjects] Delete TaskEvaluationSubjects',
  ClearTaskEvaluationSubjects = '[TaskEvaluationSubjects] Clear TaskEvaluationSubjects',
}

export class LoadTaskEvaluationSubjects implements Action {
  readonly type = TaskEvaluationSubjectsActionTypes.LoadTaskEvaluationSubjects;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class LoadTaskEvaluationSubjectsForTask implements Action {
  readonly type = TaskEvaluationSubjectsActionTypes.LoadTaskEvaluationSubjectsForTask;

  constructor(public payload: { taskId: number }) {}
}

export class AddTaskEvaluationSubjectsForTask implements Action {
  readonly type = TaskEvaluationSubjectsActionTypes.AddTaskEvaluationSubjectsForTask;

  constructor(public payload: { taskId: number; taskEvaluationSubjects: TaskEvaluationSubjectInterface[] }) {}
}
export class StartSetTaskEvaluationSubjectsForTask implements Action, FeedbackTriggeringAction {
  readonly type = TaskEvaluationSubjectsActionTypes.StartSetTaskEvaluationSubjectsForTask;

  constructor(
    public payload: {
      taskId: number;
      taskEvaluationSubjects: TaskEvaluationSubjectInterface[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}
export class SetTaskEvaluationSubjectsForTask implements Action {
  readonly type = TaskEvaluationSubjectsActionTypes.SetTaskEvaluationSubjectsForTask;

  constructor(public payload: { taskId: number; taskEvaluationSubjects: TaskEvaluationSubjectInterface[] }) {}
}

export class TaskEvaluationSubjectsLoaded implements Action {
  readonly type = TaskEvaluationSubjectsActionTypes.TaskEvaluationSubjectsLoaded;

  constructor(public payload: { taskEvaluationSubjects: TaskEvaluationSubjectInterface[] }) {}
}

export class TaskEvaluationSubjectsLoadError implements Action {
  readonly type = TaskEvaluationSubjectsActionTypes.TaskEvaluationSubjectsLoadError;
  constructor(public payload: any) {}
}

export class AddTaskEvaluationSubject implements Action {
  readonly type = TaskEvaluationSubjectsActionTypes.AddTaskEvaluationSubject;

  constructor(public payload: { taskEvaluationSubject: TaskEvaluationSubjectInterface }) {}
}

export class UpsertTaskEvaluationSubject implements Action {
  readonly type = TaskEvaluationSubjectsActionTypes.UpsertTaskEvaluationSubject;

  constructor(public payload: { taskEvaluationSubject: TaskEvaluationSubjectInterface }) {}
}

export class AddTaskEvaluationSubjects implements Action {
  readonly type = TaskEvaluationSubjectsActionTypes.AddTaskEvaluationSubjects;

  constructor(public payload: { taskEvaluationSubjects: TaskEvaluationSubjectInterface[] }) {}
}

export class UpsertTaskEvaluationSubjects implements Action {
  readonly type = TaskEvaluationSubjectsActionTypes.UpsertTaskEvaluationSubjects;

  constructor(public payload: { taskEvaluationSubjects: TaskEvaluationSubjectInterface[] }) {}
}

export class UpdateTaskEvaluationSubject implements Action {
  readonly type = TaskEvaluationSubjectsActionTypes.UpdateTaskEvaluationSubject;

  constructor(public payload: { taskEvaluationSubject: Update<TaskEvaluationSubjectInterface> }) {}
}

export class UpdateTaskEvaluationSubjects implements Action {
  readonly type = TaskEvaluationSubjectsActionTypes.UpdateTaskEvaluationSubjects;

  constructor(public payload: { taskEvaluationSubjects: Update<TaskEvaluationSubjectInterface>[] }) {}
}

export class DeleteTaskEvaluationSubject implements Action {
  readonly type = TaskEvaluationSubjectsActionTypes.DeleteTaskEvaluationSubject;

  constructor(public payload: { id: number }) {}
}

export class DeleteTaskEvaluationSubjects implements Action {
  readonly type = TaskEvaluationSubjectsActionTypes.DeleteTaskEvaluationSubjects;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearTaskEvaluationSubjects implements Action {
  readonly type = TaskEvaluationSubjectsActionTypes.ClearTaskEvaluationSubjects;
}

export type TaskEvaluationSubjectsActions =
  | LoadTaskEvaluationSubjects
  | TaskEvaluationSubjectsLoaded
  | TaskEvaluationSubjectsLoadError
  | AddTaskEvaluationSubject
  | UpsertTaskEvaluationSubject
  | AddTaskEvaluationSubjects
  | UpsertTaskEvaluationSubjects
  | UpdateTaskEvaluationSubject
  | UpdateTaskEvaluationSubjects
  | DeleteTaskEvaluationSubject
  | DeleteTaskEvaluationSubjects
  | ClearTaskEvaluationSubjects
  | LoadTaskEvaluationSubjectsForTask
  | AddTaskEvaluationSubjectsForTask
  | StartSetTaskEvaluationSubjectsForTask
  | SetTaskEvaluationSubjectsForTask;
