import { Injectable } from '@angular/core';
import {
  BadgeInterface,
  ConceptInterface,
  LearnerProfileStrandInterface,
  LoopCodeEnum,
  LoopInterface,
  ResultInterface,
  UnlockedCurriculumTreeInterface,
} from '@campus/dal';
import { LoopModeEnum } from '@campus/ui';
import { Dictionary } from '@ngrx/entity';
import { BadgeWithProgressInterface, StrandInterface } from '../../interfaces';
import { MapperStudentStrandsServiceInterface } from './mapper.student-strands.service.interface';

@Injectable({ providedIn: 'root' })
export class MapperStudentStrandsService implements MapperStudentStrandsServiceInterface {
  constructor() {}

  public mapToStudentStrand(
    yearId: number,
    strand: StrandInterface,
    unlockedCurriculumTrees: UnlockedCurriculumTreeInterface[],
    learnerProfile: LearnerProfileStrandInterface,
    loops: LoopInterface[],
    badges: BadgeInterface[],
    concepts: ConceptInterface[],
    resultsByConcept: Dictionary<ResultInterface[]>
  ): StrandInterface {
    const {
      isUnlocked,
      intakeUnlocked,
      masteringUnlocked,
      intakeUnlockedCurriculumTreeId,
      masteringUnlockedCurriculumTreeId,
    } = this.getUnlockedLoopDetails(loops, unlockedCurriculumTrees);

    const unlockedLoopIds = unlockedCurriculumTrees.map((uct) => uct.loopId);
    const badgesWithProgress = this.getBadges(badges, learnerProfile, unlockedLoopIds);

    const { actionLabel, actionIcon } = this.getActionLabelAndIconForStrand(
      learnerProfile,
      concepts,
      resultsByConcept,
      intakeUnlocked,
      masteringUnlocked
    );

    return {
      ...strand,
      intakeUnlocked,
      masteringUnlocked,
      intakeUnlockedCurriculumTreeId,
      masteringUnlockedCurriculumTreeId,
      yearId,
      isUnlocked,
      learnerProfile,
      badges: badgesWithProgress,
      actionLabel,
      actionIcon,
    };
  }

  private getUnlockedLoopDetails(loops: LoopInterface[], unlockedCurriculumTrees: UnlockedCurriculumTreeInterface[]) {
    const isUnlocked = !!unlockedCurriculumTrees.length;

    const loopIdByEnum = loops.reduce((acc, loop) => {
      acc[loop.code] = loop.id;
      return acc;
    }, {});

    const findLoop = (loopCode: LoopCodeEnum) =>
      unlockedCurriculumTrees.find((x) => x.loopId === loopIdByEnum[loopCode]);

    const intakeUnlockedCurriculumTree = findLoop(LoopCodeEnum.INTAKE);
    const masteringUnlockedCurriculumTree = findLoop(LoopCodeEnum.MASTERING);

    const intakeUnlocked = !!intakeUnlockedCurriculumTree;
    const masteringUnlocked = !!masteringUnlockedCurriculumTree;

    const intakeUnlockedCurriculumTreeId = intakeUnlocked ? intakeUnlockedCurriculumTree.id : undefined;
    const masteringUnlockedCurriculumTreeId = masteringUnlocked ? masteringUnlockedCurriculumTree.id : undefined;

    return {
      isUnlocked,
      intakeUnlocked,
      masteringUnlocked,
      intakeUnlockedCurriculumTreeId,
      masteringUnlockedCurriculumTreeId,
    };
  }

  private getActionLabelAndIconForStrand(
    learnerProfile: LearnerProfileStrandInterface,
    concepts: ConceptInterface[],
    resultsByConcept: Dictionary<ResultInterface[]>,
    intakeUnlocked: boolean,
    masteringUnlocked: boolean
  ) {
    let actionLabel = intakeUnlocked ? 'opwarmen' : 'oefenen';
    let actionIcon = intakeUnlocked ? 'fire' : 'lightbulb';

    if (learnerProfile) {
      if (learnerProfile.expertCompleted) {
        actionLabel = 'nog meer oefenen';
        actionIcon = 'diamond';
      } else if (learnerProfile.masteringCompleted) {
        actionLabel = 'word expert';
        actionIcon = 'diamond';
      } else if (learnerProfile.intakeCompleted || (masteringUnlocked && !intakeUnlocked)) {
        // For future reference:
        // if there are more loops involved, or intake loop has seperate concepts to start from
        // then hasResults should depend on result/loop relation. hasResultsForLoop (or so)
        // For now, resultsByConcept only has related results through masteringLoop
        const hasResults = (concepts || []).some((concept) => !!(resultsByConcept[concept.guid] || []).length);
        actionLabel = hasResults ? 'verder oefenen' : 'oefenen';
        actionIcon = 'lightbulb';
      } else {
        actionLabel = learnerProfile.intakeProgress > 0 ? 'verder opwarmen' : 'opwarmen';
        actionIcon = 'fire';
      }
    }
    return { actionLabel, actionIcon };
  }

  private getBadges(
    badges: BadgeInterface[],
    learnerProfile: LearnerProfileStrandInterface,
    unlockedLoopIds: number[]
  ): BadgeWithProgressInterface[] {
    return (badges || [])
      .filter((badge) => unlockedLoopIds.includes(badge.loopId))
      .map((badge) => {
        if (!learnerProfile) return badge;

        const badgeProgress = {
          progress: 0,
          loopMode: LoopModeEnum.INTAKE,
        };
        switch (badge.code) {
          case LoopModeEnum.INTAKE:
            badgeProgress.progress = learnerProfile.intakeProgress;
            badgeProgress.loopMode = learnerProfile.intakeCompleted ? LoopModeEnum.DONE : LoopModeEnum.INTAKE;
            break;
          case LoopModeEnum.MASTER:
            badgeProgress.progress = learnerProfile.masteringProgress;
            badgeProgress.loopMode = learnerProfile.masteringCompleted ? LoopModeEnum.DONE : LoopModeEnum.MASTER;
            break;
          case LoopModeEnum.EXPERT:
            badgeProgress.progress = learnerProfile.expertProgress;
            badgeProgress.loopMode = learnerProfile.expertCompleted ? LoopModeEnum.DONE : LoopModeEnum.EXPERT;
            break;
        }
        return { ...badge, ...badgeProgress };
      });
  }
}
