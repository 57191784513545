import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { MethodProductGroupProductInterface } from '../../+models';

export enum MethodProductGroupProductsActionTypes {
  MethodProductGroupProductsLoaded = '[MethodProductGroupProducts] MethodProductGroupProducts Loaded',
  MethodProductGroupProductsLoadError = '[MethodProductGroupProducts] Load Error',
  LoadMethodProductGroupProducts = '[MethodProductGroupProducts] Load MethodProductGroupProducts',
  AddMethodProductGroupProduct = '[MethodProductGroupProducts] Add MethodProductGroupProduct',
  UpsertMethodProductGroupProduct = '[MethodProductGroupProducts] Upsert MethodProductGroupProduct',
  AddMethodProductGroupProducts = '[MethodProductGroupProducts] Add MethodProductGroupProducts',
  UpsertMethodProductGroupProducts = '[MethodProductGroupProducts] Upsert MethodProductGroupProducts',
  UpdateMethodProductGroupProduct = '[MethodProductGroupProducts] Update MethodProductGroupProduct',
  UpdateMethodProductGroupProducts = '[MethodProductGroupProducts] Update MethodProductGroupProducts',
  DeleteMethodProductGroupProduct = '[MethodProductGroupProducts] Delete MethodProductGroupProduct',
  DeleteMethodProductGroupProducts = '[MethodProductGroupProducts] Delete MethodProductGroupProducts',
  ClearMethodProductGroupProducts = '[MethodProductGroupProducts] Clear MethodProductGroupProducts',
}

export class LoadMethodProductGroupProducts implements Action {
  readonly type = MethodProductGroupProductsActionTypes.LoadMethodProductGroupProducts;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class MethodProductGroupProductsLoaded implements Action {
  readonly type = MethodProductGroupProductsActionTypes.MethodProductGroupProductsLoaded;

  constructor(
    public payload: {
      methodProductGroupProducts: MethodProductGroupProductInterface[];
    }
  ) {}
}

export class MethodProductGroupProductsLoadError implements Action {
  readonly type = MethodProductGroupProductsActionTypes.MethodProductGroupProductsLoadError;
  constructor(public payload: any) {}
}

export class AddMethodProductGroupProduct implements Action {
  readonly type = MethodProductGroupProductsActionTypes.AddMethodProductGroupProduct;

  constructor(
    public payload: {
      methodProductGroupProduct: MethodProductGroupProductInterface;
    }
  ) {}
}

export class UpsertMethodProductGroupProduct implements Action {
  readonly type = MethodProductGroupProductsActionTypes.UpsertMethodProductGroupProduct;

  constructor(
    public payload: {
      methodProductGroupProduct: MethodProductGroupProductInterface;
    }
  ) {}
}

export class AddMethodProductGroupProducts implements Action {
  readonly type = MethodProductGroupProductsActionTypes.AddMethodProductGroupProducts;

  constructor(
    public payload: {
      methodProductGroupProducts: MethodProductGroupProductInterface[];
    }
  ) {}
}

export class UpsertMethodProductGroupProducts implements Action {
  readonly type = MethodProductGroupProductsActionTypes.UpsertMethodProductGroupProducts;

  constructor(
    public payload: {
      methodProductGroupProducts: MethodProductGroupProductInterface[];
    }
  ) {}
}

export class UpdateMethodProductGroupProduct implements Action {
  readonly type = MethodProductGroupProductsActionTypes.UpdateMethodProductGroupProduct;

  constructor(
    public payload: {
      methodProductGroupProduct: Update<MethodProductGroupProductInterface>;
    }
  ) {}
}

export class UpdateMethodProductGroupProducts implements Action {
  readonly type = MethodProductGroupProductsActionTypes.UpdateMethodProductGroupProducts;

  constructor(
    public payload: {
      methodProductGroupProducts: Update<MethodProductGroupProductInterface>[];
    }
  ) {}
}

export class DeleteMethodProductGroupProduct implements Action {
  readonly type = MethodProductGroupProductsActionTypes.DeleteMethodProductGroupProduct;

  constructor(public payload: { id: number }) {}
}

export class DeleteMethodProductGroupProducts implements Action {
  readonly type = MethodProductGroupProductsActionTypes.DeleteMethodProductGroupProducts;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearMethodProductGroupProducts implements Action {
  readonly type = MethodProductGroupProductsActionTypes.ClearMethodProductGroupProducts;
}

export type MethodProductGroupProductsActions =
  | LoadMethodProductGroupProducts
  | MethodProductGroupProductsLoaded
  | MethodProductGroupProductsLoadError
  | AddMethodProductGroupProduct
  | UpsertMethodProductGroupProduct
  | AddMethodProductGroupProducts
  | UpsertMethodProductGroupProducts
  | UpdateMethodProductGroupProduct
  | UpdateMethodProductGroupProducts
  | DeleteMethodProductGroupProduct
  | DeleteMethodProductGroupProducts
  | ClearMethodProductGroupProducts;
