import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MethodInterface } from '../../+models';
import { InkMethodsActions, InkMethodsActionTypes } from './ink-method.actions';

export const NAME = 'inkMethods';

export interface State extends EntityState<MethodInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

const sortByAdaptive = (a: MethodInterface, b: MethodInterface): number => {
  return +b.isAdaptive - +a.isAdaptive;
};

const sortByName = (a: MethodInterface, b: MethodInterface): number => a.name.localeCompare(b.name);

export const adapter: EntityAdapter<MethodInterface> = createEntityAdapter<MethodInterface>({
  sortComparer: (a, b) => sortByAdaptive(a, b) || sortByName(a, b),
});

export const initialState: State = adapter.getInitialState({
  loaded: false,
});

export function reducer(state = initialState, action: InkMethodsActions): State {
  switch (action.type) {
    case InkMethodsActionTypes.InkMethodsLoaded: {
      return adapter.setAll(action.payload.inkMethods, { ...state, loaded: true });
    }

    case InkMethodsActionTypes.InkMethodsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case InkMethodsActionTypes.ClearInkMethods: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
