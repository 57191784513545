export const icons = {
  logo: 'assets/images/polpo-logo.svg',
  diabolo: 'assets/icons/diabolo/diabolo.svg',

  // providers
  smartschool: 'assets/icons/providers/smartschool.svg',
  facebook: 'assets/icons/providers/facebook.svg',
  google: 'assets/icons/providers/google.svg',
  acm: 'assets/icons/providers/acm.svg',
  microsoft: 'assets/icons/providers/microsoft.svg',
  leerid: 'assets/icons/providers/leerid.svg',

  /* filled */
  'favorite-filled': 'assets/icons/filled/favorite.svg',
  star: 'assets/icons/filled/star.svg',
  'turned-in': 'assets/icons/filled/turned_in.svg',
  wizard: 'assets/icons/filled/auto-fix.svg',
  visibility: 'assets/icons/filled/visibility.svg',
  'visibility-off': 'assets/icons/filled/visibility_off.svg',
  'marketing-message': 'assets/icons/filled/polpo-small.svg',
  'mini-logo': 'assets/icons/filled/polpo-small.svg',
  'check-circle': 'assets/icons/filled/check_circle.svg',
  'filter-active': 'assets/icons/filled/filter.svg',
  connections: 'assets/icons/filled/connections.svg',
  sync: 'assets/icons/filled/sync.svg',

  /* tasks */
  'task:active': 'assets/icons/tasks/task-active.svg',
  'task:add': 'assets/icons/tasks/add-to-task.svg',
  'task:pending': 'assets/icons/tasks/task.svg',
  'task:finished': 'assets/icons/tasks/task-complete.svg',
  'task:remove': 'assets/icons/tasks/remove-from-task.svg',
  'adaptive-task:active': 'assets/icons/tasks/adaptive-task-active.svg',
  'adaptive-task:pending': 'assets/icons/tasks/adaptive-task.svg',
  'adaptive-task:finished': 'assets/icons/tasks/adaptive-task-complete.svg',
  task: 'assets/icons/tasks/task.svg',
  tasks: 'assets/icons/tasks/task.svg',
  'task:archived': 'assets/icons/tasks/task-archived.svg',
  'task:auto_archived': 'assets/icons/tasks/task-archived.svg',
  assignment: 'assets/icons/tasks/assignment.svg',

  /* bundles */
  bundle: 'assets/icons/bundles/bundles.svg',
  bundles: 'assets/icons/bundles/bundles.svg',
  'bundle:add': 'assets/icons/bundles/add-to-bundle.svg',
  'bundle:remove': 'assets/icons/bundles/remove-from-bundle.svg',

  /* outlined */
  account: 'assets/icons/outlined/account_circle.svg',
  'active-licenses': 'assets/icons/outlined/active_licenses.svg',
  add: 'assets/icons/outlined/add.svg',

  ai: 'assets/icons/outlined/ai.svg',
  archive: 'assets/icons/outlined/archive.svg',
  'arrow-back': 'assets/icons/outlined/arrow_back.svg',
  'arrow-drop-down': 'assets/icons/outlined/arrow_drop_down.svg',
  'arrow-downward': 'assets/icons/outlined/arrow_downward.svg',
  'arrow-next': 'assets/icons/outlined/keyboard_arrow_right.svg',
  'arrow-previous': 'assets/icons/outlined/keyboard_arrow_left.svg',
  assignees: 'assets/icons/outlined/supervised_user_circle.svg',
  book: 'assets/icons/educontent/interactive_whiteboard.svg',
  bookshelf: 'assets/icons/outlined/bookshelf.svg',
  'chevron-right': 'assets/icons/outlined/chevron_right.svg',
  cancel: 'assets/icons/outlined/cancel.svg',
  check: 'assets/icons/outlined/done.svg',
  clear: 'assets/icons/outlined/close.svg',
  close: 'assets/icons/outlined/close.svg',
  'content-copy': 'assets/icons/outlined/content_copy.svg',
  context_menu: 'assets/icons/outlined/more_vert.svg',
  'date-range': 'assets/icons/outlined/date_range.svg',
  download: 'assets/icons/outlined/save_alt.svg',
  delete: 'assets/icons/outlined/delete.svg',
  'delete-sweep': 'assets/icons/outlined/delete_sweep.svg',
  drag: 'assets/icons/outlined/drag_indicator.svg',
  edit: 'assets/icons/outlined/create.svg',
  excel: 'assets/icons/outlined/microsoft_excel.svg',
  exercise: 'assets/icons/educontent/quiz.svg',
  'expand-more': 'assets/icons/outlined/expand_more.svg',
  'general-file': 'assets/icons/educontent/file.svg',
  help: 'assets/icons/outlined/help.svg',
  history: 'assets/icons/outlined/history.svg',
  home: 'assets/icons/outlined/dashboard.svg',
  hourglass: 'assets/icons/outlined/hourglass_bottom.svg',
  favorite: 'assets/icons/outlined/favorite.svg',
  filter: 'assets/icons/outlined/filter.svg',
  'level:verrijking': 'assets/icons/outlined/arm-flex.svg',
  'level:inoefening': 'assets/icons/outlined/autorenew.svg',
  'level:remediëring': 'assets/icons/outlined/repeat_one.svg',
  lock: 'assets/icons/outlined/lock.svg',
  magnifier: 'assets/icons/outlined/search.svg',
  mail: 'assets/icons/outlined/mail.svg',
  menu: 'assets/icons/outlined/menu.svg',
  method: 'assets/icons/outlined/bookshelf.svg',
  methods: 'assets/icons/outlined/bookshelf.svg',
  'more-vert': 'assets/icons/outlined/more_vert.svg',
  notifications: 'assets/icons/outlined/notifications.svg',
  'open-in-new': 'assets/icons/outlined/open_in_new.svg',
  preview: 'assets/icons/outlined/preview.svg',
  print: 'assets/icons/outlined/print.svg',
  profile: 'assets/icons/outlined/account_circle.svg',
  'reset-filters': 'assets/icons/outlined/filter-clear.svg',
  results: 'assets/icons/outlined/bar_chart.svg',
  refresh: 'assets/icons/outlined/autorenew.svg',
  score: 'assets/icons/outlined/trophy.svg',
  search: 'assets/icons/outlined/search.svg',
  'select-all': 'assets/icons/outlined/done_all.svg',
  settings: 'assets/icons/outlined/settings.svg',
  'settings-suggest': 'assets/icons/outlined/settings_suggest.svg',
  'sort:alpha-down': 'assets/icons/outlined/sort_by_alpha.svg',
  'sort:numeric-down': 'assets/icons/outlined/sort_by_number.svg',
  'sort:date': 'assets/icons/outlined/date_range.svg',
  'sort:learning-area': 'assets/icons/outlined/learning_area.svg',
  'star-outline': 'assets/icons/outlined/star_border.svg',
  students: 'assets/icons/outlined/account_circle.svg',
  teacher: 'assets/icons/outlined/teacher.svg',
  share: 'assets/icons/outlined/share.svg',
  schools: 'assets/icons/outlined/schools.svg',
  'shopping-bag': 'assets/icons/outlined/shopping_bag.svg',
  'show-solution': 'assets/icons/outlined/show-solution.svg',
  'swap-vert': 'assets/icons/outlined/swap_vert.svg',
  swap: 'assets/icons/outlined/swap.svg',
  time: 'assets/icons/outlined/time.svg',
  toc: 'assets/icons/outlined/toc.svg',
  'turned-in-not': 'assets/icons/outlined/turned_in_not.svg',
  unarchive: 'assets/icons/outlined/unarchive.svg',
  warning: 'assets/icons/outlined/warning.svg',
  lightbulb: 'assets/icons/outlined/lightbulb.svg',
  newspaper: 'assets/icons/outlined/newspaper.svg',
  'learning-path': 'assets/icons/outlined/learning-path.svg',
  gamepad: 'assets/icons/educontent/gamepad.svg',
  railway: 'assets/icons/outlined/railway.svg',
  tours: 'assets/icons/outlined/tours.svg',
  restart: 'assets/icons/outlined/restart_alt.svg',
  continue: 'assets/icons/outlined/double_arrow.svg',
  'zoom-in': 'assets/icons/outlined/zoom_in.svg',
  'zoom-out': 'assets/icons/outlined/zoom_out.svg',
  'no-result': 'assets/icons/outlined/no-results.svg',
  'priority-high': 'assets/icons/outlined/priority-high.svg',
  'change-circle': 'assets/icons/outlined/change_circle.svg',
  privacy: 'assets/icons/outlined/privacy_tip.svg',
  chat: 'assets/icons/outlined/chat.svg',
  burst_mode: 'assets/icons/outlined/burst_mode.svg',

  /* deprecated */
  'ludo.zip': 'assets/icons/educontent/quiz.svg',

  /* file types */
  file: 'assets/icons/educontent/file.svg',
  'file-type:algemeen bestand': 'assets/icons/educontent/file.svg',
  'file-type:oplossingen': 'assets/icons/outlined/show-solution.svg',
  'file-type:concordantie': 'assets/icons/educontent/concordance.svg',

  'file-type:doelen': 'assets/icons/outlined/goal.svg',
  /** DOC */
  'file-type:document': 'assets/icons/educontent/document.svg',
  'file-type:doc': 'assets/icons/educontent/document.svg',
  'file-type:docx': 'assets/icons/educontent/document.svg',
  'file-type:odt': 'assets/icons/educontent/document.svg',
  'file-type:dotx': 'assets/icons/educontent/document.svg',
  'file-type:txt': 'assets/icons/educontent/document.svg',
  'file-type:rtf': 'assets/icons/educontent/document.svg',
  'file-type:md': 'assets/icons/educontent/document.svg',
  /** SPREADSHEET */
  'file-type:spreadsheet': 'assets/icons/educontent/sheet.svg',
  'file-type:xls': 'assets/icons/educontent/sheet.svg',
  'file-type:xlsx': 'assets/icons/educontent/sheet.svg',
  'file-type:ods': 'assets/icons/educontent/sheet.svg',
  'file-type:xlt': 'assets/icons/educontent/sheet.svg',
  'file-type:xltx': 'assets/icons/educontent/sheet.svg',
  /** PRESENTATION */
  'file-type:presentatie': 'assets/icons/educontent/presentation.svg',
  'file-type:presentation': 'assets/icons/educontent/presentation.svg',
  'file-type:ppt': 'assets/icons/educontent/presentation.svg',
  'file-type:pptx': 'assets/icons/educontent/presentation.svg',
  'file-type:pps': 'assets/icons/educontent/presentation.svg',
  'file-type:ppsx': 'assets/icons/educontent/presentation.svg',
  'file-type:odp': 'assets/icons/educontent/presentation.svg',
  /** IMAGES */
  'file-type:image': 'assets/icons/educontent/image.svg',
  'file-type:jpg': 'assets/icons/educontent/image.svg',
  'file-type:svg': 'assets/icons/educontent/image.svg',
  'file-type:png': 'assets/icons/educontent/image.svg',
  'file-type:gif': 'assets/icons/educontent/image.svg',
  'file-type:jpeg': 'assets/icons/educontent/image.svg',
  'file-type:tga': 'assets/icons/educontent/image.svg',
  'file-type:raw': 'assets/icons/educontent/image.svg',
  'file-type:tiff': 'assets/icons/educontent/image.svg',
  'file-type:bmp': 'assets/icons/educontent/image.svg',
  'file-type:webp': 'assets/icons/educontent/image.svg',
  'file-type:psd': 'assets/icons/educontent/image.svg',
  'file-type:ai': 'assets/icons/educontent/image.svg',
  /** AUDIO */
  'file-type:audio': 'assets/icons/educontent/volume_up.svg',
  'file-type:mp3': 'assets/icons/educontent/volume_up.svg',
  'file-type:wav': 'assets/icons/educontent/volume_up.svg',
  'file-type:ogg': 'assets/icons/educontent/volume_up.svg',
  'file-type:wma': 'assets/icons/educontent/volume_up.svg',
  'file-type:m4a': 'assets/icons/educontent/volume_up.svg',
  'file-type:m4p': 'assets/icons/educontent/volume_up.svg',
  'file-type:flac': 'assets/icons/educontent/volume_up.svg',
  'file-type:aiff': 'assets/icons/educontent/volume_up.svg',
  'file-type:aac': 'assets/icons/educontent/volume_up.svg',
  /** VIDEO */
  'file-type:video': 'assets/icons/educontent/videocam.svg',
  'file-type:m4v': 'assets/icons/educontent/videocam.svg',
  'file-type:mov': 'assets/icons/educontent/videocam.svg',
  'file-type:flv': 'assets/icons/educontent/videocam.svg',
  'file-type:wmv': 'assets/icons/educontent/videocam.svg',
  'file-type:mkv': 'assets/icons/educontent/videocam.svg',
  'file-type:webm': 'assets/icons/educontent/videocam.svg',
  'file-type:vob': 'assets/icons/educontent/videocam.svg',
  'file-type:ogv': 'assets/icons/educontent/videocam.svg',
  'file-type:avi': 'assets/icons/educontent/videocam.svg',
  'file-type:qt': 'assets/icons/educontent/videocam.svg',
  'file-type:mp4': 'assets/icons/educontent/videocam.svg',
  'file-type:asf': 'assets/icons/educontent/videocam.svg',
  'file-type:mpg': 'assets/icons/educontent/videocam.svg',
  'file-type:mpeg': 'assets/icons/educontent/videocam.svg',
  /** WEB */
  'file-type:links': 'assets/icons/educontent/link.svg',
  'file-type:link': 'assets/icons/educontent/link.svg',
  'file-type:lnk': 'assets/icons/educontent/link.svg',
  'file-type:bronmateriaal': 'assets/icons/educontent/link.svg',
  /** PDF */
  'file-type:pdf': 'assets/icons/educontent/pdf.svg',
  'file-type:ps': 'assets/icons/educontent/pdf.svg',
  /** ARCHIVE */
  'file-type:zip': 'assets/icons/educontent/zip.svg',
  'file-type:rar': 'assets/icons/educontent/zip.svg',
  /** SORTING TOOL */
  'file-type:sorting-tool': 'assets/icons/educontent/sorting_tool.svg',
  'file-type:sorteer': 'assets/icons/educontent/sorting_tool.svg',
  'file-type:sorteertool': 'assets/icons/educontent/sorting_tool.svg',
  /** TIMELINE */
  'file-type:timeline': 'assets/icons/educontent/timeline.svg',
  'file-type:tijdslijn': 'assets/icons/educontent/timeline.svg',
  /** GAMES */
  'file-type:exercise': 'assets/icons/educontent/quiz.svg',
  'file-type:ludo.zip': 'assets/icons/educontent/quiz.svg',
  'file-type:game': 'assets/icons/educontent/gamepad.svg',

  /** GGB */
  'file-type:ggb': 'assets/icons/educontent/geogebra.svg',
  'file-type:geogebra': 'assets/icons/educontent/geogebra.svg',

  /** PLATFORMS */
  'file-type:youtube': 'assets/icons/educontent/youtube.svg',
  'file-type:drive': 'assets/icons/educontent/google-drive.svg',
  'file-type:dropbox': 'assets/icons/educontent/dropbox.svg',
  'file-type:google-docs': 'assets/icons/educontent/google-drive.svg',
  'file-type:presenter': 'assets/icons/educontent/presentation.svg',
  'file-type:sharepoint': 'assets/icons/educontent/microsoft-sharepoint.svg',
  'file-type:onedrive': 'assets/icons/educontent/microsoft-onedrive.svg',
  //@TODO (Karl) ICONS FOR SMARTSCHOOL
  'file-type:smartschool': 'assets/icons/educontent/file.svg',
  'file-type:wikipedia': 'assets/icons/educontent/wikipedia.svg',
  'file-type:vimeo': 'assets/icons/educontent/vimeo.svg',
  //@TODO (Karl) ICONS FOR FLICKR
  'file-type:flickr': 'assets/icons/educontent/file.svg',
  //@TODO (Karl) ICONS FOR PREZI
  'file-type:prezi': 'assets/icons/educontent/file.svg',
  //@TODO (Karl) ICONS FOR WEZOOZ
  'file-type:wezooz': 'assets/icons/educontent/file.svg',
  //@TODO (Karl) ICONS FOR WETRANSFER
  'file-type:wetransfer': 'assets/icons/educontent/file.svg',
  'file-type:algebrakit': 'assets/icons/educontent/algebrakit.svg',

  // Based on product types
  'file-type:applicatie': 'assets/icons/educontent/devices.svg',
  'file-type:bijlage': 'assets/icons/educontent/attachment.svg',
  'file-type:bordboek': 'assets/icons/educontent/interactive_whiteboard.svg',
  'file-type:simulatie': 'assets/icons/educontent/gamepad.svg',

  boardbook: 'assets/icons/educontent/interactive_whiteboard.svg',
  'file-type:boek-e': 'assets/icons/educontent/interactive_whiteboard.svg',
  'file-type:boek': 'assets/icons/educontent/interactive_whiteboard.svg',
  'file-type:correctiesleutel': 'assets/icons/educontent/grading.svg',
  'file-type:differentiatie': 'assets/icons/educontent/multiple_stop.svg',
  'file-type:digitale oefening': 'assets/icons/educontent/quiz.svg',
  'file-type:oefeningen': 'assets/icons/educontent/quiz.svg',
  'file-type:evaluatie': 'assets/icons/educontent/rule.svg',
  'file-type:evaluatiemateriaal': 'assets/icons/educontent/rule.svg',
  'file-type:handleiding': 'assets/icons/educontent/book.svg',
  'file-type:kahoot': 'assets/icons/educontent/kahoot.svg',
  'file-type:kopieerblad': 'assets/icons/educontent/file_copy.svg',
  'file-type:kopieerblad google docs': 'assets/icons/educontent/file_copy.svg',
  'file-type:kopieerblad word': 'assets/icons/educontent/file_copy.svg',
  'file-type:kopieerblad pdf': 'assets/icons/educontent/file_copy.svg',
  'file-type:mini-site': 'assets/icons/educontent/web.svg',
  'file-type:oefenblaadje': 'assets/icons/educontent/edit_note.svg',
  'file-type:onthoudboekje': 'assets/icons/educontent/batch_prediction.svg',

  'file-type:paper-exercise': 'assets/icons/educontent/edit_note.svg',
  'file-type:paper': 'assets/icons/educontent/edit_note.svg',

  'file-type:text': 'assets/icons/educontent/text.svg',
  'file-type:tekst': 'assets/icons/educontent/text.svg',
};
