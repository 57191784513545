import { forwardRef, Inject, Injectable } from '@angular/core';
import { ContentInterface, EduContent, EduContentTypeEnum, Result, ResultInterface } from '@campus/dal';
import { StudentTaskWithContentInterface } from '../../../interfaces';
import { ContentActionInterface } from '../content-action.interface';
import {
  ActionsForTaskInstanceEduContentOptionsInterface,
  ContentActionDictionary,
  ContentActionType,
  ContentOpenActionsServiceInterface,
  ContentOpenerInterface,
  CONTENT_OPENER_TOKEN,
} from './content-open-actions.service.interface';
import { DefaultContentOpenerService } from './default-content-opener.service';

@Injectable({
  providedIn: 'root',
})
export class ContentOpenActionsService implements ContentOpenActionsServiceInterface {
  constructor(
    @Inject(forwardRef(() => CONTENT_OPENER_TOKEN))
    private contentOpener: ContentOpenerInterface,
    @Inject(DefaultContentOpenerService)
    private defaultContentOpener: DefaultContentOpenerService
  ) {}

  public contentActionDictionary: ContentActionDictionary = {
    openEduContentAsExercise: {
      label: 'Openen',
      icon: 'open-in-new',
      tooltip: 'Open oefening zonder oplossingen',
      handler: this.getHandler('openEduContentAsExercise'),
      isPrimary: true,
    },
    openEduContentAsSolution: {
      label: 'Oplossing',
      icon: 'show-solution',
      tooltip: 'Open oefening met oplossingen',
      handler: this.getHandler('openEduContentAsSolution'),
    },
    openEduContentAsPreview: {
      label: 'Openen',
      icon: 'open-in-new',
      tooltip: 'Open oefening',
      handler: this.getHandler('openEduContentAsSolution'),
      isPrimary: true,
    },
    openEduContentAsStream: {
      label: 'Openen',
      icon: 'open-in-new',
      tooltip: 'Open het lesmateriaal',
      handler: this.getHandler('openEduContentAsStream'),
      isPrimary: true,
    },
    openEduContentAsDownload: {
      label: 'Openen',
      icon: 'open-in-new',
      tooltip: 'Open het lesmateriaal',
      handler: this.getHandler('openEduContentAsDownload'),
      isPrimary: true,
    },
    openEduContentAsWebApp: {
      label: 'Openen',
      icon: 'open-in-new',
      tooltip: 'Open de web-app',
      handler: this.getHandler('openEduContentAsLink'),
      isPrimary: true,
    },
    openInstructions: {
      label: 'Uitleg bekijken',
      icon: 'open-in-new',
      tooltip: 'Open de uitleg',
      handler: this.getHandler('openInstructions'),
    },
    openEduContentAsLink: {
      label: 'Openen',
      icon: 'open-in-new',
      tooltip: 'Open de link',
      handler: this.getHandler('openEduContentAsLink'),
      isPrimary: true,
    },
    openBoeke: {
      label: 'Openen',
      icon: 'book',
      tooltip: 'Open het bordboek',
      handler: this.getHandler('openBoeke'),
      isPrimary: true,
    },
    openPaperExercise: {
      label: 'Openen',
      icon: 'preview',
      tooltip: 'Open de oefening',
      handler: this.getHandler('openPaperExercise'),
      isPrimary: true,
    },
    openEduContentForReview: {
      label: 'Antwoorden',
      icon: 'preview',
      tooltip: 'Open oefening met antwoorden',
      handler: this.getHandler('openEduContentForReview'),
    },
    openEduContentAsHtml: {
      label: 'Lezen',
      icon: 'preview',
      tooltip: 'Lees de tekst',
      handler: this.getHandler('openEduContentAsHtml'),
      isPrimary: true,
    },
  };

  /**
   * Gets all actions for the provided eduContent
   *
   * @param {ContentInterface} content
   * @returns {ContentActionInterface[]}
   * @memberof ContentOpenActionsService
   */

  getActionsForContent(
    content: ContentInterface,
    result?: Result,
    options: { forReview?: boolean; forDWB?: boolean } = { forReview: false, forDWB: false }
  ): ContentActionInterface[] {
    return this.getContentActions(content, options.forReview);
  }

  getActionForContent(content: ContentInterface, actionType: ContentActionType): ContentActionInterface {
    const actions = this.getContentActions(content);
    const action = actions.find((a) => a === this.contentActionDictionary[actionType]);
    return action;
  }

  getActionsForTaskInstanceEduContent(
    eduContent: EduContent,
    result: Pick<ResultInterface, 'status'>,
    taskInstance: Partial<StudentTaskWithContentInterface>,
    options?: ActionsForTaskInstanceEduContentOptionsInterface
  ): ContentActionInterface[] {
    throw new Error('Method not implemented.');
  }

  private getContentActions(content: ContentInterface, forReview?: boolean): ContentActionInterface[] {
    if (content instanceof EduContent && (content as EduContent).isExercise) {
      return this.getExerciseContentActions({
        forReview,
        withPreview: true,
        previewOnly: content.type !== EduContentTypeEnum.EXERCISE, // is SCORM but not LUDO
      });
    }

    switch (content.type) {
      case EduContentTypeEnum.BOEKE:
        return [this.contentActionDictionary.openBoeke];

      case EduContentTypeEnum.PAPER_EXERCISE:
        return [this.contentActionDictionary.openPaperExercise];

      case EduContentTypeEnum.WHITEBOARD:
      case EduContentTypeEnum.TIMELINE:
      case EduContentTypeEnum.LINK:
        return [this.contentActionDictionary.openEduContentAsLink];
      case EduContentTypeEnum.WEB_APP:
        if (content instanceof EduContent && content.hasInstructions) {
          return [this.contentActionDictionary.openEduContentAsWebApp, this.contentActionDictionary.openInstructions];
        }

        return [this.contentActionDictionary.openEduContentAsWebApp];
      case EduContentTypeEnum.TEXT:
        return [this.contentActionDictionary.openEduContentAsHtml];
    }

    if (content instanceof EduContent && content.streamable) {
      return [this.contentActionDictionary.openEduContentAsStream];
    } else {
      return [this.contentActionDictionary.openEduContentAsDownload];
    }
  }

  private getHandler(name: keyof ContentOpenerInterface) {
    if (this.contentOpener[name]) {
      return this.contentOpener[name].bind(this.contentOpener);
    }

    return this.defaultContentOpener[name]?.bind(this.defaultContentOpener);
  }

  private getExerciseContentActions({
    withPreview,
    previewOnly,
    forReview,
  }: {
    withPreview: boolean;
    previewOnly?: boolean;
    forReview?: boolean;
  }) {
    // forReview has priority over previewOnly
    if (forReview) {
      return [this.contentActionDictionary.openEduContentForReview];
    }

    if (previewOnly) {
      return [this.contentActionDictionary.openEduContentAsPreview];
    }

    const result = [this.contentActionDictionary.openEduContentAsExercise];

    if (withPreview) {
      result.push(this.contentActionDictionary.openEduContentAsSolution);
    }

    return result;
  }
}
