import { InjectionToken } from '@angular/core';
import { PassportUserCredentialInterface, PersonInterface } from '@campus/dal';
import { NavItem } from '@campus/ui';
import { NavigationItemService } from '.';

export const APP_NAVIGATION_TREE_TOKEN = new InjectionToken<NavItem[]>('AppNavigationTreeToken');

export const NAVIGATION_ITEM_SERVICE_TOKEN = new InjectionToken<NavigationItemService>('NavigationItemService');

export interface NavigationItemServiceInterface {
  getNavItemsForTree(
    tree: AppNavTreeKeys,
    userPermissions: string[],
    credentials?: PassportUserCredentialInterface[],
    user?: PersonInterface
  ): NavItem[];
}

export interface AppNavTreeInterface {
  dashboardNav?: NavItem[];
  sideNav: NavItem[];
  settingsNav: NavItem[];
  profileMenuNav: NavItem[];
}

export type AppNavTreeKeys = keyof AppNavTreeInterface;
