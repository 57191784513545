import { Action } from '@ngrx/store';
import { LevelInterface } from '../../+models';

export enum LevelsActionTypes {
  LevelsLoaded = '[Levels] Levels Loaded',
  LevelsLoadError = '[Levels] Load Error',
  LoadLevels = '[Levels] Load Levels',
}

export class LoadLevels implements Action {
  readonly type = LevelsActionTypes.LoadLevels;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class LevelsLoaded implements Action {
  readonly type = LevelsActionTypes.LevelsLoaded;

  constructor(public payload: { levels: LevelInterface[] }) {}
}

export class LevelsLoadError implements Action {
  readonly type = LevelsActionTypes.LevelsLoadError;
  constructor(public payload: any) {}
}

export type LevelsActions = LoadLevels | LevelsLoaded | LevelsLoadError;
