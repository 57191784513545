/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { LoopBackConfig as SDKLoopBackConfig } from '@diekeure/polpo-api-angular-sdk';

export class Config {
  static getAuthPrefix(): string {
    // @ts-expect-error TS2556
    return SDKLoopBackConfig.getAuthPrefix(...arguments);
  }

  static setAuthPrefix(prefix: string): void {
    SDKLoopBackConfig.setAuthPrefix(...arguments);
  }
}
