import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { TaskInstanceQueries } from '.';
import { TaskInstanceServiceInterface, TASK_INSTANCE_SERVICE_TOKEN } from '../../tasks/task-instance.service.interface';
import { DalState } from '../dal.state.interface';
import {
  LoadTaskInstances,
  TaskInstancesActionTypes,
  TaskInstancesLoaded,
  TaskInstancesLoadError,
} from './task-instance.actions';

@Injectable()
export class TaskInstanceEffects {
  loadTaskInstances$ = createEffect(() =>
    this.actions.pipe(
      ofType(TaskInstancesActionTypes.LoadTaskInstances),
      concatLatestFrom(() => this.store.select(TaskInstanceQueries.getLoaded)),
      fetch({
        run: (action: LoadTaskInstances, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.taskInstanceService
            .getAllForUser(action.payload.userId)
            .pipe(map((taskInstances) => new TaskInstancesLoaded({ taskInstances })));
        },
        onError: (action: LoadTaskInstances, error) => {
          return new TaskInstancesLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(TASK_INSTANCE_SERVICE_TOKEN)
    private taskInstanceService: TaskInstanceServiceInterface
  ) {}
}
