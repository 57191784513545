import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const WEB_SOCKET_SERVICE_TOKEN = new InjectionToken<WebSocketServiceInterface<unknown>>('WebSocketService');

export declare type WebSocketMessage = string | ArrayBuffer | Blob | ArrayBufferView;

export interface WebSocketServiceInterface<T> {
  incomingMessages$: Observable<WebSocketMessage | T>;
  configureSocket(url: string);
  sendMessage(message: WebSocketMessage | T);
  close();
}
