import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { PersonProductServiceInterface } from '.';
import { PersonApi, PersonProductApi } from '../+api';
import { PersonProductInterface } from '../+models';

@Injectable({
  providedIn: 'root',
})
export class PersonProductService implements PersonProductServiceInterface {
  constructor(private personApi: PersonApi, private personProductApi: PersonProductApi) {}

  getAllForUser(userId: number): Observable<PersonProductInterface[]> {
    return this.personApi
      .getData(userId, 'personProducts')
      .pipe(map((res: { personProducts: PersonProductInterface[] }) => res.personProducts));
  }

  addPersonProducts(personProducts: PersonProductInterface[]): Observable<PersonProductInterface[]> {
    return this.personProductApi.createPersonProducts(personProducts) as Observable<PersonProductInterface[]>;
  }

  deletePersonProduct(personProductId: number): Observable<boolean> {
    return this.personProductApi.deletePersonProduct(personProductId).pipe(mapTo(true));
  }

  deletePersonProducts(personProductIds: number[]): Observable<boolean> {
    return this.personProductApi.deletePersonProducts(personProductIds).pipe(mapTo(true));
  }

  addPersonProduct(personProduct: PersonProductInterface): Observable<PersonProductInterface> {
    return this.personProductApi.createPersonProduct(personProduct) as Observable<PersonProductInterface>;
  }
}
