import { groupArrayByKey } from '@campus/utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GoalTopicInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './goal-topic.reducer';

export const selectGoalTopicState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectGoalTopicState, (state: State) => state.error);

export const getLoaded = createSelector(selectGoalTopicState, (state: State) => state.loaded);

export const getAll = createSelector(selectGoalTopicState, selectAll);

export const getCount = createSelector(selectGoalTopicState, selectTotal);

export const getIds = createSelector(selectGoalTopicState, selectIds);

export const getAllEntities = createSelector(selectGoalTopicState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * goal$: GoalTopicInterface[] = this.store.pipe(
    select(GoalTopicQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectGoalTopicState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * goal$: GoalTopicInterface = this.store.pipe(
    select(GoalTopicQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectGoalTopicState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getAllByLearningDomain = createSelector(getAll, (goalTopics: GoalTopicInterface[]) => {
  return groupArrayByKey(goalTopics, 'learningDomainId');
});

export const getLinkedEduContentMetadataLoaded = (props: { id: number }) =>
  createSelector(selectGoalTopicState, (state) => {
    return !!state.eduContentsLoaded[props.id];
  });

export const getGoalTopicsLoadedForLearningArea = (props: { learningAreaId: number }) =>
  createSelector(selectGoalTopicState, (state) => {
    return !!state.loadedForLearningArea[props.learningAreaId];
  });

export const isBookLoaded = (props: { bookId: number }) =>
  createSelector(selectGoalTopicState, (state: State) => !!state.loadedForBook[props.bookId]);
