import { InjectionToken } from '@angular/core';
import { TaskInstanceInterface } from '@campus/dal';
import { StudentTaskInterface } from '../../interfaces';

export interface MapperStudentTaskServiceInterface {
  mapToStudentTasks(taskStudentInstances: TaskInstanceInterface[]): StudentTaskInterface[];
  isUrgent(endDate: Date): boolean;
  calculatePercentage(
    completedRequired: number,
    requiredItems: number,
    completedOptional: number,
    optionalItems: number
  ): number;
}

export const MAPPER_STUDENT_TASK_SERVICE_TOKEN = new InjectionToken<MapperStudentTaskServiceInterface>(
  'MapperStudentTaskService'
);
