import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';

@Component({
  selector: 'campus-content-editable',
  templateUrl: './content-editable.component.html',
  styleUrls: ['./content-editable.component.scss'],
})
export class ContentEditableComponent {
  private _active = false;
  private oldText: string;

  @Input() text = '';
  @Input() placeholder = '';
  @Input() required = true; // default true for backward compatibility
  @Input() relatedItem: any;
  @Input() icon?: string;

  get active() {
    return this._active;
  }
  @Input()
  set active(value: boolean) {
    this._active = value;
    if (this._active) {
      this.startEditing();
    } else {
      this.cancelChanges();
    }
  }

  //Optional: input could be added here to add an upper length limit

  @Input() multiline = false;
  @Output() textChanged: EventEmitter<string> = new EventEmitter();

  @ViewChild(MatInput)
  private inputField: MatInput;

  constructor(private cd: ChangeDetectorRef) {}

  startEditing() {
    this.oldText = this.text;

    //We manually detect changes so that the inputField will appear
    //and be ready to receive focus, else it's not ready yet

    this.cd.detectChanges();

    this.inputField.focus();
  }

  saveChanges() {
    if (!this.textIsValid()) {
      return;
    }

    if (this.oldText !== this.text) {
      this.textChanged.emit(this.text.trim());
    }

    this._active = false;
  }

  cancelChanges() {
    this.text = this.oldText;
    this._active = false;
  }

  onFocus($event: any) {
    $event.target.select();
  }

  textIsValid() {
    return !this.required || this.text.trim().length > 0;
  }
}
