import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { UnlockedBoekeGroupQueries } from '.';
import {
  UnlockedBoekeGroupServiceInterface,
  UNLOCKED_BOEKE_GROUP_SERVICE_TOKEN,
} from '../../boeke/unlocked-boeke-group.service.interface';
import { DalState } from '../dal.state.interface';
import {
  LoadUnlockedBoekeGroups,
  UnlockedBoekeGroupsActionTypes,
  UnlockedBoekeGroupsLoaded,
  UnlockedBoekeGroupsLoadError,
} from './unlocked-boeke-group.actions';

@Injectable()
export class UnlockedBoekeGroupsEffects {
  loadUnlockedBoekeGroups$ = createEffect(() =>
    this.actions.pipe(
      ofType(UnlockedBoekeGroupsActionTypes.LoadUnlockedBoekeGroups),
      concatLatestFrom(() => this.store.select(UnlockedBoekeGroupQueries.getLoaded)),
      fetch({
        run: (action: LoadUnlockedBoekeGroups, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.unlockedBoekeGroupService
            .getAllForUser(action.payload.userId)
            .pipe(map((unlockedBoekeGroups) => new UnlockedBoekeGroupsLoaded({ unlockedBoekeGroups })));
        },
        onError: (action: LoadUnlockedBoekeGroups, error) => {
          return new UnlockedBoekeGroupsLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(UNLOCKED_BOEKE_GROUP_SERVICE_TOKEN)
    private unlockedBoekeGroupService: UnlockedBoekeGroupServiceInterface
  ) {}
}
