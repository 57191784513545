import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { UnlockedBoekeStudentQueries } from '.';
import {
  UnlockedBoekeStudentServiceInterface,
  UNLOCKED_BOEKE_STUDENT_SERVICE_TOKEN,
} from '../../boeke/unlocked-boeke-student.service.interface';
import { DalState } from '../dal.state.interface';
import {
  LoadUnlockedBoekeStudents,
  UnlockedBoekeStudentsActionTypes,
  UnlockedBoekeStudentsLoaded,
  UnlockedBoekeStudentsLoadError,
} from './unlocked-boeke-student.actions';

@Injectable()
export class UnlockedBoekeStudentsEffects {
  loadUnlockedBoekeStudents$ = createEffect(() =>
    this.actions.pipe(
      ofType(UnlockedBoekeStudentsActionTypes.LoadUnlockedBoekeStudents),
      concatLatestFrom(() => this.store.select(UnlockedBoekeStudentQueries.getLoaded)),
      fetch({
        run: (action: LoadUnlockedBoekeStudents, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.unlockedBoekeStudentService
            .getAllForUser(action.payload.userId)
            .pipe(map((unlockedBoekeStudents) => new UnlockedBoekeStudentsLoaded({ unlockedBoekeStudents })));
        },
        onError: (action: LoadUnlockedBoekeStudents, error) => {
          return new UnlockedBoekeStudentsLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(UNLOCKED_BOEKE_STUDENT_SERVICE_TOKEN)
    private unlockedBoekeStudentService: UnlockedBoekeStudentServiceInterface
  ) {}
}
