import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './achievement.reducer';

export const selectAchievementState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectAchievementState, (state: State) => state.error);

export const getLoaded = createSelector(selectAchievementState, (state: State) => state.loaded);

export const getAll = createSelector(selectAchievementState, selectAll);

export const getCount = createSelector(selectAchievementState, selectTotal);

export const getIds = createSelector(selectAchievementState, selectIds);

export const getAllEntities = createSelector(selectAchievementState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * achievement$: AchievementInterface[] = this.store.pipe(
    select(AchievementQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectAchievementState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * achievement$: AchievementInterface = this.store.pipe(
    select(AchievementQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectAchievementState,
  (state: State, props: { id: number }) => state.entities[props.id]
);
