import { forwardRef, Inject, Injectable } from '@angular/core';
import { EvaluationStateEnum, Task, TaskStatusEnum, TaskWithAssignees, TaskWithAssigneesInterface } from '@campus/dal';
import { TaskActionInterface } from '../task-action.interface';
import {
  TaskActionDictionary,
  TaskActionsTeacherServiceInterface,
  TaskActionType,
  TeacherTaskOpenerInterface,
  TEACHER_TASK_OPENER_TOKEN,
} from './task-actions-teacher.service.interface';

@Injectable({ providedIn: 'root' })
export class TaskActionsTeacherService implements TaskActionsTeacherServiceInterface {
  constructor(
    @Inject(forwardRef(() => TEACHER_TASK_OPENER_TOKEN))
    private taskOpener: TeacherTaskOpenerInterface
  ) {}

  public taskActionDictionary: TaskActionDictionary = {
    openTask: {
      id: 'openTask',
      label: 'Openen',
      icon: 'open-in-new',
      tooltip: 'Open de taak',
      isPrimary: true,
      handler: this.taskOpener.openTask?.bind(this.taskOpener),
      disabled: false,
    },
    openEvaluationTask: {
      id: 'openEvaluationTask',
      label: 'Openen',
      icon: 'open-in-new',
      tooltip: 'Open de taak',
      isPrimary: true,
      handler: this.taskOpener.openEvaluationTask?.bind(this.taskOpener),
      disabled: false,
    },
    archiveTask: {
      id: 'archiveTask',
      label: 'Archiveren',
      icon: 'archive',
      tooltip: 'Archiveer de taak',
      handler: this.taskOpener.archiveTask?.bind(this.taskOpener),
      disabled: false,
    },
    unarchiveTask: {
      id: 'unarchiveTask',
      label: 'Dearchiveren',
      icon: 'unarchive',
      tooltip: 'De taak niet meer archiveren',
      handler: this.taskOpener.unarchiveTask?.bind(this.taskOpener),
      disabled: false,
    },
    openResultsForTask: {
      id: 'openResultsForTask',
      label: 'Resultaten',
      icon: 'results',
      tooltip: 'Toon de resultaten voor de taak',
      handler: this.taskOpener.openResultsForTask?.bind(this.taskOpener),
      disabled: false,
    },
    openResultsForEvaluationTask: {
      id: 'openResultsForEvaluationTask',
      label: 'Resultaten',
      icon: 'results',
      tooltip: 'Toon de resultaten voor de taak',
      handler: this.taskOpener.openResultsForEvaluationTask?.bind(this.taskOpener),
      disabled: false,
    },
    editTask: {
      id: 'editTask',
      label: 'Bewerken',
      icon: 'edit',
      tooltip: 'Bewerk de taak',
      handler: this.taskOpener.editTask?.bind(this.taskOpener),
      disabled: false,
    },
    openTocForTask: {
      id: 'openTocForTask',
      label: 'Inhoudsopgave',
      icon: 'toc',
      tooltip: 'Toon de inhoudsopgave voor de taak',
      handler: this.taskOpener.openTocForTask?.bind(this.taskOpener),
      disabled: false,
    },
    startEvaluationTask: {
      id: 'startEvaluationTask',
      label: 'Starten',
      icon: 'start',
      tooltip: 'Start de evaluatie',
      handler: this.taskOpener.startEvaluationTask?.bind(this.taskOpener),
      disabled: false,
    },
    stopEvaluationTask: {
      id: 'stopEvaluationTask',
      label: 'Stoppen',
      icon: 'stop',
      tooltip: 'Stop de evaluatie',
      handler: this.taskOpener.stopEvaluationTask?.bind(this.taskOpener),
      disabled: false,
    },
    openWizard: {
      id: 'openWizard',
      label: 'Wizard',
      icon: 'wizard',
      tooltip: 'Open de wizard',
      handler: this.taskOpener.openWizard?.bind(this.taskOpener),
      disabled: false,
    },
  };

  getActions(
    task: TaskWithAssigneesInterface,
    filter?: TaskActionType[],
    actionOrder?: TaskActionType[]
  ): TaskActionInterface[] {
    const actions = this.getTaskActions(task);

    if (!filter && !actionOrder) return actions;

    const filteredActions = actions.filter((action) => !filter || filter.includes(action.id));

    const orderedActions = actionOrder
      ? actionOrder.map((actionType) => filteredActions.find((action) => action.id === actionType))
      : filteredActions;

    return orderedActions.filter(Boolean);
  }

  private getTaskActions(task: TaskWithAssigneesInterface): TaskActionInterface[] {
    const isEvaluationTask = Task.isEvaluationTask(task);
    const taskActions = isEvaluationTask ? this.getEvaluationTaskActions(task) : this.getRegularTaskActions(task);
    const commonTaskActions = [];

    return [...commonTaskActions, ...taskActions];
  }

  private getRegularTaskActions(task: TaskWithAssigneesInterface): TaskActionInterface[] {
    return [
      this.taskActionDictionary.openTask,
      task.archivedYear ? this.taskActionDictionary.unarchiveTask : this.taskActionDictionary.archiveTask,
      ...(this.canOpenResults(task) ? [this.taskActionDictionary.openResultsForTask] : []),
    ];
  }

  private getEvaluationTaskActions(task: TaskWithAssigneesInterface): TaskActionInterface[] {
    const evaluationState = TaskWithAssignees.toTaskWithAssignees(task).evaluationState;
    const taskActions = this.getTaskActionsForEvaluation(evaluationState);

    return taskActions;
  }

  private getTaskActionsForEvaluation(evaluationState: EvaluationStateEnum): TaskActionInterface[] {
    const actionsForState = this.getActionsAndState(evaluationState);
    return [
      this.taskActionDictionary.openEvaluationTask,
      ...Object.entries(actionsForState).map(([action, enabled]) => {
        return { ...this.taskActionDictionary[action], disabled: !enabled };
      }),
    ];
  }

  private getActionsAndState(evaluationState: EvaluationStateEnum): Partial<Record<TaskActionType, boolean>> {
    // returns actions for state and boolean if action is enabled

    switch (evaluationState) {
      case EvaluationStateEnum.EVALUATION_NOT_CONFIGURED:
      case EvaluationStateEnum.EVALUATION_FUTURE:
        return {
          editTask: true,
          openResultsForEvaluationTask: false,
          openTocForTask: true,
          startEvaluationTask: false,
          openWizard: true,
        };

      case EvaluationStateEnum.EVALUATION_NOT_STARTED:
        return {
          editTask: true,
          openResultsForEvaluationTask: false,
          openTocForTask: true,
          startEvaluationTask: true,
          openWizard: true,
        };
      case EvaluationStateEnum.EVALUATION_PAST:
        return {
          editTask: true,
          openResultsForEvaluationTask: false,
          openTocForTask: true,
          startEvaluationTask: false,
        };

      case EvaluationStateEnum.EVALUATION_PAST_FINISHED:
        return {
          openResultsForEvaluationTask: true,
          openTocForTask: true,
          startEvaluationTask: false,
        };
      case EvaluationStateEnum.EVALUATION_STARTED:
        return {
          editTask: false,
          openResultsForEvaluationTask: true,
          openTocForTask: true,
          stopEvaluationTask: true,
        };
      case EvaluationStateEnum.EVALUATION_STOPPED:
        return {
          editTask: true,
          openResultsForEvaluationTask: true,
          openTocForTask: true,
          startEvaluationTask: true,
        };
    }
  }

  private canOpenResults(task: TaskWithAssigneesInterface) {
    const statuses = [TaskStatusEnum.ACTIVE, TaskStatusEnum.FINISHED];
    return statuses.includes(task.status);
  }
}
