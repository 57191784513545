import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { CampaignApi, PersonApi, SchoolApi } from '../+api';
import {
  PersonInterface,
  SchoolAdminInterface,
  SchoolRoleMappingClassGroupInterface,
  SchoolRoleMappingInterface,
  StudentInterface,
  TeacherInterface,
  TeacherStudentInterface,
} from '../+models';
import {
  ImportStudentsResultInterface,
  LinkedPersonServiceInterface,
  SchoolAdminType,
  StudentImportType,
} from './linked-persons.service.interface';

@Injectable({
  providedIn: 'root',
})
export class LinkedPersonService implements LinkedPersonServiceInterface {
  constructor(private personApi: PersonApi, private schoolApi: SchoolApi, private campaignApi: CampaignApi) {}

  //#region Polpo
  getAllForUser(userId: number): Observable<PersonInterface[]> {
    return this.personApi.getData(userId, 'persons').pipe(map((res: { persons: PersonInterface[] }) => res.persons));
  }

  getTeacherStudentsForUser(userId: number): Observable<TeacherStudentInterface[]> {
    return this.personApi
      .getData(userId, 'teacherStudents')
      .pipe(map((res: { teacherStudents: TeacherStudentInterface[] }) => res.teacherStudents));
  }

  // links currentUser to a teacher based on the publicKey
  // returns the newly linked teacher
  linkStudentToTeacher(publicKey: string): Observable<PersonInterface[]> {
    return this.personApi.linkStudentToTeacherRemote(publicKey);
  }

  unlinkStudentFromTeacher(studentId: number, teacherStudentId: number): Observable<boolean> {
    return this.personApi.destroyByIdTeacherStudentByStudent(studentId, teacherStudentId).pipe(mapTo(true));
  }

  unlinkStudents(userId: number, studentIds: number[]): Observable<boolean> {
    return this.personApi.unlinkStudentsFromTeacherRemote(userId, studentIds).pipe(mapTo(true));
  }
  //#endregion

  //#region Kabas
  createStudent(
    schoolId: number,
    student: Partial<StudentInterface> &
      Required<Pick<StudentInterface, 'firstName' | 'name' | 'rootNumber' | 'personalCode'>>,
    classGroupId?: number,
    classNumber?: number
  ): Observable<{
    person: PersonInterface;
    schoolRoleMapping: SchoolRoleMappingInterface;
    schoolRoleMappingClassGroup: SchoolRoleMappingClassGroupInterface;
  }> {
    const leerId = student.leerId || null;

    return this.schoolApi.createStudent(schoolId, {
      ...student,
      leerId,
      classGroupId,
      classNumber,
    }) as Observable<{
      person: PersonInterface;
      schoolRoleMapping: SchoolRoleMappingInterface;
      schoolRoleMappingClassGroup: SchoolRoleMappingClassGroupInterface;
    }>;
  }

  createTeacher(
    schoolId: number,
    teacher: Partial<TeacherInterface> & Required<Pick<TeacherInterface, 'firstName' | 'name' | 'email'>>
  ): Observable<{
    person: PersonInterface;
    schoolRoleMapping: SchoolRoleMappingInterface;
  }> {
    return this.schoolApi.createTeacher(schoolId, teacher) as Observable<{
      person: PersonInterface;
      schoolRoleMapping: SchoolRoleMappingInterface;
    }>;
  }

  createTeacherForCampaign(
    campaign: string,
    teacher: Partial<TeacherInterface> & Required<Pick<TeacherInterface, 'firstName' | 'name' | 'email' | 'password'>>
  ): Observable<string> {
    return this.campaignApi.createTeacher(campaign, teacher) as Observable<string>;
  }

  removeStudentFromSchool(schoolId: number, studentId: number): Observable<any> {
    return this.schoolApi.removeStudent(schoolId, studentId);
  }

  removeStudentsFromSchool(schoolId: number, studentIds: number[]): Observable<any> {
    return this.schoolApi.removeStudents(schoolId, studentIds);
  }

  updateStudent(
    schoolId: number,
    studentId: number,
    student: Partial<StudentInterface>,
    classGroupId?: number,
    classNumber?: number
  ): Observable<{
    person: PersonInterface;
    schoolRoleMapping: SchoolRoleMappingInterface;
    schoolRoleMappingClassGroup?: SchoolRoleMappingClassGroupInterface;
  }> {
    return this.schoolApi.updateStudent(schoolId, studentId, {
      ...student,
      classGroupId,
      classNumber,
    }) as Observable<{
      person: PersonInterface;
      schoolRoleMapping: SchoolRoleMappingInterface;
      schoolRoleMappingClassGroup?: SchoolRoleMappingClassGroupInterface;
    }>;
  }

  importStudents(
    schoolId: number,
    students: StudentImportType[],
    deleteMissingRootNumbers: boolean
  ): Observable<ImportStudentsResultInterface> {
    return this.schoolApi.importStudents(
      schoolId,
      students,
      deleteMissingRootNumbers
    ) as Observable<ImportStudentsResultInterface>;
  }

  updateTeacher(schoolId: number, teacherId: number, teacher: Partial<TeacherInterface>): Observable<PersonInterface> {
    return this.schoolApi.updateTeacher(schoolId, teacherId, teacher) as Observable<PersonInterface>;
  }

  removeTeacherFromSchool(schoolId: number, teacherId: number): Observable<any> {
    return this.schoolApi.removeTeacher(schoolId, teacherId);
  }

  createSchoolAdmin(
    schoolId: number,
    schoolAdmin: Partial<SchoolAdminInterface> & Required<SchoolAdminType>
  ): Observable<{
    person: PersonInterface;
    schoolRoleMapping: SchoolRoleMappingInterface;
  }> {
    return this.schoolApi.createSchoolAdmin(schoolId, schoolAdmin) as Observable<{
      person: PersonInterface;
      schoolRoleMapping: SchoolRoleMappingInterface;
    }>;
  }

  updateSchoolAdmin(
    schoolId: number,
    schoolAdminId: number,
    schoolAdmin: Partial<SchoolAdminInterface>
  ): Observable<PersonInterface> {
    return this.schoolApi.updateSchoolAdmin(schoolId, schoolAdminId, schoolAdmin) as Observable<PersonInterface>;
  }

  removeSchoolAdminFromSchool(schoolId: number, adminId: number): Observable<boolean> {
    return this.schoolApi.removeSchoolAdmin(schoolId, adminId).pipe(mapTo(true));
  }

  linkSchoolAdmin(
    schoolId: number,
    schoolAdminId: number
  ): Observable<{
    person: PersonInterface;
    schoolRoleMapping: SchoolRoleMappingInterface;
  }> {
    return this.schoolApi.linkSchoolAdmin(schoolId, schoolAdminId) as Observable<{
      person: PersonInterface;
      schoolRoleMapping: SchoolRoleMappingInterface;
    }>;
  }

  getLoginLink(person: PersonInterface): Observable<string> {
    return this.personApi
      .loginLink(person.id) // Note: this returns {link:string} and not a string, like the sdk would have you believe
      .pipe(map((res: any) => res.link));
  }
  //#endregion
}
