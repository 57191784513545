import { findManyInArray, findOneInArray, groupArrayByKey } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EduContentBookInterface, MethodInterface, UnlockedFreePracticeInterface } from '../../+models';
import { getAll as getAllBooks } from '../edu-content-book/edu-content-book.selectors';
import { getAllEntities as getMethodDict } from '../method/method.selectors';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './unlocked-free-practice.reducer';

export const selectUnlockedFreePracticeState = createFeatureSelector<State>(NAME);

export const nonDeletedState = createSelector(selectUnlockedFreePracticeState, (state: State): State => {
  const ids = (state.ids as number[]).filter((id) => !state.entities[id].deleted);
  const entities = ids.reduce<Dictionary<UnlockedFreePracticeInterface>>(
    (dict, id) => ((dict[id] = state.entities[id]), dict),
    {}
  );

  return {
    ...state,
    ids,
    entities,
  };
});

export const getError = createSelector(selectUnlockedFreePracticeState, (state: State) => state.error);

export const getLoaded = createSelector(selectUnlockedFreePracticeState, (state: State) => state.loaded);

export const getAllWithDeleted = createSelector(selectUnlockedFreePracticeState, selectAll);

export const getAll = createSelector(nonDeletedState, selectAll);

export const getCount = createSelector(nonDeletedState, selectTotal);

export const getIds = createSelector(nonDeletedState, selectIds);

export const getAllEntities = createSelector(nonDeletedState, selectEntities);

export const getAllEntitiesWithDeleted = createSelector(selectUnlockedFreePracticeState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * unlockedFreePractice$: UnlockedFreePracticeInterface[] = this.store.pipe(
    select(UnlockedFreePracticeQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectUnlockedFreePracticeState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * unlockedFreePractice$: UnlockedFreePracticeInterface = this.store.pipe(
    select(UnlockedFreePracticeQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectUnlockedFreePracticeState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const findOne = createSelector(
  getAll,
  (unlockedFreePractices: UnlockedFreePracticeInterface[], props: Partial<UnlockedFreePracticeInterface>) =>
    findOneInArray(unlockedFreePractices, props, true)
);

export const findMany = createSelector(
  getAll,
  (unlockedFreePractices: UnlockedFreePracticeInterface[], props: Partial<UnlockedFreePracticeInterface> = {}) =>
    findManyInArray(unlockedFreePractices, props)
);

export const getGroupedByEduContentTOCId = createSelector(
  getAll,
  (unlockedFreePractices: UnlockedFreePracticeInterface[]) => groupArrayByKey(unlockedFreePractices, 'eduContentTOCId')
);

export const getGroupedByEduContentBookId = createSelector(
  getAll,
  (unlockedFreePractices: UnlockedFreePracticeInterface[]) => groupArrayByKey(unlockedFreePractices, 'eduContentBookId')
);

export const getWithDeletedGroupedByEduContentBookId = createSelector(
  getAllWithDeleted,
  (unlockedFreePractices: UnlockedFreePracticeInterface[]) => groupArrayByKey(unlockedFreePractices, 'eduContentBookId')
);

export const getWithDeletedByLearningAreaId = createSelector(
  getWithDeletedGroupedByEduContentBookId,
  getAllBooks,
  getMethodDict,
  (
    ufpByBookId: Dictionary<UnlockedFreePracticeInterface[]>,
    books: EduContentBookInterface[],
    methods: Dictionary<MethodInterface>
  ): Dictionary<UnlockedFreePracticeInterface[]> => {
    return books.reduce((acc, book) => {
      const method = methods[book.methodId];
      if (!method || !ufpByBookId.hasOwnProperty(book.id)) {
        return acc;
      }

      if (!acc[method.learningAreaId]) {
        acc[method.learningAreaId] = [];
      }

      acc[method.learningAreaId].push(...ufpByBookId[book.id]);

      return acc;
    }, {});
  }
);
