import { Inject, Injectable } from '@angular/core';
import { BROWSER_STORAGE_SERVICE_TOKEN, StorageServiceInterface } from '@campus/browser';
import { ObjectFunctions } from '@campus/utils';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { MemoizedSelector, MemoizedSelectorWithProps, Store, select } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { RolesEnum } from '../../+models';
import { ActionSuccessful } from '../dal.actions';
import { DalState } from '../dal.state.interface';
import { RouterStateUrl } from '../router/route-serializer';
import { getRouterState } from '../router/router.selectors';
import { TasksActionTypes } from '../task/task.actions';
import { RouteDataSelectorInterface } from './../router/route-serializer';
import {
  LoadUi,
  SaveUi,
  SetBreadcrumbs,
  SetDenseMenu,
  SetDisableGlobalBookFilter,
  SetPageHeaderDetails as SetHeaderDetails,
  SetIsRootComponent,
  SetNavOpen,
  SetTourRoles,
  UiActionTypes,
  UiLoaded,
} from './ui.actions';
import { UiState } from './ui.reducer';
@Injectable()
export class UiEffects {
  loadUi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiActionTypes.LoadUi),
      concatLatestFrom(() => this.store),
      fetch({
        run: (action: LoadUi, state) => {
          if (!action.payload.force && state.ui.loaded) return;

          let data;
          try {
            data = this.storageService.get('ui');
            data = JSON.parse(data);
          } catch (error) {
            //just return the initial state on error
            return new UiLoaded({ state: { ...state.ui, loaded: true } });
          }
          return new UiLoaded({ state: { ...data, loaded: true } });
        },
      })
    )
  );

  localStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiActionTypes.SetListFormat, UiActionTypes.SaveSettings),
      map(() => new SaveUi())
    )
  );

  saveUi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiActionTypes.SaveUi),
      concatLatestFrom(() => this.store),
      fetch({
        run: (action: SaveUi, state) => {
          try {
            const keysToSave: (keyof UiState)[] = ['listFormat', 'savedSettings'];
            const stateToSave = keysToSave.reduce((acc, key) => {
              acc[key] = state.ui[key];
              return acc;
            }, {});

            this.storageService.set('ui', JSON.stringify(stateToSave));
          } catch (error) {
            // we don't want errors on failing localstorage, because it's not breaking
          }
        },
      })
    )
  );

  headerDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      concatLatestFrom(() => this.store),
      fetch({
        run: (action: RouterNavigationAction, state) => {
          const routerState = <RouterStateUrl>(<unknown>action.payload.routerState);
          return this.buildHeaderDetailsForRoute(routerState.routeParts, state);
        },
        onError: (action, error) => {
          console.error('Loading page header details failed -', error);
          return new ActionSuccessful({
            successfulAction: 'page header details failed successfully',
          });
        },
      })
    )
  );

  updateHeaderDetailsAfterTaskUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TasksActionTypes.UpdateTask),
      concatLatestFrom(() => this.store.pipe(select(getRouterState))),
      concatLatestFrom(() => this.store),
      map(([[_action, routerState], state]) => this.buildHeaderDetailsForRoute(routerState.state.routeParts, state))
    )
  );

  breadcrumbs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      concatLatestFrom(() => this.store),
      fetch({
        run: (action: RouterNavigationAction, state) => {
          const routerState = <RouterStateUrl>(<unknown>action.payload.routerState);

          return this.buildBreadCrumbsForRoute(routerState, state);
        },
        onError: (action, error) => {
          console.error('Loading breadcrumbs failed -', error);
          return new ActionSuccessful({
            successfulAction: 'breadcrumbs failed successfully',
          });
        },
      })
    )
  );

  updateBreadCrumbsAfterTaskUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TasksActionTypes.UpdateTask, TasksActionTypes.StopEvaluationTask),
      concatLatestFrom(() => this.store.select(getRouterState)),
      concatLatestFrom(() => this.store),
      map(([[_action, routerState], state]) => this.buildBreadCrumbsForRoute(routerState.state, state))
    )
  );

  tourRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      fetch({
        run: (action: RouterNavigationAction) => {
          const routerState = <RouterStateUrl>(<unknown>action.payload.routerState);

          // walk up the route parts to get the latest tourRoles setting, it's the only one that counts
          const tourRoles = this.findRoutePartDataProperty<RolesEnum[]>(routerState, 'tourRoles') || [];

          return new SetTourRoles({
            tourRoles,
          });
        },
        onError: (action, error) => {
          console.error('Updating tour failed -', error);
          return new ActionSuccessful({
            successfulAction: 'tourRoles failed successfully',
          });
        },
      })
    )
  );

  denseMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      fetch({
        run: (action: RouterNavigationAction) => {
          const routerState = <RouterStateUrl>(<unknown>action.payload.routerState);

          // walk up the route parts to get the latest showNav setting, it's the only one that counts

          const denseMenu = !!this.findRoutePartDataProperty<boolean>(routerState, 'denseMenu');

          return new SetDenseMenu({
            denseMenu,
          });
        },
        onError: (action, error) => {
          console.error('Updating denseMenu failed -', error);
          return new ActionSuccessful({
            successfulAction: 'denseMenu failed successfully',
          });
        },
      })
    )
  );

  disableGlobalBookFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      fetch({
        run: (action: RouterNavigationAction) => {
          const routerState = <RouterStateUrl>(<unknown>action.payload.routerState);

          const disableGlobalBookFilter = !!this.findRoutePartDataProperty<boolean>(
            routerState,
            'disableGlobalBookFilter'
          );

          return new SetDisableGlobalBookFilter({
            disableGlobalBookFilter,
          });
        },
        onError: (action, error) => {
          console.error('Updating disableGlobalBookFilter failed -', error);
          return new ActionSuccessful({
            successfulAction: 'disableGlobalBookFilter failed successfully',
          });
        },
      })
    )
  );

  showNav$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      concatLatestFrom(() => this.store),
      fetch({
        run: (action: RouterNavigationAction, state) => {
          const routerState = <RouterStateUrl>(<unknown>action.payload.routerState);

          // walk up the route parts to get the latest showNav setting, it's the only one that counts
          let open = state.ui.navOpen;
          open = !!this.findRoutePartDataProperty<boolean>(routerState, 'showNav');

          return new SetNavOpen({
            open,
          });
        },
        onError: (action, error) => {
          console.error('Updating showNav failed -', error);
          return new ActionSuccessful({
            successfulAction: 'showNav failed successfully',
          });
        },
      })
    )
  );

  isRootComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      fetch({
        run: (action: RouterNavigationAction) => {
          const routerState = <RouterStateUrl>(<unknown>action.payload.routerState);

          // walk up the route parts to get the latest isRootComponent setting, it's the only one that counts
          const isRootComponent = !!this.findRoutePartDataProperty<boolean>(routerState, 'isRootComponent');

          return new SetIsRootComponent({
            isRootComponent,
          });
        },
        onError: (action, error) => {
          console.error('Updating isRootComponent failed -', error);
          return new ActionSuccessful({
            successfulAction: 'isRootComponent failed successfully',
          });
        },
      })
    )
  );

  private findRoutePartDataProperty<T>(routerState: RouterStateUrl, property: string) {
    let found: T;
    for (let i = routerState.routeParts.length - 1; i >= 0; --i) {
      const routePart = routerState.routeParts[i];
      if (routePart.data[property] !== undefined) {
        found = routePart.data[property];
        break;
      }
    }
    return found;
  }

  private buildBreadCrumbsForRoute(routerState: RouterStateUrl, state: DalState) {
    // routerState contains every 'hop' of the routermodule
    // filtering empty hops
    // building url substrings per hop
    const { routeParts, queryParams } = routerState;
    const filteredRoutes = routeParts.reduce((acc, routePart) => {
      // filter
      if (routePart.url) {
        acc.push({
          ...routePart,
          // build url path
          urlParts: [...(acc[acc.length - 1] ? acc[acc.length - 1].urlParts : []), routePart.url],
        });
      }
      return acc;
    }, [] as RouterStateUrl[]);

    const breadcrumbs = filteredRoutes.reduce((filteredRouteParts, routePart) => {
      const { skip, headerDetails, breadcrumbText, preserveQueryParams, noBreadcrumbLink } = routePart.data;
      if (skip) return filteredRouteParts;

      const displayText = this.getPropertyValue(
        state,
        routePart,
        // sometimes headerDetails is defined, but without the title prop,
        breadcrumbText || (headerDetails && headerDetails.title),
        breadcrumbText as string
      );

      return [
        ...filteredRouteParts,
        {
          displayText,
          link: noBreadcrumbLink ? undefined : routePart.urlParts,
          queryParams: preserveQueryParams && queryParams,
        },
      ];
    }, []);

    return new SetBreadcrumbs({ breadcrumbs });
  }

  private buildHeaderDetailsForRoute(routeParts: RouterStateUrl[], state: DalState) {
    let displayTitle: string;
    let displayIcon: string;
    let navigateTo: string[];
    let displayMessage: string;
    let displayLogo: string;
    let usetifulTag: string;
    let _showPageNavigationIcon: boolean;

    const filterRoutePartsForNavigatingBack = (arr: RouterStateUrl[]) => {
      // only use parts with url/no empty urls
      const noEmptyUrlParts = arr.filter((rp) => rp.url);

      //get rid of last element as we navigate back
      noEmptyUrlParts.pop();

      //get rid of each last elements that should be skipped
      while (noEmptyUrlParts.length && noEmptyUrlParts[noEmptyUrlParts.length - 1].data.skip) {
        noEmptyUrlParts.pop();
      }
      return noEmptyUrlParts;
    };
    if (routeParts) {
      for (let i = routeParts.length; i > 0; --i) {
        const part = routeParts[i - 1];

        const defaultHeaderDetails = {
          navigateBack: undefined,
          title: undefined,
          icon: undefined,
          logo: undefined,
          message: undefined,
          usetifulTag: undefined,
          showPageNavigationIcon: undefined,
        };

        const {
          navigateBack,
          title,
          icon,

          logo,
          message,
          usetifulTag: tag,
          showPageNavigationIcon,
        } = part.data.headerDetails || defaultHeaderDetails;

        if (!usetifulTag && tag) {
          usetifulTag = tag;
        }

        if (!navigateTo) {
          if (Array.isArray(navigateBack)) {
            displayIcon = 'arrow-back';
            navigateTo = navigateBack;
          } else if (navigateBack) {
            displayIcon = 'arrow-back';
            const filteredRouteParts = filterRoutePartsForNavigatingBack(routeParts);

            navigateTo = filteredRouteParts.map((rp) => rp.url);
          }
        }

        if (showPageNavigationIcon !== undefined) {
          _showPageNavigationIcon = showPageNavigationIcon;
        }

        if (title) {
          displayTitle = this.getPropertyValue(state, part, title, part.data.breadcrumbText as string);
          if (!displayIcon && icon) {
            displayIcon = icon;
          }

          if (message) {
            displayMessage = this.getPropertyValue(state, part, message);
          }
          if (logo) {
            displayLogo = this.getPropertyValue(state, part, logo);
          }
          break;
        }
      }
    }
    const headerDetails = {
      title: displayTitle,
      icon: displayIcon,
      navigateTo,
      logo: displayLogo,
      message: displayMessage,
      usetifulTag,
      showPageNavigationIcon: _showPageNavigationIcon,
    };

    return new SetHeaderDetails({ headerDetails });
  }

  getPropertyValue(
    state: DalState,
    routePart: RouterStateUrl,
    property: RouteDataSelectorInterface | string,
    fallback: string = ''
  ) {
    if (!ObjectFunctions.isObject(property)) return property ?? fallback;

    const { selector, selectorParamName, displayProperty, parseData } = property as RouteDataSelectorInterface;
    if (!selector) return fallback;

    // the fallback to routePart.url is for backwards compatibility with existing routes such as :chapter
    const selectorIdValue = routePart.params[selectorParamName || 'id'] || routePart.url;
    // in an effect, so selectors are synchronous
    const value = this.getValueFromSelector(selector, state, selectorIdValue);

    if (displayProperty) return value[displayProperty];
    if (parseData) return parseData(value);

    return value;
  }

  private getValueFromSelector(
    selector: RouteDataSelectorInterface['selector'],
    state: DalState,
    selectorIdValue: any
  ): string {
    const isSelector = selector.hasOwnProperty('projector');
    if (!isSelector) {
      // if it's not a selector, it must be function with props that returns a selector
      return (selector as (props: { id: number }) => MemoizedSelector<any, any>)({ id: selectorIdValue })(state);
    }
    return (selector as MemoizedSelectorWithProps<DalState, any, any>)(state, { id: selectorIdValue });
  }

  constructor(
    private actions$: Actions,

    @Inject(BROWSER_STORAGE_SERVICE_TOKEN)
    private storageService: StorageServiceInterface,
    private store: Store<DalState>
  ) {}
}
