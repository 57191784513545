import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { LearningRecordInterface } from '../+models';

export const LEARNING_RECORD_SERVICE_TOKEN = new InjectionToken<LearningRecordServiceInterface>(
  'LearningRecordService'
);

export interface LearningRecordServiceInterface {
  updateInProgress$: Observable<boolean>;
  registerEventListener(): void;
  saveLearningRecord(learningRecord: LearningRecordInterface): Observable<LearningRecordInterface>;
}

export enum LearningEventVerbEnum {
  EXPERIENCED = 'experienced',
  COMPLETED = 'completed',
  ATTEMPTED = 'attempted',
  ANSWERED = 'answered',
  RATED = 'rated',
  EARNED = 'earned',
}

export interface LearningEventData {
  type: 'LEARNING_EVENT';
  registrationId: number;
  verb: LearningEventVerbEnum;
  payload?: LearningEventDataPayload;
}

export type LearningEventQuestionPayload = Pick<
  LearningRecordInterface,
  'completion' | 'score' | 'response' | 'attempts' | 'attemptScores' | 'hintRequested' | 'duration'
>;

export type LearningEventExercisePayload = Pick<LearningRecordInterface, 'completion' | 'score' | 'duration'>;

export type LearningEventEduContentPayload = Pick<LearningRecordInterface, 'completion' | 'duration'>;

export type LearningEventSurveyPayload = Pick<LearningRecordInterface, 'completion' | 'duration'>;

export type LearningEventSurveyQuestionPayload = Pick<
  LearningRecordInterface,
  'completion' | 'response' | 'score' | 'duration'
>;

export type LearningEventDataPayload =
  | LearningEventQuestionPayload
  | LearningEventExercisePayload
  | LearningEventEduContentPayload
  | LearningEventSurveyPayload
  | LearningEventSurveyQuestionPayload;
