import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LearnerProfileStrandInterface } from '../../+models';
import { LearnerProfileStrandsActions, LearnerProfileStrandsActionTypes } from './learner-profile-strand.actions';

export const NAME = 'learnerProfileStrands';

export interface State extends EntityState<LearnerProfileStrandInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<LearnerProfileStrandInterface> = createEntityAdapter<LearnerProfileStrandInterface>(
  {
    selectId: (learnerProfileStrand) => `${learnerProfileStrand.strandGuid}-${learnerProfileStrand.personId}`,
  }
);

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: LearnerProfileStrandsActions): State {
  switch (action.type) {
    case LearnerProfileStrandsActionTypes.AddLearnerProfileStrand: {
      return adapter.addOne(action.payload.learnerProfileStrand, state);
    }

    case LearnerProfileStrandsActionTypes.UpsertLearnerProfileStrand: {
      return adapter.upsertOne(action.payload.learnerProfileStrand, state);
    }

    case LearnerProfileStrandsActionTypes.AddLearnerProfileStrands: {
      return adapter.addMany(action.payload.learnerProfileStrands, state);
    }

    case LearnerProfileStrandsActionTypes.UpsertLearnerProfileStrands: {
      return adapter.upsertMany(action.payload.learnerProfileStrands, state);
    }

    case LearnerProfileStrandsActionTypes.UpdateLearnerProfileStrand: {
      return adapter.updateOne(action.payload.learnerProfileStrand, state);
    }

    case LearnerProfileStrandsActionTypes.UpdateLearnerProfileStrands: {
      return adapter.updateMany(action.payload.learnerProfileStrands, state);
    }

    case LearnerProfileStrandsActionTypes.DeleteLearnerProfileStrand: {
      return adapter.removeOne(action.payload.id, state);
    }

    case LearnerProfileStrandsActionTypes.DeleteLearnerProfileStrands: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case LearnerProfileStrandsActionTypes.LearnerProfileStrandsLoaded: {
      return adapter.setAll(action.payload.learnerProfileStrands, {
        ...state,
        loaded: true,
      });
    }

    case LearnerProfileStrandsActionTypes.LearnerProfileStrandsLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case LearnerProfileStrandsActionTypes.ClearLearnerProfileStrands: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
