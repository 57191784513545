/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { PurchaseApi as SDKPurchaseApi } from '@diekeure/polpo-api-angular-sdk';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class PurchaseApi extends ModelApiBase {
  private SDKPurchaseApi = inject(SDKPurchaseApi);

  constructor(api: PolpoApi) {
    super(api, 'Purchases');
  }

  getPurchasesForSchoolYear(schoolYear: number) {
    // @ts-expect-error TS 2556
    return this.SDKPurchaseApi.getPurchasesForSchoolYear(...arguments);
  }

  placeOrder(schoolId: number, productIds: number[], customerOrderReference = '', schoolBillingAddress, fullDigital) {
    // @ts-expect-error TS 2556
    return this.SDKPurchaseApi.placeOrder(...arguments);
  }

  finalisePurchases(purchaseIds: number[]) {
    return this.SDKPurchaseApi.finalisePurchases(...arguments);
  }
}
