import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EditorStatusInterface } from '../../+models';
import { EditorStatusesActions, EditorStatusesActionTypes } from './editor-status.actions';

export const NAME = 'editorStatuses';

export interface State extends EntityState<EditorStatusInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<EditorStatusInterface> = createEntityAdapter<EditorStatusInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

export function reducer(state = initialState, action: EditorStatusesActions): State {
  switch (action.type) {
    case EditorStatusesActionTypes.AddEditorStatus: {
      return adapter.addOne(action.payload.editorStatus, state);
    }

    case EditorStatusesActionTypes.UpsertEditorStatus: {
      return adapter.upsertOne(action.payload.editorStatus, state);
    }

    case EditorStatusesActionTypes.AddEditorStatuses: {
      return adapter.addMany(action.payload.editorStatuses, state);
    }

    case EditorStatusesActionTypes.UpsertEditorStatuses: {
      return adapter.upsertMany(action.payload.editorStatuses, state);
    }

    case EditorStatusesActionTypes.UpdateEditorStatus: {
      return adapter.updateOne(action.payload.editorStatus, state);
    }

    case EditorStatusesActionTypes.UpdateEditorStatuses: {
      return adapter.updateMany(action.payload.editorStatuses, state);
    }

    case EditorStatusesActionTypes.DeleteEditorStatus: {
      return adapter.removeOne(action.payload.id, state);
    }

    case EditorStatusesActionTypes.DeleteEditorStatuses: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case EditorStatusesActionTypes.EditorStatusesLoaded: {
      return adapter.setAll(action.payload.editorStatuses, { ...state, loaded: true });
    }

    case EditorStatusesActionTypes.EditorStatusesLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case EditorStatusesActionTypes.ClearEditorStatuses: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
