import { groupArrayByKey, groupArrayByKeys } from '@campus/utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AlgebrakitSessionInterface } from '../../+models';
import { NAME, selectAll, selectEntities, State } from './algebrakit-session.reducer';

export const selectAlgebrakitSessionState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectAlgebrakitSessionState, (state: State) => state.error);

export const getLoaded = createSelector(selectAlgebrakitSessionState, (state: State) => state.loaded);

export const getAll = createSelector(selectAlgebrakitSessionState, selectAll);

export const getAllEntities = createSelector(selectAlgebrakitSessionState, selectEntities);

export const getAlgebrakitSessionIdsForTaskIds = createSelector(
  getAll,
  (algebrakitSessions: AlgebrakitSessionInterface[], props: { taskIds: number[] }) => {
    const taskIdSet = new Set(props.taskIds);

    return algebrakitSessions.filter((session) => taskIdSet.has(session.taskId)).map((session) => session.id);
  }
);

export const getAlgebrakitSessionIdsForBundleIds = createSelector(
  getAll,
  (algebrakitSessions: AlgebrakitSessionInterface[], props: { bundleIds: number[] }) => {
    const bundleIdSet = new Set(props.bundleIds);

    return algebrakitSessions.filter((session) => bundleIdSet.has(session.bundleId)).map((session) => session.id);
  }
);

export const getAlgebrakitSessionsByTaskId = createSelector(
  getAll,
  (algebrakitSessions: AlgebrakitSessionInterface[]) => {
    return groupArrayByKey(algebrakitSessions, 'taskId');
  }
);
export const getAllByEduContentId = createSelector(getAll, (algebrakitSessions: AlgebrakitSessionInterface[]) => {
  return groupArrayByKeys(algebrakitSessions, ['eduContentId']);
});
