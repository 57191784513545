import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { CurriculumTreeConceptInterface } from '../../+models';

export enum CurriculumTreeConceptsActionTypes {
  CurriculumTreeConceptsLoaded = '[CurriculumTreeConcepts] CurriculumTreeConcepts Loaded',
  CurriculumTreeConceptsLoadError = '[CurriculumTreeConcepts] Load Error',
  LoadCurriculumTreeConcepts = '[CurriculumTreeConcepts] Load CurriculumTreeConcepts',
  AddCurriculumTreeConcept = '[CurriculumTreeConcepts] Add CurriculumTreeConcept',
  UpsertCurriculumTreeConcept = '[CurriculumTreeConcepts] Upsert CurriculumTreeConcept',
  AddCurriculumTreeConcepts = '[CurriculumTreeConcepts] Add CurriculumTreeConcepts',
  UpsertCurriculumTreeConcepts = '[CurriculumTreeConcepts] Upsert CurriculumTreeConcepts',
  UpdateCurriculumTreeConcept = '[CurriculumTreeConcepts] Update CurriculumTreeConcept',
  UpdateCurriculumTreeConcepts = '[CurriculumTreeConcepts] Update CurriculumTreeConcepts',
  DeleteCurriculumTreeConcept = '[CurriculumTreeConcepts] Delete CurriculumTreeConcept',
  DeleteCurriculumTreeConcepts = '[CurriculumTreeConcepts] Delete CurriculumTreeConcepts',
  ClearCurriculumTreeConcepts = '[CurriculumTreeConcepts] Clear CurriculumTreeConcepts',
}

export class LoadCurriculumTreeConcepts implements Action {
  readonly type = CurriculumTreeConceptsActionTypes.LoadCurriculumTreeConcepts;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class CurriculumTreeConceptsLoaded implements Action {
  readonly type = CurriculumTreeConceptsActionTypes.CurriculumTreeConceptsLoaded;

  constructor(public payload: { curriculumTreeConcepts: CurriculumTreeConceptInterface[] }) {}
}

export class CurriculumTreeConceptsLoadError implements Action {
  readonly type = CurriculumTreeConceptsActionTypes.CurriculumTreeConceptsLoadError;
  constructor(public payload: any) {}
}

export class AddCurriculumTreeConcept implements Action {
  readonly type = CurriculumTreeConceptsActionTypes.AddCurriculumTreeConcept;

  constructor(public payload: { curriculumTreeConcept: CurriculumTreeConceptInterface }) {}
}

export class UpsertCurriculumTreeConcept implements Action {
  readonly type = CurriculumTreeConceptsActionTypes.UpsertCurriculumTreeConcept;

  constructor(public payload: { curriculumTreeConcept: CurriculumTreeConceptInterface }) {}
}

export class AddCurriculumTreeConcepts implements Action {
  readonly type = CurriculumTreeConceptsActionTypes.AddCurriculumTreeConcepts;

  constructor(public payload: { curriculumTreeConcepts: CurriculumTreeConceptInterface[] }) {}
}

export class UpsertCurriculumTreeConcepts implements Action {
  readonly type = CurriculumTreeConceptsActionTypes.UpsertCurriculumTreeConcepts;

  constructor(public payload: { curriculumTreeConcepts: CurriculumTreeConceptInterface[] }) {}
}

export class UpdateCurriculumTreeConcept implements Action {
  readonly type = CurriculumTreeConceptsActionTypes.UpdateCurriculumTreeConcept;

  constructor(
    public payload: {
      curriculumTreeConcept: Update<CurriculumTreeConceptInterface>;
    }
  ) {}
}

export class UpdateCurriculumTreeConcepts implements Action {
  readonly type = CurriculumTreeConceptsActionTypes.UpdateCurriculumTreeConcepts;

  constructor(
    public payload: {
      curriculumTreeConcepts: Update<CurriculumTreeConceptInterface>[];
    }
  ) {}
}

export class DeleteCurriculumTreeConcept implements Action {
  readonly type = CurriculumTreeConceptsActionTypes.DeleteCurriculumTreeConcept;

  constructor(public payload: { id: number }) {}
}

export class DeleteCurriculumTreeConcepts implements Action {
  readonly type = CurriculumTreeConceptsActionTypes.DeleteCurriculumTreeConcepts;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearCurriculumTreeConcepts implements Action {
  readonly type = CurriculumTreeConceptsActionTypes.ClearCurriculumTreeConcepts;
}

export type CurriculumTreeConceptsActions =
  | LoadCurriculumTreeConcepts
  | CurriculumTreeConceptsLoaded
  | CurriculumTreeConceptsLoadError
  | AddCurriculumTreeConcept
  | UpsertCurriculumTreeConcept
  | AddCurriculumTreeConcepts
  | UpsertCurriculumTreeConcepts
  | UpdateCurriculumTreeConcept
  | UpdateCurriculumTreeConcepts
  | DeleteCurriculumTreeConcept
  | DeleteCurriculumTreeConcepts
  | ClearCurriculumTreeConcepts;
