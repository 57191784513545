import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaluationSubjectGoalInterface, EvaluationSubjectInterface } from '../+models';

export const EVALUATION_SUBJECT_SERVICE_TOKEN = new InjectionToken('EvaluationSubjectService');
export interface UpdateEvaluationSubjectResponseInterface {
  evaluationSubject: EvaluationSubjectInterface;
  evaluationSubjectGoals: EvaluationSubjectGoalInterface[];
}
export interface AddEvaluationSubjectResponseInterface {
  evaluationSubject: EvaluationSubjectInterface;
  evaluationSubjectGoals: EvaluationSubjectGoalInterface[];
}

export interface EvaluationSubjectServiceInterface {
  getEvaluationSubjectsForBook(bookId: number): Observable<EvaluationSubjectInterface[]>;
  addEvaluationSubject(
    evaluationSubject: EvaluationSubjectInterface
  ): Observable<AddEvaluationSubjectResponseInterface>;
  updateEvaluationSubject(
    evaluationSubjectId: number,
    changes: Partial<EvaluationSubjectInterface>
  ): Observable<UpdateEvaluationSubjectResponseInterface>;
  deleteEvaluationSubject(evaluationSubjectId: number): Observable<boolean>;
  getEvaluationSubjects(
    searchTerm?: string,
    filters?: { [key: string]: string | number | number[] },
    pagination?: { from?; amount? },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<{ results: object[]; count: number }>;
}
