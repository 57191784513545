import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DalState, loadAndResolvePermissions, Permissions, UserActions, UserQueries } from '@campus/dal';
import { EnvironmentTermPrivacyInterface, ENVIRONMENT_TERM_PRIVACY_TOKEN } from '@campus/environment';
import { PermissionService } from '@campus/shared';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { RedirectHelper } from './redirect.guard';

@Injectable()
export class TermPrivacyGuard implements CanActivate {
  private permissionService: PermissionService = inject(PermissionService);
  private store: Store<DalState> = inject(Store);
  private environmentTermPrivacy: EnvironmentTermPrivacyInterface =
    inject<EnvironmentTermPrivacyInterface>(ENVIRONMENT_TERM_PRIVACY_TOKEN);
  private router: Router = inject(Router);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    this.store.dispatch(new UserActions.LoadUser({}));

    return this.store.pipe(
      select(UserQueries.getLoaded),
      filter((loaded) => !!loaded),
      loadAndResolvePermissions(this.store, undefined),
      switchMap(() =>
        combineLatest([
          this.store.pipe(select(UserQueries.getCurrentUser)),
          this.permissionService.hasPermission$(Permissions.settings.SHOW_PRIVACY_SETTINGS),
        ])
      ),
      switchMap(([currentUser, hasShowPrivacySettingsPermissions]) => {
        if (hasShowPrivacySettingsPermissions) return of(true);

        const termsUrl = RedirectHelper.getRoute(this.environmentTermPrivacy.url);

        const isNavigatingToTerms = state.url.startsWith(termsUrl);
        if (isNavigatingToTerms) return of(true);

        if (currentUser.terms) return of(true);
        return of(this.router.parseUrl(termsUrl));
      })
    );
  }
}
