/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { TaskEvaluationSubjectScoreApi as SDKTaskEvaluationSubjectScoreApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { TaskEvaluationSubjectScoreInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class TaskEvaluationSubjectScoreApi extends ModelApiBase {
  private SDKTaskEvaluationSubjectScoreApi = inject(SDKTaskEvaluationSubjectScoreApi);

  constructor(api: PolpoApi) {
    super(api, 'TaskEvaluationSubjectScores');
  }

  setTaskEvaluationSubjectScoresForTask(
    taskId: number,
    taskEvaluationSubjectScores: TaskEvaluationSubjectScoreInterface[]
  ): Observable<any> {
    // @ts-expect-error TS 2556
    return this.SDKTaskEvaluationSubjectScoreApi.setTaskEvaluationSubjectScoresForTask(...arguments);
  }
}
