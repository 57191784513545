import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AssigneeInterface,
  AssigneeTypesEnum,
  Bundle,
  BundleInterface,
  ClassGroupInterface,
  GroupInterface,
} from '../../+models';
import { getBundleClassGroupAssigneeByBundle } from '../bundle-class-group/bundle-class-group.selectors';
import { getBundleGroupAssigneeByBundle } from '../bundle-group/bundle-group.selectors';
import { getBundlePersonAssigneeByBundle } from '../bundle-person/bundle-person.selectors';
import { getAllEntities as getClassGroupDict } from '../class-group/class-group.selectors';
import { getAllEntitiesWithStudentIds as getGroupDict } from '../group/group.selectors';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './bundle.reducer';

export const selectBundleState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectBundleState, (state: State) => state.error);

export const getLoaded = createSelector(selectBundleState, (state: State) => state.loaded);

export const getAll = createSelector(selectBundleState, selectAll);

export const getCount = createSelector(selectBundleState, selectTotal);

export const getIds = createSelector(selectBundleState, selectIds);

export const getAllEntities = createSelector(selectBundleState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * bundle$: BundleInterface[] = this.store.pipe(
    select(BundleQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectBundleState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => Bundle.toBundle(state.entities[id]));
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * bundle$: BundleInterface = this.store.pipe(
    select(BundleQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectBundleState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

/**
 * returns an object with bundles grouped by learning area id as key
 * @example
 * bundlesByLearningArea$ = this.store.pipe(select(BundleQueries.getByLearningAreaId))
 */
export const getByLearningAreaId = createSelector(selectBundleState, (state: State) => {
  const byKey: Dictionary<Bundle[]> = {};
  // must cast state.ids to number[] (from 'string[] | number[]') or we can't use array functions like forEach
  const ids: number[] = <number[]>state.ids;
  ids.forEach((id: number) => {
    const item = Bundle.toBundle(state.entities[id]);
    if (!byKey[item.learningAreaId]) {
      byKey[item.learningAreaId] = [];
    }
    byKey[item.learningAreaId].push(item);
  });
  return byKey;
});

export const getShared = createSelector(getAll, (bundles: BundleInterface[]) =>
  bundles.filter((bundle) => bundle.sharedPending).map((bundle) => Bundle.toBundle(bundle))
);

export const getOwn = createSelector(getAll, (bundles: BundleInterface[]) =>
  bundles.filter((bundle) => !bundle.sharedPending).map((bundle) => Bundle.toBundle(bundle))
);

export const getBundleAssigneesByBundleByStudent = createSelector(
  getGroupDict,
  getClassGroupDict,
  getBundleClassGroupAssigneeByBundle,
  getBundleGroupAssigneeByBundle,
  getBundlePersonAssigneeByBundle,
  (
    groups: Dictionary<GroupInterface>,
    classGroups: Dictionary<ClassGroupInterface>,
    bundleClassGroups: Dictionary<AssigneeInterface[]>,
    bundleGroups: Dictionary<AssigneeInterface[]>,
    bundlePersons: Dictionary<AssigneeInterface[]>
  ) => {
    type AssigneesByType = {
      [key in AssigneeTypesEnum]: AssigneeInterface[];
    };

    const dict: Dictionary<Dictionary<AssigneesByType>> = {};

    const studentInBundleAssigneeReducer = (bundleId: string, studentId: number, assignee: AssigneeInterface) => {
      if (!dict[bundleId]) {
        dict[bundleId] = {};
      }

      if (!dict[bundleId][studentId]) {
        dict[bundleId][studentId] = Object.values(AssigneeTypesEnum).reduce(
          (acc, key) => ({
            ...acc,
            [key]: [],
          }),
          {} as AssigneesByType
        );
      }

      dict[bundleId][studentId][assignee.type].push(assignee);
    };

    Object.entries(bundleClassGroups).forEach(([bundleId, bcgArr]) => {
      bcgArr.forEach((bcg) => {
        classGroups[bcg.relationId].studentIds.forEach((studentId) =>
          studentInBundleAssigneeReducer(bundleId, studentId, bcg)
        );
      });
    });

    Object.entries(bundleGroups).forEach(([bundleId, bgArr]) => {
      bgArr.forEach((bg) => {
        groups[bg.relationId].studentIds.forEach((studentId) =>
          studentInBundleAssigneeReducer(bundleId, studentId, bg)
        );
      });
    });

    Object.entries(bundlePersons).forEach(([bundleId, bpArr]) => {
      bpArr.forEach((bp) => studentInBundleAssigneeReducer(bundleId, bp.relationId, bp));
    });

    return dict;
  }
);
