import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { GoalApi } from '../+api';
import { TableApiRequestInterface } from '../+external-interfaces';
import { GoalEvaluationsRowInterface, GoalInterface, GoalStrandsRowInterface } from '../+models';
import { DAL_OPTIONS, DalOptions } from '../dal.module';
import {
  CreateGoalParamsInterface,
  GoalRootInterface,
  GoalServiceInterface,
  GoalsWithLearningPlansByLearningDomainResponseInterface,
  UpdateGoalRelationChangesInterface,
} from './goal.service.interface';

@Injectable({
  providedIn: 'root',
})
export class GoalService implements GoalServiceInterface {
  private http = inject(HttpClient);
  private dalOptions = inject<DalOptions>(DAL_OPTIONS);

  private goalApi: GoalApi = inject(GoalApi);

  createGoal(groupId: number, params?: CreateGoalParamsInterface): Observable<number> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/Goals/v2`;
    return this.http.post<number>(url, { goalGroups: [groupId], ...params }, { withCredentials: true });
  }

  getGoalsForLearningArea(learningAreaId: number): Observable<GoalInterface[]> {
    return this.goalApi.getGoalsForLearningArea(learningAreaId);
  }

  removeGoal(goalId: number) {
    return this.goalApi.deleteGoal(goalId).pipe(mapTo(true));
  }

  upsertGoal(goal: GoalInterface): Observable<GoalInterface> {
    return this.goalApi.upsertGoal(goal) as Observable<GoalInterface>;
  }

  updateDisplayOrders(goals: Pick<GoalInterface, 'id' | 'displayOrder'>[]): Observable<GoalInterface[]> {
    return this.goalApi.updateDisplayOrders(goals) as Observable<GoalInterface[]>;
  }

  getGoals(
    searchTerm?: string,
    filters?: { [key: string]: string | number | number[] },
    pagination?: { from?; amount? },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<{ results: GoalRootInterface[]; count: number }> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/Goals/filtered`;
    const params = new HttpParams({
      fromObject: {
        searchTerm: searchTerm || '',
        filters: JSON.stringify(filters),
        columns: JSON.stringify(columns),
        sorting: JSON.stringify(sorting),
        pagination: JSON.stringify(pagination),
      },
    });

    return this.http.get<{ results: GoalRootInterface[]; count: number }>(url, {
      params,
      withCredentials: true,
    });
  }

  getRelevantGoalsForGoal(
    goalId: number,
    searchTerm?: string,
    filters?: { [key: string]: string | number | number[] },
    pagination?: { from?; amount? },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<{ results: GoalInterface[]; count: number }> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/Goals/${goalId}/relevant/filtered`;
    const params = new HttpParams({
      fromObject: {
        searchTerm: searchTerm || '',
        filters: JSON.stringify(filters || {}),
        columns: JSON.stringify(columns || []),
        sorting: JSON.stringify(sorting || {}),
        pagination: JSON.stringify(pagination || {}),
      },
    });

    return this.http.get<{ results: GoalInterface[]; count: number }>(url, {
      params,
      withCredentials: true,
    });
  }

  getGoal(goalId: number): Observable<GoalInterface> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/Goals/v2/${goalId}`;
    return this.http.get<GoalInterface>(url, {
      withCredentials: true,
    });
  }

  patchGoal(goalId: number, changes?: Partial<GoalInterface>): Observable<number> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/Goals/v2/${goalId}`;
    return this.http.patch<number>(url, changes, { withCredentials: true });
  }

  updateGoalRelation(goalId: number, body: UpdateGoalRelationChangesInterface): Observable<number> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/Goals/v2/${goalId}/relations`;
    return this.http.post<number>(url, body, { withCredentials: true });
  }

  updateGoalRelationMultiple(goals: number[], body: UpdateGoalRelationChangesInterface): Observable<number> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/Goals/v2/relations-multiple`;

    body = { ...body, goals } as any;

    return this.http.post<number>(url, body, { withCredentials: true });
  }

  deleteGoal(goalId: number): Observable<number> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/Goals/${goalId}`;

    return this.http.delete<number>(url, { withCredentials: true });
  }

  getGoalsWithLearningPlansByLearningDomain(
    searchTerm?: string,
    filters?: { [key: string]: string | number[] | number },
    pagination?: { from?; amount? },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<{
    results: GoalsWithLearningPlansByLearningDomainResponseInterface[];
    count: number;
  }> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/Goals/with-learningplan`;
    const params = new HttpParams({
      fromObject: {
        searchTerm: searchTerm || '',
        filters: JSON.stringify(filters || {}),
        sorting: JSON.stringify(sorting || {}),
        pagination: JSON.stringify(pagination || {}),
        columns: JSON.stringify(columns || []),
      },
    });
    return this.http.get<{
      results: GoalsWithLearningPlansByLearningDomainResponseInterface[];
      count: number;
    }>(url, { params, withCredentials: true });
  }

  updateMultiple(goals: Partial<GoalInterface>[]): Observable<number[]> {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/Goals/update-multiple`;
    const body = { goals };

    return this.http.patch<number[]>(url, body, { withCredentials: true });
  }

  getGoalStrands$(goalGroupId: number, options: TableApiRequestInterface) {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/Goals/goal-strands/${goalGroupId}/filtered`;
    const params = new HttpParams({
      fromObject: {
        searchTerm: options.searchTerm || '',
        filters: JSON.stringify(options.filters || {}),
        columns: JSON.stringify(options.columns || []),
        sorting: JSON.stringify(options.sorting || {}),
        pagination: JSON.stringify(options.pagination || {}),
      },
    });

    return this.http.get<{ results: GoalStrandsRowInterface[]; count: number }>(url, {
      params,
      withCredentials: true,
    });
  }

  getGoalEvaluations$(goalGroupId: number, options: TableApiRequestInterface) {
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/Goals/goal-evaluations/${goalGroupId}/filtered`;
    const params = new HttpParams({
      fromObject: {
        searchTerm: options.searchTerm || '',
        filters: JSON.stringify(options.filters || {}),
        columns: JSON.stringify(options.columns || []),
        sorting: JSON.stringify(options.sorting || {}),
        pagination: JSON.stringify(options.pagination || {}),
      },
    });

    return this.http.get<{ results: GoalEvaluationsRowInterface[]; count: number }>(url, {
      params,
      withCredentials: true,
    });
  }
}
