import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { AchievementInterface } from '../../+models';

export enum AchievementsActionTypes {
  AchievementsLoaded = '[Achievements] Achievements Loaded',
  AchievementsLoadError = '[Achievements] Load Error',
  LoadAchievements = '[Achievements] Load Achievements',
  AddAchievement = '[Achievements] Add Achievement',
  UpsertAchievement = '[Achievements] Upsert Achievement',
  AddAchievements = '[Achievements] Add Achievements',
  UpsertAchievements = '[Achievements] Upsert Achievements',
  UpdateAchievement = '[Achievements] Update Achievement',
  UpdateAchievements = '[Achievements] Update Achievements',
  DeleteAchievement = '[Achievements] Delete Achievement',
  DeleteAchievements = '[Achievements] Delete Achievements',
  ClearAchievements = '[Achievements] Clear Achievements',
}

export class LoadAchievements implements Action {
  readonly type = AchievementsActionTypes.LoadAchievements;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class AchievementsLoaded implements Action {
  readonly type = AchievementsActionTypes.AchievementsLoaded;

  constructor(public payload: { achievements: AchievementInterface[] }) {}
}

export class AchievementsLoadError implements Action {
  readonly type = AchievementsActionTypes.AchievementsLoadError;
  constructor(public payload: any) {}
}

export class AddAchievement implements Action {
  readonly type = AchievementsActionTypes.AddAchievement;

  constructor(public payload: { achievement: AchievementInterface }) {}
}

export class UpsertAchievement implements Action {
  readonly type = AchievementsActionTypes.UpsertAchievement;

  constructor(public payload: { achievement: AchievementInterface }) {}
}

export class AddAchievements implements Action {
  readonly type = AchievementsActionTypes.AddAchievements;

  constructor(public payload: { achievements: AchievementInterface[] }) {}
}

export class UpsertAchievements implements Action {
  readonly type = AchievementsActionTypes.UpsertAchievements;

  constructor(public payload: { achievements: AchievementInterface[] }) {}
}

export class UpdateAchievement implements Action {
  readonly type = AchievementsActionTypes.UpdateAchievement;

  constructor(public payload: { achievement: Update<AchievementInterface> }) {}
}

export class UpdateAchievements implements Action {
  readonly type = AchievementsActionTypes.UpdateAchievements;

  constructor(public payload: { achievements: Update<AchievementInterface>[] }) {}
}

export class DeleteAchievement implements Action {
  readonly type = AchievementsActionTypes.DeleteAchievement;

  constructor(public payload: { id: number }) {}
}

export class DeleteAchievements implements Action {
  readonly type = AchievementsActionTypes.DeleteAchievements;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearAchievements implements Action {
  readonly type = AchievementsActionTypes.ClearAchievements;
}

export type AchievementsActions =
  | LoadAchievements
  | AchievementsLoaded
  | AchievementsLoadError
  | AddAchievement
  | UpsertAchievement
  | AddAchievements
  | UpsertAchievements
  | UpdateAchievement
  | UpdateAchievements
  | DeleteAchievement
  | DeleteAchievements
  | ClearAchievements;
