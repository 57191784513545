import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { EduContentSourceInterface } from '../../+models';
import * as EduContentSourceActions from './edu-content-source.actions';

export const NAME = 'eduContentSources';

export interface State extends EntityState<EduContentSourceInterface> {
  // additional entities state properties
  loaded: boolean;
  error?: any;
}

export const adapter: EntityAdapter<EduContentSourceInterface> = createEntityAdapter<EduContentSourceInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
});

const EduContentSourceReducer = createReducer(
  initialState,
  on(EduContentSourceActions.addEduContentSource, (state, action) => adapter.addOne(action.eduContentSource, state)),
  on(EduContentSourceActions.upsertEduContentSource, (state, action) =>
    adapter.upsertOne(action.eduContentSource, state)
  ),
  on(EduContentSourceActions.addEduContentSources, (state, action) => adapter.addMany(action.eduContentSources, state)),
  on(EduContentSourceActions.upsertEduContentSources, (state, action) =>
    adapter.upsertMany(action.eduContentSources, state)
  ),
  on(EduContentSourceActions.updateEduContentSource, (state, action) =>
    adapter.updateOne(action.eduContentSource, state)
  ),
  on(EduContentSourceActions.updateEduContentSources, (state, action) =>
    adapter.updateMany(action.eduContentSources, state)
  ),
  on(EduContentSourceActions.deleteEduContentSource, (state, action) => adapter.removeOne(action.id, state)),
  on(EduContentSourceActions.deleteEduContentSources, (state, action) => adapter.removeMany(action.ids, state)),
  on(EduContentSourceActions.clearEduContentSources, (state) => adapter.removeAll(state)),
  on(EduContentSourceActions.eduContentSourcesLoaded, (state, action) =>
    adapter.setAll(action.eduContentSources, { ...state, loaded: true })
  ),
  on(EduContentSourceActions.eduContentSourcesLoadError, (state, action) => {
    return {
      ...state,
      ...{ error: action.error },
      loaded: false,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return EduContentSourceReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
