import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EduContent, SectionContentInterface, SectionInterface } from '../../+models';
import { getAllEntities as getEduContentDict } from './../edu-content/edu-content.selectors';
import { getBySectionId as getSectionContentsBySectionId } from './../section-content/section-content.selectors';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './section.reducer';

export const selectSectionState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectSectionState, (state: State) => state.error);

export const getLoaded = createSelector(selectSectionState, (state: State) => state.loaded);

export const getAll = createSelector(selectSectionState, selectAll);

export const getCount = createSelector(selectSectionState, selectTotal);

export const getIds = createSelector(selectSectionState, selectIds);

export const getAllEntities = createSelector(selectSectionState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * section$: SectionInterface[] = this.store.pipe(
    select(SectionQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectSectionState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * section$: SectionInterface = this.store.pipe(
    select(SectionQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectSectionState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

/**
 * returns a dictionary of Sections grouped by eduContentTocId
 * @example
 * section$: Dictionary<SectionInterface[]> = this.store.pipe(
     select(SectionQueries.getByEduContentTocId)
   );
 */
export const getByEduContentTocId = createSelector(
  getAll,
  getSectionContentsBySectionId,
  getEduContentDict,
  (
    sections: SectionInterface[],
    sectionContentsBySectionId: Dictionary<SectionContentInterface[]>,
    eduContentDict: Dictionary<EduContent>
  ): Dictionary<SectionInterface[]> => {
    const sectionsByEduContentTocId = groupArrayByKeys(sections, ['eduContentTOCId'], undefined, false, (section) => {
      const sectionContents = sectionContentsBySectionId[section.id] || [];
      const filteredSectionContents = sectionContents.filter((sectionContent) => {
        return eduContentDict[sectionContent.eduContentId]?.publishedEduContentMetadata?.showInDigitalBookTab;
      });

      return {
        ...section,
        sectionContents: filteredSectionContents,
      };
    });

    return sectionsByEduContentTocId;
  }
);

/**
 * returns a dictionary of Sections grouped by eduContentTocId
 * @example
 * section$: Dictionary<SectionInterface[]> = this.store.pipe(
 *  select(SectionQueries.getSectionsByEduContentTocId)
 * );
 **/
export const getSectionsByEduContentTocId = createSelector(
  getAll,
  (sections: SectionInterface[]): Dictionary<SectionInterface[]> => {
    return groupArrayByKeys(sections, ['eduContentTOCId']);
  }
);

export const isBookLoaded = (props: { bookId: number }) =>
  createSelector(selectSectionState, (state: State) => !!state.loadedForBook[props.bookId]);

export const isTocLoaded = (props: { tocId: number }) =>
  createSelector(selectSectionState, (state: State) => !!state.loadedForToc[props.tocId]);
