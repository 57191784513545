import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DalState } from '../dal.state.interface';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { fetch, optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UserContentQueries } from '.';
import { UserContentInterface } from '../../+models';
import { UserContentService } from '../../bundle/user-content.service';
import { LinkUserContent } from '../bundle/bundle.actions';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import {
  AddUserContent,
  LoadUserContents,
  StartAddUserContent,
  UpdateUserContent,
  UserContentsActionTypes,
  UserContentsLoaded,
  UserContentsLoadError,
} from './user-content.actions';

@Injectable()
export class UserContentsEffects {
  loadUserContents$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserContentsActionTypes.LoadUserContents),
      concatLatestFrom(() => this.store.select(UserContentQueries.getLoaded)),
      fetch({
        run: (action: LoadUserContents, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.userContentService
            .getAllForUser(action.payload.userId)
            .pipe(map((userContents) => new UserContentsLoaded({ userContents })));
        },
        onError: (action: LoadUserContents, error) => {
          return new UserContentsLoadError(error);
        },
      })
    )
  );

  startAddUserContent$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserContentsActionTypes.StartAddUserContent),
      pessimisticUpdate({
        run: (action: StartAddUserContent) => {
          const { userId, userContent: uCToCreate, bundleId } = action.payload;
          return this.userContentService.createUserContent(userId, uCToCreate).pipe(
            switchMap((userContent: UserContentInterface) => {
              const linkToBundleAction = new LinkUserContent({ bundleId, userContentIds: [userContent.id] });
              // success feedback is handled by linkToBundleAction

              const actionsToDispatch: Action[] = [new AddUserContent({ userContent }), linkToBundleAction];
              return from(actionsToDispatch);
            })
          );
        },
        onError: (action: StartAddUserContent, error) => {
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            'Het is niet gelukt om eigen lesmateriaal te linken.'
          );

          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback,
          });

          return errorFeedbackAction;
        },
      })
    )
  );

  updateUserContent$ = createEffect(() =>
    this.actions.pipe(
      ofType(UserContentsActionTypes.UpdateUserContent),
      optimisticUpdate({
        run: (action: UpdateUserContent) => {
          return this.userContentService.updateUserContent(action.payload.userContent.changes).pipe(
            map((update) => {
              return new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'Lesmateriaal werd bijgewerkt.'
                ),
              });
            })
          );
        },
        undoAction: (action: UpdateUserContent, error: any) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om het lesmateriaal bij te werken.'
            ),
          });
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    private userContentService: UserContentService,
    @Inject('uuid') private uuid: () => string
  ) {}
}
