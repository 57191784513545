import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { ObjectFunctions } from './object.functions';

export class ObservableFunctions {
  public static distinctUntilSomeKeyChanged<T extends object>() {
    return distinctUntilChanged<T>(ObjectFunctions.deepEquals);
  }

  public static confirm<T>(confirmFn: (s?: T) => Observable<boolean>) {
    return (source$: Observable<T>): Observable<T> =>
      source$.pipe(
        switchMap((s) =>
          confirmFn(s).pipe(
            take(1),
            filter((confirmed) => !!confirmed)
          )
        ),
        withLatestFrom(source$),
        map(([_, source]) => source)
      );
  }
}
