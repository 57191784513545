import { groupArrayByKeys } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EduContentTOCEvaluationInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './edu-content-toc-evaluation.reducer';

export const selectEduContentTocEvaluationState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectEduContentTocEvaluationState, (state: State) => state.error);

export const getIds = createSelector(selectEduContentTocEvaluationState, selectIds);
export const getAll = createSelector(selectEduContentTocEvaluationState, selectAll);

export const getAllEntities = createSelector(selectEduContentTocEvaluationState, selectEntities);

export const getCount = createSelector(selectEduContentTocEvaluationState, selectTotal);

/**
 * returns array of objects in the order of the given ids
 * @example
 * eduContentTocEvaluation$: EduContentTocEvaluationInterface[] = this.store.pipe(
    select(EduContentTocEvaluationQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectEduContentTocEvaluationState, (state: State, props: { ids: string[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns an eduContentTocEvaluation with the given id
 * @example
 * eduContentTocEvaluation$: EduContentTocEvaluationInterface = this.store.pipe(
    select(EduContentTocEvaluationQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectEduContentTocEvaluationState,
  (state: State, props: { id: string }) => state.entities[props.id]
);

export const getAllByTocId = createSelector(
  getAll,
  (eduContentTOCEvaluations: EduContentTOCEvaluationInterface[]) =>
    groupArrayByKeys(eduContentTOCEvaluations, ['eduContentTOCId']) as Dictionary<EduContentTOCEvaluationInterface[]>
);

export const getAllByEvaluationId = createSelector(
  getAll,
  (eduContentTOCEvaluations: EduContentTOCEvaluationInterface[]) =>
    groupArrayByKeys(eduContentTOCEvaluations, ['evaluationId']) as Dictionary<EduContentTOCEvaluationInterface[]>
);

export const isBookLoaded = (props: { bookId: number }) =>
  createSelector(selectEduContentTocEvaluationState, (state: State) => !!state.loadedForBook[props.bookId]);

export const getEduContentTocEvaluationsLoadedForBook = createSelector(
  selectEduContentTocEvaluationState,
  (state: State, props: { bookId: number }) => !!state.loadedForBook[props.bookId]
);
