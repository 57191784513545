import { trigger } from '@angular/animations';
import { Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output } from '@angular/core';
import { LoopModeEnum, slideInOutFromRightToLeftAnimation } from '@campus/ui';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { concatMap, delay, filter, mapTo, repeat, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'campus-result-guide',
  templateUrl: './result-guide.component.html',
  animations: [trigger('slideInOut', slideInOutFromRightToLeftAnimation)],
})
export class ResultGuideComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  defaultClasses = ['block', 'index-surface'];

  @Output()
  clickClose = new EventEmitter<boolean>();

  rememberHide = false;

  LoopModeEnum = LoopModeEnum;

  steps: number[] = [1, 2, 3, 4];
  activeStep = 1;
  studentProgressAnimation$: Observable<{ progress: number; mode: LoopModeEnum; selectorIndex: number }>;
  teacherProgressAnimation$: Observable<{ progress: number; status: string }>;

  public readonly selectorOptionsMap = {
    0: 'heel moeilijk',
    1: 'nog moeilijk',
    2: 'lukt al goed',
    3: 'sterk',
    4: 'heel sterk',
  };
  public readonly selectorOptions = Object.values(this.selectorOptionsMap);

  private studentDestroy$ = new BehaviorSubject<boolean>(false);
  private teacherDestroy$ = new BehaviorSubject<boolean>(false);

  private teacherAnimationData = [
    { progress: 0, duration: 500, status: 'failed' },
    { progress: 10, duration: 500, status: 'failed' },
    { progress: 10, duration: 500, status: 'failed' },
    { progress: 20, duration: 500, status: 'failed' },
    { progress: 30, duration: 500, status: 'failed' },
    { progress: 40, duration: 500, status: 'failed' },
    { progress: 50, duration: 500, status: 'passed' },
    { progress: 60, duration: 500, status: 'passed' },
    { progress: 70, duration: 500, status: 'passed' },
    { progress: 80, duration: 500, status: 'good' },
    { progress: 90, duration: 500, status: 'good' },
    { progress: 100, duration: 500, status: 'good' },
    { progress: 100, duration: 1000, status: 'good' },
  ];
  private studentProgressAnimationData = [
    { progress: 0, mode: LoopModeEnum.MASTER, duration: 500, selectorIndex: 0 },
    { progress: 10, mode: LoopModeEnum.MASTER, duration: 500, selectorIndex: 0 },
    { progress: 10, mode: LoopModeEnum.MASTER, duration: 500, selectorIndex: 0 },
    { progress: 20, mode: LoopModeEnum.MASTER, duration: 500, selectorIndex: 0 },
    { progress: 30, mode: LoopModeEnum.MASTER, duration: 500, selectorIndex: 0 },
    { progress: 40, mode: LoopModeEnum.MASTER, duration: 500, selectorIndex: 0 },
    { progress: 50, mode: LoopModeEnum.MASTER, duration: 500, selectorIndex: 1 },
    { progress: 60, mode: LoopModeEnum.MASTER, duration: 500, selectorIndex: 1 },
    { progress: 70, mode: LoopModeEnum.MASTER, duration: 500, selectorIndex: 1 },
    { progress: 80, mode: LoopModeEnum.MASTER, duration: 500, selectorIndex: 2 },
    { progress: 90, mode: LoopModeEnum.MASTER, duration: 500, selectorIndex: 2 },
    { progress: 100, mode: LoopModeEnum.MASTER, duration: 500, selectorIndex: 2 },
    { progress: 10, mode: LoopModeEnum.EXPERT, duration: 500, selectorIndex: 3 }, //75%
    { progress: 20, mode: LoopModeEnum.EXPERT, duration: 500, selectorIndex: 3 },
    { progress: 30, mode: LoopModeEnum.EXPERT, duration: 500, selectorIndex: 3 },
    { progress: 40, mode: LoopModeEnum.EXPERT, duration: 500, selectorIndex: 3 },
    { progress: 50, mode: LoopModeEnum.EXPERT, duration: 500, selectorIndex: 4 },
    { progress: 60, mode: LoopModeEnum.EXPERT, duration: 500, selectorIndex: 4 },
    { progress: 70, mode: LoopModeEnum.EXPERT, duration: 500, selectorIndex: 4 },
    { progress: 80, mode: LoopModeEnum.EXPERT, duration: 500, selectorIndex: 4 },
    { progress: 90, mode: LoopModeEnum.EXPERT, duration: 500, selectorIndex: 4 },
    { progress: 100, mode: LoopModeEnum.EXPERT, duration: 500, selectorIndex: 4 },
    { progress: 100, mode: LoopModeEnum.EXPERT, duration: 1000, selectorIndex: 4 },
  ];

  ngOnInit() {
    this._checkStepAnimation();
  }

  ngOnDestroy() {
    this.studentDestroy$.next(true);
    this.studentDestroy$.next(true);
    this.teacherDestroy$.unsubscribe();
    this.teacherDestroy$.unsubscribe();
  }

  public nextStep() {
    this.activeStep = this.steps.length === this.activeStep ? this.activeStep : this.activeStep + 1;
    this._checkStepAnimation();
  }
  public previousStep() {
    this.activeStep = this.activeStep - 1 || 1;
    this._checkStepAnimation();
  }

  public goToStep(step: number) {
    this.activeStep = step < 1 ? 1 : step > this.steps.length ? this.steps.length : step;
    this._checkStepAnimation();
  }

  private _checkStepAnimation() {
    if (this.activeStep === 4) {
      this.studentDestroy$.next(false);
      this.teacherDestroy$.next(true);
      this._onStartStudentAnimation();
    } else if ([1, 3].includes(this.activeStep)) {
      this.teacherDestroy$.next(false);
      this.studentDestroy$.next(true);
      this._onStartTeacherAnimation();
    } else {
      this.teacherDestroy$.next(true);
      this.studentDestroy$.next(true);
    }
  }

  private _onStartStudentAnimation() {
    this.studentProgressAnimation$ = from(this.studentProgressAnimationData).pipe(
      takeUntil(this.studentDestroy$.pipe(filter((d) => !!d))),
      concatMap((step) => of(step).pipe(delay(step.duration), mapTo(step))),
      repeat(),
      startWith(this.studentProgressAnimationData[0])
    );
  }
  private _onStartTeacherAnimation() {
    this.teacherProgressAnimation$ = from(this.teacherAnimationData).pipe(
      takeUntil(this.teacherDestroy$.pipe(filter((d) => !!d))),
      concatMap((step) => of(step).pipe(delay(step.duration), mapTo(step))),
      repeat(),
      startWith(this.teacherAnimationData[0])
    );
  }
}
