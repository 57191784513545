import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FILEREADER_SERVICE_TOKEN, FileReaderServiceInterface } from '@campus/browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonApi, SchoolApi } from '../+api';
import { PersonInterface, SchoolInterface, SchoolRoleMappingInterface, SchoolsTableInterface } from '../+models';
import { DAL_OPTIONS, DalOptions } from '../dal.module';
import { SchoolServiceInterface } from './school.service.interface';

@Injectable({
  providedIn: 'root',
})
export class SchoolService implements SchoolServiceInterface {
  constructor(
    @Inject(DAL_OPTIONS) private options: DalOptions,
    @Inject(FILEREADER_SERVICE_TOKEN)
    private fileReaderService: FileReaderServiceInterface,
    private httpClient: HttpClient,
    private personApi: PersonApi,
    private schoolApi: SchoolApi
  ) {}

  printStudentLoginLabels(schoolId: number, studentIds: number[]): Observable<Blob> {
    // SDK TODO
    const endpoint = `/api/Schools/${schoolId}/students/print-login-labels`;

    return this.httpClient
      .post(this.options.apiBaseUrl + endpoint, studentIds, {
        withCredentials: true,
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        map((res: HttpResponse<Blob>) => {
          const blob = res.body;
          this.fileReaderService.download(blob, 'Agendastickers');
          return blob;
        })
      );
  }

  downloadStudentLoginLabelsExcel(
    schoolId: number,
    studentIds: number[],
    includeStudentsWithoutClassGroups: boolean = false
  ): Observable<Blob> {
    // SDK TODO
    const endpoint = `/api/Schools/${schoolId}/students/download-login-excel`;

    return this.httpClient
      .post(this.options.apiBaseUrl + endpoint, studentIds, {
        withCredentials: true,
        responseType: 'blob',
        observe: 'response',
        params: { includeStudentsWithoutClassGroups },
      })
      .pipe(
        map((res: HttpResponse<Blob>) => {
          const blob = res.body;
          this.fileReaderService.download(blob, 'Overzicht Logins.xlsx');
          return blob;
        })
      );
  }

  printStudentLoginLetters(schoolId: number, studentIds: number[]): Observable<boolean> {
    throw new Error('Not yet implemented');
  }

  sendInviteMail(schoolId: number, teacherId: number): Observable<Partial<PersonInterface>> {
    return this.schoolApi.sendInviteMail(schoolId, teacherId);
  }

  sendResetPasswordMail(schoolId: number, teacherId: number): Observable<boolean> {
    throw new Error('Not yet implemented');
  }

  getSchools(
    orderSchoolYear: number,
    searchTerm?: string,
    filters?: { [key: string]: string | number[] | number },
    pagination?: { from?: number; amount?: number },
    columns?: string[],
    sorting?: { [key: string]: 'asc' | 'desc' }
  ): Observable<{ results: SchoolsTableInterface[]; count: number }> {
    const { apiBaseUrl } = this.options;
    const url = `${apiBaseUrl}/api/schools/filtered`;
    const filtersWithSchoolYear = {
      ...filters,
      'SchoolBilling.schoolYear': orderSchoolYear,
    };
    const params = new HttpParams({
      fromObject: {
        searchTerm: searchTerm || '',
        filters: JSON.stringify(filtersWithSchoolYear || {}),
        columns: JSON.stringify(columns || null),
        sorting: JSON.stringify(sorting || {}),
        pagination: JSON.stringify(pagination || {}),
      },
    });

    return this.httpClient.get<{ results: SchoolsTableInterface[]; count: number }>(url, {
      params,
      withCredentials: true,
    });
  }

  createSchool(school: SchoolInterface): Observable<SchoolInterface> {
    return this.schoolApi.createSchool(school);
  }

  removeSchool(schoolId: number): Observable<{
    schoolId: number;
    classGroupIds: number[];
    schoolRoleMappingIds: number[];
    schoolRoleMappingClassGroupIds: number[];
  }> {
    return this.schoolApi.removeSchool(schoolId) as Observable<{
      schoolId: number;
      classGroupIds: number[];
      schoolRoleMappingIds: number[];
      schoolRoleMappingClassGroupIds: number[];
    }>;
  }

  updateSchool(id: number, update: Partial<SchoolInterface>): Observable<SchoolInterface> {
    return this.schoolApi.updateSchool(id, update);
  }

  updateStudentPersonalCode(
    schoolId: number,
    studentId: number,
    personalCode: string
  ): Observable<SchoolRoleMappingInterface> {
    return this.schoolApi
      .updateStudentCode(schoolId, studentId, personalCode)
      .pipe(map((res: { schoolRoleMapping: SchoolRoleMappingInterface }) => res.schoolRoleMapping));
  }

  addStudentCoins(schoolId: number, studentId: number, gameCoinsToAdd: number): Observable<SchoolRoleMappingInterface> {
    return this.schoolApi
      .addStudentCoins(schoolId, studentId, gameCoinsToAdd)
      .pipe(map((res: { schoolRoleMapping: SchoolRoleMappingInterface }) => res.schoolRoleMapping));
  }

  getForZip(zipCode: string): Observable<SchoolInterface[]> {
    return this.schoolApi.getForZip(zipCode);
  }

  linkSchoolsToTeacher(userId, schoolIds: number[]): Observable<SchoolRoleMappingInterface[]> {
    return this.personApi.linkSchools(userId, schoolIds);
  }

  unlinkTeacher(userId: number, schoolRoleMappingId: number): Observable<boolean> {
    return this.personApi.destroyByIdSchoolRoleMapping(userId, schoolRoleMappingId) as any;
  }

  create(school: SchoolInterface): Observable<SchoolInterface> {
    // SDK TODO - use createSchool ?
    return this.schoolApi.create(school);
  }

  updateTransition(schoolId: number, type: 'students' | 'licenses'): Observable<SchoolInterface> {
    return this.schoolApi.updateTransition(schoolId, type);
  }

  unlinkStudentsInSchool(schoolId: number): Observable<SchoolRoleMappingInterface[]> {
    return this.schoolApi.unlinkStudentsInSchool(schoolId) as unknown as Observable<SchoolRoleMappingInterface[]>;
  }
}
