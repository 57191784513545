import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BulkUpdateErrorInterface,
  BulkUpdateResultInfoInterface,
} from '../+external-interfaces/bulk-update-result-info';
import {
  BundleGroupInterface,
  BundleInterface,
  BundlePersonInterface,
  UnlockedContentGroupInterface,
  UnlockedContentInterface,
  UnlockedContentStudentInterface,
} from '../+models';

export const BUNDLE_SERVICE_TOKEN = new InjectionToken<BundleServiceInterface>('BundleService');

export interface ShareBundleApiResponseInterface {
  succeeded: { teacherId: number; origBundleId: number; sharedBundleId: number }[];
  errors: { teacherId: number; origBundleId: number; sharedBundleId: number }[];
}

export interface SetAssigneeDataInterface {
  dateRange: { start: Date; end: Date };
  groupIds: number[];
  studentIds: number[];
}

export interface SetAssigneeResponseInterface {
  bundle: BundleInterface;
  bundleGroups: BundleGroupInterface[];
  bundlePersons: BundlePersonInterface[];
  unlockedContentGroups: UnlockedContentGroupInterface[];
  unlockedContentStudents: UnlockedContentStudentInterface[];
}

export interface UpdateBundleResultInterface
  extends BulkUpdateResultInfoInterface<BundleInterface, BulkUpdateErrorInterface> {}

export interface BundleServiceInterface {
  getAllForUser(userId: number): Observable<BundleInterface[]>;
  linkEduContent(bundleId: number, eduContentIds: number[]): Observable<UnlockedContentInterface[]>;
  linkUserContent(bundleId: number, userContentIds: number[]): Observable<UnlockedContentInterface[]>;
  createBundle(userId: number, bundle: BundleInterface): Observable<BundleInterface>;
  updateBundle(update: Partial<BundleInterface>): Observable<BundleInterface>;
  shareBundle(
    ownerId: number,
    bundleId: number,
    teacherIds: number[],
    message?: string
  ): Observable<ShareBundleApiResponseInterface>;
  setAssignees(bundleId: number, assigneeData: SetAssigneeDataInterface): Observable<SetAssigneeResponseInterface>;
  deleteBundles(bundleIds: number[]): Observable<UpdateBundleResultInterface>;
}
