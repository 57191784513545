import { Inject, Injectable } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Dictionary, Update } from '@ngrx/entity';
import { Action, Store } from '@ngrx/store';
import { fetch, optimisticUpdate, pessimisticUpdate } from '@nrwl/angular';
import { from, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { TaskActions, TaskQueries } from '.';
import { DalActions } from '..';
import { BulkUpdateResultInfoInterface } from '../../+external-interfaces/bulk-update-result-info';
import {
  EduContent,
  EduContentBookInterface,
  HistoryTypesEnum,
  TaskClassGroupInterface,
  TaskEduContentInterface,
  TaskGroupInterface,
  TaskInterface,
  TasksPermissions,
} from '../../+models';
import { AUTH_SERVICE_TOKEN, AuthServiceInterface } from '../../persons';
import {
  CreateEvaluationTaskResponseInterface,
  DeleteEvaluationTasksResultInterface,
  TASK_SERVICE_TOKEN,
  TaskActiveErrorInterface,
  TaskServiceInterface,
  UpdateEvaluationScoreListSubjectResponseInterface,
  UpdateTaskResultInterface,
} from '../../tasks/task.service.interface';
import { DalState } from '../dal.state.interface';
import { LoadData } from '../data/person/person-data.actions';
import { EduContentBookQueries } from '../edu-content-book';
import { EffectFeedback, EffectFeedbackActions, FeedbackTriggeringAction } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import { EvaluationScoreActions } from '../evaluation-score';
import { EvaluationScoreListActions } from '../evaluation-score-list';
import { EvaluationScoreListSubjectActions } from '../evaluation-score-list-subject';
import { HistoryActions } from '../history';
import { ResultActions } from '../result';
import { TaskClassGroupActions } from '../task-class-group';
import { TaskEduContentActions } from '../task-edu-content';
import { TaskGoalYearLevelActions } from '../task-goal-year-level';
import { TaskGroupActions } from '../task-group';
import { TaskStudentActions } from '../task-student';
import { UserQueries } from '../user';
import {
  AddTask,
  DeleteTasks,
  LoadTasks,
  NavigateToAdaptiveTaskWizard,
  NavigateToEvaluationTaskDetail,
  NavigateToTaskDetail,
  NavigateToTasksOverview,
  PrintPaperTaskSolution,
  SetEvaluationSubjectsForTask,
  StartAddEvaluationTask,
  StartAddTask,
  StartAddTaskWithAssignees,
  StartArchiveTasks,
  StartDeleteTasks,
  StartDuplicateTask,
  StartEvaluationTask,
  StopEvaluationTask,
  TaskDuplicated,
  TasksActionTypes,
  TasksLoadError,
  TasksLoaded,
  UpdateAccess,
  UpdateTask,
  UpdateTasks,
} from './task.actions';

@Injectable()
export class TaskEffects {
  loadTasks$ = createEffect(() =>
    this.actions.pipe(
      ofType(TasksActionTypes.LoadTasks),
      concatLatestFrom(() => this.store.select(TaskQueries.getLoaded)),
      fetch({
        run: (action: LoadTasks, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.taskService.getAllForUser(action.payload.userId).pipe(map((tasks) => new TasksLoaded({ tasks })));
        },
        onError: (action: LoadTasks, error) => {
          return new TasksLoadError(error);
        },
      })
    )
  );

  updateAccess$ = createEffect(() =>
    this.actions.pipe(
      ofType(TasksActionTypes.UpdateAccess),
      pessimisticUpdate({
        run: (action: UpdateAccess) => {
          return this.taskService
            .updateAccess(
              action.payload.userId,
              action.payload.taskId,
              action.payload.taskGroups,
              action.payload.taskStudents,
              action.payload.taskClassGroups
            )
            .pipe(
              switchMap((response) =>
                from([
                  TaskGroupActions.updateTaskGroupsAccess({
                    taskId: action.payload.taskId,
                    taskGroups: response.taskGroups,
                  }),
                  TaskClassGroupActions.updateTaskClassGroupsAccess({
                    taskId: action.payload.taskId,
                    taskClassGroups: response.taskClassGroups,
                  }),
                  TaskStudentActions.updateTaskStudentsAccess({
                    taskId: action.payload.taskId,
                    taskStudents: response.taskStudents,
                  }),
                  new EffectFeedbackActions.AddEffectFeedback({
                    effectFeedback: EffectFeedback.generateSuccessFeedback(
                      this.uuid(),
                      action,
                      'De toegang is aangepast.'
                    ),
                  }),
                ])
              )
            );
        },
        onError: (action: UpdateAccess, error: any) => {
          const effectFeedback = EffectFeedback.generateErrorFeedback(
            this.uuid(),
            action,
            'Het is niet gelukt om de taak toe te wijzen.'
          );

          const effectFeedbackAction = new EffectFeedbackActions.AddEffectFeedback({ effectFeedback });
          return effectFeedbackAction;
        },
      })
    )
  );

  startAddTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(TasksActionTypes.StartAddTask),
      pessimisticUpdate({
        run: (action: StartAddTask) => {
          return this.taskService.createTask(action.payload.userId, action.payload.task).pipe(
            switchMap((task: TaskInterface) => {
              const actionsToDispatch: Action[] = [new AddTask({ task })];

              if (action.payload.writeToHistory) {
                actionsToDispatch.push(
                  new HistoryActions.StartUpsertHistory({
                    history: {
                      name: task.name,
                      type: HistoryTypesEnum.TASK,
                      taskId: task.id,
                      created: new Date(),
                      learningAreaId: task.learningAreaId,
                    },
                  })
                );
              }

              if (action.payload.navigateAfterCreate) {
                if (task.isAdaptive) {
                  actionsToDispatch.push(new NavigateToAdaptiveTaskWizard({ task }));
                } else {
                  actionsToDispatch.push(new NavigateToTaskDetail({ task }));
                }
              }

              if (action.payload.assignTo) {
                const assignTo = action.payload.assignTo;
                const taskClassGroups: TaskClassGroupInterface[] = [];
                const taskGroups: TaskGroupInterface[] = [];
                if (assignTo.classGroupId) {
                  taskClassGroups.push(assignTo);
                } else {
                  taskGroups.push(assignTo);
                }
                actionsToDispatch.push(
                  new UpdateAccess({
                    userId: action.payload.userId,
                    taskId: task.id,
                    taskClassGroups,
                    taskStudents: [],
                    taskGroups,
                  })
                );
              }

              if (action.payload.linkEduContent) {
                const { eduContentIds } = action.payload.linkEduContent;
                const taskEduContents: Partial<TaskEduContentInterface>[] = eduContentIds.map((eduContentId) => ({
                  taskId: task.id,
                  eduContentId: eduContentId,
                  index: 0,
                }));
                actionsToDispatch.push(
                  new TaskEduContentActions.StartAddTaskEduContents({
                    userId: action.payload.userId,
                    taskEduContents,
                  })
                );
              }

              return from(actionsToDispatch);
            })
          );
        },
        onError: (action: StartAddTask, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de taak te maken.'
            ),
          });
        },
      })
    )
  );

  startAddTaskWithAssignees$ = createEffect(() =>
    this.actions.pipe(
      ofType(TasksActionTypes.StartAddTaskWithAssignees),
      pessimisticUpdate({
        run: (action: StartAddTaskWithAssignees) => {
          return this.taskService.createTask(action.payload.userId, action.payload.task).pipe(
            switchMap((task: TaskInterface) => {
              const actionsToDispatch: Action[] = [new AddTask({ task })];

              actionsToDispatch.push(
                new UpdateAccess({
                  userId: action.payload.userId,
                  taskId: task.id,
                  taskGroups: action.payload.assignees.taskGroups,
                  taskClassGroups: action.payload.assignees.taskClassGroups,
                  taskStudents: action.payload.assignees.students,
                  customFeedbackHandlers: { useCustomSuccessHandler: 'useNoHandler' },
                })
              );

              if (action.payload.writeToHistory) {
                actionsToDispatch.push(
                  new HistoryActions.StartUpsertHistory({
                    history: {
                      name: task.name,
                      type: HistoryTypesEnum.TASK,
                      taskId: task.id,
                      created: new Date(),
                    },
                  })
                );
              }

              actionsToDispatch.push(new NavigateToTaskDetail({ task }));

              actionsToDispatch.push(
                new EffectFeedbackActions.AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(this.uuid(), action, 'De taak is aangemaakt.'),
                })
              );

              return from(actionsToDispatch);
            })
          );
        },
        onError: (action: StartAddTaskWithAssignees, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de taak te maken.'
            ),
          });
        },
      })
    )
  );

  startAddEvaluationTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(TasksActionTypes.StartAddEvaluationTask),
      pessimisticUpdate({
        run: (action: StartAddEvaluationTask) => {
          const { task: taskData, evaluationId, evaluationSubjectIds, assignTo, navigateAfterCreate } = action.payload;
          const assignees = this.getAssignees(assignTo);

          return this.taskService.createEvaluationTask(taskData, evaluationId, evaluationSubjectIds, assignees).pipe(
            switchMap((taskEvaluationReponse: CreateEvaluationTaskResponseInterface) => {
              const {
                task,
                evaluationScoreList,
                evaluationScoreListSubjects,
                taskEduContents,
                taskClassGroups,
                taskGroups,
              } = taskEvaluationReponse;

              const actionsToDispatch: Action[] = [
                new TaskActions.AddTask({ task }),
                new EvaluationScoreListActions.AddEvaluationScoreList({ evaluationScoreList }),
                new EvaluationScoreListSubjectActions.AddEvaluationScoreListSubjects({
                  evaluationScoreListSubjects,
                }),
                new TaskEduContentActions.AddTaskEduContents({
                  taskEduContents,
                }),
                new LoadData({ userId: this.authService.userId, fields: ['eduContents'], force: true }),
              ];

              if (assignTo) {
                const taskId = task.id;
                if (taskClassGroups) {
                  actionsToDispatch.push(
                    TaskClassGroupActions.updateTaskClassGroupsAccess({ taskId, taskClassGroups })
                  );
                }
                if (taskGroups) {
                  actionsToDispatch.push(TaskGroupActions.updateTaskGroupsAccess({ taskId, taskGroups }));
                }
              }

              if (navigateAfterCreate) {
                if (task.isAdaptive) {
                  // taak-op-maat
                  const step = evaluationScoreList.isDigital ? 'suggestion' : 'scores';
                  actionsToDispatch.push(new NavigateToAdaptiveTaskWizard({ task, step }));
                } else {
                  actionsToDispatch.push(new NavigateToEvaluationTaskDetail({ task }));
                }
              }

              return from(actionsToDispatch);
            })
          );
        },
        onError: (action: StartAddEvaluationTask, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de evaluatie te maken.'
            ),
          });
        },
      })
    )
  );

  deleteEvaluationTasks$ = createEffect(() =>
    this.actions.pipe(
      ofType(TasksActionTypes.StartDeleteEvaluationTasks),
      pessimisticUpdate({
        run: (action: StartDeleteTasks) => {
          return this.taskService.deleteEvaluationTasks(action.payload.ids).pipe(
            switchMap((taskDestroyResult: DeleteEvaluationTasksResultInterface) => {
              const actions = [];
              const { success, errors } = taskDestroyResult;

              // remove the destroyed ones from the store
              if (this.isFilled(success.tasks)) {
                actions.push(
                  new DeleteTasks({
                    ids: success.tasks,
                  }),
                  new TaskEduContentActions.DeleteTaskEduContents({
                    ids: success.taskEduContents,
                  }),
                  TaskClassGroupActions.deleteTaskClassGroups({
                    ids: success.taskClassGroups,
                  }),
                  new ResultActions.DeleteResults({
                    ids: success.results,
                  }),
                  new EvaluationScoreListActions.DeleteEvaluationScoreLists({
                    ids: success.evaluationScoreLists,
                  }),
                  new EvaluationScoreListSubjectActions.DeleteEvaluationScoreListSubjects({
                    ids: success.evaluationScoreListSubjects,
                  }),
                  new EvaluationScoreActions.DeleteEvaluationScores({
                    ids: success.evaluationScores,
                  })
                );

                // show a snackbar if there is no other feedback (i.e. no errors)
                if (!this.isFilled(errors)) {
                  const message = this.getEvaluationTaskSuccessMessage(success.tasks.length, 'delete');
                  actions.push(this.getTaskUpdateFeedbackAction(action, message, 'success'));
                }
              }

              // show feedback for the ones still in use
              if (this.isFilled(errors)) {
                const errorMessage = this.getEvaluationTaskDeleteErrorMessageHTML(taskDestroyResult, 'delete');
                actions.push(this.getTaskUpdateFeedbackAction(action, errorMessage, 'error'));
              }
              return from(actions);
            })
          );
        },
        onError: (action: StartDeleteTasks, error) => {
          return this.getEvaluationTaskUpdateOnErrorFeedbackAction(action, 'delete');
        },
      })
    )
  );

  deleteTasks$ = createEffect(() =>
    this.actions.pipe(
      ofType(TasksActionTypes.StartDeleteTasks),
      pessimisticUpdate({
        run: (action: StartDeleteTasks) => {
          return this.taskService.deleteTasks(action.payload.ids).pipe(
            switchMap((taskDestroyResult: UpdateTaskResultInterface) => {
              const actions = [];
              const { success, errors } = taskDestroyResult;

              // remove the destroyed ones from the store
              if (this.isFilled(success)) {
                actions.push(
                  new DeleteTasks({
                    ids: success.map((task) => task.id),
                  })
                );

                // show a snackbar if there is no other feedback (i.e. no errors)
                if (!this.isFilled(errors)) {
                  const message = this.getTaskUpdateSuccessMessage(success.length, 'delete');
                  actions.push(this.getTaskUpdateFeedbackAction(action, message, 'success'));
                  if (action.payload.navigateAfterDelete) {
                    actions.push(new NavigateToTasksOverview());
                  }
                }
              }

              // show feedback for the ones still in use
              if (this.isFilled(errors)) {
                const errorMessage = this.getTaskUpdateErrorMessageHTML(taskDestroyResult, 'delete');
                actions.push(this.getTaskUpdateFeedbackAction(action, errorMessage, 'error'));
              }
              return from(actions);
            })
          );
        },
        onError: (action: StartDeleteTasks, error) => {
          return this.getTaskUpdateOnErrorFeedbackAction(action, 'delete');
        },
      })
    )
  );

  startDuplicateTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(TasksActionTypes.StartDuplicateTask),
      pessimisticUpdate({
        run: (action: StartDuplicateTask) => {
          return this.taskService.duplicateTask(action.payload.taskId).pipe(
            switchMap((task: TaskInterface) => {
              const { taskEduContents, taskClassGroups, taskGroups, taskStudents, ...taskData } = task;
              const actionsToDispatch: Action[] = [new TaskDuplicated({ task: taskData })];

              if (taskEduContents?.length) {
                actionsToDispatch.push(
                  new TaskEduContentActions.AddTaskEduContents({
                    taskEduContents,
                  })
                );
              }

              if (taskClassGroups?.length) {
                actionsToDispatch.push(
                  TaskClassGroupActions.addTaskClassGroups({
                    taskClassGroups,
                  })
                );
              }

              if (taskGroups?.length) {
                actionsToDispatch.push(
                  TaskGroupActions.addTaskGroups({
                    taskGroups,
                  })
                );
              }

              if (taskStudents?.length) {
                actionsToDispatch.push(
                  TaskStudentActions.addTaskStudents({
                    taskStudents,
                  })
                );
              }

              if (action.payload.writeToHistory) {
                actionsToDispatch.push(
                  new HistoryActions.StartUpsertHistory({
                    history: {
                      name: task.name,
                      type: HistoryTypesEnum.TASK,
                      taskId: task.id,
                      created: new Date(),
                      learningAreaId: task.learningAreaId,
                    },
                  })
                );
              }

              actionsToDispatch.push(
                new EffectFeedbackActions.AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'De taak is gedupliceerd.'
                  ),
                })
              );

              actionsToDispatch.push(new NavigateToTaskDetail({ task, force: true }));

              return from(actionsToDispatch);
            })
          );
        },
        onError: (action: StartDuplicateTask, error) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de taak te dupliceren'
            ),
          });
        },
      })
    )
  );

  redirectToOverview$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(TasksActionTypes.NavigateToTasksOverview),
        tap((action: NavigateToTasksOverview) => {
          this.router.navigate(['tasks', 'manage']);
        })
      ),
    { dispatch: false }
  );

  redirectToTask$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(TasksActionTypes.NavigateToTaskDetail),
        concatLatestFrom(() => [
          this.store.select(UserQueries.getPermissions),
          this.store.select(EduContentBookQueries.getAllEntities),
        ]),
        tap(
          ([action, userPermissions, booksDict]: [
            NavigateToTaskDetail,
            string[],
            Dictionary<EduContentBookInterface>
          ]) => {
            const hasManageTaskContentV2Permission = userPermissions.find(
              (permission) => permission === TasksPermissions.MANAGE_TASK_CONTENT_V2
            );

            if (!!action.payload.force || !hasManageTaskContentV2Permission) {
              this.router.navigate(['tasks', 'manage', action.payload.task.id]);
            } else {
              const bookId = action.payload.task.eduContentBookId;
              const book = booksDict[bookId];
              const tab = book?.hasEvaluations ? 'digital_workbook' : 'practice';
              this.router.navigate(['tasks', 'manage', action.payload.task.id, 'content'], {
                queryParams: { tab, book: action.payload.task.eduContentBookId },
              });
            }
          }
        )
      ),
    { dispatch: false }
  );

  redirectToEvaluationDetail$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(TasksActionTypes.NavigateToEvaluationTaskDetail),
        tap((action: NavigateToEvaluationTaskDetail) => {
          this.router.navigate(['evaluations', action.payload.task.id]);
        })
      ),
    { dispatch: false }
  );

  redirectToWizard$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(TasksActionTypes.NavigateToAdaptiveTaskWizard),
        tap((action: NavigateToAdaptiveTaskWizard) => {
          const { task, step } = action.payload;
          const route = ['tasks', 'manage', task.id, 'wizard', task.adaptiveSource];
          if (step) {
            route.push(step);
          }
          this.router.navigate(route);
        })
      ),
    { dispatch: false }
  );

  updateTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(TasksActionTypes.UpdateTask),
      optimisticUpdate({
        run: (action: UpdateTask) => {
          return this.taskService.updateTasks(action.payload.userId, [action.payload.task.changes]).pipe(
            map((update) => {
              return new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(this.uuid(), action, 'De taak werd bijgewerkt.'),
              });
            })
          );
        },
        undoAction: (action: UpdateTask, error: any) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de taak bij te werken.'
            ),
          });
        },
      })
    )
  );

  startArchiveTasks$ = createEffect(() =>
    this.actions.pipe(
      ofType(TasksActionTypes.StartArchiveTasks),
      pessimisticUpdate({
        run: (action: StartArchiveTasks) => {
          return this.taskService
            .updateTasks(
              action.payload.userId,
              action.payload.tasks.map((updateTask) => {
                return { ...updateTask.changes, id: +updateTask.id };
              })
            )
            .pipe(
              switchMap((taskUpdateInfo: BulkUpdateResultInfoInterface<TaskInterface, TaskActiveErrorInterface>) => {
                const { success, errors } = taskUpdateInfo;
                const actions = [];

                if (this.isFilled(success)) {
                  const partialUpdates = success.reduce(
                    (acc, task) => [...acc, { id: task.id, changes: task }],
                    []
                  ) as Update<TaskInterface>[];

                  actions.push(
                    new UpdateTasks({
                      userId: action.payload.userId,
                      tasks: partialUpdates,
                    })
                  );

                  if (!this.isFilled(errors)) {
                    const message = this.getTaskUpdateSuccessMessage(
                      success.length,
                      this.intentToArchive(action) ? 'archive' : 'dearchive'
                    );
                    actions.push(this.getTaskUpdateFeedbackAction(action, message, 'success'));
                  }
                }

                if (this.isFilled(errors)) {
                  const errorMessage = this.getTaskUpdateErrorMessageHTML(
                    taskUpdateInfo,
                    this.intentToArchive(action) ? 'archive' : 'dearchive'
                  );
                  actions.push(this.getTaskUpdateFeedbackAction(action, errorMessage, 'error'));
                }
                return from(actions);
              })
            );
        },
        onError: (action: StartArchiveTasks, error: any) => {
          return this.getTaskUpdateOnErrorFeedbackAction(
            action,
            this.intentToArchive(action) ? 'archive' : 'dearchive'
          );
        },
      })
    )
  );

  startEvaluationTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(TasksActionTypes.StartEvaluationTask),
      pessimisticUpdate({
        run: (action: StartEvaluationTask) => {
          const { userId, taskId } = action.payload;
          return this.taskService.updateTasks(userId, [{ id: taskId, isOpen: true, wasStarted: true }]).pipe(
            switchMap((update) => {
              const { success, errors } = update;
              const actions = [];

              if (this.isFilled(errors)) {
                throw errors;
              }

              if (this.isFilled(success)) {
                const partialUpdates = success.reduce(
                  (acc, task) => [...acc, { id: task.id, changes: task }],
                  []
                ) as Update<TaskInterface>[];
                actions.push(
                  new UpdateTasks({
                    userId: action.payload.userId,
                    tasks: partialUpdates,
                  })
                );
              }

              return from([
                ...actions,
                new AddEffectFeedback({
                  effectFeedback: EffectFeedback.generateSuccessFeedback(
                    this.uuid(),
                    action,
                    'De evaluatie werd gestart.'
                  ),
                }),
              ]);
            })
          );
        },
        onError: (action: StartEvaluationTask, error: any) => {
          const feedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              `Het is niet gelukt om de evaluatie te starten.`
            ),
          });
          return of(feedbackAction);
        },
      })
    )
  );

  stopEvaluationTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(TasksActionTypes.StopEvaluationTask),
      optimisticUpdate({
        run: (action: StopEvaluationTask) => {
          const { userId, taskId } = action.payload;

          return this.taskService.updateTasks(userId, [{ id: +taskId, isOpen: false }]).pipe(
            map((update) => {
              return new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(
                  this.uuid(),
                  action,
                  'De evaluatie werd gestopt.'
                ),
              });
            })
          );
        },
        undoAction: (action: StopEvaluationTask, error: any) => {
          return new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de evaluatie te stoppen.'
            ),
          });
        },
      })
    )
  );

  printPaperTaskSolution$ = createEffect(() =>
    this.actions.pipe(
      ofType(TasksActionTypes.PrintPaperTaskSolution),
      map((action: PrintPaperTaskSolution) => {
        const { task, size } = action.payload;

        // check for solution files
        const eduContentWithSolutions = [];
        const eduContentWithoutSolutions = [];

        task.taskEduContents.forEach((taskEduContent) => {
          if (taskEduContent.eduContent.type !== 'paper-exercise') {
            return;
          }
          if (taskEduContent.eduContent.hasSolutionFile) {
            eduContentWithSolutions.push(taskEduContent.eduContent);
          } else {
            eduContentWithoutSolutions.push(taskEduContent.eduContent);
          }
        });

        if (!eduContentWithoutSolutions.length || action.payload.force) {
          // no problem
          this.taskService.printSolution(task.id, size);
          return new DalActions.ActionSuccessful({
            successfulAction: TasksActionTypes.PrintPaperTaskSolution,
          });
        } else {
          // show banner
          const effectFeedback = new EffectFeedback({
            id: this.uuid(),
            triggerAction: new PrintPaperTaskSolution({
              task,
              size,
            }),
            message: this.getNoSolutionFileFeedbackMessage(eduContentWithoutSolutions),
            userActions: eduContentWithSolutions.length
              ? [
                  {
                    title: 'Overige afdrukken',
                    userAction: new PrintPaperTaskSolution({
                      task,
                      size,
                      force: true,
                    }),
                  },
                ]
              : [],
            type: 'error',
          });

          const feedbackAction = new EffectFeedbackActions.AddEffectFeedback({
            effectFeedback,
          });

          return feedbackAction;
        }
      })
    )
  );

  setEvaluationSubjectsForTask$ = createEffect(() =>
    this.actions.pipe(
      ofType(TasksActionTypes.SetEvaluationSubjectsForTask),
      concatLatestFrom(() => this.store.select(TaskQueries.getAllEvaluationEntities)),
      pessimisticUpdate({
        run: (action: SetEvaluationSubjectsForTask, evaluationTaskDict: Dictionary<TaskInterface>) => {
          return this.taskService
            .updateEvaluationScoreListSubjects(action.payload.taskId, action.payload.evaluationSubjectIds)
            .pipe(
              switchMap(
                ({
                  taskEduContents,
                  evaluationScoreListSubjects,
                  evaluationScores,
                  taskGoalYearLevels,
                }: UpdateEvaluationScoreListSubjectResponseInterface) => {
                  const { taskId, evaluationScoreListId, evaluationScoreListSubjectIds } = action.payload;
                  const isEvaluation = !!evaluationTaskDict[taskId];

                  const actionsToDispatch = [];

                  if (isEvaluation) {
                    // find out how we normally reload eduContents
                    // ideally this is also returned from the backend, alternatively we add 'eduContents' to LoadTaskData
                    if (taskEduContents.length) {
                      actionsToDispatch.push(
                        new LoadData({ userId: this.authService.userId, fields: ['eduContents'], force: true })
                      );
                    }
                    actionsToDispatch.push(
                      new TaskEduContentActions.SetTaskEduContentsForTask({ taskId, taskEduContents })
                    );
                  } else {
                    actionsToDispatch.push(
                      new EvaluationScoreActions.SetEvaluationScoresForTask({
                        taskId,
                        evaluationScores,
                        evaluationScoreListSubjectIds,
                      }),
                      new TaskGoalYearLevelActions.SetTaskGoalYearLevelsForTask({ taskId, taskGoalYearLevels })
                    );
                  }

                  actionsToDispatch.push(
                    new EvaluationScoreListSubjectActions.SetEvaluationScoreListSubjectsForScoreList({
                      evaluationScoreListId,
                      evaluationScoreListSubjects,
                    }),
                    new AddEffectFeedback({
                      effectFeedback: EffectFeedback.generateSuccessFeedback(
                        this.uuid(),
                        action,
                        'De evaluatieonderwerpen werden aangepast.'
                      ),
                    })
                  );

                  return from(actionsToDispatch);
                }
              )
            );
        },
        onError: (action: SetEvaluationSubjectsForTask, error) => {
          const feedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de evaluatieonderwerpen aan te passen.'
            ),
          });
          return of(feedbackAction);
        },
      })
    )
  );

  private getEvaluationTaskSuccessMessage(tasksLength: number, method: 'archive' | 'dearchive' | 'delete'): string {
    const methodVerbs = {
      archive: 'gearchiveerd',
      dearchive: 'gedearchiveerd',
      delete: 'verwijderd',
    };

    return `De ${tasksLength === 1 ? 'evaluatie werd' : 'evaluaties werden'} ${methodVerbs[method]}.`;
  }

  private getTaskUpdateSuccessMessage(tasksLength: number, method: 'archive' | 'dearchive' | 'delete'): string {
    const methodVerbs = {
      archive: 'gearchiveerd',
      dearchive: 'gedearchiveerd',
      delete: 'verwijderd',
    };

    return `De ${tasksLength === 1 ? 'taak werd' : 'taken werden'} ${methodVerbs[method]}.`;
  }

  private getEvaluationTaskDeleteErrorMessageHTML(
    taskDestroyInfo: DeleteEvaluationTasksResultInterface,
    method: 'archive' | 'dearchive' | 'delete'
  ): string {
    const { success, errors } = taskDestroyInfo;
    const methodVerbs = {
      archive: 'gearchiveerd',
      dearchive: 'gedearchiveerd',
      delete: 'verwijderd',
    };
    const verb = methodVerbs[method];

    const html = [];

    if (!success.tasks.length) {
      html.push(`<p>Er werden geen evaluaties ${verb}.</p>`);
    } else if (success.tasks.length === 1) {
      html.push(`<p>De evaluatie werd ${verb}.</p>`);
    } else {
      html.push(`<p>Er werden ${success.tasks.length} evaluaties ${verb}.</p>`);
    }
    html.push('<p>De volgende evaluaties zijn nog in gebruik:</p>');
    html.push('<ul>');
    html.push(
      ...errors.map((error) => {
        return (
          `<li>de resultaten van evaluatie <strong>${error.task.name}</strong> zijn gebruikt door volgende taken: <ul>` +
          error.relatedTasks.reduce((acc, relatedTask) => (acc += `<li>${relatedTask.name}</li>`), '') +
          `</ul></li>`
        );
      })
    );
    html.push('</ul>');

    return html.join('');
  }

  private getTaskUpdateErrorMessageHTML(
    taskUpdateInfo: UpdateTaskResultInterface,
    method: 'archive' | 'dearchive' | 'delete'
  ) {
    const { success, errors } = taskUpdateInfo;
    const methodVerbs = {
      archive: 'gearchiveerd',
      dearchive: 'gedearchiveerd',
      delete: 'verwijderd',
    };
    const verb = methodVerbs[method];

    const html = [];

    if (!success.length) {
      html.push(`<p>Er werden geen taken ${verb}.</p>`);
    } else if (success.length === 1) {
      html.push(`<p>De taak werd ${verb}.</p>`);
    } else {
      html.push(`<p>Er werden ${success.length} taken ${verb}.</p>`);
    }
    html.push('<p>De volgende taken zijn nog in gebruik:</p>');
    html.push('<ul>');
    html.push(
      ...errors.map(
        (error) =>
          `<li><strong>${error.task}</strong> is nog in gebruik door ${
            error.user
          } tot ${error.activeUntil.toLocaleDateString(this.dateLocale)}.</li>`
      )
    );
    html.push('</ul>');

    return html.join('');
  }

  private getTaskUpdateFeedbackAction(
    action: FeedbackTriggeringAction,
    message: string,
    type: 'error' | 'success'
  ): any {
    const effectFeedback =
      type === 'success'
        ? EffectFeedback.generateSuccessFeedback(this.uuid(), action, message)
        : {
            ...EffectFeedback.generateErrorFeedback(this.uuid(), action, message),
            userActions: [], // don't add a retry button
          };

    return new AddEffectFeedback({
      effectFeedback,
    });
  }

  private getEvaluationTaskUpdateOnErrorFeedbackAction(
    action: FeedbackTriggeringAction,
    method: 'archive' | 'dearchive' | 'delete'
  ) {
    const methodVerbs = {
      archive: 'te archiveren',
      dearchive: 'te dearchiveren',
      delete: 'te verwijderen',
    };

    const feedbackAction = new AddEffectFeedback({
      effectFeedback: EffectFeedback.generateErrorFeedback(
        this.uuid(),
        action,
        `Het is niet gelukt om de evaluaties ${methodVerbs[method]}.`
      ),
    });
    return of(feedbackAction);
  }

  private getTaskUpdateOnErrorFeedbackAction(
    action: FeedbackTriggeringAction,
    method: 'archive' | 'dearchive' | 'delete'
  ) {
    const methodVerbs = {
      archive: 'te archiveren',
      dearchive: 'te dearchiveren',
      delete: 'te verwijderen',
    };

    const feedbackAction = new AddEffectFeedback({
      effectFeedback: EffectFeedback.generateErrorFeedback(
        this.uuid(),
        action,
        `Het is niet gelukt om de taken ${methodVerbs[method]}.`
      ),
    });
    return of(feedbackAction);
  }

  private getNoSolutionFileFeedbackMessage(errors: EduContent[]): string {
    const list = errors.map((error) => {
      return `<li>${error.name}</li>`;
    });

    const message = [`<p>Volgend materiaal heeft geen correctiesleutel:</p>`];
    message.push('<ul>', ...list, '</ul>');
    return message.join('');
  }

  private getAssignees(assignTo: TaskClassGroupInterface & TaskGroupInterface): {
    taskClassGroups: TaskClassGroupInterface[];
    taskGroups: TaskGroupInterface[];
  } {
    if (!assignTo) return;

    if (assignTo.classGroupId) {
      return { taskClassGroups: [assignTo], taskGroups: [] };
    }
    if (assignTo.groupId) {
      return { taskClassGroups: [], taskGroups: [assignTo] };
    }
  }

  /* Small helpers */
  private intentToArchive = (action) => action.payload.tasks.some((task) => task.changes.archived);
  private isFilled = (arr) => Array.isArray(arr) && arr.length;

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    private router: Router,
    @Inject(AUTH_SERVICE_TOKEN) private authService: AuthServiceInterface,
    @Inject(MAT_DATE_LOCALE) private dateLocale,
    @Inject('uuid') private uuid: () => string,
    @Inject(TASK_SERVICE_TOKEN) private taskService: TaskServiceInterface
  ) {}
}
