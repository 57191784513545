import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LearningAreaApi } from '../+api';
import { LearningAreaInterface } from '../+models/LearningArea.interface';
import { LearningAreaServiceInterface } from './learning-area.service.interface';

@Injectable({
  providedIn: 'root',
})
export class LearningAreaService implements LearningAreaServiceInterface {
  constructor(private learningAreaApi: LearningAreaApi) {}

  getAll(): Observable<LearningAreaInterface[]> {
    return this.learningAreaApi.find().pipe(map((learningAreas) => learningAreas.map(this.addIconPrefix)));
  }

  // TODO: remove when DB is updated
  private addIconPrefix(learningArea: LearningAreaInterface): LearningAreaInterface {
    if (learningArea.icon && !learningArea.icon.startsWith('learning-area:')) {
      return { ...learningArea, icon: 'learning-area:' + learningArea.icon };
    }

    return learningArea;
  }
}
