import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EduContentBookInterface } from '../../+models';
import { EduContentBooksActions, EduContentBooksActionTypes } from './edu-content-book.actions';

export const NAME = 'eduContentBooks';

export interface State extends EntityState<EduContentBookInterface> {
  // additional entities state properties
  loaded: boolean;
  loadedForMethod: {
    [methodId: number]: boolean;
  };
  error?: any;
}

export const adapter: EntityAdapter<EduContentBookInterface> = createEntityAdapter<EduContentBookInterface>({
  sortComparer: (a: EduContentBookInterface, b: EduContentBookInterface) =>
    a.title.localeCompare(b.title, undefined, {
      numeric: true,
      sensitivity: 'base',
    }),
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForMethod: {},
});

export function reducer(state = initialState, action: EduContentBooksActions): State {
  switch (action.type) {
    case EduContentBooksActionTypes.EduContentBooksLoaded: {
      return adapter.setAll(action.payload.eduContentBooks, {
        ...state,
        loaded: true,
      });
    }

    case EduContentBooksActionTypes.EduContentBooksLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case EduContentBooksActionTypes.AddEduContentBooksForMethod: {
      const newState = adapter.addMany(action.payload.eduContentBooks, state);

      return {
        ...newState,
        loadedForMethod: {
          ...newState.loadedForMethod,
          [action.payload.methodId]: true,
        },
      };
    }

    case EduContentBooksActionTypes.UpdateEduContentBook: {
      return adapter.updateOne(action.payload.eduContentBook, state);
    }

    case EduContentBooksActionTypes.ClearEduContentBooks: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
