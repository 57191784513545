import { forwardRef, Inject, Injectable } from '@angular/core';
import { StudentTaskInterface } from '../../../interfaces';
import { TaskActionInterface } from '../task-action.interface';
import { TaskActionDictionary } from '../teacher/task-actions-teacher.service.interface';
import {
  StudentTaskOpenerInterface,
  STUDENT_TASK_OPENER_TOKEN,
  TaskActionsStudentServiceInterface,
} from './task-actions-student.service.interface';

@Injectable({ providedIn: 'root' })
export class TaskActionsStudentService implements TaskActionsStudentServiceInterface {
  constructor(
    @Inject(forwardRef(() => STUDENT_TASK_OPENER_TOKEN))
    private taskOpener: StudentTaskOpenerInterface
  ) {}

  public taskActionDictionary: TaskActionDictionary = {
    openTask: {
      id: 'openTask',
      label: 'Openen',
      icon: 'task',
      tooltip: 'Open de taak',
      handler: this.taskOpener.openTask.bind(this.taskOpener),
      disabled: false,
    },
    resumeTask: {
      id: 'resumeTask',
      label: 'Verder werken',
      icon: 'task:active',
      tooltip: 'Werk verder aan de taak',
      handler: this.taskOpener.openTask.bind(this.taskOpener),
      disabled: false,
    },
  };

  getActions(task: StudentTaskInterface) {
    return this.getTaskActions(task);
  }

  private getTaskActions(task: StudentTaskInterface): TaskActionInterface[] {
    const isActive = !task.isFinished;
    const inProgress = task.count && task.count.completed && task.count.completed < task.count.total;

    const canContinue = [isActive, inProgress].every(Boolean);

    if (canContinue) {
      return [this.taskActionDictionary.resumeTask];
    }
    return [this.taskActionDictionary.openTask];
  }
}
