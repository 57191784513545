import { Component, Input } from '@angular/core';

@Component({
  selector: 'campus-product-list',
  templateUrl: './product-list.component.html',
})
export class ProductListComponent {
  @Input()
  view: 'reel' | 'cluster' = 'cluster';
}
