import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { map } from 'rxjs/operators';
import { MethodQueries } from '.';
import { MethodServiceInterface, METHOD_SERVICE_TOKEN } from '../../metadata/method.service.interface';
import { DalState } from '../dal.state.interface';
import {
  AllowedMethodsLoaded,
  AllowedMethodsLoadError,
  LoadAllowedMethods,
  LoadMethods,
  MethodsActionTypes,
  MethodsLoaded,
  MethodsLoadError,
} from './method.actions';

@Injectable()
export class MethodEffects {
  loadMethods$ = createEffect(() =>
    this.actions.pipe(
      ofType(MethodsActionTypes.LoadMethods),
      concatLatestFrom(() => this.store.select(MethodQueries.getLoaded)),
      fetch({
        run: (action: LoadMethods, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.methodService
            .getAllForUser(action.payload.userId)
            .pipe(map((methods) => new MethodsLoaded({ methods })));
        },
        onError: (action: LoadMethods, error) => {
          return new MethodsLoadError(error);
        },
      })
    )
  );

  loadAllowedMethods$ = createEffect(() =>
    this.actions.pipe(
      ofType(MethodsActionTypes.LoadAllowedMethods),
      concatLatestFrom(() => this.store.select(MethodQueries.getAllowedMethodsLoaded)),
      fetch({
        run: (action: LoadAllowedMethods, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.methodService
            .getAllowedMethodIds(action.payload.userId)
            .pipe(map((methodIds: number[]) => new AllowedMethodsLoaded({ methodIds })));
        },
        onError: (action: LoadAllowedMethods, error) => {
          return new AllowedMethodsLoadError(error);
        },
      })
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(METHOD_SERVICE_TOKEN)
    private methodService: MethodServiceInterface
  ) {}
}
