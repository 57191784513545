import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ButtonCoreComponent } from '../button-core/button-core.component';

@Component({
  selector: 'campus-toggle-icon-button, [toggle-icon-button]',
  templateUrl: './toggle-icon-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleIconButtonComponent extends ButtonCoreComponent implements OnChanges {
  @HostBinding('class')
  defaultClasses = [
    ...this.coreClasses,
    'toggle-icon-button',
    'relative',
    'flex',
    'p-3xs',
    'corner-full',
    'border',

    'disabled:border-on-surface',
    'disabled:border-opacity-disabled',
    'disabled:icon-on-surface',
    'disabled:icon-opacity-disabled',
  ];

  ripple = false;

  @Input() icon: string;

  @HostBinding('attr.aria-pressed')
  ariaPressed = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected) {
      this.ariaPressed = this.selected;
    }
  }
}
