import { BreadcrumbLinkInterface, ListFormat } from '../../+external-interfaces';
import { PageHeaderInterface } from '../../+external-interfaces/page-header.interface';
import { RolesEnum } from '../../+models';
import { UiAction, UiActionTypes } from './ui.actions';

/**
 * Interface for the 'Ui' data used in
 *  - UiState, and
 *  - uiReducer
 */
export const NAME = 'ui';

export interface UiState {
  loaded: boolean; // has the Ui list been loaded
  listFormat?: ListFormat;
  sideSheetOpen?: boolean;
  navOpen?: boolean;
  breadcrumbs?: BreadcrumbLinkInterface[];
  isRootComponent?: boolean;
  headerDetails?: PageHeaderInterface;
  enableTour?: boolean;
  tourRoles: RolesEnum[];

  denseMenu?: boolean;
  disableGlobalBookFilter?: boolean;
  savedSettings?: { [key: string]: unknown };
  pageNavigationOpen?: boolean;
}

export const initialState: UiState = {
  listFormat: ListFormat.GRID,
  sideSheetOpen: true,
  navOpen: false,
  breadcrumbs: [],
  isRootComponent: false,
  headerDetails: { title: '' },
  loaded: false,
  tourRoles: [],

  denseMenu: false,
  savedSettings: {},
  pageNavigationOpen: false,
};

export function reducer(state: UiState = initialState, action: UiAction): UiState {
  switch (action.type) {
    case UiActionTypes.UiLoaded:
      state = {
        ...state,
        ...action.payload.state,
        loaded: true,
      };
      break;
    case UiActionTypes.SetListFormat:
      state = {
        ...state,
        listFormat: action.payload.listFormat,
      };
      break;
    case UiActionTypes.ToggleSideSheet:
      state = {
        ...state,
        sideSheetOpen: !state.sideSheetOpen,
      };
      break;
    case UiActionTypes.ToggleNavOpen: {
      const open = action.payload ? !!action.payload.open : !state.navOpen;
      state = {
        ...state,
        navOpen: open,
      };
      break;
    }

    case UiActionTypes.SetIsRootComponent: {
      state = {
        ...state,
        isRootComponent: action.payload.isRootComponent,
      };
      break;
    }

    case UiActionTypes.SetTourRoles:
      state = { ...state, tourRoles: action.payload.tourRoles };
      break;

    case UiActionTypes.SetBreadcrumbs:
      state = {
        ...state,
        breadcrumbs: action.payload.breadcrumbs,
      };
      break;
    case UiActionTypes.SetHeaderDetails:
      state = {
        ...state,
        headerDetails: action.payload.headerDetails,
      };
      break;
    case UiActionTypes.SetNavOpen:
      state = {
        ...state,
        navOpen: action.payload.open,
      };
      break;
    case UiActionTypes.SetDenseMenu:
      state = {
        ...state,
        denseMenu: action.payload.denseMenu,
      };
      break;
    case UiActionTypes.SetDisableGlobalBookFilter:
      state = {
        ...state,
        disableGlobalBookFilter: action.payload.disableGlobalBookFilter,
      };
      break;
    case UiActionTypes.SaveSettings:
      state = {
        ...state,
        savedSettings: { ...state.savedSettings, ...action.payload },
      };
      break;
    case UiActionTypes.SetPageNavigationOpen: {
      const open = action.payload ? !!action.payload.open : !state.pageNavigationOpen;

      state = {
        ...state,
        pageNavigationOpen: open,
      };
      break;
    }
  }
  return state;
}
