import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService, DalState, DataActions, LearnerProfileConceptQueries } from '@campus/dal';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable()
export class MissingLearnerProfileGuard implements CanActivate {
  constructor(private store: Store<DalState>, private authService: AuthService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.store.dispatch(
      new DataActions.LoadData({
        userId: this.authService.userId,
        fields: ['learnerProfileConcepts'],
      })
    );

    return this.store.pipe(
      select(LearnerProfileConceptQueries.getLoaded),
      filter(Boolean),
      switchMap(() => this.store.pipe(select(LearnerProfileConceptQueries.getAll))),
      map(
        (learnerProfileConcepts) => !!learnerProfileConcepts.length // false = missing/incomplete learner profile
      )
    );
  }
}
