import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TaskEvaluationSubjectScoreInterface } from '../../+models';
import {
  TaskEvaluationSubjectScoresActions,
  TaskEvaluationSubjectScoresActionTypes,
} from './task-evaluation-subject-score.actions';

export const NAME = 'taskEvaluationSubjectScores';

export interface State extends EntityState<TaskEvaluationSubjectScoreInterface> {
  // additional entities state properties
  loaded: boolean;
  loadedForTask: {
    [taskId: number]: boolean;
  };
  saving: boolean;
  error?: any;
}

export const adapter: EntityAdapter<TaskEvaluationSubjectScoreInterface> =
  createEntityAdapter<TaskEvaluationSubjectScoreInterface>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loaded: false,
  loadedForTask: {},
  saving: false,
});

export function reducer(state = initialState, action: TaskEvaluationSubjectScoresActions): State {
  switch (action.type) {
    case TaskEvaluationSubjectScoresActionTypes.AddTaskEvaluationSubjectScore: {
      return adapter.addOne(action.payload.taskEvaluationSubjectScore, state);
    }

    case TaskEvaluationSubjectScoresActionTypes.UpsertTaskEvaluationSubjectScore: {
      return adapter.upsertOne(action.payload.taskEvaluationSubjectScore, state);
    }

    case TaskEvaluationSubjectScoresActionTypes.AddTaskEvaluationSubjectScoresForTask: {
      const newState = adapter.addMany(action.payload.taskEvaluationSubjectScores, state);

      return {
        ...newState,
        loadedForTask: {
          ...newState.loadedForTask,
          [action.payload.taskId]: true,
        },
      };
    }
    case TaskEvaluationSubjectScoresActionTypes.StartSetTaskEvaluationSubjectScoresForTask: {
      return { ...state, saving: true };
    }

    case TaskEvaluationSubjectScoresActionTypes.SetTaskEvaluationSubjectScoresForTask: {
      const toRemove = Object.values(state.entities)
        .filter((entity) => action.payload.taskEvaluationSubjectIds.includes(entity.taskEvaluationSubjectId))
        .map((e) => e.id);

      const clearedState = adapter.removeMany(toRemove, state);
      const newState = adapter.addMany(action.payload.taskEvaluationSubjectScores, clearedState);

      return {
        ...newState,
        loadedForTask: {
          ...state.loadedForTask,
          [action.payload.taskId]: true,
        },
        saving: false,
      };
    }

    case TaskEvaluationSubjectScoresActionTypes.AddTaskEvaluationSubjectScores: {
      return adapter.addMany(action.payload.taskEvaluationSubjectScores, state);
    }

    case TaskEvaluationSubjectScoresActionTypes.UpsertTaskEvaluationSubjectScores: {
      return adapter.upsertMany(action.payload.taskEvaluationSubjectScores, state);
    }

    case TaskEvaluationSubjectScoresActionTypes.UpdateTaskEvaluationSubjectScore: {
      return adapter.updateOne(action.payload.taskEvaluationSubjectScore, state);
    }

    case TaskEvaluationSubjectScoresActionTypes.UpdateTaskEvaluationSubjectScores: {
      return adapter.updateMany(action.payload.taskEvaluationSubjectScores, state);
    }

    case TaskEvaluationSubjectScoresActionTypes.DeleteTaskEvaluationSubjectScore: {
      return adapter.removeOne(action.payload.id, state);
    }

    case TaskEvaluationSubjectScoresActionTypes.DeleteTaskEvaluationSubjectScores: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case TaskEvaluationSubjectScoresActionTypes.TaskEvaluationSubjectScoresLoaded: {
      return adapter.setAll(action.payload.taskEvaluationSubjectScores, { ...state, loaded: true });
    }

    case TaskEvaluationSubjectScoresActionTypes.TaskEvaluationSubjectScoresLoadError: {
      return { ...state, error: action.payload, loaded: false };
    }

    case TaskEvaluationSubjectScoresActionTypes.ClearTaskEvaluationSubjectScores: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
