import { groupArrayByKey } from '@campus/utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GoalPrerequisiteInterface } from '../../+models';
import { NAME, selectAll, selectEntities, State } from './goal-prerequisite.reducer';

export const selectGoalPrerequisiteState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectGoalPrerequisiteState, (state: State) => state.error);

export const getLoaded = createSelector(selectGoalPrerequisiteState, (state: State) => state.loaded);

export const getAll = createSelector(selectGoalPrerequisiteState, selectAll);

export const getAllEntities = createSelector(selectGoalPrerequisiteState, selectEntities);

export const getAllByGoalPostrequisite = createSelector(getAll, (goals: GoalPrerequisiteInterface[]) => {
  return groupArrayByKey(goals, 'postrequisiteGoalId');
});
export const getAllByGoalPrerequisite = createSelector(getAll, (goals: GoalPrerequisiteInterface[]) => {
  return groupArrayByKey(goals, 'prerequisiteGoalId');
});

export const getGoalPrerequisitesLoadedForLearningArea = (props: { learningAreaId: number }) =>
  createSelector(selectGoalPrerequisiteState, (state) => {
    return !!state.loadedForLearningArea[props.learningAreaId];
  });
