import { groupArrayByKey } from '@campus/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssigneeInterface, AssigneeTypesEnum, GroupInterface } from '../../+models';
import { BundleGroupInterface } from '../../+models/BundleGroup.interface';
import { selectGroupState } from '../group/group.selectors';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './bundle-group.reducer';

export const selectBundleGroupState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectBundleGroupState, (state: State) => state.error);

export const getLoaded = createSelector(selectBundleGroupState, (state: State) => state.loaded);

export const getAll = createSelector(selectBundleGroupState, selectAll);

export const getCount = createSelector(selectBundleGroupState, selectTotal);

export const getIds = createSelector(selectBundleGroupState, selectIds);

export const getAllEntities = createSelector(selectBundleGroupState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * bundleGroup$: BundleGroupInterface[] = this.store.pipe(
    select(BundleGroupQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectBundleGroupState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * bundleGroup$: BundleGroupInterface = this.store.pipe(
    select(BundleGroupQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectBundleGroupState,
  (state: State, props: { id: number }) => state.entities[props.id]
);

export const getBundleGroupAssigneeByBundle = createSelector(getAll, selectGroupState, (bundleGroups, groupState) =>
  bundleGroups
    .sort((bgA: BundleGroupInterface, bgB: BundleGroupInterface): number => {
      const groupA: GroupInterface = groupState.entities[bgA.groupId];
      const groupB: GroupInterface = groupState.entities[bgB.groupId];
      if (!groupA?.name) return 1;
      if (!groupB?.name) return -1;

      return groupA.name.localeCompare(groupB.name, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    })
    .reduce((dict, bG) => {
      if (!dict[bG.bundleId]) {
        dict[bG.bundleId] = [];
      }
      dict[bG.bundleId].push({
        id: bG.id,
        type: AssigneeTypesEnum.GROUP,
        relationId: bG.groupId,
        label: groupState.entities[bG.groupId].name,
      });

      return dict;
    }, {} as Dictionary<AssigneeInterface[]>)
);

export const getByBundleId = createSelector(
  selectBundleGroupState,
  (state: State, props: { bundleId: number }): BundleGroupInterface[] =>
    (state.ids as (string | number)[]).reduce((acc, id) => {
      if (state.entities[id].bundleId === props.bundleId) {
        acc.push(state.entities[id]);
      }
      return acc;
    }, [])
);

export const getAllGroupedByBundleId = createSelector(selectBundleGroupState, (state: State) => {
  return groupArrayByKey(Object.values(state.entities), 'bundleId');
});
