import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class UrlValidator implements Validator {
  validate(ctrl: AbstractControl): ValidationErrors | null {
    if ([null, undefined, ''].includes(ctrl.value)) return null;

    const validPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

    const isValid = validPattern.test(ctrl.value);
    return isValid ? null : { invalidUrl: true };
  }
}
