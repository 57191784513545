import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch, optimisticUpdate } from '@nrwl/angular';
import { undo } from 'ngrx-undo';
import { from } from 'rxjs';
import { map, mapTo, switchMap } from 'rxjs/operators';
import {
  EduContentBookServiceInterface,
  EduContentsClearedInterface,
  EduContentsUnlinkedInterface,
  EDU_CONTENT_BOOK_SERVICE_TOKEN,
} from '../../edu-content-book';
import {
  EduContentTocServiceInterface,
  EDU_CONTENT_TOC_SERVICE_TOKEN,
} from '../../edu-content-toc/edu-content-toc.service.interface';
import { DalState } from '../dal.state.interface';
import { EduContentActions } from '../edu-content';
import { EduContentTocEduContentMetadataActions } from '../edu-content-toc-edu-content-metadata';
import { EffectFeedback } from '../effect-feedback';
import { AddEffectFeedback } from '../effect-feedback/effect-feedback.actions';
import {
  AddEduContentBooksForMethod,
  ClearEduContentsFromBook,
  EduContentBooksActionTypes,
  EduContentBooksLoaded,
  EduContentBooksLoadError,
  LoadEduContentBooks,
  LoadEduContentBooksForMethod,
  LoadEduContentBooksFromIds,
  UnlinkEduContentTocEduContentMetadataFromBook,
  UpdateEduContentBook,
} from './edu-content-book.actions';
import { getLoaded as getBooksLoaded, selectEduContentBookState } from './edu-content-book.selectors';

@Injectable()
export class EduContentBookEffects {
  loadEduContentBooks$ = createEffect(() =>
    this.actions.pipe(
      ofType(EduContentBooksActionTypes.LoadEduContentBooks),
      concatLatestFrom(() => this.store.select(getBooksLoaded)),
      fetch({
        run: (action: LoadEduContentBooks, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.eduContentBookService
            .getAllForUser(action.payload.userId)
            .pipe(map((eduContentBooks) => new EduContentBooksLoaded({ eduContentBooks })));
        },
        onError: (action: LoadEduContentBooks, error) => {
          return new EduContentBooksLoadError(error);
        },
      })
    )
  );

  loadEduContentBooksForMethod$ = createEffect(() =>
    this.actions.pipe(
      ofType(EduContentBooksActionTypes.LoadEduContentBooksForMethod),
      concatLatestFrom(() => this.store.select(selectEduContentBookState)),
      fetch({
        run: (action: LoadEduContentBooksForMethod, eduContentBookState) => {
          const { methodId } = action.payload;

          if (eduContentBookState.loadedForMethod[methodId]) {
            return;
          }

          return this.tocService.getBooksByMethodIds([methodId]).pipe(
            map(
              (eduContentBooks) =>
                new AddEduContentBooksForMethod({
                  methodId,
                  eduContentBooks,
                })
            )
          );
        },
        onError: (action: LoadEduContentBooksForMethod, error) => {
          return new EduContentBooksLoadError(error);
        },
      })
    )
  );

  loadEduContentBooksFromIds$ = createEffect(() =>
    this.actions.pipe(
      ofType(EduContentBooksActionTypes.LoadEduContentBooksFromIds),
      concatLatestFrom(() => this.store.select(getBooksLoaded)),
      fetch({
        run: (action: LoadEduContentBooksFromIds, loaded: boolean) => {
          if (!action.payload.force && loaded) return;
          return this.tocService
            .getBooksByIds(action.payload.bookIds)
            .pipe(map((eduContentBooks) => new EduContentBooksLoaded({ eduContentBooks })));
        },
        onError: (action: LoadEduContentBooksFromIds, error) => {
          return new EduContentBooksLoadError(error);
        },
      })
    )
  );

  updateEduContentBook$ = createEffect(() =>
    this.actions.pipe(
      ofType(EduContentBooksActionTypes.UpdateEduContentBook),
      optimisticUpdate({
        run: (action: UpdateEduContentBook) => {
          const { id, changes } = action.payload.eduContentBook;
          return this.eduContentBookService.updateEduContentBook(+id, changes).pipe(
            mapTo(
              new AddEffectFeedback({
                effectFeedback: EffectFeedback.generateSuccessFeedback(this.uuid(), action, 'De uitgave is aangepast.'),
              })
            )
          );
        },
        undoAction: (action: UpdateEduContentBook, error) => {
          const errorFeedbackAction = new AddEffectFeedback({
            effectFeedback: EffectFeedback.generateErrorFeedback(
              this.uuid(),
              action,
              'Het is niet gelukt om de uitgave aan te passen.'
            ),
          });
          return from([undo(action), errorFeedbackAction]);
        },
      })
    )
  );

  clearEduContentsFromBook$ = createEffect(() =>
    this.actions.pipe(
      ofType(EduContentBooksActionTypes.ClearEduContentTocsFromBook),
      concatLatestFrom(() => this.store),
      optimisticUpdate({
        run: (action: ClearEduContentsFromBook, state) => {
          return this.eduContentBookService.clearEduContents(action.payload.id).pipe(
            switchMap((response: EduContentsClearedInterface) => {
              const feedback = [];
              if (response.errors && response.errors.length) {
                feedback.push(
                  new AddEffectFeedback({
                    effectFeedback: EffectFeedback.generateErrorFeedback(
                      this.uuid(),
                      action,
                      `Het is niet gelukt om de leerobjecten met de ids [${response.errors
                        .map((error) => error.eduContentId)
                        .join(', ')}] van het boek te verwijderen!`
                    ),
                  })
                );
              }
              if (response.success && response.success.length) {
                const eduContentIds = response.success.map((succ) => succ.eduContentId);
                const affectedEduContentTOCEduContentMetadataIds = this.getAffectedEduContentTOCEduContentMetadataIds(
                  state,
                  action.payload.id,
                  eduContentIds
                );

                feedback.push(
                  new EduContentActions.DeleteEduContents({
                    ids: response.success
                      .filter(({ operation }) => operation === 'remove')
                      .map(({ eduContentId }) => eduContentId),
                  }),
                  new EduContentTocEduContentMetadataActions.DeleteEduContentTOCEduContentMetadatas({
                    ids: affectedEduContentTOCEduContentMetadataIds,
                  }),
                  new AddEffectFeedback({
                    effectFeedback: EffectFeedback.generateSuccessFeedback(
                      this.uuid(),
                      action,
                      'Het is gelukt om de leerobjecten van het boek te verwijderen!'
                    ),
                  })
                );
              }
              return from(feedback);
            })
          );
        },
        undoAction: (action: ClearEduContentsFromBook, error: any) => {
          return from([
            undo(action),
            new AddEffectFeedback({
              effectFeedback: EffectFeedback.generateErrorFeedback(
                this.uuid(),
                action,
                `Het is niet gelukt om de leerobjecten van het boek te verwijderen!`
              ),
            }),
          ]);
        },
      })
    )
  );

  unlinkEduContentTOCEduContentMetadataFromBook$ = createEffect(() =>
    this.actions.pipe(
      ofType(EduContentBooksActionTypes.UnlinkEduContentTocsFromBook),
      optimisticUpdate({
        run: (action: UnlinkEduContentTocEduContentMetadataFromBook) => {
          return this.eduContentBookService.unlinkEduContents(action.payload.id).pipe(
            switchMap((response: EduContentsUnlinkedInterface) => {
              const feedback = [];
              if (response.errors && response.errors.length) {
                feedback.push(
                  new AddEffectFeedback({
                    effectFeedback: EffectFeedback.generateErrorFeedback(
                      this.uuid(),
                      action,
                      `Het is niet gelukt om de leerobjecten met de ids [${response.errors
                        .map((error) => error.eduContentMetadataId)
                        .join(', ')}] van het boek te ontkoppelen!`
                    ),
                  })
                );
              }
              if (response.success && response.success.length) {
                feedback.push(
                  new AddEffectFeedback({
                    effectFeedback: EffectFeedback.generateSuccessFeedback(
                      this.uuid(),
                      action,
                      'Het is gelukt om de leerobjecten van het boek te ontkoppelen!'
                    ),
                  })
                );
              }
              return from([
                new EduContentTocEduContentMetadataActions.DeleteEduContentTOCEduContentMetadatasByTocIdAndMetadataId({
                  eduContentTocEduContentMetadataPartials: response.success,
                }),
                ...feedback,
              ]);
            })
          );
        },
        undoAction: (action: UnlinkEduContentTocEduContentMetadataFromBook, error: any) => {
          return from([
            undo(action),
            new AddEffectFeedback({
              effectFeedback: EffectFeedback.generateErrorFeedback(
                this.uuid(),
                action,
                'Het is niet gelukt om de leerobjecten van het boek te ontkoppelen!'
              ),
            }),
          ]);
        },
      })
    )
  );

  private getAffectedEduContentTOCEduContentMetadataIds(state: DalState, bookId: number, eduContentIds?: number[]) {
    const affectedTOCIds: { [id: number]: boolean } = (state.eduContentTocs.ids as number[]).reduce((acc, tocId) => {
      const tocEntity = state.eduContentTocs.entities[tocId];

      if (tocEntity.treeId === bookId) {
        return {
          ...acc,
          [tocId]: true,
        };
      }

      return acc;
    }, {});

    const affectedEduContentIds: { [id: number]: boolean } = eduContentIds.reduce((acc, eduContentId) => {
      return {
        ...acc,
        [eduContentId]: true,
      };
    }, {});

    const affectedEduContentMetadataIds = (state.eduContentMetadatas.ids as number[]).reduce(
      (acc, eduContentMetadataId) => {
        const eduContentMetadataEntity = state.eduContentMetadatas.entities[eduContentMetadataId];

        if (affectedEduContentIds[eduContentMetadataEntity.eduContentId]) {
          return {
            ...acc,
            [eduContentMetadataId]: true,
          };
        }

        return acc;
      },
      {}
    );

    const affectedEduContentTOCEduContentMetadataIds = (state.eduContentTOCEduContentMetadata.ids as number[]).filter(
      (id) => {
        const ectEcmEntity = state.eduContentTOCEduContentMetadata.entities[id];

        return (
          (!affectedEduContentMetadataIds || affectedEduContentMetadataIds[ectEcmEntity.eduContentMetadataId]) &&
          affectedTOCIds[ectEcmEntity.eduContentTOCId]
        );
      }
    );

    return affectedEduContentTOCEduContentMetadataIds;
  }

  constructor(
    private actions: Actions,
    private store: Store<DalState>,
    @Inject(EDU_CONTENT_TOC_SERVICE_TOKEN)
    private tocService: EduContentTocServiceInterface,
    @Inject(EDU_CONTENT_BOOK_SERVICE_TOKEN)
    private eduContentBookService: EduContentBookServiceInterface,
    @Inject('uuid') private uuid: () => string
  ) {}
}
