import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ArrayFunctions } from '@campus/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LearningPlanApi } from '../+api';
import { LearningPlanInterface } from '../+models';
import { DAL_OPTIONS, DalOptions } from '../dal.module';
import { LearningPlanAssignmentInterface } from './../+models/LearningPlanAssignment.interface';
import { SpecialtyInterface } from './../+models/Specialty.interface';
import { LearningPlanServiceInterface } from './learning-plan.service.interface';

@Injectable({
  providedIn: 'root',
})
export class LearningPlanService implements LearningPlanServiceInterface {
  private http = inject(HttpClient);
  private dalOptions = inject<DalOptions>(DAL_OPTIONS);
  constructor(private learningPlanApi: LearningPlanApi) {}

  getLearningPlanAssignments(
    eduNetId,
    yearId,
    schoolTypeId,
    learningAreaId
  ): Observable<Map<SpecialtyInterface, LearningPlanAssignmentInterface[]>> {
    return this.learningPlanApi
      .find({
        where: { learningAreaId, eduNetId },
        include: {
          relation: 'assignments',
          scope: { include: ['specialty'], where: { schoolTypeId, yearId } },
        },
      })
      .pipe(map(this.toSpecialitiesMap));
  }

  // TODO: move to own service?
  getSpecialities(eduNetId, yearId, schoolTypeId, learningAreaId): Observable<SpecialtyInterface[]> {
    return this.learningPlanApi
      .find({
        where: { learningAreaId, eduNetId },
        include: {
          relation: 'assignments',
          scope: { include: ['specialty'], where: { schoolTypeId, yearId } },
        },
      })
      .pipe(map(this.toSpecialitiesArray));
  }

  getLearningPlans(methodIds: number[] | number, yearIds?: number[]): Observable<LearningPlanInterface[]> {
    const actualIds = Array.isArray(methodIds) ? methodIds : [methodIds];
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/LearningPlans/for-methods`;
    const params = new HttpParams({
      fromObject: {
        methodIds: actualIds,
        yearIds,
      },
    });

    return this.http.get<LearningPlanInterface[]>(url, { params, withCredentials: true });
  }

  getLearningPlansForBooks(eduContentBookIds: number[] | number): Observable<LearningPlanInterface[]> {
    eduContentBookIds = ArrayFunctions.wrapIntoArray(eduContentBookIds);
    const { apiBaseUrl } = this.dalOptions;
    const url = `${apiBaseUrl}/api/LearningPlans/for-educontentbooks`;
    const params = new HttpParams({
      fromObject: {
        eduContentBookIds,
      },
    });

    return this.http.get<LearningPlanInterface[]>(url, { params, withCredentials: true });
  }

  private toSpecialitiesMap(
    learningPlans: LearningPlanInterface[]
  ): Map<SpecialtyInterface, LearningPlanAssignmentInterface[]> {
    const learningPlanAssignments = learningPlans.reduce((acc, plan) => {
      plan.assignments.forEach((assignment) => {
        assignment.learningPlan = plan;
        assignment.learningPlan.assignments = null;
        assignment.learningPlanId = plan.id;
        acc.push(assignment);
      });
      return acc;
    }, [] as LearningPlanAssignmentInterface[]);

    const specialitiesMap = learningPlanAssignments.reduce((sMap, assignment) => {
      const prevValues = sMap.get(assignment.specialty) || [];
      return sMap.set(assignment.specialty, [...prevValues, assignment]);
    }, new Map());

    return specialitiesMap;
  }

  private toSpecialitiesArray(learningPlans: LearningPlanInterface[]): SpecialtyInterface[] {
    const specialities = {};

    learningPlans.forEach((plan) =>
      plan.assignments.forEach((assignment) => {
        if (!specialities[assignment.specialty.id]) {
          specialities[assignment.specialty.id] = assignment.specialty;
        }
      })
    );

    return Object.values(specialities);
  }
}
