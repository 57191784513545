import { Injectable, InjectionToken } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';
import { PersonApi, TeacherInfoApi } from '../+api';
import { CompleteProfileInterface, PersonInterface, RealmInterface, TeacherInfoInterface } from '../+models';

export const PERSON_SERVICE_TOKEN = new InjectionToken<PersonServiceInterface>('PersonService');

export interface PersonServiceInterface {
  checkProfileComplete(): Observable<CompleteProfileInterface>;
  checkUniqueUsername(userId: number, username: string): Observable<boolean>;
  checkUniqueLeerId(userId: number, leerId: string): Observable<boolean>;
  checkMultipleUniqueLeerIds(userId: number, leerIds: string[]): Observable<string[]>;
  validatePassword(userId: number, password: string): Observable<boolean>;
  checkUniqueEmail(userId: number, email: string): Observable<boolean>;
  checkUniqueAdminEmail(email: string): Observable<{ unique: boolean; user?: PersonInterface }>;
  checkUniquePublicKey(userId: number, publicKey: string): Observable<boolean>;
  updateUser(userId: number, changedProps: Partial<PersonInterface>): Observable<PersonInterface>;
  updatePassword(userId: number, password: string, currentPassword?: string, accessToken?: string): Observable<boolean>;
  upsertTeacherInfo(changedProps: Partial<TeacherInfoInterface>): Observable<TeacherInfoInterface>;
  getRealms(personId: number): Observable<RealmInterface[]>;
  selectRealm(personId: number, realm: string): Observable<{ link: string }>;
}

@Injectable({
  providedIn: 'root',
})
export class PersonService implements PersonServiceInterface {
  constructor(private personApi: PersonApi, private teacherInfoApi: TeacherInfoApi) {}

  checkUniqueUsername(userId: number, username: string): Observable<boolean> {
    return this.personApi.checkUnique(userId, 'username', username).pipe(
      map((response: { unique: boolean }) => {
        return response.unique;
      })
    );
  }

  checkUniqueLeerId(userId: number, leerId: string): Observable<boolean> {
    return this.personApi.checkUnique(userId, 'leerId', leerId).pipe(
      map((response: { unique: boolean }) => {
        return response.unique;
      })
    );
  }

  checkMultipleUniqueLeerIds(userId: number, leerIds: string[]): Observable<string[]> {
    return this.personApi.checkMultipleUniques(userId, 'leerId', leerIds).pipe(
      map((response: { nonuniques: string[] }) => {
        return response.nonuniques;
      })
    );
  }

  validatePassword(userId: number, password: string): Observable<boolean> {
    return this.personApi.validatePasswordStrength(userId, password);
  }

  checkUniqueEmail(userId: number, email: string): Observable<boolean> {
    // || null in case userId is undefined
    // else loopback will use an empty object as default value which doesn't pass API parameter validation
    return this.personApi.checkUnique(userId || null, 'email', email).pipe(
      map((response: { unique: boolean }) => {
        return response.unique;
      })
    );
  }

  checkProfileComplete(): Observable<CompleteProfileInterface> {
    return this.personApi.checkProfileComplete();
  }

  checkUniqueAdminEmail(email: string): Observable<{ unique: boolean; user?: PersonInterface }> {
    return this.personApi.checkUniqueAdmin(email) as Observable<{
      unique: boolean;
      user?: PersonInterface;
    }>;
  }

  checkUniquePublicKey(userId: number, publicKey: string): Observable<boolean> {
    const checkUnique = this.teacherInfoApi.checkUnique(userId, 'publicKey', publicKey) as unknown as Observable<{
      unique: boolean;
    }>;
    return checkUnique.pipe(
      map((response: { unique: boolean }) => {
        return response.unique;
      })
    );
  }

  updateUser(userId: number, changedProps: Partial<PersonInterface>): Observable<PersonInterface> {
    return this.personApi.updateUser(userId, changedProps).pipe(catchError((error) => throwError(error)));
  }

  upsertTeacherInfo(changedProps: TeacherInfoInterface): Observable<TeacherInfoInterface> {
    if (changedProps.id && !changedProps.publicKey) {
      return this.teacherInfoApi.deleteById(changedProps.id).pipe(mapTo(null));
    } else if (changedProps.id) {
      return this.teacherInfoApi.updateAttributes(changedProps.id, changedProps);
    } else {
      return this.teacherInfoApi.create(changedProps);
    }
  }

  updatePassword(
    userId: number,
    password: string,
    currentPassword?: string,
    accessToken?: string
  ): Observable<boolean> {
    return this.personApi.updatePassword(userId, { password, currentPassword }, accessToken).pipe(
      map((_) => true),
      catchError((error) => throwError(error))
    );
  }

  public getRealms(personId: number): Observable<RealmInterface[]> {
    return this.personApi.getRealms(personId);
  }

  public selectRealm(personId: number, realm: string) {
    return this.personApi.loginWithRealm(personId, realm).pipe(map((link) => ({ link })));
  }
}
