/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable, inject } from '@angular/core';
import { ProductApi as SDKProductApi } from '@diekeure/polpo-api-angular-sdk';
import { Observable } from 'rxjs';
import { ProductInterface } from '../../+models';
import { ModelApiBase, PolpoApi } from '../shared';

@Injectable({ providedIn: 'root' })
export class ProductApi extends ModelApiBase {
  private SDKProductApi = inject(SDKProductApi);

  constructor(api: PolpoApi) {
    super(api, 'Products');
  }

  find(options?): Observable<ProductInterface[]> {
    return this.SDKProductApi.find(...arguments);
  }

  patchAttributes(productId: number, update): Observable<ProductInterface> {
    // @ts-expect-error TS 2556
    return this.SDKProductApi.patchAttributes(...arguments);
  }

  destroyProduct(productId: number) {
    // @ts-expect-error TS 2556
    return this.SDKProductApi.destroyProduct(...arguments);
  }

  createProductContents(productId: number, productContent) {
    // @ts-expect-error TS 2556
    return this.SDKProductApi.createProductContents(...arguments);
  }

  destroyByIdProductContents(productId: number, productContentId: number) {
    // @ts-expect-error TS 2556
    return this.SDKProductApi.destroyByIdProductContents(...arguments);
  }

  linkMethodProductGroups(productId: number, methodProductGroupId: number) {
    // @ts-expect-error TS 2556
    return this.SDKProductApi.linkMethodProductGroups(...arguments);
  }

  unlinkMethodProductGroups(productId: number, methodProductGroupId: number) {
    // @ts-expect-error TS 2556
    return this.SDKProductApi.unlinkMethodProductGroups(...arguments);
  }
}
