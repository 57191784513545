import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import {
  ClassGroupInterface,
  PersonInterface,
  SchoolAdminInterface,
  StudentInterface,
  TeacherInterface,
} from '../../+models';
import { StudentImportType } from '../../persons/linked-persons.service.interface';
import { CustomFeedbackHandlersInterface, FeedbackTriggeringAction } from '../effect-feedback';

export enum LinkedPersonsActionTypes {
  LinkedPersonsLoaded = '[LinkedPersons] LinkedPersons Loaded',
  LinkedPersonsLoadError = '[LinkedPersons] Load Error',
  LoadLinkedPersons = '[LinkedPersons] Load LinkedPersons',
  AddLinkedPerson = '[LinkedPersons] Add LinkedPerson',
  AddLinkedPersons = '[LinkedPersons] Add LinkedPersons',
  DeleteLinkedPerson = '[LinkedPersons] Delete LinkedPerson',
  DeleteLinkedPersons = '[LinkedPersons] Delete LinkedPersons',
  ClearLinkedPersons = '[LinkedPersons] Clear LinkedPersons',
  UpdateLinkedPerson = '[LinkedPerson] Update LinkedPerson',
  UpsertLinkedPersons = '[LinkedPerson] Upsert LinkedPersons',

  StartAddStudent = '[LinkedPersons] Start Add Student',
  StartUpdateStudent = '[LinkedPersons] Start Update Student',
  DeleteStudent = '[LinkedPersons] Remove Student',
  DeleteStudents = '[LinkedPersons] Remove Students',

  UpdateStudentPersonalCode = '[LinkedPerson] Update StudentPersonalCode',
  AddStudentCoins = '[LinkedPerson] Update StudentCoins',

  StartAddTeacher = '[LinkedPersons] Start Add Teacher',
  StartUpdateTeacher = '[LinkedPersons] Start Update Teacher',
  DeleteTeacher = '[LinkedPersons] Remove Teacher',

  StartAddSchoolAdmin = '[LinkedPersons] Start Add SchoolAdmin',
  StartUpdateSchoolAdmin = '[LinkedPersons] Start Update SchoolAdmin',
  DeleteSchoolAdmin = '[LinkedPersons] Remove schoolAdmin',
  StartLinkSchoolAdmin = '[LinkedPersons] Start Link SchoolAdmin',

  StartImportStudents = '[LinkedPersons] Start Import Students',

  SendResetMail = '[LinkedPersons] Send Password Reset Mail',

  NavigateToStudentDetail = '[LinkedPersons] Navigate To Student Detail',
  NavigateToTeacherDetail = '[LinkedPersons] Navigate To Teacher Detail',

  SendInviteMail = '[LinkedPersons] Send Invite Mail',
}

export class LoadLinkedPersons implements Action {
  readonly type = LinkedPersonsActionTypes.LoadLinkedPersons;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class LinkedPersonsLoaded implements Action {
  readonly type = LinkedPersonsActionTypes.LinkedPersonsLoaded;

  constructor(public payload: { persons: PersonInterface[] }) {}
}

export class LinkedPersonsLoadError implements Action {
  readonly type = LinkedPersonsActionTypes.LinkedPersonsLoadError;
  constructor(public payload: any) {}
}

export class AddLinkedPerson implements Action {
  readonly type = LinkedPersonsActionTypes.AddLinkedPerson;

  constructor(public payload: { person: PersonInterface }) {}
}

export class AddLinkedPersons implements Action {
  readonly type = LinkedPersonsActionTypes.AddLinkedPersons;

  constructor(public payload: { persons: PersonInterface[] }) {}
}

export class DeleteLinkedPerson implements Action {
  readonly type = LinkedPersonsActionTypes.DeleteLinkedPerson;

  constructor(public payload: { id: number }) {}
}

export class DeleteLinkedPersons implements Action {
  readonly type = LinkedPersonsActionTypes.DeleteLinkedPersons;

  constructor(public payload: { ids: number[] }) {}
}

export class ClearLinkedPersons implements Action {
  readonly type = LinkedPersonsActionTypes.ClearLinkedPersons;
}
export class StartAddStudent implements FeedbackTriggeringAction {
  readonly type = LinkedPersonsActionTypes.StartAddStudent;

  constructor(
    public payload: {
      schoolId: number;
      student: Partial<StudentInterface> &
        Required<Pick<StudentInterface, 'firstName' | 'name' | 'rootNumber' | 'leerId' | 'personalCode'>>;
      classGroup?: ClassGroupInterface;
      classNumber?: number;
      navigateAfterCreate?: boolean;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpdateLinkedPerson implements Action {
  readonly type = LinkedPersonsActionTypes.UpdateLinkedPerson;
  constructor(
    public payload: {
      person: Update<PersonInterface>;
    }
  ) {}
}

export class UpdateStudentPersonalCode implements FeedbackTriggeringAction {
  readonly type = LinkedPersonsActionTypes.UpdateStudentPersonalCode;
  constructor(
    public payload: {
      schoolId: number;
      personId: number;
      personalCode: string;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class AddStudentCoins implements FeedbackTriggeringAction {
  readonly type = LinkedPersonsActionTypes.AddStudentCoins;
  constructor(
    public payload: {
      schoolId: number;
      personId: number;
      gameCoinsToAdd: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class UpsertLinkedPersons implements Action {
  readonly type = LinkedPersonsActionTypes.UpsertLinkedPersons;
  constructor(
    public payload: {
      persons: PersonInterface[];
    }
  ) {}
}

export class StartUpdateStudent implements Action {
  readonly type = LinkedPersonsActionTypes.StartUpdateStudent;

  constructor(
    public payload: {
      schoolId: number;
      studentId: number;
      student: Partial<StudentInterface>;
      classGroupId?: number;
      classNumber?: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class DeleteStudent implements FeedbackTriggeringAction {
  readonly type = LinkedPersonsActionTypes.DeleteStudent;

  constructor(
    public payload: {
      id: number;
      schoolId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class DeleteStudents implements FeedbackTriggeringAction {
  readonly type = LinkedPersonsActionTypes.DeleteStudents;

  constructor(
    public payload: {
      ids: number[];
      schoolId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartAddTeacher implements FeedbackTriggeringAction {
  readonly type = LinkedPersonsActionTypes.StartAddTeacher;

  constructor(
    public payload: {
      schoolId: number;
      teacher: Partial<TeacherInterface> & Required<Pick<TeacherInterface, 'firstName' | 'name' | 'email'>>;
      navigateAfterCreate?: boolean;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartUpdateTeacher implements FeedbackTriggeringAction {
  readonly type = LinkedPersonsActionTypes.StartUpdateTeacher;

  constructor(
    public payload: {
      schoolId: number;
      teacher: Update<TeacherInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class DeleteTeacher implements FeedbackTriggeringAction {
  readonly type = LinkedPersonsActionTypes.DeleteTeacher;

  constructor(
    public payload: {
      id: number;
      schoolId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartAddSchoolAdmin implements FeedbackTriggeringAction {
  readonly type = LinkedPersonsActionTypes.StartAddSchoolAdmin;
  constructor(
    public payload: {
      schoolId: number;
      schoolAdmin: Partial<SchoolAdminInterface> & Required<Pick<SchoolAdminInterface, 'firstName' | 'name' | 'email'>>;
      sendInviteMail?: boolean;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartUpdateSchoolAdmin implements FeedbackTriggeringAction {
  readonly type = LinkedPersonsActionTypes.StartUpdateSchoolAdmin;
  constructor(
    public payload: {
      schoolId: number;
      schoolAdmin: Update<SchoolAdminInterface>;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class DeleteSchoolAdmin implements FeedbackTriggeringAction {
  readonly type = LinkedPersonsActionTypes.DeleteSchoolAdmin;

  constructor(
    public payload: {
      id: number;
      schoolId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartLinkSchoolAdmin implements FeedbackTriggeringAction {
  readonly type = LinkedPersonsActionTypes.StartLinkSchoolAdmin;

  constructor(
    public payload: {
      id: number;
      schoolId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class StartImportStudents implements FeedbackTriggeringAction {
  readonly type = LinkedPersonsActionTypes.StartImportStudents;

  constructor(
    public payload: {
      schoolId: number;
      students: StudentImportType[];
      rootNumbersToRemove?: string[];
      rootNumbersToUnlink?: string[];
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class NavigateToStudentDetail implements Action {
  readonly type = LinkedPersonsActionTypes.NavigateToStudentDetail;

  constructor(
    public payload: {
      studentId: number;
      classGroupId?: number; //Note: use id = 0 for 'geen klas' and id = undefined for don't open a panel
    }
  ) {}
}

export class SendResetMail implements FeedbackTriggeringAction {
  readonly type = LinkedPersonsActionTypes.SendResetMail;
  constructor(
    public payload: {
      email: string;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export class NavigateToTeacherDetail implements Action {
  readonly type = LinkedPersonsActionTypes.NavigateToTeacherDetail;

  constructor(
    public payload: {
      teacherId: number;
    }
  ) {}
}

export class SendInviteMail implements FeedbackTriggeringAction {
  readonly type = LinkedPersonsActionTypes.SendInviteMail;

  constructor(
    public payload: {
      schoolId: number;
      personId: number;
      customFeedbackHandlers?: CustomFeedbackHandlersInterface;
    }
  ) {}
}

export type LinkedPersonsActions =
  | LoadLinkedPersons
  | LinkedPersonsLoaded
  | LinkedPersonsLoadError
  | AddLinkedPerson
  | AddLinkedPersons
  | DeleteLinkedPerson
  | DeleteLinkedPersons
  | ClearLinkedPersons
  | StartAddStudent
  | StartUpdateStudent
  | DeleteStudent
  | DeleteStudents
  | StartAddTeacher
  | DeleteTeacher
  | DeleteSchoolAdmin
  | StartLinkSchoolAdmin
  | StartAddSchoolAdmin
  | StartUpdateSchoolAdmin
  | StartImportStudents
  | UpdateLinkedPerson
  | UpdateStudentPersonalCode
  | AddStudentCoins
  | UpsertLinkedPersons
  | StartImportStudents
  | NavigateToStudentDetail
  | NavigateToTeacherDetail
  | SendInviteMail
  | SendResetMail;
