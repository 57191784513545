import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AUTH_SERVICE_TOKEN } from '../persons';
import { BearerHttpServiceInterface } from './bearer-http.service.interface';

enum HttpMethod {
  'GET' = 'get',
  'POST' = 'post',
  'PUT' = 'put',
  'PATCH' = 'patch',
  'DELETE' = 'delete',
}

@Injectable({
  providedIn: 'root',
})
export class BearerHttpService implements BearerHttpServiceInterface {
  private http = inject(HttpClient);
  private authService = inject(AUTH_SERVICE_TOKEN);

  constructor() {}
  public get<T>(url: string, params?: HttpParams): Observable<T> {
    return this.call<T>(HttpMethod.GET, url, null, params);
  }

  public post<T>(url: string, body: any, params?: HttpParams): Observable<T> {
    return this.call<T>(HttpMethod.POST, url, body, params);
  }

  public patch<T>(url: string, body: any, params?: HttpParams): Observable<T> {
    return this.call<T>(HttpMethod.PATCH, url, body, params);
  }

  public put<T>(url: string, body: any, params?: HttpParams): Observable<T> {
    return this.call<T>(HttpMethod.PUT, url, body, params);
  }

  public delete<T>(url: string, params?: HttpParams): Observable<T> {
    return this.call<T>(HttpMethod.DELETE, url, null, params);
  }

  private call<T>(httpMethod: HttpMethod, url: string, body: any, params?: HttpParams): Observable<T> {
    return this.authService.getAccessToken$().pipe(
      take(1),
      switchMap((accessToken) => {
        const token = `${accessToken.userId}:${accessToken.id}`;
        const headers = new HttpHeaders({
          'x-auth': `Bearer ${token}`,
        });

        if (httpMethod === HttpMethod.GET) {
          return this.http.get<T>(url, {
            headers,
            params,
          });
        }
        if (httpMethod === HttpMethod.POST) {
          return this.http.post<T>(url, body, {
            headers,
            params,
          });
        }
        if (httpMethod === HttpMethod.PATCH) {
          return this.http.patch<T>(url, body, {
            headers,
            params,
          });
        }
        if (httpMethod === HttpMethod.PUT) {
          return this.http.put<T>(url, body, {
            headers,
            params,
          });
        }
        if (httpMethod === HttpMethod.DELETE) {
          return this.http.delete<T>(url, {
            headers,
            params,
          });
        }
      })
    );
  }
}
