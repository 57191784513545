import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AtlasSchoolInterface } from '../../+models';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './atlas-school.reducer';

export const selectAtlasSchoolState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectAtlasSchoolState, (state: State) => state.error);

export const getLoaded = createSelector(selectAtlasSchoolState, (state: State) => state.loaded);

export const getAll = createSelector(selectAtlasSchoolState, selectAll);

export const getCount = createSelector(selectAtlasSchoolState, selectTotal);

export const getIds = createSelector(selectAtlasSchoolState, selectIds);

export const getMaxLastUpdated = createSelector(
  getAll,
  (atlasSchools: AtlasSchoolInterface[]) =>
    atlasSchools.reduce(
      (prevMaxSchool: AtlasSchoolInterface, curMaxSchool: AtlasSchoolInterface) =>
        curMaxSchool.lastUpdated > prevMaxSchool.lastUpdated ? curMaxSchool : prevMaxSchool,
      { lastUpdated: null }
    ).lastUpdated
);

export const getAllEntities = createSelector(selectAtlasSchoolState, selectEntities);

/**
 * returns array of objects in the order of the given ids
 * @example
 * atlasSchool$: AtlasSchoolInterface[] = this.store.pipe(
    select(AtlasSchoolQueries.getByIds, { ids: [2, 1, 3] })
  );
 */
export const getByIds = createSelector(selectAtlasSchoolState, (state: State, props: { ids: number[] }) => {
  return props.ids.map((id) => state.entities[id]);
});

/**
 * returns array of objects in the order of the given ids
 * @example
 * atlasSchool$: AtlasSchoolInterface = this.store.pipe(
    select(AtlasSchoolQueries.getById, { id: 3 })
  );
 */
export const getById = createSelector(
  selectAtlasSchoolState,
  (state: State, props: { id: number }) => state.entities[props.id]
);
