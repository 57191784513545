import { Action } from '@ngrx/store';
import { AlgebrakitSessionInterface } from '../../+models';

export enum AlgebrakitSessionActionTypes {
  AlgebrakitSessionsLoaded = '[AlgebrakitSessions] AlgebrakitSessions Loaded',
  AlgebrakitSessionsLoadError = '[AlgebrakitSessions] Load Error',
  LoadAlgebrakitSessions = '[AlgebrakitSessions] Load AlgebrakitSessions',
  DeleteAlgebraKitSessions = '[AlgebrakitSessions] Delete AlgebrakitSessions',
}

export class LoadAlgebrakitSessions implements Action {
  readonly type = AlgebrakitSessionActionTypes.LoadAlgebrakitSessions;

  constructor(public payload: { force?: boolean; userId: number } = { userId: null }) {}
}

export class AlgebrakitSessionsLoaded implements Action {
  readonly type = AlgebrakitSessionActionTypes.AlgebrakitSessionsLoaded;

  constructor(public payload: { algebrakitSessions: AlgebrakitSessionInterface[] }) {}
}

export class AlgebrakitSessionsLoadError implements Action {
  readonly type = AlgebrakitSessionActionTypes.AlgebrakitSessionsLoadError;
  constructor(public payload: any) {}
}

export class DeleteAlgebrakitSessions implements Action {
  readonly type = AlgebrakitSessionActionTypes.DeleteAlgebraKitSessions;

  constructor(public payload: { ids: number[] }) {}
}

export type AlgebraKitSessionActions =
  | LoadAlgebrakitSessions
  | DeleteAlgebrakitSessions
  | AlgebrakitSessionsLoaded
  | AlgebrakitSessionsLoadError;
