import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonApi, PurchaseApi, SchoolApi } from '../+api';
import {
  AtlasStatus,
  LicenseInterface,
  PurchaseInterface,
  PurchaseProductInterface,
  PurchaseTableV2Interface,
  SchoolBillingAddressInterface,
  SchoolBillingInterface,
} from '../+models';
import { DalOptions, DAL_OPTIONS } from '../dal.module';
import { GetPurchasesOptionsInterface, PurchaseServiceInterface } from './purchase.service.interface';

@Injectable({
  providedIn: 'root',
})
export class PurchaseService implements PurchaseServiceInterface {
  private options: DalOptions = inject<DalOptions>(DAL_OPTIONS);
  private httpClient: HttpClient = inject(HttpClient);
  private personApi: PersonApi = inject(PersonApi);
  private schoolApi: SchoolApi = inject(SchoolApi);
  private purchaseApi: PurchaseApi = inject(PurchaseApi);

  getAllForUser(userId: number): Observable<PurchaseInterface[]> {
    return this.personApi
      .getData(userId, 'purchases')
      .pipe(map((res: { purchases: PurchaseInterface[] }) => res.purchases));
  }

  getAllForYear(schoolYear: number): Observable<PurchaseInterface[]> {
    return this.purchaseApi.getPurchasesForSchoolYear(schoolYear) as Observable<PurchaseInterface[]>;
  }

  getPurchases(
    schoolYear: number,
    currentTableAtlasStatus: AtlasStatus,
    options?: GetPurchasesOptionsInterface
  ): Observable<{ results: PurchaseTableV2Interface[]; count: number }> {
    const { apiBaseUrl } = this.options;
    const url = `${apiBaseUrl}/api/purchases/filtered`;

    const { searchTerm, filters, columns, sorting, pagination } = options || {};

    const filtersWithSchoolYear = {
      ...filters,
      'Purchase.schoolYear': schoolYear,
      'Purchase.atlasStatus': currentTableAtlasStatus,
    };
    const params = new HttpParams({
      fromObject: {
        searchTerm: searchTerm || '',
        filters: JSON.stringify(filtersWithSchoolYear || {}),
        columns: JSON.stringify(columns || null),
        sorting: JSON.stringify(sorting || {}),
        pagination: JSON.stringify(pagination || {}),
      },
    });

    return this.httpClient.get<{ results: PurchaseTableV2Interface[]; count: number }>(url, {
      params,
      withCredentials: true,
    });
  }

  getAtlasStatusTotals(schoolYear: number): Observable<{ [key: string]: number }> {
    const { apiBaseUrl } = this.options;
    const url = `${apiBaseUrl}/api/purchases/status/total`;

    const params = new HttpParams({
      fromObject: {
        schoolYear: schoolYear.toString(),
      },
    });

    return this.httpClient.get<{ [key: string]: number }>(url, {
      params,
      withCredentials: true,
    });
  }

  placeOrder(
    schoolId: number,
    productIds: number[],
    fullDigital: number[],
    customerOrderReference = '',
    schoolBillingAddress?: SchoolBillingAddressInterface
  ): Observable<{
    purchase: PurchaseInterface;
    purchaseProducts: PurchaseProductInterface[];
    licenses: LicenseInterface[];
    schoolBillings: SchoolBillingInterface[];
  }> {
    return this.schoolApi
      .placeOrder(schoolId, productIds, customerOrderReference, schoolBillingAddress, fullDigital)
      .pipe(
        map(
          ({
            purchase,
            purchaseProducts,
            licenses,
            schoolBillings,
          }: {
            purchase: PurchaseInterface;
            purchaseProducts: PurchaseProductInterface[];
            licenses: LicenseInterface[];
            schoolBillings: SchoolBillingInterface[];
          }) => ({ purchase, purchaseProducts, licenses, schoolBillings })
        )
      );
  }

  updatePurchase(
    schoolId: number,
    purchaseId: number | string,
    purchase: Partial<PurchaseInterface>
  ): Observable<{
    purchase: PurchaseInterface;
    purchaseProducts: PurchaseProductInterface[];
    licenses: LicenseInterface[];
    purchaseProductIdsToDelete: number[];
    schoolBillings: SchoolBillingInterface[];
  }> {
    return this.schoolApi.updatePurchase(schoolId, purchaseId, purchase).pipe(
      map(
        (response: {
          purchase: PurchaseInterface;
          purchaseProducts: PurchaseProductInterface[];
          licenses: LicenseInterface[];
          purchaseProductIdsToDelete: number[];
          schoolBillings: SchoolBillingInterface[];
        }) => ({
          purchase: response.purchase,
          purchaseProducts: response.purchaseProducts,
          licenses: response.licenses,
          purchaseProductIdsToDelete: response.purchaseProductIdsToDelete,
          schoolBillings: response.schoolBillings,
        })
      )
    );
  }

  deletePurchase(id: number): Observable<{
    purchases: PurchaseInterface[];
    purchaseProducts: PurchaseProductInterface[];
    schoolBilling: SchoolBillingInterface;
  }> {
    return this.schoolApi.deletePurchase(id) as unknown as Observable<{
      purchases: PurchaseInterface[];
      purchaseProducts: PurchaseProductInterface[];
      schoolBilling: SchoolBillingInterface;
    }>;
  }

  createPurchase(
    schoolId: number,
    purchaseToCreate: PurchaseInterface
  ): Observable<{
    purchase: PurchaseInterface;
    purchaseProducts: PurchaseProductInterface[];
    licenses: LicenseInterface[];
    schoolBillings: SchoolBillingInterface[];
  }> {
    return this.schoolApi.createPurchase(schoolId, purchaseToCreate).pipe(
      map(
        ({
          purchase,
          purchaseProducts,
          licenses,
          schoolBillings,
        }: {
          purchase: PurchaseInterface;
          purchaseProducts: PurchaseProductInterface[];
          licenses: LicenseInterface[];
          schoolBillings: SchoolBillingInterface[];
        }) => ({
          purchase,
          purchaseProducts,
          licenses,
          schoolBillings,
        })
      )
    );
  }

  finalisePurchases(purchaseIds?: number[]): Observable<{
    purchases: PurchaseInterface[];
    purchaseProducts: PurchaseProductInterface[];
    schoolBillings: SchoolBillingInterface[];
  }> {
    return this.purchaseApi.finalisePurchases(purchaseIds || ([{}] as number[])).pipe(
      map(
        ({
          purchases,
          purchaseProducts,
          schoolBillings,
        }: {
          purchases: PurchaseInterface[];
          purchaseProducts: PurchaseProductInterface[];
          schoolBillings: SchoolBillingInterface[];
        }) => ({
          purchases,
          purchaseProducts,
          schoolBillings,
        })
      )
    );
  }
}
