import { ArrayFunctions } from '@campus/utils';
import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { LessonSheetInterface } from '../../+models';
import { LessonSheetActions, LessonSheetsActionTypes } from './lesson-sheet.actions';

export const NAME = 'lessonSheets';

export interface State extends EntityState<LessonSheetInterface> {
  loaded: boolean;
  error?: any;
  loadedForBook: Dictionary<boolean>;
}

export const adapter: EntityAdapter<LessonSheetInterface> = createEntityAdapter<LessonSheetInterface>({
  sortComparer: ArrayFunctions.sortByKey('title'),
});

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loadedForBook: {},
});

export function reducer(state = initialState, action: LessonSheetActions): State {
  switch (action.type) {
    case LessonSheetsActionTypes.AddLessonSheet: {
      return adapter.addOne(action.payload.lessonSheet, state);
    }

    case LessonSheetsActionTypes.AddLessonSheets: {
      return adapter.addMany(action.payload.lessonSheets, state);
    }

    case LessonSheetsActionTypes.UpdateLessonSheet: {
      return adapter.updateOne(action.payload.lessonSheet, state);
    }

    case LessonSheetsActionTypes.UpdateLessonSheets: {
      return adapter.updateMany(action.payload.lessonSheets, state);
    }

    case LessonSheetsActionTypes.DeleteLessonSheet: {
      return adapter.removeOne(action.payload.lessonSheetId, state);
    }

    case LessonSheetsActionTypes.LessonSheetsLoaded: {
      return adapter.setAll(action.payload.lessonSheets, {
        ...state,
        loaded: true,
      });
    }

    case LessonSheetsActionTypes.LessonSheetsLoadError: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case LessonSheetsActionTypes.AddLessonSheetsForBook: {
      const { bookId, lessonSheets } = action.payload;

      return adapter.addMany(lessonSheets, {
        ...state,
        loadedForBook: { ...state.loadedForBook, [bookId]: true },
      });
    }

    default: {
      return state;
    }
  }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
