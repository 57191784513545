import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'join' })
export class JoinPipe implements PipeTransform {
  transform(value: any[], property?: string, delimiter: string = ', ') {
    if (!Array.isArray(value)) return value;

    return value.map((v) => (property ? v[property] : v)).join(delimiter);
  }
}
