import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NAME, selectAll, selectEntities, selectIds, selectTotal, State } from './method-style.reducer';

export const selectMethodStyleState = createFeatureSelector<State>(NAME);

export const getError = createSelector(selectMethodStyleState, (state: State) => state.error);

export const getLoaded = createSelector(selectMethodStyleState, (state: State) => state.loaded);

export const getAll = createSelector(selectMethodStyleState, selectAll);

export const getCount = createSelector(selectMethodStyleState, selectTotal);

export const getIds = createSelector(selectMethodStyleState, selectIds);

export const getAllEntities = createSelector(selectMethodStyleState, selectEntities);

export const getByEduContentBookId = (eduContentBookId: number) =>
  createSelector(getAll, (entities) =>
    entities.find((entity) => Number(entity.eduContentBookId) === Number(eduContentBookId))
  );

export const isBookLoaded = (props: { bookId: number }) =>
  createSelector(selectMethodStyleState, (state: State) => !!state.loadedForBook[props.bookId]);
