<ng-container *ngIf="hasScore; else noResult">
  <div class="shared-score__score ui-body-1"> {{ result.score | number: '1.0-0' }}%</div>
  <div *ngIf="anyIncomplete" class="shared-score__wip" data-cy="shared-score-incomplete"
    ><campus-icon class="shared-score__wip-icon" svgIcon="hourglass"></campus-icon
  ></div>
  <div *ngIf="attemptedClasses.length" class="shared-score__attempt-list">
    <ng-container *ngIf="!(anyIncomplete && attemptedClasses.length === 1)">
      <div
        class="shared-score__attempt-item"
        *ngFor="let attempt of attemptedClasses"
        [ngClass]="attempt"
        data-cy="shared-score-attempt"
      ></div
    ></ng-container>
  </div>
</ng-container>

<ng-template #noResult>
  <campus-icon class="shared-score__no-result-icon" svgIcon="no-result" data-cy="shared-score-no-result"></campus-icon>
</ng-template>
